import React, { useState } from 'react';
import './alertzone.scss';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md'; // Import icons
import alarm1 from 'assets/images/containers/main/alram1.jpeg';
import alarm2 from 'assets/images/containers/main/alram2.jpeg';

const alerts = [
  {
    image: alarm1,
  },
  {
    image: alarm2,
  },
];

const AlertZone = () => {
  const [currentAlert, setCurrentAlert] = useState(0);

  const nextAlert = () => {
    setCurrentAlert((prev) => (prev + 1) % alerts.length);
  };

  const prevAlert = () => {
    setCurrentAlert((prev) => (prev === 0 ? alerts.length - 1 : prev - 1));
  };

  return (
    <div className="alertCon">
      <div className="alert-zone-container">
        <h2 className="alert-title">팝업존</h2>
        <div className="alert-zone-slides">
          {alerts.map((alert, index) => (
            <div
              key={index}
              className={`alert-slide ${
                index === currentAlert ? 'active' : ''
              }`}
            >
              <img src={alert.image} alt={`Alert ${index}`} />
            </div>
          ))}
        </div>
        <button
          type="button"
          onClick={prevAlert}
          className="alert-zone-btn prev-btn"
        >
          <MdChevronLeft size={24} /> {/* React icon for previous button */}
        </button>
        <button
          type="button"
          onClick={nextAlert}
          className="alert-zone-btn next-btn"
        >
          <MdChevronRight size={24} /> {/* React icon for next button */}
        </button>
      </div>
    </div>
  );
};

export default AlertZone;
