import React from 'react';

// 컴포넌트
import SubTitle from 'components/subTitle/SubTitle';

// scss
import './greetings.scss';

// 이미지
import bossSignMo from 'assets/images/containers/business/greetings/bossSign_mo.png';
import bossSignPc from 'assets/images/containers/business/greetings/bossSign_pc.png';
import backMo from 'assets/images/containers/business/greetings/greetings_back_mo.png';
import backPc from 'assets/images/containers/business/greetings/greetings_back_pc.png';


function Greetings() {
  return (
    <div className="greetings subCon">
      <SubTitle />
      <div className='greetingsInner'>
        <dl>
          <dt>뛰어난 품질과 혁신을 향한<br/>농가의 파트너, 젖소개량사업소</dt>
          <dd>안녕하십니까?<br/>젖소개량사업소는 지난 60여년을 낙농가 여러분의 애정과 관심 속에서 지속적으로 우리 낙농가 젖소개량에 이바지하여 왔습니다.</dd>
          <dd>개량의 첫 단계인 인공수정 기술의 개발과 보급은 물론, 낙농선진국으로부터 우수 씨수소를 도입하여 저렴한 가격으로 농가에 정액을 공급함으로써 낙농경영의 부담을 줄여 목장의 생산성 향상을 높일 수 있도록 하였습니다.</dd>
          <dd>또한 낙농선진국으로부터 상위 0.1%의 고능력 수정란을 도입하여 청정육종농가에 수정란 이식을 통해 우리나라 환경에 최적합한 한국형 씨수소를 선발함으로써 낙농가 개량향상에 이바지하고 있습니다.</dd>
          <dd>1995년 최초의 한국형 씨수소 “한강	&#40;208HO 10001&#41;”을 시작으로 70여두의 종축을 선발하였습니다. 이렇게 선발된 한국형 씨수소 중 “비티에스&#40;208HO 00556	&#41;” 을 비롯한 많은 한국형 씨수소가 국제유전능력평가&#40;INTERBULL&#41; 기관의 객관적인 검증을 통해, 우리나라 젖소 개량에 뛰어난 능력을 가졌음을 입증하였습니다.</dd>
          <dd>하지만 우리 젖소개량사업소의 개량의 꿈은 더 크고 넓습니다.앞으로의 젖소개량사업소 비전을 “세계 최고의 젖소개량기관”으로 삼아 우리 낙농가의 개량은 물론 더 넓은 세계 시장으로의 진출 기반으로 구축해 나가겠습니다.</dd>
          <dd>우리 사업소 홈페이지를 통하여 실시간 검정성적 조회는 물론 유우군능력검정으로 수집된 약1억건의 빅데이터를 인공지능 등 다양한 분석기법을 통해 19년도부터 경제수명 및 도태우 분석, 유전평가 계획교배 등 신규 개량분석 서비스를 제공하고 있습니다.</dd>
          <dd>앞으로도 우리 사업소 홈페이지를 통하여 목장의 개량속도 향상과 경제적인 목장경영을 이루시길 바라며, 농협 젖소개량사업소 홈페이지가 낙농가 여러분께 유익한 정보의 장이 되도록 최선을 다하겠습니다.</dd>
        </dl>
        <div className='bossSign'>
          <p>젖소개량사업소장 <span>최완용</span></p>
        </div>
        <div className='greetingsBack'>
          <img
            srcSet={`${backMo} 768w, ${backPc} 769w`}
            sizes="(max-width: 768px) 100vw, 50vw"
            alt='젖소개량사업소 전경'
          />
        </div>
      </div>
    </div>
  );
}

export default Greetings;
