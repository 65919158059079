import React from 'react';
import useWindowSize from 'hooks/useWindowSize';

// 이미지
import Ox3One from 'assets/images/containers/ox/oxData/ox3_1.png';
import Ox3TwoPc from 'assets/images/containers/ox/oxData/ox3_2_pc.png';
import Ox3TwoMo from 'assets/images/containers/ox/oxData/ox3_2_mo.png';
import Ox3Three from 'assets/images/containers/ox/oxData/ox3_3.png';

function Ox3() {

  const windowSize = useWindowSize();

  return (
    <div className='ox3'>
      <strong>개량된 젖소를 엊기 위해</strong>
      <p className='ox3Title'>젖소개량 씨수소의 중요성을<span> 알고 계시나요?</span></p>
      <p className='ox3Text'>아래 계통도에서 보듯이 아비소&#40;씨수소&#41;가 자식에게 미치는 유전적 개량 가능성이 76%로 좋은 정액을 선택하는 것이 얼마나 중요한 것인가를 알 수 있으며, 이것이 곧 젖소개량의 지름길임을 나타내고 있습니다.</p>
      <div className='ox3Titleimg'>
        <img src={Ox3One} alt='젖소 유전적 개량 가능성 100%'/>
      </div>
      <div className='plan'>
        <span>계획교배 요령</span>
        <ul>
          <li className='oxSquence'>
            <span>계획교배의 순서</span>
            {
              windowSize.width >= 768
              ?
              <img src={Ox3TwoPc} alt='계획교배 순서 이미지' />
              :
              <img src={Ox3TwoMo} alt='계획교배 순서 이미지' />
            }
            <div className='hidden'>
              <p>측군에 대한 철저한 분석, 측군의 계량목표 설정, 종모우에 대한 자료수집을 통해 개량목표에 부합되는 암소별 종모우를 선정한다. 1. 근친교배를 피한다. 2. 산유능력이 우수한 정액을 선정한다. 3. 불량한 체형부위를 개선할 정액을 선정한다. 4.정액의 특장점을 고려한다.</p>
            </div>
          </li>
          <li className='oxSample'>
            <span>측군자료 정리 예</span>
            <div className='tablebox'>
              <table>
                <thead>
                  <tr>
                    <th >명호</th>
                    <th>산차</th>
                    <th>305일 성년</th>
                    <th>유지율</th>
                    <th>아비</th>
                    <th>외조부</th>
                    <th>개량대상 체형</th>
                    <th>비고</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td >1호</td>
                    <td>5</td>
                    <td>9,500</td>
                    <td>3.5</td>
                    <td>H-177</td>
                    <td>H-058</td>
                    <td>유방깊이</td>
                    <td>&nbsp;</td>
                  </tr>
                  <tr>
                    <td >2호</td>
                    <td>5</td>
                    <td>7,650</td>
                    <td>3.7</td>
                    <td>H-177</td>
                    <td>H-076</td>
                    <td>뒷다리 각도</td>
                    <td>&nbsp;</td>
                  </tr>
                  <tr>
                    <td >3호</td>
                    <td>4</td>
                    <td>6,735</td>
                    <td>3.6</td>
                    <td>H-157</td>
                    <td>&nbsp;</td>
                    <td>발굽 및 다리</td>
                    <td>&nbsp;</td>
                  </tr>
                  <tr>
                    <td >~</td>
                    <td>~</td>
                    <td>~</td>
                    <td>~</td>
                    <td>~</td>
                    <td>~</td>
                    <td>~</td>
                    <td>~</td>
                  </tr>
                  <tr>
                    <td >9호</td>
                    <td>1</td>
                    <td>8,760</td>
                    <td>3.6</td>
                    <td>H-186</td>
                    <td>H-156</td>
                    <td>체형전반</td>
                    <td>&nbsp;</td>
                  </tr>
                  <tr>
                    <td >10호</td>
                    <td>1</td>
                    <td>10,235</td>
                    <td>2.7</td>
                    <td>H-188</td>
                    <td>H-156</td>
                    <td>엉덩이부위</td>
                    <td>&nbsp;</td>
                  </tr>
                  <tr>
                    <td >평균</td>
                    <td>2.7</td>
                    <td>8,349</td>
                    <td>3.3</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </li>
          <li className='manyTable'>
            <span>개체별 개량방향 설정</span>
            <ul>
              <li>
                <div className='imgbox'>
                  <small small>-생산능력 개량방향</small>
                  <img src={Ox3Three} alt='생산능력 개량방향'/>
                </div>
                <div className='tablebox'>
                  <table>
                    <thead>
                      <tr>
                        <th colSpan="2" >추천 씨수소</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td >A, D 그룹</td>
                        <td >H-194, H-197, H-254, H-265, H-266, H-267, H-269, H-270</td>
                      </tr>
                      <tr>
                        <td >B 그룹</td>
                        <td >H-194, H-197, HK-020, H-254, H-261, H-263, H-267, H-268</td>
                      </tr>
                      <tr>
                        <td >C 그룹</td>
                        <td >H-194, H-197, H-200, H-254, H-261, H-265, H-266, H-267, H-270</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </li>
              <li>
                <small>-체형능력 개량방향</small>
                <ul className='tableArea'>
                  <li className='tablebox'>
                    <table>
                      <thead>
                        <tr>
                          <th>구분</th>
                          <th>유방부위 개량</th>
                          <th>체형 전반 개량</th>
                          <th>발굽 및 다리 개량</th>
                          <th>비고</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>해당암소</td>
                          <td>1호, 7호</td>
                          <td>2호, 9호, 10호</td>
                          <td>2호, 3호</td>
                          <td>&nbsp;</td>
                        </tr>
                      </tbody>
                    </table>
                  </li>
                  <li className='tablebox'>
                    <table>
                      <thead>
                        <tr>
                          <th colSpan="2" >추천 씨수소</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td >유방부위 개량</td>
                          <td >H-194, H-261, H-263, H-264, H-268, H-269, H-270, H-923</td>
                        </tr>
                        <tr>
                          <td >체형 전반 개량</td>
                          <td >H-261, H-266, H-267, H-268, H-269, H-270, H-923</td>
                        </tr>
                        <tr>
                          <td >발굽 및 다리 개량</td>
                          <td >H-266</td>
                        </tr>
                      </tbody>
                    </table>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li className='oxGuide'>
            <span>씨수소 선택요령</span>
            <ul>
              <li>선정 씨수소와 근친우려 씨수소 &#40;수정 대상 암소의 아비소가 동일시&#41;는 피한다.</li>
              <li>여러 가지 형질을 한꺼번에 개량하는 것 보다는 중점개량 형질을 우선하여 씨수소를 선정한다.</li>
              <li>젖소 씨수소 총괄표를 활용한다.</li>
            </ul>
          </li>
          <li className='oxGuide'>
            <span>기타 알아두셔야 할 사항</span>
            <ul>
              <li>가급적 수정대상 씨수소를 여유있게 선정한다.</li>
              <li>년초 교배계획을 작성하여 수정사나 조합에 미리 신청한다.</li>
              <li>인공수정시 수정증명서를 반드시 발급받아 확인 보관한다.</li>
              <li>씨수소에 대한 정보에 항상 관심을 갖는다.</li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Ox3;
