import React from 'react';

import Figure1by1 from 'assets/images/sub/_images/figure_1_1.gif';

function Fo7() {

  return (
    <div className="techinfo_view">
				<p className="viewTitle">소의 번식생명</p>
				<p>동물에 있어서, 종족의 번식과 생존을 지키는 여러 가지 방법이 있다.</p>
				<p>고기와 파충류에서 암컷은 수많은 알을 낳으며, 그 알에는 수정란과 영양물질, 보호 외투막이 수정란을 둘러싸고 있다. 하지만 약탈자의 침입과 알의 성장과 부화에 부적합한 환경으로 많은 알들이 새로운 생명으로 부화를 하지 못하게 된되는데, 이와는 반대로 포유동물의 경우는 적은 난자를 만들어 내지만 분만전까지 암컷의 배속에서 외부로부터 보호함으로써 생존율을 높이고 있다.</p>
				<p className="mg30f">산자의 생산 또는 번식의 과정은 매우 복잡한 과정이며 섬세한 조직에서 일어난다. 또한 번식이 없으면, 유우에서 우유를 생산할 수 없다는 측면에서 번식에서의 성공은 성공적인 유우생산의 시작을 의미한다(그림 1.1) </p>
				<div className="imgBox"><img src={Figure1by1} alt="" /></div>
				<div>
					불임의 동물들은 번식을 할수 없다. 경영상태가 아주 좋은 유우목장에서도 가끔 소가 일반적인 수준보다 번식률이 떨어지는데, 낮은 번식률의 형성은 목장의 중대한 재정적문제를 수반하게 되는데 그 이유는 아래와 같다. 
					<ol>
						<li>1. 소의 수명에 비하여 우유 생산량의 감소를 가져온다. </li>
						<li>2. 번식 장애 및 새로이 임신을 유도하기위한 처리를 통하여 직접적이 소비의 증가를 가져온다. </li>
						<li>3. 축군의 유전적 개량이 늦어지게 되는데, 이는 도태할수 있는 개체가 제한적이기 때문이다. 일반적으로 고능력우의 경우는 번식성적이 좋지 않고 저능력우는 번식성적이 좋은 경향을 보이는데 저능력우의 자식이 축군 대체용으로 될 가능성이 높기 때문이다. </li>
					</ol>
				</div>
				
				<p>발육상태가 좋은 미경산우에서 성성숙, 또는 성적인 성숙의 시작은 대략 생후 10개월에 일어난다. 열대지방의 경우에는 성성숙이 다른 지역에 대하여 조금 늦게 일어나 14개월 또는 더늦게 나타나기도 한다.</p>
				<p>발정주기는 성성숙 이후에 순환되며 미경산우는 발정주기는 21일이다. 미 경산우의 경우 대략 생후 15∼16개월에 수정이 이루어져 24∼25개월령에 첫송아지를 분만하여야 이상적이다. 그리고 초산후에도 12∼13개월을 주기로 새끼를 출산하여야 경제적이다. 하지만 유우의 연중 유량이 9000kg이상인 고능력우에서는 13개월에서 14개월을 주기로 출산을 유도하여도 괜찮다. </p>
				<div>
					아래의 두가지 경우는 경제적인 많은 손실을 가져오는 경우이다.
					<ol>
						<li>1. 생후 15개월에 생리적인 발달이 되지 않거나 적절한 크기에 도달하지 못하여 수정을 유도하지 못하는 미경산우 </li>
						<li>2. 분만 주기가 15∼16개월보다 더 길게 나타나는 수정력이 낮은 유우 </li>
					</ol>
				</div>
				<table className="tablestyle">
					<caption>표 1.1 유우의 번식 패턴</caption>
					<thead>
						<tr>
							<th className="first">특징</th>
							<th>평균</th>
							<th>범위</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>성성숙 도달월령</td>
							<td>10</td>
							<td>6-14</td>
						</tr>
						<tr>
							<td>발정주기 일수</td>
							<td>21</td>
							<td>18-24</td>
						</tr>
						<tr>
							<td>발정기(시간)</td>
							<td>18</td>
							<td>10-24</td>
						</tr>
						<tr>
							<td>배란(발정시작후 시간)</td>
							<td>11</td>
							<td>5-16</td>
						</tr>
					</tbody>
				</table>
			</div>
  );
}

export default Fo7;
