import React from 'react';

function GeneReport() {
  return (
    <div>
      GeneReport
    </div>
  );
}

export default GeneReport;