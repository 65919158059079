import React from 'react';

import pic657 from 'assets/images/sub/_images/pic657.jpg';
import pic658 from 'assets/images/sub/_images/pic658.jpg';
import pic659 from 'assets/images/sub/_images/pic659.jpg';
import pic660 from 'assets/images/sub/_images/pic660.jpg';

function Do129() {

  return (
    <div className="techinfoview">
      <h1>갓난 송아지의 변비증:일명태변정체</h1>
      <p className="pstyle">갓난 송아지의 장내에는 태변(胎便)이라 불리우는 태아 때의 대사물이 들어 있어 출생후(1-2일 이내)에 배설되지만 그렇지 않는 때는 포유를 싫어하고 기운없이 고통을 받다가 폐사하는 일이 있다.</p>
      <div className="leftpart">
        <h2>원인</h2>
        <ul>
          <li>갓난 송아지의 태변은 초유의 작용으로 배설되지만 때로는 정체하여 변비가 된다.  
            <ul>
              <li>초유섭취가 부족하거나, 초유에 이상이 있을 때 태변이 배설되지 않고 발병한다. </li>
              <li>태변이 단단하게 굳어 있을 때</li>
              <li>환경의 급변시 태변이 정체한다. </li>
            </ul>
          </li>
        </ul>
        <h2>증상</h2>
        <ul>
          <li>발병은 생후 2∼3일 만에 나타난다. </li>
          <li> 등을 구부리고 배 쪽(복부)을 돌아보며 복통 증세를 나타낸다.</li>
          <li>포유를 싫어하며 젖을 주어도 먹으려고 하지 않는다.</li>
          <li>호흡이 가빠진다. (정상 18∼20회/분)</li>
          <li>항문에 까많게 말라붙은 변이 묻어있다. </li>
          <li>태변이 배설되지 않을때는 폐사하기도 한다.</li>
        </ul>
        <h2>목장에서의 처치</h2>
        <ul>
          <li>사전예방으로 초유가 부족할때는 대용초유를 먹이든가 포유시에 사하제를 섞어 급여한다. (※ 대용 초유 만드는 법, 제 2장 간호와 응급처치편 참조 )</li>
          <li>대량의 미지근한 물이나 글리세린(50% 액 300∼1000ml)또는 비눗물로 관장하여 태변의 배출을 시도한다. </li>
          <li>태변이 항문을 막고 있으면 이것을 손가락으로 후벼파서 제거한다. 사전예방을 위해서 출생 직후부터 개별우리에서 격리 사유한다. </li>
          <li>액물 치료법으로 네오스티그민 제제를 1∼3ml 피하주사한다. </li>
          <li>쇠약해 있을때는 강심제를 주사한다.</li>
        </ul>
      </div>
      <div className="rightpart">
        <img src={pic657} className="nonborder" alt=""/>
        <img src={pic658} className="nonborder" alt=""/>
        <img src={pic659} className="nonborder" alt=""/>
        <img src={pic660} className="nonborder" alt=""/>
      </div>
    </div>
  );
}

export default Do129;