import React from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';

// react-query
import { useQuery, useQueryClient } from '@tanstack/react-query';

// api
import { getState, postState } from 'utils/api';

// utils
import { openOxPrint } from 'utils/OpenOxPrint';

function SemenView() {
  const queryClient = useQueryClient();

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const semenNo = searchParams.get('s_j_no');

  const { data, refetch } = useQuery({
    queryKey: ['semen', semenNo],
    queryFn: () =>
      getState('/board/select_semen_jumoon_list', { s_j_no: semenNo }),
    enabled: !!semenNo,
  });

  const semen = data?.result1;
  const semenList = data?.result2 || [];

  const handleDelete = async () => {
    if (window.confirm('정액 주문을 삭제 하시겠습니까?')) {
      try {
        const response = await postState('/board/delete_semen_jumoon/', {
          s_j_no: semenNo,
        });
        if (response?.return_code === 'SUCCESS') {
          alert('삭제 완료');
          queryClient.invalidateQueries({
            queryKey: ['semen'],
          });
          navigate('/bo/semen/semenList');
        }
        if (response?.return_code === 'ERROR') {
          alert(response?.return_message);
        }
      } catch (error) {
        console.error('Delete semen error:', error);
        alert('삭제 중 오류가 발생했습니다.');
      }
    }
  };

  const handleStatusToggle = async () => {
    const statusMessage = semen?.S_J_CMPL_FL === 1 ? '미완료처리' : '완료처리';
    if (window.confirm(`${statusMessage} 하시겠습니까?`)) {
      try {
        const response = await postState('/board/update_semen_sjcmplfl/', {
          s_j_no: semenNo,
        });
        if (response?.return_code === 'SUCCESS') {
          alert(`${statusMessage} 완료`);
          refetch(); // Refresh data after status update
        }
        if (response?.return_code === 'ERROR') {
          alert(response?.return_message);
        }
      } catch (error) {
        console.error('Update status error:', error);
        alert('상태 변경 중 오류가 발생했습니다.');
      }
    }
  };

  return (
    <div className="semen-view bo-content">
      <h2>정액 주문 정보</h2>

      <div className="table-container bold-border">
        <table>
          <thead>
            <tr>
              <th>코드</th>
              <th>명호</th>
              <th>아비혈통</th>
              <th>종합지수</th>
              <th>유량</th>
              <th>지방</th>
              <th>단백질</th>
              <th>체형</th>
              <th>유방</th>
              <th>가격</th>
              <th>주문량</th>
              <th>주문금액</th>
            </tr>
          </thead>
          <tbody>
            {semenList.length === 0 ? (
              <tr>
                <td colSpan="12" className="no-data">
                  주문 정보가 없습니다.
                </td>
              </tr>
            ) : (
              semenList.map((item, index) => (
                <tr key={index}>
                  <td>
                    <button
                      type="button"
                      onClick={() => openOxPrint(item.S_CODE)}
                    >
                      {item.S_CODE}
                    </button>
                  </td>
                  <td>{item.S_SORTNAME}</td>
                  <td>{item.S_SSORTNAME}</td>
                  <td>{`${item.S_ORIGIN} ${item.S_TPI}`}</td>
                  <td>{Number(item.S_PTAM).toLocaleString()}</td>
                  <td>{item.S_PTAF}</td>
                  <td>{item.S_PTAT}</td>
                  <td>{item.S_PTAP}</td>
                  <td>{item.S_UDC}</td>
                  <td>{Number(item.S_DANGA).toLocaleString()}</td>
                  <td>{item.S_STR}</td>
                  <td>{Number(item.SUM_MNY).toLocaleString()}</td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>

      <div
        className="table-container bold-border"
        style={{ marginTop: '1rem' }}
      >
        <table>
          <tbody>
            <tr>
              <th width="20%">주문자명</th>
              <td width="30%" style={{ textAlign: 'start' }}>
                {semen?.S_J_NAME}
              </td>
              <th width="20%">전화번호</th>
              <td width="30%" style={{ textAlign: 'start' }}>
                {semen?.S_J_TEL}
              </td>
            </tr>
            <tr>
              <th>주소</th>
              <td colSpan="3" style={{ textAlign: 'start' }}>
                {semen?.S_J_ADDR}
              </td>
            </tr>
            <tr>
              <th>공급받을곳</th>
              <td style={{ textAlign: 'start' }}>{semen?.S_B_NAME}</td>
              <th>공급받을곳 전화</th>
              <td style={{ textAlign: 'start' }}>{semen?.S_B_TEL}</td>
            </tr>
            <tr>
              <th>공급희망일</th>
              <td colSpan="3" style={{ textAlign: 'start' }}>
                {semen?.S_B_DATE}
              </td>
            </tr>
            <tr>
              <th>메모</th>
              <td colSpan="3" style={{ textAlign: 'start' }}>
                {semen?.S_J_MEMO}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="button-group">
        <button type="button" onClick={handleStatusToggle} className="btn-l">
          {semen?.S_J_CMPL_FL === 1 ? '미완료처리' : '완료처리'}
        </button>
        <button type="button" onClick={handleDelete} className="btn-l">
          삭제
        </button>
        <button
          type="button"
          onClick={() => {
            if (window.confirm('주문 정보를 수정하시겠습니까?')) {
              navigate(`/web/Ox/BuySemen/orderedit/${semenNo}`);
            }
          }}
          className="btn-l"
        >
          주문정보수정
        </button>
        <button type="button" onClick={() => navigate(-1)} className="btn-l">
          목록
        </button>
      </div>
    </div>
  );
}

export default SemenView;
