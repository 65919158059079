import React from 'react';

import pic773 from 'assets/images/sub/_images/pic773.jpg';
import pic774 from 'assets/images/sub/_images/pic774.jpg';

function Do169() {

  return (
    <div className="techinfoview">
      <h1>간질증</h1>
      <p className="pstyle">제방의 풀섶이나 하천주변에 서식하는 간질충이 방목한 젖소를 통해 체내에 침입하여 담관내에 기생하면서 간장을 파괴하며 급, 만성간염을 일으키는 기생충 질병으로서 젖소에서 가장 피해가 큰 기생충 병이다. </p>
      <div className="leftpart">
        <h2>증상</h2>
        <ul>
          <li>최근 독성이 강항 농약이 사용규제 경향에 따라 중간숙주인 우렁이가 증식하여 급성 간질증의 발생을 볼 수있게 되었다.
            <ul>
              <li>급성
                <ul>
                  <li>원기와 식욕이 줄고 복통, 비유량감소, 혈변이 나타난다.(증상은 기생충수에 의해 좌우된다.) </li>
                </ul>
              </li>
              <li>만성
                <ul>
                  <li>감염후 대개 60일∼300일 사이에 유충이 간(肝)에서 담관으로 옮겨가 만성 담관염과 간경화를 일으킨다. </li>
                  <li>이 시기에 소는 현저히 쇠약하고 지속성 설사와 빈혈이 나타난다.</li>
                  <li>말기에는 앞가슴과 아랫턱이 차가운 느낌이 부종이 생기며, 극도로 쇠약해져 기립불능에 빠진다. </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
        <h2>목장에서의 처치</h2>
        <ul>
          <li>구충으로 오염원을 제거한다. </li>
          <li>달팽이의 구제(驅除):늪, 물구덩에 황상동을 뿌려 0.001%의 용액이 되게 하면 8분만에 달팽이가 죽는다. </li>
          <li>치료대책으로서 구충제의 투여와 동시에 허약한 소에 대해서는 영양제 등으로 대증요법을 실시한다.</li>
        </ul>
      </div>
      <div className="rightpart">
        <img src={pic773} className="nonborder" alt=""/>
        <img src={pic774} className="nonborder" alt=""/>
      </div>
    </div>
  );
}

export default Do169;