import React from 'react';

function Fo6() {

  return (
    <div className="techinfo_view">
				<p className="viewTitle">젖소의 생산성에 관한 형질의 총합적 개량</p>
				<h2>개량이 필요한 형질</h2>
				<dl>
					<dt>생애를 통한 생산성</dt> 
					<dd>
						<p>종래, 젖소의 생산성지표로서는, 1유기의 생산에 집착한 비유능력이 중시되어 왔다.</p>
						<p>그러나, 젖소에는 육성기간이라든지, 건유기간등의 비생산기간이 있기 때문에, 1유기의 비유능력이 생산성에 직결되는 것만은 아니다. 특히, 근년에는 착유우의 평균산차가 짧아지고 있다. 공용년수도 단축되는 경향이 있고, 1995년의 &quot;낙농전국기초조사&quot;에 회답한 약 2만호의 폐용산차는 평균 4.1산으로 되어 있다. 더구나, 후리-스톨우사를 가진 낙농가의 49%는 평균 3산이하로 되어 있다. 비유 피 - 크가 5산 이후에 온다는 것, 공용년수 4년과 5년의 차이가 원유 1kg당 2엔정도의 상각비차가 생기는 것(2편 (3)의 (다) 참조)을 고려한다면, 폐용산차는 적어도 5산까지는 늘릴 필요가 있다.</p>
						<p>이 때문에 암소의 탄생으로부터 폐용까지 생애를 통한 생산성에 대한 중요성이 증가하고 있다. 생애를 통한 생산성을 직접 측정한다는 것은, 그 암소가 폐용될 때까지 기다리지 않으면 안된다. 그 경우, 암소선발에는 쓰이지 않으며, 후대검정 하는데도 대단히 장기간을 요한다. 그 때문에, 생애를 통한 생산성에 간접적으로 관계되는 형질을 찾아, 그들 형질을 비유형질과 병합해서 총합적으로 개량할 필요가 있다. </p>
					</dd>
				</dl>
				<dl className="mg60f">
					<dt>지표가 되는 형질</dt> 
					<dd>
						<p>생애를 통한 생산성에 영향을 미치는 것은, 비유능력에 더하여 주로 장수연산성(連産性), 즉 강건성과 번식성이다. 특히 근년에 와서, 공용년수의 단축경향은 급속한 유량증가나 후리 - 스톨 우사의 보급에, 강건성이 쫓아가지 못하는 것이 원인으로 생각된다.</p>
						<p>초산월령이나, 초회 수정 수태율등의 번식성은 대개 유전율이 낮기 때문에, 개량보다도 육성이나 사양관리의 개선이 중요하다고 이야기되고 있다. 한편, 강건성은 직접 측정되는 형질이 아니므로, 지표가 되는 체형심사가 실시되고 있다.</p>
						<p>그리고, 최근에는 산차가 나아감에 따라 원유중 체세포 수가 중가하는 것도 공용년수 단축의 한 원인으로 이야기되고 있다. 단지, 우군평균이 적은 우군에서는 고산차가 되어도 거의 증가하지 않는 것이 보고되고 있기 때문에, 먼저 위생관리의 철저가 중요하다고 생각된다. (그림33) 그러나, 유전적개량도 가능하다고 생각되기 때문에 일본에서도, 유전적능력평가의 검토가 추진되고 있다.</p>
					</dd>
				</dl>

				<h2>총합적개량을 위한 총합지수 </h2>
				<dl>
					<dt> NPT의 개발</dt> 
					<dd>
						<ul className="listStyle03 mg10l">
							<li>총합지수 
								<p>이와 같이, 생애를 통한 생산성 향상은, 단일형질의 개량에 의한 것이 아니고, 복수형질을 총합적으로 개량해 나가는 수밖에 없다. 그러나, 비유만이라도 7형질이나 있는데, 여기에다 체형등까지 첨가한 다수의 형질을 다룬다는 것은 어떻게 했으면 좋겠는지 판단하기 어려워진다.</p>
								<p>이 때문에, 후에 이야기하는 미국의 TPI의 카나다의 LPI와 같이, 주요한 낙농국에서는, 총합적인 경제성에서 뛰어난 젖소를 선발하기 위한 지수를 이용하고 있다. 이와 같은 &quot;총합지수&quot;가 있으면, 먼저 암소도 종모우도 총합지수로 선발하고, 그 위에 개객 젖소의 취약한 부분을 보완하는 교배를 해 나가면 되는 것이다.</p>
								<p>일본에서도 총합지수의 작성이 기대되어 오던 중, 1996년부터 종모우와 우군검정우에 대한 NPT(Nippon Total Index의 약칭)가 공표하겠금 되었다. NPT는 (사)일본홀스타인등록협회가 국내의 데이터를 써서, 제일선의연구자, 기술자들이 분석·검토하여 개발한 지수로, 가축개량센타가 산출한 유전적능력평가치로부터 계산된다. </p>
							</li>
							<li>장수 연산성에 관한 형질 
								<p>NPT는 일본 젖소의 생애를 통한 생산성을 높히는 것을 목적으로 하고 있다. 구체적으로는, 비유능력의 개량량을 극력 저하시키는 일없이 장수 연산성(連産性)을 높히는 것을 의도하고 있다.</p>
								<p>NPT의 작성에서는 비유형질, 체형형질과 장수 연산성과의 관련등 상세한 분석이 이루어지고 있다. 장수 연산성이란, 72개월령까지의 착유일수(이하 &quot;72개월령 생산일수&quot;라 함)가 유전적 개량을 위한 지표로서 뛰어난 역할을 한다.</p>
								<p>72개월령생산일수는 저능력우가 조기에 도태되므로, 유량이나 유성분과의 사이에 높은 유전상관(유전상관으로 0.7전후)이 있다는 사실이 인정되고 있다. 또한, 체형형질에서는 강건성과 유전적관계가 추정되는 유기(乳器)에 관한 형질과의 관계가 주목되었다. </p>
							</li>
							<li>NPT의 산출식
								<p>이와 같은 분석을 거쳐서 작성된 NPT의 산출식은 다음과 같다.</p>
								<table className="tablestyle20 mg15f">
									<tr>
										<td rowSpan="2">NPT=〔3x</td>
										<td>산유성분</td>
										<td rowSpan="2"> + 1 x </td>
										<td>체형성분</td>
										<td rowSpan="2">x 100 </td> 
									</tr>
									<tr>
										<td>166</td>
										<td>1.212</td>
									</tr>
								</table>
								<ul className="mg15f">
									<li><strong>산유성분</strong>=(-0.07x유량의 EBV)+(1x유지량의 EBV)+(8x유단백질량의 EBV)</li>
									<li><strong>체형성분</strong>=(1x결정득점의 EBV)+(1x유방성분)</li>
									<li><strong>유방성분</strong>=(0.22x전유방부착의 EBV)+(0.14x후유방높이의 EBV)+(0.05x후유방폭의 EBV)+ (0.16x유방헌수의 EBV)+(0.35x유방깊이의 EBV)+(0.08x유두배치의 EBV)</li>
								</ul>
								<p>NPT의 산출식의 분모에 있는 166과 1.212은 산유성분과 체형성분의 단위차이등을 조정하기 위한 것이다.</p>
								<p>산유성분과 체형성분의 비중은 3:1로 되어 있다. 이것은 &quot;산유성분만의 때에 비하여 비유형질의 개량량을 극력저하시키지 않고, 장수 연산성의 개량량을 최고로 올리는 비중붙이기&quot;이다. </p>
							</li>
							<li>산유성분 
								<p>유량과 유성분량에는 유성분율과의 사이에 유전적으로 부(負)의 상관(한쪽이 증가하면 다른 쪽이 감소하는 관계)이 있다. 생산성 향상을 위해서는 유량과 유성분량을 증가시킬 필요가 있으나, 유성분율이 저하하는 것은 바람직하지 못하다. 개량증식목표에서 표시하는 바와 같이, 현재의 원유 유성분율이나 음용우유의 유제품 수요상황, 금후의 원유수요등을 고려하면, 유지율은 현상을 유지시키고 유단백질율은 높혀, 유량을 늘려 나가는 것이 적당하다고 생각된다.</p>
								<p>비유능력에 대해서는 산유성분으로서, 유량, 유지량, 유단백질량에 -0.07:1:8의 비중을 붙여주고 있다. 이것은 &quot;유지율이 저하시키지 않으면서 유단백질율을 년당 +0.01%개량하는 경우에, 유량,유성분량과 72개월령 생산일수의 개량량이 최대가 되는 비중두기&quot;이다. 유량의 비중이 약간 마이너스로 되어 있으나, 유지량과 유단백질량에 큰 비중이 있기 때문에, 유량의 개량량도 년133kg까지 이르는 것으로 예측된다.</p>
							</li>
							<li>체형성분
								<p>체형형질에 대해서는 &quot;장수 연산성과의 관계가 가장 강하게 나타나는 지수&quot;가 당초에 검토되었다. 그 결과, 유기(乳器)에 관한 선형형질만이 선택되어, 여기에 비중을 붙인 앞서 말한 바와 같은 유방성분(乳房成分)이 작성되었다. 낙농가에 대한 앙케이트에서는 유기(乳器)와 지제(肢蹄)에 관한 형질이 중시 되었으나, 후지측망(後肢側望)과의 제의 각도(啼의 角度)에는 명확한 가치가 인정되지 않았다. 이에 대하여, 최종적으로는 관계자의 요망이 강함에 따라서, 지제(肢蹄)도 포함되어 있는 결정득점이 첨가하게 되었다.</p>
								<p>먼저, 지제(肢蹄)에 대해서는, 강건성과의 관련이 강하다고 일반적으로 생각되고 있는 점에서, 1997년도에는 득점구분의 일반외모로부터 지제를 분리한 유전적능력평가가 개시됨과 함께, 선형심사에 있어서 새로이 후지후망(後肢後望)의 심사가 시작되고 있다. 이 때문에 NPT에서도 어떤 경우이든 이것을 받아들여 나가는 것이 바람직하다. </p>
							</li>
						</ul>
					</dd>
				</dl>
				
				<dl>
					<dt>NPT의 사용방법</dt> 
					<dd>
						<p>&lt;표 32&gt;에는 암소와 종모우의 선택에 NPT를 사용한 경우와 산유성분만을 이용하여 선발한 경우에는 유량·유단백질량의 개량량을 그만큼 저하시키지 않고도, 체형과 72개월령 생산일수가 개량된다는 것을 알 수 있다.</p>
						<p>이와같이 NPT는 비유형질과 체형형질을 동시에 균형좋게 개량하므로써, 생애를 통한 생산성향상을 가져올 수 있는 것이다. 암소뿐만 아니라, 교배하는 종모우도, 먼저 NPT의 서열로 선발·선정하면 된다.</p>
						<p>그 위에, 유단백질율이 낮은 암소에는 단백질율이 높은 종모우를 교배하는 등 약점을 보완하므로써, 우군이 유전적능력이 가장 빨리 균형좋게 개량된다. 5편의 (3)에서 말한 후대검정의 강화를 위한 추천우(표23의 5)는 이점을 고려한 평가로 보아진다.</p>
						<p>NPT에 조입(租入)된 형질이 일부에 너무 치우쳤거나, 그 비중붙이기에 불안감이 있는 경우도 있다고 생각된다. 그러나, 일본과 조건이 다른 해외의 연구성과를 그대로 가져온 것이 아니고, 국내에서 축적된 데이터에 근거하여 분석한 것에 입각한 것으로, 충분한 신뢰에 바탕을 두었다고 생각된다.</p>
						<p>그런데, 총합지수는 그때 그때의 젖소의 유전적능력에 관한 정보나 그 수준 또는, 원유가격, 거래조건, 여기에 더하여 앞으로 추구할 개량방향등에 의하여 결정되는 것이다. 또한, 기초분석이나 지수작성기술도 매년 고도화가 시도되고 있다. 그 때문에 나라에 따라 시대에 따라서 변화할 수 있고, 일본에서도 계속해서 개량되어 나가고 있다.</p>
						<p>또한, 유대효과(종래의 경제효과)도 지수의 일종이지만, 이것은 비유형질의 유전적능력을, 현재의 원유거래에 있어서, 메릿트로 환산한 것이다. 그 때문에 장기적인 개량의 지침으로서는 NPT가 훨씬 우수한 개량지수이다.</p>
					</dd>
				</dl>
				<table className="tablestyle">
					<caption>&lt;표 32&gt; NPT 및 산유성분을 써서 종모우 및 암소를 선발한 경우에 기대되는 년당 유전적 개량량</caption>
					<thead>
						<tr>
							<th className="first">구 분</th>
							<th>유량(kg)</th>
							<th>유지량 (kg)</th>
							<th>무지고형분량(kg)</th>
							<th>유단백질량(kg)</th>
							<th>결정득점</th>
							<th>유기</th>
							<th>유방성분(乳房成分)</th>
							<th>72개월령 생산일수(일)</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>NPT</td>
							<td>133</td>
							<td>5.7</td>
							<td>11.9</td>
							<td>4.6</td>
							<td>0.02</td>
							<td>0.03</td>
							<td>0.02</td>
							<td>15.5</td>
						</tr>
						<tr>
							<td>산유성분</td>
							<td>149</td>
							<td>6.0</td>
							<td>13.0</td>
							<td>4.9</td>
							<td>-0.01</td>
							<td>0.00</td>
							<td>-0.04</td>
							<td>14.3</td>
						</tr>
					</tbody>
				</table>
				<ul className="refer mg10t mg60f">
					<li>(주) 유방성분은 관계하는 6개형질 각각의 개량량으로부터 산출 </li>
				</ul>

				<h2>여러외국의 총합지수 </h2>
				<dl>
					<dt>미국</dt> 
					<dd>
						<p>미국에서는 농무성이 산출한 유전적능력평가치를 써서, 미국홀스타인협회가 TPI(Type Production Index의 약칭)를 공포하고 있다. TPI의 산출식은 빈번히 개량되어, 1997년 8월 시점에서는 아래와 같이 되어 있다.</p>
						<p>즉 PTA는 ETA에 상당한 값을 가진다. </p>
						<div className="bgBox">
							<table className="tablestyle21 mg15f">
								<tr>
									<td rowSpan="2">TPI= [(3x</td>
									<td>유단백질량의 PTA</td>
									<td rowSpan="2">+ 1x</td>
									<td>(유지량의 PTA)</td>
									<td rowSpan="2">+ 1x</td>
									<td>(결정득점의 PTA) </td>
								</tr>
								<tr>
									<td>19.0</td>
									<td>22.5</td>
									<td>0.7</td>
								</tr>
								<tr>
									<td rowSpan="2">+</td>
									<td>(0.65x유방성분)</td>
									<td rowSpan="2">+</td>
									<td>(0.35x지제성분)]</td>
									<td rowSpan="2">x50 + 576 </td>
								</tr>
								<tr>
									<td>0.8</td>
									<td>0.85</td>
								</tr>
							</table>
							<p>유방성분=(0.16x전유방부착의 STA) + (0.16x후유방높이의 STA) + (0.12x후유방폭의 STA) + (0.10x유방헌수의 STA) + (0.30x유방깊이의 STA) + (0.16x유두배치의 STA)</p>
							<p className="mg0f">지제성분=0.5x[(0.48x제의각도의 STA) + (0.37x후지후망의 STA) - (0.15x후지측망의 STA)] + (0.5x지제스코아)</p>
						</div>
						<p>이와같이, NPT와 비교한 경우, 체형의 비중이 1/3로 크다는 점이라든지, 체형에 지제성분을 첨가한 점이 주목된다.</p>
						<p>또한, 일본의 유대효과에 상당한 지수로서는 유량, 유지량, 유단백질량의 PTA의 비중을 변화한 3종류의 PTA달러(Cheese Yield, Milk/Fat, Milk/ Fat/ Protein)가 농무성에 의하여 산출되고 있다. 또한, 1995년 1월부터는 유량, 유지량, 유단백질량에 체세포수 스코아와 생산수명도 고려한 NM달러(Net Merit의 약칭)도 산출되고 있다. 금후, 일본에서도 NM달러와 같은 지수개발이 바람직하다. </p>
					</dd>
				</dl>
				
				<dl>
					<dt>카나다</dt> 
					<dd>
						<p>카나다에서는 CDN(Caanadian Dairy Network의 약칭, 1996년부터 카나다 농무성에서 위임받아, 유전적능력평가를 실시하고 있는 기관)이 분석한 유전적능력평가치를 사용해서, 카나다 홀스타인협회가 LPI(Lifetime Profit Index의 약칭)를 공표하고 있다. LPI의 산출식도 빈번히 개량되어, 1997년 8월 시점에서, 아래와 같은 식이 유도되었다. sd는 표준평차를 말한다.</p>
						<table className="tablestyle22 mg15f">
							<tr>
								<td rowSpan="2">LPI=7x6x[</td>
								<td>(2x유지량의 EBV)</td>
								<td rowSpan="2">+ </td>
								<td>(9x유단백질의 EBV) </td>
							</tr>
							<tr>
								<td>sd</td>
								<td>sd</td>
							</tr>
							<tr>
								<td rowSpan="2">+4x[</td>
								<td> (1x선형심사의 EBV)</td>
								<td rowSpan="2">+ </td>
								<td>(1x체적의 EBV) </td>
							</tr>
							<tr>
								<td>sd</td>
								<td>sd</td>
							</tr>
							<tr>
								<td rowSpan="2">+</td>
								<td>(4x지제의 EBV)</td>
								<td rowSpan="2">+</td>
								<td>(5x유기의 EBV)</td>
							</tr>
							<tr>
								<td>sd</td>
								<td>sd</td>
							</tr>
						</table>
						<p>NPT와 비교한 경우, 체형의 비중이 2/5로 미국보다 더 커지고 있다. 또한 미국의 NM달러에 상당하는 비유능력과 체세포수스코아, 우군수명을 고려한 TEV(Total Economic Value의 약칭)도 산출되고 있다. </p>
					</dd>
				</dl>
				
				<dl>
					<dt>화란</dt>
					<dd>
						<p>화란에서는 농협조직인 NRS가 유전적능력평가를 실시(1998년중에는 와닝겐대학으로 변경될 예정)하여 비유형질만으로 INET(Net Guilder Index의 약칭)를 산출하고 있다.</p>
						<p>INET=(-0.15x유량의 BV) + (2x유지량의 BV) + (12x유단백질량의 BV)</p>
						<p>이 지수는, 유지율을 일정하게 보존하면서, 유단백질량을 개량하는 것을 의도하고 있다. 결과적으로 유량과 유지량도 증가하게 된다. 비중붙이기는 일본의 산유성분에 가까우나, 개념은 경제효과에 가까운 것으로, 단위도 화란의 통화단위인 길다(Guilder)로 되어 있다.</p>
						<p>(주) 각국의 총합지수에서는 각 형질의 평가치를 표준편차로 나누는 것등과 같이 조정이 이루어지고 있기 때문에, 각 형질에 곱해지고 있는 계수에 의하여, 각 형질에 대한 비중붙이기를 비교할 수는 없다. (유단백질량과 유지량의 비중붙이기를 일본의 8:1에 대해 미국은 3:1로 서로 비교할 수는 없다.) </p>
					</dd>
				</dl>
			</div>
  );
}

export default Fo6;
