import React from 'react';

// scss
import './webpage_list.scss';

// 이미지
import imgF1 from 'assets/images/containers/data/relatedSites/fertilized/imgSite05_1.jpg';
import imgF3 from 'assets/images/containers/data/relatedSites/fertilized/imgSite05_3.jpg';
import imgF4 from 'assets/images/containers/data/relatedSites/fertilized/imgSite05_4.jpg';
import imgF7 from 'assets/images/containers/data/relatedSites/fertilized/imgSite05_7.jpg';
import imgF8 from 'assets/images/containers/data/relatedSites/fertilized/imgSite05_8.jpg';
import imgF10 from 'assets/images/containers/data/relatedSites/fertilized/imgSite05_10.jpg';
import imgF13 from 'assets/images/containers/data/relatedSites/fertilized/imgSite05_13.jpg';
// import imgNO from 'assets/images/containers/data/relatedSites/imgSite_no.gif';

function Fertilized() {
  return (
    <div className="Fertilized">
      <div className="webpage">
        <dl className="webpage_list">
          <dd>
            <a
              href="https://www.absglobal.com/"
              target="_blank"
              rel="noreferrer"
            >
              <div className="webpage_banner">
                <img src={imgF1} alt="ABS Global, Inc." />
              </div>
              <p>ABS Global, Inc.</p>
            </a>
          </dd>

          {/* <dd>
                    <div className="webpage_banner">
                        <img src={imgNO} alt="Shores Holsteins International"/>
                    </div>
                    <p>Shores Holsteins International</p>                    
                </dd> */}
          <dd>
            <a
              href="http://www.amsgenetics.com/"
              target="_blank"
              rel="noreferrer"
            >
              <div className="webpage_banner">
                <img src={imgF3} alt="AMS Genetics" />
              </div>
              <p>AMS Genetics</p>
            </a>
          </dd>
          <dd>
            <a
              href="https://sunshinegenetics.com/"
              target="_blank"
              rel="noreferrer"
            >
              <div className="webpage_banner">
                <img src={imgF4} alt="Sunshine Genetics" />
              </div>
              <p>Sunshine Genetics</p>
            </a>
          </dd>
          {/* <dd>
                    <div className="webpage_banner">
                        <img src={imgNO} alt="Cormdale Genetics"/>
                    </div>
                    <p>Cormdale Genetics</p> 
                </dd>
                <dd>
                      <div className="webpage_banner">
                        <img src={imgNO} alt="AB Technology"/>
                    </div>
                    <p>AB Technology</p>
                </dd> */}
          <dd>
            <a
              href="https://www.genesdiffusion.com/"
              target="_blank"
              rel="noreferrer"
            >
              <div className="webpage_banner">
                <img src={imgF7} alt="Genes Diffusion" />
              </div>
              <p>Genes Diffusion</p>
            </a>
          </dd>
          <dd>
            <a
              href="https://www.accelgen.com/"
              target="_blank"
              rel="noreferrer"
            >
              <div className="webpage_banner">
                <img src={imgF8} alt="Accelerated Genetics" />
              </div>
              <p>Accelerated Genetics</p>
            </a>
          </dd>
          {/* <dd>
                    <div className="webpage_banner">
                        <img src={imgNO} alt="Huff'n Puff Embryo Transfer"/>
                    </div>
                    <p>Huff&apos;n Puff Embryo Transfer</p>                    
                </dd> */}
          <dd>
            <a
              href="https://www.bles-dairies.nl/"
              target="_blank"
              rel="noreferrer"
            >
              <div className="webpage_banner">
                <img src={imgF10} alt="Bles Dairies Genetics" />
              </div>
              <p>Bles Dairies Genetics</p>
            </a>
          </dd>
          {/* <dd>
                    <div className="webpage_banner">
                        <img src={imgNO} alt="Parrs Inc."/>
                    </div>
                    <p>Parrs Inc.</p> 
                </dd>
                <dd>
                    <div className="webpage_banner">
                        <img src={imgNO} alt="Manco Genetics Mating Site"/>
                    </div>
                    <p>Manco Genetics Mating Site</p> 
                </dd> */}
          <dd>
            <a
              href="https://www.semex.com/i?page=home"
              target="_blank"
              rel="noreferrer"
            >
              <div className="webpage_banner">
                <img src={imgF13} alt="Semex" />
              </div>
              <p>Semex</p>
            </a>
          </dd>
        </dl>
      </div>
    </div>
  );
}

export default Fertilized;
