import React from 'react';

import d1by12 from 'assets/images/sub/_images/1_12.gif';
import d1by69 from 'assets/images/sub/_images/1-69.jpg';
import d1by75 from 'assets/images/sub/_images/1-75.jpg';
import d1by77 from 'assets/images/sub/_images/1-77.gif';

function Fo25() {

  return (
    <div className="techinfo_view">
				<p className="viewTitle">분만 전 관리</p>
				<h2>분만이 다가 온다면?</h2>
				<ul className="listStyle04 mg15f">
					<li>착유우군으로 넣는다.</li>
					<li>유방의 털을 깎거나 털을 뽑는다거나 잔털을 태워 없앤다. </li>
					<li>소의 몸, 유방에 접촉하여 사람에게 익숙하게 한다. </li>
					<li>유방이 불룩해져오면 Tipping(가볍게 만진다)한다. </li>
					<li>익숙하게 하여 급여를 한다. </li>
					<li>Holstein(홀스타인)종의 임신기간은 표준 280일이지만 초산우 평균은 276이라고 말 할 수 있다.(9개월 6일)</li>
					<li>출산 예정일의 계산식은 종부(種付)월일 - 3개월 + 6일. 예정일의 10일전부터 확실히 감시한다. </li>
				</ul>
				<div className="imgBox mg60f"><img src={d1by69} alt="" title="유방은 털을 뽑거나 잔털을 태워 없애는 쪽이 소는 얌전하다(화재에 주의)" /></div>
				
				<h2>적응시킨 급여로 준비개시</h2>
				<ul className="listStyle04 mg15f">
					<li>적응시킨 급여는 증체가 목적이 아니라, 분만후의 고영양에 대응 할 수 있도록 반추내 미생물상을 준비하기 위해</li>
					<li>분만 예정의 10∼14일 전부터 시작한다</li>
					<li>분만후에 사용할 사료를 반드시 조금(소량)을 준다</li>
					<li>농후사료의 CP가 15∼16%가 되도록 분배한다</li>
					<li>농후사료의 양은 1일당 4kg까지 차츰 차츰 증가시킨다</li>
					<li>분만 20일전에 Ca제는 전면중지하고, 뼈로부터 공급할 신호를 보낸다</li>
				</ul>
				<div className="imgBox mg60f"><img src={d1by12} alt="" title="길들이는 급여 방법" /></div>
				
				<h2>대낮에 태어나게 하기 위한 절차</h2>
				<ul className="listStyle04 mg15f">
					<li>분만예정 12개월 전부터 급사방법을 야간급사로 바꾼다</li>
				</ul>
				<table className="tablestyle">
					<thead>
						<tr>
							<th className="first">분만 시각</th>
							<th>야간급사 <sup>1)</sup></th>
							<th>주간급사 <sup>2)</sup></th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>5시 ∼ 13시</td>
							<td>55.3%</td>
							<td>36.6%</td>
						</tr>
						<tr>
							<td>13시 ∼ 21시</td>
							<td>36.2%</td>
							<td>34.9%</td>
						</tr>
						<tr>
							<td>21시 ∼ 5시</td>
							<td>8.5%</td>
							<td>28.5%</td>
						</tr>
					</tbody>
				</table>
				<ul className="refer mg10t mg30f">
					<li>1) 시험군 2) 대조군 (帶廣축산 대학) </li>
				</ul>
				
				<h2>청결한 분만실에서의 출산</h2>
				<ul className="listStyle04 mg15f">
					<li>분만실은 분만이외의 목적으로 사용하지 않는다.(포육용등)</li>
					<li>곰팡이등이 붙어 있지 않는 깔짚을 충분히 넣는다</li>
					<li>부료(깔짚)는 긴 것을 사용한다
						<ul>
							<li>a: 짚류는 긴채로 사용한다</li>
							<li>b: 톱밥, 화산회등은 사용하지 않는다. </li>
							<li>c: 짚등의 대용으로 신문지을 재단(裁斷)하여 사용하는 것도 유효.</li>
						</ul>
					</li>
					<li>분만실의 사용후는 오물을 꺼내어 건조시켜 둔다</li>
					<li>분만실은 소가 용이하게 돌아 다니는 것이 가능하고, 분만 개조의 보조기구를 사용할 수 있는 공간이 필요.(3.7 ×3.7m정도)</li>
				</ul>
				<div className="imgBox mg60f"><img src={d1by75} alt="" title="분만실은 청결히 하고, 긴 채로 짚을 넣는다." /></div>
				
				<h2>분만 시중을 위해 준비해 둘것</h2>
				<ul className="listStyle04 mg15f">
					<li>될 수 있는 한 자연 분만을 시키고, 과도한 조산(助産)은 하지 않는다</li>
					<li>초산우는 분만이 시작되고 나서 2시간을 목표로 하여 조산(助産)을 한다</li>
				</ul>
				<div className="imgBox mg60f"><img src={d1by77} alt="" /></div>
				
				<div className="bgBox">
					<h3>저자소개</h3>
					<ul className="listStyle01">
						<li>(元山良平: 모토야마료우헤이) </li>
						<li>1953년 북해도(훗카이도) 八雲마을 출생</li>
						<li>帶廣 축산대학 졸업</li>
						<li>현재 북해도(北海道:훗카이도) 十勝(토카치)중부지구 농업개량 보급소 전문 보급원</li>
						<li>1991년 8월 20일 발행</li>
						<li>발행: Dairyman 사(社)</li>
					</ul>
				</div>
			</div>
  );
}

export default Fo25;
