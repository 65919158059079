import React from 'react';
import { Outlet , useLocation } from 'react-router-dom';

// 컴포넌트
import SubTitle from 'components/subTitle/SubTitle';

import CommonBoardApi from 'components/board/commonBoardApi';

// scss

function IndustryNews() {

  const location = useLocation();

  return (
    <div className="industryNews subCon">
      <SubTitle />
      {
        location.pathname.includes('detail') ? (
          <Outlet />
        ) : (
          <CommonBoardApi />
        )
      }
    </div>
  );
}

export default IndustryNews;
