import React from 'react';

function Fo55() {

  return (
    <div className="techinfo_view">
				<p className="viewTitle">요결석</p>
				<dl>
					<dt>발병대상</dt>
					<dd>소, 양, 특히 단기 비육중인 소와 양</dd>
				</dl>
				
				<dl>
					<dt>원인</dt>
					<dd>
						<p>미국과 캐나다 북부평원의 실리카가 풍부한 토양에서 방목하거나 또는 곡류 비율이 높은 사료 급여시 요중에 무기질과 여러가지 염분의 침적이 생긴다. 그러나 원인이 되는 요인들은 명확하게 알려져 있지 않다. 지금까지 알려진 요결석의 원인은</p>
						<ul className="listStyle04 mg15f">
							<li>칼슘의 과다섭취</li> 
							<li>인의 비율이 높고 칼슘의 비율이 낮을 때(요결석을 예방하는데 알맞은 비율은 Ca : P의 비율이 2 :1정도 일때)</li> 
							<li>사료중에 실리카 함량이 높거나 또는 면실박, 수수, 펄프, 사탕무우 엽부, 밀짚, 천연 목초 등 silica함량이 높은 곡류와 목초로 인해 발생한다.</li> 
						</ul>
						<p>이외 비타민 A의 결핍이나 diethylstibestrol의 복용량이 많을 때 발생할 수 있다.</p>
					</dd>
				</dl>
				
				<dl>
					<dt>징후 및 증상</dt>
					<dd>자주 배뇨를 시도하거나 배뇨의 정지 또는 소량으로 배설되고 고통과 신장 복통을 유발한다. 요결석은 암컷의 경우 결석을 배설할 수 있기 때문에 수컷에 한하여 일어난다. 요결석에 걸리면 방광이 파열되어 폐사하기도 하며 혹은 요독증이 생길수도 있다. 요결석은 특히 단기 비육중인 거세한 소와 양에 배합사료만을 급여할때 잘 발생하는 질병이다.</dd>
				</dl>
				
				<dl>
					<dt>질병분포와 손실규모</dt>
					<dd>전 세계적으로 분포하며 결석의 발생이 사양 말기에 자주 일어나기 때문에 경제적인 타격이 크다. 일단 발병하면 완전한 회복이 어렵다.</dd>
				</dl>
				
				<dl>
					<dt>치료</dt>
					<dd>
						<p>다양한 치료방법이 있다.</p>
						<ul className="listStyle04 mg15f">
							<li>염화 암모늄을 일일 두당 30g(자양) 또는 38-45g(소)의 비율로 급여하거나 황화 암모늄을 급여할 경우는 50-60% 더 많이 첨가한다.</li>
							<li>인의 비율(목초)을 늘려서 칼슘의 함량과 같게 한다(monosodium phosphate를 첨가).</li>
							<li>사료중 염의 함량을 3-4%로 증가시켜 음수량이 증가하도록 유도한다(염 함량이 지나치게 많으면 사료섭취량이 떨어진다).</li>
							<li>사료중에 알팔파를 20% 비율이 되게 배합한다.</li>
							<li>방광으로부터 결석의 통과를 돕기 위하여 근육 이완제를 사용한다.</li>
							<li>수술로 결석을 제거한다.</li>
						</ul>  
						<p>소의 경우 수술을 통하여 결석을 제거해 주는 것이 가장 효율적인 치료 방법이다. 거세우의 경우는 요도를 이분화시킨 후 요관의 일부를 압축하여 결석을 체외로 배출시키며 요 조직중의 잔류물을 제거한 거세우는 즉시 시장에 출하하기도 한다. </p>
					</dd>
				</dl>
				
				<dl>
					<dt>대책</dt>
					<dd>비육말기의 거세한 소 또는 양이 요결석에 심하게 걸렸을 경우에는 도축을 하는 것이 일반적이다. 사료중에 소금함량을 3-4%로 늘려주어 음수량을 증가시키도록 한다. 사료에 광범위 항생제를 첨가하여도 요결석을 효과적으로 막을 수 있다.</dd>
				</dl>
				
				<dl>
					<dt>예방</dt>
					<dd>결석의 화학적 성분이 예방의 기본이다. 왜냐하면 결석의 조짐이 보이는 시기에는 배뇨량을 늘리고 감염을 줄이며, 요중 pH를 변화시키거나 약물을 사용하여 대사작용을 변화시키므로써 요줌 일부 화학적 성분을 감소시킬 수 있기 때문이다. 양질의 사료와 관리체계로 발병을 줄일 수 있다. 거세 시기를 늦추고(숫송아지의 경우 4-5개월령에 거세) 단기 비육중인 소에 음수량을 증가시키기 위해 염분함량을 높여 주면(곡류 사료의 경우 염분 함량을 1-3%로 하고, 겨울철에는 상한선까지 급여) 효과적으로 예방할 수 있다. 그러나 다음의 세가지 사항을 피하도록 한다. 1) 고수준의 칼륨이나 인의 섭취 2) 부적당한 Ca : P의 비율 3) 사료중에 비트펄프 또는 수수의 함량 증가 등</dd>
				</dl>	
				
				<dl>
					<dt>참고</dt>
					<dd>요도중에 돌과 같은 결석은 항상 신장으로부터 비롯된다. 이들 덩어리가 배뇨의 흐름을 막아 흔히 &apos;물 배&apos;라고 부르는 상태로 된다. 광물질의 침적은 크기와 형태 및 성분면에서 다양하다. 그러므로 단기 비육중인 소의 경우는 인산염의 형태로 그리고 방목지의 소에서는 자주 규산염의 형태로 침적된다. 한편 캐나다 Alberta연구소의 연구원들에 따르면 러시아 야생호밀에 방목한 송아지보다 자연 목초에 방목한 송아지에서 요결석 발생율이 100배정도 높다고 하였다. </dd>
				</dl>
			</div>
  );
}

export default Fo55;
