import React, { useState , useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { postState } from 'utils/api';

// 컴포넌트
import ErrorMessage from 'components/errorMessage/errorMessage';

// scss
import './pedigree.scss';

function Pedigree() {

  // 에러처리
  const [isModalOpen, setIsModalOpen] = useState(false); // 모달 추가
  const [errorMessage, setErrorMessage] = useState(''); 

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => {
    setIsModalOpen(false);
    setErrorMessage(''); // 모달이 닫힐 때 에러메세지 초기화
  };

  // 데이터 불러오기
  // eslint-disable-next-line no-restricted-globals
  const location = useLocation();
  const baseDir=`${location.pathname.toLowerCase()}/`;   
  
  const originData = useRef(null); 
  const [ mainData , setMainData ] = useState(null);

//   useEffect(() => {
//     console.log(mainData);
//   },[mainData]);

  const [ props , setProps] = useState({
    id: localStorage.getItem('milkFarmCode'),
    passwd: localStorage.getItem('milkFarmPwd'),    
  });

  useEffect(() => {
    postState(baseDir,props)
    .then(getdata => {      
      if (getdata !== undefined) {  
        // console.log(getdata);    
        originData.current = getdata;
        setMainData(getdata.plot_data);
      }
    })
    .catch(error => {           
      setErrorMessage(error.message);
      openModal();
    });    
  }, [props]);

  return (
    <div className='pedigree'>
      <ErrorMessage isOpen={isModalOpen} content={errorMessage} closeModal={closeModal}/>
      <h2>혈통관리</h2>
      <table className='static'>
        <tbody>
          <tr>
            <th>사육현황</th>
            <td>총두수 : {mainData && mainData.length} 두</td>
            <td>경산우 : {mainData && mainData.filter(cow => cow.pari > 0).length} 두</td>
            <td>미경산우 : {mainData && mainData.filter(cow => cow.pari === 0).length} 두</td>
          </tr>
          <tr>
            <th rowSpan='2'>혈통등록현황</th>
            <td>
              <button 
                type='button'
                onClick={()=> setMainData(originData.current.plot_data)}
              >
                등록두수 : {originData.current?.plot_data && originData.current.plot_data.length}두 &#40;100%&#41;
              </button>
            </td>
            <td>
              농가 평균근친도 : 
              {
                mainData && 
                mainData.length > 0
                ?
                `${(mainData.reduce((acc, cur) => acc + Number(cur.kcno),0)/mainData.length).toFixed(2)}%`
                : 0.00
              }
              </td>
            <td>
              근친도 범위 : 
              {
                mainData &&
                mainData.length > 0 
                ?
                `${mainData && Math.min(...mainData.map(item => item.kcno))}% 
                ~ 
                ${mainData && Math.max(...mainData.map(item => item.kcno))}%`
                :
                '0% ~ 0%'
              } 
                
            </td>
          </tr>
          <tr>
            <td>
              <button 
                type='button'
                onClick={()=> 
                  setMainData(
                    originData.current.plot_data.filter(cow => cow.fsemencode === null && cow.dam === null)
                  )
                }
              >
                부모 모름 : {mainData && mainData.filter((item) => item.dam === null && item.fsemencode === null).length}두
                &#40;{
                  mainData && 
                  mainData.length > 0
                  ? Math.floor((mainData.filter((item) => item.dam === null && item.fsemencode === null).length/mainData.length)*100) : 0
                }%&#41;
              </button>
            </td>
            <td>
              <button 
                type='button'
                onClick={()=> 
                  setMainData(
                    originData.current.plot_data.filter(cow => cow.fsemencode === null)
                  )
                }
              >
                부 모름 : {mainData && mainData.filter((item) => item.fsemencode === null).length}두
                &#40;{
                  mainData && 
                  mainData.length > 0
                  ?
                  Math.floor((mainData.filter((item) => item.fsemencode === null).length / mainData.length)*100) : 0
                }%&#41;
              </button>
            </td>  
            <td>
              <button 
                type='button'
                onClick={()=>
                  setMainData(
                    originData.current.plot_data.filter(cow => cow.dam === null)
                  )
                }
              >
                모 모름 : {mainData && mainData.filter((item) => item.dam === null).length}두 
                &#40;{
                  mainData && 
                  mainData.length > 0
                  ? Math.floor((mainData.filter((item) => item.dam === null).length / mainData.length)*100) : 0
                }%&#41;
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <p>※ 혈통정보 관리제공 : 한국종축개량협회</p>
      <div className='pedigreeList'>
        <ul className='pediHead'>
            <li>관리번호</li>
            <li>바코드</li>
            <li>등록번호</li>
            <li>생일</li>
            <li>부정액코드</li>
            <li>모등록번호</li>
            <li>모명호</li>
            <li>근친계수</li>
        </ul>
        <ul className='pediBody'>
          {
            mainData &&
            mainData.map((cow, index) => (
              <li key={index}>
                <ul>
                  <li className='manageNum'>{cow.csname}</li>
                  <li>{cow.barcode}</li>
                  <li>{cow.regno}</li>
                  <li>{cow.birth}</li>
                  <li>{cow.fsemencode}</li>
                  <li>{cow.dam}</li>
                  <li>{cow.mnname}</li>
                  <li>{cow.kcno}</li>
                </ul>
              </li>
            ))
          }
        </ul>
      </div>
    </div>
  );
}

export default Pedigree;
