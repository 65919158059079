import React from 'react';

import Pic19 from 'assets/images/sub/_images/pic19.gif';
import Pic20 from 'assets/images/sub/_images/pic20.gif';
import Pic21 from 'assets/images/sub/_images/pic21.gif';
import Pic22 from 'assets/images/sub/_images/pic22.gif';
import Pic23 from 'assets/images/sub/_images/pic23.gif';
import Pic24 from 'assets/images/sub/_images/pic24.gif';
import Pic25 from 'assets/images/sub/_images/pic25.gif';
import Pic26 from 'assets/images/sub/_images/pic26.gif';
import Pic27 from 'assets/images/sub/_images/pic27.gif';
import Pic28 from 'assets/images/sub/_images/pic28.gif';
import Pic29 from 'assets/images/sub/_images/pic29.gif';
import Pic30 from 'assets/images/sub/_images/pic30.gif';
import Pic31 from 'assets/images/sub/_images/pic31.gif';
import Pic32 from 'assets/images/sub/_images/pic32.gif';
import Pic33 from 'assets/images/sub/_images/pic33.gif';
import Pic34 from 'assets/images/sub/_images/pic34.gif';
import Pic35 from 'assets/images/sub/_images/pic35.gif';
import Pic36 from 'assets/images/sub/_images/pic36.gif';
import Pic37 from 'assets/images/sub/_images/pic37.gif';
import Pic38 from 'assets/images/sub/_images/pic38.gif';
import Pic39 from 'assets/images/sub/_images/pic39.gif';
import Pic40 from 'assets/images/sub/_images/pic40.gif';
import Pic41 from 'assets/images/sub/_images/pic41.gif';
import Pic42 from 'assets/images/sub/_images/pic42.gif';
import Pic43 from 'assets/images/sub/_images/pic43.gif';
import Pic44 from 'assets/images/sub/_images/pic44.gif';
import Pic45 from 'assets/images/sub/_images/pic45.gif';
import Pic46 from 'assets/images/sub/_images/pic46.gif';
import Pic47 from 'assets/images/sub/_images/pic47.gif';
import Pic48 from 'assets/images/sub/_images/pic48.gif';
import Pic49 from 'assets/images/sub/_images/pic49.gif';
import Pic50 from 'assets/images/sub/_images/pic50.gif';
import Pic51 from 'assets/images/sub/_images/pic51.gif';
import Pic52 from 'assets/images/sub/_images/pic52.gif';
import Pic53 from 'assets/images/sub/_images/pic53.gif';
import Pic54 from 'assets/images/sub/_images/pic54.gif';
import Pic55 from 'assets/images/sub/_images/pic55.gif';
import Pic56 from 'assets/images/sub/_images/pic56.gif';
import Pic57 from 'assets/images/sub/_images/pic57.gif';
import Pic58 from 'assets/images/sub/_images/pic58.gif';
import Pic59 from 'assets/images/sub/_images/pic59.gif';
import Pic60 from 'assets/images/sub/_images/pic60.gif';


function Do8() {

  return (
    <div className="techinfoview">
    <h1>선형심사(線形審査) : 1차형질</h1>
    <table className="tablestyle2">
        <tr>
            <th colSpan="2">(1)키(十字部高 : STATURE)</th>
        </tr>
        <tr>
            <td>
                <ul>
                    <li>심사부위 : 양 요각과 허리가 만나는 지점(횡돌기)에서 지면까지의 수직적인 거리</li>
                    <li> 영 향 : 지면과 유방사이의 거리에 영향</li>
                    <li>주의사항 : 처음 시작할 때는 감각을 익히기 위해 체측기를 사용하는 것이 좋다.</li>
                    <li>이상적인 점수대 : 25점 이상</li>
                </ul>
            </td>
            <td>
                <ul>
                    <li>50</li>
                    <li>45-매우 큼(150cm)</li>
                    <li>40</li>
                    <li>35-큼(145cm)</li>
                    <li>30</li>
                    <li>25-중간정도(140cm)</li>
                    <li>20</li>
                    <li>15-작음(150cm)</li>
                    <li>10</li>
                    <li>5-매우작음(130cm)</li>
                    <li>1</li>
                </ul>
            </td>
        </tr>
    </table>
    <table className="tablestyle3">
        <tr>
            <th colSpan="4">(2) 강건성(强健性 : STRENGTH)</th>
        </tr>
        <tr>
            <td>
                <img src={Pic19} alt="강건성"/>
            </td>
            <td>
                <img src={Pic20} alt="강건성"/>
            </td>
            <td>
                <img src={Pic21} alt="강건성"/>
            </td>
            <td rowSpan="3">
                <ul>
                    <li>50</li>
                    <li>45-극히 강하고 넓음</li>
                    <li>40</li>
                    <li>35-매우 강함</li>
                    <li>30</li>
                    <li>25-중간정도의 강건성과 너비</li>
                    <li>20</li>
                    <li>15-좁고 약함</li>
                    <li>10</li>
                    <li>5-극히 좁고 약함</li>
                    <li>1</li>
                </ul>
            </td>
        </tr>
        <tr>
            <td>1~5점</td>
            <td>25점</td>
            <td>45~50점</td>
        </tr>
        <tr>
            <td>극히 좁고 약함</td>
            <td>중간정도의 강건성</td>
            <td>극히 강하고 넓음</td>
        </tr>
        <tr>
            <td colSpan="4">
                <ul>
                    <li>심사부위 : 앞 몸의 튼튼함(흉심, 비경너비, 앞몸의 뼈대)</li>
                    <li>영 향 : 일반적인 건강 및 고 비유능력 유지</li>
                    <li>주의사항 : 지세(持勢)보다 가슴바닥의 너비를 중점적으로 관찰, 살찐 정도에도 영향을 받아서는 안됨</li>
                    <li>이상적인 점수대 : 25점이상(초산우는 15점 이상)</li>
                </ul>
            </td>
        </tr>
    </table>
    <table className="tablestyle3">
        <tr>
            <th colSpan="4">(3) 체심(體深 : BODY DEPTH)</th>
        </tr>
        <tr>
            <td>
                <img src={Pic22} alt="체심"/>
            </td>
            <td>
                <img src={Pic23} alt="체심"/>
            </td>
            <td>
                <img src={Pic24} alt="체심"/>
            </td>
            <td rowSpan="3">
                <ul>
                    <li>50</li>
                    <li>45-극히 강하고 깊음</li>
                    <li>40</li>
                    <li>35-깊음</li>
                    <li>30</li>
                    <li>25-중간정도의 깊이</li>
                    <li>20</li>
                    <li>15-얕음</li>
                    <li>10</li>
                    <li>5-극히 얕음</li>
                    <li>1</li>
                </ul>
            </td>
        </tr>
        <tr>
            <td>1~5점</td>
            <td>25점</td>
            <td>45~50점</td>
        </tr>
        <tr>
            <td>극히 얕음</td>
            <td>중간정도의 깊이</td>
            <td>극히 깊음</td>
        </tr>
        <tr>
            <td colSpan="4">
                <ul>
                    <li> 심사부위 : 뒷갈비의 깊이</li>
                    <li>영 향 : 많은 양의 조사료 소화능력</li>
                    <li>주의사항 : 체장, 키, 다리의 길이에 영향을 받지말고 평가해야 함</li>
                    <li>이상적인 점수대 : 25점이상(초산우는 15점 이상)</li>
                </ul>
            </td>
        </tr>
    </table>
    <table className="tablestyle3">
        <tr>
            <th colSpan="4">(4) 예각성(銳角性 : DAIRY FORM)</th>
        </tr>
        <tr>
            <td>
                <img src={Pic25} alt="예각성"/>
            </td>
            <td>
                <img src={Pic26} alt="예각성"/>
            </td>
            <td>
                <img src={Pic27} alt="예각성"/>
            </td>
            <td rowSpan="3">
                <ul>
                    <li>50</li>
                    <li>45-뼈의 윤곽이 매우 선명, 편평하며 극히 예각적임</li>
                    <li>40</li>
                    <li>35-매우 예각적임</li>
                    <li>30</li>
                    <li>25-예각적임</li>
                    <li>20</li>
                    <li>15-예각성이 부족함</li>
                    <li>10</li>
                    <li>5-굵고 두텁고 거침</li>
                    <li>1</li>
                </ul>
            </td>
        </tr>
        <tr>
            <td>1~5점</td>
            <td>25점</td>
            <td>45~50점</td>
        </tr>
        <tr>
            <td>굵고 거침</td>
            <td>예각적임</td>
            <td>극히 예각적임</td>
        </tr>
        <tr>
            <td colSpan="4">
                <ul>
                    <li>심사부위 : 갈비뼈의 개장도(開張度),허벅지의 두께,목의 길이, 뼈의 편평성(扁平性)</li>
                    <li>영 향 : 우유 생산 능력</li>
                    <li>주의사항 : 갈비뼈의 개장에 1차적인 중점을 두어 평가함.<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    비유단계에 따라 점수가 변하면 안되며, 건유기에 가까운 소들은 감안해 주어야 함.<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    (뼈의 편평성, 갈비의 개장 및 목의 길이는 분만직후나 건유기에도 동일함)</li>
                    <li>이상적인 점수대 : 25점이상</li>
                </ul>
            </td>
        </tr>
    </table>
    <table className="tablestyle3">
        <tr>
            <th colSpan="4">(5) 엉덩이 기울기(角度 : RUMP ANGLE)</th>
        </tr>
        <tr>
            <td>
                <img src={Pic28} alt="엉덩이 기울기"/>
            </td>
            <td>
                <img src={Pic29} style={{width: '60%'}} alt="엉덩이 기울기"/>
            </td>
            <td>
                <img src={Pic30} alt="엉덩이 기울기"/>
            </td>
            <td rowSpan="3">
                <ul>
                    <li>50-좌골이 요각보다 13cm 아래에 위치</li>
                    <li>45</li>
                    <li>40-좌골이 요각보다 9.5cm 아래</li>
                    <li>35</li>
                    <li>30-좌골이 요각보다 9cm 아래</li>
                    <li>25-좌골이 요각보다 4cm 아래</li>
                    <li>20</li>
                    <li>15-좌골과 요각이 수평</li>
                    <li>10-좌골이 요각보다 2cm 위에 위치</li>
                    <li>5</li>
                    <li>1-좌골이 요각보다 6cm 위에 위치</li>
                </ul>
            </td>
        </tr>
        <tr>
            <td>1~5점</td>
            <td>25점</td>
            <td>45~50점</td>
        </tr>
        <tr>
            <td>좌골이 요각보다 높다</td>
            <td>요각에서 좌골까지 약간 경사</td>
            <td>경사가 심하다</td>
        </tr>
        <tr>
            <td colSpan="4">
                <ul>
                    <li>심사부위 : 요각에서 좌골에 이르는 엉덩이 경사도</li>
                    <li>영 향 : 생식기관의 배설기능을 통한 번식능력</li>
                    <li>주의사항 : 미근(尾根)이나 천골(薦骨)에 관계없음. 요각과 좌골이 수평일 경우 15점</li>
                    <li>이상적인 점수대 : 20~30점 사이</li>
                </ul>
            </td>
        </tr>
    </table>
    <table className="tablestyle3">
        <tr>
            <th colSpan="4">(6) 엉덩이 너비(THURL WIDTH)</th>
        </tr>
        <tr>
            <td>
                <img src={Pic31} alt="엉덩이 너비"/>
            </td>
            <td>
                <img src={Pic32} alt="엉덩이 너비"/>
            </td>
            <td>
                <img src={Pic33} alt="엉덩이 너비"/>
            </td>
            <td rowSpan="3">
                <ul>
                    <li>50</li>
                    <li>45-극히 넓은 곤폭</li>
                    <li>40</li>
                    <li>35-넓은 곤폭</li>
                    <li>30</li>
                    <li>25-중간정도의 곤폭</li>
                    <li>20</li>
                    <li>15-약한 좁은 곤폭</li>
                    <li>10</li>
                    <li>5-극히 좁은 곤폭</li>
                    <li>1</li>
                </ul>
            </td>
        </tr>
        <tr>
            <td>1~5점</td>
            <td>25점</td>
            <td>45~50점</td>
        </tr>
        <tr>
            <td>극히 좁음</td>
            <td>중간정도의 곤폭</td>
            <td>극히 넓음</td>
        </tr>
        <tr>
            <td colSpan="4">
                <ul>
                    <li>심사부위 : 고관절(股關節)의 너비</li>
                    <li>영 향 : 분만의 용이성(容易性)</li>
                    <li>주의사항 : 좌골폭이나 요각폭과는 상관없음</li>
                    <li>이상적인 점수대 : 25점이상(초산우는 20점 이상)</li>
                </ul>
            </td>
        </tr>
    </table>
    <table className="tablestyle3">
        <tr>
            <th colSpan="4">(7) 옆에서 본 뒷다리(後肢-側望 : REAR LEGS, SIDE VIEW)</th>
        </tr>
        <tr>
            <td>
                <img src={Pic34} alt="옆에서 본 뒷다리"/>
            </td>
            <td>
                <img src={Pic35} style={{width:'68%'}} alt="옆에서 본 뒷다리"/>
            </td>
            <td>
                <img src={Pic36} alt="옆에서 본 뒷다리"/>
            </td>
            <td rowSpan="3">
                <ul>
                    <li>50</li>
                    <li>45-비절이 낫모양으로 극히 굽음</li>
                    <li>40</li>
                    <li>35-약간 낫모양으로 굽음</li>
                    <li>30</li>
                    <li>25</li>
                    <li>20</li>
                    <li>15-비절이 직비에 가까움</li>
                    <li>10</li>
                    <li>5-막대처럼 곧음</li>
                    <li>1</li>
                </ul>
            </td>
        </tr>
        <tr>
            <td>1~5점</td>
            <td>25점</td>
            <td>45~50점</td>
        </tr>
        <tr>
            <td>직비</td>
            <td>비절에서 중간정도의 굽이</td>
            <td>곡비</td>
        </tr>
        <tr>
            <td colSpan="4">
                <ul>
                    <li>심사부위 : 옆에서 보았을 때 뒷다리 관절의 굽은 정도</li>
                    <li>영 향 : 지제(肢蹄)의 지구력(持久力)과 체중의 효율적 유지</li>
                    <li>주의사항 : 아픈 다리가 있을 경우 정상적인 쪽을 관찰, 앞뒤로 딛고 서 있는 위치를 평가해서는 안됨</li>
                    <li>이상적인 점수대 : 20~30점</li>
                </ul>
            </td>
        </tr>
    </table>
    <table className="tablestyle3">
        <tr>
            <th colSpan="4">(8) 발굽기울기(蹄의 角度 : FOOT ANGLE)</th>
        </tr>
        <tr>
            <td>
                <img src={Pic37} alt="발굽기울기"/>
            </td>
            <td>
                <img src={Pic38} alt="발굽기울기"/>
            </td>
            <td>
                <img src={Pic39} alt="발굽기울기"/>
            </td>
            <td rowSpan="3">
                <ul>
                    <li>50</li>
                    <li>45-각도가 극히 큰 상태</li>
                    <li>40</li>
                    <li>35-발굽이 약간 서있는 상태</li>
                    <li>30</li>
                    <li>25-중간정도의 각도(45도)</li>
                    <li>20</li>
                    <li>15-각도가 적은 발굽</li>
                    <li>10</li>
                    <li>5-극도로 평평한 각도의 발굽</li>
                    <li>1</li>
                </ul>
            </td>
        </tr>
        <tr>
            <td>1~5점</td>
            <td>25점</td>
            <td>45~50점</td>
        </tr>
        <tr>
            <td>평평한 각도</td>
            <td>중간정도의 각도</td>
            <td>각도가 극히 큰 상태</td>
        </tr>
        <tr>
            <td colSpan="4">
                <ul>
                    <li>심사부위 : 옆에서 보았을 때 각질부의 경사면과 지면 사이에 이루어지는 각도</li>
                    <li>영 향 : 지제의 지구력, 운동성, 발굽깍기의 빈도</li>
                    <li>주의사항 : 안팎간의 차이가 있을 때는 바깥쪽을 평가, 긴 발굽인 경우에는 제관부(蹄冠部)가 수평에 가까울수록 각도가 크다고 보면됨</li>
                    <li>이상적인 점수대 : 25점이상</li>
                </ul>
            </td>
        </tr>
    </table>
    <table className="tablestyle3">
        <tr>
            <th colSpan="4">(9) 앞유방의 붙임성(前乳房附着 : FORE UDDER ATTACHMENT)</th>
        </tr>
        <tr>
            <td>
                <img src={Pic40} alt="앞유방의 붙임성"/>
            </td>
            <td>
                <img src={Pic41} alt="앞유방의 붙임성"/>
            </td>
            <td>
                <img src={Pic42} alt="앞유방의 붙임성"/>
            </td>
            <td rowSpan="3">
                <ul>
                    <li>50</li>
                    <li>45-극히 강하게 붙어 있음</li>
                    <li>40</li>
                    <li>35-매우 강하게 붙어 있음</li>
                    <li>30</li>
                    <li>25-중간정도로 붙음</li>
                    <li>20</li>
                    <li>15-약하게 붙어 있음</li>
                    <li>10</li>
                    <li>5-극히 약하게 붙어 있음</li>
                    <li>1</li>
                </ul>
            </td>
        </tr>
        <tr>
            <td>1~5점</td>
            <td>25점</td>
            <td>45~50점</td>
        </tr>
        <tr>
            <td>극히 약한 부착</td>
            <td>중간정도의 부착</td>
            <td>극히 강한 부착</td>
        </tr>
        <tr>
            <td colSpan="4">
                <ul>
                    <li>심사부위 : 앞유방 복벽에 붙어 있는 정도</li>
                    <li>영 향 : 전유방의 모양과 상처를 입을 확율</li>
                    <li>주의사항 : 유방의 깊이, 앞유방의 길이 및 모양과는 상관 없음. 질병이 없거나 정도가 심하지 않은 쪽을 평가함</li>
                    <li>이상적인 점수대 : 25점이상</li>
                </ul>
            </td>
        </tr>
    </table>
    <table className="tablestyle3">
        <tr>
            <th colSpan="4">(10) 뒷유방의 부착높이(後乳房附着高 : REAR UDDER, HEIGHT)</th>
        </tr>
        <tr>
            <td>
                <img src={Pic43} alt="뒷유방의 부착높이"/>
            </td>
            <td>
                <img src={Pic44} alt="뒷유방의 부착높이"/>
            </td>
            <td>
                <img src={Pic45} alt="뒷유방의 부착높이"/>
            </td>
            <td rowSpan="3">
                <ul>
                    <li>50-외음부로부터 18cm의 거리</li>
                    <li>45</li>
                    <li>40-외음부로부터 21cm의 거리</li>
                    <li>35</li>
                    <li>30-외음부로부터 25cm의 거리</li>
                    <li>25-외음부로부터 27cm의 거리</li>
                    <li>20-외음부로부터 28.5cm의 거리</li>
                    <li>15</li>
                    <li>10-외음부로부터 32cm의 거리</li>
                    <li>5</li>
                    <li>1-외음부로부터 36cm의 거리</li>
                </ul>
            </td>
        </tr>
        <tr>
            <td>1~5점</td>
            <td>25점</td>
            <td>45~50점</td>
        </tr>
        <tr>
            <td>극히 낮은 부착</td>
            <td>중간정도의 높이</td>
            <td>극히 높게 붙음</td>
        </tr>
        <tr>
            <td colSpan="4">
                <ul>
                    <li>심사부위 : 외음부 하단에서부터 뒷유방 유선조직이 시작되는 부분까지의 거리</li>
                    <li> 영 향 : 유방의 용적(비유능력)</li>
                    <li>주의사항 : 부착모양이나 너비가 아니고 높이만을 고려하여 평가함</li>
                    <li>이상적인 점수대 : 25점이상</li>
                </ul>
            </td>
        </tr>
    </table>
    <table className="tablestyle3">
        <tr>
            <th colSpan="4">(11) 뒷유방의 너비(後乳房附幅 : REAR UDDER, WIDTH)</th>
        </tr>
        <tr>
            <td>
                <img src={Pic46} alt="뒷유방의 너비"/>
            </td>
            <td>
                <img src={Pic47} alt="뒷유방의 너비"/>
            </td>
            <td>
                <img src={Pic48} alt="뒷유방의 너비"/>
            </td>
            <td rowSpan="3">
                <ul>
                    <li>50-20cm의 너비</li>
                    <li>45</li>
                    <li>40-18cm의 너비</li>
                    <li>35</li>
                    <li>30-15cm의 너비</li>
                    <li>25-14cm의 너비</li>
                    <li>20-13cm의 너비</li>
                    <li>15</li>
                    <li>10-10cm의 너비</li>
                    <li>5</li>
                    <li>1-8cm의 너비</li>
                </ul>
            </td>
        </tr>
        <tr>
            <td>1~5점</td>
            <td>25점</td>
            <td>45~50점</td>
        </tr>
        <tr>
            <td>극히 좁음</td>
            <td>중간정도의 너비</td>
            <td>극히 폭이 넓음</td>
        </tr>
        <tr>
            <td colSpan="4">
                <ul>
                    <li>심사부위 : 외측인대가 허벅지에 붙어 있는 지점의 너비 </li>
                    <li>영 향 : 우유생산 잠재력</li>
                    <li>주의사항 : 착유직전에 관찰 할 수 있는 너비로 보정을 해서 평가하는 유일한 형질임. 하지만 비유단계에 대한 보정(최고 비유기 때)은 하지 말 것</li>
                    <li>이상적인 점수대 : 25점이상</li>
                </ul>
            </td>
        </tr>
    </table>
    <table className="tablestyle3">
        <tr>
            <th colSpan="4">(12) 유방의 정중제인대(正中提靭帶:UDDER CLEFT)</th>
        </tr>
        <tr>
            <td>
                <img src={Pic49} alt="유방의 정중제인대"/>
            </td>
            <td>
                <img src={Pic50} alt="유방의 정중제인대"/>
            </td>
            <td>
                <img src={Pic51} alt="유방의 정중제인대"/>
            </td>
            <td rowSpan="3">
                <ul>
                    <li>50-7cm의 깊이</li>
                    <li>45-6cm의 깊이</li>
                    <li>40</li>
                    <li>35-4.5cm의 깊이</li>
                    <li>30</li>
                    <li>25-3cm의 깊이</li>
                    <li>20</li>
                    <li>15-1.5cm의 깊이</li>
                    <li>10</li>
                    <li>5-평형</li>
                    <li>1-0.6cm의 깊이</li>
                </ul>
            </td>
        </tr>
        <tr>
            <td>1~5점</td>
            <td>25점</td>
            <td>45~50점</td>
        </tr>
        <tr>
            <td>0.6cm의 깊이</td>
            <td>3cm의 깊이</td>
            <td>6cm 이상의 깊이</td>
        </tr>
        <tr>
            <td colSpan="4">
                <ul>
                    <li>심사부위 : 뒷유방의 바닥에서 죄우유구(乳軀)의 갈라진 부분의 깊이</li>
                    <li>영 향 : 착유의 용이성 및 유방이 상처를 입을 확율</li>
                    <li>주의사항 : 유방의 질(質)이나 깊이, 유두의 부착지점이나 수직성(방향), 유정맥의 발달정도등에 영향을 받아서는 안됨</li>
                    <li>이상적인 점수대 : 25~40점 이상</li>
                </ul>
            </td>
        </tr>
    </table>
    <table className="tablestyle3">
        <tr>
            <th colSpan="4">(13) 유방의 깊이(乳房의 深度 : UDDER DEPTH)</th>
        </tr>
        <tr>
            <td>
                <img src={Pic52} style={{width: '70%'}} alt="유방의 깊이"/>
            </td>
            <td>
                <img src={Pic53} style={{width: '64%'}} alt="유방의 깊이"/>
            </td>
            <td>
                <img src={Pic54} style={{width: '70%'}} alt="유방의 깊이"/>
            </td>
            <td rowSpan="3">
                <ul>
                    <li>50-비절단 18cm위에 위치</li>
                    <li>45</li>
                    <li>40-비절단 13cm위</li>
                    <li>35</li>
                    <li>30-비절단 8cm위</li>
                    <li>25-비절단 6cm위</li>
                    <li>20</li>
                    <li>15-비절단과 수평</li>
                    <li>10</li>
                    <li>5-비절단 5cm아래에 위치</li>
                    <li>1-비절단 8cm아래</li>
                </ul>
            </td>
        </tr>
        <tr>
            <td>1~5점</td>
            <td>25점</td>
            <td>45~50점</td>
        </tr>
        <tr>
            <td>극도로 처진 유방</td>
            <td>비절단보다 약간위</td>
            <td>극도로 높이 부착</td>
        </tr>
        <tr>
            <td colSpan="4">
                <ul>
                    <li>심사부위 : 비절간을 기준으로 유방의 가장 처진 부분과의 거리 </li>
                    <li> 영 향 : 유방의 용적, 유방염을 비롯한 상처를 입을 확율</li>
                    <li>주의사항 : 젖꼭지 끝이 아닌 기부(基部)를 비절단과 비교함</li>
                    <li>이상적인 점수대 : 25~30(초산우는 30~40)점 사이</li>
                </ul>
            </td>
        </tr>
    </table>
    <table className="tablestyle3">
        <tr>
            <th colSpan="4">(14) 뒤에서 본 앞유두의 배열위치(前乳頭附着 : FRONT TEAT PLACEMENT)</th>
        </tr>
        <tr>
            <td>
                <img src={Pic55} style={{width: '73%'}} alt="뒤에서 본 앞유두의 배열위치"/>
            </td>
            <td>
                <img src={Pic56} alt="뒤에서 본 앞유두의 배열위치"/>
            </td>
            <td>
                <img src={Pic57} alt="뒤에서 본 앞유두의 배열위치"/>
            </td>
            <td rowSpan="3">
                <ul>
                    <li>50</li>
                    <li>45-유두가 극히 안쪽으로 붙음</li>
                    <li>40</li>
                    <li>35-안쪽으로 붙은 유두</li>
                    <li>30</li>
                    <li>25-중앙에 붙은 유두</li>
                    <li>20</li>
                    <li>15-바깥쪽으로 붙은 유두</li>
                    <li>10</li>
                    <li>5-극히 바깥쪽으로 붙은 유두</li>
                    <li>1</li>
                </ul>
            </td>
        </tr>
        <tr>
            <td>1~5점</td>
            <td>25점</td>
            <td>45~50점</td>
        </tr>
        <tr>
            <td>극도로 바깥쪽으로 붙음</td>
            <td>중앙에 붙음</td>
            <td>극히 안쪽으로 붙음</td>
        </tr>
        <tr>
            <td colSpan="4">
                <ul>
                    <li>심사부위 : 뒤에서 보아 앞 유구가 유구의 중앙을 기준으로 좌우로 붙어 있는 위치</li>
                    <li>영 향 : 착유의 용이성, 상처를 입을 확율</li>
                    <li>주의사항 : 유두가 뻗어있는 방향과는 상관없이 붙어 있는 위치를 본다.</li>
                    <li>이상적인 점수대 : 25점이상</li>
                </ul>
            </td>
        </tr>
    </table>
    <table className="tablestyle3">
        <tr>
            <th colSpan="4">(15) 앞유두의 길이(前乳頭長 : FRONT TEAT LENGTH)</th>
        </tr>
        <tr>
            <td>
                <img src={Pic58} alt="앞유두의 길이"/>
            </td>
            <td>
                <img src={Pic59} alt="앞유두의 길이"/>
            </td>
            <td>
                <img src={Pic60} alt="앞유두의 길이"/>
            </td>
            <td rowSpan="3">
                <ul>
                    <li>50-9cm의 길이</li>
                    <li>45</li>
                    <li>40-7.5cm의 길이</li>
                    <li>35</li>
                    <li>30-6cm의 길이</li>
                    <li>25-5.5cm의 길이</li>
                    <li>20-5cm의 길이</li>
                    <li>15</li>
                    <li>10-4cm의 길이</li>
                    <li>5</li>
                    <li>1-2.5cm의 길이</li>
                </ul>
            </td>
        </tr>
        <tr>
            <td>1~5점</td>
            <td>25점</td>
            <td>45~50점</td>
        </tr>
        <tr>
            <td>3cm 이하</td>
            <td>5.5cm의 길이</td>
            <td>8cm 이상</td>
        </tr>
        <tr>
            <td colSpan="4">
                <ul>
                    <li> 심사부위 : 유두의 길이</li>
                    <li>영 향 : 착유의 용이성, 상처를 입을 확율</li>
                    <li>주의사항 : 굵은 정도는 평가하지 않음. 길이가 각기 다를 따는 가장 긴 것을 측정</li>
                    <li>이상적인 점수대 : 20~30점 사이</li>
                </ul>
            </td>
        </tr>
    </table>
</div>
  );
}

export default Do8;
