import React from 'react';


function Do41() {

  return (
    <div className="techinfoview">
				<h1>TMR 사료의 급여시의 유의점과 경제성</h1>
				<h2>TMR 급여시의 유의점</h2>
				<ul>
					<li>TMR 사료의 품질평가 
						<p>농가에서 구입하는 TMR은 일반적으로 다른 사료를 급여하지 않아도 되는 완전한 TMR이 아니다. 따라서 앞의 &lt;표 15&gt;를 참조하여 볏짚 또는 농후사료와 함께 급여하는 것이 바람직하다. 시중에서 구입하는 TMR의 품질을 간접적으로 평가하는 방법을 열거하면 다음과 같다. </p>
						<ul>
							<li>형태와 색깔이 양호하고 이물질이 섞여 있지 않으며, 곰팡이 등의 미생물에 오염되어 있지 않을 것 </li>
							<li>사료의 맛을 보아 혀를 자극하는 나쁜 맛이나 쓴 맛 등이 없을 것 </li>
							<li>향기가 좋고 곰팡이 냄새, 자극적이거나 썩은 냄새가 없을 것</li>
							<li> 손으로 만져 보아 수분함량이 적당하다고 판단되는 것 </li>
						</ul><br/>
					</li>
					<li>급여 횟수 
						<p>대부분의 연구에 의하면 완전배합사료는 하루에 한번 배합하여 급여해도 만족한 결과를 가져온다고는 하지만 신선도를 유지하기 위해 2∼3회로 나누어 배합.급여하면 채식량을 많게 할 수 있다. 특히 여름철에는 최소한 2회 이상으로 하는 것이 좋다. </p>
						<br/>
					</li>
					<li>사료조 관리
						<p>사료조 관리의 주목적은 신선하고 기호성이 높으면 균형된 영양의 TMR을 모든 젖소가 편안한 상태에서 섭취할 수 있도록 함으로서 건물섭취량을 최대화하는데 있다. 젖소의 산유능력이 향상됨에 따라 건물섭취량은 높은 생산을 유지하는 데 가장 중요한 요인이 되는데, 사료조 관리의 요점을 열거하면 다음과 같다. </p>
						<ul>
							<li>사료는 사료조에 고르게 분포하도록 준다. </li>
							<li>젖소 두당 사료조 길이를 최소 45∼76cm로 한다. </li>
							<li>사료의 분리여부를 관찰하여 남긴 사료의 양과 형태에 관하여 평점을 기록한다. 골라 먹지 않았다면 남긴 사 료는 급여시의 TMR조성 및 외형과 비슷하여야 한다. </li>
							<li> 남긴 사료의 온도가 외온보다 높지 않아야 하고 신선해야 한다. 먹고 남긴 사료가 고온이거나 곰팡이 냄새가 나면 사료급여횟수를 늘려야 함을 뜻한다.</li>
							<li>남긴 사료의 양은 급여량의 5% 이하여야 하며 매일 깨끗이 치워야 한다. </li>
							<li>하루에 2∼3회 사료조의 사료를 젖소 앞으로 밀어 준다. </li>
							<li>사료조에 사료가 항상 있어야 한다. 사료조가 비어 있는 시간이 2∼3시간을 넘지않도록 한다.</li>
							<li>사료를 급여할 때 젖소의 움직임을 관찰한다. 병든 소나 다리에 이상이 있는 젖소는 신선한 사료를 줄 때 사 료조에 접근하지 않는 경향이 있다. </li>
							<li>사료조는 평사조로 하고 바닥을 매끈매끈하게 만들어서 청소하기 쉽고 사료섭취가 편하도록 한다. </li>
							<li>사료조는 젖소가 방목시 풀을 뜯어먹을 때처럼 머리를 숙이고 사료를 섭취할 수 있도록 설치한다. 이는 사료 섭취를 도울 뿐만 아니라 침의 분비를 촉진하며 사료를 헤치는 것을 줄일 수 있기도 하다.</li>
							<li>매일 신선한 물이 충분히 공급되는지 점검한다. </li>
							<li>소는 이른 아침과 저녁때 사료를 많이 먹으므로 아침 저녁 착유 직후에 사료를 주도록한다. </li>
						</ul><br/>
					</li>
					<li>단일 착유우군을 위한 TMR 급여
						<p className="pstyle">분만후 건유전까지의 착유우 모두에게 한가지 TMR을 급여하는 방식이다. 이는 우군간의 이동에서 오는 스트레스로 인한 산유량감소가 없고 착유 및 우사시설을 한 구룹관리에 이용하기가 용이하며 한 종류의 사료만을 급여하기 때문에 사료배합이 단순한 장점을 가지고 있다. 그리고 이러한 사료급여체계를 성공적으로 도입하기 위해서는 다음 사항에 유의하여야 한다. </p>
						<ul>
							<li>전체우군의 평균 산유량이 8,500kg 이상이어야 한다. 두당 평균산유량이 높다는 것은 저능력우가 거의 없다는 것을 뜻한다. </li>
							<li>분만간격이 짧은 최상의 번식성적이 유지되어야 한다. 분만간격이 길어지면 지방간이 발생할 위험이 있다. </li>
							<li>과감한 도태를 시행해야 한다. 이로서 살만 찌고 산유량이 낮은 개체를 도태함으로서 사료비의 낭비를 막을 수 있다.</li>
							<li>대부분의 경산우는 착유말기에 체중의 회복을 위해 여분의 영양을 필요로 하며 초산우의 경우는 성장을 위해 필요한 영양을 섭취 해야 한다.</li>
						</ul><br/>
					</li>
					<li>부분 TMR급여 
						<p className="pstyle">&nbsp;&nbsp;&nbsp;이는 착유우를 스탄쉥이나 타이스톨등 계류식우사에 수용할 때 이용할 수 있는 방법이다. 즉 TMR이 만들어진 기준 산유량 보다 6∼9kg 이상 많은 량의 우유를 생산하는 소에게 농후사료를 하루에 2회로 나누어 1∼4kg을 따로 급여하되 TMR에 부가적으로 급여하는 방법이다. 추가로 공급할 수 있는 농후사료에는 보호지방이나 동물성 단백사료등 고가의 단미사료는 물론 미량광물질등 특수 첨가제를 쓸 수도 있다. 결과적으로 이 방법은 우유를 많이 생산하는 특정한 개체에게 추가로 영양을 공급하는 것을 가능하게 한다. <br/>&nbsp;&nbsp;&nbsp;이와는 달리 두당 1일 1∼2.5kg 정도의 긴(자르지 않은) 건초를 계류식우사에서 별도로 급여하는 경우도 있다. 젖소는 앞에 놓인 건초만을 섭취하기 때문에 이런 정도의 건초급여로는 완전배합사료의 영양소균형이 깨지지 않는다. 이와같이 추가적으로 급여하는 건초는 1일 2회 나누어 TMR급여 전에 급여하여야 하며 사일리지 입자의 크기가 너무 짧거나 TMR의 수분함량이 50%를 휠씬 넘을 때 효과가 높다. <br/>&nbsp;&nbsp;&nbsp;부분 TMR급여의 또 다른 형태는 산유량이 낮은 개체의 TMR 섭취량을 적정수준(예를 들면 평균섭취량의 60% 수준등)으로 제한하고 상대적으로 비용이 적게 드는 건초를 자유채식토록 하는 것이다. 이는 TMR의 영양수준을 값이 싼 건초로 희석하므로서 사료비의 절감은 물론 과비현상도 막을 수 있는 이점을 가지고 있다. 이상과 같은 변형방법은 낙농가로 하여금 한 종류의 완전배합사료만을 모든 착유우에 급여하되 산유량이 높거나 낮은 개체를 경제적으로 사양할 수 있게 해준다. </p>
						<br/>
					</li>
					<li>연령, 영양상태, 비유기에 따른 고려사항 
						<ul>
							<li>초산우군 분리사육
								<ul>
									<li>초산우는 체구가 작기 때문에 충분한 사조의 길이가 주어진다 하더라도 체구가 큰 다산우와 효과적으로 경쟁할 수 없다. 연구결과에 의하면 초산우를 따로 사육하면 다산우와 합사했을 때보다 누워서 쉬는 시간이 많아지며 사료를 더 자주 먹을 뿐만 아니라 건물섭취량도 늘어난다고 한다. 몸집이 작고 겁이 많은 다산우도 초산우군에 넣는 것이 좋다. </li>
								</ul>
							</li>
							<li>과비우군 
								<ul>
									<li>제목이 암시하는 바와 같이 이 우군은 왕성한 식욕이나 우군내에서의 지배적인 서열에 있어 고영양 TMR을 많이 섭취하거나 번식간격이 긴 사유로 해서 지나치게 유전능력이 우수하기 때문에 도태 할 대상은 아니더라도 계속 더 살이 찌면 대사성 질병에 걸릴 가능성이 높다. 따라서 과비우군에게는 저렴한 조사료등을 많이 급여하는 것이 좋다. 건유 2주전의 착유우를 이 우군에 편입시키면 영양수준이 낮은 사료를 섭취하기 때문에 산유량이 줄어 건유시키는 데 도움이 된다. </li>
								</ul>
							</li>
							<li>비유초기 우군 
								<ul>
									<li>경영규모가 큰 농가에서는 분만후 2∼4주 사이의 착유우를 따로 사육하므로서 좋은 성과를 얻을 수 있다. 즉 이 기간에는 건물 섭취량이 적기 때문에 보호단백질, 보호지방, 그리고 주요 미량원소의 함량이 높은 사료를 급여하므로서 영양공급을 원활하게 하며 약간의 짜르지 않은 긴 건초를 급여하므로서 만복감을 주며 반취위의 기능을 원활하게 할 수도 있다. 그리고 소의 건강상태도 주도면밀하게 관찰할 수 있다.  </li>
								</ul>
							</li>
							<li>건유말기(분만직전) 우군 
								<ul>
									<li>분만 2∼3주전의 건유우나 초임우를 별도로 관리하는 방법으로 이 NDRNSDP 분만후에 급여할 사료를 급여한다. 즉 TMR의 단백질수준을 12%에서 14%로 올리며 보호단백질도 첨가하여 30∼50%의 건물이 분만후에 급여하는 TMR로 구성되게 하고 발효되는 탄수화물사료를 급여하여 반취위내의 미생물을 변화시키고 융모돌기의 신장을 촉진하게 한다. 물론 두당 1일 2.5∼3.5kg의 긴 건초를 반드시 급여하여야 하며, 젖소에게 소량의 유지(油脂)를 급여하여 지방의 냄새에 익숙하도록 한다. </li>
								</ul>
							</li>
						</ul><br/>
					</li>
					<li>우군의 분리
						<p>TMR 사양체계를 통한 최대효과를 가져 오려면 우군의 분리가 매우 중요한데 분리하는 우군의 숫자는 농가의 실정에 맞추어 결정하여야 하며, 이는 우군의 크기, 우군의 산유량, 시설규모 등 여러 가지 요인에 의해서 바뀔 수 있다. </p>
						<ul>
							<li>우군분리시 고려사항 
								<p>먼저, 비유중인 젖소와 건유우는 반드시 분리되어야 한다. 그리고 산유능력이 다른 비유중인 젖소를 능력별로 적당하게 사육시키기 위해서는 반드시 우군의 분리가 이루어져야 한다. 그렇지 않으면, 고능력우가 영양소를 적게 섭취하여 능력이 저하될 수 있으며, 저능력우는 영양소를 과다섭취하게 되어 비만이 될 수 있다. 다음은 우군을 분리하기 전에 먼저 고려하여야 할 사항들이다. </p>
								<ul>
									<li>산유량 </li>
									<li> 영양소 요구량 (NE1, CP, UIP) </li>
									<li>번식상태 </li>
									<li>연 령</li>
									<li>신체충실지수(BCS)</li>
									<li>시설적인 제약 및 우군의 크기 </li>
									<li>노동력 </li>
									<li>비유단계 </li>
								</ul>
							</li>
							<li>우군분리의 기준
								<ul>
									<li> 한 우군에 속한 착유우의 두당 1일 산유량 차이(최대∼최소)가 13kg 이하가 되도록 한다.</li>
									<li>착유우 전체를 1∼2군으로 할 경우에는 TMR 배합기준을 우군의 평균산유량보다 30%높게 결정하며, 3군으로 할 때는 10% 높게 두당 1일 산유량 수준을 목표로 한다. </li>
									<li>초산우 우군에게는 계속되는 성장에 필요한 영양요구량을 고려하여 산유량 4,5kg을 더해서 TMR배합 기준으로 하며 2산우의 경우에는 2kg의 가산점을 준다. </li>
									<li>착유우를 산유량수준이 낮은 우군으로 이동시킬 때는 다음 사항을 고려해야 한다. 
										<ul>
											<li>지방보정우유(FCM)생산수준 </li>
											<li>신체충실지수가 적절한 상태일 것 </li>
											<li>나이 </li>
											<li>번식상태 </li>
											<li>젖소를 다른군으로 이동 시킬때는 여러 마리를 한꺼번에 사료급여 시간에 맞추어 시행하되 사료조의 길이가 충분하도록 한다. 미국 일부지역에서는 이동하는 소에 악취가 나는 물질을 묻혀 섞일 때 기존 그룹의 소 들로부터 공격을 받는 문제를 줄이기도 한다. </li>
										</ul>
									</li>
								</ul>
							</li>
							<li>우군분리의 형태 
								<ul>
									<li>&lt;1개군으로 분리&gt;
										<p>이 방법은 한 종류의 TMR만을 기본으로 하는 착유두수 30두수 미만인 규모에 적용할 수 있는 방법으로 사료급여시의 노동력은 절감할 수 있겠지만, 낙농가는 보유하고 있는 착유우의 능력 등을 정확히 알고 가장 기본이 될 수 있는 TMR을 선택하지 않으면 안되는 어려움이 있다. 또한 이 방법은 산유량이나 비유시기에 따른 개체별 영양소 요구량의 변이를 극복하기가 쉽지 않다는 가장 큰 단점을 가지고 있다. </p>
									</li>
									<li>&lt;2개군으로 분리&gt;
										<p>비유단계별로 구분하여 전기용과 중.말기용의 두가지 TMR을 제조한 다음실제로는 비유전기 및 중기에 있는 젖소는 해당 TMR을 자유채식케 하면서, 비유말기의 젖소는 건초 등을 영양소 희석제로 이용하면서 자유채식케 하는 방법이다. 그리고 건유우의 경우에는 TMR 급여량을 소량으로 제한하고 건초 급여량을 늘린다. 그리고 일반적으로 사료비 절감을 위해서 비유 중.말기용은 비교적 값이 저렴한 원료를 사용하고, 전기용 원료는 값이 비싸도 양질인 원료를 구별하여 사용하는 것이 바람직하다. 이 방법은 2가지의 TMR사료를 급여하기 때문에 비유전기에 있던 젖소가 비유중기로 넘어가는 경우 사료성분의 급작스러운 변화에 의한 영양생리적 충격이나 손실이 적다는 장점이 있으며, 착유우 40두 이상의 규모에 적당한 방법이다. </p>
									</li>
									<li>&lt;3개군으로 분리&gt;
										<p>대규모의 기업적 낙농가에서 사용할 수 있는 전형적인 TMR 이용방법으로 산유량, 산차 및 비유단계 등을 기준으로 대형 우사내에 다수의 군으로 구분하여 수용하고, 여러대의 사료혼합기와 급여기를 구비하고 자동화하여 작업시간을 단축한다. 국내처럼 TMR 배합소에서 일괄 제조하여 배급하는 경우에는 우군의 특성에 맞는 TMR 사료를 구입하여 급여할 수 있다. 다수의 군으로 구분되므로 최고산유기를 지난 젖소는 비유단계의 진행에 따라 여러 단계의 순차적인 TMR 사료를 거쳐 건유기로 들어가기 때문에 급격한 영영소의 변경에 의한 생리적 충격이 극히 적으며, 착유작업도 비유량이 비슷한 젖소들이기 때문에 효율이 높아지며, 우군에 따른 TMR 사료의 별도의 보충급여 같은 것이 불필요하다. 그러나 우군이 너무 세분화되어 있으면, 군을 바꾸는데 많은 시간과 노동력을 필요로 한다. 또한 군을 나누는 경우 새로운 군내에서 서열 다툼으로 인한 스트레스 때문에 유량이 감소할 수 있다. </p>
									</li>
								</ul>
								<p>&lt;표20&gt;에는 비유중인 젖소를 3개군으로 나눈 우군분리의 예를 보여주고 있다.</p><br/>
								<p><b>&lt;표 20&gt; 우군분리의 예</b></p>
								<table className="tablestyle">
									<tr>
										<td>군별</td>
										<td>초산우</td>
										<td>경산우</td>
									</tr>
									<tr>
										<td>A</td>
										<td>30kg이상</td>
										<td>35kg이상</td>
									</tr>
									<tr>
										<td>B</td>
										<td>20∼29kg</td>
										<td>25∼34kg</td>
									</tr>
									<tr>
										<td>C</td>
										<td>19kg 미만</td>
										<td>24kg미만</td>
									</tr>
									<tr>
										<td>D</td>
										<td>건 유 우</td>
										<td>건 유 우</td>
									</tr>
								</table>
							</li>
						</ul>
					</li>
				</ul>
				<h2>TMR 의 경제성</h2>
				<p className="pstyle">&nbsp;&nbsp;&nbsp;TMR의 경제성에 관한 연구 결과가 많은 것은 아니지만, 미국 코넬(Cornell)대학에서 수행한 연구의 결과를 보면 다음과 같다. 비교대상은 경산우 80두를 사육하는 TMR 급여농가 42군데와 착유실내 급여(Parlor feeding)를 하는 농가이다. 사료는 옥수수사일리지와 저수분 목초사일리지였으며, 벙커사일로를 사용하고 우사는 프리스톨 방식이었다. <br/>&nbsp;&nbsp;&nbsp;실험 결과를 보면, TMR을 사용함으로써 화폐단위로 표시는 되어 있지 않았지만, 순익이 증가한다고 하였다. TMR시스템은 농후사료의 가격변동에 따라서 사료를 선택할 수 있다. TMR시스템을 이용함으로써 수익이 증가하는 제일 큰 요인은 개체유량의 증가이며, 두 번째가 조사료의 손실감소인 것으로 나타났다. 이러한 수익증가외에 비용의 절감에 의해서도 TMR시스템의 경제성이 증가할 수 있다. 비용의 절감에 있어서는 우선 소의 건강장애가 감소하여 그 만큼 질병치료에 소요되는 비용이 감소하고 착유실내 급여의 불필요 등 사양관리의 간소화로 인하여 임금으로 지불되는 비용이 감소할 수도 있다.</p>
			</div>
  );
}

export default Do41;
