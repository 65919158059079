import React from 'react';

import Pic491 from 'assets/images/sub/_images/pic491.jpg';
import Pic492 from 'assets/images/sub/_images/pic492.jpg';
import Pic493 from 'assets/images/sub/_images/pic493.jpg';

function Do83() {

  return (
    <div className="techinfoview">
				<h1>자궁염전</h1>
				<p className="pstyle">자궁이 소의 머리와 꼬리를 연결하는 축을 중심으로 우측 또는 좌측으로 회전한 것으로 임신후반기 특히 분만개시 때에 많이 발생하며 난산의 중요한 원인이 되고 있다.</p>
				<div className="leftpart">
					<h2>원인</h2>
					<ul>
						<li>타가축에 비해 소에서 많이 생기는 것은 복강내에 자궁을 지탱하고 있는 자궁광간막이 앞쪽으로 갈수록 좁아져 자궁의 유지가 불완전하기 때문이다. </li>
						<li>임신말기 내리막길에서 구른다든지. 보행중에 갑자기 방향을 바꿀 때 일어나기 쉽다.</li>
						<li>분만개시기에 자궁의 이상수축으로 인하여 발생한다.</li>
					</ul>
					<h2>증상</h2>
					<ul>
						<li>분만기의 자궁염전에서는 진통이 계속되어도 외음부에 아무것도 나타나지 않으며 처음에는 강하게 힘을 주나 점차 피로해져 진통을 볼 수 없게 된다. </li>
						<li>임신말기의 자궁염전은 식욕이 감소 또느 s중지되고 가벼운 고창증을 동반한 복통의 모습을 보인다. 이와 같은 증상이 2∼3일 계속된 뒤 또다시 먹기 시작하므로 위장 질환과 되기 쉽다. </li>
						<li>염전이 심하면 음순(외음부)이 붓는다. (특히 염전방향과 반대측의 음순이 붓는다. )</li>
						<li>손을 소독한 뒤 질 속에 넣어보면 앞쪽으로 나아갈 수 없다. </li>
					</ul>
					<h2>목장에서의 처치</h2>
					<ul>
						<li>분만일이 가까워진 소가 식욕이 없거나 고창증을 보일때는 단순한 식체로 간주하지 말고 수의사의 왕진을 요청해 질검사와 직장검사를 받는다. (처치가 늦어지면 어미와 새끼가 모두 위험하다.) </li>
						<li>임신말기의 소에 무리한 운동은 피해야 한다. </li>
						<li>수의사의 지시하에 여러명의 도움으로 우체 회전법을 실시해야 한다. </li>
						<li>이 병은 염전된 자궁을 원래의 상태로 둘려 놓지 않으면 치료될 수 없다.</li>
					</ul>
				</div>
				<div className="rightpart">
					<img src={Pic491} className="nonborder" alt=""/>
					<p>* 오른쪽으로 180도 염전된 젖소 -왼쪽음순이 크게 부어 있는 것을 볼 수있다.</p>
					<img src={Pic492} className="nonborder" alt=""/>
					<p>* 염전된 자궁을 우체회전법으로 회복시키기 위해 쓰러뜨리는 모습 </p>
					<img src={Pic493} className="nonborder" alt=""/>
					<p>* 앞다리와 뒷다리를 별도로 묶은 밧줄을 갑자기 당겨 염전과 같은 방향으로 소를 회전시킨다.</p>
				</div>
			</div>
  );
}

export default Do83;