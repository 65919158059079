import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Cookies from 'js-cookie';

// api
import { getState } from 'utils/api';

// scss
import './boLogin.scss';

function BoLogin() {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    try {
      const response = await getState('/board/check_login', data);

      if (response?.return_code === 'SUCCESS' && response?.result?.CNT === 1) {
        Cookies.set('session', 'logged_in');
        navigate('/bo/user');
      } else {
        alert('로그인에 실패했습니다.');
      }
    } catch (error) {
      alert('로그인 중 오류가 발생했습니다.');
    }
  };

  return (
    <article className="login-wrapper">
      <div className="login-container">
        <div className="login-box">
          <h1 className="login-title">BACKOFFICE</h1>
          <form onSubmit={handleSubmit(onSubmit)} className="login-form">
            <div className="form-group">
              <label htmlFor="id" className="form-label">
                아이디
              </label>
              <input
                type="text"
                {...register('id', {
                  required: '아이디를 입력해주세요',
                  maxLength: {
                    value: 10,
                    message: '아이디는 10자 이내로 입력해주세요',
                  },
                })}
                className="form-input"
                placeholder="아이디를 입력하세요"
              />
              {errors.id && (
                <span className="error-message">{errors.id.message}</span>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="password" className="form-label">
                비밀번호
              </label>
              <input
                type="password"
                {...register('password', {
                  required: '비밀번호를 입력해주세요',
                  maxLength: {
                    value: 10,
                    message: '비밀번호는 10자 이내로 입력해주세요',
                  },
                })}
                className="form-input"
                placeholder="비밀번호를 입력하세요"
              />
              {errors.password && (
                <span className="error-message">{errors.password.message}</span>
              )}
            </div>
            <button type="submit" className="login-button">
              로그인
            </button>
          </form>
        </div>
      </div>
    </article>
  );
}

export default BoLogin;
