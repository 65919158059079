import React from 'react';

import pic734 from 'assets/images/sub/_images/pic734.jpg';
import pic735 from 'assets/images/sub/_images/pic735.jpg';
import pic736 from 'assets/images/sub/_images/pic736.jpg';

function Do154() {

  return (
    <div className="techinfoview">
      <h1>눈의 암</h1>
      <p className="pstyle">원인은 분명히 규명되어 있지 않으나 바이러스가 눈의 편평상피에 침입하여 일으키는 것으로 알려져 있다. </p>
      <div className="leftpart">
        <h2>원인</h2>
        <ul>
          <li>발암소질의 유전적 요인으로서 눈꺼풀주위의 색소결핍시 발생율이 높다. </li>
          <li>오랜 일조시간으로 강한 자외선의 영향을 받을 때 </li>
          <li>영양불량 </li>
          <li>전염성 각 결막염 그밖의 눈의 염증등에서 암으로 속발됨</li>
        </ul>
        <h2>증상</h2>
        <ul>
          <li>이 종양은 눈의 거의 모든 부위에서 증식하지만 특히 각막과 그 주변부에 많이 발생한다. 
            <ul>
              <li>이 종양은 눈의 거의 모든 부위에서 증식하지만 특히 각막과 그 주변부에 많이 발생한다. </li>
              <li>증상이 진행되면 반점은 종양(腫瘍)화하여 자라서 궤양과 출혈등을 일으키면서 실명을 유발한다.</li>
              <li>더욱 악화되면 그에 수반하여 식욕부진, 영양저하, 유량감소등 일반상태가 악화되어 폐용(廢用)된다. </li>
            </ul>
          </li>
        </ul>
        <h2>목장에서의 처치</h2>
        <ul>
          <li>이병은 바이러스에 의해 발병한다고 알려져 있으므로 다발하는 지역에서 소를 도입할 때는 특히 주의해야 한다. </li>
          <li>본 증으로 의심나는 소를 발견하면 즉시 격리하고 치료나 도태등 적절한 조치를 강구한다. </li>
          <li>치료로서는 외과적 절제 수술이 가장 일반적이며 완치 가능한 방법이다.</li>
        </ul>
      </div>
      <div className="rightpart">
        <img src={pic734} className="nonborder" alt=""/>
        <img src={pic735} className="nonborder" alt=""/>
        <img src={pic736} className="nonborder" alt=""/>
      </div>
    </div>
  );
}

export default Do154;