import React from 'react';
import { Outlet } from 'react-router-dom';

// scss
import './dciexpert.scss';

function DciExpert() {
  return (
    <div>
      <Outlet /> 
    </div>
  );
}

export default DciExpert;
