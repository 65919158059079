import React from 'react';

import Pic805 from 'assets/images/sub/_images/pic805.jpg';
import Pic806 from 'assets/images/sub/_images/pic806.jpg';
import Pic807 from 'assets/images/sub/_images/pic807.jpg';
import Pic808 from 'assets/images/sub/_images/pic808.jpg';
import Pic809 from 'assets/images/sub/_images/pic809.jpg';
import Pic810 from 'assets/images/sub/_images/pic810.jpg';
import Pic811 from 'assets/images/sub/_images/pic811.jpg';
import Pic812 from 'assets/images/sub/_images/pic812.jpg';
import Pic813 from 'assets/images/sub/_images/pic813.jpg';

function Fo3() {

  return (
    <div className="techinfo_view">
				<p className="viewTitle">여러 외국에 있어서 젖소개량 현황</p>
				<h2>젖소의 비유능력 </h2>
				<dl>
					<dt>경산우 1두당 비유성적</dt>
					<dd>
						<p>&lt;표 12&gt;는, 주요 낙농국의 젖소(모든품종)에 대한 경산우 1두당 년간 유량을 표시하고 있다. 미국이 가장 높지만, 일본도 매우 높은 수준이다.</p>
						<p>종래, 저소개량은 사양두수가 많은(다시 말하면 육종기반이 큰)미국이 세계를 리 - 드하고, 카나다가 그 다음으로 쫓아가고 있다는 감을 가지고 있었다. 일본에서는 미국을 목표하되, 당장은 사양두수가 거의 비슷한 카나다를 지목하여 개량이 추진되어 왔다.</p>
					</dd>
				</dl>
				<div className="tableWrap">
					<table className="tablestyle">
						<caption>&lt;표 12&gt; 주요낙농국의 경산우 1두당 유량(1995년, 일본)</caption>
						<thead>
							<tr>
								<th className="first">구 분</th>
								<th>일 본</th>
								<th>북해도</th>
								<th>미 국</th>
								<th>캐나다</th>
								<th>영 국</th>
								<th>화 란</th>
								<th>호 주</th>
								<th>뉴질랜드</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>경산우1두당유량(kg)</td>
								<td>6,986</td>
								<td>7,195</td>
								<td>7,478</td>
								<td>6,089</td>
								<td>5,344</td>
								<td>6,524</td>
								<td>4,783</td>
								<td>3,277</td>
							</tr>
						</tbody>
					</table> 
				</div>
				<ul className="refer mg30f" style={{marginTop: '-20px'}}>
					<li>자료) ＩＣＲＡ조사년보 (1996년). 단지, 카나다, 호주는 FAO&quot;생산연감 1995&quot; </li>
				</ul>
				<p>그러나, 홀스타인종만을 보면, 최근 사양두수나 사양규모가 일본에 유사한 유럽제국, 특히 화란에서 현저한 비유성적이 신장한 것을 볼 수 있다. 화란은 유육겸용종의 후리 - 지안종이 주가 되어 있었으나, 정액과 수정난의 도입에 의하여 급속도로 북미형 홀스타인종으로 전환함과 아울러 선진적인 육종프로그램의 실행과 효율적인 개랑체제로의 재편에 의한 젖소개량을 강력히 추진하고 있다.</p>
				<p className="mg30f">그 결과, 홀스타인종우군검정의 비유성적을 보면, 최근 10년간에 유량으로 1,890kg정도 신장하고 있다. 1994년의 비유성적을 비교하면, 〈표13〉과 같이 유량으로는 아직 미국, 일본을 하회하고 있으나, 유지율,유단백질율이 압도적으로 높기 때문에, 유지량+유단백질량으로는 일본보다 많고, 미국과는 거의 같은 수준으로 되어 있다. 우군검정우의 성적이, 비검정우보다 일반적으로 높다라고 생각하면, 보급율이 높은 화란은, 실질적으로 미국을 상회하고있다는 결과가 된다. </p>

				<table className="tablestyle">
					<caption>&lt;표 13&gt; 홀스타인종(우군검정우)의 비유성적(1994년)</caption>
					<thead>
						<tr>	
							<th className="first"> </th>
							<th>일 본</th>
							<th>미 국</th>
							<th>화 란</th>
						</tr>
					</thead>
					<tbody>
						<tr>	
							<td>유 량(kg)</td>
							<td>8,209</td>
							<td>8,730</td>
							<td>7,511</td>
						</tr>
						<tr>	
							<td>유 지 율(%)</td>
							<td>3.81</td>
							<td>3.66</td>
							<td>4.47</td>
						</tr>
						<tr>	
							<td>유단백질(%)</td>
							<td>3.04</td>
							<td>3.21</td>
							<td>3.47</td>
						</tr>
						<tr>	
							<td>유지량+유단백질량(kg)</td>
							<td>571</td>
							<td>600</td>
							<td>597</td>
						</tr>
						<tr>	
							<td>(참고)우군검정보급율(%)</td>
							<td>43.3</td>
							<td>48.9</td>
							<td>77.4</td>
						</tr>
					</tbody>
				</table>
				<ul className="refer mg10t mg30f">
					<li>(자료) ＩＣＲＡ조사년보(1995) </li>
				</ul>
						
				<dl>
					<dt>유전적개량량</dt>
					<dd>(표 14)는 최근 5년간에 있어서 각국의 년당 유전적개량량을 표시한 것이다. 나라에 따라 유전적 능력평가 방법이 다르기 때문에 엄밀히 비교할 수 없으므로, 화란이 유량에서는 매우 높은 개량속도를 실헌하고 있는 사실을 엿볼 수 있다. 그렇지만 유지량은 일본이 높은 신장세를 보이고 있으나, 이것은 일본에서 사용되어 온 경제효과의 표시에서 유지량의 비중이 크다는 것을 나타내는 것이기 때문에 흥미진진하다.</dd>
				</dl>
				<table className="tablestyle">
					<caption>&lt;표 14&gt; 최근 5년간의 홀스타인종(우군검정우)의 년당 유전적 개량량</caption>
					<thead>
						<tr>
							<th className="first">구 분</th>
							<th>일 본</th>
							<th>미 국</th>
							<th>화 란</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>유량(kg)</td>
							<td>126</td>
							<td>124</td>
							<td>142</td>
						</tr>
						<tr>
							<td>유지량(kg)</td>
							<td>4.7</td>
							<td>3.8</td>
							<td>3.5</td>
						</tr>
						<tr>
							<td>유단백질량(kg)</td>
							<td>3.5</td>
							<td>3.8</td>
							<td>4.5</td>
						</tr>
					</tbody>
				</table>
				<ul className="refer mg10t mg60f">
					<li>(주) 1989 ~1993년에 생산된 암소의 유전적 능력의 추이에 대한 일차회귀계수. 단지, 화란은 1990 ~1993년의 것 (자료)가축개량센타유용우 평가 보고(1997-Ⅱ), USDA ALPL자료, NRS&apos; Jaarstatistieken.</li>
				</ul>
				
				<h2>주요 낙농국의 보급율 </h2>
				<p>우군검정에 관한 국제적인 위원회인 ICAR(International Committee for Animal Recording의 약자) 에는 35개국과 4지역(영국)이 가맹하고 있다. 그중 경산우 사양두수 10만두미만 혹은 보고가 없는 7개국 지역을 제외한 최근의 상황은 &lt;표 15&gt;와 같다.</p>
				<p>일본의 보급율(검정우의 비율)은 즈요 낙농국의 가운데 최저수준에 있다. 일본과 같은 사양조건에서, 1두당 생산성 향상을 꾀하기 위해서는 유성분을 포함한 개체의 비유데이터는 필요불가결하다고 생각된다. 그 때문에 이 차이가 경영체질의 차이로 되고, 경쟁력의 차이로 된다는 것을 머리에 유념할 필요가 있다. </p>

				<table className="tablestyle mg30f">
					<caption>&lt;표 15&gt; ICAR가맹국의 우군검정상황(1995년)</caption>
					<thead>
						<tr>
							<th className="first">국 명</th>
							<th>경산우두수(천두) (이중검정우의 비율%)</th>
							<th>국 명</th>
							<th>경산우두수(천두) (이중검정우의 비율%)</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>체 코</td>
							<td>720(91.0)</td>
							<td>호 주</td>
							<td>705(49.2)</td>
						</tr>
						<tr>
							<td>노르웨이</td>
							<td>325(90.0)</td>
							<td>미 국</td>
							<td>9,461(48.4)</td>
						</tr>
						<tr>
							<td>덴 마 크</td>
							<td>700(88.0)</td>
							<td>영국(스코틀랜드)</td>
							<td>236(48.0)</td>
						</tr>
						<tr>
							<td>뉴질랜드</td>
							<td>2,904(87.4)</td>
							<td>이탈리아(&apos;94)</td>
							<td>2,277(47.3)</td>
						</tr>
						<tr>
							<td>스 웨 덴</td>
							<td>487(80.0)</td>
							<td>일 본</td>
							<td>1,211(43.6)</td>
						</tr>
						<tr>
							<td>화 란</td>
							<td>1,708(79.7)</td>
							<td>스로베니아</td>
							<td>179(36.7)</td>
						</tr>
						<tr>
							<td>이스라엘</td>
							<td>121(78.8)</td>
							<td>벨기에</td>
							<td>673(36.0)</td>
						</tr>
						<tr>
							<td>독 일</td>
							<td>5,229(74.9)</td>
							<td>아일랜드</td>
							<td>1,267(27.0)</td>
						</tr>
						<tr>
							<td>슬로바키아</td>
							<td>359(69.0)</td>
							<td>남아프리카</td>
							<td>500(26.7)</td>
						</tr>
						<tr>
							<td>핀 란 드</td>
							<td>399(68.9)</td>
							<td>영국(북아일랜드)</td>
							<td>271(24.3)</td>
						</tr>
						<tr>
							<td>헝가리(&apos;94년)</td>
							<td>382(68.0)</td>
							<td>포르투갈</td>
							<td>360(22.0)</td>
						</tr>
						<tr>
							<td>에스토니아</td>
							<td>211(67.9)</td>
							<td>크로아치아</td>
							<td>235(20.0)</td>
						</tr>
						<tr>
							<td>스 위 스 </td>
							<td>770(67.0)</td>
							<td>희랍(&apos;94)</td>
							<td>205(17.5)</td>
						</tr>
						<tr>
							<td>캐 나 다</td>
							<td>1,279(59.8) </td>
							<td>튜니지아</td>
							<td>127(15.0)</td>
						</tr>
						<tr>
							<td>불 란 서</td>
							<td>4,754(55.0)</td>
							<td>스페인</td>
							<td>1,218(11.9)</td>
						</tr>
						<tr>
							<td>영국(웨일즈)</td>
							<td>2,107(50.2)</td>
							<td>폴란드</td>
							<td>3,579(9.6)</td>
						</tr>
					</tbody>
				</table>
				
				<dl>
					<dt>검정방법</dt>
					<dd>
						<p>각국의 검정방법에는 입회검정과 자가검정(검정원과 협력해서 수행하는 검정을 포함)이 있는데, 각각 검정간격등 해서 다시 구분된다.</p>
						<p>검정방법은 각국별로 낙농가가 이용하는 정보로써, 필요충분한 정확성이 확보된다는 전제하에 선택되고 있다.</p>
						<p>&lt;표 16&gt;과 같이, 가장 일반적인 것을 일본과 같은 30일 간격(월 1회 아침, 저녁)의 입회검정이다. 한편, 대규모 경영형태가 아직도 많이 산재해 있는 뉴질랜드에서는, 자가검정이 &lt;표 15&gt;와 같이 높은 보금율을 달성하고 있는 것을 알 수 있다. 덴마크도 자가검정이 주류를 이르고 있으나, 출하유량과의 첵크등에 의하여 정확성이 확보되고 있다.</p>
						<p>또한, 미국에서는 30일 간격의 입회검정으로 하고 있으나, &quot;이번에는 아침, 다음에는 저녁&quot;하는 식으로 아침, 저녁 교대로 검정을 실시하는 AT법이 반수를 넘고 있다. 일본에서도 이 방법을 채용하는 것에 대하여 검토되고 있으나, 정확성의 측면에서는 의문시하는 나라도 적지 않다. </p>
					</dd>
				</dl>
				
				<div className="tableWrap">
					<table className="tablestyle">
						<caption>&lt;표 16&gt; 주요 낙농국에 있어서 우군검정방법(1995년)</caption>
						<thead>
							<tr>
								<th className="first">구 분</th>
								<th>일 본</th>
								<th>미 국</th>
								<th>캐나다</th>
								<th>화 란</th>
								<th>불란서</th>
								<th>독 일</th>
								<th>덴마크</th>
								<th>뉴질랜드</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>입회검정</td>
								<td>99</td>
								<td>80</td>
								<td>51</td>
								<td>100</td>
								<td>100</td>
								<td>85</td>
								<td>24</td>
								<td>1</td>
							</tr>
							<tr>
								<td>이중21일간격</td>
								<td>0</td>
								<td>0</td>
								<td>41</td>
								<td>12</td>
								<td>0</td>
								<td>0</td>
								<td>0</td>
								<td>0</td>
							</tr>
							<tr>
								<td>이중30일간격</td>
								<td>99</td>
								<td>26</td>
								<td>10</td>
								<td>69</td>
								<td>88</td>
								<td>85</td>
								<td>24</td>
								<td>0</td>
							</tr>
							<tr>
								<td>이중42일간격</td>
								<td>0</td>
								<td>0</td>
								<td>0</td>
								<td>19</td>
								<td>5</td>
								<td>0</td>
								<td>0</td>
								<td>1</td>
							</tr>
							<tr>
								<td>이중AT간격</td>
								<td>0</td>
								<td>54</td>
								<td>0</td>
								<td>0</td>
								<td>7</td>
								<td>0</td>
								<td>0</td>
								<td>0</td>
							</tr>
							<tr>
								<td>자가검정</td>
								<td>1</td>
								<td>20</td>
								<td>49</td>
								<td>0</td>
								<td>0</td>
								<td>15</td>
								<td>76</td>
								<td>99</td>
							</tr>
						</tbody>
					</table>
				</div>
				<ul className="refer mg60f" style={{margintop: '-20px;'}}>
					<li>(단위:%)</li>
					<li>(주) AT법은 30일 간격의 아침, 저녁 교대 검정</li>
					<li>(자료) ICAR 조사 년보(1996년) </li>
				</ul>
				
				<h2>후대검정 </h2>
				<dl>
					<dt>주요 낙농국의 개요</dt> 
					<dd>(표 17)은 주요 낙농국의 홀스타인종에 대한 후대검정의 개요를 나타내고 있다. 홀스타인종의 경산우두수는 미국이 압도적으로 많고, 불란서, 독일이 그 다음을 쫓아가고 있다. 일본, 카나다, 화란은 거의 같은 두수이다. 그러나 우군검정의 보급율은 카나다에서 약 60%,화란에서는 약 80%에 달하고 있기 때문에, 개량기반이 되는 우군검정은, 카나다가 일본의 1.3배, 화란이 1.6배가 되고 있다.</dd>
				</dl>
				<div className="tableWrap">
					<table className="tablestyle">
						<caption>&lt;표 17&gt; 주요 낙농국에 있어서 홀스타인종의 후대검정 개요</caption>
						<thead>
							<tr>
								<th className="first">구 분</th>
								<th>일 본</th>
								<th>미 국</th>
								<th>캐나다</th>
								<th>화 란</th>
								<th>불란서</th>
								<th>독일</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>경산우사양두수(천두)</td>
								<td>1,200</td>
								<td>9,100</td>
								<td>1,150</td>
								<td>1,070</td>
								<td>3,070</td>
								<td>2,800</td>
							</tr>
							<tr>
								<td>우군검정우두수(천두)</td>
								<td>520</td>
								<td>4,400</td>
								<td>690</td>
								<td>840</td>
								<td>1,950</td>
								<td>2,000</td>
							</tr>
							<tr>
								<td>우군검정우비율(%)</td>
								<td>43.3</td>
								<td>48.9</td>
								<td>61.3</td>
								<td>77.4</td>
								<td>63.5</td>
								<td>72.0</td>
							</tr>
							<tr>
								<td>년간후보종모우두수 (두/년)</td>
								<td>185</td>
								<td>1,200</td>
								<td>350</td>
								<td>350</td>
								<td>660</td>
								<td>700</td>
							</tr>
							<tr>
								<td>선발 압</td>
								<td>1/3정도</td>
								<td>1/8정도</td>
								<td>1/10정도</td>
								<td>1/20정도</td>
								<td>1/10정도</td>
								<td>1/8정도</td>
							</tr>
							<tr>
								<td>1후보종모우당 검정 딸소두수(두)</td>
								<td>36→50 (최근실적28)</td>
								<td>50</td>
								<td>60</td>
								<td>100</td>
								<td>85</td>
								<td>70~100</td>
							</tr>
							<tr>
								<td>경산우에 대한 검정 딸소의 비율(%)</td>
								<td>2</td>
								<td>3</td>
								<td>9</td>
								<td>16</td>
								<td>9</td>
								<td>9~13</td>
							</tr>
							<tr>
								<td>(참고)주요인공수정 사업체수</td>
								<td>5</td>
								<td>12</td>
								<td>1 (80%이상점유)</td>
								<td>1 (90%이상점유)</td>
								<td>5</td>
								<td>-</td>
							</tr>
						</tbody>
					</table>
				</div>
				<ul className="refer mg30f" style={{marginTop: '-20px'}}>
					<li>(주) 공용산차는 평균5산으로 추계</li>
				</ul>

				<p>위표에서 보는 바와 같이, 1년간에 후대검정에 거는 후보종모우 두수는, 일본의 185두에 대하여 카나다, 화란은 다함께 350두까지 올라간다. 한편, 후대검정우에 선발되어, 정액이 일반으로 공급되는 종모우두수는 일본이 년간 50 ~ 60두에 대하여, 화란에서는 15두이하로 되고 있다.</p>
				<p>이 결과, 선발압(선발두수/후보종모우두수)은 일본이 1/3정도인데 비하여, 카나다는 1/10정도, 화란은1/20이상으로 되어 있다.</p>
				<p>또한 1후보종모우당 검정딸소두수는 일본이 계획 36두(현재는 50두)에 대하여 실제로는 28두 정도로 하고 있는데 비하여, 카나다는 60두, 화란은 100두정도로 되어 있다.</p>
				<p className="mg30f">카나다에서는 후보종모우를 감축하고, 다시 딸소 수를 증가하는 방안을 검토하는 있는 것으로 듣고 있다. </p>

				<dl className="mg60f">
					<dt>기술적인 포인트 </dt>
					<dd>
						<ul className="listStyle03 mg10l">
							<li>
								선발압<br/>
								<p>유량등의 유전적 능력은 그림17과 같이 정규분포를 한다. 이 분포는예를 들면, 후보종모우라면 두수가 많아졌어도 이론적으로는 모양과 평균치의 위치는 변하지 않는다. 즉 후보종모우두수가 많을수록, 보다 높은 능력을 가진 종모우가 탄생하는 확률이 높아진다.</p>
								<div className="imgBox w100p"><img src={Pic805} alt=''/> <img src={Pic806} alt=''/></div>
								<p>또한 185두 가운데 상위로부터 60두 선발하는 경우와 20두 선발하는 경우를 비교하면, 당연히 두수가 많을수록 능력이 낮은 것을 선발하는 결과가 된다. 실제로 60두가 필요하다면 어쩔수가 없으나, 일본의 경우에는 표 18과 같이 상시 40두정도 있으면 족하다는 계산이 된다. 1두의 종모우를 2년 쓴다고 하면, 매년 20두씩 선발하면 충분한 것으로 된다. </p>
								
								<table className="tablestyle">
									<caption>&lt;표 18&gt; 일본내 동결정액공급에 필요한 검정필종모우두수(시산)</caption>
									<tbody>
										<tr>
											<th>인공수정실두수</th>
											<td>1,500천두/1년</td>
											<td>①</td>
										</tr>
										<tr>
											<th>1두당 평균 인공수정회수</th>
											<td>2.0회</td>
											<td>②</td>
										</tr>
										<tr>
											<th>연 인공수정회수</th>
											<td>3,000천회/년</td>
											<td>③=①×②</td>
										</tr>
										<tr>
											<th>후계우 생산을 위한 인공수정비율</th>
											<td>70%</td>
											<td>④</td>
										</tr>
										<tr>
											<th>후계우 생산을 위한 인공수정회수</th>
											<td>2,100천회/년</td>
											<td>⑤=③④</td>
										</tr>
										<tr>
											<th>검정필종모우를 쓰는 인공수정비율</th>
											<td>80%</td>
											<td>⑥</td>
										</tr>
										<tr>
											<th>검정필종모우를 쓰는 인공수정회수</th>
											<td>1,680천회/년</td>
											<td>⑦=⑤×⑥</td>
										</tr>
										<tr>
											<th>종모우1두당 동결정액공급본수</th>
											<td>40천본/년</td>
											<td>⑧</td>
										</tr>
										<tr>
											<th>검정필종모우 필요두수</th>
											<td>42두</td>
											<td>⑨=⑦÷⑧</td>
										</tr>
									</tbody>
								</table>
								<ul className="refer mg10t mg15f">
									<li>(주) ④는 후계우생산을 위한 암소 비율, ⑥의 나머지 20%는 후보종모우의 조정교배와 수입정액</li>
								</ul>
								<p>그림을 보면, 350두 가운데의 15두와 185두 가운데 60두간에는 차가 너무 크다. 합해서 20두로 짜는 것이 필요하다고 생각된다. </p>
							</li>
							<li>
								정확성<br/>
								<p>한편, 종모우평가의 정확성에는, 데이터의 무작위성과 후보종모우 1두당 검정딸소두수에 영향을 미친다. 유량과 결정득점에 대하여, 데이터에 편기가 없다는 것을 전제로 하여, 일본의 유전적 능력평가에 있어서 딸소수와 정확성의 지표가 되는 신로도의 관계를 표시하는 그림 18과 같이 된다. 유량에서는 계획두수인 딸소 36두에서 75-85%, 50두에서는 약 85-90%의 신뢰도가 확보되고 있다.<br/></p>
								<div className="imgBox w100p"><img src={Pic807} alt=''/> <img src={Pic808} alt=''/></div>
								<p>이것은 데이터가 적으면 자연히 편기도 생기기 쉽다는 사실을 감안한다면, 유량 및 같은 유전율을 가지고 있는 유성분의 정확성을 확보하는 위에, 50두가 하나의 목표두수로 보게 된다.</p>
								<p>단지, 이들 비유형질의 유전율은 0.30이지만, 유전율이 0.23인 결정득점의 경우에는 50두를 해도 신뢰도는 75-80%로 저하한다. 다시 유전율이 낮은 형질(예를 들면, 유기는 0.10)이라면 보다 많은 딸소가 없으면 충분한 정확성은 확보할 수가 없게 된다. 그 때문에 화란에서는 100두나 되는 딸소를 확보하고 있다.</p>
								<p>그런데, 데이터에 편기가 있으면, 딸소가 몇두가 있어도 정확성은 확보되지 못한다. 1,000두의 딸소에 의하여 신뢰도 99%로 계산되어도, 실제로는 정확하지 않은 것으로 된다. 미국에서는 이전부터 특정한 후보종모우의 딸소가 우군내에서 차별적으로 취급되는 경우에는 보정치로 조정하는 연구를 하고 있다. 그렇게 해서도 표준적 프로그램이외에서 후대검정된 종모우의 딸소가 우군내에서 차별적으로 취급되는 경우에는 보정치로 조정하는 연구를 하고 있다. 그렇게 해서도 표준적 프로그램이외에서 후대검정된 종모우의 평가치가 일반적으로 공용된 경우의 딸소성적이 첨가된 시점에서 저하하는 문제가 일어나고 있다. 이러한 점은 신뢰도등에 반영시키는 것이 곤란하기 때문에 검정딸소의 배치방법으로 표시하는 S,M.O의 &quot;샘플코 - 드&quot;를 붙이는 것에 주의를 촉구한다. (O와 M에서 요주의) </p>
							</li>
							<li> 
								인공수정 사업체<br/>
								<p>최근, 화란에서는 종래의 인공수정사업체가 하나의 농협조직(홀랜드 제네틱스, Holland Genetics)으로 통합되었다. 외국자본의 사업체등이 있기는 하나, 이 조직이 90%이상 전국을 카바하고 있다. 또한 카나다에서도 통합이 진행되어 1개사가 80%이상을 점유하게 되었다고 듣고 있다. 미국에서도 12개사 정도로까지 계열화가 진행되고 있다.</p>
								<p>이러한 배경에는 유전적능력평가의 정밀도가 높아진 결과, 그것이 종모우의 절대적 가치 기준이 되어, 종모우를 소유하는 사업체가 어디에 있는가는 의미가 없게된 것 같다. 또한 뒤에서 이야기하겠지만, 종모우의 국제평가등에 의하여 동결정액의 시장이 국제화함으로써, 국외와의 경쟁이 치열하게 되었다는 것도 하나의 이유이다.</p>
								<p>보다 우수한 종모우를 만들기 위한 효율적인 후보종모우의 확보나 효율적인 정액의 제조, 배포를 위해서는 업무의 통합이 필요하게 되었다는 이유도 있는 것 같다. </p>
							</li>
						</ul>
					</dd>
				</dl>
				
				<h2>유전적능력의 국제평가 </h2>
				<dl>
					<dt>인터-볼(InterBull)</dt> 
					<dd>
						<p>젖소의 유전자원은 세계공통으로 되고 있다. 특히 세계로 널리 퍼진 홀스타인종은 항시, 동결정액이나 수정난의 교류가 이루어지고 있다.</p>
						<p>이러한 가운데, ICAR의 소위원회인 인터-볼(International Bull Evaluation Service)은 1994년부터 년2회, 6개품종의 종모우의 국제적인 유전적능력평가를 실시하고 있다.</p>
						<p>인터-볼은 ICAR의 하부조직으로써, 회원국의 유용종모우의 유전적능력평가를 조화하는 것을 목적으로, 1983년에 설립된 조직이다. 일본도 가축개량센타와 (사)가축개량사업단의 회원으로 되어 있다.</p>
						<p>1994년에 4개국으로 시작된 종모우의 국제평가는 1997년 8월에는 표19에 표시된 20개국이 참가하여, 52,517두의 홀스타인종 종모우가 평가되고 있다. </p>
					</dd>
				</dl>
				<dl>
					<dt>MACE법</dt> 
					<dd>
						<p>인터-볼에서는 유량,유지방, 유단백질에 대하여 국제평가가 이루어지고 있다. 평가에는 MACE법(Multitrait Across Country Evaluation의 약칭)이라 불리워지는 방법이 채용되고 있다. 데이터로서는 각각의 참가국에서 제출한 종모우평가치가 사용되고 있다. </p>
						
						<table className="tablestyle">
							<caption>&lt;표 19&gt; 인터-볼에 의한 국제평가 참가국(1997년 8월)</caption>
							<thead>
								<tr>
									<th className="first">참 가 국</th>
									<th>홀스타인종 데이타수</th>
									<th>참 가 국</th>
									<th>홀스타인종 데이타수</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>미 국</td>
									<td>15,158</td>
									<td>체 코</td>
									<td>1,007</td>
								</tr>
								<tr>
									<td>불 란 서</td>
									<td>7,519</td>
									<td>벨 기</td>
									<td>645</td>
								</tr>
								<tr>
									<td>독 일</td>
									<td>6,521</td>
									<td>핀 란 드</td>
									<td>553</td>
								</tr>
								<tr>
									<td>화 란</td>
									<td>4,558</td>
									<td>아일랜드 </td>
									<td>533</td>
								</tr>
								<tr>
									<td>카 나 다</td>
									<td>4,460</td>
									<td>스 페 인</td>
									<td>556</td>
								</tr>
								<tr>
									<td>덴 마 크</td>
									<td>3,647</td>
									<td>스 위 스</td>
									<td>429</td>
								</tr>
								<tr>
									<td>이탈리아</td>
									<td>2,751</td>
									<td>오 지 리</td>
									<td>124</td>
								</tr>
								<tr>
									<td>영 국</td>
									<td>2,674</td>
									<td>스로베니아</td>
									<td>58</td>
								</tr>
								<tr>
									<td>호 주</td>
									<td>2,563</td>
									<td>노르웨이</td>
									<td>-</td>
								</tr>
								<tr>
									<td>뉴질랜드</td>
									<td>2,223</td>
									<td>합 계</td>
									<td>57,506</td>
								</tr>
								<tr>
									<td>스웨덴</td>
									<td>1,536</td>
									<td>종모우실두수 </td>
									<td>53,517</td>
								</tr>
							</tbody>
						</table>
						<ul className="refer mg10t mg15f">
							<li>주) 데이터수는 1980년이후 생산된 딸소가 10우군 이상에 있는 종모우의 국내평가치</li>
							<li>(자료) InterBull New Letter(1997-8)</li>
						</ul>
						<p>각국의 종모우평가치는 젖소의 유전적 능력 수준에 차이가 있기 때문에 비교가 되지 않는다. 그 때문에 2개국간의 종모우평가치를 비교하고저 할 경우, 먼저 변환계수를 쓰는 것을 생각할 수 있다. 예를들면 1996년 8월의 미국의 유량평가치는 미국에서의 평가치×0.61+303=화란에서의 평가치에 의하여, 화란의 평가치로 변환할 수가 있다.</p>
						<p>그러나, 유량이라는 같은 형질이라해도, 다른 환경하에서는 같은 유전적능력으로 된다는 보장은 없다. 환경과 유전에는 서로 관계가 있는데, 예를 들면 어떤 환경하에서는 종모우A가 +100, 종모우 B가 +80이었는데, 다른 환경하에서는 종모우 A가 +60으로 저하하는 한편, 종모우 B가 +80 그대로 나타나는 일이 없으라는 법이 없다. 이것은 같은 형질에 있어서도 다수의 유전자가 관여하고 있기 때문에 환경에 따라서 관여하는 유전자가 다소 달라진다든지, 같은 유전자에도 작용에 차이가 생기기도 한다는 것으로 생각하면 이해하기 쉽다. 고온다습한 환경하에서는 생리적 기능이 저하하지 않는 소나 사사하의 스트레스에 강한 소등이 있을 수 있다는 생각을 하면, 이것이 국내에서 후대검정을 꼭 해야한다는 의의의 하나가 여기에 있다.</p>
						<p>변환계수에서는 이와 같은 점이 고려되어 있지 않으나, MACE법에서는, 이러한 환경과 유전의 관계에 대하여 참가국에 따라 다르다는 점을 고려한 평가가 이루어지고 있다. 그 때문에, 〈그림 19〉와 같이 2두의 종모우를 보는 경우, A국에서의 순위와 B국에서의 순위가 다른 경우도 일어나는 일이 있다.</p>
						<p>인터-볼는 각각의 참가국에 대하여 그 나라에서의 평가치를 제공하고 있다. 즉 데이터는 각국의 종모우 평가치이지만, 동일한 종모우의 다른 나라에서의 데이터나 다른 나라의 종모우와의 혈연관계가 고려되기 때문에, 자기나라의 종모우 평가치도 변화하게 된다. </p>
					</dd>
				</dl>
				
				<dl>
					<dt>국제평가의 결과</dt> 
					<dd>인터-볼로 적용한 국제평가의 결과는, 각각의 참가국에 제공되기 때문에 상세한 것은 불명하다. 또한, 1997년 8월 현재, 체형형질에 대해서는 평가가 실시되어 있지 않다. 그러나, 미국, 카나다등에서는 MACE법을 써서 독자적으로 계산을 하고 있다.</dd>
				</dl>
				<div className="imgBox"><img src={Pic809} alt=''/><br/>&lt;그림 19&gt; 인터-불에 의한 국제평가의 개요</div>
				<ul className="listStyle04 mg15f">
					<li>인터-볼의 평가에는 참가국 각각의 국내평가치가 데이터로써 쓰인다. </li>
					<li>동일 형질(예를들면 유량)에서도, 참가국에 의하여 조건이 다르기(예를들면 고온·다습) 때문에, 순위는 변화한다.(A국과 B국의 종모우 순위뿐 아니라, 종모우 B1과 종모우 B2와 같이 B국내의 순위가 A국에서는 역으로 되는 수도 있다.</li>
				</ul>
				<p>예를 들면, 카나다에서는 작년부터 &quot;WHO&apos;S WHO&quot;에 비유형질과 체형형질의 평가치로부터 계산되는 LPIDP 기초를 두고 있는 해외의 종모우 상위 100두가 계재되어 있다. 〈표20〉은 1997년 2월과 8월의 것이다. </p>
				<ul className="listStyle03">
					<li>&lt;표 20&gt; 카나다 WHO&apos;S WHO에 대한 MACE법에 의한 종모우의 국제순위(LPI순)
						<table className="tablestyle mg10t mg30f">
							<caption>상위 10두의 공용국과 명호(1997년 2월 평가)</caption>
							<thead>
								<tr>
									<th className="first">순 위</th>
									<th>공 용 국</th>
									<th>명 호</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>1위</td>
									<td>화 란</td>
									<td>ETAZON CELSIUS</td>
								</tr>
								<tr>
									<td>2위</td>
									<td>불 란 서 </td>
									<td>FATAL</td>
								</tr>
								<tr>
									<td>3위</td>
									<td>화 란</td>
									<td>ETAZON LABLLE</td>
								</tr>
								<tr>
									<td>4위</td>
									<td>화 란</td>
									<td>NEWHOUSE RONALD TL</td>
								</tr>
								<tr>
									<td>5위</td>
									<td>불 란 서</td>
									<td>GIBBON</td>
								</tr>
								<tr>
									<td>6위</td>
									<td>화 란</td>
									<td>EASTLAND CASH</td>
								</tr>
								<tr>
									<td>7위</td>
									<td>화 란</td>
									<td>EATZON LORD LILY</td>
								</tr>
								<tr>
									<td>8위</td>
									<td>미 국</td>
									<td>END-ROAD</td>
								</tr>
								<tr>
									<td>9위</td>
									<td>화 란</td>
									<td>HOLIM STANS TORNEDO ER TL</td>
								</tr>
								<tr>
									<td>10위</td>
									<td>독 일</td>
									<td>AMARETTO</td>
								</tr>
							</tbody>
						</table>
												
						<table className="tablestyle mg30f">
							<caption>상위 10두의 공용국과 명호(1997년 8월 평가)</caption>
							<thead>
								<tr>
									<th className="first">순 위</th>
									<th>공 용 국</th>
									<th>명 호</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>1위</td>
									<td>독 일</td>
									<td>LASTER</td>
								</tr>
								<tr>
									<td>2위</td>
									<td>화 란</td>
									<td>NEWHOUSE RONALD TL</td>
								</tr>
								<tr>
									<td>3위</td>
									<td>미 국</td>
									<td>EAST VIEW INFLUENCE MATTIE G.</td>
								</tr>
								<tr>
									<td>4위</td>
									<td>독 일</td>
									<td>LUKAS</td>
								</tr>
								<tr>
									<td>5위</td>
									<td>불 란 서</td>
									<td>FATAL</td>
								</tr>
								<tr>
									<td>6위</td>
									<td>미 국</td>
									<td>PAURO-BRO R시 DEMAND TCG-ET</td>
								</tr>
								<tr>
									<td>7위</td>
									<td>미 국</td>
									<td>LADYS-MANOR WINCHIESTER-ET</td>
								</tr>
								<tr>
									<td>8위</td>
									<td>미 국</td>
									<td>OUT-JOCK CHOICE AMEL-ET</td>
								</tr>
								<tr>
									<td>9위</td>
									<td>화 란</td>
									<td>SOUTHLAND MARKER TL</td>
								</tr>
								<tr>
									<td>10위</td>
									<td>미 국 </td>
									<td>BLOK-BROS AS LEIF</td>
								</tr>
							</tbody>
						</table>
						
						<table className="tablestyle">
							<caption>상위 50위까지의 공용국 내역</caption>
							<thead>
								<tr>
									<th className="first">구 분</th>
									<th>미 국</th>
									<th>화 란</th>
									<th>불 란 서</th>
									<th>독 일</th>
									<th>이 태 리</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>1997년 2월 평가 </td>
									<td>27</td>
									<td>12</td>
									<td>7</td>
									<td>2</td>
									<td>2</td>
								</tr>
								<tr>
									<td>1997년 8월 평가</td>
									<td>16</td>
									<td>25</td>
									<td>6</td>
									<td>3</td>
									<td>0</td>
								</tr>
							</tbody>
						</table>
						<ul className="refer mg10t mg30f">
							<li>(주) 카나다에 있어서 해외종모우 순위를 위한 것이므로, 카나다의 종모우는 포함하지 않았음. </li>
						</ul>
						<p>그런데, 이것을 보면 겨우 반년 사이에도 상당한 변동이 있다는 것을 알 수 있다. 변동의 이유로서는 각각의 나라에 있어서 새로운 종모우가 나타난다든가 종모우평가치에 변동이 있었다든지, MACE법의 세부내용이 수시로 개량되고 있다는 것등이 고려된다.</p>
						<p>그러한데도, 유럽제국의 종모우가 상위에 있다는 사실이 주목을 끈다. 불란서, 독일은 사양두수도 많기 때무네 놀랄 것은 없지만, 화란 종모우의 성적에는 정직하게 놀라움을 느끼지 않을 수 없다. 화란의 현황은 사양두수가 적어 육종기반이 적은 나라인데도, 큰나라에 못지 않는 종모우가 작출되고 있음을 실증하고 있다고 말할 수 있다. </p>
					</li>
				</ul>
				
				<dl className="mg60f">
					<dt>일본의 대응</dt>
					<dd>
						<p>인터-볼의 국제평가에는 여러나라의 종모우 평가치가 데이터로서 사용된다. 그런데, 딸소수가 적은 종모우는 정확성이 낮기 때문에 과소평가되는 경향이 보이고 있다. 50두 정도 있으면 그런대로 무엇인가 되겠지만, 일본의 현황인 평균 28두로써는 핸디캡이 너무 크다고 말하지 않을 수 없다. 그 때문에 당면과제는 50두의 딸소확보에 전력하지 않을 수 없는 형편이다.</p>
						<p>즉, 순위가 크게 변화하고 있는 바와 같이 MACE법도 아직까지 불완전하다고 이야기되고 있다. 일본의 경우에는 고온·다습, 농후사료 다급이라는 환경에 있기 때문에, 다른 나라와의 유전상관이 낮은 것이 예상된다. 특히 평가치의 해석이 어려운 것으로 생각된다. 나온 결과를 유효하게 사용하기 위해서는 50두를 확보하기까지의 사이에, 먼저 기초적인 연구를 많이 해두는 것이 중요할 것 같다. </p>
					</dd>
				</dl>
				
				<h2>혈통등록과 새로운 개체식별 시스템 </h2>
				<dl>
          <dt>혈통등록상황  </dt>
					<dd>
						<p>젖소개량은 어버이소로부터 자축으로의 유전을 기초로 한다. 그 때문에 혈통등록의 시작은 젖소개량의 역사의 시작을 의미한다. 그러나, 낙농선진제국에 있어서 경산우두수에 대한 등록우의 비율을 보면, 화란과 같이 80% 가까이 하는 나라가 있는가 하면, 미국과 같이 10%정도 하는 나라까지 여러 가지가 있다. 암소 대부분을 육종집단으로 삼거나, 일부를 종축으로 선택하느냐에 따라, 다시 말하면 후계우를 남기는 암소 대부분의 정확한 혈연정보가 필요하다고 생각하거나, 소위 말하는 종축의 혈통을 증명하기 위한 수단으로 생각하는가에 따라 위치부여가 달라질 것 같다.</p>
						<p>미국에서는 유전적 능력평가에 있어서, 같은 낙농가내의 무등록우와 등록우에 대한 사양환경의 차이를 보정하고 있으나, 이런 것들은 미국에 있어서 혈통등록의 위치부여를 엿볼 수 있는 것이다.</p>
						<p>한편, 가장 혈통등록의 비율이 높은 화란등 유럽의 낙농선진제국에 있어서, 최근 체제가 변화하고 있다. 혈통등록이란 개체식별과 정확한 혈연관계의 확인, 등록원부로의 기재에 의한 혈통구축, 개체의 혈통과 능력의 증명으로 구성된다고 생각하면, 첫째로 큰이점은 새로히 정비된 귀표를 사용하는 시스템에 의하여 100% 카바된다는 것이다. </p>
					</dd>
				</dl>
				<dl>
					<dt>새로운 개체식별 시스템</dt>
					<dd>
						<ul className="listStyle03 mg10l">
							<li>코 - 드의 통일과 정보의 통합<br/>
								<p>젖소에는 여러 가지 코 - 드가 부착되어 있다. 등록이나 우군검정과 같이 국내통일된 코 - 드도 있겠지만, 일본에 있어서 가축공제나 농협이 붙이는 것 같은 지역별 코 - 드, 이와달리 낙농가가 붙이는 코 - 드도 있다. 그러나 가장 일반적인 등록이라 해도 젖소에 번호가 그려져 있는 것은 아니다. 반문(문희)을 보고 비로소 번호와 개체가 일치하는 것이다. 또한 반문을 보고도, 전혀 등록되어 있지 않으면 개체는 식별되지 않는다.</p>
								<p>한편, 여러 가지 코 - 드가 붙어 있다고 말하는 것은 1두의 젖소에 관한 정보가 무질서하게 관리되고 있다는 말 밖에는 없다. 모든 젖소에 식별을 위한 코 - 드를 붙이고, 그 코 - 드를 색인 귀표를 장착해서 정보도 통합시키면 편리하기 짝이 없다. </p>
							</li>
							<li>화란의 상황 <br/>
								<p>화란을 방문한 사람은 어느 낙농가의 젖소를 보아도, 또한 방목되고 있는 어느 소(육용우 포함)를 보아도, 양쪽 귀에 같은 모양의 황색귀표가 부칙되어 있는 것에 주의를 기울이게 된다. 실은, 이 귀표에는 그 소의 개체 코 - 드가 새겨져 있고, 국내 소에는 모두 이 귀표가 붙여져 있다.</p>
								<p>이것에 의하여 화란의 젖소는 반문이나 기타 특징에 의존하지 않고, 개체 코 - 드에 의하여 개체식별 확인이 이루어지고 있어, 소유자, 혈통, 우군검정, 수정, 가축진료등 모든 개체정보가 관리되고 있다. 이 결과 100% 카바된 혈연정보에 의하여 개량효율이 높아져, 개체정보의 통합에 의하여 정보의 효율적 활용이 촉진되고, 한번 보아 알수 있는 귀표에 의하여 개체확인의 간소화가 이루어지고 있다. 또한 출산된 후 그 시점까지의 소유자, 즉 이동결로가 분명해지기 때문에, 전염병 발생시의 추저조사도 가능해지고 있다. </p>
							</li>
							<li>유럽전역의 정비<br/>
								<p>유럽에서는 1992년에 전염병이 발생한 경우의 감염경로나 감염원의 규명을 목적으로 하여 이와같은 시스템을 정비할 것을 권고받았다. 화란에서는 이것을 받아들여 법제화하는등 정비가 추진되어 왔다. 다시 광우병 소동을 계기로 한 1997년 4월에는 2000년까지 정비하는 것을 규칙화하여, 가맹국에 의무화하도록 하였다.</p>
								<p>화란이외, 불란서, 덴마크등에서도 이미 정비가 이루어져 합계 13개국에서 구체적인 계획 추진이 시작되었으나, 반드시 방법은 일치하지 않고 있다. 금후에는 가장 합리적인 화란방식에 가까운 형태로 통일적으로 정비가 추진될 것 같다. </p>
							</li>
							<li>유럽이외의 상황<br/>
								<p>유럽이외에서는 카나다가 독자적인 방식으로 도입을 추진하고 있고, 또한 한국에도 도입이 진행되고 있다는 말을 듣고 있다고 한다. </p>
							</li>
						</ul>
					</dd>
				</dl>
				<dl>
					<dt>화란의 개체식별 시스템 개량 </dt>
					<dd>위에서 이야기한바와 같이, 최근 화란에 있어서 젖소개량에 현저한 진전이 보이고 있다. 그 배경에는 개량을 추진하는 체제 및 조직을 효율화하고 강화하고 있는 것을 볼 수 있는데 이 귀표를 쓰는 개체식별 시스템의 확립이 크게 공헌하고 있는 것 같다. 화란에서는 처음부터 혈통등록, 교배, 우군검정, 진료등 낙농가에서 발생하는 모든 정보가 그림 20과 같이 NRS(농협조직)에서 전산관리되고 있다. 그 기초가 되는 것이 개체 코 - 드이며, 개체식별 시스템이다.</dd>
				</dl>
				<div className="imgBox"><img src={Pic810} alt=''/></div>
				<p>개체식별은 수정기록부터 출발한다. 수정기록은 인공수정사가 가진 핸디터미날(노트북 전산기)등을 통하여 NRS로 보내진다. NRS에서는 낙농가로부터의 주문을 받아서, 업자를 통하여 귀표(그림21)를 송부한다. 귀표는 한쪽 이표 2장형태의 것으로 양쪽귀에 장착하기 때문에 4매 1조가 되어 있다.</p>
				<div className="imgBox"><img src={Pic811} alt=''/></div>
				<p>개체 코 - 드는 9개단위이지만, 최후1개단은 그 앞의 8개단에 대응한 체크용으로 되어 있다. 귀표를 보면, 하단에 4단위의 숫자가 크게 쓰여 있으나, 이것은 개체 코 - 드의 5개단째부터 8개단을 귀표번호로 한 것을 확대한 것이다.</p>
				<p>또한 낙농가에는 귀표와 동시에, 개체 코 - 드가 기재된 출생보고 카 - 드(그림22)가 송부된다. 낙농가는 분만하면 송아지에 귀표를 장착하고, 이 카 - 드에 농가 코 - 드등 필요사항을 기입한다. 그리하여 상단에 기재되어 있는 번호에 전화하면 자동음성의 질문에 화답하는 형태로 카 - 드를 보면서 전화의 버튼을 누르는 것으로 필요사항을 입력해 나간다. 아비소등은 수정기록으로 이미 데이터베이스(DB)에 있기 때문에 보고할 필요가 없다. 입력된 정보는 기존의 정보와 맞추어보고서 어미소의 소유자와 재태일수등에 모순이 없으면 개체등록이 완료된다. 출생보고는 PC로도 가능하며, 현재 낙농가의 약 40%가 전화에 의한 자동응답시스템, 약 20%가 PC를 통한 보고로 되고 있다.(나머지 40%는 농협드의 직원에게 유료로 의뢰함.)</p>
				
				<dl>
					<dt>&lt;그림 23&gt; 화란에서 쓰이고 있는 개체등록 카드</dt>
					<dd>
						<div className="imgBox"><img src={Pic812} alt=''/></div>
						<ul>
							<li>① 보고장소의 전화번호</li>
							<li>② 개체코-드</li>
							<li>③ 귀표번호</li>
							<li>④ 농가코드</li>
							<li>⑤ 성별</li>
							<li>⑥ 분만월일(보고일 몇일전)</li>
							<li>⑦ 어미소의 개체코드</li>
							<li>⑧ 1주간이내의 이동의 유무</li>
							<li>⑨ 명호</li>
							<li>⑩ 혈통등록의 유무</li>
							<li>⑪ 털색</li>
							<li>⑫ 특징</li>
							<li>⑬ 보고일<br/><p>(실물18×10.5cm) </p></li>
						</ul>
					</dd>
					<dd>
						<div className="imgBox"><img src={Pic813} alt=''/></div>
						<ul>
							<li>① 농가성명.주소</li>
							<li>② 개체코드</li>
							<li>③ 명호</li>
							<li>④ 홀스타인(HF)의 비율</li>
							<li>⑤ 아비소</li>
							<li>⑥ 어미소</li>
							<li>⑦ 귀표의 복사
								<p></p>
							</li>
						</ul>
					</dd>
				</dl>
				
				<p>낙농가로부터의 출생보고후, NRS로부터는 개체등록 카 - 드(그림23)가 낙농가에 송부된다. 반문은 필요하면 낙농가가 기입한다. 그 후 이 개체의 정보는 모든 새로운 개체 코 - 드에 기초한 NRS정보시스템에서 관리된다. 또한 귀표가 붙은 개체는 반문등의 특징에 의하지 않고, 개체 코 - 드로 식별 및 확인하게 된다.</p>
				<p>낙농가에는 NRS정보시스템에 의하여 여러 가지 정보가 제공된다. 장표(帳票)뿐 아니라, PC를 가지고 있으면, 데이터베이스에 접근하여, 정보의 인출이 가능해진다.</p>
				<p>정보는 본래 낙농가의 것이다. 그렇지만 내용에 의하여 별도로 관리되는 것은 부자연할뿐 아니라, 또한 비효율적이다. 일본에서도 귀표를 쓰는 개체식별시스템에 대한 검토가 시작되었지만, 정보의 통합까지를 시야에 넣은 검토가 기대되고 있다고 한다.</p>
			</div>
  );
}

export default Fo3;
