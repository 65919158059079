import React from 'react';

import pic758 from 'assets/images/sub/_images/pic758.jpg';
import pic759 from 'assets/images/sub/_images/pic759.jpg';
import pic760 from 'assets/images/sub/_images/pic760.jpg';

function Do163() {

  return (
    <div className="techinfoview">
      <h1>소형 파이로플라즈마병</h1>
      <div className="leftpart">
      <p className="pstyle">소의 적혈구내에 타이레리아 서젠티(T-heileria sergenti)라는 원충이 기생하여 일으키는 질병으로서 소에서 소에게의 전파는 진드기가 매개하기 때문에 [진드기 열]이라고도 불리운다. </p>
        <h2>증상</h2>
        <ul>
          <li>이 원충에 의해 일어나는 가장 특징적인 증상은 발열과 빈혈이다.
            <ul>
              <li>원충에 감염된 1주일 후 40∼42℃로 열이 오르나 이 열은 치료없이도 자연히 회복되며 약 1개월 후 재발열하여 급성 빈혈을 일으킨다. </li>
              <li>이때는 조석(朝夕)간의 온도차가 1℃이상 차이가 나는 이장열(弛張熱)이라 불리는 특징적인 열형(熱型)이 나타난다. </li>
              <li>빈혈이 진행되면 소는 원기와 식욕이 없어지고 동작도 느려진다. </li>
              <li>방목장소에서 발병우의 발견이 늦어지면 빈혈로 폐사하거나 살아나도 빈혈이 계속되어 발육이 늦어진다. </li>
            </ul>
          </li>
        </ul>
        <h2>목장에서의 처치</h2>
        <ul>
          <li>병우를 발견하면 소를 안정시키고 양질사료를 충분히 급여토록 한다. </li>
          <li>장거리 이동을 절대로 금해야 한다. </li>
          <li>갑작스런 기상변화등에 대해 스트레스를 받지 않도록 주의해야 한다. </li>
          <li>빈혈이 심할때는 건강한 소의 혈액 2∼3l를 수혈한다. </li>
          <li>시중의 치료제제를 사용하여 치료를 할 수 있다.</li>
        </ul>
      </div>
      <div className="rightpart">
        <img src={pic758} className="nonborder" alt=""/>
        <img src={pic759} className="nonborder" alt=""/>
        <img src={pic760} className="nonborder" alt=""/>
      </div>
    </div>
  );
}

export default Do163;