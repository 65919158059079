import React from 'react';

import Pic568 from 'assets/images/sub/_images/pic568.jpg';
import Pic569 from 'assets/images/sub/_images/pic569.jpg';
import Pic570 from 'assets/images/sub/_images/pic570.jpg';

function Do106() {

  return (
    <div className="techinfoview">
      <h1>소 전염성 비기관염</h1>
      <p className="pstyle"></p>
      <div className="leftpart">
        <h2>원인</h2>
        <ul>
          <li>소의 전염성 비기관염은 IBR(infectious bovine rhinotracheitis)바이러스에 의해 상부기도(上部氣道)에 염증을 일으키는 급성호흡기 전염병이다. 모든 소에 감염하지만 육성우가 감염되면 피해가 크다. 일명 IBR이라 한다. </li>
          <li>일단 감염된 소는 타액과 콧물, 내쉬는 숨 등에 바이러스가 함유되어 있고 회복되어도 수개월간 바이러스를 보유 배설한다. </li>
        </ul>
        <h2>증상</h2>
        <ul>
          <li>감염후 2∼4일 40℃이상의 고열을 나타낸다. </li>
          <li>습한 기침과 호흡이 가빠지고 침을 흘리며 끈적한 콧물을 배출한다. </li>
          <li>증상이 심하지 않으면 대부분의 소에서는 10∼14일 정도로 회복한다. </li>
          <li>중증일때는 콧등과 콧구명에 궤양이 생기고 고름과 같은 콧물이 나오며 호흡이 곤란해진다.</li>
          <li>세균의 2차감염이 일어나면 폐염을 병발하므로 회복이 늦어진다. </li>
          <li>임신우가 감염이 되면 3개월이내 유산하는 일이 있으며 그 비율은 2∼20%이고, 감염우의 사망률은 1∼3%정도이다. </li>
        </ul>
        <h2>목장에서의 처치</h2>
        <ul>
          <li>IBR이라고 의삼하는 소가 발견되면 즉각 격리시켜 다른소와 접촉하지 못하게 한다. </li>
          <li>IBR의 감염에 의한 피해를 줄이기 위해서는 백신접종을 통하여 사전에 면역 능력을 갖추도록 해야 한다. </li>
          <li>IBR에 대한 유효한 치료약은 개발되어 있지 않으며 세균의 2차감염을 억제하기 위해 항생물질(테트라 싸이클린 등)의 투여가 요망된다.</li>
        </ul>
      </div>
      <div className="rightpart">
        <img src={Pic568} className="nonborder" alt=""/>
        <img src={Pic569} className="nonborder" alt=""/>
        <img src={Pic570} className="nonborder" alt=""/>
      </div>
    </div>
  );
}

export default Do106;