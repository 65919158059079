import React, { useState , useEffect } from 'react';
import {
  ResponsiveContainer,
  ReferenceLine,
  ScatterChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Scatter,
  Cell
} from 'recharts';

// api
import { postState } from 'utils/api';
import { openCrownix2,openCrownix6 } from 'utils/openCrownix';

// 이미지
import PrintPDF from 'assets/svgs/common/print_PDF.svg';

// 컴포넌트
import ErrorMessage from 'components/errorMessage/errorMessage';
import MonthCode from 'components/dcitest/monthCode/MonthCode';

// scss
import './NewGraphMun01.scss';

function NewGraphMun01() {

  // 에러처리
  const [isModalOpen, setIsModalOpen] = useState(false); // 모달 추가
  const [errorMessage, setErrorMessage] = useState(''); 

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => {
    setIsModalOpen(false);
    setErrorMessage(''); // 모달이 닫힐 때 에러메세지 초기화
  };

  // 데이터 불러오기
  const baseDir='/chart/new_graph_mun/';
  const [ mainData , setMainData ] = useState();


  const [ props , setProps] = useState({
    id:`${localStorage.getItem('cong')}${localStorage.getItem('farm')}`,
    passwd:localStorage.getItem('passwd'),
    date:localStorage.getItem('month')
  });

  
  useEffect(() => {

    postState(baseDir,props)
    .then(getdata => {      
      if (getdata !== undefined) {
      setMainData(getdata);
      }
    })
    .catch(error => {           
      setErrorMessage(error.message);
      openModal();
    });
    
  }, [props]);

  // 차트
  const COLORS = ['black','red','gren','yellow','blue','gray','purple','orange','brown'];
  const classifyNumber = (x) => {
    // 기준이 몬지 몰르겟네..
    if (x.gu1) return 0;
    if (x.gu2) return 1;
    if (x.gu3) return 2;
    if (x.gu4) return 3;
    if (x.gu5) return 4;
    if (x.gu6) return 5;
    if (x.gu7) return 6;
    if (x.gu8) return 7;
    if (x.gu9) return 8;
    return 0;    
  };
  const CustomClick = (e) => {
    openCrownix2('New_ch_cow1.mrd',e.cowid , e.pari);
    // alert(e.sname2);
    };
  const gu1 = mainData && mainData.plot_data
    .filter(item => item.gu1 !== null);
  return (
    <div className='pdfMainArea'>
      <ErrorMessage isOpen={isModalOpen} content={errorMessage} closeModal={closeModal}/>
      <div className='pdfMainHeader'>
        <button 
          type='button'
          onClick={() => openCrownix6('New_graph_mun_01.mrd')}
        >
          <img src={PrintPDF} alt='PDF로 인쇄하기' />
          인쇄하기
        </button>
        {
          props && 
          <MonthCode 
            props={props}
            setProps={setProps}
            setErrorMessage={setErrorMessage}
            openModal={openModal}
          />
        }
      </div>
      <div className='pdfArea'>
        <div className='newGraphMun01'>
          <div className='graphHead'>
            <span>{props.date.substring(0,4)}년 {props.date.slice(-2)}월 그래프</span>
            <ul>
              <li>
                <button type='button' onClick={()=> openCrownix6('New_graph_mun2.mrd')}>
                  MUN : 유단백 보기
                </button>
              </li>
              <li>
                <button type='button' onClick={() => openCrownix6('New_mun_27info.mrd')}>
                  상세정보보기  
                </button>
              </li>
              <li>
                <button type='button' onClick={() => openCrownix6('New_graph_mun_4m.mrd')}>
                  4개월 보기
                </button>
              </li>
            </ul>
          </div>
          <h1>비유일수별 MUN 분포도</h1>
          <div className='dotChartArea' >
            <ResponsiveContainer width="100%" height={400}>
              <ScatterChart
                width="90%"
                height="85%"
                margin={{              
                  top: 20,
                  right: 20,
                  bottom: 20,
                  left: 20,
                  }}>
                <CartesianGrid />
                <XAxis
                  dataKey="cumday"
                  type='number'
                  label={{ value: '비 유 일 수 [일]', position: 'bottom' }}
                />
                <YAxis
                  dataKey="mun"
                  type='number'
                  label={{ value: 'MUN (MG / DL)', position: 'insideLeft', offset: '-10', angle: -90 }}
                  domain={[dataMin => Math.floor(dataMin)-2, 30]}
                />
                <Tooltip cursor={{ strokeDasharray: '3 3' }} />
                <Scatter data={mainData && mainData.plot_data} fill="green" onClick={CustomClick}>
                  {
                    mainData &&
                    mainData.plot_data.map((entry) => (
                      <Cell key={`cell-${entry.cumday+entry.mun+entry.z}`} fill={COLORS[classifyNumber(entry)]}/>
                    ))
                  }
                </Scatter>
                <ReferenceLine x={70} stroke="green" />
                <ReferenceLine x={150} stroke="green" />
                <ReferenceLine y={12} stroke="red" />
                <ReferenceLine y={18} stroke="red" />
              </ScatterChart>
            </ResponsiveContainer>
            <ul className='Mun01Label'>
              <li>조단백부족, 에너지과잉</li>
              <li>에너지양호</li>
              <li>조단백과잉, 에너지과잉</li>
              <li>조단백부족, 약간에너지과잉</li>
              <li>조단백균형, 에너지균형</li>
              <li>조단백과잉, 약간에너지부족</li>
              <li>조단백부족, 에너지부족</li>
              <li>에너지부족</li>
              <li>조단백과잉, 에너지부족</li>
            </ul>
          </div>
          <table className='pdfTable'>
            <thead>
              <tr>
                <th>분만후일수 &#40;일&#41;</th>
                <th>유단백</th>
                <th>MUN</th>
                <th>내용</th>
                <th>해당개체 - 개체명 &#40;단백율/MUN&#41;</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td rowSpan='9'>0~45</td>
                <td rowSpan='3'>~3.0</td>
                <td>~ 12</td>
                <td>단백 또는 DIP 부족</td>
                <td>{mainData && mainData.plot_data.filter(item => item.gu7 !== null && item.cumday <= 45).map((entry) => (`${entry.sname2} ,`))}</td>
              </tr>
              <tr>
                <td className='tdBorderLeft'>12 ~ 18</td>
                <td>SIP와 DIP비율적절, NFC부족</td>
                <td>{mainData && mainData.plot_data.filter(item => item.gu8 !== null).filter(item => item.cumday <= 45).map((entry) => (`${entry.sname2} ,`))}</td>
              </tr>
              <tr>
                <td className='tdBorderLeft'>18 ~</td>
                <td>NFC에 비해 SIP, DIP 과잉, 아미노산균형불량</td>
                <td>{mainData && mainData.plot_data.filter(item => item.gu9 !== null).filter(item => item.cumday <= 45).map((entry) => (`${entry.sname2} ,`))}</td>
              </tr>
              <tr>
                <td rowSpan='3' className='tdBorderLeft'>3.0<br/>~<br/>3.2</td>
                <td>~ 12</td>
                <td>NFC에 비해 DIP 부족</td>
                <td>{mainData && mainData.plot_data.filter(item => item.gu4 !== null && item.cumday <= 45).map((entry) => (`${entry.sname2} ,`))}</td>
              </tr>
              <tr>
                <td className='tdBorderLeft'>12 ~ 18</td>
                <td>DIP, RUP 및 아미노산의 균형양호</td>
                <td>{mainData && mainData.plot_data.filter(item => item.gu5 !== null && item.cumday <= 45).map((entry) => (`${entry.sname2} ,`))}</td>
              </tr>
              <tr>
                <td className='tdBorderLeft'>18 ~</td>
                <td>DIP 과잉, 아미노산, NFC 균형양호</td>
                <td>{mainData && mainData.plot_data.filter(item => item.gu6 !== null && item.cumday <= 45).map((entry) => (`${entry.sname2} ,`))}</td>
              </tr>
              <tr>
                <td rowSpan='3' className='tdBorderLeft'>3.2 ~</td>
                <td>~ 12</td>
                <td>DIP부족, 아미노산 양호, NFC 과잉</td>
                <td>{mainData && mainData.plot_data.filter(item => item.gu1 !== null && item.cumday <= 45).map((entry) => (`${entry.sname2} ,`))}</td>
              </tr>
              <tr>
                <td className='tdBorderLeft'>12 ~ 18</td>
                <td>DIP, RUP 및 아미노산의 균형양호, NFC 과잉</td>
                <td>{mainData && mainData.plot_data.filter(item => item.gu2 !== null && item.cumday <= 45).map((entry) => (`${entry.sname2} ,`))}</td>
              </tr>
              <tr>
                <td className='tdBorderLeft'>18 ~</td>
                <td>DIP 과잉, 아미노산균형양호, NFC 과잉</td>
                <td>{mainData && mainData.plot_data.filter(item => item.gu3 !== null && item.cumday <= 45).map((entry) => (`${entry.sname2} ,`))}</td>
              </tr>
              <tr>
                <td rowSpan='9'>46~150</td>
                <td rowSpan='3'>~3.0</td>
                <td>~ 12</td>
                <td>단백 또는 DIP 부족</td>
                <td>{mainData && mainData.plot_data.filter(item => item.gu7 !== null && item.cumday > 45 && item.cumday <=150 ).map((entry) => (`${entry.sname2} ,`))}</td>
              </tr>
              <tr>
                <td className='tdBorderLeft'>12 ~ 18</td>
                <td>SIP와 DIP,RUP및 아미노산의 균형 양호</td>
                <td>{mainData && mainData.plot_data.filter(item => item.gu8 !== null && item.cumday > 45 && item.cumday <=150 ).map((entry) => (`${entry.sname2} ,`))}</td>
              </tr>
              <tr>
                <td className='tdBorderLeft'>18 ~</td>
                <td>NFC에 비해 SIP, DIP 과잉, 아미노산균형불량</td>
                <td>{mainData && mainData.plot_data.filter(item => item.gu9 !== null && item.cumday > 45 && item.cumday <=150 ).map((entry) => (`${entry.sname2} ,`))}</td>
              </tr>
              <tr>
                <td rowSpan='3' className='tdBorderLeft'>3.0<br/>~<br/>3.2</td>
                <td>~ 12</td>
                <td>DIP, SIP 부족</td>
                <td>{mainData && mainData.plot_data.filter(item => item.gu4 !== null && item.cumday > 45 && item.cumday <=150 ).map((entry) => (`${entry.sname2} ,`))}</td>
              </tr>
              <tr>
                <td className='tdBorderLeft'>12 ~ 18</td>
                <td>단백/NFC 비율양호</td>
                <td>{mainData && mainData.plot_data.filter(item => item.gu5 !== null && item.cumday > 45 && item.cumday <=150 ).map((entry) => (`${entry.sname2} ,`))}</td>
              </tr>
              <tr>
                <td className='tdBorderLeft'>18 ~</td>
                <td>DIP 과잉, 아미노산 균형 불량</td>
                <td>{mainData && mainData.plot_data.filter(item => item.gu6 !== null && item.cumday > 45 && item.cumday <=150 ).map((entry) => (`${entry.sname2} ,`))}</td>
              </tr>
              <tr>
                <td rowSpan='3' className='tdBorderLeft'>3.2 ~</td>
                <td>~ 12</td>
                <td>DIP부족, NFC 과잉</td>
                <td>{mainData && mainData.plot_data.filter(item => item.gu1 !== null && item.cumday > 45 && item.cumday <=150 ).map((entry) => (`${entry.sname2} ,`))}</td>
              </tr>
              <tr>
                <td className='tdBorderLeft'>12 ~ 18</td>
                <td>단백, 아미노산균형, NFC 양호</td>
                <td>{mainData && mainData.plot_data.filter(item => item.gu2 !== null && item.cumday > 45 && item.cumday <=150 ).map((entry) => (`${entry.sname2} ,`))}</td>
              </tr>
              <tr>
                <td className='tdBorderLeft'>18 ~</td>
                <td>NFC 섭취량에 비해 SIP, DIP 과잉</td>
                <td>{mainData && mainData.plot_data.filter(item => item.gu3 !== null && item.cumday > 45 && item.cumday <=150 ).map((entry) => (`${entry.sname2} ,`))}</td>
              </tr>

              <tr>
                <td rowSpan='9'>150이상</td>
                <td rowSpan='3'>~3.0</td>
                <td>~ 12</td>
                <td>DIP/SIP 비율이 낮음, NFC 부족</td>
                <td>{mainData && mainData.plot_data.filter(item => item.gu7 !== null && item.cumday > 150).map((entry) => (`${entry.sname2} ,`))}</td>
              </tr>
              <tr>
                <td className='tdBorderLeft'>12 ~ 18</td>
                <td>SIP와 DIP양호 아미노산균형 불량</td>
                <td>{mainData && mainData.plot_data.filter(item => item.gu8 !== null && item.cumday > 150).map((entry) => (`${entry.sname2} ,`))}</td>
              </tr>
              <tr>
                <td className='tdBorderLeft'>18 ~</td>
                <td>NFC에 비해 SIP, DIP 과잉, 아미노산균형불량 또는 NFC 부족</td>
                <td>{mainData && mainData.plot_data.filter(item => item.gu9 !== null && item.cumday > 150).map((entry) => (`${entry.sname2} ,`))}</td>
              </tr>
              <tr>
                <td rowSpan='3' className='tdBorderLeft'>3.0<br/>~<br/>3.2</td>
                <td>~ 12</td>
                <td>NFC에 비해 DIP/SIP 비율이 낮음</td>
                <td>{mainData && mainData.plot_data.filter(item => item.gu4 !== null && item.cumday > 150).map((entry) => (`${entry.sname2} ,`))}</td>
              </tr>
              <tr>
                <td className='tdBorderLeft'>12 ~ 18</td>
                <td>SIP,DIP양호, 아미노산균형양호</td>
                <td>{mainData && mainData.plot_data.filter(item => item.gu5 !== null && item.cumday > 150).map((entry) => (`${entry.sname2} ,`))}</td>
              </tr>
              <tr>
                <td className='tdBorderLeft'>18 ~</td>
                <td>DIP 과잉, 아미노산 일부가부족, NFC 양호</td>
                <td>{mainData && mainData.plot_data.filter(item => item.gu6 !== null && item.cumday > 150).map((entry) => (`${entry.sname2} ,`))}</td>
              </tr>
              <tr>
                <td rowSpan='3' className='tdBorderLeft'>3.2 ~</td>
                <td>~ 12</td>
                <td>아미노산균형양호, NFC 부족</td>
                <td>{mainData && mainData.plot_data.filter(item => item.gu1 !== null && item.cumday > 150).map((entry) => (`${entry.sname2} ,`))}</td>
              </tr>
              <tr>
                <td className='tdBorderLeft'>12 ~ 18</td>
                <td>아미노산균형 및 NFC 양호</td>
                <td>{mainData && mainData.plot_data.filter(item => item.gu2 !== null && item.cumday > 150).map((entry) => (`${entry.sname2} ,`))}</td>
              </tr>
              <tr>
                <td className='tdBorderLeft'>18 ~</td>
                <td>NFC 섭취량에 비해 SIP, DIP 과잉</td>
                <td>{mainData && mainData.plot_data.filter(item => item.gu3 !== null && item.cumday > 150).map((entry) => (`${entry.sname2} ,`))}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      
    </div> 
  );
}

export default NewGraphMun01;
