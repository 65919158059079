import React from 'react';

import Figure2by1 from 'assets/images/sub/_images/figure_2_1.gif';
import Figure2by2 from 'assets/images/sub/_images/figure_2_2.gif';
import Figure2by3 from 'assets/images/sub/_images/figure_2_3.gif';

function Fo11() {

  return (
    <div className="techinfo_view">
				<p className="viewTitle">발정관찰과 인공수정 - 서론, 발정관찰</p>
				<h2>서론</h2>
				<p>번식효율은 우수한 우군의 측면에서 아주 중요한 요인중의 하나이다. 이러한 많은 요인들은 분만간격, 건유기간, 초산연령, 수정횟수 등이 소의 번식과 밀접한 관계가 있으며, 또한 도체율도 우수한 우군의 형성에 영향을 미친다. 번식이 지연됨으로서 다음과 같은 많은 경제적 손실이 발생한다.</p>
				<ul className="mg60f">
					<li>1) 일생동안 우유의 생산량이 감소된다.
						<ul className="listStyle04 mg10f">
							<li>우유 생산량이 최고치에 도달하지 못한다.</li>
							<li>건유기간이 연장된다.</li>
						</ul>
					</li>
					<li>2) 연간 송아지 생산두수가 줄어든다.
						<ul className="listStyle04 mg10f">
							<li>우유생산량이 낮은 소의 도태기회가 줄어든다.</li>
							<li>유전적 진보가 늦어진다.</li>
						</ul>
					</li>
					<li>3) 육종과 진료비 등의 직접비용이 증가한다.</li>
				</ul>
				
				<h2>발정관찰</h2>
				<dl>
					<dt>발정관찰의 중요성</dt>
					<dd>
						<p>대부분의 농민들은 고능력우를 갖기를 원한다. 그러나, 번식은 소의 능력을 좌우하는 중요한 요인이며, 일생동안 비유능력은 축적된 비유능력에 의해 결정된다. 이런 능력을 최대화 하기 위해서는 분만 후 80-90일 이내에 인공수정을 해야한다. 이것은 송아지를 생산하고 12.5-12.8개월 동안 새롭게 비유할 수 있다는 것을 의미한다. 분만간격기 길수록 우유생상에 불리하다.<br/>
						대부분의 농장에서는 수소를 이용항 수정보다 인공수정으로 번식을 시키는 것이 보편적인 추세이다. 여전히 나농가에서는 발정관찰과 인공수정이 불가능한 상황에서는 수소를 이용하여 번식을 한다.<br/>
						인공수정에 의한 효율적인 번식관리와 우수한 우군을 확보하기 위한 번식계획에서는 정확한 발정관찰이 필수적이다. 정확한 발정관찰을 위해서는다음과 같은 사항들은 아주 중요하다.</p>
						<ul className="listStyle04">
							<li>15개월령의 미경산우를 번식에 이용한다.</li>
							<li>평균분만간격을 12.5-12.8개월 유지한다.</li>
							<li>우수한 종무우를 이용한 유전적 진보(genetic progress)를 극대화 시킨다.</li>
						</ul>
					</dd>
				</dl>
				
				<dl>
					<dt>발정징후 </dt>
					<dd>소에서 발정관찰은 정확성이 요구되어지며, 대부분의 소들은 발정초기에서 말기까지 그 징후들이 비슷하다. 이러한 행동의 변화들은 현재 소가 발정초기 또는 말기인지를 파악할 수 있는 결정적이다. 어떤 소들은 발정징후가 아주 뚜렷하지만, 일부는 미미한 징후만을 나타내기도 한다. 게다가 계류장치(프리스톨, 타이스톨)와 날씨는 발정징후를 강하게 하기도한다. </dd>
				</dl>
				
				<dl>
					<dt>초기 발정징후</dt>
					<dd>발정초기에는 신경질적인 반응을 나타내거나 불안해하며 보행을 하기도 하며, 침착하게 누워 있기도 한다. 용모자세발정(standing estrus)에 도달하기전에는 대부분의 소들은 울기도한다. 목장의 경우에는 발정이 오면 우군으로부터 떨어져 있으며, 울타리를 따라 걸어다니며, 꼬리를 흔들면서 뛰어 다니기도 한다. 발정이 온 소들은 다른 소에 승가를 한다.<br/>
					또한, 발정이 오면 생식기관의 혈류속도가 빨라지는 것은 외음부가 약간 부풀어 오르고 분홍색으로 변하기 때문이다. </dd>
				</dl>
				
				<dl>
					<dt>용모자세발정</dt>
					<dd>
						<div className="imgBox"><img src={Figure2by1} alt=""/></div>
						<p>승가를 허용하고 발정이 지속되는 것을“ 용모자세발정(standing estrus)”이라고 하는데 이시기는 교미허용이 되는 징후이기도 하다. 소들은 대부분 이 시기에 교미를 허용하지만, 일부는 수소의 체중 때문에 피하기도 한다.</p>
						<p>발정지속시간은 경산우와 미경산우간의 개체차이에 따라 다양한데, 그 범위는 6∼30시간이며, 평균지속시간은 16시간이다. 빈번한 승가행위는 고려해 보아야 하는데, 기온이 낮거나, 다른 소가 있을 경우는 승가행위가 증가며, 기온이 높을 경우에는 이런 행위는 제한적이다. 다리나 발이 불편거나 운동장이 경사가 지면, 불안해서 승가를 하지 않는 경우도 있다.</p>
						<p>자궁경관이나 질에서 흰 점액이 분비되는데, 외음부를 통하여 흘러 내리기도 하며 꼬리에 묻어 있을 때도 있다. 때때로 타이 스톨의 우사에서 소들은 점액이 우사바닥에 저류되어 있기도 한다. </p>
					</dd>
				</dl>
				
				<table className="tablestyle mg30f">
					<caption>표 2.1. 유우의 발정징후 </caption>
					<tbody>
						<tr>
							<th className="left">용모자세발정</th>
						</tr>
						<tr>
							<td>
								<ul className="listStyle04 mg10f">
									<li>승가를 하면 움직이지 않고 서 있다.</li>
									<li>징후는 발정초기와 말기와 연관이 있다.</li>
								</ul>
							</td>
						</tr>
						<tr>
							<th className="left">발정초기와 말기</th>
						</tr>
						<tr>
							<td>
								<ul className="listStyle04 mg10f">
									<li>수소처럼 고함을 지른다.</li>
									<li>신경질적이다.</li>
									<li>공격적이다. 머리와 머리를 대고 빈번히 서로 응시한다.</li>
									<li>다른소의 옆을 밀기도 한다.</li>
									<li>다른소의 외음부나 오증을 보고 킁킁거린다.</li>
									<li>서로 원을 그리면서 베회하거나, 발정온소는 다른소의 등에 턱을 대고 앉아 있기도 한다.</li>
									<li>외음부는 분홍색이며 부풀어 오르며 점액이 분비된다.</li>
								</ul>
							</td>
						</tr>
						<tr>
							<th className="left">부수적인 징후</th>
						</tr>
						<tr>
							<td>
								<ul className="listStyle04 mg10f">
									<li>식욕과 우유생산량이 줄어든다.</li>
									<li>소가 더러워 진다.</li>
								</ul>
							</td>
						</tr>
					</tbody>
				</table>
				
				<dl>
					<dt>발정후기의 징후</dt>
					<dd>소는 더 이상 승가는 하지 않지만 발정초기의 징후는 지속된다. 꼬리상단의 털은 거칠고, 문질러져서 없어진 부분도 있다. </dd>
				</dl>
				
				<dl>
					<dt>발정후기(발정후출혈)</dt>
					<dd>발정후출혈은 소의 꼬리 외음부에서 관찰되는 생식기관으로부터의 출혈이다. 출혈은 standing estrus후 1∼3일 사이에 일어나며, 소의 교미 또는 임신여부에 관계없이 일어난다. 만약 발정이 관찰되지 않았는데 발정후 출혈이 일어난다면, 교미가 늦어서 일어나는 현상일 것이다. 이런 경우에는 18-19일 후에 발정이 예상되므로 출혈날짜를 기록하여 두는 것이 바람직하다. </dd>
				</dl>
				
				<dl>
					<dt>발정관찰의 효율</dt>
					<dd>울타리나 편의 시설에서 사육을 한다면 다른것에 비한다면 발정관찰을 쉽게 할 수 있다. 예를들면, 프리스톨에서는 승가행위를 쉽게 관찰 할 수 있지만, 스탄치온 우사에서는 그렇지 못하다. 발정현상을 억제하는 원인들은 불편함과 불안에 기인된다. 이러한 요인들에는 고온과 습도, 바람, 비, 눈 등의 자연작인 요인과 미끄럼, 제한된 공간 등도 있다. 발정의 확실한 정후는 다른 소에 의한 승가허용이라고 할 수 있다. 우유생산량의 감소와 식욕감퇴는 여러 가지 복합적인 요인들에 의한 결과이다.</dd>
				</dl>
				
				<div className="imgBox"><img src={Figure2by2} alt=""/></div>
				
				<dl>
					<dt>발정징후에 있어서 일일동향 </dt>
					<dd>
						<p>발정개시는 뚜렷한 양상을 나타내고 있는데, 저녁 늦게 많이 일어나고 밤과 아침 일찍 일어나는 경향이 뚜렷하다. 많은 연구자들의 보고에 의하면, 승가행위의 빈도는 낮에는 적고 밤에 많이 나타난다. 실질적으로 약 70％의 승가가 오후 7시에서 오전 7시 사이에 일어 난다(그림 2.2).</p>
						<p>발정우의 승가는 약 40％가 아침 7∼8시 사이에 관찰되었다(그림 2.3). 그러나 발정을 관찰할 수 있는 기회는 정오에 현저히 감소되었다. 10％이하의 소만이 오후 1∼2시 사이에 발정이 관찰되었다. 그러나 저녁과 밤에는 발정을 관찰할 수 있는 기회가 없다. 소가 승가를 하는 시간 범위는 3∼30시간이다. 발정지속시간이 짧은 소는 이른 밤에 발정이 오므로 다음날 아침에는 발정을 관찰할 수 없는 경우가 많다. 우군에서 90％ 이상의 발정을 확인하려면 이른 아침, 늦은 저녁시간 그리고 하루 4∼5시간 간격으로 세심하게 관찰하여야 한다.</p>
					</dd>
				</dl>
				
				<div className="imgBox"><img src={Figure2by3} alt=""/></div>
				
				<dl>
					<dt>발정확인상의 문제점</dt>
					<dd>
						<p>대부분의 농장에서 성공적인 발정관찰을 하기는 아주 어렵다. 다음과 같은 이유로 인하여 발정관찰하기가 어렵다.</p>
						<ul className="listStyle04 mg15f">
							<li>소가 임신을 한 경우</li>
							<li>소가 분만후 발정이 재귀되지 않았을 경우</li>
							<li>영양결핍, 세균감염, 그외의 원인으로 발정휴지기인 경우 </li>
							<li>난소낭종과 같은 질병인 경우</li>
							<li>미약발정후 배란된 경우</li>
							<li>발정기에 발정관찰을 실패한 경우</li>
						</ul>
						<p>이러한 이유로 발정관찰을 하지 못했을 경우는 경제적인 이익의 손실이 초래되므로 소에 대한 번식관련 기록(분만일자, 분만후 첫 발정일 등)은 정확하게 기록하는 것은 번식률을 향상시키는데 필수적인 요인이 된다. 분만 후 60일이 되어도 발정이 관찰되지 않는 소는 수의사에게 문의하여 대책을 세워야 할 것이다. 발정주기에 1번의 발정인 온 소는 임신이 될 때까지 21일마다 발정이 재귀된다.</p>
					</dd>
				</dl>
				
				<dl>
					<dt>임신우에서 가발정</dt>
					<dd>정상적으로 임신한 소는 발정이 오지 않는다. 임신이 유지되기 위해서는 황체에서 프로게스테론(progesterone)이 분비되고 발정주기를 억제하게 된다. 그러나 약 5％의 임신한 소(경산우)와 미경산우는 다른소가 승가를 하면 허용한다.</dd>
				</dl>
				
				<dl>
					<dt>미약발정</dt>
					<dd>미약발정은 발정징후가 없이 배란이 일어나는데, 미경산우에서 첫 발정은 보통 미약발정이 일어난다. 경산우의 경우는 분만후 첫 배란은 10∼15일 사이에 일어난다. 일반적으로 첫 발정은 발정징후와는 상관이 없이 일어난다. 그러나 발정징후는 일반적으로 분만후 25∼40일 사이에 배란이 일어났을 때 나타난다.</dd>
				</dl>
				
				<dl>
					<dt>무발정</dt>
					<dd>무발정은 발정 징후가 일어나지 않은 상태를 말한다. 부적절한 영양수준과 분만후 자궁의 감염이 무발정의 두 가지 중요한 원인이다. 게다가 난산, 후산정체로 정상작인 난소의 기능의 재귀가 지연되기 때문이다.</dd>
				</dl>
				
				<dl>
					<dt>난소낭종</dt>
					<dd>난소표면에 존재하는 난포의 발달이 원할하지 않으면 난자의 배란장애가 수반된다. 난소에는 성숙난포가 존재하는데, 난소질병의 원인은 현재까지 정확하게 알 수가 없다. 그러나 연구자들은 난소낭종을 두가지로 분류하는데 난소낭종과 황체낭종으로 구분하고 있다. <br/>
					난소낭종을 가진 소들은 발정이 지속되며, 정상적인 발정주기후에 일어난다. GnRH를 주사하면 효과적으로 치료를 할 수가 있다. GnRH는 LH(황체호르몬)을 분비하여 난소낭종을 파열시키는 역할을 한다.<br/>
					난소낭종을 가진 소와 대조적으로 황체낭종을 가진 소는 발정의 발현이 되지 않는다. 황체낭종은 난포가 파열되지 않고 황체화되어 중심부에 강이 형성되어 액체가 고여 있는 상태를 말한다. 치료방법으로는 프로스타그란딘(prostagladins)을 처리하여 황체조직을 퇴회시킴으로서 치료가 가능하다.<br/>
					난소낭종을 가진 30∼70％의 소는 치료없이 자연적으로 회복이 된다. 낭종은 때때로 다른 낭종이 형성됨으로서 자연적으로 치료되는 경우도 있다.</dd>
				</dl>
				
				<dl>
					<dt>발정관찰의 정확성 </dt>
					<dd>
						<p>분만후 60일이 경과하면, 몇몇 소들은 디음과 같은 이유로 인하여 발정관찰이 되지 않는다.</p>
						<ul className="listStyle04 mg15f">
							<li>소는 정상적인 난소기능이 수행되지 않으면서 무발정인 경우</li>
							<li>발정기인 소를 발정관찰을 실패한 경우</li>
						</ul>
						<p>Videco recorder를 이용하지 않는다면, 모든 발정관찰이 불가능하다. 연구자들은 일부 소에서 발정징후가 오후 9시와 오전 6시에 일어나지만, 대부분의 소들은 한낮에 발정징후를 보이는데 특히, 이른 아침 또는 늦은 저녁에 일어난다고 한다.</p>
					</dd>
				</dl>
				
				<dl>
					<dt>축사</dt>
					<dd>축사는 날씨가 좋은 날 우군을 자주 관찰하기가 쉬울뿐만 아니라, 발정관찰도 쉽게 할 수가 있었야 한다. 프리스톨의 경우는 동일한 장소에서 발정관찰을 할 수가 있어서 발정관찰에 편리한 시설이다. 수소가 분리되어 있어도 수소가 시각적 또는 아주 근접해 있어도 발정여부를 확인할 수가 있다. 한편으로 소를 방목하거나 계류장치에 의해 계류되어 있을 경우에는 발정관찰이 어렵다.</dd>
				</dl>
				
				<dl>
					<dt>우군의 크기</dt>
					<dd>일정한 시간내에 1마리 이상의 소가 발정이 왔을 때 발정이 온 집단이 구성된다. 우군이 아주 클 경우에는 많은 두수가 발정이 오므로 발정을 관찰할 수 있는 빈도가 증가하게 된다. 3마리의 소가 동시에 발정이 왔을 경우 각각 발정지속기간이 증가한다. 
					</dd>
				</dl>
			</div>
  );
}

export default Fo11;
