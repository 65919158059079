import React from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useQuery, useQueryClient } from '@tanstack/react-query';

// api
import { getState, postState, delState } from 'utils/api';

function UserView() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const aid = searchParams.get('aid');
  const queryClient = useQueryClient();

  const { data } = useQuery({
    queryKey: ['user', aid],
    queryFn: () => getState('/board/select_user', { aid }),
    enabled: !!aid,
  });

  const user = data?.result;

  const getUserStatus = (status) => (status === 0 ? '정상' : '사용불가');
  const getUserMenuAuth = (menuCode) => {
    switch (menuCode) {
      case 0:
        return '관리자';
      case 1:
        return '정액관리';
      case 2:
        return '전문가';
      case 3:
        return '뉴스그룹';
      case 4:
        return '청정육종농가';
      default:
        return '-';
    }
  };

  const handleDelete = async () => {
    if (window.confirm('사용자를 삭제 하시겠습니까?')) {
      try {
        const response = await postState('/board/delete_user/', { aid });
        if (response?.return_code === 'SUCCESS') {
          queryClient.invalidateQueries(['users']);
          alert('삭제되었습니다.');
          navigate(-1);
        }
        if (response?.return_code === 'ERROR') {
          alert(response?.return_message);
        }
      } catch (error) {
        console.error('Delete user error:', error);
        alert('사용자 삭제 중 오류가 발생했습니다.');
      }
    }
  };

  return (
    <div className="user-view bo-content">
      <h2>사용자 정보</h2>
      <div className="table-container bold-border">
        <table>
          <tbody>
            <tr>
              <th width="15%">사용자 아이디</th>
              <td width="35%">{user?.AID}</td>
              <th width="15%">사용자 그룹 아이디</th>
              <td width="35%">{user?.GRP_ID}</td>
            </tr>
            <tr>
              <th>사용자명</th>
              <td>{user?.USR_NAME}</td>
              <th>비밀번호</th>
              <td>{user?.PASS}</td>
            </tr>
            <tr>
              <th>설명</th>
              <td colSpan="3">{user?.USR_DESC}</td>
            </tr>
            <tr>
              <th>상태</th>
              <td colSpan="3">{getUserStatus(user?.USR_STAT)}</td>
            </tr>
            <tr>
              <th>메뉴</th>
              <td colSpan="3">{user?.USR_MENU}</td>
            </tr>
            <tr>
              <th>권한</th>
              <td colSpan="3">{getUserMenuAuth(user?.USR_MENU)}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="button-group">
        <Link
          to={`/bo/user/userEdit?aid=${aid}&grp_id=${user?.GRP_ID}`}
          className="btn-l"
        >
          수정
        </Link>
        <button type="button" onClick={handleDelete} className="btn-l">
          삭제
        </button>
        <button
          type="button"
          onClick={() => navigate(`/bo/user/userList?grp_id=${user?.GRP_ID}`)}
          className="btn-l"
        >
          목록
        </button>
      </div>
    </div>
  );
}

export default UserView;
