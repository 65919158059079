import React from 'react';

import Pic500 from 'assets/images/sub/_images/pic500.jpg';
import Pic501 from 'assets/images/sub/_images/pic501.jpg';

function Do86() {

  return (
    <div className="techinfoview">
				<h1>만성 고창증</h1>
				<p className="pstyle">고창(鼓脹)의 정도는 심하지 않으나 반복, 지속적인 고창증세를 나타내며 생후 6개월령 전후의 육성우에서 많이 볼 수 있다. </p>
				<div className="leftpart">
					<h2>원인</h2>
					<ul>
						<li>식도구의 종양등에 의한 식도구 외측의 압박으로 가스배출이 안될 때 </li>
						<li>창상성 제2위염, 모구(毛球), 비닐조각을 먹었을 때. </li>
						<li>개복수술후의 유착, 횡격막 헤르니아, 유열 후에 속발성으로 나타난다. </li>
						<li>육성우에서는 소화율이 나쁜 거친 조사료의 연속급여로 만성위무력증, 불량발효된 분유등의 급여로 인해 발생한다. </li>
					</ul>
					<h2>증상</h2>
					<ul>
						<li>식욕은 없어지고 복부는 가스가 축적되어 팽대한다. </li>
						<li>포말성 고창증과 달리 청진을 할 때 급속성 고음(鼓音)이 들리는 수가 많다.</li>
						<li>제1위의 운동은 초기에는 항진(亢進)하지만, 점차 미약해져 마침내 위 무력증에 빠진다. </li>
					</ul>
					<h2>목장에서의 처치</h2>
					<ul>
						<li>원인이 분명한 경우는 그것을 제거하는 것이 첫째 방법이다. </li>
						<li>전위(前胃) 무력증에 의할 때는 황산마그네슘 200∼300g을 다량의 물에 녹여서 투여하거나 식물성기름 200∼300ml를 먹인다. </li>
						<li>비닐류의 섭취로 인한 만성고창증이 의심될 때는 개복수술을 하여야만 치료 될 수 있다. </li>
						<li>증상이 가벼울때는 희염산(10%Hcl)30ml+알코홀15ml+크레올린100ml을 수배로 희석하여 먹이면 건위제로서 유효한다.</li>
					</ul>
				</div>
				<div className="rightpart">
					<img src={Pic500} className="nonborder" alt=""/>
					<img src={Pic501} className="nonborder" alt=""/>
				</div>
			</div>
  );
}

export default Do86;