import React from 'react';

import Pic148 from 'assets/images/sub/_images/pic148.gif';
import Pic149 from 'assets/images/sub/_images/pic149.gif';
import Pic150 from 'assets/images/sub/_images/pic150.gif';
import Pic151 from 'assets/images/sub/_images/pic151.gif';
import Pic152 from 'assets/images/sub/_images/pic152.gif';
import Pic153 from 'assets/images/sub/_images/pic153.gif';
import Pic154 from 'assets/images/sub/_images/pic154.gif';
import Pic155 from 'assets/images/sub/_images/pic155.gif';
import Pic156 from 'assets/images/sub/_images/pic156.gif';
import Pic157 from 'assets/images/sub/_images/pic157.gif';
import Pic158 from 'assets/images/sub/_images/pic158.gif';
import Pic159 from 'assets/images/sub/_images/pic159.gif';

function Do22() {

  return (
    <div className="techinfoview">
				<h1>번식과 호르몬</h1>
				<p>&nbsp;&nbsp;&nbsp;어떠한 동물체이든 그 기본적인 요소로 세포, 조직, 기관으로 되어 있는데 생체의 정상적인 생리현상은 그러한 요소의 각기 다른 별개의 작용에 의한 것이 아니라 세포가 모여서 조직을 구성하고 조직이 모여서 기관을 형성하며 기관이 모여서 개체를 이루게 된다.</p><br/>
				<p>[성호르몬 일람표]</p>
				<table className="tablestyle9">
					<tr>
						<td>명칭</td>
						<td>생산부위</td>
						<td>주 요 생 리 적 작 용</td>
					</tr>
					<tr>
						<td>Androgen</td>
						<td>정소간질세포</td>
						<td>수컷 부생식기자극, 정자형성 촉진 동화작용</td>
					</tr>
					<tr>
						<td>Estrogen</td>
						<td>난소(태반, 정소)</td>
						<td>암컷 부생식기자극, 발정유지</td>
					</tr>
					<tr>
						<td>Progesterone</td>
						<td>난소(태반)</td>
						<td>암컷 부생식기자극, 착상작용, 임신유지</td>
					</tr>
					<tr>
						<td>생식선 자극 호르몬<br/>난포자극 호르몬(FSH)<br/>황체형성호르몬(LH)<br/>Prolactin(LTH)<br/>태반융모성생식선자극<br/>호르몬(HCG)<br/>임마혈청성생식선자극<br/>호르몬(PMSG)<br/>태반성황체자극호르몬</td>
						<td>뇌하수체 전엽<br/>뇌하수체 전엽 <br/>사람, 태반<br/>융모막<br/>임신과 태반<br/>(내막반)<br/>태반(흰쥐, 사람)</td>
						<td>난포발육, 세정관자극<br/>배란, 황체형성, 간질자극성Steroid의 분비촉진<br/>유선비유자극, gastagen 분비<br/>LH와 같은 작용, 황체기능보강 <br/>FSH 및 lh와 같은 작용, 부황체형성 <br/>prolactin과 같은 작용, 황체기능보강</td>
					</tr>
					<tr>
						<td>후엽호르몬 Oxytocin</td>
						<td>뇌하수체 후엽	</td>
						<td>자궁근수축, 젖분비</td>
					</tr>
					<tr>
						<td>기타 </td>
						<td></td>
						<td></td>
					</tr>
					<tr>
						<td>황체퇴행인자 <br/>Relaxin</td>
						<td>자궁내막 황체 및 임신자궁</td>
						<td>황체퇴행, 산도개장, 골반인대이완, 자궁운동억제</td>
					</tr>
				</table><br/>
				<p>&nbsp;&nbsp;&nbsp;이와 같이 각 기본요소들은 종적이고 유기적인 관련은 물론 이들 요소간의 횡적관련에 의해서 생체의 정상적인 생리현상이 영위되고 유지되어 가고 있다. 동물체의 생리기능은 효소, Vitamin, Hormone, 신경 등의 작용과 같은 종합적 작용결과 이루어지는 것이다. 특히 생식작용은 신경작용과 내분비,외분비 등의 공동적 상호작용에 의하여 이루어진다. 따라서 성현상을 이해하려면 성 Hormone의 작용을 알아야 한다.</p>
				<img src={Pic148} style={{width: '50%;'}} alt=""/>
				<p style={{width: '50%', textAlign: 'center'}}>[번식에 직접 관여하는 Hormone]</p><br/>
				<table className="tablestyle10">
					<tr>
						<td colSpan="2">대뇌</td>
						<td></td>
						<td colSpan="2"></td>
					</tr>
					<tr>
						<td colSpan="2">↓</td>
						<td></td>
						<td colSpan="2"></td>
					</tr>
					<tr>
						<td colSpan="2">시상하부</td>
						<td>〓〓〓》</td>
						<td colSpan="2">뇌하수체 후엽</td>
					</tr>
					<tr>
						<td colSpan="2">↓</td>
						<td></td>
						<td colSpan="2">↓</td>
					</tr>
					<tr>
						<td colSpan="2">뇌하수체 전엽</td>
						<td></td>
						<td colSpan="2"> Oxytocin</td>
					</tr>
					<tr>
						<td>↓</td>
						<td>↓</td>
						<td></td>
						<td>↓</td>
						<td>↓</td>
					</tr>
					<tr>
						<td>FSH(PMSG)</td>
						<td>LH(HCG)</td>
						<td></td>
						<td>분만</td>
						<td>비유</td>
					</tr>
					<tr>
						<td>↓</td>
						<td>↓</td>
						<td></td>
						<td></td>
						<td></td>
					</tr>
					<tr>
						<td>난포발육, 배란</td>
						<td>황체형성, 황체자극</td>
						<td></td>
						<td colSpan="2">자궁내막</td>
					</tr>
					<tr>
						<td>↓</td>
						<td>↓</td>
						<td></td>
						<td colSpan="2">↓</td>
					</tr>
					<tr>
						<td>Estrogen 분비</td>
						<td>Progesteroneqn 분비</td>
						<td></td>
						<td colSpan="2">prostaglandine</td>
					</tr>
					<tr>
						<td>↓</td>
						<td>↓</td>
						<td></td>
						<td colSpan="2">↓</td>
					</tr>
					<tr>
						<td>발정발현</td>
						<td>착상과 임신유지</td>
						<td></td>
						<td colSpan="2">황체퇴행</td>
					</tr>
				</table>
				<p>[내분비 생리의 기본이해도]</p><br/>
				<ul>
					<li>번식적령기
						<p>&nbsp;&nbsp;&nbsp;가축이 일단 성성숙에 도달하면 시기에 알맞은 방법으로 교배를 시켜야 한다. 교배를 시킬 때 문제가 되는 것은 교배적령기와 발정주기 중 어느 단계에서 교배를 시킬 것이냐 하는 것이다. 품종과 사료섭취량 및 출산시기등과 같은 여러 가지 조건에 따라 다르지만 Holstein의 경우 체중이 약 350Kg에 달했을 때라고 본다. 그러나 최초로 번식에 공용할 수 있는 시기는 신체의 발육이 거의 완성되는 15개월령 전후로서 이때로부터 특별한 이상이 없는 한 15세까지 공용이 가능하다.</p><br/>
						<p>[소의 품종별 번식 적령기]</p>
						<table className="tablestyle">
							<tr>
								<td>품종</td>
								<td>월령</td>
								<td>체중</td>
							</tr>
							<tr>
								<td>한우</td>
								<td>16 - 20개월</td>
								<td>250Kg</td>
							</tr>
							<tr>
								<td>샤로레, 교잡종</td>
								<td>16 - 20</td>
								<td>280 - 320</td>
							</tr>
							<tr>
								<td>앵거스, 헤어포드</td>
								<td>16 - 20</td>
								<td>300 - 320</td>
							</tr>
							<tr>
								<td>샤로레</td>
								<td>16 - 20 </td>
								<td>340 - 350</td>
							</tr>
							<tr>
								<td>젖소</td>
								<td>14 - 18</td>
								<td>350 - 400</td>
							</tr>
						</table><br/>
					</li>
					<li>발정
						<ul>
							<li>발정주기
								<ul>
									<li>발정주기란 한 발정기의 개시일부터 다음 발정기의 개시일까지를 말하며, 성주기라고도 한다. 소의 발정주기는 평균 21일이라 하지만 18 - 24일 범위에 소가 가장 많다. 이 범위안에 드는 소는 60 - 90%정도이다.</li>
								</ul>
							</li>
							<li>발정지속시간
								<ul>
									<li>발정지속시간은 평균 20시간이라고 한다. 하지만 품종, 개체, 산차, 영양상태, 계절 등의 요인에 영향을 받아 차이가 난다. 특히 분만후 2 - 3회 까지는 불과 5 - 10시간 정도로 몹시 짧고 정상적인 사양관리를 하는 소는 18 - 20시간 정도이다. 영양이 나쁜 소가 다소 간편이며 여름철에는 더 짧아지는경우가 있다. 무더운 여름철에는 수태율이 떨어지면서 태아의 사망율이 높고 직장온도가 39℃이상 일때는 수태가 전혀되지 않는다. 발정개시 시간이 오전인 소 보다는 오후인 소가 2 - 4시간 정도 길며 연령이 많아짐에 따라 길어지는 경우가 있다.</li>
								</ul>
							</li>
							<li>발정개시시각
								<ul>
									<li>대체적으로 낮보다는 밤이 많고, 특히 한밤중부터 새벽 또는 이른아침까지 오는 것이 많으며, 오후보다는 오전이 훨씬 더 많이 개시된다.</li>
								</ul>
								<img src={Pic149} alt=""/>
							</li>
							<li>분만 후 발정재귀
								<ul>
									<li>소는 분만후 30일 내외의 생리적 무발정기간이 있으나 이 기간이 지나면 자궁상태가 임신전 상태로 회복되면서 발정이 일어나게 된다. 건강한 소라면 대체적으로 30 - 90일이면 발정재귀가 되고 45 - 60일에 가장 많이 온다.</li>								
								</ul>
								<img src={Pic150} style={{width: '50%'}} alt=""/>
								<p style={{width: '50%', textAlign: 'center'}}>발정이 나타나는 시각</p>
								<img src={Pic151} style={{width: '50%'}} alt=""/>
								<p style={{width: '50%', textAlign: 'center'}}>연중 월별 경산우 및 초임우의 1회 수정시 수태율(Badingga 등, 1985)</p>
							</li>
							<li>임신 중 발정
								<ul>
									<li>수태가 되었음에도 발정이 오는 수가 있는데 대체적으로 3 - 5%정도이며, 그 시기는 임신 3개월 미만에 일어난다. 이는 임신중에도 간혹 난포가 발달되어 생기는데 성숙난포가 되어 배란되는 수도 있어서 만약 이때에 수정을 시킬 때는 극히 드물게 임신이 되는 중복임신이 되기도 한다. 이때는 어린 배아나 태아가 조기사망 또는 유산될 위험성을 배제할 수 없으니 재수정시 신중을 기해야 한다.</li>
								</ul>
							</li>
							<li>발정식별
								<ul>
									<li>발정의 식별은 축주가 관심을 가지고 지속적 능동적으로 실시해야만 수태율을 높일 수 있고, 임신주기를 단축시킬 수 있다.</li>							
								</ul>
								<p>[발정의 식별방법에 따른 발견률]</p>
								<table className="tablestyle">
									<tr>
										<td>식 별 방 법</td>
										<td>발 견 률</td>
									</tr>
									<tr>
										<td>1일 24시간 관찰</td>
										<td>90 - 100%</td>
									</tr>
									<tr>
										<td>1일 2 - 3회 관찰</td>
										<td>81 - 90%</td>
									</tr>
									<tr>
										<td>일반관리</td>
										<td>56%</td>
									</tr>
									<tr>
										<td>수소(시정모)이용</td>
										<td>93 -100%</td>
									</tr>
									<tr>
										<td>승가(기어오름)</td>
										<td>36 - 57%</td>
									</tr>
									<tr>
										<td>승가허용</td>
										<td>65 - 98%</td>
									</tr>
								</table>
							</li>
						</ul><br/>
					</li>
					<li>수정적기
						<ul>
							<li>수정적기란 결국 발정기간중 어느 시기에 수정을 하면 가장 높은 수태율을 얻을 수 있느냐의 문제이므로 이것은 경험적으로 결정할 수 있다. 수정적기를 결정하는 생리적인 요인으로는
								<ul>
									<li>배란시기</li>
									<li> 배란된 난자가 암소의 생식기내에서 수정능력을 유짓하는 시간 </li>
									<li>정자가 수정부위까지 상행하는데 요하는 시간 </li>
									<li>정자가 수정능력을 회복하는데 요하는 시간 </li>
									<li>암소의 생식기 내에서 수정능력을 유지하는 시간 </li>
								</ul>
							젖소의 경우 배란은 발정 종료후에 일어난다. 즉, 발정개시로부터 배란이 일어나기까지 기간은 25 - 30시간으로서 이는 발정 종료후 8 - 11시간에 해당한다. 미경산우는 경산우보다 약간 빠르며, 육우는 유우보다는 빠르다. 배란된 난자가 난관내에서 생존하는 시간은 18 - 20시간 정도이고, 배란후 5- 6시간 이내에 정자와 결합하는 것이 바람직하다.  수정의 적기는 일반적으로 발정개시후 12 - 18시간(배란전 13 - 18) 또는 발정종료전후 3 - 4시간 사이가 수태율이 가장 높은 최적기다.	
							</li>							
						</ul>
						<img src={Pic152} style={{width: '40%'}} alt=""/>
						<p style={{width: '40%', textAlign: 'center'}}>소의 수정적기와 수태율</p>
						<br/>
					</li>
					<li>정액의 주입 방법
						<ul>
							<li>정액의 주입적기를 결정하는 것은 수태율을 높여 번식률을 향상하는데 있어서 가장 중요하다. 수정적기 결정요인은 배란시기, 빈축의 생식기 내에서의 난자의 수정능력 보유시간, 정자의 수정부위 도달시간과 수정능력 획득시간, 정자가 빈축의 생식기내에서 수정능력 보유시간등이 되는데 각 가축의 품종에 따라 개체의 연령, 건강상태 등에 따라 다르므로 발정징후를 정확히 파악하는 것이 수정 적기의 요인이 된다.
								<ul>
									<li>정액 주입량과 정자수 
										<ul>
											<li>가축에 따라 정액 주입량과 정자수는 상이한데 수정에 필요로 하는 정자수에 의하여 정액의 주입량이 결정되므로 정자수가 희박할수록 다량의 정액이 요구되며 정자 농도가 높을수록 소량이 필요하다.
												<p>[각 가축의 정액 주입량과 정자수]</p>
												<table className="tablestyle">
													<tr>
														<td>동물명</td>
														<td>1회 주입 정액량</td>
														<td>정자수</td>
														<td>1회 주입 정자 한도수</td>
													</tr>
													<tr>
														<td>소</td>
														<td>0.25 -1</td>
														<td>2.5천만</td>
														<td>5백만원이상</td>
													</tr>
													<tr>
														<td>말</td>
														<td>10-25</td>
														<td>10억이상</td>
														<td>3억이상</td>
													</tr>
													<tr>
														<td>면양</td>
														<td>0.1-0.5</td>
														<td>1억이상</td>
														<td>4천만원이상</td>
													</tr>
													<tr>
														<td>산양</td>
														<td>0.1-0</td>
														<td>1억이상</td>
														<td>5천만원이상</td>
													</tr>
													<tr>
														<td>돼지</td>
														<td>50</td>
														<td>30억이상</td>
														<td>10억이상</td>
													</tr>
													<tr>
														<td>개</td>
														<td>10-20</td>
														<td>10억이상</td>
														<td>2억이상</td>
													</tr>
													<tr>
														<td>닭</td>
														<td>0.03-0.1</td>
														<td>5천만원-1억이상</td>
														<td>3천만원이상</td>
													</tr>
													<tr>
														<td>토끼</td>
														<td>0.5-1</td>
														<td>7천만원-1억이상</td>
														<td>5천만원이상</td>
													</tr>
												</table>
											위와 같이 다수 정자가 필요한 것은 활력을 갖고 있는 정자가 60%내외이고 그 중 일부는 질 심부나 자궁경부 밖에 역류되어 흡수 되거나 자궁에서 난관을 향해 상주하지 못하고 정체 배회하여 실제로 난관 팽대부까지 가서 난자의 하강을 대기하든가 난자 주위에 군집하여 난포세포를 분산하는데 필요한 물질(Hyakuridase)을 분비하고 Acrosin이라는 효소에 의하여 투명대가 융해됨으로써 비로서 정자가 난황막에 접근하게 된다.	
											</li>
										</ul>
									</li>
								</ul>
							</li>
						</ul>
					</li>
					<li>정액의 주입 적기
						<ul>
							<li>수정 적기를 정확하게 습득하는 것은 인공수정사업에 있어서 수태율 향상에 절대적 요건이 되며 가축개량사의 권위를 농민으로부터 얻을 수 있는 중요한 기회가 된다. 가축 개량사는 항상 자기 구역내의 빈축에 대한 개체별 특성을 파악하여 수정에 활용하므로써 번식률을 높이도록 한다. 수태율 향상은 인공수정 사업의 진퇴를 결정할 수 있는 열쇠라고 보며 양축가 소득증대에 큰 기어가 된다는 것을 명성할 필요가 있다.
								<ul>
									<li>발정징후 
										<p>가축이 발정이 되면 외부적으로 그 징후를 알 수 있는데 소의 발정증세는 다음과 같다.</p>
										<ul>
											<li>평상시 보다 성질이 온순해진다. </li>
											<li>다른 암소에 올라타거나 또는 다른 암소가 올라타도 그대로 있는다. </li>
											<li>맑은 점액이 외음부 밖으로 흘러내린다. </li>
											<li>식욕이 떨어진다. </li>
											<li>거동이 불안하여 음순이 붉게 충혈된다. </li>
											<li>소리를 지른다.</li>
										</ul>
										<img src={Pic153} style={{width: '60%'}} alt=""/>
										<p style={{width: '60%', textAlign: 'center'}}>소의 교배 적기</p>
									</li>
									<li>배란시기
										<ul>
											<li>배란기는 성숙 난포에서 난자가 배란되는 시기를 의미하는 데 가축의 종류에 따라서 상이하며 개체나 연령 계절적 영향이나 사양관리에서 오는 영향 등에 따라 다르다.</li>											
										</ul>
										<p>[각 가축의 수정 적기와 배란기 상기표]</p>
										<table className="tablestyle">
											<tr>
												<td>동물명</td>
												<td>발정시간<br/>(평균)</td>
												<td>배란기</td>
												<td>수정적기</td>
											</tr>
											<tr>
												<td>소</td>
												<td>12∼38시간(22)</td>
												<td>발정개시후 20-26시간<br/>발정종료후 6-10시간</td>
												<td>발정개시후 9 -20</td>
											</tr>
											<tr>
												<td>돼지</td>
												<td>48∼70시간(55)</td>
												<td>발정개시후 24-36시간</td>
												<td>발정개시후 15-20</td>
											</tr>
											<tr>
												<td>개</td>
												<td>2주 ∼3주</td>
												<td>발정개시후 10-16시간</td>
												<td>발정개시후 10-14일째</td>
											</tr>
											<tr>
												<td>토끼</td>
												<td>-</td>
												<td>교미자극이나 배란자극후<br/>10시간경 배란함</td>
												<td>교미자극후 5-6시간</td>
											</tr>
											<tr>
												<td>닭</td>
												<td>-</td>
												<td>-</td>
												<td>산란후 오후 2시정도가 적당</td>
											</tr>
										</table><br/>
									</li>
									<li>난자의 수명
										<ul>
											<li>난자의 성숙 난포로부터 배란되어 난관내에서 생존하는 기간을 난자 수명이라고 하는데 배란된 난자가 수란관 팽대부까지 하강하여 정자를 수용할 수 있는 능력 기간을 난자 수용능력기간이라고 말한다. 난자는 정자보다 수명이 극히 짧고 난자 수용능력 시간이 5 - 6시간 이상이 못 된다는 것이 생리학자의 일반적인 의견이다.
											<p>[난자의 수정 능력 보유 시간]</p>
											<table className="tablestyle">
												<tr>
													<td>가축</td>
													<td>최대 수정 능력 보유 시간</td>
												</tr>
												<tr>
													<td>소</td>
													<td>18 - 20(tlrks)</td>
												</tr>
												<tr>
													<td>면양, 산양</td>
													<td>24</td>
												</tr>
												<tr>
													<td>토끼</td>
													<td>6 - 8</td>
												</tr>
												<tr>
													<td>돼지</td>
													<td>10 - 21</td>
												</tr>
											</table>
											</li>
										</ul><br/>
									</li>
									<li>정자의 수용능력 보유 시간
										<ul>
											<li>정자가 사정되어 난자를 공격하여 수정 능력을 획득하려면 정자의 생종과 성숙하는데 시간이 필요하다. 정자의 수용능력 유지 시간은 사정, 4시간 이후부터 30시간 이라고 보는 것이 타당하다.</li>
										</ul><br/>
									</li>
									<li>수정 적기
										<ul>
											<li>수태율을 높게 올리기 위해서는 발정온 가축을 적기에 수정시켜 주어야 하는데 수정 적기는 가축에 따라 다르다. 수정 적기는 난포에서 난자가 배란되는 시기, 배란된 난자의 수정능력 보유시간에 정자가 수정되어 난관팽대부까지 운반되는데 소요되는 시간, 정자의 수정능력 획득에 소요되는 시간 및 암소의 생식기도 내에서의 정자의 수정능력 유지시간 등에 의하여 결정되는데 소는 발정 종료를 전후하여 2-3시간, 돼지는 수돼지를 허용하기 시작한 시점, 즉 부동반응을 보이기 시작한 시점으로부터 12- 35시간 사이가 좋고, 면양은 발정 개시후 20-30시간, 산양은 발정개시후 25-30시간 사이가 최적이다. 송아지를 일찍 얻을 욕심으로 수태율 적기이전에 실시하면 어미 소는 수태율이 낮고 난산이나 산후 회복이 늦어 분만이 지연되고 생리적 결함이 생기며, 출생된 송아지도 발육이 저조하여 경제적인 손실이 크게 따른다. 반대로 너무 늦게 수정시켜도 수태율이 떨어지고 번식이 지연되는 만큼의 사양 비용이 발생되므로 사육자에겐 그만큼 소득이 감소된다.
												<p>[시간별 수정 적기(소)]</p>
												<table className="tablestyle">
													<tr>
														<td>시간</td>
														<td>0</td>
														<td>3</td>
														<td>6</td>
														<td>9</td>
														<td>12</td>
														<td>18</td>
														<td>24</td>
														<td colSpan="2">28</td>
													</tr>
													<tr>
														<td>수정적기여부</td>
														<td colSpan="3">이르다 →</td>
														<td colSpan="3">←적당하다</td>
														<td>→최적기←</td>
														<td>→적당하다←</td>
														<td>←아주늦다</td>
													</tr>
												</table>
											</li>
										</ul>
									</li>
								</ul>
							</li>
						</ul><br/>
					</li>
					<li>정액 주입법
						<ul>
							<li>소의 정액 주입은 스트로 정액을 이용하는데 그 주입 방법은 다음과 같다.
								<ul>
									<li>발정한 암소를 놀라지 않게 자연상태로 보정한다. 스트레스를 받으면 부신수질에서 아드레날린이 분비되어 배란을 지연시키고 자궁의 수축운동을 억제하여 수태율이 저하된다. </li>
									<li>왼쪽팔에 직장검사용 장갑을 끼고 비눗물을 충분히 바른후에 직장내에 삽입해서 배분시킨다.</li>
									<li>생식기를 부드럽게 검사한다. </li>
									<li>수정할 정액의 가축명을 확인한 후 스트로 정액을 개봉한다. </li>
									<li>왼팔을 직장에 넣은상태로 외음부를 깨끗이 닦아준다. </li>
									<li>왼손으로 자궁경관을 잡고 오른손에 주입기를 잡고 외음부를 넓게 벌려 주입기를 질내에 천천히 삽입을 유도한다. </li>
									<li>주입기의 선단이 자궁경관의 마지막 삼추벽을 통과하였을 때 왼손의 둘째 손가락으로 주입기 끝을 확인한 후 자궁경심부에 서서히 정액을 주입한다. </li>
									<li>주입기와 왼손을 뺀다. </li>
									<li>수정증명서를 양축가에게 발급하여 주고 필요한 기록을 한다.</li>
								</ul>
							</li>
						</ul>
					</li>
					<li>인공수정 시술자와 수태율
						<ul>
							<li>직장검사를 통하여 난포의 발육정도를 원숙히 감지하고 수정을 시킬 경우 62.3∼66.4%의 수태율을 보인 반면 숙련도가 미숙한 경우에는 57%의 수태율을 나타낸다. 정상적인 발정이 오고 숙련된 수정사가 수정을 시킨다면 동결정액의 주입정자수가 융해온도 등에 관계없이 높은 수태율을 나타낼 것으로 사료된다.
								<img src={Pic154} style={{width: '40%'}} alt=""/>
								<p style={{width: '40%', textAlign: 'center'}}>주입기 끝부분 위치 분포도</p>
								<p>[인공수정 시술자에 따른 수태율]</p>
								<table className="tablestyle">
									<tr>
										<td>수정사</td>
										<td>수정두수</td>
										<td>임신두수</td>
										<td>수태율(%)</td>
									</tr>
									<tr>
										<td>A</td>
										<td>227</td>
										<td>147</td>
										<td>64.8</td>
									</tr>
									<tr>
										<td>B</td>
										<td>137</td>
										<td>91</td>
										<td>66.4</td>
									</tr>
									<tr>
										<td>C</td>
										<td>207</td>
										<td>129</td>
										<td>62.3</td>
									</tr>
									<tr>
										<td>D</td>
										<td>86</td>
										<td>49</td>
										<td>57.0</td>
									</tr>
								</table>
								<br/>
								<img src={Pic155} style={{border: '0px', borderRadius: '0px', padding: '0px', width:'40%'}} alt=""/>
								<p style={{width: '40%', textAlign: 'center'}}>인공수정시 정액의 주입 부위</p>
								<img src={Pic156} style={{border: '0px', borderRadius: '0px', padding: '0px', width:'40%'}} alt=""/>
								<p style={{width: '40%', textAlign: 'center'}}>주입시 삽입시 발생할 수 있는 장애물</p>
								<img src={Pic157} style={{width:'40%'}} alt=""/>
								<p style={{width: '40%', textAlign: 'center'}}>질전정, 질심부 및 자궁경관의 세균 검출율</p>
							</li>
						</ul>
					</li>
					<li>인공수정의 순서
						<ul>
							<li>동결정액 융해에 필요한 38℃의 물이 담긴 보온병 준비</li>
							<li>동결정액을 수정시킬 소가 있는 곳까지 동결시킨 상태로 운반할 수 있는 소형콘테이너를 준비한다. </li>
							<li>수정용 기구(인공수정용 주입기, 보호캡, 화장지, 비닐커버,수정용 장갑)일체를 보온병옆에 둔다. </li>
							<li>저장탱크에서 사용하고자 하는 정액의 위치를 확인한 후 3 -5초내에 스트로를 소형 콘테이너에 옮겨 담는데 주의할 점은 정액을 확인할 때에는 정액을 저장탱크의 서리가 낀 높이까지만 스토로를 올려서 확인하고 절대 입구 밖으로는 노출시키지 않아야 한다. <br/>
								<img src={Pic158} style={{padding: '0px', width:'40%'}} alt=""/>
								<p style={{width: '40%', textAlign:'center'}}>액체질소통(콘테이너)에서의 정액 취급</p>
							</li>
							<li>수정시킬 소를 확인하고 재발정인 경우 임신여부를 확인 </li>
							<li>수정기구(주입기, 씨스)를 포장용기에서 5 - 8㎝ 노출시켜 놓은후 화장지(티슈)를 꺼내 주머니에 넣는다. </li>
							<li>수정용 비닐장갑을 꺼내 편리한 장소에 놓아두고 만약 겨울철과 같이 외부 기온이 낮으면 주입기를 겨드랑이 사이에서 마찰시켜 마찰열을 받게 하여 준비해 놓는다. </li>
							<li>스토로를 소형저장탱크로부터 꺼내 35 - 38℃물이 담긴 보온병에서 20 -25초간 융해시킨다. </li>
							<li>정액을 융해한 후 정액의 이름 번호를 확인하여 해당 암소에 수정할 것인지 재차 확인한다. </li>
							<li>녹인 스트로 주위의 물기를 티슈로 닦아 표면을 건조시킨다.(반드시 물기를 제거하여야 하는 것은 만약 물이 정액에 섞임녀 정충이 기형이 되기 때문임) </li>
							<li>주입기에 스트로를 장착하고 가위로 스트로 선단을 절단한다. </li>
							<li>주입기에 씨스를 씌우는데 보다 위생적으로 하기 위하여 씨스마다 비닐카바가 씌워진 상품이 나오는데 어떤 수정사는 비닐카바를 바로 벗겨버리는 경우가 있는데 비닐카바는 주입기를 자궁경관 직전까지 삽입시 질에 의해 주입기의 시스가 오염되는 것을 방지하기 위하여 자궁경관 직전에서 당겨서 제거하도록 한것임을 알아놓고 실천하여야 한다. </li>
							<li>주입기를 입에 무는데 추운 날씨에는 스트로가 장착 부분이 입속에 위치하도록 물고 있으면 정자는 온도충격을 적게 받게 좋다. </li>
							<li>비닐장갑을 착용후 윤활제를 바른다. </li>
							<li> 오른손은 티슈를 감싼 꼬리상부를 잡고 들어올린 후 장갑낀 좌측손을 직장내에 삽입하여 분을 제거한다. </li>
							<li>자궁경관을 확인하여 앞으로 밀고 만약 재발정인 경우는 자궁의 크기 변화를 확인한다. </li>
							<li>꼬리를 좌측팔 위에 위치시키고 오른손으로 외음부를 티슈로 닦아 오물을 제거한다. </li>
							<li>좌측손을 직장입구에 위치시켜 아래로 늘려서 음문이 퍼지도록하여 새 티슈를 음순사이에 끼워 음순이 벌어지게 하여 둔다. </li>
							<li>그 다음 주입기를 음문으로 넣어 질을 지나 자궁경관내에 삽입한다. 이때 분을 제거할 필요가 있을 때는 반드시 주입기를 경관 입구까지 삽입한 후분을 제거하여야 오염이 안된다. </li>
							<li> 정액을 천천히 주입한다.(이때 10부터 0까지 카운트다운 하면서 주입한다.) </li>
							<li>천천히 주입기를 빼낸다. </li>
							<li>천천히 직장으로부터 팔을 뺀다. </li>
							<li>장갑과 씨스, 씨스카바, 빈스크로우를 쓰레기통에 담는다. </li>
							<li>필요한 기재사항을 기록한다. </li>
							<li>양동이에 물을 담고 소독약을 탄 후, 손과 장화 등을 세척한다. </li>
						</ul>
						<p>&nbsp;&nbsp;&nbsp;이상의 수정순서는 정액의 온도변화에서 오는 충격을 최소로 해야한다는 점과 수정작업을 위생적으로 실시하여야 한다는 것이 요점이다. 즉 수태율은 수정사의 기술에 따라 차이가 난다는 뜻이다. 예를 들면 품질이 좋고 비싼 정액에 대하여서는 수정사가 조심스럽게 정성껏 다루어 수정시키므로 수태율이 평균 3% 향상된다는 것이다.</p>						
					</li>
					<li>정액 주입과 부위별 수태율
						<ul>
							<li>수태율을 향상시키기 위해서 자궁체에 정확히 주입하는 방법과 아울러 양쪽 자궁각에 정액 주입하는 기술이 필요하다. 
								<ul>
									<li>직장검사 기술숙련과 자궁경 잡는 법 </li>
									<li>자궁경 끝에 인지를 갖다대고 주입기 끝을 촉진하는 법 </li>
									<li>정액이 완전히 주입될 때까지 주입기 끝을 일정하게 유지시는 법 </li>
								</ul>
								이상은 기초적인 것이지만 기술정도에 따라 수태율의 증.감은 예상해야 한다.
								<p>[주입부위와 경험에 따른 수태율]</p>
								<table className="tablestyle">
									<tr>
										<td colSpan="3">자궁체 주입시 수태율</td>
										<td colSpan="2">양쪽자궁각 주입시 수태율</td>
									</tr>
									<tr>
										<td>교육직전</td>
										<td>교육직후</td>
										<td>교육6개월후</td>
										<td>교육직후</td>
										<td>교육6개월후</td>
									</tr>
									<tr>
										<td>53%</td>
										<td>95%</td>
										<td>75%</td>
										<td>96%</td>
										<td>97%</td>
									</tr>
									<tr>
										<td>(23/43)</td>
										<td>(32/35)</td>
										<td>(40/53)</td>
										<td>(53/54)</td>
										<td>(32/33)</td>
									</tr>
								</table>
								그리고 전문수정사라 할지라도 교육직전에는 53%의 수태율을 나타냈지만 교육직후 95%까지 향상되었고, 교육후 6개월에는 75%로 내려간 것으로 보아 계속해서 관심과 정성을 들여 수정을 해야 한다.
								<ul>
									<li>자궁각 내의 정액 주입방법
										<ul>
											<li>자궁경을 잡고 주입기 끝은 자궁경 끝에 있는지 확인 </li>
											<li>주입기 끝을 왼쪽(오른쪽)으로 이동시켜 약간의 저항을 느낄 때가지 좌측 자궁각으로 이동 </li>
											<li>주입기 끝을 확인 후 정액의 반을 주입 </li>
											<li>주입기를 자궁체로 빼내고 </li>
											<li>주입기 끝을 오른쪽(왼쪽)으로 이동 약간의 저항을 느낄때까지 우측 자궁각으로 이동 </li>
											<li>나머지 정액을 주입</li>											
										</ul>
										<p>위와 같이 자궁체 보다는 자궁각에 수정했을 때 수태율이 약 20% 향상되었다. 그러나 자궁각 주입시 자궁내 상처나 오염문제를 생각하지 않을 수 없다.</p>
										<p>[정액 주입 부위별 수태율]</p>
										<table className="tablestyle">
											<tr>
												<td>목장</td>
												<td>자궁체</td>
												<td>자궁각</td>
											</tr>
											<tr>
												<td>1</td>
												<td>46.0(1,556)</td>
												<td>67.2(580)</td>
											</tr>
											<tr>
												<td>2</td>
												<td>49.9(270)</td>
												<td>74.(318)</td>
											</tr>
											<tr>
												<td>3</td>
												<td>45.8(403)</td>
												<td>69.9(321)</td>
											</tr>
											<tr>
												<td>4</td>
												<td>56.8(412)</td>
												<td>71.2(318)</td>
											</tr>
											<tr>
												<td>평균(전체)</td>
												<td>44.7(2,641)</td>
												<td>64.6(1,537)</td>
											</tr>img src
										</table>
										<p>그러나 연구보고에 의하면 자궁내 정액을 주입했을 경우 자궁내막에 손상과 자궁내 미생물 수의 상태가 대조구와 전혀 차이가 없었으며 흔히 우려하는 자궁내 감염은 사전 위생적으로 한다는 개념만 갖고 있으면 큰 문제점이 없으리라 생각된다.</p>
										<img src={Pic159} style={{width: '50%'}} alt=""/>
										<p style={{width: '50%', textAliign:'center'}}>암소의 생식기관과 인공수정을 실시하는 모습</p>
										<p>[자궁각 내 정액주입에 의한 자궁각 손상]</p>
										<table className="tablestyle">
											<tr>
												<td>정액주입</td>
												<td colSpan="2">자궁내막의 변색<br/><p style={{float: 'left', paddingLeft: '20px'}}>cm</p><p style={{float: 'right', paddingRight: '20px'}}>%</p></td>
											</tr>
											<tr>
												<td>대조구</td>
												<td>8.92</td>
												<td>6.01</td>
											</tr>
											<tr>
												<td>자궁체</td>
												<td>11.46</td>
												<td>6.91</td>
											</tr>
											<tr>
												<td>자궁각</td>
												<td>11.08</td>
												<td>7.29</td>
											</tr>
											<tr>
												<td>평 균</td>
												<td>10.44</td>
												<td>6.73</td>
											</tr>
										</table><br/>
									</li>
									<li>분만예정일 계산법
										<ul>
											<li>예정일 = 종부월 - 3(혹은 +9)</li>
											<li>예정일 = 종부일 + 10</li>
											<li>예) 4월 10일에 종부 〓&gt; 4 - 3 = 1월, 10 + 10 = 20일. 즉, 이듬해 1월 20일 분만예정
											</li>
										</ul>
									</li>
								</ul>
							</li>
						</ul><br/>
					</li>
					<li>분만전 어미소 관리
						<ul>
							<li> 분만 2개월 전부터 (임신말기) 배합사료를 10 ∼ 20% 증량공급 </li>
							<li>비타민과 광물질 첨가제 급여(미네랄블럭, 비타폴트-A) </li>
							<li>충분한 운동과 일광욕 </li>
							<li>분만 1주일전에 분만실에 이동 </li>
							<li> 분만이 시작되면 곁에서 관찰</li>
							<li>특히 처음 종부시키는 어린 어미소는 충분한 영양을 공급하여 정상적인 어미소 자신의 몸성장과 송아지 성장을 유도</li>
						</ul>
					</li>
				</ul>
			</div>
  );
}

export default Do22;
