import React from 'react';

function Fo54() {

  return (
    <div className="techinfo_view">
				<p className="viewTitle">스위트 클로버 병</p>
				<dl>
					<dt>발병대상</dt>
					<dd>소, 면양이나 말에서 종종 발생하기도 한다.</dd>
				</dl>
				
				<dl>
					<dt>원인</dt>
					<dd>곰팡이가 발생했거나 부패된 sweet clover 건초 또는 사일리지를 급여하므로써 야기된다. 곰팡이가 생겼거나 부패된 sweet clover 건초에서 무해한 천연 coumarin은 혈액 응고에 필요한 비타민 K의 작용을 저해하는 dicoumarol로 전환된다.</dd>
				</dl>
				
				<dl>
					<dt>징후 및 증상</dt>
					<dd>혈액응고 작용에 손상을 준다. 이 결과 신체 각 부위의 피하는 내출혈로 인해 부풀어 오른다. 그러므로 제각시와 거세, 분만 또는 상처가 난 경우 치명적이며 과다한 출혈을 일으킬 수도 있다. 그리고 연령에 관계없이 발병하며 신생동물은 분만시에 발생할 수 있다. </dd>
				</dl>
				
				<dl>
					<dt>질병분포와 손실규모</dt>
					<dd>Sweet clover를 경작중이거나 건초용으로 건조시키는 곳 어디에서나 일어난다.</dd>
				</dl>
				
				<dl>
					<dt>치료</dt>
					<dd>감염물질을 제거하거나 또는 메나디온(비타민 K3)을 공급한다. 이러한 징후가 있는 가축에게 동일한 사료를 급여하지 않은 정상적인 가축의 혈액이나 혈장을 주사한다. </dd>
				</dl>
				
				<dl>
					<dt>대책</dt>
					<dd>
						<p>축군내에 sweet clover병이 발생했을 경우</p>
						<ul>
							<li>감염된 사료의 급여를 중단하고</li>
							<li>양질 건초(특히 알팔파)로 대체해 준다/ </li>
						</ul>
					</dd>
				</dl>
				
				<dl>
					<dt>예방</dt>
					<dd>Sweet clover건초나 사일리지를 적절히 건조시킨다. 또한 coumarin 함량이 적은 sweet clover 품종도 개발되어 있으므로 이들 품종을 사용한다.</dd>
				</dl>	
				
				<dl>
					<dt>참고</dt>
					<dd>이 병은 sweet clover 목초 또는 곰팡이가 생긴 lespedeza 건초를 급여할 때도 발생하게 된다</dd>
				</dl>
			</div>
  );
}

export default Fo54;
