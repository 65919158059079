import React, { useState , useRef, useEffect } from 'react';
import { getState } from 'utils/api';

// 컴포넌트
import SubTitle from 'components/subTitle/SubTitle';

// scss
import './dictionary.scss';

function Dictionary() {

  const [ dictionary , setDictionary ] = useState(null);
  const [ dictionaryInfo , setDictionaryInfo ] = useState(null);

  const baseDir = '/data/dictionary/';
  const props = {};

  useEffect(() => {
    getState(baseDir,props)
    .then(getdata => {
      const newDictionary = getdata.map((dic) => (
        {
          ...dic,
          isOpen: false,
        }
      ));
      setDictionary(newDictionary);
      setDictionaryInfo({
        sjc_name: newDictionary[0].sjc_name,
        info: newDictionary[0].category_list[0]
      });
    })
     .catch(error => {
      console.log(error);
     });
  },[]);

  const selectRef = useRef(null);
  const inputRef = useRef(null);
  
  const toggleOpen = (index) => {
    setDictionary(prev => {
      const newDictionary = [...prev];
      newDictionary[index].isOpen = !newDictionary[index].isOpen;
    
      return newDictionary;
    });
  };

  const filterInfoByInput = (input, filterFn) => {
    const newInfo = {
      sjc_name: '전체',
      info: {
        sjc_name: '검색',
        dictionalry_list: []
      }
    };
  
    dictionary.forEach((dic) => {
      dic.category_list?.forEach((dic2) => {
        dic2.dictionalry_list?.forEach((dic3) => {
          if (filterFn(dic3, input)) {
            newInfo.info.dictionalry_list.push(dic3);
          }
        });
      });
    });
  
    return newInfo;
  };
  
  const searchDictionary = () => {
    const select = selectRef.current.value;
    const input = inputRef.current.value;
  
    let filterFn;
  
    if (select === 'title') {
      filterFn = (item, searchTerm) => item.sj_subject.includes(searchTerm);
    } else if (select === 'text') {
      filterFn = (item, searchTerm) => item.sj_content.includes(searchTerm);
    }
  
    const newInfo = filterInfoByInput(input, filterFn);
    setDictionaryInfo(newInfo);
  };

  const enterKeyDown = (event) => {
    if (event.key === 'Enter') {
      searchDictionary();
    }
  };

//   useEffect(() => {
//     console.log(dictionaryInfo);
//   },[dictionaryInfo]);

  return (
    <div className="dictionary subCon">
      <SubTitle />
      <div className='dictionaryInner'>
        <div className='dictionaryNav'>
          <strong>낙농용어분류</strong>
          <ul className='dictionaryList'>
          {
            dictionary &&
            dictionary.map((dic, index) => (
              <li className='dic2' key={index}>
                <button 
                  type='button' 
                  className={`${dic.isOpen ? 'open' : ''}`}
                  onClick={() => toggleOpen(index)}
                >
                  {dic.sjc_name} 
                  <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.59 -8.60828e-08L6 4.94467L1.41 -8.88624e-07L5.66301e-07 1.52227L6 8L12 1.52227L10.59 -8.60828e-08Z" fill="white"/>
                  </svg>
                </button>
                { 
                  dic.isOpen && 
                  dic.category_list && 
                  (
                    <ul className={`${dic.isOpen ? 'open' : ''}`}>  
                      {
                        dic.category_list.map((dic2 , i) => (
                          <li key={i}>
                            <button 
                              type='button'
                              onClick={()=> {
                                setDictionaryInfo({
                                  sjc_name: dic.sjc_name,
                                  info: dic2
                                });
                              }}
                            >
                              {dic2.sjc_name}
                            </button>
                          </li>
                        ))
                      }
                    </ul>
                  )
                }
              </li>
            ))}
          </ul> 
        </div>
        <div className='dictionaryCon'>
          <div className='dictionarySearch'>
            <select ref={selectRef} defaultValue='title'>
              <option value='title'>제목</option>
              <option value='text'>내용</option>
            </select>
            <input type='text' ref={inputRef} onKeyDown={enterKeyDown}/>
            <button onClick={()=> searchDictionary()} type='button'>검색</button>
          </div>
          <div className='dictionaryInfo'>
            <strong>분류 : {dictionaryInfo && dictionaryInfo.sjc_name} &#62; {dictionaryInfo && dictionaryInfo.info?.sjc_name}</strong>
            <ul>
              {
                dictionaryInfo?.info &&
                dictionaryInfo.info.dictionalry_list.map((dic , index) => (
                  <li key={index}>
                    <strong>{dic.sj_subject}</strong>
                    <p>{dic.sj_content}</p>
                  </li>
                ))
              }
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dictionary;
