import React, { useState } from 'react';

// scss
import './NewTotalprint.scss';

function NewTotalprint() {
  
  const [ inputdata , setInputdata ] = useState([
    {
      name: '13개월 우군 검정성적 변화',
      id: '1',
      checked: false
    },
    {
      name: '검정성적에 의한 유성분 분석 보고서',
      id: '2',
      checked: false
    },
    {
      name: '월간 우군 관리 계획표',
      id: '3',
      checked: false
    },
    {
      name: '종합 기술 분석표',
      id: '4',
      checked: false
    },
    {
      name: '주의 개체 리스트',
      id: '5',
      checked: false
    },
    {
      name: '전월 대비 검정성적',
      id: '6',
      checked: false
    },
    {
      name: '분만일수, 유단백, MUN별 개체 상태 리스트',
      id: '7',
      checked: false
    },
    {
      name: '전체 그래프',
      id: '8',
      checked: false
    }
  ]);
  
  return (
    <div className='newTotalprint'>
      <h1>농가 분석 리포트</h1>
      <table className='pdfTable'>
        <thead>
          <tr>
            <th>선택</th>
            <th>리포트 종류</th>
          </tr>
        </thead>
        <tbody>
          {
            inputdata &&
            inputdata.map((item) => 
              <tr key={item.name}>
                <td>
                  <input 
                    type='checkbox' 
                    name={item.id} 
                    id={item.id} 
                    checked={item.checked}
                    onChange={()=>
                      setInputdata(
                        inputdata.map((data) => {
                          if (data.id === item.id) {
                            return {
                              ...data,
                              checked: !data.checked
                            };
                          }
                          return data;
                        })
                      )
                    }
                  />
                  <label htmlFor={item.id}></label>
                </td>
                <td>{item.name}</td>
              </tr>
            )
          }
        </tbody>
      </table>
      <ul className='totalprintBtn'>
        <li>
          <button 
            type='button'
            onClick={()=>
              setInputdata(
                inputdata.map((data) => ({...data, checked: true}))
              )
            }
          >전체선택</button>
        </li>
        <li>
          <button 
            type='button'
            onClick={()=>
              setInputdata(
                inputdata.map((data) => ({...data, checked: false}))
              )
            }  
          >전체취소</button>
        </li>
        <li>
          <button type='button'>조회</button>
        </li>
      </ul>
    </div>   
  );
}

export default NewTotalprint;
