import React from 'react';

import pic717 from 'assets/images/sub/_images/pic717.jpg';
import pic718 from 'assets/images/sub/_images/pic718.jpg';
import pic719 from 'assets/images/sub/_images/pic719.jpg';

function Do148() {

  return (
    <div className="techinfoview">
      <h1>소의 모낭충증</h1>
      <p className="pstyle">모낭충이 소 피부의 털 구멍에 기생함으로써 일어나는 피부병이다. 1개의 털구멍에 모낭충이 100-200개로 증식하면 모낭충자체의 용적과 모포의 벽이나 조직을 갉아 먹기 때문에 털이 빠지며 그후에 조직액, 지방 등이 충만하여 피부에 좁쌀에서 완두콩크기의 결절을 만든다.</p>
      <div className="leftpart">
        <h2>증상</h2>
        <ul>
          <li>주로 많이 생기는 부위는 머리, 어깨, 가슴부위 등 주로 앞쪽에 생겨나지만 심해지면 전신에 파급된다.
            <ul>
              <li> 좁쌀 크기로부터 완두콩 크기의 결절이 나타나며</li>
              <li>가려움을 나타내지 않는다. </li>
              <li> 결절은 밀집하여 생기지만 개개의 결절이 서로 맞붙는 경우는 없다.</li>
              <li>초기에는 이 결절에서 진물(삼출액)이 흘러나오지만 완두콩 크기정도가 되면 이것을 볼 수 없다.</li>
            </ul>
          </li>
        </ul>
        <h2>목장에서의 처치</h2>
        <ul>
          <li>결절의 수가 많지 않을때는 결절을 절개후 내용물을 짜낸 후 옥도정기를 바른다.</li>
          <li>결절의 수가 많고 여러 마리가 감염되어 있을 때는 네구본(바이엘사 제품) 등의 약품의 1% 수용액으로 만들어 여러날 동안 전신에 뿌린다.</li>
        </ul>
      </div>
      <div className="rightpart">
        <img src={pic717} className="nonborder" alt=""/>
        <img src={pic718} className="nonborder" alt=""/>
        <img src={pic719} className="nonborder" alt=""/>
      </div>
    </div>
  );
}

export default Do148;