import React from 'react';


function Do66() {

  return (
    <div className="techinfoview">
				<h1>원유검사-관능검사, 이화학적 검사</h1>
				<p className="pstyle">&nbsp;&nbsp;&nbsp;원유검사는 크게 수유검사와 시험검사로 나눌 수 있다. 수유검사는 관능검사, 비중검사, 알콜검사 및 진애검사 등이 있으며 시험검사로는 적정산도시험, 세균 수시험, 체세포수시험, 세균발육억제물질검사, 성분검사 및 기타검사 등이 있다. 세균수와 체세포수 시험은 각각 목장별로 15일에 1회 이상 실시해야 하지만, 착 유검사결과 부적합하다고 인정된 목장, 새로 우유를 납유하는 목장, 기타 부적합 한 원유를 납유한 목장에 대해서는 필요에 따라 검사를 실시한다.</p>
				<h2>관능검사</h2>
				<ul>
					<li>우유에 대한 지식과 경험에 의하여 이상유를 감별하는 방법으로써 원유를 청결 한 시험관에 10ml 정도를 취하여 우유의 색, 맛, 향, 응고물의 유무 등을 확인 한다.</li>
				</ul>
				<h2>이화학적 검사</h2>
				<ul>
					<li>진애시험(Sediment test)
						<ul>
							<li>원유에 함유된 오물 즉, 쇠똥, 피모, 흙, 곤충 등의 혼입여부를 검사하는 방법 으로써 우유를 진애검사용 필터(filter)에 통과시켜 우유중 걸리는 진애의 양과 종류를 관찰하는 품질검사 방법으로 검사결과는 mg 단위로 표시한다. 일반적으 로 청결한 상태에서 취급한 우유는 1.Omg 이하이다. </li>
						</ul>
					</li>
					<li>자비시험(colt-on-boiling test)
						<ul>
							<li>원유의 산도가 0.25% 이상인 경우 가열시 카제인이 응고하는 성질을 이용하여 원유가 가열처리 소독에 적합한가를 검사하는 것이다. 충분히 혼합된 가검 우 유 10ml를 시험관에 넣고, 알콜램프로 서서히 가열하여 끓인 후 응고여부를 육 안으로 검사한다. 응고가 있을시에는 살균처리가 부적합한 것으로 판정한다.</li>
						</ul>
					</li>
					<li>알코을 침전시험
						<ul>
							<li>원유의 신선도를 검사하는 가장 일반적인 방법으로서 집유장 또는 유처리장에 서 각하시험법으로 이용하고 있다. 원유 샘플 2ml과 70%(V/V)의 에탄을 동량 을 시험관 또는 알코올 시험관에 혼합한 후 응집여부를 검사하게 되며, 샘플 및 시약의 온도(15℃)를 일정하게 유지시키도록 함으로써 가양성 반응 및 가음성 반응을 억제시킨다. 산도가 0.2% 이상일 때 즉, 초유, 비유말기, 병적상태의 우 유는 양성반응을 보인다. </li>
						</ul>
					</li>
					<li>산도시험 (Acidity test)
						<ul>
							<li>우유가 신선하지 않을 때 산을 생성하기 때문에 신선도를 확인하기 위하여 산 도를 측정함으로써 알아내는 방법으로 중화시키는데 소요되는 알칼리량을 검사하 게 된다 샘플 10ml를 비이커에 넣고 10ml의 증류수로 혼합한 후 1% 페놀프 탈레인용액(페놀프탈레인 1g + 에칠알콜 100ml) 0.5ml를 가한후 0.1N NaOH 용액으로 엷은 홍색이 30초 이상 지속할때까지 계속 적정한다. 여기에 소비된 NaOH량을 다음식에 의하여 계산하고 그 산도를 젖산 %로 표시한다. 우유의 산도는 0.135∼0.175%이며, 0.18% 이상인 경우는 부적합 우유로 규정 하고 있다.<br/><br/>
								<table className="tablestyle14">
									<tr>
										<td rowSpan="2">산도(유산%중량) = </td>
										<td>(0.1N-NaOH 적정량) × 0.009</td>
										<td rowSpan="2">× 100</td>
									</tr>
									<tr>
										<td>샘플우유(ml) × 우유의 비중</td>
									</tr>
								</table>
								
							</li>
						</ul><br/>
					</li>
					<li>포스파타아제 시험(Phosphatase test)
						<ul>
							<li>우유중 인산 ester 및 폴리인산의 가수분해를 촉매하는 효소를 총칭하여 포스 타파아제라 한다. 이 효소는 62.8℃ 30분 또는 71∼75℃ 15∼30초의 가열에 의해서 파괴되므로 저온살균처리 여부와 생유 혼입 여부를 검출하는 방법으로 음 성 및 양성 샘플을 가검샘플 우유와 함께 시험에 공시한다. 시험결과를 표준결과 와 비교하여 가검우유 1ml중 2.3ug 이상의 페놀이 포함된 경우 부적합 우유로 판정된다.</li>
						</ul>
					</li>
					<li>비중 검사(lactometer test)
						<ul>
							<li>생유나 우유중 가수 및 탈지여부를 추정하는데 사용되며, 홀스타인 젖소의 우 유 비중은 15℃에서 1.027∼1.035 이다. 측정방법은 시료전체를 충분히 혼합시 킨 다음 약 200ml의 우유를 실린더 벽에 넣은 다음 우유 비중계를 실린더 중앙 부에 넣어 1∼3분간 방치한 후 메니스커스 상단의 눈금을 읽은 다음 15℃ 온도 에서 비중을 측정하거나 그렇지 않은 경우 15℃의 비중으로 환산하여 표시한다. </li>
						</ul>
					</li>
					<li>가수 시험
						<ul>
							<li>테트라 유청비중 측정법, 유청의 굴절률 측정법 등을 이용하여 인위적인 물 첨 가를 확인함으로써 가수여부를 결정한다. 아세트산 유청법은 우유 100ml를 비 이커에 취하여 25% 아세트산용액 2ml를 가하여 교반한 다음 비이커 위에 시계접시를 올려 놓고 70℃의 수조에서 20분간 가온한 후 냉수중에서 10분 간 냉각시킨다 소형여과지로 여과하여 얻은 유청을 굴절계를 사용하여 정확 하게 20℃ 에서 굴절율을 측정한다. 굴절계의 눈금이 39이하이면 가수가 확실 하며, 39∼40인 경우에는 가수 가능성이 있다. 40이하이면 유청중의 회분을 다음과 같이 정량하여 가수여부를 판정한다 즉, 유청 25m1를 칭량한 증발접 시 또는 대형 도가니에 취하여 탕욕상에서 증발, 건조시킨 다음 작은 불꽃으 또 가열 탄화시켜 칭량하여 100ml당 g수로 환산하여 그 값이 0.715g 이하 인 경우에 가수되었다고 판정한다. 동유청법은 황산동 용액을 가하여 교반 여 과하여 20℃에서 굴절율을 측정하여 36이하이면 가수한 것으로 판정한다.</li>
						</ul>
					</li>
					<li>우유 빙점(feeezing Point) 측정
						<ul>
							<li>정상우유의 빙점은 -0.50∼-0.61℃이며, 평균 빙점은 -0.53∼-0.55℃이다. 우유의 어는점에는 주로 염류 및 유당의 함량이 관계타며, 단백질과 지방은 별로 영향을 미치지 않는다. 물을 가하면 빙점이 높아지므로 우유 빙점 측정은 가수검 사에 응용되며 1%의 가수에 의해서 어느점은 0.0055℃가 높아지므로 정밀한 우유 빙점검사기(Cryoscope)를 사용하면 3%의 가수 부정유의 검출은 가능하 다. 빙점을 측정하는 검사법으로는 Hortvet Cryoscope법과 Thermistor Cryoscope법이 있다. 이 기기를 표준화하기 위해서는 증류수, 7% 및 10% Sucrose의 3종에 대한 빙점을 보정한 후 사용해야 한다. 최근에는 Sucrose 대 신에 NaCl를 이용하여 보정하기도 한다. 빙점에 영향을 주는 요소로는 계절, 젖소의 연령, 건강상태, 품종, 가수여부, 사료, 날씨, 온도, 착유시간, 우유의 보 관상태 등이 있다.</li>
						</ul>
					</li>
					<li>중화유 감별법(Luckenbach법) 
						<ul>
							<li>우유 산도 상승에 따른 부적합 판정을 피하기 위하여 가성 소오다, 탄성소오다 및 수산화 칼슘 등 알칼리성 약품에 의한 중화 여부를 감별한다.  </li>
						</ul>
					</li>
					<li>두유 혼입 시험법 
						<ul>
							<li>우유에 두유혼입여부를 감별하는 시험법으로 Erdmann 및 Froede 시약 첨 가에 따른 색깔의 변화로 확인할 수 있다. </li>
						</ul>
					</li>
					<li>방부제 및 세균발육억제물질 검사법(TTC test)
						<ul>
							<li>우유중 세균발육억제물질(항생물질, 기타 화학물질) 혼입여부를 Streptococous thermophilus 균주를 이용하여 TTC 시약(2,3,5-triphenyl- 2,1,3,4- tetrazolium chloride) 환원, 즉 세균 발육억제 물질이 존재하지 않을 경우에 는 적색의 formazan을 형성하게 되어 우유샘플이 적색으로 변하나, 존재시는 우유 고유의 빛인 흰색으로 남게 된다. </li>
						</ul>
					</li>
					<li>지방시험법
						<ul>
							<li>Rose-Gottlieb법, Gerber법 Babcock법 등이 이용되고 있다.
								<ul>
									<li><b>Rose-Gottlieb법</b> : IDF의 표준방법으로서 암모니아와 알코올을 시료에 가하여 지방구를 싸고 있는 단백질 피막을 용해시킨 다름 2종의 에테르로 지방을 용해 추출하여 에테르 층만을 옮긴 다음 에테르를 증발시켜 지방을 측정하는 방법이다. </li>
									<li><b>Gerber법</b> : 국내에서 유지방 검사시 사용되는 표준방법이다. 시료중의 비지방분을 황산으로 처리하여 지방의 낮은 비중을 이용 분리하여 정제하 는 방법으로서, 겔벨유지계에 비중이 1.820∼1.825/15℃인 황산을 10 ml 넣고 시료 11ml를 서서히 유지계관 벽에 대고 서서히 넣은후 아밀알 콜 1ml를 넣고 혼합한 다음 60∼65℃ 항온수조에 15분 방치한 후 1000rpm에서 10분간 원심분리한다. 항온수조에 5분동안 방치한 후 나타 난 유지계의 눈금의 량, 즉 아래 메니스커스 최하단에서 위 메니스커스 최 하단 사이를 지방량으로 읽는다.</li>
									<li><b>Babcock법</b> : 원리는 Gerber법과 동일하며, 시료를 Babcock 유지계에 넣고 황산을 가한 후 혼합한 다음 700rpm에서 5분간 원심처리한 후 뜨 거운 물을 병의 경부까지 채운 다음 2분간 원심분리한다. 지방층의 눈금이 상단까지 오도록 뜨거운 물을 가한 뒤 1분간 원심분리한 후 65℃ 항온수 조에 병의 목이 잠기도록 3분이상 침지한 후 눈금을 읽는다.</li>
									<li><b>적외선 우유 분석기 (Infrared milk analyzer) </b> : 지방분자의 에스테르 결합에 있어서 Carbonyl군, 안백질 분자의 아미노산에 있어서 펩타이드 결합, 유당분자에 있어서 하이드록시에 의하여 특정 파장의 적외선 에너지 를 흡수하는 것에 기초를 둔 자동화기기이다. 이 기기는 시료의 교반에서 부터 결과표시까지 모든 과정이 자동화로 이루어지며, 이 기기는 지방, 단 백질, 유당의 공인된 표준방법으로 보정한 후 사용해야 한다.</li>
								</ul>
							</li>
						</ul>
					</li>
				</ul>
			</div>
  );
}

export default Do66;