import React from 'react';
import { Outlet } from 'react-router-dom';

import ScrollToTop from 'components/scrollToTop/ScrollToTop';
import Header from 'components/header/Header';
import Banner from 'components/banner/Banner';
import Footer from 'components/footer/Footer';

function Web() {
  
  return (  
    <>
      <ScrollToTop />
      {/* Skip Navigation 추가 */}
      <div id="skipnav">
        <a href="#header">헤더 바로가기</a>
        <a href="#main">본문 바로가기</a>
        <a href="#footer">푸터 바로가기</a>
      </div>
      
      <Header />
      <main id='main' className='main'>
        <h2 className='hidden'>본문 콘텐츠 영역</h2>
        <Outlet />
      </main>
      <Banner />
      <Footer />
    </>  
  );
}

export default Web;
