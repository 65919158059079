import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

// api
import { getState, postState } from 'utils/api';

// react-query
import { useQuery } from '@tanstack/react-query';

function BackupBreedBullView() {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const sid = searchParams.get('sid');

  const { data: breedBullData = {}, isError } = useQuery({
    queryKey: ['backupBreedBull', sid],
    queryFn: async () => {
      if (!sid) return {};
      const response = await getState('/board/select_backup_breed_bull', {
        sid,
      });

      if (response?.return_code === 'SUCCESS') {
        return response.result;
      }
      throw new Error('Failed to fetch breed bull data');
    },
    enabled: !!sid,
  });

  const handleDelete = async () => {
    if (!window.confirm('삭제하시겠습니까?')) {
      return;
    }

    try {
      const response = await postState('/board/delete_backup_breed_bull/', {
        sid,
      });

      if (response?.return_code === 'SUCCESS') {
        alert('삭제되었습니다.');
        navigate('/bo/breedBull/backupBreedBull/list');
      }
    } catch (error) {
      console.error('Error deleting breed bull:', error);
      alert('삭제 중 오류가 발생했습니다.');
    }
  };

  return (
    <div className="bo-page">
      <h2>후보 종모우 상세</h2>

      <div
        className="table-container bold-border"
        style={{ marginBottom: '1rem' }}
      >
        <table>
          <colgroup>
            <col width="20%" />
            <col width="" />
          </colgroup>
          <tbody>
            <tr>
              <th>정액코드</th>
              <td>{breedBullData?.s_code}</td>
            </tr>
            <tr>
              <th>단축명호</th>
              <td>{breedBullData?.s_sortname}</td>
            </tr>
            <tr>
              <th>영문정식명칭</th>
              <td>{breedBullData?.s_fullname_e}</td>
            </tr>
            <tr>
              <th>생년월일</th>
              <td>{breedBullData?.s_birth}</td>
            </tr>
            <tr>
              <th>원산지</th>
              <td>{breedBullData?.s_origin}</td>
            </tr>
            <tr>
              <th>부(영문정식명칭)</th>
              <td>{breedBullData?.s_sire_e}</td>
            </tr>
            <tr>
              <th>모(영문정식명칭)</th>
              <td>{breedBullData?.s_dam_e}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="button-group">
        <button
          type="button"
          className="btn-l"
          onClick={() =>
            navigate(`/bo/breedBull/backupBreedBull/edit?sid=${sid}`)
          }
        >
          수정
        </button>
        <button type="button" className="btn-l" onClick={handleDelete}>
          삭제
        </button>
        <button
          type="button"
          className="btn-l"
          onClick={() => navigate('/bo/breedBull/backupBreedBull/list')}
        >
          목록
        </button>
      </div>
    </div>
  );
}

export default BackupBreedBullView;
