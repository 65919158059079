import React from 'react';


function Do63() {

  return (
    <div className="techinfoview">
				<h1>질병예방 관리-질병의 조기발견</h1>
				<p className="pstyle">&nbsp;&nbsp;&nbsp;건강한 송아지와 육성우를 길러내는데 가장 중요한 것은 환경관리로서 각종 질병 매개체에 노출되지 않도록 하는 것이다. 갓 태어난 송아지는 각종 질병 매개체로부터 감염되기 쉬우며, 생후 1개월령이 될 때까지는 자체 면역을 형성하지 못한다. 물론 초유가 질병에 대한 저항력을 부여하기는 하지만 위생관리나 질병 예방조치가 불충분하다면 각종 질병으로부터 완전히 보호될 수는 없다. </p>
				<h2>질병의 조기 발견 </h2>
				<p className="pstyle">&nbsp;&nbsp;&nbsp;가축은 말을 하지 못하는 동물이기 때문에 건강에 이상이 생겨도 의사표현을 말로서 나타내지 못한다. 그러나 신체에 나타난 이상상태는 자연히 외모와 거동에 나타나므로 사육자가 항상 주의하여 관찰한다면 질병으로 인하여 외부로 나타나는 이상 상태를 충분히 발견할 수 있다. 낙농가가 송아지의 질병을 조기에 발견하기 위해서는 항상 사육하는 젖소를 가까이 하여서 건강할 때의 개체적 특징들을 잘 파악해 둘 필요가 있다. 그러나 송아지의 병적 이상상태가 외모나 태도에 반영되어 나타난 경우는 이미 그 병의 상태가 상당히 진행되어 있으므로 낙농가는 평상시 관리를 소홀히 해서는 안된다. 만일 송아지의 질병을 조기에 발견한다면 진단과 치료가 용이해지고 병을 발생 초기에 치료할 수 있어서 질병이 속히 회복되며, 치료비가 절약될 뿐만 아니라 젖소의 경제적 능력을 빨리 회복시킬수 있다. 또한 다른 개체에 전염되는 전염병일 경우 전염하기 전에 예방할 수 있는 시간적 여유를 가질 수 있다. <br/>&nbsp;&nbsp;&nbsp;젖소에 질병이 발생하였을 때 외부적으로 나타나는 여러 가지 사항을 기술하면 다음과 같다. </p>
				<ul>
					<li> 행동의 변화 
						<ul>
							<li>행동이 활발하지 못하고 침울한 상태, 기운이 쇠퇴하고 활기가 없는 눈초리, 방목장이나 운동장에서 같은 젖소의 무리와 동떨어져서 홀로 침울하게 서 있는 모습, 누운채 기립이 곤란한 상태, 포유시간에 식욕을 느끼지 않는 송아지, 암소의 성질이 횡포해지고 수소체격으로 변하는 경우, 물통을 씹으며 빙글빙글 선회하다가 쓰러지고 치료할 때 매우 신경질적으로 소란을 피우는 소, 흙을 핥아 먹거나 나무조각을 씹는 소등은 건강상 어떠한 변화가 있다는 표시이다. </li>
						</ul>
					</li>
					<li>눈모양의 변화
						<ul>
							<li>건강한 젖소의 눈은 티없이 맑고 반짝이며 눈꺼풀은 긴장성과 탄력성이 있어 보이고 안구의 운동과 눈꺼풀의 개폐운동은 매우 건전하고 활달하다. </li>
							<li>안구에 핏발이 서 있고 눈에 안전성이 없으며 날카로운 느낌을 보일 때에 젖소는 대개 발정중이거나 충격을 받아 놀라거나 또는 매우 흥분되어 있는 경우가 많다. </li>
							<li>눈에 빛이 없어지고 안구운동에 민활성이 없어졌을 때에는 병적이상이 있다고 생각할 수 있다. </li>
							<li> 안구가 가늘게 진동하고 동공이 확대되어 있으며 눈꺼풀의 운동반사가 없어지는 등의 증상은 열사병, 신경성 질환, 유열 등의 대사성질환에서 나타날 수 있다. </li>
						</ul>
					</li>
					<li> 체표면의 변화 
						<ul>
							<li>피모 
								<ul>
									<li>일반적으로 영양상태가 좋고 건강한 유우의 피모는 윤기가 있어서 반질반질 하며 털의 길이가 짧다. 그러나 사양관리가 불량하고 영양이 불충분한 소와 그 밖의 만성질병이 있는 유우는 피모에 광택이 없어지며 거칠고 딱딱한 느낌이 있으며 불결해 보인다. </li>
								</ul>
							</li>
							<li> 피부 및 점막의 색깔
								<ul>
									<li>피부의 빛깔로는 붉은 빛깔의 충혈, 검붉은 빛깔, 황색, 창백한 빛깔등이 있는데 이들의 변화를 주의하여 본다. 흰 피부에 생기는 붉은 빛깔은 염증 때문에 생기는 충혈인 경우가 많다. <br/>건강한 동물의 점막은 엷은 분홍색을 띠는 것이 보통이다. 점막이 창백하거나 황색을 띠면 병적 상태라고 볼 수 있다. 그러나 소가 발정기에 들어 질점막이 붉게 충혈되고 약간 부어 오르는 것은 정상적 생리현상이다.  </li>
								</ul>
							</li>
							<li>부종 
								<ul>
									<li>부종이란 조직액이 피부 밑 피하조직내에 고여 있는 상태를 말한다. </li>
								</ul>
							</li>
							<li>땀흘림 
								<ul>
									<li>병적으로 땀을 많이 흘리는 발한과다는 주로 폐출혈, 폐기종, 폐렴등 심한 호흡곤란을 일으키는 질병, 장폐쇄, 급성식체, 고창증등 통증이 매우 심한 질병, 정신흥분을 일으키는 질병 등에서 볼 수 있다. </li>
								</ul>
							</li>
							<li>가려움증 
								<ul>
									<li>가려움이 생기는 원인으로는 오랫동안 피부 빗질을 하지 않아서 피부에 때가 많이 끼어 피부염이 생기거나 소버짐, 이, 모낭충등의 피부기생충이 기생하고 있는 경우가 많고 때로는 알레르기일 때도 있다. </li>
								</ul>
							</li>
						</ul>
					</li>
					<li>체온의 변화 
						<ul>
							<li>체온은 유우의 질병을 진단하는데 중요한 지표가 된다. 특히, 급성 전염병의 초기, 각종 염증성 질환, 일사병등에 있어서는 체온이 상승한다. </li>
						</ul>
					</li>
					<li>맥박의 변화 
						<ul>
							<li>폐렴, 패혈증, 창상성심낭염, 심내막염 및 악성림프종이 심장부에 생겼을 때에는 맥박에 변화가 일어난다. </li>
						</ul>
					</li>
					<li>호흡의 변화 
						<ul>
							<li>소가 수송열, 전염성기관지염, 송아지 폐렴 및 그밖의 열성질환을 앓을 때에는 특히 호흡수에 변화가 일어난다.  </li>
						</ul>
					</li>
					<li>침 흘림
						<ul>
							<li>인후두부의 마비로 인한 연하장애, 구강에 날카로운 물질이 찔려 있어서 통증을 느낄때 또는 각종 구강염, 인후두부의 궤양, 치아질환, 식도패쇄 및 중독증에 걸려 있을 때에는 침을 많이 흘린다. 이에 반하여 체온이 높거나 심한 설사를 할 때에는 탈수 상태에 빠지므로 침이 말라버린다.</li>
						</ul>
					</li>
					<li>코와 코흘림 
						<ul>
							<li>전신 상태가 건전하지 못한 소는 감각이 둔하고 무관심해져서 혀로 비공을 핥아 내지 않기 때문에 짙은 콧물이 코 끝에 달리게 된다. </li>
						</ul>
					</li>
					<li>식욕과 반추의 변화
						<ul>
							<li>식욕이 좋고 나쁜 것은 건강의 척도로 삼을 수 있으므로 식욕의 변화는 사육자가 질병을 조기에 발견하는데 크게 도움이 될 수 있다. 병적인 식욕부진 또는 식욕감퇴는 구강염, 치아질환, 혀, 인후두부등의 염증, 소화기계의 질병, 케토시스와 같은 대사성 질병, 급성열병, 전염병등에서 볼 수 있다. <br/>반추란 반추위로부터 식도를 거쳐 구강에 이르는 식괴의 역류, 구강에서의 재저작과 침과의 재혼합 및 재연하의 과정을 말한다. 반추횟수와 트림횟수가 감소되거나 반추가 약해지거나 완전 정지되는 현상은 질병의 발생과 관계가 깊다. </li>
						</ul>
					</li>
					<li>배설물 
						<ul>
							<li>분
								<ul>
									<li>분은 섭취한 사료를 소화 흡수하고 남은 잔사물이며 질병의 성질을 판단하는 데 도움이 된다. 일상 젖소를 사양관리하는데 있어서 분의 양, 빛깔, 냄새 및 굳기의 변화와 분에 혼합되어있는 어떤 혼합물의 성질등을 눈으로 관찰하고 냄새를 맡아 이상을 알아보는 것 만으로도 젖소 질병을 판단하는데 크게 도움이 된다. </li>
								</ul>
							</li>
							<li>오줌 
								<ul>
									<li>오줌에 관해서는 배뇨회수, 배뇨시의 태도, 오줌의 빛깔 등을 주의해서본다. </li>
								</ul>
							</li>
						</ul>
					</li>
					<li>보행
						<ul>
							<li>보행에 이상이 생겨서 발을 저는 것을 발절음 또는 파행이라고 한다. 이 발절음현상은 지관절의 탈구, 골절, 뼈의 타박상, 운동신경장애 등이 있을 때 매우 심하게 나타난다. &lt;표 71&gt;은 여러 가지 질병의 증상과 원인에 대해 설명한 것으로 이들을 질병의 조기 발견에 활용한다면 도움이 될 것이다. </li>
						</ul>
						<p><b>&lt;표 71&gt; 여러 가지 질병의 증상과 원인</b></p>	
						<table className="tablestyle">
							<tr>
								<td>증상</td>
								<td>원인</td>
							</tr>
							<tr>
								<td>체온 41.5`C 이상</td>
								<td>패혈증, 폐염, 제대염, 세균감염, 렙토스피라, 파라인 플루엔자, 전염성 비기관염(IBR).디프테리아.살모넬라균증</td>
							</tr>
							<tr>
								<td>눈물흘림</td>
								<td>먼지, 자극, 핑크아이, 전염성비기관염(IBR), 파라인 플루엔자</td>
							</tr>
							<tr>
								<td>뿌옇게된 눈</td>
								<td>핑크아이, IBR, 비타민A부족증, 렙토스피라</td>
							</tr>
							<tr>
								<td>눈이 푹 꺼짐</td>
								<td>설사에 의한 탈수, 비전염성 설사, 살모넬라균증, 콕시듐증, 바이러스성 설사(BVD)</td>
							</tr>
							<tr>
								<td>콧물흘림</td>
								<td>폐염, IBR, BVD</td>
							</tr>
							<tr>
								<td>설사</td>
								<td>사료이상, 과식, 세균성 설사, BVD</td>
							</tr>
							<tr>
								<td>호 흡 촉 박</td>
								<td>고열, 폐염,IBR, 빈혈증, 급성폐염, 디프테리아 </td>
							</tr>
							<tr>
								<td>기침</td>
								<td>폐염, 폐충, IBR, 파라인플루엔자</td>
							</tr>
							<tr>
								<td>원기부족, 복부팽대</td>
								<td>영양부족, 만성폐염, 기생충증  </td>
							</tr>
							<tr>
								<td>갑작스런 폐사</td>
								<td>패혈증, 기종저, 중독증</td>
							</tr>
							<tr>
								<td>다리절음, 경직</td>
								<td>화농성관절염, 구루병</td>
							</tr>
							<tr>
								<td>경련</td>
								<td>선천적인 뇌손상, 비타민B1 부족, 중독증, 납중독, 패혈증, 마그네슘부족</td>
							</tr>
							<tr>
								<td>혈뇨</td>
								<td>렙토스피라, 신장염증, 찬물 과잉섭취, 입 , 목부위 종양 BVD, 디프테리아</td>
							</tr>
						</table>
					</li>
				</ul>
			</div>
  );
}

export default Do63;
