import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './mainbusiness.scss';
import arrow from 'assets/images/containers/main/arrow.png';
import shape from 'assets/images/containers/main/shape.png';

const MainBusiness = ({ slides }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const businessLinks = {
    '유우군 능력 검정사업': '/Web/Business/BestCowTest',
    '후대검정 사업': '/Web/Business/DescendantCow',
    '씨수소 사양관리': '/Web/Business/SuperiorCow',
  };
  const nextPage = () => {
    setCurrentSlide((prev) => (prev + 1) % slides.length);
  };

  const prevPage = () => {
    setCurrentSlide((prev) => (prev > 0 ? prev - 1 : slides.length - 1));
  };

  const currentInfo = slides[currentSlide];

  return (
    <div className="businessCon">
      <div className="business-slider">
        <div className="business-info">
          <div className="row-con">
            <div className="info-group">
              <h2 className="business-title">
                젖소개량사업소<span> 주요사업안내</span>
              </h2>
              <h3>{currentInfo.title}</h3>
              <p>{currentInfo.description}</p>
              <ul className="info-list">
                {currentInfo.infoList.map((item, index) => (
                  <li key={index} className="info-item">
                    <Link
                      to={businessLinks[currentInfo.title] || '#'}
                      className="info-link"
                    >
                      <span>{item}</span>
                      <div className="info-plus"></div>{' '}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            <div className="bgCon">
              <div className="image-container">
                {currentInfo.images.map((image, index) => (
                  <img
                    key={index}
                    src={image}
                    alt={`Business Slide ${currentSlide}`}
                  />
                ))}
              </div>
              <div className="pagination">
                <button type="button" onClick={prevPage}>
                  &lt;
                </button>
                <span>{`${currentSlide + 1} / ${slides.length}`}</span>
                <button type="button" onClick={nextPage}>
                  &gt;
                </button>
              </div>
            </div>
          </div>
          {/* //// */}
          <span className="shape">
            <img src={shape} alt="blackshape" />
          </span>
        </div>
      </div>
    </div>
  );
};

export default MainBusiness;
