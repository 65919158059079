import React, { useState , useEffect } from 'react';
import {
  ResponsiveContainer,
  LineChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Line,
  Label
} from 'recharts';

// api
import { postState } from 'utils/api';
import { openCrownix } from 'utils/openCrownix';

// 이미지
import PrintPDF from 'assets/svgs/common/print_PDF.svg';
import Logo from 'assets/images/common/header_logo_pc.png';

// 컴포넌트
import ErrorMessage from 'components/errorMessage/errorMessage';
import MonthCode from 'components/dcitest/monthCode/MonthCode';

// scss
import './NewFCMFPCMECM.scss';

function NewFCMFPCMECM() {

  // 에러처리
  const [isModalOpen, setIsModalOpen] = useState(false); // 모달 추가
  const [errorMessage, setErrorMessage] = useState(''); 

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => {
    setIsModalOpen(false);
    setErrorMessage(''); // 모달이 닫힐 때 에러메세지 초기화
  };

  // 데이터 불러오기
  const baseDir='/dcitest/dciexpert/newfcmfpcmecm/';
  const [ mainData , setMainData ] = useState();

  const [ props , setProps] = useState({
    id:`${localStorage.getItem('cong')}${localStorage.getItem('farm')}`,
    passwd:localStorage.getItem('passwd'),
    date:localStorage.getItem('month')
  });

  useEffect(() => {

    postState(baseDir,props)
    .then(getdata => {      
      if (getdata !== undefined) {
    //   console.log ('#### props : ' ,props);
      setMainData(getdata);
    //   console.log ('#### data : ' ,getdata);
      }
    })
    .catch(error => {      
    //   console.error('Error:', error.message);      
      setErrorMessage(error.message);
      openModal();
    });
    
  }, [props]);

  return (
    <div className='pdfMainArea'>
      <ErrorMessage isOpen={isModalOpen} content={errorMessage} closeModal={closeModal}/>
      <div className='pdfMainHeader'>
        <button 
          type='button'
          onClick={()=> openCrownix('New_fcmfpcmecm.mrd')}
        >
          <img src={PrintPDF} alt='PDF로 인쇄하기' />
          인쇄하기
        </button>
        { 
          props && 
          <MonthCode 
            props={props}
            setProps={setProps}
            setErrorMessage={setErrorMessage}
            openModal={openModal}
          />
        }
      </div>
      <div className='pdfArea'>
        <div className='newFCMFPCMECMPdf'>
          <h1>{props.date.substring(0,4)}년 {props.date.slice(-2)}월 FCM, FPCM, ECM을 이용한 분석</h1>           
          
          <table className='pdfTable'>
            <thead>
              <tr>
                <th>검정월</th>
                <th>평균산차</th>
                <th>초산우비율(%)</th>
                <th>초산월령</th>
                <th>분만간격</th>
                <th>비유일수</th>
                <th>FCM</th>
                <th>FPCM</th>
                <th>ECM</th>
                <th>유량(kg)</th>
                <th>유지(%)</th>
                <th>단백(%)</th>
                <th>고형(%)</th>
              </tr>            
            </thead>
            <tbody>
              {
                mainData &&
                mainData.plot_data.map((item, index) => (
                  <tr key={index}>
                    <td>{item.tmon}</td>
                    <td className='numberData'>{item.avgpari}</td>
                    <td className='numberData'>{item.chosanrate}</td>
                    <td className='numberData'>{item.chosan}</td>
                    <td className='numberData'>{item.buninter}</td>
                    <td className='numberData'>{item.avgmday.toFixed()}</td>
                    <td className='numberData'>{item.fcm}</td>
                    <td className='numberData'>{item.fpcm}</td>
                    <td className='numberData'>{item.ecm}</td>
                    <td className='numberData'>{item.avgmilk}</td>
                    <td className='numberData'>{item.fatp}</td>                    
                    <td className='numberData'>{item.protp}</td>
                    <td className='numberData'>{item.snfp}</td>                  
                  </tr>
                ))
              }
            </tbody>
          </table>
          {
            mainData && (
            <div className='barChartArea' >
              <ResponsiveContainer width="100%" height={600}>
                <LineChart
                  width="90%"
                  height="80%"
                  data={mainData.plot_data2}
                  margin={{              
                    top: 20,
                    right: 20,
                    bottom: 20,
                    left: 20,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="tmon">
                    <Label value="검정월" offset={-15} position="insideBottom" />
                  </XAxis>
                  <YAxis>
                    <Label value="유량(Kg)" offset={0} position="insideLeft" angle='-90'/>
                  </YAxis>
                  <Tooltip />
                  <Line dataKey="fcm" stroke="violet" name='FCM' legendType='square'/>
                  <Line dataKey="fpcm" stroke="red" name='FPCM' legendType='square'/>
                  <Line dataKey="ecm" stroke="green" name='ECM' legendType='square'/>
                  <Line dataKey="avgmilk" stroke="yellow" name='유량' legendType='square'/>
                  {/* <Legend offset={-30}/> */}
                </LineChart>
              </ResponsiveContainer>
              <ul className='dcmfpcmecmLabel'>
                <li>FCM</li>
                <li>FPCM</li>
                <li>ECM</li>
                <li>유량</li>
              </ul>        
            </div> )
          }
          <img src={Logo} alt='농협경제지주 젖소개량사업소 로고' className='pdfLogo'/>
        </div>
      </div>
    </div>    
  );
}

export default NewFCMFPCMECM;
