import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { getState, postState } from 'utils/api';

function OriginBreedBullEdit() {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const sCode = searchParams.get('s_code');

  const [formData, setFormData] = useState({
    s_code: '',
    s_fullcode: '',
    s_reg: '',
    s_sortname: '',
    s_ssortname: '',
    s_sortname_e: '',
    s_ssortname_e: '',
    s_fullname_e: '',
    s_fullname_k: '',
    s_origin: '',
    s_unit: '',
    s_danga: '',
    s_storage: '',
    s_sire_e: '',
    s_sire_k: '',
    s_sire_reg: '',
    s_dam_e: '',
    s_dam_k: '',
    s_dam_reg: '',
    s_mgs_e: '',
    s_mgs_k: '',
    s_mgs_reg: '',
    s_estimate: '',
    s_tpi: '',
    s_ptam: '',
    s_pta_rel: '',
    s_ptaf: '',
    s_ptafp: '',
    s_ptap: '',
    s_ptapp: '',
    s_ptat: '',
    s_ptat_rel: '',
    s_udc: '',
    s_sta15: '',
    s_sta16: '',
    s_sta17: '',
    s_sta18: '',
    s_sta19: '',
    s_scs: '',
    s_sta20: '',
    s_sta21: '',
    s_sta22: '',
    s_sta1: '',
    s_sta2: '',
    s_sta3: '',
    s_sta4: '',
    s_sta5: '',
    s_sta6: '',
    s_sta7: '',
    s_sta8: '',
    s_sta9: '',
    s_sta10: '',
    s_sta11: '',
    s_sta12: '',
    s_sta13: '',
    s_sta14: '',
    s_spec: '',
    s_spec_e: '',
    s_memo: '',
    s_sajin_url: '',
    s_dt_sajin_url: '',
  });

  const [files, setFiles] = useState({
    s_sajin_url: null,
    s_dt_sajin_url: null,
  });
  const [deleteFiles, setDeleteFiles] = useState([]);
  const [addFiles, setAddFiles] = useState({
    s_sajin_url: null,
    s_dt_sajin_url: null,
  });

  useEffect(() => {
    const fetchBreedBullData = async () => {
      if (sCode) {
        try {
          const response = await getState('/board/select_breed_bull', {
            s_code: sCode,
          });

          if (response?.return_code === 'SUCCESS') {
            setFormData(response.result);
          }
        } catch (error) {
          console.error('Error fetching breed bull data:', error);
        }
      }
    };

    fetchBreedBullData();
  }, [sCode]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const { name, files: uploadedFiles } = e.target;

    if (!uploadedFiles || !uploadedFiles[0]) {
      setFiles((prev) => ({
        ...prev,
        [name]: null,
      }));
      setFormData((prev) => ({
        ...prev,
        [name]: '',
      }));
      setDeleteFiles((prev) => {
        const result = [...prev];
        result.push(name);
        return [...new Set(result)];
      });
      return;
    }

    // is Edit
    if (sCode) {
      setAddFiles((prev) => ({
        ...prev,
        [name]: uploadedFiles[0],
      }));
      setDeleteFiles((prev) => {
        const result = [...prev];
        result.push(name);
        return [...new Set(result)];
      });
    }

    setFiles((prev) => ({
      ...prev,
      [name]: uploadedFiles[0],
    }));
    setFormData((prev) => ({
      ...prev,
      [name]: uploadedFiles[0].name,
    }));
  };

  const handleFileDelete = (name) => {
    setFiles((prev) => ({
      ...prev,
      [name]: null,
    }));
    setFormData((prev) => ({
      ...prev,
      [name]: '',
    }));
    setDeleteFiles((prev) => {
      const result = [...prev];
      result.push(name);
      return [...new Set(result)];
    });
  };

  const handleSubmit = async () => {
    // Validation
    if (!formData.s_code) {
      alert('정액코드를 입력해주세요.');
      return;
    }

    if (!formData.s_estimate) {
      alert('평가기준일을 입력해주세요.');
      return;
    }

    if (!formData.s_fullcode) {
      alert('FULL코드를 입력해주세요.');
      return;
    }

    const confirmMessage = sCode ? '수정하시겠습니까?' : '등록하시겠습니까?';
    if (!window.confirm(confirmMessage)) {
      return;
    }

    try {
      const formDataToSend = new FormData();
      Object.entries(formData).forEach(([key, value]) => {
        if (key !== 's_sajin_url' && key !== 's_dt_sajin_url') {
          formDataToSend.append(key, value);
        }
      });

      if (sCode) {
        Object.entries(addFiles).forEach(([key, file]) => {
          if (file) {
            formDataToSend.append(`add_${key}_file`, file);
          }
        });
        if (deleteFiles.length > 0) {
          formDataToSend.append('delete_file_list', deleteFiles);
        }
      } else {
        Object.entries(files).forEach(([key, file]) => {
          if (file) {
            formDataToSend.append(`${key}_file`, file);
          }
        });
      }

      //   Array.from(formDataToSend.entries()).forEach(([key, value]) => {
      //     console.log(key, value);
      //   });

      const endpoint = `/board/${sCode ? 'update' : 'insert'}_breed_bull/`;
      const response = await postState(endpoint, formDataToSend);

      //   console.log(response);

      if (response?.return_code === 'SUCCESS') {
        alert(`${sCode ? '수정' : '등록'}되었습니다.`);
        navigate('/bo/breedBull/originBreedBull/list');
      }
      if (response?.return_code === 'ERROR') {
        alert(response?.return_message);
      }
    } catch (error) {
      console.error('Error saving breed bull:', error);
      alert('저장 중 오류가 발생했습니다.');
    }
  };

  const handleDelete = async () => {
    if (!window.confirm('삭제하시겠습니까?')) {
      return;
    }

    try {
      const response = await postState('/board/delete_breed_bull/', {
        s_code: sCode,
      });

      if (response?.return_code === 'SUCCESS') {
        alert('삭제되었습니다.');
        navigate('/bo/breedBull/originBreedBull/list');
      }
      if (response?.return_code === 'ERROR') {
        alert(response?.return_message);
      }
    } catch (error) {
      console.error('Error deleting breed bull:', error);
      alert('삭제 중 오류가 발생했습니다.');
    }
  };

  return (
    <div className="bo-page">
      <h2>종모우 {sCode ? '수정' : '등록'}</h2>

      {/* 종모우정보 */}
      <div
        className="table-container bold-border"
        style={{ marginBottom: '1rem' }}
      >
        <table>
          <colgroup>
            <col width="15%" />
            <col width="" />
            <col width="15%" />
            <col width="" />
          </colgroup>
          <tbody>
            <tr>
              <th colSpan="4">종모우정보</th>
            </tr>
            <tr>
              <th>
                FULL코드<span className="required">*</span>
              </th>
              <td colSpan="3">
                <input
                  type="text"
                  name="s_fullcode"
                  value={formData?.s_fullcode}
                  onChange={handleInputChange}
                  maxLength={100}
                />
              </td>
            </tr>
            <tr>
              <th>
                정액코드<span className="required">*</span>
              </th>
              <td>
                {sCode ? (
                  <span>{formData?.s_code}</span>
                ) : (
                  <input
                    type="text"
                    name="s_code"
                    value={formData?.s_code}
                    onChange={handleInputChange}
                    maxLength={10}
                  />
                )}
              </td>
              <th>등록번호</th>
              <td>
                <input
                  type="text"
                  name="s_reg"
                  value={formData?.s_reg}
                  onChange={handleInputChange}
                  maxLength={10}
                />
              </td>
            </tr>
            <tr>
              <th>단축명호</th>
              <td>
                <input
                  type="text"
                  name="s_sortname"
                  value={formData?.s_sortname}
                  onChange={handleInputChange}
                  maxLength={100}
                />
              </td>
              <th>아비혈통</th>
              <td>
                <input
                  type="text"
                  name="s_ssortname"
                  value={formData?.s_ssortname}
                  onChange={handleInputChange}
                  maxLength={100}
                />
              </td>
            </tr>
            <tr>
              <th>E단축명호</th>
              <td>
                <input
                  type="text"
                  name="s_sortname_e"
                  value={formData?.s_sortname_e}
                  onChange={handleInputChange}
                  maxLength={100}
                />
              </td>
              <th>E아비혈통</th>
              <td>
                <input
                  type="text"
                  name="s_ssortname_e"
                  value={formData?.s_ssortname_e}
                  onChange={handleInputChange}
                  maxLength={100}
                />
              </td>
            </tr>
            <tr>
              <th>E정식명칭</th>
              <td>
                <input
                  type="text"
                  name="s_fullname_e"
                  value={formData?.s_fullname_e}
                  onChange={handleInputChange}
                  maxLength={100}
                />
              </td>
              <th>K정식명칭</th>
              <td>
                <input
                  type="text"
                  name="s_fullname_k"
                  value={formData?.s_fullname_k}
                  onChange={handleInputChange}
                  maxLength={100}
                />
              </td>
            </tr>
            <tr>
              <th>원산지</th>
              <td>
                <input
                  type="text"
                  name="s_origin"
                  value={formData?.s_origin}
                  onChange={handleInputChange}
                  maxLength={1}
                />
              </td>
              <th>단위</th>
              <td>
                <input
                  type="text"
                  name="s_unit"
                  value={formData?.s_unit}
                  onChange={handleInputChange}
                  maxLength={2}
                />
              </td>
            </tr>
            <tr>
              <th>단가</th>
              <td>
                <input
                  type="text"
                  name="s_danga"
                  value={formData?.s_danga}
                  onChange={handleInputChange}
                  maxLength={10}
                />
              </td>
              <th>재고</th>
              <td>
                <input
                  type="text"
                  name="s_storage"
                  value={formData?.s_storage}
                  onChange={handleInputChange}
                  maxLength={10}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* 혈통정보 */}
      <div
        className="table-container bold-border"
        style={{ marginBottom: '1rem' }}
      >
        <table>
          <colgroup>
            <col width="10%" />
            <col width="" />
            <col width="10%" />
            <col width="" />
            <col width="10%" />
            <col width="" />
          </colgroup>
          <tbody>
            <tr>
              <th colSpan="6">혈통정보</th>
            </tr>
            <tr>
              <th>부(E-NAME)</th>
              <td>
                <input
                  type="text"
                  name="s_sire_e"
                  value={formData?.s_sire_e}
                  onChange={handleInputChange}
                  maxLength={100}
                />
              </td>
              <th>부(K-NAME)</th>
              <td>
                <input
                  type="text"
                  name="s_sire_k"
                  value={formData?.s_sire_k}
                  onChange={handleInputChange}
                  maxLength={100}
                />
              </td>
              <th>부(등록번호)</th>
              <td>
                <input
                  type="text"
                  name="s_sire_reg"
                  value={formData?.s_sire_reg}
                  onChange={handleInputChange}
                  maxLength={20}
                />
              </td>
            </tr>
            <tr>
              <th>모(E-NAME)</th>
              <td>
                <input
                  type="text"
                  name="s_dam_e"
                  value={formData?.s_dam_e}
                  onChange={handleInputChange}
                  maxLength={100}
                />
              </td>
              <th>모(K-NAME)</th>
              <td>
                <input
                  type="text"
                  name="s_dam_k"
                  value={formData?.s_dam_k}
                  onChange={handleInputChange}
                  maxLength={100}
                />
              </td>
              <th>모(등록번호)</th>
              <td>
                <input
                  type="text"
                  name="s_dam_reg"
                  value={formData?.s_dam_reg}
                  onChange={handleInputChange}
                  maxLength={20}
                />
              </td>
            </tr>
            <tr>
              <th>외조부(E-NAME)</th>
              <td>
                <input
                  type="text"
                  name="s_mgs_e"
                  value={formData?.s_mgs_e}
                  onChange={handleInputChange}
                  maxLength={100}
                />
              </td>
              <th>외조부(K-NAME)</th>
              <td>
                <input
                  type="text"
                  name="s_mgs_k"
                  value={formData?.s_mgs_k}
                  onChange={handleInputChange}
                  maxLength={100}
                />
              </td>
              <th>외조부(등록번호)</th>
              <td>
                <input
                  type="text"
                  name="s_mgs_reg"
                  value={formData?.s_mgs_reg}
                  onChange={handleInputChange}
                  maxLength={20}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* 능력정보 */}
      <div
        className="table-container bold-border"
        style={{ marginBottom: '1rem' }}
      >
        <table>
          <colgroup>
            <col width="10%" />
            <col width="10%" />
            <col width="10%" />
            <col width="10%" />
            <col width="10%" />
            <col width="10%" />
            <col width="10%" />
            <col width="10%" />
            <col width="10%" />
          </colgroup>
          <tbody>
            <tr>
              <th colSpan="9">능력정보</th>
            </tr>
            <tr>
              <th colSpan="2">
                평가기준일<span className="required">*</span>
              </th>
              <td colSpan="7">
                <input
                  type="text"
                  name="s_estimate"
                  value={formData?.s_estimate}
                  onChange={handleInputChange}
                  maxLength={10}
                />
              </td>
            </tr>
            <tr>
              <th rowSpan="2">생산능력</th>
              <th>종합지수(TPI)</th>
              <td>
                <input
                  type="text"
                  name="s_tpi"
                  value={formData?.s_tpi}
                  onChange={handleInputChange}
                  maxLength={10}
                />
              </td>
              <th>유량(PTAM)</th>
              <td>
                <input
                  type="text"
                  name="s_ptam"
                  value={formData?.s_ptam}
                  onChange={handleInputChange}
                  maxLength={10}
                />
              </td>
              <th>신뢰도(REL)</th>
              <td colSpan="3">
                <input
                  type="text"
                  name="s_pta_rel"
                  value={formData?.s_pta_rel}
                  onChange={handleInputChange}
                  maxLength={10}
                />
              </td>
            </tr>
            <tr>
              <th>유지방(PTAF)</th>
              <td>
                <input
                  type="text"
                  name="s_ptaf"
                  value={formData?.s_ptaf}
                  onChange={handleInputChange}
                  maxLength={10}
                />
              </td>
              <th>유지방율(%)</th>
              <td>
                <input
                  type="text"
                  name="s_ptafp"
                  value={formData?.s_ptafp}
                  onChange={handleInputChange}
                  maxLength={10}
                />
              </td>
              <th>유단백(PTAP)</th>
              <td>
                <input
                  type="text"
                  name="s_ptap"
                  value={formData?.s_ptap}
                  onChange={handleInputChange}
                  maxLength={10}
                />
              </td>
              <th>유단백율(%)</th>
              <td>
                <input
                  type="text"
                  name="s_ptapp"
                  value={formData?.s_ptapp}
                  onChange={handleInputChange}
                  maxLength={10}
                />
              </td>
            </tr>
            <tr>
              <th rowSpan="2">체형능력</th>
              <th>체형(PTAT)</th>
              <td>
                <input
                  type="text"
                  name="s_ptat"
                  value={formData?.s_ptat}
                  onChange={handleInputChange}
                  maxLength={10}
                />
              </td>
              <th>체형신뢰도(%)</th>
              <td>
                <input
                  type="text"
                  name="s_ptat_rel"
                  value={formData?.s_ptat_rel}
                  onChange={handleInputChange}
                  maxLength={10}
                />
              </td>
              <th>유방지수(UDC/MS)</th>
              <td>
                <input
                  type="text"
                  name="s_udc"
                  value={formData?.s_udc}
                  onChange={handleInputChange}
                  maxLength={10}
                />
              </td>
              <th>유용능력(DF)</th>
              <td>
                <input
                  type="text"
                  name="s_sta15"
                  value={formData?.s_sta15}
                  onChange={handleInputChange}
                  maxLength={10}
                />
              </td>
            </tr>
            <tr>
              <th>신체형대(BD)</th>
              <td>
                <input
                  type="text"
                  name="s_sta16"
                  value={formData?.s_sta16}
                  onChange={handleInputChange}
                  maxLength={10}
                />
              </td>
              <th>엉덩이(RUMP)</th>
              <td>
                <input
                  type="text"
                  name="s_sta17"
                  value={formData?.s_sta17}
                  onChange={handleInputChange}
                  maxLength={10}
                />
              </td>
              <th>암유방지수(FUDD)</th>
              <td>
                <input
                  type="text"
                  name="s_sta18"
                  value={formData?.s_sta18}
                  onChange={handleInputChange}
                  maxLength={10}
                />
              </td>
              <th>뒷유방지수(RUDD)</th>
              <td>
                <input
                  type="text"
                  name="s_sta19"
                  value={formData?.s_sta19}
                  onChange={handleInputChange}
                  maxLength={10}
                />
              </td>
            </tr>
            <tr>
              <th>보조특징</th>
              <th>체세포(SCS)</th>
              <td>
                <input
                  type="text"
                  name="s_scs"
                  value={formData?.s_scs}
                  onChange={handleInputChange}
                  maxLength={10}
                />
              </td>
              <th>생산수명(PL)</th>
              <td>
                <input
                  type="text"
                  name="s_sta20"
                  value={formData?.s_sta20}
                  onChange={handleInputChange}
                  maxLength={10}
                />
              </td>
              <th>난산율(DBH)</th>
              <td>
                <input
                  type="text"
                  name="s_sta21"
                  value={formData?.s_sta21}
                  onChange={handleInputChange}
                  maxLength={10}
                />
              </td>
              <th>착유속도(MS)</th>
              <td>
                <input
                  type="text"
                  name="s_sta22"
                  value={formData?.s_sta22}
                  onChange={handleInputChange}
                  maxLength={10}
                />
              </td>
            </tr>
          </tbody>
        </table>
        <table>
          <colgroup>
            <col width="10%" />
            <col width="10%" />
            <col width="10%" />
            <col width="10%" />
            <col width="10%" />
            <col width="10%" />
            <col width="10%" />
            <col width="10%" />
            <col width="10%" />
            <col width="10%" />
            <col width="10%" />
          </colgroup>
          <tbody>
            <tr>
              <th rowSpan="5">세부체형</th>
              <th>정중제인대</th>
              <td>
                <input
                  type="text"
                  name="s_sta1"
                  value={formData?.s_sta1}
                  onChange={handleInputChange}
                  maxLength={10}
                />
              </td>
              <th>뒷다리각도</th>
              <td>
                <input
                  type="text"
                  name="s_sta7"
                  value={formData?.s_sta7}
                  onChange={handleInputChange}
                  maxLength={10}
                />
              </td>
              <th>발굽경사</th>
              <td>
                <input
                  type="text"
                  name="s_sta8"
                  value={formData?.s_sta8}
                  onChange={handleInputChange}
                  maxLength={10}
                />
              </td>
              <th>발굽과다리</th>
              <td colSpan="3">
                <input
                  type="text"
                  name="s_sta9"
                  value={formData?.s_sta9}
                  onChange={handleInputChange}
                  maxLength={10}
                />
              </td>
            </tr>
            <tr>
              <th>뒷유방부착높이</th>
              <td>
                <input
                  type="text"
                  name="s_sta2"
                  value={formData?.s_sta2}
                  onChange={handleInputChange}
                  maxLength={10}
                />
              </td>
              <th>뒷유방너비</th>
              <td>
                <input
                  type="text"
                  name="s_sta3"
                  value={formData?.s_sta3}
                  onChange={handleInputChange}
                  maxLength={10}
                />
              </td>
              <th>유방깊이</th>
              <td colSpan="3">
                <input
                  type="text"
                  name="s_sta4"
                  value={formData?.s_sta4}
                  onChange={handleInputChange}
                  maxLength={10}
                />
              </td>
            </tr>
            <tr>
              <th>앞유방부착</th>
              <td>
                <input
                  type="text"
                  name="s_sta5"
                  value={formData?.s_sta5}
                  onChange={handleInputChange}
                  maxLength={10}
                />
              </td>
              <th>유두길이</th>
              <td>
                <input
                  type="text"
                  name="s_sta6"
                  value={formData?.s_sta6}
                  onChange={handleInputChange}
                  maxLength={10}
                />
              </td>
              <th>엉덩이각도</th>
              <td>
                <input
                  type="text"
                  name="s_sta10"
                  value={formData?.s_sta10}
                  onChange={handleInputChange}
                  maxLength={10}
                />
              </td>
              <th>엉덩이너비</th>
              <td colSpan="3">
                <input
                  type="text"
                  name="s_sta11"
                  value={formData?.s_sta11}
                  onChange={handleInputChange}
                  maxLength={10}
                />
              </td>
            </tr>
            <tr>
              <th>체심</th>
              <td>
                <input
                  type="text"
                  name="s_sta12"
                  value={formData?.s_sta12}
                  onChange={handleInputChange}
                  maxLength={10}
                />
              </td>
              <th>강건성</th>
              <td>
                <input
                  type="text"
                  name="s_sta13"
                  value={formData?.s_sta13}
                  onChange={handleInputChange}
                  maxLength={10}
                />
              </td>
              <th>키</th>
              <td colSpan="3">
                <input
                  type="text"
                  name="s_sta14"
                  value={formData?.s_sta14}
                  onChange={handleInputChange}
                  maxLength={10}
                />
              </td>
            </tr>
            <tr>
              <th>스펙</th>
              <td colSpan="3">
                <input
                  type="text"
                  name="s_spec"
                  value={formData?.s_spec}
                  onChange={handleInputChange}
                  maxLength={200}
                />
              </td>
              <th>E스팩</th>
              <td colSpan="5">
                <input
                  type="text"
                  name="s_spec_e"
                  value={formData?.s_spec_e}
                  onChange={handleInputChange}
                  maxLength={200}
                />
              </td>
            </tr>
            <tr>
              <th>메모</th>
              <td colSpan="10">
                <input
                  type="text"
                  name="s_memo"
                  value={formData?.s_memo}
                  onChange={handleInputChange}
                  maxLength={200}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* 사진정보 */}
      <div
        className="table-container bold-border"
        style={{ marginBottom: '1rem' }}
      >
        <table>
          <colgroup>
            <col width="20%" />
            <col width="*" />
          </colgroup>
          <tbody>
            <tr>
              <th colSpan="2">사진정보</th>
            </tr>
            <tr>
              <th>일반사진</th>
              <td>
                <input
                  type="file"
                  id="s_sajin_url"
                  name="s_sajin_url"
                  onChange={handleFileChange}
                  style={{
                    display: 'none',
                  }}
                />
                <label
                  htmlFor="s_sajin_url"
                  style={{
                    display: 'inline-block',
                    padding: '8px 16px',
                    backgroundColor: '#f0f0f0',
                    border: '1px solid #ddd',
                    borderRadius: '4px',
                    cursor: 'pointer',
                  }}
                >
                  파일선택
                </label>
                <span>{formData?.s_sajin_url}</span>
                {formData?.s_sajin_url && (
                  <button
                    type="button"
                    onClick={() => handleFileDelete('s_sajin_url')}
                  >
                    삭제
                  </button>
                )}
              </td>
            </tr>
            <tr>
              <th>낭우사진</th>
              <td>
                <input
                  type="file"
                  id="s_dt_sajin_url"
                  name="s_dt_sajin_url"
                  onChange={handleFileChange}
                  style={{
                    display: 'none',
                  }}
                />
                <label
                  htmlFor="s_dt_sajin_url"
                  style={{
                    display: 'inline-block',
                    padding: '8px 16px',
                    backgroundColor: '#f0f0f0',
                    border: '1px solid #ddd',
                    borderRadius: '4px',
                    cursor: 'pointer',
                  }}
                >
                  파일선택
                </label>
                <span>{formData?.s_dt_sajin_url}</span>
                {formData?.s_dt_sajin_url && (
                  <button
                    type="button"
                    onClick={() => handleFileDelete('s_dt_sajin_url')}
                  >
                    삭제
                  </button>
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="button-group">
        <button type="button" className="btn-l" onClick={handleSubmit}>
          {sCode ? '수정' : '등록'}
        </button>
        {sCode && (
          <button type="button" className="btn-l" onClick={handleDelete}>
            삭제
          </button>
        )}
        <button
          type="button"
          className="btn-l"
          onClick={() => navigate('/bo/breedBull/originBreedBull/list')}
        >
          목록
        </button>
      </div>
    </div>
  );
}

export default OriginBreedBullEdit;
