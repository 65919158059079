import React, { useState , useEffect, useRef } from 'react';
import { Outlet , useNavigate } from 'react-router-dom';
import { postState } from 'utils/api';

// 컴포넌트
import MilkHeader from 'components/milk/header/MilkHeader';
import ErrorMessage from 'components/errorMessage/errorMessage';

// scss
import './milk.scss';

function Milk() {

  const navigate = useNavigate();
  function logout() {
    if (window.confirm('로그아웃 하시겠습니까?')) {
      localStorage.removeItem('milkFarmPwd');
      localStorage.removeItem('milkFarmCode');
  
      navigate('/web/main');
    }
  }
  // 에러처리
  const [isModalOpen, setIsModalOpen] = useState(false); // 모달 추가
  const [errorMessage, setErrorMessage] = useState(''); 

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => {
    setIsModalOpen(false);
    setErrorMessage(''); // 모달이 닫힐 때 에러메세지 초기화
  };

  // 데이터 불러오기
  // eslint-disable-next-line no-restricted-globals 
  const baseDir='/user/milklogin/';  
  const [ mainData , setMainData ] = useState();

  const [ props , setProps] = useState({
    id:localStorage.getItem('milkFarmCode'),
    passwd:localStorage.getItem('milkFarmPwd'),    
  });

  useEffect(() => {
    postState(baseDir,props)
    .then(getdata => {      
      if (getdata !== undefined) {      
      setMainData(getdata.result);            
      }
    })
    .catch(error => {           
      setErrorMessage(error.message);
      openModal();
    });    
  }, [props]);


  return (
    <div className='milk'>
      <ErrorMessage isOpen={isModalOpen} content={errorMessage} closeModal={closeModal}/>
      <MilkHeader />
      <main className='milkInner'>
        <div className='milkInnerHead'>
          <ul className='visit'>
            <li>Today <span>{mainData && mainData.today}</span></li>
            <li>Total <span>{mainData && mainData.totalno}</span></li>
          </ul>
          <div className='user'>
            <ul>
              <li>{mainData && mainData.fname} 목장</li>
              <li>대표 : {mainData && mainData.fdname}</li>
              <li>지역 : {mainData && mainData.fadd.split(' ').splice(0,2).join(' ')}</li>
            </ul>
            <button 
              type='button'
              onClick={logout}
            >
              로그아웃
            </button>
          </div>
        </div>
        <article className='milkBody'>
          <Outlet />
        </article>
      </main>
    </div>
  );
}

export default Milk;
