import React, { useState , useEffect } from 'react';
import { Link , useNavigate , useLocation } from 'react-router-dom';

// 컴포넌트

// 이미지
import MilkLogo from 'assets/images/common/milkHeader_logo.png';

// scss
import './milkHeader.scss';

function MilkHeader() {

  const location = useLocation().pathname.split('/')[2];

  return (
    <header className='milkHeader'>
      <h1 className='hidden'>젖소유전능력분석</h1>
      <nav className='milkNav'>
        <h2 className='hidden'>네비게이션 영역</h2>
        <ul>
          <li>
            <Link 
              to='/milk/pedigree'
              className={location === 'pedigree' ? 'current' : ''}
            >
              혈통정보 관리</Link>
          </li>
          <li>
            <Link 
              to='/milk/girlcowability'
              className={location === 'girlcowability' ? 'current' : ''}
            >
              암소 유전능력</Link>
          </li>
          <li>
            <Link 
              to='/milk/semenselect'
              className={location === 'semenselect' ? 'current' : ''}
            >
              정액선정</Link>
          </li>
          <li>
            <Link 
              to='/milk/girlcowselect'
              className={location === 'girlcowselect' ? 'current' : ''}
            >
              암소선정</Link>
          </li>
          <li>
            <Link 
              to='/milk/milkplanmating'
              className={location === 'milkplanmating' ? 'current' : ''}
            >
              계획교배</Link>
          </li>
        </ul>
      </nav>
    </header>
  );
}

export default MilkHeader;
