import React from 'react';

// scss
import './webpage_list.scss';

// 이미지
import imgC1 from 'assets/images/containers/data/relatedSites/consultation/imgSite06_1.jpg';
// import imgC2 from 'assets/images/containers/data/relatedSites/consultation/imgSite06_2.jpg';
// import imgC3 from 'assets/images/containers/data/relatedSites/consultation/imgSite06_3.jpg';
// import imgC4 from 'assets/images/containers/data/relatedSites/consultation/imgSite06_4.jpg';
// import imgC5 from 'assets/images/containers/data/relatedSites/consultation/imgSite06_5.jpg';
import imgC6 from 'assets/images/containers/data/relatedSites/consultation/imgSite06_6.jpg';
import imgC8 from 'assets/images/containers/data/relatedSites/consultation/imgSite06_8.jpg';
import imgC9 from 'assets/images/containers/data/relatedSites/consultation/imgSite06_9.jpg';
import imgC11 from 'assets/images/containers/data/relatedSites/consultation/imgSite06_11.jpg';
import imgC12 from 'assets/images/containers/data/relatedSites/consultation/imgSite06_12.jpg';
// import imgNO from 'assets/images/containers/data/relatedSites/imgSite_no.gif';

function Consultation() {
  return (
    <div className="Consultation">
      <div className="webpage">
        <dl className="webpage_list">
          <dd>
            <a
              href="https://www.ams.usda.gov//"
              target="_blank"
              rel="noreferrer"
            >
              <div className="webpage_banner">
                <img src={imgC1} alt="Agricultural Marketing Service." />
              </div>
              <p>Agricultural Marketing Service</p>
            </a>
          </dd>

          {/* <dd>
                    <div className="webpage_banner">
                        <img src={imgC2} alt="Dairy Lab Services"/>
                    </div>
                    <p>Dairy Lab Services</p>                    
                </dd> */}
          {/* <dd>
                    <div className="webpage_banner">
                        <img src={imgC3} alt="AgSource Cooperative Services"/>
                    </div>
                    <p>AgSource Cooperative Services</p>
                </dd>
                <dd>
                  <a href="https://www.dairypc.org/" target="_blank" rel="noreferrer">  
                    <div className="webpage_banner">
                        <img src={imgC4} alt="Dairy Practices Council"/>
                    </div>
                    <p>Dairy Practices Council</p>
                  </a>                    
                </dd>
                <dd>
                    <div className="webpage_banner">
                        <img src={imgC5} alt="Agway Inc"/>
                    </div>
                    <p>Agway Inc</p> 
                </dd> */}
          <dd>
            <a href="https://www.fiveg.com/" target="_blank" rel="noreferrer">
              <div className="webpage_banner">
                <img src={imgC6} alt="Five-G Consulting-Dairy design firm" />
              </div>
              <p>Five-G Consulting-Dairy design firm</p>
            </a>
          </dd>
          {/* <dd>
                    <div className="webpage_banner">
                        <img src={imgNO} alt="Canadian Dairy Farm Directory"/>
                    </div>
                    <p>Canadian Dairy Farm Directory</p>                    
                </dd> */}
          <dd>
            <a
              href="https://www.facebook.com/Thecattleexchange/"
              target="_blank"
              rel="noreferrer"
            >
              <div className="webpage_banner">
                <img src={imgC8} alt="The Cattle Exchange - Dave Rama Real" />
              </div>
              <p>The Cattle Exchange - Dave Rama Real</p>
            </a>
          </dd>
          <dd>
            <a href="http://www.cowpix.com/" target="_blank" rel="noreferrer">
              <div className="webpage_banner">
                <img src={imgC9} alt="Canadian Livestock Photographers, Inc." />
              </div>
              <p>Canadian Livestock Photographers, Inc.</p>
            </a>
          </dd>
          {/* <dd>
                    <div className="webpage_banner">
                        <img src={imgNO} alt="Lester Building Systems"/>
                    </div>
                    <p>Lester Building Systems</p>
                </dd> */}
          <dd>
            <a href="https://csbloyal.com/" target="_blank" rel="noreferrer">
              <div className="webpage_banner">
                <img src={imgC11} alt="Citizens State Bank, Loyal, WI" />
              </div>
              <p>Citizens State Bank, Loyal, WI</p>
            </a>
          </dd>
          <dd>
            <a
              href="https://agriculture.okstate.edu/departments-programs/afs/?Forwarded=afs.okstate.edu/library/dairy/"
              target="_blank"
              rel="noreferrer"
            >
              <div className="webpage_banner">
                <img
                  src={imgC12}
                  alt="WWW Virtual Library for Dairy Production"
                />
              </div>
              <p>WWW Virtual Library for Dairy Production</p>
            </a>
          </dd>
        </dl>
      </div>
    </div>
  );
}

export default Consultation;
