import React from 'react';
import { useLocation , Outlet } from 'react-router-dom';

// 컴포넌트
import SubTitle from 'components/subTitle/SubTitle';
import CommonBoardApi from 'components/board/commonBoardApi';

// scss
import './notice.scss';

function Notice() {

  const location = useLocation();

  return (
    <div className="notice subCon">
      <SubTitle />
      {
        location.pathname.includes('detail') ? (
          <Outlet />
        ) : (
          <CommonBoardApi />
        )
      }
    </div>
  );
}

export default Notice;
