import React, { useRef, useState } from 'react';
import { postState } from 'utils/api';

// 컴포넌트
import SubTitle from 'components/subTitle/SubTitle';
import ErrorMessage from 'components/errorMessage/errorMessage';
import Department from '../common/Department';

// scss
import '../common/common.scss';

function PerformanceCheck() {
  const farmCodeRef = useRef(null); // 조합 농가코드
  const monthRef = useRef(null); // 비밀번호
  const passwordRef = useRef(null); // 검정월

  const [isModalOpen, setIsModalOpen] = useState(false); // 모달 추가
  const [errorMessage, setErrorMessage] = useState('');

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => {
    setIsModalOpen(false);
    setErrorMessage(''); // 모달이 닫힐 때 에러메세지 초기화
  };

  async function submitHandler(event) {
    event.preventDefault();

    const inputid = farmCodeRef.current.value; // 조합 농가코드
    const pwd = passwordRef.current.value; // 비밀번호
    const month = monthRef.current.value; // 검정월

    const nowYear = new Date().getFullYear(); // 현재 년도
    const nowMonth = new Date().getMonth() + 1; // 현재 월
    const numberRegex = /^[0-9]+$/; // 숫자로만 이루어진 정규표현식

    const monthRegex = /^\d{4}(0[1-9]|1[012])$/; // 검정월 입력 정규표현식

    if (inputid.trim() === '') {
      setErrorMessage('조합 농가코드를 입력하십시오.');
      openModal();
      farmCodeRef.current.focus();
      return;
    }

    if (!numberRegex.test(inputid) || inputid.length < 8) {
      setErrorMessage('조합 농가코드 숫자 8자리를 입력해주세요.');
      openModal();
      farmCodeRef.current.focus();
      return;
    }

    if (month.trim() === '') {
      setErrorMessage('검정월을 입력하십시오.');
      openModal();
      monthRef.current.focus();
      return;
    }

    if (month.length < 6) {
      setErrorMessage('검정월 숫자 6자리를 입력해주세요.');
      openModal();
      monthRef.current.focus();
      return;
    }

    if (!monthRegex.test(month)) {
      setErrorMessage('검정월에는 년도와 월이 입력되어야 합니다.');
      openModal();
      monthRef.current.focus();
      return;
    }

    if (
      Number(month.slice(0, 4)) > nowYear ||
      Number(month.slice(0, 4)) < 2005
    ) {
      setErrorMessage(
        `년도에는 2005년에서 ${nowYear}년 사이의 년도를 입력해주세요.`,
      );
      openModal();
      monthRef.current.focus();
      return;
    }

    if (
      nowYear === Number(month.slice(0, 4)) &&
      Number(month.slice(4, 6)) > nowMonth
    ) {
      setErrorMessage('현재년도의 월까지만 입력하실 수 있습니다.');
      openModal();
      monthRef.current.focus();
      return;
    }

    if (pwd.trim() === '') {
      setErrorMessage('비밀번호를 입력하십시오.');
      openModal();
      passwordRef.current.focus();
      return;
    }

    if (pwd.length < 4) {
      setErrorMessage('비밀번호 4자리를 입력해주세요.');
      openModal();
      passwordRef.current.focus();
      return;
    }

    const baseDir = '/user/login/';
    const props = {
      id: inputid,
      passwd: pwd,
    };

    // console.log('*** components/pages/login/submitHandler - user_id : ', inputid);
    // console.log('*** components/pages/login/submitHandler - user_pwd : ', pwd);
    // console.log('*** components/pages/login/submitHandler - month : ', month);

    postState(baseDir, props)
      .then((getdata) => {
        if (getdata.result === false) {
          setErrorMessage('입력정보와 일치하는 유저를 찾을 수 없습니다.');
          openModal();
          farmCodeRef.current.focus();
          return;
        }

        if (getdata.result !== false) {
          localStorage.setItem('cong', getdata.result.ccode);
          localStorage.setItem('farm', getdata.result.fcode);
          localStorage.setItem('month', month);
          localStorage.setItem('passwd', pwd);
          localStorage.setItem('farmid', getdata.result.farmid);

          // console.log('### is login', islogin);

          window.open('/dcitest/dciFarm/NewMain', '_blank');
        }
      })
      .catch((error) => {
        alert(error.message);
      });

    closeModal();
  }

  return (
    <div className="performanceCheck subCon pullContent">
      <ErrorMessage
        isOpen={isModalOpen}
        content={errorMessage}
        closeModal={closeModal}
      />
      <div className="commonInner">
        <div className="commonInfo">
          <SubTitle />
          <strong>
            조합 농가코드는 우편으로 발송되는 검정성적표 우측 상단을 참고하세요
          </strong>
          <ul>
            <li>1. 조합 농가코드 &#40;8자리&#41; : 1234-5678 에서 12345678</li>
            <li>
              2. 검정월 &#40;6자리&#41; : 2017년 11월 자료를 보고 싶으면, 201711
            </li>
          </ul>
        </div>
        <div className="commonForm">
          <ul>
            <li>
              <label htmlFor="code">조합 농가코드</label>
              <input ref={farmCodeRef} type="text" id="code" maxLength="8" />
            </li>
            <li>
              <label htmlFor="month">검정월</label>
              <input ref={monthRef} type="text" id="month" maxLength="6" />
            </li>
            <li>
              <label htmlFor="password">비밀번호</label>
              <input
                ref={passwordRef}
                type="password"
                id="password"
                maxLength="4"
                defaultValue="0000"
              />
            </li>
          </ul>
          <div className="commonFormBtn">
            <button type="button" onClick={(event) => submitHandler(event)}>
              <span>조회</span>
            </button>
          </div>
        </div>
      </div>
      <div className="commonCon">
        <p>농가별 검정성적 조회 자료내역</p>
        <ul className="commonDocument">
          <li>
            <div className="commonDocumentInner">
              <strong>농가용</strong>
              <ul>
                <li>26개월 우군 검정성적 변화</li>
                <li>월별 개체 검정성적</li>
                <li>검정성적에 의한 유성분 분석보고서</li>
                <li>월간 우군관리 계획표</li>
                <li>번식성적 분석 보기</li>
                <li>월별 검정 현황 그래프</li>
              </ul>
            </div>
          </li>
          <li>
            <div className="commonDocumentInner">
              <strong>전문가용</strong>
              <ul>
                <li>검정성적 종합분석 보고서</li>
                <li>종합 기술분석표</li>
                <li>미수태우에 대한 검정성적</li>
                <li>FCM, FPCM, ECM을 이용한 분석</li>
                <li>전월 대비 검정성적</li>
                <li>도태우 분석 보고서</li>
                <li>컨설팅 전체 현황 리포트</li>
              </ul>
            </div>
          </li>
          <li>
            <div className="commonDocumentInner">
              <strong>유전능력 평가 및 검정자료 받기</strong>
              <ul>
                <li>정액 GENTYPES&#40;10MS&#41;보기</li>
                <li>농가 유전능력 평가보기</li>
                <li>검정자료 받기</li>
              </ul>
            </div>
          </li>
        </ul>
        <p>알림</p>
        <ul className="commonNotice">
          <li>
            본 프로그램은 농촌진흥청 축산과학원의 협조로 개발된 젖소 개체별
            맞춤형 컨설팅 프로그램입니다.
          </li>
          <li>
            우리 홈페이지의 농가별 검정성적의 상업적 이용 및 무단 사용은
            개인정보 보호에 관한 법률로 제제될 수 있다.
          </li>
          <li>
            검정농가 이외의 본 자료 이용시 반드시 농협 젖소개량사업소와 사전
            협의해 주시길 바랍니다. &#40;031-929-1080&#41;
          </li>
        </ul>
      </div>
      <Department
        phone="031-929-1064,74"
        department="젖소개량사업소 육종검정팀"
      />
    </div>
  );
}

export default PerformanceCheck;
