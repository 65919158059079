import React from 'react';
import Info from 'containers/web/info/Info';

function InfoPage() {
  return (
    <Info />
  );
}

export default InfoPage;
