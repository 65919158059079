import React from 'react';

import pic765 from 'assets/images/sub/_images/pic765.jpg';
import pic766 from 'assets/images/sub/_images/pic766.jpg';

function Do166() {

  return (
    <div className="techinfoview">
      <h1>그라스 테타니(Grass Tetany) 저 마그네슘 혈증</h1>
      <p className="pstyle">혈액중의 마그네슘(Mg)이 현저하게 감소되어 흥분, 경련등의 신경증상을 보이는 질병이다. 자연 초지의 방목우에서는 발생하지 않았다.</p>
      <div className="leftpart">
        <h2>원인</h2>
        <ul>
          <li>방목지 토양에 마그네슘 함량이 극히 낮은 경우 그곳에서 생산되는 곡류와 목초류에 마그네슘치가 떨어져 발생한다. </li>
          <li>어떠한 증상으로 인해 마그네슘 흡수 및 이용에 장애가 생겼을 때 </li>
          <li>계절적으로 이른봄, 특히 방목개시후 2∼3주일 이내에 다발한다.</li>
          <li>고령·착유는 이병의 발병 소인이 될 수 있다. </li>
        </ul>
        <h2>증상</h2>
        <ul>
          <li>급성의 경우는 소가 갑자기 미친 듯이 뛰다가 넘어져 강직성 경련을 일으키며 2∼3시간 이내에 폐산한다. </li>
          <li>의식 장애가 있고 운동에도 이상이 생겨 반마비 상태에 이른다. 약간의 자극에도 불안, 흥분상태를 나타내는 지각 과민을 보이기도 하며, 사지를 쭉쭉 뻗는 듯한 경련이 간헐적으로 반복된다. </li>
          <li>시각장애, 식욕부진, 무기력을 보인다. </li>
        </ul>
        <h2>목장에서의 처치</h2>
        <ul>
          <li>이병은 대개 경과가 빠르므로 소를 세심하게 관찰하여 발병전조(1∼2일전부터 원기, 식욕부진, 불안, 흥분상태, 비틀거림 등)를 나타내는 시기에 곧바로 수의사의 치료를 받는 것이 좋다. </li>
          <li>응급 처치로 병우를 보호축사에 옮기고 안정시켜 흥분에 의한 외상(外傷)을 막는다. </li>
          <li>마그네슘 및 칼슘제를 서서히 정맥주사한다. </li>
          <li>초지의 토양시비 문제를 토양 전문가와 상의하여 시정토록한다. </li>
        </ul>
      </div>
      <div className="rightpart">
        <img src={pic765} className="nonborder" alt=""/>
        <img src={pic766} className="nonborder" alt=""/>
      </div>
    </div>
  );
}

export default Do166;