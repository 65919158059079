import React from 'react';

import pic392 from 'assets/images/sub/_images/pic392.gif';

function Do210() {

  return (
    <div className="techinfoview">
      <h1>기타 착유시설물</h1>
      <h2>다용도실</h2>
      <p className="pstyle">다용도실은 착유에 필요한 여러 가지 장비를 가동/관리하는 장소로 각 장비별 소요면적은 &lt;표 84&gt;에 나타나 있다. 조명, 배수구 및 환풍 시설을 설치해야 하며, 특히 컴프레서와 진공펌프 시설을 냉각시키기 위하여 환풍기를 설치한다. (그림 63).겨울철에는 진공펌프와 컴프레서에서 발생되는 열을 이용하도록 한다.</p><br/>
      <p><b>&lt;표 84&gt; 다용도실 장비설치에 따른 소요면적</b></p>
      <table className="tablestyle">
        <tr>
          <td>항 목</td>
          <td>면 적, ft</td>
        </tr>
        <tr>
          <td>진공펌프</td>
          <td>6-9</td>
        </tr>
        <tr>
          <td>컴프레서</td>
          <td>8-10</td>
        </tr>
        <tr>
          <td>온 수 기</td>
          <td>4-6</td>
        </tr>
        <tr>
          <td>난 방 로</td>
          <td>3-5</td>
        </tr>
        <tr>
          <td>작업 공간</td>
          <td>20-30</td>
        </tr>
        <tr>
          <td>냉 장 고</td>
          <td>6-9</td>
        </tr>
        <tr>
          <td>책 상</td>
          <td>4-12</td>
        </tr>
      </table>
      <img src={pic392} className="nonborder" width="30%" alt=""/>
      <p>그림 63. 환기 컴프레서 설치</p>
      <h2>창 고</h2>
      <p className="pstyle">&nbsp;&nbsp;&nbsp;창고는 세척제, 약품, 고무제품, 착유, 교체장비, 부피가 있는 물건 등을 보관하는 장소로 다용도실과 구분하여 설치하며 단열처리를 해야 한다. 창고에는 조명, 배수구 및 2개의 냉장고를 설치해야 하는데, 1개의 냉장고에는 약품 보관용으로 나머지 1개는 고무제품 보관용으로 사용한다. 창고내부의 온도는 4.4℃∼27.6℃를 유지하도록 하는데, 단열처리가 되어 있으면 냉장고에서 발생되는 열기만으로도 보온이 가능하다. 우사나 차도로부터 먼지바람이 유입되지 않는 장소에 창고의 위치를 정한다. </p>
      <h2>우유저장실 </h2>
      <p className="pstyle">&nbsp;&nbsp;&nbsp;우유저장실은 일반적으로 저유조(milk bulk tank), 여과기, 냉각장치, 착유장비를 세척하고 보관하는 장소를 포함하고 있다. 저유조의 크기에 따라 우유저장실의 크기가 결정되지만, 앞으로의 확장에 대비하여 큰 저유조가 설치될 수 있도록 크게 만드는 것이 좋다. 저유조는 벽으로부터 24만큼의 거리를 두며 배출구로부터는 36정도 거리를 둔다. 중력을 이용한 여과장치와 대형 저유조가 설치된 경우에 천자의 높이가 최소한 이어야 한다. <br/>&nbsp;&nbsp;&nbsp;우유저장실에 과다한 습기를 제거하고, 열기가 축적되지 않도록 하며, 장비가 어는 것을 방지할 수 있는 환경관리가 중요하다. 이를 위하여 여과장치가 부착된 갑 환풍기(600-800 cam fan)를 설치하고, 지붕에 공기배출구를 설치하며, 컴프레서가 우유저장실 내에 설치되어 있을 경우에는 보다 큰 팬(fan)이 달린 환풍기를 설치한다. 겨울철에는 난방시설을 갖추어야 한다. </p>
      <h2>사무실 및 변소 </h2>
      <p className="pstyle">&nbsp;&nbsp;&nbsp;착유장의 사무실은 소의 건강과 산유량 기록을 보관하는 장소로서 사용될 뿐만 아니라 전체목장의 사무실로도 이용될 수 있다. 사무실에 설치된 컴퓨터에 먼지나 수분이 들어가 손상을 입히지 않도록 각별히 유의하여야 한다. 사무실 난방에는 중앙난방이나 전열기가 이용되는데, 여름철에 대비해서 에어컨이나 추가로 환기장치를 설치할 수 있다.  착유 장에는 최소한 한 개의 변소와 세면기를 마련해야 한다. 착유 자를 위한 샤워장, 탈의실 또는 휴식시설을 마련하는 것도 바람직하다. 단 위생적인 이유로 변소와 우유저장실을 바로 통하는 문을 내서는 안된다.</p>
    </div>
  );
}

export default Do210;