import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useNavigate, Link } from 'react-router-dom';

// api
import { getState } from 'utils/api';

// components
import Pagination from 'components/bo/Pagination';

// constants
import { BOARD_NAME, BOARD_CODE } from 'constants/backOfficeBoard';

function LocstatList() {
  const navigate = useNavigate();

  const [searchState, setSearchState] = useState({
    search_type: 'bdc_wname',
    search_keyword: '',
    page_no: 1,
    brd_code: BOARD_CODE.LOCSTAT,
  });

  const { data, isLoading, isError, error } = useQuery({
    queryKey: ['locstatList', searchState],
    queryFn: () =>
      getState('/board/select_farm_test_list', {
        ...searchState,
      }),
  });

  const farmBestList = data?.result?.list || [];
  const totalCount = data?.result?.total_count || 0;
  const totalPages = data?.result?.page_count || 0;

  const handleSearch = (e) => {
    e.preventDefault();
    setSearchState({
      ...searchState,
      page_no: 1,
    });
  };

  const handlePageChange = (pageNo) => {
    setSearchState({
      ...searchState,
      page_no: pageNo,
    });
  };

  const handleRegister = () => {
    navigate('/bo/farmTest/locstat/edit');
  };

  return (
    <div className="bo-page">
      <h2>{BOARD_NAME.LOCSTAT}</h2>
      <div className="searchbox">
        <select
          value={searchState.search_type}
          onChange={(e) =>
            setSearchState({ ...searchState, search_type: e.target.value })
          }
        >
          <option value="bdc_wname">년월</option>
        </select>
        <input
          type="text"
          value={searchState.search_keyword}
          onChange={(e) =>
            setSearchState({ ...searchState, search_keyword: e.target.value })
          }
        />
        <button type="button" className="btn-search" onClick={handleSearch}>
          검색
        </button>
      </div>

      <div className="board-top">
        <p>
          총 자료수: <strong>{totalCount}</strong> 개, 페이지{' '}
          {searchState.page_no}/{totalPages}
        </p>
      </div>

      <div className="table-container">
        <table>
          <colgroup>
            <col width="10%" />
            <col width="15%" />
            <col width="*" />
            <col width="15%" />
          </colgroup>
          <thead>
            <tr>
              <th>번호</th>
              <th>년월</th>
              <th>제목</th>
              <th>등록일</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td colSpan="4" className="loading-data">
                  데이터를 불러오는 중입니다...
                </td>
              </tr>
            ) : isError ? (
              <tr>
                <td colSpan="4" className="error-data">
                  에러가 발생했습니다: {error.message}
                </td>
              </tr>
            ) : farmBestList.length === 0 ? (
              <tr>
                <td colSpan="4" className="no-data">
                  게시글이 없습니다.
                </td>
              </tr>
            ) : (
              farmBestList.map((item) => (
                <tr key={item.bdc_code}>
                  <td className="center">{item.row_num}</td>
                  <td className="center">
                    <Link
                      to={`/bo/farmTest/locstat/view?bdc_code=${item.bdc_code}&brd_code=${item.brd_code}`}
                    >
                      {item.bdc_wname}
                    </Link>
                  </td>
                  <td>{item.bdc_title}</td>
                  <td className="center">{item.bdc_rdt}</td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>

      <Pagination
        currentPage={searchState.page_no}
        totalPage={totalPages}
        onPageChange={handlePageChange}
      />

      <div className="button-group">
        <button type="button" className="btn-l" onClick={handleRegister}>
          등록
        </button>
      </div>
    </div>
  );
}

export default LocstatList;
