import React from 'react';

import Pic451 from 'assets/images/sub/_images/pic451.jpg';
import Pic452 from 'assets/images/sub/_images/pic452.jpg';
import Pic453 from 'assets/images/sub/_images/pic453.jpg';
import Pic454 from 'assets/images/sub/_images/pic454.jpg';
import Pic455 from 'assets/images/sub/_images/pic455.jpg';

function Do72() {

  return (
    <div className="techinfoview">
				<h1>창상성 제2위 횡격막염</h1>
				<p className="pstyle">이 병은 못과 같은 쇠붙이나 날카로운 이물이 제 2위 벽을 뚫고 들어가 횡격막까지 도달하여 그 부위에 염증을 일으켜 제 2위와 횡격막의 유착, 또는 농양형성을 일으키는 병이다.</p>
				<div className="leftpart">
					<h2>원인</h2>
					<ul>
						<li>소의 위의 해부학적 위치 및 구조에 관련되고 있다.
							<ul>
								<li>채식시 혀로 사료를 감아올리듯 삼키기 때문에 사료에 섞여있는 이물질을 가려내지 못한다. </li>
								<li>삼킨 이물은 제 2위 바닥에 머물게 되면 제 2위는 수축운동이 가장 왕성하여 수축운동이 계속 될 경우 이물질(쇠붙이)이 쉽게 점막을 뚫는다. </li>
								<li>제 2위 바로앞에 있는 횡격막에 도달한다.</li>
								<li>그 결과 이물에 붙어있던 세균이나 위액에 의해 복막염을 일으키고 유착이나 농양을 형성한다.</li>
							</ul>
						</li>
					</ul>
					<h2>증상</h2>
					<p></p>
					<ul>
						<li>급성
							<ul>
								<li>식욕소실, 제 1위 운동의 감퇴 또는 정지. </li>
								<li>유량 감소가 눈에 뛴다. </li>
								<li>운동을 기피하고 일어설때와 내리막길에서는 신음소리를 낸다. </li>
								<li>가슴을 막대기로 아래쪽에서 받쳐올리면 통증을 보인다.</li>
							</ul>
						</li>
						<li>만성
							<ul>
								<li>식욕부진이 되풀이 되고 소화 불량을 일으키며 야위어 간다.</li>
							</ul>
						</li>
					</ul>
					<h2>목장에서의 처치</h2>
					<ul>
						<li>특별한 응급처치는 없으면 예방이 최선이다.
							<ul>
								<li>사료통 주변 및 우사 내외에 금속성 이물질의 철저한 제거</li>
								<li>생후 10∼12개월령에 막대자석을 먹인다. </li>
								<li>보조적 치료로서 전고 후저 자세를 갖도록 소의 전면을 20∼40sm정도 높여준다. </li>
								<li>설파제와 항생제를 이용할 수 있으나 큰 기대는 할 수 없다.</li>
								<li>진단이 내려지면 수의사와 상의해 보고 도태하는 것이 바람직하다.</li>
							</ul>
						</li>
					</ul>
				</div>
				<div className="rightpart">
					<img src={Pic451} className="nonborder" alt=""/>
					<p>창상성 제2위염에 의해 생겨난 아랫턱과 가슴의 부종</p>
					<img src={Pic452} className="nonborder" alt=""/>
					<p>제2위와 유착된 횡격막, 우측아래(농양을 이루고 있다.)는 철사에 찔린 모습</p>
					<img src={Pic453} className="nonborder" alt=""/>
					<p>제2위의 점막에 못이 박혀 있음을 볼 수 있다.</p>
					<img src={Pic454} className="nonborder" alt=""/>
					<img src={Pic455} className="nonborder" alt=""/>
					<p>전고후저(前高後低)의 자세</p>
				</div>
			</div>
  );
}

export default Do72;