import React from 'react';

import Pic221 from 'assets/images/sub/_images/pic221.gif';
import Pic222 from 'assets/images/sub/_images/pic222.gif';

function Do58() {

  return (
    <div className="techinfoview">
				<h1>젖먹이 송아지 사양관리(4일령~이유시까지)-젖떼기와 이유사료급여</h1>
				<h2>젖떼기 </h2>
				<p><b>&lt;표 59&gt; 송아지 이유시기와 폐사율과의 관계 </b></p>
				<table className="tablestyle">
					<tr>
						<td>이유시 주령</td>
						<td>시험두수(두)</td>
						<td>6개월령까지 평균폐사율(%)</td>
					</tr>
					<tr>
						<td>3∼4</td>
						<td>11</td>
						<td>14.2</td>
					</tr>
					<tr>
						<td>5∼6</td>
						<td>28</td>
						<td>9.0</td>
					</tr>
					<tr>
						<td>7∼8</td>
						<td>58</td>
						<td>7.1</td>
					</tr>
					<tr>
						<td>8</td>
						<td>42</td>
						<td>6.1</td>
					</tr>
				</table><br/>
				<p className="pstyle">대체로 송아지는 5∼8주령에 젖을 뗀다. 젖뗄 때 송아지는 건강하고, 정상적으로 성장하며, 아유사료를 체중의 1%이상 섭취해야 하는 바 그렇지 못할 때는 젖먹이는 기간을 늘린다. 이유를 앞둔 송아지의 이유사료섭취량을 늘리기 위해서 젖떼기 5∼7일 전부터 우유 나 대용유를 하루에 한번씩 급여하거나 급여량을 반으로 줄인다. 21∼28일령에 조기이유할 수도 있 으나 현실적으로 여건상 어려움이 따르며 폐사율도 높게 나타나므로 권장할 사항은 아니다. 물론 관리를 잘하면 불가능한 것은 아니다. &lt;표 59&gt;은 송아지 이유시기와 폐사율과의 관계를 나타낸 것이다. </p>
				<h2>이유사료(calf starter)급여 </h2>
				<ul>
					<li>제 1위의 발달 
						<p className="pstyle">&nbsp;&nbsp;&nbsp;어린 송아지는 제 4위의 용적이 70%나 되며 제 1위가 발달하지 못하여 섬유소의 소화기능이 없다. 송아지가 섭취한 우유는 식도구(esophageal groove)를 통하여 직접 제 4위로 들어가서 소화된다. <br/>&nbsp;&nbsp;&nbsp;송아지가 성장함에 따라서 고형사료의 섭취량이 늘어나면 제 1위내의 박테리아와 프로토조아등 미생물이 증가되며 이들 미생물이 생산하는 휘발성 지방산에 의해 제 1위의 발달이 촉진된다. 송아지는 위와 같은 과정을 거쳐서 4개월령에 이르면 반추기능을 제대로 갖추게 된다.</p>
					</li>
					<li>이유사료 급여방법 
						<p className="pstyle">이유사료는 송아지가 5일령 정도 되었을 때부터 천천히 섭취훈련을 시키는데 송아지가 우유를 먹은 직후에 우유 급여용기 바닥에 한 줌 넣어 주거나 송아지 입에 묻혀 준다. 이렇게 훈련하여 7∼10일령에는 얼마간의 이유사료를 섭취할 수 있도록 해주어야 한다. 이유사료를 먹는 양이 적으므로 조금씩 자주 주어 항시 깨끗하고 신선한 것을 먹도록 하며 먹지 않고 남는 것은 새것으로 갈아주는 것이 좋다. </p>
						<img src={Pic221} className="nonborder" style={{width: '60%'}} alt=""/>
						<table className="tablestyle16">
							<tr>
								<td>(1단계) 생시-21일령</td>
								<td>(2단계) 22-84일령</td>
								<td>(3단계) 84일령 이상</td>
							</tr>
							<tr>
								<td style={{textAlign: 'left'}}>1. 제4위가 전체 위용적의 70%차지<br/>2. 제1위는 반추가능 없음<br/>3. 제1위는 마른사료 섭취에 의한 자극으로 발달</td>
								<td style={{textAlign: 'left'}}>1. 마른사료 특히 보조사료 섭취로 제1위내 미생물의 증식촉진<br/>2. 미생물이 생산하는 휘발성 지방산(VFA)이 제1위의 발달촉진</td>
								<td style={{textAlign: 'left'}}>이 시기에 송아지는 반추기능을 갖춤</td>
							</tr>
						</table>
						<p>그림 21. 보조사료에 의한 증체효과</p>
						<img src={Pic222} alt=""/>
						<p className="pstyle">&nbsp;&nbsp;&nbsp;이유사료는 형태별로 곡류이유사료(grain starter)와 완전이유사료(complete starter)가 있다. 완전이유사료란 조사료의 급여없이 이것 한 가지 만으로도 사육할 수 있는 것으로 한국에는 아직 개발되어 있지 않다.<br/>&nbsp;&nbsp;&nbsp;곡류이유사료(grain starter)를 급여할 때는 하루에 1.8∼2.3kg을 섭취할 때까지 계속 충분히 먹을 수 있도록 하고 양질의 조사료(건초)를 자유채식시킨다. 이유사료는 어느 형태의 것을 급여하든지 4개월령까지 급여해야 한다.<br/>&nbsp;&nbsp;&nbsp;사료는 어린 송아지에게 급여하는 주요한 보조사료이므로 영양소의 함량이 다른 사료보다 높아야한다. 가소화양분총량(TDN)이 75∼80%, 조단백질(CP)이 15∼20% 되어야 한다 건물기준, &lt;표 60&gt; 참조). 그림 21은 보조사료에 의한 증체효과를 나타낸 것으로 우유만 섭취시켰을 때 보다 훨씬 증체효율이 높았음을 보여주고 있다. 어린 송아지에게 보조사료로서 육성사료나 착유사료를 급여하는 것은 바람직하지 못하다. 단백질, 에너지 수준이 낮을 뿐아니라 요소나 기타 다른 형태의 비단백태질소화합물(NPN)이 함유되어 송아지가 이용할 수 없기 때문이다. 6개월령이 되지 않은 송아지에게 NPN은 적합한 단백질 공급원이 될 수 없다. 대부분의 이유사료에는 비타민 A, D, E가 강화되어 있으므로 질병등으로 사료섭취량이 떨어져 비타민의 부족이 예상될 때는 첨가제 급여나 근육주사의 방법으로 보충해 준다. 무기물은 종합첨가제를 자유채식시키는 것이 손쉽고 실제적인 방법이다. </p><br/>
						<p>&lt;표 60&gt; 송아지용 보조사료의 영양소 함량의 범위(건물 기준) </p>
						<table className="tablestyle">
							<tr>
								<td style={{width: '40%'}}>영 양 소</td>
								<td>최 저</td>
								<td>최 대</td>
							</tr>
							<tr>
								<td>가소화양분총양(TDN) (%)</td>
								<td>76.0</td>
								<td>78.0</td>
							</tr>
							<tr>
								<td>조 단 백 질 (%)</td>
								<td>15.0</td>
								<td>20.0</td>
							</tr>
							<tr>
								<td>조 지 방 (%)</td>
								<td>2.5</td>
								<td>5.0</td>
							</tr>
							<tr>
								<td>조 섬 유 (%)</td>
								<td>2.0</td>
								<td>7.0</td>
							</tr>
							<tr>
								<td>칼 슘(Ca) (%)</td>
								<td>0.4</td>
								<td>0.6</td>
							</tr>
							<tr>
								<td>인 (P) (%)</td>
								<td>0.3</td>
								<td>0.4</td>
							</tr>
							<tr>
								<td>비타민 A (IU/kg)</td>
								<td>1,655</td>
								<td>2,208</td>
							</tr>
							<tr>
								<td>비타민 D (IU/kg)</td>
								<td>309</td>
								<td>662</td>
							</tr>
						</table>
					</li>
				</ul>
			</div>
  );
}

export default Do58;
