import React from 'react';

import d1by25 from 'assets/images/sub/_images/1-25.jpg';
import d1by27 from 'assets/images/sub/_images/1-27.jpg';
import d1by7 from 'assets/images/sub/_images/1_7.gif';
import d1by35 from 'assets/images/sub/_images/1-35.jpg';

function Fo20() {

  return (
    <div className="techinfo_view">
				<p className="viewTitle">생후 1-2개월의 관리</p>
				<h2>하리(下痢), 폐염(肺炎)을 막는다. </h2>
				<ul className="listStyle04 mg15f">
					<li>영양성 하리인가 세균성 하리인가, 수의사에게 원인균을 분석해서 받은 원인을 확실하고 빠르게 치료을 하고, 다른 소에게 전염되는 것을 막는다. </li>
					<li>환기가 나쁜 우사에 자우를 두면, 암모니아에 의해 기관의 점막이 상하여 원인균에 감염되기 쉽게 되고, 감가와 폐염(肺炎)을 일으키기 쉽다. </li>
					<li>자우의 배가 적거나 차게 하거나, 외풍이 들어오는 곳은 하리와 폐염에 걸리기 쉽다.</li>
					<li>우유와 액상 사료로 교체한 자우에게 초유를 주면 하리를 일으킨다.</li>
				</ul>
				<div className="imgBox mg60f"><img src={d1by25} alt="" title="깨끗한 공기는 감기와 폐염방지에 가장 좋은 묘약." /></div>
				
				<h2>만약 하리를 했다면.</h2>
				<dl className="mg60f">
					<dt>하리를 일으킬 때는응급조치를 취하고 신속하게 수의사에게 진찰을 받는다.</dt>
					<dd>
						<div className="imgBox"><img src={d1by27} alt=""/></div>
						<ul>
							<li>a: 가벼운 하리일때에는 액상사료를 계속해서 체력의 소모를 막지만 심할 때에는 액상사료를 중지하고 전해질수에 의한 수분의 보급으로 새로 바꾼다. 액상사료는 세균의 영양이 되어 버리기 때문.</li>
							<li>b: 탈수증상을 막기 위해서 전해질수의 경구(經口) 보충액이 바람직하지만 응급적으로는 싱거운 끓인 소금물과 고형 스프의 원가를 녹이는 것으로 대용한다. </li>
							<li>c: 끓는 소금물은 거의 염분이 있는 정도. 염분 0.7∼0.9%, 온도는 39℃정도, 양은 체액의 소실량 + 유지량 2.7 ∼3.2ℓ/일. </li>
						</ul>
					</dd>
				</dl>
				
				<h2>하리에 의한 발육 지연의 목표</h2>
				<ul className="listStyle04 mg15f">
					<li>가벼운 하리: 2∼3일 지연.</li>
					<li>중간 정도의 하리: 1주간 지연. </li>
					<li>심한 하리: 1개월 지연. 심한 하리를 한 자우는 성우가 되더라도 하리를 하기 쉽다.!! </li>
				</ul>
				<div className="tableWrap">
					<table className="tablestyle">
						<caption>&lt;표&gt; 하리의 정도. </caption>
						<thead>
							<tr>
								<th className="first">하리의 정도</th>
								<th>체액의 손질(체중당 %)</th>
								<th>변의 상태</th>
								<th>주요 증상</th>
								<th>전해질 용액의 보충액량 </th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>경도(輕度)</td>
								<td>0∼4%</td>
								<td>핫케이크 정도의 된 연변</td>
								<td>하리는 하고 있지만, 건강이 좋고 포유도 잘 빤다</td>
								<td>특히 필요은 없음<br/>3.6∼3.8ℓ/일(日)</td>
							</tr>
							<tr>
								<td>중도(中度)</td>
								<td>5∼9%</td>
								<td>
									약간 수양성 갈분 정도의 단단함.<br/>
									하리의 냄새가 강해진다.
								</td>
								<td>
									피부의 탄력성이 감소.<br/>
									건조한 코와 입.<br/>
									피모의 광택이 없어진다. 
								</td>
								<td>5.0∼6.8ℓ/일(日)</td>
							</tr>
							<tr>
								<td rowSpan="2">심한 정도</td>
								<td>10∼14% </td>
								<td>수양성</td>
								<td>
									우묵하게 들어간 눈.<br/>
									피모가 흩어져 헝클어진다.<br/>
									피부의 타력성이 감소.
								</td>
								<td>7.2∼9.11ℓ/일(日)</td>
							</tr>
							<tr>
								<td>15%이상  <sup>1)</sup></td>
								<td>이따금 수양성 변의 양도 감소 </td>
								<td>
									기립 불능이 된다. <br/>
									다리와 입이 차가와 진다. <br/>
									생명이 위험하게 된다.
								</td>
								<td>9.5ℓ/일(日)</td>
							</tr>
						</tbody>
					</table>
				</div>
				<ul className="refer mg60f" style={{marginTop: '-20px'}}>
					<li>1) 체액이 15%이상 소실되면 사망할 위험이 높아진다. </li>
					<li>* 피부의 탄력성은 경부(頸部) 또는 후지(後肢)의 피부를 집어 올려 주름이 원상태로 돌아 오는 상태에 의해 판단한다. → 바로 돌아 오지 않는 상태에 있어서는 5% 소실. </li>
					<li>* 전해질 용액은 1일량을 회수를 나누어 급여한다. </li>
				</ul>
				
				<h2>백신(Vaccine)은 필요한가?</h2>
				<ul className="listStyle04 mg15f">
					<li>방목이 적어지고, 각종병에 대한 면역 획득의 기회가 적어지고 있다. </li>
					<li>초유로부터 얻은 각종 항체도 1∼2개월로서 소실되 버리기 때문에 자력으로 항체를 만들 수 있도록 되기 까지의 사이는 백신(Vaccine)에 의해 면역(免疫)을 획득(獲得)하게 하여 질병(疾病)의 감염(感染)을 막는다.</li>
					<li>백신(Vaccine)은 전부 병에 대하여 개발되고 있을 리는 없지만 개발되고 있는 백신(Vaccine)은 적극적으로 사용하여 질병을 예방한다. </li>
					<li>백신(Vaccine)의 투여는 수의사와 상담하여 필요한 백신(Vaccine)을 접종해 받는다. </li>
				</ul>
				<div className="tableWrap">
					<table className="tablestyle">
						<caption>표. 백신 프로그램(Program)</caption>
						<thead>
							<tr>
								<th className="first">월령 백신명</th>
								<th>0.1~1</th>
								<th>3</th>
								<th>6</th>
								<th>10 </th>
								<th>15</th>
								<th>24</th>
								<th>비고</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td className="left">우(牛)전염성 비기관염(IBR) 3종 혼합 우(牛)바이러스성 하리점막병(BVD,MD) 백신 우(牛)파라인플루엔자Ⅲ(Parain fluenza type3)(PI3)</td>
								<td colSpan="6" className="left">
									호흡기병 대책 이상산대책 (BVD·MD)로 보아도 좋다.<br/>
									<img src={d1by7} alt=""/> <img src={d1by7} alt=""/> <img src={d1by7} alt=""/>
								</td>
								<td className="left">BVD·MD는 임신우에게는 접종하지 않는다.</td>
							</tr>
							<tr>
								<td className="left">우(牛) 바이러스 감염병(RS) 생백신(Vaccine)</td>
								<td colSpan="6" className="left">
									호흡기병 대책 이상산대책 (BVD·MD)로 보아도 좋다.<br/>
									<img src={d1by7} alt=""/> <img src={d1by7} alt=""/>
								</td>
								<td className="left">BVD·MD는 임신우에게는 접종하지 않는다.</td>
							</tr>
							<tr>
								<td className="left">기종저(Blackleg) Clostridium(원인균) 악성수종(Mulignant edema) 3종 혼합 불활화 백신 신염(Nephritis) </td>
								<td colSpan="6" className="left">발생이 있던 지역에서는 계절에 관계없이 접종.(3∼4개월령에게 접종)</td>
								<td className="left">접종할 때는 수의사에게 반듯이 상담한다.</td>
							</tr>
						</tbody>
					</table>
				</div>
				<ul className="refer mg60f" style={{marginTop:'-20px'}}>
					<li>* 각종 백신(Vaccine)은 각각 단체라도 사용할 수 있다. 十勝(토카치)가축보건소</li>
				</ul>
				
				<h2>포육기(哺育期)에도 물은 필요!</h2>
				<ul className="listStyle04 mg15f">
					<li>액상사료와 물의 역할은 다르다</li>
					<li>고형물을 먹기 시작하면 반듯이 물을 급여한다.
						<ul>
							<li>a: 자우에게 액상사료와 물의 차이를 느끼게 한다.</li>
							<li>b: 신선한 물을 급여한다.(물통으로 급여할 때는 매일 새로 바꾼다.) </li>
							<li>c: Water cup(자동 급수기)를 이용할 때는 큰 육성우의 이웃하게 한다. (작은 자우에게는 Water cup(자동 급수) 밸브를 누루게 하지 않고 또는 큰소가 물을 마시는 것으로 인해 항상 새물로 한다.)</li>
							<li>d: 온수라도 냉수라도 발육에 큰차이는 생기지 않는다.</li>
						</ul>
					</li>
					<li>수분이 부족하면 반추위의 발육이 늦어진다.</li>
				</ul>
				<div className="tableWrap">
					<table className="tablestyle">
						<caption>표.급여와 온수급여(인공유 기간90일)</caption>
						<thead>
							<tr>
								<th className="first">구분</th>
								<th>두수</th>
								<th>개시시 체중</th>
								<th>종료시 체중</th>
								<th>1일 증체량</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>냉수구</td>
								<td>4</td>
								<td>43.8kg</td>
								<td>106.8kg</td>
								<td>0.692kg</td>
							</tr>
							<tr>
								<td>온수구</td>
								<td>4</td>
								<td>45.7kg</td>
								<td>101.6kg</td>
								<td>0.615kg</td>
							</tr>
						</tbody>
					</table>
				</div>
				
				<table className="tablestyle">
					<caption>표. 기온과 자우의 음수량</caption>
					<thead>
						<tr>
							<th className="first">기온</th>
							<th>음수량</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>10℃</td>
							<td>3.1∼3.5kg</td>
						</tr>
						<tr>
							<td>21.1℃</td>
							<td>4.4kg</td>
						</tr>
						<tr>
							<td>29.4℃ </td>
							<td>5.9kg</td>
						</tr>
					</tbody>
				</table>
				<ul className="refer mg30f">
					<li>건물 1kg 섭취정도의 음수량(Macdonald등 1958)</li>
				</ul>
				
				<table className="tablestyle mg60f">
					<caption>표. 이유후 육성우 수분 요구량. </caption>
					<thead>
						<tr>
							<th className="first">주령</th>
							<th>체중</th>
							<th>항온때 음료수와 사료중 수분의 합계</th>
							<th>체중비</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>4</td>
							<td>65kg</td>
							<td>4.5~5.4kg</td>
							<td>7~8%</td>
						</tr>
						<tr>
							<td>8</td>
							<td>80kg</td>
							<td>5.8kg</td>
							<td>7.3%</td>
						</tr>
						<tr>
							<td>12</td>
							<td>100kg</td>
							<td>81.~9.1kg</td>
							<td>8~9%</td>
						</tr>
						<tr>
							<td>16</td>
							<td>130kg</td>
							<td>11.3~1.7kg</td>
							<td>8.5~9.8%</td>
						</tr>
						<tr>
							<td>20</td>
							<td>150kg</td>
							<td>14.5~16.3kg</td>
							<td>9.5~11%</td>
						</tr>
						<tr>
							<td>26</td>
							<td>170kg</td>
							<td>15.0~21.8kg</td>
							<td>9~13%</td>
						</tr>
					</tbody>
				</table>
				
				<h2>인공유(人工乳)을 먹게 하기 위해서는!</h2>
				<ul className="listStyle04 mg15f">
					<li>포유가 끝난 후 손에 소량의 인공유를 가지고 자우의 입안에 넣어 맛을 느끼게 한다.</li>
					<li>포유량을 줄이는 것이 인공유를 먹게 하는 최대의 요령.</li>
					<li>인공유의 필요량을 먹었다면 건초를 포식하게 한다. </li>
					<li>인공유는 영양분이 확보되어 있는 양질인 것을 사용한다. </li>
					<li>인공유는 먹을 수 있는 양만큼 급여하는 것이 바람직하지만 몇일분인지를 결정해서 급여하여 변패(變敗)하지 않도록 관리한다. </li>
					<li>반추위가 충분히 발달하여 있지 않을 때에 거칠고 단단한 조사료를 급여하면 위벽에 상처를 내버려 궤양(潰瘍)의 원인이 된다. </li>
				</ul>
				<div className="imgBox"><img src={d1by35} title="인공유 손으로 밀어 넣어 준다" alt=""/></div>
			</div>
  );
}

export default Fo20;
