import React from 'react';

import pic357 from 'assets/images/sub/_images/pic357.gif';
import pic358 from 'assets/images/sub/_images/pic358.gif';
import pic359 from 'assets/images/sub/_images/pic359.gif';
import pic360 from 'assets/images/sub/_images/pic360.gif';

function Do204() {

  return (
    <div className="techinfoview">
      <h1>전환기 우사시설(transition housing : 3~5개월령)</h1>
      <p className="pstyle">송아지를 이유 후에 개별우리로부터 군사(그룹)우리로 옮기면, 새로운 우사 환경, 다른 소아지들과의 관계, 사료와 음수에 대한 경쟁 등이 스트레스 요인으로 작용하게 되어 성장에 많은 영향을 미친다. 따라서, 송아지가 그룹 사육에 잘 적응할 수 있도록 송아지 환경에 대한 세심한 주의를 기울여야 한다. 그룹우리로 옮기기 전에, 질병보유 여부를 면밀하게 조사하고 적당한 양의 사료와 물을 섭취하는지를 관찰한다.  전환기 우사는 이유 후부터 약 5개월령까지 머무는 시설로, 한 우리에 나이차가 1개월 이하인 4∼5마리의 송아지를 사육하는 것이 적당하나, 최대로 20마리까지 그룹사육할 수 있다. 한 마리당 휴식장은 25∼30ft2로 하면, 늘 신선한 물을 공급해 주어야 한다. </p>
      <h2>대형 독우상자(super hutch) </h2>
      <p className="pstyle">대형 독우상자를 전환기 우사로 이용할 수 있는데, 최대 6마리 수용할 수 있도록 설계한다. (그림 28). 그림 29와 같이 대형 독우상자 앞의 운동장을 포장하고 울타리를 만들 수도 있다. 그림 30과 같이 목초지에 설치하는 경우에는, 대형 독우상자를 옮겨가며 각 구역의 목초를 순차적으로 이용할 수도 있다. 이때 급수조의 위치는 전체 목초지의 중앙에 설치할 수도 있고, 대형 독우상자와 함께 이용시킬수 있도록 설계 한다.</p>
      <img src={pic357} className="nonborder" width="50%" alt=""/>
      <p>그림 28. 송아지 큰 사육상자의 설계도</p>
      <img src={pic358} className="nonborder" width="20%" alt=""/>
      <p>그림 29. 포장된 운동장이 있는 대형 독우상자의 설계도</p>
      <img src={pic359} className="nonborder" width="20%" alt=""/>
      <p>그림 30. 구획된 목초지대의 대형 독우상자와 급수조의 위치</p>
      <img src={pic360} className="nonborder" width="20%" alt=""/>
      <p>그림 31. 육성기 우사의 설계도</p>
      <h2>전환기 우사(transition barn) </h2>
      <p className="pstyle">착유두수가 100두 이상인 목장에서는 그림 31과 같이 10＇× 20＇ 크기의 우리를 연속해서 이용하며, 이유 후 전환기 송아지를 6개월령까지 사육할 수 있다. 각 우리는 사료급여 통로를 마련할 경우에는 6마리를, 사료급여 통로를 따로 마련하지 않고 전체에 깔짚을 까는 경우에는 8마리를 수용할 수 있다.  일반적으로 전환기 우사는 단열처리를 하지 않으며 지붕의 기울기는 3:12이다. 햇빛이 잘 들도록 우사 정면은 남쪽 또는 동쪽을 향하도록 하며, 뒷벽의 처마에 입기구를 마련하여 겨울철의 습도조절에 도움을 주도록 한다. 여름철에는 뒷벽을 덮었던 커튼을 제거함으로서 자연통풍이 되도록 한다. 우리 전면의 양쪽 끝부분은 4＇튀어나오도록 하여 겨울철에 찬공기가 우리 모서리를 통하여 유입되는 것을 최소화시킨다. 급수조는 사료조 선상에 설치하여 물이 튀어 깔짚을 적시지 않게 한다.</p>
      <h2>송아지 우사(calf barn) </h2>
      <p className="pstyle">송아지 우사는 칸막이 개별우리와 전환기 군사우리의 복합 형태이다 (그림32). 옆면이 개방되어 있어서 여름철에 환풍이 원활하고 커튼을 치면 겨울철에 샛바람을 막을 수 있다. 겨울철에는 외부 온도보다 약간 낮게 유지될 수 있도록 우사내의 환기를 조절한다.  우리 사이는 송아지들이 직접 접촉하지 못하도록 칸막이를 설치한다. 여름철에는 우리 앞면과 뒷면은 철망으로 막아 통풍이 잘 되도록 하고, 겨울철에는 뒷면을 판자로 막아 찬공기의 유입을 차단한다. 분뇨의 제거가 용이하도록 우리는 쉽게 분해될 수 있어야 한다</p>
    </div>
  );
}

export default Do204;