import React from 'react';

// constants
import { BOARD_CODE, BOARD_NAME } from 'constants/backOfficeBoard';

// components
import CommonInfoEdit from '../_components/CommonInfoEdit';

function EditInfoNew() {
  return (
    <CommonInfoEdit
      boardCode={BOARD_CODE.NEW}
      boardName={BOARD_NAME.NEW}
      returnUrl="/bo/info/infoNew/list"
      apiEndpoints={{
        get: '/board/select_info',
        insert: '/board/insert_info/',
        update: '/board/update_info/',
        delete: '/board/delete_info/',
      }}
    />
  );
}

export default EditInfoNew;
