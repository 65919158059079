import React from 'react';

import Figure1by10 from 'assets/images/sub/_images/figure_1_10.gif';
import Figure1by11 from 'assets/images/sub/_images/figure_1_11.gif';
import Figure1by12 from 'assets/images/sub/_images/figure_1_12.gif';

function Fo10() {

  return (
    <div className="techinfo_view">
				<p className="viewTitle">호르몬 : 번식생리의 조절기구</p>
				
				<p>발정기의 행동, 배란, 임신유지 등 번식주기동안의 많은 일들은 호르몬에 의하여 조절된다.</p>
				<p className="mg60f">호르몬은 화학적 전령사로 불리우는데, 분비세포에 의하여 분비되어 혈관을 통하여 다른 세포 및 조직으로 이동하여 특정효과를 나타낸다. 호르몬을 분비하는 세포 및 기관을 내분비선이라고 하며 호르몬의 영향을 받아 특정 효과를 나타내는 기관을 표적기관이라고 한다(그림 1.10). 종종 하나의 기관이 내분비선과 표적기관의 두가지의 기능을 모두하는 경우도 있다 (예들 들면 난소). 내분비선은 일반적으로 혈관으로 아주 낮은 농도(1×10<sup>-9</sup>g 또는 1×10<sup>-12</sup>g/ ml)의 호르몬을 방출한다. </p>
				
				<h2>호르몬의 역할?</h2>
				<p>호르몬은 내분비선에 의해 생성되지만 모든 세포에 작용을 하는 것이 아니고 표적기관에 작용을 하는데 이 표적기관은 특정 호르몬의 수용체를 가지고 있다(그림 1.10). </p>
				<div className="imgBox"><img src={Figure1by10} alt=""/></div>
				<p>수용체는 자물쇠의 기능을 가지는데 특정호르몬이 자물쇠에 꼭 맞는 열쇠의 역할을 하여 표적기관에 작용을 하게 된다. 예를들어 하나의 기관이 에스트로젠의 표적기관일 때, 그 기관은 에스트로젠 수용체를 가지고 있다. 호르몬이 자신의 수용체와 결합한후에 표적기관의 세포적 반응이 시작된다. </p>
				<p className="mg60f">그리고 하나의 기관이 여러 호르몬의 수용체를 가지고 있는 경우도 있으며, 여러 가지 호르몬이 분비되었을 때 최종 반응이 변할수도 있다.</p>
				
				<h2>번식관련 호르몬 분비 기관 </h2>
				<p>뇌의 뇌하수체와 시상하부, 난소 그리고 자궁의 4가지 내분비계는 21일의 발정주기를 조절하는 호르몬을 분비한다. </p>
				<p>임신시에는 태반과 수정란도 호르몬을 생산한다. 호르몬의 분비 양상은 21일 발정주기동안 다르게 나타나는데 발정주기동안의 호르몬의 생산 기관과 표적기관에 대하여 (그림 1.11)에 요약되어 있다. </p>
				<div className="imgBox"><img src={Figure1by11} alt=""/></div>
				
				<dl>
					<dt>시상하부- GnRH </dt>
					<dd>
						<p>뇌의 하부에 위치하고 있으며 성선자극호르몬 방출호르몬(Gonadotropin-releasing hormone; GnRH)- 황체형성자극호르몬으로 불리기도 함(Luteinizing hormone-releasing hormone; LHRH)- 을 분비한다.</p>
						<p>GnRH 호르몬은 혈관을 따라 뇌하수체로 이동하여 뇌하수체에서 난포자극호르몬(the follicle stimulating hormone; FSH)과 황체형성호르몬(Luteinizing hormone; LH)의 분비를 조절 및 자극한다. GnRH 호르몬을 인위적으로 주사하면 직경이 10mm보다 큰 난포의 배란을 유도하며 또한 미성숙 난포의 성숙을 유도하여 새로운 난포주기의 성장을 시작한다. </p>
					</dd>
				</dl>
				
				<dl>
					<dt>뇌하수체 -FSH 와 LH </dt>
					<dd>
						<p>뇌하수체는 시상하부의 아래에 위치하고 있으며 뇌하수체 전엽에서 중요한 번식호르몬인 성선자극호르몬을 분비하여 배우자를 생성하는 기관을 자극한다(암컷에서는 난소, 수컷에서는 정소)</p>
						<p>FSH호르몬과 LH호르몬은 난소에 작용하는데, FSH는 난소의 난포의 성장을 유도하며, FSH와 LH의 공동작용으로 난포의 성장이 계속진행되도록 하며, LH는 성장한 난포가 배란이 되도록 한다. </p>
					</dd>
				</dl>
				
				<dl>
					<dt>난소의 난포 - 에스트로젠 </dt>
					<dd>
						<p>에스트로젠은 난소에서 발달한 난포에서 생성되는 호르몬이다. 난포의 크기가 커져감에 따라 에스트로젠의 혈중농도가 높아져 시상하부에서 발정기에 암컷의 발정행위를 유도한다.</p>
						<p>또한 에스트로젠의 높은 혈중농도는 뇌하수체에서 LH 농도의 급상승을 유도하여 배란이 되도록 한다. 따라서 에스트로젠의 높은 농도가 암컷이 수컷의 교미행위에 응하도록하여 배란후 배란된 난자가 정자를 만나 수정이 이루어지도록 조절한다.</p>
						<p>에스트로젠은 또한 근육의 위축을 자극하여 배란된 난자가 난관으로 내려갈수 있도록 유도한다. 그리고 질, 자궁경과 그리고 자궁 근육의 위축을 유도하여 정자가 난관으로 올라올수 있도록 유도한다. </p>
					</dd>
				</dl>
				
				<dl>
					<dt>황체- 프로제스테론 </dt>
					<dd>
						<p>배란후에 난포를 구성하던 세포는 황체세포로 전환되어 황체를 형성한다. 항체는 프로제스테론을 생산하는데, 이호르몬은 2가지의 효과를 나타낸다.</p>
						<ul className="listStyle04 mg15f">
							<li>임신을 위한 자궁의 준비 </li>
							<li>새로운 난포의 완전한 발달을 억제한다. 이러한 억제는 임신의 경우에는 발정주기의 재개를 막는다. </li>
						</ul>
						<p>따라서 만일 임신이 된다면 황체는 임신기간동안 임신황체로써 퇴행되지않고 역할을 계속한다. 임신이 되지 않았다면, 황체는 퇴행되고 프로제스테론의 분비도 멈추게되어 발정주기가 재개될 것이다.</p>
					</dd>
				</dl>
				
				<dl className="mg60f">
					<dt>자궁- 프로스타그란딘 </dt>
					<dd>
						<p>발정주기의 16-18일에 소의 자궁은 수정란의 유무를 인식할수 있다. 임신이 되지 않은 소에서는 자궁은 프로스타그란딘을 분비하는데 이 프로스타그란딘은 황체를 자극하여 퇴행을 유도한다. 따라서 프로스타그란딘의 황체퇴행 유도는 난포를 완전하게 성숙시키며, 며칠 이내로 발정이 오도록 유도한다. 만일 프로스타그란딘을 발정주기의 5일째에 주사를 주면 황체가 형성되지 않은 시기이므로 아무런 효과를 볼수 없다. 하지만 성숙된 황체를 가지고 있는 경우에는 프로스타그란딘을 주사한후 2일에서 7일 후에 발정이 온다.</p>
						<p>프로스타그란딘을 주사한후 발정이 며칠만에 오는지는 난포의 성장단계에 따라 다르게 나타난다. </p>
					</dd>
				</dl>
				
				<h2>발정주기의 호르몬 조절..</h2>
				<p className="mg30f">에스트로젠은 발정전기와 발정기를 지배하는 호르몬이며 프로제스테론은 발정후기와 발정휴지기를 지배하는 호르몬이다(그림 1.12) </p>
				<div className="imgBox"><img src={Figure1by12} alt=""/></div>
				<p className="mg30f">중요호르몬을 생산하는 기관과 표적기관 그리고 효과를 표 1.2에 요약했다.</p>
				
				<div className="tableWrap mg30f">
				<table className="tablestyle">
					<caption>표 1.2 발정주기를 조절하는 호르몬</caption>
					<thead>
						<tr>
							<th className="first">호르몬</th>
							<th>생산하는 장소</th>
							<th>표적기관</th>
							<th>효과</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>GnRH</td>
							<td>시상하부</td>
							<td>뇌하수체전엽</td>
							<td className="left">FSH와 LH의 증가</td>
						</tr>
						<tr>
							<td>FSH</td>
							<td>뇌하수체</td>
							<td>난소(난포)</td>
							<td className="left">난포의 발달과 에스트로젠의 생산</td>
						</tr>
						<tr>
							<td>LH</td>
							<td>뇌하수체</td>
							<td>난소(난포)</td>
							<td className="left">배란유도, 황체의 발달 그리고 프로제스테론의 생산</td>
						</tr>
						<tr>
							<td rowSpan="3">에스트로젠</td>
							<td rowSpan="3">난소(난포)</td>
							<td>뇌</td>
							<td className="left">발정행위 유도</td>
						</tr>
						<tr>
							<td>뇌하수체전엽</td>
							<td className="left">FSH 와 LH의 증가</td>
						</tr>
						<tr>
							<td>난관,자궁, 자궁경관,질과 외음순</td>
							<td className="left">정자와 난자의 이동을 돕기위하여 근육을 이완수축의 활동 그리고 점성이 낮은 점액의 분비 </td>
						</tr>
						<tr>
							<td rowSpan="2">프로제스테론</td>
							<td rowSpan="2">난소(황체)</td>
							<td>시상하부</td>
							<td className="left">난포의 완전한 성장과 배란을 억제</td>
						</tr>
						<tr>
							<td>자궁</td>
							<td className="left">근육의 활동을 억제시키고 자궁을 임신에 맞는 환경을 조성</td>
						</tr>
						<tr>
							<td>프로스타그란딘</td>
							<td>자궁</td>
							<td>난소(황체)</td>
							<td className="left">황체의 퇴행을 유도하고 프로제스테론의 분비를 감소</td>
						</tr>
					</tbody>
				</table>	
				</div>	
				
				<p>발정전기에는 비임신 자궁으로부터 프로스타그란딘의 방출이 황체의 퇴행을 유도한다. </p>		
				<p>황체의 퇴행으로 프로제스테론의 혈중농도가 떨어지는데 이것은 뇌하수체에서 성선자극호르몬(FSH와 LH)을 분비하도록 유도한다. LH와 FSH의 혈중농도가 증가하면 난소를 자극하여 난포의 성장이 일어난다. </p>	
				<p>여러개의 난포가 성장하지만 단지 하나의 난포가 (쌍태 또는 삼태일경우는 둘 또는 셋의 난포)가 완전하게 성장하여 배란된다. 완전히 성장하지 못한 난포는 퇴행되어진다.</p>	
				<p>대난포가 성장함에 따라, 난소의 가장 외막인 기저막은 LH와 결합하여 안드로젠을 생산하고, 과립막세포는 FSH의 자극을 받아서 안드로젠을 에스트로젠으로 전환시켜 대난포에서 에스트로젠을 분비한다. </p>	
				<p>발정기에는 에스트로젠의 농도가 증가함에따라 FSH와 LH의 농도의 증가를 야기시킨다. FSH와 LH 농도의 급증은 난포의 마지막 변화를 유도하며 난자는 최종적으로 배란되어진다.</p>	
				<p>배란시 난포이 파괴는 발정시작으로부터 24-32시간에 일어나며, 배란후 남아 있는 난포 세포들은 발정후기에 황체로 변화되어진다. 황체의 성장에따라 혈중 프로제스테론의 농도가 증가한다. 황체는 배란후 8-10경에 최대의 크기로 성장하는데, 그후에 자궁의 신호에 의존하여 임신일 경우는 계속 황체를 유지하는 반면 비 임신일 경우는 자궁으로부터 분비되는 프로스타그란딘의 영향을 받아 황체의 퇴행이 일어난다. 그리고 황체의 퇴행은 황체주기의 새로운 재개를 유도하는 것이다. </p>	
			</div>
  );
}

export default Fo10;
