import React from 'react';

import pic427 from 'assets/images/sub/_images/pic424.gif';
import pic428 from 'assets/images/sub/_images/pic425.gif';

function Do220() {

  return (
    <div className="techinfoview">
      <h1>분뇨저장시설</h1>
      <p className="pstyle">&nbsp;&nbsp;&nbsp;저장시설을 설계할 때는 ①목장시설의 운영 ②각종 건물의 위치 ③여름철 바람의 방향 등을 고려하여야 한다. 저장시설은 급수원과 착유시설로부터 최소한 각각 100＇과 50＇만큼 떨어진 곳에 위치해야 한다. <br/>&nbsp;&nbsp;&nbsp;지하수와 지표수를 오염시키지 않기 위하여, 저장시설이 설치될 토양상태를 잘 파악하여야 하는데, 저장시설은 자갈기반 위에 설치되지 않도록 하여야 하며, 암반(bedrock)과 지하수면으로부터 최소한 각각 3＇과 2＇만큼 높은 곳에 위치하여야 한다. <br/>&nbsp;&nbsp;&nbsp;저장시설은 분뇨를 채우고 퍼내기 쉽도록 설치하여야 하며, 날씨에 관계없이 접근할 수 있는 분뇨 수송장소가 마련되어야 한다. </p>
      <h2>슬러리와 액체분뇨 저장시설</h2>
      <p className="pstyle">&nbsp;&nbsp;&nbsp;저장시설의 규모는 ①관련법규 ②소의 두수와 체중 ③폐수생산량 ④강수량 및 ⑤저장기간에 따라 결정된다. 대부분의 기후 조건에서 분뇨와 폐수를 10-12개월 동안 저장할 수 있는 규모의 저장시설을 갖추는 것이 권장된다. 분뇨만을 저장할 경우에 비나 눈이 유입되는 것을 감안하여 저장시설의 크기를 10% 정도 증가시킨다. </p>
      <ul>
        <li>지하식 저장탱크 
          <ul>
            <li>지하식 저장탱크는 콘크리트로 만들어지며, 탱크의 깊이는 엄번과 지하수면의 위치와 펌프의 작동효율에 의하여 결정된다. 탱크는 외압, 하중, 지하수에 의한 압력 등에 견딜수 있을 만큼 튼튼하게 축조되어야 한다. 탱크를 축조한 후, 펌프의 손상을 방지하기 위하여 탱크내부에 있는 이물질은 제거되어야 하며 또한, 분뇨를 희석시키고 외압에 견디도록 물을 탱크의 6＂-12＂ 높이만큼 미리 채워야 한다. </li>
          </ul>
        </li>
        <li>저장웅덩이(earthen storage basin) 
          <ul>
            <li>저장웅덩이는 저렴한 비용으로 설치할 수 있는 장기 저장시설로, 위치와 분뇨를 퍼내는 방법에 따라 웅덩이 안쪽에 점토나 콘크리트 처리를 할수 있다.(그림98) 지하수와 지표수를 오염시키지 않도록 규정에 준하여 설치하여야 한다.  웅덩이 안쪽 기울기는 2:1-3:1(수평:수직)이 일반적이며, 웅덩이 바깥쪽 기울기는 3:1이 넘지 않도록 하고, 둑의 폭은 최소한 12＇정도 되게 하여 풀베는기계나 교반장비를 접근시킬 수 있어야 한다.(그림98) 분뇨를 부울 때 웅덩이에 손상을 주지 않도록 교반장비와 펌프가 위치한 곳에 콘크리트 경사로를 마련해야 한다.</li>
          </ul>
          <img src={pic427} className="nonborder" width="60%" alt=""/>
          <p>그림 98. 분뇨 저장 웅덩이</p>
        </li>
        <li>지상식 저장탱크 
          <ul>
            <li>지상식 저장탱크는 일반적으로 낮고 지름이 큰 탱크로서 사일로와 비슷한 모양을 나타낸다. 지상식 저장탱크는 운동장 빗물(lot runoff)을 저장할 수는 없지만, 규정에 준하는 부지를 마련할 수 없을 경우에 좋은 대안 시설이다.  콘크리트, 철근 콘크리트, 콘크리트 패널, 강철 등을 사용하여 높이가 10＇-13＇되고 지름이 30＇-120＇인 지상식 저장탱크를 만들 수 있다. 연결부, 이음매 및 볼트구멍을 통하여 분뇨가 새어나와 미관을 해치지만 대부분의 작은 틈은 분에 의해 막힌다. 건물기초와 측면벽 사이의 연결이 잘못되는 경우가 종종 있기 때문에 신뢰할 수 있는 건축업자와 계약하도록 하여야 한다. </li>
          </ul>
        </li>
        <li>저장시설 채우기(filling) 
          <ul>
            <li>지하식 저장탱크나 저장웅덩이의 경우에는 펌프, 중력에 의한 운반, 또는 트랙터 스크래퍼를 이용하여 저장시설에 분뇨를 채우며, 지상식 저장탱크에 분뇨를 채울 때는 일반적으로 펌프를 이용한다.  저장시설의 밑바닥 부분에 분뇨파이프의 유입구를 설치(하부유입식;bottom loading)하면 분뇨가 고루 퍼지고, 분뇨표면에 딱딱한 분층(crust)이 형성되어 파리발생이 적고 냄새가 덜난다. 파이프의 유입구는 막히지 않고 겨울철에 얼지 않도록 밑바닥으로부터 1＇정도 높은 곳에 위치하도록 한다. 또한 하부유입식은 분뇨를 밀어내므로 분뇨가 저장시설 내에 보다 균등하게 분포되도록 하는 장점이 있다.  이에 반해, 상부유압삭(top loading)의 경우에는 겨울철에 분뇨가 얼어붙어 유입구 주위에 쌓이게 되고, 딱딱한 분층이 형성되지 않아서 냄새와 파리발생이 많다. </li>
          </ul>
        </li>
        <li>교반과 분뇨 빼내기 
          <ul>
            <li>슬러리와 교반은 펌프질하여 생기는 액체의 일부 또는 전부를 교반노즐을 통하여 분사하면 딱딱한 분층을 부수고 가라앉은 고형분을 휘저어, 균일한 혼합상태의 분뇨가 되게 해준다. 희석이 필요한 경우에는 수분이 90%될 때까지 분뇨를 희석시킨다.  교반펌프로서는 원심 초퍼펌프와 스크루 펌프가 이용되는데, 저장웅덩이의 교반은 three-point hitch나 고성능 분뇨펌프를 부착시킨 프레일러를 사용한다. 이 경우에 펌프를 경사로 아래쪽으로 내리고 펌프흡입구를 분층 밑에 위치하도록 한다. 흡입구에는 분쇄기가 부착되어 있어서 딱딱한 분층을 분쇄하고 고형분을 흡입하여 철저하게 교반한다.  지상식과 지하식 저장탱크의 경우에는 원심 초퍼펌프를 분뇨에 잡기게 하여 교반한다. 지상식 저장탱크의 교반은 탱크기초에 부착시킨 펌프를 이용하는데, 탱크의 지름이 긴 경우에는 교반노즐이 중앙에 위치하도록 한다. 지하식 저장탱크의 교반은 탱크의 길이와 같게 한 6＇폭의 연속 부속시설을 이용한다. </li>
          </ul>
        </li>
        <li>관개시설 
          <ul>
            <li>관개시설도 분뇨를 저장시설로부터 배출시키는데 이용될 수 있다. 대부분의 관개시설은 고체-액체 분리시설, 분뇨저장못, 또는 착유장으로부터 유출되는 고형분 함량이 4%이하의 액체분뇨를 배출시키는데 이용된다. 시설비, 기계장비 및 동력이 작게 소요되는 표면 관개시설은 고형분 함량이 다소 높은 액체분뇨도 배출시킬 수 있으나, 기울기가 2% 이상인 지면에 설치되어서는 안된다. 표면 관개시설을 설계하고 관리할 때는 분뇨가 넘쳐 시설을 침식시키지 않도록 주의하여야 한다. 스프링클러 관개시설의 경우에는, 분뇨가 토양으로 잘 스며들지 않으며, 고다하게 작동시키면 분뇨가 토양표면에 넘쳐흐르기 때문에, 관리에 각별한 주의가 요구된다. 관개를 하기전에 토양을 갈아 엎어서 분뇨의 침투가 원활하도록 해주어야 한다. </li>
          </ul>
        </li>
        <li>안전 
          <ul>
            <li>액체분뇨 취급방식은 노동력을 절감시키는 장점은 있지만, 분뇨가스를 발생시키므로 위험을 내재하는 단점도 잇다. 노천 분뇨저장시설은 익사할 위험성이 있으며, 실제로 분뇨취급 사고로 희생자가 생긴 사례도 있다. 설계사, 판촉사원 및 시공자는 모두 실제 관리자에게 안전교육을 실시할 책임이 있다. 저장시설 주위에 반드시 경고문을 설치해야만 한다. 분뇨를 교반할 때, 발생되는 치명적인 독가스 중에서 황화수소(HS)는 독성이 가장 강하다. 이 밖에도 분뇨를 취급하거나 저장할 때 암모니아, 이산화탄소, 메탄 및 기타가스사 위험한 수준까지 발생될 수 있다. 액체 분뇨저장시설에서 작업헐 경우에 다음과 같은 주의 사항을 반드시 준수해야 한다. 
              <ul>
                <li>저장시설에 들어갈 때는 반드시 호흡장치가 부착된 의복이나 방독면을 착용해야 하며, 최소한 2명이 지켜보는 가운데 작업을 수행해야 한다. </li>
                <li>펌프와 장비의 수리는 반드시 저장시설 밖에서 하며, 절대로 수리를 위해서 분뇨수거조나 펌프조에 들어가지 말아야 한다. </li>
                <li>분뇨를 교반할 때는 건물내의 환풍시설을 최대로 가동시킨다. </li>
                <li>우사 밑에 수거된 분뇨를 교반하기 전에 가능하면 소를 밖으로 내보낸다. </li>
                <li>저장탱크의 교반구와 열린부분은 쇠창살판이나 뚜껑으로 막는다. </li>
                <li>교반과 청소 중에 보호를 위하여, 모든 접근 지역에 가로책을 설치한다. </li>
                <li>저장시설과 교반장소 주위에 위험 - 분뇨저장시설, 취험 - 독가스, 접근금지 등을 나타내는 위험표시판을 설치하여야 한다. </li>
                <li>펌프, 펌프호퍼, 살포기, 탱크짐차, 동력장치 등의 장비에 안전 덮개장치를 설치한다. </li>
                <li>울타리를 설치하여 사람의 접근을 방지한다.(울타리 맡이나 사이로 어린이도 통과할 수 없도록 만들어야 한다.) </li>
              </ul><br/>
            저장시설은 특히 어린이에게 위험하며, 가축도 익사될 수 있는 장소이다. 저장시설에 설치된 밀기식 경사로 (push-off ramp) 상단이나 경사로에서 미끄러져 트랙터와 운전사가 저장시설 안으로 빠져 들어갈 수 있으므로, 밀기식 경사로 상단에는 미끄러져 내려가는 트랙터를 막을 수 있을 정도로 강한 안전 가로책을 설치하여야 한다.  저장웅덩이의 표면에 형성되는 딱딱한 분층은 체중을 지탱할 수 있을 정도로 단단해 보이기 때문에 사람을 현혹시킬 수 있다. 연못의 얼음과 같이 일반적으로 저장웅덩이의 가장자리의 분층은 두껍지만 가운데의 분층은 얇다.  저장시설에 사람이나 가축이 진입하지 못하도록 설치하는 울타리의 높이는 최소한 5＇이며, 반드시 위혐표지판을 눈에 잘 띄게 세워서 설치하여야 한다. 노천식 저장시설에는 최소한 한군데의 구명줄과 장대를 갖춘 구명소를 마련해야 한다.  저장시설을 설계할 때는 항상 안전을 고려해야 한다. 공사 후에는 일년에 한번 씩 안전검사를 실시하여야하며 문제사 발견되면 즉시 고쳐야 한다. 세밀한 안전관리는 생명을 구한다. 	
            </li>
          </ul>
        </li>
      </ul>
      <h2>반고체 분뇨저장 시설</h2>
      <p className="pstyle">&nbsp;&nbsp;&nbsp;물이 첨가되지 않은 깔짚이 많이 포함된 분뇨는 고체나 반고체 상태로 보관되고 살포된다. 송아지와 분만 우리에는 깔짚이 많이 사용되기 때문에 이곳으로부터 배출되는 분뇨는 일반적으로 고체상태로 취급된다. 그러나, 이러한 깔짚함량이 높은 분뇨는 후리스톨이나 사료급여 통로로부터 발생되는 분뇨와 혼합되면 반고체 분뇨가 된다. </p>
      <ul>
        <li>배수되는 저장시설 
          <ul>
            <li>그림 99에서 보는 바와 같이 널판이나 말뚝사이에 폭 3/4＂의 수직 틈새가 있는 널판 또는 말뚝 댐 저장시설(picket dam storage)은 액체를 수직틈새를 통하여 빠져나가게 하고 고체분뇨만을 보유하게 된다. 틈새가 수평으로 형성되게 하면 안되며 구체적인 설계수치는 표 93에 나타나 있다.</li>
          </ul>
          <img src={pic428} className="nonborder" width="60%" alt=""/>
          <p>그림 99. 배수식 분뇨 저장 시설</p><br/>
          <p><b>&lt;표 93&gt; 말뚝 댐 저장시설 설계</b></p>
          <table className="tablestyle">
            <tr>
              <td rowSpan="2">말뚝의 높이</td>
              <td colSpan="2">기둥</td>
              <td rowSpan="2">말뚝 상단으로부터의 거리(ft)</td>
              <td colSpan="2">수평보조대</td>
            </tr>
            <tr style={{background: '#273143',color: '#fff'}}>
              <td>크기(in×in)</td>
              <td>간격(ft)</td>
              <td>크기(in×in)</td>
              <td>간격(ft)</td>
            </tr>
            <tr>
              <td>1-4</td>
              <td>4×6</td>
              <td>5</td>
              <td>0-4</td>
              <td>4×4</td>
              <td>3</td>
            </tr>
            <tr>
              <td>5</td>
              <td>6×6</td>
              <td>4</td>
              <td>4-6</td>
              <td>4×4</td>
              <td>2.5</td>
            </tr>
            <tr>
              <td>6</td>
              <td>6×8</td>
              <td>4</td>
              <td>6-8</td>
              <td>4×4</td>
              <td>2</td>
            </tr>
          </table>
          <p className="pstyle">하부유입식 피스톤 펌프나 밀기식 경사로를 이용하여 저장시설에 분뇨를 채울 수 있으며 분뇨를 저장시설로부터 배출시킬 때는 로더나 분뇨 어거를 이용한다.  저장시설로 분뇨 이외 어떠한 폐수도 유입되지 않도록 하여야 한다. 따라서, 이 저장시설을 통해서 단지 빗물만이 제거되기 때문에 분뇨중에 수분함량에는 변화가 없다. 전통적인 살포기를 사용하기 위해서는 짚이나 톱밥과 같은 깔짚을 분뇨에 첨가시키는 것이 좋으며, 이러한 형태의 저장시설은 모래를 깔짚으로 이용하는 후리스톨 우사에는 적합하지 않다. 저장시설로부터 배수되는 폐수는 지하수나 지표수를 오염시킬 수 있으므로, 수거탠크나 웅덩이에 모이게 한다. </p>
        </li>
        <li>지붕이 있는 저장시설(roofed storage) 
          <ul>
            <li>지붕이 있는 저장시설은 많은 양의 깔짚이 썩인 분뇨를 배출하는 젖소 우사를 위하여 개발된 것으로 저장시설의 콘크리트 바닥은 물매보다 3＇아래에 위치하도록 한다. 저장시설의 벽은 기둥과 널판 또는 콘크리트를 이용하여 12＇높이가 되게하며 내부유압에 견딜수 있도록 외부의 흙으로 채운다 (그림100). 지붕은 빗물의 유입을 막아 분뇨를 건조한 상태로 유지시키며, 분뇨 표묜은 딱딱하게 되어 냄새 및 파리발생을 줄여준다. 벽면 상단에 환기구를 설치한다. 이 저장시설에 분뇨는 피스톤 폼프를 이용하는 하부유입식으로 채워진다. 분뇨를 제거할때는 문을 구성하는 널판을 제거하여 분뇨가 밖에 설치된 콘크리트 평판위를 흘러 나가도록 한 다음, 로더로 흘러나온 분뇨를 살포기에 적재한다. </li>
          </ul>
        </li>
      </ul>
      <h2>고체 분뇨 저장시설</h2>
      <p className="pstyle">&nbsp;&nbsp;&nbsp;고체분뇨 저장시설은 처녀소나 건유우를 위한 루즈반으로부터 배출되는 분뇨를 저장하는데 적합하다. 저장시설에 분뇨를 채울 때는 트랙터 스크래퍼, 승강식 적재기, 또는 피스톤펌프를 이용할 수 있다. 운동장 빗물이 저장시설에 유입되지 못하도록 하여 분뇨를 살포하기 적절한 때면 아무때나 접근할 수 있도록 저장시설은 연중 접근이 가능한 지역에 위치하도록 한다. <br/>&nbsp;&nbsp;&nbsp;분뇨를 넣고 꺼내는 장비를 접근시킬 수 있도록 편리한 접근시설을 마련해야 한다. 빗물과 지표 유출수의 유입을 방지하기 위해서는 저장시설에 접근하는 사로의 경사를 위로 향하게 하여야 한다. 분뇨배출용 경사로의 폭은 최소한 40＇되어야 하며 기울기는 10:1이 되게 한다. 경사로의 표면을 거찰게 처리하면 미끄럼을 방지할 수 있고 경사로면을 가로질러 비스듬하게 홈을 만들어 빗물이 배수되도록 한다. <br/>&nbsp;&nbsp;&nbsp;바닥과 경사는 2%(1/4/ft) 되게 하며, 콘크리트로 처리한다. 기둥과 널판 또는 콘크리트를 사용하여 벽을 만들며, 벽면 중에 1-2개는 분뇨를 넣고 꺼내는 장비의 하중을 견뎌낼 수 있도록 튼튼하게 만든다.</p>
    </div>
  );
}

export default Do220;