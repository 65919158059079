import React from 'react';


function Do28() {

  return (
    <div className="techinfoview">
				<h1>착유우의 가소화 영양소 총량과 정미에너지</h1>
				<p className="pstyle">&nbsp;&nbsp;&nbsp;가소화영양소총량 (tatal digestible nutrients : TDN)이나 정미에너지 (net energy : NE)는 모두 젖소의 에너지요구량 또는 사료중의 에너지 수준을 표시하는 지표이다. 에너지를 표시하는 지표는 이들 외에도 사료의 총열량을 말하는 총에너지 (gross energy : GE), 총에너지에서 분으로 배설되는 에너지를 차감한 가소화에너지 (digestible dry matter : DDM), 가소화에너지에서 오줌으로 배설되는 에너지를 뺀 대사에너지 (metabolizable energy : ME)등이 있다.<br/><br/>&nbsp;&nbsp;&nbsp;정미에너지는 대사에너지에서 열발생으로 손실되는 에너지를 뺀 것을 말한다. 그런데 같은 사료를 젖소에 급여하더라도 그 사료가 체유지사료로 쓰이느냐 또는 성장 (증체)이나 우유분비를 위해 사용되느냐에 따라 정미에너지가가 다르다. 따라서 NRC 사료성분표를 보면 사료마다 체유지 정미에너지 (NEm.), 증체 정미에너지 (NEg.), 우유생산 정미에너지 (NEl.)를 다 표시하고 있다. 여기서 사료별 총에너지와 정미에너지와의 관계를 보면 다음 표 8에서 보는 바와 같다.</p><br/>
				<p><b>&lt;표 8&gt; 사료별 총에너지와 정미에너지와의 관계</b></p>
				<table className="tablestyle">
					<tr>
						<td>사료</td>
						<td>총에너지</td>
						<td>분뇨로 배설되는 에너지</td>
						<td>대사에너지</td>
						<td>열손실</td>
						<td>정미에너지</td>
					</tr>
					<tr>
						<td colSpan="6">············ Mcal / 건물 1kg ············</td>
					</tr>
					<tr>
						<td>분쇄옥수수</td>
						<td>4.42</td>
						<td>1.12</td>
						<td>3.30</td>
						<td>1.27</td>
						<td>2.03</td>
					</tr>
					<tr>
						<td>티모시 건초</td>
						<td>4.51</td>
						<td>2.66</td>
						<td>1.85</td>
						<td>0.75</td>
						<td>1.10</td>
					</tr>
					<tr>
						<td>크로바 건초</td>
						<td>4.44</td>
						<td>2.46</td>
						<td>1.98</td>
						<td>0.97</td>
						<td>1.01</td>
					</tr>
					<tr>
						<td>밀 짚</td>
						<td>4.44</td>
						<td>3.06</td>
						<td>1.38</td>
						<td>1.17</td>
						<td>0.21</td>
					</tr>
				</table><br/>
				<p className="pstyle">&nbsp;&nbsp;&nbsp;즉 사료별 건물 1kg당 총에너지는 거의 차이가 없으나 분뇨 및 열손실을 제외한 정미에너지 값은 서로 큰 차이가 있음을 알 수 있다. 에너지는 Mcal(megacalorie)로 표시되는 바 1Mcal는 물 1kg의 온도를 1℃올리는데 소요되는 열량을 말하며 이를 &quot;therm&quot;으로 나타내기도 한다.<br/><br/>&nbsp;&nbsp;&nbsp;젖소에 대한 정미에너지는 가소화영양소총량을 기초로 계산할 수 있다. 사료종류별 가소화 영양소총량과 정미에너지와의 관계를 보면 다음과 같다.</p><br/>
				<ul>
					<li>곡류 : TDN 1kg = 2.20 Mcal</li>
					<li>양질의 조사료 : TDN 1kg = 1.65 Mcal</li>
					<li>저질 조사료 : TDN 1kg = 1.10 Mcal</li>
				</ul><br/>
				<p>미농무성이 만든 사료성분표를 기초로 정미에너지를 추정하는 공식을 보면 다음과 같다. </p><br/>
				<ul>
					<li>정미에너지 추정치 (Mcal) = 0.0245 × TDN (%, DM기준) - 0.12</li> 
				</ul><br/>
				<p>이는 모두 건물량을 기초로 계산하는데, 예를들어 건물량이 85%이며, 가소화 영양소총량이 80.1%인 옥수수의 정미에너지는 다음과 같이 계산된다. </p><br/>
				<ul>
					<li>80.1 × 100/85 = 94.2% TDN (DM기준)</li>
					<li>94.2 × 0.0245 - 0.12 = 2.19 Mcal / kg NE (DM기준)</li>
				</ul>
			</div>
  );
}

export default Do28;
