import React from 'react';

// 이미지
import ox1BackMo from 'assets/images/containers/ox/oxData/ox1_back_mo.png';
import ox1BackPc from 'assets/images/containers/ox/oxData/ox1_back_pc.png';
import oxCon1 from 'assets/images/containers/ox/oxData/ox1_con1.png';
import oxCon2 from 'assets/images/containers/ox/oxData/ox1_con2.png';
import oxCon3 from 'assets/images/containers/ox/oxData/ox1_con3.png';
import oxCon4 from 'assets/images/containers/ox/oxData/ox1_con4.png';
import oxCon5 from 'assets/images/containers/ox/oxData/ox1_con5.png';

// scss
import './oxData.scss';

function Ox1() {
  return (
    <div className="ox1">
      <dl className="ox1Title">
        <dt>한국형 젖소보증 씨수소란?</dt>
        <dd>
          한국형 씨수소,<span> 자체 생산의 긴 역사와 뛰어난 신뢰성</span>
        </dd>
      </dl>
      <img
        srcSet={`${ox1BackMo} 768w, ${ox1BackPc} 769w`}
        sizes="(max-width: 768px) 100vw, 50vw"
        alt="한국형 젖소보증 씨수소 배경사진"
      />
      <ul className="ox1Info">
        <li>
          <span>의미</span>
          <p>
            우리나라 최초로 자체 생산한 &quot;명성&quot;을 시작으로 1995년에
            &quot;한강&quot; 등을 선발 개시하였고, 2024년까지 94두를 선발하여
            활용되고 있는 씨수소가 &quot;한국형&quot; 젖소 씨수소이다.
            <span>
              우리나라 낙농가의 땀과 노력, 그리고 한국 젖소 유전자원 개발을 위한
              정부와 농협의 의지로 맺은 결실이다.
            </span>
          </p>
        </li>
        <li>
          <span>특성</span>
          <ul>
            <li>
              <img src={oxCon1} alt="우수성 사진" />
              <strong>01. 우수성</strong>
              <p>
                <span>
                  우리나라의 고유환경과 사육조건에서 최고의 능력을 발휘하는
                  씨수소이다.
                </span>
                우리나라가 갖고 있는 기후, 온도변화, 습도, 강우량 또한 젖소
                사육조건에서 가장 높은 능력을 발휘할 수 있는 씨수소로서 이러한
                씨수소를 선발하기 위해 6년 전부터 후보씨수소들의
                유전자&#40;정액&#41;가 전국에 보급되어 생산된 딸소들의 유량,
                유성분, 그리고 체형 등에 대한 능력을 검증받아 첨단의 유전통계적
                기법을 이용, 후보씨수소들의 능력을 추정한다. 특히, 후보
                씨수소들의 정액을 통해 전달된 유전능력을 정확히 추정하여 가장
                우수한 개체들을 선발한다.
              </p>
            </li>
            <li>
              <img src={oxCon2} alt="역사성 사진" />
              <strong>02. 역사성</strong>
              <p>
                <span>
                  30년&#40;1995년~현재&#41; 이상의 역사를 지닌 국내에서
                  유일무이한 사업
                </span>
                이며, 정부와 농협이 함께 협력하여 자체 선발하고, 선발된
                유전자원&#40;정액 등&#41;을 생산하고 국내 낙농가에 공급하고 있는
                젖소 씨수소 선발사업이다.
              </p>
            </li>
            <li>
              <img src={oxCon3} alt="정확성 사진" />
              <strong>03. 정확성</strong>
              <p>
                <span>
                  생산&#40;유량, 유지량 등&#41; 능력, 체형 등을 동시에 고려하여
                  정부, 젖소개량사업소, 대학, 낙농가의 공동노력에 의해
                  선발하였다.
                </span>
                한국형 씨수소는 낙농선진국&#40;북미&#41;의 상위 0.1% 이상의 초
                고능력을 갖고 있는 유전자원을 도입함과 동시에 국내 선도농가의
                유전능력 상위 개체들의 유전자원을 확보·이용하여 생산된
                수송아지의 유전체유전능력, 외모 및 발육상태, 정액성상, 혈통,
                등을 고려하여 후보씨수소 선발 후 후대검정용 정액을 생산, 전국의
                낙농가에 보급을 통해 생산된 딸소들의 능력&#40;유생산, 체형
                등&#41;을 검증하여 정부, 개량관련기관 및 학자들로 구성된
                가축개량협의회&#40;젖소분과&#41;에서 한국형 젖소 씨수소가
                선발된다.
              </p>
            </li>
            <li>
              <img src={oxCon4} alt="신뢰성 사진" />
              <strong>04. 신뢰성</strong>
              <p>
                <span>
                  우리나라 전국 낙농가&#40;낙농현장&#41;에서 실제로 검증되었다.
                </span>
                2012년부터 참여하고 있는 국제유전능력평가&#40;MACE&#41;를
                통해서도 국내생산 씨수소의 우수한 성적을 검증받고 있다. 또한
                국립축산과학원을 통해 매년 3차례 평가되고 있는 유전능력평가
                결과를 통해서도 국내산 정액과 수입되고 있는 정액 전체를 망라한
                4천 여두의 씨수소 중 한국형 씨수소인 아크&#40;208HO0809&#41;와
                샤인&#40;208HO0878&#41;, 그리고 슈퍼사이어&#40;208HO0829&#41;의
                경우 종합지수&#40;KTPI&#41;등이 상위 5% 이내의 성적을 차지하여
                한국형 씨수소의 능력이 국내에서 유통되고 있는 타 정액보다 뛰어난
                씨수소임을 과학적으로 입증하였다.
              </p>
            </li>
            <li>
              <img src={oxCon5} alt="경제성 사진" />
              <strong>05. 경제성</strong>
              <p>
                <span>외화 절약의 한 몫을 하였다.</span>지금까지 국내로 외국의
                유전인자를 들여오기 위해 많은 외화를 지출해왔는데 한국형
                보증씨수소 선발로 많은 외화를 절약하였다. 또한, 우리나라와
                유사한 기후를 가진 동아프리카 등 해외시장에도 수출을 늘려가고
                있다.
              </p>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
}

export default Ox1;
