import React from 'react';

function Fo47() {

  return (
    <div className="techinfo_view">
				<p className="viewTitle">골연화증</p>
				<dl>
					<dt>발병대상</dt>
					<dd>모든 동물</dd>
				</dl>
				
				<dl>
					<dt>원인</dt>
					<dd>부적절한 칼슘 및 인 함량, 칼슘, 인 비율의 부정확, 일정한 지역에 가두어 키울때는 비타민 D 부족.</dd>
				</dl>
				
				<dl>
					<dt>징후 및 증상</dt>
					<dd>인이 결핍되면 이기증(뼈와 나무등을 갉아 먹거나 또는 먼지 등을 먹는 행위)과 식욕부진, 그리고 뼈와 뻣뻣해지고 호흡이 불규칙해지며 산유량이 감소하고 쇠약해진다.</dd>
					<dd>칼슘 결핍증은 뼈가 약해지며 번식장애와 산유량 감소를 야기한다.</dd>
					<dd>성축에서 많이 발생하며 이의 급성증상은 임신과 비유기에 주로 일어난다. </dd>
				</dl>
				
				<dl>
					<dt>질병분포와 손실규모</dt>
					<dd>미국 남서부 지역은 인 결핍지역으로 분류되어 있으며 칼슘 결핍지역도 일부 보고되고 있다.</dd>
				</dl>
				
				<dl>
					<dt>대책 및 치료</dt>
					<dd>칼슘과 인의 함량이 풍부한 사료를 선택하여 급여하거나 특수 미네랄제제를 보충하여 준다. 병이 더 진행되면 치료가 불가능하다. </dd>
				</dl>
				
				<dl>
					<dt>예방</dt>
					<dd>균형잡힌 사료를 공급하거나 필요하다면 적절한 칼슘과 인 보충제를 자유로이 섭취할 수 있도록 하여준다. 또한 토양을 비옥하게 하여 사료내 칼슘과 인의 함량이 증가하도록 한다.</dd>
				</dl>	
				
				<dl>
					<dt>참고</dt>
					<dd>소와 양 및 말의 경우는 인 결핍증의 발생빈도가 높고 칼슘 결핍증은 미네랄 함량이 낮은 곡류를 주로 섭취하는 돼지의 경우에 많이 발생한다. </dd>
				</dl>
			</div>
  );
}

export default Fo47;
