import React from 'react';
import SuperiorCow from 'containers/web/business/superiorCow/SuperiorCow';
import { Helmet } from 'react-helmet-async';

function SuperiorCowPage() {
  return (
    <>
      <Helmet>
        <title>씨수소 사양관리</title>
      </Helmet>
      <SuperiorCow />
    </>
  );
}

export default SuperiorCowPage;
