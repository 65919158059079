import React from 'react';

import pic635 from 'assets/images/sub/_images/pic631.jpg';

function Do124() {

  return (
    <div className="techinfoview">
      <h1>유방습진</h1>
      <div className="leftpart">
        <h2>원인</h2>
        <ul>
          <li>분만 후 유방부종이 심할 때 발생하기 쉽다.</li>
        </ul>
        <h2>목장에서의 처치</h2>
        <ul>
          <li>농후사료를 줄이고 착유회수를 늘린다. 통풍이 잘 되도록 한다. 우사내의 건조, 일광욕의 실시. 사료배합의 주의 아연화 전분가루를 비벼 붙힌 뒤 상처가 마르면 즉시 연고를 발라준다. 1∼2회 실시로 회복되는 일이 많다.</li>
        </ul>
      </div>
      <div className="rightpart">
        <img src={pic635} className="nonborder" alt=""/>
      </div>
    </div>
  );
}

export default Do124;