import React from 'react';

function Do9() {

  return (
    <div className="techinfoview">
				<h1>홀스타인 빈우 외모심사 표준</h1>
				<table className="tablestyle" id="tablestyle4">
					<tr>
						<td rowSpan="2">부위</td>
						<td rowSpan="2">설명</td>
						<td colSpan="2">배점</td>
					</tr>
					<tr style={{background: '#273143', color:'#fff'}}>
						<td>♀</td>
						<td>♂</td>
					</tr>
					<tr>
						<td>1. 일반외모<br/><br/>엉덩이<br/>키<br/>전구<br/>등<br/>허리<br/><br/>품종의 특징</td>
						<td>
							<ul>
								<li>지제를 제외한 나머지 부분의 골격을 평가화되 우선 순위별로 아래와 같은 형질을 심사한다. </li>
								<li>요각보다 좌골이 약간 낮은 상태로 길이가 길며 너비가 넓은 것</li>
								<li>곤부는 좌우로 넓게 벌어져 있고 좌골과 요각사이의 중앙에 위치할 것 </li>
								<li>미근은 좌골보다 약간 높고 산뜻하게 붙어 있을 것 </li>
								<li>꼬리는 조잡하지 않을 것</li>
								<li>외음부는 거의 수직일 것 </li>
								<li>몸 전체를 통하여 뼈의 길이가 길 것</li>
								<li>기갑과 엉덩이 높이가 상대적으로 적절할 것 </li>
								<li>앞다리가 곧 바르고 적절히 넓게 벌어져 있고 장방형으로 딛고 있어서 앞몸을 잘 지탱할 것 </li>
								<li>견갑골과 견단이 흉벽에 단단히 붙어 있어 견후가 적당히 충만 되어 있을 것 </li>
								<li>수평이고 튼튼할 것 </li>
								<li>넓고 강하고 거의 수평일 것 </li>
								<li>암소 다운면서 전반적으로 모양새를 갖추고 균형이 잡혀 잇을 것 </li>
								<li>머리는 윤곽이 선명하고 강한 턱과 크게 벌어진 콧구멍 및 넓은 주둥이를 갖출 것</li>
								<li>얼굴은 접시골로 약간 우목하게 들어간 모양일 것</li>
							</ul>
							<p>※ 일반 외모는 엉덩이, 키 및 전구를 중점적으로 고려할 것</p>
						</td>
						<td>15</td>
						<td>30</td>
					</tr>
					<tr>
						<td>2. 유용특질<br/><br/>갈비<br/>허벅지<br/>기갑<br/>목<br/>피부</td>
						<td>
							<ul>
								<li>외모에 나타난 산유능력을 평가함에 있어 전반적인 예각성과 개장성을 중점적으로 고려하되 우선 순위별로 아래와 같은 형질을 심사한다.</li>
								<li>사이가 넓게 벌어져 있고 뼈 자체도 넓고 편평하고 깊으며 뒤쪽으로 휘어져 있을 것 </li>
								<li>지방이 끼지 않고 안쪽으로 편평하며 뒤에서 보아 넓게 벌어져 있을 것 </li>
								<li>등뼈의 윤곽이 선명하고 날카로울 것</li>
								<li>길고 날씬하면서 어깨로의 이어짐이 부드럽고 목구멍에서 가슴까지의 윤곽이 산뜻할 것 </li>
								<li>얇고 낙낙하며 신축성이 있을 것</li>
							</ul>
						</td>
						<td>20</td>
						<td>25</td>
					</tr>
					<tr>
						<td>3. 체적<br/><br/>몸통<br/>가슴</td>
						<td>
							<ul>
								<li>나이를 고려한우체의 용적(길이, 깊이, 너비)을 평가하며 우선 순위별로 아래와 같은 형질을 심사한다.</li>
								<li>길고, 깊고, 넓으며 뒤로 갈수록 갈비의 개장이 넓고 깊이가 깊어야 하며 아래 허구리도 깊을 것</li>
								<li>잘 벌어진 앞갈비와 어깨로의 이어짐이 좋을 것</li>
								<li>흉심이 깊고 가슴바닥이 넓을 것</li>
							</ul>
							<p>※ 체적평가시는 몸통에 일차적 비중을 두어 심사한다.</p>
						</td>
						<td>10</td>
						<td>20</td>
					</tr>
					<tr>
						<td>4. 지제<br/><br/>발굽<br/>뒷다리</td>
						<td>
							<ul>
								<li>발굽과 뒷다리를 평가하는 것으로 활동성에 중점을 두어 심사하되 우선 순위별로 아래와 같은 형질을 심사한다.</li>
								<li>발톱사이가 벌어지지 않고 둥글고 짧은 발톱으로 각도가 높으며 뒤꿈치가 깊을 것</li>
								<li>뒤에서 볼 때 : 곧바르고 넓게 사각적으로 설 것</li>
								<li>옆에서 볼 때 : 비절에서 적당히 굽어 있을 것</li>
								<li>뼈 : 적당히 질량감을 유지하며 편평하고 선명할 것</li>
								<li>발목 : 탄력을 유지하면서 짧고 강할 것</li>
							</ul>
							<p>※ 뒷다리보다는 발굽에 조금 더 비중을 두어 심사한다.</p>
						</td>
						<td>15</td>
						<td>25</td>
					</tr>
					<tr>
						<td>5. 유방<br/><br/>유방깊이<br/>유두부착<br/>뒷유방<br/>정중제인대<br/>앞유방<br/>유두<br/>유방균형과 조직</td>
						<td>
							<ul>
								<li>비유기관인 유방을 평가하는 것으로 높은 산유량과 오랫동안의 생산수명을 유지할 수 있는 형질에 많은 비중을 두어 심사하되 우선 순위별로 아래와 같은 형질을 심사한다.</li>
								<li>적당한 용적과 여유를 가지면서도 비절을 기준으로 적절한 깊이를 유지할 것 </li>
								<li>나이나 산차를 고려하여 심사할 것 </li>
								<li>직사각형으로 각 유구의 중앙부분에 붙어 있어야 하며 수직이고, 옆에서 볼 때나 뒤에서 볼 때 적당한 간격을 유지할 것 </li>
								<li>높고 넓으며 강하게 붙어 있어야 하며 위 아래의 너비가 일정하고 유방바닥으로 이어지는 부분은 약간 둥글게 이어질 것 </li>
								<li>적당히 좌우 유구가 구분될 정도로 강한 제인대를 보여줄 것</li>
								<li>풍부한 용적과 적당한 길이를 가지고 강하게 붙어 있을 것 </li>
								<li>원통형 모양으로 중간정도의 기이와 직경을 유지하며 크기가 일정할 것 </li>
								<li>옆에서 볼 때 유방바닥이 수평일 것</li>
								<li>대응되는 유구들이 균형을 유지할 것</li>
								<li>착유 후에는 잘 수축되고 부드러우며 신축성이 있을 것</li>
							</ul>
						</td>
						<td>40</td>
						<td>-</td>
					</tr>
					<tr>
						<td>계</td>
						<td></td>
						<td></td>
						<td>100</td>
					</tr>
				</table>
			</div>
  );
}

export default Do9;
