import React from 'react';

function TableMilkPerformance({ milkPerformance }) {
  if (!milkPerformance || !Array.isArray(milkPerformance)) {
    return (
      <div className="tablebox milkPerformanceTable">
        <table>
          <thead>
            <tr>
              <th>산차</th>
              <th>분만일</th>
              <th>착유일수</th>
              <th>누적유량</th>
              <th>305일 유량</th>
              <th>체세표(천Cell)</th>
              <th>유지방(%)</th>
              <th>유단백(%)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colSpan="8">데이터가 없습니다.</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

  const total = milkPerformance.reduce(
    (accumulator, currentObject) => accumulator + (currentObject?.CUMDAY || 0),
    0,
  );
  const total2 = milkPerformance.reduce(
    (accumulator, currentObject) => accumulator + (currentObject?.CUMMILK || 0),
    0,
  );
  const average =
    milkPerformance.length > 0
      ? milkPerformance
          .map((data) => data?.MILK305 || 0)
          .reduce((sum, milk) => sum + milk, 0) / milkPerformance.length
      : 0;

  return (
    <div className="tablebox milkPerformanceTable">
      <table>
        <thead>
          <tr>
            <th>산차</th>
            <th>분만일</th>
            <th>착유일수</th>
            <th>누적유량</th>
            <th>305일 유량</th>
            <th>체세표(천Cell)</th>
            <th>유지방(%)</th>
            <th>유단백(%)</th>
          </tr>
        </thead>
        <tbody>
          {milkPerformance.map((data, i) => (
            <tr key={i}>
              <td>{data?.PARI || '-'}</td>
              <td>{data?.BUNDATE || '-'}</td>
              <td>{data?.CUMDAY?.toLocaleString() || '-'}</td>
              <td>{data?.CUMMILK?.toLocaleString() || '-'}</td>
              <td>{data?.MILK305?.toLocaleString() || '-'}</td>
              <td>{data?.SCCAVG?.toLocaleString() || '-'}</td>
              <td>{data?.AVGFATP?.toLocaleString() || '-'}</td>
              <td>{data?.AVGPROTP?.toLocaleString() || '-'}</td>
            </tr>
          ))}
        </tbody>

        <tfoot>
          <tr>
            <td colSpan="2">생애</td>
            <td>{total.toLocaleString()}</td>
            <td>{total2.toLocaleString()}</td>
            <td>{Math.floor(average).toLocaleString()}</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
}

export default TableMilkPerformance;
