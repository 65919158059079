import React from 'react';

import Pic111 from 'assets/images/sub/_images/pic111.gif';
import Pic112 from 'assets/images/sub/_images/pic112.gif';
import Pic113 from 'assets/images/sub/_images/pic113.gif';
import Pic114 from 'assets/images/sub/_images/pic114.gif';
import Pic115 from 'assets/images/sub/_images/pic115.gif';
import Pic116 from 'assets/images/sub/_images/pic116.gif';
import Pic117 from 'assets/images/sub/_images/pic117.gif';
import Pic118 from 'assets/images/sub/_images/pic118.gif';
import Pic119 from 'assets/images/sub/_images/pic119.gif';
import Pic120 from 'assets/images/sub/_images/pic120.gif';
import Pic121 from 'assets/images/sub/_images/pic121.gif';
import Pic122 from 'assets/images/sub/_images/pic122.gif';
import Pic123 from 'assets/images/sub/_images/pic123.gif';
import Pic124 from 'assets/images/sub/_images/pic124.gif';
import Pic125 from 'assets/images/sub/_images/pic125.gif';

function Do13() {

  return (
    <div className="techinfoview">
    <h1>지제에 대하여</h1>
    <p style={{lineHeight: '200%'}}>&nbsp;&nbsp;&nbsp;오랜 기간 동안 지제(발굽과 다리)는 전체적인 일반외모의 한 부분에 속해 있었으나, 세월이 경과함에 따라 농가가 업계 전문가들이 지제 부문이 장수성에의 역할이 크다는 것을 인식하게 되었다. 1994년에 젖소 품종 등록협회(미국)들이 심사표준을 개정하여 지제에 15점을 따로 분리 배점할 것을 결의하여 오늘날에 이르게 되었다. <br/>&nbsp;&nbsp;&nbsp;가끔 낙농생산성 계산시 지제의 강건성을 간과하는 경우가 있지만 매우 중요하다. 발정 발견시 실패, 낮은 번식성적, 대체우 생산비용의 상승 등이 모두 나쁜 지제 때문에 발생되는 비효율이라 하겠다. 다행스럽게도 이러한 문제의 일부가 양질의 영양관리나 발굽삭제(깎기)에 의해 바로잡아질 수 있다는 것이다. <br/>&nbsp;&nbsp;&nbsp;옆에서 본 뒷다리의 모습을 평가해 보면 얼마나 오랫동안 우군 내에 살아 남을 수 있는지를 가늠해 볼 수 있다. 비절에서 너무 직비인 뒷다리는 걸어다닐 때의 충격을 흡수할 수 있는 능력이 부족하여 수명이 짧아 진다. 반대로 비절에서 너무 곡비인 경우 체중이 발굽의 뒤쪽으로 쏠려서 발굽질병을 초래하게 된다. 이 형질을 심사하기 위해서는 뒷다리 비절의 굽은 정도를 평가하여야 한다. 앞다리는 고려할 필요가 없다.</p> <br/>
    <table className="tablestyle6">
        <tr>
            <td colSpan="5"><b>legs side view 옆에서 본 뒷다리</b></td>
        </tr>
        <tr>
            <td>&gt;45 극히 낫처럼 휜곡비</td>
            <td>38~32 약간 휨</td>
            <td>28~22 중간정도로 휨</td>
            <td>18~12 약간직비</td>
            <td>&lt;5 극히 심한 직비</td>
        </tr>
        <tr>
            <td><img src={Pic111} alt="옆에서 본 뒷다리 정도"/></td>
            <td><img src={Pic112} alt="옆에서 본 뒷다리 정도"/></td>
            <td><img src={Pic113} alt="옆에서 본 뒷다리 정도"/></td>
            <td><img src={Pic114} alt="옆에서 본 뒷다리 정도"/></td>
            <td><img src={Pic115} alt="옆에서 본 뒷다리 정도"/></td>
        </tr>
    </table>
     <br/>
    <p>
        뒷다리의 뒤에서 본 모습은 한때는 연구 형질이었으나 중요성이 인식된 후 공식 선형형질 로 포함되었다. 이 형질은 뒤에서 보았을 때 뒷다리가 얼마나 똑바로 서 있는지를 평가하는 것이다. 양비절이 약간 안쪽으로 가까운 것이 자연스러운 것이기는 하지만, 곧바르게 딛고 있을수록 더 바람직한 것이다. 
    </p>
     <br/>
    <table className="tablestyle6">
        <tr>
            <td colSpan="5"><b>legs rear view 뒤에서 본 뒷다리</b></td>
        </tr>
        <tr>
            <td>&gt;45 극히 수직으로 닫고 있음</td>
            <td>38~32 거의 수직임</td>
            <td>28~22 양 비절이 약간 근접</td>
            <td>18~12 양비절이 근접</td>
            <td>&lt;5 양비절이 극히 근접</td>
        </tr>
        <tr>
            <td><img src={Pic116} alt="뒤에서 본 뒷다리 정도"/></td>
            <td><img src={Pic117} alt="뒤에서 본 뒷다리 정도"/></td>
            <td><img src={Pic118} alt="뒤에서 본 뒷다리 정도"/></td>
            <td><img src={Pic119} alt="뒤에서 본 뒷다리 정도"/></td>
            <td><img src={Pic120} alt="뒤에서 본 뒷다리 정도"/></td>
        </tr>
    </table>
     <br/>
    <p>
        발굽각도는 지면과 발톱이 이루는 각도를 관찰하는 것으로 45℃정도의 중간 각도가 25점 이다. 발굽각도가 높을수록 바람직하다. 발굽의 발뒤꿈치(heel) 두께에 영향을 받지 말아야 하나, 지제의 최종점수에는 반영이 된다.
    </p>
     <br/>
     <br/>
    <table className="tablestyle6">
        <tr>
            <td colSpan="5"><b>foot angle 발굽 각도</b></td>
        </tr>
        <tr>
            <td>&gt;45 극히 각도가 큼</td>
            <td>38~32 큰 각도</td>
            <td>28~22 중간정도(45˚)</td>
            <td>18~12 약간 낮은 각도</td>
            <td>&lt;5 극히 낮은 각도</td>
        </tr>
        <tr>
            <td><img src={Pic121} alt="발굽각도 정도"/></td>
            <td><img src={Pic122} alt="발굽각도 정도"/></td>
            <td><img src={Pic123} alt="발굽각도 정도"/></td>
            <td><img src={Pic124} alt="발굽각도 정도"/></td>
            <td><img src={Pic125} alt="발굽각도 정도"/></td>
        </tr>
    </table>
     <br/>
</div>
  );
}

export default Do13;
