import React from 'react';


function Do64() {

  return (
    <div className="techinfoview">
				<h1>질병예방 관리-기타질병관리</h1>
				<h2>위생관리</h2>
				<p>송아지 설사나 내부 기생충등이 자주 발생할 경우에는 적절한 위생관리를 통하여 좋은결과를 얻을 수 있도록 개선해야 한다. 다음과 같은 실제적인 사항등에 주의를 기울여서 환경개선을 통한 질병예방에 힘써야 할 것이다. </p>
				<ul>
					<li>분만실은 완전히 청소, 소독하고 사용하지 않을때는 비워둔다. 
						<ul>
							<li>분뇨와 깔짚을 완전히 치우고 바닥과 벽을 세척제로 깨끗이 닦어내고 소독수를 뿌리면서 닦은후, 마른 다음 새 깔짚을 넣는다. </li>
							<li>분만실이 없어서 계류식 우상에서 분만할 때도 똑같은 방법으로 청소, 소독하고 깔짚을 충분히 깔아준다. </li>
							<li>여름철에는 풀 상태가 양호하고 그늘이 있는 조그마한 면적의 초지가 있으면 분만장소로 아주 적합하게 이용할 수 있다. </li>
						</ul>
					</li>
					<li>송아지 포유기구는 매번 사용후에 깨끗이 세척 소독한다. 
						<ul>
							<li>특히 포유기구의 고무로 도니 부분의 세척에 유의한다. </li>
						</ul>
					</li>
					<li>분만실에서 갓난 송아지를 다룰때나 질병에 걸린 송아지를 치료할때는 반드시 손을씻고 소독하여 질병의 전파를 예방한다. </li>
					<li> 사료통에 있는 젖은 건초나 농후사료는 매일 치워주고 항시 깨끗한 것을 넣어준다. </li>
					<li>깔짚을 자주 갈아주어 언제나 깨끗한 상태를 유지한다. </li>
					<li>개체별 송아지 우리는 이동할 수 있도록 설치된 것은 이유 직후에 깨끗이 청소 소독하여 햇볕에 말린다. 고정식으로 되어 있어 이동할 수 없는 경우에는 분만실의 경우처럼 청소 소독한다. 여분이 있으면 최소한 2∼3주간 비워 뒀다가 사육하는 것이 바람직하다. </li>
					<li>송아지 사육상(calf hatch)도 같은 방법으로 처리하며 송아지를 새로 넣을 때는 새로운 장소로 옮겨서 사육한다. </li>
					<li>사료나 물은 분뇨로 오염되지 않도록 한다. 특히 내부 기생충의 방제는 절대적으로 필요한 조치다. </li>
				</ul>
				<h2>예방접종</h2>
				<ul>
					<li>브루셀라 (brucellosis) 
						<ul>
							<li>임신말기 (7∼9개월)에 유산을 일으키는 질병으로 유산후 일시적 또는 영구적 불임을 유발하는 번식관계 질병이다. 이 질병을 예방하기 위해서는 유산물질을 신속히 제거하고 축사를 철저히 소독한다. 외국에서는 백신을 접종하고 있으며, 한국에서는 예방접종을 하지않고 가축전염병 예방법에 따라 젖소는 매년 1회 정기검진을 실시하여 감염된 소는 도살처리하여 감염원을 제거하고 있다. </li>
						</ul>
					</li>
					<li>전염성 기관지염(IBR) 
						<ul>
							<li>고열(39∼42`C), 기침,콧물, 호흡곤란, 결막염, 눈물흘림, 생식기의 염증, 식욕부진, 비유량 감소 또는 정지 등의 증상을 나타내며 임신우에서는 유산을 일으키기도 한다. 이는 바이러스성 질병으로서 한국에서도 많이 발생하고 있으며 특히 어린 송아지에 피해가 크다. 갓난 송아지에는 30분 이내에 초유를 반드시 먹이고 6∼7개월령이 되었을 때 예방접종을 실시한다. 건강한 소에 2회 주사로 약 6개월이상 면역이 지속된다.</li>
						</ul>
					</li>
					<li>소 바이러스 설사 (BVD;bovine virus diarrhea) 
						<ul>
							<li>이 병은 바이러스성 질병으로 송아지에 감염시 폐사율이 높다. 설사, 탈수, 호흡기증상등을 일으키며 체열도 39.5∼42`C로 올라간다. 예방을 위해서는 8개월령에 도달했을 때 예방접종을 실시하며 감염된 송아지는 원인요법이 없으므로 2차 세균감염을 막기 위해 항생제투여, 전해질 보충등의 방법으로 치료한다. </li>
						</ul>
					</li>
					<li>파라인플루엔자 (PI₃; PARAINFLUENZA-3) 
						<ul>
							<li>이 병은 바이러스 질병으로 폐염, 설사, 기침, 호흡곤란 증상을 나타내며 송아지의 경우 2주령까지 많이 나타난다. 송아지가 6개월령 이상이 되면 예방접종을 실시하며 면역을 길러준다. 예방접종은 매년 반복해서 실시한다. 송아지는 초유를 통하여 면역체를 이행받게 된다. </li>
						</ul>
					</li>
				</ul>
				<h2>송아지 설사 (calf scour) </h2>
				<p className="pstyle">&nbsp;&nbsp;&nbsp;송아지 설사는 폐염과 함께 송아지에서 가장 많이 발생되며 폐사율이 높은 질병으로 바이러스나 박테리아에 의해 발생된다. 출생 직후의 초유 급여가 가장 중요하며 격리사육, 찬바람 방지, 배꼽소독 등도 반드시 필요하다. 또한 포유시 송아지에게 과식시키지 말고 젖먹이는 시간을 정확히 지키는 것도 중요하다. 대용유를 이용할 경우 저질의 대용유를 급여할 때 설사를 일으키기도 하므로 품질이 좋은 대용유를 이용해야 한다,. 특히 대용유의 탄수화물 공급원으로 전분 (starch)이나 설탕이 많이 들어있는 것은 이용하지 않는 것이 좋다. 송아지는 전분이나 설탕을 소화하지 못하는 반면 박테리아는 자라기에 좋은 조건이 되기 때문이다. 송아지 설사는 빨리 발견하는 것이 중요하므로 항시 주의깊게 관찰해야 하며 설사가 발견되면 지체하지 말고 즉시 치료한다. <br/>&nbsp;&nbsp;&nbsp;설사의 원인치료를 위해서 항생제나 설파제를 투여하며 설사로 인한 탈수(dehydration)와 산성화에 대한조치도 병행해야 한다,. 탈수에 의한 체액보충과 산성화의 회복을 위해서는 전해물질 (electrolyte)을 투여하는데 생리식염수나 포도당용액을 정맥주사하는 방법과전해질제제를 구입하거나 직접 만들어 물에 타서 먹이는 방법이 있다. 증상이 가벼운 경우 즉 발병초기에는 전해질제제를 먹이는 방법이 손쉽다. 증상이 심할 때는 수의사를 불러서 처리하는 것이 현명한 방법이다. 보충해야할 전해질의 양은 탈수강도를 판단하여(&lt;표 72&gt;) 결정한다. 예를 들어 체중이 50kg인 송아지가 8%의 탈수증상을 나타냈다면 50× 8%=4ℓ 즉 하루에 4ℓ의 전해물질을 보충해야 한다. </p><br/>
				<p><b>&lt;표 72&gt; 탈수강도 판단</b></p>
				<table className="tablestyle">
					<tr>
						<td>탈수강도</td>
						<td>증상</td>
					</tr>
					<tr>
						<td>4% 이하</td>
						<td>증상없음</td>
					</tr>
					<tr>
						<td>6% 이하</td>
						<td>피부탄력소실, 구강건조</td>
					</tr>
					<tr>
						<td>8% 이하</td>
						<td>안구함몰</td>
					</tr>
					<tr>
						<td>10% 이하</td>
						<td>구강저온, 체온하강</td>
					</tr>
					<tr>
						<td>12% 이하</td>
						<td>혼수상태, 허탈, 폐사</td>
					</tr>
				</table>
				<h2>폐렴 (pneumonia) </h2>
				<p className="pstyle">&nbsp;&nbsp;&nbsp;세균과 바이러스의 감염에 의해 발병된다. 환경과 기후의 급변, 찬바람, 과습, 환기불량, 탄산가스나 암모니아등 유독성가스, 무더위등이 원인이다. 이 질병은 특히 6개월령 이하의 어린 송아지에 많이 발생되며 폐사율이 높고 치료가 된 후에도 발육이 뒤질 뿐 아니라 호흡기질병에 잘 걸리게 된다. 증상은 기침, 콧물, 눈물흘림, 고열(40∼41.1`C), 원기쇠약, 맥박과 호흡수 증가, 식욕감퇴 등의 증상을 나타낸다. 호흡곤란이 심할때에는 입을 벌리고 개구식 호흡을 하며 코에서 나오는 분비물은 처음에는 묽고 투명하다가 심해지면서 고름같은 농성점액으로 변하게 된다. 폐염의 예방을 위해서는 환경의 급격한 변화를 방지하고 겨울철에 찬바람을 막아주고 환기가 잘되게 하여 항상 신선한공기를 마실 수 있도록 하며, 깨끗하고 건조한 상태를 유지해주는 것이 중요하다,. 2차감염으로 폐염을 유발시키는 전염성 기관지염 (IBR)이나, 파라인플루엔자(PI3)의 예방접종등도 철저히 해야한다. 치료방법은 환축을 격리하고, 복합항생체를 근육주사하며 보조요법으로 거담제, 강심제, 항히스타민제와 비타민 C를 주사하며 식욕이 감퇴되어 사료를 먹지 않을 경우 영양공급을 위해서 비타민B 복합제를 5∼10% 포도당에타서 정맥주사 한다. 항생제 치료효과가 없을 때는 혈청제를 주사하면 효과를 볼 수 있다. </p>
				<h2>기타 질병예방 </h2>
				<ul>
					<li>기생충 
						<ul>
							<li>회충, 폐충, 간질충등의 내부기생충을 없애기 위하여 생후 6개월령이 되면 구충제를 투여하고 매년 정기적으로 투약한다. 파리, 모기, 진드기, 이 등의 외부기생충이 많이 발생하는 시기에는 살충제를 자주 뿌려준다. </li>
						</ul>
					</li>
					<li>소버짐(ring worm) 
						<ul>
							<li>소버짐 일명 링웜이라는 피부병은 곰팡이에 의해 발생하며 특히 눈, 얼굴, 목주위에 많이 발생한다. 소버짐의 치료는 강옥도나 시판 치료 약품을 발라주고, 병행해서 비타민A를 근육주사 한다, 이 병의 예방을 위해서는 청결하게 관리하고 일광욕을 자주시키며 우사안의 환기가 잘되게 하여 습하지 않도록 해주고 이 피부병에 걸린 소는 격리하여 치료하므로서 다른 소에 전염되지 않도록 한다. </li>
						</ul>
					</li>
					<li>사마귀(warts) 
						<ul>
							<li>피부에 혹같이 생긴 조직이 형성되어 점차 커지는 피부병으로 머리, 목, 어깨 주위에 많이 발생한다. 이 병은 바이러스에 의해서 발병하는 전염성 피부병이다. 사마귀에 감염된 소는 격리 수용하여 다른 소와의 접촉을 피하도록 한다. 사마귀의 숫자가 적을 때는 잘드는 가위나 칼로 잘라내고 옥도정기나 항생제연고를 발라준다. </li>
						</ul>
					</li>
					<li>옴(개선충 및 모낭충증) 
						<ul>
							<li>이병에 걸리면 어깨, 목, 꼬리부분이 털이 빠지며 심한 자극성 염증, 가려움증, 피부에 2차적 세균감염으로 농포형성, 피부에 딱지가 형성되며 치료하지않고 방치하면 쇠약, 수척해진다. 감염된 소는 격리 수용하여 치료하는데 환부에 유황연고를 바르거나 2% 네구본 용액을 5∼7일 간격으로 환부에 살포한다. </li>
						</ul>
					</li>
					<li>부제병 (foot rot) 
						<ul>
							<li>발이 빠지는 습한 운동장이나 방목지에서 장시간 있게 되므로서 약해진 발부분을 통하여 박테리아나 다른 세균이 침투하여 발생되는 발굽병이다. 다리를 절거나 부으며, 발굽부 위에 열이 나는 증상을 보인다. 예방을 위해서는 우사바닥, 운동장 등을 항상 건조하게 유지하여야 하며 새로 조성한 방목지 등에 나무 그루터기를 제거하여 발굽에 상처가 나는것을 예방하도록 한다. 부제병이 발견 되었을 때는 감염되어 썩은 부위를 깨끗이 도려내고 소독한 후 항생제 연고등을 바른 다음 솜과 붕대로 싸매고 물이 들어가지 않도록 목타르를 바르거나, 비닐 테이프를 감아서 소를 건조한 곳으로 옮긴다, 이와같은 방법으로 3∼ 4일 간격으로 계속 치료하면 낫게 된다.</li>
						</ul>
					</li>
				</ul>
			</div>
  );
}

export default Do64;
