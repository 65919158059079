import React, { useRef, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

// 컴포넌트
import SubTitle from 'components/subTitle/SubTitle';
import Department from 'containers/web/performance/common/Department';
import BuyContact from './BuyContact';

// scss
import './buySemen.scss';

function BuySemen() {
  const navigate = useNavigate();

  // 스크롤 스르륵
  const orderSemenRef = useRef(null);
  const mapRef = useRef(null);

  const scrollToRef = (ref) => {
    const currenttop = ref.current.offsetTop - 100;
    window.scrollTo({
      top: currenttop,
      behavior: 'smooth',
    });
  };

  const scrollBehaivor = (tab) => {
    switch (tab) {
      case 'orderSemen':
        scrollToRef(orderSemenRef);
        break;
      case 'map':
        scrollToRef(mapRef);
        break;
      default:
        break;
    }
  };

  // 비밀번호 입력 모달
  const [orderInfoPasswordArea, setOrderInfoPasswordArea] = useState(false);
  const [orderInfoId, setOrderInfoId] = useState(null);
  const [orderInfoPwd, setOrderInfoPwd] = useState('');

  const orderInfoRef = useRef(null);

  const checkPassword = () => {
    const inputPassword = orderInfoRef.current.value;

    if (inputPassword === orderInfoPwd) {
      navigate('orderInfo');
      setOrderInfoPasswordArea(false);
    } else {
      alert('비밀번호가 일치하지 않습니다.');
    }
  };

  return (
    <div className="buySemen subCon pullContent">
      <SubTitle />
      <ul className="buySemenTab">
        <li>
          <button type="button" onClick={() => scrollBehaivor('orderSemen')}>
            온라인 정액주문
          </button>
        </li>
        <li>
          <button type="button" onClick={() => scrollBehaivor('map')}>
            구입 연락처
          </button>
        </li>
      </ul>
      <div className="buySemenInner">
        <h5 ref={orderSemenRef}>온라인 정액주문</h5>
        <Outlet
          context={{
            setOrderInfoPasswordArea,
            setOrderInfoPwd,
            setOrderInfoId,
            orderInfoId,
            orderInfoPwd,
          }}
        />
      </div>
      <BuyContact mapRef={mapRef} />
      <Department
        phone="031-929-1032,1015"
        department="젖소개량사업소 사양제조팀"
      />
      {orderInfoPasswordArea && (
        <div className="inputPassword">
          <div className="inputPasswordInner">
            <p>주문시 입력한 비밀번호를 입력해 주세요.</p>
            <div className="inputArea">
              <input ref={orderInfoRef} type="password" maxLength={4} />
              <button
                type="button"
                onClick={() => {
                  setOrderInfoPwd(orderInfoRef.current.value);
                  checkPassword();
                }}
              >
                비밀번호 확인
              </button>
            </div>
            <button
              type="button"
              className="close"
              onClick={() => setOrderInfoPasswordArea(false)}
            >
              <span className="hidden">닫기</span>
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default BuySemen;
