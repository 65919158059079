import React from 'react';
import './ErrorBoundary.scss';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // 다음 렌더링에서 폴백 UI가 보이도록 상태를 업데이트
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // 에러 로깅 등의 작업을 수행할 수 있습니다
    console.error('Error:', error);
    console.error('Error Info:', errorInfo);
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      // 폴백 UI를 커스텀하게 렌더링
      return (
        <div className="error-boundary">
          <h1>문제가 발생했습니다.</h1>
          <p>잠시 후 다시 시도해주세요.</p>
          <button
            type="button"
            onClick={() => {
              window.location.href = '/web/main';
            }}
          >
            메인으로 이동
          </button>
        </div>
      );
    }

    return children;
  }
}

export default ErrorBoundary;
