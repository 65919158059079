import React from 'react';
import { useNavigate } from 'react-router-dom';

// constants
import { BOARD_CODE, BOARD_NAME } from 'constants/backOfficeBoard';

// components
import CommonInfoList from '../_components/CommonInfoList';

function InfoTechInfoList() {
  const navigate = useNavigate();

  const handleRegister = () => {
    navigate('/bo/info/infoTechInfo/edit');
  };

  const handleViewDetail = (item) => {
    navigate(
      `/bo/info/infoTechInfo/view?bdc_code=${item.BDC_CODE}&brd_code=${BOARD_CODE.TECH_INFO}`,
    );
  };

  return (
    <CommonInfoList
      title={BOARD_NAME.TECH_INFO}
      brdCode={BOARD_CODE.TECH_INFO}
      queryId="infoTechInfo"
      apiEndpoint="/board/select_info_list"
      onRegister={handleRegister}
      onViewDetail={handleViewDetail}
    />
  );
}

export default InfoTechInfoList;
