import React from 'react';

import Pic587 from 'assets/images/sub/_images/pic587.jpg';
import Pic588 from 'assets/images/sub/_images/pic588.jpg';
import Pic589 from 'assets/images/sub/_images/pic589.jpg';
import Pic590 from 'assets/images/sub/_images/pic590.jpg';
import Pic591 from 'assets/images/sub/_images/pic591.jpg';
import Pic592 from 'assets/images/sub/_images/pic592.jpg';
import Pic593 from 'assets/images/sub/_images/pic593.jpg';

function Do112() {

  return (
    <div className="techinfoview">
      <h1>후산정체</h1>
      <p className="pstyle">분만후 태아의 태반이 모체 태반으로부터 박리되지 않은 상태로서, 8시간이 지나도 후산이 배출되지 않는 경우를 후산정체 또는 태반정체라고 한다. 후산은 보통 3∼8시간 이내에 배출된다. </p>
      <div className="leftpart">
        <h2>원인</h2>
        <ul>
          <li>소의 태반의 복잡한 구조가 후산정체의 한 원인이 되고 있다. </li>
          <li>후 진통 미약과 태아 견인시 무리한 힘을 가했을 때 </li>
          <li>임신중의 자궁감염증(부르셀라, 비브리오, 결핵균, 사상균 등)에 의한 경우 </li>
          <li>비타민 A 부족에 의한 태반과 상피의 각화(角化)로 인해 </li>
          <li>자궁무력증을 가져오는 질환(자궁염전, 다태아, 난산 등)</li>
        </ul>
        <h2>증상</h2>
        <ul>
          <li>후산의 대부분의 상기그림과 같이 외음부 밖에 매달려 있는 것과 이와 반대로 후산의 대부분이 자궁내에 있고 일부만 나온 것등 정체의 정도는 다양하다. </li>
          <li>정체된지 2일 정도 지나면 후산이 부태되어 고약한 악취를 낸다(특히 여름철) </li>
          <li>세균의 2차 감염으로 인한 합병증을 일으키지 않는한 대개 전신증상이 없으나 경우에 따라서는 체온상승, 식욕감퇴, 유량감소등을 나타내기도 한다. </li>
        </ul>
        <h2>목장에서의 처치</h2>
        <ul>
          <li>매달린 후산을 무리하게 당겨내면 도중에 끊어지거나 자궁탈의 원인이 될 수 있다. </li>
          <li>매달린 후산을 외음부 가까이서 자르면 후산이 자궁내로 들어가 경관이 일찍 닫히므로 나중에 수의사가 왕진한 때에는 자궁내에 약물투여나 후산의 제거가 어렵게 된다. </li>
          <li>후산이 바닥에 닿을 정도로 길게 늘어져 있으면 바닥에 20cm 정도를 남기고 비절(飛節)부근에서 잘라주는 것이 좋다. </li>
          <li>열이 너거나 식욕이 줄면 즉시 치료가 필요하다. 방치하면 산욕열이나 자국내막염등의 합병증을 일으킬 염려가 있다. </li>
          <li>전신 증상이 없는 경우에도 5∼7일째 후산을 제거하거나 치료를 받는 것이 수태를 앞당기는 길이다. </li>
          <li>후산정체로 치료를 한 경우에는 분만후 1개월 전후해서 자궁의 회복상태를 검사하여 필요한 경우 그 시점에서 치료를 받도록 한다.</li>
        </ul>
      </div>
      <div className="rightpart">
        <img src={Pic587} className="nonborder" alt=""/>
        <img src={Pic588} className="nonborder" alt=""/>
        <img src={Pic589} className="nonborder" alt=""/>
        <img src={Pic590} className="nonborder" alt=""/>
        <img src={Pic591} className="nonborder" alt=""/>
        <img src={Pic592} className="nonborder" alt=""/>
        <img src={Pic593} className="nonborder" alt=""/>
      </div>
    </div>
  );
}

export default Do112;