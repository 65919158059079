import React from 'react';

import pic602 from 'assets/images/sub/_images/pic602.jpg';
import pic603 from 'assets/images/sub/_images/pic603.jpg';
import pic604 from 'assets/images/sub/_images/pic604.jpg';

function Do115() {

  return (
    <div className="techinfoview">
      <h1>난소낭종</h1>
      <p className="pstyle">난소낭종은 직경 2.5cm이상의 난포(정상적인 성숙난포는 대개 2cm이하)가 배란되지 않고 10일 이상 계속 난소에 남아 있는 상태를 말한다. 젖소의 번식장애 중 가장 흔하다. </p>
      <div className="leftpart">
        <h2>원인</h2>
        <ul>
          <li>정확히 밝혀져 있진 않지만 황체 형성 호르몬 L.H의 부족 </li>
          <li>환경적 스트레스에 의한 경우(비유와 사료의 과부족, 운동량과 일조량의 부족) </li>
          <li>낭종에 걸리리 쉬운 체질의 유전 등이 지적되고 있다. </li>
          <li>호르몬제를 잘못사용했을 경우 </li>
          <li>에스트로겐 물질을 다량함유한 사료와 과급(알팔파 건초, 레드클로바 등) </li>
        </ul>
        <h2>증상</h2>
        <ul>
          <li>대부분 무발정이 장기간 계속된다(일부는 강렬한 발정이 4∼5일부터 1주간에 걸쳐 연속발정을 보이기도 한다) </li>
          <li>발정이 불규칙하게 반복되는 일이 있다. </li>
          <li>위의 사진과 같이 미근부가 올라가며 외음부가 부어오른 느낌이 든다.</li>
          <li>난소낭종의 점액은 정상 발정우의 점액보다 불투명하고 점조도가 높다.</li>
          <li>직장검사 소견으로 한쪽 또는 양측의 난소에 1개 또는 여러개의 큰 난포가 만져지며 10여일이 지나도 배란되지 않고 남아있다. </li>
        </ul>
        <h2>목장에서의 처치</h2>
        <ul>
          <li>난소낭종은 조기에 발견하여 치료할수록 회복율이 높다. </li>
          <li>과비한 낭종우는 박류(粕類)와 농후사료의 급열르 줄인다. </li>
          <li>비유량이 많은 낭종우는 저단백질, 고열량의 농후사료(보리 등)로 바꿔준다. </li>
          <li>여름철 방목하기 전에 열량이 부족치 않도록 건초를 충분히 급여한다.</li>
        </ul>
      </div>
      <div className="rightpart">
        <img src={pic602} className="nonborder" alt=""/>
        <img src={pic603} className="nonborder" alt=""/>
        <img src={pic604} className="nonborder" alt=""/>
      </div>
    </div>
  );
}

export default Do115;