import React from 'react';


function Do55() {

  return (
    <div className="techinfoview">
				<h1>젖먹이 송아지 사양관리(4일령~이유시까지)-인공포유</h1>
				<p>젖소 송아지는 한우나 육우 송아지와 달리 자연 포유를 하지 않고 우유나 대용유를 가지고 인공포유를 하며 이와 별도로 어린 송아지 보조사료와 건초 등도 먹이게 된다. </p>
				<h2>인공포유</h2>
				<ul>
					<li>전유 또는 대용유 급여 
						<p>분만후 3∼4일간 초유로 급여한 후부터는 전유, 양질의 대용유, 냉동 또는 발효시킨 초유를 급여하거나 이를 혼합하여 급여한다. 건유를 10주까지 급여하는 계획은 &lt;표 47&gt;에 나타난 바와 같다. </p>
					</li>
					<li>급여량 
						<p>포유 기간 중의 송아지에게 급여하는 액상 사료의 양은 송아지 체중의 8∼10%로 한다. 이보다 적으면 발육이 늦어지고 너무 많으면 설사와 소화장애를 일으킨다. 체중에 따른 포유량은 &lt;표 48&gt;과 같다. </p><br/>
						<p><b>&lt;표 47&gt; 전유급여계획</b></p>
					<table className="tablestyle">
						<tr>
							<td rowSpan="2">나이</td>
							<td colSpan="3">생시체중 36kg이상</td>
							<td colSpan="3">생시체중 36kg이하</td>
						</tr>
						<tr style={{background: '#273143', color: '#fff'}}>
							<td>(Ⅰ)</td>
							<td>(Ⅱ)</td>
							<td>(Ⅲ)</td>
							<td>(Ⅰ)</td>
							<td>(Ⅱ)</td>
							<td>(Ⅲ)</td>
						</tr>
						<tr>
							<td></td>
							<td colSpan="6">…………………………………………… kg …………………………………………………</td>
						</tr>
						<tr>
							<td>1∼3일</td>
							<td>3.6</td>
							<td>3.6</td>
							<td>3.2</td>
							<td>2.3</td>
							<td>2.3</td>
							<td>2.3</td>
						</tr>
						<tr>
							<td>4∼7일</td>
							<td>3.6</td>
							<td>3.6</td>
							<td>3.2</td>
							<td>2.3</td>
							<td>2.3</td>
							<td>2.3</td>
						</tr>
						<tr>
							<td>2주</td>
							<td>4.0</td>
							<td>4.0</td>
							<td>3.2</td>
							<td>2.7</td>
							<td>2.7</td>
							<td>2.3</td>
						</tr>
						<tr>
							<td>3주</td>
							<td>4.5</td>
							<td>4.5</td>
							<td>3.2</td>
							<td>3.2</td>
							<td>3.2</td>
							<td>2.3</td>
						</tr>
						<tr>
							<td>4주</td>
							<td>4.0</td>
							<td>4.0</td>
							<td>3.2</td>
							<td>3.2</td>
							<td>3.2</td>
							<td>2.3</td>
						</tr>
						<tr>
							<td>5주</td>
							<td>3.2</td>
							<td>2.3</td>
							<td>-</td>
							<td>2.7</td>
							<td>2.3</td>
							<td>2.3</td>
						</tr>
						<tr>
							<td>6주</td>
							<td>2.7</td>
							<td>-</td>
							<td>-</td>
							<td>2.7</td>
							<td>2.3</td>
							<td>1.8</td>
						</tr>
						<tr>
							<td>7주</td>
							<td>1.8</td>
							<td>-</td>
							<td>-</td>
							<td>2.3</td>
							<td>1.4</td>
							<td>-1.8</td>
						</tr>
						<tr>
							<td>8주</td>
							<td>-</td>
							<td>-</td>
							<td>-</td>
							<td>1.8</td>
							<td>-</td>
							<td>-</td>
						</tr>
						<tr>
							<td>9주</td>
							<td>-</td>
							<td>-</td>
							<td>-</td>
							<td>1.4</td>
							<td>-</td>
							<td>-</td>
						</tr>
						<tr>
							<td>10주</td>
							<td>-</td>
							<td>-</td>
							<td>-</td>
							<td>1.4</td>
							<td>-</td>
							<td>-</td>
						</tr>
						<tr>
							<td>총 급여량</td>
							<td>312</td>
							<td>224</td>
							<td>158</td>
							<td>312</td>
							<td>225</td>
							<td>163</td>
						</tr>
						<tr>
							<td>일당증체(kg)</td>
							<td>0.54</td>
							<td>0.50</td>
							<td>0.47</td>
							<td>0.34</td>
							<td>0.32</td>
							<td>0.29</td>
						</tr>
					</table>
					<p>* 1~3일령은 초유급여<br/>주 : (Ⅰ) 8주령에 이유, (Ⅱ) 6주령에 이유, (Ⅲ) 5주령에 이용 </p><br/>
					</li>
					
					<li>포유 횟수
						<p className="pstyle">하루 급여량을 2회로 나누어 규칙적으로 급여하는 것이 좋다. 3회, 4회 포유 시켜도 송아지 발육에 있어서 2회 포유시와 차이가 없으며 다만 노동 소요량만 증가한다. 미국 네브라스카 주립대에서는 젖먹이는데 드는 노동시간을 줄이기 위하여 1일 1회 포유시험을 시도하여 2회 급여 시와 별 차이가 없는 성적을 거두었다(&lt;표 49&gt;). 1일 2회 포유시에 주의할 사항은 2회이상 포유할 때와 같거나 약간 적은 양의 우유나 대용유를 규칙적으로 급여해야 하며 대용유를 쓸때는 특히 질이 좋은 것으로 선택해야 한다는 것이다. 여기에 1일 1회 포유시의 액상 사료급여 프로그램을 보면 &lt;표 50&gt;과 같다. </p><br/>
						<p><b>&lt;표 48&gt; 체중에 따른 포유량</b></p>
					<table className="tablestyle">
						<tr>
							<td><p style={{float: 'right', marginRight: '10px'}}>체중</p><br/><p style={{float: 'left', marginLeft: '10px'}}>일령</p></td>
							<td>30kg</td>
							<td>40kg</td>
							<td>50kg</td>
						</tr>
						<tr>
							<td></td>
							<td colSpan="3">…………………………… kg …………………………………</td>
						</tr>
						<tr>
							<td>1∼5</td>
							<td>2.4∼3.0</td>
							<td>3.2∼4.0 </td>
							<td>4.0∼5.0</td>
						</tr>
						<tr>
							<td>6∼이유시</td>
							<td>2.4∼3.0</td>
							<td>3.2∼4.0 </td>
							<td>4.0∼5.0</td>
						</tr>
					</table>
					<p>* 5∼8주 이유시 5일령에 보조사료 급여개시 <br/>* 젖떼기 전에 보조사료를 체중의 1% 이상 섭취해야함 </p><br/>
					<p><b>&lt;표 49&gt; 동일량의 전유를 1일 1회 또는 2회에 급여하였을 때의 송아지 발육성적</b></p>
					<table className="tablestyle">
						<tr>
							<td colSpan="2">항목</td>
							<td>1일 1회 급여</td>
							<td>1일 2회 급여</td>
						</tr>
						<tr>
							<td rowSpan="4">일당증체량(kg)</td>
							<td>생후 6주령 평균</td>
							<td>0.41</td>
							<td>0.41</td>
						</tr>
						<tr>
							<td>생후 12주령 평균</td>
							<td>0.57 </td>
							<td>0.50</td>
						</tr>
						<tr>
							<td>연중 발생일 비율 (%)</td>
							<td>2.6</td>
							<td>2.9</td>
						</tr>
						<tr>
							<td>폐 사 율 (%)</td>
							<td>1.5</td>
							<td>1.5</td>
						</tr>
					</table><br/>
					<p><b>&lt;표 50&gt; 1일 1회 포유시의 액상사료 급여량</b></p>
					<table className="tablestyle">
						<tr>
							<td rowSpan="2">분만 후 기간</td>
							<td colSpan="2">예1</td>
							<td>예2</td>
						</tr>
						<tr style={{background: '#273143',color:'#fff'}}>
							<td style={{width:'25%'}}>오전</td>
							<td style={{width:'25%'}}>오후</td>
							<td style={{width:'25%'}}>1일 1회</td>
						</tr>
						<tr>
							<td></td>
							<td colSpan="3">……………………………………… kg …………………………………………</td>
						</tr>
						<tr>
							<td>1∼3일</td>
							<td>-</td>
							<td>-</td>
							<td>-</td>
						</tr>
						<tr>
							<td>4일</td>
							<td>1.6</td>
							<td>1.6</td>
							<td>3.2</td>
						</tr>
						<tr>
							<td>5일</td>
							<td>1.8</td>
							<td>1.4</td>
							<td>3.2</td>
						</tr>
						<tr>
							<td>6일</td>
							<td>2.3</td>
							<td>0.9</td>
							<td>3.2</td>
						</tr>
						<tr>
							<td>7일</td>
							<td>2.7</td>
							<td>0.5</td>
							<td>3.2</td>
						</tr>
						<tr>
							<td>2일</td>
							<td>3.2</td>
							<td>-</td>
							<td>3.2</td>
						</tr>
						<tr>
							<td>3일</td>
							<td>3.2</td>
							<td>-</td>
							<td>3.2</td>
						</tr>
						<tr>
							<td>4일</td>
							<td>3.2</td>
							<td>-</td>
							<td>3.2</td>
						</tr>
						<tr>
							<td>5일</td>
							<td>3.2</td>
							<td>-</td>
							<td>3.2</td>
						</tr>
					</table>
					<p>* 신선한 초유급여</p><br/>
          </li>
					<li>급여온도 
						<p className="pstyle">송아지에게 먹이는 우유나 대용유의 온도는 일정하게 유지하는 것이 중요하며 이상적인 방법은 온도를 38∼40℃ 정도이다. 그러나 노동력의 절감과 번거로움을 피하기 위해서 데우지 않고 냉장상태 (2∼5℃)의 초유나 우유를 급여한 시험 연구에 의하면 송아지의 발육에 지장을 주지 않았다고 한다 (&lt;표 51&gt; 참조). 물론 겨울철에는 찬 우유를 먹일 경우 체온 유지를 위한 열량 소모가 많아지는 단점이 있기는 하다. 결론적으로 말하자면 우유의 온도를 항시 일정하게 만들어서 먹인다면 데우든 안 데우든 크게 문제가 되지 않는다. </p><br/>
						<p><b>&lt;표 51&gt; 표유기간 중에 초유와 전유를 차게 또는 데워서 급여했을 때의 송아지 발육성적 </b></p>
					<table className="tablestyle">
						<tr>
							<td rowSpan="2">항목</td>
							<td colSpan="2">전유</td>
							<td colSpan="2">초유</td>
						</tr>
						<tr style={{background: '#273143',color:'#fff'}}>
							<td>덥힌것<br/>(38∼40℃)</td>
							<td>찬것(2~5℃)</td>
							<td>덥힌것<br/>(38∼40℃)</td>
							<td>찬것(2~5℃)</td>
						</tr>
						<tr>
							<td>총 증체량(kg)</td>
							<td colSpan="4"></td>
						</tr>
						<tr>
							<td>생후 24일까지</td>
							<td>5.0 </td>
							<td>4.5</td>
							<td>6.8</td>
							<td>7.7</td>
						</tr>
						<tr>
							<td>생후 61일까지</td>
							<td>32.7</td>
							<td>30.4</td>
							<td>35.0</td>
							<td>36.3</td>
						</tr>
						<tr>
							<td>이유사료 섭취량(kg)</td>
							<td colSpan="4"></td>
						</tr>
						<tr>
							<td>생후 24일까지</td>
							<td>5.4</td>
							<td>5.0</td>
							<td>5.9</td>
							<td>6.8</td>
						</tr>
						<tr>
							<td>생후 61일까지</td>
							<td>73.5</td>
							<td>71.7</td>
							<td>74.0</td>
							<td>81.7</td>
						</tr>
						<tr>
							<td>생후 25일간의 설사일수</td>
							<td>1.4</td>
							<td>1.4</td>
							<td>1.4</td>
							<td>1.3</td>
						</tr>
						<tr>
							<td>송 아 지 폐 사 율</td>
							<td>0/12</td>
							<td>1/12</td>
							<td>0/12</td>
							<td>0/12</td>
						</tr>
					</table><br/>
					</li>
					<li>포유기간
						<p>일반적으로 6∼8주령에 젖을 떼고 있으나 정상적으로 성장하는 건강한 송아지는 보조사료급여등을 통해서 4∼5주령에 젖을 뗄 수도 있다. 다음과 같은 경우에는 젖먹이는 기간을 늘려 준다. </p>
						<ul>
							<li>송아지가 건강하지 못할 때</li>
							<li>송아지의 생시 체중이 낮아서 발육이 불량할 때 </li>
							<li>송아지의 보조사료 섭취량이 체중의 1%에 미달할 때</li>
						</ul><br/>
					</li>
					<li>유방염 치료 중의 젖소가 생산한 우유급여 
						<p>농가에서 생산한 우유 중에는 유방염 치료가 진행중인 소가 생산한 우유나 기타 항생제가 함유된 우유등 납유할 수 없는 것이 있는데 이중의 일부는 송아지에게 먹일 수 있다. 즉 경미한 유방염 우유, 항생제 처리한 소의 우유, 가벼운혈유(bloody milk)는 송아지에게 급여하여도 성장에 지장이 없으며 먹이는 양만 큼 우유를 절약할 수 있으므로 경제적이다. 그러나 <b> 외관상 이상이 있는 우유, 체온이 높은 환축에서 생산된 우유, 항생제 치료 후 첫 번째 착유한 우유 등</b>은 송아지에게 먹이지 않도록 한다. <br/><br/>이상과 같은 유방염 치료 우유나 항생제 처리 우유를 급여할 때는 </p>
						<ul>
							<li>송아지를 개체별로 격리된 장소에 수용하거나 </li>
							<li>젖먹인 후 최소한 30분이상 격리 수용할 수 있도록 해야 한다.</li>
						</ul>
						<p> 이는 유방염 치료 우유를 섭취한 송아지가 다른 젖소의 젖을 빨게 되면 유두 발육 부진(rudimentary teat)이나 후보우 유방염을 일으키는 원인이 되기 때문이다. </p><br/>
					</li>
					<li>남은 초유의 이용 
						<p className="pstyle">분만후 4∼5일 동안에 생산되는 초유중 남는 것이 있으면 전유 또는 대용유대신에 송아지에게 먹일 수 있다. 이런 초유를 먹일 때는 초유 3 : 물 1의 비율로 혼합, 전체 고형분 함량을 전유와 비슷하게 희석해서 먹인다. 즉 전유의 고형분함량은 12.5∼13%인데 반하여 초유에는 15∼16%이므로 3 : 1의 비율로 희석해서 급여해야만 이미 전유에 익숙해져 있는 송아지의 소화 장애를 방지할 수 있다.</p>
					</li>
				</ul>
			</div>
  );
}

export default Do55;
