import React from 'react';
import BestCowTest from 'containers/web/business/bestCowTest/BestCowTest';
import { Helmet } from 'react-helmet-async';

function BestCowTestPage() {
  return (
    <>
      <Helmet>
        <title>유우군 능력검정</title>
      </Helmet>
      <BestCowTest />
    </>
  );
}

export default BestCowTestPage;
