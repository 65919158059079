import React from 'react';
import HowtoReport from 'containers/web/data/howtoReport/HowtoReport';
import { Helmet } from 'react-helmet-async';

function HowtoReportPage() {
  return (
    <>
      <Helmet>
        <title>젖소검정성적표보는법</title>
      </Helmet>
      <HowtoReport />
    </>
  );
}

export default HowtoReportPage;
