import React from 'react';

// scss
import './webpage_list.scss';

// 이미지
import imgI1 from 'assets/images/containers/data/relatedSites/international/imgSite07_1.jpg';
import imgI3 from 'assets/images/containers/data/relatedSites/international/imgSite07_3.jpg';
// import imgI5 from 'assets/images/containers/data/relatedSites/international/imgSite07_5.jpg';
import imgI6 from 'assets/images/containers/data/relatedSites/international/imgSite07_6.jpg';
// import imgNO from 'assets/images/containers/data/relatedSites/imgSite_no.gif';

function International() {
  return (
    <div className="International">
      <div className="webpage">
        <dl className="webpage_list">
          <dd>
            <a href="https://genaust.com.au/" target="_blank" rel="noreferrer">
              <div className="webpage_banner">
                <img src={imgI1} alt="Australian Genetics" />
              </div>
              <p>Australian Genetics</p>
            </a>
          </dd>
          {/* <dd>
                    <div className="webpage_banner">
                        <img src={imgNO} alt="Cormdale Genetics"/>
                    </div>
                    <p>Cormdale Genetics</p>                    
                </dd> */}
          <dd>
            <a
              href="https://www.bles-dairies.nl/"
              target="_blank"
              rel="noreferrer"
            >
              <div className="webpage_banner">
                <img src={imgI3} alt="Bles Dairies Genetics" />
              </div>
              <p>Bles Dairies Genetics</p>
            </a>
          </dd>
          {/* <dd>
                    <div className="webpage_banner">
                        <img src={imgNO} alt="Danish Holstein Assoc"/>
                    </div>
                    <p>Danish Holstein Assoc</p>
                </dd>
                <dd>
                    <div className="webpage_banner">
                        <img src={imgI5} alt="Broekhuis Vee BV"/>
                    </div>
                    <p>Broekhuis Vee BV</p> 
                </dd> */}
          <dd>
            <a href="https://crv4all.com/en" target="_blank" rel="noreferrer">
              <div className="webpage_banner">
                <img src={imgI6} alt="Holland Genetics" />
              </div>
              <p>Holland Genetics</p>
            </a>
          </dd>
        </dl>
      </div>
    </div>
  );
}

export default International;
