import React from 'react';

import Pic800 from 'assets/images/sub/_images/pic800.jpg';
import Pic801 from 'assets/images/sub/_images/pic801.jpg';
import Pic802 from 'assets/images/sub/_images/pic802.jpg';
import Pic803 from 'assets/images/sub/_images/pic803.jpg';
import Pic804 from 'assets/images/sub/_images/pic804.jpg';

function Fo2() {

  return (
    <div className="techinfo_view">
				<p className="viewTitle">젖소개량의 체제와 관련사업의 개요</p>
				<h2>젖소개량의 기본 - 유전적 능력</h2>
				<dl>
					<dt>유전자</dt>
					<dd>
						<div className="imgBox"><img src={Pic800} alt=''/></div>
						<p>젖소개량이란, 유량등의 경제적 가치를 형질(경제형질)의 유전적 능력을 세대(世代)를 거치면서 높여나가는 것을 말한다. 유전적 능력이란, 젖소가 분만되면서 가지고 있는 능력을 말하는데 어미소로부터 생기는 난자(卵子)와 아비소에서 나오는 정자(精子)가 가지고 나온 유전자에 의하여 결정된다.</p>
						<p>유전자는 하나하나 모두 짝을 이루고 있으며, 난자와 정자는 서로 짝을 이루는 가운데 어느편으로부터 이든간에 반쪽만을 어버이로부터 가지고 나온다. 그렇게 해서, 수정에 의하여 다시 짝을 이루면서 송아지(自牛)가 된다.</p>
						<p>예를 들면, A라는 유전자가 있고, 유전자 A에는 A1과 A2의 두 종류가 있다고 하자. 이경우에 어미소가 가지고 유전자가 A1-A2, 아비소도 A1-A2의 짝이라고 하면, 송아지의 유전자로서는 다음과 같이 세가지 방법의 경우를 생각할 수 있다. 이와 같이 하나의 유전자에 기착해서도 전형제(全兄第: 친형제) 소들이 반드시 같은 모양을 갖는다고 말할 수 없다.</p>
					</dd>
				</dl>
				<dl className="mg60f">
					<dt>육종가(BV)</dt> 
					<dd>
						<p>유량들에는 매우 많은 유전자가 관여하고 있다. 그 때문에 유량만을 보아도 전적으로 같은 유전자를 가진 형제소가 생산되는 것은 클론(CLONE)이 아닌 이상 있을 수 없다.</p>
						<p>그러나, 하나하나의 유전자는 +쪽으로 활동하거나 -쪽으로 활동하거나 한다. 그 때문에 송아지의 유전능력은 양친의 유전능력의 중간이 되는 확률이 가장 높다. 다시 말하면, 많은 전형제소들이 있으면 나올때부터 좋은 개체도 있고 불량한 개체도 있으나, 양친의 중간정도의 것이 가장 많아 평균으로 하면 양친의 유전적 능력의 중간이 된다.</p>
						<p>잡종강세(雜種强勢)와 같이, 자축(仔畜)의 평균적인 유전능력이 양친의 유전능력의 중간보다 크게 되는 형질도 있으나, 젖소의 경제형질 대부분은 자축의 유전적 능력의 중간이 되는 것이다. 이와 같이, 양친으로부터 받아서 계대한 유전자의 효과가 상가적(相加的)으로 나타나는 형질의 유전적 능력을 상가적 유전효과 또는 육종가(育種家, Breeding Value의 약자로 BV)라 부른다. </p>
					</dd>
				</dl>
				
				<h2>개량방법</h2> 
				<p>자축의 유전적 능력은 양친의 유전적 능력의 중간을 중심으로 분포한다. 그 때문에 암소도 수소도 선발하지 않고 아무것도 하지 않은 상태에서 대를 이어나가면, 그 집단의 유전적 능력은 변화하지 않는다.</p>
				<p>그러나, 급격히 집단의 두수를 증가시키는 경우가 아닌 한, 모든 암소로부터 후계소를 다 생산할 필요는 없다. 그 때문에 후계소 생산에 쓰이는 암소는 전 암소중에서 선발하는 것이 가능하다. 젖소개량을 위해서는 그의 선발을 어떻게 유전적 능력이 높은 개체로부터 확실히 잘 하는가가 첫 번째의 포인트가 되는 것이다.</p>
				<p className="mg30f">한편, 종모우는 수많은 자손을 남길 수 있다. 동결정액의 보급에 의하여 10만두를 넘는 딸소를 남긴 종모우도 있는 것 같다. 그 때문에 보다 우수한 종모우를 이용하는 것이 개량속도에 크게 영향을 미친다. 즉 선발된 암소에 얼마나 우수한 종모우를 교배할 수 있는가가 두 번째의 포인트가 된다.</p>
				<dl>
					<dt>우군검정, 후대검정, 혈통등록</dt>
					<dd>
						<p>암소를 선발하기 위해서는 암소의 능력, 특히 비유능력을 파악하는 일이 개량의 제일보(첫거름)이다. 이를 위해서는, 1두당 유량과 유성분을 측정하여 일정기간의 생산량을 파악할 필요가 있게 된다. 그러나, 유량은 어떻든 간에 유성분율의 측정은 개인으로는 어렵다. 이 때문에 세계각국에서는 소위 &quot;우군검정&quot;이 실시되고 있다.</p>
						<p>한편, 보다 우수한 종모우(種牡牛)를 교배하기 위해서는 종모우의 비유능력을 파악하여 선발할 필요가 있다. 그러나, 수소는 젖을 내지 않는다. 그 때문에 딸소의 비유성적으로부터 아비소인 종모우의 유전적 능력을 평가하는 &quot;후대검정&quot;이 행해지고 있다.</p>
						<p>낙농선진제국의 대부분이 후보종모우의 딸소를 일반 낙농가에서 생산하여 우군검정을 통하여 좋은 데이터로부터 후보종모우의 유전적 능력을 평가하는 방식에 의하여 후대검정(後代檢定)이 실시되고 있다.</p>
						<p>또한, 유전적 능력은 부모소로부터 자축으로 이어져 나간다. 그 때문에 젖소개량을 위해서는 정확한 혈연정보가 필수불가결하다. 혈연정보는 &quot;혈통등록&quot;에 의하여 구축되고 있다. 특히, 최근의 유전적 능력 평가 방법에 있어서는 혈통정보를 충분히 활용하기 위해서는 그 중요성이 점증되고 있다. 그림 1과 같이 우군검정, 후대검정, 혈통등록의 세가지 사업이 말하자면 젖소개량을 추진하는 가장 중요한 세가지 기둥이 되는 것이다.</p> 
					</dd>
				</dl>	
				<div className="imgBox mg60f"><img src={Pic801} alt=''/><br/>&lt;그림 1&gt; 젖소개량의 3기둥</div>

				<p className="viewTitle">젖소개량사업의 변천</p>
				<h2>인공수정기술의 발달과 후대검정 및 우군검정의 개시</h2> 
				<dl>
					<dt>혈통등록</dt>
					<dd>가축개량은 혈통등록으로부터 시작된다. 일본에 있어서 홀스타인종의 혈통등록은 대정(大正)시대까지 소급되지만, 1948년에 사단법인 일본 홀스타인등록협회가 설립된 이래, 현재와 같은 체제가 이루어진 것이다.</dd>
				</dl>
				
				<dl>
					<dt>인공수정기술의 발달과 종모우센터의 통합</dt>
					<dd>우수한 수소의 유전자를 넓게 활용하기 위한 인공수정기술은 지역단위의 인공수정조직 정비(전국 500개소)를 거쳐서, 1995년경에도 각도 도부현 1개소의 종모우 센터에 통합되어 그의 보급이 시도되어왔다. 다시 1960년대 후반에 가서 동결정액기술이 보급됨에 따라 정액유통의 광역화에 의하여 보다 우수한 소수의 종모우를 전국적으로 활용하는 일이 가능하게 되면서 전국적인 개량체제에도 재편작업이 추진되었다.</dd>
				</dl>
				
				<dl>
					<dt>후대검정과 우군검정의 개시</dt>
					<dd>
						<p>이것을 받아 전국에 공급하는 종모우를 선발하기 위한 후대검정을 일보에서도 개시하게 되었다. 1970년대 전반에는 국립종축목장에서 후보종모우를 생산하여 후대검정을 수행하는 소위 &quot;목장사업&quot;과 낙농가의 우량암소로부터 생산한 후보종모우를 22도현의 검정장에서 후대검정에 거는 말하자면 &quot;선발사업&quot;이 개시되어 &quot;검정필 종모우&quot;의 공급이 이루어지게 되었다.</p>
						<p>또한 암소의 비유능력파악과 효율적인 사료관리에 필요한 정보의 확보를 목적으로한 우군검정도 이 시기에 시작되었다. 그러면서도 민간의 인공수정사업체에 의한 미검정우의 정액공급도 계속되었다. 거기에 더햐여 북해도내에서는 1980년대에 들어와서는 우군검정기록을 써서 미검정으로 공용한 종모우의 사후추적으로 하는 유전적 능력평가를 실시하게 되었다.</p>
					</dd>
				</dl>
				
				<dl className="mg60f">
					<dt>총합검정의 개시</dt>
					<dd>
						<p>이와 같이, 일본의 후대검정은 검정장에서 딸소의 능력검정을 수행하는 소위 스테이션방식으로 시작되었다. 그러나, 우군검정의 보급이 진행됨과 아울러 그 기록에 기초한 유전적 능력 평가의 가능성을 보이므로써, 낙농가에 딸소를 배치하는 야외방식(Field system)의 후대검정이 가능하다는 생각을 하게 되었다.</p>
						<p>연구 및 검토를 되풀이한 결과, 1984년도에는 야외방식을 도입한 소위 &quot;총힙검정&quot;이 시작되었다. 그리하여 이것을 기회로 하여 민간의 인공수정사업체도 참가하게 되어, 국내의 모든 종모우가 후대검정을 받는 체제가 정비되었다. 당초에는 검정가능두수의 제약이 있어, 그때까지도 일부는 미검정 그대로의 공용의 계속되었다가, 1998년도 사업의 후보종모우가 선발된 1993년도 이후, 새로운 미검정우의 공용은 더 이상 지속되지 않았다.</p>
					</dd>
				</dl>

				<h2>전국통일의 유전적 능력 평가의 개시</h2> 
				<dl>
					<dt>종모우 평가</dt>
					<dd>
						<p>야외방식에 의한 후대검정이 가능하게 된 배경의 하나는, 통계학적인 분석방법과 전산기의 발전을 들 수 있다. 총합검정에 있어서 종모우의 유전적 능력 평가에 대하여는, 1984년도에 후대검정을 시작한 최초의 후보종모우의 검정 완료시기에 맞추어서, 국내 연구자, 기술자등에 의한 검토가 이루어져서, BLUP법과 MGS모델(통계적 유전분석방법)이 채용되었다.</p>
						<p>야외(농가)방식에 대한 종모우평가에서는 후보종모우의 딸소뿐만 아니고, 낙농가에 있는 다른 암소의 데이터도 쓰여진다. 그리고 과거의 데이터도 쓰인다. 그러기 때문에 데이터를 가진 암소의 아비소에 해당하는 종모우 모두의 유전적 능력이 평가된다. 이것에 의하여 1989년부터 년2회 모든 종모우의 동일시기에 대한 유전적 능력 평가치가 공표되고 있다.</p>
					</dd>
				</dl>
				<dl className="mg60f">
					<dt>암소평가</dt>
					<dd>
						<p>한편, 일본에서는 종모우평가를 목적으로 했기 때문에 MGS모델이 채용되었지만, 미국등에서는 같은 시기에 종모우 평가가 보다 정확히 되는 것과 함께, 암소의 평가도 가능한 애니멀 모델이 채용되었다. 그 때문에 일본에서도 애니멀 모델(Animal Model)의 채용이 기대되기 시작했다.</p>
						<p>이것을 받아, 1992년부터는 슈퍼 컴퓨터를 설치한 가축개량센타에서 종모우와 암소(우군검정우)의 유전적 능력 평가가 실시되고 있다. 이러한 과정을 거쳐서, 일본의 유용우의 유전적 개랑은 착실히 진행되어 뒤에서 이야기하는 바와 같이 미국과 거의 같은 정도의 개량량을 얻기까지에 이르렀다.</p>
					</dd>
				</dl>

				<p className="viewTitle">젖소개량사업의 개요</p>
				<h2>우군검정</h2>
				<p>우군검정은, 낙농가가 조직하는 검정조합에 의하여 실시되고 있다. 검정은 월1회(아침과 저녁), 낙농가에 검정원이 방문하여 1두씩의 유량의 측정과 유성분분석을 위한 시료채취를 실시하는 입회 검정을 기본으로 행해지고 있다. 시료는 분석실험실에 송부되어 성분율, 체세포수 등이 측정된다. 검정결과는 사단법인 가축개량사업단(북해도내는 사단법인 북해도 유우검정협회)에서 집계 및 분석된다. 검정조합과 사단법인 가축개량사업단간에 온라인화가 개설된 결과, 모든 지역의 낙농가에는 검정일로부터 1주간 이내에 &quot;검정성적표&quot;등의 정보가 보내진다. 또한 여러 가지 분석도 이루어져, 우군개량이나 경영개선을 위한 참고정보가 도도부현의 정보분석센타나 검정조합에 제공되고 있다.</p>
				<p>우군검정은, 낙농가의 부담을 기본으로 하여 일부는 국가와 도도부현에서 보조를 받아서 실시되고 있다. 사업의 흐름과 현재의 검정항목 및 낙농가로 휘드백되고 있는 정보는 그림 2에 나타낸 바와 같다. 가축개량센타에서 분석된 암소의 유전적 능력 평가치는 &quot;우군개량정보&quot;로서 년2회 제공되고 있다.</p>
				<div className="imgBox"><img src={Pic802} alt=''/><br/>&lt;그림 2&gt;</div>
				<p>검정항목 : 유량, 유성분(유지율, SNF율, 유단백질율), 체세포수, 사료급여량, 번식, 기타 낙농가에 피드백하는 정보</p>
				<ul className="listStyle01 mg60f">
					<li>검정성적표 (매월 - 검정기록에 기초한 개체 및 우군의 검정성적순) 
						<ul className="listStyle04 mg10f">
							<li>관리정보 : 월령, 체중, 수정, 임신, 분만, 공태, 건유, 유방염등의 정보 </li>
							<li>검정일 및 과거 1년간의 우군성적 : 유량, 유성분, 체세포수, 농후사료단가, 사료효과, 유대, 유사비, 305일 기대량등의 정보 </li>
							<li>개체의 성적 : 유량, 유성분, 체세포수, 체중, 농후사료급여량, CP요구량, TDN요구량, DM최대섭취량, 누계기록, 305일 실량 또는 기대량, 우평코드(A~E계급분포)등의 정보 </li>
							<li>개체관리정보 : 수정월일, 수정종모우, 분만예정, 요확인사항, 공태일수, 유방암등의 정보</li>
						</ul>
					</li>
					<li>검정종료통지서 (유기종료우한정-최근 산차 및 생애검정성적, 유전적 능력 평가치 등)</li>
					<li>검정성적집계표(매4반기 - 개체에 대한 과거 1년간의 검정성적 일람)</li>
					<li>검정종료우성적일람(매4반기 - 과거 1년간에 검정완료한 소의 리스트)</li>
					<li>번식관리표(매월 - 검정일 다음날부터 50일간의 번식 관리 정보)
						<ul className="listStyle04 mg10f">
							<li>장기 미수정우 및 최신 수정기록 일람 </li>
							<li>카렌다 : 발정, 수정, 수태, 건유, 분만예정우 등</li>
						</ul>
					</li>
					<li>우군개량정보 (년2회 - 최신의 개체에 대한 유전적 능력 평가치등) </li>
				</ul>

				<h2>후대검정</h2>
				<p>후대검정은 후보종모우의 작출과 선정, 우군검정농가의 암소에 대한 교배(이하 조정교배라부르자), 우군검정농가에서의 딸소생산으로부터 능력검정, 유전적 능력평가, 종모우의 선발등의 각 과정으로 이어진다. 사업의 흐름은 ＜그림3＞에 표시된 그대로이지만, 후보종모우의 선정으로부터 유전적 능력평가까지는, 국가의 보조사업을 중심으로 하여 실시되고 있다.</p>
				<p className="mg60f">후보종모우는 5개의 민간인공수정사업체(사단법인 북해도가축개량사업단, 사단법인 가축개량사업단, 주식회사 자판 홀스타인 브리딩 써비스, 주식호사 토카치 가축인공수정소, 주식회사 올-자판 브리딩 써비스)가 작출한 수소 가운데서 선정되고 있다. 또한 종래부 민간의 후보종모우는 대부분 수입우인데, 후대검정을 국내에서 실시하는 의의의 하나로서 국내의 유전자원을 활용하는 것등, 국가사업으로 국내의 우수 암소를 계획적으로 활용하여 후보종모우를 작출하고 있는 것이다. 거기에 더하여 소수이기는 하지만 가축개량센타가 MOTE(Multiple Ovlation and Embryo Transfer의 약칭)등의 수정란이식 신기술을 활용하여 후보종모우를 작출하는 것이다. 그런데 앞서 이야기한 바와 같이 1993년 이후 새로운 미검우의 공용은 중지시켰고, 1997년 가을시점에서 공용중의 종모우 139두 가운데, 이미 135두는 후대검정을 받고 공용된 종모우로 되어 있다. </p>
				
				<h2>혈통등록과 체형심사</h2> 
				<p>혈통등록은 사단법인 일본 홀스타인 등록협회에 의하여 실시되고 있다. 낙농가의 신청에 기초하는 것으로 반문등에 의한 개체식별, 등록원부에의 기재, 혈통 및 능력증명으로 작업이 추진되고 있다.</p>
				<div className="imgBox"><img src={Pic803} alt=''/></div>
				<p>등록건수는 1년간 경산우 두수 약 25~28만두에 대하여 년 20~22만건내외에서 추이되고 있으나, 최근 수년간에는 감소하는 경향을 보이고 있다. 정확한 혈연정보가 젖소개량의 요체이기 때문에, 특히 비유능력검정을 실시하고 있는 우군검정에 있어서는 혈통등록은 필요불가결한 것으로 생각되고 있다.</p>
				<p className="mg60f">단지, 다음장에서 소개하는 바와 같이 유럽제국을 중심하는 나라들은 귀표를 장착하는 개체식별 시스템이 정착되어 있어, 일본에서도 이런 시스템을 도입하려고 검토가 시작되고 있다. 금후, 혈통등록과 어떠한 연계를 맺어서 할 것인지가 큰 과제로 생각된다. 또한 동협회에 의하여 강건성의 지표가 되는 체형심사도 실시하고 있다. </p>

				<p className="viewTitle">젖소개량사업의 성과 </p>
				<p>가축개량센터에서 암소의 유전적능력평가를 시작함으로써 국내의 젖소개량실태가 명확하게 되었다. 암소의 유전적 능력의 생년별의 평균치 추이가 바로 이것을 표시하고 있다. (그림31참조) 또한 사양관리 개선의 실태도 명확하게 되었다.(그림32참조) 〈그림4〉은 유전적능력개량, 사양관리개선, 실제유량(검정성적)의 증가를 종합해서 표시한 것이다. 어느 경우이건 초산유량으로 기준을 하였다. 유전적능력개량과 사양관리개선이 그대로 유량증가로 나타나 있는 것을 알 수 있다.</p>
				<p>또한 개량이 착실히 진행되어 왔다는 것이 명료하게 표시되어 있다. 특히, 총합검정개시로부터 1989년까지는 년당 57kg의 개량량이 있었으나, 그 후에는 년당 126kg로 2배이상 늘어난 점이 눈에 띄인다. 1989넌은 최초의 후보 종모우가 검정을 완료하는 것을 기회로 해서 전국통일의 종모우 평가가 시작되는 해였다. 그 성적을 기초로 하여 종모우가 설발 및 교배되어 생산된 송아지의 생년이 1990년이다. 즉, 1989년생에 대하여 1990년이후에 생산된 암소의 유전적능력이 년당 2배가 되었다는 것은, 후대검정(총합검정)을 시작하여, 전국 통일의 종모우 평가를 개시한 성과로 밖에는 달리 말할 수가 없다.</p>
				<p>최근 10년정도는 유지율 기준치의 인상과 함께, 유지율 유지를 위하여 농후사료급여량의 증가에 브레이크를 밟은 것이라든지, 계획생산의 강화 또는 빈번한 이상 기상들의 원인도 있어, 사양관리의 년당 개선량은 둔화되고 있다.</p>
				<p>그러나, 보면 알수 있듯이 유전적 능력이 착실히 높아짐에 따라 유량은 증가하고 있다. 다시 말하면, 종래보다 더욱 생산성 향상을 위하여는 젖소개량의 중요성이 더 비중이 높아지고 있다는 것이다.</p>
				<div className="imgBox"><img src={Pic804} alt=''/><br/>&lt;그림 4&gt; 유전적능력의 개량 및 사양관리개선(초선유량) </div>
				<p>&lt;주&gt; 유전적 능력은 생년, 사양환경은 분만년, 검정성적은 유기종료년과 틈이 있기 때문에, 생년과 분만년은 2년, 생년과 유기종료년은 3년의 간격이 있는 것으로 표시하였다. 유전적 능력 은 그림 31을 직선회귀식으로 표시하였다. 유전적 능력+사양환경효과는 그림 32를 직선회귀식으로 표시한 것을 유전적능력에 곱하여 표시하였다.</p>
			</div>
  );
}

export default Fo2;
