import React from 'react';

// 컴포넌트
import GenePrintChart from './GeneChart';

// scss
import './genePrint.scss';

function GenePrint() {

  return (
    <article className="genePrint">
      <header className='genePrintHeader'>
        <h1>
          <span className='hidden'>젖소개량사업소 로고</span>  
        </h1>
        <button
          type='button'
          onClick={() => window.close()}
        >
          닫기         
        </button>
      </header>
      <div className='genePrintInner'>
        <ul className='genePrintInfo'>
          <li>관리번호 : 457호 ( 두희 버크아이 로소리티 457호 ) </li>
          <li>생년월일 : 2013-09-24</li>
          <li>아비 : 컴스타 로소리티-이티(200HO05588)</li>
          <li>어미 : 두희 게리슨 버크아이 354호</li>
          <li>외조부 : 알-이-더블류 버크아이-이티(200HO04779)</li>
          <li>유전자타입 : A1A2  BLADF  CTF  CVMF  DUMPSF  BL  FXIF  HH1F  HH3F  HH4F  IBKF  BL  MFF  MFF
          </li>
        </ul>
        <table className='genePrintTable'>
          <thead>
            <tr>
              <th>생산형질</th>
              <th>&#40;신뢰도:74.55%&#41;</th>
              <th>랭크&#40;%&#41;</th>
              <th>체형형질</th>
              <th>&#40;신뢰도:74.55%&#41;</th>
              <th>랭크&#40;%&#41;</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>유생산</td>
              <td>498.94</td>
              <td>74.05%</td>
              <td>체형 종합</td>
              <td>1.61</td>
              <td>19.92%</td>
            </tr>
            <tr>
              <td>유지방</td>
              <td>17.46</td>
              <td>76.86%</td>
              <td>유방 종합</td>
              <td>1.6</td>
              <td>82.33%</td>
            </tr>
            <tr>
              <td>유단백</td>
              <td>19.51</td>
              <td>71.41%</td>
              <td>지제 종합</td>
              <td>1.37</td>
              <td>71.66%</td>
            </tr>
            <tr>
              <td>체세포</td>
              <td>-0</td>
              <td>55.47%</td>
              <td>KTPI</td>
              <td>2,544.03</td>
              <td>73.16%</td>
            </tr>
          </tbody>
        </table>
        <GenePrintChart />
      </div>
    </article>
  );
}

export default GenePrint;
