import React from 'react';

import Pic166 from 'assets/images/sub/_images/pic166.gif';
import Pic167 from 'assets/images/sub/_images/pic167.gif';


function Do65() {

  return (
    <div className="techinfoview">
				<h1>저비용 고효율의 사양관리 1000×30×30전략</h1>
				<p style={{float: 'right', paddingRight: '20px'}}>경상대학교 축산학부 이 정규 교수</p>
				<h2>시작하면서</h2>
				<p className="pstyle">&nbsp;&nbsp;&nbsp;난데없이 무슨 전략? 그러나 우리 낙농가라면 한번쯤은 생각해본 수치일 것이다. 1일 납유유량 1톤(1000㎏), 한달이면 30일, 1㎏당 체세포 1등급에 대한 인센티브 30원이면 과연 얼마나 될까? 매달 900,000원이라는 거금이다. 1년이면 10,800,000원, 현재 3등급을 받고 있는 농가라면 1년에 21,600,000원이라는 어마어마한 돈으로 귀착된다. 이 정도의 돈이라면 요즘같은 IMF 시대에는 고급인력 1명의 연봉을 해결하고도 남는다. 그렇다. 체세포와의 전쟁을 위해서는 별도의 인력을 투입해도 될 만큼의 가치가 충분할 것이다. 체세포수의 감소는 단순히 유대 30원의 인센티브만 주어지는 것은 아니다. 이는 산유량의 증가와도 직결된다. 단순히 계산하면 체세포수 40만(2등급)인 농가가 20만 이하(1등급)로 감소시킬 경우 1톤 납유 농가라면 두당 25kg 착유할 수 있는 젖소 40두를 착유하여야 하겠고, 필자가 산유능력검정 자료를 분석한 바에 의하면 두당 1일 유량 증가량은 0.757㎏이 되므로(&lt;표 4&gt; 참조), 40두×0.757㎏×30일 이면 매월 908㎏의 우유를 더 납유할 수 있다는 결과를 얻게된다. 유대 또한 908㎏×616원 이면 559,328원을 더 받게 될 것이며, 이 또한 1년이면 6,708,000원이 된다. 체세포 등급 3급 농가가 1등급을 받는다면 유량증가로 인한 유대 증가는 월 1백만원이 넘을 것으로 추정된다. 결론적으로 체세포 등급이 2등급인 농가가 1등급으로 개선될 경우 연간 유대를 17,500,000원이라는 거금을 추가로 받을 수 있다는 계산이 나오는데 실상 이는 그렇지 못한 농가가 매년 그만큼의 손실을 입고 있다고 하는 편이 옳을 것이다. </p><br/>
				<p><b>&lt;표 1&gt; 체세포 등급 변동에 따른 유대 변동 내역</b></p>
				<table className="tablestyle13">
					<tr>
						<td colSpan="3">가정 1 : 1일 납유유량 1000kg<br/>가정 2 : 착유두수 40두(두당 1일 25kg)<br/>가정 3 : 체세포 등급 2등급에서 1등급으로 개선</td>
					</tr>
					<tr>
						<td>구분</td>
						<td>계산공식</td>
						<td>금액</td>
					</tr>
					<tr>
						<td>유대증가(30원/kg)</td>
						<td>1000kg×30일×30원</td>
						<td>900,000원/월</td>
					</tr>
					<tr>
						<td>유량증가(0.757kg/1두 1일)</td>
						<td>0.757㎏×40두×30일</td>
						<td>559,000원/월</td>
					</tr>
					<tr>
						<td>소계</td>
						<td></td>
						<td>1,459,000원/월</td>
					</tr>
					<tr>
						<td>연간유대변동</td>
						<td>1,459,000원×12개월</td>
						<td>17,508,000원/년</td>
					</tr>
				</table><br/>
				<p className="pstyle">&nbsp;&nbsp;&nbsp;그 동안 경험으로 잘 알고 있는 사실이지만 기준유대 10원 올리기가 얼마나 어려웠던가? 기준유대 10원이 오르면 시유 가격은 30원 오르는 것이 그 동안의 전례가 아니었던가? 그에 따른 부작용은 항상 원유체화로 귀결되고 낙농가가 푸대접 받는 쓰라린 아픈 기억을 언제까지나 반복할 것인가? 최근 IMF로 사료값이 폭등하면서 상향조정된 유대로 인한 소비위축과 그에 따른 필연적 현상인 유대 하향조정 논의를 바라보면서 다시 한번 생각나는 것이 유대 제값 받기 노력의 필요성인 것이다. 그렇다면 우리는 얼마나 노력하였으며, 우리의 현재 위치는 과연 어느정도 일까? &lt;표 2&gt;에는 1997년도 전국 산유능럭검정 지역 20개소 중 조사가 이루어진 18개소의 체세포수 평균치 일부를 나타내었다. 전국 평균은 ㎖당 40만8천이였으며, 이는 체세포수 등급 2등급에 간신히 턱걸이 하고 있다고 보아도 무방한 수준이다. 그러나 가장 성적이 좋은 종축개량협회 검정농가의 평균은 23만1천개로서 전국평균의 절반수준으로 체세포수 등급 1등급에 해당된다. 따라서 상당수의 검정농가는 체세포 때문에 기준유대에서 불이익 30원을 당하고 있으며, 종축개량협회 검정농가의 대부분은 30원의 인센티브를 받고 있어 유대 차이는 1㎏당 60원을 더 받아 온 것이다. 종축개량협회 검정농가들의 대부분은 대규모 낙농가이며 1톤 이상씩의 납유를 하는 농가가 대부분이다. 이들도 틈만나면 낙농하기 힘들다고들 아우성인데 그렇지 못한 낙농가들은 어떻게 버티고 있는지 필자로서는 도저히 알수가 없는 노릇이다.</p><br/>
				<p><b>&lt;표 2&gt; 주요 검정지역별 1일 1두당 유량 및 1㎖당 체세포수(체세포수 낮은 지역순)</b></p>
				<table className="tablestyle">
					<tr>
						<td>검정지역</td>
						<td>조사건수</td>
						<td>1일유량</td>
						<td>1ml당 체세포수(만)</td>
					</tr>
					<tr>
						<td>종축개량협회</td>
						<td>62,682</td>
						<td>25.8</td>
						<td>23.1</td>
					</tr>
					<tr>
						<td>천안</td>
						<td>13,693</td>
						<td>22.9</td>
						<td>31.8</td>
					</tr>
					<tr>
						<td>고창</td>
						<td>15,157</td>
						<td>20.8</td>
						<td>36.2</td>
					</tr>
					<tr>
						<td>평택</td>
						<td>9,695</td>
						<td>21.0</td>
						<td>36.4</td>
					</tr>
					<tr>
						<td>대구</td>
						<td>5,445</td>
						<td>21.1</td>
						<td>38.6</td>
					</tr>
					<tr>
						<td>순천</td>
						<td>4,728</td>
						<td>22.7</td>
						<td>39.1</td>
					</tr>
					<tr>
						<td>홍성</td>
						<td>9,359</td>
						<td>22.9</td>
						<td>40.1</td>
					</tr>
					<tr>
						<td>양산</td>
						<td>17,206</td>
						<td>22.5</td>
						<td>40.1</td>
					</tr>
					<tr>
						<td>상주</td>
						<td>10,701</td>
						<td>20.6</td>
						<td>42.4</td>
					</tr>
					<tr>
						<td>서울우유</td>
						<td>178,421</td>
						<td>22.1</td>
						<td>45.5</td>
					</tr>
					<tr>
						<td>공주</td>
						<td>4,428</td>
						<td>20.4</td>
						<td>48.9</td>
					</tr>
					<tr>
						<td>전국평균</td>
						<td>401,876</td>
						<td>22.6</td>
						<td>40.8</td>
					</tr>
				</table><br/>
				<p className="pstyle">&nbsp;&nbsp;&nbsp;&lt;표 2&gt;에서도 알수 있듯이 체세포수 30만 이하와 40만 이하 및 40만 이상인 지역의 산유량은 분명한 차이를 보인다. 특히 50만에 가까운 공주지역의 유량이 현저히 낮은 것은 체세포수의 영향이 매우 크게 작용한 것으로 판단된다. 따라서 더 이상의 체세포를 낮추어야할 당위성을 논할 필요는 없을 것이다. 저비용 고효율 낙농의 관건은 여하히 체세포를 줄일 것인가에 초점을 맞추는 것이 타당할 것이다. 여기서는 우선 체세포와 산유량과의 관계를 먼저 알아보고 산유능력검정을 통한 체세포수 감소방안을 알아보고자 한다.</p>
				<h2>유방염 감염 여부를 체세포수로서 알수 있을까? </h2>
				<p className="pstyle">&nbsp;&nbsp;&nbsp;우유 내에는 자연발생적인 체세포가 존재하기 마련이다. 그러나 이 체세포의 기원은 두가지로 대별될수 있다. 그 하나는 유선조직이 새롭게 교체되면서 떨아져 나오는 유선상피세포로서 발생하는 정상적인 체세포이고, 다른 하나는 유방 조직에 침투하는 세균에 면역학적인 대항 역할을 수행하는 백혈구이다. 따라서 우유내 체세포는 정상적인 젖소에서는 1㎖당 50,000에서 200,000개 정도를 유지하지만, 일단 유방염에 감염된 젖소의 경우에는 이에 대항하기 위한 백혈구 수가 급속히 증가하여 혈관에서 우유내로 유입되므로 백혈구에 의한 체세포 수가 증가하게 된다. 결론적으로 체세포에 대한 정보는 유방염 관리를 위한 효과적인 검색정보가 될 수 있으며, 우유내 체세포수는 유방건강, 우유생산량 및 우유의 질과는 상반된 관계를 가지게 되는 것이다.</p>
				<h2>체세포수(SCC)와 체세포점수(SCS)</h2>
				<p className="pstyle">&nbsp;&nbsp;&nbsp;체세포수는 우유 1㎖당 천세포 단위로 측정되는데, 이는 몇 만에서 수백만까지의 범위를 갖게되어 그 수치의 활용성에 문제가 있다. 따라서 대부분의 나라에서는 체세포 수를 변환하여 점수로서 표기하는데 그 방법으로는 로그함수를 이용한다. 이렇게 변환할 경우 0∼9의 범위를 갖게되어 축군 또는 개체의 체세포수 점수를 10가지로 나눌수 있다. 또한 각 점수에 대하여는 각각 10가지로 세분하여 나눌 수 있다. 이러한 점수분류는 외국의 경우는 일반적으로 이용되고 있는 방법으로 개체별로 조사된 체세포수를 활용하는데 매우 효과적이다. 체세포 점수(SCS)는 체세포수(SCC)가 배수로 증가할 때 1씩 증가한다. 즉, SCS 1은 체세포수 25,000, SCS 2는 체세포수 50,000, SCS 3은 체세포수 100,000, SCS 4는 체세포수 200,000, SCS 5는 체세포수 400,000, SCS 6은 체세포수 800,000, SCS 7은 체세포수 1,600,000이 된다.<br/><br/>체세포수 보다 체세포 점수(SCS)를 이용할 경우 이점은 다음과 같다.<br/></p>
				<ul>
					<li>유량손실량과 일정한 관계를 유지한다
						<ul>
							<li>우유생산량의 감소와 체세포점수와는 직선적인 관계를 나타낸다. 미국에서는 체세포 점수가 2보다 클 경우 1씩 증가할? 마다 1일 유량손실이 초산의 경우 0.75파운드, 2산차 이상에서는 1.5파운드인 것으로 보고 있으며, 우리나라의 최근 자료를 분석한 결과 초산에서는 0.37㎏, 2산차에서는 0.68㎏, 3산차이상에서는 0.76㎏씩 유량이 감소하는 것을 알수 있었다.</li>
						</ul>
					</li>
					<li>체세포점수의 평균
						<ul>
							<li>체세포수와 체세포점수는 1회의 측정치 보다는 여러번의 측정과 이들의 비교검토를 통해서 더욱 효과적으로 활용할 수 있다. 즉, 최근 몇 개월간의 체세포수 또는 점수의 변화를 체크하므로서 개체의 유방염 감염정도를 점검할 수가 있는 것이다. 또한 여러개체의 측정치를 평균하므로서 축군의 건강상태를 체크할 수 있는 것이다.</li>
						</ul>
					</li>
				</ul><br/>
				<p><b>&lt;표 3&gt; 체세포수(SCC)와 체세포점수(SCS)와의 관계</b></p>
				<table className="tablestyle">
					<tr>
						<td rowSpan="2">체세포점수</td>
						<td colSpan="10">체세포점수(소수점 첫 자리)/ 체세포수(천/㎖)</td>
					</tr>
					<tr style={{background: '#273143', Color: '#fff'}}>
						<td>.0</td>
						<td>.1</td>
						<td>.2</td>
						<td>.3</td>
						<td>.4</td>
						<td>.5</td>
						<td>.6</td>
						<td>.7</td>
						<td>.8</td>
						<td>.9</td>
					</tr>
					<tr>
						<td>0</td>
						<td>12</td>
						<td>13</td>
						<td>14</td>
						<td>15</td>
						<td>16</td>
						<td>18</td>
						<td>19</td>
						<td>20</td>
						<td>22</td>
						<td>23</td>
					</tr>
					<tr>
						<td>1</td>
						<td>25</td>
						<td>27</td>
						<td>29</td>
						<td>31</td>
						<td>33</td>
						<td>35</td>
						<td>38</td>
						<td>41</td>
						<td>44</td>
						<td>47</td>
					</tr>
					<tr>
						<td>2</td>
						<td>50</td>
						<td>54</td>
						<td>57</td>
						<td>62</td>
						<td>66</td>
						<td>71</td>
						<td>76</td>
						<td>81</td>
						<td>87</td>
						<td>93</td>
					</tr>
					<tr>
						<td>3</td>
						<td>100</td>
						<td>107</td>
						<td>115</td>
						<td>123</td>
						<td>132</td>
						<td>141</td>
						<td>152</td>
						<td>162</td>
						<td>174</td>
						<td>187</td>
					</tr>
					<tr>
						<td>4</td>
						<td>200</td>
						<td>214</td>
						<td>230</td>
						<td>246</td>
						<td>264</td>
						<td>283</td>
						<td>303</td>
						<td>325</td>
						<td>348</td>
						<td>373</td>
					</tr>
					<tr>
						<td>5</td>
						<td>400</td>
						<td>429</td>
						<td>460</td>
						<td>492</td>
						<td>528</td>
						<td>566</td>
						<td>606</td>
						<td>650</td>
						<td>696</td>
						<td>746</td>
					</tr>
					<tr>
						<td>6</td>
						<td>800</td>
						<td>857</td>
						<td>919</td>
						<td>985</td>
						<td>1056</td>
						<td>1131</td>
						<td>1213</td>
						<td>1300</td>
						<td>1393</td>
						<td>1493</td>
					</tr>
					<tr>
						<td>7</td>
						<td>1600</td>
						<td>1715</td>
						<td>1838</td>
						<td>1970</td>
						<td>2111</td>
						<td>2263</td>
						<td>2425</td>
						<td>2599</td>
						<td>2786</td>
						<td>2986</td>
					</tr>
					<tr>
						<td>8</td>
						<td>3200</td>
						<td>3430</td>
						<td>3676</td>
						<td>3940</td>
						<td>4223</td>
						<td>4526</td>
						<td>4851</td>
						<td>5199</td>
						<td>5572</td>
						<td>5972</td>
					</tr>
					<tr>
						<td>9</td>
						<td>6400</td>
						<td>6860</td>
						<td>7352</td>
						<td>7880</td>
						<td>8445</td>
						<td>9052</td>
						<td>9701</td>
						<td>10398</td>
						<td>11144</td>
						<td>11944</td>
					</tr>
				</table><br/>
				<p><b>&lt;표 4&gt; 체세포점수(SCS)에 따른 유량 손실량 추정치</b></p>
				<table className="tablestyle">
					<tr>
						<td rowSpan="2">체세포 점수(SCS)</td>
						<td rowSpan="2">체세포수(SCC)</td>
						<td colSpan="3">유량손실량(1일, ㎏)</td>
						<td colSpan="3">유량손실량(유기, ㎏)</td>
					</tr>
					<tr style={{background: '#273143', color: '#fff'}}>
						<td>1산차</td>
						<td>2산차</td>
						<td>3산이상</td>
						<td>1산차</td>
						<td>2산차</td>
						<td>3산이상</td>
					</tr>
					<tr>
						<td>0</td>
						<td>12.5</td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
					</tr>
					<tr>
						<td>1</td>
						<td>25</td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
					</tr>
					<tr>
						<td>2</td>
						<td>50</td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
					</tr>
					<tr>
						<td>3</td>
						<td>100</td>
						<td>0.372</td>
						<td>0.688</td>
						<td>0.757</td>
						<td>99</td>
						<td>183</td>
						<td>202</td>
					</tr>
					<tr>
						<td>4</td>
						<td>200</td>
						<td>0.743</td>
						<td>1.376</td>
						<td>1.516</td>
						<td>198</td>
						<td>367</td>
						<td>404</td>
					</tr>
					<tr>
						<td>5</td>
						<td>400</td>
						<td>1.115</td>
						<td>2.063</td>
						<td>2.274</td>
						<td>297</td>
						<td>550</td>
						<td>606</td>
					</tr>
					<tr>
						<td>6</td>
						<td>800</td>
						<td>1.487</td>
						<td>2.751</td>
						<td>3.032</td>
						<td>396</td>
						<td>734</td>
						<td>808</td>
					</tr>
					<tr>
						<td>7</td>
						<td>1600</td>
						<td>1.859</td>
						<td>3.439</td>
						<td>3.789</td>
						<td>496</td>
						<td>917</td>
						<td>1010</td>
					</tr>
					<tr>
						<td>8</td>
						<td>3200</td>
						<td>2.230</td>
						<td>4.127</td>
						<td>4.547</td>
						<td>595</td>
						<td>1100</td>
						<td>1213</td>
					</tr>
					<tr>
						<td>9</td>
						<td>6400</td>
						<td>2.602</td>
						<td>4.815</td>
						<td>5.305</td>
						<td>694</td>
						<td>1283</td>
						<td>1415</td>
					</tr>
				</table>
				<p>※ 우리나라 11,357두의 검정샘플 83,231개와 조사일의 검정성적을 기준으로 분석하였음</p>
				<h2>유방염에 걸린 소를 어떻게 찾아서 퇴치할 것인가?</h2>
				<p className="pstyle">&nbsp;&nbsp;&nbsp;먼저, 벌크유에 대한 체세포수 검사결과라도 철저히 점검하고 기록하자. 매번 통보되는 체세포수를 기록하고 유대정산을 위한 체세포수의 변동을 꾸준히 관찰하여야 하겠다. &lt;그림 1&gt;에서 보는 바와 같이 벌크유의 체세포수가 10만이면 그 중에 약 6%정도의 개체는 50만 이상인 개체가 포함되어 있으며, 벌크유의 체세포수가 50만이면 30%의 개체는 50만을 넘기고 있으며, 특히 21%는 체세포수가 75만 이상인 개체로 구성되어 있는 것으로 판단하여야 하며, 벌크유의 체세포가 90만이 되면 51%의 개체가 50만 이상인 것으로 판단하여야 한다. 낙농가의 평균 체세포수가 40만을 넘기고 있다는 사실은 우리 농가가 보유하고 있는 젖소의 약 30%이상이 잠재성유방염에 걸려 있으며, 약 21%는 임상형 유방염에 가깝다고 본다면 무리가 있는 것일까? 우리 목장은 어떠한가 지금 당장 유대전표라도 끄집어 내어 살펴보고 다시한번 자세를 가다듬어 보자.</p>
				<img src={Pic166} alt=""/>
				<p className="pstyle">&nbsp;&nbsp;&nbsp;다음은 개체유의 체세포수를 조사하여야 한다. 개체유란 한 마리의 우유, 즉 4분방의 우유를 모두 합친 우유이다. 산유능력검정을 받고 있는 농가라면 매월 정기적으로 알게되는 개체별 체세포수이다. 개체별 체세포수는 낮으면 낮을수록 좋겠지만 일단 10만/㎖를 목표로 하여야 한다. 개체유에 대한 체세포수 역시 매우 주의깊게 점검하여야 하는 이유는 이것 역시 4분방의 각각의 체세포수의 평균치이기 때문이다. 따라서 한 분방만 유방염에 감염되면 다른 분방으로 즉시 이환된다는 사실을 잊어서는 안될 것이다. 개체별 체세포수를 알기 위해서는 가장쉬운 방법이 산유능력검정사업에 참여하는 것이다. 현재 검정사업에 참여하고 있는 농가는 매월 통보되는 개체별 체세포수를 점검하고 개체별 체세포의 변화 상태를 그래프로 그려가며 세심하게 점검하여야 한다. 개체의 산차가 증가하면 체세포수도 유방염과 관계 없이 증가하며, 같은 유기중에도 비유일수가 증가하면 체세포수도 증가하게 된다. 따라서 개체별 체세포수의 급격한 변화에 대한 꾸준한 관찰이 매우 중요한 것이다. 검정을 받지 못하고 있는 농가는 해당조합에 문의하여 빠른 시일에 검정에 참여하는 것이 좋으며, 그것도 여의치 못하면 우선 인근의 가축위생시험소나 지도소와 협의하여 개체별 샘플을 검사할 수 있는 기회를 가져야 한다. 그러나 개체유의 체세포수도 유방염 감염개체를 밝혀내는데는 아래 &lt;그림 2&gt;에서 보는바와 같이 한계가 있다.</p>
				<img src={Pic167} style={{margin: '20px'}} alt=""/>
				<p className="pstyle">&nbsp;&nbsp;&nbsp;세 번째는 분방유의 체세포수를 알아야 한다. 분방유란 4개의 각 유두에서 착유한 우유로서 어떤 분방이 유방염에 걸렸는지를 알려주는 중요한 역할을 하게된다. 분방유의 경우 다른 분방의 영향을 받지 않기 때문에 앞의 개체유의 경우와 같은 문제가 생기지 않는다. 따라서 유방염의 경우 최소한의 검토단위로서 적합한 것으로 분방유에 대한 접근이 가장 합리적일 것이다. 분방유의 체세포수 체크에는 CMT 방법이 가장 손쉽고 실용적인 방법이다. CMT에 의한 유방염 검진요령을 정확히 모를 경우 조합의 지도요원을 초빙하여 정확히 숙지하여야 한다.<br/>네 번째로 조치할 사항은 유방염을 일으킨 원인균을 밝혀내는 일이다. 최근에는 검정이 있은 날에는 개체별 체세포수가 분석 되는대로 검정성적표와는 관계없이 우선 농가에 통보되는 것이 보통이다. 그 중에서 우선 체세포수가 50만이 넘는 개체와 지난달에 비하여 현저히 체세포수가 증가한 개체는 통보 받는 즉시 분방별 CMT 검사를 실시하고 의심되는 분방의 우유샘플을 무균상태로 채취하여 인근의 가축위생시험소, 진료소 또는 거래 가축병원에 가져가서 감수성검사를 의뢰하여야 한다. 이것을 귀찮다고 생각한다면 월 150여만원의 손실을 감수하겠다는 각오가 선행되어야 한다. 스스로 하여야할 기본적인 일을 시행하지 않고서 소득을 보장하라는 것은 한마디로 억지에 불과한 것이다. 대체로 감수성검사에는 2∼3일의 시간을 필요로 하므로 이를 감안하여 적절한 시기에 확인하는 것도 잊어서는 아니될 것이다.</p>
				<h2>마치면서</h2>
				<p className="pstyle">&nbsp;&nbsp;&nbsp;앞으로는 벌크유, 개체유, 분방유의 특성을 충분히 이해하고, 각각의 추이를 면밀히 검토하여 상황에 따른 적절한 조치를 취하므로서 내 손안에 들어와 있는 돈을 놓치는 일이 없도록 하여야 하겠다. 내 목장에는 단순히 체세포수 등급을 잘받고 있다는데 자만하지 말고 진정한 의미에서 유방염이 없어지도록 각별한 노력을 기울여야 하겠다. 또한 산유능력검정 관련기관에서는 체세포점수(SCS)에 의한 개체점검이 가능하도록 검정성적자료 제공에 관심을 가질필요가 있을 것이다. 체세포수는 유방의 건강상태를 나타내는 지표임과 동시에 목장 경영안정의 지표가 되기도 한다는 사실을 한시도 잊어서는 아니될 것이다.</p>
			</div>
  );
}

export default Do65;