import React from 'react';

import Pic554 from 'assets/images/sub/_images/pic554.jpg';
import Pic555 from 'assets/images/sub/_images/pic555.jpg';
import Pic556 from 'assets/images/sub/_images/pic556.jpg';

function Do102() {

  return (
    <div className="techinfoview">
      <h1>콕시듐병</h1>
      <div className="leftpart">
        <h2>원인</h2>
        <ul>
          <li>아이메리아라는 원충이 소화관의 짐막상피에 기생하여 일으키는 병이다.  이 원충은 소화관 점막에서 증식을 반복하여 마침내 분변중에 섞여서 배설된다. 배설된 충란이 볏짚등에 붙어있다가 다른소가 이를 섭취함으로써 감염이 된다. </li>
        </ul>
        <h2>증상</h2>
        <ul>
          <li>3∼18개월령 사이의 어린소에서 발생이 많다. 
            <ul>
              <li> 처음에는 가벼운 설사를 일으키고 점차로 점액과 같은 혈변을 배설하며 분변은 심한 악취가 난다. </li>
              <li>이병에 걸린 소는 혈변으로 인해 항문 주변이 적갈색으로 오염되어 있다. </li>
              <li>증상이 악화되면 복부를 뒷발로 차거나 노책(勞責)을 되풀이 하며, 치료가 늦어지면 기립불능이 되어 폐사한다. </li>
            </ul>
          </li>
        </ul>
        <h2>목장에서의 처치</h2>
        <ul>
          <li>원기와 식욕이 없고 혈변설사가 나타나는 소는 이병으로 의심하여 격리한다.</li>
          <li>수의사의 진찰을 받고 분변검사를 의뢰한다. </li>
          <li>설사로 오염된 자리는 끊는 물이나 소독제를 사용하여 철저히 소독한다. </li>
          <li>분변검사 결과 포자 원충이 발견되면 다른소에 대해서도 분변검사를 실시하여 치료를 받도록 한다. </li>
        </ul>
      </div>
      <div className="rightpart">
        <img src={Pic554} className="nonborder" alt=""/>
        <img src={Pic555} className="nonborder" alt=""/>
        <img src={Pic556} className="nonborder" alt=""/>
      </div>
    </div>
  );
}

export default Do102;