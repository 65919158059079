import React from 'react';
import { useNavigate } from 'react-router-dom';

// 컴포넌트
import SubTitle from 'components/subTitle/SubTitle';

// scss
import './provinceStatus.scss';

function ProvinceStatus() {
  const navigate = useNavigate();

  const data = [];
  const today = new Date();
  const currentYear = today.getFullYear();
  const currentMonth = today.getMonth();

  let startYear;
  if (currentMonth === 0) {
    startYear = currentYear - 1;
  } else {
    startYear = currentYear;
  }
  for (let year = startYear; year >= 2005; year -= 1) {
    const yearMonthObject = {};
    yearMonthObject.year = year;
    const monthlist = [];
    if (year === currentYear) {
      for (let month = 1; month <= currentMonth; month += 1) {
        // console.log(`Year: ${year}, Month: ${month}`);
        monthlist.push(month);
      }
    } else {
      for (let month = 1; month <= 12; month += 1) {
        monthlist.push(month);
      }
    }
    yearMonthObject.month = monthlist;
    data.push(yearMonthObject);
  }

  const handleOpenOzReport = (yr, mt) => {
    const fname = 'New_do.mrd';
    const tmonth = String(yr).padStart(4, '0') + String(mt).padStart(2, '0');
    const queryParams = new URLSearchParams({
      fname,
      mon: tmonth,
    });
    const url = `${process.env.PUBLIC_URL}/crownix.html?${queryParams}`;
    window.open(url, '_blank', 'noopener, noreferrer');
  };
  // const handleOpenOzReport = (year,month) => {
  //   const ym=String(year).padStart(4, '0')+String(month).padStart(2, '0');
  //   const params=`tmonth~${ym}`;
  //   const Fname='new_do';
  //   const Oname='sql_New_work1';
  //   const llWidth = 900 ;
  //   const llHeight = 600;
  //   const leftWin = (window.screen.width - llWidth) / 2;
  //   const topWin = ((window.screen.height - llHeight) / 2) - 40;

  //   const queryParams = { fname: Fname, oname: Oname, param:params};
  //   const queryString = new URLSearchParams(queryParams).toString();
  //   const ozUrl='http://oz.dcic.co.kr:10010/ozhviewer/call_oz.jsp?';
  //   const urlWithQuery = `${ozUrl}${queryString}`;

  //   const options = `width="${llWidth}",height="${llHeight}",left="${leftWin}",top="${topWin}",scrollbars=no`;
  //   const popup = window.open(urlWithQuery,'openPage',options);
  // };

  return (
    <div className="provinceStatus subCon">
      <SubTitle />
      <ul className="provinceStatusPdf">
        {data.map((file) => (
          <li key={file.year}>
            <span>{file.year}년도</span>
            <ul className="monthPdf">
              {file.month.map((month) => (
                <li
                  // onClick={() => handleOpenOzReport(file.year,month)}}
                  onClick={() => handleOpenOzReport(file.year, month)}
                  key={month}
                >
                  {month}월
                </li>
              ))}
            </ul>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default ProvinceStatus;
