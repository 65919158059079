import React from 'react';

import pic267 from 'assets/images/sub/_images/pic267.jpg';
import pic268 from 'assets/images/sub/_images/pic268.gif';
import pic269 from 'assets/images/sub/_images/pic269.jpg';
import pic270 from 'assets/images/sub/_images/pic270.jpg';
import pic271 from 'assets/images/sub/_images/pic271.jpg';

function Do185() {

  return (
    <div className="techinfoview">
      <h1>페레니얼 라이그라스</h1>
      <p>영명 : Perennial ryegrass<br/>학명 : Lolium perenne L.  </p>
      <div style={{width: '60%',float: 'left'}}>
        <h2>원산지</h2>
        <p className="pstyle">&nbsp;&nbsp;&nbsp;남부유럽, 북부아프리카 그리고 아시아 남서부가 원산지이고 1667년 영국에서 목초로 재배 되었다고 믿어지며, 서부유럽, 영국, 뉴질랜드, 남부대서양등에서 중요한 목초로서 이용되고 있다.</p>
        <h2>성상</h2>
        <p className="pstyle">&nbsp;&nbsp;&nbsp;페레니얼 라이그라스는 중간다년생으로 천근성 섬유근을 가지고 있으며, 잎들은 치밀하고 농녹색이고 광택이 나며 어릴 때에는 잎이 접혀있다. 초장은 30∼60cm정도로 짧은 편이며, 잎은 짧은편이며 끝이 뾰족하다. 소수는 줄기에서 바깥쪽으로 각을 이루고 있어 일반화본과 목초와 구분된다.</p>
        <h2>재배</h2>
        <p className="pstyle">&nbsp;&nbsp;&nbsp;생산량을 높이기 위하여는 년간 강수량은 750∼1,250mm정도는 되어야 하며, 토양은 비옥하여야 한다. 토양적응도는 넓어서 중점토와 배수 불량한 곳에서도 잘 자라나 비옥한 것이 더욱 좋다. 다른 화본과 목초보다는 초지에서 견디는 힘이 약간 못하지만 일반적으로 3∼4년은 유지된다. 재배지는 너무 건조하지 않은 지대이어야하고 너무 춥지 않아야하며 지나친 고온지대이거나 가물지 않어야 한다. 기온이 높고 건조한 7∼8월에는 별로 생산이 되지 않고 9월까지 가뭄이 계속되면 재생이 늦어진다. 10a당 청초수량은 3,000∼4,000kg 정도며 품종으로는 에스-24(S-24), 리베일(Reveille), 노레아(Norlea), ?토우(Taptoe), 에?트(Ellett) 등이 있다. </p>
        <h2>이용</h2>
        <ul>
          <li>건초
            <p>페레니얼라이그라스는 건초로 조제하기보다는 오히려 방목초지로 이용하는 것이 유리하나 건초로도 이용할 수 있다. 일반적으로 출수직후에 일번초로 이용할 수 있고 수확후 재생한 것을 늦여름이나 초가을에 방목을 할 수 있다. </p>
          </li>
          <li>방목 및 청예
            <p>원칙적으로 방목초지로 이용하기 위하여 파종을 한다. 유식물은 분얼력이 왕성하고 생장이 빠르며 높은 수량 그리고 좋은 질의 특성은 초지조성용으로 적합하다. 새로이 파종한 초지는 파종후 2개월후에는 방목을 할 수 있다. 생육이 빠르기 때문에 때로는 생육을 조정해 줄 필요가 있다. 수잉기 이전에 윤환방목 함이 생육조절에 도움이 되나 억세져서 사일레지용으로 수확할 필요가 있다. 다수확을 위하여는 질소질 비료를 필히 사용하여야 한다.</p>
          </li>
        </ul>
      </div>
      <div style={{width: '40%', float: 'left'}}>
        <table>
          <tr>
            <td><img src={pic267} className="nonborder2" alt=""/></td>
          </tr>
          <tr>
            <td><p style={{textAlign: 'center'}}>밑동의 색깔과 분얼경의 발생</p></td>
          </tr>
          <tr>
            <td><img src={pic268} className="nonborder2" alt=""/></td>
          </tr>
          <tr>
            <td><p style={{textAlign: 'center'}}>식물전체의 모양과 소수</p></td>
          </tr>
          <tr>
            <td><img src={pic269} className="nonborder2" alt=""/></td>
          </tr>
          <tr>
            <td><p style={{textAlign: 'center'}}>종 자</p></td>
          </tr>
          <tr>
            <td><img src={pic270} className="nonborder2" alt=""/></td>
          </tr>
          <tr>
            <td><p style={{textAlign: 'center'}}>유식물의 생육과정</p></td>
          </tr> 
          <tr>
            <td><img src={pic271} className="nonborder2" alt=""/></td>
          </tr>
          <tr>
            <td><p style={{textAlign: 'center'}}>페레니얼라이그라스(상)와 <br/>이타리안라이그라스(하)의 이삭 비교</p></td>
          </tr> 
        </table>
      </div>
    </div>
  );
}

export default Do185;