import React from 'react';

import Pic8 from 'assets/images/sub/_images/pic8.png';
import Pic9 from 'assets/images/sub/_images/pic9.gif';
import Pic10 from 'assets/images/sub/_images/pic10.png';
import pic11 from 'assets/images/sub/_images/pic11.gif';
import Pic12 from 'assets/images/sub/_images/pic12.gif';


function Do2() {

  return (
    <div className="techinfoview">
				<h1>수태율 향상 방안</h1>
				<h2>발정</h2>
				<ul>
					<li>발정징후
						<p>발정은 임신을 위한 행동으로번식에는 중요한 의미가 있다. 특히 인공수정으로 수태율을 높이기 위해서는 발정발견은 필수적이다. 정상적인 발정징후는 불안하여 안절부절하며 보행수가 증가하고 식욕감퇴증상을 보이며 신경이 예민해져 소리를 지르기도 하며 다른소에게 승가하거나 승가를 허용하기도 한다. 또한 외음부가 붓고 커지며 점액이 누출된다.</p>
					</li>
					<li>발정주기 및 지속기간
						<p>발정주기란 한 발정기의 개시일로부터 다음 발정기의 개시일까지를 말하며 성주기라고도 한다. 개체에 따라서 12~36일까지 차이가 심하지만 보통 18~24일로 평균 21일이다.<br/>발정지속시간은 평균 18~20시간이지만 개체에 따라 차이가 크다. 육성우는 짧고 경산우는길며 계절적으로는 여름은 짧고 겨울은 길다. 영양상태가 나쁜소는 정상우보다 짧다.</p>
					</li>
					<li>발정식별
						<p>하루중 발정이 개시되는 시기는 저녁 6시부터 아침 6시사이에 약 70%의 소가 이 시간에 발정이 개시된다. 따라서 아침 착유전 집중적인 관찰을 해야 한다. 아침 착유시 발정이 발견되면 착유 후 소를 운동장으로 보내지 말고 보정시킨 후 직장을 통하여 자궁경을 잡고 점액을 배출시켜 본다. 점액의 점도가 높으면 이소는 어제 저녁 (오후 6~12시) 무렵부터 발정이 시작되었으므로 상태에 따라 바로 수정을 시키든지 오전중에 시켜야 적기를 맞출 수 있다. 이것을 아침무렵 시작된 줄 알고 오후에 수정을 시킬 경우 적기가 늦어 높은 수태율을 기대할 수 없다.<br/></p>
						<img src={Pic8} alt="발정식별"/>
						<p>발정의 식별방법에 따라 발견율도 많은 차이를 보이고 있어 일상관리시 특별히 관심을 가지고 관찰해야 발정이 온 개체를 89~90% 알아낼 수 있다.</p>
						<p><br/>&lt;발정의 식별방법에 따른 발견율&gt;</p>
						<table className="tablestyle">
							<tr>
								<td style={{width: '50%'}}>식별방법</td>
								<td>발견율(%)</td>
							</tr>
							<tr>
								<td>1일 24시간 관찰</td>
								<td>90~100</td>
							</tr>
							<tr>
								<td>1일 2~3회 집중관찰</td>
								<td>81~90</td>
							</tr>
							<tr>
								<td>일반관리</td>
								<td>56</td>
							</tr>
							<tr>
								<td>수소이용(시정모)</td>
								<td>93~100</td>
							</tr>
							<tr>
								<td>보행기 사용</td>
								<td>96</td>
							</tr>
							<tr>
								<td>승가</td>
								<td>35~57</td>
							</tr>
							<tr>
								<td>승가허용</td>
								<td>65~98</td>
							</tr>
						</table>
						<br/><p>&lt;승가 및 승가형용우 발정비교&gt;</p>
						<table className="tablestyle">
							<tr>
								<td rowSpan="2">구분</td>
								<td colSpan="2">승가</td>
								<td colSpan="2">승가허용</td>
							</tr>
							<tr style={{background: '#273143' , color: '#fff'}}>
								<td>두수</td>
								<td>비율(%)</td>
								<td>두수</td>
								<td>비율(%)</td>
							</tr>
							<tr>
								<td>발정우</td>
								<td>317</td>
								<td>56.7</td>
								<td>551</td>
								<td>98.6</td>
							</tr>
							<tr>
								<td>임신우</td>
								<td>111</td>
								<td>19.9</td>
								<td>3</td>
								<td>0.5</td>
							</tr>
							<tr>
								<td>분만 후 암소</td>
								<td>33</td>
								<td>5.9</td>
								<td>2</td>
								<td>0.4</td>
							</tr>
							<tr>
								<td>기타</td>
								<td>9</td>
								<td>17.9</td>
								<td>5</td>
								<td>0.5</td>
							</tr>
							<tr>
								<td>계</td>
								<td>559</td>
								<td>100</td>
								<td>559</td>
								<td>100</td>
							</tr>
						</table>
						<br/><p>※<b>임신 중 발정</b><br/>수태후에도 발정율은 3~5%이며 임신중의 발정은 임신초기의 3개월 사이에 나타나는 것이 보통이나 그보다 늦게 나타날 수 있다. 이때 수정을 시킬 경우 월령이 다른 두 마리의 새끼를 동시에 임신하는 중복임신이 되는 수가 있다. 하지만 이미 임신을 하고 있는 개체에 재수정을 할 경우 어린 배아나 태아가 조기사망 또는 유산위험 가능성이있다.<br/><br/>※<b>고온 스트레스가 번식에 미치는 영향</b><br/>
						- 발정주기를 연장시키거나 발정기간의 단축, 발정징후의 미약화 및 무발정 현상을 초래한다.<br/>
						- 번식에 관계하는 내분지 기능을 저하시킨다. <br/>
						- 번식에 관계하는 내분비 기능을 저하시킨다. <br/>
						- 자궁내 온도상승으로 인한 배의 성장에 필요한 영양소와 산소등의 공급부족으로 배의 생존에 악영향을   미쳐 수태율을 저하시킨다. <br/>
						- 대책: 여름철에 고온스트레스를 받지 않도록 통풍, 송풍, 기화냉각, 일사의 차단과 단열, 우체관리(브러싱, 물뿌리기)등을 잘해야 한다. <br/><br/>
						</p>
					</li>
					<li>수정적기
						<ul>
							<li>배란시기 : 발정 종료 후 8-11시간</li>
							<li>배란 후 난자의 수정 능력 유지 시간(난관 내 생존시간)이 18-20시간이나 배란 후 섲ㅇ시기가 빠를수록 좋다(노하시는 다정자 침입으로 수태가 이루어지지 않는다.)</li>
							<li>정자가 수정부위까지 상행하는데 요하는 시간 4-8시간</li>
							<li>수정능력 획득시간 6시간</li>
							<li>정자의 수정능력 보유시간 24-40시간</li>
						</ul>
						<p>따라서 수정적기는 수정능력을 획득한 신선한 정자와 배란직후의 신선한 난자가 만날 수 있는 발정종료 3시간전에서 종료 후 2시간 이내에 수정시키는 것이 수태율 향상의 지름길이다.</p>
						<img src={Pic9} alt="수정적기"/>
					</li>
				</ul>
				<h2>인공수정 방법 및 유의점</h2>
				<ul>
					<li>정핵융해
						<ul>
							<li>196℃에 저장된 정액은 35℃~40℃의 온수에 20초간 융해한 후 사용한다.
							<br/><p>&lt;융해온도에 따른 정자의 회복율(3시간 평균)&gt;</p>
							<table className="tablestyle">
								<tr>
									<td>융해온도</td>
									<td>활력 및 생존율(%)</td>
									<td>정상첨체(%)</td>
								</tr>
								<tr>
									<td>5℃ 찬물</td>
									<td>30.3</td>
									<td>31.2</td>
								</tr>
								<tr>
									<td>24℃ 공기중</td>
									<td>1.3</td>
									<td>26.4</td>
								</tr>
								<tr>
									<td>35℃~40℃ 온수</td>
									<td>51.4</td>
									<td>61.0</td>
								</tr>
							</table>
							</li>
						</ul><br/>
					</li>
					<li>정액주입 방법
						<ul>
							<li>주입에 필요한 기구를 사용하기 편리한 곳에 둔다. </li>
							<li>발정우를 보정한다(특히 놀라지 않게 한다.) </li>
							<li>37℃의 온수를 충분히 준비한다.</li>
							<li>수정할 정액을 미리 확인한다.</li>
							<li>정액을 융해한다.:20초정도(융해시 흔들지 말아야 한다 흔들리면 정자가 손상을 입게 된다.) </li>
							<li>융해한 정액은 휴지로 물기를 닦는다.(스트로 절단시 정액과 물이 혼합되지 않게 하며 스트로는 봉함부위쪽을 직각으로 자른다.) </li>
							<li>주입기의 가운데에 있는 밀대를 15cm가량 후퇴시킨다.(스트로를 끼우기 위한 것) </li>
							<li>스트로를 장착한다.(스트로를 자른 부분이 바깥쪽에 있게 한다.) </li>
							<li>주입기에 시스를 끼운다.(세균감염방지를 위해 끝부분은 만지지 않는다: 질내 삽입부분)(때에 따라서 슬리브를 씌운다.) </li>
							<li>발정우의 외음부를 휴지로 깨끗이 닦는다.</li>
							<li>외기 온도가 낮을 시는 정액이 장착된 주입기를 품안에 넣거나 정액이 장착된 쪽의 주입기부위를 입으로 문다.</li>
							<li>직장에 삽입한 팔로 외음부 부위의 직장별을 은근히 눌러 외음부가 열리게 한다. </li>
							<li>왼손에 비닐장갑을 낀 상태로 오른손으로 자궁경을 잡고 주입기를 서서히 상방향 15도 각도를 유지하면서 자궁경관입구까지 삽입한다.</li>
							<li>약 20~25cm 정도 삽입하면 더 이상 들어가지 않는데 이곳이 자궁경관 입구로 자궁경관이 자궁을 향해 길게 연결되어 있다. </li>
							<li>경관 앞에서 비닐(슬리브)를 벗기고 주입기를 경관내로 서서히 밀어 넣는다(둥근형의 자궁추벽을 염두에 두면서 서서히 주입기를 좌우로 돌리면서 넣는다.) </li>
							<li>주입기는 가볍게 잡아야 하며 엄지로 밀대를 누르면 주입기에 힘이 가해져 생식기에 사어를 입힐 수 있다. </li>
							<li>경관을 완전히 통과하면 집게손가락으로 주입기 끝을 확인한다. </li>
							<li>경관을 잡고있던 손을 풀고 엄지로 주입기의 끝을 서서히 눌러서 정액이 흘러내리듯이 밀어 넣는다.</li>						
						</ul>
						<p>적기에 수정을 하더라도 수태율 향상을 위해서는 자궁체에 정확히 주입하는 방법과 아울러 양쪽 자궁각에 주입하는 기술이 있다.<br/><br/>&lt;정액주입 부위별 수태율&gt;<br/></p>
							<table className="tablestyle">
								<tr>
									<td>지역별</td>
									<td>자궁체</td>
									<td>자궁각</td>
								</tr>
								<tr>
									<td>1지역</td>
									<td>46.0%(1,556두)</td>
									<td>67.2(580)</td>
								</tr>
								<tr>
									<td>2지역</td>
									<td>49.9(270)</td>
									<td>74.1(318)</td>
								</tr>
								<tr>
									<td>3지역</td>
									<td>45.8(403)</td>
									<td>69.9(321)</td>
								</tr>
								<tr>
									<td>4지역</td>
									<td>56.8(412)</td>
									<td>71.2(318)</td>
								</tr>
								<tr>
									<td>평균(전체)</td>
									<td>44.7(2641)</td>
									<td>64.6(1,537)</td>
								</tr>
							</table>
							<p><br/>&lt;주입부위와 경험에 따른 수태율&gt;</p>
							<table className="tablestyle">
								<tr>
									<td colSpan="3">자궁체 주입시 수태율</td>
									<td colSpan="2">양쪽 자궁각 주입시 수태율</td>
								</tr>
								<tr>
									<td>교육직전</td>
									<td>교육직후</td>
									<td>교육6개월후</td>
									<td>교육직후</td>
									<td>교육6개월후</td>
								</tr>
								<tr>
									<td>53%(23/43두)</td>
									<td>95%(32/35두)</td>
									<td>75%(40/53두)</td>
									<td>96%(52/54두)</td>
									<td>97%(32/33두)</td>
								</tr>
							</table><br/>
					</li>
					<li>냉동정액관리
						<p>액체질소는 콘테이너 깊이 1/3수준까지 충전되어 있으면 정액은 안전하다.<br/>그러나 1/3이하일 경우 액체질소의 기화가 빠르게 일어나 액체질소가 떨어지는 경우가 있다. 이때의 정액은 사용하지 못한다. 액체질소(ln2)의 온도는 -195.8℃이다.</p>
					</li>
				</ul>
				<h2>냉동정액 취급 요령</h2>
				<p>냉동정액을 취급시 외기온도에서 7초이상 노출되어서는 안된다. 정액을 뽑으려고 할 때는 캐니스터(CANISTER)를 콘테이너(냉동정액고)밖으로 않은 상태(서리라인 이하로 고정)에서 신속하게 꺼내야 한다.</p>
				<ul>
					<li>액체질소의 성질
						<p>액체질소(LN2)는 공기중의 질소를 고압으로 압축하여 분리, 액화신킨 것이다. 공기의 조성은 질소 78%, 산소 21%, 기타 약 1%정도이다. 액체질소 1l 가 15℃에서 기체로 변할때는 860l 가 됨으로 만약에 냉동정액고내의 액체질소가 갑자기 기화된다면 굉장한 폭발력이 생긴다.<br/>액체질소의 비등점은 -195.8℃이며 1kg이 가화할 때 48Kcal의 열을 흡수함으로 초저온을 일으킨다. 초저온이므로 동상에 유의해야한다. </p>
					</li>
					<li>냉동정액(고) 관리 요령
						<p>냉동정액고(Frosen Semen Container)는 스테인레스 또는 알루미늄으로 만들어진 특수보온병이다. 외성과 내성으로 되어 있으며 가운데는 10-8기압으로 초진공화 되어 있다. 외관상으로 영구보존이 가능할 물건 같으나 소모성의 성질을 가지고 있다. 취급시 충격을 주어서는 안되며 공기가 잘 통하는 냉압소에 보관하고 LN2잔량이 밑바닥에 10cm(I/3-I/4)이하가 되면 보관정액의 활력 저하 및 사멸의 위험성이 있다.<br/><br/>&lt;액체질소 관리카드&gt;</p>
						<table className="tablestyle">
							<tr>
								<td>확인횟수</td>
								<td>안전깊이준수</td>
								<td>충진1</td>
								<td>충진2</td>
								<td>충진3</td>
								<td>충진4</td>
							</tr>
							<tr>
								<td rowSpan="2">질소확인일</td>
								<td rowSpan="2">30cm</td>
								<td>&apos;98.1.1</td>
								<td>&apos;98.4.5</td>
								<td>&apos;98.7.8</td>
								<td></td>
							</tr>
							<tr>
								<td>31cm</td>
								<td>30cm</td>
								<td>31.5cm</td>
								<td></td>
							</tr>
							<tr>
								<td rowSpan="2">질소확인1</td>
								<td rowSpan="2">23cm</td>
								<td>&apos;98.2.1</td>
								<td>&apos;98.5.5</td>
								<td></td>
								<td></td>
							</tr>
							<tr>
								<td>24cm</td>
								<td>23.5cm</td>
								<td></td>
								<td></td>
							</tr>
							<tr>
								<td rowSpan="2">질소확인2</td>
								<td rowSpan="2">18cm</td>
								<td>&apos;98.3.1</td>
								<td>&apos;98.6.5</td>
								<td></td>
								<td></td>
							</tr>
							<tr>
								<td>19cm</td>
								<td>18cm</td>
								<td></td>
								<td></td>
							</tr>
							<tr>
								<td rowSpan="2">충진전</td>
								<td rowSpan="2">8cm</td>
								<td>&apos;98.4.1</td>
								<td>&apos;98.7.5</td>
								<td></td>
								<td></td>
							</tr>
							<tr>
								<td>10cm</td>
								<td>7cm</td>
								<td></td>
								<td></td>
							</tr>
						</table>
						<p>냉동정액은 정액관리대장을 비치하여 월소요량을 파악하고 계획교배, 최적종모우 정액을 선정하여 6개의 캐니ㅡ타별로 축번을 관리하면 취급하기도 좋고 사용하기도 편리하다.<br/><br/>&lt;정액 관리대장&gt;</p>
						<table className="tablestyle">
							<tr>
								<td colSpan="5">캐니스타 번호 : #1 축명 H-270 마틴</td>
							</tr>
							<tr>
								<td>일자</td>
								<td>입고</td>
								<td>출고</td>
								<td>잔량</td>
								<td>비고</td>
							</tr>
							<tr>
								<td>&apos;98.2.1</td>
								<td>50</td>
								<td></td>
								<td>50</td>
								<td></td>
							</tr>
							<tr>
								<td>2.25</td>
								<td></td>
								<td>2</td>
								<td>48</td>
								<td></td>
							</tr>
							<tr>
								<td>2.16</td>
								<td></td>
								<td>3</td>
								<td>45</td>
								<td></td>
							</tr>
							<tr>
								<td>2.20</td>
								<td></td>
								<td>1</td>
								<td>44</td>
								<td></td>
							</tr>
						</table>
					</li>
				</ul>
				<h2>수태율 향상을 위한 사양관리</h2>
				<ul>
					<li>충분한 운동을 시킨다.</li>
					<li>충분한 조사료를 공급한다.</li>
					<li>영양결핍이나 과잉급여를 피해야 한다.</li>
					<li>분만 후 발정재귀를 빨리오게 한다(분만전 20일경 비타민 E 550 I.U + 세레늄 40mg 근육주사로 태반정체 예방)</li>
					<li>다즙사료 급여시 목건초와 혼합급여하면 좋다.</li>
					<li>비타민 A, E 및 광물질을 투여하면 좋다.</li>
					<li>소의 비유기별 적정한 바디 컨디션을 유지시킨다.</li>
					<li>비유량이 많고 고능력우 일수록 사양관리를 철저히 하여 번식률이 떨어지지 않도록 한다.</li>
					<li>번식기록을 철저히 활용한다.</li>
					<li>고온다습을 피하고 그늘이나 환기가 잘되는 우사에서 사육한다.</li>
				</ul>
				<h2>저수태우 대책</h2>
				<ul>
					<li>발생요인<br/>
						<img src={Pic10} alt="발생요인"/>
					</li>
					<li>많이 나타나는 번식장애<br/>&lt;한우, 젖소, 육우 원인별 번식장애&gt;
						<table className="tablestyle">
							<tr>
								<td>품종</td>
								<td>번식장애요인</td>
								<td>발생률(%)</td>
							</tr>
							<tr>
								<td rowSpan="2">한우</td>
								<td>난소이상</td>
								<td>82.4</td>
							</tr>
							<tr>
								<td>자궁이상</td>
								<td>17.6</td>
							</tr>
							<tr>
								<td rowSpan="2">젖소</td>
								<td>난소이상</td>
								<td>67.8</td>
							</tr>
							<tr>
								<td>기타이상</td>
								<td>32.2</td>
							</tr>
							<tr>
								<td rowSpan="2">육우</td>
								<td>난소이상</td>
								<td>93.0</td>
							</tr>
							<tr>
								<td>기타이상</td>
								<td>7.0</td>
							</tr>
						</table>
					</li>
					<li>번식장애 치료
						<ul>
							<li>내분비생리의 기본 이해<br/>
								<img src={Pic12} alt="번식장애"/>
							</li>
							<li>원인별 치료대책
								<ul>
									<li>난소기능불충분
										<table className="tablestyle">
											<tr>
												<td style={{width: '10%'}}>구분</td>
												<td style={{width:'5%'}}>증상</td>
												<td>원인</td>
												<td>진단(직장 촉진시)</td>
												<td>사양관리개선</td>
												<td>호르몬제 치료</td>
											</tr>
											<tr>
												<td>난소발육부진</td>
												<td>무발정</td>
												<td>사약관리불량, 영양부족</td>
												<td>크기가 엷고 작음</td>
												<td>TDN, 단백질, 비타민, 광물질 보충급여</td>
												<td>PMGS 1,000IU 근육주사 또는 LH-RH 100~20㎍ 주사</td>
											</tr>
											<tr>
												<td>난소휴지</td>
												<td>무발정</td>
												<td>비유량이 많음</td>
												<td>난소는 보통 크기로 난포, 황체 없음. 탄력이 없음</td>
												<td>-</td>
												<td>-</td>
											</tr>
											<tr>
												<td>난소위축</td>
												<td>무발정</td>
												<td>겨울, 봄사이에 많이 발생. 비유량이 많음.</td>
												<td>난소가 작고 딱딱하다.</td>
												<td>-</td>
												<td>-</td>
											</tr>
										</table>
									</li>
									<li>미약발정
										<p>미약발정은 외견상 발정증상이 매우 희미하게 나타나고 발정 지속기간도 짧은 상태이므로 무발정우로 오인될 수 있다. 이러한 소의 자궁과 난소는 별 이상이 없다.</p>
									</li>
									<li>배란지연, 무배란성 발정
										<p>외부적으로 발정징후는 뚜렷하게 나타나지만 배란이 지연되거나 배란이 결국 되지 않는 무배란성발정이 있다. 배란지연은 주로 FSH와 LH간의 불균형이 원인으로 직장검사시 발정지속 시간별로 촉지하는 것으로 확인진단이 가능하고 무배란성은 발정후 7~10일 후에 직장검사시 황체가 인정되지 않으면 무배란으로 진단한다. 불임증이 원인이 될 수 있다. <br/>치료에는 HCG 2,000~5,000IU 정매주사 또는 LH-RH 100㎍를 주사한다.</p>
									</li>
									<li>난소낭종
										<table className="tablestyle">
											<tr>
												<td>구분</td>
												<td>증상</td>
												<td>원인</td>
												<td>진단(직장촉신시)</td>
												<td>치료</td>
											</tr>
											<tr>
												<td>난포낭종</td>
												<td>사모광(약 70%) 무발정(약 30%)</td>
												<td>
													<ul style={{textAlign: 'left', marginLeft: '10px'}}>
														<li>- FSH 과잉분비</li>
														<li>- LH의 분비부족</li>
														<li>- 간기능 저하로 에스트로겐 분해 저하</li>
														<li>- 농후사료 과다급여로 과비</li>
														<li>- 운동부족</li>
													</ul>
												</td>
												<td>
													<ul style={{textAlign: 'left', marginLeft: '10px'}}>
														<li>(단발성)</li>
														<li>- 난소의 난포가 2cm 이상 비대하여 탁구공크기 정도 촉지된다.</li>
														<li>(다발성)</li>
														<li>- 정상크기의 난포가 여러개의 포도송이 모양으로 존재</li>
													</ul>
												</td>
												<td>
													<ul>
														<li>다발성은 치료 불가</li>
														<li>HCG 10,000IU정맥주사</li>
														<li>LG_RH 200㎍~300㎍ 근주</li>
														<li>간장기능 증진제 및 비타민제 병행투여</li>
													</ul>
												</td>
											</tr>
											<tr>
												<td>황체낭종</td>
												<td>무발정</td>
												<td>LH의 분비부족</td>
												<td>난포낭종과 식별이 곤란하나 황체낭종은 조직이 침착되어 딱딱함</td>
												<td>PGF2α 25~300mg 근주</td>
											</tr>
										</table>
									</li>
									<li>자궁질환
										<table className="tablestyle">
											<tr>
												<td>구분</td>
												<td>증상</td>
												<td>원인</td>
												<td>진단(직장촉진시)</td>
												<td>치료</td>
											</tr>
											<tr>
												<td>경관염</td>
												<td>농이배출</td>
												<td>
													<ul style={{textAlign: 'left' , marginLeft: '10px'}}>
														<li>- 분만시 창상</li>
														<li>- 인공수정시 무리한 주입에 따른 상처발생</li>
													</ul>
												</td>
												<td>- 자궁경이 딱딱하고 부어있다.</td>
												<td>
													<ul style={{textAlign: 'left' , marginLeft: '10px'}}>
														<li>- 페니실린 50만~100만 IU와 스트렙토마이신 50~100mg 증류수에 희석하여 주입</li>
														<li>- 타도현탁액 20ml주입</li>
													</ul>
												</td>
											</tr>
										</table>
									</li>
									<li>하계불임증
										<p>하계불임증(Summer Sterility) 은 연중 번식이 가능한 동물에서 여름철의 고온, 다습 때문에 일시적인 불임 또는 수태율 저하를 말한다.</p>
										<ul>
											<li>고온에 의한 생리반응
												<p>① <b>발정상태</b> : 고온으로 인한 호르몬 분비가 비정상적이 되어 발정지속시간이 정상보다 5~6시간 짧은 13~15시간이고 발정증상은 시원한 야간에 많이 나타나고 더위가 지속될 때는 미약발정이 많아 발정 발견이 어렵고 발견하더라도 수정적기를 판단하기 어렵다.<br/>
												② <b>고온과 수태</b> : 수정이 되었더라도 고온 스트레스에 의한 수정란이 분할 도중에 사멸하는 경우와 자궁벽에 착상을 하지 못해 수태율은 현저히 저하된다. 또한 직장온도가 39℃이상시는 수태가 전혀되지 않는다.<br/><br/>
												&lt;외부환경 온도가 초임우 및 경산우 1회 수정시 수태율에 미치는 영향&gt;
												</p>
												<img src={pic11} alt="외부환경 온도가 초임우 및 경산우 1회 수정시 수태율에 미치는 영향"/><br/>
											</li>
											<li>고온대책 : 환경개선과 사료급여개선의 2가지로 나눌수 있다.
												<p>① <b>환경개선</b> : 우사내 통풍이 원활하도록하여 우사내외에 3~4시간 간격으로 물을 뿌려 복사열을 낮추도록하며 운동장엔 차광막과 지하수를 이용한 급수조를 설치하여 항상 충분하게 음수를 할 수 있게 하도록 한다. <br/><br/>
												&lt;차광막 서치가 수태에 미치는 영향&gt;</p>
												<table className="tablestyle">
													<tr>
														<td>항목</td>
														<td>차광막 설치구</td>
														<td>대조구9비 설치구</td>
													</tr>
													<tr>
														<td>수정두수</td>
														<td>54</td>
														<td>75</td>
													</tr>
													<tr>
														<td>수태율(%)</td>
														<td>44.4</td>
														<td>25.3</td>
													</tr>
													<tr>
														<td>태아사망수</td>
														<td>0</td>
														<td>2</td>
													</tr>
												</table>
												<p>오전 11시부터는 우사내 계류시키면서 소몸에 직접 주기적으로 뿌려주면서 환풍기를 가동시켜 체온상승을 억제토록한다. 최근에는 간이살수관을 설치하여 간간히 안개부문로 우체에 직접 뿌려 송풍장치로 수분을 기회기켜 체온을 시원하게 하는 장치가 널리 보급되어 여름철 유량저하 억제와 수태율 향상 효과를 높히고 있다.<br/><br/></p>
												<p>② <b>사료급여</b> : 고온다습하에서 젖소가 스스로 환경에 적용하게 하기 위하여 필요로하는 활동과 제 1위에서 발생되는 발효열을 줄이기 위해 사료 섭취량을 줄인다. 그러므로 제 1위 기능을 최적 상태로 유지하기 위하여 섭취량 감소에 따른 영양소의 부족을 초래하므로 에너지 함량이 높은 농후사료함량을 높혀준다. 조사료는 양질의 건초를 충분히 급여하는 것이 원칙이나 현실적으로 어렵다. 특히 청초급여는 수분함량이 높아 발효과 빠른 시간에 일어나기 때문에 하루정도 예견하여 수분함량을 낮추어 급여하는 것이 바람직하다. 사료급여 하루에 4~6번 나누어 주고 한낮의 더운 때를 피하여 이른 아침과 저녁무렵 다소 시원한 시간에 급여한다.</p><br/>
											</li>
											<li><b>인공수정</b> : 수정적기 판단은 직장을 통해 자궁경관의 점액의 농도와 난소 촉지로 난포의 성숙도를 감안하여 판단한다. 수정은 적기에 하되 될 수 있는대로 아침 일찍 또는 저녁 늦게 시키고 수정한 젖소들은 다른 소들과는 다르게 각별한 관심을 가져야 한다.<br/></li>
											<li>불임우 발생의 대책
												<p>① 사양표준에 의한 사료 급여로 지나친 저영양이나 과비를 방지<br/>② 양질의 조사료 위주 사양과 농후사료를 제한 급여<br/>③ 충분한 운동과 정확한 개체관리 <br/> ④ 철저한 위생관리<br/>⑤ 광물질 및 식염 급여<br/><br/>&lt;목초, 운동 및 일광욕의 정도와 수태율과의 관계&gt;</p>
												<table className="tablestyle">
													<tr>
														<td colSpan="2" rowSpan="2">
															<p style={{float: 'right', marginRight: '15px'}}>내역</p><br/><p style={{float: 'left' , marginLeft: '15px'}}>구분</p>
														</td>
														<td colSpan="2">양호</td>
														<td colSpan="2">중등</td>
														<td colSpan="2">부족</td>
													</tr>
													<tr style={{background: '#273143' , color: '#fff'}}>
														<td>두수(두)</td>
														<td>수태율(%)</td>
														<td>두수(두)</td>
														<td>수태율(%)</td>
														<td>두수(두)</td>
														<td>수태율(%)</td>
													</tr>
													<tr>
														<td colSpan="2">수정두수</td>
														<td>70</td>
														<td></td>
														<td>31</td>
														<td></td>
														<td>13</td>
														<td></td>
													</tr>
													<tr>
														<td rowSpan="3">수정횟수</td>
														<td>1회 수정에 의한 수태</td>
														<td>60</td>
														<td>85.7</td>
														<td>8</td>
														<td>25.8</td>
														<td>3</td>
														<td>23.1</td>
													</tr>
													<tr>
														<td>2회 수정에 의한 수태</td>
														<td>8</td>
														<td>11.5</td>
														<td>8</td>
														<td>25.8</td>
														<td>3</td>
														<td>23.1</td>
													</tr>
													<tr>
														<td>3회 수정에 의한 수태</td>
														<td>1</td>
														<td>1.4</td>
														<td>13</td>
														<td>41.9</td>
														<td>5</td>
														<td>38.5</td>
													</tr>
													<tr>
														<td colSpan="2">불임</td>
														<td>1</td>
														<td>1.4</td>
														<td>2</td>
														<td>6.5</td>
														<td>2</td>
														<td>15.3</td>
													</tr>
													<tr>
														<td colSpan="2">계</td>
														<td colSpan="6">114</td>
													</tr>
												</table>
												<ul>
													<li>* 하루 목초 급여량</li>
													<li> - 양호 : (5~11월) 50kg, (12~4월) 40kg 이상</li>
													<li> - 중등 : (5~11월) 30~49kg, (12~4월) 20~39kg</li>
													<li> - 부족 : (5~11월) 29kg 이하, (12~4월) 19kg이하</li>
												</ul>
												<ul>
													<li>* 하루운동 및 일광욕 시간</li>
													<li> - 양호 : 4시간 이상</li>
													<li> - 중등 : 2~3시간</li>
													<li> - 1시간 이하</li>
												</ul>
											</li>
										</ul>
									</li>
								</ul>
							</li>
						</ul>
					</li>
				</ul>
			</div>
  );
}

export default Do2;
