import React from 'react';
import { Outlet } from 'react-router-dom';

function Semen() {
  return (
    <div className="semen-container">
      <Outlet />
    </div>
  );
}

export default Semen;
