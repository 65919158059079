import React from 'react';

import Pic456 from 'assets/images/sub/_images/pic456.jpg';
import Pic457 from 'assets/images/sub/_images/pic457.jpg';
import Pic458 from 'assets/images/sub/_images/pic458.jpg';
import Pic459 from 'assets/images/sub/_images/pic459.jpg';

function Do73() {

  return (
    <div className="techinfoview">
				<h1>제 4위 전위증</h1>
				<p className="pstyle">제 4위의 일부 또는 전부가 정상의 위치에서 벗어나 좌측 복벽과 1위의 사이에 끼어들거나(좌측전위) 또는 우측으로 이동하여 간(肝)과 우측복벽사이에 끼어들어(우측전위) 압박을 받음으로써 섭취한 사료가 하부장관으로 이동하는 양이 그히 제한되어 있는 상태를 말한다. 이런 상태로 방치되면 소화기능이 크게 감퇴되어 기아상태에 빠져 만성영양실조로 폐사하게 된다.</p>
				<div className="leftpart">
					<h2>원인</h2>
					<ul>
						<li>사양관리 부적합
							<ul>
								<li>도시근교 낙농 즉, 농후사료와 사사 위주의 사양관리하에서 빈발한다. 이러한 사양관리하에 있는 젖소는 제 1위의 발육이 나쁘고 또 제 4위가 소화장애를 일으켜 이완 또는 무력 상태가 되고 확장되어 전위되기 쉬운 상태로 된다. </li>
								<li>송아지 이유 이후의 육성기간중에 양질의 조사료 섭취가 충분치 못하여 1위 발육이 불량하였을 때. </li>
								<li>너무 잘게 분쇄(1cm 미만)된 옥수수 사일레지의 과급</li>
							</ul>
						</li>
						<li>임신·분만 
							<ul>
								<li>임신말기 팽대된 임신자궁에 의해 제 1위가 전상방(轉上方)으로 들려 올려짐으로써 확장된 4위가 이 간격을 통해 변위될 수 있다. </li>
								<li>분만후 침하된 1위에 의해 4위가 압박을 받게 되어 4위 내용물의 통과가 더욱 어려워지게 된다. </li>
							</ul>
						</li>
						<li>해부학적 요인
							<ul>
								<li>제4위의 대만부(大彎部)는 해부학적으로 그 부착상태가 허술하기 때문에, 유리된 상태에서 좌우 및 전후방향으로 이동할 수 있는 가능성이 많다. </li>
							</ul>
						</li>
						<li>급작스런 운동
							<ul>
								<li>갑자기 껑충 뛴다거나하는 순간 4위가 1위 밑에 깔릴 가능성이 있다.</li>
							</ul>
						</li>
					</ul>
					<h2>증상</h2>
					<ul>
						<li>초기에는 갑자기 식욕이 감퇴하고 1∼2일 간격으로 사료를 먹다 말다 하는 간헐적(間歇的)식욕을 보인다. </li>
						<li>농후사료는 거의 먹지 않고 사일리지보다는 청초 또는 건초를 즐겨먹는 편식벽을 보인다. </li>
						<li>소량의 배분(排糞)을 하는데 암갈색의 미끄럽고 부드러운 반죽같은 변이다. </li>
						<li>청진시 타진에 의해 금속성 소리를 들을 수 있다. </li>
						<li>분만 전후에 다발한다.</li>
					</ul>
					<h2>목장에서의 처치</h2>
					<ul>
						<li>좌측전위는 수술에 의한 성공률이 매우 높아(약 90%이상) 수의사와 상의하여 시술하는 것이 바람직하다. </li>
						<li>우측전위는 경과가 좌측보다 신속하므로 즉시 수의사와 상담하여 조치를 받는다. </li>
						<li>좌측전위의 경우 우체 회전법에 의해 치료되는 수가 있으나 재발의 우려가 있다.</li>
					</ul>
				</div>
				<div className="rightpart">
					<img src={Pic456} className="nonborder" alt=""/>
					<img src={Pic457} className="nonborder" alt=""/>
					<p>제4위 우측 전위가 일어난 소-복통때문에 등을 구부리고 있다.</p>
					<img src={Pic458} className="nonborder" alt=""/>
					<p>*제4위 저위증에 걸린 소의 분변:점액과 혈액이 섞여있다.</p>
					<img src={Pic459} className="nonborder" alt=""/>
				</div>
			</div>
  );
}

export default Do73;