import React from 'react';

function Fo51() {

  return (
    <div className="techinfo_view">
				<p className="viewTitle">구루병</p>
				<dl>
					<dt>발병대상</dt>
					<dd>어린 가축과 어린 아이</dd>
				</dl>
				
				<dl>
					<dt>원인</dt>
					<dd>칼슘과 인 중의 어느 하나 결핍 또는 그 비율의 불균형 및 비타민 D의 부족으로 야기된다. 사사의 경우는 비타민 D가 그리고 방목의 경우는 인이 부족되기 쉽다..</dd>
				</dl>
				
				<dl>
					<dt>징후 및 증상</dt>
					<dd>슬과 비절이 확대되며 움직일 때 심한 통증을 준다. 그리고 흉골과 늘골의 연결부에 불규칙한 돌출부위가 형성되며 다리가 휘어진다. 구루병은 송아지와 새끼 말, 자돈, 자양, 새끼 염소, 강아지 및 병아리 등의 어린 가축에 발생하는 질병이다.</dd>
				</dl>
				
				<dl>
					<dt>질병분포와 손실규모</dt>
					<dd>세계적으로 분포되어 있으며 이 병은 치명적이지는 않지만 상당히 건강이 쇄약해지기 쉬우며 경제적인 손실이 크다.</dd>
				</dl>
				
				<dl>
					<dt>치료 및 대책</dt>
					<dd>약화된 상태가 아니라면 적당량의 비타민 D와 칼슘 및 인을 공급해 주거나 이들 2원소의 비율을 조절하여 주므로써 치료가 가능하다. </dd>
				</dl>
				
				<dl>
					<dt>예방</dt>
					<dd>충분한 양의 비타민 D와 칼슘 및 인을 공급하여 주고 칼슘 : 인의 비율을 적절하게 맞추어 준다. </dd>
				</dl>	
				
				<dl>
					<dt>참고</dt>
					<dd>구루병은 뼈의 성장시 골화(경화)되지 못하는 증상으로 비타민 D가 부족한 사료를 급여한 산란계의 경우 산란이 중지될 때까지 점차 난각이 얇아진다.</dd>
				</dl>
			</div>
  );
}

export default Fo51;
