import React, { useState, useRef, useEffect } from 'react';

import { getState } from 'utils/api';
import { useLocation } from 'react-router-dom';

// hooks
import useWindowSize from 'hooks/useWindowSize';

// components
import PromotionalVideoDetail from 'containers/web/info/promotionalVideo/PromotionalVideoDetail';
import Pagination from 'components/board/Pagination';
import SubTitle from 'components/subTitle/SubTitle';

// 이미지
import searchIcon from 'assets/svgs/containers/performance/search_Icon.svg';

// scss
import './promotionalVideo.scss';

function PromotionalVideo() {
  // 데이터 받아오기
  const [videoInfos, setVideoInfos] = useState([]);
  const location = useLocation();
  const baseDir = location.pathname
    .toLowerCase()
    .replace('web/', '')
    .replace('/detail', '');

  // 페이지 네이션
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [props, setProps] = useState({
    page: currentPage,
  });

  useEffect(() => {
    setProps((prevProps) => ({
      ...prevProps,
      page: currentPage,
    }));
  }, [currentPage]);

  const fetchApiData = async () => {
    getState(baseDir, props)
      .then((getdata) => {
        console.log('가져왔니', getdata);
        setTotalPages(Math.ceil(getdata.count / 10));
        setVideoInfos(getdata);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchApiData();
  }, [props]);

  // 비디오 검색
  const [videoInfo, setVideoInfo] = useState([]);
  useEffect(() => {
    if (videoInfos) {
      setVideoInfo(videoInfos.results);
    }
  }, [videoInfos]);

  const videoSelectRef = useRef(null);
  const videoInputRef = useRef(null);

  const searchVideoInfo = () => {
    const input = videoInputRef.current.value;
    const select = videoSelectRef.current.value;

    if (input === '') {
      setVideoInfo(videoInfos);
      return false;
    }

    if (select === '제목') {
      setVideoInfo(() => {
        const newVideoList = videoInfos.filter((info) =>
          info.title.includes(input),
        );

        return newVideoList;
      });
    }

    return false;
  };

  const checkEnterKeyPress = (e) => {
    if (e.key === 'Enter') {
      searchVideoInfo();
    }
  };

  // 비디오 props
  const [detailVideoView, setDetailVideoView] = useState(false);
  const [detailVideoInfo, setDetailVideoInfo] = useState(null);

  return (
    <div className="promotionalVideo subCon">
      <SubTitle />
      {detailVideoView ? (
        <PromotionalVideoDetail
          detailVideoInfo={detailVideoInfo}
          setDetailVideoView={setDetailVideoView}
          setDetailVideoInfo={setDetailVideoInfo}
        />
      ) : (
        <>
          <div className="boardHeader">
            <ul className="boardTotal">
              <li>
                총 게시물 <span>{videoInfo && videoInfo.length}건</span>
              </li>
            </ul>
            <div className="boardSearch">
              <div className="searchInput">
                <select ref={videoSelectRef} defaultValue="제목">
                  <option value="제목">제목</option>
                </select>
                <input
                  ref={videoInputRef}
                  type="text"
                  onKeyDown={(e) => checkEnterKeyPress(e)}
                />
              </div>
              <button type="button" onClick={() => searchVideoInfo()}>
                <img src={searchIcon} alt="검색버튼" />
                <span>검색</span>
              </button>
            </div>
          </div>
          <ul className="youtubeBoard">
            {videoInfo &&
              videoInfo.map((info, index) => (
                <li
                  key={index}
                  onClick={() => {
                    setDetailVideoInfo(info);
                    setDetailVideoView(true);
                  }}
                >
                  <img
                    src={`https://img.youtube.com/vi/${info.thumb_url}/maxresdefault.jpg`}
                    alt="유튜브 썸네일"
                  />
                  <p>{info.bdc_title}</p>
                  <ul className="youtubeAside">
                    <li>등록일 : {info.bdc_rdt.split('T')[0]}</li>
                    <li>조회수 : {info.bdc_hit}</li>
                  </ul>
                </li>
              ))}
          </ul>
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={setCurrentPage}
          />
        </>
      )}
    </div>
  );
}

export default PromotionalVideo;
