import React from 'react';

// scss
import './genePrint.scss';

function GenePrintChart() {

  const dummy = [
    {
      허리강건성: 1.3,
      엉덩이기울기: 1.56,
      엉덩이너비: 0.33,
      유방깊이: 1.5,
      유방길: 4.49,
      정중제인대: 2.85,
      앞유방붙음성: 0.87,
      앞유두위치: 2.15,
      뒷유방높이: 1.58,
      뒷유방너비: 1.98,
      뒷유두위치: 3.33,
      유두길이: 0.54,
      발굽기울기: 0.86,
      뒤꿈치깊이: -0.02,
      뼈질: 2.38,
      옆에서본뒷다리: 1.02,
      뒤에서본뒷다리: 1.14,
      보행성: 0.37
    }
  ];

  let maxValue = 0;
  Object.keys(dummy[0]).forEach(key => {
    if (dummy[0][key] > maxValue) {
      maxValue = dummy[0][key];
    }
  });

  const percentageValues = {};
  Object.keys(dummy[0]).forEach(key => {
    percentageValues[key] = Math.round(dummy[0][key] / maxValue * 50);
  });

  return (
    <article className='genePrintChart'>
      <span>체형 세부 형질</span>

      <table className='genePrintChartTable'>
        <thead>
          <tr>
            <th>등급</th>
            <th>형질명</th>
            <th>육종가</th>
            <th>기준</th>
            <th>-3</th>
            <th>-2</th>
            <th>-1</th>
            <th>1</th>
            <th>2</th>
            <th>3</th>
            <th>기준</th>
          </tr>
        </thead>
        <tbody>
          {/* 엉덩이 */}
          <tr>
            <th rowSpan='3'>엉덩이</th>
            <td>허리강건성</td>
            <td>{dummy[0].허리강건성}</td>
            <td>약함</td>
            <td colSpan='6'>
              <span
                style={{
                  width: `${percentageValues['허리강건성']}%`,
                  [Number(dummy[0]['허리강건성']) < 0 ? 'right' : 'left']: '50%',
                  backgroundColor: Number(dummy[0]['허리강건성']) < 0 ? '#34495E' : '#4E995A'
                }}
              ></span>
            </td>
            <td>강함</td>
          </tr>
          <tr>
            <td>엉덩이기울기</td>
            <td>{dummy[0].엉덩이기울기}</td>
            <td>높음</td>
            <td colSpan='6'>
              <span
                style={{
                  width: `${percentageValues['엉덩이기울기']}%`,
                  [Number(dummy[0]['엉덩이기울기']) < 0 ? 'right' : 'left']: '50%',
                  backgroundColor: Number(dummy[0]['엉덩이기울기']) < 0 ? '#34495E' : '#4E995A'
                }}
              ></span>
            </td>
            <td>낮음</td>
          </tr>
          <tr>
            <td>엉덩이너비</td>
            <td>{dummy[0].엉덩이너비}</td>
            <td>좁음</td>
            <td colSpan='6'>
              <span
                style={{
                  width: `${percentageValues['엉덩이너비']}%`,
                  [Number(dummy[0]['엉덩이너비']) < 0 ? 'right' : 'left']: '50%',
                  backgroundColor: Number(dummy[0]['엉덩이너비']) < 0 ? '#34495E' : '#4E995A'
                }}
              ></span></td>
            <td>넓음</td>
          </tr>
          {/* 유방 */}
          <tr>
            <th rowSpan='9'>유방</th>
            <td>유방 깊이</td>
            <td>{dummy[0].유방깊이}</td>
            <td>깊음</td>
            <td colSpan='6'>
              <span
                style={{
                  width: `${percentageValues['유방깊이']}%`,
                  [Number(dummy[0]['유방깊이']) < 0 ? 'right' : 'left']: '50%',
                  backgroundColor: Number(dummy[0]['유방깊이']) < 0 ? '#34495E' : '#4E995A'
                }}
              ></span></td>
            <td>얇음</td>
          </tr>
          <tr>
            <td>유방길</td>
            <td>{dummy[0].유방길}</td>
            <td>딱딱함</td>
            <td colSpan='6'>
              <span
                style={{
                  width: `${percentageValues['유방길']}%`,
                  [Number(dummy[0]['유방길']) < 0 ? 'right' : 'left']: '50%',
                  backgroundColor: Number(dummy[0]['유방길']) < 0 ? '#34495E' : '#4E995A'
                }}
              ></span></td>
            <td>부드러움</td>
          </tr>
          <tr>
            <td>정중제인대</td>
            <td>{dummy[0].정중제인대}</td>
            <td>약함</td>
            <td colSpan='6'>
              <span
                style={{
                  width: `${percentageValues['정중제인대']}%`,
                  [Number(dummy[0]['정중제인대']) < 0 ? 'right' : 'left']: '50%',
                  backgroundColor: Number(dummy[0]['정중제인대']) < 0 ? '#34495E' : '#4E995A'
                }}
              ></span></td>
            <td>강함</td>
          </tr>
          <tr>
            <td>앞유방 붙음성</td>
            <td>{dummy[0].앞유방붙음성}</td>
            <td>약함</td>
            <td colSpan='6'>
              <span
                style={{
                  width: `${percentageValues['앞유방붙음성']}%`,
                  [Number(dummy[0]['앞유방붙음성']) < 0 ? 'right' : 'left']: '50%',
                  backgroundColor: Number(dummy[0]['앞유방붙음성']) < 0 ? '#34495E' : '#4E995A'
                }}
              ></span></td>
            <td>강함</td>
          </tr>
          <tr>
            <td>앞유두 위치</td>
            <td>{dummy[0].앞유두위치}</td>
            <td>바깥쪽</td>
            <td colSpan='6'>
              <span
                style={{
                  width: `${percentageValues['앞유두위치']}%`,
                  [Number(dummy[0]['앞유두위치']) < 0 ? 'right' : 'left']: '50%',
                  backgroundColor: Number(dummy[0]['앞유두위치']) < 0 ? '#34495E' : '#4E995A'
                }}
              ></span></td>
            <td>안쪽</td>
          </tr>
          <tr>
            <td>뒷유방 높이</td>
            <td>{dummy[0].뒷유방높이}</td>
            <td>낮음</td>
            <td colSpan='6'>
              <span
                style={{
                  width: `${percentageValues['뒷유방높이']}%`,
                  [Number(dummy[0]['뒷유방높이']) < 0 ? 'right' : 'left']: '50%',
                  backgroundColor: Number(dummy[0]['뒷유방높이']) < 0 ? '#34495E' : '#4E995A'
                }}
              ></span></td>
            <td>높음</td>
          </tr>
          <tr>
            <td>뒷유방 너비</td>
            <td>{dummy[0].뒷유방너비}</td>
            <td>좋음</td>
            <td colSpan='6'>
              <span
                style={{
                  width: `${percentageValues['뒷유방너비']}%`,
                  [Number(dummy[0]['뒷유방너비']) < 0 ? 'right' : 'left']: '50%',
                  backgroundColor: Number(dummy[0]['뒷유방너비']) < 0 ? '#34495E' : '#4E995A'
                }}
              ></span></td>
            <td>넓음</td>
          </tr>
          <tr>
            <td>뒷유두 위치</td>
            <td>{dummy[0].뒷유두위치}</td>
            <td>좋음</td>
            <td colSpan='6'>
              <span
                style={{
                  width: `${percentageValues['뒷유두위치']}%`,
                  [Number(dummy[0]['뒷유두위치']) < 0 ? 'right' : 'left']: '50%',
                  backgroundColor: Number(dummy[0]['뒷유두위치']) < 0 ? '#34495E' : '#4E995A'
                }}
              ></span></td>
            <td>안쪽</td>
          </tr>
          <tr>
            <td>유두 길이</td>
            <td>{dummy[0].유두길이}</td>
            <td>짧음</td>
            <td colSpan='6'>
              <span
                style={{
                  width: `${percentageValues['유두길이']}%`,
                  [Number(dummy[0]['유두길이']) < 0 ? 'right' : 'left']: '50%',
                  backgroundColor: Number(dummy[0]['유두길이']) < 0 ? '#34495E' : '#4E995A'
                }}
              ></span></td>
            <td>김</td>
          </tr>
          {/* 지제 */}
          <tr>
            <th rowSpan='9'>지제</th>
            <td>발굽 기울기</td>
            <td>{dummy[0].발굽기울기}</td>
            <td>낮음</td>
            <td colSpan='6'>
              <span
                style={{
                  width: `${percentageValues['발굽기울기']}%`,
                  [Number(dummy[0]['발굽기울기']) < 0 ? 'right' : 'left']: '50%',
                  backgroundColor: Number(dummy[0]['발굽기울기']) < 0 ? '#34495E' : '#4E995A'
                }}
              ></span></td>
            <td>가파름</td>
          </tr>
          <tr>
            <td>뒤꿈치깊이</td>
            <td>{dummy[0].뒤꿈치깊이}</td>
            <td>얇음</td>
            <td colSpan='6'>
              <span
                style={{
                  width: `${percentageValues['뒤꿈치깊이']}%`,
                  [Number(dummy[0]['뒤꿈치깊이']) < 0 ? 'right' : 'left']: '50%',
                  backgroundColor: Number(dummy[0]['뒤꿈치깊이']) < 0 ? '#34495E' : '#4E995A'
                }}
              ></span></td>
            <td>깊음</td>
          </tr>
          <tr>
            <td>뼈질</td>
            <td>{dummy[0].뼈질}</td>
            <td>조약함</td>
            <td colSpan='6'>
              <span
                style={{
                  width: `${percentageValues['뼈질']}%`,
                  [Number(dummy[0]['뼈질']) < 0 ? 'right' : 'left']: '50%',
                  backgroundColor: Number(dummy[0]['뼈질']) < 0 ? '#34495E' : '#4E995A'
                }}
              ></span></td>
            <td>편평함</td>
          </tr>
          <tr>
            <td>옆에서 본 뒷다리</td>
            <td>{dummy[0].옆에서본뒷다리}</td>
            <td>곧음</td>
            <td colSpan='6'>
              <span
                style={{
                  width: `${percentageValues['옆에서본뒷다리']}%`,
                  [Number(dummy[0]['옆에서본뒷다리']) < 0 ? 'right' : 'left']: '50%',
                  backgroundColor: Number(dummy[0]['옆에서본뒷다리']) < 0 ? '#34495E' : '#4E995A'
                }}
              ></span></td>
            <td>굽음</td>
          </tr>
          <tr>
            <td>뒤에서 본 뒷다리</td>
            <td>{dummy[0].뒤에서본뒷다리}</td>
            <td>모임</td>
            <td colSpan='6'>
              <span
                style={{
                  width: `${percentageValues['뒤에서본뒷다리']}%`,
                  [Number(dummy[0]['뒤에서본뒷다리']) < 0 ? 'right' : 'left']: '50%',
                  backgroundColor: Number(dummy[0]['뒤에서본뒷다리']) < 0 ? '#34495E' : '#4E995A'
                }}
              ></span></td>
            <td>곧음</td>
          </tr>
          <tr>
            <td>보행성</td>
            <td>{dummy[0].보행성}</td>
            <td>물량</td>
            <td colSpan='6'>
              <span
                style={{
                  width: `${percentageValues['보행성']}%`,
                  [Number(dummy[0]['보행성']) < 0 ? 'right' : 'left']: '50%',
                  backgroundColor: Number(dummy[0]['보행성']) < 0 ? '#34495E' : '#4E995A'
                }}
              ></span></td>
            <td>양호</td>
          </tr>
        </tbody>
      </table>
    </article>
  );
}

export default GenePrintChart;
