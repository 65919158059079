import React from 'react';

import pic440 from 'assets/images/sub/_images/pic440.gif';
import pic441 from 'assets/images/sub/_images/pic441.gif';
import pic442 from 'assets/images/sub/_images/pic442.gif';
import pic443 from 'assets/images/sub/_images/pic443.gif';

function Do224() {

  return (
    <div className="techinfoview">
      <h1>사료급여 및 저장시설-완전배합사료(TMR)의 급여</h1>
      <p className="pstyle">조사료, 농후사료 및 보충사료를 모두 배합한 상태인 TMR의 이용은 점점 보편화되어 가는 추세이다. TMR은 우군을 분리하고 각 우군의 영양소 요구량을 충족시킬 수 있도록 급여하여야 하는데, 이를테면 산유량 수준에 따라 우군을 분리하여 각기 다른 영양소 함량을 갖는 TMR을 급여하여야 한다. 초기자본이 많이 투자되고 우군분리의 어려움이 있지만, TMR급여체계는 다음과 같은 장점이 있다. </p>
      <ul>
        <li>영양소를 균형있게 공급할 수 있다. </li>
        <li>소화장애를 감소시킨다. </li>
        <li>기호성이 낮은 저질조사료의 섭취를 증가시킨다. </li>
        <li>분쇄한 것이면 건초도 포함시킬 수 있다. </li>
      </ul>
      <p className="pstyle">저울장치가 있는 고정식 또는 이동식 배합기를 이용하여 TMR을 준비할 수 있는데, 저울을 설치함으로서 각 원료사료의 필요량을 정확하게 측정할 수 있으며, 사료재고량 파악을 가능케 한다. </p>
      <ul>
        <li>이동식 급여체계 
          <ul>
            <li>이동식 TMR체계는 저울/배합기가 장치된 트레일러나 트럭을 이용하는 방법으로 원료사료를 배합한 후 트레일러나 트럭을 사료조로 이동시켜 사료를 급여한다(그림113). 사료저장소나 사료급여 장소가 각기 다른 곳에 위치해도 구애받지 않는다는 것이 이동식 체계의 장점으로 고정식 보다 융통성이 있다. 이동식 체계는 100두 이상 사육하는 목장에서 시간과 경비를 절감시킬 수 있는 TMR 급여방법이다.</li>						
          </ul>
          <img src={pic440} width="60%" alt=""/>
          <p>그림 113. 이동식 TMR 급여체계의 예</p>
        </li>
        <li>고정식 급여체계 
          <ul>
            <li>고정식 TMR체계의 경우, 배합기가 특정한 장소에 고정되어 있으므로 모든 원료사료는 배합기로 운반되어야 한다. 배합 후, 컨베이어나 손수레를 이용하여 TMR을 각 사료급여 장소로 운반한다(그림 114). 이 체계는 직립 사일로와 기계식 운반장치가 있는 작은 규모의 목장(90두 이하)에 적합한 TMR 급여방법이다(그림 115). <br/>
              <img src={pic441} width="60%" alt=""/>
              <p>그림 114. 고정식 TMR 급여체계의 예</p>
              <img src={pic442} width="50%" alt=""/>
              <p>그림 115. 고정식 TMR 급여체계의 예</p>	
              <ul>
                <li>일괄배합(batch mixing) 
                  <ul>
                    <li>조사료와 농후사료의 각 원료사료를 정량대로 하나씩 배합기에 넣은 후, 잘 섞는 것이 일괄배합이다. 배합기의 크기는 급여군의 두수와 급여횟수에 따라 표 97과 같이 달라진다. 배합기의 가동용량은 실제 배합기의 전체부피로 나타내는데, 배합기는 제조회사에 따라 가동용량이 60%∼70%인 배합기를 구입하면 무난하다. 용량 이상을 배합하여 배합기에 무리를 주면 사료의 배합에 소요되는 시간이 길어지고, 어거배합기의 경우에는 사료가 밖으로 넘쳐 나온다. 텀블배합기(tumble mixer)의 경우, 원료사료가 뒤섞일 수 있는 여유 공간이 필요하다.  운반장치에 따라 배합기에 사료를 채우고 배출하는데 소요되는 시간이 달라진다. 배합기에 원료사료를 넣는 순서는 배합기의 종류에 따라 달라지기 때문에, 배합기 제조회사의 권장사항을 따르도록 한다. 직립 사일로의 경우, 부피가 큰 원료사료의 무게를 미리 측정할 수 있는 분리된 축적박스(accumulator box)가 설치되어 있으면, 다음 일괄배합에 소요되는 시간을 단축할 수 있다. 공간이 허락한다면, 활송장치 주변에 사일리지를 쌓아 놓을 수도 있다.  배합되는 양이 적은 원료사료들은 미리 섞어서 배합하며, TMR은 급여하기 전에 완전히 배합되어야 한다. 일반적으로 사료배합에 소요되는 시간은 3∼6분인데, 제조회사의 권장시간에 따라 최소 배합시간을 결정한다. 건초를 TMR에 포함시키는 일은 쉽지 않다. 배합기의 건초 배합능력에 따라 건초의 배합용량이 달라지는데, 일반적으로 최대 건초 배합량은 배합기 무게용량의 약 10% 정도이다. 
                      <p><b>&lt;표 97&gt; 배합기의 크기a </b></p>
                      <table className="tablestyle">
                        <tr>
                          <td rowSpan="2">사료급여횟수/일</td>
                          <td colSpan="5">사육규모</td>
                        </tr>
                        <tr style={{background: '#273143',color: '#fff'}}>
                          <td>20</td>
                          <td>40</td>
                          <td>60</td>
                          <td>80</td>
                          <td>100</td>
                        </tr>
                        <tr>
                          <td colSpan="6">배합기의 크기, ft3</td>
                        </tr>
                        <tr>
                          <td>1</td>
                          <td>100</td>
                          <td>200</td>
                          <td>300</td>
                          <td>400</td>
                          <td>500</td>
                        </tr>
                        <tr>
                          <td>2</td>
                          <td>50</td>
                          <td>100</td>
                          <td>150</td>
                          <td>200</td>
                          <td>250</td>
                        </tr>
                        <tr>
                          <td>3</td>
                          <td>33</td>
                          <td>67</td>
                          <td>100</td>
                          <td>133</td>
                          <td>167</td>
                        </tr>
                      </table>
                      <p>a 일일 두당 22.5㎏ 건물을 섭취한다고 가정, 수분함량 50%의 사료 9㎏/ft3 .<br/>일일 두당 약 5.0ft3소요. </p>
                      <ul>
                        <li>* TMR배합기 
                          <p>여러 가지 배합기 중에서, 어거(auger), 릴(reel), 텀블(tumble) 및 리본(ribbon)형 배합기가 가장 널리 사용되는 형태이다(그림 116). TMR사료를 올바르게 제조하기 위해서는 제조회사에서 권장하는 사료의 배합량과 시간을 준수하여야 한다.</p>
                          <img src={pic443} className="nonborder" width="60%" alt=""/>
                          <p>그림 116. TMR 배합기의 종류</p>
                        </li>
                        <li>* 저울 
                          <p>배합되는 사료의 무게를 정확하게 측정하기 위해서는 저울을 배합기에 설치하여야 한다. 디지털식과 기계식 저울은 각각 0.25%와 1%까지 정확하고, 유동식 저울은 간단하지만 정확도는 떨어진다. 먼지나 물이 들어가지 않도록 저울을 관리하여야 하며 저울에 먼지나 사료가 쌓여 고장이 나지 않도록 바닥으로부터 6&quot;∼12&quot;정도 높이에 저울을 설치한다. 비타민, 소금 등과 같이 배합량이 적은 첨가제는 별도의 스프링 저울을 이용하여 무게를 측정한 후, 배합기에 넣는다. </p>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li>연속배합(continuous mixing) 
                  <ul>
                    <li>연속배합의 경우, 사료 컨베이어에서 각 원료사료를 계량하며, 일반적으로 in-line 무게측정장치로 배합되는 사일리지의 양을 측정한다. 사일리지의 유입율에 맞추어 농후사료 및 보충사료의 유입율을 조절한다. 만약 사일리지의 유입량이 부피로 측정되는 경우에는, 부피가 크고 수분이 많은 사료는 균일하게 흐르지 않기 때문에, 유입율을 자주 점검하여야 한다. 조사료나 곡류의 부피 측정에 축적박스를 이용하면 배합을 빠르게 진행시킬 수 있다.</li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
}

export default Do224;