import React from 'react';

function Do177() {

  return (
    <div className="techinfoview">
      <h1>초지의 분뇨사용</h1>
      <h2>가축분뇨의 비료가치</h2>
      <p className="pstyle">가축분뇨는 질소. 인산, 칼리등 비료의 3대요소 이외에도 식물생자에 필요한 마그네슘, 칼슘 등의 영양소와 구리, 나트리움, 망간, 아연, 붕소, 모리부덴 등의 미량요소들을 다양하게 함유하고 있으며 특히 유기물 함량이 많아 비료가치가 높다. 가축분뇨의 비료성분 함량은 가축의 종류, 계절, 사료의 종류 등에 따라 차이가 있으며 특히 분뇨의 희석배수에 따라 큰 차이가 있다.</p><br/>
      <p><b>&lt;표 13&gt; 가축의 종류별 비료성분</b></p>
      <table className="tablestyle">
        <tr>
          <td rowSpan="2">가축의 종류</td>
          <td rowSpan="2">건물함량</td>
          <td colSpan="5">비료성분함량(kg/㎡)</td>
        </tr>
        <tr style={{background: '#273143',color:'#fff'}}>
          <td>질소</td>
          <td>인산</td>
          <td>칼리</td>
          <td>마그네슘</td>
          <td>칼슘</td>
        </tr>
        <tr>
          <td rowSpan="3">젖소(목초위주 사양)</td>
          <td>10.0%</td>
          <td>5.3</td>
          <td>2.0</td>
          <td>8.0</td>
          <td>1.1</td>
          <td>2.7</td>
        </tr>
        <tr>
          <td>7.5</td>
          <td>4.0</td>
          <td>1.5</td>
          <td>6.0</td>
          <td>0.8</td>
          <td>2.0</td>
        </tr>
        <tr>
          <td>5.0</td>
          <td>2.7</td>
          <td>1.0</td>
          <td>4.0</td>
          <td>0.5</td>
          <td>1.3</td>
        </tr>
        <tr>
          <td rowSpan="3">비육우</td>
          <td>10.0</td>
          <td>6.0</td>
          <td>2.0</td>
          <td>4.7</td>
          <td>1.1</td>
          <td>1.7</td>
        </tr>
        <tr>
          <td>7.5</td>
          <td>4.5</td>
          <td>1.5</td>
          <td>3.5</td>
          <td>0.8</td>
          <td>1.3</td>
        </tr>
        <tr>
          <td>5.0</td>
          <td>3.0</td>
          <td>1.0</td>
          <td>2.3</td>
          <td>0.5</td>
          <td>0.9</td>
        </tr>
      </table><br/>
      <p className="pstyle">가축의 종류에 따른 분뇨의 질소, 인산, 칼리, 비료성분 비율을 상대적으로 비교해 보면 젖소의 분뇨에는 칼리함량이 높고 비육우의 분뇨는 인산함량이 젖소보다 많다. 따라서 젖소의 분뇨를 초지에 시용할 경우에는 ha당 칼리 요구량에 맞추애 연간 사용량을 계산하는 것이 좋은데 그 이유는 질소나 인산에 맞추어 시용했을 경우 칼리비료의 과잉시비로 목초의 나트리움, 마그네슘, 칼슘 등의 흡수가 감소되어 영양생리상 오히려 역효과가 나게될 염려가 있기 &#39105;문이다. 반면에 칼리비료성분을 기준으로 정보당 가축분뇨 시용량을 맞추게 되면 질소의 인산성분이 부족하게 되므로 양만큼 금비로 보충해 주어야 한다. 여기서 특히 주의해야 할 점은 분뇨중의 3요소 비료성분은 각기 이용효율이 다르기 때문에 금비의 보충량을 계산할 때 효율을 고려해야 한다.</p>
      <ul>
        <li>질소
          <p>&nbsp;&nbsp;&nbsp;소의 분뇨에 함유된 질소성분은 약50%가 식물체에 직접 흡수 이용되기 쉬운 암모니아태 질소이며 나머지 50%는 유기뮬에 결합된 질소로서 몇해에 걸쳐 서서히 효력을 나타낸다. 또한 암모니아태 질소는 식물체가 즉시 이용할 수 있는 장접은 있으나 반면에 기체로 되어 공중으로 날아사 버리는 손실량이 많게 된다.<br/>&nbsp;&nbsp;&nbsp;분뇨의 질소성분은 이용효율이 약 30∼70%로서 계절에 따라 큰차이가 있는데 2월∼3월에는 30∼60%, 4월∼6월에는 40∼70%에 달하나, 9월∼10월에는 30∼40% 밖에 이용되지 않는다.</p>
        </li>
        <li>인산
          <p>&nbsp;&nbsp;&nbsp;소의 분뇨에는 인산성분이 적게 들어 있으나 약 80%가 수용성 무기인산으로서 금비와 같이 쉽게 이용되며 나머지 20%는 유기뮬과 결합된 피틴태 인산으로서 토양미생물의 작용에 의한 몇 년간에 걸쳐 서서히 식물체가 이용할 수 있는 형태로 전환된다. 인산성분은 토양중에서 이동이 적어 손실이 거의 안되기 때문에 가축분뇨를 수년간 계속 사용하면 인산비료를 상당히 절약할 수 있다.</p>
        </li>
        <li>칼리
          <p>&nbsp;&nbsp;&nbsp;가축분뇨주의 칼리성분은 80∼90%가 수용성으로 식물체가 즉시 이용할 수 있는 반면 물에 녹아 땅속으로 스며들거나 흘러나가 손실되기 쉽다.<br/>&nbsp;&nbsp;&nbsp;식물체는 칼리비료를 자기가 필요한 양만큼만 흡수하는 것이 아니라 필요이상으로 과잉흡수하는 특상이 있어 소위 사치성소비를 하게되어 비료의 낭비를 초래할 뿐만 아니라 상대적으로 다른 영양성분과의 불균형으로 목초의 물질과 가축의 영양생리면에서 오히려 역효과를 보게 된다. 요컨대 가축분뇨는 칼리함량이 높으므로 분뇨를 충분히 시용할 경우에는 칼리비료를 금비로 보충해줄 필요가 없다.</p>
        </li>
      </ul>
      <h2>가축의 분뇨배설량</h2>
      <p className="pstyle">&nbsp;&nbsp;&nbsp;소의 하루 분뇨배설랴은 체중의 8∼9%이며 똥이 약 25kg, 오줌이 15kg으로 합해서 40kg 정도이다.<br/>&nbsp;&nbsp;&nbsp;분뇨의 수분함량은 89∼90%로서 분뇨탱크에서 펌프로 분뇨를 뽑아 올리고 분무살포하기 위해서는 분뇨의 수분함량이 최소한 88∼92%이상되어야 한다. 분뇨탱크에 수거되는 분뇨의 양은 외양간청소에 사용된 물이 포함되므로 하루의 수거량은 분뇨혼합물에 섞인물의 함량에 따라 달라지게 된다.&nbsp;&nbsp;&nbsp;또한 연간 분뇨수거량은 연간 방목기간 및 일당 방목시간에 따라 크게 차이가 나고 빗물의 혼합, 깔집, 사료찌꺼기 등의 혼합등을 고려하면 수거량이 더 늘어나게 된다.</p><br/>
      <p><b>&lt;표 14&gt; 대가축 단위당 분뇨생산량</b></p>
      <table className="tablestyle">
        <tr>
          <td>가축별</td>
          <td>농도</td>
          <td>수분함량%</td>
          <td>분뇨혼합물생산량<br/>(kg/1일/두)</td>
          <td>연간생산량<br/></td>
        </tr>
        <tr>
          <td rowSpan="3">젖소</td>
          <td>진함</td>
          <td>90.0</td>
          <td>46</td>
          <td>17</td>
        </tr>
        <tr>
          <td>보통</td>
          <td>92.5</td>
          <td>61</td>
          <td>22</td>
        </tr>
        <tr>
          <td>묽음</td>
          <td>95.0</td>
          <td>93</td>
          <td>34</td>
        </tr>
        <tr>
          <td rowSpan="3">비육우</td>
          <td>진함</td>
          <td>90.0</td>
          <td>33</td>
          <td>12</td>
        </tr>
        <tr>
          <td>보통</td>
          <td>92.5</td>
          <td>44</td>
          <td>16</td>
        </tr>
        <tr>
          <td>묽음</td>
          <td>95.0</td>
          <td>66</td>
          <td>24</td>
        </tr>
      </table>
      <h2>가축분뇨의 비료 환산</h2>
      <p className="pstyle">&nbsp;&nbsp;&nbsp;가축분뇨를 전량 초지에 환원할 때 알맞은 초지면저과 가축두수와의 비율은 성우 2∼3두이다. 만약 초지면적은 협소한데 가축두수가 많으면 우선 과방목에 의한 초지의 부실화가 문제가 되며 다음은 생산성이 낮아진 초지에 과도한 분뇨시용으로 환경오염의 원인이 되기 쉽다. 따라서 정보당 알맞은 가축두수를 사육한다는 것은 초지의 유지면에서나 분뇨의 효율적인 이용면에서 매우 중요하다. </p><br/>
      <p><b>&lt;표 15&gt; 정보당 젖소 사육두수에 따른 가축분뇨 비료성분의 환원량</b></p>
      <table className="tablestyle">
        <tr>
          <td rowSpan="2">사육두수</td>
          <td rowSpan="2">분뇨량</td>
          <td colSpan="4">비료성분환원량(kg/ha)</td>
        </tr>
        <tr style={{background: '#273143',color:'#fff'}}>
          <td>질소총량</td>
          <td>암모니아태질소</td>
          <td>인산</td>
          <td>칼리</td>
        </tr>
        <tr>
          <td>0.5</td>
          <td>11㎥/ha</td>
          <td>44</td>
          <td>22</td>
          <td>17</td>
          <td>66</td>
        </tr>
        <tr>
          <td>1.0</td>
          <td>22</td>
          <td>88</td>
          <td>44</td>
          <td>33</td>
          <td>132</td>
        </tr>
        <tr>
          <td>1.5</td>
          <td>33</td>
          <td>132</td>
          <td>66</td>
          <td>50</td>
          <td>198</td>
        </tr>
        <tr>
          <td>2.0</td>
          <td>44</td>
          <td>176</td>
          <td>88</td>
          <td>66</td>
          <td>264</td>
        </tr>
        <tr>
          <td>2.5</td>
          <td>55</td>
          <td>220</td>
          <td>110</td>
          <td>83</td>
          <td>330</td>
        </tr>
        <tr>
          <td>3.0</td>
          <td>66</td>
          <td>264</td>
          <td>132</td>
          <td>99</td>
          <td>396</td>
        </tr>
      </table>
      <p>(가)축분뇨의 건물함량 7.5% 기준임) </p>
      <h2>가축분뇨의 사용요령</h2>
      <p className="pstyle">&nbsp;&nbsp;&nbsp;가축분뇨의 사용량은 방목지의 경우 정보당 한번 시용시 최고 15∼20㎥으로 제한헤서 매 이용직후 시용하는 것이 좋으며 연간 총 시용량은 50㎥을 초과하지 않는 것이 좋다.<br/><br/>그 이유는 위에서 이미 설명한 바와 같이 칼리비료의 지나친 공급이 문제가 되기 때문이다. 가축분뇨의 시용시 주의할 점은 다음과 같다.</p>
      <ul>
        <li>반드시 목초의 생육기간중에 살포해야 하며 특히 봄철시용이 효과적이다. 겨울철&#46145;[는 가축분뇨를 살포하면 초지에도 해롭고 환격공해에도 문제가 되므로 피하도록 한다.</li>
        <li>전년적에 고루 살포되도록 한다.</li>
        <li>질소질비료의 부족분을 금비로 보충해 준다.</li>
        <li>최소한 4∼5년마다 초지토양의 토양점검을 실시해서 (농촌지도소에 문의) 알맞은 시비량을 결정한다.</li>
        <li>하천에서 최소한 5∼10m의 간격을 두고 살포한다.</li>
        <li>살포작업은 가급적 바람이 없는날 한다.</li>
        <li>가급적 묽은 분뇨를 살포하는 것이 좋은대 너무 진한 분뇨는 초지에 피막이 형성되어 목초의 생육에 지장을 줄 수도 있다.</li>
        <li>잘 부숙된 분뇨를 살포한다.</li>
        <li>여름철 무더위에는 살포하지 않는 것이 좋다.</li>
        <li>경사지에는 시영량을 적게 한다.</li>
        <li>사질토양에는 시용량을 적게 한다. </li>
      </ul>
    </div>
  );
}

export default Do177;