import React from 'react';

import pic352 from 'assets/images/sub/_images/pic352.gif';

function Do201() {

  return (
    <div className="techinfoview">
      <h1>목장시설</h1>
      <p className="pstyle">&nbsp;&nbsp;&nbsp;우사를 개축하거나 신축할 때는 먼저 면밀한 낙농경영계획을 수립하는 것이 가장 중요하다. 경영계획을 수립할 때는 소의 영양, 성장 및 건강과 농장의 활용도를 염두에 두어야 한다. 경영계획을 세우는 목적은 산유량을 증가시키고, 송아지의 폐사율을 줄이고, 소의 유전능력을 향상시키며, 신선한 물과 양질의 사료를 공급하고, 소의 건강을 향상시키는데 있다. 새로운 목장시설을 설계할때는 노동력 요구량, 가축과 자재의 이동,환경오염, 장래확장계획, 관리지침 및 가축에게 미치는 환경을 고려해야 한다. 우사에 수용되는 가축이 요구하는 조건을 충족시킬 수 있도록 환경을 조성하는 것은 필수적이라 하겠다. 예켠대, 송아지에게는 성장할 수 있게 하고, 처녀소에게는 성숙할 수 있게 하며, 경산우에게는 번식과 건강을 유지할수 있게 하는 환경을 조성하는 일이다. 가축을 분리하여 구룹화하고 가 우군에 알맞은 시설물을 선정하는 것은 우사설계에 있어서 중요하다. 그러나, 우군관리와 가축이 필요로하는 환경에 대한 개념을 이해하는 것이 더욱 중요하다. </p>
      <h2>우군분리</h2>
      <p className="pstyle">&nbsp;&nbsp;&nbsp;각 우군의 특성에 알맞는 관리를 위한 시설물을 갖추어야 한다. 따라서 관리 계획을 수립할 때, 각 우군의 영양소 요구수준, 질병치로, 번식 등을 염두에 두어야 한다. 한 우사에도 칸막이를 설치하면 1개 이상의 우군을 수용시킬 수 있으나, 물론 관리는 우군별로 하여야 한다. 대규모 목장에서는 각 우군별로 별도의 우사를 마련할 수 있다. 사육규모에 따른 전형적인 우군구성이 표 73에 나타나 있다. 우사를 설계할 때 각 그룹에 필요한 우사의 시설 및 규모를 결정하는데 참고로 이용할 수 있다. 표 73은 모든 소가 12개월에 1회 분만하고, 초산은 24개월만에 이루어지며, 모든 수소는 분만시 판매하고, 도태율이 30% 이며, 폐사율이 0%라는 가정하에 작성되었다.</p><br/>
      <p><b>&lt;표 73&gt; 사육규모에 따른 전형적인 우군 구성 </b></p>
      <table className="tablestyle16">
        <tr>
          <td>사육규모(전체 두수)</td>
          <td>75</td>
          <td>100</td>
          <td>250</td>
          <td>400</td>
        </tr>
        <tr>
          <td>송아지 및 처녀소 </td>
          <td>75</td>
          <td>100</td>
          <td>250</td>
          <td>400</td>
        </tr>
        <tr>
          <td>0-2 개월, 70Kg  </td>
          <td>6</td>
          <td>8</td>
          <td>20</td>
          <td>32</td>
        </tr>
        <tr>
          <td>3-5 개월, 115Kg</td>
          <td>9</td>
          <td>12</td>
          <td>30</td>
          <td>48</td>
        </tr>
        <tr>
          <td> 6-8 개월, 180Kg </td>
          <td>9</td>
          <td>12</td>
          <td>30</td>
          <td>48</td>
        </tr>
        <tr>
          <td>9-12 개월, 270Kg</td>
          <td>14</td>
          <td>18</td>
          <td>45</td>
          <td>72</td>
        </tr>
        <tr>
          <td>13-15 개월, 360Kg  </td>
          <td>9</td>
          <td>12</td>
          <td>30</td>
          <td>48</td>
        </tr>
        <tr>
          <td>16-24 개월, 450Kg</td>
          <td>29</td>
          <td>38</td>
          <td>95</td>
          <td>152</td>
        </tr>
        <tr>
          <td>건유우</td>
          <td>13</td>
          <td>17</td>
          <td>43</td>
          <td>68</td>
        </tr>
        <tr>
          <td>교체기 (첫 4-14일) </td>
          <td>0-4</td>
          <td>1-5</td>
          <td>4-9</td>
          <td>5-16</td>
        </tr>
        <tr>
          <td>40일째 (2그룹으로 분리)</td>
          <td>8-9</td>
          <td>11-12</td>
          <td>28-30</td>
          <td>45-48</td>
        </tr>
        <tr>
          <td>분만전 2-3주</td>
          <td>2-5</td>
          <td>3-6</td>
          <td>8-15</td>
          <td>16-24</td>
        </tr>
        <tr>
          <td>분만 (개별 우방 마련)</td>
          <td>3-5</td>
          <td>4-6</td>
          <td>10-16</td>
          <td>16-24</td>
        </tr>
        <tr>
          <td>분만 직후(분만후 0-7일)</td>
          <td>1-3</td>
          <td>1-4</td>
          <td>3-10</td>
          <td>4-12</td>
        </tr>
        <tr>
          <td>2년생(305일)</td>
          <td>18-24</td>
          <td>26-30</td>
          <td>65-75</td>
          <td>104-120</td>
        </tr>
        <tr>
          <td>3년생 이상 (305일) </td>
          <td>44</td>
          <td>58</td>
          <td>145</td>
          <td>232</td>
        </tr>
        <tr>
          <td>고능력우 </td>
          <td>15-18</td>
          <td>20-24</td>
          <td>50-60</td>
          <td>80-96</td>
        </tr>
        <tr>
          <td>중능력우</td>
          <td>12-15</td>
          <td>16-2</td>
          <td>40-50</td>
          <td>60-70</td>
        </tr>
        <tr>
          <td>저능력우</td>
          <td>12-15</td>
          <td>16-20</td>
          <td>40-50</td>
          <td>60-70</td>
        </tr>
        <tr>
          <td>환 축</td>
          <td>0-4</td>
          <td>0-5</td>
          <td>0-13</td>
          <td>0-20</td>
        </tr>
      </table>
      <h2>분뇨처리</h2>
      <p className="pstyle">&nbsp;&nbsp;&nbsp;분뇨처리체계는 우사설계에 영향을 미칠 뿐만 아니라 분뇨를 비료로 이용하기 때문에 사료작물의 수확계획 등 전체 농장운영에 영향을 미친다. 따라서, 분뇨관리는 단순히 분뇨를 우사 밖으로 치우는데 있는 것이 아니라 오히려 전체생산체계의 한 부분이다. 분뇨처리 시설을 개선함으로서 다음과 같은 장점이 있다. 첫째, 손쉽게 분뇨를 처리할 수 있다. 둘째, 사료작물 생산에 분뇨를 효과적으로 이용한다. 셋째, 분뇨에 의한 환경오염을 감소시킨다. <br/>&nbsp;&nbsp;&nbsp;여건에 따라서 여러 가지 형태의 분뇨처리 시설을 이용할 수 있지만, 환경에 대한 사회적 관심이 점차 높아지기 때문에 장기적으로 분뇨를 저장할 수 있는 시설의 설치가 바람직하다고 하겠다. </p>
      <h2>목장부지 선정</h2>
      <p className="pstyle">&nbsp;&nbsp;&nbsp;목장부지를 선정할 때에는, 우사가 차지하는 면적 및 우사간의 공간 등을 비롯한 여러 가지 요소를 감안하여야 한다. 우사간의 거리는 최소한 40&apos;이어야 하며, 눈을 치울 수 있도록 50&apos;를, 소방용으로 75&apos;를 확보하여야 한다. 환기를 위한 공간의 확보는 축사환경 부분을 참고하면 된다. 또한, 장래에 확장할 것을 염두에 두고 부지를 확보하여야 하는데, 목장의 규모가 2배 정도로 확장될 것을 생각하고 계획을 세우면 무난하다. 차량이 통과하고 주차시킬 수 있는 공간이 있어야 하며, 사료보관 및 가공을 위한 공간을 확보하여야 하며, 주거지역과는 적절한 거리가 유지되도록 하여야 한다. </p>
      <ul>
        <li>배수 
          <ul>
            <li>차량이 소통되는 지역으로부터 멀리 떨어진 곳으로 배수가 되도록 하고, 배수로의 경사는 2-5% (1/4-5/8/ft)로 한다. </li>
          </ul>
        </li>
        <li>바람과 눈 막이 
          <ul>
            <li>바람막이는 겨울에 찬바람과 눈을 막아주는 역할을 한다. 여름철의 환기에 방해가 되지않는 범위 내에서, 나무, 건물, 언덕, 건초더미 등을 바람막이로 이용할 수 있다. 냄새, 눈/비, 곤충, 소음, 먼지 등을 줄이기 위해서는 항상 주로 바람이 부는 방향을 염두에 두고 바람막이를 설치해야 한다. </li>
          </ul>
        </li>
        <li>급수 
          <ul>
            <li>물의 연중 공급은 가축사육과 위생에 필수적인 요소이며, 소방과 오폐물을 희석시키는데 이용된다. 소는 산유량에 따라서 두당 일일 132.5-170.3ℓ의 물을 섭취하는데, 사료섭취 직후에 물을 가장 많이 섭취한다. 따라서 음수량은 1일 총음수량과 순가 최대음수량을 고려하여 급수체계를 마련하여야 한다. 지하수 공급이 어려울 경우에는 목장 주변의 연못이나 수돗물을 이용할 수 있으며 가축용 음수로서 충분한지는 전문기관에 먼저 의뢰하는 것이 안전하다.
              <img src={pic352} width="50%" alt=""/>
              <p>그림 23. 목장시설의 위치와 주도로와의 관계</p>
            </li>
          </ul>
        </li>
        <li>도로 
          <ul>
            <li>우유수송, 보수공사, 사료수송, 질병치료, 방문객 등의 차량을 위한 도로가 기후조건에 관계없이 소통될 수 있도록 마련하여야 한다. 최소한의 도로폭은 12&apos;이며, 우유 및 사료 수송차량이 회전하는 지역의 최소폭은 55&apos;이다. </li>
          </ul>
        </li>
        <li>분뇨저장 및 처리 
          <ul>
            <li>분뇨를 살포할 수 있는 충분한 경지를 마련하여야 한다. 토지의 경사가 급하여 분뇨에 의한 수질오염이 발생하지 않도록 하여야 하며, 이웃 주거지나 공공시설에 인접해 있는 부지에 분뇨시설의 설치는 피해야 한다. </li>
          </ul>
        </li>
        <li>사료저장 . 취급시설 
          <ul>
            <li>사료저장, 취급시설은 목장부지 선정에 한 고려요인이 될 수 있지만, 절대적인 영향을 미치지 않는다. 수송차량을 이용하면 사료운반을 좀 멀리 떨어져 있어도 용이하게 할 수 있다. </li>
          </ul>
        </li>
        <li>전기
          <ul>
            <li>전기는 난방, 조명, 각종 기계의 작동 등에 이용될 수 있도록 입력전력은 220V/380V이어야 한다. 정전시를 대비하여 비상발전 시설을 갖추어야 한다. </li>
          </ul>
        </li>
        <li>안전 
          <ul>
            <li>질병전파와 농장일에 방해가 되지 않도록 외부인의 출입을 최소화시켜야 한다. 농장내에 주거할 경우에는 농장진입도로를 주거지에 가깝게 위치하도록 한다. 농장이 주거지로부터 멀리 떨어져 있을 경우에 대체로 문제가 많이 발생하므로, 이런 경우에는 농장의 도로시설을 하나로 제한하며 무단침입자의 행동반경을 줄일 수 있다. 가능하면 농장도로는 멀리서도 보일 수 있도록 설계하는 것도 안전을 꾀하는 한 방법이다. </li>
          </ul>
        </li>
      </ul>
      <h2>개축 </h2>
      <p className="pstyle">농장을 확장할 때는 기존시설을 폐기할 것인가 개축할 것인가를 1)목장의 시설계획과의 적합성 2)기존시설의 견고성과 위치 3)개축과 신축 사이의 소요경비 차이 등을 고려하여 결정한다. 개축이 반드시 경비를 절감시키는 방법은 아니다. 만약,개축경비가 신축경비의 1/2-2/3가 소요된다면 신축하는 것이 바람직하다. 또한 신축시에 기존시설의 부품을 이용하면 경비를 절감시킬 수있다. </p>
      <h2>설계도,세부명세 및 공사계약 </h2>
      <p className="pstyle">건축에 관한 모든 자세한 사항의 문서화는 목장주와 건설업자 사이의 의사소통과 이해를 돕는다. 설계도는 건축에 필요한 모든 시설물에 대한 크기와 모양을 나타낸다. 세부명세서는 건축에 필요한 자재의 질과 크기가 자세하게 설명되어 있고, 종종 건축과정에 대한 개괄적인 설명도 포함된다. 공사계약서에는 공사비, 대금지불계획, 보장, 책임, 시공일자, 준공일자 등이 포함된다.  </p>
      <h2>장기계획 </h2>
      <p className="pstyle">목장시설을 개축하거나 신축하는 것은 경비가 많이 소요될 뿐만 아니라, 시설이 미치는 영향은 매우 크다. 또한, 잘못된 시설은 목장의 관리를 매우 어렵게 만들 수 있다. 목장을 확장하면, 1)사육두수가 증가되고, 2) 노동력이 더 많이 필요하며, 3)관리체계를 바꿔야 한다는 것을 염두에 두어야 한다.<br/>새로운 시설을 계획할 때는 1)향후 5-10년 동안의 생산목표, 2)관리목표, 3)가축의 환경, 4)장래에 다른 용도로 사용할 수 있는 가능성 등과 같은 장기적인 면을 고려하여야 한다.</p>
    </div>
  );
}

export default Do201;