import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import axios from 'axios';

import App from './App';
import reportWebVitals from './reportWebVitals';
import ErrorBoundary from './components/error/ErrorBoundary';
import setupAxiosInterceptors from './utils/axiosSetup';

import './styles/globals.scss';
import './styles/fonts.scss';
import './styles/color.scss';
import './styles/levels.scss';
import './styles/sub.scss';

function getCookie(Name) {
  const search = `${Name}=`;
  if (document.cookie.length > 0) {
    // 쿠키가 설정되어 있다면

    let offset = document.cookie.indexOf(search);

    if (offset !== -1) {
      // 쿠키가 존재하면

      offset += search.length;
      let end = document.cookie.indexOf(';', offset);

      // 쿠키 값의 마지막 위치 인덱스 번호 설정
      if (end === -1) end = document.cookie.length;

      // return unescape(document.cookie.substring(offset, end));
      return decodeURI(document.cookie.substring(offset, end));
    }
  }
  return true;
}

axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';
axios.defaults.headers.common['X-CSRFToken'] = getCookie('csrftoken');

// axios 인터셉터 설정
setupAxiosInterceptors();

const root = ReactDOM.createRoot(document.getElementById('root'));
const queryClient = new QueryClient();

root.render(
  <ErrorBoundary>
    <HelmetProvider>
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <App />
        </QueryClientProvider>
      </BrowserRouter>
    </HelmetProvider>
  </ErrorBoundary>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// 전역 에러 핸들러 설정
window.addEventListener('unhandledrejection', (event) => {
  console.error('Unhandled promise rejection:', event.reason);
});

window.onerror = function (message, source, lineno, colno, error) {
  console.error('Global error:', { message, source, lineno, colno, error });
  return false;
};
