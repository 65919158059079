import React from 'react';

function Fo46() {

  return (
    <div className="techinfo_view">
				<p className="viewTitle">야맹증</p>
				<dl>
					<dt>발병대상</dt>
					<dd>모든 가축과 사람</dd>
				</dl>
				
				<dl>
					<dt>원인</dt>
					<dd>비타민 A의 결핍, 어두운 곳에서의 시력은 눈의 망막에 존재하는 시홍소(rhodopsin)의 재합성율에 달려 있다. 비타민 A가 결핍되면 시홍소의 재합성에 손상을 주어 어두운 곳에서 보는 능력을 떨어뜨려 결국 야맹증에 걸리게 된다. </dd>
				</dl>
				
				<dl>
					<dt>징후 및 증상</dt>
					<dd>결핍증상은 먼저 어두운 곳에서의 적응이 늦어지며, 악화되면 완전한 야맹증이 된다.</dd>
				</dl>
				
				<dl>
					<dt>질병분포와 손실규모</dt>
					<dd>세계적으로 널리 분포하며 오랜 가뭄과 혹은 바랜 건초를 겨울동안 급여하는 지역에서 특히 심하다.</dd>
				</dl>
				
				<dl>
					<dt>치료 및 대책</dt>
					<dd>비타민 A의 사료중 부족한 양을 보충해준다. 비타민 A의 요구량과 권장량은 가축의 종류와 동일 품종이라 하더라도 연령, 체중 및 번식상태에 따라 다르다</dd>
				</dl>
				
				<dl>
					<dt>예방</dt>
					<dd>청초, 다엽건초, 사일리지, 청예목초 또는 황색 옥수수와 같이 카로틴이 풍부한 사료를 공급하여 주거나 또는 안정화된 비타민 A를 사료에 혹은 정맥내로 서서히 투여해 줄 것.</dd>
				</dl>	
				
				<dl>
					<dt>참고</dt>
					<dd>비타민 A는 합성 비타민이나 또는 전구물질인 카로틴의 형태로 공급해 줄 수 있다.</dd>
				</dl>
			</div>
  );
}

export default Fo46;
