import React from 'react';

function Fo43() {

  return (
    <div className="techinfo_view">
				<p className="viewTitle">케토시스</p>
				<dl>
					<dt>발병대상</dt>
					<dd>소, 양, 염소</dd>
				</dl>
				
				<dl>
					<dt>원인</dt>
					<dd>저혈당증의 특징이 있는 대사성 질병이며 영양소 요구량이 높은 시기(소의 경우 분만 2주전)에 요구량을 충족시키지 못한 가축은 체내 축적지방을 동원하여야만 하는데 이러한 상태가 급속도로 진행되고 사료에 적절한 탄수화물을 공급하여 주지 못하면 케토시스에 걸리게 된다. </dd>
				</dl>
				
				<dl>
					<dt>징후 및 증상</dt>
					<dd>소에서 케토시스 또는 아세톤혈증은 대개 분만후 2-6주경에 관찰되며 감염된 동물은 식욕을 상실하고 산유량의 급격한 감소와 우유 및 뇨중에 클로로포름 냄새와 비슷한 달콤한 아세톤 냄새가 나며 축사에 널리 퍼진다. 적극적인 진단방법으로는 우유 및 뇨중 케톤의 존재여부를 측정하는 것이다.</dd>
					<dd>암양과 염소의 경우 케토시스 또는 임신중독증은 대개 분만 2주전에 발생한다. 감염동물은 쌍태 또는 3태를 배고 있다. 증상으로는 갑작스런 사료거부와 이빨을 갈고 둔해진다. 또한 쇠약해지고 빈뇨, 방목시 전율 및 맹목이 되며 결국 90%정도가 죽는다.</dd>
				</dl>
				
				<dl>
					<dt>질병분포와 손실규모</dt>
					<dd>세계적으로 널리 분포되어 있으며 소의 경우 미국 전역에 걸쳐 발견되고 있으며, 양의 경우는 방목지보다 축사내 관리시에 25%로 발생율이 더 높다.</dd>
				</dl>
				
				<dl>
					<dt>치료</dt>
					<dd>소의 경우 250-500g의 프로필렌 글리콜 또는 프로피온산 나트륨을 5-10일간 2회에 걸쳐 곡류에 혼합하여 주거나 강제급여시킨다. 일시적으로 혈당수준을 높이기 위해 프로필렌 글리콜 뿐만 아니라 포도당 용액과 글루코코티코이디를 정맥내 주입할 수도 있다. 이외에도 많은 치료방법이 있다. 한편 분만전의 양과 염소에는 프로필렌 글리콜 약 90-120g을 1일 3회에 걸쳐 경구 투여한다. 발병초기의 제왕절개술이 회복을 빠르게 하며 또한 분만직전에는 새끼를 구할 수 있다.</dd>
					<dd>분만직후의 염소에는 프로필렌 글리콜 약 180-240kg을 하루에 두번 경구 투여한다. 심한 경우에는 50%포도당 용액을 정맥 주사하는 것이 효과적일 수 있다. 코디코스테로이드제는 임신중인 암컷에 프로필렌 글리콜 또는 포도당 용액 중 어느 하나와 함께 사용할 수 있다.</dd>
				</dl>
				
				<dl>
					<dt>대책</dt>
					<dd>젖소의 경우에는 분만전에 비교적 고에너지 섭취를 유지하고 계속해서 분만 후 점차 에너지 섭취량을 늘려준다. 그리고 암양의 경우에는 임신초기에 비만되지 않도록 하였다가 분만 6주전에 곡류사료를 자유섭취토록 하여준다.</dd>
				</dl>
				
				<dl>
					<dt>예방</dt>
					<dd>분만시 지나친 비만이 되지 않도록 하고, 분만후 농후사료의 비율을 점차 증가시켜 준다. 분만후 사일리지의 과다 급여보다는 양질건초를 급여하되 조사료의 갑작스런 변경을 피할 것. 적당량의 단백질과 무기질 및 비타민을 공급하여 줄 것. 안락감과 적절한 운동 및 환기를 시켜준다. 축군내 발병은 프로필렌 글리콜 또 프로피온산 나트륨은 하루 약 125kg 급여하면 효과를 기대할 수 있다. </dd>
				</dl>	
				
				<dl>
					<dt>참고</dt>
					<dd>임산증산은 소나 양에서 비슷하게 나타나지만 발병은 암양이 분만전에 두드러지고, 소는 분만후 2-6주내에 감염되는 점이 다른다</dd>
				</dl>	
			</div>
  );
}

export default Fo43;
