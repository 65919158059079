import React, { useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import './boLayout.scss';

function BoLayout({ children }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [openMenuId, setOpenMenuId] = useState(null);

  const handleLogout = () => {
    if (window.confirm('로그아웃 하시겠습니까?')) {
      Cookies.remove('session');
      navigate('/bo/login');
    }
  };

  const handleMenuClick = (menuId) => {
    setOpenMenuId(openMenuId === menuId ? null : menuId);
  };

  const isMenuActive = (path) => location.pathname.startsWith(path);

  const isSubmenuActive = (path) => location.pathname.startsWith(path);

  return (
    <div className="bo-layout">
      <header className="bo-header">
        <div className="header-content">
          <h1>BO관리</h1>
          <div className="top-nav">
            <a href="https://dcic.co.kr/" target="_self">
              HOME
            </a>
            <span className="separator">|</span>
            <button type="button" onClick={handleLogout} className="logout-btn">
              LOGOUT
            </button>
          </div>
        </div>
      </header>

      <div className="content-wrapper">
        <nav className="side-nav">
          <ul>
            <li className={isMenuActive('/bo/user') ? 'active' : ''}>
              <Link to="/bo/user">사용자관리</Link>
            </li>
            <li className={isMenuActive('/bo/employee') ? 'active' : ''}>
              <Link to="/bo/employee">직원관리</Link>
            </li>
            <li className={isMenuActive('/bo/code') ? 'active' : ''}>
              <Link to="/bo/code">코드관리</Link>
            </li>

            <li className={isMenuActive('/bo/expert') ? 'active' : ''}>
              <button type="button" onClick={() => handleMenuClick('expert')}>
                전문가관리
              </button>
              <ul className={openMenuId === 'expert' ? 'open' : ''}>
                <li
                  className={isSubmenuActive('/bo/expt/exptKo') ? 'active' : ''}
                >
                  <Link to="/bo/expt/exptKo">전문가정보(한글)</Link>
                </li>
                <li
                  className={
                    isSubmenuActive('/bo/expt/exptEng') ? 'active' : ''
                  }
                >
                  <Link to="/bo/expt/exptEng">전문가정보(영문)</Link>
                </li>
              </ul>
            </li>

            <li className={isMenuActive('/bo/farmTest') ? 'active' : ''}>
              <button type="button" onClick={() => handleMenuClick('farmTest')}>
                검정성적조회
              </button>
              <ul className={openMenuId === 'farmTest' ? 'open' : ''}>
                <li
                  className={
                    isSubmenuActive('/bo/farmTest/monthFarm') ? 'active' : ''
                  }
                >
                  <Link to="/bo/farmTest/monthFarmBestList">
                    이달의 검정농가관리
                  </Link>
                </li>
                <li
                  className={
                    isSubmenuActive('/bo/farmTest/locstat') ? 'active' : ''
                  }
                >
                  <Link to="/bo/farmTest/locstat/list">
                    행정구역별검정사업현황
                  </Link>
                </li>
              </ul>
            </li>

            <li className={isMenuActive('/bo/semen') ? 'active' : ''}>
              <Link to="/bo/semen">정액판매관리</Link>
            </li>

            <li className={isMenuActive('/bo/bull') ? 'active' : ''}>
              <button type="button" onClick={() => handleMenuClick('bull')}>
                종모우관리
              </button>
              <ul className={openMenuId === 'bull' ? 'open' : ''}>
                <li
                  className={
                    isSubmenuActive('/bo/breedBull/originBreedBull')
                      ? 'active'
                      : ''
                  }
                >
                  <Link to="/bo/breedBull/originBreedBull/list">종모우</Link>
                </li>
                <li
                  className={
                    isSubmenuActive('/bo/breedBull/backupBreedBull')
                      ? 'active'
                      : ''
                  }
                >
                  <Link to="/bo/breedBull/backupBreedBull/list">
                    후보종모우
                  </Link>
                </li>
              </ul>
            </li>

            <li className={isMenuActive('/bo/board') ? 'active' : ''}>
              <button type="button" onClick={() => handleMenuClick('board')}>
                정보창고
              </button>
              <ul className={openMenuId === 'board' ? 'open' : ''}>
                <li
                  className={
                    isSubmenuActive('/bo/board/boardNotice') ? 'active' : ''
                  }
                >
                  <Link to="/bo/board/boardNotice">공지사항</Link>
                </li>
                <li
                  className={isSubmenuActive('/bo/board/photo') ? 'active' : ''}
                >
                  <Link to="/bo/board/photo">현장포토</Link>
                </li>
                <li
                  className={
                    isSubmenuActive('/bo/board/cowTestNew') ? 'active' : ''
                  }
                >
                  <Link to="/bo/board/cowTestNew">검정뉴스</Link>
                </li>
                <li
                  className={
                    isSubmenuActive('/bo/board/improveInfo') ? 'active' : ''
                  }
                >
                  <Link to="/bo/board/improveInfo">개량정보</Link>
                </li>
                <li
                  className={
                    isSubmenuActive('/bo/board/testBisReport') ? 'active' : ''
                  }
                >
                  <Link to="/bo/board/testBisReport">검정사업보고서</Link>
                </li>
                <li
                  className={
                    isSubmenuActive('/bo/board/testDtlPushPlan') ? 'active' : ''
                  }
                >
                  <Link to="/bo/board/testDtlPushPlan">
                    검정사업세부추진계획
                  </Link>
                </li>
                <li
                  className={
                    isSubmenuActive('/bo/board/dairyDic') ? 'active' : ''
                  }
                >
                  <Link to="/bo/board/dairyDic">낙농용어사전</Link>
                </li>
              </ul>
            </li>

            <li className={isMenuActive('/bo/info') ? 'active' : ''}>
              <button type="button" onClick={() => handleMenuClick('info')}>
                최신정보
              </button>
              <ul className={openMenuId === 'info' ? 'open' : ''}>
                <li
                  className={
                    isSubmenuActive('/bo/info/InfoNew') ? 'active' : ''
                  }
                >
                  <Link to="/bo/info/InfoNew/list">주요소식</Link>
                </li>
                <li
                  className={
                    isSubmenuActive('/bo/info/InfoEvent') ? 'active' : ''
                  }
                >
                  <Link to="/bo/info/InfoEvent/list">알림&행사</Link>
                </li>
                <li
                  className={
                    isSubmenuActive('/bo/info/InfoForeignTopics')
                      ? 'active'
                      : ''
                  }
                >
                  <Link to="/bo/info/InfoForeignTopics/list">해외토픽</Link>
                </li>
                <li
                  className={
                    isSubmenuActive('/bo/info/InfoTechInfo') ? 'active' : ''
                  }
                >
                  <Link to="/bo/info/InfoTechInfo/list">기술정보</Link>
                </li>
                <li
                  className={
                    isSubmenuActive('/bo/info/InfoIndustryNews') ? 'active' : ''
                  }
                >
                  <Link to="/bo/info/InfoIndustryNews/list">업계소식</Link>
                </li>
                <li
                  className={
                    isSubmenuActive('/bo/info/InfoVideo') ? 'active' : ''
                  }
                >
                  <Link to="/bo/info/InfoVideo/list">홍보동영상</Link>
                </li>
              </ul>
            </li>

            <li className={isMenuActive('/bo/api') ? 'active' : ''}>
              <button type="button" onClick={() => handleMenuClick('api')}>
                축산경제통합시스템
              </button>
              <ul className={openMenuId === 'api' ? 'open' : ''}>
                <li
                  className={
                    isSubmenuActive('/bo/api/apiRequest') ? 'active' : ''
                  }
                >
                  <Link to="/bo/api/apiRequest">전송</Link>
                </li>
                <li
                  className={
                    isSubmenuActive('/bo/api/apiResultStatusList')
                      ? 'active'
                      : ''
                  }
                >
                  <Link to="/bo/api/apiResultStatusList">결과조회</Link>
                </li>
              </ul>
            </li>

            <li className={isMenuActive('/bo/nonghyupApi') ? 'active' : ''}>
              <button
                type="button"
                onClick={() => handleMenuClick('nonghyupApi')}
              >
                농협하나로시스템
              </button>
              <ul className={openMenuId === 'nonghyupApi' ? 'open' : ''}>
                <li
                  className={
                    isSubmenuActive('/bo/nonghyupApi/apiRequestHanaro')
                      ? 'active'
                      : ''
                  }
                >
                  <Link to="/bo/nonghyupApi/apiRequestHanaro">전송</Link>
                </li>
                <li
                  className={
                    isSubmenuActive('/bo/nonghyupApi/apiResultStatusListHanaro')
                      ? 'active'
                      : ''
                  }
                >
                  <Link to="/bo/nonghyupApi/apiResultStatusListHanaro">
                    결과조회
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
        </nav>

        <main className="main-content">{children}</main>
      </div>
    </div>
  );
}

export default BoLayout;
