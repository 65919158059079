import React from 'react';

function Fo39() {

  return (
    <div className="techinfo_view">
				<p className="viewTitle">페스큐 중독증</p>
				<dl>
					<dt>발병대상</dt>
					<dd>소, 양, 말</dd>
				</dl>
				
				<dl>
					<dt>원인</dt>
					<dd>Acremonium coenophialum라는 곰팡이가 tall fescue의 잎과 줄기 및 씨 등에 서식하면서 fescue 자체에는 해를 입히지 않으나 가축에 질병을 야기시킨다.</dd>
				</dl>
				
				<dl>
					<dt>징후 및 증상</dt>
					<dd>증세는 다양하여 가축에 따라 절름발이 증상을 나타내거나 미골 부위에 허물이 벗겨진다. 중증의 페스큐 중독증에 걸린 가축은 사료섭취량이 떨어지고, 방목시 목초 섭취량의 저하 및 유생산 저하 등의 증상이 나타난다. 임신중인 말에 있어서 일반적인 증세는 산유량의 저하와 임신기간이 길어지고 영양소 흡수능력이 떨어지며 태아가 쇠약하게 된다. 페스큐중독증에 걸린 가축은 일반적으로 영양실조와 기생충으로 고통을 받게 된다.</dd>
				</dl>
				
				<dl>
					<dt>질병분포와 손실규모</dt>
					<dd>페스큐 중독증은 미국, 호주, 뉴질랜드, 이태리 등지에서 발견되며, 미국에 있어서는 California, Colorado, Florida, Kentucky, Missouri, Tennessee등지에서 발견된다고 보고되어 왔다. Tall fescue는 미국내에 3천 5백만 에이커 정도 재배 되고 있다. 미국 Mississippi주의 보고서에서는 방목지의 10%정도가 이 병원균에 감염되어 있으며, 또한 방목지의 육우들은 일당증체량이 10%정도 감소하였다고 보고하고 있다. Kentucky 대학 연구진은 이병에 감염된 유우의 경우 감염되지 않은 유우보다 산유량이 평균 약 5.6kg 감소하였다고 보고하였다. </dd>
				</dl>
				
				<dl>
					<dt>치료</dt>
					<dd>의료적인 치료방법은 없다. 다만 축우를 fescue 방목지에서 방목하지 않거나 fescue건초의 급여를 중단하면 호전될 수 있다.</dd>
				</dl>
				
				<dl>
					<dt>대책</dt>
					<dd>연구자들은 현재까지 fescue로 부터 독성 요소를 제거하는 방법을 연구하여 왔으나 발견하지 못하였으며 이중 가장 좋은 방법으로 사양관리의 철저, 영양의 적절한 관리, 증상의 조기 발견과 병원균에 감염된 초지의 식생을 완전히 제거하고 감염되지 않은 씨앗을 다시 파종하는 방법만을 제시되었다. </dd>
				</dl>
				
				<dl>
					<dt>예방</dt>
					<dd>페스큐 중독증을 예방하는 방법은 병원균에 감염되지 않은 씨앗을 파종하는 것이다. 또한 파종시에 fescus 사이에 알팔파, 크로바 또는 다른 목초를 파종하여 fescue 섭취량을 감소시켜 감염을 어느 정도 막을 수 있다. 또한 병원균에 저항력이 있거나 적게 함유하는 tall fescue를 육종하는 방법이 있다. 페스큐 중독증이 문제되는 지역에서는 암말을 임신 2-3개월 이후에는 이를 목초지에 방목하지 않도록 한다.</dd>
				</dl>	
				
				<dl>
					<dt>참고</dt>
					<dd>페스큐 중독증은 수년간 목초를 재배한 목초지에서 가을과 겨울에 잘 발생한다.</dd>
				</dl>
			</div>
  );
}

export default Fo39;
