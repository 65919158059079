import React from 'react';

import pic706 from 'assets/images/sub/_images/pic706.jpg';
import pic707 from 'assets/images/sub/_images/pic707.jpg';

function Do144() {

  return (
    <div className="techinfoview">
      <h1>골절</h1>
      <p className="pstyle">난산으로 태아의 위치를 교정하기 위해 무리한 힘을 가할 때 출생후의 송아지가 밟히거나 좁은 바닥에 발이 끼어 힘을 줄때에 뼈가 불러지는 일이 흔히 있다. 어쨌든 무리한 힘을 가할때는 골절이 된다. </p>
      <div className="leftpart">
        <h2>증상</h2>
        <ul>
          <li>골절이 되면 소는 그 다리에 힘을 줄 수 없어 아주 심하게 절뚝 거린다.
            <ul>
              <li>골절 부위는 통증이 심하기 때문에 손 끝으로 누르면 몹시 아파한다. </li>
              <li>손으로 만져보면 골절된 주위는 열감(熱感)이 있고 부어 있다. </li>
              <li>정상일때는 구부러지지 않는 방법으로 구부러 진다. 이때에 골절된 두 개의 뼈 끝이 비벼져 마찰음이 난다.-이것은 골절의 특징이다.</li>
            </ul>
          </li>
        </ul>
        <h2>목장에서의 처치</h2>
        <ul>
          <li>소에서 뼈가 부러지면 치료를 일찍 포기하는 경향이 있지만 골절은 진단하고 치료하는 방법이 상당히 발달하여 일단 수의사에게 보이는 것이 바람직하다. </li>
          <li> 뼈가 피부 밖으로 드러나는 개방성 골절인 경우에는 상처부위를 깨끗한 천으로 싸매어 오염되지 않도록 한다. </li>
          <li>출혈이 심할때는 출혈부위를 동여매는 등의 지혈 조치를 해 준다. </li>
        </ul>
      </div>
      <div className="rightpart">
        <img src={pic706} className="nonborder" alt=""/>
        <img src={pic707} className="nonborder" alt=""/>
      </div>
    </div>
  );
}

export default Do144;