import React from 'react';


function Do60() {

  return (
    <div className="techinfoview">
				<h1>어린 송아지 사양관리(이유~4개월령)</h1>
				<h2>정상발육의 중요성</h2>
				<p className="pstyle">&nbsp;&nbsp;&nbsp;정상발육이란 증체만을 의미하는 것은 아니다. 송아지를 건강하고 활기있게 육성하는 것이 중요하다. 송아지를 육성하는데 있어 때로 너무 살을 찌우는 경우를 보게 되는데 이는 바람직한 사육방법이 아니다. 과도한 비육은 유선조직에 지방침착을 가져오게 되므로 유선발달이 저해하게 되고 따라서 분만후 우유생산량이 떨어지게 됨은 물론 대사성질병이나 난산발생율이 높아진다. 따라서 영양 요구량에 맞는 균형있는 영양공급이 중요하다. 표 61은 월령에 따른 체중과 알맞는 일당증체량을 표시한 것이며 표 62는 체중 및 영양소 요구량을 나타낸것이다.</p><br/>
				<p><b>&lt;표 61&gt; 월령별 체중과 적정 증체 수준 </b></p>
				<table className="tablestyle">
					<tr>
						<td>월 령</td>
						<td>체중 (kg)</td>
						<td>일당 증체량 (kg/일)</td>
					</tr>
					<tr>
						<td>생 시</td>
						<td>43</td>
						<td>-</td>
					</tr>
					<tr>
						<td>1</td>
						<td>54</td>
						<td>0.33</td>
					</tr>
					<tr>
						<td>2</td>
						<td>72</td>
						<td>0.64</td>
					</tr>
					<tr>
						<td>3</td>
						<td>97</td>
						<td>0.82</td>
					</tr>
					<tr>
						<td>4</td>
						<td>122</td>
						<td>0.84</td>
					</tr>
				</table>
				<p>* 생시∼4개월령까지 평균 일당증체량은 0.68kg임</p><br/>
				<p><b>&lt;표 62&gt; 송아지의 1일 영양소요구량(NRC)</b></p>
				<table className="tablestyle">
					<tr>
						<td rowSpan="2">체중</td>
						<td rowSpan="2">일당증체</td>
						<td rowSpan="2">가소화 영양소총량</td>
						<td rowSpan="2">조단백</td>
						<td colSpan="2">무기물</td>
						<td colSpan="2">비타민</td>
					</tr>
					<tr style={{background: '#273143',color:'#fff'}}>
						<td>칼슘</td>
						<td>인</td>
						<td>A</td>
						<td>D</td>
					</tr>
					<tr>
						<td colSpan="4">···································· kg ····································</td>
						<td colSpan="2">····················· g ·····················</td>
						<td>1,000IU</td>
						<td>IU</td>
					</tr>
					<tr>
						<td>42</td>
						<td>0.40</td>
						<td>0.75</td>
						<td>0.148</td>
						<td>8</td>
						<td>5</td>
						<td>1.8</td>
						<td>280</td>
					</tr>
					<tr>
						<td>50</td>
						<td>0.50</td>
						<td>0.91</td>
						<td>0.180</td>
						<td>9</td>
						<td>6</td>
						<td>2.1</td>
						<td>330</td>
					</tr>
					<tr>
						<td>75</td>
						<td>0.70</td>
						<td>1.72</td>
						<td>0.318</td>
						<td>15</td>
						<td>8</td>
						<td>3.2</td>
						<td>495</td>
					</tr>
					<tr>
						<td>100</td>
						<td>0.70</td>
						<td>2.10</td>
						<td>0.402</td>
						<td>18</td>
						<td>9</td>
						<td>4.2</td>
						<td>660</td>
					</tr>
				</table>
				<h2>농후사료 급여</h2>
				<ul>
					<li>어린송아지 사료를 급여한다.<br/>
						<p><b>&lt;표 63&gt; 일령별 송아지 사료섭취량</b></p>
						<table className="tablestyle">
							<tr>
								<td>일령</td>
								<td>섭취량(kg)</td>
							</tr>
							<tr>
								<td>40</td>
								<td>0.6∼1.3</td>
							</tr>
							<tr>
								<td>50</td>
								<td>1.2∼1.8</td>
							</tr>
							<tr>
								<td>60</td>
								<td>1.4∼2.4</td>
							</tr>
							<tr>
								<td>70</td>
								<td>1.7∼2.6</td>
							</tr>
							<tr>
								<td>80</td>
								<td>1.8∼2.9</td>
							</tr>
							<tr>
								<td>90</td>
								<td>2.0∼3.2</td>
							</tr>
						</table>
					</li>
					<li>건초를 자유채식시키면서 농후사료 급여량은 표 63에 준하여 먹인다. </li>
					<li>조사료의 질에 따라서 농후사료의 급여량을 조절한다. </li>
					<li>어린송아지에게 육성우사료나 착유우사료를 급여하는 것은 옳지 못하다. 송아지의 빨른 성장을 위해서는 단백질이 높은 사료를 먹여야 하는데 육성우나 착유우사료에는 단백질의 함량이 낮으며 어린송아지가 이용할 수 없는 용소등 비 단백태질소화합물(NPN)이 함유되어 있기 때문이다. </li>
					<li>사료는 항시 신선한 것을 급여한다. 곰팡이가 피었거나 냄새가 나는 것은 먹이지 않도록 한다.</li>
				</ul>
				<h2>조사료 급여</h2>
				<p><b>&lt;표 64&gt; 일령별 건초섭취량(1일)</b></p>
				<table className="tablestyle">
					<tr>
						<td>일령</td>
						<td>건초섭취량(kg)</td>
					</tr>
					<tr>
						<td>30</td>
						<td>50∼100</td>
					</tr>
					<tr>
						<td>50</td>
						<td>55∼250</td>
					</tr>
					<tr>
						<td>70</td>
						<td>110∼300</td>
					</tr>
					<tr>
						<td>90</td>
						<td>180∼330</td>
					</tr>
				</table>
				<ul>
					<li>가급적 건초 위주로 사양하는 것이 발육에 좋다. 청초나 사일레지 등 수분이 많은 조사료는 송아지의 건물섭취량이 적어지므로 발육이 뒤진다. </li>
					<li>송아지의 일령별 건초섭취량은 &lt;표 64&gt;와 같다. </li>
					<li>송아지에게 볏짚을 급여하는 것은 옳지 못하다. 볏짚은 질이 낮은 조사료이므로 많이먹지 못하고 또한 열량과 단백질, 비타민, A등의 함량이 낮으므로 영양공급이 불충분하기 때문이다. </li>
					<li>부득이 옥수수사일리지를 급여해야 할 경우에는 황숙기 초기에 수확하여 수분이 65∼70%인 양질의 사일레지여야 하며, 수분이 75% 이상인 사일레지는 피하는 것이 좋다. 가급적 옥수수사일레지의 처음 급여시기는 6개월령 이후로 미루는 것이 바람직하다. </li>
					<li>이 시기에는 가급적 방목을 피한다. 방목을 하게 되면, 기생충의 감염이 늘고 풀의 질이 계절적으로 고르지 않아서 송아지의 발육이 균일하지 못하며 방목을 하다 보면 송아지를 제대로 돌볼 수 없기 때문이다. </li>
				</ul>
				<h2>물 급여</h2>
				<ul>
					<li>포유기에 이유사료를 급여하기 시작하면서부터 물을 자유로이 먹을 수 있도록 한다. 한번에 많은 물을 주는 것 보다는 항시 신선하고 깨끗한 물을 먹을 수 있도록 하는 것이 좋다. 자동급수기를 사용하는 것이 좋으며 물통을 사용할 때는 항시 깨끗하게 청소한다. </li>
					<li>여름에는 시원한 물을, 겨울에는 차갑지 않은 물을 급여하는 것이 이상적이다. 특히 겨울철에는 얼음이 떠있는 물을 먹이지 않도록 한다.</li>
				</ul>
			</div>
  );
}

export default Do60;
