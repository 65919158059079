import React from 'react';

import pic732 from 'assets/images/sub/_images/pic730.jpg';
import pic733 from 'assets/images/sub/_images/pic731.jpg';

function Do153() {

  return (
    <div className="techinfoview">
      <h1>비타민(Vitamin)A 결핍증</h1>
      <div className="leftpart">
        <h2>원인</h2>
        <ul>
          <li>청초가 부족한 상태에서 장기 보존되 싸일레지와 건초를 급여할때와 가뭄이 계속된 초지의 방목소에서 다발한다. </li>
          <li>초유를 충분히 섭취하지 못한 송아지는 4∼6주후에 비타민 A 결핍증상이 나타난다. </li>
        </ul>
        <h2>증상</h2>
        <ul>
          <li> 초기에는 밤눈이 어두워지고(야맹증)눈꼽이 끼며 동공은 암녹색을 띠며 확대된 채로 고정되어 있다. </li>
          <li>세균의 2차 감염으로 각막염을 일으켜 실명하되, 설사와 폐염이 걸리기 쉽다. </li>
          <li>특히 어린 소일수록 증상이 급속도로 진행되며 피해가 크다. </li>
        </ul>
        <h2>목장에서의 처치</h2>
        <ul>
          <li>초기단계에서 체중 1kg당 4,000단위의 비타민A를 근육주사하고 동시에 같은 양의 비타민A 제제를 10일간 사료에 첨가하여 급여하다. </li>
          <li>본증상이 발생하기 쉬운 농후사료다급과 조사료가 부족한 사양관리 아래에 있는 소는 2개월마다 비타민 A를 50∼100만단위씩 주사하는 것이 좋다. </li>
          <li>비타민 A 결핍증상이 심하여 실명할 정도가 되면 치료를 하여도 효과를 기대할 수 없다.</li>
        </ul>
      </div>
      <div className="rightpart">
        <img src={pic732} className="nonborder" alt=""/>
        <img src={pic733} className="nonborder" alt=""/>
      </div>
    </div>
  );
}

export default Do153;