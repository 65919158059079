import React from 'react';

import pic354 from 'assets/images/sub/_images/pic354.gif';
import pic355 from 'assets/images/sub/_images/pic355.gif';
import pic356 from 'assets/images/sub/_images/pic356.gif';

function Do203() {

  return (
    <div className="techinfoview">
      <h1>송아지 사육시설(이유까지)</h1>
      <p>후보축이 어린시기에는 호흡기질환을 위시한 여러 가지 질병에 매우 민감하다. 따라서 이유 전의 어린시기에는 깨끗하고, 건조하며, 샛바람이 들어오지 않는 시설이 필요하다. 또한, 접촉에 의한 질병의 전염을 예방하기 위하여 개별적으로 분리 사육하도록 한다. </p>
      <h2>개방식 우사내 독우상자(calf hutch) </h2>
      <p className="pstyle">&nbsp;&nbsp;&nbsp;독우상자의 전형적인 크기는 4&apos;*S&apos;*4&apos;이다. 합판을 이용한 독우상자의 설계도가 그림 25에 나타나 있다. 독우상자의 한쪽면을 개방하여 철망울타리를 설치하여 송아지가 상자 밖으로 나갈 수 있도록 한다. 겨울철에는 바람이 통하지 않도록 합판의 이음새를 철저하게 막아주고 여름철에는 독우상자의 뒷면을 6&apos;높이까지만 막거나 뒷면에 문을 만들어 통풍이 되게 한다. 미리 만들어진 여러 가지 형태의 독우상자를 구입할 수 있는데, 플라스틱이나 섬유유리와 같이 햇빛이 잘 통하는 소재로 만들어진 독우상자는 반드시 여름철에 그늘막 설치가 필요하다. 물론 합판으로 만든 독우상자도 그늘막을 설치해 주면 여름철 더위에 의한 스트레스를 줄이는데 도움이 된다.</p>
      <img src={pic354} className="nonborder" width="60%" alt=""/>
      <p>그림 25. 송아지 사육상자 설계도</p><br/>
      <p className="pstyle">독우상자의 정면이 남쪽이나 동쪽을 향하도록 하여 겨울철에 일조시간을 길게 하고 방풍이 되도록 한다. 송아지가 빠져나간 독우상자는 최소한 2주 동안 휴식기가 필요하므로 독우상자는 충분하게 확보하여야 한다. 독우상자를 설치할 바닥에 자갈과 모래를 깔면 깔짚의 이용도 좋게 하고, 겨울철에 독우상자가 직접바닥에 닿는 것을 방지할 수 있다. 새로운 송아지를 독우상자에 넣을 때에는, 반드시 독우상자를 신선한 장소로 옮김으로서 질병사이클의 맥을 끊어서 질병발생을 예방한다. 겨울철에는 통풍이 잘되는 건물내에 독우상자를 설치하고 여름철에는 그늘막 시설물 안에 설치한다. (그림 26)</p><br/>
      <img src={pic355} className="nonborder" width="60%" alt=""/>
      <p>그림 26. 건물내 송아지 사육상자 배치</p>
      <h2>개방식 우사내 개별 우리(indiviual pen) </h2>
      <p className="pstyle">칸막이 개별우리는 개방식 건물내에 그림 27과 같이 설치할 수 있다. 전형적인 우리의 크기는 4＇× 7＇이며 옮길 수 있어야 한다. 우리간의 칸막이는 송아지들의 접촉을 막아주고, 우리 뒤편 상당부를 막아주는 커버는 겨울철에 샛바람을 막아준다. 독우상자 보다 공간을 효율적으로 이용할 수 있다는 것이 칸막이 우리의 장점이다. 콘크리트나 자갈 바닥 위에 우리를 설치하며, 관리는 독우상자(calf hutch)의 경우에 준한다.</p>
      <img src={pic356} className="nonborder" width="50%" alt=""/>
      <p>그림 27. 송아지 우리 설계도</p>
      <h2>폐쇄식 우사내 개별우상( individual stall) </h2>
      <p className="pstyle">폐쇄식 우사내에 설치하는 칸막이 개별우리의 전형적인 크기는 2＇× 4＇로 두당 차지하는 공간이 적다. 폐쇄식 우사내의 온도가 상승하면 병원균의 활성이 상승하고, 송아지의 질병이 많이 발생할 수 있다. 따라서, 우리를 폐쇄식 우사내에 설치할 경우에는 통풍장치가 좋아야 하고, 적절한 방역과 세심한 관찰을 통해 질병발생 예방에 주의 해야 한다.</p>
    </div>
  );
}

export default Do203;