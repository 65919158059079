import React from 'react';

function Do6() {

  return (
    <div className="techinfoview">
    <h1>개량목표 및 체계</h1>
    <h2>개량목표</h2>
    <ul>
      <li>축산법 제 5조 제 1항의 규정에 의거 축종별 가축개량목표설정ㆍ고시(농림부 고시 제 93-59호, &apos;96.12.30)
        <ul>
          <li>한우(수소기준)
            <table className="tablestyle">
              <tr>
                <td>대상형질</td>
                <td>&apos;92</td>
                <td>&apos;97</td>
                <td>2001</td>
                <td>년간개량량</td>
              </tr>
              <tr>
                <td>6개월령 체중(kg)</td>
                <td>179</td>
                <td>190</td>
                <td>200</td>
                <td>2.3</td>
              </tr>
              <tr>
                <td>18개월령 체중(kg)</td>
                <td>477</td>
                <td>515</td>
                <td>550</td>
                <td>8.1</td>
              </tr>
              <tr>
                <td>도체율(%)</td>
                <td>57.6</td>
                <td>57.7</td>
                <td>57.8</td>
                <td>0.02</td>
              </tr>
              <tr>
                <td>등지방두께(cm)</td>
                <td>0.75</td>
                <td>0.75</td>
                <td>0.75</td>
                <td>-</td>
              </tr>
              <tr>
                <td>등심면적(㎠)</td>
                <td>75.8</td>
                <td>76.1</td>
                <td>76.4</td>
                <td>0.07</td>
              </tr>
              <tr>
                <td>육질1등급비율(%)</td>
                <td>15</td>
                <td>30</td>
                <td>60</td>
                <td>5.0</td>
              </tr>
            </table>
          </li>
          <li>젖소(305일, 2회착유 기준)
            <table className="tablestyle">
              <tr>
                <td>대상형질</td>
                <td>&apos;92</td>
                <td>&apos;97</td>
                <td>2001</td>
                <td>년간개량량</td>
              </tr>
              <tr>
                <td>산유량(kg)</td>
                <td>5,624</td>
                <td>6,300</td>
                <td>7,000</td>
                <td>153</td>
              </tr>
              <tr>
                <td>유지율(%)</td>
                <td>3.64</td>
                <td>3.70</td>
                <td>3.70</td>
                <td>-</td>
              </tr>
              <tr>
                <td>유단백질율(%)</td>
                <td>3.34</td>
                <td>3.35</td>
                <td>3.35</td>
                <td>-</td>
              </tr>
              <tr>
                <td>무지고형분율(%)</td>
                <td>8.75</td>
                <td>8.75</td>
                <td>8.75</td>
                <td>-</td>
              </tr>
            </table>
          </li>
        </ul>
      </li>
    </ul>
    <h2>젖소개량</h2>
    <ul>
      <li>보증종모우 선발
        <ul>
          <li>후보종모우 생산
            <table className="tablestyle">
              <tr>&apos;
                <td colSpan="2">구분</td>
                <td>&apos;89</td>
                <td>&apos;90</td>
                <td>&apos;91</td>
                <td>&apos;92</td>
                <td>&apos;93</td>
                <td>&apos;94</td>
                <td>&apos;95</td>
                <td>&apos;96</td>
                <td>&apos;97</td>
              </tr>
              <tr>
                <td rowSpan="2">도입</td>
                <td>수정란(개)</td>
                <td>6</td>
                <td>8</td>
                <td>8</td>
                <td>-</td>
                <td>6</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
              </tr>
              <tr>
                <td>정액(st)</td>
                <td>200</td>
                <td>250</td>
                <td>250</td>
                <td>250</td>
                <td>250</td>
                <td>500</td>
                <td>200</td>
                <td>420</td>
                <td>340</td>
              </tr>
              <tr>
                <td rowSpan="2">시술</td>
                <td>수정란(개)</td>
                <td>12</td>
                <td>25</td>
                <td>16</td>
                <td>-</td>
                <td>6</td>
                <td>(16)</td>
                <td>(9)</td>
                <td>(20)</td>
                <td>(20)</td>
              </tr>
              <tr>
                <td>정액(두)</td>
                <td>52</td>
                <td>44</td>
                <td>66</td>
                <td>62</td>
                <td>62</td>
                <td>85</td>
                <td>88</td>
                <td>65</td>
                <td>65</td>
              </tr>
              <tr>
                <td rowSpan="2">수송아지매입</td>
                <td>수정란(개)</td>
                <td>4</td>
                <td>2</td>
                <td>3</td>
                <td>1</td>
                <td>-</td>
                <td>8</td>
                <td>-</td>
                <td>2</td>
                <td>1</td>
              </tr>
              <tr>
                <td>정액(두)</td>
                <td>4</td>
                <td>1</td>
                <td>5</td>
                <td>8</td>
                <td>7</td>
                <td>-</td>
                <td>14</td>
                <td>22</td>
                <td>12</td>
              </tr>
              <tr>
                <td colSpan="2">외국산후보종모우도입</td>
                <td>3</td>
                <td>10</td>
                <td>10</td>
                <td>8</td>
                <td>10</td>
                <td>9</td>
                <td>7</td>
                <td>10</td>
                <td>8</td>
              </tr>
              <tr>
                <td colSpan="2">수송아지확보두수(계)</td>
                <td>11</td>
                <td>13</td>
                <td>18</td>
                <td>23</td>
                <td>18</td>
                <td>17</td>
                <td>21</td>
                <td>34</td>
                <td>21</td>
              </tr>
            </table>
          </li>
          <li>예비 및 대기후보종모우 확보
            <table className="tablestyle">
              <tr>
                <td colSpan="2">구분</td>
                <td>&apos;88</td>
                <td>&apos;89</td>
                <td>&apos;90</td>
                <td>&apos;91</td>
                <td>&apos;92</td>
                <td>&apos;93</td>
                <td>&apos;94</td>
                <td>&apos;95</td>
                <td>&apos;96</td>
                <td>&apos;97</td>
              </tr>
              <tr>
                <td rowSpan="5">예비후보종모우</td>
                <td>년초두수</td>
                <td>-</td>
                <td>1</td>
                <td>12</td>
                <td>9</td>
                <td>11</td>
                <td>8</td>
                <td>9</td>
                <td>13</td>
                <td>21</td>
                <td>33</td>
              </tr>
              <tr>
                <td>선발매입</td>
                <td>1</td>
                <td>11</td>
                <td>13</td>
                <td>18</td>
                <td>14</td>
                <td>20</td>
                <td>19</td>
                <td>23</td>
                <td>34</td>
                <td>21</td>
              </tr>
              <tr>
                <td>후대검정고시</td>
                <td>-</td>
                <td>-</td>
                <td>13</td>
                <td>15</td>
                <td>15</td>
                <td>15</td>
                <td>14</td>
                <td>14</td>
                <td>15</td>
                <td>15</td>
              </tr>
              <tr>
                <td>대기우탈락</td>
                <td>-</td>
                <td>-</td>
                <td>3</td>
                <td>1</td>
                <td>2</td>
                <td>4</td>
                <td>1</td>
                <td>3</td>
                <td>7</td>
                <td>13</td>
              </tr>
              <tr>
                <td>년말두수</td>
                <td>-</td>
                <td>-</td>
                <td>16</td>
                <td>15</td>
                <td>15</td>
                <td>15</td>
                <td>14</td>
                <td>14</td>
                <td>15</td>
                <td>15</td>
              </tr>
            </table>
            <p>※&apos;90년부터 Young Bull 도입(년 10두-축협중앙회)</p>
          </li>
          <li>후대검정(사업분별 실적표)
            <table className="tablestyle">
              <tr>
                <td>구분</td>
                <td>&apos;90(1차)</td>
                <td>&apos;91(2차)</td>
                <td>&apos;92(3차)</td>
                <td>&apos;93(4차)</td>
                <td>&apos;94(5차)</td>
                <td>&apos;95(6차)</td>
                <td>&apos;96(7차)</td>
                <td>&apos;97(8차)</td>
              </tr>
              <tr>
                <td>후보우확보</td>
                <td>16(3)</td>
                <td>15</td>
                <td>15</td>
                <td>15</td>
                <td>14</td>
                <td>14</td>
                <td>15</td>
                <td>15</td>
              </tr>
              <tr>
                <td>빈우선정</td>
                <td>3665</td>
                <td>3488</td>
                <td>4548</td>
                <td>3919</td>
                <td>3417</td>
                <td>3069</td>
                <td>2916</td>
                <td>3412</td>
              </tr>
              <tr>
                <td>낭우생산</td>
                <td>861</td>
                <td>887</td>
                <td>1023</td>
                <td>889</td>
                <td>670</td>
                <td>384</td>
                <td>850</td>
                <td></td>
              </tr>
              <tr>
                <td>낭우초종부</td>
                <td>397</td>
                <td>379</td>
                <td>349</td>
                <td>174</td>
                <td>7</td>
                <td>281</td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>낭우검정개시</td>
                <td>394</td>
                <td>338</td>
                <td>289</td>
                <td>106</td>
                <td>422</td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>낭우검정종료</td>
                <td>238</td>
                <td>219</td>
                <td>75</td>
                <td>2</td>
                <td>114</td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>보증종모우선발</td>
                <td>5</td>
                <td>4</td>
                <td>1</td>
                <td>2</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </table>
            <p>* ()은 대기종모우(검정우)임</p>
          </li>
          <li>검정소 농가후대검정 추진상황
            <table className="tablestyle">
              <tr>
                <td rowSpan="3">구분</td>
                <td colSpan="4">검정소검정(축산기술연구소)</td>
                <td colSpan="4">농가검정(젖소개량부)</td>
              </tr>
              <tr style={{background: '#273143', color:'#fff'}}>
                <td rowSpan="2">후보종모우</td>
                <td rowSpan="2">낭우생산</td>
                <td colSpan="2">검정</td>
                <td rowSpan="2">후보종모우</td>
                <td rowSpan="2">낭우생산</td>
                <td colSpan="2">검정</td>
              </tr>
              <tr style={{background: '#273143', color: '#fff'}}>
                <td>개시</td>
                <td>종료</td>
                <td>개시</td>
                <td>종료</td>
              </tr>
              <tr>
                <td>&apos;88</td>
                <td>4</td>
                <td>143</td>
                <td>98</td>
                <td>77</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
              </tr>
              <tr>
                <td>&apos;89</td>
                <td>4</td>
                <td>155</td>
                <td>68</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
              </tr>
              <tr>
                <td>&apos;90</td>
                <td>5</td>
                <td>126</td>
                <td>49</td>
                <td>-</td>
                <td>16</td>
                <td>861</td>
                <td>363</td>
                <td>156</td>
              </tr>
              <tr>
                <td>&apos;91</td>
                <td>5</td>
                <td>127</td>
                <td>-</td>
                <td>-</td>
                <td>15</td>
                <td>887</td>
                <td>323</td>
                <td>70</td>
              </tr>
              <tr>
                <td>&apos;92</td>
                <td>4</td>
                <td>83</td>
                <td>-</td>
                <td>-</td>
                <td>15</td>
                <td>886</td>
                <td>115</td>
                <td>-</td>
              </tr>
              <tr>
                <td>&apos;93</td>
                <td>9</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>15</td>
                <td>746</td>
                <td>8</td>
                <td>-</td>
              </tr>
              <tr>
                <td>&apos;94</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>14</td>
                <td>470</td>
                <td>-</td>
                <td>-</td>
              </tr>
              <tr>
                <td>&apos;95</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>14</td>
                <td>384</td>
                <td>-</td>
                <td>-</td>
              </tr>
              <tr>
                <td>&apos;96</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>15</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
              </tr>
              <tr>
                <td>&apos;97</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>15</td>
                <td>490</td>
                <td>289</td>
                <td>267</td>
              </tr>
            </table>
            <p>※()내는 젖소개량부 보유종모우로 농가검정, 검정소검정 병행 실시</p>
          </li>
          <li>한국형 젖소보증종모우 선발내역
            <table className="tablestyle">
              <tr>
                <td rowSpan="2">선발년도</td>
                <td rowSpan="2">종모우명</td>
                <td rowSpan="2">생년월일</td>
                <td rowSpan="2">종합지수</td>
                <td colSpan="3">예상유전 전달능력</td>
                <td rowSpan="2">체형점수</td>
                <td rowSpan="2">유방점수</td>
                <td rowSpan="2">중점개량형질</td>
              </tr>
              <tr style={{background: '#273143', color:'#fff'}}>
                <td>유량(kg)</td>
                <td>유지방(kg)</td>
                <td>유단백질(kg)</td>
              </tr>
              <tr>
                <td>&apos;93</td>
                <td>명성</td>
                <td>&apos;84.7.24</td>
                <td>1253</td>
                <td>598</td>
                <td>9.5</td>
                <td>-7.0</td>
                <td>-1.5</td>
                <td>2.0</td>
                <td>유량, 유방</td>
              </tr>
              <tr>
                <td>&apos;94</td>
                <td>한강</td>
                <td>&apos;88.6.9</td>
                <td>1139</td>
                <td>580</td>
                <td>16</td>
                <td>14</td>
                <td>-0.08</td>
                <td>-0.63</td>
                <td>유량</td>
              </tr>
              <tr>
                <td rowSpan="7">&apos;95</td>
                <td>칼리스토</td>
                <td>&apos;88.9.20</td>
                <td>1169</td>
                <td>359</td>
                <td>20</td>
                <td>7</td>
                <td>0.50</td>
                <td>0.13</td>
                <td>유지방량</td>
              </tr>
              <tr>
                <td>금강</td>
                <td>&apos;88.11.24</td>
                <td>1577</td>
                <td>883</td>
                <td>36</td>
                <td>14</td>
                <td>0.37</td><td>1.57</td>
                <td>유량, 유방</td>
              </tr>
              <tr>
                <td>카슨</td>
                <td>&apos;89.10.21</td>
                <td>1443</td>
                <td>985</td>
                <td>27</td>
                <td>3</td>
                <td>-0.61</td>
                <td>0.99</td>
                <td>유량, 유지방량</td>
              </tr>
              <tr>
                <td>아브라함</td>
                <td>&apos;89.8.21</td>
                <td>1725</td>
                <td>1494</td>
                <td>42</td>
                <td>-0.71</td>
                <td>-0.01</td>
                <td>0.85</td>
                <td>유량, 유지방량</td>
              </tr>
              <tr>
                <td>리빌</td>
                <td>&apos;89.9.17</td>
                <td>1528</td>
                <td>865</td>
                <td>21</td>
                <td>11</td>
                <td>1.54</td>
                <td>1.43</td>
                <td>유량, 유지방량</td>
              </tr>
              <tr>
                <td>커스터</td>
                <td>&apos;89.12.18</td>
                <td>1639</td>
                <td>1186</td>
                <td>31</td>
                <td>24</td>
                <td>1.35</td>
                <td>0.97</td>
                <td>유량, 유단백질량</td>
              </tr>
              <tr>
                <td>브루터스</td>
                <td>&apos;90.2.13</td>
                <td>1446</td>
                <td>1136</td>
                <td>40</td>
                <td>11</td>
                <td>-1.26</td>
                <td>0.09</td>
                <td>유량, 유지방량</td>
              </tr>
              <tr>
                <td>&apos;96</td>
                <td>래비</td>
                <td>&apos;90.4.23</td>
                <td>2066</td>
                <td>1069</td>
                <td>52</td>
                <td>25</td>
                <td>4.31</td>
                <td>2.66</td>
                <td>유지방량, 체형</td>
              </tr>
              <tr>
                <td rowSpan="2">&apos;97</td>
                <td>물푸레</td>
                <td>&apos;91.8.23</td>
                <td>2291</td>
                <td>2081</td>
                <td>74.6</td>
                <td>42.0</td>
                <td>-0.3</td>
                <td>1.4</td>
                <td>유량, 유지방, 체형</td>
              </tr>
              <tr>
                <td>벤커</td>
                <td>&apos;91.9.11</td>
                <td>1624</td>
                <td>1089</td>
                <td>49.1</td>
                <td>8.1</td>
                <td>0.2</td>
                <td>-0.1</td>
                <td>유량, 유지방</td>
              </tr>
            </table>
          </li>
        </ul>
      </li>
      <li>젖소산유능력검정
        <ul>
          <li>축협젖소개량단지 운영상황
            <table className="tablestyle">
              <tr>
                <td rowSpan="2">년도</td>
                <td rowSpan="2">단지</td>
                <td rowSpan="2">검정농가</td>
                <td rowSpan="2">검정두수</td>
                <td colSpan="4">지원내역</td>
              </tr>
              <tr style={{background: '#273143', color:'#fff'}}>
                <td>보조사업비</td>
                <td>기금사업비</td>
                <td>융자</td>
                <td>계</td>
              </tr>
              <tr style={{background: '#273143', color:'#fff'}}>
                <td></td>
                <td>개소</td>
                <td>호</td>
                <td>두</td>
                <td>천원</td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>&apos;79</td>
                <td>5</td>
                <td>129</td>
                <td>2,309</td>
                <td>-</td>
                <td>52,000</td>
                <td>-</td>
                <td>52,000</td>
              </tr>
              <tr>
                <td>&apos;80</td>
                <td>5</td>
                <td>241</td>
                <td>3,780</td>
                <td>-</td>
                <td>15,600</td>
                <td>-</td>
                <td>15,600</td>
              </tr>
              <tr>
                <td>&apos;81</td>
                <td>5</td>
                <td>299</td>
                <td>4,564</td>
                <td>-</td>
                <td>161,000</td>
                <td>-</td>
                <td>161,000</td>
              </tr>
              <tr>
                <td>&apos;82</td>
                <td>5</td>
                <td>309</td>
                <td>5,294</td>
                <td>129,707</td>
                <td>14,000</td>
                <td>-</td>
                <td>129,707</td>
              </tr>
              <tr>
                <td>&apos;83</td>
                <td>5</td>
                <td>304</td>
                <td>5,460</td>
                <td>151,829</td>
                <td>89,548</td>
                <td>-</td>
                <td>241,377</td>
              </tr>
              <tr>
                <td>&apos;84</td>
                <td>5</td>
                <td>297</td>
                <td>5,505</td>
                <td>153,291</td>
                <td>13,192</td>
                <td>-</td>
                <td>166,483</td>
              </tr>
              <tr>
                <td>&apos;85</td>
                <td>5</td>
                <td>357</td>
                <td>6,388</td>
                <td>163,430</td>
                <td>17,192</td>
                <td>-</td>
                <td>180,622</td>
              </tr>
              <tr>
                <td>&apos;86</td>
                <td>5</td>
                <td>324</td>
                <td>5,355</td>
                <td>169,954</td>
                <td>10,556</td>
                <td>-</td>
                <td>180,510</td>
              </tr>
              <tr>
                <td>&apos;87</td>
                <td>5</td>
                <td>307</td>
                <td>5,613</td>
                <td>169,226</td>
                <td>29,335</td>
                <td>-</td>
                <td>198,581</td>
              </tr>
              <tr>
                <td>&apos;88</td>
                <td>7</td>
                <td>578</td>
                <td>7,770</td>
                <td>294,135</td>
                <td>35,936</td>
                <td>-</td>
                <td>327,071</td>
              </tr>
              <tr>
                <td>&apos;89</td>
                <td>12</td>
                <td>794</td>
                <td>11,238</td>
                <td>587,521</td>
                <td>-</td>
                <td>-</td>
                <td>587,521</td>
              </tr>
              <tr>
                <td>&apos;90</td>
                <td>14</td>
                <td>768</td>
                <td>11,358</td>
                <td>318,184</td>
                <td>9,965</td>
                <td>3,234,000</td>
                <td>3,562,159</td>
              </tr>
              <tr>
                <td>&apos;91</td>
                <td>17</td>
                <td>834</td>
                <td>13,018</td>
                <td>223,157</td>
                <td>12,014</td>
                <td>366,000</td>
                <td>601,171</td>
              </tr>
              <tr>
                <td>&apos;92</td>
                <td>19</td>
                <td>840</td>
                <td>13,569</td>
                <td>270,183</td>
                <td>13,009</td>
                <td>-</td>
                <td>283,195</td>
              </tr>
              <tr>
                <td>&apos;93</td>
                <td>21</td>
                <td>870</td>
                <td>15,411</td>
                <td>265,502</td>
                <td>-</td>
                <td>-</td>
                <td>265,502</td>
              </tr>
              <tr>
                <td>&apos;94</td>
                <td>23</td>
                <td>968</td>
                <td>19,208</td>
                <td>457,235</td>
                <td>5,350</td>
                <td>-</td>
                <td>462,585</td>
              </tr>
              <tr>
                <td>&apos;95</td>
                <td>23</td>
                <td>995</td>
                <td>22,269</td>
                <td>316,756</td>
                <td>-</td>
                <td>-</td>
                <td>316,756</td>
              </tr>
              <tr>
                <td>&apos;96</td>
                <td>23</td>
                <td>1017</td>
                <td>23,716</td>
                <td>370,000</td>
                <td>23,716</td>
                <td>-</td>
                <td>393,716</td>
              </tr>
              <tr>
                <td>&apos;97</td>
                <td>21</td>
                <td>2163</td>
                <td>53,450</td>
                <td>686,250</td>
                <td>-</td>
                <td>-</td>
                <td>686,250</td>
              </tr>
            </table>
            <p>※도별 1개소씩 시범단지<br/>※&apos;97 부실조합 3개소 사업중단, 검정사업 일원화로 종개협 편입</p>
          </li>
          <li>젖소개량단지 현황
            <table className="tablestyle">
              <tr>
                <td>조성년도</td>
                <td>단지수</td>
                <td>단지명</td>
                <td>검정대상지역</td>
                <td></td>
              </tr>
              <tr>
                <td rowSpan="5">&apos;79</td>
                <td rowSpan="5">5개소</td>
                <td>평택축협</td>
                <td>송탄시, 평택시, 평택군, 안성군, 용인군</td>
                <td>보조</td>
              </tr>
              <tr>
                <td>천안축협</td>
                <td>천안시, 천안군, 아산군</td>
                <td>보조</td>
              </tr>
              <tr>
                <td>나주축협</td>
                <td>광주직할시, 나주시, 나주군, 함평군, 영암군, 장성군</td>
                <td>보조</td>
              </tr>
              <tr>
                <td>부산경남우유(양산)</td>
                <td>부산직할시, 김해시, 양산군, 울주군, 김해군</td>
                <td>보조</td>
              </tr>
              <tr>
                <td>서울우유</td>
                <td>서울, 인천 경기도 일원 21개시 시 군</td>
                <td>자담</td>
              </tr>
              <tr>
                <td rowSpan="2">&apos;88</td>
                <td rowSpan="2">2</td>
                <td>원주축협</td>
                <td>원주시, 원주군</td>
                <td>보조</td>
              </tr>
              <tr>
                <td>고창축협</td>
                <td>고창군</td>
                <td>보조</td>
              </tr>
              <tr>
                <td rowSpan="5">&apos;89</td>
                <td rowSpan="5">5</td>
                <td>청주우유</td>
                <td>청주시, 청원군</td>
                <td>보조</td>
              </tr>
              <tr>
                <td>상주축협</td>
                <td>상주시, 상주군</td>
                <td>보조</td>
              </tr>
              <tr>
                <td>제주낙협</td>
                <td>제주도일원</td>
                <td>보조</td>
              </tr>
              <tr>
                <td>서산축협</td>
                <td>서산시, 서산군, 태안군</td>
                <td>자담</td>
              </tr>
              <tr>
                <td>지리산낙협</td>
                <td>남원시, 남원군</td>
                <td>자담</td>
              </tr>
              <tr>
                <td rowSpan="2">&apos;90</td>
                <td rowSpan="2">2</td>
                <td>경북우유</td>
                <td>달성군, 칠곡군</td>
                <td>자담</td>
              </tr>
              <tr>
                <td>천안낙협</td>
                <td>천안군, 아산군</td>
                <td>자담</td>
              </tr>
              <tr>
                <td rowSpan="3">&apos;91</td>
                <td rowSpan="3">3</td>
                <td>광주축협(경기)</td>
                <td>광주군, 하남시</td>
                <td>자담</td>
              </tr>
              <tr>
                <td>음성축협</td>
                <td>음성군</td>
                <td>자담</td>
              </tr>
              <tr>
                <td>부산경남우유(창원)</td>
                <td>창원시, 마산시, 진해시, 창원군, 함안군</td>
                <td>자담</td>
              </tr>
              <tr>
                <td rowSpan="2">&apos;92</td>
                <td rowSpan="2">2</td>
                <td>홍성낙협</td>
                <td>홍성군</td>
                <td>자담</td>
              </tr>
              <tr>
                <td>경남낙협</td>
                <td>진주시, 진양군, 사천군, 산청군, 남해군, 함양군, 함안군, 거창군, 의령군, 창원군, 하동군, 고성군</td>
                <td>자담</td>
              </tr>
              <tr>
                <td rowSpan="2">&apos;93</td>
                <td rowSpan="2">2</td>
                <td>공주낙협</td>
                <td>공주시, 공주군</td>
                <td>자담</td>
              </tr>
              <tr>
                <td>전남동부낙협</td>
                <td>순천시, 여수시, 여천시, 동광양시, 구례군, 승주군, 광양군, 여천군, 벌교읍</td>
                <td>자담</td>
              </tr>
              <tr>
                <td rowSpan="2">&apos;94~&apos;96</td>
                <td rowSpan="2">2</td>
                <td>경북낙협</td>
                <td>경주시</td>
                <td>자담</td>
              </tr>
              <tr>
                <td>경북중앙낙협</td>
                <td>경산군, 청도군, 대구시, 청송군</td>
                <td>자담</td>
              </tr>
              <tr>
                <td>계</td>
                <td>23</td>
                <td>-</td>
                <td>97개 시ㆍ군</td>
                <td></td>
              </tr>
            </table>
            <p>※&apos;97년부터는 검정참여 검정소 자율사업으로 검정성적에 따라 정부에서 일정액보조지원으로 전환</p>
          </li>
          <li>조합별 검정상황(&apos;97년말 현재)
            <table className="tablestyle">
              <tr>
                <td rowSpan="2"><p style={{float: 'right', paddingRight: '20px'}}>구분</p><br/><p style={{float: 'left', paddingLeft: '20px'}}>검정소</p></td>
                <td colSpan="3">검정원(명)</td>
                <td rowSpan="2">참여농가(호)</td>
                <td rowSpan="2">참여두수(두)</td>
                <td rowSpan="2">보조금지급액(천원)</td>
                <td rowSpan="2">보조금지급실적</td>
              </tr>
              <tr style={{background: '#273143', color:'#fff'}}>
                <td>전담</td>
                <td>촉탁</td>
                <td>계</td>
              </tr>
              <tr>
                <td>경남낙협</td>
                <td>1</td>
                <td>11</td>
                <td>12</td>
                <td>19</td>
                <td>630</td>
                <td>10887</td>
                <td></td>
              </tr>
              <tr>
                <td>경북낙협</td>
                <td>1</td>
                <td></td>
                <td>1</td>
                <td>15</td>
                <td>403</td>
                <td>5533</td>
                <td></td>
              </tr>
              <tr>
                <td>경북중앙</td>
                <td>1</td>
                <td>2</td>
                <td>1</td>
                <td>29</td>
                <td>750</td>
                <td>9580</td>
                <td></td>
              </tr>
              <tr>
                <td>고창축협</td>
                <td>2</td>
                <td>2</td>
                <td>4</td>
                <td>59</td>
                <td>1814</td>
                <td>28816</td>
                <td></td>
              </tr>
              <tr>
                <td>공주낙협</td>
                <td>1</td>
                <td>2</td>
                <td>3</td>
                <td>27</td>
                <td>731</td>
                <td>3529</td>
                <td></td>
              </tr>
              <tr>
                <td>대구우유</td>
                <td>1</td>
                <td></td>
                <td>3</td>
                <td>22</td>
                <td>619</td>
                <td>7695</td>
                <td></td>
              </tr>
              <tr>
                <td>부산(창원)</td>
                <td>6</td>
                <td></td>
                <td>6</td>
                <td>86</td>
                <td>2164</td>
                <td>33321</td>
                <td></td>
              </tr>
              <tr>
                <td>상주축협</td>
                <td>2</td>
                <td></td>
                <td>2</td>
                <td>44</td>
                <td>1191</td>
                <td>22322</td>
                <td></td>
              </tr>
              <tr>
                <td>서산축협</td>
                <td>1</td>
                <td></td>
                <td>1</td>
                <td>23</td>
                <td>722</td>
                <td>5734</td>
                <td></td>
              </tr>
              <tr>
                <td>서울우유</td>
                <td>9</td>
                <td>62</td>
                <td>71</td>
                <td>1206</td>
                <td>27233</td>
                <td>259645</td>
                <td></td>
              </tr>
              <tr>
                <td>원주축협</td>
                <td>2</td>
                <td></td>
                <td>2</td>
                <td>39</td>
                <td>1337</td>
                <td>17032</td>
                <td></td>
              </tr>
              <tr>
                <td>전남동부</td>
                <td>1</td>
                <td></td>
                <td>1</td>
                <td>23</td>
                <td>722</td>
                <td>5734</td>
                <td></td>
              </tr>
              <tr>
                <td>전남서부</td>
                <td>2</td>
                <td></td>
                <td>2</td>
                <td>39</td>
                <td>133</td>
                <td>17032</td>
                <td></td>
              </tr>
              <tr>
                <td>제주낙협</td>
                <td>3</td>
                <td>1</td>
                <td>4</td>
                <td>51</td>
                <td>1597</td>
                <td>25155</td>
                <td></td>
              </tr>
              <tr>
                <td>종축개량협</td>
                <td>5</td>
                <td>3</td>
                <td>8</td>
                <td>264</td>
                <td>7678</td>
                <td>158758</td>
                <td></td>
              </tr>
              <tr>
                <td>천안낙협</td>
                <td>1</td>
                <td></td>
                <td>1</td>
                <td>18</td>
                <td>416</td>
                <td>5232</td>
                <td></td>
              </tr>
              <tr>
                <td>천안축협</td>
                <td>4</td>
                <td></td>
                <td>4</td>
                <td>59</td>
                <td>1811</td>
                <td>19940</td>
                <td></td>
              </tr>
              <tr>
                <td>청주우유</td>
                <td>2</td>
                <td></td>
                <td>2</td>
                <td>62</td>
                <td>1417</td>
                <td>21686</td>
                <td></td>
              </tr>
              <tr>
                <td>평택축협</td>
                <td>3</td>
                <td></td>
                <td>3</td>
                <td>48</td>
                <td>1008</td>
                <td>15773</td>
                <td></td>
              </tr>
              <tr>
                <td>홍성낙협</td>
                <td>3</td>
                <td></td>
                <td>3</td>
                <td>47</td>
                <td>1025</td>
                <td>19165</td>
                <td></td>
              </tr>
              <tr>
                <td>광주축협</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>433</td>
                <td>중도사업포기</td>
              </tr>
              <tr>
                <td>계</td>
                <td>51</td>
                <td>83</td>
                <td>134</td>
                <td>2163</td>
                <td>53450</td>
                <td>686250</td>
                <td></td>
              </tr>
            </table>
          </li>
          <li>산유능력검정성적
            <table className="tablestyle">
              <tr>
                <td rowSpan="3">년도</td>
                <td colSpan="2">전국평균</td>
                <td colSpan="4">젖소개량단지(축협)</td>
                <td colSpan="4">검정농가(종개협)</td>
              </tr>
              <tr style={{background: '#273143', color:'#fff'}}>
                <td>착유두수</td>
                <td>산유량</td>
                <td>농가수</td>
                <td>검정두수</td>
                <td>산유량</td>
                <td>유지율</td>
                <td>농가수</td>
                <td>검정두수</td>
                <td>산유량</td>
                <td>유지율</td>
              </tr>
              <tr style={{background: '#273143', color:'#fff'}}>
                <td>두</td>
                <td>kg</td>
                <td>호</td>
                <td>두</td>
                <td>kg</td>
                <td>%</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>&apos;79</td>
                <td>70557</td>
                <td>4509</td>
                <td>129</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
              </tr>
              <tr>
                <td>&apos;80</td>
                <td>84114</td>
                <td>4509</td>
                <td>241</td>
                <td>315</td>
                <td>4957</td>
                <td>3.7</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
              </tr>
              <tr>
                <td>&apos;81</td>
                <td>93950</td>
                <td>4562</td>
                <td>288</td>
                <td>1058</td>
                <td>5340</td>
                <td>3.6</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
              </tr>
              <tr>
                <td>&apos;82</td>
                <td>103282</td>
                <td>4662</td>
                <td>309</td>
                <td>1890</td>
                <td>5418</td>
                <td>3.6</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
              </tr>
              <tr>
                <td>&apos;83</td>
                <td>125435</td>
                <td>4745</td>
                <td>304</td>
                <td>2280</td>
                <td>5398</td>
                <td>3.6</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
              </tr>
              <tr>
                <td>&apos;84</td>
                <td>147407</td>
                <td>4765</td>
                <td>297</td>
                <td>2045</td>
                <td>5355</td>
                <td>3.6</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
              </tr>
              <tr>
                <td>&apos;85</td>
                <td>179532</td>
                <td>4681</td>
                <td>358</td>
                <td>1891</td>
                <td>5412</td>
                <td>3.6</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
              </tr>
              <tr>
                <td>&apos;86</td>
                <td>204206</td>
                <td>4724</td>
                <td>324</td>
                <td>1882</td>
                <td>5438</td>
                <td>3.6</td>
                <td>14</td>
                <td>975</td>
                <td>6279</td>
                <td>3.53</td>
              </tr>
              <tr>
                <td>&apos;87</td>
                <td>245071</td>
                <td>4818</td>
                <td>307</td>
                <td>559</td>
                <td>6215</td>
                <td>3.57</td>
                <td>21</td>
                <td>1233</td>
                <td>6765</td>
                <td>3.71</td>
              </tr>
              <tr>
                <td>&apos;88</td>
                <td>266055</td>
                <td>5126</td>
                <td>572</td>
                <td>1582</td>
                <td>6069</td>
                <td>3.41</td>
                <td>28</td>
                <td>1613</td>
                <td>7016</td>
                <td>3.68</td>
              </tr>
              <tr>
                <td>&apos;89</td>
                <td>276947</td>
                <td>5315</td>
                <td>794</td>
                <td>1214</td>
                <td>6421</td>
                <td>3.72</td>
                <td>28</td>
                <td>1585</td>
                <td>7042</td>
                <td>3.70</td>
              </tr>
              <tr>
                <td>&apos;90</td>
                <td>272963</td>
                <td>5363</td>
                <td>768</td>
                <td>10366</td>
                <td>6176</td>
                <td>3.64</td>
                <td>41</td>
                <td>1800</td>
                <td>7249</td>
                <td>3.64</td>
              </tr>
              <tr>
                <td>&apos;91</td>
                <td>262948</td>
                <td>5533</td>
                <td>834</td>
                <td>12433</td>
                <td>6327</td>
                <td>3.62</td>
                <td>183</td>
                <td>2759</td>
                <td>7208</td>
                <td>3.63</td>
              </tr>
              <tr>
                <td>&apos;92</td>
                <td>269121</td>
                <td>5369</td>
                <td>840</td>
                <td>12668</td>
                <td>6676</td>
                <td>3.64</td>
                <td>176</td>
                <td>3607</td>
                <td>7395</td>
                <td>3.66</td>
              </tr>
              <tr>
                <td>&apos;93</td>
                <td>274034</td>
                <td>5665</td>
                <td>870</td>
                <td>15411</td>
                <td>6790</td>
                <td>3.63</td>
                <td>192</td>
                <td>3279</td>
                <td>7332</td>
                <td>3.71</td>
              </tr>
              <tr>
                <td>&apos;94</td>
                <td>279649</td>
                <td>5729</td>
                <td>968</td>
                <td>19208</td>
                <td>6763</td>
                <td>3.58</td>
                <td>202</td>
                <td>4441</td>
                <td>7395</td>
                <td>3.71</td>
              </tr>
              <tr>
                <td>&apos;95</td>
                <td>2896320</td>
                <td>5836</td>
                <td>992</td>
                <td>22269</td>
                <td>6868</td>
                <td>3.58</td>
                <td>193</td>
                <td>4463</td>
                <td>7378</td>
                <td>3.62</td>
              </tr>
              <tr>
                <td>&apos;96</td>
                <td>285600</td>
                <td>5959</td>
                <td>1017</td>
                <td>23716</td>
                <td>7038</td>
                <td>3.61</td>
                <td>201</td>
                <td>3874</td>
                <td>7537</td>
                <td>3.70</td>
              </tr>
              <tr>
                <td>&apos;97</td>
                <td>282000</td>
                <td>5882</td>
                <td>2613</td>
                <td>53450</td>
                <td>7171</td>
                <td>3.61</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
              </tr>
            </table>
            <p>※&apos;97 검정사업 일원화로 종개협 편입</p>
          </li>
        </ul>
      </li>
    </ul>
    <h2>기타</h2>
    <ul>
      <li>가축인공수정사업
        <ul>
          <li>가축인공수정소 및 수정사 현황
            <table className="tablestyle">
              <tr>
                <td rowSpan="2">년도별</td>
                <td colSpan="3">수정소</td>
                <td colSpan="3">수정사</td>
              </tr>
              <tr style={{background: '#273143', color:'#fff'}}>
                <td>축협</td>
                <td>민간</td>
                <td>계</td>
                <td>축협</td>
                <td>민간</td>
                <td>계</td>
              </tr>
              <tr>
                <td>1982</td>
                <td>362</td>
                <td>475</td>
                <td>837</td>
                <td>694</td>
                <td>476</td>
                <td>1173</td>
              </tr>
              <tr>
                <td>1983</td>
                <td>462</td>
                <td>716</td>
                <td>1178</td>
                <td>700</td>
                <td>720</td>
                <td>1420</td>
              </tr>
              <tr>
                <td>1984</td>
                <td>473</td>
                <td>1010</td>
                <td>1486</td>
                <td>698</td>
                <td>1075</td>
                <td>1773</td>
              </tr>
              <tr>
                <td>1985</td>
                <td>494</td>
                <td>1210</td>
                <td>1704</td>
                <td>676</td>
                <td>1270</td>
                <td>1946</td>
              </tr>
              <tr>
                <td>1986</td>
                <td>463</td>
                <td>1344</td>
                <td>1797</td>
                <td>643</td>
                <td>1382</td>
                <td>2025</td>
              </tr>
              <tr>
                <td>1987</td>
                <td>431</td>
                <td>1334</td>
                <td>1775</td>
                <td>530</td>
                <td>1371</td>
                <td>1901</td>
              </tr>
              <tr>
                <td>1988</td>
                <td>370</td>
                <td>1351</td>
                <td>1721</td>
                <td>476</td>
                <td>1388</td>
                <td>1864</td>
              </tr>
              <tr>
                <td>1989</td>
                <td>298</td>
                <td>1345</td>
                <td>1643</td>
                <td>370</td>
                <td>1364</td>
                <td>1734</td>
              </tr>
              <tr>
                <td>1990</td>
                <td>224</td>
                <td>1349</td>
                <td>1573</td>
                <td>279</td>
                <td>1363</td>
                <td>1642</td>
              </tr>
              <tr>
                <td>1991</td>
                <td>247</td>
                <td>1396</td>
                <td>1643</td>
                <td>286</td>
                <td>1407</td>
                <td>1693</td>
              </tr>
              <tr>
                <td>1992</td>
                <td>225</td>
                <td>1433</td>
                <td>1658</td>
                <td>281</td>
                <td>1449</td>
                <td>1730</td>
              </tr>
              <tr>
                <td>1993</td>
                <td>248</td>
                <td>1514</td>
                <td>1762</td>
                <td>312</td>
                <td>1530</td>
                <td>1842</td>
              </tr>
              <tr>
                <td>1994</td>
                <td>231</td>
                <td>1578</td>
                <td>1809</td>
                <td>284</td>
                <td>1595</td>
                <td>1879</td>
              </tr>
              <tr>
                <td>1995</td>
                <td>222</td>
                <td>1611</td>
                <td>1839</td>
                <td>284</td>
                <td>1632</td>
                <td>1915</td>
              </tr>
              <tr>
                <td>1996</td>
                <td>228</td>
                <td>1673</td>
                <td>1895</td>
                <td>280</td>
                <td>1691</td>
                <td>1971</td>
              </tr>
              <tr>
                <td>1997</td>
                <td>204</td>
                <td>1909</td>
                <td>2113</td>
                <td>267</td>
                <td>1930</td>
                <td>2197</td>
              </tr>
            </table>
          </li>
          <li>수정실적
          <p>(단위:두)</p>
          <table className="tablestyle">
            <tr>
              <td>년도</td>
              <td>한육우</td>
              <td>젖소</td>
              <td>돼지</td>
              <td>계</td>
              <td>년도</td>
              <td>한육우</td>
              <td>젖소</td>
              <td>돼지</td>
              <td>계</td>
            </tr>
            <tr>
              <td>&apos;62</td>
              <td>-</td>
              <td>758</td>
              <td>30745</td>
              <td>31503</td>
              <td>&apos;81</td>
              <td>142157</td>
              <td>145502</td>
              <td>48234</td>
              <td>605893</td>
            </tr>
            <tr>
              <td>&apos;63</td>
              <td>-</td>
              <td>863</td>
              <td>54976</td>
              <td>55839</td>
              <td>&apos;82</td>
              <td>611626</td>
              <td>163470</td>
              <td>44564</td>
              <td>819660</td>
            </tr>
            <tr>
              <td>&apos;64</td>
              <td>-</td>
              <td>2901</td>
              <td>39324</td>
              <td>42225</td>
              <td>&apos;83</td>
              <td>750894</td>
              <td>214103</td>
              <td>38435</td>
              <td>1003432</td>
            </tr>
            <tr>
              <td>&apos;65</td>
              <td>1275</td>
              <td>2597</td>
              <td>46277</td>
              <td>50149</td>
              <td>&apos;84</td>
              <td>924755</td>
              <td>273400</td>
              <td>-</td>
              <td>1198155</td>
            </tr>
            <tr>
              <td>&apos;66</td>
              <td>8937</td>
              <td>3194</td>
              <td>63197</td>
              <td>75628</td>
              <td>&apos;85</td>
              <td>981708</td>
              <td>340319</td>
              <td>-</td>
              <td>1322027</td>
            </tr>
            <tr>
              <td>&apos;67</td>
              <td>15937</td>
              <td>4129</td>
              <td>64052</td>
              <td>84117</td>
              <td>&apos;86</td>
              <td>834870</td>
              <td>359358</td>
              <td>-</td>
              <td>1194228</td>
            </tr>
            <tr>
              <td>&apos;68</td>
              <td>17705</td>
              <td>4276</td>
              <td>87376</td>
              <td>109357</td>
              <td>&apos;87</td>
              <td>783814</td>
              <td>388477</td>
              <td>-</td>
              <td>1172291</td>
            </tr>
            <tr>
              <td>&apos;69</td>
              <td>24889</td>
              <td>5995</td>
              <td>100388</td>
              <td>131272</td>
              <td>&apos;88</td>
              <td>759717</td>
              <td>426698</td>
              <td>-</td>
              <td>1186415</td>
            </tr>
            <tr>
              <td>&apos;70</td>
              <td>34490</td>
              <td>9129</td>
              <td>111674</td>
              <td>155293</td>
              <td>&apos;89</td>
              <td>768191</td>
              <td>440455</td>
              <td>-</td>
              <td>1208455</td>
            </tr>
            <tr>
              <td>&apos;71</td>
              <td>37895</td>
              <td>10861</td>
              <td>107255</td>
              <td>156011</td>
              <td>&apos;90</td>
              <td>840001</td>
              <td>424078</td>
              <td>-</td>
              <td>1264079</td>
            </tr>
            <tr>
              <td>&apos;72</td>
              <td>45110</td>
              <td>14228</td>
              <td>95973</td>
              <td>155311</td>
              <td>&apos;91</td>
              <td>966874</td>
              <td>420462</td>
              <td>-</td>
              <td>1387336</td>
            </tr>
            <tr>
              <td>&apos;73</td>
              <td>88326</td>
              <td>15507</td>
              <td>62051</td>
              <td>165884</td>
              <td>&apos;92</td>
              <td>1105486</td>
              <td>408900</td>
              <td>-</td>
              <td>1514386</td>
            </tr>
            <tr>
              <td>&apos;74</td>
              <td>93712</td>
              <td>21442</td>
              <td>47709</td>
              <td>161863</td>
              <td>&apos;93</td>
              <td>1166037</td>
              <td>432802</td>
              <td>-</td>
              <td>1598839</td>
            </tr>
            <tr>
              <td>&apos;75</td>
              <td>106310</td>
              <td>33926</td>
              <td>46185</td>
              <td>186421</td>
              <td>&apos;94</td>
              <td>1217694</td>
              <td>430320</td>
              <td>-</td>
              <td>1648014</td>
            </tr>
            <tr>
              <td>&apos;76</td>
              <td>139266</td>
              <td>44953</td>
              <td>62917</td>
              <td>247136</td>
              <td>&apos;95</td>
              <td>1330111</td>
              <td>411176</td>
              <td>-</td>
              <td>1741187</td>
            </tr>
            <tr>
              <td>&apos;77</td>
              <td>197347</td>
              <td>52316</td>
              <td>55797</td>
              <td>305460</td>
              <td>&apos;96</td>
              <td>1436281</td>
              <td>381288</td>
              <td>193706</td>
              <td>1817569</td>
            </tr>
            <tr>
              <td>&apos;78</td>
              <td>284566</td>
              <td>76830</td>
              <td>78530</td>
              <td>349926</td>
              <td>&apos;97</td>
              <td>1177966</td>
              <td>320050</td>
              <td>462412</td>
              <td>1960428</td>
            </tr>
            <tr>
              <td>&apos;79</td>
              <td>303459</td>
              <td>106069</td>
              <td>80925</td>
              <td>490453</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
            </tr>
            <tr>
              <td>&apos;80</td>
              <td>304235</td>
              <td>136117</td>
              <td>38520</td>
              <td>478872</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
            </tr>
          </table>
            <ul>
              <li>최초 가축인공수정소 설치 : 1962.1(서울 서대문구 불광동 산 42, 농협소속)</li>
              <li>1964~1966 : 전국 171개 시ㆍ군당 1개소씩 시ㆍ군 가축인공수정소 설치</li>
              <li>축산법에 의한 가축인공수정사 면허제도 도입 : 1964</li>
            </ul>
          </li>
          <li>투자실적(가축인공수정(단위 : 천원)
            <table className="tablestyle">
              <tr>
                <td>년도</td>
                <td>국고</td>
                <td>지방비</td>
                <td>축발기금</td>
                <td>축협일반회계</td>
                <td>계</td>
                <td>사업내용</td>
              </tr>
              <tr>
                <td>1972</td>
                <td>125,700</td>
                <td>48,250</td>
                <td>-</td>
                <td>-</td>
                <td>173,950</td>
                <td rowSpan="26">인건비 115, 364(국고 77,581), 장비<br/>인건비<br/>인건비<br/>인건비(177,606), 수정사 교육비<br/>인건비(412,899), 종모우 6두<br/>인건비(476,653)국고, 장비<br/>인건비(651,447)국고, 장비<br/>인건비(903,210)국고, 장비<br/>인건비<br/>인건비<br/>인건비(2,898,703), 장비<br/>인건비(2,962,810), 장비<br/>인건비(3,584,219), 장비<br/>인건비<br/>인건비(3,001,707), 장비<br/>인건비<br/>인건비<br/>인건비<br/>인건비<br/>인건비<br/>인건비<br/>인건비, 지도교육비, 울릉군지원<br/>인건비, 울릉군지원, 기타사업비<br/>&quot;<br/>&quot;</td>
              </tr>
              <tr>
                <td>1973</td>
                <td>131,036</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>131,036</td>
              </tr>
              <tr>
                <td>1974</td>
                <td>123,120</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>123,120</td>
              </tr>
              <tr>
                <td>1975</td>
                <td>178,857</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>178,857</td>
              </tr>
              <tr>
                <td>1976</td>
                <td>423,799</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>423,799</td>
              </tr>
              <tr>
                <td>1977</td>
                <td>507,705</td>
                <td>14,969</td>
                <td>-</td>
                <td>-</td>
                <td>522,674</td>
              </tr>
              <tr>
                <td>1978</td>
                <td>692,201</td>
                <td>17,508</td>
                <td>-</td>
                <td>-</td>
                <td>709,709</td>
              </tr>
              <tr>
                <td>1979</td>
                <td>944,598</td>
                <td>17,921</td>
                <td>142,209</td>
                <td>-</td>
                <td>11,104,728</td>
              </tr>
              <tr>
                <td>1980</td>
                <td>1,096,095</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>1,096,095</td>
              </tr>
              <tr>
                <td>1981</td>
                <td>1,129,310</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>1,129,310</td>
              </tr>
              <tr>
                <td>1982</td>
                <td>1,242,241</td>
                <td>-</td>
                <td>1,977,977</td>
                <td>-</td>
                <td>3,220,218</td>
              </tr>
              <tr>
                <td>1983</td>
                <td>-</td>
                <td>-</td>
                <td>3,296,425</td>
                <td>-</td>
                <td>3,296,425</td>
              </tr>
              <tr>
                <td>1984</td>
                <td>-</td>
                <td>-</td>
                <td>3,879,536</td>
                <td>-</td>
                <td>3,879,536</td>
              </tr>
              <tr>
                <td>1985</td>
                <td>-</td>
                <td>-</td>
                <td>3,323,193</td>
                <td>-</td>
                <td>3,323,193</td>
              </tr>
              <tr>
                <td>1986</td>
                <td>-</td>
                <td>-</td>
                <td>1,971,413</td>
                <td>1,048,000</td>
                <td>3,019,413</td>
              </tr>
              <tr>
                <td>1987</td>
                <td>-</td>
                <td>-</td>
                <td>1,595,456</td>
                <td>620,450</td>
                <td>2,215,906</td>
              </tr>
              <tr>
                <td>1988</td>
                <td>-</td>
                <td>-</td>
                <td>1,854,848</td>
                <td>153,236</td>
                <td>1,999,084</td>
              </tr>
              <tr>
                <td>1989</td>
                <td>1,590,044</td>
                <td>-</td>
                <td>-</td>
                <td>164,090</td>
                <td>1,754,134</td>
              </tr>
              <tr>
                <td>1990</td>
                <td>1,268,198</td>
                <td>-</td>
                <td>-</td>
                <td>183,482</td>
                <td>1,451,680</td>
              </tr>
              <tr>
                <td>1991</td>
                <td>-</td>
                <td>-</td>
                <td>1,119,675</td>
                <td>276,065</td>
                <td>1,395,740</td>
              </tr>
              <tr>
                <td>1992</td>
                <td>-</td>
                <td>-</td>
                <td>862,599</td>
                <td>346,465</td>
                <td>1,209,064</td>
              </tr>
              <tr>
                <td>1993</td>
                <td>-</td>
                <td>-</td>
                <td>613,769</td>
                <td>426,051</td>
                <td>1,039,820</td>
              </tr>
              <tr>
                <td>1994</td>
                <td>-</td>
                <td>-</td>
                <td>464,784</td>
                <td>267,777</td>
                <td>732,561</td>
              </tr>
              <tr>
                <td>1995</td>
                <td>-</td>
                <td>-</td>
                <td>255,906</td>
                <td>377,231</td>
                <td>633,137</td>
              </tr>
              <tr>
                <td>1996</td>
                <td>-</td>
                <td>-</td>
                <td>88,047</td>
                <td>390,741</td>
                <td>478,788</td>
              </tr>
              <tr>
                <td>1997</td>
                <td>-</td>
                <td>-</td>
                <td>45,627</td>
                <td>-</td>
                <td>45,627</td>
              </tr>
            </table>
          </li>
          <li>정액공급상황
            <p>(단위:개)</p>
            <table className="tablestyle">
              <tr>
                <td rowSpan="2">년도</td>
                <td rowSpan="2">한우</td>
                <td colSpan="3">젖소</td>
                <td colSpan="3">육우</td>
                <td rowSpan="2">합계</td>
              </tr>
              <tr style={{background: '#273143', color:'#fff'}}>
                <td>국내산</td>
                <td>수입</td>
                <td>계</td>
                <td>국내산</td>
                <td>수입</td>
                <td>계</td>
              </tr>
              <tr>
                <td>1974</td>
                <td>116454</td>
                <td>46755</td>
                <td>-</td>
                <td>46755</td>
                <td>1910</td>
                <td>-</td>
                <td>1910</td>
                <td>165119</td>
              </tr>
              <tr>
                <td>1975</td>
                <td>128997</td>
                <td>71938</td>
                <td>-</td>
                <td>71938</td>
                <td>2335</td>
                <td>100</td>
                <td>2435</td>
                <td>203370</td>
              </tr>
              <tr>
                <td>1976</td>
                <td>150852</td>
                <td>94707</td>
                <td>572</td>
                <td>95279</td>
                <td>6123</td>
                <td>132</td>
                <td>6255</td>
                <td>252386</td>
              </tr>
              <tr>
                <td>1977</td>
                <td>224676</td>
                <td>128961</td>
                <td>1264</td>
                <td>130225</td>
                <td>9410</td>
                <td>550</td>
                <td>9951</td>
                <td>364852</td>
              </tr>
              <tr>
                <td>1978</td>
                <td>404295</td>
                <td>181267</td>
                <td>4200</td>
                <td>185467</td>
                <td>6973</td>
                <td>800</td>
                <td>7773</td>
                <td>597535</td>
              </tr>
              <tr>
                <td>1979</td>
                <td>376291</td>
                <td>267755</td>
                <td>6800</td>
                <td>244555</td>
                <td>20613</td>
                <td>500</td>
                <td>21113</td>
                <td>641959</td>
              </tr>
              <tr>
                <td>1980</td>
                <td>355268</td>
                <td>293595</td>
                <td>1750</td>
                <td>295345</td>
                <td>36375</td>
                <td>800</td>
                <td>37175</td>
                <td>687788</td>
              </tr>
              <tr>
                <td>1981</td>
                <td>471744</td>
                <td>318424</td>
                <td>3600</td>
                <td>322024</td>
                <td>76999</td>
                <td>600</td>
                <td>77599</td>
                <td>871367</td>
              </tr>
              <tr>
                <td>1982</td>
                <td>784058</td>
                <td>386915</td>
                <td>5770</td>
                <td>392685</td>
                <td>140666</td>
                <td>942</td>
                <td>141608</td>
                <td>1318351</td>
              </tr>
              <tr>
                <td>1983</td>
                <td>1130652</td>
                <td>481941</td>
                <td>33830</td>
                <td>515771</td>
                <td>63474</td>
                <td>900</td>
                <td>64374</td>
                <td>1710797</td>
              </tr>
              <tr>
                <td>1984</td>
                <td>1392819</td>
                <td>613194</td>
                <td>10437</td>
                <td>623631</td>
                <td>42599</td>
                <td>150</td>
                <td>42749</td>
                <td>2059199</td>
              </tr>
              <tr>
                <td>1985</td>
                <td>1504294</td>
                <td>725748</td>
                <td>18336</td>
                <td>744084</td>
                <td>43135</td>
                <td>950</td>
                <td>44085</td>
                <td>2292463</td>
              </tr>
              <tr>
                <td>1986</td>
                <td>1024900</td>
                <td>630585</td>
                <td>29254</td>
                <td>659839</td>
                <td>47357</td>
                <td>840</td>
                <td>48197</td>
                <td>1732936</td>
              </tr>
              <tr>
                <td>1987</td>
                <td>1029518</td>
                <td>647850</td>
                <td>48970</td>
                <td>696820</td>
                <td>11160</td>
                <td>680</td>
                <td>11840</td>
                <td>1738178</td>
              </tr>
              <tr>
                <td>1988</td>
                <td>1046072</td>
                <td>735420</td>
                <td>62872</td>
                <td>798292</td>
                <td>7430</td>
                <td>323</td>
                <td>7753</td>
                <td>1852117</td>
              </tr>
              <tr>
                <td>1989</td>
                <td>1067300</td>
                <td>753300</td>
                <td>84397</td>
                <td>837697</td>
                <td>3732</td>
                <td>640</td>
                <td>4372</td>
                <td>1909369</td>
              </tr>
              <tr>
                <td>1990</td>
                <td>1277170</td>
                <td>724686</td>
                <td>96514</td>
                <td>821200</td>
                <td>3920</td>
                <td>340</td>
                <td>4260</td>
                <td>2102630</td>
              </tr>
              <tr>
                <td>1991</td>
                <td>1583200</td>
                <td>555200</td>
                <td>180886</td>
                <td>736386</td>
                <td>12326</td>
                <td>340</td>
                <td>12666</td>
                <td>2332252</td>
              </tr>
              <tr>
                <td>1992</td>
                <td>1911067</td>
                <td>640224</td>
                <td>199628</td>
                <td>839852</td>
                <td>17414</td>
                <td>240</td>
                <td>17654</td>
                <td>2768573</td>
              </tr>
              <tr>
                <td>1993</td>
                <td>2019720</td>
                <td>672700</td>
                <td>274036</td>
                <td>946736</td>
                <td>11570</td>
                <td>-</td>
                <td>11570</td>
                <td>2978026</td>
              </tr>
              <tr>
                <td>1994</td>
                <td>2095550</td>
                <td>662118</td>
                <td>347817</td>
                <td>1009935</td>
                <td>14926</td>
                <td>-</td>
                <td>14926</td>
                <td>3120411</td>
              </tr>
              <tr>
                <td>1995</td>
                <td>2359810</td>
                <td>704590</td>
                <td>256066</td>
                <td>960656</td>
                <td>13180</td>
                <td>-</td>
                <td>13180</td>
                <td>3333646</td>
              </tr>
              <tr>
                <td>1996</td>
                <td>2445310</td>
                <td>665447</td>
                <td>230924</td>
                <td>896371</td>
                <td>8600</td>
                <td>-</td>
                <td>8600</td>
                <td>3350281</td>
              </tr>
              <tr>
                <td>1997</td>
                <td>1838050</td>
                <td>604100</td>
                <td>216653</td>
                <td>820753</td>
                <td>700</td>
                <td>-</td>
                <td>700</td>
                <td>2659503</td>
              </tr>
            </table>
            <p>※① 냉동적액 최초공급 : &apos;72.12, 냉동정액으로 완전 전환 : &apos;77.1.1 ② &apos;93년까지는 &apos;농림부 수입추천실적, &apos;94.1.1부터는 축협중앙회 수입추천실적 &apos;95년부터는 종개협 규격확인실적임.</p>
          </li>
          <li>인공수정용 정액대 및 시술료 변동상황
            <table className="tablestyle">
              <tr>
                <td rowSpan="3">년도</td>
                <td colSpan="2" rowSpan="2">한우</td>
                <td colSpan="2" rowSpan="2">육우</td>
                <td colSpan="4">젖소</td>
              </tr>
              <tr style={{background: '#273143', color:'#fff'}}>
                <td colSpan="2">등록우</td>
                <td colSpan="2">검정우</td>
              </tr>
              <tr style={{background: '#273143', color:'#fff'}}>
                <td>정액대</td>
                <td>시술대</td>
                <td>정액대</td>
                <td>시술대</td>
                <td>정액대</td>
                <td>시술대</td>
                <td>정액대</td>
                <td>시술대</td>
              </tr>
              <tr>
                <td>&apos;63</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>300</td>
                <td>800</td>
                <td>-</td>
                <td>-</td>
              </tr>
              <tr>
                <td>&apos;64</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>200</td>
                <td>1,000</td>
                <td>-</td>
                <td>-</td>
              </tr>
              <tr>
                <td>&apos;68</td>
                <td>무료</td>
                <td>-</td>
                <td>50</td>
                <td>-</td>
                <td>100</td>
                <td>1,500</td>
                <td>-</td>
                <td>-</td>
              </tr>
              <tr>
                <td>&apos;71.4</td>
                <td>50</td>
                <td>700</td>
                <td>50</td>
                <td>-</td>
                <td>150</td>
                <td>1,500</td>
                <td>-</td>
                <td>-</td>
              </tr>
              <tr>
                <td>&apos;73</td>
                <td>50</td>
                <td>700</td>
                <td>150</td>
                <td>-</td>
                <td>200</td>
                <td>1,500</td>
                <td>-</td>
                <td>-</td>
              </tr>
              <tr>
                <td>&apos;74</td>
                <td>100</td>
                <td>700</td>
                <td>400</td>
                <td>-</td>
                <td>300</td>
                <td>1,500</td>
                <td>-</td>
                <td>-</td>
              </tr>
              <tr>
                <td>&apos;75</td>
                <td>150</td>
                <td>700</td>
                <td>400</td>
                <td>-</td>
                <td>400</td>
                <td>1,500</td>
                <td>-</td>
                <td>-</td>
              </tr>
              <tr>
                <td>&apos;76</td>
                <td>150</td>
                <td>500</td>
                <td>400</td>
                <td>-</td>
                <td>500</td>
                <td>3,000</td>
                <td>-</td>
                <td>-</td>
              </tr>
              <tr>
                <td>&apos;77</td>
                <td>150</td>
                <td>1,000</td>
                <td>500</td>
                <td>-</td>
                <td>500</td>
                <td>3,000</td>
                <td>-</td>
                <td>-</td>
              </tr>
              <tr>
                <td>&apos;78</td>
                <td>300</td>
                <td>1,500</td>
                <td>500</td>
                <td>-</td>
                <td>500</td>
                <td>4,000</td>
                <td>-</td>
                <td>-</td>
              </tr>
              <tr>
                <td>&apos;79.11</td>
                <td>500</td>
                <td>3,000</td>
                <td>500</td>
                <td>3,000</td>
                <td>600</td>
                <td>4,400</td>
                <td>1,000</td>
                <td>5,000</td>
              </tr>
              <tr>
                <td>&apos;82.1</td>
                <td>700</td>
                <td>4,500</td>
                <td>1,000</td>
                <td>4,000</td>
                <td>1,000</td>
                <td>7,000</td>
                <td>2,000</td>
                <td>7,000</td>
              </tr>
              <tr>
                <td>&apos;83.5</td>
                <td>1,000</td>
                <td>6,000</td>
                <td>1,000</td>
                <td>6,000</td>
                <td>2,000</td>
                <td>8,000</td>
                <td>3,000</td>
                <td>8,000</td>
              </tr>
              <tr>
                <td>&apos;86.2</td>
                <td>2,500</td>
                <td>6,000</td>
                <td>2,500</td>
                <td>6,000</td>
                <td>4,500</td>
                <td>8,000</td>
                <td>6,000</td>
                <td>8,000</td>
              </tr>
              <tr>
                <td>&apos;88.8</td>
                <td>1,700</td>
                <td>6,000</td>
                <td>1,700</td>
                <td>6,000</td>
                <td>3,400</td>
                <td>8,000</td>
                <td>4,700</td>
                <td>8,000</td>
              </tr>
              <tr>
                <td>&apos;89.8</td>
                <td>1,500</td>
                <td>6,500</td>
                <td>1,500</td>
                <td>6,500</td>
                <td>2,500</td>
                <td>9,000</td>
                <td>3,500</td>
                <td>9,000</td>
              </tr>
              <tr>
                <td>&apos;92.6</td>
                <td>1,500</td>
                <td>6,500</td>
                <td>1,500</td>
                <td>6,500</td>
                <td>1,500</td>
                <td>9,500</td>
                <td>4,500~2,500</td>
                <td>9,500</td>
              </tr>
              <tr>
                <td>&apos;95.8</td>
                <td>1,800</td>
                <td>자율화</td>
                <td>1,500</td>
                <td>자율화</td>
                <td>-</td>
                <td>-</td>
                <td>4,500~2,500</td>
                <td>자율화</td>
              </tr>
              <tr>
                <td>&apos;96.1</td>
                <td>2,500</td>
                <td>자율화</td>
                <td>1,500</td>
                <td>자율화</td>
                <td>-</td>
                <td>-</td>
                <td>7,000~2,000</td>
                <td>자율화</td>
              </tr>
            </table>
            <p>주)축협기준임<br/>* 한우정액 공급가격 2,075원, 판매가격 2,500원<br/>* 젖소정액 등급별 가격<br/>&apos;92.1 : 검정우 A급 4,500원, B급 3,500원, C급 : 2,500원<br/>&apos;96.1 : 1등급 7,000원, 2등급 5,000원, 3등급 4,000원, 4등급 3,000원, 5등급 2,000원<br/>판매가격 : 1등급 7,500원, 2등급 5,500원, 3등급 4,500원, 4등급 3,500원, 5등급 2,500원</p>
          </li>
          <li>인공수정용 종모우 보유두수 상황
            <table className="tablestyle">
              <tr>
                <td rowSpan="2">년도</td>
                <td colSpan="2">한우</td>
                <td colSpan="2">젖소</td>
                <td colSpan="7">육우</td>
              </tr>
              <tr style={{background: '#273143', color:'#fff'}}>
                <td>등록</td>
                <td>보증</td>
                <td>등록</td>
                <td>검정</td>
                <td>앵거스</td>
                <td>헤어포드</td>
                <td>심멘탈</td>
                <td>샤로레(순종)</td>
                <td>샤로레(F1)</td>
                <td>샤로레(F2)</td>
                <td>샤로레(F3)</td>
              </tr>
              <tr>
                <td>1981</td>
                <td>68</td>
                <td>-</td>
                <td>31</td>
                <td>2</td>
                <td>1</td>
                <td>2</td>
                <td>-</td>
                <td>13</td>
                <td>10</td>
                <td>-</td>
                <td>-</td>
              </tr>
              <tr>
                <td>1982</td>
                <td>100</td>
                <td>-</td>
                <td>30</td>
                <td>2</td>
                <td>1</td>
                <td>1</td>
                <td>-</td>
                <td>19</td>
                <td>3</td>
                <td>-</td>
                <td>-</td>
              </tr>
              <tr>
                <td>1983</td>
                <td>148</td>
                <td>-</td>
                <td>50</td>
                <td>10</td>
                <td>1</td>
                <td>3</td>
                <td>2</td>
                <td>18</td>
                <td>3</td>
                <td>-</td>
                <td>-</td>
              </tr>
              <tr>
                <td>1984</td>
                <td>198</td>
                <td>-</td>
                <td>61</td>
                <td>10</td>
                <td>-</td>
                <td>3</td>
                <td>2</td>
                <td>17</td>
                <td>3</td>
                <td>20</td>
                <td>-</td>
              </tr>
              <tr>
                <td>1985</td>
                <td>189</td>
                <td>-</td>
                <td>57</td>
                <td>8</td>
                <td>-</td>
                <td>2</td>
                <td>-</td>
                <td>5</td>
                <td>3</td>
                <td>3</td>
                <td>7</td>
              </tr>
              <tr>
                <td>1986</td>
                <td>179</td>
                <td>-</td>
                <td>47</td>
                <td>10</td>
                <td>-</td>
                <td>1</td>
                <td>-</td>
                <td>3</td>
                <td>-</td>
                <td>3</td>
                <td>9</td>
              </tr>
              <tr>
                <td>1987</td>
                <td>142</td>
                <td>18</td>
                <td>37</td>
                <td>15</td>
                <td>-</td>
                <td>1</td>
                <td>-</td>
                <td>3</td>
                <td>-</td>
                <td>-</td>
                <td>9</td>
              </tr>
              <tr>
                <td>1988</td>
                <td>98</td>
                <td>29</td>
                <td>31</td>
                <td>21</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
              </tr>
              <tr>
                <td>1989</td>
                <td>75</td>
                <td>47</td>
                <td>23</td>
                <td>24</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
              </tr>
              <tr>
                <td>1990</td>
                <td>46</td>
                <td>65</td>
                <td>14</td>
                <td>26</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
              </tr>
              <tr>
                <td>1991</td>
                <td>26</td>
                <td>82</td>
                <td>-</td>
                <td>47</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
              </tr>
              <tr>
                <td>1992</td>
                <td>23</td>
                <td>78</td>
                <td>-</td>
                <td>34</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
              </tr>
              <tr>
                <td>1993</td>
                <td>16</td>
                <td>91</td>
                <td>-</td>
                <td>39</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
              </tr>
              <tr>
                <td>1994</td>
                <td>-</td>
                <td>100</td>
                <td>-</td>
                <td>29</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
              </tr>
              <tr>
                <td>1995</td>
                <td>-</td>
                <td>98</td>
                <td>-</td>
                <td>22</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
              </tr>
              <tr>
                <td>1996</td>
                <td>-</td>
                <td>90</td>
                <td>-</td>
                <td>16</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
              </tr>
              <tr>
                <td>1997</td>
                <td>-</td>
                <td>69</td>
                <td>-</td>
                <td>16</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
              </tr>
            </table>
          </li>
          <li>돼지정액 처리업체 현황
            <table className="tablestyle">
              <tr>
                <td rowSpan="2">년도</td>
                <td rowSpan="2">허가업체수</td>
                <td colSpan="3">인력보유</td>
                <td rowSpan="2">종돈보유두수</td>
                <td colSpan="3">정액생산ㆍ공급</td>
              </tr>
              <tr style={{background:'#273143', color:'#fff'}}>
                <td>수정사</td>
                <td>수의사</td>
                <td>계</td>
                <td>생산</td>
                <td>공급</td>
                <td>폐기</td>
              </tr>
              <tr style={{background: '#273143', color:'#fff'}}>
                <td></td>
                <td>개소</td>
                <td>명</td>
                <td></td>
                <td></td>
                <td>두</td>
                <td>두분</td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>&apos;95</td>
                <td>17</td>
                <td>40</td>
                <td>4</td>
                <td>44</td>
                <td>348</td>
                <td>49839</td>
                <td>44208</td>
                <td>5731</td>
              </tr>
              <tr>
                <td>&apos;96</td>
                <td>25</td>
                <td>56</td>
                <td>8</td>
                <td>64</td>
                <td>493</td>
                <td>209126</td>
                <td>193706</td>
                <td>15420</td>
              </tr>
              <tr>
                <td>&apos;97</td>
                <td>40</td>
                <td>70</td>
                <td>12</td>
                <td>82</td>
                <td>742</td>
                <td>492412</td>
                <td>462412</td>
                <td>29607</td>
              </tr>
            </table>
          </li>
        </ul>
      </li>
    </ul><br/>
    <h2>가축개량목표</h2>
    <p>(1993.12.30 농림수산부 고시 제 93-59호)</p>
    <ul>
      <li>한우
        <ul>
          <li>축산물 수입 자유화에 대응한 한우 쇠고기의 국제경쟁력 향상을 위하여 발육 및 육질능력에 중점을 둔 한우의 개량목표를 다음과 같이 정한다.
            <p>&lt;개량목표(수소기준)&gt;</p>
            <table className="tablestyle">
              <tr>
                <td>대상형질</td>
                <td>&apos;92</td>
                <td>&apos;97</td>
                <td>2001</td>
                <td>년간개량량</td>
              </tr>
              <tr>
                <td>6개월령 체중(kg)</td>
                <td>179</td>
                <td>190</td>
                <td>200</td>
                <td>2.3</td>
              </tr>
              <tr>
                <td>18개월령 체중(kg)</td>
                <td>477</td>
                <td>515</td>
                <td>550</td>
                <td>8.1</td>
              </tr>
              <tr>
                <td>도체율(%)</td>
                <td>57.6</td>
                <td>57.7</td>
                <td>57.8</td>
                <td>0.02</td>
              </tr>
              <tr>
                <td>등지방두께(cm)</td>
                <td>0.75</td>
                <td>0.75</td>
                <td>0.75</td>
                <td>-</td>
              </tr>
              <tr>
                <td>등심면적(㎠)</td>
                <td>75.8</td>
                <td>76.1</td>
                <td>76.4</td>
                <td>0.07</td>
              </tr>
              <tr>
                <td>육질1등급비율(%)</td>
                <td>15</td>
                <td>30</td>
                <td>60</td>
                <td>5.0</td>
              </tr>
            </table>
          </li>
          <li>개량목표 달성을 위하여 다음 사항 추진에 다함께 노력한다.
            <ul>
              <li>당대 및 후대 검정위 확대로 우량 보증종모우 선발, 활용</li>
              <li>한우 개량단지의 관리내실화로 발육 및 번식능력이 우수한 울야암소(Elite Cow)선발, 활용</li>
              <li>고급육 생산기술의 개발, 보급 및 한우쇠고기의 차별화</li>
            </ul>
          </li>
        </ul>
      </li>
      <li>젖소
        <ul>
          <li>축산물 수입자유화에 대응한 유제품의 국제경쟁력 향상을 위하여 유량 및 유질능력에 중점을 둔 젖소의 개량목표를 다음과 같이 정한다.
            <p>&lt;개량목표(305일, 2회착유기준)&gt;</p>
            <table className="tablestyle">
              <tr>
                <td>대상형질</td>
                <td>&apos;92</td>
                <td>&apos;97</td>
                <td>2001</td>
                <td>년간개량량</td>
              </tr>
              <tr>
                <td>산유량(kg)</td>
                <td>5624</td>
                <td>6300</td>
                <td>7000</td>
                <td>153</td>
              </tr>
              <tr>
                <td>유지율(%)</td>
                <td>3.64</td>
                <td>3.70</td>
                <td>3.70</td>
                <td>-</td>
              </tr>
              <tr>
                <td>유단백질(%)</td>
                <td>3.34</td>
                <td>3.35</td>
                <td>.35</td>
                <td>-</td>
              </tr>
              <tr>
                <td>무지고형분율(%)</td>
                <td>8.75</td>
                <td>8.75</td>
                <td>8.75</td>
                <td>-</td>
              </tr>
            </table>
          </li>
          <li>개량목표 달성을 위하여 다음 사항 추진에 다함께 노력한다.
            <ul>
              <li>당대 및 후대검정 확대로 국내여건에 적합한 우량 보증종모우 선발, 활용</li>
              <li>농가보유 젖소에 대한 산유능력검정 화대로 낙농경영합리화 유도</li>
              <li>위생적이고 안전한 원유 및 유제품 생산으로 소비자 보호</li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
    <h2>종축등록 및 검정기관과 가축인공수정교육 및 정액공급기관지정</h2>
    <p>(1992.4.20 농림수산부 고시 제 92-22호)</p>
    <ul>
      <li>종축등록기관
        <table className="tablestyle">
          <tr>
            <td>축종별</td>
            <td>지정기관</td>
          </tr>
          <tr>
            <td>한우ㆍ젖소ㆍ육우ㆍ돼지 및 토끼</td>
            <td>한국종축개량협회</td>
          </tr>
          <tr>
            <td>말</td>
            <td>한국마사회</td>
          </tr>
        </table>
      </li>
      <li>종축검정기관
        <table className="tablestyle">
          <tr>
            <td>축종별</td>
            <td>지정기관</td>
          </tr>
          <tr>
            <td rowSpan="4">한우</td>
            <td>축산기술연구소</td>
          </tr>
          <tr>
            <td>도립종축장</td>
          </tr>
          <tr>
            <td>축산업협동조합중앙회 한우개량사업</td>
          </tr>
          <tr>
            <td>한국종축개량협회</td>
          </tr>
          <tr>
            <td rowSpan="4">젖소</td>
            <td>축산기술연구소</td>
          </tr>
          <tr>
            <td>도립종축장</td>
          </tr>
          <tr>
            <td>축산업협동조합중앙회 한우개량사업</td>
          </tr>
          <tr>
            <td>한국종축개량협회</td>
          </tr>
          <tr>
            <td>육우</td>
            <td>축산기술연구소</td>
          </tr>
          <tr>
            <td rowSpan="4">돼지</td>
            <td>축산기술연구소</td>
          </tr>
          <tr>
            <td>도립종축장</td>
          </tr>
          <tr>
            <td>대한양돈협회</td>
          </tr>
          <tr>
            <td>한국종축개량협회</td>
          </tr>
          <tr>
            <td rowSpan="3">닭</td>
            <td>축산기술연구소</td>
          </tr>
          <tr>
            <td>도립종축장</td>
          </tr>
          <tr>
            <td>대한양계협회</td>
          </tr>
        </table>
      </li>
      <li><b>가축인공수정 교육기관</b>
        <ul>
          <li>농촌진흥청 축산기술연구소</li>
          <li>도립종축장</li>
          <li>축산업협동조합중앙회 한우개량사업부 및 젖소개량사업소</li>
        </ul>
      </li>
      <li><b>가축인공수정용 정액공급기관</b>
        <ul>
          <li>축산기술연구소</li>
          <li>제주도축산사업소</li>
          <li>축산업협동조합중앙회 한우개량사업부 및 젖소개량사업소</li>
        </ul>
      </li>
      <li><b>부칙</b>
        <ul>
          <li>이 고시는 고시한 날부터 시행합니다.</li>
          <li>농수산부 고시 제 88-44호(&apos;83.10.5)는 이를 폐지합니다.</li>
        </ul>
      </li>
    </ul>
    <h2>젖소검정기준(1997.3.6 농림부 고시 제 97-19호)</h2>
    <p style={{fontSize: '18px', marginBottom: '10px'}}><b>제 1장 총칙</b></p>
    <ul>
      <li>제1조(목적)
        <p>이 기준은 축산법 시행규칙 제 10조의 규정에 의하여 젖소의 능력검정방법을 정하므로써 유전적으로 능력이 우수한 종축생산 및 보증종모우선발로 젖소개량을 촉진하는데 있다.</p>
      </li>
      <li>제2조(용어의 정의)
        <p>이 기준에 사용하는 용어의 정의는 다음과 같다.</p>
        <ul>
          <li>&quot;당대검정&quot;이라함은 고능력 수정란 또는 우량 정액등을 이용해서 생산된 수소를 대상으로 선대의 기록이나 자체의 외모심사 및 발육상태등을 조사하여 후보종모우를 선발하는 일련의 과정을 말한다. </li>
          <li>&quot;후대검정&quot;이란 함은 후보종모우의 후손인 딸소(이하 &quot;검정낭우&quot;라 한다.)의 생산. 육성과 검정낭우의 산유능력 및 번식능력을 조사하여 후보종모우를 평가하고 보증종모우를 선발하는 일련의 과정을 말한다. </li>
          <li>&quot;후보종모우&quot;라 함은 후대검정을 위하여 당대검정을 통해 선발된 능력이 공인된 수소를 말하며 이를 예비후보종모우와 대기후보종모우로 구분한다.</li>
          <li>&quot;보증종모우&quot;라 함은 후대검정을 통해 선발된 능력이 공인된 수소를 말한다.</li>
          <li>&quot;종빈우&quot;라 함은 혈통이 등록된 것으로서 후보종모우를 생산하는 암소를 말한다.</li>
          <li>&quot;교배빈우&quot;라 함은 검정낭우를 생산하는 암소를 말한다.</li>
          <li>&quot;예비후보종모우&quot;라 함은 후보종모우로 선발되어 검정낭우를 생산하기 위해 교배에 사용되기전 까지의 수소를 말한다.</li>
          <li>&quot;대기후보종모우&quot;라 함은 검정낭우를 생산하기 위해 교배에 사용된 후 후대검정결과 보증종모우가 되기전까지 대기하고 있는 수소를 말한다.</li>
          <li>&quot;번식능력검정&quot;이라 함은 암소의 초임월령, 초산월령, 임신기간, 분만간격, 송아지 분만난이도, 송아지체중. 기타 번식형질에 대해 조사하는 것을 말한다.</li>
          <li>&quot;산유능력검정&quot;이라 함은 암소(검정낭우 포함)의 산유량, 유지율, 유지량 및 기타 유성분 등에 대한 생산능력을 조사하는 것을 말한다.</li>
          <li>&quot;검정군&quot;이라 함은 검정대상우의 일정 집단을 말한다.</li>
          <li>&quot;검정협의회&quot;란 함은 검정기관이나 검정조합에서 검정사업을 효율적으로 수행하기 위하여 전문가로부터 검정관계 자문을 받기 위해 구성된 기구를 말한다.</li>
        </ul>
      </li>
      <li>제3조(검정구분)
        <ul>
          <li>검정목적에 따라 후보종모우를 생산하기 위한 &quot;당대검정&quot;과 보증종모우를 선발하기 위한 &quot;후대검정&quot;으로 구분한다.</li>
          <li>검정은 국·도립기관이 실시하는 &quot;검정소검정&quot;과 축협중앙회등이 농가를 대상으로 실시하는 &quot;농장검정으로 구분하며 &quot;농장검정&quot;은 &quot;입회검정&quot;과 &quot;자가검정&quot;으로 구분한다.</li>
        </ul>
      </li>
      <li>제4조(검정기관 등)
        <p>검정은 축산법 시행규칙 제 10조 제3항의 규정에 의거 농림부장관이 지정한 검정기관에서 실시하며, 업무의 효율화를 기하기 위하여 검정기관별로 &quot;검정협의회&quot;를 구성할 수 있다.</p>
      </li>
      <li>제5조(검정기자재 확보)
        <p>검정기관은 검정에 필요한 다음 기자재를 확보하여야 한다.</p>
        <ul>
          <li>검정시료 채취 기자재 및 운반기구 </li>
          <li>우유성분 분석 기자재 </li>
          <li>성적분석 및 평가분석에 소요되는 전산처리실설</li>
        </ul>
      </li>
    </ul>
    <p style={{fontSize: '18px', marginBottom: '10px'}}><b>제2장 당대검정</b></p>
    <ul>
      <li>제6조(후보종모우의 자격)
        <ul>
          <li>예비후보종모우의 선발대상은 다음과 같다.
            <ul>
              <li> 고능력 수정란에 의해 생산된 수송아지 </li>
              <li> 고능력 정액을 고능력 종빈우에 수정하여 생산하여 수송아지 </li>
              <li> 보증종모우 정액을 고능력 종빈우에 수정하여 생산된 수송아지 </li>
              <li> 상기 수정란, 정액의 기준은 [별표1]과 같다.</li>
            </ul>
          </li>
          <li>후보종모우의 심사항목 및 시기는 다음과 같다.
            <ul>
              <li>외모심사는 2회 (6개월령, 12개월령)실시하여 심사결과 80점 이상을 받아야 하며, 심사는 축산법 시행규칙 제 9조 제 4항의 규정에 의거 종축등록기관이 공고하는 &quot;가축외모심사기준&quot;에 의한다.</li>
              <li> 발육상태(생시. 3개월령, 6개월령, 12개월령)가 [별표2]의 발육표준이상인 것</li>
              <li>성욕 및 정액검사는 12~16개월령에 실시하며 정액검사기준은 [별표3]과 같다.</li>
            </ul>
          </li>
          <li>후보종모우의 특별인정
            <p>전기 항 각 호에 문제가 없는 수송아지로서 가축개량협의회에서 인정된 수송아지</p>
          </li>
        </ul>
      </li>
      <li>제7조(종빈우의 자격)
        <p>후보종모우 생산을 위한 종빈우는 다음 각 호의 조건을 모두 구비하여야 한다.</p>
        <ul>
          <li>초산차 이상으로 정상적인 번식기록을 갖고 외모심사 점수는 78점 이상인 것 </li>
          <li>산유능력은 1일 2회, 305일 착유, 성년형으로 환산한 보정유량 기준으로 산유능력 검정에 참여한 전 두수중 유량에 대한 유전능력이 상위 5%이내, 평균 유지율 3.4%이상인 것</li>
          <li>등록기관에 등록된 것</li>
          <li>만성질환이 없는 것</li>
        </ul>
      </li>
      <li>제8조(당대검정축의 사양기준)
        <p>검정기관의 당대검정우 사양기준은 [별표4]와 같다.</p>
      </li>
    </ul>
    <p style={{fontsize: '18px', marginBottom: '10px'}}><b>제3장 후대검정</b></p>
    <ul>
      <li>제9조(교배빈우의 자격)
        <p>검정용 낭우생산을 위하여 후보종모우의 정액으로 교배시키는 빈우는 다음 각호의 조건을 모두 구비하여야 한다.</p>
        <ul>
          <li>등록기관에 등록이 되어 있을 것 </li>
          <li>산유능력 검정에 참여하고 있는 농가의 보유소 </li>
          <li>만성질환이 없을 것</li>
        </ul>
      </li>
      <li>제10조(검정낭우의 생산)
        <ul>
          <li>검정낭우를 생산, 확보하기 위한 후보종모우당 교배빈우 두수는 다음과 같다.
            <ul>
              <li>검정소 검정시는 80두 이상 </li>
              <li>농장검정시는 120두 이상</li>
            </ul>
          </li>
          <li> 후보종모우당 검정해야할 검정낭우 확보두수는 20두 이상이어야 한다.</li>
        </ul>
      </li>
      <li>제11조(검정낭우 생산을 위한 교배방법)
        <ul>
          <li>검정낭우 생산을 위한 교배방법은 인공수정을 원칙으로 하며, 수정회수는 3발정 주기까지 적용한다.</li>
          <li>후보종모우별 교배계획은 검정기관에서 무작위로 설계, 추진하되 가능한한 연중 고르게 수태되도록 한다.</li>
          <li>후보종모우의 검정용 정액공급은 무상으로 공급함을 원칙으로 한다. 단 농장검정의 경우는 검정참여 회비를 일부 받을 수 있다.</li>
        </ul>
      </li>
      <li>제12조(검정낭우 관리)
        <ul>
          <li>초유급여기간은 3일 이상이어야 하며, 총 포유기간은 45일 이상으로 한다.</li>
          <li>검정낭우의 외모에 기형 및 유전적 결함이 있거나 혈액형조사를 통하여 친자가 아님이 확인된 때에는 검정대상에서 제외한다.</li>
          <li>검정낭우에 대한 초종부는 14개월령 이상으로 체중 350kg 이상이 되었을 때 실시한다.</li>
          <li>검정낭우의 초발정부터 임신·분만까지 일련의 번식상황을 기록 보관해야한다.</li>
          <li>검정낭우의 포유기간에서부터 검정종료시까지의 사양관리는 NRC사양표준에 준한다. 단 농장검정에서도 가급적 이 기준에 준하도록 지도한다.</li>
        </ul>
      </li>
      <li>제13조(조사항목)
        <ul>
          <li>후대검정에서 검정낭우의 조사항목은 번식능력과 산유능력으로 구분한다.</li>
          <li>번식능력 조사항목은 다음과 같다.
            <ul>
              <li> 번식상황 : 생년월일, 이유일. 등록번호, 종부일 임신기간, 분만일 </li>
              <li> 분만상황 : 정상분만여부. 유산, 사산, 성별, 산차수, 초산시 분만의 난이도</li>
            </ul>
          </li>
          <li>산유능력 조사항목은 다음과 같다.
            <ul>
              <li>산유량, 유선분량 및 율(유지방, 유단백질, 무지고형분) 등 </li>
              <li>착유일수, 1일 착유회수 등</li>
            </ul>
          </li>
          <li>검정낭우에 대하여는 외모 및 선형심사를 실시한다.</li>
          <li>상기 조사항목과 이외의 필요항목에 대한 조사기입요령은 [별표5]와 같다.</li>
        </ul>
      </li>
      <li>제14조(검정의 중지)
        <p>검정낭우에 대한 각호 1에 사유가 발생할 때는 검정을 중지하거나 검정성적을 후보종모우 능력평가에서 제외시킨다.</p>
        <ul>
          <li>검정낭우에 대기 후보종모우에서 기인하는 유전적 불량형질의 발현이 인정될 때 </li>
          <li>만성질환이 있는 것</li>
        </ul>
      </li>
      <li>제15조(후보종모우의 정액생산고나리)
        <ul>
          <li>대기후보종모우는 검정종료까지 냉동정액으로 2천스트로우(후대검정용제외)이상 생산 보관한다.</li>
          <li>보증종모우로 선발된 정액은 인공수정용으로 활용하고 보증종모우선발에서 탈락된 후보종모우 정액은 폐기 처분한다.</li>
        </ul>
      </li>
      <li>제16조(후대검정성적 평가 및 보증종모우 선발)
        <p>가축개량협의회는 대기 후보종모우별 후대검정 성적을 심의하여 보증 종모우를 선발하고 선발된 보증종모우에 대하여는 다음 항목에 대하여 성적을 공표해야한다.</p>
        <ul>
          <li>산유량, 유성분량 및 율, 체형에 대한 암·수소의 유전능력에 예상차</li>
          <li>보증종모우에 대한 외모심사 결과 및 기타 필요한 사항</li>
        </ul>
      </li>
    </ul>
    <p style={{fontSize: '18px', marginBottom: '10px'}}><b>제4장 농장검정</b></p>
    <ul>
      <li>제17조(농장검정의 범위)
        <ul>
          <li>농장검정은 개체별 암소(검정낭우 포함)의 산유 및 번식능력을 검정하는 것을 말한다.</li>
          <li>농장검정은 검정일에 검정원의 입회여부에 따라 입회검정과 자가검정으로 구분한다.</li>
          <li>검정농가의 검정료 부담여부에 따라 유료검정과 무료검정으로 구분한다.</li>
        </ul>
      </li>
      <li>제18조(검정대상축)
        <p>검정을 받고자 하는 대상축은 종축등록기관에 개체등록이 되어 있어야 하며, 미등록인 경우에는 검정개시 6개월 이내에 반드시 등록이 되어 있어야 함</p>
      </li>
      <li>제19조(대상농가 및 지역선정)
        <ul>
          <li>농장검정은 다음 기준에 의거 검정대상 지역을 선정한다.
            <ul>
              <li> 전·기업 낙농목장 </li>
              <li>검정원이 입회검정 및 사료채취 운반이 가능한 지역 </li>
              <li> 검정군 형성이 가능한 지역</li>
            </ul>
          </li>
          <li>검정농가는 다음 기준에 의거 선정한다.
            <ul>
              <li>검정사업을 이해하고 검정요령을 준수하며, 지속적인 검정이 가능한 농가 </li>
              <li>조사료 확보가 가능하며, 성빈우 10두 이상으로 착유두수를 5두이상 보유한 농가 </li>
              <li>젖소개량에 의욕이 높고, 검정대상우를 종축등록기관에 등록 할 수 있는 농가</li>
            </ul>
          </li>
        </ul>
      </li>
      <li>제20조(검정원의 자격)
        <ul>
          <li>검정원은 전담검정원과 촉탁검정원으로 구분한다,</li>
          <li> 전담검정원은 고등학교 축산계학과 이상의 졸업자 또는 가축개량업무에 3년이상 종사하여 검정에 충분한 지식을 갖춘자로 하며, 촉탁검정원은 특별한 자격요건을 정하지 않되 소정의 젖소검정에 관한 교육을 이수한 자로 한다.</li>
        </ul>
      </li>
      <li>제21조(검정원의 업무)
        <p>검정원은 다음 각호의 임무를 수행한다.</p>
        <ul>
          <li>검정목장의 선정 </li>
          <li>검정우 명부작성 </li>
          <li>입회검정 및 자가검정 지도 </li>
          <li>우유성분 및 검정성적 기록 </li>
          <li>검정기록작성 및 농가의 사양, 경영개선 지도 </li>
          <li>검정 기자재 관리 </li>
          <li>기타 필요한 업무수행</li>
        </ul>
      </li>
      <li>제22조(검정횟수)
        <p>검정회수는 생애검정을 원칙으로 검정농가당 매월 1회 및 측정당일 연속 2회(당일 오전, 오후 또는 당일 오후,익일 오전)를 기준으로 한다.</p>
      </li>
      <li>제23조(검정절차)
        <p>검정원은 다음 절차에 의거 검정업무를 수행한다. </p>
        <ul>
          <li>검정원은 제 25조 제 3항의 검정간격내에서 불특정일에 불시 입회하여야 한다. </li>
          <li>검정원은 검정당일 착유시간전에 검정우를 개체별로 확인한다. </li>
          <li>검정원은 검정기록에 의심의 여지가 있을 경우 검정일로부터 15일 이내에 재측정한다. </li>
          <li>조사기록상황은 검정기록표로 작성하여 우송 또는 전산입력등의 방법으로 검정기관에 송부한다. </li>
          <li>검정기관은 검정기록표에 의거 검정성적표를 작성, 직접 또는 검정소를 통하여 검정농가에 통보한다. </li>
          <li>검정기관 및 검정소(조합)는 검정성적표를 참고하여 검정 참여농가에 개량, 사양, 경영에 관한 사항을 지도한다.</li>
        </ul>
      </li>
      <li>제24조(검정의 중지)
        <p>검정우에 질병, 생리적이유, 검정우도채, 매각 등으로 검정기록이 얻어질 수 없을 때에는 당해 검정우에 대해 검정을 중지한다.</p>
      </li>
      <li>제25조(검정기록 및 평가)
        <ul>
          <li>검정기록은 분만후 제 6일째 아침 착유부터 비유기 끝까지 실시하고 산유량 기록은 분만일로부터 기록한다.</li>
          <li> 첫 검정기록은 적어도 분만후 6∼45일 이내에 실시한다.</li>
          <li>검정일 간격은 30±5일을 원칙으로 한다.</li>
          <li> 착유회수는 1일 2회 착유를 기준으로 하되, 1일 2회 이상 착유시는 매 착유시마다 기록하고, 30-5일 보정성적 평가시는 [별표 6]의 보정계수를 사용하여 2회 착유기준으로 보정한다.</li>
          <li>생산량을 정확히 비교하기 위하여 [별표7]에 의하여 305일 성년형보정생산량으로 환산한다. 필요시에는 보정하여 비교할 수 있다.</li>
          <li>착유기간이 305일이 되지 못할 경우에는 305일로 보정하며, 착유일수가 75일이하는 305일로 보정할 수 없다.</li>
          <li>검정중 임신후 180일 이내에 유산, 사산할 경우는 기록의 중지없이 계속검정을 실시하여 건유일 또는 305일 착유로 1비유기가 검정종료되며 임신후 180일이 경과하여 유산, 사산할 경우는 유산당일부터 다음 산차 및 새로운 비유기로 본다.</li>
        </ul>
      </li>
      <li>제26조(검정성적의 활용)
        <p>검정기관은 매년 검정성적을 평가하여 능력검정 참여농가의 경영 및 사양관리 지도등에 활용한다.</p>
      </li>
      <li>제27조(검정농가의 준수사항)
        <ul>
          <li>검정농가는 검정원이 정당한 검정임무를 수행할 수 있도록 적극 협조한다.</li>
          <li> 검정농가는 검정원의 검정기록 확인을 위한 재검정 요구에 응해야 한다.</li>
          <li>정당한 사유가 없는한 검정일에 검정을 기피 또는 연기할 수 없다.</li>
          <li>검정당일도 일상적인 착유시간, 착유과정을 진행시켜야 하며, 유량 및 기타 검정기록에 영향을 줄 수 있는 물리적, 생리적 영향을 주는 약품 등의 사용 및 행위를 해서는 안된다.</li>
        </ul>
      </li>
    </ul>
    <p style={{fontSize: '18px', marginBottom: '10px'}}><b>제5장 기타</b></p>
    <ul>
      <li>제28조(검정결과 처리)
        <p>검정기관은 검정결과에 대하여 공표하고, 등록기관에 통보하여야 하며, 등록기관에서는 동 결과를 등록중에 기록ㆍ보전하여야 한다.</p>
      </li>
      <li>제29조(기타 세부절차 및 방법)
        <p>젖소검정에 관하여 필요한 사항 중 이 기준에 정하지 않은 사항에 대하여는 가축개량협의회(젖소분과)의 심의를 거쳐 검정기관장이 별도로 정하여 시행한다.</p>
      </li>
      <li>부칙
        <ul>
          <li>본 기준은 고시한 날부터 시행합니다.</li>
          <li>농림수산부 고시 제 92-25호(&apos;92.6.5)는 이를 폐지한다.</li>
          <li>본 기준중 별표의 내용은 가축개량협의회 심의에 따라 조정, 시행할 수 있다.</li>
          <li> 본 기준 고시 당시 검정진행중인 사항은 종전의 고시(제 92-25호)에 준하여 실시할 수 있다.</li>
        </ul>
      </li>
      <li><b>[별표1]</b>
        <p>후보종모우 생산용 수정란 및 정액 기준</p>
        <ul>
          <li>축종 : 홀스타인</li>
          <li>모색 : 흑백반(적색 및 이모색 표시제외)</li>
          <li>어미 및 아비의 능력
            <ul>
              <li>외모심사 : 78점 이상</li>
              <li>산유량, 유지량, 유단백질량 및 체형 형질중 2가지이상 형질에 대한 후대에의 능력전달 예상치가 전체 우군의 상위 5%이내이거나 종합지수가 전체 우군의 상위 1%이내의 것</li>
            </ul>
          </li>
          <li>혈통
            <ul>
              <li>가급적 최근 3대 혈통 내 종모우가 국내에 많이 도입된 계통이 아닐 것</li>
            </ul>
          </li>
          <li>기타
            <ul>
              <li>능력 적용은 각국의 능력표기 방식을 존중하되 가능한 한 객관적인 자료에 의거 미국의 능력표기 방식으로 환산하여 비교 적용한다.</li>
            </ul>
          </li>
        </ul>
      </li>
      <li><b>[별표2]</b>
        <p>젖소 수소의 발육 표준</p>
        <table className="tablestyle">
          <tr>
            <td>월령(월)</td>
            <td>체중(kg)</td>
            <td>월령(월)</td>
            <td>체중(kg)</td>
          </tr>
          <tr>
            <td>생시</td>
            <td>43</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>1</td>
            <td>58</td>
            <td>13</td>
            <td>388</td>
          </tr>
          <tr>
            <td>2</td>
            <td>76</td>
            <td>14</td>
            <td>418</td>
          </tr>
          <tr>
            <td>3</td>
            <td>97</td>
            <td>15</td>
            <td>448</td>
          </tr>
          <tr>
            <td>4</td>
            <td>121</td>
            <td>16</td>
            <td>477</td>
          </tr>
          <tr>
            <td>5</td>
            <td>148</td>
            <td>17</td>
            <td>504</td>
          </tr>
          <tr>
            <td>6</td>
            <td>178</td>
            <td>18</td>
            <td>530</td>
          </tr>
          <tr>
            <td>7</td>
            <td>208</td>
            <td>19</td>
          </tr>
          <tr>
            <td>8</td>
            <td>238</td>
            <td>20</td>
            <td>577</td>
          </tr>
          <tr>
            <td>9</td>
            <td>268</td>
            <td>21</td>
            <td>598</td>
          </tr>
          <tr>
            <td>10</td>
            <td>298</td>
            <td>22</td>
            <td>618</td>
          </tr>
          <tr>
            <td>11</td>
            <td>328</td>
            <td>23</td>
            <td>636</td>
          </tr>
          <tr>
            <td>12</td>
            <td>358</td>
            <td>24</td>
            <td>653</td>
          </tr>
        </table>
      </li>
      <li><b>[별표3]</b>
        <p>정액검사기준</p>
        <ul>
          <li>검사월령 및 방법
            <ul>
              <li>예비 후보종모우의 정액검사는 12개월령 이후 16개월령 사이에 1주간격으로 5회 실시한다.</li>
            </ul>
          </li>
          <li>검사항목 및 기준
            <ul>
              <li> 정액사출량 : 1회 3ml 이상</li>
              <li> 정자의 활력 : 70% 이상</li>
              <li> 정자의 기형율 : 15% 이내</li>
              <li>정자수 : 1ml당 5억이상</li>
              <li>수소이온농도(pH) : 양호</li>
              <li>삼투압 : 250∼350 osmol</li>
              <li>기타 : 무색, 무취이며 혈액이나 농이 없는 것</li>
            </ul>
          </li>
          <li>정자의 등급
            <table className="tablestyle">
              <tr>
                <td></td>
                <td>A(100)</td>
                <td>B(85)</td>
                <td>C(50)</td>
                <td>D(25)</td>
                <td>E(0)</td>
              </tr>
              <tr>
                <td>생존율(%)</td>
                <td>91이상</td>
                <td>71~90</td>
                <td>51~70</td>
                <td>31~50</td>
                <td>30이하</td>
              </tr>&quot;
              <tr>
                <td rowSpan="2">활력기형율(%)</td>
                <td>5</td>
                <td>4</td>
                <td>3</td>
                <td>2</td>
                <td>1</td>
              </tr>
              <tr>
                <td>10이하</td>
                <td>11~15</td>
                <td>16~25</td>
                <td>26~40</td>
                <td>41이상</td>
              </tr>
            </table>
            <p>※생존율, 활력, 기형율 중 1가지 항목이라도 C급 이하는 선발대상에서 제외</p>
          </li>
          <li>정자의 활력 판정기준
            <ul>
              <li> 5(최우량) : 80%이상이 매우 활발한 운동을 하는 것 </li>
              <li> 4(우&nbsp;&nbsp;&nbsp;&nbsp;수) : 70~80%정도가 활발한 운동을 하는 것 </li>
              <li>3(양&nbsp;&nbsp;&nbsp;&nbsp;호) : 50~70%정도가 전진운동을 하는 것 </li>
              <li>2(보&nbsp;&nbsp;&nbsp;&nbsp;통) : 30~50%정도가 약한 전진운동을 하는 것 </li>
              <li>1(불&nbsp;&nbsp;&nbsp;&nbsp;량): 30%미만의 전진운공과 진자운동을 하는 것</li>
            </ul>
          </li>
        </ul>
      </li>
      <li><b>[별표4]</b>
        <p>후보종모우 사양관리기준</p>
        <ul>
          <li>예비후보종모우Td
            <p>(포유기)</p>
            <ul>
              <li>분만후 초유를 급여하되 최소 3일 이상 급여</li>
              <li>분만직후 별도의 송아지칸에 수용하여 인공포유를 시키며, 초유이유 이후부터는 2~3두씩 함께 수용한다.</li>
              <li>인공포유시 송아지 체중의 8~10% 수준의 전유 또는 대용유를 1일 2회 나누어 규칙적으로 급여하고 10일령부터는 양질의 건초와 송아지 사료를 자유섭취케 한다.</li>
              <li>풀은 분만후 3주령부터 자유섭취케 한다.</li>
              <li>생후 2주령이내에 제각을 하고 운동과 일광욕도 서서히 시키며 이유는 최소 7주령에 실시한다.</li>
            </ul>
            <p>(이유기~12개월령)</p>
            <ul>
              <li>조사료는 건초위주로 자유채식 시키고 엔실레지는 7개월령부터 소량을 급여한다.</li>
              <li>농후사료는 보조사료를 3개월령까지 자유채식 시키고 3개월령이후는 육성사료를 NRC사양표준에 의거 급여하되 일당증체량이 0.6~0.8kg을 유지한다.</li>
              <li>소금, 칼슘, 인, 비타민A, D등은 충분한 량을 급여하고 자유섭취케 한다.</li>
            </ul>
          </li>
          <li>대기 후보종모우
            <ul>
              <li>조사료는 건초를 체중의 1.5% 수준으로 급여한다.</li>
              <li>농후사료는 DCP 12%, TDN 70%인 것을 NRC 사양표준에 의거 급여한다.</li>
              <li>물은 자유로이 섭취할 수 있게 한다.</li>
              <li>운동과 일광욕은 충분히 시키되 특히 운동은 1일 30분이상 실시한다.</li>
              <li>발굽의 삭제는 정기적으로 한다.</li>
              <li>비만해지지 않게 농수사료의 급여량을 조절하고 운동이 부족되지 않도록 한다.</li>
              <li>종모우의 정액생산량 및 정액생성상을 수시로 점검하여 이상발생시 일정기간을 정하여 특별관리를 한다.</li>
              <li>매년 정기 종합진단을 1회이상 실시하고 필요한 예방접종을 한다.</li>
            </ul>
          </li>
        </ul>
      </li>
      <li><b>[별표5]</b>
        <p>검정항목과 조사기입 방법</p>
        <table className="tablestyle">
          <tr>
            <td></td>
            <td>검정항목</td>
            <td>조사기입방법</td>
          </tr>
          <tr>
            <td rowSpan="7">산유능력</td>
            <td>산유량 및 유성분</td>
            <td>산유량 및 유성분량은 소수점 두자리까지 계산한후 사사오입하여 소수점 한자리까지 기입하며, 유성분율은 소수점 세자리까지 계산한후 사사오입하여 소수점 두자리까지 기입한다.</td>
          </tr>
          <tr>
            <td>사료급여량 농후사료</td>
            <td>검정일 당일 개체별 급여량은 0.1kg 단위까지 기입</td>
          </tr>
          <tr>
            <td>조사료</td>
            <td>조사료 종류별로 1일 1두당 평균급여량을 조사하여 0.1kg 단위까지 기입</td>
          </tr>
          <tr>
            <td>유가</td>
            <td>수취가격에 의하여 월평균 1kg당 가격을 원단위까지 기입</td>
          </tr>
          <tr>
            <td>농후사료 단가 체중</td>
            <td>급여하고 있는 구입 농후사료의 월평균 1kg당 가격을 원단위까지 기입 체중 측정치 또는 우형기로 매월 측정하되 오전중 실시하여 1kg 단위까지 기입</td>
          </tr>
          <tr>
            <td>건유월일</td>
            <td>검정우의 건유월일은 연속해서 착유하지 않는 때</td>
          </tr>
          <tr>
            <td>분만년월일 및 산차기타사항</td>
            <td>검정우가 분만한 때의 월일과 산차기록, 분만장애, 유방염등 기타 장애</td>
          </tr>
          <tr>
            <td rowSpan="7">번식월령</td>
            <td>초임월령</td>
            <td>출생일로부터 임신이 확정된 수정일까지의 월수를 계산한 후 잔여일이 15일을 초과할 때에는 1월을 가산하고 15일에 미달할때에는 가산하지 아니함.</td>
          </tr>
          <tr>
            <td>초산월령</td>
            <td>출생일로부터 초산일까지의 월수를 계산한 후 잔여일이 15일을 초과할 때에는 1월을 가산하고 15일에 미달할 때에는 가산하지 아니함</td>
          </tr>
          <tr>
            <td>임신기간</td>
            <td>수정일로부터 분만당일까지의 일수</td>
          </tr>
          <tr>
            <td>분만간격</td>
            <td>전분만일로부터 다음 분만일까지의 일수</td>
          </tr>
          <tr>
            <td>생시체중</td>
            <td>출생 후 초유급여전의 송아지 체중</td>
          </tr>
          <tr>
            <td>수태당 수정횟수</td>
            <td>발정후 수태시까지 수정횟수</td>
          </tr>
          <tr>
            <td>기타사항</td>
            <td>분만장애 및 번식에 관여된 사항</td>
          </tr>
        </table>
      </li>
    </ul>
    <h2>수출종축등의 생산능력·규격기준[1995년. 3. 6 농림부 고시 제 95-12호] </h2>
    <ul>
      <li>한우종우
        <ul>
          <li>생산능력 기준
            <ul>
              <li>씨수소
                <table className="tablestyle">
                  <tr>
                    <td>보증씨수소</td>
                    <td>기타씨수소</td>
                  </tr>
                  <tr>
                    <td>자신의 후대검정 성적이 일당증체량 0.8kg 미만이고, 근내지방도 2.0 미만인 것</td>
                    <td>아비소의 후대검정 성적이 일당증체량 0.8kg 미만이고, 근내지방도 2.0 미만인 것</td>
                  </tr>
                </table>
              </li>
              <li>씨암소
                <ul>
                  <li>아비소의 후대검정 성적이 일당증체량 0.8kg 미만이고, 근내 지방도 2.0미만인 것</li>
                </ul>
              </li>
            </ul>
          </li>
          <li>한우정액
            <ul>
              <li>규격기준
                <ul>
                  <li>한국종축개량협회에서 발행한 혈통등록증, 보통등록증 또는 고등등록증을 소지한 것</li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
      </li>
      <li>한우정액
        <ul>
          <li>생산능력기준
            <ul>
              <li>한우종우의 보증씨수소와 동일함</li>
            </ul>
          </li>
          <li>규격기준
            <ul>
              <li>축산법 제19조의 규정에 의한 정액 등 처리업체로부터 생산되고 한국종축개량협회에서 발행한 정액혈통 증명서를 소지한 것.</li>
            </ul>
          </li>
        </ul>
      </li>
      <li>한우수정란
        <ul>
          <li>생산능력기준
            <table className="tablestyle">
              <tr>
                <td>정액을 제공한 씨수소</td>
                <td>난자를 제공한 씨암소</td>
              </tr>
              <tr>
                <td>한우종우의 보증씨수소와 동일함</td>
                <td>한우종우의 씨암소와 동일함</td>
              </tr>
            </table>
          </li>
          <li>규격기준
            <ul>
              <li>축산법 제19조의 규정에 의한 정액 등 처리업체로부터 생산되고 한국종축개량협회에서 발행한 수정란 혈통증명서를 소지한 것.</li>
            </ul>
          </li>
        </ul>
      </li>
      <li>부칙
        <ul>
          <li>이 고시는 고시한 날로부터 시행한다.</li>
        </ul>
      </li>
    </ul>
    <h2>수입종축등의 생산능력·규격기준[1996. 12.30 농림부 고시 제 96-97호]</h2>
    <ul>
      <li>육우
        <ul>
          <li>종모우
            <ul>
              <li>수출국이 공인한 축종 또는 품종협회에서 인정하는 후대검정을 필하고 실제로 공용되는 종모우로써 생시., 이유시, 12개월령 체중중 2개형질이상이 상위 20%내에 드는 것</li>
            </ul>
          </li>
          <li>종빈우
            <ul>
              <li>수출국이 공인한 축종 또는 품종드록기관에 당대를 포함하여 3대조상까지 혈통등록 되어 있는 것</li>
            </ul>
          </li>
          <li>정액
            <ul>
              <li>종모우와 같은 것</li>
            </ul>
          </li>
          <li>수정란
            <ul>
              <li>부능력 : 수출국이 공인한 축종 또는 품종협회에서 인정하는 후대검정을 필하고 실제로 공용되는 종모우로써 생시, 이유시, 12개월령 체중중 2개형질이상이 상취 5%내에 드는 것 </li>
              <li>모능력 : 종빈우와 같음.</li>
            </ul>
          </li>
        </ul>
      </li>
      <li>젖소
        <ul>
          <li>종모우
            <ul>
              <li>검정필 종모우(보증종모우)
                <table className="tablestyle">
                  <tr>
                    <td>국별</td>
                    <td>형질</td>
                    <td>능력기준</td>
                    <td>임계능력</td>
                    <td>신뢰도</td>
                  </tr>
                  <tr>
                    <td rowSpan="4">미국</td>
                    <td>유량(PTAM)</td>
                    <td rowSpan="4">상위 30%이상 선택 2</td>
                    <td rowSpan="4">상위 50%이상</td>
                    <td rowSpan="4">65%이상</td>
                  </tr>
                  <tr>
                    <td>지방량(PTAF)</td>
                  </tr>
                  <tr>
                    <td>단백질량(PTAP)</td>
                  </tr>
                  <tr>
                    <td>체형(PTAT)</td>
                  </tr>
                  <tr>
                    <td rowSpan="4">캐나다</td>
                    <td>유량(PTAM)</td>
                    <td rowSpan="4">상위 10%이상 선택 2</td>
                    <td rowSpan="4">상위 50%이상</td>
                    <td rowSpan="4">65%이상</td>
                  </tr>
                  <tr>
                    <td>지방량(PTAF)</td>
                  </tr>
                  <tr>
                    <td>단백질량(PTAP)</td>
                  </tr>
                  <tr>
                    <td>체형(PTAT)</td>
                  </tr>
                  <tr>
                    <td rowSpan="4">일본</td>
                    <td>유량(PTAM)</td>
                    <td rowSpan="4">상위 10%이상 선택 2</td>
                    <td rowSpan="4">상위 50%이상</td>
                    <td rowSpan="4">65%이상</td>
                  </tr>
                  <tr>
                    <td>지방량(PTAF)</td>
                  </tr>
                  <tr>
                    <td>단백질량(PTAP)</td>
                  </tr>
                  <tr>
                    <td>체형(PTAT)</td>
                  </tr>
                  <tr>
                    <td rowSpan="4">호주</td>
                    <td>유량(PTAM)</td>
                    <td rowSpan="4">상위 5%이상 선택 2</td>
                    <td rowSpan="4">상위 20%이상</td>
                    <td rowSpan="4">65%이상</td>
                  </tr>
                  <tr>
                    <td>지방량(PTAF)</td>
                  </tr>
                  <tr>
                    <td>단백질량(PTAP)</td>
                  </tr>
                  <tr>
                    <td>체형(PTAT)</td>
                  </tr>
                </table>
                <p>* 유방형질(UDC, MS, 유기 등)의 능력기준도 상위 50%이상이어야 함</p>
              </li>
              <li>등록종모우
                <table className="tablestyle">
                  <tr>
                    <td>국별</td>
                    <td></td>
                    <td>형질</td>
                    <td>능력기준</td>
                    <td>임계능력</td>
                    <td>신뢰도</td>
                  </tr>
                  <tr>
                    <td rowSpan="4">미국</td>
                    <td rowSpan="4">부</td>
                    <td>유량(PTAM)</td>
                    <td rowSpan="4">상위 10%이상 선택 2</td>
                    <td rowSpan="4">상위 30%이상</td>
                    <td rowSpan="4">8%이상</td>
                  </tr>
                  <tr>
                    <td>지방량(PTAF)</td>
                  </tr>
                  <tr>
                    <td>단백질량(PTAP)</td>
                  </tr>
                  <tr>
                    <td>체형(PTAT)</td>
                  </tr>
                  <tr>
                    <td rowSpan="4"></td>
                    <td rowSpan="4">모</td>
                    <td>유량(PTAM)</td>
                    <td rowSpan="4">상위 20%이상 선택 2</td>
                    <td rowSpan="4">상위 30%이상</td>
                    <td rowSpan="4">45%이상</td>
                  </tr>
                  <tr>
                    <td>지방량(PTAF)</td>
                  </tr>
                  <tr>
                    <td>단백질량(PTAP)</td>
                  </tr>
                  <tr>
                    <td>체형(PTAT)</td>
                  </tr>
                  <tr>
                    <td rowSpan="4">캐나다</td>
                    <td rowSpan="4">부</td>
                    <td>유량(PTAM)</td>
                    <td rowSpan="4">상위 5%이상 선택 2</td>
                    <td rowSpan="4">상위 30%이상</td>
                    <td rowSpan="4">8%이상</td>
                  </tr>
                  <tr>
                    <td>지방량(PTAF)</td>
                  </tr>
                  <tr>
                    <td>단백질량(PTAP)</td>
                  </tr>
                  <tr>
                    <td>체형(PTAT)</td>
                  </tr>
                  <tr>
                    <td rowSpan="4"></td>
                    <td rowSpan="4">모</td>
                    <td>유량(PTAM)</td>
                    <td rowSpan="4">상위 10%이상 선택 2</td>
                    <td rowSpan="4">상위 30%이상</td>
                    <td rowSpan="4">45%이상</td>
                  </tr>
                  <tr>
                    <td>지방량(PTAF)</td>
                  </tr>
                  <tr>
                    <td>단백질량(PTAP)</td>
                  </tr>
                  <tr>
                    <td>체형(PTAT)</td>
                  </tr>
                  <tr>
                    <td rowSpan="4">일본</td>
                    <td rowSpan="4">부</td>
                    <td>유량(PTAM)</td>
                    <td rowSpan="4">상위 5%이상 선택 2</td>
                    <td rowSpan="4">상위 30%이상</td>
                    <td rowSpan="4">8%</td>
                  </tr>
                  <tr>
                    <td>지방량(PTAF)</td>
                  </tr>
                  <tr>
                    <td>단백질량(PTAP)</td>
                  </tr>
                  <tr>
                    <td>체형(PTAT)</td>
                  </tr>
                  <tr>
                    <td rowSpan="4"></td>
                    <td rowSpan="4">모</td>
                    <td>유량(PTAM)</td>
                    <td rowSpan="4">상위 10%이상 선택 2</td>
                    <td rowSpan="4">상위 30%이상</td>
                    <td rowSpan="4">45%</td>
                  </tr>
                  <tr>
                    <td>지방량(PTAF)</td>
                  </tr>
                  <tr>
                    <td>단백질량(PTAP)</td>
                  </tr>
                  <tr>
                    <td>체형(PTAT)</td>
                  </tr>
                  <tr>
                    <td rowSpan="4">호주</td>
                    <td rowSpan="4">부</td>
                    <td>유량(PTAM)</td>
                    <td rowSpan="4">상위 5%이상 선택 2</td>
                    <td rowSpan="4">상위 10%이상</td>
                    <td rowSpan="4">45%이상</td>
                  </tr>
                  <tr>
                    <td>지방량(PTAF)</td>
                  </tr>
                  <tr>
                    <td>단백질량(PTAP)</td>
                  </tr>
                  <tr>
                    <td>체형(PTAT)</td>
                  </tr>
                  <tr>
                    <td rowSpan="4"></td>
                    <td rowSpan="4">모</td>
                    <td>유량(PTAM)</td>
                    <td rowSpan="4">상위 5%이상 선택 2</td>
                    <td rowSpan="4">상위 10%이상</td>
                    <td rowSpan="4">45%이상</td>
                  </tr>
                  <tr>
                    <td>지방량(PTAF)</td>
                  </tr>
                  <tr>
                    <td>단백질량(PTAP)</td>
                  </tr>
                  <tr>
                    <td>체형(PTAT)</td>
                  </tr>
                </table>
                <p>* 유방형질(UDC, MS, 유기 등)의 능력기준도 상위 50%이상이어야 함</p>
              </li>
            </ul>
          </li>
          <li>종빈우
            <ul>
              <li>혈통등록우로써 다음 기준에 적합한 것
                <table className="tablestyle">
                  <tr>
                    <td>국별</td>
                    <td></td>
                    <td>형질</td>
                    <td>능력기준</td>
                    <td>임계능력</td>
                    <td>신뢰도</td>
                  </tr>
                  <tr>
                    <td rowSpan="4">미국</td>
                    <td rowSpan="4">부</td>
                    <td>유량(PTAM)</td>
                    <td rowSpan="4">상위 30%이상 선택 2</td>
                    <td rowSpan="4">상위 50%이상</td>
                    <td rowSpan="4">65%이상</td>
                  </tr>
                  <tr>
                    <td>지방량(PTAF)</td>
                  </tr>
                  <tr>
                    <td>단백질량(PTAP)</td>
                  </tr>
                  <tr>
                    <td>체형(PTAT)</td>
                  </tr>
                  <tr>
                    <td rowSpan="4"></td>
                    <td rowSpan="4">모</td>
                    <td>유량(PTAM)</td>
                    <td rowSpan="4">상위 30%이상 선택 2</td>
                    <td rowSpan="4">상위 50%이상</td>
                    <td rowSpan="4">-</td>
                  </tr>
                  <tr>
                    <td>지방량(PTAF)</td>
                  </tr>
                  <tr>
                    <td>단백질량(PTAP)</td>
                  </tr>
                  <tr>
                    <td>체형(PTAT)</td>
                  </tr>
                  <tr>
                    <td rowSpan="4">캐나다</td>
                    <td rowSpan="4">부</td>
                    <td>유량(PTAM)</td>
                    <td rowSpan="4">상위 10%이상 선택 2</td>
                    <td rowSpan="4">상위 50%이상</td>
                    <td rowSpan="4">65%이상</td>
                  </tr>
                  <tr>
                    <td>지방량(PTAF)</td>
                  </tr>
                  <tr>
                    <td>단백질량(PTAP)</td>
                  </tr>
                  <tr>
                    <td>체형(PTAT)</td>
                  </tr>
                  <tr>
                    <td rowSpan="4"></td>
                    <td rowSpan="4">모</td>
                    <td>유량(PTAM)</td>
                    <td rowSpan="4">상위 10%이상 선택 2</td>
                    <td rowSpan="4">상위 50%이상</td>
                    <td rowSpan="4">-</td>
                  </tr>
                  <tr>
                    <td>지방량(PTAF)</td>
                  </tr>
                  <tr>
                    <td>단백질량(PTAP)</td>
                  </tr>
                  <tr>
                    <td>체형(PTAT)</td>
                  </tr>
                  <tr>
                    <td rowSpan="4">일본</td>
                    <td rowSpan="4">부</td>
                    <td>유량(PTAM)</td>
                    <td rowSpan="4">상위 10%이상 선택 2</td>
                    <td rowSpan="4">상위 50%이상</td>
                    <td rowSpan="4">65%</td>
                  </tr>
                  <tr>
                    <td>지방량(PTAF)</td>
                  </tr>
                  <tr>
                    <td>단백질량(PTAP)</td>
                  </tr>
                  <tr>
                    <td>체형(PTAT)</td>
                  </tr>
                  <tr>
                    <td rowSpan="4"></td>
                    <td rowSpan="4">모</td>
                    <td>유량(PTAM)</td>
                    <td rowSpan="4">상위 10%이상 선택 2</td>
                    <td rowSpan="4">상위 50%이상</td>
                    <td rowSpan="4">-</td>
                  </tr>
                  <tr>
                    <td>지방량(PTAF)</td>
                  </tr>
                  <tr>
                    <td>단백질량(PTAP)</td>
                  </tr>
                  <tr>
                    <td>체형(PTAT)</td>
                  </tr>
                  <tr>
                    <td rowSpan="4">호주</td>
                    <td rowSpan="4">부</td>
                    <td>유량(PTAM)</td>
                    <td rowSpan="4">상위 5%이상 선택 2</td>
                    <td rowSpan="4">상위 20%이상</td>
                    <td rowSpan="4">65%이상</td>
                  </tr>
                  <tr>
                    <td>지방량(PTAF)</td>
                  </tr>
                  <tr>
                    <td>단백질량(PTAP)</td>
                  </tr>
                  <tr>
                    <td>체형(PTAT)</td>
                  </tr>
                  <tr>
                    <td rowSpan="4"></td>
                    <td rowSpan="4">모</td>
                    <td>유량(PTAM)</td>
                    <td rowSpan="4">상위 5%이상 선택 2</td>
                    <td rowSpan="4">상위 20%이상</td>
                    <td rowSpan="4">-</td>
                  </tr>
                  <tr>
                    <td>지방량(PTAF)</td>
                  </tr>
                  <tr>
                    <td>단백질량(PTAP)</td>
                  </tr>
                  <tr>
                    <td>체형(PTAT)</td>
                  </tr>
                </table>
                <p>* 유방형질(UDC, MS, 유기 등)의 능력기준도 상위 50%이상이어야 함. <br/>* 임신우의 경우 교배종모우의 능력은 부의 능력과 동일하여야 함.</p>
              </li>
            </ul>
          </li>
          <li>정액
            <table className="tablestyle">
              <tr>
                <td>국별</td>
                <td>형질</td>
                <td>능력기준</td>
                <td>임계능력</td>
                <td>신뢰도</td>
              </tr>
              <tr>
                <td rowSpan="4">네덜란드</td>
                <td>유량(BVM)</td>
                <td rowSpan="4">상위 20%이상 선택 2</td>
                <td rowSpan="4">상위 50%이상</td>
                <td rowSpan="4">65%이상</td>
              </tr>
              <tr>
                <td>지방량(BVF)</td>
              </tr>
              <tr>
                <td>단백질량(BVP)</td>
              </tr>
              <tr>
                <td>체형(BVT)</td>
              </tr>
              <tr>
                <td>미국</td>
                <td rowSpan="4" colSpan="4">검정필 종모우(보증종모우) 기준과 동일</td>
              </tr>
              <tr>
                <td>캐나다</td>
              </tr>
              <tr>
                <td>일본</td>
              </tr>
              <tr>
                <td>호주</td>
              </tr>
            </table>
            <p>* 유방형질(UDC, MS, 유기 등)의 능력기준도 상위 50%이상이어야 함.</p>
          </li>
          <li>수정란
            <table className="tablestyle">
              <tr>
                <td>국별</td>
                <td></td>
                <td>형질</td>
                <td>능력기준</td>
                <td>임계능력</td>
                <td>신뢰도</td>
              </tr>
              <tr>
                <td rowSpan="4">네덜란드</td>
                <td rowSpan="4">부</td>
                <td>유량(BVM)</td>
                <td rowSpan="4">상위 50%이상 선택 2</td>
                <td rowSpan="4">상위 30%이상</td>
                <td rowSpan="4">80%이상</td>
              </tr>
              <tr>
                <td>지방량(BVF)</td>
              </tr>
              <tr>
                <td>단백질량(BVP)</td>
              </tr>
              <tr>
                <td>체형(BVT)</td>
              </tr>
              <tr>
                <td rowSpan="4"></td>&quot;
                <td rowSpan="4">모</td>
                <td>유량(BVM)</td>
                <td rowSpan="4">상위 10%이상 선택 2</td>
                <td rowSpan="4">상위 30%이상</td>
                <td rowSpan="4">45%이상</td>
              </tr>
              <tr>
                <td>지방량(BVF)</td>
              </tr>
              <tr>
                <td>단백질량(BVP)</td>
              </tr>
              <tr>
                <td>체형(BVT)</td>
              </tr>
              <tr>
                <td colSpan="2">미국, 캐나다, 호주, 일본</td>
                <td colSpan="4">등록종모우 기준과 동일</td>
              </tr>
            </table>
            <p>* 유방형질(UDC, MS, 유기 등)의 능력기준도 상위 50%이상이어야 함</p>
          </li>
          <li>기타
            <ul>
              <li>유전적 불량인자의 유전장원을 수입에서 제외함 </li>
              <li>모든 유전능력 자료는 수출국의 홀스타인 품종관련협회가 제공하는 것으로 함 </li>
              <li>임계능력이란 선택에서 빠지는 형질의 최소 유전능력을 말함</li>
              <li>규격의 적용시기는 L/C 개설 당해 연도의 수출국 발표성적을 적용함</li>
            </ul>
          </li>
        </ul>
      </li>
      <li>종돈
        <ul>
          <li>종모돈
            <ul>
              <li>수출국이 공인한 종돈 등록기관의 당대를 포함한 3대조상 이상 혈통등록을 필하고, </li>
              <li> 수출국이 공인한 능력검정기관에서 능력검정성적을 인정받은 것으로 능력검정성적이 체중 30∼90kg 보정치 기준 일당증체량 950g 이상 또는 체중 90kg 도달일령 145일 이하, 배장근 단면적 39.0㎠이상 또는 생체정육율 60%이상. 사료요구율 2.40이하, 등지방두께 1.60cm이하의 4개 항목중 2개항목이상이 적합한 것, 단, 배장근단면적과 생체정육율은 체중 105kg 기준임. </li>
              <li>당대검정성적이 없는 어린 후보종모돈은 아비의 능력이 앞서 기술한 종모돈 능력기준에 적합하여야 함.</li>
            </ul>
          </li>
          <li>종빈돈
            <ul>&quot;가&quot;
              <li>수출국이 공인한 종돈등록 기관에 당대를 포함하여 3대 조상이상 혈통등록을 필한 것.
                <ul>
                  <li>임신돈의 경우 수출국이 공인한 종돈등록기관이 위 항의 요건을 갖춘 종모돈과의 교배에 의하여 임신된 것임을 증명한 것</li>
                </ul>
              </li>
            </ul>
          </li>
          <li>정액
            <ul>
              <li>수출국이 공인한 종돈등록기관이 &quot;가&quot;항의 요건을 갖춘 종모돈에서 생산된 정액임을 증명한 것</li>
            </ul>
          </li>
          <li>수정란
            <ul>
              <li>수출국이 공인한 종돈등록기관이 &quot;가&quot;항 및 &quot;나&quot;항의 요건을 갖춘 종모돈 및 종빈돈에서 생산된 수정란임을 증명한 것.</li>
            </ul>
          </li>
          <li>기타
            <ul>
              <li>등지방두께 측정부위는 제 4늑골, 최후늑골, 최후요추의 3부위 측정치평균 </li>
              <li>등심단면적은 최후늑골부의 측정치</li>
            </ul>
          </li>
        </ul>
      </li>
      <li>종계 및 종란
        <ul>
          <li>종계 및 종란
            <ul>
              <li>사단법인 대한 양계협회에서 시행하는 닭 경제능력검정을 받아 그 성적이 인정된 계종으로써 수출국 또는 수출육종회사에서 발행한 계통보증서가 첨부된 것 </li>
              <li>수입신고대상은 다음과 같다. (단, 사단법인 대한양계협회의 닭경제능력검정을 받고자 수입하는 종란은 계종 제한을 받지 않는다.)
                <ul>
                  <li>산란계 : 하이란인 백색, 하이라인 갈색, 이사브라운, 바브콕, 닉칙백색, 세이버스타크로스 578, 데칼브와렌, 하이섹스갈색, 로만백색, 로만갈색, 아베에이카브라운, 와렌엑셀, 사이로, 데칼브브라운, 바브콕B-300, 바브콕 B-380, 테트라하코, 테트라 SL, 보반스브라운, 브라운닉. </li>
                  <li>육용계 : 하이브로, 인디안리버, 하바드, 아바에이카, 로스, 코브, 피터슨, 블렉탬플, 베데트, 세이버스타브로, 로만, 에이비안, 타툼, 세이버레드브로, 싸쏘 431, 싸쏘 551, 하이와이, 케이스팜, 세이버데드스타, JA 57, MPK</li>
                </ul>
              </li>
            </ul>
          </li>
          <li>기타
            <ul>
              <li>상기계종 외에 기준고시 이후 사단법인 대한양계협회의 닭경제 능력검정을 받아 성적이 인정된 계종에 대하여는 대한양계협회의 확인을 받아 수입할 수 있음.</li>
            </ul>
          </li>
        </ul>
      </li>
      <li>부칙
        <ul>
          <li>(시행일) 이 고시는 고시한 날부터 시행한다. </li>
          <li>(경과조치) 농림수산부 고시 제 1995-110(&apos;95.12.26)는 이를 폐지한다.</li>
        </ul>
      </li>
    </ul>
  </div>
  );
}

export default Do6;
