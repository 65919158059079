import React from 'react';

import Pic483 from 'assets/images/sub/_images/pic483.jpg';
import Pic484 from 'assets/images/sub/_images/pic484.jpg';
import Pic485 from 'assets/images/sub/_images/pic485.jpg';
import Pic486 from 'assets/images/sub/_images/pic486.jpg';
import Pic487 from 'assets/images/sub/_images/pic487.jpg';

function Do81() {

  return (
    <div className="techinfoview">
				<h1>제 1위 과산증</h1>
				<p className="pstyle">곡류와 같은 탄수화물을 한꺼번에 많이 섭취한 경우, 유산(乳酸)의 과잉 생산으로 제1위내는 산성증을 초래하여 유효미생물의 사멸과 활성의 저하로 인해 소화장애를 일으키는 병이다.</p>
				<div className="leftpart">
					<h2>원인</h2>
					<ul>
						<li> 탄수화물 함량이 높은 사료(곡류, 맥주박, 보리, 옥수수 등)를 포식하거나 훔쳐먹은 경우 </li>
						<li>건초 급여에서 곡류 또는 농후사료로 갑자기 바꿀 경우에 일어난다.</li>
					</ul>
					<h2>증상</h2>
					<ul>
						<li>섭취한 사료의 질이나 양에 따라 다소 다르다. 
							<ul>
								<li>식욕이 줄고 탈수 현상이 일어나며 시큼한 설사변을 배설한다. </li>
								<li>가벼운 경우는 일시적인 식욕부진, 제1위 운동의 감퇴, 연변(軟便)을 배설 </li>
								<li>심한 경우는 식욕과 제1위운동의 정지, 시큼한 냄새의 설사를 계속하여 안구가 들어가고, </li>
								<li>더욱 진행되면 보행이 불안하며 기립불능과 혼수상태에 빠져 폐사한다.</li>
								<li>이 병에 관련되어 제엽염(蹄葉炎)을 일으키는 일이 있다.  </li>
							</ul>
						</li>
					</ul>
					<h2>목장에서의 처치</h2>
					<ul>
						<li>설사 증상이 나타나면 곡물 등 탄수화물 사료 급여를 중지하고 양질의 건초나 볏짚만 급여한다. </li>
						<li> 탄수화물 함량이 많은 사료를 급여할 경우에는 여러날에 걸쳐 서서히 바꾸도록 한다. 경증의 경우에는 사료의 교환만으로 3∼4일째에는 회복한다. </li>
						<li>증세가 심한 경우에는 병세 진행이 빠르며, 늦어지면 폐사하므로 즉시 수의사의 진찰을 받도록 한다. </li>
						<li>경증인 때는 알칼리제(소다류 300∼500g)를 먹이거나 건강한 소의 1위액을 먹이면 좋은 효과를 볼 수 있다. </li>
						<li>중증일 경우는 외과적 수술이 가장 유효하다.  </li>
					</ul>
				</div>
				<div className="rightpart">
					<img src={Pic483} className="nonborder" alt=""/>
					<p>*사료의 갑작스러운 변화로 제1위 과산증을 일으켜 허탈상태에 빠진 육성우 </p>
					<img src={Pic484} className="nonborder" alt=""/>
					<p>* 시큼한 냄새의 설사변 배설 </p>
					<img src={Pic485} className="nonborder" alt=""/>
					<p>*심한 탈수증으로 안구가 들어간 모습 </p>
					<img src={Pic486} className="nonborder" alt=""/>
					<p>*제1위 과산증으로 생긴 제1위의 점막궤양 </p>
					<img src={Pic487} className="nonborder" alt=""/>
					<p>*좌측은 정상적인 제1위액, 우측은 과산증 소로부터 채취한 제1위액으로 부유물도 침전물도 없다.</p>
				</div>
			</div>
  );
}

export default Do81;