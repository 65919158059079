import React from 'react';

import pic767 from 'assets/images/sub/_images/pic767.jpg';
import pic768 from 'assets/images/sub/_images/pic768.jpg';
import pic769 from 'assets/images/sub/_images/pic769.jpg';

function Do167() {

  return (
    <div className="techinfoview">
      <h1>심내막염</h1>
      <div className="leftpart">
        <h2>원인</h2>
        <ul>
          <li>소에서 각종 화농성 질환의 원인이 되는 화농간균과 연쇄구균등이 원래의 병소(예, 유방염, 자궁염, 관절염 등)에서 혈류를 타고 심장에 흘러 들어가 심장 판막이나 그 부근의 심장 내막에 정착, 증식을 일으켜 혈류의 순환장애를 일으키는 질병이다. </li>
        </ul>
        <h2>증상</h2>
        <ul>
          <li>식욕부진과 발열(發熱)이 계속된다. 특히 열은 항생제 투여시에는 일시적으로 내리나, 투약을 중지하면 다시 열이 오른다. </li>
          <li>우측 3∼4 늑골사이. 견단선(肩端線) 약간 아랫쪽에서 심장 잡음이 청진된다. </li>
          <li>경정맥이 뚜렷하게 부푼다. </li>
          <li>말기에는 호흡곤란과 폐염이 함께 발생한다. </li>
        </ul>
        <h2>목장에서의 처치</h2>
        <ul>
          <li>근본적인 치료는 불가능하며 본병을 일으키는 원인이 되는 질병, 즉 자궁염, 유방염, 신장염, 관절염 등 대부분이 분만후에 발생하는 감염증으로서 이러한 질병의 조기퇴치가 중요하다. </li>
          <li>본병으로 진단되면 페니실린의 투여로 일시적으로 악화를 억제할 수 있으나 근본적인 치료는 어려우므로 도태시키는 것이 현명하다.</li>
        </ul>
      </div>
      <div className="rightpart">
        <img src={pic767} className="nonborder" alt=""/>
        <img src={pic768} className="nonborder" alt=""/>
        <img src={pic769} className="nonborder" alt=""/>
      </div>
    </div>
  );
}

export default Do167;