import React from 'react';
import reportData from './ReportData';
import './Table.scss';

function Table() {
  return (
    <div className="tableCon">
      <h2 className="tableCon_title">농가제공 검정 성적표 일람</h2>
      {/* <div className="scroll">스크롤 하실 수 있습니다.</div> */}
      <div className="table_container">
        <table className="table_box">
          <thead>
            <tr>
              <th>구분</th>
              <th>주요내용</th>
            </tr>
          </thead>
          <tbody>
            {reportData.map((item, index) => (
              <tr key={index}>
                <td>{`${item.id}. ${item.category}`}</td>
                <td>
                  {item.content &&
                    item.content.map((text, i) => (
                      <span key={i}>- {text}</span>
                    ))}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Table;
