import React from 'react';

// 이미지
import geneHowto1 from 'assets/images/gene/use-01-01.png';
import geneHowto2 from 'assets/images/gene/use-02-back.png';

// scss
import './geneHowto.scss';

function GeneHowto() {
  return (
    <div className='geneHowto'>
      <div className='geneHowtoInner'>
        <h2>유전체 분석 결과 조회 방법</h2>
        <ul className='howtoList'>
          <li>
            <img src={geneHowto1} alt='유전체 분석 결과 조회 방법' />
            <ul>
              <li><span>1: 개체 정보:</span> 관리번호, 생년월일, 1산 유량</li>
              <li><span>2: 평가 구분:</span> -GE-EBV는 개체 성적 및 유전체 정보를 사용하여 예측 된 유전체 육종가<br/>-GPA는 아비, 어미의 평가 성적 및 개체 유전체 정보를 사용하여 예측 된 유전체 육종가<br/>-GPI는 아비, 어미의 아비 평가 성적 및 개체 유전체 정보를 사용하여 예측 된 유전체 육종가</li>
              <li><span>3: 유전체 육종가:</span> 생산, 체형 및 종합 점수 유전체 육종가</li>
              <li><span>4: A2 유전자:</span> 베타카제인&#40;B-Casein&#41;</li>
              <li><span>5: 개체 세부 정보:</span> 개체 세부 유전체 육종가 정보&#40;개체 관리 번호 클릭시&#41;</li>
            </ul>
          </li>
          <li>
            <img src={geneHowto2} alt='유전체 분석 결과 조회 방법' />
            <ul>
              <li><span>1: 개체 정보:</span> 명호, 생년월일, 혈통정보</li>
              <li><span>2: 유용 및 유전 질병:</span> A2 유전자 등 정보</li>
              <li><span>3: 유전체 육종가:</span> -생산, 체형 유전체 육종가, 지수 및 신뢰도<br/>-개체의 각 형질 별 탱크<br/>※탱크는 낮을 수록 상위권</li>
              <li><span>4: 표준화 육종가:</span> 체형 및 형질 표준화 육종가</li>
            </ul>
          </li>
        </ul>
        <div className='howtoInfo'>
          <h3>유전질병 및 유전자 정보</h3>
          <div className='geneTable'>
            <table>
              <thead>
                <tr>							
                  <th>순번</th>
                  <th>명칭</th>
                  <th colSpan="3">형태</th>		
                </tr>
              </thead>
              <tbody>
                <tr>				
                  <td>1</td>
                  <td>A2유전자</td>
                  <td>A1A1</td>
                  <td>A1A2</td>
                  <td>A2A2</td>
                </tr>
                <tr>				
                  <td>2</td>
                  <td>소백혈구 흡착 결핍증</td>
                  <td>BLADF</td>
                  <td>BLAD</td>
                  <td>BLAD</td>
                </tr>
                <tr>				
                  <td>3</td>
                  <td>시트룰린혈증</td>
                  <td>CTF</td>
                  <td>CT</td>
                  <td>CT</td>
                </tr>
                <tr>				
                  <td>4</td>
                  <td>복합척추기형</td>
                  <td>CVMF</td>
                  <td>CVM</td>
                  <td>CVM</td>
                </tr>
                <tr>				
                  <td>5</td>
                  <td>UMP 합성효소 결핍증</td>
                  <td>DUMPSF</td>
                  <td>DUMPS</td>
                  <td>DUMPS</td>
                </tr>
                <tr>				
                  <td>6</td>
                  <td>우성 레드</td>
                  <td>BL</td>
                  <td>DRC</td>
                  <td>DR</td>
                </tr>
                <tr>				
                  <td>7</td>
                  <td>FACTOR XI</td>
                  <td>FXIF</td>
                  <td>FXI</td>
                  <td>FXI</td>
                </tr>
                <tr>				
                  <td>8</td>
                  <td>홀스테인 하플로타입1</td>
                  <td>HH1F</td>
                  <td>HH1</td>
                  <td>HH1</td>
                </tr>
                <tr>				
                  <td>9</td>
                  <td>홀스테인 하플로타입3</td>
                  <td>HH3F</td>
                  <td>HH3</td>
                  <td>HH3</td>
                </tr>
                <tr>				
                  <td>10</td>
                  <td>홀스테인 하플로타입4</td>
                  <td>HH4F</td>
                  <td>HH4</td>
                  <td>HH4</td>
                </tr>
                <tr>				
                  <td>11</td>
                  <td>각막염 고각막염</td>
                  <td>IBKF</td>
                  <td>IBK</td>
                  <td>IBK</td>
                </tr>
                <tr>				
                  <td>12</td>
                  <td>모생유전자</td>
                  <td>BL</td>
                  <td>RC</td>
                  <td>RED</td>
                </tr>
                <tr>				
                  <td>13</td>
                  <td>Mule Foot</td>
                  <td>MFF</td>
                  <td>MF</td>
                  <td>MF</td>
                </tr>
                <tr>				
                  <td>14</td>
                  <td>Mule Foot</td>
                  <td>MFF</td>
                  <td>MF</td>
                  <td>MF</td>
                </tr>
              </tbody>
            </table>
          </div>
          <p>※형태의 마지막 F&#40;Free&#41;로 해당 유전자에 질병 또는 유용유전자 정보가 없음. *는 해당 유전자 보유&#40;Carrier&#41;를 의미</p>
        </div>
      </div>
    </div>
  );
}

export default GeneHowto;
