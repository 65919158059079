import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { getState, postState } from 'utils/api';

function MstCodeEdit() {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const mCode = searchParams.get('m_code');

  const [formData, setFormData] = useState({
    m_code: mCode || '',
    m_name: '',
    m_desc: '',
  });

  useEffect(() => {
    const fetchMstCodeData = async () => {
      if (mCode) {
        try {
          const response = await getState('/board/select_master_code', {
            m_code: mCode,
          });

          console.log(response);

          if (response?.return_code === 'SUCCESS') {
            setFormData({
              m_code: response.result.m_code,
              m_name: response.result.m_name,
              m_desc: response.result.m_desc,
            });
          }
          if (response?.return_code === 'ERROR') {
            alert(response?.return_message);
          }
        } catch (error) {
          console.error('Error fetching master code data:', error);
        }
      }
    };

    fetchMstCodeData();
  }, [mCode]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    // Validation
    if (!formData.m_code) {
      alert('코드번호를 입력해주세요.');
      return;
    }

    if (!formData.m_name) {
      alert('코드이름을 입력해주세요.');
      return;
    }

    const confirmMessage = mCode ? '수정하시겠습니까?' : '등록하시겠습니까?';
    if (!window.confirm(confirmMessage)) {
      return;
    }

    try {
      const endpoint = mCode
        ? '/board/update_master_code/'
        : '/board/insert_master_code/';

      const response = await postState(endpoint, formData);

      if (response?.return_code === 'SUCCESS') {
        alert(mCode ? '수정되었습니다.' : '등록되었습니다.');
        navigate('/bo/code/mstCodeList');
      }
      if (response?.return_code === 'ERROR') {
        alert(response?.return_message);
      }
    } catch (error) {
      console.error('Error saving master code:', error);
      alert('저장 중 오류가 발생했습니다.');
    }
  };

  return (
    <div className="bo-page">
      <h2>주코드 {mCode ? '수정' : '등록'}</h2>
      <div className="table-container bold-border">
        <table className="table_form">
          <colgroup>
            <col width="20%" />
            <col width="80%" />
          </colgroup>
          <tbody>
            <tr>
              <th>
                주 코드번호<span className="required">*</span>
              </th>
              <td>
                {mCode ? (
                  <span>{mCode}</span>
                ) : (
                  <input
                    type="text"
                    name="m_code"
                    value={formData.m_code}
                    onChange={handleInputChange}
                    maxLength={10}
                  />
                )}
              </td>
            </tr>
            <tr>
              <th>
                주 코드이름<span className="required">*</span>
              </th>
              <td>
                <input
                  type="text"
                  name="m_name"
                  value={formData.m_name}
                  onChange={handleInputChange}
                  maxLength={50}
                />
              </td>
            </tr>
            <tr>
              <th>주 코드설명</th>
              <td>
                <input
                  type="text"
                  name="m_desc"
                  value={formData.m_desc}
                  onChange={handleInputChange}
                  maxLength={50}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="button-group">
        <button type="button" className="btn-l" onClick={handleSubmit}>
          주코드 {mCode ? '수정' : '등록'}
        </button>
        <button
          type="button"
          className="btn-l"
          onClick={() => navigate('/bo/code/mstCodeList')}
        >
          주코드 목록
        </button>
      </div>
    </div>
  );
}

export default MstCodeEdit;
