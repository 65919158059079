import React from 'react';

function Fo36() {

  return (
    <div className="techinfo_view">
				<p className="viewTitle">방목지 고창증</p>
				<dl>
					<dt>발병대상</dt>
					<dd>반추동물</dd>
				</dl>
				
				<dl>
					<dt>원인</dt>
					<dd>
						<p>고창증은 반추위내 가스를 제거하는 능력의 저하로 일어난다. 반추위내 트림을 하기 위한 조사료원의 부족, 또한 과다한 발효에 의한 거품의 생성들이 방목지 고창증의 주원인이다. 방목지 고창증은 급 성장을 하고있는 두과식물과 밀 재배 방목지의 어린 식물을 섭취하였을 때 일반적으로 자주 발생한다. 방목지 고창증은 몇가지 요인 즉, 식물과 가축 그리고 미생물에 의한 상호작용으로 일어나는 포말성 고창증(frothy bloat)이다. 식물의 가용성 단백질과 사포닌이 거품 생성을 저해시키는데 중요한 역할을 한다. 또한 요소 비료를 방목지에 많이 사용하면 고창증을 줄일 수도 있다.</p>
						<p>어떤 특정 사료에 대하여 고창증을 일으키는 가축은 만성 고창증 동물로 알려져 있다. 이러한 가축들은 유전적 영향인지도 모르지만 발효가스를 트림으로 배출하는 것이 불가능하다. 그 원인은 생리적으로 비정상인데 그 원인이 있다. </p>
					</dd>
				</dl>
				
				<dl>
					<dt>징후 및 증상</dt>
					<dd>소대퇴골 전면부 좌측 위의 팽창이 첫 번째로 관찰된다. 이 증상에 이어서 우측면의 팽창, 항문의 돌출, 호흡의 불규칙, 혀의 청색증, 몸부림등의 증상을 보이며, 이때 적절한 조치를 취하지 않으면 죽게 된다. 방목초지에 방목시 30분 이내로 사망하는 경우도 있다.</dd>
				</dl>
				
				<dl>
					<dt>질병분포와 손실규모</dt>
					<dd>광범위하게 발생한다. 그러나 지역에 따라서 발병빈도가 다르기 때문에 그에 따른 치사율도 다르다. 미국의 경우는 비육우와 젖소의 증체율 저하 및 산유량의 감소로 일년에 약 1억불 정도의 손실을 가져온다.</dd>
				</dl>
				
				<dl>
					<dt>치료</dt>
					<dd>
						<p>시간이 허용하는 한 수의사에게 의뢰한다. Stomach tube를 조심스럽게 삽입하여 치료하는 것이 반추위내 가스를 뽑아내는데 매우 유용하다. 투관침과 cannula를 사용하여 위에 구멍을 뚫어 치료하는 방법은 마지막으로 시도하고 응급시에는 수술칼을 사용할 수도 있다. 중증인 경우에는 아래와 같이 자가치료를 한다.</p> 
						<ul>
							<li>1) 걸음걸이와 움직임을 관찰한다.</li>
							<li>2) 가축에게 옥수수 기름, 대두유, 30∼60g의 Poloxalene 등을 강제로 먹인다</li>
						</ul>
					</dd>
				</dl>
				
				<dl>
					<dt>대책</dt>
					<dd>고창증이 자주 발생하면 사료를 바꾸어 보는 것이 현명한 방법이다. 두과식물로 인하여 고창증이 발생하였을 때에는 poloxalene, oxytetracyline, lauryl, ether등과 같은 고창증 치료제를 사용 설명서대로 사용하여야 한다.</dd>
				</dl>
				
				<dl>
					<dt>예방</dt>
					<dd>
						<ul>
							<li>1) 연속적으로 두과식물 초지나 어린 두과식물 초지에서 방목하는 것을 피한다.</li>
							<li>2) 방목지로 가축을 내보내기 전에 우선 조악한 건초를 먼저 급여하는 것이 좋다.</li>
							<li>3) 방목하면서 건초를 함께 급여한다.</li>
							<li>4) 공복시에 급히 사료를 급여하는 것을 피한다.</li>
							<li>5) 가축을 방목한 후에 한번은 방목지에 내보내는 것이 좋다.</li>
							<li>6) 어느 때라도 가축들이 소금과 물을 섭취할 수 있도록 놓아둔다.</li>
							<li>7) 서리내린 방목지는 피한다.</li>
							<li>8) Poloxalene. oxytetarcycline, Laureth-23등을 제조업체의 지시에 따라 사용한다. 또한 mineral block에 거품방지제를 첨가한 것을 방목지 여러 곳에 놓아둔다. 기름과 유지는 고창증을 조절하는 목적으로 호주와 뉴질랜드에서 널리 사용되어 왔다.</li>
						</ul>
					</dd>
				</dl>	
				
				<dl>
					<dt>참고</dt>
					<dd>두과목초 또는 곡류 방목초지 또는 알팔파 건초등은 다른 어떤 사료보다도 고창증을 일으킬 가능성이 높다. 두과 목초지는 특히 비 또는 이슬이 내린 후에 습도가 높을 때 그리고 어린 초지일 때 고창증을 유발하기 쉽다.</dd>
				</dl>
			</div>
  );
}

export default Fo36;
