import React from 'react';

import Pic547 from 'assets/images/sub/_images/pic547.jpg';
import Pic548 from 'assets/images/sub/_images/pic548.jpg';
import Pic549 from 'assets/images/sub/_images/pic549.jpg';

function Do100() {

  return (
    <div className="techinfoview">
      <h1>존씨병</h1>
      <p className="pstyle">결핵균과 같은 마이코박테리움(Myco Bacterium) 속(屬)의 파라 튜버클로시스라는 세균이 장(腸)에 감염됨으로써 발생하는 반추 가축(소와 양 등)의 질병이다. </p>
      <div className="leftpart">
        <h2>원인</h2>
        <ul>
          <li>감염은 원인균이 입을 통해 들어감으로써 일어난다. </li>
          <li>감염된 소의 분변으로 오염된 사료나 물을 섭취하여 감염된다.</li>
        </ul>
        <h2>증상</h2>
        <ul>
          <li>감염초기에는 증상이 나타나지 않고 6개월∼수년간 잠복기를 걸쳐, 소가 스트레스를 받아 저항력이 약화될 때 발병한다. 
            <ul>
              <li>분만후 수주일 이내에 갑자기 증상이 나타나는 경우가 많다. </li>
              <li>특별한 원인 없이 자주 설사를 되풀이 한다(변은 균질성이며 악취가 없고, 점액이나 혈액의 혼입은 볼 수 없다.) </li>
              <li>장기간 계속된 설사로 수척, 빈혈, 비유량의 감소를 볼 수있다. </li>
              <li>병의 말기에는 식욕부진, 안구함몰, 고도의 탈수 증상을 보이고 기립불능이 되며 결국은 폐사한다. </li>
            </ul>
          </li>
        </ul>
        <h2>목장에서의 처치</h2>
        <ul>
          <li>본병은 적절한 치료법이 없기 때문에 진단이 내려지면 도태해야 한다. </li>
          <li>따라서 의심이 가는 병우를 발견하면 수의사와 상담하여 즉시 격리 수용토록 한다. </li>
          <li>우사소독을 충분히 하고 배설된 분변은 태워버린다. </li>
        </ul>
      </div>
      <div className="rightpart">
        <img src={Pic547} className="nonborder" alt=""/>
        <img src={Pic548} className="nonborder" alt=""/>
        <img src={Pic549} className="nonborder" alt=""/>
      </div>
    </div>
  );
}

export default Do100;