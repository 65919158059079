import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ROUTE_PAGE } from 'constants/constants';

// 이미지
import home from 'assets/svgs/common/home_icon.svg';

// scss
import './sideBarPc.scss';

function SideBarPc() {
  // 사이드바 열고닫기
  const [twoDepthOpen, setTwoDepthOpen] = useState(false);
  const [threeDepthOpen, setThreeDepthOpen] = useState(false);

  const controlTwoDepth = () => {
    if (twoDepthOpen) {
      setTwoDepthOpen(false);
    } else {
      setTwoDepthOpen(true);
    }

    if (threeDepthOpen) {
      setThreeDepthOpen(false);
    }
  };

  const controlThreeDepth = () => {
    if (threeDepthOpen) {
      setThreeDepthOpen(false);
    } else {
      setThreeDepthOpen(true);
    }

    if (twoDepthOpen) {
      setTwoDepthOpen(false);
    }
  };

  // 경로 받아서 렌더링
  const location = useLocation();
  const route = location.pathname.split('/').filter(Boolean);
  const [oneDepth, twoDepth, threeDepth] = route;

  const renderTwoDepth = () => (
    <ul className="sideBarTwoDepth">
      {Object.keys(ROUTE_PAGE).map((key) => (
        <li key={key} className="twoDepthItem">
          <Link
            to={`/${oneDepth}/${key}/${Object.keys(ROUTE_PAGE[key].child)[0]}`}
          >
            {ROUTE_PAGE[key].title}
          </Link>
        </li>
      ))}
    </ul>
  );

  const renderThreeDepth = () => (
    <ul className="sideBarThreeDepth">
      {Object.entries(ROUTE_PAGE[twoDepth].child).map(([key, value]) => {
        // 2024-12-17 추가
        // 검정성적조회 > 농가별 검정성적조회인 경우 외부 URL로 이동
        if (twoDepth === 'Performance' && key === 'PerformanceCheck') {
          return (
            <li key={key} className="threeDepthItme">
              <a
                href="http://www2.dcic.co.kr/performace_02.do"
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => {
                  setTwoDepthOpen(false);
                  setThreeDepthOpen(false);
                }}
              >
                {value}
              </a>
            </li>
          );
        }

        // 기존 내부 라우팅
        return (
          <li key={key} className="threeDepthItme">
            <Link
              to={`/${oneDepth}/${twoDepth}/${key}`}
              onClick={() => {
                setTwoDepthOpen(false);
                setThreeDepthOpen(false);
              }}
            >
              {value}
            </Link>
          </li>
        );
      })}
    </ul>
  );
  // 경로 받아서 렌더링 끝

  return (
    <div className="sideBarPc">
      <ul className="sideBarList">
        <li className="sideBarItem home">
          <Link to="/Web/Main">
            <img src={home} alt="누르면 홈으로 이동" />
            <span className="hidden">홈으로 이동</span>
          </Link>
        </li>
        <li className={`sideBarItem ${twoDepthOpen ? 'on' : ''}`}>
          <button
            type="button"
            className="currentTwoDepth"
            onClick={() => controlTwoDepth()}
          >
            {ROUTE_PAGE[twoDepth].title}
          </button>
          {renderTwoDepth()}
        </li>
        <li className={`sideBarItem ${threeDepthOpen ? 'on' : ''}`}>
          <button
            type="button"
            className="currentThreeDepth"
            onClick={() => controlThreeDepth()}
          >
            {ROUTE_PAGE[twoDepth].child[threeDepth]}
          </button>
          {renderThreeDepth()}
        </li>
      </ul>
    </div>
  );
}

export default SideBarPc;
