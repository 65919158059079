import React from 'react';
import Guide from 'containers/web/guide/Guide';

function GuidePage() {
  return (
    <Guide />
  );
}

export default GuidePage;
