import React, { Suspense } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

// components
import CommonViewApi from 'components/board/commonViewApi';

// Lazy load components
import WebPage from 'pages/web/WebPage';
import MainPage from 'pages/web/MainPage';
import OxPrintPage from 'pages/oxPrint/OxPrintPage';

// 사업안내 컴포넌트
import BusinessPage from 'pages/web/business/BusinessPage';
import CompanyIntroPage from 'pages/web/business/companyIntro/CompanyIntroPage';
import GreetingsPage from 'pages/web/business/greetings/GreetingsPage';
import HistoryPage from 'pages/web/business/history/HistoryPage';
import FreezeSemenPage from 'pages/web/business/freezeSemen/FreezeSemenPage';
import BestCowTestPage from 'pages/web/business/bestCowTest/BestCowTestPage';
import DescnedantCowPage from 'pages/web/business/descendantCow/DescendantCowPage';
import SuperiorCowPage from 'pages/web/business/superiorCow/SuperiorCowPage';
import StaffPage from 'pages/web/business/staff/StaffPage';
import VisitPage from 'pages/web/business/visit/VisitPage';

// 검정성적조회 컴포넌트
import PerformancePage from 'pages/web/performance/PerformancePage';
import MonthFarmHousePage from 'pages/web/performance/monthFarmhouse/MonthFarmhousePage';
import PerformanceCheckPage from 'pages/web/performance/performanceCheck/PerformanceCheckPage';
import DieoutCowAnalysisPage from 'pages/web/performance/dieoutCowAnalysis/DieoutCowAnalysisPage';
import FindCowPage from 'pages/web/performance/findCow/FindCowPage';
import PlannedMatingCowPage from 'pages/web/performance/plannedMatingCow/PlannedMatingCowPage';
import ProvinceStatuspage from 'pages/web/performance/provinceStatus/ProvinceStatusPage';
import GeneAnalysisPage from 'pages/web/performance/geneAnalysis/GeneAnalysisPage';

// 후대검정조회 컴포넌트
import ProgencyPage from 'pages/web/progency/ProgencyPage';
import SuperiorCowSearchPage from 'pages/web/progency/superiorCowSearch/SuperiorCowSearchPage';

// 씨수소안내 컴포넌트
import OxPage from 'pages/web/ox/OxPage';
import OxSearchPage from 'pages/web/ox/oxSearch/OxSearchPage';
import BuySemenPage from 'pages/web/ox/buySemen/BuySemenPage';
import BuyBoardList from 'containers/web/ox/buySemen/BuyBoardList';
import OrderSemen from 'containers/web/ox/buySemen/OrderSemen';
import OrderInfoSemen from 'containers/web/ox/buySemen/OrderInfoSemen';
import EditOrderSemen from 'containers/web/ox/buySemen/EditOrderSemen';
import OxDataPage from 'pages/web/ox/oxData/OxDataPage';

// 최신정보 컴포넌트
import InfoPage from 'pages/web/info/InfoPage';
import MainNewsPage from 'pages/web/info/mainNews/MainNewsPage';
import NotiAndEventsPage from 'pages/web/info/notiAndEvents/NotiAndEventsPage';
import OverSeasPage from 'pages/web/info/overseas/OverseasPage';
import TechnicalInfoPage from 'pages/web/info/technicalInfo/TechnicalInfoPage';
import IndustryNewsPage from 'pages/web/info/industryNews/IndustryNewsPage';
import PromotionalVideoPage from 'pages/web/info/promotionalVideo/PromotionalVideoPage';

// 정보창고 컴포넌트
import DataPage from 'pages/web/data/DataPage';
import NoticePage from 'pages/web/data/notice/NoticePage';
import FieldPicturePage from 'pages/web/data/fieldPicture/FieldPicturePage';
import PublicationPage from 'pages/web/data/publication/PublicationPage';
import QnaTechnicalInfoPage from 'pages/web/data/qnaTechnicalInfo/QnaTechnicalInfoPage';
import QnaHome from 'containers/web/data/qnaTechnicalInfo/QnaHome';
import QnaForeign from 'containers/web/data/qnaTechnicalInfo/QnaForeign';
import FoIndex from 'containers/web/data/qnaTechnicalInfo/qnaForeignItem/FoIndex';
import HowtoReportPage from 'pages/web/data/howtoReport/HowtoReportPage';
import DictionaryPage from 'pages/web/data/dictionary/DictionaryPage';
import RelatedSitesPage from 'pages/web/data/relatedSites/RelatedSitesPage';

// 청정육종농가 컴포넌트
// import AlbumPage from 'pages/web/album/AlbumPage';
// import NeulmokPage from 'pages/web/album/neulmok/NeulmokPage';
// import SunhaePage from 'pages/web/album/sunhae/SunhaePage';
// import WonjuPage from 'pages/web/album/wonju/WonjuPage';
// import SeonggangPage from 'pages/web/album/seonggang/SeonggangPage';
// import LAndHPage from 'pages/web/album/lAndH/LAndHPage';
// import GuwonPage from 'pages/web/album/guwon/GuwonPage';
// import IcheolPage from 'pages/web/album/icheol/IcheolPage';
// import DupyeongPage from 'pages/web/album/dupyeong/DupyeongPage';

// 이용안내 컴포넌트
import GuidePage from 'pages/web/guide/GuidePage';
import SiteMapPage from 'pages/web/guide/siteMap/SiteMapPage';
import PersonalInfoPage from 'pages/web/guide/personalInfo/PersonalInfoPage';
import EmailPage from 'pages/web/guide/email/EmailPage';

// 404 Page 컴포넌트
import NotFound from 'components/notFound/NotFound';

// dci 영역
import DcitestPage from 'pages/dcitest/DcitestPage';
import DciEvalPage from 'pages/dcitest/dciEval/DciEvalPage';
import DciDocPage from 'pages/dcitest/dciDoc/DciDocPage';
import DciFarmPage from 'pages/dcitest/dciFarm/DciFarmPage';
import DciInfoPage from 'pages/dcitest/dciInfo/DciInfoPage';
import DciExpertPage from 'pages/dcitest/dciExpert/DciExpertPage';

// 농가용
import NewMain from 'containers/dcitest/dciFarm/dciFarmItem/NewMain';
import NewCowList from 'containers/dcitest/dciFarm/dciFarmItem/NewCowList';
import Graph from 'containers/dcitest/dciFarm/dciFarmItem/graph/Graph';
import NewPlan from 'containers/dcitest/dciFarm/dciFarmItem/NewPlan';
import NewCowBun from 'containers/dcitest/dciFarm/dciFarmItem/NewCowBun';
import OxList from 'containers/dcitest/dciFarm/dciFarmItem/OxList';
import NewSemenSelection from 'containers/dcitest/dciFarm/dciFarmItem/NewSemenSelection';
import NewFarmgraph from 'containers/dcitest/dciFarm/dciFarmItem/NewFarmgraph';
import NewFarmSemen from 'containers/dcitest/dciFarm/dciFarmItem/NewFarmSemen';
import NewFarmMcow from 'containers/dcitest/dciFarm/dciFarmItem/NewFarmMcow';

// 전문가용
import NewCowDcicAnal from 'containers/dcitest/dciExpert/dciExpertItem/NewCowDcicAnal';
import NewAnla01 from 'containers/dcitest/dciExpert/dciExpertItem/NewAnal01';
import NewNoninschkview from 'containers/dcitest/dciExpert/dciExpertItem/NewNoninschkview';
import NewFCMFPCMECM from 'containers/dcitest/dciExpert/dciExpertItem/NewFCMFPCMECM';
import NewBmonthcomp from 'containers/dcitest/dciExpert/dciExpertItem/NewBmonthcomp';
import NewCowAnal101 from 'containers/dcitest/dciExpert/dciExpertItem/NewCowAnal101';
import NewTotalprint from 'containers/dcitest/dciExpert/dciExpertItem/NewTotalprint';

// 유전능력 평가보기
import NewGentype20 from 'containers/dcitest/dciEval/dciEvalItem/NewGentype20';
import NewGentype45 from 'containers/dcitest/dciEval/dciEvalItem/NewGentype45';
import NewCowreportavg from 'containers/dcitest/dciEval/dciEvalItem/NewCowreportavg';

// 유전평가 및 계획교배
import MilkPage from 'pages/milk/MilkPage';
import GirlcowAbilityPage from 'pages/milk/girlcowability/GirlcowAbilityPage';
import GirlcowSelectPage from 'pages/milk/girlcowselect/GrilcowSelectPage';
import MilkPlanMatingPage from 'pages/milk/milkplanmating/MilkPlanMatingPage';
import PedigreePage from 'pages/milk/pedigree/PedigreePage';
import SemenSelectPage from 'pages/milk/semenselect/SemenSelectPage';

// 유전체 분석
import GenePage from 'pages/gene/GenePage';
import GeneResultPage from 'pages/gene/generesult/GeneResultPage';
import GeneReportPage from 'pages/gene/genereport/GeneReportPage';
import GeneGradePage from 'pages/gene/genegrade/GeneGradePage';
import GeneHowtoPage from 'pages/gene/genehowto/GeneHowtoPage';
import GenePrintPage from 'pages/genePrint/GenePrintPage';

// 백 오피스
import BoPage from 'pages/bo/BoPage';
import BoLoginPage from 'pages/bo/login/BoLoginPage';

// 백 오피스 사용자관리
import UserPage from 'pages/bo/user/UserPage';
import GroupList from 'containers/bo/user/GroupList';
import GroupEdit from 'containers/bo/user/GroupEdit';
import UserList from 'containers/bo/user/UserList';
import UserView from 'containers/bo/user/UserView';
import UserEdit from 'containers/bo/user/UserEdit';

// 백 오피스 직원관리
import EmployeePage from 'pages/bo/employee/EmployeePage';
import EmployeeList from 'containers/bo/employee/EmployeeList';
import EmployeeView from 'containers/bo/employee/EmployeeView';
import EditEmployee from 'containers/bo/employee/EditEmployee';

// 백 오피스 코드관리
import CodePage from 'pages/bo/code/CodePage';
import MstCodeList from 'containers/bo/code/MstCodeList';
import MstCodeEdit from 'containers/bo/code/MstCodeEdit';
import SlvCodeList from 'containers/bo/code/SlvCodeList';
import SlvCodeView from 'containers/bo/code/SlvCodeView';
import SlvCodeEdit from 'containers/bo/code/SlvCodeEdit';

// 백 오피스 전문가 관리
import ExptPage from 'pages/bo/expt/ExptPage';
import ExptKoPage from 'pages/bo/expt/exptKo/ExptKoPage';
import ExptKoList from 'containers/bo/expt/exptKo/ExptKoList';
import ExptKoEdit from 'containers/bo/expt/exptKo/ExptKoEdit';
import ExptKoView from 'containers/bo/expt/exptKo/ExptKoView';

import ExptEngPage from 'pages/bo/expt/exptEng/ExptEngPage';
import ExptEngList from 'containers/bo/expt/exptEng/ExptEngList';
import ExptEngEdit from 'containers/bo/expt/exptEng/ExptEngEdit';
import ExptEngView from 'containers/bo/expt/exptEng/ExptEngView';

// 백 오피스 검정성적 조회
import FarmTestPage from 'pages/bo/farmTest/FarmTestPage';
import MonthFarmBestListPage from 'pages/bo/farmTest/monFarmBestList/MonthFarmBestListPage';
import MonthFarmBestView from 'containers/bo/farmTest/monFarmBestList/MonthFarmBestView';
import EditMonthFarmBest from 'containers/bo/farmTest/monFarmBestList/EditMonthFarmBest';
import LocstatPage from 'pages/bo/farmTest/locstat/LocstatPage';
import LocstatList from 'containers/bo/farmTest/locstat/LocstatList';
import LocstatView from 'containers/bo/farmTest/locstat/LocStatView';
import EditLocstat from 'containers/bo/farmTest/locstat/LocStatEdit';

// 백 오피스 정액판매 관리
import SemenPage from 'pages/bo/semen/SemenPage';
import SemenList from 'containers/bo/semen/SemenList';
import SemenView from 'containers/bo/semen/SemenView';
import SemenEdit from 'containers/bo/semen/SemenEdit';
// 백 오피스 종모우 관리
import BreedBullPage from 'pages/bo/breedBull/BreedBullPage';

import OriginBreedBullPage from 'pages/bo/breedBull/originBreedBull/OriginBreedBullPage';
import OriginBreedBullList from 'containers/bo/breedBull/originBreedBull/OriginBreedBullList';
import OriginBreedBullView from 'containers/bo/breedBull/originBreedBull/OriginBreedBullView';
import OriginBreedBullEdit from 'containers/bo/breedBull/originBreedBull/OriginBreedBullEdit';

import BackupBreedBullPage from 'pages/bo/breedBull/backupBreedBull/BackupBreedBullPage';
import BackupBreedBullList from 'containers/bo/breedBull/backupBreedBull/BackupBreedBullList';
import BackupBreedBullView from 'containers/bo/breedBull/backupBreedBull/BackupBreedBullView';
import BackupBreedBullEdit from 'containers/bo/breedBull/backupBreedBull/BackupBreedBullEdit';

// 백 오피스 정보창고
import BoardPage from 'pages/bo/board/BoardPage';

import BoardNoticePage from 'pages/bo/board/boardNotice/BoardNoticePage';
import PhotoPage from 'pages/bo/board/photo/PhotoPage';

// 백 오피스 정보창고 검정뉴스
import CowTestNewPage from 'pages/bo/board/cowTestNew/CowTestNewPage';
import CowTestNewList from 'containers/bo/board/cowTestNew/CowTestNewList';
import CowTestNewView from 'containers/bo/board/cowTestNew/CowTestNewView';
import CowTestNewEdit from 'containers/bo/board/cowTestNew/CowTestNewEdit';

// 백오피스 정보창고 개량정보
import ImproveInfoPage from 'pages/bo/board/improveInfo/ImproveInfoPage';
import ImproveInfoList from 'containers/bo/board/improveInfo/ImproveInfoList';
import ImproveInfoView from 'containers/bo/board/improveInfo/ImproveInfoView';
import ImproveInfoEdit from 'containers/bo/board/improveInfo/ImproveInfoEdit';

// 백오피스 정보창고 검정사업보고서
import TestBisReportPage from 'pages/bo/board/testBisReport/TestBisReportPage';
import TestBisReportList from 'containers/bo/board/testBisReport/TestBisReportList';
import TestBisReportView from 'containers/bo/board/testBisReport/TestBisReportView';
import TestBisReportEdit from 'containers/bo/board/testBisReport/TestBisReportEdit';

// 백오피스 정보창고 검정사업 세부 추진계획
import TestDtlPushPlanPage from 'pages/bo/board/testDtlPushPlan/TestDtlPushPlanPage';
import TestDtlPushPlanList from 'containers/bo/board/testDtlPushPlan/TestDtlPushPlanList';
import TestDtlPushPlanView from 'containers/bo/board/testDtlPushPlan/TestDtlPushPlanView';
import TestDtlPushPlanEdit from 'containers/bo/board/testDtlPushPlan/TestDtlPushPlanEdit';

// 백 오피스 정보창고 낙농용어사전
import DairyDicPage from 'pages/bo/board/dairyDic/DairyDicPage';
import DairyDicList from 'containers/bo/board/dairyDic/DairyDicList';
import DairyDicEdit from 'containers/bo/board/dairyDic/DairyDicEdit';
import DairyDicView from 'containers/bo/board/dairyDic/DairyDicView';

// 백 오피스 최신정보
import BoInfoPage from 'pages/bo/info/InfoPage';
import InfoNewPage from 'pages/bo/info/infoNew/InfoNewPage';
import InfoNewListPage from 'containers/bo/info/infoNew/InfoNewList';
import InfoNewViewPage from 'containers/bo/info/infoNew/InfoNewView';
import InfoNewEditPage from 'containers/bo/info/infoNew/EditInfoNew';

import InfoEventPage from 'pages/bo/info/infoEvent/InfoEventPage';
import InfoEventListPage from 'containers/bo/info/infoEvent/InfoEventList';
import InfoEventViewPage from 'containers/bo/info/infoEvent/InfoEventView';
import InfoEventEditPage from 'containers/bo/info/infoEvent/EditInfoEvent';

import InfoForeignTopicsPage from 'pages/bo/info/infoForeignTopics/InfoForeignTopicsPage';
import InfoForeignTopicsListPage from 'containers/bo/info/infoForeignTopics/InfoForeignTopicsList';
import InfoForeignTopicsViewPage from 'containers/bo/info/infoForeignTopics/InfoForeignTopicsView';
import InfoForeignTopicsEditPage from 'containers/bo/info/infoForeignTopics/EditInfoForeignTopics';

import InfoTechInfoPage from 'pages/bo/info/infoTechInfo/InfoTechInfoPage';
import InfoTechInfoListPage from 'containers/bo/info/infoTechInfo/InfoTechInfoList';
import InfoTechInfoViewPage from 'containers/bo/info/infoTechInfo/InfoTechInfoView';
import InfoTechInfoEditPage from 'containers/bo/info/infoTechInfo/EditInfoTechInfo';

import InfoIndustryNewsPage from 'pages/bo/info/infoIndustryNews/InfoIndustryNewsPage';
import InfoIndustryNewsListPage from 'containers/bo/info/infoIndustryNews/InfoIndustryNewsList';
import InfoIndustryNewsViewPage from 'containers/bo/info/infoIndustryNews/InfoIndustryNewsView';
import InfoIndustryNewsEditPage from 'containers/bo/info/infoIndustryNews/EditInfoIndustryNews';

import InfoVideoPage from 'pages/bo/info/infoVideo/InfoVideoPage';
import InfoVideoListPage from 'containers/bo/info/infoVideo/InfoVideoList';
import InfoVideoViewPage from 'containers/bo/info/infoVideo/InfoVideoView';
import InfoVideoEditPage from 'containers/bo/info/infoVideo/EditInfoVideo';

// 백 오피스 축산경제통합시스템
import ApiPage from 'pages/bo/api/ApiPage';
import ApiRequestPage from 'pages/bo/api/apiRequest/ApiRequestPage';
import ApiResultStatusLitsPage from 'pages/bo/api/apiResultStatusList/ApiResultStatusListPage';
// 백 오피스 농협 하나로 시스템
import NonghyupApiPage from 'pages/bo/nonghyupApi/NonghyupApiPage';
import ApiRequestHanaroPage from 'pages/bo/nonghyupApi/apiRequestHanaro/ApiRequestHanaroPage';
import ApiResultStatusListHanaroPage from 'pages/bo/nonghyupApi/apiResultStatusListHanaro/ApiResultStatusListHanaroPage';

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <div className="App">
        <Suspense fallback={<div></div>}>
          <Routes>
            {/* 라우트 영역 */}
            <Route path="/" element={<Navigate to="/Web/Main" replace />} />
            {/* 일반 홈페이지 */}
            <Route path="/Web/*" element={<WebPage />}>
              <Route path="Main" element={<MainPage />} />
              <Route path="Business/*" element={<BusinessPage />}>
                <Route path="CompanyIntro" element={<CompanyIntroPage />} />
                <Route path="Greetings" element={<GreetingsPage />} />
                <Route path="History" element={<HistoryPage />} />
                <Route path="FreezeSemen" element={<FreezeSemenPage />} />
                <Route path="BestCowTest" element={<BestCowTestPage />} />
                <Route path="DescendantCow" element={<DescnedantCowPage />} />
                <Route path="SuperiorCow" element={<SuperiorCowPage />} />
                <Route path="Staff" element={<StaffPage />} />
                <Route path="Visit" element={<VisitPage />} />
              </Route>
              <Route path="Performance/*" element={<PerformancePage />}>
                <Route path="MonthFarmHouse" element={<MonthFarmHousePage />} />
                <Route
                  path="PerformanceCheck"
                  element={<PerformanceCheckPage />}
                />
                <Route
                  path="DieoutCowAnalysis"
                  element={<DieoutCowAnalysisPage />}
                />
                <Route path="FindCow" element={<FindCowPage />} />
                <Route
                  path="PlannedMatingCow"
                  element={<PlannedMatingCowPage />}
                />
                <Route path="ProvinceStatus" element={<ProvinceStatuspage />} />
                <Route path="GeneAnalysis" element={<GeneAnalysisPage />} />
              </Route>
              <Route path="Progency/*" element={<ProgencyPage />}>
                <Route
                  path="SuperiorCowSearch"
                  element={<SuperiorCowSearchPage />}
                />
              </Route>
              <Route path="Ox/*" element={<OxPage />}>
                <Route path="OxSearch" element={<OxSearchPage />} />
                <Route path="BuySemen/*" element={<BuySemenPage />}>
                  <Route index element={<BuyBoardList />} />
                  <Route path="order" element={<OrderSemen />} />
                  <Route path="orderedit/:sJNo" element={<EditOrderSemen />} />
                  <Route path="orderInfo" element={<OrderInfoSemen />} />
                </Route>
                <Route path="OxData" element={<OxDataPage />} />
              </Route>
              <Route path="Info/*" element={<InfoPage />}>
                <Route path="MainNews" element={<MainNewsPage />}>
                  <Route path="detail/:id" element={<CommonViewApi />} />
                </Route>
                <Route path="NotiAndEvents" element={<NotiAndEventsPage />}>
                  <Route path="detail/:id" element={<CommonViewApi />} />
                </Route>
                <Route path="Overseas" element={<OverSeasPage />}>
                  <Route path="detail/:id" element={<CommonViewApi />} />
                </Route>
                <Route path="TechnicalInfo" element={<TechnicalInfoPage />}>
                  <Route path="detail/:id" element={<CommonViewApi />} />
                </Route>
                <Route path="IndustryNews" element={<IndustryNewsPage />}>
                  <Route path="detail/:id" element={<CommonViewApi />} />
                </Route>
                <Route
                  path="PromotionalVideo"
                  element={<PromotionalVideoPage />}
                >
                  <Route path="detail/:id" element={<CommonViewApi />} />
                </Route>
              </Route>
              <Route path="Data/*" element={<DataPage />}>
                <Route path="Notice" element={<NoticePage />}>
                  <Route path="detail/:id" element={<CommonViewApi />} />
                </Route>
                <Route path="FieldPicture" element={<FieldPicturePage />}>
                  <Route path="detail/:id" element={<CommonViewApi />} />
                </Route>
                <Route path="Publication" element={<PublicationPage />} />
                <Route
                  path="QnaTechnicalInfo"
                  element={<QnaTechnicalInfoPage />}
                >
                  <Route path="QnaHome" element={<QnaHome />}>
                    <Route path="detail/:id" />
                  </Route>
                  <Route path="QnaForeign" element={<QnaForeign />}>
                    <Route path="detail/:id" element={<FoIndex />} />
                  </Route>
                </Route>
                <Route path="HowtoReport" element={<HowtoReportPage />} />
                <Route path="Dictionary" element={<DictionaryPage />} />
                <Route path="RelatedSites" element={<RelatedSitesPage />} />
              </Route>
              {/* 
                2024 - 02 - 20 고객사 요청으로 잠시 숨김
                <Route path='Album/*' element={<AlbumPage />}>
                <Route path='Neulmok' element={<NeulmokPage />} />
                <Route path='Sunhae' element={<SunhaePage />} />
                <Route path='Wonju' element={<WonjuPage />} />
                <Route path='Seonggang' element={<SeonggangPage />} />
                <Route path='LAndH' element={<LAndHPage />} />
                <Route path='Guwon' element={<GuwonPage />} />
                <Route path='Icheol' element={<IcheolPage />} />
                <Route path='Dupyeong' element={<DupyeongPage />} />
              </Route> */}
              <Route path="Guide/*" element={<GuidePage />}>
                <Route path="SiteMap" element={<SiteMapPage />} />
                <Route path="PersonalInfo" element={<PersonalInfoPage />} />
                <Route path="Email" element={<EmailPage />} />
              </Route>
              <Route path="*" element={<NotFound />} />
            </Route>
            {/* 젖소검정분석 홈페이지 */}
            <Route path="/dcitest/*" element={<DcitestPage />}>
              <Route path="dciFarm/*" element={<DciFarmPage />}>
                <Route path="NewMain" element={<NewMain />} />
                <Route path="NewCowList" element={<NewCowList />} />
                <Route path="Graph" element={<Graph />} />
                <Route path="NewPlan" element={<NewPlan />} />
                <Route path="NewCowBun" element={<NewCowBun />} />
                <Route path="OxList" element={<OxList />} />
                <Route
                  path="NewSemenSelection"
                  element={<NewSemenSelection />}
                />
                <Route path="NewFarmgraph" element={<NewFarmgraph />} />
                <Route path="NewFarmSemen" element={<NewFarmSemen />} />
                <Route path="NewFarmMcow" element={<NewFarmMcow />} />
              </Route>
              <Route path="dciExpert" element={<DciExpertPage />}>
                <Route path="NewCowDcicAnal" element={<NewCowDcicAnal />} />
                <Route path="NewAnal01" element={<NewAnla01 />} />
                <Route path="NewNoninschkview" element={<NewNoninschkview />} />
                <Route path="NewFCMFPCMECM" element={<NewFCMFPCMECM />} />
                <Route path="NewBmonthcomp" element={<NewBmonthcomp />} />
                <Route path="NewCowAnal101" element={<NewCowAnal101 />} />
                <Route path="NewTotalprint" element={<NewTotalprint />} />
              </Route>
              <Route path="dciEval" element={<DciEvalPage />}>
                <Route path="NewGentype20" element={<NewGentype20 />} />
                <Route path="NewGentype45" element={<NewGentype45 />} />
                <Route path="NewCowreportavg" element={<NewCowreportavg />} />
              </Route>
              <Route path="dciDoc" element={<DciDocPage />} />
              <Route path="dciInfo" element={<DciInfoPage />} />
            </Route>
            {/* 유전평가 및 계획교배 페이지 */}
            <Route path="/milk/*" element={<MilkPage />}>
              <Route path="pedigree" element={<PedigreePage />} />
              <Route path="girlcowability" element={<GirlcowAbilityPage />} />
              <Route path="semenselect" element={<SemenSelectPage />} />
              <Route path="girlcowselect" element={<GirlcowSelectPage />} />
              <Route path="milkplanmating" element={<MilkPlanMatingPage />} />
            </Route>
            {/* 유전체 분석 페이지 */}
            <Route path="/gene" element={<GenePage />}>
              <Route path="geneResult" element={<GeneResultPage />} />
              <Route path="geneReport" element={<GeneReportPage />} />
              <Route path="geneGrade" element={<GeneGradePage />} />
              <Route path="geneHowto" element={<GeneHowtoPage />} />
            </Route>
            {/* 젖소 정보 */}
            <Route path="/OxPrint/*" element={<OxPrintPage />} />
            {/* 유전체 분석 정보 */}
            <Route path="/geneprint" element={<GenePrintPage />} />
            {/* 백오피스 라우팅 */}
            <Route path="/bo/*" element={<BoPage />}>
              <Route path="login" element={<BoLoginPage />} />
              <Route path="user/*" element={<UserPage />}>
                <Route index element={<Navigate to="groupList" replace />} />
                <Route path="groupList" element={<GroupList />} />
                <Route path="groupEdit" element={<GroupEdit />} />
                <Route path="userList" element={<UserList />} />
                <Route path="userView" element={<UserView />} />
                <Route path="userEdit" element={<UserEdit />} />
              </Route>
              <Route path="employee/*" element={<EmployeePage />}>
                <Route index element={<Navigate to="list" replace />} />
                <Route path="list" element={<EmployeeList />} />
                <Route path="view" element={<EmployeeView />} />
                <Route path="edit" element={<EditEmployee />} />
              </Route>
              <Route path="code/*" element={<CodePage />}>
                <Route index element={<Navigate to="mstCodeList" replace />} />
                <Route path="mstCodeList" element={<MstCodeList />} />
                <Route path="mstCodeEdit" element={<MstCodeEdit />} />
                <Route path="slvCodeList" element={<SlvCodeList />} />
                <Route path="slvCodeView" element={<SlvCodeView />} />
                <Route path="slvCodeEdit" element={<SlvCodeEdit />} />
              </Route>
              <Route path="expt" element={<ExptPage />}>
                <Route path="exptKo" element={<ExptKoPage />}>
                  <Route index element={<Navigate to="list" replace />} />
                  <Route path="list" element={<ExptKoList />} />
                  <Route path="edit" element={<ExptKoEdit />} />
                  <Route path="view" element={<ExptKoView />} />
                </Route>
                <Route path="exptEng" element={<ExptEngPage />}>
                  <Route index element={<Navigate to="list" replace />} />
                  <Route path="list" element={<ExptEngList />} />
                  <Route path="edit" element={<ExptEngEdit />} />
                  <Route path="view" element={<ExptEngView />} />
                </Route>
              </Route>
              <Route path="farmTest/*" element={<FarmTestPage />}>
                <Route
                  path="monthFarmBestList"
                  element={<MonthFarmBestListPage />}
                />
                <Route
                  path="monthFarmBestView"
                  element={<MonthFarmBestView />}
                />
                <Route
                  path="EditmonthFarmBest"
                  element={<EditMonthFarmBest />}
                />
                <Route
                  path="EditmonthFarmBest/:bdcCode"
                  element={<EditMonthFarmBest />}
                />
                <Route path="locstat/*" element={<LocstatPage />}>
                  <Route index element={<Navigate to="list" replace />} />
                  <Route path="list" element={<LocstatList />} />
                  <Route path="view" element={<LocstatView />} />
                  <Route path="edit" element={<EditLocstat />} />
                </Route>
              </Route>
              <Route path="semen" element={<SemenPage />}>
                <Route index element={<Navigate to="semenList" replace />} />
                <Route path="semenList" element={<SemenList />} />
                <Route path="semenView" element={<SemenView />} />
                <Route path="semenEdit" element={<SemenEdit />} />
              </Route>
              <Route path="breedBull/*" element={<BreedBullPage />}>
                <Route
                  path="originBreedBull/*"
                  element={<OriginBreedBullPage />}
                >
                  <Route path="list" element={<OriginBreedBullList />} />
                  <Route path="view" element={<OriginBreedBullView />} />
                  <Route path="edit" element={<OriginBreedBullEdit />} />
                </Route>
                <Route
                  path="backupBreedBull/*"
                  element={<BackupBreedBullPage />}
                >
                  <Route path="list" element={<BackupBreedBullList />} />
                  <Route path="view" element={<BackupBreedBullView />} />
                  <Route path="edit" element={<BackupBreedBullEdit />} />
                </Route>
              </Route>
              <Route path="board/*" element={<BoardPage />}>
                <Route path="boardNotice" element={<BoardNoticePage />} />
                <Route path="photo" element={<PhotoPage />} />
                <Route path="cowTestNew" element={<CowTestNewPage />}>
                  <Route index element={<Navigate to="list" replace />} />
                  <Route path="list" element={<CowTestNewList />} />
                  <Route path="view" element={<CowTestNewView />} />
                  <Route path="edit" element={<CowTestNewEdit />} />
                </Route>
                <Route path="improveInfo" element={<ImproveInfoPage />}>
                  <Route index element={<Navigate to="list" replace />} />
                  <Route path="list" element={<ImproveInfoList />} />
                  <Route path="view" element={<ImproveInfoView />} />
                  <Route path="edit" element={<ImproveInfoEdit />} />
                </Route>
                <Route path="testBisReport" element={<TestBisReportPage />}>
                  <Route index element={<Navigate to="list" replace />} />
                  <Route path="list" element={<TestBisReportList />} />
                  <Route path="view" element={<TestBisReportView />} />
                  <Route path="edit" element={<TestBisReportEdit />} />
                </Route>
                <Route path="testDtlPushPlan" element={<TestDtlPushPlanPage />}>
                  <Route index element={<Navigate to="list" replace />} />
                  <Route path="list" element={<TestDtlPushPlanList />} />
                  <Route path="view" element={<TestDtlPushPlanView />} />
                  <Route path="edit" element={<TestDtlPushPlanEdit />} />
                </Route>
                <Route path="dairyDic" element={<DairyDicPage />}>
                  <Route index element={<Navigate to="list" replace />} />
                  <Route path="list" element={<DairyDicList />} />
                  <Route path="edit" element={<DairyDicEdit />} />
                  <Route path="view" element={<DairyDicView />} />
                </Route>
              </Route>
              <Route path="info/*" element={<BoInfoPage />}>
                <Route path="InfoNew/*" element={<InfoNewPage />}>
                  <Route path="list" element={<InfoNewListPage />} />
                  <Route path="view" element={<InfoNewViewPage />} />
                  <Route path="edit" element={<InfoNewEditPage />} />
                </Route>
                <Route path="InfoEvent/*" element={<InfoEventPage />}>
                  <Route path="list" element={<InfoEventListPage />} />
                  <Route path="view" element={<InfoEventViewPage />} />
                  <Route path="edit" element={<InfoEventEditPage />} />
                </Route>
                <Route
                  path="InfoForeignTopics/*"
                  element={<InfoForeignTopicsPage />}
                >
                  <Route path="list" element={<InfoForeignTopicsListPage />} />
                  <Route path="view" element={<InfoForeignTopicsViewPage />} />
                  <Route path="edit" element={<InfoForeignTopicsEditPage />} />
                </Route>
                <Route path="InfoTechInfo/*" element={<InfoTechInfoPage />}>
                  <Route path="list" element={<InfoTechInfoListPage />} />
                  <Route path="view" element={<InfoTechInfoViewPage />} />
                  <Route path="edit" element={<InfoTechInfoEditPage />} />
                </Route>
                <Route
                  path="InfoIndustryNews/*"
                  element={<InfoIndustryNewsPage />}
                >
                  <Route path="list" element={<InfoIndustryNewsListPage />} />
                  <Route path="view" element={<InfoIndustryNewsViewPage />} />
                  <Route path="edit" element={<InfoIndustryNewsEditPage />} />
                </Route>
                <Route path="InfoVideo/*" element={<InfoVideoPage />}>
                  <Route path="list" element={<InfoVideoListPage />} />
                  <Route path="view" element={<InfoVideoViewPage />} />
                  <Route path="edit" element={<InfoVideoEditPage />} />
                </Route>
              </Route>
              <Route path="api/*" element={<ApiPage />}>
                <Route path="apiRequest" element={<ApiRequestPage />} />
                <Route
                  path="apiResultStatusList"
                  element={<ApiResultStatusLitsPage />}
                />
              </Route>
              <Route path="nonghyupApi/*" element={<NonghyupApiPage />}>
                <Route
                  path="apiRequestHanaro"
                  element={<ApiRequestHanaroPage />}
                />
                <Route
                  path="apiResultStatusListHanaro"
                  element={<ApiResultStatusListHanaroPage />}
                />
              </Route>
            </Route>
            {/* 404 페이지 */}
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Suspense>
      </div>
    </QueryClientProvider>
  );
}

export default App;
