import React from 'react';


function Do40() {

  return (
    <div className="techinfoview">
				<h1>TMR의 제조</h1>
				<h2>TMR의 제조</h2>
				<ul>
					<li>TMR제조시 고려사항 
						<p className="pstyle">&nbsp;&nbsp;&nbsp;비유초기 젖소에게는 되도록 많은 양의 영양소와 에너지를 섭취할 수 있도록 하는 것이 매우 중요하다. 앞서 밝힌 바와 같이 TMR은 산유량에 필요한 영양소 공급이 어려운 비유초기에 많은 양의 사료를 섭취하게 하는 이점이 있다. 다시 말해서 건유기에 조사료 위주의 사료를 섭취하던 젖소에게 분만후 4일부터 농후사료 60%의 TMR을 급여하더라도 사료섭취량, 산유량, 반추위의 소화에 아무런 영향을 미치지 않는다. </p>
						<ul>
							<li>비유초기 및 최고 산유기의 고능력우에게 급여하는 TMR은 조사료와 농후사료의 비율이 최소한 40% : 60% 선을 유지하여야 하는데, 적절한 조.농비율은 40∼44% : 56∼60%이다.이는 조.농비율 60% : 40%의 경우보다 소화율이 높기 때문에 건물섭취량이 많아지고 우유중의 단백질함량도 높아진다. 이와같이 고에너지사료를 급여하면 체중감소도 둔화된다.조.농비율과 함께 고려할 사항은 조섬유함량과 조사료의 물리적 형태이다. </li>
							<li>농후사료함량이 높은TMR을 급여하면 일찍 에너지 균형을 이루게 된다. 그러나 TMR의 농후사료 함량이 60∼80%인 TMR의 소화율은 농후사료함량이 60%이하인 경우보다 소화율이 크게 떨어지는 경향이 있다. 착유우가 지나치게 살이 찌는 것을 막기 위해서는 비유곡선에 맞게 TMR의 영양수준(조.농비율)을 조정해야 한다. </li>
							<li>착유우의 사료 섭취량은 건물기준으로 체중의 3.3∼3.5% 수준이다. 하루에 35kg이상의 우유를 생산하는 착유우는 체중의 4% 수준의 사료를 섭취한다. 체중이 많은 젖소는 많은양의 사료를 섭취하기 때문에 체중이 작은 젖소보다 영양부족으로 인한 스트레스를 덜 받는다. 따라서 선발기준은 체중이 큰쪽으로 하는 것이 좋으나 생산효율은 체중이 작은 젖소가 높을 수도 있다. 건물기준 사료섭취량의 목표를세우고 TMR을 제조할 때 적절한 건물 섭취량을 추정하는 것이 매우 중요하다. 일반적으로 산유량이 많고 체중이 큰 젖소는 산유량이 적고 체구가 작은 소보다 많은 양의 사료를 섭취한다. </li>
							<li> 고능력우의 산유에너지(NEI) 상한은 1,72Kcal/kg이다. 이는 조사료의 건물기준으로 50:50의 양질 사이레지와 알팔파건초로 공급될 경우로 조.농비율이 44:56수준이다. 저능력 우의 최저수준은 1.43Mcal/kg이며 TMR의 농후사료 수준이 최소한 16% 이상이어야 한다. </li>
							<li>단백질원과 단백질수준 또한 TMR 제조의 중요한 요인이다. 면실을 단백질원으로 사용할 때는 고단백사료(16.3%)가 저단백질사료(13.5%)보다 산유량,사료 섭취량 및 유단백함량을 증가시킨다. 면실단백은 대두단백보다 효율이 낮아 대두단백 13.5%가 면실단백 16.3%사료와 비슷한 효율을 내다. 물론 단백질수준을 13.5%에서 16.3%로 높이면 산유량이 증가되나 증가율이 감소되기 때문에 단백질을 높이는데 대한 경제성을 고려하여 조단백질 14∼19%범위에서 결정하여야 한다. </li>
							<li>TMR은 균형된 적절한 수준의 광물즐을 공급하는 최선의 수단이 된다. 자유채식을 통해서는 개체별로 광물질을 일정한 수준 섭취하도록 하는 것이 불가능하며 요구량을 균형있게 공급할 수도 없다. 광물질 수준은 NRC사양표준의 최고 ∼ 최저 수준에 맞도록 해야한다.</li>
							<li>비유초기 사료에는 양질의 두과 조사료를 쓰는 것이 좋다. 이는 두과 조사료가 옥수수 사일리지보다 단백질 함량이 높고 반추위내 용해도가 낮기 때문이다. 그리고 자연적인 완충(buffering)능력이 높으며 건물 섭취량을 높일 수 있다. </li>
						</ul>
					</li>
					<li>혼합의 균일도 및 배합기(mixer)의 관리
						<p className="pstyle">&nbsp;&nbsp;&nbsp;가축의 능력에 맞게 균일한 영양소를 계속 공급하고, 편식을 방지하기 위해서는 TMR혼합시의 균일도가 매우 중요하다. TMR의 혼합효율에는 원료사료의 입자도, 비중 및 모양이나 형태 등이 관여하는데, 젖소가 한 번 섭취할 때마다 동일한 성분의 사료가 섭취되도록 하기 위해서는 이러한 물성까지도 고려한 원료사료의 선택, 원료 투입의 순서, 적절한 혼합시간 등을 지켜야 하며, 이를 위해서는 배합기의 정확성을 달마다 점검하여야 한다. 그리고 한 배치(batch)에 들어가는 원료사료의 양이 약 10kg 정도로 적은 경우에는 미리 섞어 배합하는 것이 바람직하다. 한편 원료사료의 종류와 배합기의 종류에 따라 배합시간이 달라지게 되는데, 과도한 배합은 미흡한 배합에 비해 더 많은 문제점을 일으킬 수 있다. 배합을 너무 오래하면 조사료의 입자도가 감소할 수 있으며, 기능적인 섬유소량이 감소한다. 또한 원료 첨가의 순서도 배합에 영향을 미칠 수 있는데, 건초를 먼저 넣고, 곡류, 단백질, 광물질, 사일리지 등의 순으로 첨가한다. </p>
					</li>
					<li>수분함량과 기호성
						<p className="pstyle">&nbsp;&nbsp;&nbsp;TMR의 적절한 수분함량은 건물섭취량의 증감을 결정하는 중요한 요인으로, 수분이 적은 경우는 농후사료와 혼합하기가 어려우며, 너무 많은 경우에는 특히 하절기에 있어서는 2차 발효와 곰팡이에 의한 변질이 일어나며 기호성도 극히 저하된다. 이를 방지하기 위해서는 혼합 후에 즉시 급여하는 것이 바람직하다. 수분함량이 적은 경우에는 비트펄프에 물을 첨가한 후 유산균제제등을 혼합해서 수분조정과 변질방지를 동시에 할 수 있다. <br/>&nbsp;&nbsp;&nbsp;건물섭취량은 수분함량과 섬유질함량에 의해서 영향을 받는다. 특히, 사일리지를 주성분으로 하는 TMR의 경우, 수분의 함량은 건물섭취량을 결정하는 가장 큰 요인이며, 사료 전체의 수분함량이 45∼50% 이상으로 증가하면 건물의 섭취량이 급격히 줄어든다. 실험에 의하면 사일리지를 주성분으로 하는 TMR에 있어서 수분함량이 1%증가함에 따라서 젖소의 건물섭취량은 0,14%씩 줄어들었다. 이와 같이 사일리지의 수분함량에 따라서 젖소의 건물섭취량은 0,14%씩 줄어들었다. 이와같이 사일리지의 수분함량에 따라서 전체 TMR이 영향을 받게 되므로 정기적으로 수분함량을 점검하고 그에 대한 보정을 해주어야 한다.. 사일리지의 수분함량은 특히 수직사일로(기밀사일로나 탑형사일로)의 경우에 그변이가 심하므로 정기적인 사일리지의 수분함량 조사가 이루어져야 할 것이다. </p>
					</li>
					<li>조사료의 선택 및 절단길이 
						<p className="pstyle">&nbsp;&nbsp;&nbsp;TMR배합에 이용하는 조사료는 연중 안정적인 공급이 중요한데, 이 경우사일리지의 제조 방법은 TMR급여에 적당한 실린더형의 수확기(harvester)로 2∼3cm로 절단해서 수확.저장하는 것이 좋다. 그리고 건초를 구입하여 조사료를 보충하는 경우는 절단기(cutter)로 가늘게 썰어야 하기 때문에 노동력과 시설이 여분으로 필요하다. 더욱이 건초의 절단에는 먼지가 많이 발생하기 때문에 별도의 시설이 필요하다. <br/>&nbsp;&nbsp;&nbsp;한편, 조사료의 절단길이가 짧을수록 장내의 우회속도가 빠르게 되고 그 결과 많은 양을 섭취할 수 있게 되어 에너지의 섭취량도 많아진다. 그러나 반추시간이 적게 되어 조사료로서의 물리적인 효과는 감소하며, 급여사료의 장내통과 속도가 빠르게 되면 비타민, 칼슘 등의 소화흡수가 저하되므로 적당한 조사료의 절단길이를 선택하여야 한다.</p>
					</li>
				</ul>
			</div>
  );
}

export default Do40;
