import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';

// api
import { getState, postState } from 'utils/api';

function CowTestNewEdit() {
  const [searchParams] = useSearchParams();
  const bdcCode = searchParams.get('bdc_code');
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [file, setFile] = useState(null);
  const [deleteFile, setDeleteFile] = useState('');
  const [addFile, setAddFile] = useState(null);

  const [formData, setFormData] = useState({
    bdc_title: '',
    bdc_wname: '',
    bdc_body: '',
    bdf_fname: '',
    brd_code: '1020',
  });

  useEffect(() => {
    const fetchCowTest = async () => {
      if (!bdcCode) return;

      try {
        const response = await getState('/board/select_board', {
          bdc_code: bdcCode,
        });
        if (response?.return_code === 'SUCCESS' && response.result) {
          setFormData({
            bdc_title: response.result.bdc_title,
            bdc_wname: response.result.bdc_wname,
            bdc_body: response.result.bdc_body,
            bdc_body1: response.result.bdc_body1,
            bdf_fname: response.result.bdf_fname,
          });
        }
      } catch (error) {
        console.error('Error fetching cow test:', error);
      }
    };

    fetchCowTest();
  }, [bdcCode]);

  const handleFileChange = (e) => {
    const { files: uploadedFiles } = e.target;

    if (!uploadedFiles || !uploadedFiles[0]) {
      setFormData((prev) => ({
        ...prev,
        bdf_fname: '',
      }));
      setFile(null);
      return;
    }

    const newFile = uploadedFiles[0];

    // Check if it's a PDF
    if (!newFile.type.includes('pdf')) {
      alert('PDF 파일만 업로드 가능합니다.');
      e.target.value = '';
      return;
    }

    // Check file size (20MB limit)
    if (newFile.size > 20 * 1024 * 1024) {
      alert('파일크기 20Mbyte 이상은 저장할 수 없습니다.');
      e.target.value = '';
      return;
    }

    setFile(newFile);
    setAddFile(newFile);

    setFormData((prev) => ({
      ...prev,
      bdf_fname: newFile.name,
    }));
  };

  const handleFileDelete = () => {
    setFormData((prev) => ({
      ...prev,
      bdf_fname: '',
    }));
    setDeleteFile('bdf_fname');
    setAddFile(null);
    setFile(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.bdc_title) {
      alert('간행물명을 입력해주세요.');
      return;
    }
    if (!formData.bdc_wname) {
      alert('년도를 입력해주세요.');
      return;
    }

    try {
      const endpoint = bdcCode
        ? '/board/update_cow_test_info/'
        : '/board/insert_cow_test_info/';

      const formDataToSend = new FormData();

      // Add form data fields
      Object.keys(formData).forEach((key) => {
        if (formData[key] !== null && formData[key] !== '') {
          formDataToSend.append(key, formData[key]);
        }
      });

      if (bdcCode) {
        formDataToSend.append('bdc_code', bdcCode);
        formDataToSend.append('bdc_add_file', addFile);
        formDataToSend.append('bdc_delete_file', deleteFile);
      }

      if (!bdcCode) {
        formDataToSend.append('file', file || '');
      }

      Array.from(formDataToSend.entries()).forEach(([key, value]) => {
        console.log(key, value);
      });

      const response = await postState(endpoint, formDataToSend);

      if (response?.return_code === 'SUCCESS') {
        queryClient.invalidateQueries(['cowTests']);
        alert(bdcCode ? '수정되었습니다.' : '등록되었습니다.');
        navigate('/bo/board/cowTestNew/list');
      }
      if (response?.return_code === 'ERROR') {
        alert(response?.return_message);
      }
    } catch (error) {
      console.error('Error saving cow test:', error);
      alert('저장 중 오류가 발생했습니다.');
    }
  };

  const handleDelete = async () => {
    if (!window.confirm('삭제 하시겠습니까?')) return;

    try {
      const response = await postState('/board/delete_cow_test_info/', {
        bdc_code: bdcCode,
      });
      if (response?.return_code === 'SUCCESS') {
        queryClient.invalidateQueries(['cowTests']);
        alert('삭제되었습니다.');
        navigate('/bo/board/cowTestNew/list');
      }
      if (response?.return_code === 'ERROR') {
        alert(response?.return_message);
      }
    } catch (error) {
      console.error('Delete cow test error:', error);
      alert('삭제 중 오류가 발생했습니다.');
    }
  };

  return (
    <div className="cow-test-edit bo-content">
      <h2>검정성적 {bdcCode ? '수정' : '등록'}</h2>
      <div className="table-container bold-border">
        <table>
          <tbody>
            <tr>
              <th>
                간행물명<span className="required">*</span>
              </th>
              <td className="nr">
                <input
                  type="text"
                  value={formData.bdc_title}
                  onChange={(e) =>
                    setFormData({ ...formData, bdc_title: e.target.value })
                  }
                  maxLength={100}
                />
              </td>
            </tr>
            <tr>
              <th>
                년도<span className="required">*</span>
              </th>
              <td className="nr">
                <input
                  type="text"
                  value={formData.bdc_wname}
                  onChange={(e) =>
                    setFormData({ ...formData, bdc_wname: e.target.value })
                  }
                  maxLength={10}
                />
              </td>
            </tr>
            <tr>
              <th>대체URL</th>
              <td className="nr">
                <input
                  type="text"
                  value={formData.bdc_body1}
                  onChange={(e) =>
                    setFormData({ ...formData, bdc_body: e.target.value })
                  }
                  maxLength={150}
                  className="form2"
                />
              </td>
            </tr>
            <tr>
              <th>간행물 PDF</th>
              <td className="nr">
                <div className="file-input-container">
                  <input
                    type="file"
                    id="pdf_file"
                    accept=".pdf"
                    onChange={handleFileChange}
                    style={{ display: 'none' }}
                  />
                  <label
                    htmlFor="pdf_file"
                    className="file-input-button"
                    style={{
                      display: 'inline-block',
                      padding: '8px 16px',
                      backgroundColor: '#f0f0f0',
                      border: '1px solid #ddd',
                      borderRadius: '4px',
                      cursor: 'pointer',
                      marginRight: '10px',
                    }}
                  >
                    파일선택
                  </label>
                  <span style={{ marginRight: '10px' }}>
                    {formData.bdf_fname || '선택된 파일이 없습니다.'}
                  </span>
                  {formData.bdf_fname && (
                    <button
                      type="button"
                      onClick={handleFileDelete}
                      style={{
                        display: 'inline-block',
                        padding: '8px 16px',
                        backgroundColor: '#f0f0f0',
                        border: '1px solid #ddd',
                        borderRadius: '4px',
                        cursor: 'pointer',
                      }}
                    >
                      삭제
                    </button>
                  )}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="button-group">
        <button type="button" onClick={handleSubmit} className="btn-l">
          {bdcCode ? '수정' : '등록'}
        </button>
        {bdcCode && (
          <button type="button" onClick={handleDelete} className="btn-l">
            삭제
          </button>
        )}
        <button
          type="button"
          onClick={() => navigate('/bo/board/cowTestNew/list')}
          className="btn-l"
        >
          목록
        </button>
      </div>
    </div>
  );
}

export default CowTestNewEdit;
