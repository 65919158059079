// HowtoReport.js
import React from 'react';
import SubTitle from 'components/subTitle/SubTitle';
import './HowtoReport.scss';
import Table from './Table';

function HowtoReport() {
  return (
    <div className="howtoReport subCon">
      <SubTitle />
      <div className="noticeCon">
        <p className="noticeCon_desc">
          검정성적표는 앞의 구분과 인쇄된 세부항목을 보아 거기에 표시된 자체의
          의미는 충분히 이해할 수 있어도 성적은 여러가지 각도에서 검토하여야
          한다. 검정성적표는 지면을 경제적으로 활용하기 위해 세부항목을 많게
          하여 보기가 약간 어려운 점도 없지 않지만 내용은 난해한 것이 아니다.
          성적을 보다 잘 이해하고 활용하기 위해서 다음과 같이 구분할 수 있다.
        </p>
      </div>
      <div className="noticeSecondCon">
        <h2 className="noticeSecondCon_Title">검정성적표 보는 기본방법</h2>
        <div className="noticeSecondCon_Desc">
          <ul>
            <li>단지 1항목의 수치만으로 판단할 수 있는것</li>
            <li>
              경시적인 수치의 변화를 파악하고, 현재의 수치가 향상되고 있는
              것인지 떨어지고 있는 것인지를 판단하지 않으면 안되는것
            </li>
            <li>
              관계되는 타 항목과 상호 관련성을 분석하지 않으면 판단될 수 없는 것
            </li>
          </ul>
        </div>
      </div>
      <Table />
      {/* 테이블 박스 컴포넌트 따로만들기 */}
    </div>
  );
}

export default HowtoReport;
