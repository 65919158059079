import React from 'react';
import { useLocation } from 'react-router-dom';

// 훅
import useWindowSize from 'hooks/useWindowSize';

// 상수
import { ROUTE_PAGE } from 'constants/constants';

// scss
import './subMain.scss';

// 이미지
import BusinessPc from 'assets/images/common/subMain/Business_pc.png';
import BusinessMo from 'assets/images/common/subMain/Business_mo.png';
import PerformancePc from 'assets/images/common/subMain/Performance_pc.png';
import PerformanceMo from 'assets/images/common/subMain/Performance_mo.png';
import ProgencyPc from 'assets/images/common/subMain/Progency_pc.png';
import ProgencyMo from 'assets/images/common/subMain/Progency_mo.png';
import OxPc from 'assets/images/common/subMain/Ox_pc.png';
import OxMo from 'assets/images/common/subMain/Ox_mo.png';
import InfoPc from 'assets/images/common/subMain/Info_pc.png';
import InfoMo from 'assets/images/common/subMain/Info_mo.png';
import QnaPc from 'assets/images/common/subMain/Qna_pc.png';
import QnaMo from 'assets/images/common/subMain/Qna_mo.png';
import DataPc from 'assets/images/common/subMain/Data_pc.png';
import DatasMo from 'assets/images/common/subMain/Data_mo.png';
import AlbumPc from 'assets/images/common/subMain/Album_pc.png';
import AlbumMo from 'assets/images/common/subMain/Album_mo.png';
import GuidePc from 'assets/images/common/subMain/Guide_pc.png';
import GuideMo from 'assets/images/common/subMain/Guide_mo.png';

// 컴포넌트
import SideBarPc from './sideBar/SideBarPc';
import SideBarMo from './sideBar/sideBarMo';

function SubMain() {
  
  const windowSize = useWindowSize();
  const location = useLocation();
  const currentLocation = location.pathname.split('/').filter(Boolean)[1];

  let currentImage;
  switch (currentLocation) {
    case 'Business':
      currentImage = windowSize.width < 768 ? BusinessMo : BusinessPc;
      break;
    case 'Performance':
      currentImage = windowSize.width < 768 ? PerformanceMo : PerformancePc;
      break;
    case 'Progency':
      currentImage = windowSize.width < 768 ? ProgencyMo : ProgencyPc;
      break;
    case 'Ox':
      currentImage = windowSize.width < 768 ? OxMo : OxPc;
      break;
    case 'Info':
      currentImage = windowSize.width < 768 ? InfoMo : InfoPc;
      break;
    case 'Qna':
      currentImage = windowSize.width < 768 ? QnaMo : QnaPc;
      break;
    case 'Data':
      currentImage = windowSize.width < 768 ? DatasMo : DataPc;
      break;
    case 'Album':
      currentImage = windowSize.width < 768 ? AlbumMo : AlbumPc;
      break;
    case 'Guide':
      currentImage = windowSize.width < 768 ? GuideMo : GuidePc;
      break;
    default:
      currentImage = null; // 기본 이미지 또는 처리 로직 추가
  }

  return (
    <div className='subMain'>
      {
        currentImage && (
          <img src={currentImage} alt={`이미지_${currentLocation}`} />
        )
      }
      <h3>{ROUTE_PAGE[currentLocation].title}</h3>
      {
        windowSize.width < 1440 
        ?
        <SideBarMo />
        :
        <SideBarPc />
      }
    </div>
  );
}

export default SubMain;
