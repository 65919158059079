import React from 'react';
import FreezeSemen from 'containers/web/business/freezeSemen/FreezeSemen';
import { Helmet } from 'react-helmet-async';

function FreezeSemenPage() {
  return (
    <>
      <Helmet>
        <title>동결정액 생산 및 공급</title>
      </Helmet>
      <FreezeSemen />
    </>
  );
}

export default FreezeSemenPage;
