import React, { useState, useEffect } from 'react';

// api
import { postState } from 'utils/api';
import { openCrownix3, openCrownix } from 'utils/openCrownix';

// 이미지
import PrintPDF from 'assets/svgs/common/print_PDF.svg';
import Logo from 'assets/images/common/header_logo_pc.png';

// 컴포넌트
import ErrorMessage from 'components/errorMessage/errorMessage';
import FarmcongCode from 'components/dcitest/farmcongCode/FarmcongCode';

// scss
import './NewFarmMcow.scss';

function NewFarmMcow() {
  // 에러처리
  const [isModalOpen, setIsModalOpen] = useState(false); // 모달 추가
  const [errorMessage, setErrorMessage] = useState('');

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => {
    setIsModalOpen(false);
    setErrorMessage(''); // 모달이 닫힐 때 에러메세지 초기화
  };

  // 데이터 불러오기
  const baseDir = '/dcitest/dcifarm/newfarmmcow/';
  const [mainData, setMainData] = useState();

  const [props, setProps] = useState({
    id: `${localStorage.getItem('cong')}${localStorage.getItem('farm')}`,
    passwd: localStorage.getItem('passwd'),
    date: localStorage.getItem('month'),
  });

  useEffect(() => {
    postState(baseDir, props)
      .then((getdata) => {
        if (getdata !== undefined) {
          //   console.log(getdata);
          setMainData(getdata);
        }
      })
      .catch((error) => {
        setErrorMessage(error.message);
        openModal();
      });
  }, [props]);

  // 페이지 네이션
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20;

  // Calculate the index range for the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems =
    mainData && mainData.plot_data.slice(indexOfFirstItem, indexOfLastItem);
  const maxPage = Math.ceil(
    mainData && mainData.plot_data.length / itemsPerPage,
  );

  return (
    <div className="pdfMainArea">
      <ErrorMessage
        isOpen={isModalOpen}
        content={errorMessage}
        closeModal={closeModal}
      />
      <div className="pdfMainHeader">
        <button type="button" onClick={() => openCrownix('New_farm_mcow.mrd')}>
          <img src={PrintPDF} alt="PDF로 인쇄하기" />
          인쇄하기
        </button>
        {props && (
          <FarmcongCode
            props={props}
            setProps={setProps}
            setErrorMessage={setErrorMessage}
            openModal={openModal}
          />
        )}
        <div className="pdfPagenation">
          <p>
            {currentPage} / {maxPage}
          </p>
          <button
            type="button"
            onClick={() => {
              if (currentPage !== 1) setCurrentPage(currentPage - 1);
            }}
          >
            이전
          </button>
          <button
            type="button"
            onClick={() => {
              if (currentPage !== maxPage) setCurrentPage(currentPage + 1);
            }}
          >
            다음
          </button>
        </div>
      </div>
      <div className="pdfArea">
        <div className="newFarmMcowPdf">
          <h1>어미별 딸소 조회</h1>
          <ul className="newFarmMcowInfo">
            <li>목장명 : {mainData && mainData.farm_property[0].fname}</li>
            <li>축주명 : {mainData && mainData.farm_property[0].fdname}</li>
          </ul>

          <table className="pdfTable">
            <thead>
              <tr>
                <th>관리번호</th>
                <th>등록번호</th>
                <th>생일</th>
                <th>산차</th>
                <th>최종검정월</th>
                <th>딸소 개체수</th>
                <th>생존딸소</th>
              </tr>
            </thead>
            <tbody>
              {currentItems &&
                currentItems.map((item, index) => (
                  <tr key={index}>
                    <td>
                      <button
                        type="button"
                        onClick={() =>
                          openCrownix3('New_sang_jcode.mrd', item.cow_jcode)
                        }
                      >
                        {item.sname}
                      </button>
                    </td>
                    <td>{item.regno}</td>
                    <td>{item.birthday}</td>
                    <td>{item.pari}</td>
                    <td>{item.ltmonth}</td>
                    <td>{item.pro_cnt}</td>
                    <td>{item.pro_cnt_live}</td>
                  </tr>
                ))}
            </tbody>
          </table>
          <img
            src={Logo}
            alt="농협경제지주 젖소개량사업소 로고"
            className="pdfLogo"
          />
        </div>
      </div>
    </div>
  );
}

export default NewFarmMcow;
