import React,{ useState , useEffect } from 'react';
import {
  ResponsiveContainer,
  ReferenceLine,
  ScatterChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Scatter,
  Cell
} from 'recharts';

// api
import { postState } from 'utils/api';
import { openCrownix2,openCrownix6 } from 'utils/openCrownix';

// 이미지
import PrintPDF from 'assets/svgs/common/print_PDF.svg';

// 컴포넌트
import ErrorMessage from 'components/errorMessage/errorMessage';
import MonthCode from 'components/dcitest/monthCode/MonthCode';

// scss
import './NewGraphScc01.scss';

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <p className="label">{`${payload[0].payload.sname}`}</p>
      </div>
    );
  }
  return null;
};

const CustomTooltip2 = ({ active, payload, label }) => {
  
  if (active && payload && payload.length) {
    
    return (
      <div className="custom-tooltip">
        
        <p className="label">{`${payload[0].payload.comm}`}</p>
      </div>
    );
  }

  return null;
};



function NewGraphScc01() {

  // 에러처리
  const [isModalOpen, setIsModalOpen] = useState(false); // 모달 추가
  const [errorMessage, setErrorMessage] = useState(''); 

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => {
    setIsModalOpen(false);
    setErrorMessage(''); // 모달이 닫힐 때 에러메세지 초기화
  };

  // 데이터 불러오기

  const baseDir='/chart/new_graph_scc/';
  const [ mainData , setMainData ] = useState();

  const [ props , setProps] = useState({
    id:`${localStorage.getItem('cong')}${localStorage.getItem('farm')}`,
    passwd:localStorage.getItem('passwd'),
    date:localStorage.getItem('month')
  });

  useEffect(() => {

    postState(baseDir,props)
    .then(getdata => {      
      if (getdata !== undefined) {
        setMainData(getdata);
      }
    })
    .catch(error => {           
      setErrorMessage(error.message);
      openModal();
    });
    
  }, [props]);

  const COLORS = ['green','yellow','blue','red'];
  const classifyNumber = (num) => {
    if (num >= 0 && num < 100) return 0;
    if (num >= 100 && num <= 200)  return 1; 
    if (num > 200 && num < 500) return 2;
    if (num >= 500) return 3;
    return 0;
  };

  const CustomClick = (e) => {
    openCrownix2('New_ch_cow1.mrd',e.cowid , e.pari);
    // alert(e.sname2);
    };

  return (
    <div className='pdfMainArea'>
    <ErrorMessage isOpen={isModalOpen} content={errorMessage} closeModal={closeModal}/>
      <div className='pdfMainHeader'>
        <button 
          type='button'
          onClick={() => openCrownix6('New_graph_scc_01.mrd')}
        >
          <img src={PrintPDF} alt='PDF로 인쇄하기' />
          인쇄하기
        </button>
        {
          props && 
          <MonthCode 
            props={props}
            setProps={setProps}
            setErrorMessage={setErrorMessage}
            openModal={openModal}
          />
        }
      </div>
      <div className='pdfArea'>
        <div className='newGraphScc01'>  
          <div className='graphHead'>
            <span>{props.date.substring(0,4)}년 {props.date.slice(-2)}월 그래프</span>
            <button 
              type='button'
              onClick={() => openCrownix6('New_graph_scc_4m.mrd')}
            >
              3개월 보기
            </button>
          </div>
          <h1>비유일수별 체세포수 분포도</h1>
          <div className='dotChartAreaDual'>
            <ResponsiveContainer width="68%" height={500}>
              <ScatterChart
                width="90%"
                height="85%"
                margin={{              
                  top: 20,
                  right: 20,
                  bottom: 20,
                  left: 20,
                  }}>                
                <CartesianGrid />
                <XAxis
                  dataKey="day"
                  type="number"
                  label={{ value: '비 유 일 수 [일]', position: 'bottom' }}
                />
                <YAxis
                  dataKey="scc"
                  type="number"
                  domain={[0, '6000']} 
                  label={{ value: '체세포수 (천)', position: 'insideLeft', offset: '-10', angle: -90 }}
                />
                <Tooltip cursor={{ strokeDasharray: '3 3' }} content={CustomTooltip} />
                <Scatter data={mainData && mainData.plot_data} fill="green" onClick={CustomClick}>
                  {
                    mainData &&
                    mainData.plot_data.map((entry) => (
                      <Cell key={`cell-${entry.day+entry.scc+entry.z}`} fill={COLORS[classifyNumber(entry.scc)]}/>
                    ))
                  }
                </Scatter>
                <ReferenceLine y={100} stroke="red" />
                <ReferenceLine y={200} stroke="blue" />
                <ReferenceLine y={500} stroke="black" />
              </ScatterChart>
            </ResponsiveContainer>
            
            <ResponsiveContainer width='29%' height={500}>
            <ScatterChart
                width="90%"
                height="85%"
                margin={{              
                  top: 20,
                  right: 20,
                  bottom: 20,
                  left: 20,
                  }}>
                <CartesianGrid />
                <XAxis
                  dataKey="xaxis"
                  type="number"
                  label={{ value: '영역별두수(비율%)', position: 'bottom' }}
                />
                <YAxis
                  dataKey="yaxis"
                  type="number"                                    
                />
                <Tooltip cursor={{ strokeDasharray: '3 3' }} content={CustomTooltip2} />
                <Scatter data={mainData && mainData.plot_data2} fill="green">
                  {
                    mainData &&
                    mainData.plot_data2.map((entry) => (
                      <Cell key={`cell-${entry.day+entry.scc+entry.z}`} fill={COLORS[classifyNumber(entry.scc)]}/>
                    ))
                  }
                </Scatter>
              </ScatterChart>
            </ResponsiveContainer>
            </div>
            <ul className='fatLabel'>
              <li>10만미만우</li>
              <li>10만이상 20만이하우</li>
              <li>20만초과 50만미만우</li>
              <li>50만이상우</li>
            </ul>
        </div>   
      </div>
    </div>
  );
}

export default NewGraphScc01;
