import React, { useState } from 'react';
import { Link, useLocation, Outlet , useNavigate} from 'react-router-dom';

const data = [
  [
    '낙농현황과 젖소개량의 의미량(젖소의 개량/(사)한국종축개량협회/일본편)',
    '젖소개량의 체제와 관련사업의 개요개량(젖소의 개량/(사)한국종축개량협회/일본편)',
    '여러외국에 있어서 젖소개량 현황(젖소의 개량/(사)한국종축개량협회/일본편)',
    '젖소개량사업의 전개(젖소의 개량/(사)한국종축개량협회/일본편)',
    '젖소의 유전적 능력평가와 그 활용(젖소의 개량/(사)한국종축개량협회/일본편)',
    '젖소의 생산성에 관한 형질의 총합적 개량(젖소의 개량/(사)한국종축개량협회/일본편)'
  ],
  [
    '소의 번식생명(Reproduction Genetic Selection/University of Wisconsin/미국편)',
    '소의 번식기관(Reproduction Genetic Selection/University of Wisconsin/미국편)',
    '발정주기(Reproduction Genetic Selection/University of Wisconsin/미국편)',
    '호르몬 : 번식생리의 조절기구(Reproduction Genetic Selection/University of Wisconsin/미국편)',
    '발정관찰과 인공수정-서론, 발정관찰(Reproduction Genetic Selection/University of Wisconsin/미국편)',
    '발정관찰과 인공수정-인공수정(Reproduction Genetic Selection/University of Wisconsin/미국편)',
    '발정관찰과 인공수정-자연교미(Reproduction Genetic Selection/University of Wisconsin/미국편)',
    '임신과 분만-임신(Reproduction Genetic Selection/University of Wisconsin/미국편)',
    '임신과 분만-분만(Reproduction Genetic Selection/University of Wisconsin/미국편)',
    '분만후의 관리(Reproduction Genetic Selection/University of Wisconsin/미국편)',
    '소분만후의 생식기관의 이상(Reproduction Genetic Selection/University of Wisconsin/미국편)'
  ],
  [
    '육성목표(고능력을 목표로 하는 육성/Dairy man문고/일본편)',
    '출생시의 관리(고능력을 목표로 하는 육성/Dairy man문고/일본편)',
    '생후 1-2개월의 관리(고능력을 목표로 하는 육성/Dairy man문고/일본편)',
    '생후 2-9개월의 관리(고능력을 목표로 하는 육성/Dairy man문고/일본편)',
    '육성 중기의 관리(10-14개월령)(고능력을 목표로 하는 육성/Dairy man문고/일본편)',
    '수정기의 관리(15-16개월령)(고능력을 목표로 하는 육성/Dairy man문고/일본편)',
    '첫 임신 중기의 관리(17-23개월령)(고능력을 목표로 하는 육성/Dairy man문고/일본편)',
    '분만 전 관리(고능력을 목표로 하는 육성/Dairy man문고/일본편)',
    '초유는 빨리(고능력을 목표로 하는 육성/Dairy man문고/일본편)',
    '액상 사료를 주는 방법(고능력을 목표로 하는 육성/Dairy man문고/일본편)',
    '고형 사료를 주는 방법(고능력을 목표로 하는 육성/Dairy man문고/일본편)',
    '농후사료와 물과 영양제(고능력을 목표로 하는 육성/Dairy man문고/일본편)',
    '육성후기의 요점(고능력을 목표로 하는 육성/Dairy man문고/일본편)',
    '조기 이유의 장점(고능력을 목표로 하는 육성/Dairy man문고/일본편)'
  ],
  [

  ],
  [
    '젖소의 질병(Feeds & Nutrition/The Ensminger Publishing Company/미국편)',
    '각종 영양성 질병-산성증독증(Feeds & Nutrition/The Ensminger Publishing Company/미국편)',
    '각종 영양성 질병-식이성 인 결핍증(Feeds & Nutrition/The Ensminger Publishing Company/미국편)',
    '각종 영양성 질병-비육시의 고창증(Feeds & Nutrition/The Ensminger Publishing Company/미국편)',
    '각종 영양성 질병-방목지 고창증(Feeds & Nutrition/The Ensminger Publishing Company/미국편)',
    '각종 영양성 질병-만곡증(Feeds & Nutrition/The Ensminger Publishing Company/미국편)',
    '각종 영양성 질병-무기력증(Feeds & Nutrition/The Ensminger Publishing Company/미국편)',
    '각종 영양성 질병-페스큐 중독증(Feeds & Nutrition/The Ensminger Publishing Company/미국편)',
    '각종 영양성 질병-제엽염(Feeds & Nutrition/The Ensminger Publishing Company/미국편)',
    '각종 영양성 질병-저마그네슘 혈증(Feeds & Nutrition/The Ensminger Publishing Company/미국편)',
    '각종 영양성 질병-요오드 결핍증(Feeds & Nutrition/The Ensminger Publishing Company/미국편)',
    '각종 영양성 질병-케토시스(Feeds & Nutrition/The Ensminger Publishing Company/미국편)',
    '각종 영양성 질병-간농양(Feeds & Nutrition/The Ensminger Publishing Company/미국편)',
    '각종 영양성 질병-유열(Feeds & Nutrition/The Ensminger Publishing Company/미국편)',
    '각종 영양성 질병-야맹증(Feeds & Nutrition/The Ensminger Publishing Company/미국편)',
    '각종 영양성 질병-골연화증(Feeds & Nutrition/The Ensminger Publishing Company/미국편)',
    '각종 영양성 질병-광감작(Feeds & Nutrition/The Ensminger Publishing Company/미국편)',
    '각종 영양성 질병-회백 뇌척수염(Feeds & Nutrition/The Ensminger Publishing Company/미국편)',
    '각종 영양성 질병-폐기종(Feeds & Nutrition/The Ensminger Publishing Company/미국편)',
    '각종 영양성 질병-구루병(Feeds & Nutrition/The Ensminger Publishing Company/미국편)',
    '각종 영양성 질병-소금 결핍증(Feeds & Nutrition/The Ensminger Publishing Company/미국편)',
    '각종 영양성 질병-산성중독증(Feeds & Nutrition/The Ensminger Publishing Company/미국편)',
    '각종 영양성 질병-스위트 클로버 병(Feeds & Nutrition/The Ensminger Publishing Company/미국편)',
    '각종 영양성 질병-요결석(Feeds & Nutrition/The Ensminger Publishing Company/미국편)',
    '각종 영양성 질병-송아지 허약증(Feeds & Nutrition/The Ensminger Publishing Company/미국편)',
    '각종 영양성 질병-백색근육증(Feeds & Nutrition/The Ensminger Publishing Company/미국편)'
  ],
  [

  ],
  [
    '송아지 상자(고능력을 목표로 하는 육성/Dairy man문고/일본편)',
    '육성우의 시설(고능력을 목표로 하는 육성/Dairy man문고/일본편)',
    '육성우사의 환경(고능력을 목표로 하는 육성/Dairy man문고/일본편)',
    '육성우사의 계획과 관리(고능력을 목표로 하는 육성/Dairy man문고/일본편)'
  ]
];

function QnaForeign() {

  const [ qnaForeignTab , setQnaForeignTab ] = useState(0);
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <div className="qnaForeign">
     <ul className='qnaHomeTab'>
        <li>
          <button 
            type='button' 
            onClick={()=> {
              setQnaForeignTab(0);
              navigate('/web/Data/QnaTechnicalInfo/QnaForeign');
            }}
            className={qnaForeignTab === 0 ? 'current' : ''}
          >개량</button>
        </li>
        <li>
          <button 
            type='button' 
            onClick={()=> {
              setQnaForeignTab(1);
              navigate('/web/Data/QnaTechnicalInfo/QnaForeign');
            }}
            className={qnaForeignTab === 1 ? 'current' : ''}
          >번식</button>
        </li>
        <li>
          <button 
            type='button' 
            onClick={()=> {
              setQnaForeignTab(2);
              navigate('/web/Data/QnaTechnicalInfo/QnaForeign');
            }}
            className={qnaForeignTab === 2 ? 'current' : ''}
          >사양</button>
        </li>
        <li>
        <button 
            type='button' 
            onClick={()=> {
              setQnaForeignTab(3);
              navigate('/web/Data/QnaTechnicalInfo/QnaForeign');
            }}
            className={qnaForeignTab === 3 ? 'current' : ''}
          >유방염컨트롤</button>
        </li>
        <li>
          <button 
            type='button' 
            onClick={()=> {
              setQnaForeignTab(4);
              navigate('/web/Data/QnaTechnicalInfo/QnaForeign');
            }}
            className={qnaForeignTab === 4 ? 'current' : ''}
          >질병&middot;위생</button>
        </li>
        <li>
          <button 
            type='button' 
            onClick={()=> {
              setQnaForeignTab(5);
              navigate('/web/Data/QnaTechnicalInfo/QnaForeign');
            }}
            className={qnaForeignTab === 5 ? 'current' : ''}
          >조사료&middot;사료작물</button>
        </li>
        <li>
          <button 
            type='button' 
            onClick={()=> {
              setQnaForeignTab(6);
              navigate('/web/Data/QnaTechnicalInfo/QnaForeign');
            }}
            className={qnaForeignTab === 6 ? 'current' : ''}
          >시설상비</button>
        </li>
      </ul>
      <ul className='qnaHomeList'>
        {
          !location.pathname.includes('detail') && (
            data[qnaForeignTab].length === 0
            ?
            <li>검색된 데이터가 없습니다.</li>
            :
            data[qnaForeignTab].map((depth,i) => {
              let page = 0;
  
              if (qnaForeignTab === 0) {
                page = i+1;
              }
  
              if (qnaForeignTab !== 0) {
                for (let index = 0; index < qnaForeignTab ; index += 1) {
                  page += data[index].length;
                }
              
                page += (i+1);
              }
  
              return (
                <li key={depth}>
                  <Link to={`detail/${page}`}>{depth}</Link>
                </li>
              );
            })
          )
        }
      </ul>
      <Outlet />
    </div>
  );
}

export default QnaForeign;
