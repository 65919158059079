import React from 'react';

import pic243 from 'assets/images/sub/_images/pic243.jpg';
import pic244 from 'assets/images/sub/_images/pic244.jpg';
import pic245 from 'assets/images/sub/_images/pic245.jpg';
import pic246 from 'assets/images/sub/_images/pic246.gif';

function Do190() {

  return (
    <div className="techinfoview">
      <h1>화이트 클로버</h1>
      <p>영명 : White clover <br/>학명 : Trifolium repense L.</p>
      <div style={{width: '60%',float: 'left'}}>
        <h2>원산지</h2>
        <p className="pstyle">&nbsp;&nbsp;&nbsp;극지방에서도 발견될 정도로 세계에 널리 분포되어 있으며, 원산지는 지눙해의 동북아와 서부아세아인데 영국에서 1707년에 재배하였다는 기록이 있다. 그러나 1757년에 네덜란드에서 재배가 시작되었으며 영국에서는 1764년에 처음 재배되었다.</p>
        <h2>성상</h2>
        <p className="pstyle">&nbsp;&nbsp;&nbsp;화이트 클로버는 단년내지 다년생 두과 목초로서 직근성이며, 뿌리가 벋는 깊이는 깊지 않고 매우 적은 밑동줄기를 가지고 있다. 포복경 이상부위에서 생산이 가능하고 뿌리마디에서 독립 개체로 번식한다. 일반적으로 각 잎마다 잎 종간 부위에 “v”자형의 무늬가 있다. 꽃은 특별히 흰색이기 때문에 일반적으로 흰클로버라고 부르게 되었으나 때로는 엷은 핑크색일 때도 있다.<br/>&nbsp;&nbsp;&nbsp;소립의 황색종자는 견고한 껍질을 가지고 있어 발아가 파종후 수년이 걸릴 때도 있다.<br/>&nbsp;&nbsp;&nbsp;화이트 클로버의 견고한 종자는 방목시에 가축이 채식할 경우에는 소화가 되지 않고 종자 그대로 장을 통해 배설되는 때도 있어 땅에 떨어진 종자가 다시 발아하여 번식하는 경우도 있다. 줄기는 속이 비어있지 않고 지표면에 포복하며 줄기에는 잔털이 없다.<br/>&nbsp;&nbsp;&nbsp;라디노 클로버는 내한성이 강하지는 못하기 때문에 너무 짧게 예취하면 좋지 않고 습한 기후나 구름이 많은 날씨에는 꽃이 피는 데 좋지 않다. 라디노형 클로버는 한 여름에도 생육을 잘하며 더취화이트와 야생화이트 클로버는 생육기간이 길기 때문에 초장이 큰 목초와 혼파하지 않는 것이 좋으며, 이들 3가지형은 서로간에 수정이 된다.<br/>&nbsp;&nbsp;&nbsp;화이트 클로버에느 대형종인 라디노 클로버, 중간형인 컴먼화이트클로버 그리고 소형이며 야생형인 덧취화이트크로버가 있다.</p>
        <h2>재배</h2>
        <p className="pstyle">&nbsp;&nbsp;&nbsp;건조한 점질양토나 식양토에 적합하고, 비옥하고 수분이 적당한 사질토에서는 잘 자란다. 약산성 토양에는 강하지만 알카리성 토양에는 약하다.<br/>&nbsp;&nbsp;&nbsp;기후조건은 서늘하고 습한 지역에서 잘 자란다. 대형인 라디노클로버는 내한성이 강하지 않은 편이므로 고산지의 초지개량에는 쓸 수가 없으며 번식은 자연낙종이나 신생뿌리로 번식 유지 될 수 있다.<br/>&nbsp;&nbsp;&nbsp;천근성이기 때문에 한발에는 약하며 고온기에는 생육이 좋지 않고 장기간 가뭄이 올 때는 고사하게 된다. 긴 장마가 계속될 때도 큰 피해를 입는다.<br/>&nbsp;&nbsp;&nbsp;10a당 생초수량은 2,000-3,000kg이며, 품종으로는 대형의 레갈(Regal), 필그림(pilgrim)이 있으며 보통형으로는 에스-100 (S-100), 후이아(Huia) 그리고 소형으로는 S-184, 듀론(Duron)등이 있다. </p>
        <h2>이용</h2>
        <ul>
          <li>건초
            <p>라디노 클로버는 화이트 클로버중 초장이 크고 생산량이 높기 때문에 혼파하여 건초를 조제하기에 좋은 품종이다. 라디노 클로버는 목질화 되기 전에 건초를 조제하여야 많은 잎과 엽병이 포함되어 건초내에 단백질 함량이 많고 소화율도 높아진다.</p>
          </li>
          <li>방목 및 청예
            <p>화이트 클로버는 초지에서 가장 유용한 두과목초이며 하번초의 성질을 가지고 있는 덧취 화이트(Dutch White), 콤머화이트 클로버 및 라디노 클로버는 초지조성용으로 적합하다. 모든 화이트 클로버는 잎이나 엽병이 무성하고 꽃이 피기 시작할 때 가축에 이용하는 것이 기호성이 좋고 영양가도 가장 높다.  화이트 클로버는 초봄부터 생육을 시작하고 하고기 때는 생육이 좋지 않으나 방목 후 재생은 빠르다. 라디노 클로버는 다년생으로 토양수분이 적당한 지대에서 생산량이 많다. 초장이 약 20cm이상 자랐을 때 방목을 하는 것이 수량이 가장 높고 방목하는 정도는 혼파시 화본과 목초의 초종에 따라 결정되며, 티모시나 부롬 그라스와 같이 키가 큰 화본과 목초와 혼파할때는 8cm정도만 남겨놓고 방목할 수 있고, 오차드 그라스와 같이 재생력이 빠른 화본과 목초와 혼파하였을 때는 방목 높이를 약 5cm정도까지로 낮게 해도 클로버는 잘 유지되게 된다. 습지토양에서는 과방목에 상한 특성을 이용, 덧취 화이트(Dutch white)클로버를 혼파할 때도 있으며, 2-3년 정도밖에 유지하지 못한다.</p>
          </li>
        </ul>
      </div>
      <div style={{width: '40%', float: 'left'}}>
        <table>
          <tr>
            <td><img src={pic243} className="nonborder2" alt=""/></td>
          </tr>
          <tr>
            <td><img src={pic244} className="nonborder2" alt=""/></td>
          </tr>
          <tr>
            <td><p style={{textAlign: 'center'}}>유식물의 생육과정</p></td>
          </tr>
          <tr>
            <td><img src={pic245} className="nonborder2" alt=""/></td>
          </tr>
          <tr>
            <td><p style={{textAlign: 'center'}}>잎과 분지경(중앙)</p></td>
          </tr>
          <tr>
            <td><img src={pic246} className="nonborder2" alt=""/></td>
          </tr>
          <tr>
            <td><p style={{textAlign: 'center'}}>꽃과 잎의 모양</p></td>
          </tr>
        </table>
      </div>
    </div>
  );
}

export default Do190;