import React from 'react';
import History from 'containers/web/business/history/History';
import { Helmet } from 'react-helmet-async';


function HistoryPage() {
    return (
    <>
      <Helmet>
        <title>연혁 및 일반현황</title>
      </Helmet>
      <History />
    </>
  );
}

export default HistoryPage;
