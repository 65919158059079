import React, { useState } from 'react';
import axios from 'axios';

function ApiRequest() {
  const today = new Date();
  const thisYear = today.getFullYear();
  const thisMonth = `${
    today.getMonth() + 1 < 10
      ? `0${today.getMonth() + 1}`
      : today.getMonth() + 1
  }`;

  const [selectedYear, setSelectedYear] = useState(thisYear);
  const [selectedMonth, setSelectedMonth] = useState(thisMonth);

  // ... existing code ...

  const handleDataSend = async (type) => {
    const thisYm = `${thisYear}${thisMonth}`;
    const choiceYm = `${selectedYear}${selectedMonth}`;

    if (type === 'monthly' && thisYm < choiceYm) {
      alert('현재 달 기준 이후로 전송할 수 없습니다.');
      return;
    }

    console.log(thisYm, choiceYm);

    const message =
      type === 'monthly'
        ? '월별검정데이터를 전송하시겠습니까? 전송은 수분이 소요되며 이후 결과조회에서 확인할 수 있습니다.'
        : '경제수명도태분석을 전송하시겠습니까? 전송은 수분이 소요되며 이후 결과조회에서 확인할 수 있습니다.';

    if (window.confirm(message)) {
      console.log('전송!');
      //   try {
      //     await axios.post('/bo/api/apiMonthCertificationData.do', {
      //       year: selectedYear,
      //       month: selectedMonth,
      //       gubn: type === 'monthly' ? 'M' : 'E',
      //       sendReason: '',
      //     });
      //   } catch (error) {
      //     console.error('Error:', error);
      //   }
    }
  };

  return (
    <div className="bo-content">
      <h2>축산경제통합시스템 API 전송</h2>

      <div className="table-container bold-border">
        <table>
          <tbody>
            <tr>
              <th width="120">기준월 선택</th>
              <td colSpan="2">
                <select
                  value={selectedYear}
                  onChange={(e) => {
                    setSelectedYear(Number(e.target.value));
                  }}
                >
                  {Array.from({ length: thisYear - 2018 }, (_, i) => (
                    <option key={2019 + i} value={2019 + i}>
                      {2019 + i}
                    </option>
                  ))}
                </select>
                년&nbsp;&nbsp;
                <select
                  value={selectedMonth}
                  onChange={(e) => setSelectedMonth(e.target.value)}
                >
                  {Array.from({ length: 12 }, (_, i) => {
                    const month = i + 1;
                    const monthStr = month < 10 ? `0${month}` : `${month}`;
                    return (
                      <option key={monthStr} value={monthStr}>
                        {monthStr}
                      </option>
                    );
                  })}
                </select>
                월
              </td>
            </tr>
            <tr>
              <th>월별검정데이터</th>
              <td>
                매월 25일에 자동으로 실행됩니다.
                <br />
                자동전송이 실패했을 경우 또는 다시 전송하고자 할 경우 날짜를
                선택하고 전송(1)버튼을 클릭하면 선택한 날짜에 대해 다시 전송을
                하게 됩니다.
              </td>
              <td width="80">
                <div className="button-group">
                  <button
                    className="btn-l"
                    onClick={() => handleDataSend('monthly')}
                    type="button"
                  >
                    전송&#40;1&#41;
                  </button>
                </div>
              </td>
            </tr>
            <tr>
              <th>경제수명도태분석</th>
              <td>
                1년에 2번 수동으로 실행합니다.
                <br />
                선택한 날짜가 기준월이 됩니다.
                <br />
                날짜 선택하고 전송(2)버튼을 클릭하면 선택한 날짜에 대해 전송을
                하게 됩니다.
              </td>
              <td>
                <div className="button-group">
                  <button
                    className="btn-l"
                    onClick={() => handleDataSend('eco')}
                    type="button"
                  >
                    전송&#40;2&#41;
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default ApiRequest;
