import React from 'react';

import d3by67by1 from 'assets/images/sub/_images/3-67-1.jpg';
import d3by67by2 from 'assets/images/sub/_images/3-67-2.jpg';
import d3by71by1 from 'assets/images/sub/_images/3-71-1.jpg';
import d3by71by2 from 'assets/images/sub/_images/3-71-2.jpg';
import d3by73 from 'assets/images/sub/_images/3-73.jpg';
import d3by74 from 'assets/images/sub/_images/3-74.jpg';
import d3by79 from 'assets/images/sub/_images/3-79.jpg';
import d3by83by2 from 'assets/images/sub/_images/3-83-2.jpg';
import d3by83by3 from 'assets/images/sub/_images/3-83-3.jpg';
import d3by83by4 from 'assets/images/sub/_images/3-83-4.jpg';
import d3by85 from 'assets/images/sub/_images/3-85.jpg';
import d3by87 from 'assets/images/sub/_images/3-87.jpg';

function Fo61() {

  return (
    <div className="techinfo_view">
				<p className="viewTitle">육성우사의 계획과 관리</p>
				<h2>Corral Barn(그늘막 우사)이란?</h2>
				<ul className="listStyle04 mg15f">
					<li>동기간, 사내온도가 외기온과 거의 동일우사.</li>
					<li>완전히 단열하지 않던가, 지붕만을 단열한다.</li>
					<li>자연 환기방식을 채용한다.</li>
					<li>Calf hutch, Super calf hutch도 Corral Barn의 일종.</li>					
					<li>적절한 환기설계에 의해 우사내의 공기의 질은 상당히 양호하게 보존된다.</li>					
					<li>건설비, 관리비라도 Warm Barn보다도 쌈.</li>					
					<li>사내의 온도는 외기온에 좌우되고 섬세하고 빈틈없는 조절은 할 수 없다.</li>					
					<li>사내온도가 빙점하가 되는 것이 있으므로 수도의 동결방지 대책이 필요.</li>					
					<li>우사내를 따뜻하게 하려고 해서 환기량을 즐여서는 안된다.</li>
				</ul>
				<div className="imgBox mg60f"><img src={d3by67by1} alt=""/> <img src={d3by67by2} className="mg20l" alt="Corrall Barn의 예 : 벽에는 개폐가능한 입기구가 설치되어 Open ridge가 되어 있다."/></div>

				<h2>Warm Barn이란?</h2>
				<ul className="listStyle04 mg15f">
					<li>충분한 환기를 하더라도 사내온도가 0℃이상으로 유지되도록 설계된 우사.</li>
					<li>지붕, 벽에 단열재를 넣는다.</li>
					<li>강제 환기방식을 채용한다.</li>
					<li>적절한 단열, 환기 설계에 의해 한냉기에도 양호한 환경으로 작업을 할 수 있다.</li>					
					<li>건설비, 관리비라도 Corral Barn보다도 고가.</li>
				</ul>
				<div className="imgBox mg60f"><img src={d3by71by1} alt="지붕은 백색이 가장 좋음(붉은 색, 청색은 회색과 거의 동일한 온도가 된다.)"/> <img src={d3by71by2} className="mg20l" alt=""/></div>
				
				<h2>지붕은 무슨 색이 좋은가?</h2>
				<ul className="listStyle04 mg60f">
					<li>서열방지를 위해 백색이 바람직하다. 실버(은색)보다도 백색쪽이 효과적. 백색에 가까운 색이 아니라 [새하얀색]으로 한다. </li>					
					<li>더운 여름, 나란히 주차해 있는 백색차와 백색이외의 차이의 트렁크에 동시에 손을 대보면 백색의 방서효과를 실감할 수 있다.</li>
				</ul>
				
				<h2>차양의 길이는? </h2>
				<ul className="listStyle04 mg15f">
					<li>태양고도가 높은 여름은 햇살이 사내에 잠입하지 않도록. 태양고도가 낮은 겨울은 사내에 일사를 들일 수 있도록 설계한다.</li>					
					<li>저녁해(夕陽)은 차양만으로는 차단할 수 없다. 대문 양쪽의 울타리벽을 설치하든가 한냉발을 이용하여 여름의 석양이 소에게 직접 비추지 않도록 한다.</li>
				</ul>
				<div className="imgBox"><img src={d3by73} alt=""/></div>
				<div className="tableWrap">
					<table className="tablestyle">
						<caption>태양의 남중고도</caption>
						<thead>
							<tr>
								<th className="first" rowSpan="3">북위↓</th>
								<th colSpan="5">남 중 고 도 (南 中 高 度)</th>
							</tr>
							<tr>
								<th>하지(下地)</th>
								<th>입하(立夏)<br/>입추(立秋)</th>
								<th>춘분(春分)<br/>추분(秋分)</th>
								<th>입춘(立春)<br/>입동(立冬)</th>
								<th>동지(冬至)</th>
							</tr>
							<tr>
								<th>6월 22일</th>
								<th>5월 6일<br/>8월 8일</th>
								<th>3월 21일<br/>9월 23일</th>
								<th>2월 4일<br/>11월 8일</th>
								<th>12월 22일</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>那覇(나하) (26°13′)→ 25°</td>
								<td>88.4°</td>
								<td>81.3°</td>
								<td>65.0°</td>
								<td>48.7°</td>
								<td>41.6°</td>
							</tr>
							<tr>
								<td>카고시마 (31°36′)→ 30°</td>
								<td>83.5°</td>
								<td>76.3°</td>
								<td>60.0°</td>
								<td>43.7°</td>
								<td>36.6°</td>
							</tr>
							<tr>
								<td>高知(코오찌) (33°33′)→ 32°</td>
								<td>81.5°</td>
								<td>74.3°</td>
								<td>58.0°</td>
								<td>41.7°</td>
								<td>34.6°</td>
							</tr>
							<tr>
								<td>京都(쿄토) (35° 1′)→ 34° </td>
								<td>79.5°</td>
								<td>72.3°</td>
								<td>56.0°</td>
								<td>39.7°</td>
								<td>32.6°</td>
							</tr>
							<tr>
								<td>富山(토야마) (36°41′)→ 36°</td>
								<td>77.5°</td>
								<td>70.3°</td>
								<td>54.0°</td>
								<td>37.7°</td>
								<td>30.6°</td>
							</tr>
							<tr>
								<td>秋田(아키타) (39°43′)→ 38°</td>
								<td>75.5°</td>
								<td>68.3°</td>
								<td>52.0°</td>
								<td>35.7°</td>
								<td>28.6°</td>
							</tr>
							<tr>
								<td>札幌(삿뽀로) (43° 4′)→ 40°</td>
								<td>73.5°</td>
								<td>66.3°</td>
								<td>50.0°</td>
								<td>33.7°</td>
								<td>26.6°</td>
							</tr>
							<tr>
								<td>稚內 (45°25′)→ 45°</td>
								<td>68.5°</td>
								<td>61.4°</td>
								<td>45.0°</td>
								<td>28.7°</td>
								<td>21.6°</td>
							</tr>
						</tbody>
					</table>
				</div>
				<ul className="refer mg60f" style={{marginTop: '-20px'}}>
					<li>(理科年表) (單位°) </li>
					<li>여름의 햇살 북위 40°(8월 8일) </li>
					<li>겨울의 햇살 북위 40°(12월 22일)</li>
				</ul>
				
				<h2>더위를 막는다. </h2>
				<ul className="listStyle04 mg15f">
					<li>방사에 의해 소가 받는 열을 작게 한다.</li>					
					<li>대류, 증산(蒸散)에 의한 소의 방열을 크게 한다.</li>
				</ul>
				<div className="imgBox"><img src={d3by74} alt=""/></div>
				<div className="tableWrap mg60f">
					<table className="tablestyle">
						<caption>표. 기설, 신설별의 우사의 방서 대책. </caption>
						<thead>
							<tr>
								<th className="first">개선해야 할 열의 이동경로</th>
								<th>유우의 열 출입을 유리하게 하는 방법</th>
								<th>기설우사에 있어서의 구체적 대책</th>
								<th>신축, 개축시에 있어서의 구체적 대책</th>							
							</tr>
						</thead>
						<tbody>
							<tr>
								<td rowSpan="2">방사</td>
								<td>지붕의 내표면 온도를 내린다. </td>
								<td>지붕면을 백색으로 도장한다. 지붕에 산수한다. 지붕에 단열재를 넣는다. </td>
								<td>백색의 지붕재료를 사용한다. 지붕을 단열한다.</td>
							</tr>
							<tr>
								<td>소에게 일사를 쬐지 않는다.</td>
								<td>갈대발등의 차양을 활용한다. 차양이 너무 짧을 경우에는 아래채를 설치한다.</td>
								<td>남측에는 반드시 차양을 설치한다. 차양을 설치한다.</td>
							</tr>
							<tr>
								<td rowSpan="2">대류· 증산</td>
								<td>우사내 온·습도의 상승을 막는다.</td>
								<td>창을 완전히 떼어 내어 통풍을 좋게 한다. </td>
								<td>측벽은 전면 개방할 수 있는 구조로 한다.</td>
							</tr>
							<tr>
								<td>소에게 맞는 풍속을 크게 한다.</td>
								<td>Duck fan(환풍기)를 설치한다.</td>
								<td>Duck fan(환풍기)를 설치한다.</td>
							</tr>
						</tbody>
					</table>
				</div>
				
				<h2>결로(結露)를 막는다. </h2>
				<ul className="listStyle04 mg60f">
					<li>온도가 높은 공기만큼 수증기를 많이 함유하고 있다. 공기가 차게 되면 그것까지 함유하고 있는 수증기를 보존하고 유지할 수 없게 되어 결로가 발생한다.</li>					
					<li>찬 컵의 표면이 물방울을 맺히게 하는 것은 공기가 컵에서 차게되어 결로하기 때문이다. 우사의 결로도 이것과 동일하다.</li>				
					<li>결로가 발생하는 온도를 이슬점온도라 하고 공기의 온도와 습도에 의해 결정된다.</li>				
					<li>Corrall Barn에서는 한냉기, 벽과 지붕의 내표면 온도가 상당히 내린다. 환기가 불충분으로 우사내 온도, 습도가 오르면 내표면 온도가 우사내의 이슬점온도보다 낮게 되어 결로한다. 한냉기에도 충분한 환기가 필요. </li>				
					<li>Corral Barn(그늘막 우사)에서는 단열재의 두께와 환기량에 충분히 유의하여 설계함과 동시에 단열재의 우사측에는 방습층을 설치한다.</li>
				</ul>
				
				<h2>결로(結露)와 단열(斷熱) </h2>
				<ul className="listStyle04 mg60f">
					<li><strong>무단열 (Corrall Barn 환기부족의 예)</strong><br/>
						벽의 축사측 표면 온도는 낮다. 환기부족으로 축사내가 따뜻하면 결로는 피할 수 없다. 벽의 내표면 온도가 마이너스이면 결상한다. 환기량을 줄여 Corrall Barn을 따뜻하려고 해서는 안된다. 
					</li>					
					<li><strong>단열 + 방습층 (Warm Barn 좋은 예)</strong><br/>
						벽의 축사측의 표면온도는 높다. 방습층에 의해 수분은 단열재안으로 유입하지 않기 때문에 단열재의 내부라도 결로는 발생하지 않는다.
					</li>				
					<li><strong>단열(유습성) (Warm Barn 나쁜예) (벽내결로)</strong><br/>
						벽의 축사측의 표면온도는 높다. 그러나 방습층이 없기 때문에 수분은 단열재안으로 들어가고, 벽의 내부의 이슬점온도보다도 낮으므로 결로한다. 단열재는 수분을 함유하면 단열성이 급격히 저하한다.
					</li>
				</ul>
				
				<h2>부료와 분뇨처리방법은? </h2>
				<ul className="listStyle04 mg15f">
					<li>우사의 Type에 의해 필요한 부료량과 분뇨처리 방식은 다르다. </li>					
					<li>우사를 계획할 때 최초로 검토해야 할 것은 분뇨를 어떻게 처리할 것인가이다.</li>				
					<li>입수 가능한 부료의 종류량, 경지면적과의 관계에 대하여 충분히 검토한다.</li>
				</ul>
				<div className="imgBox mg60f"><img src={d3by79} alt=""/></div>
				
				<h2>목조우사의 방부방법은?</h2>
				<ul className="listStyle04 mg15f">
					<li>토대주변과 Stall Barn의 칸막이 목책등이 특히 부후[(腐朽): 썩기쉽다]하기 쉽다. </li>					
					<li>충분히 건조한 목재를 사용하여 적절한 약제로 방부한다.</li>			
					<li>약제 도포후는 충분히 건조시키고 나서 우사에 소를 넣는다.</li>
				</ul>
				<div className="tableWrap">
					<table className="tablestyle">
						<caption>우사의 부후개소(腐朽個所)와 방부용(防腐用) 약제(藥劑) </caption>
						<thead>
							<tr>
								<th className="first" colSpan="2">구분</th>
								<th>대상으로 하는 목재를 사용하는 개소</th>
								<th>처리할 구체적인 부재명</th>
								<th>처리방법</th>					
								<th>약제등</th>			
							</tr>
						</thead>
						<tbody>
							<tr>
								<td rowSpan="2">필요</td>
								<td>ⅰ</td>
								<td>토대</td>
								<td>토대</td>
								<td rowSpan="2">가압주입 처리 목재 또는 현장 처리</td>
								<td rowSpan="2">Creosote(크레오소트)유 (예를 들면 Creosote)가압주입공법과 표면 처리공법. CCA계 20% 예를 들면(Boride:붕화물) 가압주입공법만 </td>
							</tr>
							<tr>
								<td>ⅱ</td>
								<td>지면에서부터 높이 1m이내의 목제 외벽 둘레</td>
								<td>기둥, 샛기둥, 지주 내력면재를 포함한다. 얇은 부분(3할)의 Torus(합판 포함)</td>
							</tr>
							<tr>
								<td>바람직하다</td>
								<td>ⅲ</td>
								<td>지붕등</td>
								<td>지붕의 처마끝 부분의 몸체, 서까래, 야지판</td>
								<td>현장처리</td>
								<td>유기인(P)계 표면처리공법만. 콜타르(Cortar)</td>
							</tr>
						</tbody>
					</table>
				</div>
				<ul className="refer mg60f" style={{marginTop: '-20px'}}>
					<li>(山下:야마시따)</li>
				</ul>
				
				<h2>철골우사의 녹을 막기위해서는 ?</h2>
				<ul className="listStyle04 mg15f">
					<li>분뇨와 소와 접촉하는 부분과 접촉하지 않는 부분에는 녹의 요인, 진행상황이 다르다.</li>					
					<li>분뇨에 접촉되면 철은 상당히 빠르게 녹슨다. 보통의 합성수지 조합 페인트로는 효과는 없고, 용융아연 도금이든지 중(重) 방식(方式)도료로 대응한다.</li>			
					<li>합성수지 조합페인트는 소와의 접촉에도 약하다. 소에 접촉한 경우는 용융아연으로 도금된 재료를 사용하는가. 유리테이프를 싸서 도장한다. </li>		
					<li>우사 상부는 환기가 좋게 결로하지 않으면 녹의 진행이 느리다. 타르를 증류한 뒤에 남은 검은 점액(목재의 방부제에 이용됨)등 단면의 복잡한 재료는 도장이 어렵기 때문에 아연도금 경량형강(輕量形鋼)이 바람직하다.</li>		
					<li>우상의 덧칠은 중요하기 때문에 건설시에 견고하게 방식처리를 한다. </li>
				</ul>
				<div className="tableWrap mg30f">
					<table className="tablestyle">
						<caption>우사의 각부위에 적합한 방식 방법</caption>
						<thead>
							<tr>
								<th className="first">우사의 각부위</th>
								<th>대응</th>
								<th>방식(防蝕) 방법</th>			
							</tr>
						</thead>
						<tbody>
							<tr>
								<td className="left">우사내 하부에서 소와 분뇨와 접촉한 부분</td>
								<td rowSpan="7"></td>
								<td className="left">두꺼운 막·2액형 변성 에폭시 수지(Epoxy resin)계 도료3회 칠</td>
							</tr>
							<tr>
								<td className="left">특히 소와 빈번히 접촉한 부분</td>
								<td className="left">두꺼운 막· 2액형 변성 에폭시 수지(Epoxy resin)계 도료 2회 칠</td>
							</tr>
							<tr>
								<td className="left">우사내 상부등 소와 분뇨와 접촉하지 않은 부분</td>
								<td className="left">두꺼운 막·2액형 변성 에폭시 수지(Epoxy resin) 3회 칠 (중간칠때에 Glass cross tape:유리테이프를 쌈)</td>
							</tr>
							<tr>
								<td rowSpan="4" className="left">기둥다리와 목책의 경계의 전(栓)부분</td>
								<td className="left">유성·합성수지조합 페인트 3회 칠</td>
							</tr>
							<tr>
								<td className="left">콘크리트에 의한 전(栓)</td>
							</tr>
							<tr>
								<td className="left">소금절인 파이프 및 콘크리트에 의한 전(栓)</td>
							</tr>
							<tr>
								<td className="left">FRP칠</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div className="tableWrap mg60f">
					<table className="tablestyle">
						<tbody>
							<tr>
								<td rowSpan="2" className="left">
									<ul>
										<li>2년간, 분뇨에 침지된 시험구. <br/>합성수지조합페인트는 너덜 너덜 벗겨져 있다.</li>
										<li>변성 에폭시계 도료는 중 방식도료의 1개. </li>
										<li>용융아연도금은 분이 부착하여 있지만</li>
										<li>녹은 없다. 합성수지조합 변성 에폭시(Epoxy)계</li>
									</ul>
								</td>
								<td><img src={d3by83by2} alt=""/></td>
								<td><img src={d3by83by3} alt=""/></td>
								<td><img src={d3by83by4} alt=""/></td>
							</tr>
							<tr>
								<td colSpan="3">콘크리트를 전이 깎임으로 인해 부식된 주각부. 용융아연도금 </td>
							</tr>
						</tbody>
					</table>
				</div>
				
				<h2>개축인가 신축인가? </h2>
				<ul className="listStyle04 mg15f">
					<li>개축비가 신축비용의 1/2∼1/3이상이면 신축하는 쪽이 좋다.</li>					
					<li>신축 경우 현재 우사를 다른 용도로 전용할 수 없는가 위치관계를 포함해 검토해 본다. </li>				
					<li>계획에맞게는 장래의 규모확대를 충분히 고려한다. 부지조건이 허락하면 동방향으로 증축가능한 장소를 선택한다. </li>
					<li>우사의 배치가 결정되면 현지에 임시 말뚝을 박아 보고 문제가 없는가 확인한다.</li>
					<li>자우의 육성방침을 결정할 기회. </li>
				</ul>
				<div className="imgBox mg60f"><img src={d3by85} alt="증축한 우사. 신축의 우사."/></div>
				
				<h2>시설이 좋으면 아주 온전한가?</h2>
				<ul className="listStyle04 mg15f">
					<li>어떻게 근사한 시설이라도 관리를 태만해서는 좋은 소는 성장하지 않는다.</li>					
					<li>시설에 약간의 결점이 있어도 좋은 관리로 인해 보충하는 것도 가능</li>				
					<li>부지런한 소의 관찰과 처치, 우사의 청소, 청의 개폐로 인한 환기량의 조절. 파손(破損) 개소(個所)의 빠른 보수등이 기본.</li>
					<li>일상의 관리야 말로 낙농가의 마음과 자우의 건강이 있다.</li>
				</ul>
				<div className="imgBox mg60f"><img src={d3by87} alt="일상의 관리야 말로 중요."/></div>
				
				<div className="bgBox">
					<h3>저자(著者) 소개 </h3>
					<ul className="listStyle01">
						<li>佐藤義和(사토오요시카즈) </li>
						<li>1957년 山形(야마카타)縣(현) 鶴岡市 출생</li>
						<li>북해도 대학 농학부 졸업</li>
						<li>현재 농림수산성 북해도 농업시험장 농지농업시설연구실 연구원</li>
						<li>1991년 8월 20일 발행</li>
						<li>발행: Dairy man 社</li>
					</ul>
				</div>
			</div>
  );
}

export default Fo61;
