import React from 'react';

import pic669 from 'assets/images/sub/_images/pic669.jpg';
import pic670 from 'assets/images/sub/_images/pic670.jpg';
import pic671 from 'assets/images/sub/_images/pic671.jpg';

function Do133() {

  return (
    <div className="techinfoview">
      <h1>변형 적혈구 증가증</h1>
      <p className="pstyle">적혈구의 대부분이 이상한 모양(버섯모양, 별 모양 등)으로 변형되어 적혈구의 본래의 역할인 가스교환이 정상적으로 이루어지지 못해 호흡곤란을 나타내는 병으로 특히 출생직후의 갓난 송아지에 많이 볼 수 있다. </p>
      <div className="leftpart">
        <h2>원인</h2>
        <ul>
          <li>정확한 원인은 아직 규명되지 않고 있으나 태아 생성기 때 골수의 적혈구 생성기능에 이상이 생기기 때문이라고 생각된다. </li>
        </ul>
        <h2>증상</h2>
        <ul>
          <li>출생직후 특별한 이상이 없음에도 불구하고 호흡이 빨라진다. (1분에 60회 이상) </li>
          <li>기운이 없고 포유시간이 길며, 이물성 폐염증세와 비슷하다. </li>
          <li>체온은 정상이며 눈의 결막이나 질점막등은 빈혈로 인해 창백해진다. </li>
          <li>치료가 늦어지면 증상이 악화되어 폐사하며, 다행히 폐사하지 않더라도 발육이 지연된다. </li>
        </ul>
        <h2>목장에서의 처치</h2>
        <ul>
          <li>이 병이 의심이 될 때는 송아지를 안정시키고 수의사의 왕진을 구한다. (본증은 혈액검사로써 진단할 수 있다. ) </li>
          <li>이 병으로 진단되었을 때 즉시 수혈(혈액 500-1000cc)하면 회복되는 수가 많다. </li>
          <li> 증상이 심할 때는 2∼3회 반복하여 수혈할 필요가 있다.</li>
        </ul>
      </div>
      <div className="rightpart">
        <img src={pic669} className="nonborder" alt=""/>
        <img src={pic670} className="nonborder" alt=""/>
        <img src={pic671} className="nonborder" alt=""/>
      </div>
    </div>
  );
}

export default Do133;