import React from 'react';

import pic412 from 'assets/images/sub/_images/pic412.gif';
import pic413 from 'assets/images/sub/_images/pic413.gif';
import pic414 from 'assets/images/sub/_images/pic414.gif';
import pic415 from 'assets/images/sub/_images/pic415.gif';
import pic416 from 'assets/images/sub/_images/pic416.gif';
import pic417 from 'assets/images/sub/_images/pic417.gif';
import pic418 from 'assets/images/sub/_images/pic418.gif';

function Do215() {

  return (
    <div className="techinfoview">
      <h1>축사환경-자연환기시스템</h1>
      <p className="pstyle">&nbsp;&nbsp;&nbsp;젖소에게 신선하고 깨끗한 공기를 제공해 주고 쾌적한 환경을 만들어 주는데는 환기가 가자 중요하다. 환기과정을 살펴보면 ①신선한 공기가 설치된 환기구를 통하여 건물 내부로 유입되어 ②이미 건물 내부에 있던 공기와 섞여 ③유입된 신선한 공기가 열과 수분을 흡수하여 온도와 습도를 떨어 뜨린 후 ④습기, 열 및 오염물질을 동반하여 건물 밖으로 빠져나간다. 이러한 과정 중에 하나라도 문제가 생기면 적절한 환기가 이루어 질 수 없다. <br/>&nbsp;&nbsp;&nbsp;환기시설은 축사 내의①온도와 습도 ②온도의 균등성 ③소를 스치는 공기의 속도 ④냄새와 가스농도 ⑤공기 중에 떠 다니는 먼지와 병원물질 수준 등에 영향을 미친다. <br/>&nbsp;&nbsp;&nbsp;환기 방식은 풍압과 축사 내외부의 온도차를 이용하는 자연 환기방식과 환기팬을 이용하여 공기의 유출을 조절하는 기계식 환기방식으로 나뉜다.</p>
      <h2>자연환기 시스템</h2>
      <p className="pstyle">&nbsp;&nbsp;&nbsp;풍압과 축사 내외부의 온도차를 이용하는 방법으로, 그림 83과 같이 처마와 벽상단의 입기구를 통하여 신선한 공기가 유입되면 내부의 더운 공기와 섞인 후, 용마루에 설치된 배기구를 통하여 빠져 나간다. 바람이 없는 잔잔한 날에도 덥고 습한 공기가 용마루 배기구를 통하여 빠져 나가는 , 소위 굴뚝현상 때문에, 어느 정도의 환기가 이루어 진다. 겨울철에는 처마나 벽상단의 입기구가 신선한 공기 유입의 주요 수단으로 이용되지만, 여름철에는 벽을 개방하여 바람이 축사내를 통과할 수 있도록 한다. </p>
      <img src={pic412} className="nonborder" width="60%" alt=""/>
      <p>그림 83. 자연 환기방식 건물의 공기흐름</p>
      <ul>
        <li>설계
          <ul>
            <li>축사의 위치 
              <ul>
                <li>축사의 위치는 자연환기방식에 있어서 가장 중요한 요소이다. 축사는 높은 곳에 위치하도록 설계하여야 하고, 나무, 사일로, 옥수수 등과 같이 높이 자라는 작물과 그 밖의 구조물들은 축사 쪽으로 부는 바람을 방해할 수 있기 때문에, 이러한 방해물로부터 사방이 최소한 50′떨어지도록하여 축사를 짓는다.  </li>
              </ul>
            </li>
            <li>축사의 방향
              <ul>
                <li>축사의 방향도 자연환기에 많은 영향을 미친다. 여름철에 바람이 축사의 긴 측면을 향하여 볼 수 있도록 축사의 설치방향을 결정해야 한다. 따라서 여름철 바람의 방향에 따라서 축사의 긴 쪽이 남향 또는 동남향이 되도록 한다. </li>
              </ul>
            </li>
            <li>용마루 배기구 
              <ul>
                <li>용마루에 지붕의 폭 10′당 배기구의 폭이 2″되게 설치한다. 용마루 배기구를 통하여 빠져나가는 공기 때문에 비나 눈이 용마루 배기구를 통하여 유입되는 것이 대부분 차단되므로, 용마루 배기구에 커버를 설치할 필요가 없다. 비나 눈의 차단을 돕기 위하여 그림 84와 같이 배기구 폭의 1 1/2∼2배 높이의 스탠드(upstand)를 설치할 수 있다. 가능하면, 용마루 배기구 바로 아래는 분뇨통로와 같이 눈이나 비에 덜 민감한 부분이 위치하도록 설계한다. 용마루 배기구로부터 2′∼3′하단에 물받이 통로를 설치하여 빗물을 축사 밖으로 빠지도록 한다(그림 85).</li>
              </ul>
              <img src={pic413} width="40%" alt=""/>
              <p>그림 84. 용마루 스탠드</p>
              <img src={pic414} width="40%" alt=""/>
              <p>그림 85. 용마루 배기구 밑에 설치된 물받이 통</p>
            </li>
            <li> 처마 입기구 
              <ul>
                <li>처마 입기구는 그림 86에 나타난 바와 같이 축사의 측면벽 상단에 설치하여, 입기구 폭은 최소한 용마루 배기구 폭의 1/2이상이 되도록 한다. 몹시 추운 겨울날에는 처마 입기구를 막아 줘야 하지만, 너무 장기간 동안 막아서는 안된다. 처마에 설치된 환기창(fascia)은 찬바람이 직접 입기구를 통하여 강하게 유입되는 것을 막아 준다.</li>									
              </ul>
              <img src={pic415} className="nonborder" width="60%" alt=""/>
              <p>그림 86. 처마 입기구의 상세도</p>
            </li>
            <li>측면 및 끝벽 개방
              <ul>
                <li>여름철에는 측면벽을 완전히 개방하여, 마치 축사가 그늘막처럼 보이도록 한다. 여름철 측면벽 개방형태가 그림 87과 88에 나타나 있다. 여름철에는 소 키의 높이로 바람이 불어 들어오도록 측면벽을 최대로 개방하고, 개방할 수 없을 경우에는 측면 하단을 개방하도록 한다(그림 89). 여름철 끝벽 개방에 대한 예는 그림 87에 나타나 있는데, 끝벽에 설치된 판자나 커튼을 제거하므로써 여름철 환기를 도모할 수 있다. </li>
              </ul>
            </li>
            <li>지붕의 기울기 
              <ul>
                <li>지붕의 경사가 급할수록 축사내 더운 공기가 용마루 배기구를 통하여 잘 빠져나가지만, 경사가 지나치게 급하면 유입된 공기가 빠르게 배출되어 더운 공기와 섞일 시간적 여유가 없어진다. 또한, 경사가 너무 완만하면 공기의 배출이 느려져 축사 내부의 온도가 상승하고 수분응축이 생긴다. 따라서, 지붕의 기울기는 4/12∼6/12가 되도록 한다.</li>									
              </ul>
              <img src={pic416} className="nonborder" width="30%" style={{float:'left'}} alt=""/>
              <img src={pic417} className="nonborder2" style={{width: '30%'}} alt=""/>
              <p>&nbsp;&nbsp;&nbsp;&nbsp;그림 87. 환기를 위한 부분 개방식 커튼 체계&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;그림 88. 환기를 위한 완전개방식 커튼 체계</p>
              <img src={pic418} width="50%" className="nonborder" alt=""/>
              <p>그림 89. 여름철 환기를 위한 끝벽의 개방</p>
            </li>
          </ul>
        </li>
        <li>새의 침입방지
          <ul>
            <li>폐쇄식 우사의 환기구에 망을 설치하므로써 새의 침입을 줄일 수 있는데, 단단한 3/4″천이나 플라스틱망을 설치할 수 있다. 겨울철에는 환기구에 설치된 망에 얼음이 쌓이면 제거하여야 한다. 트러스 코오드(truss chord)에 플라스틱망을 설치하여 새가 둥지를 만들지 못하도록 할 수 있으며, 처마환기구에는 망을 수평으로 설치하여 새가 앉거나 둥지를 만들지 못하게 한다. </li>
          </ul>
        </li>
        <li>겨울철 환기관리
          <ul>
            <li>배기구나 입기구를 완전히 막지 말아야 한다. 처마 입기구는 최소한 축사 폭 10′당 1/2″만큼 열려 있어야 한다. 아주 추운 날씨일 경우에는 일시적으로 환기구를 막아 주지만, 날씨가 풀리면 반드시 다시 열어 주는 것을 잊어서는 안된다. 따뜻한 겨울날씨일 경우에는 측면벽 일부를 개방하여 환기시킨다. </li>
          </ul>
        </li>
        <li>여름철 환기관리
          <ul>
            <li>환기장치로 무더운 여름철 더위 스트레스를 해결할 수 없을 경우에는 우상당 500 cfm 용량의 선풍기를 천장에 설치하여 소 위에 더운 공기를 이동시킬 수 있다. 또한, 사료급여 통로 쪽에 스프링쿨러 장치를 설치하여 소를 완전히 적셔서 수분이 증발하면서 소의 열을 식히도록 하는 방법도 있다. 스프링쿨러는 사료급여통로 위나 대기장 내에 설치할 수 있는데, 물에 소가 흠뻑 젖을 정도로 장시간 작동되도록 하여야 한다. 이때 반드시 송풍을 해주어야 한다. </li>
          </ul>
        </li>
      </ul>
      <h2>자연환기법의 문제점 및 해결방법</h2>
      <p className="pstyle">자연환기법에서 생기는 대부분의 문제점은 해결하기 쉽다. 환기구의 설치가 적절하지 않거나 관리가 소홀할 경우에 환기문제가 주로 발생하는데, 몇 가지 일반적인 문제점, 원인 및 해결방법의 예를 들어보면 다음과 같다.  </p>
      <ul>
        <li>문제점 A : 습도가 높고 냄새가 많이 남 
          <ul>
            <li>원인
              <ul>
                <li>불충분한 공기교환</li>
                <li>냄새와 습기 발생원의 존재</li>
              </ul>
            </li>
            <li>해결방법
              <ul>
                <li>권장된 규격의 환기구 설치 </li>
                <li> 환기구의 올바른 작동 확인</li>
                <li>환기구의 먼지 제거 </li>
                <li>자연 환기구의 설치가 부적합한 경우에는 기계식 환기방식을 고려 </li>
                <li>용마루 배기구 커버의 제거</li>
                <li>과밀되지 않도록 권장 두수를 수용</li>
                <li>분뇨와 젖은 깔짚을 제거하여 청결상태 유지</li>
                <li>급수조나 파이프에서 물이 새지 않도록 조치 </li>
              </ul>
            </li>
          </ul>
        </li>
        <li>문제점 B : 천정과 벽에 지나친 수분응축 
          <ul>
            <li>원인
              <ul>
                <li>축사 내부면의 온도가 이슬점 온도보다 낮아 공기 중에 수분의 함량이 많아진다. </li>
              </ul>
            </li>
            <li>해결방법 
              <ul>
                <li>충분한 공기교환을 통하여 수분제거</li>
                <li>폐쇄식 축사의 경우, 단열처리가 잘 되어 있는지를 확인하고 보수</li>
                <li>개방식 축사의 경우, 마룻대(purlin)가 4″이상 깊으면 지붕위의 공기 흐름을 방해하므로 지붕을 단열처 리하고 도리를 매끄럽게하여 공기의 흐름을 원활하게 조치</li>
                <li>내부와 외부온도의 차이를 0.2℃∼3.2℃ 내에 유지</li>
              </ul>
            </li>
          </ul>
        </li>
        <li>문제점 C : 여름철에 지나치게 높은 실내온도  
          <ul>
            <li>원인 
              <ul>
                <li>불충분한 공기교환 및 순환</li>
                <li>지나친 지붕 복사열</li>
                <li>높은 외부 온도</li>
              </ul>
            </li>
            <li>해결방법
              <ul>
                <li>소의 키 높이에서 공기가 흐를 수 있도록 적절한 환기구 마련</li>
                <li>공기의 흐름을 방해하는 장애물 제거</li>
                <li>공기 순환팬이나 스프링쿨러 설치</li>
                <li>투명한 재질의 지붕일 경우, 차광망 설치</li>
              </ul>
            </li>
          </ul>
        </li>
        <li>문제점 D : 겨울철에 지나친 샛바람의 유입 
          <ul>
            <li>원인 
              <ul>
                <li>부적절한 샛바람 제어</li>
              </ul>
            </li>
            <li>해결방법
              <ul>
                <li>모든 여름철용 개방면을 봉함</li>
                <li>바람이 유입되는 쪽의 처마 입기구를 부분적으로 막음</li>
                <li>용마루 배기구 쪽으로 바람이 유입되면 배기구를 부분적으로 막아 주며 절대로 완전히 봉하지 말아야 한다.</li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
}

export default Do215;