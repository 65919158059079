import React from 'react';

// constants
import { BOARD_NAME } from 'constants/backOfficeBoard';

function ImproveInfoEdit() {
  return (
    <div className="bo-page">
      <h2>{BOARD_NAME.IMPROVE_INFO} 수정페이지</h2>
    </div>
  );
}

export default ImproveInfoEdit;
