import React from 'react';

import Pic469 from 'assets/images/sub/_images/pic469.jpg';
import Pic470 from 'assets/images/sub/_images/pic470.jpg';

function Do76() {

  return (
    <div className="techinfoview">
				<h1>제4위 식체</h1>
				<p className="pstyle">소화가 안되는 단단한 조사료 또는 이물(異物)이 제4위 내에 축적된 상태를 말한다.</p>
				<div className="leftpart">
					<h2>원인</h2>
					<ul>
						<li>짧게 절단된 볏짚 또는 체모(體毛)를 핥아 먹음으로써 제4위 내에 형성된 모구(毛球), 비닐, 태반(胎盤)과 같은 비소화성 물질이 4위내에 들어가서 십이지장으로 통하는 유문(幽門)을 막음으로써 발생한다. </li>
						<li>유문부 가까운 곳에 형성된 궤양이 유착되거나 종양등으로 유문부가 협착되어 식괴 통과가 어려울 때 발생한다.</li> 
					</ul>
					<h2>증상</h2>
					<ul>
						<li>식욕감퇴, 배분량감소 및 변비 </li>
						<li>송아지의 경우는 2∼3일 간격으로 고창증이 생겨 1위가 팽대된다. </li>
						<li>식체된 4위는 우측 하복부의 팽대를 일으킨다.</li>
						<li>소화제를 먹여 보아도 치료 효과가 별로 없고 점차 야위어 간다.  </li>
					</ul>
					<h2>목장에서의 처치</h2>
					<ul>
						<li>링겔액을 정맥주사하고 사하제를 투여한다. </li>
						<li>상기의 방법으로 효과가 없으면 수의사와 상의하여 수술을 받도록 한다. 일반적으로 예후는 불량하다.</li>
						<li>비닐, 끈 등 비소화성 물질을 소가 먹지 못하도록 치우고 볏짚은 너무 짧게 썰어주지 않는다.</li> 
					</ul>
				</div>
				<div className="rightpart">
					<img src={Pic469} className="nonborder" alt=""/>
					<p>*소의 위에서 발견된 모구 </p>
					<img src={Pic470} className="nonborder" alt=""/>
					<p>숫송아지의 제4위 식체:우하복부의  팽대를 볼 수 있다. </p>
				</div>
			</div>
  );
}

export default Do76;