import React from 'react';

import pic726 from 'assets/images/sub/_images/pic720.jpg';
import pic727 from 'assets/images/sub/_images/pic721.jpg';
import pic728 from 'assets/images/sub/_images/pic722.jpg';

function Do151() {

  return (
    <div className="techinfoview">
      <h1>몰리브덴(Molybdenum)중독</h1>
      <p className="pstyle">몰리브덴(Mo)의 함유량이 높은 토양이나 광산, 제련소근처의 몰리브덴 오염지대에서 자란 목초를 다량 급여함에 의해 일어난다.</p>
      <div className="leftpart">
        <h2>원인</h2>
        <ul>
          <li>몰리브덴은 미량 무기물인 구리(Cu)의 체내 이용을 방해하여 구리(Cu) 핍증상을 나타내게 한다.</li>
        </ul>
        <h2>증상</h2>
        <ul>
          <li>피모의 퇴색과 탈모증상, 특히 눈주변에 뚜렷하게 나타난다.</li>
          <li>검부쪽의 피부는 파도모양으로 결을 이루고 있다.</li>
          <li>약 80%의 소에서 설사를 나타낸다. 착유우인 경우는 설사의 정도가 심하며 지사제의 사용으로도 효과가 없다. </li>
          <li>등을 구부리고 거동이 자유로빚 못하며 심하면 기립불능이 된다.</li>
          <li> 체온, 호흡, 맥박은 정상이다.</li>
          <li>수태율이 극히 저하한다.</li>
        </ul>
        <h2>목장에서의 처치</h2>
        <ul>
          <li>몰르브덴 함량이 많은 목초급여를 즉각 중지해야 한다.</li>
          <li>일반적으로 황상동을 1일 1.5∼5.0g 정도를 물에 녹여 증상이 개선될 때까지 매일 먹인다.</li>
          <li>황산동을 과잉 급여하면 구리중독을 일으키므로 전문가와 상담, 적절히 투여하도록 해야한다.</li>
          <li>회복되는 모습은 대개 설사중지, 올바른 걸음걸이. 영양상태 회복. 피모상태의 호전으로 이어진다.</li>
        </ul>
      </div>
      <div className="rightpart">
        <img src={pic726} className="nonborder" alt=""/>
        <img src={pic727} className="nonborder" alt=""/>
        <img src={pic728} className="nonborder" alt=""/>
      </div>
    </div>
  );
}

export default Do151;