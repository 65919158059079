import React from 'react';

import Pic474 from 'assets/images/sub/_images/pic474.jpg';
import Pic475 from 'assets/images/sub/_images/pic475.jpg';
import Pic476 from 'assets/images/sub/_images/pic476.jpg';

function Do78() {

  return (
    <div className="techinfoview">
				<h1>지방괴사증</h1>
				<p className="pstyle">복강내에 과다하게 축적된 지방이 어떠한 원인에 의해 단단한 트리글리세라이드로 변화되어 이것이 덩어리가 되어 괴사적 변화를 일으킨 것(원인은 분명히 밝혀져 있지 않다)</p>
				<div className="leftpart">
					<h2>증상</h2>
					<ul>
						<li>분만전후에 발병하는 일이 많다
							<ul>
								<li>식욕부진과 변지가 반드시 나타난다. </li>
								<li>만성일때는 배분량이 줄어들고 설사가 나타나다가 피똥으로 바뀌며 식욕이 저하하고 야위어진다. </li>
								<li>지방괴사가 생기는 부위는 결장주변의 지방조직에 가장 많고(82.7%) 그 다음 직장주의(42.3%) 신장주의(27.9%)순이다. </li>
								<li>임상증상이 나타나면 병세는 급속히 악화된다. </li>
							</ul>
						</li>
					</ul>
					<h2>목장에서의 처치</h2>
					<ul>
						<li>특별한 치료법이 없다. </li>
						<li>과비로 인한 질병이므로 비만이 되지 않도록 적절한 사양 관리에 노력해야 한다. </li>
						<li>특히 송아지 때의 비만은 복강내 지방축적이 심하므로 사람의 비만아와 같이 성우가 되어도 비만이 된다.  </li>
					</ul>
					<h2>예방</h2>
					<ul>
						<li>식욕부진과 변비등 임상증상이 드러날 경우는 치료효과를 기대할 수 없다. </li>
						<li>발생가능성에 대비하여 분말의 율무를 일량 250g을 비타민 E와 병용하여 연일 사료에 섞어서 급여하여 3∼6개월후에 치료 및 예방 효과가 있음이 보고되고 있다.</li>
					</ul>
				</div>
				<div className="rightpart">
					<img src={Pic474} className="nonborder" alt=""/>
					<p>*지방괴사증에 걸린소</p>
					<img src={Pic475} className="nonborder" alt=""/>
					<p>*괴사된 지방으로 둘러싸여 직장내강이 막힌 상태 </p>
					<img src={Pic476} className="nonborder" alt=""/>
					<p>*신장주변의 괴사된 지방조직에 의해 위축된 신장의 모습</p>
				</div>
			</div>
  );
}

export default Do78;