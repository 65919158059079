import React from 'react';
import { Link } from 'react-router-dom';

// scss
import './notFound.scss';

function NotFound() {
  return (
    <div className="notFound">
      <h2>죄송합니다. 요청하신 페이지를 찾을 수 없습니다.</h2>
      <p>방문하시려는 페이지의 주소가 잘못 입력되었거나,<br/>페이지의 주소가 변경 혹은 삭제되어 요청하신 페이지를 찾을 수 없습니다.</p>
      <p>입력하신 주소가 정확한지 다시 한번 확인해 주시기 바랍니다.</p>
      <Link to='/Web/Main'>홈으로 이동</Link>
    </div>
  );
}

export default NotFound;
