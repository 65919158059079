import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getState, postState } from 'utils/api';

function SlvCodeEdit() {
  const [searchParams] = useSearchParams();
  const sCode = searchParams.get('s_code');
  const mCode = searchParams.get('m_code');

  const [formData, setFormData] = useState({
    m_code: mCode || '',
    s_code: sCode || '',
    s_name: '',
    s_desc: '',
  });

  const navigate = useNavigate();

  useEffect(() => {
    const fetchSlvCode = async () => {
      try {
        const response = await getState('/board/select_slave_code', {
          s_code: sCode,
          m_code: mCode,
        });
        if (response?.return_code === 'SUCCESS') {
          setFormData({
            s_code: response.result.s_code || sCode,
            m_code: response.result.m_code || mCode,
            s_name: response.result.s_name,
            s_desc: response.result.s_desc,
          });
        }
      } catch (error) {
        console.error('Error fetching slave code:', error);
      }
    };

    if (sCode && mCode) {
      fetchSlvCode();
    } else if (mCode) {
      setFormData((prev) => ({ ...prev, m_code: mCode }));
    }
  }, [sCode, mCode]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validation
    if (!formData.s_code) {
      alert('부코드를 입력해주세요.');
      return;
    }
    if (!formData.s_name) {
      alert('부 코드이름를 입력해주세요.');
      return;
    }

    try {
      const endpoint = sCode
        ? '/board/update_slave_code/'
        : '/board/insert_slave_code/';

      const response = await postState(endpoint, formData);

      if (response?.return_code === 'SUCCESS') {
        alert(sCode ? '수정되었습니다.' : '등록되었습니다.');
        navigate(`/bo/code/slvCodeList?m_code=${mCode}`);
      }
      if (response?.return_code === 'ERROR') {
        alert(response?.return_message);
      }
    } catch (error) {
      console.error('Error saving slave code:', error);
      alert('저장 중 오류가 발생했습니다.');
    }
  };

  return (
    <div className="bo-content">
      <h2>부코드 {sCode ? '수정' : '등록'}</h2>
      <div className="table-container bold-border">
        <table>
          <tbody>
            <tr>
              <th>주 코드번호</th>
              <td className="nr">{mCode}</td>
            </tr>
            <tr>
              <th>부 코드번호</th>
              <td className="nr">
                {sCode ? (
                  formData.s_code
                ) : (
                  <input
                    type="text"
                    value={formData.s_code}
                    onChange={(e) =>
                      setFormData({ ...formData, s_code: e.target.value })
                    }
                    maxLength={10}
                  />
                )}
              </td>
            </tr>
            <tr>
              <th>부 코드이름</th>
              <td className="nr">
                <input
                  type="text"
                  value={formData.s_name}
                  onChange={(e) =>
                    setFormData({ ...formData, s_name: e.target.value })
                  }
                  maxLength={50}
                />
              </td>
            </tr>
            <tr>
              <th>부 코드설명</th>
              <td className="nr">
                <input
                  type="text"
                  value={formData.s_desc || ''}
                  onChange={(e) =>
                    setFormData({ ...formData, s_desc: e.target.value })
                  }
                  maxLength={50}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="button-group">
        <button type="button" onClick={handleSubmit} className="btn-l">
          부코드 {sCode ? '수정' : '등록'}
        </button>
        <button
          type="button"
          onClick={() => navigate(`/bo/code/slvCodeList?m_code=${mCode}`)}
          className="btn-l"
        >
          부코드 목록
        </button>
      </div>
    </div>
  );
}

export default SlvCodeEdit;
