import React from 'react';


function Do39() {

  return (
    <div className="techinfoview">
				<h1>TMR의 영양적 구비요건 - 광물질과 비타민</h1>
				<h2>광물질</h2>
				<ul>
					<li>칼슘(%,건물기준) 
						<table className="tablestyle16">
							<tr>
								<td>비유초기</td>
								<td>0.80</td>
							</tr>
							<tr>
								<td>비유중기</td>
								<td>0.70</td>
							</tr>
							<tr>
								<td>비유말기</td>
								<td>0.60</td>
							</tr>
							<tr>
								<td>고지방사료</td>
								<td>0.90 ∼ 1.0</td>
							</tr>
						</table>
					</li>
					<li>인(%,건물기준) 
						<table className="tablestyle16">
							<tr>
								<td>비유초기</td>
								<td>0.50∼0.55</td>
							</tr>
							<tr>
								<td>비유중기</td>
								<td>0.40∼0.45</td>
							</tr>
							<tr>
								<td>비유말기</td>
								<td>0,35</td>
							</tr>
							<tr>
								<td>건 유 우</td>
								<td>0.26∼0.32</td>
							</tr>
						</table>
						<p>사료배합시 헤일리지의 인함량을 건물기준 0.30이상으로 계산하지 말아야 함<br/>육골분의 인함량은 공급자가 제공하는 수치를 사용할 것.</p>
					</li>
					<li> 마그네슘(%,건물기준) 
						<table className="tablestyle16">
							<tr>
								<td>비유초기</td>
								<td>0.25∼0.30</td>
							</tr>
							<tr>
								<td>비유중.말기</td>
								<td>0.20∼0.25</td>
							</tr>
							<tr>
								<td>고지방사료</td>
								<td>0.30∼0.35</td>
							</tr>
							<tr>
								<td>건유우</td>
								<td>0.16∼0.20 </td>
							</tr>
						</table>
					</li>
					<li>칼륨(%, 건물기준) 
						<table className="tablestyle16">
							<tr>
								<td>비유우</td>
								<td>1.0∼1.2</td>
							</tr>
							<tr>
								<td>건유우</td>
								<td>0.65∼0.80</td>
							</tr>
						</table>
						<p>옥수수 사일리지 위주의 조사료 급여</p>
					</li>
					<li>황(%,건물기준) 
						<table className="tablestyle16">
							<tr>
								<td>비유우</td>
								<td>0.20∼0,22</td>
							</tr>
							<tr>
								<td>건유우</td>
								<td>0.16∼0.18</td>
							</tr>
						</table>
					</li>
					<li>소금(g/두/일) 
						<table className="tablestyle16">
							<tr>
								<td>비유초기</td>
								<td>85∼115</td>
							</tr>
							<tr>
								<td>비유중.말기</td>
								<td>60∼85</td>
							</tr>
							<tr>
								<td>건유우</td>
								<td>15∼45</td>
							</tr>
						</table>
					</li>
					<li>미량광물질 
						<table className="tablestyle16">
							<tr>
								<td>철</td>
								<td>코발트</td>
								<td>구리</td>
								<td>망간</td>
								<td>아연</td>
								<td>요오드</td>
								<td>셀레늄</td>
							</tr>
							<tr>
								<td>50∼100ppm</td>
								<td>0.10∼0.12</td>
								<td>10∼12</td>
								<td>40∼50</td>
								<td>40∼60</td>
								<td>0.60</td>
								<td>0.30(6∼7mg)</td>
							</tr>
						</table>
					</li>
				</ul>
				<h2>비타민</h2>
				<ul>
					<li>비타민 A(IU/두/일) 
						<table className="tablestyle16">
							<tr>
								<td>비유우(초기)</td>
								<td>150,000</td>
							</tr>
							<tr>
								<td>건유우</td>
								<td>100,000</td>
							</tr>
						</table>
					</li>
					<li>비타민 D(IU/두/일)
						<table className="tablestyle16">
							<tr>
								<td>비유우(초기)</td>
								<td> 50,000(상한은75,000∼100.000)</td>
							</tr>
							<tr>
								<td>건유우</td>
								<td>25,000</td>
							</tr>
						</table>
					</li>
					<li>비타민 E(IU/두/일) 
						<table className="tablestyle16">
							<tr>
								<td>NRC기준 건유우 및 비유우</td>
								<td>Weiss등, 오클라호마대학 비유우</td>
								<td>건유우</td>
							</tr>
							<tr>
								<td>350∼400</td>
								<td>300∼500</td>
								<td>1,000</td>
							</tr>
						</table>
					</li>
				</ul>
			</div>
  );
}

export default Do39;
