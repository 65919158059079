import React from 'react';
import Progency from 'containers/web/progency/Progency';

function ProgencyPage() {
  return (
    <Progency />
  );
}

export default ProgencyPage;
