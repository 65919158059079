import React from 'react';

import pic708 from 'assets/images/sub/_images/pic708.jpg';
import pic709 from 'assets/images/sub/_images/pic709.jpg';
import pic710 from 'assets/images/sub/_images/pic710.jpg';

function Do145() {

  return (
    <div className="techinfoview">
      <h1>피부진균증</h1>
      <p className="pstyle">일명 곰팡이성 피부염 또는 소버짐, 윤선(輪癬)등 여러 가지로 불리운다</p>
      <div className="leftpart">
        <h2>원인</h2>
        <ul>
          <li>동물의 피부에 감염되는 곰팡이에는 여러종이 있지만 소에서는 보통 트리코피통(Trichophyton)속(屬)의 베르코즘(verrucosum)이라는 곰팡이가 원인이다.
            <ul>
              <li>이것은 소끼리의 접촉감염에 의하여 전염되고,</li>
              <li>오염된 축사의 벽이나 기둥에 의해 전염된다.
                <ul>
                  <li>습기 많은 장마철에 영양이 좋치 않은 노령우나 어린소에 많이 발생한다.</li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
        <h2>증상</h2>
        <ul>
          <li>감염곰팡이는 젖소의 털과 피부에 붙어 증식하고 점차 털구멍에 들어가 더욱 증식하여 조직을 파괴하여 탈모증과 가려움을 일으킨다.</li>
          <li>잘 생기는 부위는 안면 특히 눈의 주위, 목과 꼬리 부분이며 점차 전신으로 퍼진다.</li>
          <li>처음에는 작은 원형으로 탈모되어 점차확대되어 가며 마치 회색 석면(石綿)을 피부에 씌어 놓은 것 같다.</li>
          <li>이 곰팡이는 소 이외에 사람에도 감염되기 쉽고 이 경우 더욱 심한 증상을 일으키므로 감염우의 취급에 주위해야 한다.</li>
        </ul>
        <h2>목장에서의 처치</h2>
        <ul>
          <li>나타난 병소가 적을 때는 약물(시판되는 곰팡이 치료제:마이코피트, 데푼지트등)을 붓으로 매일 1회 이상 찍어 바른다.</li>
          <li>병소가 많거나 여러 마리일 때는 상기의 약제를 1주 3∼4회 정도 온몸에 뿌려준다.</li>
          <li>습진 형태가 증상이 심한 것은 수의사의 진료를 받을 필요가 있다.</li>
        </ul>
      </div>
      <div className="rightpart">
        <img src={pic708} className="nonborder" alt=""/>
        <img src={pic709} className="nonborder" alt=""/>
        <img src={pic710} className="nonborder" alt=""/>
      </div>
    </div>
  );
}

export default Do145;