import React from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useQuery, useQueryClient } from '@tanstack/react-query';

// api
import { getState, postState } from 'utils/api';

function DairyDicView() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const sjId = searchParams.get('sj_id');
  const queryClient = useQueryClient();

  const { data } = useQuery({
    queryKey: ['dairyDic', sjId],
    queryFn: () => getState('/board/select_dictionary', { sj_id: sjId }),
    enabled: !!sjId,
  });

  console.log(data);

  const dairyDic = data?.result;

  const handleDelete = async () => {
    if (window.confirm('삭제 하시겠습니까?')) {
      try {
        const response = await postState('/board/delete_dictionary/', {
          sj_id: sjId,
        });
        if (response?.return_code === 'SUCCESS') {
          queryClient.invalidateQueries(['dairyDics']);
          alert('삭제되었습니다.');
          navigate('/bo/board/dairyDic/list');
        }
        if (response?.return_code === 'ERROR') {
          alert(response?.return_message);
        }
      } catch (error) {
        console.error('Delete dairy dictionary error:', error);
        alert('삭제 중 오류가 발생했습니다.');
      }
    }
  };

  return (
    <div className="dairy-dic-view bo-content">
      <h2>낙농사전 정보</h2>
      <div className="table-container bold-border">
        <table>
          <tbody>
            <tr>
              <th width="15%">명칭</th>
              <td width="35%">{dairyDic?.sj_subject}</td>
              <th width="15%">분류</th>
              <td width="35%">{dairyDic?.sjc_name}</td>
            </tr>
            <tr>
              <th>내용</th>
              <td colSpan="3" style={{ whiteSpace: 'pre-wrap' }}>
                {dairyDic?.sj_content}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="button-group">
        <Link to={`/bo/board/dairyDic/edit?sj_id=${sjId}`} className="btn-l">
          {sjId ? '수정' : '등록'}
        </Link>
        <button type="button" onClick={handleDelete} className="btn-l">
          삭제
        </button>
        <button
          type="button"
          onClick={() => navigate('/bo/board/dairyDic/list')}
          className="btn-l"
        >
          목록
        </button>
      </div>
    </div>
  );
}

export default DairyDicView;
