import React from 'react';

function Fo53() {

  return (
    <div className="techinfo_view">
				<p className="viewTitle">산성중독증</p>
				<dl>
					<dt>발병대상</dt>
					<dd>소, 양, 염소</dd>
				</dl>
				
				<dl>
					<dt>원인</dt>
					<dd>코발트 결핍, 미국 Florida의 경우 코발트 결핍은 구리 결핍과 연관이 있다. </dd>
				</dl>
				
				<dl>
					<dt>징후 및 증상</dt>
					<dd>식욕상실, 쇠약, 식욕저하, 피부의 탈락, 피모의 불량, 무기력한 행동, 성장 저하 등</dd>
				</dl>
				
				<dl>
					<dt>질병분포와 손실규모</dt>
					<dd>코발트 결핍증은 넓은 지역에 걸쳐 분포되어 있으며, 이는 덴마크 질병, 풍토성 소모증(enzootic marasmus), 관목병, 쇄약증(Nakuritis) 그리고 영양실조증(pinning disease) 등의 여러가지 명칭으로 알려져 있다.</dd>
				</dl>
				
				<dl>
					<dt>치료</dt>
					<dd>소금 10kg당 1.2-3g 정도의 비율로 코발트염을 공급하거나 또는 적당량의 미량 광물질을 급여한다. 코발트염을 정맥주사하는 것은 큰 효과를 거두지 못하는데 그 이유는 코발트의 활성형인 비타민 B12를 만들어 내기 위해서는 반추위내 작용이 필요하기 때문이다. </dd>
				</dl>
				
				<dl>
					<dt>대책</dt>
					<dd>약 0.1mg/kg의 코발트를 함유한 사료를 급여한다. 사료중의 함량이 0.04-0.07m/kg의 범위거나 또는 그 이하로 되면 결핍증세를 나타낸다. </dd>
				</dl>
				
				<dl>
					<dt>예방</dt>
					<dd>소금이나 사용중인 미네랄 믹스 10kg당 1.2-3g의 비율로 염화 코발트나 황화 코발트 또는 탄산 코발트를 혼합하여 급여한다</dd>
				</dl>	
				
				<dl>
					<dt>참고</dt>
					<dd>코발트는 반추위내 미생물이 비타민 B12를 합성하는데 필요하다. 단위가축일 경우에는 외부로 부터 비타민 B12를 추가 급여하여야 한다. </dd>
				</dl>
			</div>
  );
}

export default Fo53;
