import React from 'react';

import Pic132 from 'assets/images/sub/_images/pic132.gif';
import Pic133 from 'assets/images/sub/_images/pic133.gif';
import Pic134 from 'assets/images/sub/_images/pic134.gif';
import Pic135 from 'assets/images/sub/_images/pic135.gif';
import Pic136 from 'assets/images/sub/_images/pic136.gif';
import Pic137 from 'assets/images/sub/_images/pic137.gif';
import Pic138 from 'assets/images/sub/_images/pic138.gif';
import Pic139 from 'assets/images/sub/_images/pic139.gif';
import Pic140 from 'assets/images/sub/_images/pic140.gif';
import Pic141 from 'assets/images/sub/_images/pic141.gif';

function Do15() {

  return (
    <div className="techinfoview">
				<h1>유용성에 대하여</h1>
				<p style={{lineHeight: '200%'}}>&nbsp;&nbsp;&nbsp;고능력우를 육종하고 싶으면 유용성에 초점을 맞추어야 할 것이다. 성은 다른 체형형질보 다 생산능력과 더욱 상관관계가 높다. 심사표준에는 20점이 반영되어 있다.<br/>&nbsp;&nbsp;&nbsp;산뜻하고, 예각적이며 개장성이 좋은 외모로 상징되지만, 최종점수의 유용성은 강건성과 말쑥함 및 조악하지 않은 외모도 같이 고려되어 진다. 이 형질을 평가할 때에는 분만후 얼 마 동안이나 착유가 진행되었는지를 잘 감안하여 관찰해야 할 것이다. 비유 말기가 가까워 지면 살붙임도 많아지게 된다. 그렇지만 유용성이 좋은 소라면 다음 산차 초기에 축적되었 던 체중이 우유로 변환되어 유용성 자질이 제대로 관찰될 수 있다.<br/>&nbsp;&nbsp;&nbsp;유용성은 단지 한가지 선형형질만 반영하지만, 심사표준에서 두 번째로 높은 비중으로 다 뤄지고 있다. 예각성이 높은 소는 목이 얇고 길며, 기갑이 날카롭고, 등뼈갈비, 요각 및 좌골 이 선명하며, 허벅지가 얇고 안쪽으로 만곡(彎曲, 활모양으로 굽음)되어 있어야 한다. 피부에 도 약간의 비중이 주어지는데, 낙낙하고 얇으며 두텁지 않아야 한다.</p><br/>
				<table className="tablestyle6">
					<tr>
						<td colSpan="5"><b>angularith 예각성</b></td>
					</tr>
					<tr>
						<td>&gt;45 극히 예각적임</td>
						<td>38~32 예각적임</td>
						<td>28~22 중간 정도의 예각성</td>
						<td>18~12 조악(粗惡)함</td>
						<td>&lt;5 극히 조악함</td>
					</tr>
					<tr>
						<td><img src={Pic132} alt="예각성 정도"/></td>
						<td><img src={Pic133} alt="예각성 정도"/></td>
						<td><img src={Pic134} alt="예각성 정도"/></td>
						<td><img src={Pic135} alt="예각성 정도"/></td>
						<td><img src={Pic136} alt="예각성 정도"/></td>
					</tr>
				</table>				
				<br/>
				<p>
					&nbsp;&nbsp;&nbsp;갈비의 개장도도 유용성을 판별하는 중요한 구성 요소이다. 갈비뼈 사이의 벌어진 정도와 갈비뼈가 뻗은 방향을 평가한다. 갈비뼈는 소의 뒤쪽 후방으로 뻗어야지 땅쪽으로 곧장 수 직으로 뻗어서는 안 된다는 것이다. 뼈 자체도 편평(扁平)해야지 둥글어서는 안 된다.<br/>&nbsp;&nbsp;&nbsp;유용성 평가에 있어서는 살붙임(BSC)은 평가하지 않는다. 갈비의 개장이나 예각성이 중 요한 평가요소이다. 세 번째 소를 보면 약간 여분의 살붙임이 관찰되나 유용성이 뛰어난 소 라면, 그 개체가 비유 후반기에 여분의 살붙임이 있더라도 이를 잘 간파할 수 있다.
				</p>
				<br/>
				<table className="tablestyle6">
					<tr>
						<td colSpan="5"><b>openness of rib 갈비의 개장도(開張度)</b></td>
					</tr>
					<tr>
						<td>&gt;45 극히 잘 벌어진 갈비</td>
						<td>38~32 잘 벌어진 갈비</td>
						<td>28~22 중간 정도</td>
					</tr>
					<tr>
						<td><img src={Pic137} alt="갈비의 개장도 정도"/></td>
						<td><img src={Pic138} alt="갈비의 개장도 정도"/></td>
						<td><img src={Pic139} alt="갈비의 개장도 정도"/></td>
					</tr>
					<tr>
						<td>28~22 꽉 짜인 갈비</td>
						<td>18~12 극히 꽉 짜인 갈비</td>
					</tr>
					<tr>
						<td><img src={Pic140} alt="갈비의 개장도 정도"/></td>
						<td><img src={Pic141} alt="갈비의 개장도 정도"/></td>
					</tr>
				</table>	
			</div>
  );
}

export default Do15;
