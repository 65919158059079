import React from 'react';
import Publication from 'containers/web/data/publication/Publication';
import { Helmet } from 'react-helmet-async';

function PublicationPage() {
  return (
    <>
      <Helmet>
        <title>젖소검정사업보고서</title>
      </Helmet>
      <Publication />
    </>
  );
}

export default PublicationPage;
