import React from 'react';
import { ROUTE_PAGE } from 'constants/constants';

// 컴포넌트
import GnbItem from './NavPcGnbItem';

// scss
import './NavPc.scss';

function NavPc() {
  const renderGnb = () =>
    Object.keys(ROUTE_PAGE)
      .filter((key) => key !== 'Guide')
      .map((key) => {
        const { title, child } = ROUTE_PAGE[key];
        return <GnbItem key={key} itemkey={key} title={title} child={child} />;
      });

  return (
    <nav className="navPc">
      <h2 className="hidden">네비게이션 영역</h2>
      <ul className="gnb">{renderGnb && renderGnb()}</ul>
    </nav>
  );
}

export default NavPc;
