import React from 'react';

import d2by57 from 'assets/images/sub/_images/2-57.jpg';

function Fo30() {

  return (
    <div className="techinfo_view">
				<p className="viewTitle">육성후기의 요점</p>
				<h2>방목의 주의(자가 방목)</h2>
				<ul className="listStyle04 mg15f">
					<li>방목의 일반적 특징은 고단백질, 고수분의 사료, 건량, 초질의 변화가 심하다. 청결한 환경 그리고 풍부한 운동량이다</li>
					<li>비유우가 방목주체의 경우 육성우도 방목주체로 해야함. 대형우는 적합하지 않음</li>
					<li>방목초를 유효하게 이용하기 위해서는 Strip grazing(대상방목)이 바람직하다</li>
					<li>방목은 3개월령부터 가능하지만 농후사료의 급여(1.5∼2.0kg/일)가 필요하다</li>
					<li>방목주체의 사료체계에서는 6개월령부터가 무난하다</li>
					<li>6∼12개월령으로 방목초의 사료가치가 부족(건물중 TDN65%이하)할 경우에는 농후사료의 급여가 바람직하다</li>
					<li>육성우의 방목초로서는 조단백질 16%정도가 바람직하고, 두과(豆科) 중심의 초지에서는 단백질과잉과 건물 섭취량의 부족이 되기 쉽다</li>
				</ul>
				<div className="imgBox mg60f"><img src={d2by57} alt=""/></div>
				
				<h2>6개월령부터의 사료급여</h2>
				<ul className="listStyle04 mg15f">
					<li>6개월령 목표체중은 170∼180kg, 1일 증체 700∼800g 이 목표</li>
					<li>양분함량의 목표는 TDN 66%, CP12%(NRC).
						<ul>
							<li>: 6개월령 이후의 NRC 단백질수준은 최저치라고 생각해야 하고 현장의 대응으로서는 115%정도의 설정이 무난</li>
						</ul>
					</li>
					<li>조사료의 품질에도 원인이 되지만 6개월령쯤부터 사료중의 조사료비율이 많아지게 된다</li>
					<li>옥수수 사일레지와 같은 고에너지 저단백질조사료를 급여할 경우는 농후사료를 감급(減給)할 수 있지만 단백질이 부족하지 않도록 농후사료의 선택이 필요하다</li>
				</ul>
				<div className="tableWrap">
					<table className="tablestyle">
						<caption>표. 조사료성분과 농후사료의 급여비율 및 CP% (6-12개월령) </caption>
						<thead>
							<tr>
								<th className="first">조사료<br/>DM중 TDN% CP%</th>
								<th>54%<br/>(조 : 배)<br/>(54 :46)</th>
								<th>56%<br/>조 : 배<br/>58 : 42</th>
								<th>58%<br/>조 : 배<br/>64 : 36</th>
								<th>60%<br/>조 : 배<br/>70 : 30</th>
								<th>62%<br/>조 : 배<br/>78 : 22</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td rowSpan="2">6 %</td>
								<td colSpan="5">농후사료의 CP%, ( )는 DM중% </td>
							</tr>
							<tr>
								<td>16.7 (19.0)</td>
								<td>17.9 (20.3)</td>
								<td>20.0 (22.7)</td>
								<td>22.9 (26.0)</td>
								<td>29.3 (33.3)</td>
							</tr>
							<tr>
								<td>8 %</td>
								<td>14.7 (16.7)</td>
								<td>15.4 (17.5)</td>
								<td>16.8 (19.1)</td>
								<td>18.7 (21.3)</td>
								<td>29.3 (33.3)</td>
							</tr>
							<tr>
								<td>10 %</td>
								<td>12.6 (14.3)</td>
								<td>13.0 (14.8)</td>
								<td>13.7 (15.6)</td>
								<td>14.7 (16.7)</td>
								<td>16.8 (19.1)</td>
							</tr>
							<tr>
								<td>12 %</td>
								<td>10.6 (12.0)</td>
								<td>10.6 (12.0)</td>
								<td>10.6 (12.0)</td>
								<td>10.6 (12.0)</td>
								<td>10.6 (12.0)</td>
							</tr>
							<tr>
								<td>14 %</td>
								<td>8.5 (9.7)</td>
								<td>8.1 (9.2)</td>
								<td>7.4 (8.4)</td>
								<td>6.4 (7.3)</td>
								<td>4.3 (4.9)</td>
							</tr>
						</tbody>
					</table>
				</div>
				<ul className="refer mg60f" style={{marginTop: '-20px'}}>
					<li>NRC추장양분(DM중) TDN66%, CP12% 농후사료성분 DM88% TDN80% (DM중)</li>
				</ul>
				
				<h2>볏짚, 구입조사료의 이용</h2>
				<ul className="listStyle04 mg15f">
					<li>6개월령쯤부터는 유해물질이 아니라 영양균형이 잡히도록 영양설계을 하는 한 대부분의 조사료는 이용할 수 있다</li>
					<li>사료가격이 낮은 볏짚등을 이용하는 경우에는 Beet pulp, 알팔파(Alfalfa)제품등과의 조합으로 저소화율, 저단백질, 저카로틴등의 결점을 보충하는 것이 중요하다</li>
					<li>기호성이 낮은 조사료의 이용방법으로서는 혼합사료(TMR)로서 급여하는 것이 바람직하다</li>
				</ul>
				<div className="tableWrap mg60f">
					<table className="tablestyle">
						<caption>표 볏짚을 이용한 설계예(8개월령)</caption>
						<thead>
							<tr>
								<th className="first" colSpan="4">성분 %</th>
								<th colSpan="4">급여량 (kg)</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td rowSpan="2">배합(18 - 70)</td>
								<td>DM</td>
								<td>CP</td>
								<td>TDN</td>
								<td>급여량</td>
								<td>DM</td>
								<td>CP</td>
								<td>TDN</td>
							</tr>
							<tr>
								<td>88</td>
								<td>20.0</td>
								<td>80</td>
								<td>2.5</td>
								<td>2.2</td>
								<td>0.440</td>
								<td>1.76</td>
							</tr>
							<tr>
								<td rowSpan="3">볏짚</td>
								<td>90</td>
								<td>17.0</td>
								<td>59</td>
								<td>0.4</td>
								<td>0.36</td>
								<td>0.061</td>
								<td>0.21</td>
							</tr>
							<tr>
								<td>87</td>
								<td>4.8</td>
								<td>44</td>
								<td>1.2</td>
								<td>1.04</td>
								<td>0.050</td>
								<td>0.46</td>
							</tr>
							<tr>
								<td>91</td>
								<td>9.0</td>
								<td>74</td>
								<td>2.0</td>
								<td>1.82</td>
								<td>0.164</td>
								<td>1.35</td>
							</tr>
							<tr>
								<td colSpan="5" rowSpan="2">합계<br/>합계 DM중 %</td>
								<td rowSpan="2">5.42</td>
								<td>0.715</td>
								<td>3.78</td>
							</tr>
							<tr>
								<td>13.2</td>
								<td>69.7</td>
							</tr>
							<tr>
								<td colSpan="5" rowSpan="2">필요량 NRC (체중 236kg 증체 0.7kg/일)<br/>필요량 DM중%</td>
								<td rowSpan="2">5.37</td>
								<td>0.678</td>
								<td>3.78</td>
							</tr>
							<tr>
								<td>12.6</td>
								<td>70.4</td>
							</tr>
						</tbody>
					</table>
				</div>
				
				<h2>12개월령이후의 사료급여</h2>
				<ul className="listStyle04 mg15f">
					<li>12개월령의 체중목표는 300∼320kg, 1일증체 700∼800kg이 목표이다</li>
					<li>추장양분량으로서는 TDN61%, CP12%로 이것에 적합한 조사료이면 농후사료는 필요없다</li>
					<li>번식성적불량의 원인으로서는 저양분이 많으므로 1일증체 700∼800g를 확보한다</li>
					<li>옥수수 사일레지와 같은 고에너지 저단백질 조사료를 급여할 경우, 에너지과잉, 단백질 부족이 생기기 때문에 농후사료의 선택이 중요하다</li>
				</ul>
				<div className="tableWrap">
					<table className="tablestyle">
						<caption>표. 조사료성분과 농후사료급여비율 및 CP% (12개월령 이후)</caption>
						<thead>
							<tr>
								<th className="first">조사료<br/>DM중 TDN% CP%</th>
								<th>54%<br/>(조 : 배)<br/>(73 : 27)</th>
								<th>56%<br/>조 : 배<br/>79 : 21</th>
								<th>58%<br/>조 : 배<br/>90 : 10</th>
								<th>60%<br/>조 : 배<br/>95 : 5</th>
								<th>62%<br/>조 : 배<br/>100 : -</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td rowSpan="2">6 %</td>
								<td colSpan="5">농후사료의 CP% ( )는 DM중%</td>
							</tr>
							<tr>
								<td>24.8 (28.2)</td>
								<td>30.4 (34.6)</td>
								<td>58.0 (66.0)</td>
								<td>110.9 (126.0)</td>
								<td>-</td>
							</tr>
							<tr>
								<td>8 %</td>
								<td>20.1 (22.8)</td>
								<td>23.8 (27.0)</td>
								<td>42.2 (48.0)</td>
								<td>77.4 (88.0)</td>
								<td>-</td>
							</tr>
							<tr>
								<td>10 %</td>
								<td>15.3 (17.4)</td>
								<td>17.2 (19.5)</td>
								<td>26.4 (30.0)</td>
								<td>44.0 (50.0)</td>
								<td>-</td>
							</tr>
							<tr>
								<td>12 %</td>
								<td>10.6 (12.0)</td>
								<td>10.6 (12.0)</td>
								<td>10.6 (12.0)</td>
								<td>10.6 (12.0)</td>
								<td>-</td>
							</tr>
							<tr>
								<td>14 %</td>
								<td>5.8 (6.6)</td>
								<td>4.0 (4.5)</td>
								<td>-</td>
								<td>-</td>
								<td>-</td>
							</tr>
						</tbody>
					</table>
				</div>
				<ul className="refer mg60f" style={{marginTop: '-20px'}}>
					<li>NRC추장양분(DM중) TDN61%, CP12% 농후사료성분 DM88% TDN80% (DM중)</li>
				</ul>
				
				<h2>살이 찐 정도(肥肉)는 좋은데 체고가 낮은 것은 ?</h2>
				<ul className="listStyle04 mg60f">
					<li>에너지의 수준은 적정하지만 단백질의 부족으로 골격의 형성이 억제되고 있다.(체고뿐만아니라 체장도 억제되고 있다.)</li>
					<li>옥수수 사일레지와 같은 고에너지 저단백질의 조사료와 저 단백 농후사료와의 조합(組合)으로 되기 쉽다</li>
					<li>3개월령부터의 농후사료는 조사료의 성분에 의해 결정해야 하는 것이다.(어떤조사료라도 대응할 수 있는 육성용, 약우용농후사료는 존재하지 않는다.)</li>
				</ul>
				
				<h2>육성우의 발육 수준과 분비량</h2>
				<ul className="listStyle04 mg15f">
					<li>발육 수준의 파악은 체중, 체고, 특히 체지방 축적 상태를 종합적으로 볼 필요가 있다.</li>
					<li>발육수준이 높으면 유방에 지방이 침착(비유량의 저하)한다고 하는 지적도 있지만, 고비유의 실례을 보는 한 그것은 상당히 높은 수준으로 일어난다고 생각된다. (단백질이 부족하지 않는 조건하에서는 토카치 농협연의 성장곡선 &lt;체중&gt;을 조금 상회한 수준이더라도 폐해(弊害)는 없을 것이다. 예를 들면 14개월령은 400kg)</li>
					<li>단백질이 부족하면 골격형성이 억제되어 체지방이 붙기 쉬워지기 때문에 적절한 단백질 확보가 중요하다</li>
					<li>육성기간중의 몸의 상태(Body condition)는 3.0으로 추이(推移)하여 분만전 2개월까지에는 3.5로 하는 것이 중요</li>
				</ul>
				<table className="tablestyle">
					<caption>표.초산비유기 305일 유량(FCM)</caption>
					<thead>
						<tr>
							<th className="first" rowSpan="2">15개월령 생체중</th>
							<th colSpan="3">체지방의 비율</th>
						</tr>
						<tr>
							<th>8%이하</th>
							<th>10 ∼ 20%</th>
							<th>22%이상</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>363kg</td>
							<td>5,357kg</td>
							<td>6,885kg</td>
							<td>5,675kg</td>
						</tr>
						<tr>
							<td>409kg</td>
							<td>6,038kg</td>
							<td>7,582kg</td>
							<td>6,356kg</td>
						</tr>
						<tr>
							<td>454kg</td>
							<td>6,583kg</td>
							<td>8,127kg</td>
							<td>6,901kg</td>
						</tr>
					</tbody>
				</table>
				<ul className="refer mg10t">
					<li>(1991. 2)</li>
				</ul>
			</div>
  );
}

export default Fo30;
