import React from 'react';

import Pic142 from 'assets/images/sub/_images/pic142.gif';
import Pic143 from 'assets/images/sub/_images/pic143.gif';
import Pic144 from 'assets/images/sub/_images/pic144.gif';

function Do17() {

  return (
    <div className="techinfoview">
				<h1>소의 생식(번식) 기관</h1>
				<h2>암소의 번식기관</h2>
				<ul>
					<li>난소
						<ul>
							<li>호르몬 생성 및 난자를 배출</li>
							<li>한쌍(2개)존재 - 신장뒤에 위치</li>
							<li>아몬드 형태</li>
							<li>직장검사 촉진시 딱딱하게 느껴짐</li>
							<li>표면의 불규칙성은 난포난 황체가 존재하기 때문</li>
						</ul>
						<p>※ 난포 : 난자가 존재하는 곳</p>
						<p>※ 황체 : 난자가 배란된 후 형성되는 것</p>
					</li>
					<li>난관
						<ul>
							<li>난자를 난소로부터 자궁각까지 유도 </li>
							<li>난자와 정자가 결합하여 수정하는 부위 (난관팽대부) </li>
							<li> 한쌍으로 꾸불꾸불한 관을 이루고 있음 </li>
							<li> 난소에 인접한 난관은 난관누두부라 함 (깔대기 모양) </li>
							<li>줄무늬 모양의 누두부 끝은 난관채라 함 </li>
							<li>난관채 배란시 난소를 감싸고 난자를 난관으로 이동시킴 </li>
							<li>소의 난관의 길이 - 25cm</li>
						</ul>
					</li>
					<li>자궁
						<p>소의 자궁은 자궁경, 자궁체, 자궁각으로 구성</p>
						<ul>
							<li>자궁각
								<ul>
									<li>자궁각의 형태 : 양분</li>
									<li>수정란이 자궁각으로 내려와 착상하는 곳</li>
									<li>태아가 성장하는 곳</li>
									<li>자궁각의 길이 : 35-40cm</li>
								</ul>
							</li>
							<li>자궁체
								<ul>
									<li>자궁각과 자궁경 사이에 위치</li>
									<li>자궁각의 미부가 간막인댕 의하여 부착되어 있음</li>
									<li>자궁체의 길이 : 2-4cm</li>
								</ul>
							</li>
							<li>자궁경
								<ul>
									<li>자궁경의 형태 : 2-5개 추벽 존재</li>
									<li>발정기에 약간 느선해져 정자의 통과가 용이</li>
									<li>점액의 분비로 음부로 배출</li>
									<li>자궁경의 길이 : 8-10cm</li>
									<li>자궁경의 직경 : 3-4cm</li>
								</ul>
								<img src={Pic142} style={{padding: '0', border: '0px', width:'500px'}} alt=""/><br/>
								<p><b>[암컷의 생식기관 모식도와 다른 해부학적 구조들과의 연결부위]</b></p>
							</li>
						</ul>
					</li>
					<li>질
						<ul>
							<li>자궁과 음부사이에 위치 </li>
							<li>골반내 위치한 산도의 일부 </li>
							<li>교미시 웅성생식기를 수용하는 곳 </li>
							<li>질의 길이 : 25 -30cm</li>
						</ul>
					</li>
					<li>음부
						<ul>
							<li>자성생식기의 외부 - 질로부터 외측에 뻗어 있음</li>
						</ul>
					</li>
					<li>혈관 및 신경분포
						<ul>
							<li>자궁 : 한쌍의 전자궁동맥, 중자궁동맥, 후자궁동맥에서 혈액 공급 </li>
							<li>난관 : 난소동맥으로부터 혈액 공급 </li>
							<li>전자궁동맥 : 난소에 혈액을 공급하는 난소동맥의 가지 
								<ul>
									<li>자궁각선단에 혈액을 공급</li>
								</ul>
							</li>
							<li>중자궁동맥 : 내장골동맥과 외장골동맥의 가지 
								<ul>
									<li>태아가 발달되고 있는 부위에 혈액을 공급 </li>
									<li>임신이 진행되면서 크게 확장 </li>
									<li>임신감정시 중자궁동맥을 촉진하여 진동음을 느낌</li>
								</ul>
							</li>
							<li>후자궁동맥 : 질동맥의 가지 
								<ul>
									<li>질, 음부, 항문 등에 혈액을 공급 </li>
								</ul>
							</li>
							<li>난소,난관.자궁의 신경 전달 : 자율 신경 </li>
							<li>질, 음부 - 음부신경(지각섬유와 부교감섬유)</li>
						</ul>
					</li>
				</ul>
				<h2>수소의 번식기관</h2>
				<ul>
					<li>정소
						<ul>
							<li>백막이라는 섬유상막으로 싸여 있는 곡세정관으로 구성 </li>
							<li>정자세포가 생성되는 곳 </li>
							<li>웅성호르몬인 Testosterone이 분비되는 곳</li>
						</ul>
					</li>
					<li>정소상체
						<ul>
							<li>정관과 정소(수출관)을 연결하는 긴 곡세관 </li>
							<li>정자가 사출되기 전 성숙하는 정소 </li>
							<li> 정소상체는 두부, 체부, 미부로 구성 
								<ul>
									<li>두부 : 정소와 연결되 부위 </li>
									<li>체부 : 정소의 종축과 평행하게 부착 </li>
									<li>미부 : 정관에 연결 (체부 거꾸로 올라감)</li>
								</ul>
							</li>
						</ul>
					</li>
					<li>음낭
						<ul>
							<li>정소가 들어있는 피부주머니 </li>
							<li>음낭 피부는 얇고 유연하며 털이 없음 </li>
							<li>피부 안쪽 - 육양막과 근섬유 존재 : 온도에 따라 수축 적용 </li>
							<li>육양막 - 정소종격을 형성 : 2개의 강 (주머니)을 형성</li>
						</ul>
					</li>
					<li>부생식선					
						<p>정관팽대부, 정낭선, 전립선, 뇨도구선, 카우퍼씨선으로 구성되어 있고 사출시 정장을 분비 </p>
						<p>※ 정장 - 정자의 이동, 영양소 공급</p>
						<p>- 암소 생식기 내의 과도한 산성의 완충제 역할</p>
						<ul>
							<li>정관팽대부
								<ul>
									<li>정관 끝의 확장된 선단부</li>
									<li>정액의 액체 부분을 분비</li>
								</ul>
							</li>
							<li>정낭선
								<ul>
									<li>방광 입구 부분의 골반요도에 사출기를 통해서 정관에 액체 분비</li>
								</ul>
							</li>
							<li>전립선
								<ul>
									<li>골반 요도를 둘러싸고 있음</li>
									<li>정액의 특유한 냄새를 내는 알칼리성 액체를 분비</li>
								</ul>
							</li>
							<li>뇨도구선(카우퍼씨선)
								<ul>
									<li>골반 요도의 양면에 하나씩 위치</li>
									<li>사정시 분비하는 교양물질을 생산</li>
								</ul>
							</li>
						</ul>
					</li>
					<li>음경
						<ul>
							<li>수컷의 교미기관 </li>
							<li>음경은 선부, 체부, 기부로 구성 </li>
							<li>기부 : 골반의 좌골궁에 부착 </li>
							<li>음경의 내부구조는 해면체로 되어있음 </li>
							<li>교미시 음경의 발기는 혈액이 음경내 충혈되기 때문 </li>
							<li>소의 경우는 S형 만곡부의 음경임 </li>
							<li>표피 : 음경을 감싸고 있는 주름진 피부</li>
						</ul>
					</li>
					<li>혈관 및 신경분포
						<ul>
							<li>정소 : 신장동맥 뒤 대동맥의 분지인 정소동맥으로부터 혈액 공금 </li>
							<li>정소의 신경은 자율 신경 </li>
							<li>음경, 방관, 요도, 부생식선 : 외음부의 내측동맥이 혈액을 공급 </li>
							<li> 음경의 배측신경은 외음부신경</li>
							<li> 귀두부의 감각섬유는 발기와 사정의 반사를 전달 </li>
							<li>반사중추는 요추부위의 척수에 존재<br/>
								<img src={Pic143} style={{width: '30%'}} alt=""/>
								<img src={Pic144} style={{width: '30%', paddingTop: '5%', paddingBottom: '5%'}} alt=""/><br/>
								<p style={{width:'30%', float: 'left', textAlign: 'center'}}>수소의 생식기</p>
								<p style={{width: '35%', float: 'left', textAlign: 'center'}}>수소의 정소와 정소상체</p>
							</li>
						</ul>
					</li>
				</ul>
			</div>
  );
}

export default Do17;
