import React from 'react';

import pic754 from 'assets/images/sub/_images/pic754.jpg';

function Do161() {

  return (
    <div className="techinfoview">
      <h1>청산중독</h1>
      <div className="leftpart">
      <p className="pstyle">청산중독은 혈액이 함유하는 산소를 조직으로 운반하는데 작용하는 효소의 역할을 억제하기 때문에 발생하므로 체내 조직에 공급되는 산소의 양이 적어져서 저산소증(低酸素症)을 일으켜 폐사하게 된다. </p>
        <h2>원인</h2>
        <ul>
          <li>청산을 다량 먹거나 흡인했을 때 </li>
          <li>청산을 생성하는 유독식물을 다량 먹었을때(이런 유독 식물은 청산생성배당체를 함유하고 있는데. 소화과정에서 효소에 의해 가수분해 되어 청산을 생성하여 중독을 일으킨다.) </li>
          <li> 질산염을 과다하게 시비한 초지에서 자란 목초, 풀이 시들었을 때, 짓밟혔을 때 또는 병에 걸렸을때는 유독성 배당체의 양이 증가하며 풀이 무성하게 자라는 계절에도 식물체내에 그양이 증가한다.</li>
        </ul>
        <h2>증상</h2>
        <ul>
          <li> 단시간내에 많은 양을 섭취했을때는 갑자기 심한 전신 경련을 일으키며 수분내에 폐사한다. </li>
          <li>환축은 갑자기 침을 흘리며 입에 거품을 물고 호흡이 촉박해져 5∼15분상이에 심한 호흡곤란을 보이며 입을 벌리고 가쁜 숨을 쉰다. </li>
          <li>폐사 직전에는 목과 등의 선이 활모양으로 뒤로 휘어지고 발버둥치며 대개 큰 소리로 포효(울부짖음)한다. </li>
          <li>혈액은 선홍색(鮮紅色)이며 가시(可視)점막은 청색을 띤다. </li>
        </ul>
        <h2>목장에서의 처치</h2>
        <ul>
          <li>전분질이 많은 사료 (옥수수 등)를 동시급여하면 청산중독이 경감될 수 있다. </li>
          <li>20% 아질산소다약 10ml, 20% 차아 황산소다액 40∼50ml를 정맥주사를 한다. </li>
          <li>경과가 신속하므로 수의사를 불러 치료할 사이도 없이 폐사하는 경우가 많다. </li>
        </ul>
      </div>
      <div className="rightpart">
        <img src={pic754} className="nonborder" alt=""/>
      </div>
    </div>
  );
}

export default Do161;