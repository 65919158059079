import React from 'react';

import Pic514 from 'assets/images/sub/_images/pic514.jpg';
import Pic515 from 'assets/images/sub/_images/pic515.jpg';
import Pic516 from 'assets/images/sub/_images/pic516.jpg';
import Pic517 from 'assets/images/sub/_images/pic517.jpg';
import Pic518 from 'assets/images/sub/_images/pic518.jpg';

function Do91() {

  return (
    <div className="techinfoview">
				<h1>식도경색</h1>
				<div className="leftpart">
					<h2>원인</h2>
					<ul>
						<li>젖소와 같이 되새김질을 하는 가축은 사료를 잘게 씹지 않고 삼키는 습성이 있으므로 근채류(감자, 고구마, 무우 등)나 호박등을 잘게 썰어서 주지 않았을 때 그 덩어리가 식도에 막혀 식도경색을 일으킨다.  </li>
					</ul>
					<h2>증상</h2>
					<ul>
						<li>갑자기 채식을 중지하고 목을 빼고 기침을 하기 시작한다. </li>
						<li>침을 삼킬 수 없으므로 계속 흘리고 있으며 고통스러워 한다. </li>
						<li>1위내의 가스가 배출되지 않기 때문에 고창증을 유발한다. </li>
						<li>마신물이나 음식은 코로 역류되며 이물성 폐염이 될 수 있다. </li>
					</ul>
					<h2>목장에서의 처치</h2>
					<ul>
						<li>급성 고창증이 병발되어 호흡곤란을 보일때는 우선 1위내에 투관침을 찔러 가스를 배출시킨다.</li>
						<li>식도에 이물이 막혀 있는 부위를 찾아낸다. </li>
						<li>개구기를 사용하거나 혀를 붙잡아 입을 벌리 식도를 막고 있는 이물을 끄집어 내든지 그림과 같이 손이나 추진기를 사용하여 1위쪽으로 밀어 넣는다. </li>
						<li>처치를 한 다음 물을 먹여 보아 물을 토해내지 않으면 치료된 것으로 판정한다. </li>
						<li>이물을 꺼낼 수도 밀어 넣을 수도 없을 때에는 수의사를 불러 식도절개 수술을 받아야 한다. </li>
						<li>평소에 근채류등의 사료는 잘게 썰어 주는 습관을 갖도록 한다.  </li>
					</ul>
				</div>
				<div className="rightpart">
					<img src={Pic514} className="nonborder" alt=""/>
					<img src={Pic515} className="nonborder" alt=""/>
					<img src={Pic516} className="nonborder" alt=""/>
					<img src={Pic517} className="nonborder" alt=""/>
					<img src={Pic518} className="nonborder" alt=""/>
				</div>
			</div>
  );
}

export default Do91;