import React from 'react';
import YouTube from 'react-youtube';

// scss
import './promotionalVideoDetail.scss';

function PromotionalVideoDetail({
  detailVideoInfo,
  setDetailVideoView,
  setDetailVideoInfo
}) {

  return (
    <div className="promotionalVideoDetail">
      <div className='promotionalVideoDetailInner'>
        <div className='promotionalVideoDetailHead'>
          <h5>{detailVideoInfo.bdc_title}</h5>
          <div className='promotionalVideoDetailHeadInfo'>
            <ul>
              <li>관리자</li>
              <li>{detailVideoInfo.bdc_rdt.split('T')[0]}</li>
            </ul>
            <span>조회수 {detailVideoInfo.bdc_hit}</span>
          </div>
        </div>
        <div className='promotionalVideoDetailBody'>
          <YouTube 
            videoId={detailVideoInfo.thumb_url}
            opts={{
              width: '100%',
              height: '100%',
              playerVars: {
                autoplay: 1,
                rel: 0,
                modestbranding: 1,
              },
            }}
            onEnd={(e)=> e.target.stopVideo(0)}
          />
        </div>
        <ul className='promotionalVideoDetailFoot'>
          {/* <li>
            <span>다음글</span>
            {
              next >= 0 &&
              <button 
                type='button'
                onClick={()=> setDetailVideoInfo(videoInfos[next])}
              >{next >= 0 && videoInfos[next].title}</button>
            }
          </li>
          <li>
            <span>이전글</span>
            {
              prev < videoInfos.length &&
              <button 
                type='button'
                onClick={()=> setDetailVideoInfo(videoInfos[prev])}
              >{ videoInfos[prev].title}</button>
            }
          </li> */}
        </ul>
      </div>
      <div className='viewBtn'>
        <button type='button' onClick={()=> setDetailVideoView(false)}>목록</button>
      </div>
    </div>
  );
}

export default PromotionalVideoDetail;
