import React from 'react';

import pic702 from 'assets/images/sub/_images/pic702.jpg';
import pic703 from 'assets/images/sub/_images/pic703.jpg';

function Do142() {

  return (
    <div className="techinfoview">
      <h1>슬류</h1>
      <p className="pstyle">슬류는 말하자면 소의 앞 무릎에 생기는 일종의 혹이다.  </p>
      <div className="leftpart">
        <h2>원인</h2>
        <ul>
          <li>소의 습관성 앞무릎을 구부려 전 체중을 받치며 일어서기 때문에 앞무릎은 바닥에 압박을 받을 기회가 많은 곳이다.</li>
        </ul>
        <h2>증상</h2>
        <ul>
          <li>앞 무릎이 파동감(波動感)이 나타나기 시작하여 점차로 커지며, 그 크기가 사람의 머리만한 것도 있다. </li>
          <li>만져보면 피부는 단단하게 느껴지고 속에는 물이 차 있는 것과 같다. </li>
          <li>발병 초기에는 통증도 없고 평상시 대로 걷는다. </li>
        </ul>
        <h2>목장에서의 처치</h2>
        <ul>
          <li>그다지 신통한 방법은 없으며 앞 무릎을 두꺼운 천으로 감아두는 것이 좋다. </li>
          <li>바닥에 가마니나 고무 깔판을 깔아주어 증상이 악화 되지 않도록 해준다. </li>
          <li>증상이 심하지 않을 때는 방치해 두어도 괜찮다.</li>
          <li>세균에 감염되어 관절염을 병발하게 되면 외과수술이 불가피하다.</li>
        </ul>
      </div>
      <div className="rightpart">
        <img src={pic702} className="nonborder" alt=""/>
        <img src={pic703} className="nonborder" alt=""/>
      </div>
    </div>
  );
}

export default Do142;