import React from 'react';
import { Link } from 'react-router-dom';
import defaultCow from 'assets/images/containers/main/defaultCow.png';
import youcow from 'assets/images/containers/main/youCow.png';
import korea from 'assets/images/containers/main/korea.png';
import america from 'assets/images/containers/main/america.png';
import canada from 'assets/images/containers/main/canada.png';
import './cowInfocomponent.scss';

const CowInfoComponent = ({ cow }) => {
  // 이름에서 영어 알파벳, 숫자, 대시, 괄호를 제거합니다.
  const cowname = `${cow.s_code} ${cow.s_sortname}`;
  const cowFulName = `${cow.s_fullname_e} ${cow.s_fullname_k}`;
  const nameWithoutNumbers = cowname.replace(/[a-zA-Z\d\-() ]+/g, '').trim();

  // 국가에 따른 국기 이미지를 결정하는 함수
  const getFlagImage = (country) => {
    switch (country) {
      case 'K':
        return korea;
      case 'A':
        return america;
      case 'C':
        return canada;
      default:
        return null; // 기본값은 null 또는 기본 이미지를 설정할 수 있습니다.
    }
  };

  const flagImage = getFlagImage(cow.s_origin);

  return (
    <Link to="/Web/Ox/OxSearch" className="link-to-search">
      <div className="cowInfoCon">
        <div className="cow-info">
          <div className="cow-header">
            <h2 className="cow-name">{cowname}</h2>
            {flagImage && (
              <img
                src={flagImage}
                alt={`${cow.s_origin} flag`}
                className="country-flag"
              />
            )}
          </div>

          <div className="info">
            <p>
              <strong>등록번호:</strong> {cow.s_reg}
            </p>
            <p>
              <strong>종합지수:</strong> {cow.s_tpi}
            </p>
            <p>
              <strong>중점개량형질:</strong> {cow.s_spec}
            </p>
            <p>
              <strong>평가시기:</strong> {cow.s_estimate}
            </p>
            <p>
              <strong>명칭:</strong>
              <span className="cowFullName"> {cowFulName}</span>
            </p>
          </div>
          <div className="cowImgBox">
            <div className="cow-images">
              <div className="image-container">
                <img
                  src={
                    cow.s_sajin_url
                      ? `${process.env.REACT_APP_STATICS_URL}/_ox/${cow.s_sajin_url}`
                      : `${youcow}`
                  }
                  alt={`${cowname} 1`}
                />
                <p className="image-text">{nameWithoutNumbers}</p>{' '}
                {/* 수정된 텍스트 */}
              </div>
              <div className="image-container">
                <img
                  src={
                    cow.s_dt_sajin_url
                      ? `${process.env.REACT_APP_STATICS_URL}/_ox/${cow.s_dt_sajin_url}`
                      : `${defaultCow}`
                  }
                  className="defaultCow"
                  alt={`${cow.name} 2`}
                />
                <p className="image-text">{`${nameWithoutNumbers} 딸소`}</p>{' '}
                {/* 수정된 텍스트 */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default CowInfoComponent;
