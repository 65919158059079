import React from 'react';


import Pic76 from 'assets/images/sub/_images/pic76.gif';
import Pic77 from 'assets/images/sub/_images/pic77.gif';
import Pic78 from 'assets/images/sub/_images/pic78.gif';
import Pic79 from 'assets/images/sub/_images/pic79.gif';
import Pic80 from 'assets/images/sub/_images/pic80.gif';
import Pic81 from 'assets/images/sub/_images/pic81.gif';
import Pic82 from 'assets/images/sub/_images/pic82.gif';
import Pic83 from 'assets/images/sub/_images/pic83.gif';
import Pic84 from 'assets/images/sub/_images/pic84.gif';
import Pic85 from 'assets/images/sub/_images/pic85.gif';
import Pic86 from 'assets/images/sub/_images/pic86.gif';
import Pic87 from 'assets/images/sub/_images/pic87.gif';
import Pic88 from 'assets/images/sub/_images/pic88.gif';
import Pic89 from 'assets/images/sub/_images/pic89.gif';
import Pic90 from 'assets/images/sub/_images/pic90.gif';
import Pic91 from 'assets/images/sub/_images/pic91.gif';
import Pic92 from 'assets/images/sub/_images/pic92.gif';
import Pic93 from 'assets/images/sub/_images/pic93.gif';
import Pic94 from 'assets/images/sub/_images/pic94.gif';
import Pic95 from 'assets/images/sub/_images/pic95.gif';
import Pic96 from 'assets/images/sub/_images/pic96.gif';
import Pic97 from 'assets/images/sub/_images/pic97.gif';
import Pic98 from 'assets/images/sub/_images/pic98.gif';
import Pic99 from 'assets/images/sub/_images/pic99.gif';
import Pic100 from 'assets/images/sub/_images/pic100.gif';
import Pic101 from 'assets/images/sub/_images/pic101.gif';
import Pic102 from 'assets/images/sub/_images/pic102.gif';
import Pic103 from 'assets/images/sub/_images/pic103.gif';
import Pic104 from 'assets/images/sub/_images/pic104.gif';
import Pic105 from 'assets/images/sub/_images/pic105.gif';
import Pic106 from 'assets/images/sub/_images/pic106.gif';
import Pic107 from 'assets/images/sub/_images/pic107.gif';
import Pic108 from 'assets/images/sub/_images/pic108.gif';
import Pic109 from 'assets/images/sub/_images/pic109.gif';
import Pic110 from 'assets/images/sub/_images/pic110.gif';




function Do12() {

  return (
    <div className="techinfoview">
    <h1>유방에 대하여</h1>
    <p style={{lineHeight: '200%'}}>&nbsp;&nbsp;&nbsp;젖소를 상징하는 것이 무엇이냐 하면, 바로 유방일 것?. 장수성, 생산성 및 수익성에 끼 치는 영향을 생각하면, 심사표준에서 유방에 가장 큰 비중을 두는 것은 당연하다. 100점중에 40점이란 점수는, 유방형질이 품평회장이나, 계획교배시나, 선형심사를 받는 당일 가장 주목 을 받을 만한 가치가 있는 것이다. <br/>&nbsp;&nbsp;&nbsp;유방부문은 7가지 형질로 구성되어 있으며, 여기에 모두 그림으로 제시되어 있다. <br/>&nbsp;&nbsp;&nbsp;여덟 번째 유방 형질인 유방바닥의 수평도는 생산성에 어떠한 영향을 주는지 연구대사응 로 자료가 수집되고 있으니, 앞으로 정식 심사형질로 채택될 수도 있겠다. 유방형질중에서도 가장 중요한 것은 유방의 깊이이다. 그림에서 보는 바와 같이 유방의 깊이는 비절단과 비교 하여 평가한다. 유방바닥(젖꼭지 뿌리부분)이 비절단과 일치할 경우에 15점이 된다. -1인치 (2.54Cm)의거리에 따라 5점이 가감될 수 있다. 예를 들면 비절단 위로 1인치 올라가면 20점 이 배점된다. 유방깊이를 배점할때는 유방의 가장 낮은 유구와 비절단을 비교하여야 한다. 예를 들면 유방깊이의 4번째 그림을 보면 앞유방이 뒷유방보다 낮을 때에는 앞유방의 가장 낮은 부분과 비절단을 비교하여 평가하여야 한다. </p> <br/>
    <table className="tablestyle6">
        <tr>
            <td colSpan="5"><b>udder depth 유방의 깊이</b></td>
        </tr>
        <tr>
            <td>&gt;45 극히 얕음</td>
            <td>38~32 비절단 훨씬 위</td>
            <td>28~22 비절단 약간 위</td>
            <td>18~12 비절단과 일치</td>
            <td>&lt;5 비절단 아래로 극히 깊음</td>
        </tr>
        <tr>
            <td><img src={Pic76} alt="유방의 깊이 정도"/></td>
            <td><img src={Pic77} alt="유방의 깊이 정도"/></td>
            <td><img src={Pic78} alt="유방의 깊이 정도"/></td>
            <td><img src={Pic79} alt="유방의 깊이 정도"/></td>
            <td><img src={Pic80} alt="유방의 깊이 정도"/></td>
        </tr>
    </table>
     <br/>
    <p>
        정중제인대는 유방을 앞뒤로 가르고 있는 중앙의 현수인대로, 이를 평가할 때에는 뒷유방 바닥에서 뒷유구의 갈라진 골이 아래위로 얼마나 깊은가를 관찰하여 심사한다. 골의 깊이가 3.2Cm일 때 25점이고, 0.8Cm마다 5점씩 가감할 수 있다. 
    </p>
     <br/>
    <table className="tablestyle6">
        <tr>
            <td colSpan="5"><b>udder cleft 정중제인대</b></td>
        </tr>
        <tr>
            <td>&gt;45 극히 강한 인대</td>
            <td>38~32 강한 인대</td>
            <td>28~22 중간정도</td>
            <td>18~12 약한 인대</td>
            <td>&lt;5 극히 약한 인대</td>
        </tr>
        <tr>
            <td><img src={Pic81} alt="정중제인대 정도"/></td>
            <td><img src={Pic82} alt="정중제인대 정도"/></td>
            <td><img src={Pic83} alt="정중제인대 정도"/></td>
            <td><img src={Pic84} alt="정중제인대 정도"/></td>
            <td><img src={Pic85} alt="정중제인대 정도"/></td>
        </tr>
    </table>
     <br/>
    <p>
        앞유두 부착을 평가할 때는 뒷유두는 평가에서 제외된다. 중앙에 부착되어 있을 경우가 25점이지만, 30∼35점이 이상적이라 할 수 있는데, 이는 산차가 거듭될수록 바깥쪽으로 벌어 지기 때문이다.
    </p>
     <br/>
     <br/>
    <table className="tablestyle6">
        <tr>
            <td colSpan="5"><b>front teat placement 앞유두 부착</b></td>
        </tr>
        <tr>
            <td>&gt;45 유구 중앙보다 훨씬 안쪽 부착</td>
            <td>38~32 안쪽 부착</td>
            <td>28~22 중앙 부착</td>
            <td>18~12 중앙보다 바깥쪽 부착</td>
            <td>&lt;5 유구의 극히 바깥쪽 부착</td>
        </tr>
        <tr>
            <td><img src={Pic86} alt="앞유두 부착 정도"/></td>
            <td><img src={Pic87} alt="앞유두 부착 정도"/></td>
            <td><img src={Pic88} alt="앞유두 부착 정도"/></td>
            <td><img src={Pic89} alt="앞유두 부착 정도"/></td>
            <td><img src={Pic90} alt="앞유두 부착 정도"/></td>
        </tr>
    </table>
     <br/>
    <p>
        뒷유방너비는 뒷유방의 가장 넓은 부분이 아니고, 뒷유방의 제일 높은 부분으로 유선조직 이 시작되는 부위의 너비를 평가하는 것이다. 혼동하기 쉬운 실례를 그림의 4번째 소의 뒷 유방 중앙 부분의 너비는 3번째 소보다 넓으나, 부착 부분의 너비는 반대인 것을 알 수 있 을 것이다. 그래서 4번째 소는 15점이 되는 것이다. 13Cm의 너비가 25점으로, 1인치(2.5Cm) 의 변동이 있을 때마다 10점을 가감할 수 있다.
    </p>
     <br/>
    <table className="tablestyle6">
        <tr>
            <td colSpan="5"><b>rear udder width 뒷유방 너비</b></td>
        </tr>
        <tr>
            <td>&gt;45 극히 넓음</td>
            <td>38~32 넓음</td>
            <td>28~22 중간정도의 너비</td>
            <td>18~12 좁음</td>
            <td>&lt;5 극히 좁음</td>
        </tr>
        <tr>
            <td><img src={Pic91} alt="뒷유방 너비 정도"/></td>
            <td><img src={Pic92} alt="뒷유방 너비 정도"/></td>
            <td><img src={Pic93} alt="뒷유방 너비 정도"/></td>
            <td><img src={Pic94} alt="뒷유방 너비 정도"/></td>
            <td><img src={Pic95} alt="뒷유방 너비 정도"/></td>
        </tr>
    </table>
     <br/>
    <p>
        뒷유방의 부착 높이는 공식적으로 뒷유방 유선조직의 제일 높은 부분부터 시작되는 것으 로, 홀스타인종에 있어서는 외음부 밑부분부터 유선조직 상부가지의 거리로 평가한다. 26.7Cm일 경우 25점이고, 매 3,6Cm마다 10점씩 가감할 수 있다. 다른 주요 젖소 품종에서 는 좌골단부터 비절단까지의 거리를 2등분하여 그 가운데를 25점으로 채점하기도 한다.
    </p>
     <br/>
     <br/>
    <table className="tablestyle6">
        <tr>
            <td colSpan="5"><b>rear udder height 뒷유방부착 높이</b></td>
        </tr>
        <tr>
            <td>&gt;45 극히 높음</td>
            <td>38~32 높음</td>
            <td>28~22 중간정도의 높이</td>
            <td>18~12 낮음</td>
            <td>&lt;5 극히 낮음</td>
        </tr>
        <tr>
            
            <td><img src={Pic96} alt="뒷유방 부착 높이 정도"/></td>
            <td><img src={Pic97} alt="뒷유방 부착 높이 정도"/></td>
            <td><img src={Pic98} alt="뒷유방 부착 높이 정도"/></td>
            <td><img src={Pic99} alt="뒷유방 부착 높이 정도"/></td>
            <td><img src={Pic100} alt="뒷유방 부착 높이 정도"/></td>
        </tr>
    </table>
     <br/>
    <p>
        심사표준에 의하면, 이상적인 앞유방부착은 강하면서도, 중간 정도의 길이이고, 앞쪽으로 부드럽게 배부분과 연결되는 것으로 되어 있다. 심사원이 채점을 할 때는 부착의 강도를 관찰한다. 앞유방의 길이와 모양은 생각하지 않는다. 이 형질은 다소 채점하기가 까다로운 것으로 실제 자로 잴 수 없다. 그러나 강하게 부착된 것과 약한 것은 분명한 차이가 있다.
    </p>
     <br/>
     <br/>
    <table className="tablestyle6">
        <tr>
            <td colSpan="5"><b>fore udder attachment 앞유방 부착</b></td>
        </tr>
        <tr>
            <td>&gt;45 극히 강하고 깔끔함</td>
            <td>38~32 강함</td>
            <td>28~22 중간 정도의 강한 부착</td>
            <td>18~12 약함</td>
            <td>&lt;5 극히 약하고 파손된 인대</td>
        </tr>
        <tr>
            <td><img src={Pic101} alt="앞유방 부착 정도"/></td>
            <td><img src={Pic102} alt="앞유방 부착 정도"/></td>
            <td><img src={Pic103} alt="앞유방 부착 정도"/></td>
            <td><img src={Pic104} alt="앞유방 부착 정도"/></td>
            <td><img src={Pic105} alt="앞유방 부착 정도"/></td>
        </tr>
    </table>
     <br/>
    <p>
        유두길이는 5.7cm가 이상적인(25점)이고, 매1.3cm마다 10점씩 가감할 수 있다. 젖꼭지마다 길이의 차이가 있다면 가장 긴 것을 기준으로 배점하면 된다.
    </p>
     <br/>
     <br/>
    <table className="tablestyle6">
        <tr>
            <td colSpan="5"><b>teat length 유두 길이</b></td>
        </tr>
        <tr>
            <td>&gt;45  8.3cm 혹은 그 이상 긴것</td>
            <td>38~32 7cm 정도</td>
            <td>28~22 5.7cm 정도</td>
            <td>18~12 4.4cm 정도</td>
            <td>&lt;5 3.2cm 정도</td>
        </tr>
        <tr>
            <td><img src={Pic106} alt="유두길이 정도"/></td>
            <td><img src={Pic107} alt="유두길이 정도"/></td>
            <td><img src={Pic108} alt="유두길이 정도"/></td>
            <td><img src={Pic109} alt="유두길이 정도"/></td>
            <td><img src={Pic110} alt="유두길이 정도"/></td>
        </tr>
    </table>
</div>
  );
}

export default Do12;
