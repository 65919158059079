import React from 'react';

import pic263 from 'assets/images/sub/_images/pic263.jpg';
import pic264 from 'assets/images/sub/_images/pic264.jpg';
import pic265 from 'assets/images/sub/_images/pic265.jpg';
import pic266 from 'assets/images/sub/_images/pic266.jpg';

function Do186() {

  return (
    <div className="techinfoview">
      <h1>리드 카나리그라스</h1>
      <p>영명 : Reed Cannarygrass <br/>학명 : Phalaris arundinacea L. </p>
      <div style={{width: '60%',float: 'left'}}>
        <h2>원산지</h2>
        <p className="pstyle">&nbsp;&nbsp;&nbsp;1749년에 스웨덴에서 목초로 재배되었으며, 1850년경 북유럽 일부지역에서 재배되었다. 카나리그라스라는 이름은 1년경 카나리그라스의 종자가 카나리새의 먹이가 되었다는 사실에서 유래되었다는 설과 이 초종의 원산지인 Canary섬의 이름을 따서 명명하였다는 설이 있다.</p>
        <h2>성상</h2>
        <p className="pstyle">&nbsp;&nbsp;&nbsp;일반적인 성질은 배수불량한 곳이나, 습지에서도 재배가 잘 된다. 초장이 크고 영년생의 북방형초종으로서 질이 거친 화본과 목초이다. 지하경이 벋어 떼를 이루며 방석모양의 생육을하여, 뿌리는 깊고 넓게 퍼지며 영양분의 흡수범위도 넓다. 잎은 엷은 녹색이고 주로 줄기의 밑부위에 있고, 초장은 약 60∼240cm정도이다. 종자의 색깔은 회색 내지는 회갈색으로 왁스 성분이 있으며, 수경 끝에 착생한다. 꽃은 밀집된 원추화서이다. </p>
        <h2>재배</h2>
        <p className="pstyle">&nbsp;&nbsp;&nbsp;화본과 목초로서 습지대에서 잘 자라는 초종이나, 종자의 발아율이 좋지 않은 것이 결점이다. 종자는 매끄러운 종피에 싸여 있으며, 저장 기간이 짧아 발아율이 좋지 않다. 다른 화본과 목초는 종자의 수명이 6∼7년 정도이나 리드카나리그라스는 3∼4년정도에 지나지 않지만 초지조성은 잘 된다. 초지조성할 때는 초지조성이 되기까지 2년 이상이 걸릴 때도 있다. 이런 이유 때문에 점질토양에는 부적당한 초종이라고 할 수 있다. 눈이 많이 내리지않는 조건하에서 강추위에 견디는 힘은 약하며 그늘에 약한 두과목초와 혼파를 하면 초장이 크기 때문에 두과목초의 생산량이 감소된다. 알카로이드 성분이 식물체내에 포함되어 있으므로 가축의 증체에 좋지 않는 효과가 있어 알카로이드 성분이 적은 새로운 품종을 육종중에 있다. 10a당 청초 수량은 3,000∼4,000kg이며, 품종으로는 후론티어(Frontier), 벤추어(Venture), 후래어(Flare) 등이 있다. </p>
        <h2>이용</h2>
        <ul>
          <li>건초
            <p>생산량이 특히 많지만, 다른 화본과 목초보다 단백질의 함량이 낮기 때문에 질은 그리 좋지않다. 그러나 질이 낮은 것은 일반적으로 수확적기보다 늦게 예취를 하고 너무 습한지대에서 수확하기 때문이므로 수확은 출수기 이전에 하는 것이 좋다. 수량은 많으나 다른 목초와 경합 때문에 두과와 혼파하는 것이 좋고 시비량을 늘려 주는 것이 좋으며, 특히 질소질 비료를 많이 사용하는 것이 좋다.</p>
          </li>
          <li>방목 및 청예
            <p>초봄 일찍부터 생육을 시작하며 수량이 많고 재생도 잘되지만 기호성이 낮은 것이 문제로서 초지를 분할하여 윤환방목을 시키면 다소 기호성이 낮아도 효과적으로 이용할 수가 있다. 질이 좋은 시기에 청예로 이용하려면 초장이 30cm 이상 자라기전에 수확하여야 한다. 그러나 계속된 과방목은 좋이 않으며 10cm 미만으로는 방목을 하지 않아야 한다.</p>
          </li>
        </ul>
      </div>
      <div style={{width: '40%', float: 'left'}}>
        <table>
          <tr>
            <td><img src={pic263} className="nonborder2" alt=""/></td>
          </tr>
          <tr>
            <td><img src={pic264} className="nonborder2" alt=""/></td>
          </tr>
          <tr>
            <td><p style={{textAlign: 'center'}}>유식물의 생육과정</p></td>
          </tr>
          <tr>
            <td><img src={pic265} className="nonborder2" alt=""/></td>
          </tr>
          <tr>
            <td><p style={{textAlign: 'center'}}>지하경의 모양</p></td>
          </tr>
          <tr>
            <td><img src={pic266} className="nonborder2" alt=""/></td>
          </tr>
          <tr>
            <td><p style={{textAlign: 'center'}}>이삭과 종자</p></td>
          </tr> 
        </table>
      </div>
    </div>
  );
}

export default Do186;