import React, { useState } from 'react';

// api
import { openCrownix } from 'utils/openCrownix';

// 이미지
import PrintPDF from 'assets/svgs/common/print_PDF.svg';
import Logo from 'assets/images/common/header_logo_pc.png';

// 컴포넌트
import ErrorMessage from 'components/errorMessage/errorMessage';
import FarmcongCode from 'components/dcitest/farmcongCode/FarmcongCode';

// scss
import './NewFarmSemen.scss';

const data = [

  {'정액코드' : '11HO08195',
   '정액명' : '벡스터',
   '영문명' : 'BAXTER',
   '등록번호' : '020132973942',
   '딸소 개체수' : 7
  },

  {'정액코드' : '11HO09647',
   '정액명' : '아이오타',
   '영문명' : 'IOTA',
   '등록번호' : '220361898306',
   '딸소 개체수' : 10
  },

  {'정액코드' : '11HO10387',
   '정액명' : '알타수퍼젯',
   '영문명' : 'ALTASUPERJET',
   '등록번호' : '020062295415',
   '딸소 개체수' : 4
  },

  {'정액코드' : '11HO08195',
   '정액명' : '벡스터',
   '영문명' : 'BAXTER',
   '등록번호' : '020132973942',
   '딸소 개체수' : 7
  },

  {'정액코드' : '11HO09647',
   '정액명' : '아이오타',
   '영문명' : 'IOTA',
   '등록번호' : '220361898306',
   '딸소 개체수' : 10
  },

  {'정액코드' : '11HO10387',
   '정액명' : '알타수퍼젯',
   '영문명' : 'ALTASUPERJET',
   '등록번호' : '020062295415',
   '딸소 개체수' : 4
  } 

];

function NewFarmSemen() {

  // 에러처리
  const [isModalOpen, setIsModalOpen] = useState(false); // 모달 추가
  const [errorMessage, setErrorMessage] = useState(''); 

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => {
    setIsModalOpen(false);
    setErrorMessage(''); // 모달이 닫힐 때 에러메세지 초기화
  };

  // 데이터 불러오기
  const [ props , setProps] = useState({
    id:`${localStorage.getItem('cong')}${localStorage.getItem('farm')}`,
    passwd:localStorage.getItem('passwd'),
    date:localStorage.getItem('month')
  });

  return (
    <div className='pdfMainArea'>
      <ErrorMessage isOpen={isModalOpen} content={errorMessage} closeModal={closeModal}/>
      <div className='pdfMainHeader'>
        <button 
          type='button'
          onClick={()=> openCrownix('New_farm_semen.mrd')}
        >
          <img src={PrintPDF} alt='PDF로 인쇄하기' />
          인쇄하기
        </button>
        {
          props && 
          <FarmcongCode 
            props={props}
            setProps={setProps}
            setErrorMessage={setErrorMessage}
            openModal={openModal}
          />
        }
      </div>
      <div className='pdfArea'>
        <div className='newFarmSemenPdf'>
          <h1>아비 정액 리스트</h1>           
          <ul className='newFarmSemenInfo'>           
            <li>
              목장명 : 남촌
            </li>   
            <li>
              축주명 : 양병철
            </li>           
          </ul>

          <table className='pdfTable'>
            <thead>
              <tr>
                <th>정액코드</th>
                <th>정액명</th>
                <th>영문명</th>
                <th>등록번호</th>
                <th>딸소 개체수</th>                              
              </tr>
            </thead>
            <tbody>
              {
                data &&
                data.map((item, index) => (
                  <tr key={index}>
                    <td>{item['정액코드']}</td>
                    <td>{item['정액명']}</td>
                    <td>{item['영문명']}</td>
                    <td>{item['등록번호']}</td>
                    <td>{item['딸소 개체수']}</td>                                     
                  </tr>
                ))
              }
            </tbody>
          </table>
          <img src={Logo} alt='농협경제지주 젖소개량사업소 로고' className='pdfLogo'/>
        </div>
      </div>
    </div>    
  );
}

export default NewFarmSemen;
