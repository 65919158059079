import React from 'react';
import useWindowSize from 'hooks/useWindowSize';

// 컴포넌트
import SubTitle from 'components/subTitle/SubTitle';

// 이미지
// import staff320Old from 'assets/images/containers/business/staff/staff_320.png';
// import staff768Old  from 'assets/images/containers/business/staff/staff_768.png';
// import staff1440Old from 'assets/images/containers/business/staff/staff_1440.png';
import staff320 from 'assets/images/containers/business/staff/staff-320.png';
import staff768 from 'assets/images/containers/business/staff/staff-768.png';
import staff1440 from 'assets/images/containers/business/staff/staff-1440.png';

// scss
import './staff.scss';

function Staff() {
  const windowSize = useWindowSize();

  return (
    <div className="staff subCon">
      <SubTitle />
      {windowSize.width >= 1440 && <img src={staff1440} alt="직원현황" />}
      {windowSize.width < 1440 && windowSize.width >= 768 && (
        <img src={staff768} alt="직원현황" />
      )}
      {windowSize.width < 768 && <img src={staff320} alt="직원현황" />}
      <div className="hidden">
        <p>젖소개량사업소 조직도</p>
        <ul>
          <li>
            <strong>소장</strong>: 최완용 Tel: 031-929-1001
          </li>
          <li>
            <strong>총괄부</strong>: 총괄 부장 이철구 (1004) 업무 총괄
            <ul>
              <li>
                <strong>경영관리팀</strong>
                <ul>
                  <li>팀장 이동수 (1010): 경영관리 업무 총괄</li>
                  <li>과장 손광희 (1011): 총무, 고정자산</li>
                  <li>과장대리 손민주 (1012): 회계, 출납, 관리</li>
                </ul>
              </li>
              <li>
                <strong>사양제조팀</strong>
                <ul>
                  <li>팀장 김태수 (1030): 사양제조 업무 총괄</li>
                  <li>계장 신지홍 (1032): 사양관리, 정액제조</li>
                  <li>계장 김태현 (1042): 정액제조 (반장)</li>
                  <li>계장 이관구 (1042): 정액제조</li>
                  <li>계장 성민호 (1042): 정액제조</li>
                  <li>계장 김삼원 (1042): 사양관리 (반장)</li>
                  <li>계장 백승윤 (1022): 사양관리</li>
                  <li>계장 유한길 (1022): 사양관리</li>
                </ul>
              </li>
              <li>
                <strong>육종검정팀</strong>
                <ul>
                  <li>팀장 신지섭 (1050): 육종검정 업무 총괄</li>
                  <li>연구역대우 이석현 (1071): 육종농가, 개량분석</li>
                  <li>계장 이준영 (1064): 능력검정사업 홍보·광고</li>
                  <li>계장 김수연 (1074): DB 관리, 전산</li>
                  <li>주임 유승식 (1052): 씨수소 인수, 수정란 이식</li>
                </ul>
              </li>
              <li>
                <strong>영양센터</strong>
                <ul>
                  <li>계장 송일섭: 사양관리 (반장)</li>
                  <li>계장 김종희: 사양관리</li>
                  <li>계장 이지훈: 사양관리</li>
                  <li>계장 백승경: 사양관리</li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Staff;
