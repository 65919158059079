import React from 'react';

import Pic480 from 'assets/images/sub/_images/pic481.jpg';
import Pic481 from 'assets/images/sub/_images/pic482.jpg';
import Pic482 from 'assets/images/sub/_images/pic483.jpg';

function Do80() {

  return (
    <div className="techinfoview">
				<h1>맹장 염전과 확장증</h1>
				<div className="leftpart">
					<h2>원인</h2>
					<ul>
						<li>본 병은 분만후 2∼3주일 이내에 많이 발생한다. 과잉급여된 농후사료로 맹장내 휘발성지방산의 농도가 높아져 맹장을 이완 시킴으로써 가스가 축적되어 확장, 전위, 염전을 유발시킨다.  이와 같은 맹장확장중이나 맹장염전 증례는 농후사료를 급한 소에서 다발하고 있다.  </li>
					</ul>
					<h2>증상</h2>
					<ul>
						<li>식욕감퇴와 가벼운 복통이 있고 유량이 줄며, 체온, 맥박, 호흡수에는 뚜렷한 변화가 없다. </li>
						<li>가스로 가득찬 맹장은 오른쪽 상겸부를 팽대시킨다. </li>
						<li>타진(打診)에 의한 청진으로 금속음을 들을 수 있다. </li>
						<li>탈수(脫水)증상이 나타난다.</li>
					</ul>
					<h2>목장에서의 처치</h2>
					<ul>
						<li>증상이 가벼운 경우에는 수일간사료를 줄이고(특히 농후사료), 염류사하제, 효소제를 투여하며 연동촉진제 등을 응용할 수 있다. </li>
						<li>증상이 심할때는 수의사에 의뢰하여 개복수술들의 외과적 처치를 받아야 한다.</li>
					</ul>
				</div>
				<div className="rightpart">
					<img src={Pic480} className="nonborder" alt=""/>
					<p>맹장과 결장 열전시 팽대된 우측복부</p>
					<img src={Pic481} className="nonborder" alt=""/>
					<p>맹장과 결장 염전시의 위치</p>
					<img src={Pic482} className="nonborder" alt=""/>
					<p>맹장과 결장의 확장시 금속성 음향 청취 부위</p>
				</div>
			</div>
  );
}

export default Do80;