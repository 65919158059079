import React from 'react';

import Pic550 from 'assets/images/sub/_images/pic550.jpg';
import Pic551 from 'assets/images/sub/_images/pic551.jpg';
import Pic552 from 'assets/images/sub/_images/pic552.jpg';
import Pic553 from 'assets/images/sub/_images/pic553.jpg';

function Do101() {

  return (
    <div className="techinfoview">
      <h1>기생성 위장염</h1>
      <p className="pstyle">소의 소화기관내에 선충류(특히:위충)에 속하는 기생충이 기생하여 위장 장애를 일으키는 병을 말하며 소화관내 기생하는 대부분의 선충이 제4위와 소장에 기생하여 위나 장의 점막에 염증을 일으키기 때문에 이러한 병멱을 붙였다. </p>
      <div className="leftpart">
        <h2>증상</h2>
        <ul>
          <li>감염초기에는 외부증상이 나타나지 않는다. 
            <ul>
              <li>발병시기는 7∼8월이후로 기생충이 다수감염한 경우에만 발병한다. </li>
              <li>대부분 설사가 계속되면서 쇠약해지고 빈혈을 일으킨다. </li>
              <li>설사증은 세균이나 바이러스감염에 기인한 것 및 콕시디움증 등과의 감별이 필요하다. </li>
              <li>빈혈증세로 방목소에 다발하는 파이로 플라즈마 병과 혼동되기 쉽다. </li>
              <li>증상이 심해지면 부종이 생기고 유량이 줄며 번식장애를 가져온다. </li>
            </ul>
          </li>
        </ul>
        <h2>목장에서의 처치</h2>
        <ul>
          <li> 현재로서는 정기적인 구충을 실시하여 오염원을 줄이는 것이 최선의 대책이다. </li>
          <li>이병과 유사증상을 일으키는 질병과의 감별은 분별을 채취하여 가까운 가축위생 시험소나 가축병원에 의뢰하여 충란검사를 해서 확인 할 수 있다. </li>
        </ul>
      </div>
      <div className="rightpart">
        <img src={Pic550} className="nonborder" alt=""/>
        <img src={Pic551} className="nonborder" alt=""/>
        <img src={Pic552} className="nonborder" alt=""/>
        <img src={Pic553} className="nonborder" alt=""/>
      </div>
    </div>
  );
}

export default Do101;