import React from 'react';
import TechnicalInfo from 'containers/web/info/technicalInfo/TechnicalInfo';
import { Helmet } from 'react-helmet-async';

function TechnicalInfoPage() {
  return (
    <>
      <Helmet>
        <title>기술정보</title>
      </Helmet>
      <TechnicalInfo />
    </>
  );
}

export default TechnicalInfoPage;
