import React from 'react';

// scss
import './webpage_list.scss';

// 이미지
import imgM1 from 'assets/images/containers/data/relatedSites/medicine/imgSite08_1.jpg';
import imgM2 from 'assets/images/containers/data/relatedSites/medicine/imgSite08_2.jpg';
import imgM4 from 'assets/images/containers/data/relatedSites/medicine/imgSite08_4.jpg';
// import imgNO from 'assets/images/containers/data/relatedSites/imgSite_no.gif';
import imgA4 from 'assets/images/containers/data/relatedSites/artificial/imgSite01_4.jpg';

function Medicine() {
  return (
    <div className="Medicine">
      <div className="webpage">
        <dl className="webpage_list">
          <dd>
            <a
              href="https://www.cj.co.kr/kr/aboutus/business/cjfeedncare"
              target="_blank"
              rel="noreferrer"
            >
              <div className="webpage_banner">
                <img src={imgA4} alt="제일제당 사료사업부" />
              </div>
              <p>제일제당 사료사업부</p>
            </a>
          </dd>
          <dd>
            <a
              href="https://www.samyang.co.kr/kr/index"
              target="_blank"
              rel="noreferrer"
            >
              <div className="webpage_banner">
                <img src={imgM1} alt="삼양사료" />
              </div>
              <p>삼양사료</p>
            </a>
          </dd>
          <dd>
            <a
              href="http://www.kicfeed.com/korea.asp"
              target="_blank"
              rel="noreferrer"
            >
              <div className="webpage_banner">
                <img src={imgM2} alt="해표사료" />
              </div>
              <p>해표사료</p>
            </a>
          </dd>
          {/* <dd>
            <div className="webpage_banner">
              <img src={imgNO} alt="삼지약품" />
            </div>
            <p>삼지약품</p>
          </dd> */}
          <dd>
            <a
              href="https://www.purinapetcare.co.kr/"
              target="_blank"
              rel="noreferrer"
            >
              <div className="webpage_banner">
                <img src={imgM4} alt="퓨리나 코리아" />
              </div>
              <p>퓨리나 코리아</p>
            </a>
          </dd>
        </dl>
      </div>
    </div>
  );
}

export default Medicine;
