import React from 'react';

import pic605 from 'assets/images/sub/_images/pic605.jpg';
import pic606 from 'assets/images/sub/_images/pic606.jpg';
import pic607 from 'assets/images/sub/_images/pic607.jpg';
import pic608 from 'assets/images/sub/_images/pic608.jpg';
import pic609 from 'assets/images/sub/_images/pic609.jpg';
import pic610 from 'assets/images/sub/_images/pic610.jpg';
import pic611 from 'assets/images/sub/_images/pic611.jpg';

function Do116() {

  return (
    <div className="techinfoview">
      <h1>질탈</h1>
      <p className="pstyle">질탈은 일반적으로 질벽(膣壁)의 일부가 외음부 밖으로 탈출하는 것으로 이때 자궁도 뒤쪽으로 밀려 나온다. 소에서는 태반(胎盤)으로부터 대량의 난포호르몬(에스트로젠)이 분비되는 시기인 분만전 2∼3개월에 많이 볼 수 있고 분만이 가까워짐에 따라 발생율이 증가한다.  </p>
      <div className="leftpart">
        <h2>원인</h2>
        <ul>
          <li>임신말기 분비되는 에스트로젠(estrogen)은 골반의 인대의 질 주위 조직의 이완과 외음부의 부종을 일으킨다. </li>
          <li>임신말기 복부의 압박이 증가한다. </li>
          <li>난소낭종에 걸렸을 때와 노령우에서 </li>
        </ul>
        <h2>증상</h2>
        <ul>
          <li>증상이 가벼운 것은 탈출한 부분이 작고, 앉으면 튀어나오고 일어서면 자연히 밀려 들어간다. </li>
          <li>중증(重症)은 질 전체가 탈출하고 그 내부에는 방광이 꼬여 들어가 있는 일이 있다. 이 때는 배뇨장해로 인해 통증을 호소한다. </li>
          <li>탈출한 채 시간이 지나면 탈출된 점막이 붓게 되며 염증을 일으키고 점차로 공기에 닿는 시간이 길어짐에 따라 점막은 건조와 갈라짐으로 인해 통증이 심해져 노책을 계속한다. </li>
          <li>임신중 일때는 유산이나 직장탈 등 합병증을 일으키기도 한다. </li>
          <li>질탈은 거의 대부분 재발하며 다음 임신기에는 한층 더 심해진다.</li>
        </ul>
        <h2>목장에서의 처치</h2>
        <ul>
          <li>소가 앉을 때만 얼마간 탈출하는 증상에 대해서는 계류 우사칸(stanchion)에서 독바으로 옮긴다. </li>
          <li>계류우사내에서는 경사된 발판을 사용하여 소의 후구(後軀)를 전구(前驅)보다 5∼8cm 정도 높혀 주도록 한다. </li>
          <li>병행하여 좌측그립과 같이 간이 압정대를 설치하여 주면 더 이상의 질탈의 악화를 방지할 수 있다. </li>
          <li>처치기간 중 소화하기 쉬운 사료를 급여하여 복부의 압박이나 변비를 일으키지 않도록 해야 한다. </li>
          <li> 탈출 부위가 오염되거나 상처가 생겼을 때는 2%명반수 또는 베타딘으로 깨끗이 씻고 소독한 다음, 항생제 연고를 바른다. </li>
        </ul>
      </div>
      <div className="rightpart">
        <img src={pic605} className="nonborder" alt=""/>
        <img src={pic606} className="nonborder" alt=""/>
        <img src={pic607} className="nonborder" alt=""/>
        <img src={pic608} className="nonborder" alt=""/>
        <img src={pic609} className="nonborder" alt=""/>
        <img src={pic610} className="nonborder" alt=""/>
        <img src={pic611} className="nonborder" alt=""/>
      </div>
    </div>
  );
}

export default Do116;