import React from 'react';


function Do70() {

  return (
    <div className="techinfoview">
				<h1>외국의 원유검사 및 유대지급제도</h1>
				<h2>외국의 유대지급제도</h2>
				<p className="pstyle">&nbsp;&nbsp;&nbsp;세계각국의 원유검사 및 유대지급 제도는 낙농가, 낙농단체의 전문기관, 국가 기관 등 각 나라마다 그리고 지역마다 약간씩 다르지만 대부분의 국가에서 모든 것의 주체는 낙농가에 의해서 이루어 지고 있다. 유대지급 제도는 보통 유지방, 유단백질, 세균수, 체세포수에 의해서 지불되어지고 있으나, 일본은 유단백질 대 신 무지고형분을, 미국은 유지방, 유단백질, 무지고형분 모두를 유대지급 요소로 하며, 적용기준은 나라마다 약간의 차이가 있다.</p><br/>
				<p><b>&lt;표 12&gt; 국가별 유성분에 따른 유대지불 기준</b></p>
				<table className="tablestyle">
					<tr>
						<td>국가</td>
						<td>지방</td>
						<td>단백질</td>
						<td>유당</td>
						<td>무지고형분</td>
						<td>회분</td>
					</tr>
					<tr>
						<td>오스트리아</td>
						<td>4.02%</td>
						<td>3.24%</td>
						<td></td>
						<td></td>
						<td></td>
					</tr>
					<tr>
						<td>벨기에</td>
						<td>3.8%(g/V)</td>
						<td>3.35%(g/L)</td>
						<td></td>
						<td></td>
						<td></td>
					</tr>
					<tr>
						<td>독일</td>
						<td>3.7%min</td>
						<td>3.4%min</td>
						<td></td>
						<td></td>
						<td></td>
					</tr>
					<tr>
						<td>덴마크</td>
						<td>3.6%(g/V)</td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
					</tr>
					<tr>
						<td>캐나다</td>
						<td>3.69%</td>
						<td>3.11%</td>
						<td></td>
						<td></td>
						<td></td>
					</tr>
					<tr>
						<td>프랑스</td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
					</tr>
					<tr>
						<td>영국</td>
						<td>3.6%</td>
						<td>3.3%</td>
						<td></td>
						<td></td>
						<td></td>
					</tr>
					<tr>
						<td>아일랜드</td>
						<td>3.5%</td>
						<td></td>
						<td></td>
						<td>8.45%</td>
						<td></td>
					</tr>
					<tr>
						<td>일본</td>
						<td>3.2%</td>
						<td> 3.2%</td>
						<td></td>
						<td></td>
						<td></td>
					</tr>
					<tr>
						<td>네덜란드</td>
						<td>4.0%</td>
						<td>3.4%</td>
						<td></td>
						<td></td>
						<td></td>
					</tr>
					<tr>
						<td>뉴질랜드</td>
						<td>3.5%</td>
						<td>3.0-3.2%</td>
						<td></td>
						<td>8.65%</td>
						<td></td>
					</tr>
					<tr>
						<td>노르웨이</td>
						<td>3.75%</td>
						<td>3.35%</td>
						<td>4.85%</td>
						<td></td>
						<td>1.0%</td>
					</tr>
				</table><br/>
				<p className="pstyle">성분적 유질의 기준치는 대부분이 유지방 3.5∼3.6%, 유단백질은 3.2∼3.4% 로 설정하고 있으며, 유질이 낮은 원유에 대해서는 벌과금(Penalty) 중심으로 유대지급체계가 정해져 있으며, 유질이 좋은 원유에 대해서는 장려금 (Premium)을 지급하는 경우가 많다. 그리고 검사주기는 나라마다 매주, 월 1 회 또는 2회를 실시하고 있으며, 유대지급 기준도 15일 또는 한 달 단위로 실시 하고 있으며, 또한 유대가격도 계절별로 차이를 두는 나라도 있다.</p><br/>
				<p><b>&lt;표 13&gt; 국가별 원유검사 주관기관</b></p>
				<table className="tablestyle">
					<tr>
						<td>국가</td>
						<td>낙농가</td>
						<td>관련단체</td>
						<td>전문기관</td>
						<td>국가</td>
						<td>독립된 개인 기관</td>
						<td>분쟁 조정 기관</td>
					</tr>
					<tr>
						<td>오스트리아</td>
						<td></td>
						<td></td>
						<td>Yes</td>
						<td></td>
						<td></td>
						<td>Yes</td>
					</tr>
					<tr>
						<td>벨기에</td>
						<td></td>
						<td></td>
						<td>Yes</td>
						<td></td>
						<td></td>
						<td>Yes</td>
					</tr>
					<tr>
						<td>독일</td>
						<td></td>
						<td>Yes</td>
						<td></td>
						<td></td>
						<td></td>
						<td>Yes</td>
					</tr>
					<tr>
						<td>덴마크</td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td>Yes</td>
						<td>No</td>
					</tr>
					<tr>
						<td>캐나다</td>
						<td></td>
						<td></td>
						<td>Yes</td>
						<td>Yes</td>
						<td>Yes</td>
						<td>Yes</td>
					</tr>
					<tr>
						<td>프랑스</td>
						<td></td>
						<td></td>
						<td>Yes</td>
						<td></td>
						<td></td>
						<td>Yes</td>
					</tr>
					<tr>
						<td>영국</td>
						<td></td>
						<td>Yes</td>
						<td></td>
						<td></td>
						<td></td>
						<td>Yes</td>
					</tr>
					<tr>
						<td>아일랜드</td>
						<td>Yes</td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td>Yes</td>
					</tr>
					<tr>
						<td>일본</td>
						<td></td>
						<td></td>
						<td></td>
						<td>Yes</td>
						<td>Yes</td>
						<td>No</td>
					</tr>
					<tr>
						<td>네덜란드</td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td>Yes</td>
						<td>Yes</td>
					</tr>
					<tr>
						<td>뉴질랜드</td>
						<td>Yes</td>
						<td>Yes</td>
						<td></td>
						<td></td>
						<td></td>
						<td>Yes</td>
					</tr>
					<tr>
						<td>노르웨이</td>
						<td>Yes</td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
					</tr>
					<tr>
						<td>스웨덴</td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td>Yes</td>
						<td>No</td>
					</tr>
					<tr>
						<td>미국</td>
						<td>Yes</td>
						<td>Yes</td>
						<td></td>
						<td></td>
						<td>Yes</td>
						<td>No</td>
					</tr>
					<tr>
						<td>남아프리카</td>
						<td>Yes</td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td>Yes</td>
					</tr>
				</table><br/>
				<p><b>&lt;표 14&gt; 국가별 유대가격 결정 기관</b></p>
				<table className="tablestyle">
					<tr>
						<td>국가</td>
						<td>낙농가</td>
						<td>관련단체</td>
						<td>전문기관</td>
						<td>국가</td>
					</tr>
					<tr>
						<td>오스트리아</td>
						<td></td>
						<td></td>
						<td></td>
						<td>Yes</td>
					</tr>
					<tr>
						<td>벨기에</td>
						<td>Yes</td>
						<td></td>
						<td></td>
						<td></td>
					</tr>
					<tr>
						<td>독일</td>
						<td>Yes</td>
						<td></td>
						<td></td>
						<td></td>
					</tr>
					<tr>
						<td>덴마크</td>
						<td></td>
						<td>Yes</td>
						<td></td>
						<td></td>
					</tr>
					<tr>
						<td>캐나다</td>
						<td></td>
						<td></td>
						<td>Yes</td>
						<td>Yes</td> 
					</tr>
					<tr>
						<td>프랑스</td>
						<td>Yes</td>
						<td></td>
						<td>Yes</td>
						<td></td> 
					</tr>
					<tr>
						<td>영국</td>
						<td></td>
						<td>Yes</td>
						<td></td>
						<td></td> 
					</tr>
					<tr>
						<td>아일랜드</td>
						<td>Yes</td>
						<td></td>
						<td></td>
						<td></td> 
					</tr>
					<tr>
						<td>일본</td> 
						<td></td>
						<td></td>
						<td>Yes</td>
						<td>Yes</td> 
					</tr>
					<tr>
						<td>네덜란드</td>
						<td>Yes</td>
						<td></td>
						<td></td>
						<td></td> 
					</tr>
					<tr>
						<td>뉴질랜드</td>
						<td>Yes</td>
						<td></td>
						<td></td>
						<td></td> 
					</tr>
					<tr>
						<td>노르웨이</td>
						<td>Yes</td>
						<td></td>
						<td></td>
						<td></td> 
					</tr>
					<tr>
						<td>스웨덴</td>
						<td>Yes</td>
						<td></td>
						<td></td>
						<td></td> 
					</tr>
					<tr>
						<td>미국</td>
						<td>Yes</td>
						<td></td>
						<td></td>
						<td></td> 
					</tr>
					<tr>
						<td>남아프리카</td>
						<td>Yes</td>
						<td></td>
						<td></td>
						<td></td> 
					</tr>
				</table><br/>
				<p><b>&lt;표 15&gt; 국가별 유대지불 단위</b></p>
				<table className="tablestyle">
					<tr>
						<td>국가</td>
						<td>낙농가</td>
						<td>관련단체</td>
						<td>전문기관</td>
					</tr>
					<tr>
						<td>오스트리아</td>
						<td></td>
						<td>Yes</td>
						<td>No</td>
					</tr>
					<tr>
						<td>벨기에</td>
						<td>Yes</td>
						<td>Yes</td>
						<td>Yes</td>
					</tr>
					<tr>
						<td>독일</td>
						<td> </td>
						<td>Yes</td>
						<td>No</td> 
					</tr>
					<tr>
						<td>덴마크</td>
						<td>Yes</td>
						<td> </td>
						<td>Yes</td> 
					</tr>
					<tr>
						<td>캐나다</td>
						<td>Yes</td>
						<td>Yes</td>
						<td>No</td> 
					</tr>
					<tr>
						<td>프랑스</td>
						<td> </td>
						<td>Yes</td>
						<td>Yes</td> 
					</tr>
					<tr>
						<td>영국</td>
						<td></td>
						<td>Yes</td>
						<td>Yes</td> 
					</tr>
					<tr>
						<td>아일랜드</td>
						<td></td>
						<td>Yes</td>
						<td>No</td> 
					</tr>
					<tr>
						<td>일본</td> 
						<td></td>
						<td>Yes</td>
						<td>No</td> 
					</tr>
					<tr>
						<td>네덜란드</td>
						<td>Yes</td>
						<td></td>
						<td>Yes</td> 
					</tr>
					<tr>
						<td>뉴질랜드</td>
						<td></td>
						<td>Yes</td>
						<td>Yes</td> 
					</tr>
					<tr>
						<td>노르웨이</td>
						<td></td>
						<td>Yes</td>
						<td>No</td> 
					</tr>
					<tr>
						<td>스웨덴</td>
						<td></td>
						<td>Yes</td>
						<td>Yes</td> 
					</tr>
					<tr>
						<td>미국</td>
						<td>Yes</td>
						<td></td>
						<td>No</td> 
					</tr>
					<tr>
						<td>남아프리카</td>
						<td></td>
						<td>Yes</td>
						<td>No</td> 
					</tr>
				</table><br/>
				<p><b>&lt;표 16&gt; 국가별 유대결정 및 계절별 가격 현황</b></p>
				<table className="tablestyle">
					<tr>
						<td rowSpan="4">국가</td>
						<td colSpan="11">계절별(Seasonality)</td>
					</tr>
					<tr style={{background: '#273143', Color: '#fff'}}>
						<td colSpan="3">가격의 안정성(Price stability)</td>
						<td colSpan="8">가격기준(During which period is the price)</td>
					</tr>
					<tr style={{background: '#273143', Color: '#fff'}}>
						<td rowSpan="2">연중고정(Fixed for Year)</td>
						<td rowSpan="2">계절별(Seasonal variance)</td>
						<td rowSpan="2">시장원리(Market Variance)</td>
						<td colSpan="4">가장 높을때(The highest)</td>
						<td colSpan="3">가장 낮을떄(The lowest)</td>
						<td rowSpan="2">가격차이(Price difference high &amp; low)</td>
					</tr>
					<tr style={{background: '#273143', Color: '#fff'}}>
						<td>겨울</td>
						<td>봄</td>
						<td>여름</td>
						<td>가을</td>
						<td>겨울</td>
						<td>봄</td>
						<td>여름</td>
					</tr>
					<tr>
						<td>오스트리아</td>
						<td>Yes</td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td>2.5 BF/l</td>
					</tr>
					<tr>
						<td>벨기에</td>
						<td>No</td>
						<td>Yes</td>
						<td>No</td>
						<td>Yes</td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td>Yes</td>
						<td>10%</td>
					</tr>
					<tr>
						<td>독일</td>
						<td>No</td>
						<td>Yes</td>
						<td>Yes</td>
						<td></td>
						<td></td>
						<td></td>
						<td>Yes</td>
						<td></td>
						<td></td>
						<td></td>
						<td>28%</td>
					</tr>
					<tr>
						<td>덴마크</td>
						<td>No</td>
						<td>Yes</td>
						<td>Yes</td>
						<td></td>
						<td></td>
						<td>Yes</td>
						<td>Yes</td>
						<td></td>
						<td>Yes</td>
						<td></td>
						<td>0.01-0.2CA$/l</td>
					</tr>
					<tr>
						<td>캐나다</td>
						<td>No</td>
						<td>No</td>
						<td>Yes</td>
						<td>Yes</td>
						<td></td>
						<td></td>
						<td>Yes</td>
						<td></td>
						<td>Yes</td>
						<td>Yes</td>
						<td>0.37 FF/l</td>
					</tr>
					<tr>
						<td>프랑스</td>
						<td>No</td>
						<td>Yes</td>
						<td>No</td>
						<td>Yes</td>
						<td></td>
						<td></td>
						<td>Yes</td>
						<td></td>
						<td>Yes</td>
						<td></td>
						<td>44.8%</td>
					</tr>
					<tr>
						<td>영국</td>
						<td>No</td>
						<td>Yes</td>
						<td>No</td>
						<td></td>
						<td></td>
						<td>Yes</td>
						<td></td>
						<td></td>
						<td>Yes</td>
						<td></td>
						<td>5.3 pp/l</td>
					</tr>
					<tr>
						<td>아일랜드</td>
						<td>No</td>
						<td>Yes</td>
						<td>No</td>
						<td>Yes</td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td>Yes</td>
						<td>Yes</td>
						<td></td>
					</tr>
					<tr>
						<td>일본</td>
						<td>Yes</td>
						<td>No</td>
						<td>No</td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td>0.16 Dfl/kg</td>
					</tr>
					<tr>
						<td>네덜란드</td>
						<td>No</td>
						<td>Yes</td>
						<td>Yes</td>
						<td>Yes</td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td>Yes</td>
						<td> </td>
					</tr>
					<tr>
						<td>뉴질랜드</td>
						<td>Yes</td>
						<td>No</td>
						<td>No</td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
					</tr>
					<tr>
						<td>노르웨이</td>
						<td>No</td>
						<td>Yes</td>
						<td>No</td>
						<td></td>
						<td></td>
						<td>Yes</td>
						<td></td>
						<td>Yes</td>
						<td></td>
						<td></td>
						<td></td>
					</tr>
					<tr>
						<td>스웨덴</td>
						<td>No</td>
						<td>Yes</td>
						<td>No</td>
						<td>Yes</td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td>Yes</td>
						<td>30%</td>
					</tr>
					<tr>
						<td>미국</td>
						<td>No</td>
						<td>No</td>
						<td>Yes</td>
						<td>Yes</td>
						<td></td>
						<td></td>
						<td>Yes</td>
						<td></td>
						<td>Yes</td>
						<td>Yes</td>
						<td></td>
					</tr>
					<tr>
						<td>남아프리카</td>
						<td>Yes</td>
						<td>No</td>
						<td>Yes</td>
						<td>Yes</td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td>Yes</td>
						<td>0.02 SAR/l</td>
					</tr>
				</table>
				<h2>외국의 세균수 및 체세포수 위생등급 기준</h2>
				<p>세계 각국의 세균수 및 체세포수 등급의 규정을 살펴보면 세균수의 경우 일본 은 1등급이 30만 이하 이고 2등급은 30만 이상이며, 뉴질랜드의 경우 2만 5천 이 1등급부터 50만초과의 6등급으로 나누어져 있으며, 검사주기는 매주 1회부터 월 1∼2회 검사하여 유대에 반영하고 있다&lt;표 17&gt;.</p><br/>
				<p><b>&lt;표 17&gt; 국가별 세균수 위생 등급 현황</b></p>
				<table className="tablestyle">
					<tr>
						<td rowSpan="2">국가</td>
						<td colSpan="6">등급(ml당)</td>
						<td rowSpan="2">집유정지선</td>
						<td rowSpan="2">검사주기</td>
					</tr>
					<tr style={{background: '#273143', Color: '#fff'}}>
						<td>1</td>
						<td>2</td>
						<td>3</td>
						<td>4</td>
						<td>5</td>
						<td>6</td>
					</tr>
					<tr>
						<td>덴마크</td>
						<td>3만미만</td>
						<td>3-10만 미만</td>
						<td>10-30만 이하</td>
						<td>30만 초과</td> 
						<td>-</td>
						<td>-</td>
						<td>40만 초과</td>
						<td>매주</td>
					</tr>
					<tr>
						<td>영국</td>
						<td>2만미만</td>
						<td>2-10만 이하</td>
						<td>10만 초과</td>
						<td>-</td>
						<td>-</td>
						<td>-</td>
						<td>20만 초과</td>
						<td>매주</td>
					</tr>
					<tr>
						<td>미국</td>
						<td>2만5천 이하</td>
						<td>2만5천 초과</td>
						<td>-</td>
						<td>-</td>
						<td>-</td>
						<td>-</td>
						<td>30만 초과</td>
						<td>월1회</td>
					</tr>
					<tr>
						<td>캐나다</td>
						<td>5만미만</td>
						<td>5-7만 5천 이하</td>
						<td>7만5천 초과</td>
						<td>-</td>
						<td>-</td>
						<td>-</td>
						<td>10만 초과</td>
						<td>월1회</td>
					</tr>
					<tr>
						<td>호주</td>
						<td>2만미만</td>
						<td>2-5만이하</td>
						<td>5만 초과</td>
						<td>-</td>
						<td>-</td>
						<td>-</td>
						<td>5만 초과</td>
						<td>월2-4회</td>
					</tr>
					<tr>
						<td>뉴질랜드</td>
						<td>2만5천</td>
						<td>2만5천-5만 미만</td>
						<td>5만-10만 미만</td>
						<td>10만-20만 미만</td>
						<td>20-50만 이하</td>
						<td>50만 초과</td>
						<td>-</td>
						<td>10일에 1회</td>
					</tr>
					<tr>
						<td>일본</td>
						<td>30만 이하</td>
						<td>30만 초과</td>
						<td>-</td>
						<td>-</td>
						<td>-</td>
						<td>-</td>
						<td>100만 초과</td>
						<td>월1회</td>
					</tr>
					<tr>
						<td>이스라엘</td>
						<td>4만미만</td>
						<td>4만-10만 미만</td>
						<td>10만-25만 미만</td>
						<td>25만-50만 이하</td>
						<td>50만 초과</td>
						<td>-</td>
						<td>-</td>
						<td>월2회</td>
					</tr>
					<tr>
						<td>한국</td>
						<td>10만 미만</td>
						<td>10만-25만 미만</td>
						<td>25만-50만 미만</td>
						<td>50만 초과</td>
						<td>-</td>
						<td>-</td>
						<td>-</td>
						<td>월2회</td>
					</tr>
				</table>
				<p>(주) - 일본 : 총균수(TBC : Total Bacterial Counts) 기준임.<br/>- 기타국 : 일반세균수(CFU : Colony Forming Units) 기준임. </p><br/>
				<p className="pstyle">체세포의 경우는 나라에 따라 3등급에서 5등급까지 다양하며, 덴마크의 경우 1등급은 30만 미만으로 우리보다 높으며, 4등급 또한 75만을 기준으로 삼고 있 다. 낙농 선진국이라 할 수 있은 캐나다와 뉴질랜드 역시 1등급은 50만 미만이 고, 4, 5등급은 75만에서 100만 초과까지 다양하게 분포하고 있다. 물론 평균 생산 원유중 체세포수는 낮지만 국가 규정상의 등급 기준은 (표 18)와 같다. 1 등급 기준이 20만 미만인 나라는 우리나라와 호주, 미국 등 약 3개 나라에서만 규정되고 있고, 최고 등급도 50만 초과 나라는 한국, 일본, 호주 등 3∼4개 나라 에서만 채택되고 있어 규정상으로는 국내 규정이 상당히 높은 것으로 나타났다. </p><br/>
				<p><b>&lt;표 18&gt; 국가별 체세포 수 위생 등급 현황</b></p>
				<table className="tablestyle">
					<tr>
						<td rowSpan="2">국가</td>
						<td colSpan="5">등급(ml당)</td>
						<td rowSpan="2">집유정지선</td>
						<td rowSpan="2">검사주기</td>
					</tr>
					<tr style={{background: '#273143', Color: '#fff'}}>
						<td>1</td>
						<td>2</td>
						<td>3</td>
						<td>4</td>
						<td>5</td>
					</tr>
					<tr>
						<td rowSpan="2">덴마크</td>
						<td>30만 미만</td>
						<td>30∼40만 미만</td>
						<td>40∼75만 미만</td>
						<td>75만 이상</td>
						<td>-</td>
						<td></td>
						<td>매주</td>
					</tr>
					<tr>
						<td>+3 ore/kg</td>
						<td>0</td>
						<td>-3 ore/kg</td>
						<td>-6 ore/kg</td>
						<td></td>
						<td></td>
						<td></td>
					</tr>
					<tr>
						<td rowSpan="2">영국</td>
						<td>40만 미만</td>
						<td>40∼50만 미만</td>
						<td>50∼100만 미만</td>
						<td>100만 초과</td>
						<td>-</td>
						<td>-</td>
						<td>매주</td>
					</tr>
					<tr>
						<td>0</td>
						<td>-0.5 ppl</td>
						<td>-1.0ppl</td>
						<td>-2.0ppl</td>
						<td></td>
						<td></td>
						<td></td>
					</tr>
					<tr>
						<td rowSpan="2">미국</td>
						<td>10만 미만</td>
						<td>30∼40만 미만</td>
						<td>40∼75만 미만</td>
						<td>75만 이상</td>
						<td>-</td>
						<td>100만 이상</td>
						<td>월 6회</td>
					</tr>
					<tr>
						<td>+$20∼$80</td>
						<td>+$10∼</td>
						<td>0</td>
						<td>-$10∼</td>
						<td></td>
						<td></td>
						<td></td>
					</tr>
					<tr>
						<td rowSpan="2">캐나다</td>
						<td>50만 미만</td>
						<td>50∼60만 미만</td>
						<td>60∼70만 미만</td>
						<td>70∼75만 이하</td>
						<td>-</td>
						<td>75만 초과</td>
						<td>월 1∼4회</td>
					</tr>
					<tr>
						<td>0</td>
						<td>-1/hl </td>
						<td>-2/hl</td>
						<td>-3/hl</td>
						<td></td>
						<td></td>
						<td></td>
					</tr>
					<tr>
						<td rowSpan="2">일본</td>
						<td>30만 미만</td>
						<td>30~50만 이하</td>
						<td>50만 초과</td>
						<td>-</td>
						<td>-</td>
						<td>100만 초과</td>
						<td>월 2∼3회</td>
					</tr>
					<tr>
						<td>0</td>
						<td>-1∼-2 Yen</td>
						<td>-2∼-5 Yen</td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
					</tr>
					<tr>
						<td rowSpan="2">이스라엘</td>
						<td>30만 미만</td>
						<td>30∼50만 미만</td>
						<td>50∼70만 미만</td>
						<td>70만 초과</td>
						<td>-</td>
						<td>-</td>
						<td>월 2회</td>
					</tr>
					<tr>
						<td>+0.5%</td>
						<td>0</td>
						<td>-1.0%</td>
						<td>-2/0%</td>
						<td></td>
						<td></td>
						<td></td>
					</tr>
					<tr>
						<td rowSpan="2">한국</td>
						<td>20만 미만</td>
						<td>20∼50만 미만</td>
						<td>50만 초과</td>
						<td>-</td>
						<td>-</td>
						<td>-</td>
						<td>월 2회</td>
					</tr>
					<tr>
						<td>+30원 </td>
						<td>0</td>
						<td>-30원</td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
					</tr>
					<tr>
						<td rowSpan="2">호주</td>
						<td>20만 미만</td>
						<td>20∼30만 미만</td>
						<td>30∼50만 미만</td>
						<td>50만 초과</td>
						<td>-</td>
						<td>75만 초과</td>
						<td>-</td>
					</tr>
					<tr>
						<td colSpan="7">Varies</td> 
					</tr>
					<tr>
						<td rowSpan="2">뉴질랜드</td>
						<td>50만 미만</td>
						<td>50∼60만 미만</td>
						<td>60∼80만 미만</td>
						<td>80만∼100만 미만</td>
						<td>100만 초과</td>
						<td>-</td>
						<td>10일에 1회</td>
					</tr>
					<tr>
						<td colSpan="7">Varies</td> 
					</tr>
				</table>
				<p>(주) 자료원전 : IDF Bulletin No. 305/1995</p>
			</div>
  );
}

export default Do70;