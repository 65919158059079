import React from 'react';

// scss
import './DciInfo.scss';

function DciInfo() {
  return (
    <div className='dciInfo'>
      <div className='dciInfoInner'>
        <p className='dciInfoTitle'>제 3자 정보제공 동의</p>
        <p className='dciInfoDesc'>조합농가코드 위쪽에 &quot;제 3자 정보 제공 동의를 원하시면 조합,농가코드와 비밀번호를 입력 후 확인버튼을 누르십시오.&quot; </p>
        <ul>
          <li>
            <label htmlFor='dciInfoCode'>조합, 농가코드</label>
            <input type='text' id='dciInfoCode' name='dciInfoCode' />
          </li>
          <li>
            <label htmlFor='dciInfoPass'>비밀번호</label>
            <input type='password' id='dciInfoPass' name='dciInfoPass'/>
          </li>
        </ul>
        <button type='button'>확인</button>
      </div> 
    </div> 
  );
}

export default DciInfo;
