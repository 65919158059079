// MonthFarmhouseComponent.js
import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getState } from 'utils/api';

// 컴포넌트
import SubTitle from 'components/subTitle/SubTitle';

// scss
import './MonthFarmhouse.scss';

function MonthFarmhouse() {
  // 현재 년도 설정
  const [currentTab, setCurrentTap] = useState(
    Number(new Date().getFullYear()),
  );

  // React Query를 사용하여 데이터 가져오기
  const { data, isLoading, error } = useQuery({
    queryKey: ['monthFarmHouse', currentTab],
    queryFn: () =>
      getState('/report/v1/month_farm_house', { year: currentTab }),
    enabled: !!currentTab, // currentTab이 있을 때만 쿼리 실행
    staleTime: 1000 * 60 * 5,
  });

  console.log(data);

  // 년도 렌더링하기 ( 2005 ~ 현재년도 )
  const startYear = 2006;
  const endYear = Number(new Date().getFullYear());

  const years = [];

  for (let year = endYear; year >= startYear; year -= 1) {
    years.push(year);
  }

  // 데이터 렌더링을 위한 entries 배열 정의
  const entries = [
    { content: '검정농가 BEST', fname: 'Rank_best.mrd' },
    {
      content: '유량상위 100위 농가 [경산우 50두 이하]',
      fname: 'Rank_farm.mrd',
    },
    {
      content: '유량상위 100위 농가 [경산우 51~100두]',
      fname: 'Rank_farm1.mrd',
    },
    {
      content: '유량상위 100위 농가 [경산우 100두 초과]',
      fname: 'Rank_farm2.mrd',
    },
    {
      content: '건유우중 305유량 100위(1산)',
      fname: 'Rank_cow1.mrd',
    },
    {
      content: '건유우중 305유량 - 200위(2산이상)',
      fname: 'Rank_cow2.mrd',
    },
  ];

  // OZ Report 열기 함수
  const handleOpenOzReport = (fname, yr, mt) => {
    const tmonth = String(yr).padStart(4, '0') + String(mt).padStart(2, '0');
    const queryParams = new URLSearchParams({
      fname,
      mon: tmonth,
    });
    const url = `${process.env.PUBLIC_URL}/crownix.html?${queryParams}`;
    window.open(url, '_blank', 'noopener, noreferrer');
  };

  return (
    <div className="monthFarmhouse subCon">
      <SubTitle />
      <ul className="monthFarmHouseTab">
        {years &&
          years.map((year) => (
            <li key={year}>
              <button
                type="button"
                onClick={() => setCurrentTap(year)}
                className={year === currentTab ? 'current' : ''}
              >
                {year}
              </button>
            </li>
          ))}
      </ul>
      {isLoading && <div>로딩 중...</div>}
      {error && <div>에러가 발생했습니다: {error.message}</div>}
      {data?.result && (
        <div className="monthlyData current">
          {data.result.map((yearData) =>
            // 월 데이터를 내림차순으로 정렬하고 현재 년도인 경우 현재 월까지만 필터링
            [...yearData.month]
              .sort((a, b) => b.month - a.month)
              .filter((monthData) => {
                const currentYear = new Date().getFullYear();
                const currentMonth = new Date().getMonth() + 1;

                console.log(Number(yearData.year), currentYear);

                if (Number(yearData.year) === currentYear) {
                  return monthData.month <= currentMonth;
                }
                return true;
              })
              .map((monthData) => (
                <div key={monthData.month} className="monthFarmhouse_con">
                  <h2 className="monthFarmhouse_title">
                    {yearData.year}년 {monthData.month}월
                  </h2>
                  <ul className="monthFarmhouse_info">
                    {entries.map((entry) => (
                      <li
                        key={entry.fname}
                        className="entryItem"
                        onClick={() =>
                          handleOpenOzReport(
                            entry.fname,
                            yearData.year,
                            monthData.month,
                          )
                        }
                      >
                        <span>{entry.content}</span>
                        <span className="icon" />
                      </li>
                    ))}
                  </ul>
                </div>
              )),
          )}
        </div>
      )}
    </div>
  );
}

export default MonthFarmhouse;
