import React from 'react';

import Figure3by1 from 'assets/images/sub/_images/figure_3_1.gif';
import Figure3by2 from 'assets/images/sub/_images/figure_3_2.gif';
import Figure3by3 from 'assets/images/sub/_images/figure_3_3.gif';
import Figure3by4 from 'assets/images/sub/_images/figure_3_4.gif';

function Fo14() {

  return (
    <div className="techinfo_view">
				<p className="viewTitle">임신과 분만 - 임신</p>
				<h2>수정(Fertilization)</h2>
				<p>수정과 임신의 복잡한 과정은 호르몬의 지배를 받는다. 수정은 정자와 난자의 결함에 의해 수정란이 된다(그림3.1.). 수정이 일어나는 장소는 난관이다. 수천∼수억의 정자는 암소의 생식기관에 저장되어 있으며, 단지 천여마리 정도만이 수정의 장소에 도달한다. 정자는 난자를 수정시키기전 수정능획득이라고 하는 화학적 반응이 일어난다(이과정은 6시간이상 소요된다). 몇몇 정자는 난자의 외부세포를 통과하지만, 단지 1마리만이 난자내로 들어갈 수 있다. 이과정이 완료되면 난자에 다른 정자의 투과를 즉시 차단하게 된다. 난자의 염색체는 정자와 비로서 결합을 하게 되는 것이다. 이 과정이 완료되면 난자와 정자는 접합체가 되고 유전적 능력으로 구성된 하나의 수정란이 된다. 수정후 16∼17일이 경과하면 소는 임신을 자각하게되는데 황체는 퇴행되지 않으며 임신이 지속될 수 있도록 호르몬을 분비하며 새로운 발정주기의 개시를 막는다. </p>
				<div className="imgBox mg60f"><img src={Figure3by1} alt=""/></div>
				
				<h2>착상(Implantation)</h2>
				<p>수정후, 접합체는 세포분열이 되는데 이것을 난할이라고 한다. 난할은 지속적으로 진행이 되지만, 수정란의 크기는 8∼9일 동안 발달한다.(그림3.1) 수정란은 수정후 2∼3일이 되면 자궁으로 이행된다. 수정란이 자궁벽에 부착되는 것을 착상이라고 하며, 수정후 28일령에 착상이 일어난다.</p>
				<p className="mg30f">수정란이 자궁에서 자유롭게 유영하는 동안 자궁벽과 수정란의 세포막을 통하여 영양분을 흡수한다. 태막은 모체의 자궁벽과 결합을 하는데 이것을 태반이라고 한다. 태아에 의해 생성된 하나의 막(요막양막; allantochorion)은 특수한 자궁벽(자궁소구; caruncles)에 부착된다. 요막양막과 자궁소구의 두 조직을 궁부(cotyledon)라고 한다.(그림3.2.) 80∼100개의 궁부는 소의 태반과 연결되어 있다. 분만시 궁부와 태반이 나오지 않으면, 후산정체가 된다. </p>
				<div className="imgBox"><img src={Figure3by2} alt=""/></div>
				<dl>
					<dt>배사멸(embryo daeth)</dt>
					<dd>
						<p>다음과 같은 이유로 인해 배가 사멸된다</p>
						<ul className="listStyle04 mg10f">
							<li>호르몬 이상(불충분한 프로제스테론) </li>
							<li>박테리아에 의한 자궁감염 </li>
							<li>수정란의 유전적 기형(이상) </li>
						</ul>
						<p>착상이 완료될때까지 배사멸의 확율은 아주 높은편이다. 배사멸은 지금까지 인식되어 진것보다 훨씬 심각하다. 임신된 것중 10∼20％ 정도가 조기에 배사멸이 일어난다. 만약 수정후 17∼18일 사이에 백사멸이 일어난다면, 정상적인 발정주기가 재귀됨으로 농부는 임신이되지 않았음을 알 수가 있다. 늦게 배사멸이 일어나면 발정의 재귀가 늦어지는 결과를 초래하게 된다. </p>
					</dd>
				</dl>
				
				<dl>
					<dt>착상</dt>
					<dd>
						<div className="imgBox"><img src={Figure3by3} alt=""/></div>
						<p>수정란이 자궁에 착상하고 태반이 형성되면 이 시기의 수정란을 태아라고 한다. 태반의 일부인 제대(umbilical cord)는 영양분을 공급하며 모체와 태아의 혈액의 산물을 제거한다. 모체와 태아의 혈액은 혼합되지 않는데, 이러한 이유로 박테리아의 감염에 대한 항체와 고분자량은 태아로 전달되지 않는다. 그러나 다량의 항체가 소의 초유에 포함되어 있다. 출산후 초유는 송아지에게 포유하는 것은 아주 중요한 과정이다. </p>
						<p>임신기간 동안 태아의 발달은 유전적 요인들(품종)과 환경적 요인(소의 크기, 산차, 기후)에 의해 많은 영향을 미친다. 대부분의 태아발달은 마지막 3개월(190∼282)에 진행되는데 체중도 4∼45㎏가 된다(그림3.3.). 태아의 급속한 발달을 위해서는 많은 영양분이 필요하며, 특히 마지막 2개월 동안은 특히 많은 영양분의 공급이 필수적이다.</p>
					</dd>
				</dl>
				
				<h2>임신진단</h2>
				<p>번식관리에서 임신진단은 필수적이다. 비임신우는 12.5∼12.8개월의 분만간격을 유지하기 위해서는 발정확인을 하고 인공수정을 가능한 빨리 실시하여야 한다.</p>
				<p>표3.1.은 다양한 임신진단 kit를 이용하여 임신진단을 실시한 결과이다. 몇종의 진단 kit는 소의 조기임신진단이 가능한 방법이다. 이런 임신진단방법이 비용이 고가 일지라도 조기에 임신여부를 확인할 수 있다면 농가에서는 경제적으로 도움이 될 수 있다. 현재, 수정후 발정이 재귀되지 않으면 임신이 된 것으로 확신하는데, 이런 방법으로 임신이 확인되면 수정후 60일을 기다릴 필요가 없어진다. 우군에서 미약발정으로 발정확인의 불가 또는 배사멸은 임신진단의 오류가 발생할 수 있기 때문에 번식성적이 낮아질 수가 있다. </p>
				<div className="tableWrap">
					<table className="tablestyle">
						<caption>표3.1. 다양한 임신진단방법의 장·단점</caption>
						<thead>
							<tr>
								<th rowSpan="2" className="first">진단방법</th>
								<th rowSpan="2">임신진단 기준</th>
								<th rowSpan="2">수정후 일령</th>
								<th rowSpan="2">정확도</th>
								<th colSpan="2">비용</th>
							</tr>
							<tr>
								<th>직접</th>
								<th>간접</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>Milk progesterone</td>
								<td>우유에 고농의 프로제스테론 함유</td>
								<td>21</td>
								<td>높음</td>
								<td>높음</td>
								<td>-</td>
							</tr>
							<tr>
								<td>직장검사</td>
								<td>자궁에 태아의 존재확인</td>
								<td>40-60</td>
								<td>높음</td>
								<td>다양함</td>
								<td>-</td>
							</tr>
							<tr>
								<td>None</td>
								<td>교미후 무발정</td>
								<td>＞60</td>
								<td>낮음</td>
								<td>-</td>
								<td>다소 높음</td>
							</tr>
						</tbody>
					</table>
				</div>
				
				<dl>
					<dt>발정의 불재귀</dt>
					<dd>
						<p>수정후 21일 후 발정이 재귀도지 않으면 임신으로 간주한다. 그러나, 다음과 같은 이유로 발정이 재귀되지 않는 소도 있다</p>
						<ul className="listStyle04 mg10f">
							<li>난소낭종</li>
							<li>발정관찰 실패</li>
						</ul>
						<p>소의 행동을 전확하게 관찰하는 것은 번식성적을 향상시키기 위해서는 필수적인 사항이다. 진단기구를 사용이 불가능한 경우에는 적어도 수정후 60일이 경과하여 발정이 오지 않으면 임신으로 확신을 한다.</p>
						<p>수정후 60일이 경과하여 발정징후를 보인 소는 분만간격이 지연됨으로서 농가에서는 경제적 손실을 입게된다. 이러한 소들은 우유생산능력을 고려하여 도태를 시키고 새로운 미경산으로 대치하는 것이 우군관리에 효율적이다. </p>
					</dd>
				</dl>
				
				<dl>
					<dt>직장검사</dt>
					<dd>수의사들은 수정후 40∼60일이 되면 자궁내에 태아의 존재여부를 확인하기 위하여 직장검사를 실시하는데, 난소에 황체가 존재여부와 다른 기관, 구조물을 확인하여 임신여부를 결정한다. </dd>
				</dl>
				
				<dl className="mg60f">
					<dt>Milk progesterone</dt>
					<dd>임신동안에 발정주기는 황체의 존재와 여기서 분비되는 프로제스테론으로 임신이 유지됨으로서 재귀되지 않는다. 수정후 21∼23일에 우유에서 프로제스테론 농도에 따라 임신과 비임신을 결정한다. </dd>
				</dl>
				
				<p className="viewTitle">유산</p>
				<p>유산은 임신기간이 만료되기전 임신이이 중지되어 자궁으로부터 죽은 태아가 만출되는 것을 말한다. 자궁에서 수정과 착상과정에서 유산율이 높게 나타난다. 임신초기에 일어나는 것을 배사멸이라고 한다. </p>
				<p>착상후에는 유산율이 낮아지지만, 임신 4개월에서 임신말기에는 3∼5％의 유산이 발생한다. 유산의 원인은 다음과 같다</p>
				<ul className="listStyle04 mg10f">
					<li>임신우의 인공수정</li>
					<li>물리적인 손상(임신우의 무리한 취급)</li>
					<li>영양결핍</li>
					<li>독소나 곰팡이 또는 고농도의 에스트로젠이 들어 있는 사료급여</li>
					<li>미생물이나 세균에 의한 감염</li>
				</ul>
				<p>박테리아나 세균, 곰팡이에 의한 감염으로 유산이 임신 4∼7개월사이에 발생한다. 비브리오스나 트리코모나시스는 수소에 의해 감염되는데 이것은 인공수정을 하면 예방할 수가 있다.</p>
				<p>유산이 되면, 태아와 양수는 감염원에 의한 감염을 예방하기 위하여 신중하게 처리한다. 수의사에게 왕진을 요청하고 태아와 태반은 진단실로 보내어 원인을 규명한다. 만약에 브루셀라에 의한 유산이라면 유산된 태아와 태반은 사람의 파상풍의 원인이 되므로 조심해야 한다. </p>
				<div className="imgBox"><img src={Figure3by4} alt=""/></div>
			</div>
  );
}

export default Fo14;
