import React from 'react';

import Pic220 from 'assets/images/sub/_images/pic220.gif';

function Do54() {

  return (
    <div className="techinfoview">
				<h1>갓난 송아지 관리(출생~3일령)</h1>
				<ul>
					<li>호흡 돕기 
						<ul>
							<li>갓난 송아지는 탯줄이 끊어지는 순간부터 호흡을 시작하게 되므로 호흡에 지장이 없도록 콧구멍 속의 양수를 깨끗한 헝겊이나 소독면으로 닦아낸다. 때로는 호흡이 아주 약하거나 숨을 쉬지 못하는 경우가 있는데 이럴 때는 콧구멍을 간질 어서 신경에 자극을 주고 손 또는 인공 호흡기를 이용하여 호흡을 돕는다. 자극을 준다고 송아지의 가슴을 세게 때리는 것은 좋지 않다.  </li>
						</ul>
					</li>
					<li>말려주기 
						<ul>
							<li>송아지 몸에 묻어 있는 양수 및 오물은 빨리 제거하고 말려 주어야 한다. 날씨가 추울 때는 감기에 걸리기 쉽고 더울 때는 고온에 의한 스트레스를 받기 때문이다. <br/>가능하면 어미소가 핥아 주도록 하는 것이 좋으며 그렇지 못할 때는 수건으로 깨끗이 닦아주고 적외선 보온 등을 등위에서 쬐어 주어 발리 마르게 한다. 바닥에는 건조하고 깨끗한 깔 짚을 깔아 주고 찬바람이 직접 송아지 몸에 닿지 않도록 한다.  </li>
						</ul>
					</li>
					<li>배꼽소독 
						<ul>
							<li>태어난 송아지의 탯줄이 길게 끊어진 때는 잘 드는 가위로 약 5∼7㎝ 되도록 잘라 주고 강옥도(7%)로 소독한다. 겉에다 바르는 것만으로는 불충분하므로 배꼽까지 푹 적셔 준다. 가정용 요오드 액은 농도가 낮아서 살균 효과가 크지 못하므로 강옥도를 쓰도록 한다. </li>
						</ul>
					</li>
					<li>초유급여 
						<ul>
							<li>젖소가 분만을 하게 되면 4∼5일간은 보통 우유보다 진하고 색깔이 노란 우유를 분비하는데 이를 초유라 한다. 
								<ul>
									<li>초유의 특성 
										<ul>
											<li>갓난 송아지에 급여하는 첫 번째 사료는 반드시 초유여야 한다. 왜냐하면 초유에는 갓난 송아지가 질병에 걸리지 않도록 해주는 면역물질 즉 항체가 들어 있으며 각종 영양소가 많이 들어 있고 초유는 또한 유지방의 소화와 대사를 돕는 작용과 태변을 배설시키는 작용을 하기 때문이다. <br/>&lt;표 45&gt;는 초유와 정상 우유의 평균 조성분을 나타낸 것으로 송아지는 어미소의 태반이 아닌 초유로부터 항체와 무기물, 비타민 등의 영양소를 공급받게 된다. <br/>
												<p><b>&lt;표 45&gt; 초유와 정상 우유의 평균 조성분</b></p>
												<table className="tablestyle">
													<tr>
														<td rowSpan="2">구분</td>
														<td colSpan="3">초유</td>
														<td rowSpan="2">정상우유</td>
													</tr>
													<tr style={{background: '#273143',color:'#fff'}}>
														<td>0시간</td>
														<td>12시간</td>
														<td>24시간</td>
													</tr>
													<tr>
														<td>총 고형물 (%)</td>
														<td>24.75 </td>
														<td>20.71</td>
														<td>17.09</td>
														<td>12.86</td>
													</tr>
													<tr>
														<td>회 분 (%)</td>
														<td>1.12</td>
														<td>1.04</td>
														<td>0.96</td>
														<td>0.72 </td>
													</tr>
													<tr>
														<td>지 방 (%)</td>
														<td>6.00</td>
														<td>5.50</td>
														<td>5.00</td>
														<td>3.60</td>
													</tr>
													<tr>
														<td>총 단백질 (%)</td>
														<td>11.35</td>
														<td>9.60</td>
														<td>7.07</td>
														<td>3.25</td>
													</tr>
													<tr>
														<td>면역 항체 (㎎/㎖)</td>
														<td>38.23</td>
														<td>32.22</td>
														<td>21.52</td>
														<td>-</td>
													</tr>
												</table>
											</li>
										</ul>
									</li>
									<li>초유의 급여요령 
										<p>이는 분만후 시간이 경우 초유 중의 항체를 흡수하는 송아지 능력이 급격히 떨어지기 때문이다. (그림 18 참조). <br/>분만 첫 째날 초유 급여량을 보면 표 46과 같다. 송아지가 허약하여 생후 30분 이내에 섭취하지 못하거나 먹는 양이 적은 송아지에는 위에 호스를 넣어 강제로 초유를 먹이기도하나, 경험이 없는 농가에서는 삼가야한다.초유에 이상이 있을 경우 즉 분만전 착유, 분만전 어미소의 유루, 심한 혈유나 유방엽에 걸린 경우, 너무 묽은 초유를 분비하는 등 초유에 이상이 있을 때는 분만후 첫 번째 착유한 초유에는 면역 물질이 많이 들어 있으므로 적정 량을 급여하고 나머지는 냉장 온도에 보관하였다가 다음에 이용하는 것이 좋다. 또한 초유는 양질의 것을 급여하여야 하는데 양질의 초유는 건강하고 영양 상태가 좋은 어미소가 적어도 3∼4주 이상의 건유기를 가진 후부터 최초로 생산된 것이다. 초유를 먹이는 시간은 송아지 출생 후 빠를수록 좋으므로 30분 이내에 먹이도록 한다. 냉동초유나 비슷한 시기에 분만한 다른 소의 초유를 먹인다. 생후 2일에서 4일경까지는 계속해서 초유를 급여하되 급여량은 하루에 송아지 체중의 8~10% 정도로 하고 2회로 나누어 규칙적으로 급여한다. <br/>분만 후 처음 며칠 동안 분비되는 초유의 질에 대해서 대개는 관심을 갖고 있지 못하나 다음과 같은 사항들을 알아둘 필요가 있다. </p>
										<ul>
											<li>외관상 묽은 것은 고형분 함량이 낮고 항체도 적게 들어 있다. </li>
											<li>나이 먹은 소의 초유가 어린소의 초유보다 항체 함량이 많다 (약2배). </li>
											<li> 초산우의 송아지에게는 다산우의 냉동초유를 급여하는 것이 좋다. </li>
											<li>초유중에 들어 있는 항체의 종류는 어미소의 질병 경력이나 예방 접종 종류에 따라 다르다. </li>
										</ul>
										<img src={Pic220} className="nonborder" alt=""/>
									</li>
								</ul>
							</li>
						</ul>
					</li>
					<li>이행항체의 효력 
						<ul>
							<li>송아지에게 이행된 항체의 효력은 생후 2개월간 지속된다. 송아지가 이행항체를 형성할 수 없는 경우는 다음과 같다.
								<p><b>&lt;표 46&gt; 분만 후 초유급여시기와 급여량</b></p>
								<table className="tablestyle">
									<tr>
										<td rowSpan="2">생시부터의 시간</td>
										<td rowSpan="2">초유의 종류</td>
										<td colSpan="2">급여량</td>
									</tr>
									<tr style={{background: '#273143',color:'#fff'}}>
										<td>소형종</td>
										<td>대형종</td>
									</tr>
									<tr>
										<td>생시</td>
										<td>처음 짠 초유</td>
										<td>1.4㎏</td>
										<td>2.0㎏</td>
									</tr>
									<tr>
										<td>6</td>
										<td> </td>
										<td>1.4</td>
										<td>2.0</td>
									</tr>
									<tr>
										<td>12 </td>
										<td>처음 또는 두 번 짠 초유</td>
										<td>1.5</td>
										<td>2.0</td>
									</tr>
									<tr>
										<td>24</td>
										<td> </td>
										<td>1.5</td>
										<td>2.0</td>
									</tr>
								</table>
								<ul>
									<li>불충분한 초유 급여시 </li>
									<li> 항체가 적게 든 초유를 급여할때 </li>
									<li>초유를 생후 30분이 지나서 먹일때 </li>
									<li> 송아지의 항체 흡수 능력이 없을때 등이다. 
										<ul>
											<li>송아지의 항체 흡수 능력은 생후 24시간이 지나면 없어진다. </li>
											<li>대부분의 항체 흡수는 생후 4시간 안에 이루어진다. </li>
										</ul>
									</li>
								</ul>
							</li>
						</ul>
					</li>
					<li>스트레스 예방 
						<ul>
							<li>송아지가 생후 24시간 이내에 스트레스를 받게 되면 혈중 항체 농도가 저하되어 폐사율이 높아진다. 여름철에 고온 스트레스(heat stress)를 받지 않도록 옥외 분만시에는 그늘을 만들어 주고 사내분만시에는 환기와 통풍이 잘되도록 하며 찬바람이 직접 몸에 닿지 않도록 함으로서 감기에 걸리지 않도록 한다. <br/>어미소에게 급여하는 조사료의 질이 나쁠 때나 출생한 송아지가 허약할 때는 질병에 대한 저항성을 높이기 위해서 비타민 A, D 또는 비타민 A,D,B 복합제를 주사한다. 허약한 송아지나 설사하는 송아지에는 전해질제제를 투여하여 원기를 돋우고 체액을 보충해 준다. 그리고 필요하면 처음 24∼36시간을 어미소와 함께 있도록 하는 것도 바람직하다.</li>
						</ul>
					</li>
				</ul>
			</div>
  );
}

export default Do54;
