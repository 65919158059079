import React from 'react';

import Pic471 from 'assets/images/sub/_images/pic471.jpg';
import Pic472 from 'assets/images/sub/_images/pic472.jpg';
import Pic473 from 'assets/images/sub/_images/pic473.jpg';

function Do77() {

  return (
    <div className="techinfoview">
				<h1>창상성 심낭염</h1>
				<p className="pstyle">사료와 함께 섭취된 쇠붙이가 소의 제2위벽과 횡격막을 관통하여 심장을 싸고 있는 심낭을 찌르면 쇠붙이에 붙어 있는 세균에 의해 심낭에 화농성 염증이 일어난다.</p>
				<div className="leftpart">
					<h2>원인</h2>
					<ul>
						<li>이병은 소이외의 가축에서는 거의 볼 수 없는데 그 이유는
							<ul>
								<li>소의 혀는 다른 가축에 비해 발달이 나쁘다. (사료중 쇠붙이를 잘 가려내지 못한다.) </li>
								<li>제2위의 구조가 벌집처럼 생겨 쇠붙이가 걸리기 쉽다. </li>
								<li>제2위는 수축운동이 강력하여 쇠붙이가 주위 조직을 관통하기 쉽다.  </li>
							</ul>
						</li> 
					</ul>
					<h2>증상</h2>
					<ul>
						<li>갑작스런 식욕감퇴와 비유량의 현저한 감소 </li>
						<li>운동과 보행을 싫어하고 신음소리를 내기도 한다. </li>
						<li>이 병의 특징적인 증상으로 흉수와 아랫턱에 부종이 생긴다. </li>
						<li>기관지 양측에 있는 경정맥이 막대형으로 뚜렷하게 솟아 오른다. </li>
						<li>증상이 더욱 진행되면 아랫턱이 부종이 더욱 커지고 </li>
						<li>연변(軟便)이나 설사가 일어난다.  </li>
					</ul>
					<h2>목장에서의 처치</h2>
					<ul>
						<li>이 병은 예방이 가장 중요하다(생후 10∼12개월령에 막대자석을 먹인다.) </li>
						<li>식욕부진과 유량감소가 계속되면 이병의 전단계인 창상성 제2위염이나 제2위 횡격막염으로 의심하겨 자석을 먹이지 않은 경우 자석을 먹인다. </li>
						<li>일단 본 병으로 진단이 내려지면 외과적 수술외에 다른 치료 방법이 없으며 정도가 심하면 도태시킨다.  </li> 
					</ul>
				</div>
				<div className="rightpart">
					<img src={Pic471} className="nonborder" alt=""/>
					<p>*제2위와 심장의 위치적 관계를 나타내는 그림 </p>
					<img src={Pic472} className="nonborder" alt=""/>
					<img src={Pic473} className="nonborder" alt=""/>
				</div>
			</div>
  );
}

export default Do77;