import React from 'react';

function Do176() {

  return (
    <div className="techinfoview">
      <h1>초지의 잡초 발생과 대책</h1>
      <p className="pstyle">우리나라는 인위적으로 초지를 조성하기 시작한 역사가 매우 짧다. 따라서 일반농가의 초지관리이용 기술이나 경험이 부족한 실정이다. 아무리 농사에 익숙한 농가라 해도 밭농사의 일반적인 상식이 초지에서는 뜻대로 적용되지 않는 경우가 많아서 초지농사는 흔히 어렵다고 하낟. 특히 초지의 잡초는 상당히 다양해서 일괄적으로 제초제만을 사용해서 제거하기가 어렵다. 따라서 초지의 잡초문제는 그 원인을 밝히고 방제 내지는 조절대책을 모색하는 것이 바람직하다.</p>
      <h2>초지의 환경요인과 잡초구성</h2>
      <p className="pstyle">&nbsp;&nbsp;&nbsp;초지에는 목초와 함께 수백종의 잡초가 혼생하고 있으며 또한 초지조성전에 자생하던 기존의 산야초와 잡관목들이 재생하여 매우 복잡한 식생을 구성하고 있다. 더구나 근래에는 목초종자의 도입에 따라 묻혀 들어온 외국의 잡초들까지 추가되어 초지의 잡초는 그 종류나 발생양면에서 더욱 증가되고 있는 실정이다. 그러나 초지를 구성하고 있는 이들 개개 잡초들의 발생에는 우연이란 있을 수 없으면 반드시 이들 잡초들에 적합한 환경조건이 주어짐으로 해서 나타나게 되는 것이다. 초지에 영향하는 환경요인들이란 기상요인으로서 광, 온도, 습도, 강우량 및 강우량분포 등과 토양요인으로서 토성, 토양구성, 토양수분, 토질, 우기물함량, 토양반응등 토양의 이화학적 특성에 따른 자연환경요인들을 열거할 수가 있다. 그러나 이들 자연환경요인들보다 실제 초지식생구성에 더 결정적인 영향을 주는 것은 인위적인 요인들로서 목초의 파종초종, 초지조성방법, 시비 및 이용방법 등이라 할 수 있다. 요컨대 초지의 식생구성은 이들 환경요인과 이들간의 지극히 복잡한 상호작용에 의하여 결정되는 것이며 따라서 초지의 잡초야말로 우리에세 환경요인에 대한 정보를 가장정확히 표현해 주고 있는 것이다. 이런 관점에서 볼 때 초지의 잡초방지는 잡초의 종류에 따라 이들이 발생하게 되는 환경요인들을 파악하고 개선해 주는 것이 근본적이고도 바람직한 방법이라 하겠다.</p>
      <h2>토양조건과 초지잡초의 종류 및 방제대책</h2>
      <ul>
        <li>토양반응(pH)
          <ul>
            <li>산성토양에 잘 자라는 초지의 잡초
              <ul>
                <li>애기수염 </li>
                <li>들개미자리</li>
                <li>검은겨이삭 </li>
                <li>꿩의밥 </li>
                <li>나도겨이삭 </li>
                <li>들깨풀 </li>
                <li>멍석딸기 </li>
                <li>참쑥 </li>
                <li>닭의 장풀 </li>
                <li>개여뀌 </li>
                <li>미나리아재비</li>
                <li>바랭이 </li>
                <li>고사리 </li>
                <li>맑은대쑥 </li>
              </ul>
            </li>
          </ul>
          <p className="pstyle"><br/>목초의 생육에 적합한 토양산도는 목초의 종류에 따라 다소 차이는 있으나 대부분 pH5.5∼7.0의 범위이다. 초지조성시 석회를 사용해서 산도를 교정했다 해도 3년이 경과하면 대체로 산도가 원상태로 낮아지게 되므로 특히 애기수영과 같이 산성토양에 잘 자라는 잡초들이 번성하게 된다. 일단 대기수영에 우점된 초지는 제초제를 사용하여 제거하고 석회를 시용하여 재발tod을 미연에 방지하는 것이 바람직하다.</p>
          <br/>
        </li>
        <li>토양비옥도
          <ul>
            <li>척박지에 잘 자라는 초지잡초
              <ul>
                <li>애기수영 </li>
                <li>들국화 </li>
                <li>엉겅퀴 </li>
                <li>엉겅퀴 </li>
                <li>개솔새 </li>
                <li>잔디 </li>
                <li>씀바귀 </li>
                <li>솔새 </li>
                <li>새 </li>
                <li>고사리 </li>
                <li>김의털</li>
              </ul>
              <p className="pstyle"><br/>목초는 비료에 대한 요구도가 일반작물보다 상당히 높다. 따라서 척박한 토양에서 비료를 주저 않고 이용도 하지 않는다면 산야초와의 경쟁에서 소멸되고 결국 산야초가 우점되어 저성전의 상태로 돌아가게 된다. 우리나라에서 초지가 잘 안된다는 인식은 잘못된 것이며 그 주요인은 시비량 부족에 따른 척박지 잡초는 대부분 사료가치가 낮고 다년생 심근성으로 일단 우점되었다 하면 제거가 어렵다. 뿌리까지 죽일 수 있는 이행성 제초제로 제거하고 보파하여 갱신한다음 충분한 기비와 매년 적정량의 비배관리를 해야만 생산성이 높은 초지를 유지할 수 있다.<br/><br/>우리나라의 산지토양은 일반적으로 척박하고 산성이므로 초지를 관리 이용하지 않고 방치에 두면 상기에 열거한 잡초 및 산야초 이외에도 다음과 같은 잡관목들이 재생하게 된다.</p><br/>							
            </li>
            <li>척박한 산성토양의 잡관목류
              <ul>
                <li>갈참나무 </li>
                <li>산철죽 </li>
                <li>진달래 </li>
                <li>청머래덩굴</li>
              </ul>
              <p>반대로 비옥한 토양 또는 시비량 특히 질소질비료를 충분히 시용한 초지에서 이용을 적절히 하지 못하면 다음과 같은 잡초들이 바생하게 된다.</p><br/>
            </li>
            <li>비옥한 토양에 발생하는 잡초
              <ul>
                <li>까마중 </li>
                <li>소리쟁이 </li>
                <li>참소리쟁이 </li>
                <li>갈퀴덩쿨 </li>
                <li>방가지똥속 </li>
                <li>망초 </li>
                <li>냉이 </li>
                <li>비름 </li>
                <li>명아주 </li>
                <li>민들레 </li>
                <li>전호 </li>
                <li>별꽃 </li>
                <li>쇠비름 </li>
                <li>여뀌 </li>
                <li>피 </li>
                <li>개쑥갓 </li>
                <li>질경이 </li>
                <li>등대풀 </li>
                <li>광대나물</li>
              </ul>
              <p className="pstyle"><br/>비옥한 토양에 발생하는 잡초들은 대부분 흡비력이 강하며 특히질소비료에 대한 반응이 크다. 이러한 특성은 우리나라 초지의 주초종을 이루고 있는 오차드그라스 억시 동일하므로 이러한 잡초들이 목초와 혼성된 초지에서의 잡초대책은 이용시기, 이용높이 및 이용빈도 내지는 이용방법을 적절히 조절함으로써 억제해 나가는 것이 바람직하다. 목초는 대부분 잡초보다 재생역이 강하므로 이러한 목초의특징을 이용하여 예취나 방목을 자주 해주는 방법도 잡초억제에 효과적이다. 또한 여름철 고온도에는 소위하고현상으로 목초의 생육이 부진한 반면 잡초가 번성하게 된다. 이때는 가급적 예취높이를 높세하면 호광성 또는 광발아성 잡초의 발생과 생육을 억제하는 효과가 있다.</p>
            </li>							
          </ul>
        </li>
        <li>토양수분
          <ul>
            <li>건조한 토양에 발생하는 잡초
              <ul>
                <li>애기수영 </li>
                <li>애기장대 </li>
                <li>들국화 </li>
                <li>벼룩이자리 </li>
                <li>엉겅퀴 </li>
                <li>개망초 </li>
                <li>쇠비름 등</li>
              </ul>
              <p className="pstyle"><br/>척박하고 건조한 토양은 목초의 생육이 극히 불량하고 특히 하고현상이 심하게 나타난다 건조기 대책으로서는 관수가 가장 효과적이라 하겠으나 실제면에서 초지관수는 어려운 점이 많다. 따라서 남향경사지의 가뭄피해가 우려되는 곳에서는 초지조성시에 극단적으로 기존임목을 제거할 것이 아니라 도처에 소나무등 그늘을 만들어 줄수 있는 나무들을 남겨두는 것도 하나의 방법이라 하겠다.</p>
            
            </li>
            <li>습한 토양에 발생하는 잡초
              <ul>
                <li>개구리자리 </li>
                <li>기는미나리아재비 </li>
                <li>골풀 </li>
                <li>별꽃 </li>
                <li>큰별꽃 </li>
                <li>물여뀌 </li>
                <li>새포아풀 </li>
                <li>둑새풀 </li>
                <li>황새냉이 </li>
                <li>개갓냉이 </li>
                <li>방동산이 등</li>
              </ul>
              <p><br/>습하고 배수가 불량한 토양에서는 특히 목초의 주초종인 오차드그라스가 견디지 못하고 소멸된다. 이러한 초지는 아무리 매년 갱신보파를 한다해도 결국 1년도 목되어 다시 잡초화되고 만다. 따라서 보파해서 갱신하는 것이 최선의 방법이다.</p><br/>						
            </li>
          </ul>
        </li>
        <li>토양의 경도
          <ul>
            <li>굳은 토양의 잡초
              <ul>
                <li>질경이 </li>
                <li>골풀 </li>
                <li>엉겅퀴등</li>
              </ul>
              <p className="pstyle"><br/>&nbsp;&nbsp;&nbsp;굳은 토양데서는 목초의 뿌리발육이 나쁘다. 원칙적으로 초지의 갱신에는 겉뿌림 방법이 바람직하지만 토양이 너무 굳을 경우에는 경운갱신으로 토양의 물리성을 개량해 주는 것이 좋다.<br/>&nbsp;&nbsp;&nbsp;경운하고 조성한 초지는 성겨서 가뭄의 피해를 더 받게 되며 특히 겨울에 서릿발에 의한 피해로 목초의 고사율이 높아진다. 그러므로 경운조성한 초지는 반드시 진압해 주어야 한다. </p>						
            </li>
          </ul>
        </li>
      </ul>
      <h2>초지의 관리비용 실태와 잡초문제</h2>
      <ul>
        <li>초지의 관리실태
          <p className="pstyle">현재 우리나라에서 재배하고 있는 목초의 초종들은 대부분이 해양성 기후대인 유럽이 원산지로서 우리나라의 극단적인 대륙성기후조건하에서 이들을 가꾸기란 기술적으로 쉬운 일이 아니다. 초지를 성공적으로 유지하려면 오히려 밭농사나 논농사 이상으로 노력과 정성을 들여야 됨에도 불구하고 초지관리를 소홀히 하는 농가가 이외로 상당한 비율을 차지하고 있다.<br/><br/>농촌진흥청 축산시험장에서 1981∼1984년도에 걸쳐 4년간 전국적으로 68개소를 무작위 추출하여 초지관리 실태조사를 실시한 결과를 간추려 보면 다음과 같다.</p>
          <ul>
            <li>시비량이 부족하다.
              <p className="pstyle">우리나라 일반초지의 년간 단보당 비료 추진량은 질소-인산-칼리 28-20-24kg인데 반하여 농가의 실제시비량은 평균 9.7∼7.2∼6.4kg에 불과하다. 이렇게 비료를 적게 주면 초지가 제대로 될 리가 없고 척박지 잡초가 발생하여 부실화 되는게 당연하다. 더구나 인산, 칼리는 안주고 질소비료만 약간 주는 농가가 전체농가의20.7%나 되고 심지어는 전혀 비료를 주지 않는 농가도 11%나 된다.</p><br/>
              <p><b>&lt;표 8&gt; 실제농가의 초지비료시용 현황</b></p>
              <table className="tablestyle">
                <tr>
                  <td rowSpan="2">조성년차</td>
                  <td colSpan="3">비료</td>
                </tr>
                <tr style={{background: '#273143',color:'#fff'}}>
                  <td>N</td>
                  <td>P2O5</td>
                  <td>K2O</td>
                </tr>
                <tr>
                  <td>1년차</td>
                  <td>10.6</td>
                  <td>8.8</td>
                  <td>6.7</td>
                </tr>
                <tr>
                  <td>5년차</td>
                  <td>7.8</td>
                  <td>3.1</td>
                  <td>3.3</td>
                </tr>
                <tr>
                  <td>10년차</td>
                  <td>10.8</td>
                  <td>9.7</td>
                  <td>9.1</td>
                </tr>
                <tr>
                  <td>평균</td>
                  <td>9.7</td>
                  <td>7.2</td>
                  <td>6.4</td>
                </tr>
                <tr>
                  <td>시비추천량</td>
                  <td>28.0</td>
                  <td>20.0</td>
                  <td>24.0</td>
                </tr>
              </table><br/>
              <p><b>&lt;표 9&gt; 초지비료시용 농가의 분포</b></p>
              <table className="tablestyle">
                <tr>
                  <td rowSpan="2">조성년차</td>
                  <td colSpan="4">비료</td>
                  <td rowSpan="2">계</td>
                </tr>
                <tr style={{background: '#273143',color:'#fff'}}>
                  <td>N-P2O5-K2O</td>
                  <td>질소단용</td>
                  <td>무비료</td>
                  <td>기타</td>
                </tr>
                <tr>
                  <td>1년차</td>
                  <td>73.5</td>
                  <td>14.7</td>
                  <td>5.9</td>
                  <td>5.9</td>
                  <td>100</td>
                </tr>
                <tr>
                  <td>5년차</td>
                  <td>59.6</td>
                  <td>26.5</td>
                  <td>11.8</td>
                  <td>2.2</td>
                  <td>100</td>
                </tr>
                <tr>
                  <td>10년차</td>
                  <td>63.2</td>
                  <td>21.0</td>
                  <td>15.8</td>
                  <td>-</td>
                  <td>100</td>
                </tr>
                <tr>
                  <td>평균</td>
                  <td>65.4</td>
                  <td>20.7</td>
                  <td>11.2</td>
                  <td>2.7</td>
                  <td>100</td>
                </tr>
              </table>
            </li>
            <li>잡초방지에 소홀하다.
              <p>목초의 원산지인 유럽에서도 초지의 잡초는 다양하고 양적인면에서도 상당한 비중을 차지하고 있다. 하물며 원산지와 기후풍토가 다른 우리나라에서 초지에 잡초가 나는 것은 당연하다 하겠다. 그러나 초지의 잡초는 적정한 관리와 이용으로 제한된 수준에서억제해야 함에도 불구하고 아무런 대책없이 방치하는 농가가 대부분이다.<br/><br/>방목후의 불식초를 베어주는 청소베기를 안하는 농가가 전체 농가의 49.3%에 달하고 있다.</p>
              <br/><p><b>&lt;표 10&gt; 초지잡초방제 농가비율</b></p>
              <table className="tablestyle">
                <tr>
                  <td>조성년차</td>
                  <td>잡초무발생</td>
                  <td>무방제</td>
                  <td>방제</td>
                  <td>합계</td>
                </tr>
                <tr>
                  <td>1년</td>
                  <td>46.3</td>
                  <td>43.3</td>
                  <td>10.4</td>
                  <td>100</td>
                </tr>
                <tr>
                  <td>5년</td>
                  <td>25.5</td>
                  <td>55.2</td>
                  <td>19.5</td>
                  <td>100</td>
                </tr>
                <tr>
                  <td>평균</td>
                  <td>35.9</td>
                  <td>49.2</td>
                  <td>14.9</td>
                  <td>100</td>
                </tr>
              </table>
              <br/><p><b>&lt;표 11&gt; 청소베기 실시농가비율</b></p>
              <table className="tablestyle">
                <tr>
                  <td rowSpan="2">조성년차</td>
                  <td rowSpan="2">무실시</td>
                  <td colSpan="2">실시</td>
                  <td rowSpan="2">계</td>
                </tr>
                <tr style={{background: '#273143',color:'#fff'}}>
                  <td>필요시</td>
                  <td>자주실시</td>
                </tr>
                <tr>
                  <td>1년</td>
                  <td>51.7</td>
                  <td>27.6</td>
                  <td>20.7</td>
                  <td>100</td>
                </tr>
                <tr>
                  <td>5년</td>
                  <td>46.9</td>
                  <td>18.8</td>
                  <td>34.4</td>
                  <td>100</td>
                </tr>
                <tr>
                  <td>평균</td>
                  <td>49.3</td>
                  <td>23.2</td>
                  <td>27.5</td>
                  <td>100</td>
                </tr>
              </table>
              <br/><p><b>&lt;표 12&gt; 초지년간 이용회수별 농가분포</b></p>
              <table className="tablestyle">
                <tr>
                  <td rowSpan="2">조성년차</td>
                  <td colSpan="6">년간이용회수</td>
                  <td rowSpan="2">계</td>
                </tr>
                <tr style={{background: '#273143',color:'#fff'}}>
                  <td>2회이상</td>
                  <td>3</td>
                  <td>4</td>
                  <td>5</td>
                  <td>6</td>
                  <td>7회이상</td>
                </tr>
                <tr>
                  <td>1년</td>
                  <td>16.4</td>
                  <td>31.3</td>
                  <td>32.8</td>
                  <td>14.9</td>
                  <td>4.6</td>
                  <td>-</td>
                  <td>100</td>
                </tr>
                <tr>
                  <td>5년차</td>
                  <td>12.2</td>
                  <td>29.1</td>
                  <td>32.4</td>
                  <td>12.8</td>
                  <td>7.4</td>
                  <td>6.1</td>
                  <td>100</td>
                </tr>
                <tr>
                  <td>10년차</td>
                  <td>25.8</td>
                  <td>29.0</td>
                  <td>25.8</td>
                  <td>16.1</td>
                  <td>3.3</td>
                  <td>-</td>
                  <td>100</td>
                </tr>
                <tr>
                  <td>평균</td>
                  <td>18.1</td>
                  <td>29.8</td>
                  <td>30.4</td>
                  <td>14.6</td>
                  <td>5.1</td>
                  <td>2.0</td>
                  <td>100</td>
                </tr>
              </table><br/>
            </li>
          </ul>
        </li>
        <li>초지의 이용실태
          <p>초지를 유지하려면 반드시 예취나 방목등으로 이용해야 한다.<br/><br/>따라서 초지를 이용한다는 것은 바로 초지를 관리한다는 것과 같은의미를 가지고 있다.</p>
          <ul>
            <li>년간이용회수가 적다
              <ul>
                <li>초지는 최소한 년간 3회이상 이용해 주어야 제대로 목초의 식생이 유지된다. 반면에 7회이상 너무자주 이용해도 초지가 망가지게 된다. 초지를 년간 3회이상밖에 이용하지 않는 농가가 전체 농가의 48%에 달하고 있다. 초지의 이용회수가 낮으면 목초의 분얼이 제한되고 결주가 많이 생겨 빈 땅의 비율이 높아지고 따라서 잡초의 침입이 많게 된다. 또한 재생력이 목초보다 약한 잡초들의 생육과 특히 종자번식이 순조옵게 되어 초지가 부실화되기 쉼다. 반면에 너무 과도한 이용은 상번초인 화본과 주초종의 소멸과 하번초인 라디노 클로버의 우점을 촉진하고 단초형 악성 잡초 및 독초발생을 유발할 우려가 있다.</li>
              </ul>
            </li>
            <li>적기이용이 안되고 있다
              <ul>
                <li>초지를 성공적으로 유지하려면 적기에 이용하여야 하며 특히 첫 번째 이용시기는 개화기 이후까지 늦어지면 늦어질수록 목초의 품질도 나빠질뿐 아니라 이용후 재생도 나빠져 초지에 빈 땅이 많이 생기게 되고 따라서 잡초가 침입하는데 주원인이 된다. 실제 5월후반 이후에 초지를 이용하는 농가가 전체농가의 30%를 점하고 있어 초지가 부실화되는 사례가 많다.</li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
      <h2>잡초 억제를 위한 초지관리이용</h2>
      <ul>
        <li>충분한 시비와 석회시용
          <ul>
            <li>초지의 적정 식생구성 및 높은 생산성 유지를 위해서는 반드시 적절한 야의 비료를 주어야 한다. 또한 석회는 비록 조성 당시에 충분히 뿌렸다고 하더라도 3년 정도만 경과하면 효과가 다시떨어지므로 가급적 3년마다 토양검정을 실시하여 석회소요량을 시용해 주어야 한다. 특히 애기수영이나 쑥등 집단적으로 발생하여 초지를 망가뜨리는 잡초는 산성토양에서 잘 번성하므로 석회시용은 이들 악성잡초의 예방을 위해서도 중요하다.</li>
          </ul>
        </li>
        <li>지나친 방목은 피한다
          <ul>
            <li>초지가 망가지는 가장 큰원인중의 하나는 좁은 면적에 너무많은 가축을 넣어 지나치게 방목시키는데 있다. 뜯어 먹을 것도 어이상 없는데도 가축을 오래도록 방목지에 머물러 두면 발굽에 의한 피해만 더욱 터지고 목초의 재생도 극히 불량해질뿐 아니라 빈 땅이 생겨 잡초 침입이 주원인이 된다. 방목시에 가축이 초지에 머물러 있는 시간은 될 수로 가짧게 하고 충분한 재생기간을 두는 것이 좋다.</li>
          </ul>
        </li>
        <li>여름철의 너무 낮은 예취는 해롭다
          <ul>
            <li>무더움 여름철에 목초를 너무 낮게 예취하면 지온이 상승하고 수분증발이 많아져서 가뭄을 더 타게 되므로 목초의 우점현상이 더욱 심하게 나타난다. 또한 대부분의 잡초는 광발아성이며 고온조건에서 생장이 앙성하므로 잡초의 발생이 더욱 많아지기 쉽다. 따라서 여름철 고온기에는 초기를 10cm정도로 높이 예취하여 주는 것이 좋다. 반면에 목초를 예취하지 않으면 고온다습에 의하여 목초의 밑부분이 통기불량으로 썩기 쉬우므로 적정한 높이로 초지를 유지하는 것이 중요하다.</li>
          </ul>
        </li>
        <li>계속적인 보파가 필요하다
          <ul>
            <li>초지의 적정 식생구성과 높은 생산성을 오래 유지하기 위하여는 매년 목초종자를 보파해 주는 것이 중요하다. 초지에 빈 땅이 생겼을 경우 잡초 대신 목초종자가 항상 초지토양에 있어야 잡초의 침입을 경멸하고 초지를 유지할 수 있는 것이다. </li>
          </ul>
        </li>
      </ul>
    </div>
  );
}

export default Do176;