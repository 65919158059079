import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useNavigate, Link } from 'react-router-dom';
// api
import { getState } from 'utils/api';

// components
import Pagination from 'components/bo/Pagination';

function MstCodeList() {
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useState({
    search_type: 'm_code',
    search_keyword: '',
    page_no: 1,
  });

  const { data, isLoading, isError, error } = useQuery({
    queryKey: ['mstCodeList', searchParams],
    queryFn: () => getState('/board/select_master_code_list', searchParams),
  });

  const mstCodeList = data?.result?.list || [];
  const totalCount = data?.result?.total_count || 0;
  const totalPages = data?.result?.page_count || 0;

  const handlePageChange = (pageNo) => {
    setSearchParams((prev) => ({ ...prev, page_no: pageNo }));
  };

  const handleGroupEdit = () => {
    navigate('/bo/code/mstCodeEdit');
  };

  return (
    <div className="bo-page">
      <h2>주코드 목록</h2>
      <div className="searchbox">
        <select
          value={searchParams.search_type}
          onChange={(e) =>
            setSearchParams({
              ...searchParams,
              search_type: e.target.value,
            })
          }
        >
          <option value="m_code">주 코드번호</option>
          <option value="m_name">주 코드이름</option>
        </select>
        <input
          type="text"
          value={searchParams.search_keyword}
          onChange={(e) =>
            setSearchParams({
              ...searchParams,
              search_keyword: e.target.value,
            })
          }
        />
        <button type="button" className="btn-search">
          검색
        </button>
      </div>

      <div className="board-top">
        <p>
          총 자료수: <strong>{totalCount}</strong> 개, 페이지{' '}
          {searchParams.page_no}/{totalPages}
        </p>
      </div>

      <div className="table-container">
        <table>
          <colgroup>
            <col width="5%" />
            <col width="15%" />
            <col width="15%" />
            <col width="*" />
          </colgroup>
          <thead>
            <tr>
              <th>번호</th>
              <th>주 코드번호</th>
              <th>주 코드이름</th>
              <th>주 코드설명</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td colSpan="4" className="loading-data">
                  데이터를 불러오는 중입니다...
                </td>
              </tr>
            ) : isError ? (
              <tr>
                <td colSpan="4" className="error-data">
                  에러가 발생했습니다: {error?.message}
                </td>
              </tr>
            ) : mstCodeList.length === 0 ? (
              <tr>
                <td colSpan="4" className="no-data">
                  게시글이 없습니다.
                </td>
              </tr>
            ) : (
              mstCodeList.map((mstList, index) => (
                <tr key={mstList?.m_code}>
                  <td className="center">{mstList?.row_num}</td>
                  <td className="center">{mstList?.m_code}</td>
                  <td className="center">
                    <Link
                      to={`/bo/code/slvCodeList?m_code=${mstList?.m_code}`}
                      className="link-text"
                    >
                      {mstList?.m_name}
                    </Link>
                  </td>
                  <td>{mstList?.m_desc}</td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>

      <div className="pagination">
        <Pagination
          currentPage={searchParams.page_no}
          totalPage={totalPages}
          onPageChange={handlePageChange}
        />
      </div>

      <div className="button-group">
        <button type="button" className="btn-l" onClick={handleGroupEdit}>
          주코드 등록
        </button>
      </div>
    </div>
  );
}

export default MstCodeList;
