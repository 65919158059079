import React from 'react';

import pic663 from 'assets/images/sub/_images/pic663.jpg';
import pic664 from 'assets/images/sub/_images/pic664.jpg';
import pic665 from 'assets/images/sub/_images/pic665.jpg';

function Do131() {

  return (
    <div className="techinfoview">
      <h1>프리마틴</h1>
      <p className="pstyle">성(性)이 다른 다태아(쌍둥이, 세쌍둥이)로 태어난 암송아지 중에 생식기의 발육불량으로 번식능력이 없는 것을 말한다. </p>
      <div className="leftpart">
        <h2>원인</h2>
        <ul>
          <li>이성(異性) 다태아의 경우 암컷과 수컷, 쌍방의 혈액이 태반을 통해 교류됨으로써 수컷의 성 호르몬이 암컷의 생식기 발육을 억제함으로써 생긴다. </li>
          <li>이성 쌍둥이의 암송아지 중 약 10%는 정상적인 생식능력을 갖는다. </li>
        </ul>
        <h2>증상</h2>
        <ul>
          <li>출생직후 프리마틴 송아지는 외견상 정상 암송아지와 별 차이가 없다. 다만 
            <ul>
              <li>일반적으로 외음부가 약간 작고, 음모가 길며, 음핵이 커 눈에 띄기도 한다. </li>
              <li>성(性)성숙기가 지나도 발정이 오지 않는다. </li>
              <li>만 한 살이 지나면 외모와 성격이 수컷과 비슷해 진다. </li>
            </ul>
          </li>
        </ul>
        <h2>목장에서의 처치</h2>
        <ul>
          <li>프리마틴으로 의심이 가면 수의사의 진단을 받아 보도록 한다. </li>
          <li>프리마틴 송아지는 번식이 불가능하므로 젖소로서의 가치가 없다.</li>
        </ul>
      </div>
      <div className="rightpart">
        <img src={pic663} className="nonborder" alt=""/>
        <img src={pic664} className="nonborder" alt=""/>
        <img src={pic665} className="nonborder" alt=""/>
      </div>
    </div>
  );
}

export default Do131;