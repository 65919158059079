import React from 'react';

import Pic531 from 'assets/images/sub/_images/pic531.jpg';
import Pic532 from 'assets/images/sub/_images/pic532.jpg';

function Do96() {

  return (
    <div className="techinfoview">
      <h1>급성 위장염</h1>
      <p className="pstyle">위장염은 젖소의 병 중에 가장 흔히 발생하며, 증상에 따라 급성과 만성으로 구분한다. </p>
      <div className="leftpart">
        <h2>원인</h2>
        <ul>
          <li> 부패사료의 섭취, 농후사료의 과식·얼어 붙은 사료의 섭취 등 </li>
          <li>유독물질과 異物의 섭취:화학약품 농약 등의 중금속 자극성 이물(뼈나 나무 조각, 프라스틱 등)</li>
        </ul>
        <h2>증상</h2>
        <ul>
          <li>갑작스런 식욕 정지와 변비 또는 설사를 보인다. </li>
          <li>반추의 정지 때로는 구토증세를 일으키기도 한다. </li>
          <li>체온이 약간 상승하며 운동을 싫어한다. </li>
          <li>호흡과 맥박이 증가한다.</li>
        </ul>
        <h2>목장에서의 처치</h2>
        <ul>
          <li>사료급여를 개선하고 주변의 유해물을 제거한다. </li>
          <li>건위정장제(예:염산베타인, 중조 등)을 먹인다. </li>
          <li>영양공급과 탈수증 방지를 위해 수액과 영양제를 투여한다. </li>
        </ul>
      </div>
      <div className="rightpart">
        <img src={Pic531} className="nonborder" alt=""/>
        <img src={Pic532} className="nonborder" alt=""/>
      </div>
    </div>
  );
}

export default Do96;