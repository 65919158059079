import React from 'react';
import FieldPicture from 'containers/web/data/fieldPicture/FieldPicture';
import { Helmet } from 'react-helmet-async';

function FieldPicturePage() {
  return (
    <>
      <Helmet>
        <title>현장포토</title>
      </Helmet>
      <FieldPicture />
    </>
  );
}

export default FieldPicturePage;
