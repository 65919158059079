import React, { useState } from 'react';
import { HISTORY } from 'constants/constants';
import renderHistory from 'utils/renderHistory';

// 이미지
import people from 'assets/svgs/containers/business/history/people.svg';
import OxManagement from 'assets/svgs/containers/business/history/OxManagement.svg';
import ability from 'assets/svgs/containers/business/history/ability.svg';
import Ox from 'assets/svgs/containers/business/history/Ox.svg';
import _2015_1 from 'assets/images/containers/business/history/2015_1.png';
import _2000_1 from 'assets/images/containers/business/history/2000_1.png';
import _2000_2 from 'assets/images/containers/business/history/2000_2.png';
import _2000_3 from 'assets/images/containers/business/history/2000_3.png';
import _1980_1 from 'assets/images/containers/business/history/1980_1.png';
import _1960_1 from 'assets/images/containers/business/history/1960_1.png';

// 컴포넌트
import SubTitle from 'components/subTitle/SubTitle';

// scss
import './history.scss';

function History() {
  // 히스토리 탭 메뉴
  const [historyCategory, setHistoryCategory] = useState('현재~2015');
  const renderHistoryTab = () =>
    Object.keys(HISTORY).map((key) => (
      <li key={key}>
        <button
          type="button"
          onClick={() => setHistoryCategory(key)}
          className={historyCategory === key ? 'current' : ''}
        >
          {key}
        </button>
      </li>
    ));

  return (
    <div className="generalHistory subCon">
      <SubTitle />
      <dl className="generalTitle">
        <dt>
          출발은 늦었습니다.
          <br />
          그러나 개량속도는 <span>어느 낙농선진국보다 앞서고 있습니다.</span>
        </dt>
        <dd>
          출발은 늦었습니다. 그러나 개량속도는 어느 낙농선진국 보다도 앞서고
          있습니다. 우리나라 젖소개량의 역사는 낙농선진국에 비해 짧습니다.
          하지만 우리 젖소개량사업소에는 열정이 넘치는 정예인력과 개량성과가
          있습니다. 유우군능력검정 사업 착수 후 305일 유량이 10,205Kg을 넘어서
          두배 이상 증가, 검정참여 두수 140천두로 사업물량 확대로 유구한 역사의
          낙농선진국들을 긴장시키고 있습니다. 이제 국내 젖소개량기술과
          유전자원을 세계로 수출하는 새로운 도전을 해봅니다.
        </dd>
      </dl>
      <ul className="generalStatus">
        <li>
          <img src={people} alt="인원 이미지" />
          <dl>
            <dt>인원</dt>
            <dd>33명</dd>
          </dl>
        </li>
        <li>
          <img src={OxManagement} alt="씨수소관리 이미지" />
          <dl>
            <dt>씨수소관리</dt>
            <dd>256두 보증씨수소 30, 후보씨수소 256</dd>
          </dl>
        </li>
        <li>
          <img src={ability} alt="인원 이미지" />
          <dl>
            <dt>젖소능력검정사업</dt>
            <dd>
              2856호, 240, 766두, 29개 검정조합
              <span>-검정두수는 &#39;17년도&#39;부터 소이력제 자료를 활용</span>
            </dd>
          </dl>
        </li>
        <li>
          <img src={Ox} alt="인원 이미지" />
          <dl>
            <dt>한국형 보증씨수소</dt>
            <dd>67두</dd>
          </dl>
        </li>
      </ul>
      <div className="history">
        <p className="historyTitle">연혁</p>
        <ul className="historyTab">{renderHistoryTab && renderHistoryTab()}</ul>
        {historyCategory === '현재~2015' && (
          <>
            {renderHistory('현재~2015')}
            <div className="imgBox">
              <img src={_2015_1} alt="한국 젖소정액 에티오피아 추출" />
            </div>
          </>
        )}
        {historyCategory === '2014~2010' && renderHistory('2014~2010')}
        {historyCategory === '2009~2000' && (
          <>
            {renderHistory('2009~2000')}
            <ul className="imgBox">
              <li>
                <img src={_2000_1} alt="국립수산과학원" />
                <span>&#40;국립수산과학원&#41;</span>
              </li>
              <li>
                <img src={_2000_2} alt="생명공학연구소" />
                <span>&#40;생명공학연구소&#41;</span>
              </li>
              <li>
                <img src={_2000_3} alt="검정성적 휴대폰 서비스 개시" />
              </li>
            </ul>
          </>
        )}
        {historyCategory === '1999~1980' && (
          <>
            {renderHistory('1999~1980')}
            <div className="imgBox">
              <img src={_1980_1} alt="농장사진" />
            </div>
          </>
        )}
        {historyCategory === '1979~1960' && (
          <>
            {renderHistory('1979~1960')}
            <div className="imgBox">
              <img src={_1960_1} alt="농원 풍경" />
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default History;
