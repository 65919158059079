import React from 'react';

import d1by45 from 'assets/images/sub/_images/1-45.jpg';
import d1by47 from 'assets/images/sub/_images/1-47.jpg';
import d1by48 from 'assets/images/sub/_images/1-48.jpg';
import d1by51 from 'assets/images/sub/_images/1-51.jpg';

function Fo22() {

  return (
    <div className="techinfo_view">
				<p className="viewTitle">육성 중기의 관리</p>
				<h2>온화한 소로 양육한다.</h2>
				<ul className="listStyle04 mg15f">
					<li>개방형 사양관리가 증가하고, 소의 몸에 접촉하는 기회가 적어지고 있다. 소에게 접촉할 기회를 많이 하여 사람에게 익숙해진 소를 기르자.</li>
					<li>패독(Paddock: 마구깐에 딸린 작은 방목장)과 사육우리에 여러번 넣어 사람에게 익숙하게 한다.</li>
					<li>[ 목책 ]을 활용하여 포획을 용이하게 한다. [목책]은 월령에 맞게 느슨하게 사용할 것. 월령이 나아가면 얼굴도 켜져 목책이 얼굴에 죄어들어 화농(化膿)되거나 개구를 제한하여 채식을 억제하는 것도 있다.</li>
				</ul>
				<div className="imgBox mg60f"><img src={d1by45} alt=""/></div>
				
				<h2>대군(大群)으로서 사회 행동을 배우게 한다.!</h2>
				<ul className="listStyle04 mg15f">
					<li>10두 정도의 큰 무리로 해서 우(牛) 사회에서의 사는 방법을 배우게 한다.</li>
					<li>육성목장에 예탁할 때는 길들여 방목을 한다.</li>
				</ul>
				<div className="imgBox mg60f"><img src={d1by47} alt=""/></div>
				
				<h2>운동으로 사지(四肢)의 강화를!</h2>
				<ul className="listStyle04 mg15f">
					<li>육성 목장을 활용한다.</li>
					<li>사조(飼槽), 수조(水槽), 침상의 위치를 떼어놓아 강제적으로 운동시킨다.</li>
					<li>경사지(傾斜地)의 활용.</li>
					<li>충분한 넓이의 패독(Paddock)을 준비한다.</li>
					<li>질척거리는 패독(Paddock)은 배수를 잘 개선한다. </li>
				</ul>
				<div className="imgBox mg60f"><img src={d1by48} alt=""/></div>
				
				<h2>남은 물건(오물)의 대책</h2>
				<ul className="listStyle04 mg15f">
					<li>연동 Stanchion(스타촌)을 사용하여 농후사료의 먹다 말은것(찌꺼기)를 없게 한다.</li>
					<li>충분한 공간의 사조를 준비한다.</li>
					<li>충분한 양의 사료를 급여한다.</li>
				</ul>
				<div className="imgBox mg60f"><img src={d1by51} alt=""/></div>
			</div>
  );
}

export default Fo22;
