import React, { useState } from 'react';

// 컴포넌트
import SubTitle from 'components/subTitle/SubTitle';
import Ox1 from './Ox1';
import Ox2 from './Ox2';
import Ox3 from './Ox3';

// scss
import './oxData.scss';

function OxData() {

  const [ oxTab , setOxTab ] = useState('one');

  return (
    <div className="oxData subCon">
      <SubTitle />
      <ul className='oxDataTab'>
        <li>  
          <button 
            type='button'
            onClick={()=> setOxTab('one')}
            className={oxTab === 'one' ? 'current' : ''}  
          >한국형 젖소보증씨수소</button>
        </li>
        <li>  
          <button 
            type='button'
            onClick={()=> setOxTab('two')}
            className={oxTab === 'two' ? 'current' : ''}
          >씨수소 안내책자</button>
        </li>
        <li>  
          <button
            type='button'
            onClick={()=> setOxTab('three')}
            className={oxTab === 'three' ? 'current' : ''}
          >개량젖소</button>
        </li>
      </ul>
      { oxTab === 'one' && <Ox1 />}
      { oxTab === 'two' && <Ox2 />}
      { oxTab === 'three' && <Ox3 />}
    </div>
  );
}

export default OxData;
