import React from 'react';
import Gene from 'containers/gene/Gene';

function GenePage() {
  return (
    <Gene />
  );
}

export default GenePage;
