import React, { useState , useEffect } from 'react';

// api
import { postState } from 'utils/api';
import { openCrownix3 , openCrownix } from 'utils/openCrownix';

// 컴포넌트
import ErrorMessage from 'components/errorMessage/errorMessage';

// 이미지
import PrintPDF from 'assets/svgs/common/print_PDF.svg';
import Logo from 'assets/images/common/header_logo_pc.png';

// scss
import './NewCowBun.scss';

function NewCowBun() {

  // 에러처리
  const [isModalOpen, setIsModalOpen] = useState(false); // 모달 추가
  const [errorMessage, setErrorMessage] = useState(''); 

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => {
    setIsModalOpen(false);
    setErrorMessage(''); // 모달이 닫힐 때 에러메세지 초기화
  };

  // 데이터 불러오기
  const baseDir='/dcitest/dcifarm/newcowbun/';
  const [ mainData , setMainData ] = useState();

  const [ props , setProps] = useState({
    id:`${localStorage.getItem('cong')}${localStorage.getItem('farm')}`,
    passwd:localStorage.getItem('passwd'),
    date:localStorage.getItem('month')
  });

  useEffect(() => {

    postState(baseDir,props)
    .then(getdata => {      
      if (getdata !== undefined) {
    //   console.log ('#### props : ' ,props);
      setMainData(getdata);
    //   console.log ('#### data : ' ,getdata);
      }
    })
    .catch(error => {           
      setErrorMessage(error.message);
      openModal();
    });
    
  }, [props]);
  
  return (
    <div className='pdfMainArea'>
      <ErrorMessage isOpen={isModalOpen} content={errorMessage} closeModal={closeModal}/>
      <div className='pdfMainHeader'>
        <button 
          type='button'
          onClick={()=> openCrownix('New_cow_bun.mrd')}
        >
          <img src={PrintPDF} alt='PDF로 인쇄하기' />
          인쇄하기
        </button>
      </div>
      <div className='pdfArea'>
        <div className='newCowBunPdf'>
          <h1>번식성적 분석보기</h1>
          <ul className='newCowBunInfo'>
            <li>
            농가명 : {mainData && mainData.farm_property[0].fname}
            </li>
            <li>
              축주명 : {mainData && mainData.farm_property[0].fdname}
            </li>
            <li>
              주소 : {mainData && mainData.farm_property[0].fadd}
            </li>
          </ul>
          <table className='pdfTable'>
            <thead>
              <tr>
                <th>단축명호</th>
                <th>산차</th>
                <th>최근수정일</th>
                <th>정액코드</th>
                <th>공태일수</th>
                <th>첫 수정일</th>
                <th>수정횟수</th>
                <th>발정예정</th>
                <th>건유/분만예정</th>
                <th>최근분만일</th>
                <th>조산월령</th>
                <th>분만구분</th>
                <th>건유일수</th>
                <th>분만간격</th>
              </tr>
            </thead>
            <tbody>
              {
                mainData &&
                mainData.plot_data.map((item, index) => (
                  <tr key={index}>
                    <td>
                      <button
                        type='button'
                        onClick={() => openCrownix3('New_sang_jcode.mrd' , item.jcode)}
                      >
                        {item.sname}
                      </button>
                    </td>
                    <td>{item.pari}</td>
                    <td>{item.lisdate}</td>
                    <td>{item.lsemencode}</td>
                    <td>{item.gongdno}</td>
                    <td>{item.fin}</td>
                    <td>{item.linsno}</td>
                    <td>{item.baldate}</td>
                    <td>{item.gundate}</td>
                    <td>{item.rbundate}</td>
                    <td>{item.chosan}</td>
                    <td>{item.rbungu}</td>
                    <td>{item.gundno}</td>
                    <td>{item.tintday}</td>
                  </tr>
                ))
              }
            </tbody>
          </table>
          <img src={Logo} alt='농협경제지주 젖소개량사업소 로고' className='pdfLogo'/>
        </div>
      </div>
    </div> 
  );
}

export default NewCowBun;
