import React from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useQuery, useQueryClient } from '@tanstack/react-query';

// api
import { getState, postState } from 'utils/api';

function BoardView({ boardName, boardUrl }) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const bdcCode = searchParams.get('bdc_code');
  const queryClient = useQueryClient();

  const { data } = useQuery({
    queryKey: [boardName, bdcCode],
    queryFn: () =>
      getState('/board/select_cow_test_info', { bdc_code: bdcCode }),
    enabled: !!bdcCode,
  });

  const boardData = data?.result;

  console.log(boardData);

  const handleDelete = async () => {
    if (window.confirm('삭제 하시겠습니까?')) {
      try {
        const response = await postState('/board/delete_cow_test_info/', {
          bdc_code: bdcCode,
        });
        if (response?.return_code === 'SUCCESS') {
          queryClient.invalidateQueries([boardName]);
          alert('삭제되었습니다.');
          navigate(`/bo/board/${boardUrl}/list`);
        }
        if (response?.return_code === 'ERROR') {
          alert(response?.return_message);
        }
      } catch (error) {
        console.error('Delete board error:', error);
        alert('삭제 중 오류가 발생했습니다.');
      }
    }
  };

  return (
    <div className="cow-test-view bo-content">
      <h2>{boardName} 간행물 정보</h2>
      <div className="table-container bold-border">
        <table>
          <tbody>
            <tr>
              <th width="15%">간행물명</th>
              <td width="35%">{boardData?.bdc_title}</td>
              <th width="15%">년도</th>
              <td width="35%">{boardData?.bdc_wname}</td>
            </tr>
            <tr>
              <th>등록일</th>
              <td>{boardData?.bdc_rdt}</td>
              <th>자료 조회수</th>
              <td>{boardData?.bdc_hit}</td>
            </tr>
            <tr>
              <th>대체 URL</th>
              <td colSpan="3">{boardData?.bdc_body}</td>
            </tr>
            <tr>
              <th>간행물 PDF</th>
              <td colSpan="3">
                {boardData?.bdf_fname && (
                  <a
                    href={`${process.env.REACT_APP_STATICS_URL}/_uploadFiles/_data/${boardData?.bdf_fname}`}
                    className="attach"
                    download
                  >
                    {boardData.bdf_fname}
                  </a>
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="button-group">
        <Link
          to={`/bo/board/${boardUrl}/edit?bdc_code=${bdcCode}`}
          className="btn-l"
        >
          {bdcCode ? '수정' : '등록'}
        </Link>
        <button type="button" onClick={handleDelete} className="btn-l">
          삭제
        </button>
        <button
          type="button"
          onClick={() => navigate(`/bo/board/${boardUrl}/list`)}
          className="btn-l"
        >
          목록
        </button>
      </div>
    </div>
  );
}

export default BoardView;
