import React from 'react';

import Pic219 from 'assets/images/sub/_images/pic219.png';

function Do53() {

  return (
    <div className="techinfoview">
				<h1>분만관리</h1>
				<p>분만이란 태아가 모체의 자궁내에서 평균 280일 동안 자란후 자궁경관→질→외음부를 거쳐 밖으로 만출되는 현상을 말한다. </p>
				<h2>분만예정일 계산 </h2>
				<p>분만이 완전하고 순조롭게 되기 위해서는 미리 분만예정일을 알 필요가 있다. 그리하여 분만에정일이 가까워지면 분만에 대한 준비를 해야 한다. 분만예정일은 보통 종무한 월에서 3을 빼거나 9를 더해서 예정월을 계산하고 종부일에 6을 더해서 분만일을 계산한다. <br/><br/>예 1) 5월 8일에 수정 했을 때 <br/>5-3=2, 8+6=14<br/>즉 다음해 2월 14일이 분만예정일이 된다. <br/><br/>예 2) 2월 15일에 수정했을 때 (2에서 3을 뺄수 없으므로 9를 더해야 한다) <br/>2+9=11, 15+6=21 <br/>즉 그해 11월 21일이 분만예정일이 된다. <br/><br/>또한 &lt;표 43&gt;에서 보는 바와 같이 분만예정일 조견표를 이용하면 더 편리하다. </p> <br/>
				<h2>분만징후 </h2>
				<p className="pstyle">분만이 가까워진 어미소는 식욕이 감퇴되고 신경이 예민해지며 불안해하고,똥이 연해지고 오줌을 자주 눈다. 분만 일주일 전부터 유방이 팽창하기 시작하고 충혈되어 붉은 빛을 띠게 되며, 유두가 팽팽해지며 잔주름이 없어진다. 또한 외음부가 충혈되고 점차 부어올라 임신하지 않는 소의 음부보다 2-3배 커지며 황색 점액이 흘러나온다. 이러한 점액이 배출된다는 것은 분만의 사전준비단계로서 자궁경관이 점차 확장되고 있음을 의미 한다. 이 점액은 분만이 가까워짐에 따라 점차 반투명한 잠액으로 변한다. 임산 막바지에 들어서면 미근부 양쪽의 인대가 이완되면서 푹 꺼지고 하복부는 밑으로 처진다. </p><br/>
				<p><b>&lt;표 43&gt;분만예정일 조건표</b></p>
				<img src={Pic219} className="nonborder" alt=""/>
				<h2>분만준비와 분만시 관리 </h2>
				<ul>
					<li>분만실 준비 
						<ul>
							<li>분만 7-10일 전에 어미소를 소독된 분만실로 옮겨서 관리한다. 이는 임신한 소와 태어날 송아지를 다른 소로부터 보호하여 스트레스를 줄여주기 위한 조치다. </li>
							<li>분만실의 면적은 어미소의 행동범위, 조산이나 난산 때 수의사와 조수가 자유롭게 행동할수 있는 공간 등을 고려하여 4.5-5.5평이 되도록 충분한 공간을 만들어 주며 건조하고 깨끗하며 환기가 잘되도록 한다. </li>
							<li>깔짚을 두껍게 깔아주어서 무릎이나 유방 또는 유두에 상처가 나는 것을 예방한다. 깔짚은 항상 신선하고 깨끗한 것을 깔아 준다. 부패된 깔짚을 깔아주면 어미소의 유방염발생, 자궁감염과 송아지의 질병감염등의 우려가 있다. </li>
							<li>분만시 미리 갖추어야 할 준비물로서는 조산에 필요한 밧줄, 따뜻한 물, 소독제, 산실을 밝히기 위한 관원, 난산에 대비한 바셀린(vaseline) 또는 콩기름이나 들기름, 탈지면, 가제 및 수건, 요오드액, 가위 등을 준비하고 있어야 한다. </li>
						</ul>
					</li>
					<li>분만시 관리 
						<p>분만시 어미소룰 잘 관리하는 것은 건강한 어미소를 확보할 수 있는 조건이된다. </p>
						<ul>
							<li>분만이 가까워지면 어미소를 철저히 관찰한다. 언제 사고가 날지 예측할수 없기 때문이다. </li>
							<li>일반적으로 난산일 경우에만 분만을 도와주는데, 분만을 도와줌으로서 약 5%에 달하는 사산을 줄일 수 있다고 한다. </li>
							<li> 분만시의 관찰은 분만직후 송아지의 처리에도 큰 도움이 된다. </li>
						</ul>
					</li>
				</ul>
				<h2> 분만의 진행</h2>
				<ul>
					<li>제 1단계
						<ul>
							<li>거동이 불안하고, 신경이 예민해지며, 자주 누웠다 일어섰다 한다. </li>
							<li>사료를 뒤적거린다. </li>
							<li>진통이 점차 강해지며 제 1파수(요막파열)가 진행된다. </li>
							<li>제 1파수후 송아지가 산도에 진입한다.</li>
							<li>경산우 2시간 이내 </li>
							<li>초산우 4시간 이내</li>
						</ul>
					</li>
					<li>제 2단계
						<ul>
							<li> 태아만출기로서 만출기란 제 1파수후부터 태아가 만출되는 시기까지를 말한다. </li>
							<li>진통이 더욱 강해지며 제 2파수(양막파열)가 진행된다. </li>
							<li>소요시간 
								<ul>
									<li><b>경산우</b> : 30분-1시간</li>
									<li><b>초산우</b> : 2시간 이내</li>
								</ul>
							</li>
							<li>만일 제 2파수가 일어난 후 2-3시간 이상 지나도록 태아가 만출되지 않을 때에는 난산으로 인정하고 수의사에게 난산처치를 의뢰한다. </li>
						</ul>
					</li>
					<li>제 3단계
						<ul>
							<li>후산기로서 후산기란 태아가 만출되고 난 후 후산이 완전히 배출될 때 까지의 기간 을 말한다. </li>
							<li>태아 만출 후 30분- 8시간 사이에 후산을 하게 되는 바 후산여부를 반드시 확인해야 한다. </li>
							<li>12시간 이상 경과해도 후산이 자연 배출되지 않을 때에는 이를 후산정체라 하는데 후산이 자궁내에 오래 정체해 있으면 부패되어 어미소는 여러 가지 병적 증상을 일으킨다. </li>
							<li> 배출된 태반을 어미소가 먹지 않도록 한다. </li>
						</ul>
					</li>
				</ul>
				<h2>조산</h2>
				<p>조산이란 여러 가지 이유로 분만이 지연되어 어미소 혼자서 부만하지 못하는 경우 관리자가 분만을 도와주는 것을 말한다. </p>
				<ul>
					<li>필요시기
						<ul>
							<li> 초산우나 어린 암소의 분만시 </li>
							<li>분만예정일보다 늦게 분만하는 경우 </li>
							<li> 어미소의 산도가 선천적으로 작을 때 </li>
							<li>송아지가 지나치게 클 때 </li>
							<li> 제 2파수후 분만진행이 지연될 때 </li>
							<li>어미소가 허약하여 제대로 진통을 하지 못할 때 </li>
						</ul>
					</li>
					<li>준비사항
						<ul>
							<li>비닐장갑, 소독제, 따뜻한 물, 양동이, 바세린, 체인 또는 로프, 조산기구, 요오드액, 소독면, 수건이나 종이타올 </li>
						</ul>
					</li>
					<li>방 법
						<ul>
							<li>어미소의 후구쪽을 물과 소독수로 깨끗이 닦는다. </li>
							<li>깨끗한 마른 수건으로 물기를 말끔이 닦는다. </li>
							<li>관리자의 손을 소독수로 닦고 소독된 비닐장갑을 낀다. </li>
							<li>조산작업시 양막파열에 대비해서 손과 팔을 소독한다. </li>
							<li>산도에 손을 넣어 태아의 위치가 정상인지 확인한다. </li>
							<li>태아의 위치가 정상일 때는 송아지의 양쪽 발목에 로프를 맨다. </li>
							<li>어미소의 진통에 맞추어 당겨준다. </li>
							<li>당기는 방향은 어미소와 수평방향이 되도록 한다. </li>
							<li> 무리한 힘을 가하지 않는다. </li>
							<li>양수가 많이 빠져나가서 매끄럽지 않으면 송아지 몸에 바세린을 바른다. </li>
							<li>태아가 산도에 진입하기 전에 당기는 것은 위헙하다.너무 서둘지 말고 산도에 진입한 후에 당기기 시작한다. </li>
							<li>조산후 상처가 났을 때에는 소독해 주고 자궁에 자궁정제를 투입하여 염증을 예방한다. </li>
						</ul>
					</li>
				</ul>
				<h2>난산 </h2>
				<p className="pstyle">태위가 비정상인 경우나 정상이면서도 분만이 되지 않는 경우를 난산이라 한다. 가장 정확하고 안정한 처리 방법은 수의사의 도움을 받는 것이다. 수의사를 부를 시간적 여유가 없이 급박한 상황일 때는 인근의 경험있는 사람의 도움을 닫도록 한다. 이상태위일 때는 어미소를 일으켜세워 송아지를 안쪽으로 밀어넣고 태위를 바로 잡은 후에 송아지 말목에 로프를 매고 잡아당긴다. 뒷다리가 먼저 나오는 경우를 역산이라고 하는데 정상분만할 수 있으므로 걱정하지 말고 뒷발목에 로프를 매고 당겨주면 된다. 단 역산할 때 분만이 지연되면 양수가 송아지의폐로 들어가서 질식시하거나 급이물성 폐렴으로 분만후 폐사되는 예가 있으므로 주의해야 한다. 난산일 경우에는 송아지의 폐사율이 대단히 림으므로 임신우가 너무 살찌지 않도록 관리하고 특히 후보우는 번식적령기에 도달했을 때 수태 시키도록 한다. 미국 노스 캐롤라이나 주립대학(North Carolina State University)이 밝힌 초산우 및 경산우의 분만방법별 분포와 송아지 폐사율을 보면 표 44와 같다. </p><br/>
				<p><b>&lt;표 44&gt; 난산과 송아지 폐사율과의 관계</b></p>
				<table className="tablestyle">
					<tr>
						<td rowSpan="2">분만방법</td>
						<td colSpan="2">분포비율(%)</td>
						<td colSpan="2">송아지 폐사율<br/>(생후48시간이내%)</td>
					</tr>
					<tr style={{background: '#273143', color: '#fff'}}>
						<td>초산우</td>
						<td>경산우</td>
						<td>초산우</td>
						<td>경산우</td>
					</tr>
					<tr>
						<td>자연분만</td>
						<td>45</td>
						<td>79</td>
						<td>8</td>
						<td>6</td>
					</tr>
					<tr>
						<td>조산(가볍계당김)</td>
						<td>30</td>
						<td>15</td>
						<td>10</td>
						<td>8</td>
					</tr>
					<tr>
						<td>조산(세게 당김) </td>
						<td>14</td>
						<td>3</td>
						<td>35</td>
						<td>24</td>
					</tr>
					<tr>
						<td>난산(기구 사용)</td>
						<td>7</td>
						<td>1</td>
						<td>55</td>
						<td>66</td>
					</tr>
					<tr>
						<td>난산(수의사도움)</td>
						<td>4</td>
						<td>1</td>
						<td>48</td>
						<td>65</td>
					</tr>
					<tr>
						<td>계 또는 평균</td>
						<td>100</td>
						<td>100</td>
						<td>17</td>
						<td>8</td>
					</tr>
				</table>
				<h2>후산 정체 </h2>
				<p>송아지 분만 후 30분~8시간 사이에 태아를 싸고 있던 태반이 나오는데 이를 후산 이라 하며 태반이 나오지 않는 상태를 후산 정체 또는 태반 정체라고 한다. 대개 분만한 소의 5~10% 정도에서 후산 정체가 나타나며 발생 율이 10%이상이 되면 관리에 문제가 있다고 판단한다. </p>
				<ul>
					<li><b>원인 </b> : 후산 정체의 원인은 ① 운동부족, ② 농후사료의 과다급여, ③ 영양불량으로 인한 쇠약, ④ 비타민A의 부족, ⑤ 자궁 무력증, ⑥ 만성자궁염을 앓은 소, ⑦ 내분비 기능의 장애 등이다. </li>
					<li><b>처리 </b> : 후산 정체가 되면 자궁 내에 있는 태반의 부패를 방지하기 위해서 항생제를 자궁 내에 투입하고 (예 : 자궁정체 2~3정 투입) 2~3일 후에 수의사에게 의뢰하여 태반을 제거하고 자궁을 세척한다. 때로는 태반이 끊겨서 일부만 나오는 경우가 있으므로 후산의 양을 반드시 확인해야 한다. </li>
				</ul>
				<h2>분만 직후 어미소 관리 </h2>
				<ul>
					<li>분만 직후 밀기울을 물에 타서 먹인다. </li>
					<li>분만이 끝나면 소독수로 오염된 후구를 깨끗이 닦아주고, 상처가 난 자리는 외상처리를 하여 세균의 오염을 막는다. </li>
					<li> 분만 중에 오염되었거나 젖은 깔 짚은 갈아준다. </li>
					<li>일어설 때 다리를 떨거나 일어나지 못하는 소는 수의사의 진료를 받도록 한다.</li>
					<li>분만 후 갑작스럽게 사료를 바꾸거나 양을 늘려주는 것은 좋지 않다. (앞의 건유우 사양관리 참조)</li>
				</ul>
			</div>
  );
}

export default Do53;
