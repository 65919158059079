import React from 'react';

import Pic536 from 'assets/images/sub/_images/pic536.jpg';
import Pic537 from 'assets/images/sub/_images/pic537.jpg';
import Pic538 from 'assets/images/sub/_images/pic538.jpg';
import Pic539 from 'assets/images/sub/_images/pic539.jpg';
import Pic540 from 'assets/images/sub/_images/pic540.jpg';
import Pic541 from 'assets/images/sub/_images/pic541.jpg';

function Do98() {

  return (
    <div className="techinfoview">
				<h1>송아지 설사병</h1>
				<p className="pstyle">송아지는 저항력이 약하기 때문에 사양관리가 조금만 잘못되어도 설사를 하기 쉽다.</p>
				<div className="leftpart">
					<h2>원인</h2>
					<ul>
						<li>소화 불량성 요인 
							<ul>
								<li>불규칙적으로 우유를 먹이거나 양을 많이 주었을 때, 급작스런 대용유의 변화, 우유온도의 부적합, 불량사료, 한량한 외기의 온도등은 위장을 자극하여 소화불량을 유발함 </li>
							</ul>
						</li>
						<li>감염성 요인 
							<ul>
								<li>세균(대장균, 살모넬라균 등)이나 바이러스, 기생충(콕시듐 증)등의 감염으로 인해 발생. </li>
							</ul>
						</li>
					</ul>
					<h2>증상</h2>
					<ul>
						<li>생후 1주일이내의 송아지가 흰색의 물 같은 설사를하며 2∼3일 경과후 폐사하는데 이런 경우는 대장균의 감염에 의한 것이 많다. 생후 10일 이상의 송아지의 경우는 비교적 만성경과를 취한다. 처음에는 물같은 설사로 식욕 및 원기가 없어지고 시일이 지남j에 따라 눈이 들어가는 탈수증상을 보인다. 체온은 정상이하로 떨어져 허탈 상태가 되며 결국은 폐사한다. </li>
						<li>살모넬라 균에 의한 설사는 생후 2∼3주의 송아지에서 드물게 볼수 있는데 전파력이 강하고 폐사율이 높다. 40℃전후의 고열과 점액 및 혈액이 섞인 암갈색의 설사변을 보이는 것이 특징이다. 때로는 설사를 안할 때도 있다. 5∼6일의 경과후 폐사한다. </li>
						<li>소화불량성 노란색의 설사를 한다. </li>
					</ul>
					<h2>목장에서의 처치</h2>
					<ul>
						<li>송아지는 출생직후 최소한 1l 이상의 초유를 먹인다. </li>
						<li>설사 송아지를 격리시켜 따뜻하게 해주고 깔짚을 충분히 깔아준다. (예, 송아지 개별우리) </li>
						<li>설사가 심할때는 우유의 급여량을 줄이거나 중지하고 전해질(상품명:엘트라드등)을 물에 타서 먹인다. </li>
						<li>탈수가 심하면 생리식염수, 5% 포도당등을 정맥주사해 준다. </li>
						<li>항생제나 설파제를 투여한다. </li>
						<li>지사제와 비타민 A를 투여하며 어미소의 혈액을 수혈해 주면 아주 좋다. </li>
					</ul>
				</div>
				<div className="rightpart">
					<img src={Pic536} className="nonborder" alt=""/>
					<img src={Pic537} className="nonborder" alt=""/>
					<img src={Pic538} className="nonborder" alt=""/>
					<img src={Pic539} className="nonborder" alt=""/>
					<img src={Pic540} className="nonborder" alt=""/>
					<img src={Pic541} className="nonborder" alt=""/>
				</div>
			</div>
  );
}

export default Do98;