import React from 'react';


import pic682 from 'assets/images/sub/_images/pic682.jpg';
import pic683 from 'assets/images/sub/_images/pic683.jpg';
import pic684 from 'assets/images/sub/_images/pic684.jpg';
import pic685 from 'assets/images/sub/_images/pic685.jpg';
import pic686 from 'assets/images/sub/_images/pic686.jpg';
import pic687 from 'assets/images/sub/_images/pic687.jpg';

function Do137() {

  return (
    <div className="techinfoview">
      <h1>부제병</h1>
      <p className="pstyle">발굽의 조직에 생긴 염증으로 인하여 파행(절뚝거림)을 일으키는 질병을 가리키는 말이다. 부제병은 발병 부위별로 제저부란(蹄低腐爛), 지간부란(趾間腐爛), 지간결절(結節)등으로 나눌 수 있다.</p>
      <div className="leftpart">
        <h2>원인</h2>
        <ul>
          <li>운동장이나 방목지의 뾰족한 이물(돌, 그루터기, 쇠붙이 등)에 의해 발바닥이나 지간(발굽사이)에 상처를 입고 이 부위로 부패세균이 침입하여 발병한다. </li>
          <li>삭제불량(削蹄不良), 배수 불량하거나 불결한 운동장 등은 부제병 발생의 원인이 된다.</li>
        </ul>
        <h2>증상</h2>
        <ul>
          <li><b>제저부란</b> : 대개 갑작스런 파행과 통증을 보인다. 발굽을 나무 망치 등으로 가볍게 두드려 보면 통증을 표시하므로 쉽게 알아 낼 수 있다. 발굽의 바닥에 이물에 의한 상처등으로 염증을 일으키며, 진행되면 제관부까지 파급되며 이 무렵에는 제저 각질을 깎아보면 고약한 냄새가 난다. </li>
          <li><b>지간부란</b> : 초기에는 가벼운 파행을 보인다. 양 발굽사이의 피부와 피하직에 염증이 생겨 갈라지고 짙은 농이 생기며 악취가 난다. </li>
          <li><b>지간결절</b> : 지간의 피부에 사마귀 모양의 조직이 증식하여 발톱이 벌어진다. 증식된 조직의 크기에 따라 파행의 정도도 달라진다.</li>
        </ul>
        <h2>목장에서의 처치</h2>
        <ul>
          <li>운동장은 배수를 좋게하여 건조하게 하며 날카로운 이물은 제거한다. </li>
          <li>1년에 2회 정도는 정기적으로 발굽을 손질하여야 한다. </li>
          <li>우사 출입구에 세족조(5% 황상동액을 넣어둠)를 만들어 출입시에 발을 담그도록 한다. </li>
          <li>발가락 상이에 강옥도나 목타아르로 적신 솜을 끼워 넣고 지간 밴드(조합에서 공급)로 묶어 오염을 막는다.</li>
          <li> 치료시에 썩은 부분은 깍아내고 피가 나면 연탄불에 달군 집게로 지져 지혈한다. </li>
          <li>부제병의 치유속도는 매우 느리므로 끈기를 가지고 치료해야 한다. 중상이 심할때는 수의사의 도움을 받도록 한다. </li>
        </ul>
      </div>
      <div className="rightpart">
        <img src={pic682} className="nonborder" alt=""/>
        <img src={pic683} className="nonborder" alt=""/>
        <img src={pic684} className="nonborder" alt=""/>
        <img src={pic685} className="nonborder" alt=""/>
        <img src={pic686} className="nonborder" alt=""/>
        <img src={pic687} className="nonborder" alt=""/>
      </div>
    </div>
  );
}

export default Do137;