import React from 'react';

// 컴포넌트
import DciFarm from 'containers/dcitest/dciFarm/DciFarm';

function DciFarmPage() {
  return (
    <DciFarm />
  );
}

export default DciFarmPage;
