import axios from 'axios';

const setupAxiosInterceptors = () => {
  // 요청 인터셉터
  axios.interceptors.request.use(
    (config) => config,
    (error) => Promise.reject(error),
  );

  // 응답 인터셉터
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      // 에러 응답 처리
      if (error.response) {
        switch (error.response.status) {
          case 401:
            // 인증 에러 처리
            console.error('인증되지 않은 요청입니다.');
            break;
          case 403:
            // 권한 에러 처리
            console.error('접근 권한이 없습니다.');
            break;
          case 404:
            // Not Found 에러 처리
            console.error('요청하신 리소스를 찾을 수 없습니다.');
            break;
          case 500:
            // 서버 에러 처리
            console.error('서버 에러가 발생했습니다.');
            break;
          default:
            console.error('알 수 없는 에러가 발생했습니다.');
        }
      } else if (error.request) {
        // 요청이 전송되었으나 응답을 받지 못한 경우
        console.error('서버로부터 응답이 없습니다.');
      } else {
        // 요청 설정 중 에러가 발생한 경우
        console.error('요청 설정 중 에러가 발생했습니다:', error.message);
      }

      return Promise.reject(error);
    },
  );
};

export default setupAxiosInterceptors;
