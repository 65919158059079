import React from 'react';

import Figure3by5 from 'assets/images/sub/_images/figure_3_5.gif';
import Figure3by6 from 'assets/images/sub/_images/figure_3_6.gif';
import Figure3by7 from 'assets/images/sub/_images/figure_3_7.gif';

function Fo15() {

  return (
    <div className="techinfo_view">
				<p className="viewTitle">임신과 분만 - 분만</p>
				<p>분만이란 태반의 만출에 의한 태아의 출생을 의미한다. 분만시간이 가까워지면 분만자세로 태아는 자세를 갖춘다. 태아의 정상적인 분만자세는 앞다리는 자궁경관으로 향하며, 머리는 앞다리 사이에 위치에 있다. 비정상적인 분만자세를 하고 있는 것이 5％이며, 5∼7％가 후산정체에 걸린다.</p>
				<div className="imgBox mg60f"><img src={Figure3by5} alt=""/></div>
				
				<h2>분만징후</h2>
				<ul className="listStyle04 mg60f">
					<li>유방이 발달되어 유즙의 분비능력을 가지게 되며</li>
					<li>골반인대가 이완되며</li>
					<li>외음부로부터 점조성이 있는 점액이 분비된다. </li>
				</ul>
				
				<h2>3단계 분만과정</h2>
				<dl>
					<dt>1단계 : 자궁경관의 이완</dt>
					<dd>분만의 첫단계는 경산우는 2∼3시간, 미경산우는 6시간 동안 지속된다. 자궁경관이 이완되고 태아가 산도로 들어간다. 분만우는 정상적으로 사료는 섭취하지만 불안해 하는 경향이 있다. 5분 마다 자궁이 수축되고 5초 동안 지속된다. 소의 후미로 태반수가 박리 되는데, 노란색을 띤다.</dd>
				</dl>
				<div className="imgBox mg30f"><img src={Figure3by6} alt=""/></div>
				
				<dl>
					<dt>2단계 : 송아지 분만 </dt>
					<dd>산도를 통하여 먼저 머리와 다리가 나오고 어깨가 나온다. 분만된 송아지는 자주 일어나려고 하며, 약간 움직일 수도 있다. 첫단계에서 두 번째단계까지 약 2시간이 소요되며 송아지가 클 경우에는 10시간 이상 걸리기도 한다. </dd>
				</dl>
				
				<dl className="mg60f">
					<dt>3단계 : 태반만출</dt>
					<dd>이시기는 자궁으로부터 태반이 나오는 시기이다. 태아가 분만된 후에도 자궁의 수축이 지속된다. 자궁수축으로 자궁소구에서 태반이 분리됨으로서 궁부가 떨어져 나온다. 정상적으로 후산은 송아지 분만후 12시간내에 일어난다. </dd>
				</dl>
				
				<h2>분만장애</h2>
				<p>태반수박리는 정상적인 자궁경관의 이완을 방해할 수 있다 너무 일찍 태반수가 박리되면 정상적인 분만을 방해한다. 정상적인 분만이 진행되려면 8시간 정도가 소요된다. 송아지의 발이 먼저보이면 끌어내려고 시도를 하는 것이 보통이다. 이때는 자궁경관이 완전히 이완되지 않았기 때문에 정상적인 분만을 방해한다. </p>
				<div className="imgBox mg30f"><img src={Figure3by7} alt=""/></div>
				
				<dl>
					<dt>난산(Dystocia) </dt>
					<dd>
						<p>난산은 사산의 원인이 될 수가 있다. 후산정체나 자궁감염이 난산의 원인이 되며, 차기 분만을 지연시키며, 분만간격이 늘어난다. 난산은 관련이 있다</p>
						<ul className="listStyle03">
							<li>비정상적인 분만위치(그림3.7)</li>
							<li>송아지의 체중
								<ul className="listStyle04">
									<li>비유말기와 건유기의 영양수준이 신체조건에 영향을 미칠 수 있다(야윔 또는 과비) </li>
									<li>송아지의 성 :숫송아지가 암송아지에 비해 체중이 무겁다</li>
									<li>임신기간 : 임신말기에는 1일 0.5㎏씩 증가 </li>
									<li>송아지의 품종 </li>
								</ul>
							</li>
						</ul>
					</dd>
				</dl>
				
				<dl className="mg60f">
					<dt>난산의 최소화 </dt>
					<dd>가축의 적절한 영양수준과 사양관리로 난산을 최소화 시킬 수가 있다. 특히 미경산우는 분만이 아주 어렵고, 심지어 24∼26개월령이 된 소도 어려움이 많다. 미경산우에 부적절한 영양공급으로 난산에 걸리는데, 골격의 발달이 늦다. 충분한 골격발달이 이루어지지 못함으로서 골반이 작아져서 난산에 걸리기 쉽다. 영양분의 과잉공급도 난산의 원인이 될 수 있다. 과비한 미경산우와 경산우는 난산을 경험하는 경우가 많은데, 큰 송아지를 분만한다.</dd>
				</dl>
				
				<h2>난산 처치법</h2>
				<p>난산의 처치법으로 일반 농장에서 견인 추출법을 많이 사용하고 있으나 과도하게 견인 하였을 때는 산후 유량의 감소하고 또한 일시적으로 불임이 되거나 심할경우에는 완전한 불임이 될수 있으므로 주의해야 한다.</p>
				<p>난산의 원인은 주로 모체측에 기인되는 예가 많은데, 해부학적인 산도의 이상(산도의 기형, 자궁 경관, 질, 음문의 협착, 골반협착 등)과 내 분비이상에 따른 진통이상이 원인이 된다. 태아측의 원인으로는 실위, 과대, 기형 그리고 다태임신등을 들 수 있다. </p>
				<p>소의 분만보조 작업은 많은 경험과 판단을 필요로한다. </p>
				<p>새끼의 실위가 이상시에는 새끼를 자궁으로 밀어넣은후 2∼3시간 후에는 태아의 앞발을 산도를 통하여 볼수 있는데, 만일 이때 어미소가 피로를 느끼거나 스트레스 등으로 자세가 흐트러 질때는 고정틀 등으로 보조를 해주어야 한다. 그리고 난산시 견인추출의 방법으로 보조해 줄때도 역시 산도를 통하여 태아의 앞다리를 이용하여야 한다. 견인추출작업은 천천히 이루어져야하며 어미소의 보정도 이뤄져야된다. </p>
				<p>견인시 방향은 산도와 평행된 높이가 아닌 낮은 방향으로 잡아당겨야 된다. 이작업에 오염이 많이 발생하므로 손과 팔 그리고 소의 외음부 그리고 난산처치에 사용되는 모든 기구는 모두 소독이 필수적이다. </p>
			</div>
  );
}

export default Fo15;
