import React, { useState , useEffect } from 'react';

// api
import { postState } from 'utils/api';
import { openCrownix } from 'utils/openCrownix';

// 이미지
import PrintPDF from 'assets/svgs/common/print_PDF.svg';
import Logo from 'assets/images/common/header_logo_pc.png';

// 컴포넌트
import ErrorMessage from 'components/errorMessage/errorMessage';

// scss
import './OxList.scss';

function OxList() {

  // 에러처리
  const [isModalOpen, setIsModalOpen] = useState(false); // 모달 추가
  const [errorMessage, setErrorMessage] = useState(''); 

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => {
    setIsModalOpen(false);
    setErrorMessage(''); // 모달이 닫힐 때 에러메세지 초기화
  };

  // 데이터 불러오기
  const baseDir='/dcitest/dcifarm/oxlist/';
  const [ mainData , setMainData ] = useState();

  const [ props , setProps] = useState({
    id:`${localStorage.getItem('cong')}${localStorage.getItem('farm')}`,
    passwd:localStorage.getItem('passwd'),
    date:localStorage.getItem('month')
  });

  useEffect(() => {

    postState(baseDir,props)
    .then(getdata => {      
      if (getdata !== undefined) {
    //   console.log ('#### props : ' ,props);
      setMainData(getdata);
    //   console.log ('#### data : ' ,getdata);
      }
    })
    .catch(error => {      
    //   console.error('Error:', error.message);      
      setErrorMessage(error.message);
      openModal();
    });
    
  }, [props]);

  // 페이지 네이션
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20;

  // Calculate the index range for the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = mainData && mainData.plot_data.slice(indexOfFirstItem, indexOfLastItem);
  const maxPage = Math.ceil(mainData && mainData.plot_data.length / itemsPerPage);

  return (
    <div className='pdfMainArea'>
      <ErrorMessage isOpen={isModalOpen} content={errorMessage} closeModal={closeModal}/>
      <div className='pdfMainHeader'>
        <button 
          type='button'
          onClick={()=> openCrownix('Ox_list.mrd')}
        >
          <img src={PrintPDF} alt='PDF로 인쇄하기' />
          인쇄하기
        </button>
        <div className='pdfPagenation'>
          <p>{currentPage} / {maxPage}</p>
          <button 
            type='button' 
            onClick={() => { 
              if (currentPage !== 1 ) setCurrentPage(currentPage - 1);
            }}
            >이전</button>
          <button 
            type='button' 
            onClick={() => {
              if (currentPage !== maxPage) setCurrentPage(currentPage + 1);
            }}
            >다음</button>
        </div>
      </div>
      <div className='pdfArea'>
        <div className='oxListPdf'>
          <h1>씨수소 혈통 및 성적조회</h1>          
          <table className='pdfTable'>
            <thead>
              <tr>
                <th>정액국가</th>
                <th>정액코드</th>
                <th>명호</th>
                <th>아비혈통</th>
                <th>종합지수</th>
                <th>유량</th>
                <th>지방</th>
                <th>단백질</th>
                <th>체형</th>
                <th>유방</th>
                <th>가격</th>
                <th>중점개량형질</th>                
              </tr>
            </thead>
            <tbody>
              {
                currentItems &&
                currentItems.map((item, index) => (
                  <tr key={index}>
                    <td>{item.s_origin_name}</td>
                    <td>{item.s_fullcode}</td>
                    <td>{item.s_sortname}</td>
                    <td>{item.s_ssortname}</td>
                    <td>{item.s_origin_tpi}</td>
                    <td className='numberData'>{item.s_ptam.toLocaleString()}</td>
                    <td className='numberData'>{item.s_ptaf}</td>
                    <td className='numberData'>{item.s_ptap}</td>
                    <td className='numberData'>{item.s_ptat}</td>
                    <td className='numberData'>{item.s_udc}</td>
                    <td className='numberData'>{item.s_danga.toLocaleString()}</td>
                    <td>{item.s_spec}</td>                    
                  </tr>
                ))
              }
            </tbody>
          </table>
          <img src={Logo} alt='농협경제지주 젖소개량사업소 로고' className='pdfLogo'/>
        </div>
      </div>
    </div>    
  );
}

export default OxList;
