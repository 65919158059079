import React from 'react';

import d2by37 from 'assets/images/sub/_images/2-37.jpg';
import d2by41 from 'assets/images/sub/_images/2-41.gif';
import d2by43 from 'assets/images/sub/_images/2-43.jpg';
import d2by47 from 'assets/images/sub/_images/2-47.jpg';
import d2by53 from 'assets/images/sub/_images/2-53.jpg';


function Fo29() {

  return (
    <div className="techinfo_view">
				<p className="viewTitle">농후사료와 물과 영양제</p>
				<h2>건초는 언제부터 주는가?</h2>
				<ul className="listStyle04 mg15f">
					<li>건초의 급여는 생후 4∼5일부터개시한다.(인공유급여개시와 동일) </li>
					<li>급여량은 인공유의 채식을 방해하지 않는 양(0.2∼0.5kg/일)</li>
					<li>급여할 건초는 곰팡이가 없고 거칠고 굳세지 않은 것</li>
				</ul>
				<div className="imgBox mg30f"><img src={d2by37} alt=""/></div>
				<table className="tablestyle">
					<caption>표. 건초의 급여개시 월령(月齡)조사</caption>
					<thead>
						<tr>
							<th className="first"> </th>
							<th>고유량 농가</th>
							<th>평균유량 농가</th>
							<th>전체</th>
							<th>1978년</th>
						</tr>
					</thead>
					<tfoot>
						<tr>
							<td>계</td>
							<td>23 (100)</td>
							<td>26 (100)</td>
							<td>49 (100)</td>
							<td>100%</td>
						</tr>
					</tfoot>
					<tbody>
						<tr>
							<td>생시부터 급여</td>
							<td>7 (31)</td>
							<td>4 (15)</td>
							<td>11 (23)</td>
							<td rowSpan="4">93%</td>
						</tr>
						<tr>
							<td>~ 5일째</td>
							<td>6 (26)</td>
							<td>3 (12)</td>
							<td>9 (18)</td>
						</tr>
						<tr>
							<td>6 ~ 10일째</td>
							<td>5 (22)</td>
							<td>9 (35)</td>
							<td>14 (29)</td>
						</tr>
						<tr>
							<td>11 ~ 15일째</td>
							<td>4 (17)</td>
							<td>5 (19)</td>
							<td>9 (18)</td>
						</tr>
						<tr>
							<td>16 ~ 30일째</td>
							<td>1 (4)</td>
							<td>4 (15)</td>
							<td>5 (10)</td>
							<td>7%</td>
						</tr>
						<tr>
							<td>3개월령</td>
							<td>- (-)</td>
							<td>1 (4)</td>
							<td>1 (2)</td>
							<td>-</td>
						</tr>
					</tbody>
				</table>
				<ul className="refer mg10t mg60f">
					<li>주: 호수(戶數), ( )안은 %</li>
					<li>(쇼와 63년도 북해도 토카치 지정농업개량보급원축산부회직장연수보고서, 1978년은 북해도 토카치 농협연 조사.)</li>
				</ul>
				
				<h2>건초는 왜 주는 걸까?</h2>
				<ul className="listStyle04 mg15f">
					<li>포유기간에 주는 인공유의 역할은 영양공급과 제1위융모의 발달이지만 건초의 역할은 영양공급보다는 제1위의 물리적 확장, 반추작용과 타액(침)의 분비촉진에 있다</li>
					<li>조기에 벤것의 1번은 TDN이 높아 바람직한 건초이지만 1번초의 늦게 벤 것은 거칠고 굳세서 반추기능이 불충분한 3개월령까지의 자우에게 주면 거칠고 굳센 섬유가 제 4위로 들어가 위점막을 손상할 염려가 있다</li>
					<li>조기에 벤 1번초가 최고 좋지만, 개화기의 1번초보다는 빨리 벤 것 2·3번 건초의 쪽이 좋다</li>
				</ul>
				<div className="imgBox mg30f"><img src={d2by41} alt=""/><br/>3개월령전후의 TDN,CP필요량</div>
				<ul className="listStyle04 mg15f">
					<li>3개월령의 목표체중은 100kg. 1일증대체중은 700-800g전후가 목표이다</li>
					<li>생체중 100kg, 1일증체 800g으로 하면 1일의 영양필요량은 건물섭취량 3.02kg, TDN2.11kg, CP483g이다.(NRC).</li>
					<li>건초의 성분(DM중) TDN56%, CP 8%로 하면 건초필요량은 1.5kg/일이 되어 lose(손실)을 고려하면 1.8kg/일 급여가 필요</li>
					<li>이 시기의 영양수준이 낮으면 체고(體高)등의 성장이 억제될 것이다</li>
				</ul>
				<div className="imgBox mg60f"><img src={d2by43} alt=""/><br/>Holstein 육성우의 성장 곡선</div>
				
				<h2>Sailage의 급여는 언제부터인가?</h2>
				<ul className="listStyle04 mg15f">
					<li>사일레지와 건초의 다른 점은 사일레지는 수분이 많고 발효물이다. (단백질내에 녹기쉬운 단백질이 많다 ---------고수분만큼 많아진다.)</li>
					<li>6주이유를 전제로 하면 제 1위의 기능이 성우에 가까워 지는 3개월령쯤부터 급여가 무난하다.</li>
					<li>최초는 익숙해질 정도부터 주어 조금씩 증급(增給)한다.</li>
					<li>전체사료중의 수분이 50%이상이 되면 건물의 섭취량이 저하하기 때문에 주의가 필요하다</li>
				</ul>
				<table className="tablestyle mg30f">
					<caption>사일레지의 급여개시월령</caption>
					<thead>
						<tr>
							<th className="first"> </th>
							<th colSpan="2">전체 </th>
							<th>1978년</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>생후1개월</td>
							<td>호(戶)</td>
							<td>%</td>
							<td>%</td>
						</tr>
						<tr>
							<td>이내</td>
							<td>4</td>
							<td>(9)</td>
							<td>(5.3)</td>
						</tr>
						<tr>
							<td>2-4개월</td>
							<td>15</td>
							<td>(32)</td>
							<td>(14.7)</td>
						</tr>
						<tr>
							<td>5-6개월</td>
							<td>10</td>
							<td>(22)</td>
							<td>(26.3)</td>
						</tr>
						<tr>
							<td>7-12개월</td>
							<td>11</td>
							<td>(24)</td>
							<td>(22.1)</td>
						</tr>
						<tr>
							<td>13개월이후</td>
							<td>6</td>
							<td>(13)</td>
							<td>(31.6)</td>
						</tr>
					</tbody>
				</table>
				
				<table className="tablestyle">
					<caption>사일레지의 종류 </caption>
					<thead>
						<tr>
							<th className="first"> </th>
							<th colSpan="2">고유량농가</th>
							<th colSpan="2">평균유량농가</th>
							<th>1978년</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td></td>
							<td>호(戶)</td>
							<td>%</td>
							<td>호(戶)</td>
							<td>%</td>
							<td rowSpan="5">대부분이 Corn(옥수수) Sailage였다.</td>
						</tr>
						<tr>
							<td>Grass Sailage </td>
							<td>1</td>
							<td>(4)</td>
							<td>1</td>
							<td>(4)</td>
						</tr>
						<tr>
							<td>Corn Sailage</td>
							<td>11</td>
							<td>(48)</td>
							<td>13</td>
							<td>(79)</td>
						</tr>
						<tr>
							<td>병급</td>
							<td>9</td>
							<td>(39)</td>
							<td>3</td>
							<td>(13)</td>
						</tr>
						<tr>
							<td>Grass or corn sailage</td>
							<td>2</td>
							<td>(9)</td>
							<td>1</td>
							<td>(4)</td>
						</tr>
					</tbody>
				</table>
				<ul className="refer mg10t mg60f">
					<li>(1988년도 북해도 토카치지정 농업개량 보급원 축산부회 직장연수보고서,1978년은 북해도 토카치 농협연조사)</li>
				</ul>
				
				<h2>볏짚, 다른 조사료 좋은가? </h2>
				<ul className="listStyle04 mg15f">
					<li>거철고 굳센 성질의 정도로 의한다</li>
					<li>볏짚와 Stand grass(잡초)와 같은 조사료은 TDN이 낮아 거칠고 굳세다</li>
					<li>TDN의 부족은 어느 정도는 인공유와 Beet pulp등으로 보정(補正)할 수 있다</li>
					<li>그러나 조강(거칠고 굳센)한 사료를 반추기능이 발달하여 있지 않은 유령우에게 주면 제4위의 창상성 위궤양의 원인이 되면 지적되고 있다</li>
				</ul>
				<div className="imgBox mg60f"><img src={d2by47} alt=""/><br/>3개월령이내의 자우에게 거칠고 굳센 조사료를 주어서는 안된다</div>
				
				<h2>3개월령부터의 농후사료</h2>
				<ul className="listStyle04 mg15f">
					<li>육성용농후사료의 CP함량은 조사료의 CP함량으로 결정한다</li>
					<li>CP급여량이 많으면 육성우가 과비해진다고 생각하고 있는 사람이 있지만 이것은 완전히 반대(逆)이다</li>
					<li>CP가 부족하면 체고가 억제되어 소화율이 저하한다. CP부족은 칼슘(Ca)의 흡수를 저해하여 골의 성장을 억제한다</li>
					<li>농후사료의 급여량은 2kg /일 (한냉조건에서는 2.5kg/일) 정도가 목표이다</li>
				</ul>
				<p>아래의 표를 이용하면 조사료의 품질에 대한 배합사료의 급여비율과 적절한 CP%의 배합사료을 선정할 수 있다.</p>
				<div className="tableWrap">
					<table className="tablestyle">
						<caption>조사료 성분과 농후사료의 급여비율 및 CP%(3-6개월령)</caption>
						<thead>
							<tr>
								<th className="first">조사료 DM중<br/>TDN%<br/>CP%</th>
								<th>54%①<br/>(조 : 배)<br/>(42 :58)②</th>
								<th>56%<br/>조 : 배<br/>46 : 54</th>
								<th>58%<br/>조 : 배<br/>50 : 50</th>
								<th>60%<br/>조 : 배<br/>55 : 45</th>
								<th>62%<br/>조 : 배<br/>61 : 39</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td rowSpan="2">③ 6 %</td>
								<td colSpan="5">④배합사료의 CP% ( )는 DM중% </td>
							</tr>
							<tr>
								<td>20.4 (23.2)</td>
								<td>21.6 (24.5)</td>
								<td>22.9 (26.0)</td>
								<td>24.8 (28.2)</td>
								<td>27.8 (31.6)</td>
							</tr>
							<tr>
								<td>8 %</td>
								<td>19.2 (21.8)</td>
								<td>20.1 (22.8)</td>
								<td>21.1 (24.0)</td>
								<td>22.7 (25.8)</td>
								<td>25.1 (28.5)</td>
							</tr>
							<tr>
								<td>10 %</td>
								<td>17.9 (20.3)</td>
								<td>18.6 (21.1)</td>
								<td>19.4 (22.0)</td>
								<td>20.5 (23.3)</td>
								<td>22.4 (25.4)</td>
							</tr>
							<tr>
								<td>12 %</td>
								<td>16.6 (18.9)</td>
								<td>17.1 (19.4)</td>
								<td>17.6 (20.0)</td>
								<td>18.4 (20.9)</td>
								<td>19.6 (22.3)</td>
							</tr>
							<tr>
								<td>14 %</td>
								<td>15.3 (17.4)</td>
								<td>15.6 (17.7)</td>
								<td>15.5 (18.0)</td>
								<td>16.2 (18.4)</td>
								<td>16.8 (19.1)</td>
							</tr>
						</tbody>
					</table>
				</div>
				<ul className="refer mg30f" style={{marginTop: '-20px'}}>
					<li>NRC 추장분(DM중) TDN69% ⓐ, CP16% ⓒ</li>
					<li>농후사료성분 DM88% TDN80% (DM중)</li>
				</ul>
				<dl className="mg60f">
					<dt>표보는 방법(아래 기록의 ⓐⓑ..........①②.......와 표 .......ⓐⓑ..........①②.....를 조합(組合)한다.</dt>
					<dd>1. 필요한 TDN은 69%ⓐ</dd>
					<dd>2. TDN54%①의 조사료와 TDN80%의 배합사료를 42:58②의 비율로 급여하면 TDN69%ⓐ가 된다.</dd>
					<dd>3. 필요한 CP는 16%ⓒ</dd>
					<dd>4. 조사료의 CP%가 6%③이면 20.4%④의 CP함량의 배합사료를 급여하면 CP16%ⓒ가 된다. </dd>
				</dl>
				
				<h2>포유자우에도 물은 필요한가?</h2>
				<ul className="listStyle04 mg15f">
					<li>물의 필요량은 1개월령(포유중)으로 1일 5ℓ, 2개월령(이유)으로 6ℓ, 3개월령(이유)으로 11ℓ정도가 목표(사료중 수분을 함유하고 있다.) </li>
					<li>포유중이더라도 1개월령(전유 4ℓ)로 약 1.5ℓ정도의 음수가 필요. </li>
					<li>물이 자유롭게 마실 수 없으면 고형사료의 채식량이 감소하거나 사료효율이 저하한다</li>
					<li>한냉조건에서는 수온이 기온보다 몇분 높으면 물을 많이 마신다. 그러나 가온의 유리성은 동결방지정도이다</li>
					<li>물의 필요량은 기상상황등에 의해 크게 변화한다. 항상 필요량을 자유롭게 마실수 있는 것이 중요</li>
				</ul>
				<table className="tablestyle">
					<caption>이유전후 다르게 본 물의 급여개시일령 호(戶)</caption>
					<thead>
						<tr>
							<th className="first" rowSpan="2">개시 일령</th>
							<th colSpan="2">고유량 농가</th>
							<th colSpan="2">평균 유량 농가</th>
						</tr>
						<tr>
							<th>이유전</th>
							<th>이유후</th>
							<th>이유전</th>
							<th>이유후</th>
						</tr>
					</thead>
					<tfoot>
						<tr>
							<td>합계</td>
							<td>16</td>
							<td>7</td>
							<td>18</td>
							<td>8</td>
						</tr>	
					</tfoot>
					<tbody>
						<tr>
							<td>생후15일 이내</td>
							<td>10</td>
							<td></td>
							<td>4</td>
							<td></td>
						</tr>
						<tr>
							<td>-30일</td>
							<td>2</td>
							<td></td>
							<td>5</td>
							<td></td>
						</tr>
						<tr>
							<td>-60일</td>
							<td>2</td>
							<td>5</td>
							<td>7</td>
							<td>1</td>
						</tr>
						<tr>
							<td>-90일</td>
							<td>2</td>
							<td>2</td>
							<td>2</td>
							<td>4</td>
						</tr>
						<tr>
							<td>-120일</td>
							<td></td>
							<td></td>
							<td></td>
							<td>2</td>
						</tr>
						<tr>
							<td>120일 이상</td>
							<td></td>
							<td></td>
							<td></td>
							<td>1</td>
						</tr>
					</tbody>
				</table>
				<ul className="refer mg10t mg60f">
					<li>쇼와 63년도 북해도토카치 지정 농업개량 보급원 축산부회 직장연수보고서</li>
				</ul>
				
				<h2>고유량농가는 물의 필요성을 이해하고 있다</h2>
				<ul className="listStyle04 mg15f">
					<li>고유량 농가가 많지만, 포유기간중에 있어서의 수(水)급여가 필요하다고 대답하고 있다</li>
					<li>물은 생후 될 수 있는한 빨리 예를 들면 마시지 않더라도 항상 급여하여 물과 액상사료가 다르다는 것을 깨닫게 한다. (과잉섭취방지도 된다.)</li>
					<li>수질과 급여방법이 적당한 정도이다면 물이 하리의 원인이 되는 것은 아니다. </li>
					<li>포육우에 대한 물 급여의 생각하는 방법은 현재의 낙농기술 가운데 가장 개선을 요하는 하나일 것이다</li>
				</ul>
				<div className="tableWrap">
					<table className="tablestyle">
						<caption>포유기간중의 물 급여에 관한 조사. (戶) </caption>
						<thead>
							<tr>
								<th className="first">구분</th>
								<th>고유량 농가</th>
								<th>평균유량 농가 </th>
								<th>계</th>
								<th> </th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>필요</td>
								<td>20 (87%)</td>
								<td>17 (65%)</td>
								<td>37 (76%)</td>
								<td rowSpan="3"><img src={d2by53} alt=""/></td>
							</tr>
							<tr>
								<td>불필요</td>
								<td>3 (13%)</td>
								<td>9 (35%)</td>
								<td>12 (24%)</td>
							</tr>
							<tr>
								<td>합계</td>
								<td>23 (100%)</td>
								<td>26 (100%) </td>
								<td>49 (49%)</td>
							</tr>
						</tbody>
					</table>
				</div>
				<ul className="refer mg60f" style={{marginTop: '-20px'}}>
					<li>(쇼와 63년도 북해도 토카치 지정 농업개량 곱급원축산부회직장연수보고서) 자동 급수기(Water cup) </li>
				</ul>
				
				<h2>3개월령 이후의 미네랄,비타민(Vitamin)제</h2>
				<ul className="listStyle01 mg15f">
					<li>미네랄(Mineral)제
						<ul className="listStyle04">
							<li>인, 칼륨제로서 10-15g /일이 급여의 목표</li>
							<li>배합사료에는 추장량정도의 칼슘(Ca), 인은 함유되어 있는 경우가 많으므로 조사료중 함량에의해 필요량은 다르다</li>
							<li>나트륨(Na)은 식염으로 체중 100kg당 7g이 급여목표이다.(사료중에 함유하지 않은 것으로서)</li>
						</ul>
					</li>
					<li>비타민제
						<ul className="listStyle04">
							<li>일반적인 조사료의 품질로 일광욕을 할 수 있는 경우는 대체로 충족된다</li>
							<li>부족이 예측되는 경우는 급여가 필요하지만 비타민 D의 과급은 해가 있으므로 제제의 급여량을 엄수해야 한다</li>
						</ul>
					</li>
				</ul>
				<div className="tableWrap">
					<table className="tablestyle">
						<caption>표. 미네랄, 비타민의 추장함량과 주된 사료중 성분(DM중)</caption>
						<thead>
							<tr>
								<th className="first" rowSpan="2"> </th>
								<th colSpan="3">NRC추장량(NRC)</th>
								<th colSpan="3">사료중 성분(NRC)</th>
							</tr>
							<tr>
								<th>3-6개월</th>
								<th>6-12개월</th>
								<th>12개월이후</th>
								<th>건초 (티모시)</th>
								<th>옥수수 사일레지</th>
								<th>********</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>미네랄, 칼슘(Ca) %</td>
								<td>0.52</td>
								<td>0.41</td>
								<td>0.29</td>
								<td>0.53</td>
								<td>0.23</td>
								<td>0.03</td>
							</tr>
							<tr>
								<td>인 %</td>
								<td>0.31</td>
								<td>0.30</td>
								<td>0.23</td>
								<td>0.25</td>
								<td>0.22</td>
								<td>0.29</td>
							</tr>
							<tr>
								<td>나트륨(Na)</td>
								<td>0.10</td>
								<td>0.10</td>
								<td>0.10</td>
								<td>0.18</td>
								<td>0.01</td>
								<td>0.03</td>
							</tr>
							<tr>
								<td>비타민 A 1000IU/kg</td>
								<td>2.2</td>
								<td>2.2</td>
								<td>2.2</td>
								<td>21</td>
								<td>18</td>
								<td>1</td>
							</tr>
							<tr>
								<td>비타민 D 1000IU/kg</td>
								<td>0.3</td>
								<td>0.3</td>
								<td>0.3</td>
								<td>-</td>
								<td>0.1</td>
								<td>-</td>
							</tr>
							<tr>
								<td>비타민 E IU/kg</td>
								<td>25</td>
								<td>25</td>
								<td>25</td>
								<td>13</td>
								<td>-</td>
								<td>25</td>
							</tr>
						</tbody>
					</table>
				</div>
				
			</div>
  );
}

export default Fo29;
