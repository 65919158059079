import React from 'react';
import './favoritemenu.scss';
import { Link } from 'react-router-dom';

const FavoriteMenu = ({ menuItems }) => (
  <div className="favoriteCon">
    <div className="title_group">
      <h2 className="favoriteCon_title">
        즐겨찾는 <span>메뉴</span>
      </h2>
      <p className="sub_title">빠르게 서비스를 이용하실 수 있습니다.</p>
    </div>
    <div className="favorite-menu-grid">
      {menuItems.map((item, index) => (
        <Link key={index} to={item.link} className="menu-item">
          <div className="imgbox">
            <img src={item.icon} alt={item.title} />
          </div>
          <p>{item.title}</p>
        </Link>
      ))}
    </div>
  </div>
);

export default FavoriteMenu;
