import React from 'react';

import Pic581 from 'assets/images/sub/_images/pic581.jpg';
import Pic582 from 'assets/images/sub/_images/pic582.jpg';
import Pic583 from 'assets/images/sub/_images/pic583.jpg';

function Do110() {

  return (
    <div className="techinfoview">
      <h1>유열</h1>
      <div className="leftpart">
        <h2>원인</h2>
        <ul>
          <li>분만후 급격한 비유개시로 혈청중의 칼슘이 유즙으로 배출되어 심한 칼슘 결핍증을 초래하여 근육경련과 마비에 빠져 기립불능에 빠지는 병 
            <ul>
              <li>유열은 대부분이 분만직후부터 48시간이내에 발생</li>
            </ul>
          </li>
        </ul>
        <h2>증상</h2>
        <ul>
          <li>초기는 흥분으로 과민반응을 보이며 목윗부분이 떨리고 치아를 간다. </li>
          <li>점차로 뒷다리가 뻣뻣해지며 운동이 곤란해 지고 쉽게 넘어져 기립불능에 빠진다. </li>
          <li>전형적인 유열 자세인 복와(伏臥)자세를 취하며 의식장애로 동공이 산대(散大)되고 빛에 대한 반응이 둔해진다. </li>
          <li>체온은 정상이하로 떨어지며 맥박수는 1분간 90정도로 증가하며 약하다. </li>
          <li>방치해두면 폐사한다. </li>
        </ul>
        <h2>목장에서의 처치</h2>
        <ul>
          <li>예방대책으로서 분만 3주전부터는 급여사료에 칼슘을 줄이거나 첨가를 중지하고 (0.5%이하)칼슘에 비해 인이 많은 사료(1:3비율)를 급여한다. </li>
          <li>전에 유열에 걸렸던 소는 분만 2∼8일 전에 Vit D3 1000만 단위를 근육주사하면 예방효과가 있다.</li>
          <li>일단 기립불능에 빠지면 속히 수의사의 치료를 받도록 한다. </li>
          <li>수의사의 치료를 받기전에 긴급처치로서 유방내 송풍(送風)을 실시하여 위급한 상태를 넘길 수 있다.(유방염을 일으킬 가능성이 있으므로 긴급할 때 외는 하지 않는 것이 좋다.) </li>
          <li>칼슘제 500ml를 서서히 정맥주사 해준다. 경과를 보아 6∼8시간 간격으로 다시 주사한다.</li>
        </ul>
      </div>
      <div className="rightpart">
        <img src={Pic581} className="nonborder" alt=""/>
        <img src={Pic582} className="nonborder" alt=""/>
        <img src={Pic583} className="nonborder" alt=""/>
      </div>
    </div>
  );
}

export default Do110;