import React from 'react';

import Pic488 from 'assets/images/sub/_images/pic489.jpg';
import Pic489 from 'assets/images/sub/_images/pic480.jpg';
import Pic490 from 'assets/images/sub/_images/pic490.jpg';

function Do82() {

  return (
    <div className="techinfoview">
				<h1>복막염</h1>
				<p className="pstyle">소의 복막염은 주로 창상성 위염, 창상성 횡격막염등으로 인한 복강내 세균감염, 분만전후 난산으로 인한 자궁 천공시 오염된 자궁내용물이 복강내 유입되어 복막에 염증이 생기는 질병이다.</p>
				<div className="leftpart">
					<h2>원인</h2>
					<ul>
						<li>호의 복막염은 대부분은 속발성이다
							<ul>
								<li>쇠붙이 등 금속이물이 제2위벽을 찔러 금속이물에 붙어있던 세균이나 위액이 복강내 들어가 복막염을 일으킨다. </li>
								<li>난산시 무리한 태아견인, 이상태위(異狀胎位)시의 무리한 정복(整復), 후산정체제거등에 의한 자궁 천공시 오염된 자궁내용물이 복강내 들어갈 때. </li>
								<li>외과수술시에 수술기구의 불완전한 소독에 의해서 복막염이 발생하기도 한다.</li>
							</ul>
						</li>
					</ul>
					<h2>증상</h2>
					<ul>
						<li>만성
							<ul>
								<li>유착(복막과 위가 맞붙음)을 일으켜 소화관 운동이 방해받아 만성소화불량을 일으킨다. </li>
								<li>계속되는 소화장애로 수척하기 시작한다. </li>
								<li> 일반적으로 예후는 좋지 않다. </li>
							</ul>
						</li>
						<li>급성
							<ul>
								<li>갑자기 식욕이 떨어지고 이를 갈면서, 복부를 걷어 차는 등 복통 증세를 보인다. </li>
								<li>허리와 등을 구부린 자세로 장시간 기립한 채 움직이기를 싫어한다. </li>
								<li> 1위와 장의 운동은 완전히 정지한다. </li>
								<li>분변은 소량으로 까만색이며 많은 점액이 묻어 있다. </li>
								<li>복강내 가스가 차게 되면 복부주위는 팽대한다.  </li>
							</ul>
						</li>
					</ul>
					<h2>목장에서의 처치</h2>
					<ul>
						<li>병우(病牛)는 가능하면 넓은 장소로 옮겨 운동을 피하고 안정시키도록 한다. </li>
						<li>예방의 한 방안으로 분만시에 태아 정복이나 태아의 견인시 결코 무리함이 없이 안전한 방법으로 행할 수 있게 하여야 한다.</li>
					</ul>
				</div>
				<div className="rightpart">
					<img src={Pic488} className="nonborder" alt=""/>
					<p>* 분만시  자국파열로 복막염을 일으킨 소(안구의 함물이 뚜렷하다.)</p>
					<img src={Pic489} className="nonborder" alt=""/>
					<p>*창상성 제2위  횡격막염에 의해 생긴 복막염:제 2위 벽을 뚫고 나온 못을 볼 수 있다.</p>
					<img src={Pic490} className="nonborder" alt=""/>
					<p>* 복막염에 의해 복강내에  염증산물이 다량 붙어 있다.</p>
				</div>
			</div>
  );
}

export default Do82;