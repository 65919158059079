import React from 'react';

// scss
import './department.scss';

function Department({ phone, department }) {
  return (
    <ul className="department">
      <li>
        <dl>
          <dt>문의전화</dt>
          <dd>{phone}</dd>
        </dl>
      </li>
      <li>
        <dl>
          <dt>담당부서</dt>
          <dd>{department}</dd>
        </dl>
      </li>
    </ul>
  );
}

export default Department;
