import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

// api
import { postState, PUBLIC_API_KEY } from 'utils/api';

// components
import CkEditor from 'components/ckeditor/CkEditorComponent';
import ErrorMessage from 'components/errorMessage/errorMessage';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

function CommonInfoEdit({
  boardCode,
  boardName,
  returnUrl,
  apiEndpoints = {
    get: '/board/select_info',
    insert: '/board/insert_info',
    update: '/board/update_info',
    delete: '/board/delete_info',
  },
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const isEdit = !!location.state?.bdc_code;

  const [formData, setFormData] = useState({
    bdc_title: location.state?.bdc_title || '', // 제목
    bdc_wname: location.state?.bdc_wname || '', // 작성자
    bdc_wmail: 'admin@dcic.co.kr', // 작성자 이메일
    brd_code: boardCode, // 게시판 ( 최신정보, 현장포토 등 ) 코드
    bdc_code: location.state?.bdc_code || '', // 게시글 코드
    bdc_body: location.state?.bdc_body || '', // 본문
    // Optional fields with default values
    bdc_type: location.state?.bdc_type === 'Y' ? 2 : 0, // 대체URL 사용 여부
    bdc_matter: location.state?.bdc_matter || 'N', // 공지 여부
    bdc_del_fl: location.state?.bdc_del_fl === 'Y' ? 2 : 0, // 등록예약 여부
    bdc_sdate: location.state?.bdc_sdate || '', // 공지 시작일
    bdc_edate: location.state?.bdc_edate || '', // 공지 종료일
    bdc_del_fl_date:
      location.state?.bdc_del_fl_date || new Date().toISOString().split('T')[0], // 등록예약 예약일
    bdc_del_fl_hour: location.state?.bdc_del_fl_hour || '0', // 등록예약 예약시간
    video_url: location.state?.video_url || '', // 동영상 URL
    file_list: location.state?.file_list || [], // 첨부파일 리스트
    delete_file_list: [], // 삭제할 파일 리스트
    add_file_list: [], // 새로 추가된 파일 리스트
  });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [useExternalUrl, setUseExternalUrl] = useState(false);
  const [isNotice, setIsNotice] = useState(location.state?.bdc_matter === 'Y');
  const [useReservation, setUseReservation] = useState(false);
  const [startDate, setStartDate] = useState(
    location.state?.bdc_sdate ? new Date(location.state.bdc_sdate) : null,
  );
  const [endDate, setEndDate] = useState(
    location.state?.bdc_edate ? new Date(location.state.bdc_edate) : null,
  );
  const [reservationDate, setReservationDate] = useState(
    location.state?.bdc_del_fl_date
      ? new Date(location.state.bdc_del_fl_date)
      : new Date(),
  );
  const [fileInputs, setFileInputs] = useState(1); // 표시할 파일 입력 필드 수

  const fileInputRefs = React.useRef([]);

  useEffect(() => {
    fileInputRefs.current = Array(fileInputs)
      .fill()
      .map((_, i) => fileInputRefs.current[i] || React.createRef());
  }, [fileInputs]);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => {
    setIsModalOpen(false);
    setErrorMessage('');
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === 'checkbox') {
      setFormData((prev) => ({
        ...prev,
        [name]: checked ? 'Y' : 'N',
      }));

      // Update UI state for checkboxes
      if (name === 'bdc_type') setUseExternalUrl(checked);
      if (name === 'bdc_matter') setIsNotice(checked);
      if (name === 'bdc_del_fl') setUseReservation(checked);
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  // 날짜 선택 핸들러 추가
  const handleDateChange = (date, field) => {
    if (field === 'startDate') {
      setStartDate(date);
      setFormData((prev) => ({
        ...prev,
        bdc_sdate: date ? date.toISOString().split('T')[0] : '',
      }));
    } else if (field === 'endDate') {
      setEndDate(date);
      setFormData((prev) => ({
        ...prev,
        bdc_edate: date ? date.toISOString().split('T')[0] : '',
      }));
    } else if (field === 'reservationDate') {
      setReservationDate(date);
      setFormData((prev) => ({
        ...prev,
        bdc_del_fl_date: date ? date.toISOString().split('T')[0] : '',
      }));
    }
  };

  const handleSubmit = async () => {
    // Validation
    if (!formData.bdc_title) {
      setErrorMessage('제목을 입력해주세요.');
      openModal();
      return;
    }

    if (!formData.bdc_wname) {
      setErrorMessage('작성자를 입력해주세요.');
      openModal();
      return;
    }

    if (useExternalUrl && !formData.bdc_body) {
      setErrorMessage('대체URL을 입력해주세요.');
      openModal();
      return;
    }

    if (isNotice && (!formData.bdc_sdate || !formData.bdc_edate)) {
      setErrorMessage('공지설정기간을 선택해주세요.');
      openModal();
      return;
    }

    if (boardCode === '1037' && !formData.video_url) {
      setErrorMessage('동영상URL을 입력해주세요.');
      openModal();
      return;
    }

    const confirmMessage = isEdit ? '수정하시겠습니까?' : '등록하시겠습니까?';
    if (!window.confirm(confirmMessage)) {
      return;
    }

    const endpoint = isEdit ? apiEndpoints.update : apiEndpoints.insert;

    try {
      const formDataToSend = new FormData();

      // Add basic form fields
      Object.keys(formData).forEach((key) => {
        // 파일 관련 키들은 제외
        const excludeKeys = ['file_list', 'add_file_list', 'delete_file_list'];

        if (!excludeKeys.includes(key)) {
          let value = formData[key];

          // bdc_type 값 변환
          if (key === 'bdc_type') {
            value = value === 'Y' ? 2 : 0;
          }

          // bdc_del_fl 값 변환
          if (key === 'bdc_del_fl') {
            value = value === 'Y' ? 2 : 0;
          }

          // 객체인 경우 JSON.stringify 처리
          value =
            typeof value === 'object' && value !== null
              ? JSON.stringify(value)
              : value;

          // bdc_del_fl 이 0일땐 값을보내지않음
          if (key === 'bdc_del_fl_date' && formData.bdc_del_fl === 0) return;
          if (key === 'bdc_del_fl_hour' && formData.bdc_del_fl === 0) return;

          formDataToSend.append(key, value);
        }
      });

      // 수정일 때와 등록일 때 파일 처리 분리
      if (isEdit) {
        // 삭제할 파일 목록 추가
        if (formData.delete_file_list.length > 0) {
          formDataToSend.append(
            'delete_files',
            JSON.stringify(formData.delete_file_list),
          );
        }

        // 새로 추가할 파일 처리
        if (formData.add_file_list.length > 0) {
          // 파일 객체 추가
          formData.add_file_list.forEach((fileObj) => {
            formDataToSend.append('add_files', fileObj.file);
          });

          // 파일 정보 추가
          const fileInfos = formData.add_file_list.map((fileObj) => ({
            bdf_fname: fileObj.bdf_fname,
            bdf_sname: fileObj.bdf_sname,
            bdf_fsize: fileObj.bdf_fsize,
            bdf_type: fileObj.bdf_type,
          }));
          formDataToSend.append('add_file_infos', JSON.stringify(fileInfos));
        }
      } else if (formData.file_list.length > 0) {
        // 신규 등록일 때는 file_list만 처리
        // 파일 객체 추가
        formData.file_list.forEach((fileObj) => {
          formDataToSend.append('files', fileObj.file);
        });

        // 파일 정보 추가
        const fileInfos = formData.file_list.map((fileObj) => ({
          bdf_fname: fileObj.bdf_fname,
          bdf_sname: fileObj.bdf_sname,
          bdf_fsize: fileObj.bdf_fsize,
          bdf_type: fileObj.bdf_type,
        }));
        formDataToSend.append('file_infos', JSON.stringify(fileInfos));
      }

      //   Array.from(formDataToSend.entries()).forEach(([key, value]) => {
      //     console.log(key, value);
      //   });

      //   console.log(PUBLIC_API_KEY + endpoint);

      //   Send request using fetch
      const response = await fetch(PUBLIC_API_KEY + endpoint, {
        method: 'POST',
        body: formDataToSend,
      });

      const result = await response.json();

      //   console.log(result);

      if (result?.return_code === 'SUCCESS') {
        alert(`${isEdit ? '수정' : '등록'}되었습니다.`);
        navigate(returnUrl);
      } else {
        setErrorMessage(result?.return_message || '오류가 발생했습니다.');
        openModal();
      }
    } catch (error) {
      console.error('Error:', error);
      setErrorMessage(error.message || '오류가 발생했습니다.');
      openModal();
    }
  };

  const handleDelete = async () => {
    if (!window.confirm('삭제하시겠습니까?')) {
      return;
    }

    try {
      const response = await postState(apiEndpoints.delete, {
        bdc_code: formData.bdc_code,
        brd_code: boardCode,
      });
      if (response?.return_code === 'SUCCESS') {
        alert('삭제되었습니다.');
        navigate(returnUrl);
      }
      if (response?.return_code === 'ERROR') {
        setErrorMessage(response?.return_message);
        openModal();
      }
    } catch (error) {
      setErrorMessage(error.message);
      openModal();
    }
  };

  // 파일 변경 핸들러
  const handleFileChange = (e, index) => {
    if (formData.file_list.length >= 3) {
      alert('첨부파일은 최대 3개까지만 가능합니다.');
      return;
    }

    const file = e.target.files[0];
    if (file) {
      const newFile = {
        bdf_fname: file.name,
        bdf_sname: file.name,
        bdf_fsize: file.size,
        bdf_type: 0,
        file, // 실제 파일 객체 저장
      };

      if (isEdit) {
        // 수정 모드일 때는 add_file_list에만 추가
        setFormData((prev) => ({
          ...prev,
          add_file_list: [...prev.add_file_list, newFile],
          file_list: [...prev.file_list, newFile],
        }));
      } else {
        // 등록 모드일 때는 file_list에만 추가
        setFormData((prev) => ({
          ...prev,
          file_list: [...prev.file_list, newFile],
        }));
      }
    }
  };

  // 파일 삭제 핸들러 수정
  const handleFileDelete = (index) => {
    const fileToDelete = formData.file_list[index];
    const newFileList = [...formData.file_list];
    newFileList.splice(index, 1);

    setFormData((prev) => {
      // 기존 파일인 경우 (bdf_code 속성이 있는 경우)
      if ('bdf_code' in fileToDelete) {
        return {
          ...prev,
          delete_file_list: [...prev.delete_file_list, fileToDelete.bdf_code],
          file_list: newFileList,
        };
      }
      // 새로 추가한 파일인 경우 (bdf_code 속성이 없는 경우)
      return {
        ...prev,
        add_file_list: prev.add_file_list.filter(
          (file) => file.bdf_fname !== fileToDelete.bdf_fname,
        ),
        file_list: newFileList,
      };
    });

    // Reset file input using ref
    if (fileInputRefs.current[index]?.current) {
      fileInputRefs.current[index].current.value = '';
    }
  };

  return (
    <div className="bo-content">
      <ErrorMessage
        isOpen={isModalOpen}
        content={errorMessage}
        closeModal={closeModal}
      />

      <h2>
        {boardName} {isEdit ? '수정' : '등록'}
      </h2>

      <div className="table-container bold-border">
        <table>
          <colgroup>
            <col width="20%" />
            <col width="*" />
          </colgroup>
          <tbody>
            <tr>
              <th>
                제목<span className="required">*</span>
              </th>
              <td className="nr">
                <label>
                  <span>본문 대체 URL 사용</span>
                  <input
                    type="checkbox"
                    name="bdc_type"
                    checked={useExternalUrl}
                    onChange={handleInputChange}
                  />
                </label>
                <input
                  type="text"
                  name="bdc_title"
                  value={formData.bdc_title}
                  onChange={handleInputChange}
                  maxLength={100}
                />
              </td>
            </tr>

            {useExternalUrl && (
              <tr>
                <th>대체URL</th>
                <td className="nr">
                  <input
                    type="text"
                    name="bdc_body"
                    value={formData.bdc_body}
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
            )}

            <tr>
              <th>등록예약</th>
              <td className="nr">
                <label>
                  <span>선택시 기간 필수</span>
                  <input
                    type="checkbox"
                    name="bdc_del_fl"
                    checked={useReservation}
                    onChange={handleInputChange}
                  />
                </label>
                <ReactDatePicker
                  dateFormat="yyyy-MM-dd"
                  selected={reservationDate}
                  onChange={(date) => handleDateChange(date, 'reservationDate')}
                  disabled={!useReservation}
                  placeholderText="예약 날짜 선택"
                  minDate={new Date()}
                />
                {useReservation && (
                  <select
                    name="bdc_del_fl_hour"
                    value={formData.bdc_del_fl_hour}
                    onChange={handleInputChange}
                  >
                    {[...Array(24)].map((_, i) => (
                      <option key={i} value={i}>
                        {i}
                      </option>
                    ))}
                  </select>
                )}
              </td>
            </tr>

            {boardCode !== '1037' && (
              <tr>
                <th>공지설정</th>
                <td className="nr">
                  <label style={{ display: 'block', textAlign: 'center' }}>
                    <span>공지여부 &#40; 선택시 기간 필수 &#41;</span>
                    <input
                      type="checkbox"
                      name="bdc_matter"
                      checked={isNotice}
                      onChange={handleInputChange}
                    />
                  </label>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <ReactDatePicker
                      dateFormat="yyyy-MM-dd"
                      selected={startDate}
                      onChange={(date) => handleDateChange(date, 'startDate')}
                      disabled={!isNotice}
                      placeholderText="시작 날짜"
                      minDate={new Date()}
                    />
                    <span style={{ margin: '0 10px' }}>-</span>
                    <ReactDatePicker
                      dateFormat="yyyy-MM-dd"
                      selected={endDate}
                      onChange={(date) => handleDateChange(date, 'endDate')}
                      disabled={!isNotice}
                      placeholderText="종료 날짜"
                      minDate={startDate || new Date()}
                    />
                  </div>
                </td>
              </tr>
            )}

            <tr>
              <th>
                작성자<span className="required">*</span>
              </th>
              <td className="nr">
                <input
                  type="text"
                  name="bdc_wname"
                  value={formData.bdc_wname}
                  onChange={handleInputChange}
                  maxLength={10}
                />
              </td>
            </tr>

            {!useExternalUrl && (
              <tr>
                <th>
                  내용<span className="required">*</span>
                </th>
                <td className="nr">
                  <div className="ckeditor-container">
                    <CkEditor
                      initialData={formData.bdc_body}
                      onChange={(data) => {
                        setFormData((prev) => ({
                          ...prev,
                          bdc_body: data,
                        }));
                      }}
                    />
                  </div>
                </td>
              </tr>
            )}

            <tr>
              <th>첨부파일</th>
              <td className="nr">
                <div className="file-inputs-container">
                  {[0, 1, 2].map((index) => (
                    <div
                      key={index}
                      className="file-input-row"
                      style={{
                        marginBottom: '10px',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <div
                        style={{ position: 'relative', marginRight: '10px' }}
                      >
                        <input
                          type="file"
                          ref={fileInputRefs.current[index]}
                          onChange={(e) => handleFileChange(e, index)}
                          onClick={(e) => {
                            e.target.value = null;
                          }}
                          style={{
                            position: 'absolute',
                            width: '0',
                            height: '0',
                            padding: '0',
                            overflow: 'hidden',
                            border: '0',
                          }}
                          id={`file-input-${index}`}
                        />
                        <label
                          htmlFor={`file-input-${index}`}
                          style={{
                            display: 'inline-block',
                            padding: '8px 16px',
                            backgroundColor: '#f0f0f0',
                            border: '1px solid #ddd',
                            borderRadius: '4px',
                            cursor: 'pointer',
                          }}
                        >
                          파일선택
                        </label>
                      </div>
                      {formData.file_list[index] && (
                        <>
                          <span style={{ marginRight: '10px' }}>
                            {formData.file_list[index].bdf_fname}
                          </span>
                          <button
                            type="button"
                            className="btn-2"
                            onClick={() => handleFileDelete(index)}
                          >
                            삭제
                          </button>
                        </>
                      )}
                    </div>
                  ))}
                </div>
              </td>
            </tr>

            {boardCode === '1037' && (
              <tr>
                <th>동영상URL</th>
                <td className="nr">
                  <input
                    type="text"
                    name="video_url"
                    value={formData.video_url}
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <div className="button-group">
        <button type="button" className="btn-l" onClick={handleSubmit}>
          {isEdit ? '수정' : '등록'}
        </button>
        {isEdit && (
          <button type="button" className="btn-l" onClick={handleDelete}>
            삭제
          </button>
        )}
        <button
          type="button"
          className="btn-l"
          onClick={() => navigate(returnUrl)}
        >
          목록
        </button>
      </div>
    </div>
  );
}

export default CommonInfoEdit;
