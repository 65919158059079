import React from 'react';
import SiteMapItem from './SiteMapItem';
import SiteMapData from './SiteMapData';

function SiteMapList() {
  
//   SiteMapData.map((page,index) => console.log(page,index));

    return (
      <div>
        <ul className="sitemap_menu">
          {
          SiteMapData.map((page, index) => (
            <SiteMapItem key={index} page={page} />
          ))
          }
        </ul>
      </div>
    );
};

export default SiteMapList;