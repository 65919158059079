import React from 'react';


// 컴포넌트
import SubTitle from 'components/subTitle/SubTitle';
import SiteMapList from './SiteMapList';

// scss
import './SiteMap.scss';

function SiteMap() {

     

  return (
    <div className="siteMap subCon">
      <SubTitle />
      <SiteMapList/>
    </div>
   );
};

export default SiteMap;
