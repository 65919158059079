import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getState } from 'utils/api';
import './notice.scss';

function combineListsToDictionary(keys, values) {
  if (keys.length !== values.length) {
    throw new Error('Keys and values lists must have the same length');
  }
  const dictionary = {};
  for (let i = 0; i < keys.length; i += 1) {
    const key = keys[i];
    const value = values[i];
    dictionary[key] = value;
  }
  return dictionary;
}

const Notice = () => {
  const tabs = ['주요소식', '알림행사', '해외토픽', '기술정보'];
  const values = ['primary', 'alarm', 'other', 'tech'];
  const tabdict = combineListsToDictionary(tabs, values);
  const [activeTab, setActiveTab] = useState('주요소식');
  const [boardData, setBoardData] = useState(null);
  const [contentData, setContentData] = useState(null);
  const tabLinks = {
    주요소식: '/Web/Info/MainNews',
    알림행사: '/Web/Info/NotiAndEvents',
    해외토픽: '/Web/Info/Overseas',
    기술정보: '/Web/Info/TechnicalInfo',
  };

  const baseDir = '/board/simple/';
  const props = { page_size: 5 };

  useEffect(() => {
    getState(baseDir, props)
      .then(getdata => {
        if (getdata !== undefined) {
          setBoardData(getdata.result);      
      }})      
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if (boardData) {
      setContentData(() => {
        const filterData = boardData.find(
          (result) => result.board === tabdict[activeTab],
        );
        return filterData;
      });
    //   console.log('### content data : ', contentData);
    }
  }, [boardData, activeTab]);

  return (
    <div className="noticeCon">
      <div className="notice-container">
        <div className="rowCon">
          <h2>Notice</h2>
          <div className="notice-tabs">
            {tabs.map((tab) => (
              <button
                type="button"
                key={tab}
                onClick={() => setActiveTab(tab)}
                className={`notice-tab ${activeTab === tab ? 'active' : ''}`}
              >
                {tab}
              </button>
            ))}
          </div>
        </div>
        <ul className="notice-list">
          {contentData &&
            contentData.content.map((content) => (
              <li key={content.bdc_code} className="notice-item">
                <Link to={content.bdc_body}>{content.bdc_title}</Link>
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
};

export default Notice;
