import React from 'react';

import pic383 from 'assets/images/sub/_images/pic383.gif';
import pic384 from 'assets/images/sub/_images/pic384.gif';
import pic385 from 'assets/images/sub/_images/pic385.gif';
import pic386 from 'assets/images/sub/_images/pic386.gif';
import pic387 from 'assets/images/sub/_images/pic387.gif';
import pic388 from 'assets/images/sub/_images/pic388.gif';

function Do208() {

  return (
    <div className="techinfoview">
      <h1>착유실</h1>
      <p className="pstyle">후리스톨 우사를 위한 착유장에는 착유실, 대기장, 우유저장실, 다용도실, 치료실, 사무실 및 휴게실이 포함된다. 대규모 목장의 경우에는 거의 하루종일 착유실이 이용되므로 착유후에 개체관리는 별도로 해야 하지만, 소규모 목장의 경우에는 착유 후에 남는 시간을 이용하여 개체관리를 할 수 있기 때문에 대기장 주위에 개체관리에 필요한 시설물을 설치하는 것이 바람직하다. </p>
      <h2>착유실 </h2>
      <p className="pstyle">&nbsp;&nbsp;&nbsp;착유실을 설계하는데 있어서 가장 염두에 두어야 하는 것이 착유실의 형태를 결정하는 일이다. 착유실의 형태는착유장의 크기, 배열 및 장소 ?소의 이동패턴 착유관행 기계화 정도 등에 영향을 미친다. 착유실의 크기는목장의 규모 초기의 기계화 정도와 향후 개선계획 착유두수 가용자본과 노동력 착유시간 우유생산량 등에 따라 결정한다. 착유실의 선정은목장규모의 확장계획 초기의 투자규모 연간 운영비 개인의 선호도 등을 고려하여 결정한다. <br/>&nbsp;&nbsp;&nbsp;착유실의 형태를 선정할 때는 실질적으로 착유하는데 소요되는 시간뿐만 아니라, 착유준비, 소의 이동 및 청소에 소요되는 시간을 모두 고려하여야 한다. 따라서, 착유실의 착유능력은 지속적으로 착유하는 것을 기준으로 1시간에 몇 두를 착유할 수 있는가에 따라 평가하여야 한다. 소규모 목장의 경우에는 실제 착유에 소요되는 시간이 짧아서 큰 착유실이나 기계화를 하더라도 많은 이익을 볼 수 없기 때문에 착유실에 많은 자본을 투자하는 것이 비효율적일 수 있다. 그러나, 노력비나 앞으로의 확장을 고려한다면 최소의 비용이 드는 착유실 형태를 선택하는 것만이 최선은 아니다. </p>
      <ul>
        <li>착유실의 형태 
          <ul>
            <li>평사식(flat barn) 
              <ul>
                <li>평사식 착유실은 6-10개의 우상을 이용하여 착유실을 만든 형태로 시설비용이 저렴한 장점이 있다. 타이스톨을 후리스톨로 바꿀 때 타이스톨에 사용했던 스탄쉥이나 고정시설을 착유시 사용할 수 있으므로 타이스톨의 일부를 착유실로 개조하고 나머지 부분을 대기장으로 이용할 수 있다. 자금사정이 좋아지면 좀더 효율적인 착유실 형태로 바꾸는 것이 바람직하기 때문에 평사식 착유실 설치에 많은 투자를 하지 않는 것이 좋다. </li>
              </ul>
            </li>
            <li>측면 출입식(side-opening parlour) 
              <ul>
                <li>측면 출입식의 경우에는 소가 양쪽 착유실에 각각 2-5두씩 길게 서서 착유하게 되어 있어서 개체관찰이 용이하다는 것이 장점이다. 그러나, 유방과 유방사이가 멀기 때문에 착유실의 효율적인 기계화에 지장을 줄 뿐만 아니라 매 2두마다 피트의 길이를 8&apos;-10&apos;씩 늘여야 하는 단점이 있다.<br/>
                  <img src={pic383} className="nonborder" width="30%" alt=""/>
                  <p>그림 54. 평사식 착유시설</p>
                  <img src={pic384} className="nonborder" width="30%" alt=""/>
                  <p>그림 55. 측면출입식 착유시설(4두 동시착유실) </p>
                </li>
              </ul>
            </li>
            <li>헤링본(herringbone) 
              <ul>
                <li>헤링본은 가장 보편적으로 이용되는 고상식 착유실 형태로, 양쪽에 각각 4-10두씩의 소를 비스듬하게 횡으로 서 있게한 상태여서 착유한다. 소의 이동이 원활하고 기계화가 용이하다. 제품에 따라서 유방과 유방간의 거리는 36&apos;에서 48&apos;로 다양하다. 착유속도가 느린 소는 전체그룹을 지체시키기 때문에 도태시키거나 따로 분리하여 착유하는 것이 바람직하다. </li>
              </ul>
            </li>
            <li>병열식(parallel) 
              <ul>
                <li>병열식은 최근에 도입된 형태로 양쪽에 각각 6-40두씩의 소를 나란히 횡으로서 있게한 상태에서 착유한다. 소가 피트와 직각으로 서기 때문에 착유자는 소의 뒷다리 사이를 통하여 유두컵을 부착시켜야 한다. 소가 진입하면 분리대가 작동하여 소 사이의 신체적 접촉을 막아준다.<br/>
                  <img src={pic385} className="nonborder" width="30%" alt=""/>
                  <p>그림 56. 헤링본(16두 동시착유실)</p>
                  <img src={pic386} className="nonborder" width="30%" alt=""/>
                  <p>그림 57. 병열식 착유시설</p>
                </li>
              </ul>
            </li>
            <li>기타형태 
              <ul>
                <li>다각형 착유실(polygon parlor)은 헤링본과 측면 출입식의 장점을 살린 형태이다. 다각형 착유실은 유방간의 거리를 좁게하고, 일반적으로 4개의 측면이 있으며, 측면당 4-10두의 착유우를 수용할 수 있다.  3각 착유실(trigon parlor)은 3개의 측면을 갖고 있는 다각형 착유실의 개조형으로 착유우 규모가 250-500두인 경우를 위해 설계되었다. 최전식 착유실(rotary parlor)은 이용효율이 좋지 않고 기계고장이 잦은 단점이 있다. </li>
              </ul>
            </li>
          </ul>
        </li>
        <li>착유장의 배치 
          <ul>
            <li>대기장으로부터 소가 방향을 틀지 않고 착유실에 직선으로 진입하고 착유 후에도 착유실을 직선으로 빠져나갈 수 있도록 착유실 입구와 출구를 마련하는 것이 좋다. 우상으로 되돌아가는 통로는 1개로 제한하며 통로의 폭은 33-36로 하여 착유실로 되돌아가지 못하게 하고 착유실밖에 통로는 트랙터로 청소할 수 있도록 넓게 만든다.<br/>
            <img src={pic387} className="nonborder" width="50%" alt=""/>
            <p>그림 58. 착유 종합시설의 여러 가지 설계도</p>
            </li>
          </ul>
        </li> 
        <li>일시 퇴장식 착유우상(rapid exit stall) 
          <ul>
            <li>착유 후 우상으로부터 소가 일시에 퇴장할 수 있도록 전진방지대를 동시에 열 수 있도록 한 착유실은 대규모 착유실의 문제점인 긴 퇴장시간을 단축시킬 수 있다. 이런 경우에는 우사로 되돌아가는 통로를, 대기장을 가운데 두고 양쪼으로 2개 마련하여야 하므로 특수관리를 요하는 소의 분리를 양쪽에서 해야 하는 번거로움이 있다. 퇴장통로가 하나인 헤링본의 착유실은 환축을 따로 분리하거나 치료하는 작업이 훨씬 쉽다.<br/>
            <img src={pic388} className="nonborder" width="50%" alt=""/>
            <p>그림 59. 일시퇴장식 착유우상</p>
            </li>
          </ul>
        </li> 
        <li>착유실의 기계화 
          <ul>
            <li>착유실의 기계화는 착유실의 규모 노동력 상황 초기 투자규모 개인의 선호도 등을 고려하여 결정한다. 기계화와 동시에 착유실의 규모를 확장하면 노동력과 시설의 이용성을 증가시킬 수 있다.</li>
          </ul>
        </li> 
      </ul>
    </div>
  );
}

export default Do208;