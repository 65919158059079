import React from 'react';

import Pic496 from 'assets/images/sub/_images/pic496.jpg';
import Pic497 from 'assets/images/sub/_images/pic497.jpg';
import Pic498 from 'assets/images/sub/_images/pic498.jpg';
import Pic499 from 'assets/images/sub/_images/pic499.jpg';

function Do85() {

  return (
    <div className="techinfoview">
				<h1>급성 고창증</h1>
				<p className="pstyle">제1위와 제2위가 내용물의 발효가스에 의해 과도하게 팽만하는 질병이다. 이는 발효가스가 위 내용물과 혼합된 포말성 고창증과 위 내용물과 분리된 단순성 고창증으로 구분된다.</p>
				<div className="leftpart">
					<h2>원인</h2>
					<ul>
						<li>포말성 고창증
							<ul>
								<li>어리고 수분이 많은 두과목초나 다량의 농후사료, 특히 고단백질의 곡류를 과식한 경우에 1위내 발효시 생기는 작은 거품이 터지지 않아 트림을 배출시키지 못한 채 제1위의 내부 압력이 상승함으로써 일어난다. </li>
							</ul>
						</li>
						<li>단순성 고창증
							<ul>
								<li>식도경색, 식도 협착, 외부로부터의 압박등에 의하여 트림 배출이 곤란한 경우에 일어난다. </li>
								<li>집단비육장에서발생하는 경우; 장기간 농후사료를 중심으로 사육할 때 드물게 유리가스성 고창증이 나타난다.</li>
							</ul>
						</li>
					</ul>
					<h2>증상</h2>
					<ul>
						<li>복부 전체가 팽만하지만 좌측겸부가 특히 심하다. </li>
						<li>병우는 불안한 모습을 나타내며 앉았다 일어서기를 되풀이한다. </li>
						<li>목을 앞으로빼고 혀를 내밀며 입으로 호흡하기 때문에 침을 많이 흘린다. </li>
						<li>위운동은 초기에는 증가하지만 점차로 미약해지며 고창이 현저해지면 정지한다. </li>
						<li>단순성 고창증일때는 좌측겸부를 두드려 보면 고음(鼓音:북소리)이 뚜렷하나 포말성 고창증일때는 그렇지 않다.</li>
					</ul>
					<h2>목장에서의 처치</h2>
					<ul>
						<li>경증(輕症)일때는 나무막대기를 입에 물려서 타액분비를 촉진시켜 포말 증가를 억제하고 강제로 운동을 시키면 효과가 나타나기도 한다. </li>
						<li>투관침은 단순성 고창증에서는 효과 있으나 포말성 고창증일때는 효과가 없다. </li>
						<li>소포제(消泡劑:시판되는 대부분의 고창증치료제)를 큰소에서는 10∼20ml 송아지에서는 5∼10ml를 30배의 미지근한 물과 혼합하여 먹인다. </li>
						<li>소포제가 없을 경우에는 피마자 기름이나 식용유를 250∼500cc 먹인다. </li>
						<li>약을 먹인후 1시간 이내 효과가 나타나지 않으면 수의사에게 의뢰하여 곧바로 제1위 절개수술을 실시해야 한다. </li>
					</ul>
				</div>
				<div className="rightpart">
					<img src={Pic496} className="nonborder" alt=""/>
					<img src={Pic497} className="nonborder" alt=""/>
					<img src={Pic498} className="nonborder" alt=""/>
					<img src={Pic499} className="nonborder" alt=""/>
				</div>
			</div>
  );
}

export default Do85;