import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';

// api
import { getState, postState } from 'utils/api';

// components
import ErrorMessage from 'components/errorMessage/errorMessage';

// constants
import { BOARD_NAME, BOARD_CODE } from 'constants/backOfficeBoard';

function EditMonthFarmBest() {
  const navigate = useNavigate();
  const { bdcCode } = useParams();
  const location = useLocation();
  const isEdit = !!bdcCode;

  const [formData, setFormData] = useState({
    bdc_wname: location.state?.bdc_wname || '',
    bdc_wmail: 'admin@dcic.co.kr',
    brd_code: BOARD_CODE.MONTH_FARM_BEST, // 고정값
    bdc_code: bdcCode || '', // 수정 시 필요한 코드
  });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => {
    setIsModalOpen(false);
    setErrorMessage('');
  };

  useEffect(() => {
    if (isEdit && !location.state?.bdc_wname) {
      getState('/board/select_farm_test', {
        bdc_code: bdcCode,
        brd_code: BOARD_CODE.MONTH_FARM_BEST,
      })
        .then((data) => {
          if (data) {
            setFormData((prev) => ({
              ...prev,
              bdc_wname: data.bdc_wname || '',
            }));
          }
        })
        .catch((error) => {
          setErrorMessage(error.message);
          openModal();
        });
    }
  }, [bdcCode]);

  const handleSubmit = async () => {
    if (!formData.bdc_wname) {
      setErrorMessage('년월을 입력해주세요.');
      openModal();
      return;
    }

    const confirmMessage = isEdit ? '수정하시겠습니까?' : '등록하시겠습니까?';
    if (!window.confirm(confirmMessage)) {
      return;
    }

    const endpoint = isEdit
      ? '/board/update_farm_test/'
      : '/board/insert_farm_test/';

    // 수정과 등록에 따라 다른 데이터 전송
    const submitData = isEdit
      ? {
          bdc_code: bdcCode,
          brd_code: formData.brd_code,
          bdc_wname: formData.bdc_wname,
        }
      : {
          brd_code: formData.brd_code,
          bdc_wname: formData.bdc_wname,
          bdc_wmail: formData.bdc_wmail,
        };

    try {
      const response = await postState(endpoint, submitData);

      if (response?.return_code === 'SUCCESS') {
        alert(`${isEdit ? '수정' : '등록'}되었습니다.`);
        navigate('/bo/farmTest/monthFarmBestList');
      }
      if (response?.return_code === 'ERROR') {
        setErrorMessage(response?.return_message);
        openModal();
      }
    } catch (error) {
      setErrorMessage(error.message);
      openModal();
    }
  };

  const handleDelete = async () => {
    if (!window.confirm('농가 정보를 삭제하시겠습니까?')) {
      return;
    }

    try {
      const response = await postState('/board/delete_farm_test/', {
        bdc_code: bdcCode,
        brd_code: BOARD_CODE.MONTH_FARM_BEST,
      });
      if (response?.return_code === 'SUCCESS') {
        alert('삭제되었습니다.');
        navigate('/bo/farmTest/monthFarmBestList');
      }
      if (response?.return_code === 'ERROR') {
        setErrorMessage(response?.return_message);
        openModal();
      }
    } catch (error) {
      setErrorMessage(error.message);
      openModal();
    }
  };

  return (
    <div className="bo-content">
      <ErrorMessage
        isOpen={isModalOpen}
        content={errorMessage}
        closeModal={closeModal}
      />

      <h2>
        {BOARD_NAME.MONTH_FARM_BEST}
        {isEdit ? ' 수정' : ' 등록'}
      </h2>
      <div className="table-container bold-border">
        <table>
          <colgroup>
            <col width="20%" />
            <col width="*" />
          </colgroup>
          <tbody>
            <tr>
              <th>년월</th>
              <td className="nr">
                <input
                  type="text"
                  value={formData.bdc_wname}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      bdc_wname: e.target.value,
                    }))
                  }
                  maxLength={6}
                  style={{ imeMode: 'disabled' }}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="button-group">
        <button type="button" className="btn-l" onClick={handleSubmit}>
          {isEdit ? '수정' : '등록'}
        </button>
        {isEdit && (
          <button type="button" className="btn-l" onClick={handleDelete}>
            삭제
          </button>
        )}
        <button
          type="button"
          className="btn-l"
          onClick={() => navigate('/bo/farmTest/monthFarmBestList')}
        >
          목록
        </button>
      </div>
    </div>
  );
}

export default EditMonthFarmBest;
