import React from 'react';

import pic711 from 'assets/images/sub/_images/pic711.jpg';
import pic712 from 'assets/images/sub/_images/pic712.jpg';
import pic713 from 'assets/images/sub/_images/pic713.jpg';

function Do146() {

  return (
    <div className="techinfoview">
      <h1>유두종:사마귀</h1>
      <p className="pstyle">바이러스의 감염에 의해 발생되는 피부병으로서 대개 산발적으로 발생하며 때로는 집단 발생하기도 한다. 감염 경로는 환우와의 접촉에 의하여 피부상처를 통해 바이러스가 침입하며 환우에 사용하던 끈, 걸레, 소독되지 않은 주사침. 착유자의 손에 의해서도 전파된다.</p>
      <div className="leftpart">
        <h2>증상</h2>
        <ul>
          <li>사마귀가 잘 발생하는 부위는 유방, 항문주위, 하복부이다. 육성우에서는 눈의 주위와 목부위에 흔히 발생한다.
            <ul>
              <li>사마귀는 딱딱한 혹 모양으로 피부에 붙어 있다.</li>
              <li>거의 별다른 해(害)없이 자연히 탈락, 소실되어 치유된다.</li>
              <li>색깔은 회색 또는 회흑색이다.</li>
            </ul>
          </li>
        </ul>
        <h2>목장에서의 처치</h2>
        <ul>
          <li>사마귀는 자연 치유되는 경향이 있으므로 이렇다할 응급처치는 없다.</li>
          <li>예방으로서는 가능하면 감염소를 일시 격리하여 건강한 소와의 접촉을 피하도록 해야한다.</li>
          <li>부식제(10% 포르마린, 1∼5% 가성가리)를 발라주거나 비소제를 투여하는 방법이 있으나 좋은 효과는 기대할 수 없다.</li>
        </ul>
      </div>
      <div className="rightpart">
        <img src={pic711} className="nonborder" alt=""/>
        <img src={pic712} className="nonborder" alt=""/>
        <img src={pic713} className="nonborder" alt=""/>
      </div>
    </div>
  );
}

export default Do146;