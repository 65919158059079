import React from 'react';

import pic259 from 'assets/images/sub/_images/pic259.jpg';
import pic260 from 'assets/images/sub/_images/pic260.jpg';
import pic261 from 'assets/images/sub/_images/pic261.jpg';
import pic262 from 'assets/images/sub/_images/pic262.gif';

function Do187() {

  return (
    <div className="techinfoview">
      <h1>켄터키부루그라스</h1>
      <p>영명 : Kentucky Bluegrass<br/>학명 : Poa Pratensis L. </p>
      <div style={{width: '60%',float: 'left'}}>
        <h2>원산지</h2>
        <p className="pstyle">&nbsp;&nbsp;&nbsp;1600년초에 북미에서부터 전해졌고 이곳이 원산지로 알려졌다. 잔디로도 이용하며 영년초지용으로도 이용한다. 목초로서 이용하는 것은 생육이 빠르고 방목 후 재생이 강한 것만 선발하였다. </p>
        <h2>성상</h2>
        <p className="pstyle">&nbsp;&nbsp;&nbsp;영년생으로서 잔디형 북방형 목초이며 잎은 부드럽고 농녹생인 하번초로서 길이는 10∼30cm, 잎의 폭은 3mm 정도이고 45°각도로 퍼지며 끝은 뾰족하다. 초장은 30∼60cm정도며 일반적으로 초장이 짧다. 꽃은 원추화서이며 종자량은 많고 종자 기부에는 거미줄 같은 털이 있다. 뿌리는 천근성의 섬유모양인 잔뿌리와 지하경으로 되어 있어 방석처럼 잘 퍼진다. 초지에서 뿌리의 분포와 지하경은 지하 5cm 범위내에 많다.</p>
        <h2>재배</h2>
        <p className="pstyle">&nbsp;&nbsp;&nbsp;서늘하고 습한 기후에서 잘 자라며, 건조한 기후 조건에서는 휴면하게 된다. 광범위한 토양 조건하에 적응하나 배수가 잘 되고 석회질 함량이 높은 토양에서 수량이 많다. 햇빛을 좋아하나 토양수분이 적당하고 비옥할 경우에는 그늘이 약간진곳에서도 잘 자란다. 켄터키부루그라스의 특별한 성질은 비료가 많이 요구되는 점이다. 생육기간 동안에는 질소질 비료의 효과가 크고 특히 봄부터 여름까지가 많이 요구된다. 인산질비료 역시 중요하며 최적 산도(pH)는 5.8∼8.2이다. 생육적온은 15∼21℃이고 여름철 고온과 건조는 장해가 크므로 1∼2주동안 비가 없을 때에는 관수를 해주는 것이 좋다.<br/>&nbsp;&nbsp;&nbsp; 산성토양과 염해지에서는 약하다.<br/>&nbsp;&nbsp;&nbsp; 초지조성시 토양피복 정도가 느리고 봄에 파종하였을 때 어린 목초는 당년에 겨우 볼 수 있을 정도지만 한번 조성된 초지에서는 매우 잘 퍼지게 된다. 높은 생산성을 요구하는 초지에서는 수량이 낮기 때문에 큰 결점으로 되어 있다. 품종으로는 스톨라 310(Stola 310), 켄 부루(Ken blue), 아킬라(Apuila), 트로이(Troy) 등이 있다. <br/>&nbsp;&nbsp;&nbsp; </p>
        <h2>이용</h2>
        <ul>
          <li>건초
            <p>때때로 건초용으로 파종하는 경우도 있다. 생육기간중 잎들은 거의 바닥에 깔린 상태이므로 건초만들기에는 적합하지 않으나 때로는 혼파초지에서는 건초로 조제하기도 한다. </p>
          </li>
          <li>방목 및 청예
            <p>토양수분이 적당하고 서늘한 기후에서는 영양분이 높고 기호성이 좋다. 비료를 많이 주면 잎의 성장 최성기에 단백질의 함량은 많아진다. 개화기나 생육 후기에는 단백질은 낮아지는 반면 섬유질은 높아진다. 켄터키부루그라스는 방목지전용 초종으로서 방목에 견디는 힘이 강하나, 과방목을 시킬때는 잡초가 침범하고 수량도 적어진다. 여름철 관수한 초지에서 방목시험 결과는 오차드그라스나 스므스부름그라스보다 증체량이 높았다.</p>
          </li>
        </ul>
      </div>
      <div style={{width: '40%', float: 'left'}}>
        <table>
          <tr>
            <td><img src={pic259} className="nonborder2" alt=""/></td>
          </tr>
          <tr>
            <td><img src={pic260} className="nonborder2" alt=""/></td>
          </tr>
          <tr>
            <td><p style={{textAlign: 'center'}}>켄터키부루그라스의 지하경</p></td>
          </tr>
          <tr>
            <td><img src={pic261} className="nonborder2" alt=""/></td>
          </tr>
          <tr>
            <td><p style={{textAlign: 'center'}}>종 자</p></td>
          </tr>
          <tr>
            <td><img src={pic262} className="nonborder2" alt=""/></td>
          </tr>
          <tr>
            <td><p style={{textAlign: 'center'}}>식물체 전체의 모양, 이삭과 종자</p></td>
          </tr> 
        </table>
      </div>
    </div>
  );
}

export default Do187;