import { PUBLIC_API_KEY } from 'utils/api';

class UploadAdapter {
  constructor(loader) {
    // CKEditor 파일 로더 인스턴스
    this.loader = loader;
  }

  // 업로드 시작
  // eslint-disable-next-line class-methods-use-this
  upload() {
    // api 완성후 다시 주석해제
    return this.loader.file.then((file) => {
      const formData = new FormData();
      formData.append('upload', file);

      const apiUrl = `${PUBLIC_API_KEY}/board/insert_image/`;

      return fetch(apiUrl, {
        method: 'POST',
        body: formData,
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          if (data?.return_code === 'ERROR') {
            throw new Error(data?.return_message || '오류가 발생했습니다.');
          }
          return {
            default: data.result,
          };
        })
        .catch((error) => {
          // 서버 업로드 실패시 base64로 변환
          console.log('이미지 업로드 실패 base64로 변환');

          return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
              resolve({
                default: reader.result,
              });
            };
            reader.onerror = () => reject(new Error('Base64 변환 실패'));
            reader.readAsDataURL(file);
          });
        });
    });
  }

  // 업로드 중단
  // eslint-disable-next-line class-methods-use-this
  abort() {
    // 업로드 중단 로직 (필요한 경우 구현)
  }
}

export default UploadAdapter;
