import React from 'react';

function Do4() {

  return (
    <div className="techinfoview">
    <h1>가축 위생과 질병</h1>
    <h2>제1장 : 총론</h2>
    <ul>
      <li><b>질병(疾病 Sickness. Disease)</b>
        <ul>
          <li style={{background:'none'}}>정상적 생활기능의 변화 즉 이상적인 생활현상을 말하며 이는 체내의 저항력과 자극이 서로 싸우게 되며 이 항쟁이 지속되는 상태를 질병이라 한다.</li>
        </ul>
      </li>
      <li><b>질병의 원인</b>
        <ul>
          <li style={{background:'none'}}>유형적으로나 무형적으로 가해진 자극은 질병의 원인이 되며 내적 원인으로는 체질과 소인이 있고 외적원인으로는 영양적, 물리적, 화학적, 생물학적, 생물학적, 정신적 원인등이다. </li>
        </ul>
      </li>
      <li><b>질병의 증상</b>
        <ul>
          <li style={{background:'none'}}>일반증상과 식욕의 변화, 영양불량, 보행, 피모, 피부, 비경, 종창, 부종, 체온, 맥박, 호흡, 비루, 1위 운동, 혼수, 신경증상, 경련등의 체표변화가 일어난다. </li>
        </ul>
      </li>
      <li><b>질병의 진단</b>
        <ul>
          <li style={{background:'none'}}>외모, 해부, 임상학적, 세균, 바이러스 및 원충 진단, 기생충 및 충란검사의 원인학적 검사와 혈액검사. 오줌검사. 혈청학적 검사. 병리검사등의 기능적 진단법이 있다. </li>
        </ul>
      </li>
      <li><b>질병의 치료</b>
        <ul>
          <li style={{background:'none'}}>치료는 몸의 기능을 회복시키고 예방적 의의까지 포함하며 건강체가 질병에 걸리지 않도록 하여 건강을 유지시키는데 그 목적이 있으며 원인적, 대증, 예방적, 진단적, 치료적 요법등의 치료법이 있으며 치료방법으론 물리적, 자극, 화학, 혈청, 예방액, 호르몬, 수액, 식이, 외과적 요법등으로 치료한다. </li>
        </ul>
      </li>
      <li><b>질병의 예방</b>
        <ul>
          <li style={{background:'none'}}>이상적인 사양관리와 양질의 조사료를 급여함으로써 반추위의 기능을 강화시켜 질병에 대한 저항력을 강하게 하며 질병 발생의 원인이 되는 사항을 사전에 조치하여 주는데 최선책이며 전염병은 발생의 3대조건인 전염원, 감염경로, 감염숙주에 대한 대책으로 소독, 해충의 구제, 예방접종등 전염원의 제거, 감염경로의 차단, 감염숙주에 대한 저항성을 증강시켜 전염병에 대한 감염을 막을 수있다. </li>
        </ul>
      </li>
    </ul>
    <h2>제 2장 : 대사성 질병(大司成疾病 Metabolic Disease) </h2>
    <ul>
      <li><b>총론</b>
        <ul>
          <li style={{background:'none'}}>소가 섭취한 사료는 반추위와 장에서 소화, 흡수되고 사료중에 함유되어 있던 여러 영양소는 혈액을 통하여 체내 각 조직에 운반되어 화학적 변화를 받는데 어떤 원인에 의하여 이런 대사작용이 원만히 이루어지지 못하여 일어나는 병을 말하며 이는 집단사육, 농후사료다급, 운동량 부족등의이유에서 분만에서 비유량이 최고인 기간에 다발하여 생산성 질병이라고도 말한다.</li>
        </ul>
      </li>
      <li><b>산욕마비(Paruurient Paresis); 유열(Milk Fever)</b>
        <ul>
          <li><b>정의</b> : 유량이 많은 3-5산우에서 분만후 48간이내에 다발하며 저칼륨혈증, 체온의 하강, 전신근육의 이와, 순화장애 및 의식 장애가 특징이며 채식 전폐, 반추와 트림이 정지되며 결국에는 혼수상태가 되고 방치하면 곧 폐사함. </li>
          <li><b>원인</b> : 장의 칼슘 흡수능력 저하와 착유함으로써 혈액내 칼슘분 양적 감소와 마그네슘의 기능장애가 발생한다.</li>
          <li><b>증상</b> : 제 3단계로 구분되는데 제 1기 증상은 흥분과민 증상으로 경련, 채식 전폐, 후지뻣뻣해지며, 제 2기는 앉아있으며 의식 희미, 위장 운동이 정지되며 체온 하가. 기립불능, 호흡시 신음소리도 들리며, 제 3기는 사지 쭉 뻗고 옆으로 누워버리며 고창증, 욕창, 오연성 폐렴등이 속발하고 혼수상태에 이르러 폐사함.</li>
          <li><b>예방</b> : 건유기에 칼슘과 인의 비율을 조절하여 급여하고 분만전 Ca첨가는 금기이며 분만 3-5일번에 비타민 D를 급여하며, 분만후에는 초유를 완전히 착유하지 말고 3-5일 지난 다음에 착유한다. </li>
          <li><b>치료</b> : 증상이 나타나면 즉시 치료해야하며 25% 글루콘산칼슘(10% 염화칼슘과 20% 포도당 동량 혼합)정맥주사하는데 표준량 주사에 10분은 소요되어야 하며 칼슘은 정맥과 피하로 반반 주사되게 한다.  </li>
        </ul>
      </li>
      <li><b>케토시스(底血糖症, Ketosis)</b>
        <ul>
          <li><b>정의</b> : 탄수화물과 휘발성지방산의 대사장애로 Ketone 혈증, Ketone 뇨증, 저혈당증과 간장글리코겐 함량의 저하로 제 1위 내용물이 이상발효되어 식욕부진, 체중감소, 유량감소, 신경성 증상이 발현하는 질병 </li>
          <li><b>원인</b> : 탄수화물과 지방대사의 이상, 제 1위 내용물 이상 발효, 부신피질 호르몬의 기능 저하, 간기능 저하등으로 케톤체가 형성되어 혈당조절 기능이 억제됨으로 발생</li>
          <li><b>증상</b> : 신경형과 소화기형으로 나타나며 분만후 10-14일경에 발생, 산유량과 체중의 급격한 감소, 뇨와 호흡에서 산성(식초)냄새, 변비(단단하고 건조함)와 흑색변에 회백색의 점막이 덮여있고 체온, 맥박, 호흡등은 정상임 </li>
          <li><b>진단</b> : 분만후 발병일자, 산성냄새, 엔실리지의 부패, Ketone 혈증, Ketone 뇨증, 저혈당증 여부, 체온, 맥박, 호흡등의 정상여부</li>
          <li><b>예방</b> : 과비금비. 양질조사료 충분급여, 부패된 사일리지 금지. 운동과 일광욕 충분, 광물질과 비타민 충분 급여, 분만직전 유도사양, 분만후 촉진 사양 실시 </li>
          <li><b>치료</b> : 고농도 포도당 (25% 또는 50%), 코르티손(Cortison)을 1일 2회 정도 투여하며 간기능 강화제는 1주일 정도 투여한다. </li>
        </ul>
      </li>
      <li><b>산후기립불능증(産後起立不能症, The &quot;Downer Cow&quot; Syndrom)</b>
        <ul>
          <li><b>정의</b> : 분만후 기립이 불가능한 상태로 2회의 Ca 주사에도 불구하고 장기간 기립불능이 특징이며 고능력우나 산차수가 많은 소에서 다발한다. </li>
          <li><b>원인</b> : 저 Ca혈증성 산욕마비의 합병증, 분만전후에 발생하는 산과질환, 후지골의 골절 탈구, 신경근육손상등과 같은 외과 질환, 산후의 산도감염증, 소화기, 신경계통의 질환, 중독증. 급성유방염, 전신쇠약등 여러병의 원인이 됨 </li>
          <li><b>증상</b> : 유열의 증상과 혼돈되어 칼슘주사제를 수일간 계속 주사해도 효과가 없으며 환축이 기립하지 못한 채 그대로 누워있다. 50%정도는 4일 이내에 기립하지만 7일이상 기립 불능우는 예후가 불량함 </li>
          <li><b>예방</b> : 분만 15-30일저에 대사축진제와 비타민 AD3E제를 근육주사하고 분만일에 칼슘, 마그네슘, 유기인제제를 피하 또는 근육주사함으로써 기립불능의 원인이 될 수 있는 질병을 예방하며 축사구조를 개선하여 소가 넘어지는 것을 방지해주며 농후 사료와 조사료를 6:4의 비율로 급여함으로써 과비되지 않도록 하여야 함. </li>
          <li><b>치료</b> : 자리깃을 많이 깔아 쾌적한 환경을 만들어 주며 하루에 4-5회 정도 누워있는 자세를 바꿔줘야하며 바닥에 닿은 부분은 욕창이 생기지 않도록 맛사지하여 혈액순환 촉진시키고 때를 맞추어 착유하여 유방염에 걸리지 않게 하는 등 세심한 간화와 비타민과 광물질이 함유되어 있는 첨가제를 사료에 섞어 먹인다. </li>
        </ul>
      </li>
    </ul>
    <h2>제 3장 : 번식장애(繁殖障碍) </h2>
    <ul>
      <li><b>난소 기능 장애</b>
        <ul>
          <li><b>둔성 발정</b> : 미약발정 배란, 분만 후 1~2회째 성주기에서 발정이 약함, 프로스타그란딘 주사</li>
          <li><b>영구황체</b> : 황체퇴행 호르몬 분비 억제로 황체 잔류, 무발정이 대표적인 증상이며, 자국내막염, 자궁축농증, 태아침지, 미이라 변성시 함께 발병, 질환치료와 황체퇴향호르몬(PGF2α)주사, 자궁축농증일때는 자궁세척과 동시 실시, 황체 제거 후 3~4일경이면 발정 나타냄</li>
          <li><b>난소낭종</b> : 난포낭종, 황체낭종, 낭포성황체에 불임증의 가장 일반적인 질병으로 고비유, 채식량의 증가. 빈번한 착유회수등의 원인으로 LH 투여 PGF2α 주사 치료하며 효과적인 치료법은 사양관리 개선이 우선임. </li>
        </ul>
      </li>
      <li><b>소의 유산을 일으키는 질병</b>
        <ul>
          <li><b>전염성 세균성</b> : 브루셀라, 렙토스피라, 캄필로박터, 리스테리아. 결핵, 창상성위염, 간농 등 </li>
          <li><b>바이러스성</b> : IBR(우전염성비기관지염), 소유행성유산, 아끼바네, BVD-MD 등 </li>
          <li><b>전염성원충성</b> : 트리코모나스, 톡스플라즈마 등 </li>
          <li><b>호르몬</b> : 에스트로겐, 글루코티코이드, 하이드로코티손 투여 및 프로케스테론의 결핍</li>
          <li><b>영양결핍</b> : 비타민 A, 옥도, 셀레늄 결핍</li>
        </ul>
      </li>
      <li><b>분만 전후 발생하기 쉬운 질병</b>
        <ul>
          <li><b>후산정체</b> : 분만후 6-8시간이 지나도 태반이 배출되지 않는 것으로 발생율이 10%이상이면 관리상의 문제가 있는 것으로 판단, 주원인은 운동부족, 농후사료의 과다급여, 영양불량으로 인한 쇠약, 비타민 A의 부족, 자궁무력증, 만성자궁염을 앓은 소, 내분기기능 장애등이며 항생제 투입후 2-3일후 수의사에 의뢰하여 태반을 제거하고 자궁을 세척한다. </li>
          <li><b>자궁내막염</b> : 자궁점막이 세균 감염 받아 염증 일으킨 상황, 불임증의 일반적인 원인 자궁세척과 액제 주입하여 치료함. </li>
          <li><b>자궁탈</b> :  분만후 자궁각이 반전되어 음문 밖으로 탈출된 상태로 발견즉시 수의사에게 치료요청. 오염, 손상 및 부종을 막기 위하여 저자극성 소독액(희석된 베타딘)으로 자궁점막을 씻어서 외음부의 위치보다 약간 높게 들어 올려주며 자궁정복후에는 재발방지를 위해 전저후고의 자세를 취하도록 우상에 받칟대를 설치해줌</li>
        </ul>
      </li>
    </ul>
    <h2>제 4장 : 주요질병</h2>
    <ul>
      <li><b>제4위전위증</b>
        <ul>
          <li><b>정의</b> : 제 4위가 정상 위치인 복저면으로부터 복부의 좌방, 제 1위와 좌측복벽 사이로 변위된 좌방전위와 제자리에서 가스가 팽창되어 위치변화나 염전된 상태인 우방전위가 있음 </li>
          <li><b>원인</b> :  제4위무력증이 근본원인이며 농후사료 과다급여, 조사료 급여량의 부족, 운동부족, 칼슘부족증, 케토시스증에 의하며 어째든 위내에 가스가 차는 것이 원인임. </li>
          <li><b>증상</b> : ① 제 4위좌방전위: 만성적 경과 농후사료의 섭취량이 급격히 감소하고 청초, 건초를 잘 먹는다. 산유량과 배분량 감소되고 설사 또는 변비증이 생기며 제 1위 운동 약함 
          ② 제 4위우방전위: 발병 수주일전 분만경력 있고, 식욕부진, 산유량 감소, 탈수, 심장 박동수 증가하고 제 4위 염전일경우에는 채식 전폐, 복통, 발로 배를 타고 꼬리 흔든다. </li>
          <li><b>예방</b> : 양질의 조사료 충분 급여, 적절한 운동과 제 1위를 강화시켜 놓는다.  </li>
          <li><b>치료</b> : 좌방전위 초기는 1위 운동 촉진제와 절식으로 조절, 그외는 수술을 한다. 우방전위 및 염전은 수술후 예후가 좋지 않기 때문에 수의사와 잘 상의해서 결정함 </li>
        </ul>
      </li>
      <li><b>부제병(Foot Rot)</b>
        <ul>
          <li><b>정의</b> : 디딤파행을 일으키는 발굽병들로서 지간부란, 지간피부염, 우상피부염, 제피염, 지간중생, 열세, 제저궤양, 제구미란, 제엽염등으로 인한 발굽 질환 </li>
          <li><b>원인</b> : 지감에 불결물 축적으로 인한 화농균, 괴저균등의 침입과 발굽삭제 미실시로 감염됨. 젖소 경우 겨울철 사사기에 많이 발생됨 </li>
          <li><b>증상</b> : 지간의 급성피부염, 종창, 동통과 디딤파행, 괴양, 괴저, 수척과 유량감소 등 </li>
          <li><b>예방</b> : 불결한 주위환경과 갑작스런 배합비의 변경금지. 양지의 조사료급여, 발굽, 소독조(3-5% 포르말린용액, 5-10%황산동, 린코마이신. 테트라싸이클린등)운용과 정기적인 발굽삭제(혹서와 분만전후 피하여)를 4개월마다 실시하여 젖소 3대질병이라는 유방염, 번식장애, 케토시스등도 예방할 수 있다. </li>
          <li><b>치료</b> : 환제를 수세하고 청결하게한 후 삭제기구로 깨끗이 정리하고 소독한다. 심한 경우는 방부붕대 및 전문 수의사에게 의뢰하여 테크노비트등을 이용한 보정 발굽등을 만들어 양쪽 발굽이 똑같이 자라도록 해주어야 함. </li>
        </ul>
      </li>
      <li><b>유방염</b>
        <ul>
          <li><b>정의</b> : 유선조직에 염증발현과 유즙의 물리적, 화학적 변화를 일으키게 하는 질병 </li>
          <li><b>원인</b> : 병원성 세균, 바이러스와 곰팡이에 의해 유두관공이나 상철르 통한 감염 </li>
          <li><b>증상</b> : 급성, 만성, 괴저성, 농양형성유방염형으로 종창, 열, 동통, 식욕결핍, 황갈색 유즙, 만성시는 국소적인 경결, 위축현상 발현 </li>
          <li><b>진단</b> : 스트립컵 검사법, 캘리포니아 유방염 검사법(CMT) </li>
          <li><b>예방</b> : 정기적인 소독, 유방세척, 본착규 실시전 전유 한두번 착유하여 유질 조사, 잔유량이 없도록 착유, 착유후 유두 소독, 발굽삭제, 전두수 건유기 치료, 유방염우는 착유 끝에 실시 </li>
          <li><b>치료</b> : 건유기에 치료, 원인균 감수성 검사후 항생제선택, 소염제 치료중에는 착유 금지 </li>
        </ul>
      </li>
    </ul>
    <h2>제 5장 : 주요 전염병</h2>
    <ul>
      <li><b>총론</b><p>목장을 경영하는데 있어서 제일 무서운 것이 전염병에 대한 피해일 것이다. 그러므로 전염병 발생의 3대조건인 전염원(환자, 보균자, 접촉자, 전염원 동물, 토양), 감염경로(접촉, 공통전파체, 공기, 절족동물의 매개에 의한 전염), 감염숙주(감수성)에 대한 대책으로 전염원의 제거, 감염경로의 차단, 감염숙주에 대한 저항성을 증강시켜 전염병의 감염을 막을 수 있으며 무엇보다도 중요한 것은 적절한 예방접종과 가축위생시험소나 수의과학연구소를 이용한 정기적인 검진이 제일 중요하다. </p></li>
      <li><b>브루셀라(Bovine Brucellosis) </b>
        <ul>
          <li><b>정의</b> : 생식기관에 염증일으키는 병, 태반에도 염증, 유산 유발, 불임우가 됨</li>
          <li><b>병원체</b> :  Brucella abortus, B melitensis, B suis, B canis, B ovis 등 </li>
          <li><b>역학</b> : 경구, 상처, 교비, 인공수정에 의함, 태아, 초유, 질, 유방, 결막, 위의 융모막등에 존재 </li>
          <li><b>증상</b> : 7-8개월째 유산, 열, 보균우가 되며 수놈은 부고환염, 정액속에 균 많다. </li>
          <li><b>예방</b> : 생균 백신 19strain 백신(유산 방지) 감염우는 도태 처분 </li>
          <li><b>치료</b> : 없음 </li>
        </ul>
      </li>
      <li><b>우결핵(Bovine Tuberculosis) </b>
        <ul>
          <li><b>정의</b> :  결핵결절, 육아병변, 건락화 석회화되는 결핵 </li>
          <li><b>병원체</b> : Mycobacterium bovis(우형), M tuberculosis(인형), M avium(조형) </li>
          <li><b>역학</b> : 호흡기, 비말, 경구, 자궁내, 동거, 공기, 야생조류, 관리인, 우유를 통한 감염 </li>
          <li><b>증상</b> : 기침, 호흡곤란, 이유없이 마르고 기관지 호흡소리, 복막에 결핵결절, 임파절종창 </li>
          <li><b>예방</b> : 튜버클린 검사하여 살처분, 위생관리 철저, 의양성우는 1개월후에 재검 </li>
          <li><b>치료</b> : 초기에는 가능하나 양성우는 살처분 </li>
        </ul>
      </li>
      <li><b>구제역(Foot-and-mouth disease; FMD)</b>
        <ul>
          <li><b>정의</b> : 구제역 바이러스에 의한 우제류 동물의 급성전염병이므로 입과 제부의 점막 및 피부에 수포를 형성하며 발육, 비유, 운동, 번식 등의 장애를 일으킨다. 야생동물을 포함하여 감수성 동물에서 많이 발생하고 전염력이 강하고 virus형이 많아서 방역이 극히 어려운 제1종 법정전염병</li>
          <li><b>병원체</b> : Picorna 바이러스 계통의 Aphtovirus 속의 7종의 형과 61종이상의 아류형 </li>
          <li><b>역학</b> : 접촉 및 공기전염, 감염동물의 생산물, 오염된 물체들에 의해 급속 전파 </li>
          <li><b>증상</b> : 열, 권태, 식욕부진, 유연, 다리 진탕, 구강, 비강, 비공, 제관 및 유두부의 수포, 혀, 치근 및 구강점막의 파열, 유방염, 유량감소, 유산, 발굽탈락, 파행 등 어린 동물에는 치사율이 높음 </li>
          <li><b>진단</b> : 임상적인 증상에 의한 불가하며 실험실의 CF, ELISA, 바이러스 중화시험 등 </li>
          <li><b>예방</b> : 오염국으로부터 동물 및 축산물의 수입금지, 발생시엔 매각, 소각처분 </li>
          <li><b>치료</b> : 없음 </li>
        </ul>
      </li>
      <li><b>탄저</b>
        <ul>
          <li><b>정의</b> : 피하점막하에 부종, 출혈, 괴사, 비강, 항, 질등에 출혈, 응고부전 발현 </li>
          <li><b>병원체</b> : Bacillus Anthracis </li>
          <li><b>역학</b> : 경피, 피부, 호흡기 감염, 곤충, 오염된 목초, 야생동물, 오염된 가죽, 털, 골부 등 </li>
          <li><b>증상</b> : 1-5일의 잠복기, 갑자기 열이 나면서 24시간내 폐사, 호흡곤란, 심계항진. 설사, 혈뇨, 독소에 의해서 죽는다. </li>
          <li><b>예방</b> : 감염우는 소각하여 매장, 사체 해부금지, 비발생지역엔 예방 필요 </li>
          <li><b>치료</b> : 페니실린, 스트렙토마이신 주사 </li>
        </ul>
      </li>
      <li><b>요네병(John&apos;s Disease)</b> 
        <ul>
          <li><b>정의</b> : 결핵과 비슷, 만성설사, 빈혈증상이 나타나며 쇠약시 죽음에 이르는 질병 </li>
          <li><b>병원체</b> : Mycobacterium Paratyberculosis </li>
          <li><b>역학</b> : 분변에 의한 경구감염, 분에 200-300일 생존 가능 </li>
          <li><b>증상</b> : 처음에는 무증상, 수년후에는 설사주증, 빈혈, 식욕이 없고 비유정지됨 </li>
          <li><b>예방</b> : 양성우는 살처분하고 의양성우는 3개월후에 재검 </li>
          <li><b>치료</b> : 없음 </li>
        </ul>
      </li>
      <li><b>백혈병(Leukosis)</b> 
        <ul>
          <li><b>정의</b> : 임파계통에 임파육종 일으키는 종양성 바이러스 질병 </li>
          <li><b>병원체</b> : Bovine Leukosis Virus- Oncovirus(종양형성 함) </li>
          <li><b>역학</b> : 수평, 수직감염, 분변, 유즙, 분비물에 의하며 송아지는 초유 통해 감염  </li>
          <li><b>증상</b> : 임파절에 임파종 형성, 빈혈, 체중감량, 비유량감소, 피부에 블록하게 형성</li>
          <li><b>진단</b> : 항원항체검출, AGP, CF, FA test, Radioimmuno Assary(동위원소 이용 검출) </li>
          <li><b>예방</b> : 양성우는 도태처분 </li>
          <li><b>치료</b> : 없음</li>
        </ul>
      </li>
      <li><b>캠피로 박터병(Campylobacteriosis) </b>
        <ul>
          <li><b>정의</b> : 만성, 불현성 생식기 질병으로 생식기에 국한되있음. 사람에게는 vibrio에 의함 </li>
          <li><b>병원체</b> :  C fetus veneralis, C fetus intestinalis </li>
          <li><b>역학</b> : 교미, 인공수정, 수놈의 포피, 암놈의 음경에 분포하여 감염 </li>
          <li><b>증상</b> : 불현성 빈우 5-7개월시 유산 </li>
          <li><b>진단</b> : 병원체 분리, semen을 슬라이드글라스에 도말, 혈청학적 반응, 동물접종법 등 </li>
          <li><b>예방</b> : 수놈의 정액검사, 암놈의 치료, 수놈의 도태 </li>
          <li><b>치료</b> : streptomicine을 생식기에 발라주고 암컷은 세척 주사도 아울러 실시 </li>
        </ul>
      </li>
      <li><b>렙토스피라병(Leptospirdsis)</b> 
        <ul>
          <li><b>정의</b> :  혈색소뇨증, 빈혈, 황달, 유산, 비유량 감소케하는 급만성 질병</li>
          <li><b>병원체</b> :  L pomoan, canicola, icterohemorrhagiae등으로 신장에 정착하여 약화시 발현 </li>
          <li><b>역학</b> : 경구감염, 호흡기계통, 피부점막으로 보균우의 뇨, 목초 오염, 쥐, 상처통한 감염 </li>
          <li><b>증상</b> : 4-10일 잠복기, 열, 식욕결핍, 결막염, 설사, 혈색소뇨증, 황달, 폐렴, 유사산 등 </li>
          <li><b>진단</b> : 뇨에서 분리, 혈청학적 검사, CF test </li>
          <li><b>예방</b> : 예방접종(6개월 간격), passive immunity(초유, 어미에게 항체받아 3개월내 접종) </li>
          <li><b>치료</b> : penicillin, tetracycline </li>
        </ul>
      </li>
    </ul>
    <h2>제 6장 : 기타</h2>
    <ul>
      <li><b>송아지 설사병</b>
        <p>송아지에 설사가 일어나면 그 조치사항으로는 ① 조기발견하여 격리 ② 설사증상의 경중에 의거 항생제 주사와 지사제의 경구투여 그리고 수액요법을 실시하는 것이 일반적인데 조금 힘들더라도 수액용법을 해주는 것이 제일 좋다. </p>
        <ul>
          <li>가
            <ul>
              <li>생릭식염수 100ml +비콤 C+카토살 15ml를 정맥주사 </li>
              <li>설파후란베린 10g을 물에 타서 경구 투여(반드시 송아지를 일으켜 세워서 먹인다.) </li>
              <li>노프신 5ml를 피하주사한다.</li>
            </ul>
          </li>
          <li>나
            <ul>
              <li>하트만 1000ml+셀레비트 10ml를 정맥주사 </li>
              <li>설사머지 10g을 물에 타서 경구 투여(반드시 송아지를 일으켜 세워서 먹인다.) </li>
              <li>빅타스 5ml를 피하주사한다. </li>
            </ul>
          </li>
          <li>다
            <ul>
              <li>5%포도당 1000ml+비타콤 20ml+ 카타살 15ml를 정맥주사  </li>
              <li>비스후란 10g을 물에 타서 경구 투여(반드시 송아지를 일으켜 세워서 먹인다.) </li>
              <li>바이트릴 50주 5ml를 피하주사한다. </li>
            </ul>
          </li>
          <li>라
            <ul>
              <li>바이트릴(송아지)을 물에 타서 경구 투여(반드시 송아지를 일으켜 세워서 먹인다.)</li>
              <li>조세키놀 5ml를 피하주사한다. </li>
              <li>킹벨린 5ml를 근육주사한다. </li>
            </ul>
          </li>
        </ul>
      </li>
      <li><b>송아지 기침병</b>
        <p>송아지에 기침, 콧물등이 발생하면 ① 동거축 전체두수 치료 ② 한번 치료시에는 최소한 3일 이상 지속적인 치료를하여야 한다. </p>
        <ul>
          <li>가
            <ul>
              <li>애드보신 5ml 피하주사 </li>
              <li>피린 10ml 근육 주사 </li>
              <li>비타콤 25ml를 피하주사한다. </li>
            </ul>
          </li>
          <li>나
            <ul>
              <li>낙설 2ml 근육 주사 </li>
              <li>조세키놀 5ml를 피하주사한다. </li>
              <li>비콤 C 25ml를 피하주사한다. </li>
            </ul>
          </li>
          <li>다
            <ul>
              <li>25%포도당 500ml+조세키놀 5ml를 정맥주사</li>
              <li>바이트릴 50주 15ml를 피하주사한다. </li>
            </ul>
          </li>
        </ul>
      </li>
      <li><b>급성고창증</b>
        <p>육성우나 큰소경우에 있어서 1위에 가스가 생성하여 문제 일으키는 고창증이 종종 발생하는데 이는 두과류의 과식, 감자나 사과 그리고 농후사료를 과식했을 때 발생하는 것으로 초저녁에 문제가 된다. 최소한 밤 9:30까지는 철저하게 순찰을 돌아야 고창증 피해를 예방할 수 있으며 발생시에는 운동을 시키고 좌측겸부부위를 맛사지하며 직장으로 손을 넣어서 분을 꺼내주는 방법을 실시한다. 주의할 사항은 절대로 소가 넘어지게 해서는 안되고 투관을 실시해야 된다. </p>
        <ul>
          <li>가
            <ul>
              <li>베네콜 10 근육주사 </li>
              <li>이도제나빌 30ml 피하주사 </li>
              <li>가스마인 20ml+스티뮤렉스 100g을 물에 타서 경구투여 </li>
            </ul>
          </li>
          <li>나
            <ul>
              <li>베네콜 10 근육주사  </li>
              <li>비카헤파 30ml 피하주사 </li>
              <li>가스마인 20ml+프로세락 100g을 물에 타서 경구투여 </li>
            </ul>
          </li>
          <li>다
            <ul>
              <li>소가 넘어져 위급시는 투관침, 칼 등으로 투관하여 가스를 빼며 일으켜 준다. </li>
              <li>바이트릴 50주 30ml 피하주사 </li>
              <li>가스마인 20ml+스티뮤렉스 100g을 물에 타서 경구투여 </li>
            </ul>
          </li>
          <li>라
            <ul>
              <li>투관을 실시하였는데도 가스가 배출 되지 않으면 수의사에 의뢰하여 수술 </li>
            </ul>
          </li>
        </ul>
      </li>
      <li><b>외부에서 소를 입식하였을 때</b>
        <p>외부구입축은 수송 스트레스에 의하여 이미 호흡기 질병이 진행중이므로 입식 첫날부터 적극적인 치료를 해야한다. 이때 치료를 못하였을 경우 2주일 뒤쯤 기침이 심하게 되면서 급속도로 병세가 악화될 수 있으므로 다음과 같은 방법으로 처치한다. </p>
        <ul>
          <li>가
            <ul>
              <li>입식 1일차 : 우사바닥을 깨끗이 청소하고 깔짚을 두둑히 깔아준다. </li>
              <li>농후사료를 일체 급여하지 않는다. </li>
              <li>볏짚이나 건초만을 깨끗한 물과 함께 준다. </li>
              <li>낙셀(항생제) 5ml, 조세키놀(해열소염제) 15ml, 비타콤(영양제) 30ml 주사 </li>
            </ul>
          </li>
          <li>나
            <ul>
              <li>입식 2일차 : 농후사료를 적량의 1/5을 급여한다. </li>
              <li>카우라이트(전해질제제)두당 50g+프로세락 100g을 사료에 섞어준다. </li>
              <li>볏짚이나 건초를 깨끗한 물과 함께 준다. </li>
              <li>낙셀(항생제) 5ml, 조세키놀(해열소염제) 15ml, 비타콤(영양제) 30ml 주사 </li>
            </ul>
          </li>
          <li>다
            <ul>
              <li>입식 3일차후 : 농후사료를 적량의 1/5 씩 증량급여(6일뒤에는 정상급여) </li>
              <li>카우라이트(전해질제제) 두당 50g +프로세락 100g을 급여(1주일까지) </li>
              <li>볏짚이나 건초를 깨끗한 물과 함께 준다.</li>
              <li>낙셀 5ml, 조세키놀 15ml, 비타콤 30ml 각각 1주일까지 주사</li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
  </div>
  );
}

export default Do4;
