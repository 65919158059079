import React from 'react';

import pic393 from 'assets/images/sub/_images/pic393.gif';
import pic394 from 'assets/images/sub/_images/pic394.gif';
import pic395 from 'assets/images/sub/_images/pic395.gif';
import pic396 from 'assets/images/sub/_images/pic396.gif';
import pic397 from 'assets/images/sub/_images/pic397.gif';

function Do211() {

  return (
    <div className="techinfoview">
      <h1>착유장의 환경</h1>
      <p>착유장에 위치한 각 방은 각각 고유한 환경을 필요로 한다. 따라서 착유자을 설계할 때는 관리체계, 환기 및 냉온방을 신중히 고려하여 환경을 제어할 수 있도록 하며, 특히 신선한 공기가 외부로부터 유입되도록 하여야 한다. </p>
      <h2>열관리</h2>
      <p className="pstyle">컴프레서로부터 발생되는 열은 그림 64와 같이 계절에 따라 제어하며, 열통중앙난방을 하는 예가 그림 65에 나타나 있다. 온수를 이용하여 중앙공급식 난방을 그림 66의 경우에는 각 방의 온도를 임의로 조절할 수 있다는 장점이 있으며 우유 냉각장치로부터 생성되는 온수를 이용할 수도 있다. 각 방에는 정해진 온도에서 작동되도록 조절된 배기팬을 설치하여야 하며 600cfm 팬용량당 1 ft크기의 공기입기구를 설치하여야 한다.</p>
      <img src={pic393} width="30%" alt=""/>
      <p>그림 64a. 컴푸레서가 우유저장실밖에 위치할경우</p>
      <img src={pic394} width="30%" alt=""/>
      <p>그림 65. 착유 종합시설의 온풍식 중앙난방시스템</p>
      <img src={pic395} width="30%" alt=""/>
      <p>그림 64b. 컴푸레서가 우유저장실안에 위치할경우</p>
      <img src={pic396} width="30%" alt=""/>
      <p>그림 66. 착유 종합시설의 온수식 중앙난방시스템</p>
      <h2>대기장 </h2>
      <p className="pstyle">대기장에서 생성되는 습기와 열기를 제거하기 위하여 환기시설이 필요하다. 자연환기를 할 경우에는 겨울철에 처마 환기구를 열어 습기를 제거한다. 여름철에는 벽을 완전히 열어주고 겨울철에는 벽을 천막천으로 덮는다. 환풍기를 이용할 경우에는 팬용량과 환기량이 권장하는 수준이어야 하며, 적절한 공기흡입구를 설치하여야 한다. 환기시설 설계에 관한 정보는. 축사환경부분에 수록되어 있다. 매우 무덥고 습도가 높은 여름철에는 선(환)풍기를 추가로 설치하거나 스프링쿨러를 설치하는 것이 바람직하다. </p>
      <h2>치료실 </h2>
      <p className="pstyle">치료실이 대기장과 붙어 있을 경우에는 대기장 환기시설을 이용한다. 그렇지 않을 때는 별도의 자연 혹은 기계식 환기장치를 마련해야 한다. 물이 얼지 않을 정도의 난방상태를 유지하도록 한다. </p>
      <h2>착유실 </h2>
      <p className="pstyle">배기팬의 위치는 대기장 또는 우사의 반대쪽 벽에 설치하며, 팬용량은 겨울철과 여름철에 착유 우상당 각각 100cfm과 600cfm이다. 입기구는 400cfm 팬용량당 1 ft 크기가 되도록 한다. 여름철 환기방식으로 그림 67에서 보는 바와 같이 통풍관을 이용하는 가압식 방법이 있다. 용량이 8,000-10,000 cm 되는 팬을 박공벽에 설치하고 공기가 통기관을 따라 착유실 안으로 불어 들어 가도록 한다. 통기관의 단면적은 1,000cfm 팬용량당 1ft가 되도록 한다. 통기과에 두 개 이상의 배기구를 마련하여 착유실 내로 신선한 공기가 유입되도록 하는데, 배기구의 전체 단면적을 합한 크기가 통기관 단면적 보다 약간 적도록 하여야 한다. <br/>착유실이 단열처리가 잘 되어 있을 경우에는 소가 충분한 열을 발산하므로, 몹시 추운 날씨 외에는 난방이 필요하지 않다. 중앙 온풍난방식의 경우에는 온풍관을 착유자 피트(operator pit)의 바닥으로부터 8위에 설치하여 온풍이 바닥으로 흐르도록 하며, 냉기관은 난방로 쪽으로 향하도록 설치한다. 난방기구를 이용하여 난방할 수도 있는데, 천장에 설치할 수 있는 복사난방기구(radiant heater)을 쓰면 착유자의 손과 소의 유방을 따뜻하게 하는데 도움이 된다.</p>
      <img src={pic397} className="nonborder" width="40%" alt=""/>
      <p>그림 67. 착유실의 기계식 환기</p>
    </div>
  );
}

export default Do211;