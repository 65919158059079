import React from 'react';

import pic448 from 'assets/images/sub/_images/pic448.gif';
import pic449 from 'assets/images/sub/_images/pic449.gif';
import pic450 from 'assets/images/sub/_images/pic450.gif';

function Do229() {

  return (
    <div className="techinfoview">
      <h1>시설자재-울타리시설</h1>
      <h2>고장력 철사울타리 (high tensil wire fences) </h2>
      <p className="pstyle">&nbsp;&nbsp;&nbsp;일반적으로 6∼8가닥으로 이루어진 고장력 철사를 이용하여 외곽 울타리를 설치한다. 목초지를 나누기 위한 내부 울타리를 설치할 때는 3∼4가닥으로 이루어진 철사가 주로 이용된다. 대개의 경우, 철사간의 간격은 6&apos;∼8&apos;이며, 맨 밑에 있는 철사는 지면으로부터 10&apos;∼14높이에 설치되고, 울타리의 높이는 4&apos;∼5&apos;이다. <br/>&nbsp;&nbsp;&nbsp;울타리 기둥 사이의 간격은 12&apos;∼15&apos;이며, 방부처리가 되었거나 재질이 섬유소유리인 기둥은 잘 이용되지 않는다. 만약 섬유소유리 기둥을 사용할 경우에는, 경사지역에 설치된 기둥이 뽑히는 문제를 방지하기 위하여 기둥을 위한 특별한 고정 시설이 필요하다. <br/>&nbsp;&nbsp;&nbsp;소가 울타리를 빠져나갈 수가 없기 때문에 다선 철사울타리(multiple wire fence)가 그 동안 많이 사용되었다. 비용이 적게 드는 단선 또는 다선 전기 철사울타리는 내부 울타리용으로는 괜찮지만, 외곽 울타리용으로 적합하지 않다. </p>
      <h2>울타리 충전기 </h2>
      <p className="pstyle">&nbsp;&nbsp;&nbsp;최소한 건물로부터 10&apos; 떨어진 곳에 방수되는 밀폐구조물 내에 울타리 충전기를 설치하여야 한다.</p>
      <h2>울타리 애자 (fence insulators) </h2>
      <p className="pstyle">&nbsp;&nbsp;&nbsp;울타리 애자는 전기울타리 철사를 기둥에 묶을 때 사용되는데, 기둥의 재질이 섬유유리인 경우에는 사용되지 않는다. 그림122에 울타리 기둥의 형태에 따른 여러 가지 종류의 애자가 나타나 있다.</p>
      <img src={pic448} className="nonborder" width="30%" alt=""/>
      <p>그림 122. 전기울타리의 기둥에 설치되는 애자의 종류</p>
      <h2>울타리 설치 </h2>
      <p className="pstyle">&nbsp;&nbsp;&nbsp;전기울타리의 성공여부는 설치에 달려있다.(그림 123). 임시울타리에 가시 없는 12 또는 14 ga 철사를 사용할 수 있는데, 눈에 잘 띄지 않고 소에 의하여 쉽게 부러지는 단점이 있다. 영구적인 울타리에는 눈에 잘 띄고, 강하며, 보다 효율적인 가시가 붙어 있는 철사를 설치하도록 한다.</p>
      <img src={pic449} className="nonborder" width="60%" alt=""/>
      <p>그림 123. 전기울타리의 설치</p><br/>
      <p className="pstyle">&nbsp;&nbsp;&nbsp;모서리에는 지름이 최소한 4인 기둥을 36깊이로 설치하고 충분히 흙을 돋궈 준다. 라인 기둥의 간격은 50&apos;∼60&apos;으로 하며, 기둥재질에는 나무, 강철, 섬유유리 등이 있다. <br/>&nbsp;&nbsp;&nbsp;울타리철사를 팽팽하게 당겨 각 기둥의 애자에 연결시키며, 전류를 지속적으로 흐르게 하기 위해서 기둥을 비켜 가는 짧은 점퍼철사(wire jumper)를 설치한다. (그림123b). 철사 두가닥을 합치어 이을 때는 ?찌를 이용하여 단단하게 연결하도록 한다. 손으로 이으면 저항이 높아져 전기울타리 효율을 감소시킨다. <br/>&nbsp;&nbsp;&nbsp;그림 123c에 나타나 있는 것과 같이 케이트를 설치한다. 짧은 길이의 플라스틱이나 고무호스를 이용하거나 구입하여 절연손잡이를 설치한다. 가장 큰 장비를 이동시켜도 전기 철사에 닿지 않을 정도로 게이트를 넓게 마련한다. 스윙게이트를 설치할 경우에는, 그림124와 같이 양쪽 게이트기둥을 2×4로 연장시킨 후 전기철사로 기둥꼭대기를 연결시킨다. <br/>&nbsp;&nbsp;&nbsp;그림 125에서 보는 바와 같이, 단선 철사를 이용하여 울타리를 만들 수 있다. 단선 철사의 높이는 가축 키의 약 2/3 정도가 되어야 하며, 단선 철사울타리는 내부 분리용으로만 사용되도록 한다. 차도를 따라 전기울타리를 설치할 경우에는, 가축이 절대 못나오도록 내구력이 있고 튼튼한 재질의 철사를 이용하여야 한다.</p>
      <img src={pic450} className="nonborder" width="60%" alt=""/>
      <p>그림 124. 전기울타리의 설치된 스윙게이트 그림 125. 전기울타리 내의 공간구분</p>
    </div>
  );
}

export default Do229;