import React from 'react';

// 컴포넌트

// scss
import './webpage_list.scss';

// 이미지

// import imgA1 from 'assets/images/containers/data/relatedSites/artificial/imgSite01_1.jpg';
// import imgA2 from 'assets/images/containers/data/relatedSites/artificial/imgSite01_2.jpg';
// import imgA4 from 'assets/images/containers/data/relatedSites/artificial/imgSite01_4.jpg';
// import imgA5 from 'assets/images/containers/data/relatedSites/artificial/imgSite01_5.jpg';
import imgA9 from 'assets/images/containers/data/relatedSites/artificial/imgSite01_9.jpg';
// import imgA10 from 'assets/images/containers/data/relatedSites/artificial/imgSite01_10.jpg';
// import imgA11 from 'assets/images/containers/data/relatedSites/artificial/imgSite01_11.jpg';
import imgA12 from 'assets/images/containers/data/relatedSites/artificial/imgSite01_12.jpg';
// import imgNO from 'assets/images/containers/data/relatedSites/imgSite_no.gif';

function Artificial() {
  return (
    <div className="Artificial">
      <div className="webpage">
        <dl className="webpage_list">
          {/* <dd>
                    <div className="webpage_banner">
                        <img src={imgA1} alt="동부한농화학(한성약품)"/>
                    </div>
                    <p>동부한농화학(한성약품)</p>                    
                </dd> */}

          {/* <dd>
                    <div className="webpage_banner">
                        <img src={imgA2} alt="유한양행"/>
                    </div>
                    <p>유한양행</p>                    
                </dd> */}
          {/* <dd>
                    <div className="webpage_banner">
                        <img src={imgNO} alt="미원사료"/>
                    </div>
                    <p>미원사료</p>                    
                </dd> */}

          {/* <dd>
                    <div className="webpage_banner">
                        <img src={imgNO} alt="제일화학"/>
                    </div>
                    <p>제일화학</p>                    
                </dd>
                <dd>
                    <div className="webpage_banner">
                        <img src={imgNO} alt="삼지약품"/>
                    </div>
                    <p>삼지약품</p>                    
                </dd>
                <dd>
                    <div className="webpage_banner">
                        <img src={imgNO} alt="파마코리아"/>
                    </div>
                    <p>파마코리아</p>                    
                </dd> */}
          <dd>
            <a href="https://www.sj.co.kr/" target="_blank" rel="noreferrer">
              <div className="webpage_banner">
                <img src={imgA9} alt="(주)선진" />
              </div>
              <p>(주)선진</p>
            </a>
          </dd>

          {/* <dd>
                    <div className="webpage_banner">
                        <img src={imgA10} alt="퓨리나 코리아"/>
                    </div>
                    <p>퓨리나 코리아</p>                    
                </dd>
                <dd>
                    <div className="webpage_banner">
                        <img src={imgA11} alt="(주)한국미생물연구소(KOEX)"/>
                    </div>
                    <p>(주)한국미생물연구소(KOEX)</p>                    
                </dd> */}
          <dd>
            <a
              href="http://www.kicfeed.com/korea.asp"
              target="_blank"
              rel="noreferrer"
            >
              <div className="webpage_banner">
                <img src={imgA12} alt="해표사료" />
              </div>
              <p>해표사료</p>
            </a>
          </dd>
          {/* <dd>
                    <div className="webpage_banner">
                        <img src={imgNO} alt="우성사료"/>
                    </div>
                    <p>우성사료</p>                    
                </dd>
                            */}
        </dl>
      </div>
    </div>
  );
}

export default Artificial;
