import React, { useState , useEffect } from 'react';
import { Link , useNavigate } from 'react-router-dom';
import useWindowSize from 'hooks/useWindowSize';

// 컴포넌트
import NavMobile from './nav/NavMobile';
import NavPc from './nav/NavPc';

// scss
import './header.scss';

function Header() {
  
  const windowSize = useWindowSize();
  const navigate = useNavigate();

  // 네비게이션 오픈
  const [ navOpen , setNavOpen ] = useState(false);
  
  // 너비에 따른 네비게이션 변경
  const moveAnotherPage = () => {
    if (windowSize.width >= 1440) {
      navigate('/Web/Guide/SiteMap');
    } else {
      setNavOpen(!navOpen);
    }
  };
  
  // 화면 너비 변경시 네비게이션 열고닫기 초기화
  const resetNavOpen = (width) => {
    if (width >= 1440) {
      setNavOpen(false);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', resetNavOpen(windowSize.width));

    return () => {
      window.removeEventListener('resize', resetNavOpen(windowSize.width));
    };
  }, [windowSize.width]);

  return (
    <header id='header' className='header'>
      <div className='headerInner'>
        <h1>
          <Link to='/Web/Main'>
            <span className='hidden'>젖소개량사업소 로고</span>  
          </Link>
        </h1>
        {
          windowSize.width < 1440 
          ?
          <NavMobile navOpen={navOpen} setNavOpen={setNavOpen}/>
          :
          <NavPc />
        }
         <ul className='en_ko'>
          <li>
            <a href='/eng'>English</a>
          </li>
          <li>
            <a href='/Web/Main'>Korean</a>
          </li>
        </ul>
        <button 
          type='button' 
          className='navBtn'
          onClick={()=> moveAnotherPage()}
        >
          <span className='hidden'>네비게이션 버튼</span>
        </button>
      </div>
    </header>
  );
}

export default Header;
