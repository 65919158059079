import React, { useEffect } from 'react';
import { Link, Outlet, useLocation , useNavigate} from 'react-router-dom';

// 컴포넌트
import SubTitle from 'components/subTitle/SubTitle';
// scss
import './qnaTechnicalInfo.scss';

function QnaTechnicalInfo() {
  
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(()=> {
    navigate('/web/Data/QnaTechnicalInfo/QnaHome');
  },[]);

  return (
    <div className="qnaTechnicalInfo subCon">
      <SubTitle />
      <ul className='oxDataTab'>
        <li>  
          <Link 
            to='QnaHome' 
            className={location.pathname.includes('QnaHome') ? 'current' : ''}
          >국내기술정보</Link>
        </li>
        <li>  
          <Link
            to='QnaForeign'
            className={location.pathname.includes('QnaForeign') ? 'current' : ''}
          >외국기술정보</Link>
        </li>
      </ul>
      <Outlet />
    </div>
  );
}

export default QnaTechnicalInfo;
