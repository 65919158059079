import React from 'react';

function Do212() {

  return (
    <div className="techinfoview">
      <h1>착유장비</h1>
      <p className="pstyle">&nbsp;&nbsp;&nbsp;우유를 수거하고. 냉각하며, 저장하는데 사용되는 모든 장비가 올바로 작동할 수 있도록 하는 것은 유질을 보전하는데 매우 중요하다. <br/>&nbsp;&nbsp;&nbsp;착유에 가장 보편적으로 이용되는 방법은 진공 파이프라인 시스템(pipeline system)이다. 이 방법에서는 파이프라인을 통하여 우유가 집유조(milk trviever)에 고이게 되고, 고인 우유를 펌프로 저유저로 이동시킨다. 우유와 진공 라인은 높게 설치될 경우에 착유우상으로부터 4&apos;높이에 일반적으로 위치하기 때문에 강한 진공(13∼15Hg)이 소요되고, 착유우상 높이나 그 이하에 위치할 경우에는 진공압(11∼13Hg)이 낮아도 된다. 라인의 위치가 높으면 잦은 진공압의 변화로 유두와 유방에 손상을 줄 수 있다. <br/>&nbsp;&nbsp;&nbsp;진공압의 변화를 감소시키기 위하여 집유조에 연결되는 부분의 우유라인은 2개의 흡입구가 달린 완전한 고리가 되게 한다. 우유가 원활하게 통과되고 진공압 변화를 감소시키기 위하여 우유라인은 파이프의 크기에 따라서 일정한 경사를 유지하여야 한다. (표85) 각 부분의 최소 진공요구량이 표 86에 나타나 있는데, 집유조 주위의 진공수준이 0.6Hg이하로 내려가서는 안된다. 착우장비의 사용 또는 설치에 관한 것은 제조회사에 문의하여 정확히 하는 것이 여러 가지 문제를 줄이는 가장 좋은 방법이다.</p><br/>
      <p><b>&lt;표 85&gt; 파이프의 굵기에 따른 우유라인의 기울기</b></p>
      <table className="tablestyle">
        <tr>
          <td>파이프 굵기 , in</td>
          <td colSpan="5">단위/ 기울기</td>
        </tr>
        <tr>
          <td></td>
          <td colSpan="5">---------------------------- 기울기 ------------------------------</td>
        </tr>
        <tr>
          <td></td>
          <td>0.8%(1/10&apos;)</td>
          <td>1.0%(1.25/10&apos;)</td>
          <td>1.2%(1.5/10&apos;)</td>
          <td>1.5%(1.75/10&apos;) </td>
          <td>2.0%(2.5/10&apos;)</td>
        </tr>
        <tr>
          <td>2.0</td>
          <td>2</td>
          <td>3</td>
          <td>3</td>
          <td>4</td>
          <td>5</td>
        </tr>
        <tr>
          <td>2.5</td>
          <td>6</td>
          <td>7</td>
          <td>8</td>
          <td>9</td>
          <td>10</td>
        </tr>
        <tr>
          <td>3.0</td>
          <td>11</td>
          <td>13</td>
          <td>15</td>
          <td>17</td>
          <td>24</td>
        </tr>
      </table>
      <p>주: 1&apos; =30.48cm 1&apos;=2.54 cm</p><br/>
      <p><b>&lt;표 86&gt; 착유에 필요한 장비별 최소 진공요구량 </b></p>
      <table className="tablestyle">
        <tr>
          <td>구 분</td>
          <td>펌프용량,cfm</td>
        </tr>
        <tr>
          <td>착유기</td>
          <td>6.0</td>
        </tr>
        <tr>
          <td>밀크미터</td>
          <td>1.0</td>
        </tr>
        <tr>
          <td>Sanitary coupling per 20</td>
          <td>1.0</td>
        </tr>
        <tr>
          <td>우유 유입밸브와 stall cock per 10</td>
          <td>1.0</td>
        </tr>
        <tr>
          <td>진공조절기를 위한 예비진공</td>
          <td>3.0</td>
        </tr>
        <tr>
          <td>계량 단지</td>
          <td>1.0</td>
        </tr>
      </table>
      <ul>
        <li>우유냉각 
          <ul>
            <li>첫 번째 우유는 30분 이내에 4.4℃까지 냉각시켜야 하며, 다음에 착유한 우유와 섞였을 때 혼합된 우유의 온도가 10℃이하를 유지하여야 한다. 저유조 내부에 부착된 온도계를 정기적으로 점검하여 고장 유무를 확인하여야 한다. 우유의 온도가 1.7℃ 이하로 떨어지면, 지방함량이 낮게 측정되며 냉각판 위의 우유가 얇게 얼어 우유의 풍미에 손상을 준다. <br/>우유의 냉각법은 다음과 같이 3가지로 구분된다. 
            <ul>
              <li>전통적인 방법으로, 컴프레서와 컨덴서(condenser)를 이용한 저유조 냉각기, 컴프레서의 크기는 탱크안으로 1시간당 유입되는 우유의 양에 따라 결정된다. </li>
              <li>저유조 냉각기와 열교환기를 혼용하는 형태. 집유조와 저유조 사이에 냉각판과 냉각 튜브를 설치하여 열교환기를 통과한 물이 우유가 흐르는 반대방향으로 흐르도록 하여 우유를 냉각시킨다. 우유의 온도는 열교환기의 표면적, 물의 온도, 물과 우유의 유속에 따라 결정된다. </li>
              <li>열교환기와 얼음물을 이용하는 방법. 얼음물이 열교환기를 순환하도록 하는 방법으로 저유조로 유입되는 우유의 온도는 2.2℃ 정도이다.</li>
            </ul>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
}

export default Do212;