import React from 'react';


function Do30() {

  return (
    <div className="techinfoview">
				<h1>젖소의 요소사양</h1>
				<p className="pstyle">&nbsp;&nbsp;&nbsp;사료에 첨가는 요소에는 45%의 질소가 함유되어 있다. 따라서 요소 1kg은 단백질 2.8kg(1kg×0.45×6.25)과 맞먹 는 사료가치를 가지고 있다. 요소는 젖소에게 아주 싼 가격으로 단백질을 공급해 주기 때문에 잘만 이용하면 아주 경제적인 단백질 첨가제가 될 수 있다. 요소를 효율적으로, 안전하게 이용하는 방법을 열거하면 다음과 같다.</p>
				<ul>
					<li>요소의 섭취량은 두당 하루에 180g 이하로 제한해야 한다. 이는 단백질로 환산하면 500g에 해당하는 양이다.</li>
					<li> 농후사료 및 조사료를 합한 총 건물소비량의 1% 이하에 해당되는 요소를 급여한다. 젖소는 체중의 3.0∼3.5%에 해당하는 건물을 섭취하기 때문에 이런 비율로 요소를 급여하면 체중이 600kg인 소는 요소를 하루에 180g 보다 약간 많이 섭취하게 된다.</li>
					<li>요소의 급여상한을 넘지 않게 하기 위해서는 농후사료에 들어 있는 요소의 양을 정확하게 파악하고 또 옥수수 사이레지에 요소를 첨가했는지 여부를 미리 알고 사양계획을 수립해야 한다.</li>
					<li>사료에 필요한 에너지와 광물질이 충분히 들어 있어야 한다. 옥수수, 귀리, 보리 등은 전분이 많이 들어 있어 위내 미생물이 질소를 이용하는데 필요한 에너지 공급을 돕는다. 그리고 황숙기에 수확해서 제조한 옥수수사이레 지도 좋은 에너지원이 된다. 필수아미노산을 합성하기 위해서는 유황과 인이 필요하므로 이들 무기물도 첨가 급여 하는 것이 좋다.</li>
					<li>제 1위에 단시간동안 너무 많은 양의 요소가 쌓이지 않도록 해야 한다. 이를 위해서는 요소가 전체사료에 균일 하게 혼합되도록 해야 한다.</li>
					<li>완전히 균일하게 혼합된 상태로 머물러 있도록 해야 한다. 요소는 입자가 작기 때문에 사료포의 밑바닥으로 몰 리거나, 거친사료와 혼합했을 때는 사료조 밑에 몰릴 가능성이 있다. 따라서 소량의 당밀을 첨가 배합하게 되면 이 런 문제점을 해결 할 수 있다. 펠렛팅도 한가지 방법이다.</li>
					<li>요소가 들어있는 사료에 적응할 수 있는 시간을 3주 정도 주어야 한다. 요소를 첨가하면 기호성이 떨어지므로 처음에는 요소의 함량을 낮게 하고 또한 기호성이 높은 사료와 함께 급여하는 것이 좋다. 그리고 요소가 들어 있 는 사료의 섭취속도가 느린 경향이 있다. 당밀을 첨가하면 기호성도 높일 뿐 아니라 균일한 배합상태의 유지에도 도움이 된다.</li>
					<li>날콩과 함께 요소를 급여하지 않아야 하는데 이는 날콩속에 요소를 암모니아로 전환시키는 효소(urease)가 들 어있기 때문이다. 요소가 암모니아로 변하는 것은 제 1위내에서 일어나지만 사료로 배합된 상태에서 이런 현상이 일어나면 기호성이 떨어질 뿐만아니라 질소가 암모니아가스 형태로 날아가게 되어 사료가치 저하를 초래하며, 과 도한 암모니아 생성에 의한 요소중독 현상이 초래된다. 열처리한 콩이나 대두박은 이러한 효소가 파괴되므로 요소 와 함께 급여하여도 무난하다.</li>
					<li>요소를 수분함량이 많은 농후사료와 혼합 급여하면 기호성이 떨어진다. 이는 고수분 사료의 발효과정에서 요소 가 암모니아 형태로 전환되기 때문이다.</li>
					<li>요소가 함유된 시판 배합사료를 제조회사의 지침에 따라 급여하는 것이 비교적 안전하다. 단백질 함량이 14∼ 16%인 완전배합사료에 요소를 첨가시킬때는 최하수준으로 하는 것이 좋다.</li>
					<li>제1위내 미생물의 단백질 합성능력이 제한되어 있기 때문에 반추위내에서 사료단백이 비단백태 질소화합물로 분해되는 것은 불가피한 일이다. 이런점을 감안할 때 완전배합사료에 건물기준 13% 이상의 자연단백질이 함유되 어 있는 경우는 요소첨가의 효과가 나타나지 않는다. 따라서 고단백사료를 급여해야 할 육성중의 젖소나 우유생산 량이 일당 23kg 이상인 착유우에는 요소를 급여하지 않은 것이 좋다.</li>
					<li>요소를 한꺼번에 많은 양 섭취하게 되면 소가 흥분하고 근육이 떨리며 숨이 가쁜 요소중독 현상이 일어난다. 이때는 지체하지 말고 물에 초산을 5% 정도되게 타서 급여하면 해독이 된다. 응급치료로서 4l 정도의 식초를 먹 이면 된다.</li>
					<li>제1위의 기능이 발달되기 전에 송아지는 요소를 제대로 이용하지 못한다. 따라서 4개월령 이전의 송아지에게는 요소를 급여하지 않는 것이 좋다.</li>
				</ul>
			</div>
  );
}

export default Do30;
