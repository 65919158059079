import React from 'react';

import d1by10 from 'assets/images/sub/_images/1_10.gif';
import d1by11 from 'assets/images/sub/_images/1_11.gif';
import d1by61 from 'assets/images/sub/_images/1-61.jpg';
import d1by63 from 'assets/images/sub/_images/1-63.jpg';
import d1by67 from 'assets/images/sub/_images/1-67.jpg';

function Fo24() {

  return (
    <div className="techinfo_view">
				<p className="viewTitle">첫 임신 중기의 관리</p>
				<h2>스탄촌(Stanchion)에 적응시킨다. </h2>
				<ul className="listStyle04 mg15f">
					<li>개방형의 사양관리가 증가하고, 분만까지 Stanchion(스탄&amp;촌)의 연습을 할 기회가 적어지고 있다. 분만후, 스탄촌우사에서 착유할 경우는 사전에 길들여 두는 것이 필요.</li>
					<li>스탄촌(Stanchion)으로 길들여져 있지 않으면
						<ul>
							<li>a: 기상과 취침할 때에 무릎을 맞부딪쳐 상처를 내 사지의 고장의 원인이 된다.</li>
							<li>b: 사지의 고장으로 인한 식욕부진으로 제 4위 변위와 대사병에 걸리기 쉬워진다.</li>
							<li>c: 스탄촌의 길들이기를 육성의 전기, 중기로 하면 Stress로 발육이 정체된다.</li>
						</ul>
					</li>
					<li>임신을 확신하고 나서, 분만 준비를 시작하기 전까지로 한다.</li>
				</ul>
				<div className="imgBox mg60f"><img src={d1by61} alt="" title="무릎이 부어 있는 초임우(初任牛)는 능숙하게 일어날 수 없다." /></div>
				
				<h2>미경산우의 유방염의 예방.</h2>
				<ul className="listStyle04 mg15f">
					<li>흡혈곤충을 예방한다.
						<ul>
							<li>※ 쇠파리(warble fly), 등애(horse fly), 모기(mosquito), 이(lice), 벼룩(flea), 진드기(tick) </li>
							<li>a: (dust bag)</li>
							<li>b: (ear tag) </li>
						</ul>
					</li>
					<li>질척거리는 패독(Paddock)의 유방의 오염을 막는다.</li>
					<li>유방염유를 먹은 자우에 의한 유두의 빈 것을 빠는 것을 막는다.</li>
				</ul>
				<div className="imgBox mg60f"><img src={d1by63} alt=""/></div>
				
				<h2>체격과 초산시 유량</h2>
				<ul className="listStyle04 mg15f">
					<li>여윈 소는 유량, 유성분이 낮고, 발정회귀도 늦다</li>
					<li>여윈 소는 섭취한 영양을 비유보다 체력의 회복에 돌린다</li>
					<li>증체가 적으면 성장호르몬의 분비도 적다.</li>
					<li>육성후기의 소는 관리가 용이하게 &#39105;문에 잊혀지는 존재가 되기 쉽고, 증체를 확보하는 것이 어렵다. 특히 육성목장에 예탁하고 있는 소는 앞당겨 방목시켜 관리한다.</li>
					<li>임신 200일을 지났다면 태아의 발육으로 영양이 사용되므로 증체시키는 것은 어렵다. 200일 전까지로 비육을 조정해 둔다. 무리하게 증체시키면 난산의 원인이 되기 쉽다.</li>
					<li>증체를 확보하여 체력을 길러 분만하게 하자.!! </li>
				</ul>
				<div className="imgBox mg60f"><img src={d1by10} alt="" title="수태율의 DG가 초산시 유량에 크게 영향한다. 수태후도 관리에 충분히 주의하자."/> <img src={d1by11} className="mg20l" alt="" title="주) 점선은 건유기 60일간으로서 비유의 종료할 시기를 나타낸다. "/></div>
				
				<h2>육성우와 건유우를 동거시켜도 좋은 가?</h2>
				<ul className="listStyle04 mg15f">
					<li>영양의 요구량이 다르기 때문에 양방의 조건을 만족시키는 것은 어렵다. </li>
					<li>육성우로 맞게 하면 건유우는 과비(비만)과 단백질 과잉등 해가 된다.</li>
					<li>건유우로 맞추면, 육성우의 증체를 확보할 수 없다.</li>
					<li>체력차가 있기 때문에 육성우가 먹다 남긴다.</li>
					<li>육성우와 건유우는 따로 사육한다.!! </li>
				</ul>
				<div className="imgBox"><img src={d1by67} alt="" title="거의 동일월령의 우군으로 하여 건유우와는 따로 사육한다."/></div>
			</div>
  );
}

export default Fo24;
