import React from 'react';

function Fo52() {

  return (
    <div className="techinfo_view">
				<p className="viewTitle">소금 결핍증</p>
				<dl>
					<dt>발병대상</dt>
					<dd>모든 가축과 사람</dd>
				</dl>
				
				<dl>
					<dt>원인</dt>
					<dd>소금 부족으로 인하여 발생</dd>
				</dl>
				
				<dl>
					<dt>징후 및 증상</dt>
					<dd>식욕부진, 성장지연, 체중감소, 피모불량, 산유량 감소 및 소금탐욕현상을 나타낸다. 대개 이유후에 발생되며 혹서의 환경하에서 심한 작업을 하는 말에서 땀과 오줌 배설량의 증가로 인하여 소금 결핍증에 걸리기 쉽다. 염분이 지나치게 체외로 빠져 나가면 쉽게 지치며 땀을 흘리지 못하게 되고 근육경련을 일으킨다.</dd>
				</dl>
				
				<dl>
					<dt>질병분포와 손실규모</dt>
					<dd>전 세계적으로 분포되어 있고 특히 초식동물에 많이 발생된다.</dd>
				</dl>
				
				<dl>
					<dt>치료</dt>
					<dd>소금 결핍인 가축에 점진적으로 소금에 적응토록 하고 완전한 자유섭취가 이루어질 때까지 손에 올려 놓고 급여하면서 급여량을 늘려준다.</dd>
				</dl>
				
				<dl>
					<dt>대책</dt>
					<dd>필요한 만큼 마그네슘을 공급하고, 방목지에 루핀이 많이 자라고 있는 지역에 임신중에 가축의 방목을 피한다.</dd>
				</dl>
				
				<dl>
					<dt>예방</dt>
					<dd>항상 충분한 양의 소금을 공급하여 주되 자유섭취토록 해준다.</dd>
				</dl>	
				
				<dl>
					<dt>참고</dt>
					<dd>보통 소금은 초식동물에 가장 쉽게 값싸게 공급할 수 있는 필수적인 무기물중의 하나이다. 그러나 탈수상태의 가축에 지나친 양의 소금 공급은 오히려 중독을 유발할 수 있다. </dd>
				</dl>
			</div>
  );
}

export default Fo52;
