import React from 'react';

import pic230 from 'assets/images/sub/_images/pic230.jpg';
import pic231 from 'assets/images/sub/_images/pic231.gif';
import pic232 from 'assets/images/sub/_images/pic232.jpg';

function Do194() {

  return (
    <div className="techinfoview">
      <h1>버드스 트레포일</h1>
      <p>영명 : Birdsfoot trefoil<br/>학명 : Lotus corniculatus </p> 
      <div style={{width: '60%' , float: 'left'}}>
        <h2>원산지</h2>
        <p className="pstyle">&nbsp;&nbsp;&nbsp;원산지는 유럽과 아시아 일부지역으로 1934년에 재배된 기록이 있으며, 현재에 이르러 중요한 목초로 되어 있으며, 척박한 토양이나 토양수분이 많아 알팔파 생육이 불량한 곳에서도 생육이 잘 된다.<br/>&nbsp;&nbsp;&nbsp;버드스 트레포일은 앰파이어계통(Empire Type)과 유럽피안계통(European Type)의 2가지 계통이 있다.<br/>&nbsp;&nbsp;&nbsp;앰파이어계통은 반직립형이고, 꽃이 유럽피안계통보다 10∼14일 늦게핀다. 유럽피안형계통은 초봄 일찍이 생육을 시작하며 직립형이고 앰파이어계통보다 조숙성이고 재생이 잘 된다. 그러나 앰파이계통은 내한성이 강하다.</p>
        <h2>성상</h2>
        <p className="pstyle">&nbsp;&nbsp;&nbsp;다년생 두과목초로서 어린식물은 작고 약한 뿌리를 가지고 있으나 성숙기에 이르면 사방으로 직근을 뻗으며 직근은 토양 깊숙히 뻗는다. 줄기는 많이 갖고 있으나 알팔라와 같이 그리 크지도 않으며, 직립하여 성장하지도 않는다. 2개의 큰탁엽은 5개의 엽편을 갖고 있으며, 꽃색은 담황색 및 오렌지색이고 종자 꼬투리는 새 다리와 같은 모양으로 붙어 있기 때문에 버드스 트레포일이라는 이름을 붙이게 됐다. 때로는 꽃이나 종자가 지표 가까이 줄기에 붙어 있기 때문에 방목을 짧게 한다하여도 낙종에 의하여 자생되는 경우가 흔히 있다. 잎은 잎자루가 없고 호생하는 5개의 소엽으로 이루어졌으며, 두과목초중 유일하게 5소엽을 갖인 목초이다. 자화불임으로 주로 꿀벌에 이해 수분이 된다.</p>
        <h2>재배</h2>
        <p className="pstyle">&nbsp;&nbsp;&nbsp;수분이 많은 토양에도 잘 견디며 특히 내염성이 두과목초 중 가장 강하기 때문에 간척지의 초지에 적합하다. 또 수주일간의 침수에도 잘 견딘다.<br/>&nbsp;&nbsp;&nbsp;내산성도 강하고 또한 알팔파보다 척박지에도 잘 자란다. 알카리성 토양이나 염기성 토양에도 강하며, 건조한 지역에서도 생육이 잘 된다. 그러므로 봄철에 장마가 지고 여름철에는 건조한 저지대에서도 생육이 잘 된다. 알팔파와 같이 가을에 뿌리 부위에 영양저장이 적을 때는 내한성이 문제가 되는 경우도 있으며, 다른 목초와 경합이 될 때도 경합에 약하다. 특히 초장이 큰 티모시나 부롬그라스와 같이 부드러운 화본과 목초와의 혼파하였을 때는 그늘에는 매우 약하여 문제가 된다. 쓴맛이 있다고 하지만 가축을 길이 들면 잘 먹게 되고 쓴맛이 사료가치에 문제가 되지는 않는다.<br/>&nbsp;&nbsp;&nbsp;그늘에 약한 문제 때문에 재배기간중 혼파는 가능한한 피하여야하며 잡초는 필히 제거해 주어야 한다. 그러므로 혼파보다는 단파를 하는 것이 가장 좋은 방법이다. 종자는 매우 적은 소립이나 파종깊이가 너무 깊지 않는한 그리 염려할 바는 아니다. 또한 버드스? 트레포일은 질소고정을 위하여 특별한 뿌리혹 세균을 접종하여야 하나 특히 뿌리혹 세균이 부족하여 생육이 잘 되지 않을 때는 세심한 관리가 더욱 중요하다. 품종으로는 바이킹(Viking), 맨스필드(Mansfild), 앰파이어(Empire), 다콘(Dacon), 캐럴(Carroll) 등이 있다.</p>
        <h2>이용</h2>
        <ul>
          <li>건초
            <p>건초조제하기에 알맞은 목초이나, 오직 1회 예취할 수 밖에 없다. 일반적으로 수확량은 알팔파의 2/3정도가 되나 수분함량이 많아 건초를 조제하는데는 알팔파보다 어렵다. 그러나 건초의 질은 거의 알팔파 건초에 버금간다.</p>
          </li>
          <li>방목 및 청예
            <p>다른 두과목초보다 방목에 약하므로 과방목을 피하는 것이 중요하며 두과목초 중에서 유일하게 고창증을 이르키지 않는 목초이다. 방목기간중 수량은 많지만 방목이 끝이난후 재방목을 위하여 15∼25cm 정도 자라는데 소요기간은 4∼6주 정도가 요구된다. 최종 이용은 월동을 위한 양분저장(뿌리)을 할 수 있는 기간 이내에 끝내어야 한다.</p>
          </li>
        </ul>
      </div>
      <div style={{float :'left' , width: '40%'}}>
        <table>
          <tr>
            <td><img src={pic230} className="nonborder2" alt=""/></td>
          </tr>
          <tr>
            <td><img src={pic231} className="nonborder2" alt=""/></td>
          </tr>
          <tr>
            <td><p style={{textAlign: 'center'}}>버드스 트레포일과 꽃의 모양</p></td>
          </tr>
          <tr>
            <td><img src={pic232} className="nonborder2" alt=""/></td>
          </tr>
          <tr>
            <td><p style={{textAlign : 'center'}}>종 자</p></td>
          </tr>
        </table>
      </div>
    </div>
  );
}

export default Do194;