import React from 'react';

import pic691 from 'assets/images/sub/_images/pic691.jpg';
import pic692 from 'assets/images/sub/_images/pic692.jpg';
import pic693 from 'assets/images/sub/_images/pic693.jpg';

function Do139() {

  return (
    <div className="techinfoview">
      <h1>리스테리아 증</h1>
      <p className="pstyle">본병은 리스텔리아균의 감염에 의해 일어나는 뇌염을 주증상으로 하는 감염병이다.  이 병은 사람에도 감염되며 뇌척수막염, 사산, 유아의 패혈증을 일으키는 사망률이 높은 인수공통전염병이므로 공중위생상 매우 중요하다. </p>
      <div className="leftpart">
        <h2>감염경로</h2>
        <ul>
          <li>이 세균은 사람과 동물의 체내, 체표면, 가축의 사료, 특히 싸일레지등에 존재하며 입안의 상처를 통해 체내에 침입한다.</li>
        </ul>
        <h2>증상</h2>
        <ul>
          <li>건강하던 소가 갑자기 신경증상을 보임을써 발견된다.
            <ul>
              <li>초기 증상은 식욕부진, 원기소실 하여 축사 벽이나 칸막이에 기대어 서며 소리에 민감한 반응을 보인다. </li>
              <li>시간이 경과됨에 따라 평형감각을 잃고 축사나 울타리를 부수며 내달리거나 같은 방향으로 빙빙도는 선회운동을 나타낸다. </li>
              <li>때로는 심한 구토를 되풀이하며 3∼7일만에 급사하거나 원기와 식욕이 돌아와 회복되기도 한다. </li>
            </ul>
          </li>
        </ul>
        <h2>목장에서의 처치</h2>
        <ul>
          <li>발병우는 동거군에서 격리하며, 싸일레지를 급여하고 있을 때는 급여를 일시 중지한다. </li>
          <li>싸일레지 제조와 관리에 주의해야 한다.(pH가 5.5∼6.0으로 상승하면 리스테리아 균이 증식하기 쉽다) </li>
          <li>리스테리아균은 대부분의 항생물질에 잘 듣지만 뇌에 병변이 나타나면 효과가 없으며 조기 발견하면 항생물질의 사용으로 회복되기고 한다. </li>
          <li>특히 유효한 항생물질로는 옥시테트라싸이클린으로 체중 1k당 20∼30mg을 발견 wrm시 정맥주사한다.</li>
        </ul>
      </div>
      <div className="rightpart">
        <img src={pic691} className="nonborder" alt=""/>
        <img src={pic692} className="nonborder" alt=""/>
        <img src={pic693} className="nonborder" alt=""/>
      </div>
    </div>
  );
}

export default Do139;