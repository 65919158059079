import React from 'react';

import Pic524 from 'assets/images/sub/_images/pic524.jpg';
import Pic525 from 'assets/images/sub/_images/pic525.jpg';
import Pic526 from 'assets/images/sub/_images/pic526.jpg';

function Do94() {

  return (
    <div className="techinfoview">
				<h1>이바라기병</h1>
				<p className="pstyle">이바라기 바이러스라고 알려진 병원체에 의해 전파되는 유행성 감기의 일종이다. 이웃일본에서는 법정전염병으로 취급되고 있으며 우리나라도 근간에 그 피해가 보고되고 있다.</p>
				<div className="leftpart">
					<h2>증상</h2>
					<ul>
						<li>인후두 또는 식도가 마비되어 음식을 씹거나 삼킬 수가 없으며 물을 마셔도 다시 콧구멍으로 역류된다.</li>
						<li>초기에는 뚜렷하지 않으나 40℃전후의 열과 식욕분진을 볼 수 있다. </li>
						<li>콧등과 입안이 충혈되고 이어서 울혈과 괴사가 생긴다. </li>
						<li>결막의 충혈, 종창, 눈물을 볼 수 있다. </li>
						<li>어떤 소는 제관부(蹄冠部)에서도 충혈과 괴사가 일어나므로 구제역과의 감별이 필요하다. </li>
						<li>이물성 폐염을 일으켜 사망하기도 한다(사망율은 10%내외)</li>
					</ul>
					<h2>목장에서의 처치</h2>
					<ul>
						<li>병우을 안정시키고 양질의 건초와 생초를 급여하고 농후사료는 제한한다. </li>
						<li>마미증상이 나타날때는 강제 급수(給水)나 급사(給飼)시에 이물성폐염이 염려되므로 목장에서 임의로 실시하지 말고 수의사의 왕진을 요청한다. </li>
						<li>강심제, 수액 및 영양제를 증상이 회복될때까지 경구외로 투여한다.(정맥주사가 좋다)</li>
					</ul>
				</div>
				<div className="rightpart">
					<img src={Pic524} className="nonborder" alt=""/>
					<img src={Pic525} className="nonborder" alt=""/>
					<img src={Pic526} className="nonborder" alt=""/>
				</div>
			</div>
  );
}

export default Do94;