import React from 'react';
import SiteMap from 'containers/web/guide/siteMap/SiteMap';
import { Helmet } from 'react-helmet-async';

function SiteMapPage() {
  return (
    <>
      <Helmet>
        <title>사이트맵</title>
      </Helmet>
      <SiteMap />
    </>
  );
}

export default SiteMapPage;
