import React from 'react';

import pic233 from 'assets/images/sub/_images/pic233.jpg';
import pic234 from 'assets/images/sub/_images/pic234.jpg';
import pic235 from 'assets/images/sub/_images/pic235.jpg';
import pic236 from 'assets/images/sub/_images/pic236.gif';

function Do193() {

  return (
    <div className="techinfoview">
      <h1>알사익 클로버</h1>
      <p>영명 : Alsike clover<br/>학명 : Trifolium hybridium L.</p>
      <div style={{width: '60%',float: 'left'}}>
        <h2>원산지</h2>
        <p className="pstyle">&nbsp;&nbsp;&nbsp;알사익 클로버는 1750년대초 스웨덴에서 재배된 이후 1834년경 북미주에 보급되어 클로버와 티모시와의 혼파지역에서는 매우 중요한 두과목초이다. 알사익 클로버의 계통은 주로 2배체(2n=16)와 4배체(2n=32)의 2계통이 있다.</p>
        <h2>성상</h2>
        <p className="pstyle">&nbsp;&nbsp;&nbsp;알사익 클로버는 단년생 목초임으로 2년정도 이용 할 수 있다. 레드클로버 보다는 생육이 다소 늦고 땅으로 누으면서 생육한다. 줄기와 잎은 부드럽고, 핑크빛과 흰색의 화두는 레드클로버의 꽃보다는 좀 적으나 꽃이 장기간 핀다. 꽃은 오래된 줄기의 밑에서 새로나온 줄기의 끝에서 핀다.<br/>&nbsp;&nbsp;&nbsp;이러한 성질은 종자를 생산하는 점에서 중요할 뿐만 아니라 레드클로버보다 장기간 건초를 조제할 수 있는 점에서 유리한 것이다.<br/>&nbsp;&nbsp;&nbsp;뿌리는 땅속 깊게 뻗으며, 세근이 많이 갖고 있으나 포복형의 뿌리는 아니고 월동후 이 뿌리에서 새로운 개체가 생기는 것이다.<br/>&nbsp;&nbsp;&nbsp;적합하지 못한 조건하에서도 번식을 잘할 수 있으며 특히 임실기에 건초를 조제한 알사익 클로버의 건초는 가축에게 아주 훌륭하다.</p>
        <h2>재배</h2>
        <p className="pstyle">&nbsp;&nbsp;&nbsp;서늘한 기후를 좋아하는 알사익 클로버는 과습하지 않는 토양에서 재배가 잘 되지만 토양 적응 범위는 광범위하고 홍수시 6주동안 침수된다하더라도 잘 견딘다.<br/>&nbsp;&nbsp;&nbsp;또한 산성토양에도 재배가 잘되고 토양수분이 많은 알칼리성 토양에도 재배가 잘되며, 두과목초 중 알카리성 토양에 적응성이 가장 좋다.<br/>&nbsp;&nbsp;&nbsp;내한성이 강하며 병충해에 대한 침해도 별로 없다. 그늘에서는 별로 생육이 좋지 못하여 리드카나리 그라스와 같은 초장이 큰 화본과 목초와 혼파를 하였을 때는 생육이 좋지 않다. 품종으로는 테트라(Tetra), 오우로라(Aurora)등이 있다.</p>
        <h2>이용</h2>
        <ul>
          <li>건초
            <p>레드클로버와 같이 수분을 많이 함유하고 있어 포장에서 청초를 건조하기가 어렵지만 빨리 건조하면 녹색을 많이 함유한다. 때때로 단파를 하지만 티모시와 같이 혼파할 경우에는 많아진다.</p>
          </li>
          <li>방목 및 청예
            <p>건초조제를 하기 위하여 예취한 후 재생은 양호하고 레드클로버와 비슷하다. 가축에 기호성이 좋고 늦가을에 이용할때에도 뿌리에 탄수화물 축적이 줄어들지 않아 월동에도 영향을 주지 않는다. 혼파하는 것이 유리하며 2∼3년생 두과목초이기 때문에 혼파초지에서는 알사익 클로버의 정상적인 혼파비율을 유지하여야하고 2년이 지나면 수량이 특히 감소되므로 관리하기가 힘들다.</p>
          </li>
        </ul>
      </div>
      <div style={{width: '40%', float: 'left'}}>
        <table>
          <tr>
            <td><img src={pic233} className="nonborder2" alt=""/></td>
          </tr>
          <tr>
            <td><img src={pic234} className="nonborder2" alt=""/></td>
          </tr>
          <tr>
            <td><p style={{textAlign: 'center'}}>알사익 클로버의 생육과정</p></td>
          </tr>
          <tr>
            <td><img src={pic235} className="nonborder2" alt=""/></td>
          </tr>
          <tr>
            <td><p style={{textAlign: 'center'}}>잎의 모양과 분지경(중앙)</p></td>
          </tr>
          <tr>
            <td><img src={pic236} className="nonborder2" alt=""/></td>
          </tr>
          <tr>
            <td><p style={{textAlign: 'center'}}>알사익 클로버의 꽃의 모양</p></td>
          </tr>
        </table>
      </div>
    </div>
  );
}

export default Do193;