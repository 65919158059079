import React from 'react';

// constants
import { BOARD_CODE, BOARD_NAME } from 'constants/backOfficeBoard';

// components
import BoardList from '../_components/BoardList';

function TestDtlPushPlanList() {
  return (
    <BoardList
      boardCode={BOARD_CODE.TEST_DTL_PUSH_PLAN}
      boardName={BOARD_NAME.TEST_DTL_PUSH_PLAN}
      editPath="/bo/board/testDtlPushPlan/edit"
      viewPath="/bo/board/testDtlPushPlan/view"
    />
  );
}

export default TestDtlPushPlanList;
