import React from 'react';

import pic739 from 'assets/images/sub/_images/pic739.jpg';
import pic740 from 'assets/images/sub/_images/pic740.jpg';
import pic741 from 'assets/images/sub/_images/pic741.jpg';

function Do156() {

  return (
    <div className="techinfoview">
      <h1>괴저성 유방염</h1>
      <div className="leftpart">
      <p className="pstyle">분만후 수일 이내에 발생하며, 유방에 주위 조직과 경계가 뚜렷한 괴사가 생기고 패혈증으로 심한 전신증상을 동반하는 사망률이 높은 급성 유방염이다. </p>
        <h2>원인</h2>
        <ul>
          <li>주로 분변중의 대장균(또는 황색포도구균, 화농균 등)이 유두나 유방의 상처를 통해 침입하여 발병 </li>
          <li>장염, 복막염, 창상성위염, 산욕열 시의 세균이 혈액을 통해 유방에 운반되어 증식하여 발병 </li>
        </ul>
        <h2>증상</h2>
        <ul>
          <li>전신증상(대개 패혈증과 세균의 독소작용에 의한다.)
            <ul>
              <li>갑자기 식욕소실 또는 식욕감퇴를 보인다. </li>
              <li>41℃이상의 체온 상승 </li>
              <li>통증으로 인해 등을 구부린 자세를 나타낸다. </li>
              <li>호흡과 맥박이 증가하고 근육이 떨리며 되새김이 중지된다. </li>
              <li>설사와 탈수증상을 보인다. </li>
            </ul>
          </li>
          <li>유방의 증상
            <ul> 
              <li>유방전체를 물론 하복부나 뒷다리까지 붓는 일이 있다. </li>
              <li>유방피부에 가지색의 원형 변색부가 나타나며 급속히 확대된다.</li>
              <li>병변부위는 차갑게 느껴지며 다른 부위는 붉고 열이 있다. </li>
              <li>감염된 부위는 통증이 심하다. </li>
              <li>유방 피부가 터져 농이 나오며 조직이 썩어 탈락하기도 한다. </li>
              <li>유량은 급히 줄고 유즙이 처음에는 물과 같으나 점차 혈액이나 농과 같은 부채한 냄새의 분비물로 변한다.</li>
            </ul>
          </li>
        </ul>
        <h2>목장에서의 처치</h2>
        <ul>
          <li>본병은 일각을 다틀 정도로 급성이므로 가능한 한 빨리 수의사의 왕진을 의뢰한다. </li>
          <li>그림과 같은 방법으로 비닐 호스를 장착하고 호스의 유방접촉부분에 여러개 구멍을 뚫어 유방을 감아 수도꼭지에 연결하여 유방을 냉각시켜 염증 진행을 막는다. </li>
          <li>깔짚을 충분히 넣어 소가 넘어지는 것과 유방에 대한 자극을 극소화시키도록 한다.</li>
        </ul>
      </div>
      <div className="rightpart">
        <img src={pic739} className="nonborder" alt=""/>
        <img src={pic740} className="nonborder" alt=""/>
        <img src={pic741} className="nonborder" alt=""/>
      </div>
    </div>
  );
}

export default Do156;