import React from 'react';

import pic424 from 'assets/images/sub/_images/pic424.gif';
import pic425 from 'assets/images/sub/_images/pic425.gif';
import pic426 from 'assets/images/sub/_images/pic426.gif';

function Do219() {

  return (
    <div className="techinfoview">
      <h1>저장시설로의 분뇨운반</h1>
      <h2>트랙터 스크래퍼</h2>
      <p className="pstyle">&nbsp;&nbsp;&nbsp;스크래퍼가 부착된 트랙터로 후리스톨 통로의 분뇨를 수거하여 지하에 설치된 분뇨 저장시설에 직접 밀어 넣으면, 분뇨는 후리스톨 바닥 높이에 위치한 경사로 (push-off ramp; 그림 95)를 통하여 저장시설로 떨어진다. 안전을 위하여 후리스톨 끝에 위치한 경사로의 모든 측면에 파이프 울타리를 설치하여야 한다(그림 95). <br/>&nbsp;&nbsp;&nbsp;겨울철에 밀기식 경사로의 똑같은 자리에 계속해서 분뇨를 밀어 넣으면, 얼어붙은 분뇨가 분뇨를 밀어 넣는 지역에 누적되어 더 이상의 분뇨를 밀어 넣을 수 없게 된다. 해결방법은 폭 넓은(20&apos;∼100&apos;) 경사로를 설치하여 여러 곳에서 분뇨를 밀어 넣을 수 있도록 하는 것이다. 또 다른 해결책은 경사로 주위에 콘크리트로 바닥을 만든 임시 퇴적장을 설치하여 겨울철 분뇨는 이 곳에 쌓아 두었다가 봄철에 경사로를 통하여 저장시설로 밀어 넣는 것이다. </p>
      <h2>중력식 자연 유출 파이프(gravity flow pipes)</h2>
      <p className="pstyle">&nbsp;&nbsp;&nbsp;하수(자연 흐름식) 파이프 장치는 폐수의 유입에 의하여 파이프 입구부분에서 형성되는 수압을 이용하여 폐기물이 저장시설 쪽으로 흐르도록 한다(그림 96). 파이프의 굵기는 분뇨 (고형물의 함량이 12% 이하)의 경우에는 지름이 24∼36인 파이프를 사용한다. 이 장치는 ①트랙터 스크래퍼 ②기계식 통로 스크래퍼 ③자연 흐름식 배출로 및 ④수세식 통로와 같은 청소방법에 의하여 수거된 분뇨를 저장시설로 운반하는데 적용될 수 있다. <br/>&nbsp;&nbsp;&nbsp;적절한 수압이 형성되어 분뇨의 흐름이 원활하게 이루어지려면, 운반거리 300&apos;까지, 분뇨수거 통로나 수거조(파이프의 폐기물 유입구)가 저장시설에 분뇨가 최대로 채워졌을 때의 분뇨표면보다 최소한 4&apos;∼6&apos; 높이에 위치하여야 한다(그림 96).</p>
      <img src={pic424} className="nonborder" width="60%" alt=""/>
      <p>그림 95. 밀기식 경사로</p><br/>
      <img src={pic425} className="nonborder" width="50%" alt=""/>
      <p>그림 96. 파이프를 이용한 하수식 분뇨 운반</p><br/>
      <p className="pstyle">&nbsp;&nbsp;&nbsp;하수(자연 흐름식) 파이프 운반시설은, 일일 두당 1.35kg 이하의 깔짚이 이용될 때, 가장 잘 작동된다. 고형분이 액체와 잘 혼합되어 있지 않은 경우, 파이프의 긴 경사지역에 고체와 액체의 분리현상이 생겨서 고체가 쌓이게 되어 파이프가 막히게 된다. 또한, 폐기물이 갑자기 파이프의 수직으로 굽는 지역에 떨어져서 기포가 형성되어도 파이프가 막힌다. <br/>&nbsp;&nbsp;&nbsp;따라서, 고체를 액체에 잘 혼합시켜 파이프에 유입시키고, 파이프가 굽는 모둔 부분과 파이프 200&apos;마다 청소할 수 있도록 배출구를 설치한다. 기포 형성을 방지하기 위하여 파이프가 수직으로 굽는 부분에 배기구멍을 설치한다. 가능하면 파이프가 굽지 않도록 하며, 굽혀질 경우에는 폐기물이 잘 빠져나가도록 파이프의 연결부를 길게 굽힌다. 파이프가 수평과 수직 선상에서 굽혀질 경우에 굽는 각도가 각각 25°와 45°이하가 되도록 한다. <br/>&nbsp;&nbsp;&nbsp;파이프는 얼지 않도록 서리가 생기는 지면 이하에 설치하여야 하며, 겨울철에는 파이프의 배출구 부분이 얼지 않도록 저장시설 바닥으로부터 2&apos;까지 항상 분뇨를 채워 배출구가 분뇨에 잠겨 있도록 해야 한다. 몹시 추운 겨울날에는 거의 대부분의 개방식 후리스톨 우사는 부분적으로나 전체적으로 얼게 된다. 이런 경우에 무리해서 분뇨를 얼어붙은 파이프 안으로 밀어 넣지 말고, 콘크리트 바닥으로 된 임시 퇴적장에 쌓아 두었다가 후에 처리하는 것이 좋다. </p>
      <h2>파이프 선택 </h2>
      <p className="pstyle">&nbsp;&nbsp;&nbsp; 분뇨를 정장시설에 운반하는 방법으로 콘크리트 지하수로를 이용할 수 있지만, 콘크리트는 수분이 잘 흡수되고 누출되며, 연결부가 잘 어긋나서 좋은 재질이 아니다. 자연 흐름식 파이프시설에 이용되는 파이프는 ①내부가 매끄러워야 하며 ②수분을 흡수하지 않고 ③이음새는 방수가 되어야 한다. 내부가 매끄럽게 처리되고, 이음새가 내부압력 40 psi를 견질 수 있는 하수도용 파이프를 이용하는 것이 좋으며, 파이프의 재질로는 PVC, 고밀도 폴리에틸렌 및 강철이 이용된다.  </p>
      <h2>수거조와 펌프 </h2>
      <p className="pstyle">&nbsp;&nbsp;&nbsp; 분뇨와 착유장으로부터 유출되는 폐수는 후리스톨 우사의 끝이나 중앙에 설치된 수거조에 모이게 된다(그림 97). 수거조의 크기는 분뇨 및 폐수처리 계획에 따라 달라진다. 수거조 내의 저장기간은 7∼30일로 하며, 정기적으로 교반해 주고 펌프를 이용하여 장기 저장시설이나 살포기로 이동시킨다. 겨울철 영하의 날씨에 대비하여, 분뇨와 폐수를 3∼4개월 동안 저장할 수 잇는 큰 수거조를 만들 수도 있다.<br/>&nbsp;&nbsp;&nbsp;펌프의 형태는 분뇨의 고형물 함량에 따라 달라지는데, 후리스톨 우사의 수거조에는 일반적으로 원심 초퍼펌프(centrifugal chopper pump)가 사용된다. 원심 초퍼펌프는 추진날개가 액체 속으로 미끄러져 들어갈 수 있으므로 positive-displacement pump가 아니며, 고형분 함량이 높은 분뇨를 퍼내는데는 적합하지 않다. 펌프의 성능은 추진날개의 형태에 따라 달라지는데, 폐쇄된 추진날개가 장치된 펌프는 폐수와 액체분뇨를 퍼내는데 적합하고, 반 개방된 추진날개가 장치된 펌프는 고체분뇨도 퍼낼 수 있다. <br/>&nbsp;&nbsp;&nbsp;Positive-displacement pump에는 스크루와 피스톤 펌프가 있다. 스크루 펌프는 고형분 함량이 높은 분뇨를 퍼낼 수 있는데, 분뇨 중에 단단한 오물이 들어 있으면 펌프에 손상을 준다. 스크루 펌프가 건조한 상태에서 작동되지 않도록, 작동 중에는 항상 소량의 물을 펌프케이스 안으로 공급해 주어야 한다. 피스톤 펌프는 일반적으로 운동장이나 우사로부터 수거된 고형분 함량이 높은 분뇨를 저장시설로 운반하는데 사용된다.</p>
      <img src={pic426} className="nonborder" width="30%" alt=""/>
      <p>그림 97. 분뇨 수거조의 시설</p>
    </div>
  );
}

export default Do219;