import React from 'react';

import Pic223 from 'assets/images/sub/_images/pic223.gif';

function Do59() {

  return (
    <div className="techinfoview">
				<h1>젖먹이 송아지 사양관리(4일령~이유시까지)-조사료 급여와 기타 관리</h1>
				<h2>조사료 급여</h2>
				<p className="pstyle">&nbsp;&nbsp;&nbsp;조사료는 통상 생후 1주령이 되면 자유채식토록 준비한다. 영양소 섭취는 조사료 보다는 우유나 이유사료에서 공급받게 되므로 조사료를 급여한다는 것은 영양공급 측면에서가 아니라 제 1위의 발달을 촉진한다는 면에서 중요하다. <br/>&nbsp;&nbsp;&nbsp;송아지에게 급여하는 조사료는 질 좋은 것이어야 한다. 송아지에게 볏짚과 같은 저질 조사료를 급여하는 것은 좋지 않다. 대체로 송아지에게는 질이 좋은 건초가 많이 이용되고 있으며 긴 것을 그대로 주는 것 보다는 짧게 자르거나 펠렛으로 만들어 주는 것이 편리하며 손실도 적다. <br/>&nbsp;&nbsp;&nbsp;송아지에게 청초나 사일레지를 급여하는 것은 건초를 급여하는 것에 비해 바람직한 방법은 못 된다. 왜냐하면 수분이 많은 사료는 송아지의 건물섭취량을 떨어뜨리기 때문이다. </p>
				<h2>기타관리</h2>
				<ul>
					<li>개체표시
						<ul>
							<li>송아지는 어미소로부터 떼어 놓으면서 반드시 개체표시를 하여야 한다. 개체표시를하므로서 여러 마리의 송아지를 기를 때에도 어느 소의 자손인지 알 수 있으므로 개량과 등록 그리고 능력 검정 등 각종 기록유지에 필요하기 때문이다. 개체표시방법은 사진, 스케치, 문신, 냉동인법 등의 영구적인 방법과 목번호표, 귀번호표등을 부착 시키는 방법이 있다. </li>
						</ul>
					</li>
					<li>부유두 제거 
						<ul>
							<li>젖소의 유두는 네 개가 균형있게 부착되어 있어야 한다. 부유두가 있으면 유방염 등에 감열될 염려가 있고 기계착유시 장애가 된다. 부유두는 2∼6주령 사이에 제거하는 것이 좋다. 부유두를 제거하는 작업순서는 
								<ul>
									<li>요오드액으로 부유두와 그 주위를 소독한다. </li>
									<li>부유두는 아래쪽으로 잡아당기고 부유두와 유방이 부착되는 곳을 잘드는 가위로 깨끗이 잘라낸다. </li>
									<li>잘라낸 부위를 소독한다. 피가 나기도 하지만 곧 지혈된다. </li>
									<li>파리가 생기는 계절에는 살충제를 상처부위에 뿌려준다.</li>
								</ul>
								<img src={Pic223} alt=""/>
								<p>그림 22. 사진이나 스케치로 개체기록부에 표시하는 방법</p><br/>
								<p>&nbsp;&nbsp;&nbsp;많이 자란 육성우나 어미소의 부유두를 제거할 때 잘라낸 부위가 너무 클 경우 실로 꿰매야 할 경우도 있다. 따라서 나이든 육성우나 어미소의 경우는 경험 있는 전문가나 수의사의 도움을 받도록 한다. <br/>&nbsp;&nbsp;&nbsp;부유두를 잘라낸다고 잘못 판단하여 주유두를 잘라내므로서 초산후 세꼭지에서만 젖이 나오는 예도 있으므로 어느 것이 부유두인지 확실히 알 수 없을 때는 확실해질 때까지 그대로 두는 것이 현명하다. </p>
							</li>
						</ul>
					</li>
					<li>제각
						<p>뿔은 젖소사육에서 아무런 도움도 되지 못한다. 관리하기 불편하며, 몸체나 유방에 상처를 내기 쉽다. 뿔이 있으면 일반 외모가 나빠 보인다. <br/>제각시기는 보통 1∼2주령에 실시하며 늦어도 30일을 넘기기 않도록 한다. 제각은 어릴 때 하는 것이 상처도 적고 빨리 아물며, 송아지에게 충격을 가장 적게 주고 제각하기에도 편리하다. </p>
						<ul>
							<li>가성가리봉 이용 
								<ul>
									<li>생후 4∼10일령 또는 뿔자리를 쉽게 알 수 있는 시기에 실시하되 제각시기가 빠를수록 좋다. </li>
									<li>뿔자리 주위의 털을 깎는다. → 뿔자리 주위에 바세린이나 구리스를 둥그렇게 바른다. → 가성가리봉을 적셔서 뿔자리(생장점부위)에 돌리면서 문지른다. → 겉부분이 부드럽게 되면 다른 쪽 뿔에 문지르고 번갈아 가면서 반복한다. </li>
									<li>주의사항
										<ul>
											<li>너무 지나치게 많은 양의 가성가리가 묻지않도록 할 것 </li>
											<li>뿔자리 전체를 없앨 때까지 계속할 필요는 없다. 왜냐하면 이미 묻어있는 가성가리가 계속 작용하기 때문이다. </li>
											<li>송아지를 격리하여 다른 송아지에게 문지르지 못하도록 한다. </li>
											<li>뿔자리 주위에 바세린이나 구리스를 발라서 약물이 송아지의 얼굴이나 눈에 들어가지 않도록 한다. </li>
											<li>손을 보호하기 위해서 고무장갑을 끼고 가성가리봉을 종이와 비닐을 이용, 이중으로 싸서 사용한다.</li>
											<li>사용후 가성가리봉은 밀폐된 유리기구나 플라스틱 용기에 저장한다. </li>
											<li>어린이나 가축이 닿지 않는 건조한 곳에 보관한다. </li>
										</ul>
									</li>
								</ul>
							</li>
							<li>전기제각기 사용
								<ul>
									<li>시중에서 판매되는 전기제각기를 구입하여 송아지를 잘 보정하고 뿔자리를 찾아 지져준다. 이것은 뿔의 생장점을 불로 지져서 더 이상 자라지 못하게 하는 것으로서 간편한 방법이다. 약 2∼7주령사이에 실시하는 것이 가장 좋다.</li>
								</ul>
							</li>
						</ul>
					</li>
				</ul>
			</div>
  );
}

export default Do59;
