import React from 'react';
import IndustryNews from 'containers/web/info/industryNews/IndustryNews';
import { Helmet } from 'react-helmet-async';

function IndustryNewsPage() {
  return (
    <>
      <Helmet>
        <title>업계소식</title>
      </Helmet>
      <IndustryNews />
    </>
  );
}

export default IndustryNewsPage;
