import React from 'react';

import Pic584 from 'assets/images/sub/_images/pic584.jpg';
import Pic585 from 'assets/images/sub/_images/pic585.jpg';
import Pic586 from 'assets/images/sub/_images/pic586.jpg';

function Do111() {

  return (
    <div className="techinfoview">
      <h1>산전 산후 기립불능증</h1>
      <p className="pstyle">별다른 증상이 없이 분만직후서부터 72시간 이내에 기립불능에 빠져 칼슘제를 투여 하였음에도 불구하고 기립하지 않는 경우에 사용되는 호칭이다. </p>
      <div className="leftpart">
        <h2>원인</h2>
        <ul>
          <li>골반 주위의 근육이나 신경손상, 분만시 미끄러운 바닥에서 넘어진다거나 난산에 의한 기계적 자극, 무리한 견인(당김)등에 의함. </li>
          <li>유열치료의 지연:합병증이 없는 유열이라도 기립불능 상태가 오래 지속되면 체중의 업력으로 인한 혈행 장애로 후구마비가 되어 유열치료를 해도 일어서지 못한다. </li>
        </ul>
        <h2>증상</h2>
        <ul>
          <li>체온과 맥박, 식욕은 정상이다. </li>
          <li>머리를 옆으로 꼬거나 체온이 떨어지는 등의 유열 증상은 보이지 않는다. </li>
          <li>대개의 경우는 일어나려고 노력하지만 뒷다리를 완전히 뻗치거나 들지 못하고 다시 주저 않는다.(포복증상) </li>
          <li>간혹 식욕을 전계하는 경우도 있으며 7일 내에 기립하지 못하면 예후 불량하다.</li>
        </ul>
        <h2>목장에서의 처치</h2>
        <ul>
          <li>본증은 일단 발생되면 상당히 치유가 어렵다. 
            <ul>
              <li>깔짚이 충분한 넓은 장소로 옮겨 소가 몸을 자유롭게 움직일 수 있도록 해주어야 한다.</li>
              <li>6시간 간격으로 좌우측으로 번갈아 눕혀야 하며 혈행을 좋게 하기 위해 주물러 주어야 한다. </li>
              <li>예방을 위해서는 너무 살찌지 않도록 사양관리하며 분만 예정 1주일 경에 Vit D3 1000만 단위를 근육주사한다. </li>
              <li>분만직후에 인산칼슘을 먹이며(액 200g 정도)물을 충분히 급여하고 과잉착유와 농후사료 과급은 피해야 한다. </li>
            </ul>
          </li>
        </ul>
      </div>
      <div className="rightpart">
        <img src={Pic584} className="nonborder" alt=""/>
        <img src={Pic585} className="nonborder" alt=""/>
        <img src={Pic586} className="nonborder" alt=""/>
      </div>
    </div>
  );
}

export default Do111;