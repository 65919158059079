import React from 'react';

import pic679 from 'assets/images/sub/_images/pic679.jpg';
import pic680 from 'assets/images/sub/_images/pic680.jpg';
import pic681 from 'assets/images/sub/_images/pic681.jpg';

function Do136() {

  return (
    <div className="techinfoview">
      <h1>물중독</h1>
      <p className="pstyle">물 중독은 송아지가 다량으로 물을 마신후에 붉은 오줌을 배설하는 현상으로 새후 6개월 이내 특히 이유(離乳)전후의 송아지에 발생하기 쉽다.</p>
      <div className="leftpart">
        <h2>원인</h2>
        <ul>
          <li>송아지의 제 1위가 충분히 발달하지 못한 상태에서 다량의 물을 섭취할 때 물은 위에서 흡수되지 못하고 장관으로 이행, 급속히 흡수되어 모세관 혈관내 삼투압이 떨어져 적혈구 파열을 일으키기 때문이다.</li>
        </ul>
        <h2>증상</h2>
        <ul>
          <li>대량의 물을 마신 뒤 10∼20분에 붉은 오줌을 배설한다. 
            <ul>
              <li>체온은 일시적으로 하강, 호흡과 맥박수가 감소한다. </li>
              <li>복부가 팽대하며 원기가 없어진다. </li>
              <li>시간이 경과하면 점차 호흡이 빨라져 호흡곤란 상태가 되고 침과 거품섞인 콧물을 흘린다. </li>
              <li>증상이 심하면 괴로워하고 땀을 흘리고 가시 점막이 빈혈에 의해 창백해지고 몸을 부들부들 떨며 물 같은 설사를 한다. </li>
              <li>뇨의 색은 가벼운 증상일때는 담홍색, 중증일때는 흑갈색 또는 암적색이다.</li>
            </ul>
          </li>
        </ul>
        <h2>목장에서의 처치</h2>
        <ul>
          <li>송아지를 안정시키고 증상이 회복되기를 기다린다. </li>
          <li>송아지의 급수관리에 충분히 주의하여 1회 급수량을 체중의 8%이하로 제한해야 한다. </li>
          <li>소금을 0.4∼0.8% 비율로 물에 타주면 한번에 다량의 물을 마셔도 발병하지 않는다.  </li>
        </ul>
      </div>
      <div className="rightpart">
        <img src={pic679} className="nonborder" alt=""/>
        <img src={pic680} className="nonborder" alt=""/>
        <img src={pic681} className="nonborder" alt=""/>
      </div>
    </div>
  );
}

export default Do136;