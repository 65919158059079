import React from 'react';

function Fo17() {

  return (
    <div className="techinfo_view">
				<p className="viewTitle">소분만후의 생식기관의 이상</p>
				<p>수태율을 높이는 방법중의 하나가 분만시 또는 분만후의 질병이 생기지 않도록 하는 것이다. 이때의 생식기관의 이상은 새로운 임신을 위한 준비기관을 연장시키며 다음 임신까지 수정횟수를 많게 만들어 분만간격을 길게 만든다(표 3.4) </p>
				
				<table className="tablestyle">
					<caption>표 3.4 분만 또는 분만후의 이상이 임신 및 공태기간에 미치는 영향 </caption>
					<thead>
						<tr>
							<th className="first">증상</th>
							<th>1회 수정후 임신율</th>
							<th>공태기간</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>없음</td>
							<td>49%</td>
							<td>105</td>
						</tr>
						<tr>
							<td>난산</td>
							<td>43%</td>
							<td>105</td>
						</tr>
						<tr>
							<td>후산정체</td>
							<td>42%</td>
							<td>114</td>
						</tr>
						<tr>
							<td>자궁오염</td>
							<td>36%</td>
							<td>119</td>
						</tr>
						<tr>
							<td>난종난소</td>
							<td>35%</td>
							<td>136</td>
						</tr>
					</tbody>
				</table>
				<ul className="refer mg10t mg30f">
					<li>Smith, R. D. 1984. Dairy Integrated reproductive management. Cooperative extension service, W.V </li>
				</ul>
				
				<dl>
					<dt>후산정체 </dt>
					<dd>
						<p>후산정체는 분만후 12시간이내에 태반이 완전히 나오지 못한 경우를 말한다. </p>
						<p>일반적으로, 분만후 출혈이 줄어들고, 자궁의 수축운동으로 태반이 자궁으로부터 떨어지도록 충분한 자극을 가하지만, 5-10%의 태반이 자궁에 남아있다. 약을 통하여 분만을 유도하였거나, 난산의 경우는 자궁내에 태반이 많이 남아있게 되는데, 이경우는 박테리아의 오염을 유발시킬 우려가 높다, 우군내에 이러한 경우에 50%이상이 오염이 된다. </p>
						<p>하지만 이러한 태반을 강제적으로 제거하려 한다면 오히려 자궁에 악영향을 미쳐 영구불임이 될수 있으므로 조심해야한다. </p>
						<p>많은 연구에서도 손으로 인위적으로 태반을 제거하였을 경우 발정재개에 걸리는 시간이 길어진다고 보고하고 있다.</p>
						<p>최대한 태반이 자궁으로부터 저절로 떨어질수 있는 시간을 제공하여야 하는데 많은 노력을 기울일 부분은 오염이 되지 않도록 해야 하며, 난소의 수축운동을 자극해주어야 한다. </p>
						<p>때때로 호르몬인 에스트로젠이 후산정체 치료제로 사용되기도 한다. 후산정체에 따르는 오염을 막기위하여 항생제가 함유된 세척액을 사용하였다면 착유된 우유를 납유하면 안될 것이다. 항생제를 사용후 72-96시간내에 착유한 우유에 항생제가 잔존하므로 유의해야 한다. 또한 후산정체는 자궁염과 자궁축농증을 수반할 가능성이 높으므로 유의해야 한다. 후산정체의 정확한 치료법은 아직 없다. 따라서 분만시 위생에 최대한 유의하고 건유기간동안의 영양수준에 유의하여 예방하는 것이 최선의 방법이다. </p>
					</dd>
				</dl>
				<dl>
					<dt>자궁염</dt>
					<dd>
						<p>자궁염은 미생물의 침입에 의해 자궁에 염증이 생긴 것을 말하는데 질의 화농성물질의 배출로써 많이 진단된다. </p>
						<p>분만후의 자궁염은 정상적인 크기로의 자궁수축을 지연시키고, 난소의 활동과 발정주기의 재개를 지연시키게 된다. </p>
						<p>자궁염은 난산과 후산정체에서 가장 많이 발병하는데 우군에서 우군에서 자궁염의 발병율이 20%가 초과할때에는 관리자는 분반과 분만후의 관리에 문제가 있음을 인지하고 분만과 분만후 환경개선 주력하여 자궁염의 발병율을 감소시키는데 노력을 해야 할것이다. </p>
						<p>자궁염이 생기면 소의 건강과 식욕에 악영향을 미치며 더나아가 유우의 생산감소를 유발시킨다. </p>
						<p>치료에 있어서 많은 소에서 아무런 치료없이 여러주가 지나가면 자연적인 치료가 되는데 이는 발정주기가 재개되면 오염을 정화시키는 작용을 하여 치료가 되는 것으로 생각된다. </p>
						<p>수의학적처리로는 직장 촉지를 통한 자궁내의 염농성 액의 배출, 항생물질이 포함된 액으로 자궁세척등을 들수 있는데, 염농성 액의 배출후에 항생제는 오염을 제거하는데 아주 효과적이고, 다른 방법으로 프로스타그란딘을 주사하여 발정을 제개를 유도하면 발정기동안 자궁의 수축운동을 유도하여 오염을 없애는데 도움이 되며, 사용하는 항생제의 양도 줄일 수 있을 것이다. </p>
						<p>하지만 분만후에 항생제의 사용은 질과 자궁에 병원성 박테리아의 유입의 위험이 있어 많이 고려되어야 한다. </p>
						<p>분만후에 50-100 IU의 옥시토신의 처리는 태반의 추출을 도우므로 자궁염에 걸릴 확률을 줄일수 있다.</p>
					</dd>
				</dl>
				<dl>
					<dt>자궁축농증 </dt>
					<dd>자궁염은 자궁의 외래균의 침입에 의해 염증이 생긴 질병이지만 자궁 축농증의 경우는 자궁경관이 굳게 닫혀져 있는상태에 자궁강내에 다량의 농성물질이 저류된어 자궁이 팽만되는 증상을 나타내는 질환으로 이경우에는 보통 발정이 오지 않으며 영구 불임으로 될 가능성이 높기 때문에 반드시 치료가 되어야 한다. 일반적인 치료방법으로는 발정을 유도하기 위하여 프로스타그란딘을 처리하는 방법이다. 프로스타그란딘은 황체를 퇴행시키고 난포를 발달시켜 에스트로젠의 혈중 농도를 증가시켜 자궁경관을 이완시켜 농성물질을 배출시킬수 있기 때문이다. 농성물질이 배출되면 항생제를 투여하여 오염의 정화를 유도하고 자궁의 수축을 유도하여야 치료에 도움이 되도록 해야 한다. </dd>
				</dl>
				<dl>
					<dt>임신과 분만에 관련된 호르몬</dt>
					<dd>
						<p>표 3.5는 임신과 분만 그리고 유선의 발달에 연관된 호르몬을 요약하였다.</p>
						<p>프로제스테론은 임신기간동안 황체와 분만전 2-3개월동안 태반에서 분비되는 호르몬으로 임신을 유지 및 보호하는 중요한 호르몬으로 작용한다. </p>
						<p>임신기간 태반에서 분비되는 호르몬은 유선조직의 발달에 매우 중요한 역할을 한다. 특히 분만전 최소 50일의 건유기간은 다음 수유를 위하여 매우 중요하다.</p>
						<p>이기간에 유방조직의 유선관계와 분비세포는 다시 발달하는데, 이 건유기간이 50일 보다 작을 경우에는 유방조직의 유즙분비세포의 숫자를 감소시켜 분만후 유량을 감소시키게 되므로 유의해야한다. </p>
						<p>태아가 분비하는 코티졸이라는 호르몬에 의하여 분만이 시작된다. 태반에서 모체의 혈관과 태아의 혈관이 섞여 있지 않아 코티졸이 모체에 직접적으로 영향을 주는 것이 아니고 태아측의 태반에 코티졸의 농도가 높아지면 모체측의 태반에서 에스트로젠을 분비하기 시작하는데, 태반의 에스트로젠의 분비가 증가하기 시작하면 옥시토신의 분비를 증가시켜 자궁경관의 팽창과 자궁의 수축이 시작되며 분만이 시작된다. </p>
					</dd>
				</dl>
				
				<div className="tableWrap">
					<table className="tablestyle">
						<caption>표 3.5 임신과 분만에 관련된 호르몬들 </caption>
						<thead>
							<tr>
								<th className="first">호르몬</th>
								<th>생산위치</th>
								<th>표적기관</th>
								<th>효과</th>
								<th>시간</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>수정란단백질</td>
								<td>자궁(수정란)</td>
								<td>난소(황체)</td>
								<td className="left">임신기간동안 황체의 유지</td>
								<td>임신초기</td>
							</tr>
							<tr>
								<td rowSpan="4">포로제스테론</td>
								<td rowSpan="2">난소(황체)</td>
								<td>자궁</td>
								<td className="left">근육활동의 감소와 수정란의 영양공급과 임신에 적합한 환경조성</td>
								<td>임신초기</td>
							</tr>
							<tr>
								<td>뇌하수체</td>
								<td className="left">발정주기 재개의 방지</td>
								<td>임신기간</td>
							</tr>
							<tr>
								<td rowSpan="2">태반</td>
								<td>자궁</td>
								<td className="left">임신유지</td>
								<td>분만전2-3달</td>
							</tr>
							<tr>
								<td>유선세포</td>
								<td className="left">우유분비세포의 발달</td>
								<td>분만전2-3달</td>
							</tr>
							<tr>
								<td>태반 락토젠</td>
								<td>태반</td>
								<td>유선세포</td>
								<td className="left">우유분비세포의 발달</td>
								<td>분만전2-3달</td>
							</tr>
							<tr>
								<td>프로렉틴</td>
								<td>뇌하수체전엽</td>
								<td>유선세포</td>
								<td className="left">우유분비세포의 발달</td>
								<td>분만전2-3달</td>
							</tr>
							<tr>
								<td rowSpan="2">릴렉신</td>
								<td rowSpan="2">난소(황체)</td>
								<td>자궁</td>
								<td className="left">태아의 성장에 따른자궁의 확장 유도</td>
								<td>임신기간</td>
							</tr>
							<tr>
								<td>자궁경관과 골반</td>
								<td className="left">근육의 이완</td>
								<td>분만시</td>
							</tr>
							<tr>
								<td rowSpan="2">에스트로젠</td>
								<td rowSpan="2">태반</td>
								<td>유선세포</td>
								<td className="left">유선관의 발달</td>
								<td>마지막달</td>
							</tr>
							<tr>
								<td>뇌하수체전엽</td>
								<td className="left">옥시토신의 분비</td>
								<td>분만시</td>
							</tr>
							<tr>
								<td>코티졸</td>
								<td>태아의 부신</td>
								<td>태반</td>
								<td className="left">에스트로젠의 분비</td>
								<td>분만시</td>
							</tr>
							<tr>
								<td rowSpan="2">프로스타그란딘</td>
								<td rowSpan="2">자궁</td>
								<td>난소(황체)</td>
								<td className="left">황체의 퇴행</td>
								<td>분만시</td>
							</tr>
							<tr>
								<td>자궁</td>
								<td className="left">수축운동증가</td>
								<td>분만시</td>
							</tr>
							<tr>
								<td>옥시토신</td>
								<td>뇌하수체전엽</td>
								<td>자궁</td>
								<td className="left">수축운동증가</td>
								<td>분만시</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
  );
}

export default Fo17;
