import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// api
import { getState, postState } from 'utils/api';

function EmployeeView() {
  const [employee, setEmployee] = useState({
    bdc_name: '',
    bdc_group: '',
    bdc_part: '',
    bdc_position: '',
    bdc_tel: '',
    bdc_etc: '',
  });
  const navigate = useNavigate();
  const bdcCode = new URLSearchParams(window.location.search).get('bdc_code');

  useEffect(() => {
    const fetchEmployee = async () => {
      try {
        const response = await getState('/board/select_employee', {
          bdc_code: bdcCode,
        });
        if (response?.return_code === 'SUCCESS') {
          setEmployee(response.result);
        }
      } catch (error) {
        console.error('Error fetching employee:', error);
      }
    };

    if (bdcCode) {
      fetchEmployee();
    }
  }, [bdcCode]);

  const handleDelete = async () => {
    if (window.confirm('직원 목록에서 삭제하시겠습니까?')) {
      try {
        const response = await postState('/board/delete_employee/', {
          bdc_code: bdcCode,
        });
        if (response?.return_code === 'SUCCESS') {
          alert('삭제되었습니다.');
          navigate('/bo/employee/list');
        }
        if (response?.return_code === 'ERROR') {
          alert(response?.return_message);
        }
      } catch (error) {
        console.error('Error deleting employee:', error);
        alert('삭제 중 오류가 발생했습니다.');
      }
    }
  };

  const handleEdit = () => {
    if (window.confirm(`${employee.bdc_name}님의 정보를 수정하시겠습니까?`)) {
      navigate(`/bo/employee/edit?bdc_code=${bdcCode}`);
    }
  };

  if (!employee) return <div>Loading...</div>;

  return (
    <div className="employee-view">
      <h2>직원 정보</h2>
      <div className="table-container bold-border">
        <table>
          <tbody>
            <tr>
              <th style={{ width: '150px' }}>이름</th>
              <td>{employee?.bdc_name}</td>
            </tr>
            <tr>
              <th style={{ width: '150px' }}>소속부서</th>
              <td>{employee?.bdc_group}</td>
            </tr>
            <tr>
              <th style={{ width: '150px' }}>소속팀</th>
              <td>{employee?.bdc_part}</td>
            </tr>
            <tr>
              <th style={{ width: '150px' }}>직급</th>
              <td>{employee?.bdc_position}</td>
            </tr>
            <tr>
              <th style={{ width: '150px' }}>전화</th>
              <td>{employee?.bdc_tel}</td>
            </tr>
            <tr>
              <th style={{ width: '150px' }}>담당업무</th>
              <td>{employee?.bdc_etc}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="button-group">
        <button type="button" onClick={handleEdit} className="btn-l">
          수정
        </button>
        <button type="button" onClick={handleDelete} className="btn-l">
          삭제
        </button>
        <button
          type="button"
          onClick={() => navigate('/bo/employee/list')}
          className="btn-l"
        >
          목록
        </button>
      </div>
    </div>
  );
}

export default EmployeeView;
