import React from 'react';

import Pic507 from 'assets/images/sub/_images/pic507.jpg';
import Pic508 from 'assets/images/sub/_images/pic508.jpg';
import Pic509 from 'assets/images/sub/_images/pic509.jpg';
import Pic510 from 'assets/images/sub/_images/pic510.jpg';

function Do89() {

  return (
    <div className="techinfoview">
				<h1>태막수종</h1>
				<p className="pstyle">임신말기에 태수(胎水)가 이상 증가하는 병으로 양수(羊水)에 기인하는 것을 양막수종, 요막액(尿膜液)에 기인하는 것을 요막수종이라 하며 대부분은 양자가 병합되어 있다.</p>
				<div className="leftpart">
					<h2>원인</h2>
					<ul>
						<li>유전적 소인
							<ul>
								<li>열성 유전에 의한 것이 외국에서 보고되어 있으며 근친번식에 의해 발생하기 쉽다. </li>
							</ul>
						</li>
						<li>자궁의 병변
							<ul>
								<li>태반의 형성부전 또는 제대가 꼬였을 때 , 비타민 A의 결핍이나 브루셀라등의 감염시에도 생긴다는 주장이 있다.</li>
							</ul>
						</li>
					</ul>
					<h2>증상</h2>
					<ul>
						<li>경증
							<ul>
								<li>태수의 양이 40∼80l로 증가한다.(분만시의 정상 태수량은 약 20l) </li>
								<li>복위(腹圍)가 큰 것 외에는 특별한 이상을 볼 수 없지만 분만시 파수(破水)때에 다량의 태수가 유출된다.</li>
							</ul>
						</li>
						<li>중증
							<ul>
								<li>태수량은 100∼200l로 증가한다. 태아의 체중도 수종으로 인해 증가한다. 고도의 복부팽대, 식욕부진, 반추정지, 변비등을 보인다. </li>
								<li>음수량이 증가하고 맥박이 증가한다. 호흡시 신음을 하며 옆으로 눕는 것을 싫어한다. 중증이 되면 기립불능이된다.</li>
							</ul>
						</li>
					</ul>
					<h2>목장에서의 처치</h2>
					<ul>
						<li>경증의 경우에는 소화되기 쉬운 사료를 급여하고 가벼운 운동을 시키며 분만에 대비한다.</li>
						<li>중증인 예에서는 수의사와 상의하여 인공유산이나 제왕절개를 실시해야 한다. </li>
						<li>같은 우군에서 2두 이상 발생하면 유전적 원인을 생각할 수 있으므로 근친 번식은 피해야 한다. </li>
					</ul>
				</div>
				<div className="rightpart">
					<img src={Pic507} className="nonborder" alt=""/>
					<img src={Pic508} className="nonborder" alt=""/>
					<img src={Pic509} className="nonborder" alt=""/>
					<img src={Pic510} className="nonborder" alt=""/>
				</div>
			</div>
  );
}

export default Do89;