import React from 'react';

import Pic527 from 'assets/images/sub/_images/pic527.jpg';
import Pic528 from 'assets/images/sub/_images/pic528.jpg';
import Pic529 from 'assets/images/sub/_images/pic529.jpg';
import Pic530 from 'assets/images/sub/_images/pic530.jpg';

function Do95() {

  return (
    <div className="techinfoview">
      <h1>소의 바이러스성 설사 점막병(BVD-MD)</h1>
      <div className="leftpart">
        <h2>원인</h2>
        <ul>
          <li>본병은 바이러스에 의해 일어나는 급성전염병으로 BVD-MD라고도 불리우고 있다. 현재 국내에도 항체 보유율이 59%에 이르고 있어 전국적으로 만연되고 있음을 추정할 수 있다. 원인이 되는 바이러스에는 여러개의 형(型)이 있고 병원성에서도 차이가 있다.</li>
        </ul>
        <h2>증상</h2>
        <ul>
          <li>40℃전후의 발열로 시작되며 이때 가벼운 감기와 증세를 보인다. </li>
          <li>황색의 afn같은 설사를 배설하며 이것이 혈변으로 바뀌면 거의 폐사한다. (경과는 급성) </li>
          <li>경과가 극히 급성으로 진행되기 때문에 이 병으로 의심이 되면 즉시 수의사의 왕진을 구한다.  </li>
        </ul>
        <h2>목장에서의 처치</h2>
        <ul>
          <li>바이러스에 의해 일어나는 병이므로 현재로선 치료 방법이 없다. </li>
          <li>2차 감염을 막기위해 영양제와 항생물질을 투여한다. </li>
          <li>설사로 인한 탈수증을 막기 위해 수액을 공급한다. </li>
          <li> 현재로선 예방접종이 유일한 방법으로서, 백신을 접종하여 질병을 사전에 예방하여야 한다(IV 장 위생관리항 참조) </li>
        </ul>
      </div>
      <div className="rightpart">
        <img src={Pic527} className="nonborder" alt=""/>
        <img src={Pic528} className="nonborder" alt=""/>
        <img src={Pic529} className="nonborder" alt=""/>
        <img src={Pic530} className="nonborder" alt=""/>
      </div>
    </div>
  );
}

export default Do95;