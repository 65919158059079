import React from 'react';

import pic672 from 'assets/images/sub/_images/pic672.jpg';
import pic673 from 'assets/images/sub/_images/pic673.jpg';
import pic674 from 'assets/images/sub/_images/pic674.jpg';

function Do134() {

  return (
    <div className="techinfoview">
      <h1>송아지 백혈병</h1>
      <p className="pstyle">이 병은 송아지에 산발적으로 발생하는 혈액암의 일종이다(성우형 백혈병과는 다른 질병이다.)  현재로선 원인이 되는 균이나 바이러스는 규명되어 있지 않다. </p>
      <div className="leftpart">
        <h2>증상</h2>
        <ul>
          <li>출생직후에서 6개월령까지의 송아지에 발생 
            <ul>
              <li>체표에 있는 임파절이 붓고 딱딱해 진다. 그러나 열감(熱感)이나 통증이 없는 것이 특징이다. </li>
              <li>인후두부의 임파절이 부어올라 기관지를 압박하면 호흡곤란이 나타난다. </li>
              <li> 발병 후 경과는 급성으로 진행하여 대부분 1개월 이내에 폐사한다. </li>
              <li>피부에는 결절과 같은 혹이 생긴다. </li>
            </ul>
          </li>
        </ul>
        <h2>목장에서의 처치</h2>
        <ul>
          <li>본증에 대한 특별한 처치법은 없으며 발견되어 진단이 내려지면 즉시 도태해야 한다. </li>
        </ul>
      </div>
      <div className="rightpart">
        <img src={pic672} className="nonborder" alt=""/>
        <img src={pic673} className="nonborder" alt=""/>
        <img src={pic674} className="nonborder" alt=""/>
      </div>
    </div>
  );
}

export default Do134;