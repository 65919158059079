import React from 'react';
import { useLocation , Outlet } from 'react-router-dom';

// 컴포넌트
import SubTitle from 'components/subTitle/SubTitle';
import CommonBoardApi from 'components/board/commonBoardApi';

// scss

function MainNews() {

  const location = useLocation();

  return (
    <div className="mainNews subCon">
      <SubTitle />
      { 
        location.pathname.includes('detail') 
        ?
        <Outlet /> 
        :
        <CommonBoardApi /> 
      }
    </div>
  );
}

export default MainNews;
