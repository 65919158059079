import React, { useRef } from 'react';
import { Link } from 'react-router-dom';

// 컴포넌트
import SubTitle from 'components/subTitle/SubTitle';

// 이미지
import howto1Mo from 'assets/images/containers/business/bestCowTest/howto_1_mo.png';
import howto2Mo from 'assets/images/containers/business/bestCowTest/howto_2_mo.png';
import howto3Mo from 'assets/images/containers/business/bestCowTest/howto_3_mo.png';
import howto4Mo from 'assets/images/containers/business/bestCowTest/howto_4_mo.png';
import makeDay from 'assets/svgs/containers/business/bestCowTest/makeDay.svg';
import kind from 'assets/svgs/containers/business/bestCowTest/kind.svg';
import now from 'assets/svgs/containers/business/bestCowTest/now.svg';
import document from 'assets/svgs/containers/business/bestCowTest/document.svg';
import rankStar from 'assets/svgs/containers/business/bestCowTest/rankStar.svg';
import farm from 'assets/svgs/containers/business/bestCowTest/farm.svg';
import human from 'assets/svgs/containers/business/bestCowTest/human.svg';
import building from 'assets/svgs/containers/business/bestCowTest/building.svg';

// scss
import './bestCowTest.scss';

// 자식
import CooperPoint from './CooperPoint';

function BestCowTest() {
  const businessOverviewRef = useRef(null);
  const howtoTestRef = useRef(null);
  const howtoCowTestJoinRef = useRef(null);
  const cooperPointRef = useRef(null);

  const scrollToRef = (ref) => {
    const currenttop = ref.current.offsetTop - 100;
    window.scrollTo({
      top: currenttop,
      behavior: 'smooth',
    });
  };

  const scrollBehaivor = (tab) => {
    switch (tab) {
      case 'businessOverview':
        scrollToRef(businessOverviewRef);
        break;
      case 'howtoTest':
        scrollToRef(howtoTestRef);
        break;
      case 'howtoCowTestJoin':
        scrollToRef(howtoCowTestJoinRef);
        break;
      case 'cooperPoint':
        scrollToRef(cooperPointRef);
        break;
      default:
        break;
    }
  };

  return (
    <div className="bestCowTest subCon pullContent">
      <ul className="bestCowTab">
        <li>
          <button
            onClick={() => scrollBehaivor('businessOverview')}
            type="button"
          >
            사업개요
          </button>
        </li>
        <li>
          <button onClick={() => scrollBehaivor('howtoTest')} type="button">
            검정방법
          </button>
        </li>
        <li>
          <button
            onClick={() => scrollBehaivor('howtoCowTestJoin')}
            type="button"
          >
            참여방법
          </button>
        </li>
        <li>
          <button onClick={() => scrollBehaivor('cooperPoint')} type="button">
            조합위치
          </button>
        </li>
      </ul>
      <div className="bestCowTitle">
        <SubTitle />
        <dl>
          <dt>
            단기적 성과보다는 <span>장기적 목표에 중점을 둡니다.</span>
          </dt>
          <dd>
            우리 낙농업이 국제적인 경쟁력을 갖고 지속적으로 발전하기 위해서는
            유전능력이 뛰어난 품종으로 경쟁하는 것이 이상적입니다.
          </dd>
          <dd>
            우리풍토에 적합한 젖소라면 경쟁력은 더욱 높아질 것 입니다.
            젖소개량사업소가 1987년 1월부터 현재까지 젖소능력검정 및
            후대검정사업을 실시하고 있는 까닭이 바로 여기에 있습니다.
          </dd>
        </dl>
      </div>
      <div className="milkCowTest">
        <div className="milkCowTestInner">
          <h5>젖소능력검정</h5>
          <ul>
            <li>
              <div className="imgArea">
                <img src={makeDay} alt="설립일" />
                <span>설립일</span>
              </div>
              <p>
                젖소개량사업소는 1972년부터 젖소능력검정 사업을 실시하고
                있습니다.
              </p>
            </li>
            <li>
              <div className="imgArea">
                <img src={kind} alt="분야" />
                <span>분야</span>
              </div>
              <p>
                산유랑, 지방, 단백질, 영양의 과부족, 체세포 검사, 유방암
                사전검색 및 번식사항 등을 조사 분석함으로써 과학적인 낙농경영을
                할 수 있도록 뒷받침 합니다.
              </p>
            </li>
            <li>
              <div className="imgArea">
                <img src={now} alt="검정사업현황" />
                <span>검정사업현황</span>
              </div>
              <p>
                3099호, 241,668두, 29개 검정조합
                <br />
                -검정두수는 &#34;17년도&#34;부터 소이력제 자료 활용
              </p>
            </li>
          </ul>
        </div>
      </div>
      <div ref={businessOverviewRef} className="businessOverview">
        <h5>사업개요</h5>
        <dl className="businessOverviewTitle">
          <dt>
            농가 경영 성과를 높이는<span>경영 정보 시스템</span>
          </dt>
          <dd>
            매월 검정원이 농가를 방문하여 개체별 유량, 번식기록, 사료급여량 등을
            측정하고, 우유샘플을 채취하여 성분을 분석한 후 검정 데이터를
            인터넷으로 입력하면 검정성적을 분석하여 농가에 경영정보를 제공하는
            낙농가 ‘종합경영정보관리’ 서비스를 말합니다.
          </dd>
        </dl>
        <ul className="pageGuide">
          <li>
            <img src={document} alt="젖소검정성적표 이미지" />
            <dl>
              <dt>젖소검정성적표</dt>
              <dd>농가에 제공하는 성적표별로 보는 방법을 안내합니다.</dd>
            </dl>
            <Link to="/Web/Data/HowtoReport">바로가기</Link>
          </li>
          <li>
            <img src={rankStar} alt="농가검정성적확인 이미지" />
            <dl>
              <dt>농가검정성적확인</dt>
              <dd>맞춤형 컨설팅 프로그램으로 농가별 검정성적을 조회합니다.</dd>
            </dl>
            {/* 2025-02-05 추가 */}
            {/* <Link to="/Web/Performance/PerformanceCheck">바로가기</Link> */}
            <a
              href="http://www2.dcic.co.kr/performace_02.do"
              target="_self"
              rel="noopener noreferrer"
            >
              바로가기
            </a>
          </li>
        </ul>
      </div>
      <div ref={howtoTestRef} className="howtoTest">
        <h5>검정방법</h5>
        <dl className="howtoTestTitle">
          <dt>
            유우군검정은 <span>어떻게 시행되고 있나요?</span>
          </dt>
          <dd>
            매월 검정원이 농가를 방문하여 개체별 유량, 번식기록, 사료급여량 등을
            측정하고, 우유샘플을 채취하여 성분을 분석한 후 검정 데이터를
            인터넷으로 입력하면 검정성적을 분석하여 농가에 경영정보를 제공하는
            낙농가 ‘종합경영정보관리’ 서비스를 시행하고 있습니다.
          </dd>
        </dl>
        <ul>
          <li>
            <img src={howto1Mo} alt="검진원 방문조사 사진" />
            <dl>
              <dt>검정원 방문조사&#40;월 1회&#41;</dt>
              <dd>산유량, 샘플채취</dd>
              <dd>번식 및 질병기록</dd>
              <dd>사료급여량</dd>
              <dd>원유출하량, 유대</dd>
              <dd>고용노동비 </dd>
            </dl>
          </li>
          <li>
            <img src={howto2Mo} alt="검정조합 사진" />
            <dl>
              <dt>검정조합</dt>
              <dd>유성분 검사</dd>
              <dd>체세포수 검사</dd>
              <dd>검정기록 입력</dd>
              <dd>검정농가지도</dd>
            </dl>
          </li>
          <li>
            <img src={howto3Mo} alt="젖소개량사업소 사진" />
            <dl>
              <dt>젖소개량사업소</dt>
              <dd>검정성적분석</dd>
              <dd>농가성적표통보</dd>
              <dd>검정조합지도</dd>
              <dd>검정원 교육지도</dd>
              <dd>사업비 지원</dd>
              <dd>각종 정보지 배부</dd>
            </dl>
          </li>
          <li>
            <img src={howto4Mo} alt="검정농가 사진" />
            <dl>
              <dt>검정농가</dt>
              <dd>검정성적표 접수</dd>
              <dd>경영계획 수립</dd>
              <dd>계획교배 결정</dd>
              <dd>유질 개선</dd>
              <dd>사양관리 개선</dd>
              <dd>다양한 정보망 활용</dd>
            </dl>
          </li>
        </ul>
      </div>
      <div className="cowTestJoin">
        <h5>유우군 검정사업 참여하기</h5>
        <p>
          낙농경영을 위한 필수도구,<span>유우군 검정사업</span>
        </p>
        <div className="benefit">
          <strong>이점</strong>
          <ul>
            <li>
              <p>개체별로 정확한 생산 능력</p>
              <span>당월, 누적, 365일, 성년성적</span>
              <span>유량, 유지방, 유단백 등</span>
            </li>
            <li>
              <p>수지타산에 맞는 낙농을 보장</p>
              <span>낙농 경영관련 지표 활용</span>
              <span>체세포수, 체중</span>
              <span>농후사료비, 유대수입 등</span>
            </li>
            <li>
              <p>정확한 번식관리 정보로 번식 효율 개선</p>
              <span>건유일, 공태일, 발정일</span>
              <span>분만예정일, 지정종모우명 등</span>
            </li>
            <li>
              <p>후대검정 참여시 혜택 제공</p>
              <span>등록비, 사양비 지원 등</span>
              <span>한국씨수소 선발에 기여</span>
            </li>
            <li>
              <p>합리적인 사양관리 정보를 제공</p>
              <span>급여 사료의 영양수준 및 단가 분석</span>
              <span>영양소 요구량, 섭취량 등</span>
            </li>
          </ul>
        </div>
      </div>
      <div ref={howtoCowTestJoinRef} className="howtoCowTestJoin">
        <h5>참여방법</h5>
        <ul>
          <li>
            <div className="imgArea">
              <img src={farm} alt="농가 사진" />
              <span>농가</span>
            </div>
            <div className="textArea">
              <p>농가의 모든 암소를 지속적으로 검정에 참여시켜야 합니다</p>
              <p>소정의 검정료를 지불하셔야 합니다.</p>
              <p>
                사업을 충분히 이해하고, 표준 검정 방식이 준수되도록 적극
                협조해야 합니다.
              </p>
              <p>혈통 및 개체 식별관리를 철처히 해야 합니다.</p>
            </div>
          </li>
          <li>
            <div className="imgArea">
              <img src={human} alt="검정조합 사진" />
              <span>검정조합</span>
            </div>
            <div className="textArea">
              <p>
                축, 낙협, 낙농관련, 비영리 법인에서 희망농가를 대상으로
                사업신청을 합니다. &#40;10-15농가, 최소 300두 이상&#41;
              </p>
              <p>자격기준에 맞는 검정원을 선발, 업무를 수행토록 합니다.</p>
              <p>
                검정절차 및 방법, 수집자료 입력 및 송부등은 검정주관 기관의
                사업추진방식에 따릅니다.
              </p>
            </div>
          </li>
          <li>
            <div className="imgArea">
              <img src={building} alt="젖소개량사업소 사진" />
              <span>젖소개량사업소</span>
            </div>
            <div className="textArea">
              <p>사업지도 감독 및 사업비를 지원합니다.</p>
              <p>
                검정성적을 분석하여 농가에 알려드립니다. &#40;인터넷 또는
                우편&#41;
              </p>
              <p>검정원 교육과 농가에 대한 사업홍보를 수행합니다.</p>
            </div>
          </li>
        </ul>
      </div>
      <div ref={cooperPointRef} className="cooperPoint">
        <CooperPoint />
      </div>
    </div>
  );
}

export default BestCowTest;
