import React from 'react';

function Fo38() {

  return (
    <div className="techinfo_view">
				<p className="viewTitle">무기력증</p>
				<dl>
					<dt>발병대상</dt>
					<dd>소</dd>
				</dl>
				
				<dl>
					<dt>원인</dt>
					<dd>무기력증은 일반적으로 가축이 주저 앉는 현상으로 예를 들어 무감각증, 관절염, 분만후의 마비, 저온 스트레스, 부적절한 영양관리, 다른 광물질과 구리대사의 상호작용, 탈진, 저마그네슘 혈증, 케토시스, 유선염, 자궁근층염, 유열, 단백질 에너지 비율의 불균형, 중독 및 외상등과 같은 요인에 의하여 유발된다. 일반적으로 무기력증에 걸린 송아지는 앞다리에는 문제가 없지만 뒷다리가 마비되고 일어설 수 없게 된다. 무기력증에 걸린 송아지는 꼬리부분의 좌골신경과 근육에 압박을 가하여 6시간이내에 뒷다리에 마비를 일으킬 수 있음이 실험적으로 입증되었다</dd>
				</dl>
				
				<dl>
					<dt>징후 및 증상</dt>
					<dd>무기력증에 걸린 가축은 드러누어서 기립하기 싫어하고 또한 무기력함을 나타낸다</dd>
				</dl>
				
				<dl>
					<dt>질병분포와 손실규모</dt>
					<dd>무기력증은 전세계 어느 지역에서나 발병될 수 있으며 특히 비육중인 축군애 10%이상이 이와 같은 질병으로 시달린다.</dd>
				</dl>
				
				<dl>
					<dt>치료</dt>
					<dd>세심한 관리가 최상의 치료법이다. 가축을 이동시킬때 가축과 나란히 가면서 걸음걸이와 드러누운 모양을 관찰하여 뒤로 처지거나 혼자되는 가축은 적절한 관리와 사료급여를 실시한다. 만약 드러누운 가축이 2주 경과하였음에도 별다른 진전이 없을 때에는 압박에 의한 근육의 불치와 신경에 손상을 입어 결국 기립불능 상태에 빠지게 된다. 성축의 경우 hip lifter를 사용하면 근육에 손상을 가중시키어 증세를 호전시키기 보다는 오히려 악화시킬 위험이 있다. 기중기(Slings)는 근육손상을 덜 입히지만 사용하기가 어렵다. 현재 일부 제품중에는 가축의 다리 사이에 넣어 서서히 기립하도록 도와주어 병세를 치료하는 공기 주머니(air bag)도 있다. </dd>
				</dl>
				
				<dl>
					<dt>대책</dt>
					<dd>1) 비육우 : 가을철에 축군의 상태를 관찰하여 어린 숫송아지와 야윈가축들을 분리해서 특별한 사료를 급여한다.</dd>
					<dd>2) 유우 : 유열에 걸리기 쉬운 가축은 적절하고 알맞게 사료를 급여한다.</dd>
				</dl>
				
				<dl>
					<dt>예방</dt>
					<dd>1) 비육우 : 보충사료 급여시에는 일일 두당 옥수수 2.5-3kg과 대두박 0.5-1kg을 급여한다.</dd>
					<dd>2) 유우 : 유열의 징후를 조기에 발견하여 기립불능의 상태로 되기전에 치료를 하여야 한다.</dd>
				</dl>	
				
				<dl>
					<dt>참고</dt>
					<dd>&quot;무기력증&quot;이라는 용어는 적절한 용어는 아니다. 이유는 가축들이 여러 질병에서 이러한 증상을 보이기 때문이다</dd>
				</dl>
				
			</div>
  );
}

export default Fo38;
