import React from 'react';
import OxData from 'containers/web/ox/oxData/OxData';
import { Helmet } from 'react-helmet-async';

function OxDataPage() {
  return (
    <>
      <Helmet>
        <title>씨수소 자료해설</title>
      </Helmet>
      <OxData />
    </>
  );
}

export default OxDataPage;
