import React from 'react';

import Figure2by4 from 'assets/images/sub/_images/figure_2_4.gif';
import Figure2by5 from 'assets/images/sub/_images/figure_2_5.gif';

function Fo12() {

  return (
    <div className="techinfo_view">
				<p className="viewTitle">발정관찰과 인공수정 - 인공수정</p>
				<p>인공수정은 시술자가 인위적으로 정액을 생식기에 주입하여 임신을 가능하게 하는 기술을 말한다. 19세기 말 말을 연구하던 러시아의 과학자들에 의해서 개발되었다. 20세기초 덴마크의 육종학자들은 유우에 광범위하게 사용하였다. 1949년에는 정액을 동결하여 장기간 보존이 가능하게 됨으로서 인공수정의 효율을 증진시키는 계기가 되었다.</p>
				
				<dl>
					<dt>인공수정의 적기</dt>
					<dd>
						<p>소를 수태시키기 위해서는 인공수정의 시기가 아주 중요하다. 정화시간과 장소에서 정자와 난자가 결합을 하면 임신이 가능하다. 난자는 발정이 끝난 후 10∼14시간에 배란되며 6∼12시간 생존이 가능하다. 정자는 암소의 생식기관에 저류되어 있는데, 24시가 동안 생존이 가능하다. 발정개시후 12∼18시간 사이에 인공수정을 시키면 최고의 수태율을 얻을 수가 있다(그림2.4). 발정개시시간을 알 수가 없을 경우에는 발정 용모자세발정(Standing heat)이 끝날 무렵에 수정을 인공수정을 시키는 것이 유리하다. </p>
						<p>인공수정을 일찍하거나 시기가 늦을 경우에도 수태율이 감소하는 경향이 있다. 일반적으로 아침에 발정관찰이 되면, 저녁에 수정을 시키고, 오후에 발정관찰이 되면 다음날 아침에 수정을 시키는 것이 바람직하다.</p>
						<p>최근의 연구결과에 의하면, 발정이 온 시간에 관계없이 낮에 인공수정을 하여도 수태율에는 영향이 없다는 보고도 있다. 아침 8시에서와 11시사이에 수정을 시켜 최고의 수태율을 얻었다고 한다. </p>
					</dd>
				</dl>
				<div className="imgBox"><img src={Figure2by4} alt=""/></div>
				
				<dl>
					<dt>인공수정기술의 숙련 </dt>
					<dd>
						<p>성공적으로 인공수정을 실시하여 소를 수태시키기 위해서는 전과정을 숙지하고 있어야 하며, 주기적으로 교육을 받아야 한다. 성공적인 인공수정을 위해서는 다음과 사항을 숙지해야 한다.</p>
						<ul className="listStyle04">
							<li>적절한 정액보관방법</li>
							<li>정액의 융해법과 스트로의 취급법</li>
							<li>정확한 정액주입 위치</li>
						</ul>
					</dd>
				</dl>
				
				<dl>
					<dt>정액의 보관</dt>
					<dd>대부분의 정액은 0.5㎖의 플라스틱 스트로에 밀봉되어 있으며, 액체질소통에 보관한다. 농가에서 사용하고 있는 정액보관고는 정기적으로 보관고의 액체질소량을 점검하고 보충해 주어야 한다. 동결정액을 0℃ 정도에 보관하면 정자는 손상을 받게되며, -100℃∼-73℃러 온도가 상승하면 수정율이 낮아진다. 스트로를 정액보관고에서 꺼집어 낼 경우에는 정액이 들어 있는 캐니스터는 가능한 보관고의 목부위 아래에서 꺼집어 내어 온도변화를 줄여야 한다.</dd>
				</dl>
				
				<dl>
					<dt>정액보관고 </dt>
					<dd>
						<p>액체질소통은 효율적으로 액체질소를 보관할 수 있는 보관고로서(그림2.5.), 액체질소가 증발될 수 있게 내부는 제작되어 있다. 액체질소통의 마개는 내부탱크에서 액체질소가 안전하게 증발되도록 되어있다. 만약에 완전하게 밀폐되어 있다면, 액체질소통은 폭발하게 될 것이다. 액체질소 그자체는 폭발성과 독성이 없지만, 피부와 접촉하게 되면 동상에 걸릴 수 있다. </p>
						<p>액체질소통은 통풍이 잘되고, 외부의 충격을 피할 수 있으며 물이나 화학약품이 없는 건조한 곳에 보관하는 것이 좋다. 또한, 콘크리트 바닥보다는 편평한 판지위에 두거나 매일 관찰이 가능한 곳에서 보관하는 것이 바람직하다. 보관고의 목부위에 성애가 생기는 것은 단영이 되지 않거나 액체질소가 급속히 증발되었다는 것을 의미한다. 이런 경우에는 막대기로 보관고에 남아 있는 액체질소량을 측정한다. 만약에 액체질소가 남아 있으면 동결정액의 상태는 양호하지만, 그렇지 않을 경우에는 손상을 받지 않도록 다른 보관고로 옮긴다.</p> 
					</dd>
				</dl>
				<div className="imgBox"><img src={Figure2by5} alt=""/></div>
				
				<dl>
					<dt>정액의 융해</dt>
					<dd>정자의 수태능력을 유지하기 위해서는 동결정액은 급속융해를 실시한다. 동결정액은 공기중에서 융해하는 것보다 온수에서 신속히 융해하는 것이 바람직하다. 동결정액은 32∼35℃의 항온수조에서 30∼40초 동안 융해한다. 융해온도는 정자의 생존에 영향을 미치기 때문에 정확한 온도를 유지하는 것이 수태에 도움이 된다. 동결융해후 정자의 생존비율이 40％정도이면 정상적이며, 공기중에서 융해하면 생존율이 30％이하가 된다. 정자는 완만하게 융해하면 융해시 결빙이 형성되어 정자의 내부세포에 손상을 주기 때문에 생존율이 낮다.</dd>
				</dl>
				
				<dl>
					<dt>인공수정</dt>
					<dd>
						<p>인공수정을 성공적으로 수행하기 위해서는 암소의 생식기의 구조를 이해하고 적당한 훈련이 필요하다. 숙련된 시술자는 박테리아 등의 세균의 감염을 피하기 위하여 위생적으로 인공수정을 실시한다. 비숙련자는 세균감염, 위생관념의 부재로 인하여 인공수정이 실패가 되는 경우도 있다. </p>
						<p>자연교미의 경우, 수소는 정액을 암소의 생식기에 사정을 하지만, 인공수정의 경우에는 스트로는 자궁경관을 통과하여 자궁체에 주입해야 한다. 이렇게 하기위해서 시술자는 팔을 조심스럽게 직장내에 넣어야 한다. 지나친 동작으로 직장내에 공기가 들어가면 “직장의 풍선화”가 되어 생식기관을 다루는데 어려움이 생긴다. 자궁경관은 팔을 뻗으면 직장위에 위치하고 있다. </p>
						<p>숙련된 시술자는 자궁결관 취급방법을 잘 알고 있는데, 팔을 조심스럽게 직장내에 넣어서 자궁경관을 잡고 인공수정용 피펫을 질을 통하여 자궁경관입구로 전진시킨다. 피펫 끝이 자궁경관의 반대쪽으로 나오면 시술자는 자궁체에 정액을 서서히 주입시킨다. </p>
					</dd>
				</dl>
				
				<dl>
					<dt>저수태우</dt>
					<dd>
						<p>3∼4회 수정후 수태가 되지 않는 소를 저수태우라고 한다. 저수태우는 발정주기가 규칙적이며, 발정징후는 뚜렷하며, 직장검사로 난소검사를 해보면 정상적이다. 정상적인 암소에서 약 9∼12％만이 임신이 되기 위해서는 3회이상의 수정이 필요하다. 표 2.3은 우군의 평균분만율과 저수태우의 관계를 나타내고 있다. 평균분만율 낮은 소는 3회이상 교미후 임신되지 않는 수가 많았다.</p>
						<table className="tablestyle mg15t">
							<caption>(표3) 최근 10년간에 있어서 낙농가 호수, 사양두수 변화(일본) </caption>
							<thead>
								<tr>
									<th rowSpan="2" className="first">임신두수/100두</th>
									<th colSpan="2">임신율(%)</th>
								</tr>
								<tr>
									<th>3회수정 또는 이하</th>
									<th>3회이상 수정</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>70</td>
									<td>97</td>
									<td>3</td>
								</tr>
								<tr>
									<td>60</td>
									<td>94</td>
									<td>6</td>
								</tr>
								<tr>
									<td>50</td>
									<td>88</td>
									<td>12</td>
								</tr>
								<tr>
									<td>40</td>
									<td>78</td>
									<td>22</td>
								</tr>
								<tr>
									<td>30</td>
									<td>66</td>
									<td>34</td>
								</tr>
							</tbody>
						</table>
						<p>저수태우들은 수태율을 향상시키기 위해서는 수정시에 GnRH를 주사한다. GnRH는 황체기능을 강화시킴으로서 프로제스테론을 분비하는데, 임신을 유지하는데 필수적인 호르몬이다. 또한, GnRH는 임신초기에 수정란의 사멸을 줄이며, 3회 수정시1,2회보다 3회수정시킬때, GnRH를 주사하면 좋은 결과를 얻을 수 있다고 한다. </p>
					</dd>
				</dl>
				
				<dl>
					<dt>저 수태율의 원인 </dt>
					<dd>
						<p>수태율이 낮은 소가 아니더라도 3회이상의 수정후에도 수태가 되지 않는 이유에는 여러 가지가 있다. 저수태우는 우군의 약9∼12％이지만, 임신이 되기 위해서는 15％이상의 소가 3회이상의 수정이 필요로 한다.</p>
						<ul>
							<li>1) 발정관찰과 연관된 문제
								<ul className="listStyle04 mg10f">
									<li>발정 후 부적절한 수정시기</li>
									<li>발정시 수정을 하지 않은 경우</li>
									<li>번식기록의 오류로 인해 수정할 소가 확인되지 않을 경우</li>
								</ul>
							</li>
							<li>2) 자연교미와 인공수정과의 문제
								<ul className="listStyle04 mg10f">
									<li>수태능력이 낮은 수소 </li>
									<li>부적절한 인공수정기술</li>
								</ul>
							</li>
							<li>3) 소
								<ul className="listStyle04 mg10f">
									<li>자궁염</li>
									<li>호르몬이상</li>
									<li>난관장애</li>
									<li>해부학적인 기형 </li>
									<li>조기배사멸</li>
								</ul>
							</li>
							<li>4) 영양과 관련된 문제</li>
						</ul>
					</dd>
				</dl>
			</div>
  );
}

export default Fo12;
