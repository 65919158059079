import React from 'react';

import Pic145 from 'assets/images/sub/_images/pic145.gif';

function Do18() {

  return (
    <div className="techinfoview">
				<h1>생식세포의 형태 및 생리</h1>
				<h2>생식세포의 형태</h2>
				<ul>
					<li>정자의 형태와 구조
						<p>- 정자는 두부, 경부, 미부로 구성<br/>- 정자의 길이 50 - 70㎛ 정도 <br/>- 표면은 지질단백막으로 덮여 있음 <br/>- 두부 : 부계의 유전물질 (핵) <br/> - 미부 : 운동기관</p>
						<ul>
							<li>두부
								<ul>
									<li>정자 두부의 크기는 8 × 4 × 1㎛ 정도인 편평한 난원형 </li>
									<li>주로 핵으로 구성 </li>
									<li>핵의 전반을 덮고 있는 부위 : 첨체 </li>
									<li>첨체 : 첨체 물질 </li>
									<li>첨체물질 : 지질, 당단백질 복합체인 하이알루로니데이스와 단백질 분해효소인 아크로산을 함유</li>
									<li>첨체는 수정과정에서 중요한 첨체 반응을 하게 됨 </li>
									<li>첨체물질이 난자의 투명대를 녹여 뚫고 들어가는데 작용</li>
								</ul>
							</li>
							<li>경부
								<ul>
									<li>정자의 두부와 미부를 연결 </li>
									<li> 정자 경부의 길이 1㎛ 정도 </li>
									<li>결합부와 중심소체로 구성 </li>
									<li>경부의 아래부위에는 미토콘드리아의 일부가 덮여 있음</li>
								</ul>
							</li>
							<li>미부
								<ul>
									<li>정자 미부의 길이 40 - 50㎛ 정도 </li>
									<li>미부는 중편부, 주부, 종부로 구성 </li>
									<li>미부의 중심에는 운동장치의 주역인 축계가 있음</li>
								</ul>
							</li>
						</ul>
					</li>
					<li>난자의 형태와 구조
						<p>- 난자는 세포질과 난황을 함유<br/>- 배란시 난자의 크기 100 -150㎛ 정도 <br/>- 난자는 핵, 세포질, 난막으로 구성<br/>- 난막의 둘레에는 방사관세포들이 부착</p>
						<ul>
							<li>핵
								<ul>
									<li>난자의 핵은 DNA와 단백질로 구성 </li>
									<li>난자의 중심에서 약간 편재</li>
								</ul>
							</li>
							<li>세포질
								<ul>
									<li>난자의 세포질은 RNA, 단백질, 각종 탈수소효소, 난황과립등이 분포</li>
								</ul>
							</li>
							<li>난막
								<ul>
									<li>성숙 난자은 2개의 막으로 난황막과 투명대로 구성 </li>
									<li>난황막은 세포질에서 유래한 엷은 막 </li>
									<li>수정이 된 난자는 난황이 수축으로 2개의 막이 분리 </li>
									<li>2개의 막 사이를 위난강이라 함 </li>
									<li>수정후 위난강내 극체가 형성 
										<p>&lt;투명대&gt;</p>
										<ul>
											<li>균질한 반투과막 </li>
											<li>성분은 중성 내지 약알카리성의 점성 단백질 (탄력성 풍부) </li>
											<li>기능은 방사관에서 난자로의 영양분 통로 </li>
											<li>배란된 난자가 자궁까지 도달하는 동안 난자의 표면을 보호</li>
										</ul>
									</li>
								</ul>
							</li>
						</ul>
					</li>
				</ul>
				<h2>생식세포의 형성</h2>
				<ul>
					<li>정자의 형성
						<ul>
							<li>출생시 정소의 세정관 - 원시생식세포와 미분화 지지세포 존재</li>
							<li>성장하면서 원시생식세포가 정원세포로 됨 </li>
							<li>춘기발동기 (사춘기) 
								<ul>
									<li>세정관의 직경이 커지게 됨 </li>
									<li>정원세포에서 제 1정모세포로 변하면서 정자 형성이 시작됨 </li>
								</ul>
							</li>
							<li>정자 형성은 세정관이 위축 퇴화할때까지 일생동안 계속 진행</li>
						</ul>
					</li>
					<li>정자의 형성 과정
						<ul>
							<li> 정원세포(세정관의 기저막에 배열) 유사분열로 제 1 정모세포가 됨 </li>
							<li>1개의 정원세포에서 16개의 제 1 정모세포로 분열 </li>
							<li> 제 1정모세포 (세정관의 관강측에 위치) 제 1성숙분열로 제 2정모세포가 됨 <span style={{display:'block'}}>(염색체수 반감)</span></li>
							<li>제 2정모세포 유사분열(제 2성숙분열)로 정자세포가 됨 - 정자 발생 </li>
							<li>이후의 광정은 정자의 형태적 변화로 정자 완성</li>
							<li> 정자의 세포질 없어짐</li>
							<li> 정자세포는 상당히 크고 원형 </li>
							<li>정자는 치밀하고 두부와 미부로 운동성이 있는 세포</li>
							<li> 정자세포의 골지장치가 정자의 첨체로 변함 </li>
							<li> 미토콘드리아는 정자의 경부 하단에 존재</li>
							<li>최종적으로 1개의 정원세포는 64개의 정자를 형성 </li>
							<li> 정자가 완성되면 세정관을 통해 이행 </li>
							<li> 정자 형성에는 FSH, ICSH(간질세포자극호르몬), 정소호르몬이 작용 
								<ul>
									<li>정원세포의 발생기인 제 1성숙분열에는 정소호르몬 </li>
									<li>정자 완성의 말기에는 FSH가 필요</li>
								</ul>
							</li>
						</ul>
					</li>
					<li>난자의 형성과정
						<ul>
							<li>난자는 난소의 난포내 존재 </li>
							<li>원시난포는 출생전 이미 형성 
								<ul>
									<li>출생전 75,000개, 출생시 30,000개, 10세 이후 25,000개</li>
								</ul>
							</li>
							<li>원시난포는 제 1난모세포(제 1성숙분열 전기) 로 춘기발동기에 분열 </li>
							<li>난포의 발달 과정 
								<ul>
									<li>크기, 과립막세포층, 내막의 발달, 난포내 난모세포의 위치 등 </li>
								</ul>
							</li>
							<li>춘기발동기에 제 1난모세포와 난포세포 사이에 투명대가 형성 </li>
							<li>제 1난모세포가 난포상피세포로부터 영양을 공급받아 난황이 축적되면 제 2차난포가 됨 </li>
							<li>난포세포층이 증식되어 난포강이 형성되고 난포액이 충만하면 제 3차 난포가 됨 </li>
							<li>난포강이 커지고 단백질과 난포호르몬의 농도가 높은 난포액이 충만 
								<ul>
									<li>난소의 표면으로 돌출하고 배란 직전이 되는데 그라피안난포라 함 </li>
								</ul>
							</li>
							<li>배란 직전 제 1난모세포는 제 1성숙분열로 염색체수가 반감되면서 제 2난모세포가 되면서 제 1극체를 방출 </li>
							<li>배란 후 수정이 되면서 제 2성숙분열을 하고 제 2극체를 방출</li>
							<li>난포의 발육은 FSH의 자극으로 이루어짐 </li>
							<li>내협막세포에서 분비되는 난포호르몬에 의하여 발정이 오게됨 </li>
							<li>발정이 오면 FSH의 분비가 억제되고 LH의 분비가 촉진 </li>
							<li> LH에 의하여 배란 </li>
							<li>소의 경우 1회 발정기에 1 - 2개의 난포만 발달하고 1 - 2개의 난자만을 방출</li>
						</ul>
					</li>
				</ul>
				<h2>정자와 난자의 생리</h2>
				<ul>
					<li>정자의 생리
						<ul>
							<li>수컷 생식기 내의 정자 생리
								<ul>
									<li>곡세정관에서 형성된 정자는 직세정관 → 정소망 → 정소수출관 → 정소상체 두부로 진행 </li>
									<li>정자가 정소를 떠날때는 수정능력이 없음</li>
									<li>정소상체를 지나면서 성숙되고 정소상체 미부에서 저장됨
										<ul>
											<li>사정시 정관을 거쳐 요도로 진행하고 부생식선액과 함께 정액이 되어 체외로 사출</li>
										</ul>
									</li>
								</ul>
							</li>
							<li>정자의 운동성 
								<ul>
									<li>정자의 운동성은 정소상체를 통과하면서 획득 </li>
									<li>사정과 동시에 운동을 시작 </li>
									<li>인공수정시 주입 정액의 적합성을 판정하는 기준</li>
									<li>사정 후 정자의 운동성은 활발,(파상, 전진) 
										<ul>
											<li>미부는 파상, 두부는 종축을 종심으로 나선상 </li>
										</ul>
									</li>
									<li>정자의 운동속도 : 초당 100㎛ 정도 진행</li>
								</ul>
							</li>
							<li>암컷 생식기내 정자의 이동 
								<ul>
									<li>정자가 사출되는 부위는 질내(소) </li>
									<li>사출된 정자는 난관상부까지 이동 </li>
									<li> 난관팽대부까지 수정에 충분한 정자가 도달하는데는 일정시간 소요 </li>
									<li>자궁의 수축운동에 의하여 정액과 정액이 혼합하고 경관을 통과하여 자궁내로 이동 </li>
									<li> 사정된 정자는 경과에서 일부 여과하여 일부만 자궁내로 이동 </li>
									<li>자궁내 정자는 자궁난관면접부, 난관협부, 난관팽대부로 이동하면서 수가 제한되므로 다정자침입을 막을 수 있음.</li>
									<li>수정되지 않은 정자는
										<ul>
											<li>난관벽으로 흡수되거나</li>
											<li>백혈구의 식균작용으로 며칠내 제거되고, </li>
											<li>자궁운동으로 질로 하강 </li>
											<li>난관체를 통하여 복강내로 방출, 소실</li>
										</ul>
									</li>
								</ul>
							</li>
							<li> 정자의 생존 기간 및 수정능력 획득 
								<ul>
									<li>정자는 암컷 생식기내에서 1 - 2일 수정능력을 유지</li>
									<li>정자는 사정직후는 수정능력이 없음 </li>
									<li>자궁과 난관에서 2 - 3시간이 지나야 수정능력이 형성됨
										<p>&lt;수정능획득&gt;</p>
										<ul>
											<li>정자가 형태적, 생리적, 생화학적으로 변화 </li>
											<li>난자의 난구세포, 방사관층(세포), 투명대를 침입할 수 있는 능력</li>
										</ul>
									</li>
									<li>배란전 인공수정을 하게 되고 정자는 자궁과 난관을 이동하면서 수정능력을 획득하게 되며 수정부위에 도착하게 됨</li>
								</ul>
							</li>
						</ul>
					</li>
					<li>난자의 생리
						<ul>
							<li>난자의 수용
								<ul>
									<li>배란된 난자는 난관채로 수용</li>
									<li>난관 누두부를 거쳐 난관팽대부에 도착하여 수정</li>
									<li>난관채와 난관누두부는 배란시 넓게 퍼져 난소 표면과 밀착되므로 난자의 수용이 용이 </li>
									<li>난관채, 난관은 난포호르몬과 황체호르몬의 비율에 의하여 수축성이 조절 됨</li>
								</ul>
							</li>
							<li>난자의 수송
								<ul>
									<li> 난관체 → 난관누두부 → 난관팽대부 → 난관협부 → 자궁난관면접부 → 자궁으로 이동</li>
									<li>난자의 이동은 
										<ul>
											<li>난관내의 섬모운동</li>
											<li>난관벽의 근육작용</li>
											<li>난관팽대부 협부의 연결부와 자궁난관 연결부의 여러 기능</li>
										</ul>
									</li>
									<li> 위 생리적 작용은 배란후의 난소호르몬의 균형에 의하여 조절 </li>
									<li> 난자가 난관을 통과하는데 걸리는 시간 : 50 - 98시간 정도</li>
								</ul>
							</li>
							<li>난자의 수정능력 보유시간
								<ul>
									<li>난자의 수정능력 보유시간은 난자가 수정되어 정상적으로 발생할 수 있는 최대 기간으로 12 -24시간 </li>
									<li>난자는 난관협부에 이르면 수정능력 상실 </li>
									<li>종부(인공수정)를 늦게 하면 난자는 수정능력 보유시간 말기가 되므로 수정이 되지 않거나 수정란이 착상되지 않는 경우가 있음 </li>
									<li>이를 고려한 수정 적기 판정이 중요</li>
								</ul>
								<img src={Pic145} style={{width: '40%'}} alt=""/>
								<p style={{width: '40%', textAlign: 'center'}}>[난관 내에서의 난자의 하강]</p>
							</li>
						</ul>
					</li>
				</ul>
			</div>
  );
}

export default Do18;
