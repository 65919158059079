import React, { useEffect, useRef } from 'react';

// 컴포넌트
import SubTitle from 'components/subTitle/SubTitle';

// 이미지
import Logo from 'assets/images/common/header_logo_pc.png';
import Call from 'assets/images/containers/business/visit/call.svg';
import Marker from 'assets/images/containers/business/visit/marker.svg';
import Print from 'assets/images/containers/business/visit/print.svg';

// scss
import './visit.scss';

const { kakao } = window;

function Visit() {
  const mapRef = useRef(null); // map 객체를 useRef를 사용하여 저장

  // 초기 위치로 이동하는 함수
  const resetMap = () => {
    const map = mapRef.current;
    if (map) {
      map.panTo(new kakao.maps.LatLng(37.662749385749834, 126.8651527831102)); // 초기 위치로 이동
      map.setLevel(4); // 초기 확대 수준으로 설정 (원하는 레벨로 변경 가능)
    }
  };

  useEffect(() => {
    const container = document.getElementById('map');
    const options = {
      center: new kakao.maps.LatLng(37.662749385749834, 126.8651527831102),
      level: 4,
    };
    const map = new kakao.maps.Map(container, options);
    mapRef.current = map; // map 객체를 useRef에 저장

    const zoomControl = new kakao.maps.ZoomControl();
    map.addControl(zoomControl, kakao.maps.ControlPosition.RIGHT);

    const markerPosition = new kakao.maps.LatLng(
      37.662749385749834,
      126.8651527831102,
    );
    const marker = new kakao.maps.Marker({
      position: markerPosition,
    });

    marker.setMap(map);

    // 마커를 클릭했을 때 마커 위에 표시할 인포윈도우를 생성합니다
    const iwContent =
      '<div className="infowindow"><a href="https://map.kakao.com/link/to/젖소개량사업소 ,37.662749385749834, 126.8651527831102" rel="noreferrer" target="_blank">길찾기</a></div>'; // 인포윈도우에 표출될 내용으로 HTML 문자열이나 document element가 가능합니다
    const iwRemoveable = true; // removeable 속성을 ture 로 설정하면 인포윈도우를 닫을 수 있는 x버튼이 표시됩니다

    // 인포윈도우를 생성합니다
    const infowindow = new kakao.maps.InfoWindow({
      content: iwContent,
      removable: iwRemoveable,
    });

    // 마커에 클릭이벤트를 등록합니다
    kakao.maps.event.addListener(marker, 'click', () => {
      // 마커 위에 인포윈도우를 표시합니다
      infowindow.open(map, marker);
    });
  }, []);

  return (
    <div className="visit subCon">
      <SubTitle />
      <div id="map" style={{ width: '100%' }}></div>
      <ul className="mapBtn">
        <li>
          <a
            href="https://map.kakao.com/link/map/젖소개량사업소 ,37.662749385749834, 126.8651527831102"
            rel="noreferrer"
            target="_blank"
          >
            카카오 맵에서 보기
          </a>
        </li>
        <li>
          <a
            href="https://map.kakao.com/link/to/젖소개량사업소 ,37.662749385749834, 126.8651527831102"
            rel="noreferrer"
            target="_blank"
          >
            길찾기
          </a>
        </li>
        <li>
          <button type="button" className="resetBtn" onClick={() => resetMap()}>
            지도 초기화
          </button>
        </li>
      </ul>
      <div className="visitInfo">
        <img src={Logo} alt="젖소개량사업소 로고" />
        <ul className="visitInfoList">
          <li>
            <h4>고양사업장</h4>
            <ul className="visitInfoItem">
              <li>
                <img src={Marker} alt="위치" />
                <p>
                  경기도 고양시 덕양구 서삼릉길 233-127 &#40;경기도 고양시
                  덕양구 원당동 201-64&#41;
                </p>
              </li>
              <li>
                <img src={Print} alt="팩스번호" />
                <p>031 - 929 - 1080</p>
              </li>
              <li>
                <img src={Call} alt="위치" />
                <p>031- 929 - 1090</p>
              </li>
            </ul>
          </li>
          <li>
            <h4>영양사업장</h4>
            <ul className="visitInfoItem">
              <li>
                <img src={Marker} alt="위치" />
                <p>
                  경기도 고양시 덕양구 서삼릉길 233-127 &#40;경기도 고양시
                  덕양구 원당동 201-64&#41;
                </p>
              </li>
              <li>
                <img src={Print} alt="팩스번호" />
                <p>031 - 929 - 1080</p>
              </li>
              <li>
                <img src={Call} alt="위치" />
                <p>031- 929 - 1090</p>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Visit;
