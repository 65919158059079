import React from 'react';

import pic688 from 'assets/images/sub/_images/pic688.jpg';
import pic689 from 'assets/images/sub/_images/pic689.jpg';
import pic690 from 'assets/images/sub/_images/pic690.jpg';

function Do138() {

  return (
    <div className="techinfoview">
      <h1>제엽염</h1>
      <p className="pstyle">이 병은 옛날부터 말(馬)에서는 잘 알려져 있었지만 근년에 이르러 홀스타인 숫송아지에서 산발적으로 발생되고 있고 일명 로봇 병 또는 목마(木馬)병이라고도 하며 무균성(無菌性)염증을 나타내는 병이다. </p>
      <div className="leftpart">
        <h2>원인</h2>
        <ul>
          <li>농후사료의 과잉급여시 제1위 과산증으로 생긴 이상발효에 의한 유산(乳酸)과 히스타민이 발굽조직에 침투하여 발굽에 울혈과 염증을 일으켜 통증을 나타낸다. 후산정체, 자궁내막염, 유방염으로 생긴 히스타민 독소가 발굽조직의 혈관에 들어가 울혈과 염증을 일으킨 경우</li>
        </ul>
        <h2>증상</h2>
        <ul>
          <li>대개 체온이 상승하고 맥박과 호흡이 빨라진다. </li>
          <li>중상이 가벼울때는 운동을 싫어하고 특히 네 다리를 뻣뻣하게 세우고 로봇처럼 걷는다. </li>
          <li>병세의 악화가 되면 기립이 곤란하여 누워 있게 된다. </li>
          <li>발굽은 열감(熱感)이 있고 발굽의 양쪽끝을 누르면 통증을 나타낸다.</li>
        </ul>
        <h2>목장에서의 처치</h2>
        <ul>
          <li>농후사료 위주의 사양을 지양한다. 젖소의 경우 사료 중의 섬유소량을 18%이상으로 유지해 주어야 한다.</li>
          <li>발굽을 2∼3일간 냉습포하여 주며 수일간을 건초와 물 만으로 사양하고 이후 서서히 농후사료를 급여한다. </li>
          <li>본병에 걸린 소는 1일 1∼2회로 제한하여 농후사료를 급여한다. </li>
          <li>건강한 소의 위액을 6∼8l 정도 먹인다.</li>
        </ul>
      </div>
      <div className="rightpart">
        <img src={pic688} className="nonborder" alt=""/>
        <img src={pic689} className="nonborder" alt=""/>
        <img src={pic690} className="nonborder" alt=""/>
      </div>
    </div>
  );
}

export default Do138;