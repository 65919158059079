import React, { useState } from 'react';

// 컴포넌트
import NewGraphPari from './graphItem/NewGraphPari';
import NewGraphMilk from './graphItem/NewGraphMilk';
import NewGraphScc01 from './graphItem/NewGraphScc01';
import NewGraphFat01 from './graphItem/NewGraphFat01';
import NewGraphProt01 from './graphItem/NewGraphProt01';
import NewGraphMun01 from './graphItem/NewGraphMun01';
import NewGraphSolid01 from './graphItem/NewGraphSolid01';
import NewGraphFatDprot from './graphItem/NewGraphFatDprot';
import NewGraphMun02 from './graphItem/NewGraphMun02';
import NewGraphFatPProt from './graphItem/NewGraphFatPProt';
import NewNoticeCowlistV1 from './graphItem/NewNoticeCowlistV1';
import NewGraphTotal from './graphItem/NewGraphTotal';

// scss
import './graph.scss';

function Graph() {
  
  const [ chartTab , setChartTab ] = useState('산차별 분포도');
  const chartList = [
    '산차별 분포도',
    '비유일수별 유량 분포도',
    '비유일수별 체세포수 분포도',
    '비유일수별 유지방 분포도',
    '비유일수별 유단백 분포도',
    '비유일수별 MUN 분포도',
    '비유일수별 고형분 분포도',
    '유지방 : 유단백',
    'MUN : 유단백',
    '유지방 + 유단백',
    '주의개체 전체현황 보기',
    '전체 그래프 보기'
  ];

  return (
    <div className='dcicGraph'>
      <ul className='dcicGraphTab'>
        {
          chartList &&
          chartList.map((chart,index) => (
            <li key={index}>
              <button
                type='button'
                onClick={() => setChartTab(chart)}
                className={chartTab === chart ? 'current' : ''} 
              >
                {chart}
              </button>
            </li>
          ))
        }
      </ul>
      { chartTab === '산차별 분포도' && <NewGraphPari /> }
      { chartTab === '비유일수별 유량 분포도' && <NewGraphMilk /> }
      { chartTab === '비유일수별 체세포수 분포도' && <NewGraphScc01 /> }
      { chartTab === '비유일수별 유지방 분포도' && <NewGraphFat01 /> }
      { chartTab === '비유일수별 유단백 분포도' && <NewGraphProt01 /> }
      { chartTab === '비유일수별 MUN 분포도' && <NewGraphMun01 /> }
      { chartTab === '비유일수별 고형분 분포도' && <NewGraphSolid01 /> }
      { chartTab === '유지방 : 유단백' && <NewGraphFatDprot /> }
      { chartTab === 'MUN : 유단백' && <NewGraphMun02 /> }
      { chartTab === '유지방 + 유단백' && <NewGraphFatPProt /> }
      { chartTab === '주의개체 전체현황 보기' && <NewNoticeCowlistV1 /> }
      { chartTab === '전체 그래프 보기' && <NewGraphTotal />}
    </div>   
  );
}

export default Graph;
