import React, { useState } from 'react';

// 컴포넌트
import SubTitle from 'components/subTitle/SubTitle';
import OxNow from './OxNow';
import OxPast from './OxPast';

// scss
import './oxSearch.scss';

function OxSearch() {
  const [currentTab, setCurrentTab] = useState('now');

  return (
    <div className="oxSearch subCon">
      <ul className="oxSearchTab">
        <li>
          <button
            onClick={() => setCurrentTab('now')}
            type="button"
            className={currentTab === 'now' ? 'current' : ''}
          >
            씨수소 정보검색
          </button>
        </li>
        <li>
          <button
            onClick={() => setCurrentTab('past')}
            type="button"
            className={currentTab === 'past' ? 'current' : ''}
          >
            지난 씨수소 정보검색
          </button>
        </li>
        <li>
          <a
            href="/statics/_uploadFiles/_other/cow_info_book_2025_02_13.pdf"
            target="_blank"
            rel="noreferrer"
          >
            안내책자보기
          </a>
        </li>
      </ul>
      <SubTitle />
      {currentTab === 'now' && <OxNow />}
      {currentTab === 'past' && <OxPast />}
    </div>
  );
}

export default OxSearch;
