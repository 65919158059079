import React, { useState, useEffect } from 'react';
import { getState } from 'utils/api';
import CowInfoComponent from './CowInfoComponent';
import './slideshow.scss';

const AutoRotatingCowInfoComponent = ({ cows }) => {
  
  const [ oxData , setOxData ] = useState(null);
  const [ OxNowData , setOxNowData ] = useState(null);

  const [currentCowIndex, setCurrentCowIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(true);

  const baseDir = '/ox/oxlist';
  const props = {type:'main'};
  
  useEffect(()=> {
    getState(baseDir,props)
      .then(getdata => {
        if (getdata !== undefined) {
          setOxData(getdata);      
      }})
       .catch(error => {
        console.log(error);
       });
  },[]);

  /* useEffect(()=> {
    if (oxData) {
      setOxNowData(oxData);
      // console.log('### ox data :', OxNowData)    
    }
  },[oxData]); */

  useEffect(() => {
    let intervalId;
    if (isPlaying) {
      intervalId = setInterval(() => {
        setCurrentCowIndex((prevIndex) => (prevIndex + 1) % cows.length);
      }, 3000); // 3초마다 변경
    }
    return () => clearInterval(intervalId);
  }, [cows.length, isPlaying]);

  const togglePlay = () => {
    setIsPlaying(!isPlaying);
  };

  return (
    <div className="cowInfoCon">
      <div className="cow-info-container">
        {oxData && <CowInfoComponent cow={oxData[currentCowIndex]} />}
        <div className="controls">
          <button type="button" onClick={togglePlay}>
            {isPlaying ? '멈춤' : '재생'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AutoRotatingCowInfoComponent;
