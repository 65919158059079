import React from 'react';

import pic447 from 'assets/images/sub/_images/pic447.gif';

function Do228() {

  return (
    <div className="techinfoview">
      <h1>시설자재-콘크리트</h1>
      <h2>콘크리트의 질</h2>
      <p className="pstyle">&nbsp;&nbsp;&nbsp;목장시설에 사용되는 콘크리트는 분뇨, 동결과 해동, 팽창과 수축, 가축과 장비에 의한 마모 등에 견딜 수 있어냐 한다. 또한 콘크리트는 가축, 차량, 토양 및 분뇨의 무게를 견딜 수 있을 정도로 단단하고 두껍게 설치되어야 한다.(표113). 강하고 저항력 있는 콘크리트를 만들려면, 배합, 취급 및 건조가 적절해야 한다. <br/>&nbsp;&nbsp;&nbsp;시멘트반죽의 결합력에 의하여 콘크리트의 질이 좌우되는데. 물과 시멘트의 비율은 콘크리트의 강도와 내구성에 가장 많은 영향을 미치는 요소이다. 끝마무리용 시멘트 반죽을 만들 때는 물을 많이 사용하여야 하지만, 반죽에 물이 많이 포함되면 물이 증발된 후에 공간이 생기기 때문에 콘크리트가 약해진다. 시멘트 반죽이 너무 뻑뻑하여 다루기 힘들 경우에는, 물만 첨가시키지 말고 물과 시멘트를 배합기에 동시에 첨가하여야 한다. <br/>&nbsp;&nbsp;&nbsp;시설물에 요구되는 콘크리트의 강도가 표 114에 나타나 있다. 콘크리트 0.914㎥(1 cubic yard)에 3.8ℓ의 물을 첨가시키면, ①강도가 200∼300 psi 만큼 감소하고, ②수축이 약 10% 증가하며, ③콘크리트 1/4포대 만큼을 낭비하는 결과가 된다. 부적절한 배합도 콘크리트를 약하게 함으로, 콘크리트가 형성된 후에는 절대로 물이나 시멘트를 첨가시키지 말아야 한다. </p><br/>
      <p><b>&lt;표 113&gt; 바닥의 권장두께</b></p>
      <table className="tablestyle">
        <tr>
          <td>적 용 장 소</td>
          <td>두께,in</td>
        </tr>
        <tr>
          <td>사료조 앞바닥, 가끔 차량이 통과하는 바닥</td>
          <td>4</td>
        </tr>
        <tr>
          <td>포장된 Feedlots , 건물내 차도</td>
          <td>5</td>
        </tr>
        <tr>
          <td>곡류트럭과 같이 무거운 차량이 다니는 차도</td>
          <td>6</td>
        </tr>
      </table><br/>
      <p><b>&lt;표 114&gt; 콘크리트의 강도</b></p>
      <table className="tablestyle">
        <tr>
          <td rowSpan="2">사료조</td>
          <td rowSpan="2">대략적인 최소강도 </td>
          <td colSpan="2">최 대</td>
        </tr>
        <tr style={{background: '#273143',color: '#fff'}}>
          <td>물(갤론)/시멘트(포대) 시멘트(포대)</td>
          <td>물-시멘트비율, </td>
        </tr>
        <tr>
          <td>사료조</td>
          <td>4,500</td>
          <td>5.0</td>
          <td>0.23</td>
        </tr>
        <tr>
          <td>Feedlots, 바닥, 차도</td>
          <td>3,500</td>
          <td>6.0</td>
          <td>0.24</td>
        </tr>
        <tr>
          <td>발판(Footings)</td>
          <td>3,000</td>
          <td>6.5</td>
          <td>0.28</td>
        </tr>
      </table>
      <p>시멘트 1포대 = 42.3 kg ; 3.8ℓ의 물 = 3.76 kg</p>
      <h2>혼합재 (모래, 자갈 등) 콘크리트</h2>
      <p className="pstyle">&nbsp;&nbsp;&nbsp;콘크리트에 모래, 자갈 등과 같은 혼합재가 섞여 있으면, 미세한 공기주머니가 형성되어 콘크리트의 내구성을 현저하게 향상시킨다. 콘크리트 속에 있는 물이 얼면, 팽창압에 의하여 콘크리트가 파손된다. 그러나, 혼합재 콘크리트는 미세한 공기 주머니가 팽창압을 흡수하기 때문에 동결에 의한 콘크리트 손상을 방지 할 수 있다. 또한 혼합재 콘크리트를 사용하면, 작업능률이 향상되고 콘크리트 표면으로 흘러나오는 수분의 양이 감소된다. 모든 농업 건축에는 혼합재 콘크리트가 사용된다.(표 115) </p>
      <h2>콘크리트판 설치</h2>
      <p><b>&lt;표 115&gt; 혼합재의 크기에 따른 공기의 함량 </b></p>
      <table className="tablestyle">
        <tr>
          <td>혼합재의 크기, in</td>
          <td>공기의 함량, %</td>
        </tr>
        <tr>
          <td>1½, 2, 또는 2½</td>
          <td>5 ± 1</td>
        </tr>
        <tr>
          <td>¾ 또는 1</td>
          <td>6 ± 1</td>
        </tr>
        <tr>
          <td>⅜ 또는 ½</td>
          <td>7½ ± 1 </td>
        </tr>
      </table>
      <p className="pstyle">콘크리트판을 설치할 장소에 있는 유기물질, 잔디 등은 먼저 제거하고, 콘크리트를 깔 바닥면에 모래나 자갈을 6&quot; 두께로 깔아 배수가 잘되도록 한다. 콘크리트가 굳기 시작하면, 가장자리를 둥글게 하여 콘크리트가 떨어져 나가는 것을 방지한다.  콘크리트가 수축되어 갈라지는 것은 피할 수 없는데 이를 방지하기 위해서는, 콘크리트 두께의 ¼깊이까지 조절이음매(control joint)를 잘라 제멋대로 갈라지는 것을 방지 한다. 혼합할 때 물의 첨가량을 줄이면 수축되어 갈라지는 문제가 감소된다. 다음과 같이 콘크리트판을 직사각형으로 나눈다. <br/><br/>4 두께 ―→ 8&quot; × 12&quot; <br/>5 두께 ―→ 10&quot; × 15&quot; <br/>6 두께 ―→ 12&quot; × 18&quot; <br/><br/>콘크리트의 조절이음매를 뾰족한 흙이나 괭이를 이용하여 설치 직후 자르거나, 건조가 끝난 후 갈라지기 전에 짜른다. 건물, 콘크리트 급수탱크, 포장된 도로를 따라 ½&quot;넓이의 격리이음매(isolation joint)를 설치하여야 한다. 여름철에 팽창되어 콘크리트가 융기되는 것을 방지하기 위하여, 긴 부분에 팽창이음매(expansion joint)를 설치한다. 또한, 가장자리는 콘크리트를 두껍게 설치하여 깨지지 않게 하고 토양침식에 대비한다. </p>
      <h2>보존건조(curing) </h2>
      <p className="pstyle">&nbsp;&nbsp;&nbsp;갓 설치된 콘크리트의 표면으로부터 너무 많은 수분이 증발되면, 콘크리트의 최종강도가 현저하게 감소되므로, 콘크리트를 공기 중에 노출시켜 건조시키면 안된다. 콘크리트를 보존건조시키는 방법에는 ①플라스틱 필름으로 덮거나, ②계속해서 물을 뿌려 주거나, ③젖은 삼베포대나 짚으로 덮거나, 또는 ④건조용 분말을 사용하는 것 등이 있다. 공기 건조에 비하여, 숙성건조를 사용하면 콘크리트의 강도를 50%나 증가시킨다. 물에 의하여 송상되지 않을 정도로 콘크리트 표면이 굳었을 때, 보존건조를 시작하여, 5∼7일간 계속 실행한다. </p>
      <h2>미끄럼 방지 콘크리트바닥의 설치</h2>
      <p className="pstyle">&nbsp;&nbsp;&nbsp;빗자루질 처리로 거칠어진 축사 콘크리트 바닥표면은 트랙터 스크랩핑과 가축의 소통에 의하여 시간이 지나면 쉽게 마모되어 매끄러워진다. 바닥의 이용정도와 가축의 종류에 따라, 얼마나 바닥을 거칠게 할 것인가를 결정한다. <br/>&nbsp;&nbsp;&nbsp;그림 121에서 보는 바와 같이, 집에서 제작한 기구를 이용하여, 갓 설치된 콘크리트바닥에 미끄럼 방지를 홈을 만들 수 있다. 한쪽 방향으로 평행하게 만들 때는, 홈의 깊이와 넓이는 각각 1/2&quot;와 1/2&quot;∼1&quot;가 되게 하며, 홈간의 간격은 4&quot; o.c가 되도록 한다. 다이아몬드 형태로 만들 때는, 홈간의 간격은 6&quot; o.c가 되도록 한다. 홈은 가축이 소통하는 방향에 대하여 대각으로 위치하여야 한다. 넓은 홈의 경우, 청소하기가 불편하며, 작은 가축의 발이나 다리에 손상을 줄 수 있다. 소독이 필요한 경우에는, 홈이 방해가 될 수 있기 때문에 산화알루미늄 가루를 갓 설치된 콘크리트 표면에 뿌린다. (0.0125∼0.225kg/ft2). 석분의 크기는 4∼6 meshes/in 가 적당하다.</p><br/>
      <img src={pic447} width="30%" alt=""/>
      <p>그림 121. 홈파는 도구의 설계도</p><br/>
      <p className="pstyle">기계톱을 이용하여 매끄러운 기존 콘크리트바닥에 홈을 만들 수 있다. 또한 염소 처리된 고무페인트나 외부용 라텍스(latex)를 바닥표면에 칠하여 거칠게 할 수도 있는데. 보다 거칠게 하기 위하여 거칠게 분쇄된 옥수수가루나 톱밥을 페인트가 마르기 전에 뿌린다. 그러나, 매년 페인트칠은 다시 해야한다. 아주 심하게 닳았지만 튼튼한 바닥인 경우에는 바닥에 새로운 콘크리트를 입혀 계속해서 사용할 수 있다. 기존 콘크리트바닥에 새콘크리트가 잘 붙도록 하기 위해서는 , 특별히 청소를 잘하고 특수하게 콘크리트를 혼합하여야 한다.</p>
    </div>
  );
}

export default Do228;