import React from 'react';

import pic612 from 'assets/images/sub/_images/pic612.jpg';
import pic613 from 'assets/images/sub/_images/pic613.jpg';

function Do117() {

  return (
    <div className="techinfoview">
      <h1>자궁탈</h1>
      <p className="pstyle">자궁은 자궁광인대(子宮廣靭帶)의 지지를 받아 복강내에 매달려 있지만 분만후 자궁회복을 저해하는 여러 가지 요인에 의해 임신되었던 자궁각이 반전되어 음문 밖으로 탈출해 나온 상태를 말한다. </p>
      <div className="leftpart">
        <h2>원인</h2>
        <ul>
          <li>난산으로 인한 산도의 상처 및 무리한 후산제거 등으로 인한 통증으로 계속 뒤에 힘을 줄 때 </li>
          <li>과대태아. 쌍태아. 태수과다(胎水過多)등으로 자궁벽이 이완되어 분만후 무력한 상태로 자궁 수축이 불량할 때 </li>
          <li>영양불량, 운동부족, 체질허약 및 전고후저(前高後低)자세가 계속될 때 </li>
        </ul>
        <h2>증상</h2>
        <ul>
          <li>자궁이 체외로 빠져나오게 되면 소는 불안하여 아랫배에 힘을 주고 노책(勞責)을 계속하며 산통 증상을 나타낸다. </li>
          <li>채식과 반추는 중지된다. </li>
          <li>자궁에 체외로 빠져 나온 채 시간이 지나면 나궁은 울혈과 부종이 심해지고 점막은 전조해져 상철르 입기 쉽다. </li>
          <li>방치한 채 시간이 더욱 지나면 탈출부위가 자궁은 흑적색(黑赤色)으로 변하며 부패하면 패혈증을 나타낸다. </li>
        </ul>
        <h2>목장에서의 처치</h2>
        <ul>
          <li>시간이 지나면 경과될수록 자궁정복이 어려워지므로 신속한 조치가 필요하다. 
            <ul>
              <li>발견 즉시 수의사에게 연락한다. </li>
              <li>자궁점막의 오염과 손상 및 자궁의 울혈과 부종의 방지를 위해 자극성이 적은 소독액(희석된 베타딘액등)으로 자궁점막을 씻어서 묻어 있는 오물을 제거한 후 깨끗한 천이나 비닐로 싸서 외음부의 위치보다 약간 더 높게 들어 올려 준다. </li>
              <li>서투른 정복으로 자궁점막을 손상시켜서 예후가 불량해 진다. </li>
              <li>자궁 정복후에는 재발방지를 위해 전저후고(前低後高)의 자세를 취하도록 우상에 받침대를 설치해 둔다. 질탈에서의 간이 압정대를 장착해 주면 더욱 좋다. </li>
              <li>며칠간은 운동을 제한하고 안정시킨다.</li>
            </ul>
          </li>
        </ul>
      </div>
      <div className="rightpart">
        <img src={pic612} className="nonborder" alt=""/>
        <img src={pic613} className="nonborder" alt=""/>
      </div>
    </div>
  );
}

export default Do117;