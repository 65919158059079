import React from 'react';

import pic640 from 'assets/images/sub/_images/pic640.jpg';
import pic641 from 'assets/images/sub/_images/pic641.jpg';

function Do127() {

  return (
    <div className="techinfoview">
      <h1>갓난 송아지 가사증</h1>
      <p className="pstyle">갓 태어난 송아지에서 조산 또는 난산으로 인해 심박동은 있으나 호흡이 정지하였거나 미약한 상태 또는 증상을 말한다.</p>
      <div className="leftpart">
        <h2>원인</h2>
        <ul>
          <li>분만시에 강한 진통으로 너무 일찍 파수가 되었거나 태반이 조기에 모체와 떨어졌을 때 </li>
          <li>미약진통, 태아 과대등으로 분만이 지연되었을 때 </li>
          <li>역산으로 만출시 태아가 골반에 걸려 제대가 압박된 상태로 시간이 경과 했을 때</li>
        </ul>
        <h2>증상</h2>
        <ul>
          <li>호흡은 완전히 멈추거나 몹시 약하고 심박동은 있다. </li>
          <li>可視 점막(눈, 구강, 질점막 등)은 창백하다. </li>
          <li>콧속과 입안에 점액이 들어 있는 수가 있다. </li>
        </ul>
        <h2>목장에서의 처치</h2>
        <ul>
          <li>II장 간호와 응급처치, 송아지 관리편을 참조하여 인공후흡을 실시한다. </li>
          <li>강심제를 주사하여 준다.</li>
        </ul>
      </div>
      <div className="rightpart">
        <img src={pic640} className="nonborder" alt=""/>
        <img src={pic641} className="nonborder" alt=""/>
      </div>
    </div>
  );
}

export default Do127;