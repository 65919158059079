import React from 'react';
import Main from 'containers/web/Main';
import { Helmet } from 'react-helmet-async';

function MainPage() {
  return (
    <>
      <Helmet>
        <title>젖소개량사업소</title>
      </Helmet>
      <Main />
    </>
  );
}

export default MainPage;
