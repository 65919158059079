import React from 'react';
import PerformanceCheck from 'containers/web/performance/performanceCheck/PerformanceCheck';
import { Helmet } from 'react-helmet-async';

function PerformanceCheckPage() {
  return (
    <>
      <Helmet>
        <title>농가별 검정성적 조회</title>
      </Helmet>
      <PerformanceCheck />
    </>
  );
}

export default PerformanceCheckPage;
