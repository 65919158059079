import React from 'react';

import pic751 from 'assets/images/sub/_images/pic751.jpg';
import pic752 from 'assets/images/sub/_images/pic752.jpg';
import pic753 from 'assets/images/sub/_images/pic753.jpg';

function Do160() {

  return (
    <div className="techinfoview">
      <h1>기종저</h1>
      <div className="leftpart">
      <p className="pstyle">이 병은 기종저균의 감염에 의해 근육과 피하조직에 기종(氣腫)과 종창(腫脹)을 일으키는 것이 특징이다. 이 세균은 피막을 형성하고 있어 열이나 소독약에 극히 강하다. 따라서 일단 토양이 오염되면 그 지역은 상재지(常在地)가 되며 감염된 소의 분변과 타액 및 오염된 사료를 통해 전파된다. </p>
        <h2>증상</h2>
        <ul>
          <li>생후 6개월에서 24개월사이의 육성우에서 많이 발생하며 열이 오르고(41∼42℃근육과 피하의 종창이 특징적으로 나타난다. </li>
          <li>식욕감퇴, 쇠약, 파행을 보이고 사지의 피부온도는 저하된다. </li>
          <li>이 세균은 가스(Gas)를 생성하기 때문에 종창부위에는 가스가 차있어 종창부위를 손가락으로 누르면 모발(毛髮)을 비벼대는 듯한 특이한 소리가 난다. </li>
        </ul>
        <h2>목장에서의 처치</h2>
        <ul>
          <li>이병은 가축전염병예방법에 의해 제1종 가축전염병으로 지정되어 있으므로 본병으로 진단되면 법률에 따른 처치가 필요하다.</li>
          <li>잘못하여 목장자체에서 방혈(防血)시키거나 환축을 이동시키는 것은 토양을 오염시킬 염려가 있으므로, 본 병으로 의심이 될 때는 수의사 또는 가축위생 시험소에 연락하여 확실한 진단을 받고 지시에 따라야한다.</li>
        </ul>
      </div>
      <div className="rightpart">
        <img src={pic751} className="nonborder" alt=""/>
        <img src={pic752} className="nonborder" alt=""/>
        <img src={pic753} className="nonborder" alt=""/>
      </div>
    </div>
  );
}

export default Do160;