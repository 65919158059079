import React from 'react';

import Pic147 from 'assets/images/sub/_images/pic147.gif';


function Do21() {

  return (
    <div className="techinfoview">
				<h1>종빈우의 사양관리</h1>
				<p style={{lineHeight: '200%'}}>종빈우의 사육목적은 비육유와 달리 우량 송아지를 생산하는데 있다. 그러므로 그에 적절한 사양관리가 요구되는바 수태율을 향상시키기 위해서는 우선 개체의 철저한 사양관리를 해야 한다. 즉, 성장중의 육성우와 성장이 완료된 큰소도 번식작용에 크게 관여하는 가소화영양분총량(TDN)을 비롯한 단백질, 무기질, 비타민 A와 비타민 E 등 영양소를 충분히 급여해야 한다. 이들 영양소가 부족할시는 체성장이 부진하고 생식기관의 발육부진이나 기능감퇴, 위축 등으로 성선자극 Hormone의 분비가 비정상적으로 되어 성성숙(초발정)의 지연과 발정 및 배란의 이상, 수정이나 착상의 불량등으로 수태율이 떨어지게 된다. 따라서 종빈우는 양질의 조사료를 충분히 급여하고 부족하기 쉬운 영양분의 보충정도만 농후사료로 급여해야 한다.</p><br/>
				<p>[영양 수준에 따른 성성숙]</p>
				<table className="tablestyle">
					<tr>
						<td>구분</td>
						<td>나이(개월명)</td>
						<td>체중(Kg)</td>
					</tr>
					<tr>
						<td>표준사양</td>
						<td>13.5</td>
						<td>190.7</td>
					</tr>
					<tr>
						<td>저영양</td>
						<td>15.5</td>
						<td>185.9</td>
					</tr>
				</table><br/>
				<p>[한우의 번식적령기]</p>
				<table className="tablestyle">
					<tr>
						<td>구분</td>
						<td>나이(월령)</td>
						<td>체중(Kg)</td>
					</tr>
					<tr>
						<td>암소</td>
						<td>15 - 18</td>
						<td>250 - 300</td>
					</tr>
					<tr>
						<td>숫소</td>
						<td>15 - 20</td>
						<td>300 - 350</td>
					</tr>
				</table><br/>
				<ul>
					<li>너무 어릴때 수태시키면
						<ul>
							<li>어미소
								<ul>
									<li>수태율이 낮고</li>
									<li>산후회복이 늦어지고 </li>
									<li> 번식연한이 단축되고</li>
									<li>초산후 성장이 지연되며 </li>
									<li> 난산의 위험이 있다.</li>
								</ul>
							</li>
							<li>송아지
								<ul>
									<li>허약축이 될 가능성이 높고 </li>
									<li>발육이 뒤진다. </li>
									<li>폐사할 위험이 크다.</li>
								</ul>
							</li>
						</ul>
					</li>
					<li>한우 발정주기 및 발정지속 시간
						<ul>
							<li>발정주기 평균 21일(18-24일)</li>
							<li>발정지속시간 평균 20시간(12 - 36시간)</li>
						</ul>
					</li>
					<li>발정증세
						<ul>
							<li>외음부가 붓고, 경관 점액이 흐른다. </li>
							<li>가벼운 흥분상태를 보이며, 눈이 충혈된다. </li>
							<li>승가를 하거나 승가를 허용한다.</li>
						</ul>
						<img src={Pic147} style={{border: '0px', padding: '0px', width: '50%', borderRadius: '0%'}} alt=""/>
						<p style={{width: '50%', textAlign: 'center'}}>[인공수정 적기 판정 요령]</p>
					</li>
				</ul>
			</div>
  );
}

export default Do21;
