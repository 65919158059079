import React, { useState , useEffect } from 'react';

// api
import { postState } from 'utils/api';
import {openCrownix , openCrownix2 , openCrownix3 , openCrownix6} from 'utils/openCrownix';

// 이미지
import PrintPDF from 'assets/svgs/common/print_PDF.svg';
import Logo from 'assets/images/common/header_logo_pc.png';

// 컴포넌트
import ErrorMessage from 'components/errorMessage/errorMessage';

// scss
import './NewCowList.scss';

function NewCowList() {

  // 에러처리
  const [isModalOpen, setIsModalOpen] = useState(false); // 모달 추가
  const [errorMessage, setErrorMessage] = useState(''); 

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => {
    setIsModalOpen(false);
    setErrorMessage(''); // 모달이 닫힐 때 에러메세지 초기화
  };
  
  // 데이터 불러오기
  // eslint-disable-next-line no-restricted-globals 
  const baseDir=`${location.pathname.toLowerCase()}/`;  
  const [ mainData , setMainData ] = useState();

  const [ props , setProps] = useState({
    id:`${localStorage.getItem('cong')}${localStorage.getItem('farm')}`,
    passwd:localStorage.getItem('passwd'),
    date:localStorage.getItem('month')
  });

  useEffect(() => {

    postState(baseDir,props)
    .then(getdata => {      
      if (getdata !== undefined) {
    //   console.log ('#### props : ' ,props);
      setMainData(getdata);
    //   console.log ('#### data : ' ,getdata);
      }
    })
    .catch(error => {      
    //   console.error('Error:', error.message);      
      setErrorMessage(error.message);
      openModal();
    });
    
  }, [props]);

  // 페이지 네이션
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20;

  // Calculate the index range for the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = mainData && mainData.plot_data.slice(indexOfFirstItem, indexOfLastItem);
  const maxPage = Math.ceil(mainData && mainData.plot_data.length / itemsPerPage);

  return (
    <div className='pdfMainArea'>
      <ErrorMessage isOpen={isModalOpen} content={errorMessage} closeModal={closeModal}/>
      <div className='pdfMainHeader'>
        <button 
          type='button'
          onClick={()=> openCrownix6('New_cow_list.mrd' , mainData && mainData.farm_property[0].farmid)}
        >
          <img src={PrintPDF} alt='PDF로 인쇄하기' />
          인쇄하기
        </button>
        <div className='pdfPagenation'>
          <p>{currentPage} / {maxPage}</p>
          <button 
            type='button' 
            onClick={() => { 
              if (currentPage !== 1 ) setCurrentPage(currentPage - 1);
            }}
            >이전</button>
          <button 
            type='button' 
            onClick={() => {
              if (currentPage !== maxPage) setCurrentPage(currentPage + 1);
            }}
            >다음</button>
        </div>
      </div>
      <div className='pdfArea'>
        <div className='newCowListPdf'>
          <h1>{props.date && props.date.substring(0,4)}년 {props.date && props.date.slice(-2)}월 개체 검정성적</h1>
          <ul className='newCowListInfo'>
            <li>
              농가명 : {mainData && mainData.farm_property[0].fname}
            </li>
            <li>
              축주명 : {mainData && mainData.farm_property[0].fdname}
            </li>
            <li>
              주소 : {mainData && mainData.farm_property[0].fadd}
            </li>
          </ul>
          <div className='totalReport'>
            <button
              type='button'
              onClick={() => openCrownix('New_cow_dcicAnal.mrd')}
            >
              검정성적 종합분석 보고서 보기
            </button>
          </div>
          <table className='pdfTable'>
            <thead>
              <tr>
                <th rowSpan='2'>관리번호</th>
                <th rowSpan='2'>이력제코드</th>
                <th rowSpan='2'>분만일</th>
                <th rowSpan='2'>산차</th>
                <th rowSpan='2'>착유일수</th>
                <th colSpan='6'>검정일 성적</th>
                <th colSpan='2'>305일 성적</th>
              </tr>
              <tr>
                <th>유량 &#40;kg&#41;</th>
                <th>체세포 &#40;천/Cell&#41;</th>
                <th>유지 &#40;%&#41;</th>
                <th>단백 &#40;%&#41;</th>
                <th>고형 &#40;%&#41;</th>
                <th>MUN</th>
                <th>유량 &#40;kg&#41;</th>
                <th>유지 &#40;%&#41;</th>
              </tr>
            </thead>
            <tbody>
              {
                currentItems &&
                currentItems.map((info, index) => (
                  <tr key={index}>
                    <td>
                      <button
                        type='button'
                        onClick={() => openCrownix2('New_ch_cow1.mrd', info.cowid , info.pari)}
                      >
                        {info.sname}
                      </button>
                    </td>
                    <td>{info.barcode}</td>
                    <td>{info.rbundate}</td>
                    <td>{info.pari}</td>
                    <td>{info.cumday}</td>
                    <td className='numberData'>{info.milk && info.milk.toFixed(1)}</td>
                    <td className='numberData'>{info.scc}</td>
                    <td className='numberData'>{info.fatp}</td>
                    <td className='numberData'>{info.protp}</td>
                    <td className='numberData'>{info.snfp}</td>
                    <td className='numberData'>{info.rmun}</td>
                    <td className='numberData'>
                      <button
                        type='button'
                        onClick={() => openCrownix3('New_sang_jcode.mrd' , info.jcode)}
                      >
                        {info.milk305.toLocaleString()}
                      </button>
                    </td>
                    <td className='numberData'>{info.fat305.toLocaleString()}</td>
                  </tr>
                ))
              }
            </tbody>
          </table>
          <img src={Logo} alt='농협경제지주 젖소개량사업소 로고' className='pdfLogo'/>
        </div>
      </div>
    </div>   
  );
}

export default NewCowList;
