import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

// api
import { getState, postState } from 'utils/api';

// react-query
import { useQuery } from '@tanstack/react-query';

function OriginBreedBullView() {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const sCode = searchParams.get('s_code');

  const { data: breedBullData = {}, isError } = useQuery({
    queryKey: ['breedBull', sCode],
    queryFn: async () => {
      if (!sCode) return {};
      const response = await getState('/board/select_breed_bull', {
        s_code: sCode,
      });

      if (response?.return_code === 'SUCCESS') {
        return response.result;
      }
      throw new Error('Failed to fetch breed bull data');
    },
    enabled: !!sCode,
  });

  const handleDelete = async () => {
    if (!window.confirm('삭제하시겠습니까?')) {
      return;
    }

    try {
      const response = await postState('/board/delete_breed_bull/', {
        s_code: sCode,
      });

      if (response?.return_code === 'SUCCESS') {
        alert('삭제되었습니다.');
        navigate('/bo/breedBull/originBreedBull/list');
      }
    } catch (error) {
      console.error('Error deleting breed bull:', error);
      alert('삭제 중 오류가 발생했습니다.');
    }
  };

  return (
    <div className="bo-page">
      <h2>종모우 상세</h2>

      {/* 종모우정보 */}
      <div
        className="table-container bold-border"
        style={{ marginBottom: '1rem' }}
      >
        <table>
          <colgroup>
            <col width="20%" />
            <col width="" />
            <col width="20%" />
            <col width="" />
          </colgroup>
          <tbody>
            <tr>
              <th colSpan="4">종모우정보</th>
            </tr>
            <tr>
              <th>FULL코드</th>
              <td colSpan="3">{breedBullData?.s_fullcode}</td>
            </tr>
            <tr>
              <th>정액코드</th>
              <td>{breedBullData?.s_code}</td>
              <th>등록번호</th>
              <td>{breedBullData?.s_reg}</td>
            </tr>
            <tr>
              <th>단축명호</th>
              <td>{breedBullData?.s_sortname}</td>
              <th>아비혈통</th>
              <td>{breedBullData?.s_ssortname}</td>
            </tr>
            <tr>
              <th>E단축명호</th>
              <td>{breedBullData?.s_sortname_e}</td>
              <th>E아비혈통</th>
              <td>{breedBullData?.s_ssortname_e}</td>
            </tr>
            <tr>
              <th>E정식명칭</th>
              <td>{breedBullData?.s_fullname_e}</td>
              <th>K정식명칭</th>
              <td>{breedBullData?.s_fullname_k}</td>
            </tr>
            <tr>
              <th>원산지</th>
              <td>{breedBullData?.s_origin}</td>
              <th>단위</th>
              <td>{breedBullData?.s_unit}</td>
            </tr>
            <tr>
              <th>단가</th>
              <td>{breedBullData?.s_danga}</td>
              <th>재고</th>
              <td>{breedBullData?.s_storage}</td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* 혈통정보 */}
      <div
        className="table-container bold-border"
        style={{ marginBottom: '1rem' }}
      >
        <table>
          <colgroup>
            <col width="10%" />
            <col width="" />
            <col width="10%" />
            <col width="" />
            <col width="10%" />
            <col width="" />
          </colgroup>
          <tbody>
            <tr>
              <th colSpan="6">혈통정보</th>
            </tr>
            <tr>
              <th>부(E-NAME)</th>
              <td>{breedBullData?.s_sire_e}</td>
              <th>부(K-NAME)</th>
              <td>{breedBullData?.s_sire_k}</td>
              <th>부(등록번호)</th>
              <td>{breedBullData?.s_sire_reg}</td>
            </tr>
            <tr>
              <th>모(E-NAME)</th>
              <td>{breedBullData?.s_dam_e}</td>
              <th>모(K-NAME)</th>
              <td>{breedBullData?.s_dam_k}</td>
              <th>모(등록번호)</th>
              <td>{breedBullData?.s_dam_reg}</td>
            </tr>
            <tr>
              <th>외조부(E-NAME)</th>
              <td>{breedBullData?.s_mgs_e}</td>
              <th>외조부(K-NAME)</th>
              <td>{breedBullData?.s_mgs_k}</td>
              <th>외조부(등록번호)</th>
              <td>{breedBullData?.s_mgs_reg}</td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* 능력정보 */}
      <div
        className="table-container bold-border"
        style={{ marginBottom: '1rem' }}
      >
        <table>
          <colgroup>
            <col width="10%" />
            <col width="10%" />
            <col width="10%" />
            <col width="10%" />
            <col width="10%" />
            <col width="10%" />
            <col width="10%" />
            <col width="10%" />
            <col width="10%" />
          </colgroup>
          <tbody>
            <tr>
              <th colSpan="9">능력정보</th>
            </tr>
            <tr>
              <th colSpan="2">평가기준일</th>
              <td colSpan="7">{breedBullData?.s_estimate}</td>
            </tr>
            <tr>
              <th rowSpan="2">생산능력</th>
              <th>종합지수(TPI)</th>
              <td>{breedBullData?.s_tpi}</td>
              <th>유량(PTAM)</th>
              <td>{breedBullData?.s_ptam}</td>
              <th>신뢰도(REL)</th>
              <td colSpan="3">{breedBullData?.s_pta_rel}</td>
            </tr>
            <tr>
              <th>유지방(PTAF)</th>
              <td>{breedBullData?.s_ptaf}</td>
              <th>유지방율(%)</th>
              <td>{breedBullData?.s_ptafp}</td>
              <th>유단백(PTAP)</th>
              <td>{breedBullData?.s_ptap}</td>
              <th>유단백율(%)</th>
              <td>{breedBullData?.s_ptapp}</td>
            </tr>
            <tr>
              <th rowSpan="2">체형능력</th>
              <th>체형(PTAT)</th>
              <td>{breedBullData?.s_ptat}</td>
              <th>체형신뢰도(%)</th>
              <td>{breedBullData?.s_ptat_rel}</td>
              <th>유방지수(UDC/MS)</th>
              <td>{breedBullData?.s_udc}</td>
              <th>유용능력(DF)</th>
              <td>{breedBullData?.s_sta15}</td>
            </tr>
            <tr>
              <th>신체형대(BD)</th>
              <td>{breedBullData?.s_sta16}</td>
              <th>엉덩이(RUMP)</th>
              <td>{breedBullData?.s_sta17}</td>
              <th>암유방지수(FUDD)</th>
              <td>{breedBullData?.s_sta18}</td>
              <th>뒷유방지수(RUDD)</th>
              <td>{breedBullData?.s_sta19}</td>
            </tr>
            <tr>
              <th>보조특징</th>
              <th>체세포(SCS)</th>
              <td>{breedBullData?.s_scs}</td>
              <th>생산수명(PL)</th>
              <td>{breedBullData?.s_sta20}</td>
              <th>난산율(DBH)</th>
              <td>{breedBullData?.s_sta21}</td>
              <th>착유속도(MS)</th>
              <td>{breedBullData?.s_sta22}</td>
            </tr>
          </tbody>
        </table>

        <table>
          <colgroup>
            <col width="10%" />
            <col width="10%" />
            <col width="10%" />
            <col width="10%" />
            <col width="10%" />
            <col width="10%" />
            <col width="10%" />
            <col width="10%" />
            <col width="10%" />
            <col width="10%" />
            <col width="10%" />
          </colgroup>
          <tbody>
            <tr>
              <th rowSpan="5">세부체형</th>
              <th>정중제인대</th>
              <td>{breedBullData?.s_sta1}</td>
              <th>뒷다리각도</th>
              <td>{breedBullData?.s_sta7}</td>
              <th>발굽경사</th>
              <td>{breedBullData?.s_sta8}</td>
              <th>발굽과다리</th>
              <td colSpan="3">{breedBullData?.s_sta9}</td>
            </tr>
            <tr>
              <th>뒷유방부착높이</th>
              <td>{breedBullData?.s_sta2}</td>
              <th>뒷유방너비</th>
              <td>{breedBullData?.s_sta3}</td>
              <th>유방깊이</th>
              <td colSpan="3">{breedBullData?.s_sta4}</td>
            </tr>
            <tr>
              <th>앞유방부착</th>
              <td>{breedBullData?.s_sta5}</td>
              <th>유두길이</th>
              <td>{breedBullData?.s_sta6}</td>
              <th>엉덩이각도</th>
              <td>{breedBullData?.s_sta10}</td>
              <th>엉덩이너비</th>
              <td colSpan="3">{breedBullData?.s_sta11}</td>
            </tr>
            <tr>
              <th>체심</th>
              <td>{breedBullData?.s_sta12}</td>
              <th>강건성</th>
              <td>{breedBullData?.s_sta13}</td>
              <th>키</th>
              <td colSpan="3">{breedBullData?.s_sta14}</td>
            </tr>
            <tr>
              <th>스펙</th>
              <td colSpan="3">{breedBullData?.s_spec}</td>
              <th>E스팩</th>
              <td colSpan="5">{breedBullData?.s_spec_e}</td>
            </tr>
            <tr>
              <th>메모</th>
              <td colSpan="10">{breedBullData?.s_memo}</td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* 사진정보 */}
      <div
        className="table-container bold-border"
        style={{ marginBottom: '1rem' }}
      >
        <table>
          <colgroup>
            <col width="20%" />
            <col width="*" />
          </colgroup>
          <tbody>
            <tr>
              <th colSpan="2">사진정보</th>
            </tr>
            <tr>
              <th>일반사진</th>
              <td>
                {breedBullData?.s_sajin_url && (
                  <a
                    href={`${process.env.REACT_APP_STATICS_URL}/_uploadFiles/_ox/${breedBullData?.s_sajin_url}`}
                    className="attach"
                    download
                  >
                    {breedBullData?.s_sajin_url}
                  </a>
                )}
              </td>
            </tr>
            <tr>
              <th>낭우사진</th>
              <td>
                {breedBullData?.s_dt_sajin_url && (
                  <a
                    href={`${process.env.REACT_APP_STATICS_URL}/_uploadFiles/_ox/${breedBullData?.s_dt_sajin_url}`}
                    className="attach"
                    download
                  >
                    {breedBullData?.s_dt_sajin_url}
                  </a>
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="button-group">
        <button
          type="button"
          className="btn-l"
          onClick={() =>
            navigate(`/bo/breedBull/originBreedBull/edit?s_code=${sCode}`)
          }
        >
          수정
        </button>
        <button type="button" className="btn-l" onClick={handleDelete}>
          삭제
        </button>
        <button
          type="button"
          className="btn-l"
          onClick={() => navigate('/bo/breedBull/originBreedBull/list')}
        >
          목록
        </button>
      </div>
    </div>
  );
}

export default OriginBreedBullView;
