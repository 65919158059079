import React from 'react';


function Do57() {

  return (
    <div className="techinfoview">
				<h1>젖먹이 송아지 사양관리(4일령~이유시까지)-대용유 급여</h1>
				<p className="pstyle">대용유는 4∼6일령부터 급여하기 시작할 수 있으나 대부분의 농가는 10∼14일령까지는 우유를 먹이고 그 이후 천천히 대용유로 대체하기도 한다. <br/>대용유는 우유보다 지방 함량이 낮으며 (건물 기준), 에너지 수준도 우유의 75∼85% 수준 (건물 기준)이다. 따라서 대용유 급여시에는 그림 19에서 보는 바와 같이 전유 급여시에 비해 성장이 뒤진다. 그러나 이는 송아지의 성장이 다소 늦어지는 것을 뿐 정상적인 골격 성장은 가능하므로 건강에 이상이 있는 것은 아니다. </p>
				<h2>대용유의 희석</h2>
				<p className="pstyle">상 상태의 물에 탄 대용유는 85∼90%의 수분이 함유되도록 희석대용유를 급여할 때는 희석 비율을 적절히 하는 것이 중요하며 액해야 한다. 너무 진하게 타면 설사를 일으키고 반대로 너무 묽게 타면 고형분 섭취량이 감소하여 성장이 늦어지기 때문이다. 희석 비율은 대개 물 7∼9 : 대용유 1의 비율인데 좋은 방법은 제품에 표시된 희석 비율에 따르는 것이다. 대용유를 물에 탈 때는 희석이 잘되게 하기 위해서 43℃ 정도 되는 따뜻한 물을 부으면서 덩어리가 생기지 않도록 잘 저어 가며 섞는다. 희석 과정에서 온도가 38℃ 정도로 떨어지므로 송아지에게 알맞은 온도가 된다. </p>
				<h2>대용유 급여 프로그램 </h2>
				<p>액상 사료 급여 기간 중의 대용유 급여 프로그램을 보면 &lt;표 55&gt;와 같다.  </p>
				<p><b>&lt;표 55&gt; 대용유급여프로그램 (2회포유시 1회급여량) </b></p>
				<table className="tablestyle">
					<tr>
						<td rowSpan="3">나이</td>
						<td colSpan="4">생시체중</td>
					</tr>
					<tr style={{background: '#273143',color:'#fff'}}>
						<td colSpan="2">36㎏이상 </td>
						<td colSpan="2">36㎏이하</td>
					</tr>
					<tr style={{background: '#273143',color:'#fff'}}>
						<td>대용유</td>
						<td>물</td>
						<td>대용유</td>
						<td>물</td>
					</tr>
					<tr>
						<td></td>
						<td colSpan="4">……………………… kg …………………………</td>
					</tr>
					<tr>
						<td>1~3일령</td>
						<td>-</td>
						<td>-</td>
						<td>-</td>
						<td>-</td>
					</tr>
					<tr>
						<td>4∼7일령</td>
						<td>0.18</td>
						<td>1.58</td>
						<td>0.14</td>
						<td>1.35</td>
					</tr>
					<tr>
						<td>2주령</td>
						<td>0.23</td>
						<td>1.58</td>
						<td>0.18</td>
						<td>1.35</td>
					</tr>
					<tr>
						<td>3주령</td>
						<td>0.23</td>
						<td>1.58</td>
						<td>0.18</td>
						<td>1.35</td>
					</tr>
					<tr>
						<td>4주령</td>
						<td>0.23</td>
						<td>1.58</td>
						<td>0.18</td>
						<td>1.35</td>
					</tr>
					<tr>
						<td>5주령</td>
						<td>-</td>
						<td>-</td>
						<td>0.18</td>
						<td>1.35</td>
					</tr>
					<tr>
						<td>총 급여량</td>
						<td>24.2</td>
						<td></td>
						<td>24.8</td>
						<td></td>
					</tr>
					<tr>
						<td>7주령까지</td>
						<td>0.48</td>
						<td></td>
						<td>0.27</td>
						<td></td>
					</tr>
					<tr>
						<td>일당증체량(㎏)</td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
					</tr>
				</table>
				<p>* 3일령까지 초유급여 36㎏이상일 때 1.8㎏, 36㎏이하일 때 1.15㎏ </p>
				<h2>대용유의 선택 </h2>
				<p>농가가 구할 수 있는 대용유의 종류는 여러 가지가 있는데 질이 좋은 것을 선택하는 것이 중요하다. 질이 좋은 대용유는 상당 부분 유제품을 포함하고 있는데 &lt;표 56&gt;은 전형적인 대용유의 구성 성분과 영양소 함량을 나타내고 있다.</p><br/>
				<p><b>&lt;표 56&gt; 대용유의 조성표 </b></p>
				<table className="tablestyle">
					<tr>
						<td>구 분</td>
						<td>성분함량</td>
						<td>구 분</td>
						<td>성분함량</td>
					</tr>
					<tr>
						<td>탈 지 분 유 (%)</td>
						<td>45.0</td>
						<td>비타민 A, D (I.U./㎏)</td>
						<td>2,200</td>
					</tr>
					<tr>
						<td>건조유장분말(%)</td>
						<td>35.0</td>
						<td>비타민 E (㎎/㎏)</td>
						<td>110</td>
					</tr>
					<tr>
						<td>유 화 돈 지 (%)</td>
						<td>17.5</td>
						<td>단 백 질 (%)</td>
						<td>24</td>
					</tr>
					<tr>
						<td>항 생 물 질 (%)</td>
						<td>0.5</td>
						<td>지 방 (%)</td>
						<td>20</td>
					</tr>
					<tr>
						<td>무기물(Mg, Fe,<br/>Cu, I, Zn; %)</td>
						<td>1.0</td>
						<td>레 시 틴 (lecithin; %)<br/>탄수화물 (%)</td>
						<td>2<br/>-</td>
					</tr>
					<tr>
						<td>비타민 B군 (%)</td>
						<td>1.0</td>
						<td>가소화영양소총량(TDN; %)</td>
						<td>110</td>
					</tr>
				</table><br/>
				<p>대용유는 제품에 부착되어 있는 성분표에 표시된 각종 영양 성분과 다음에 설명하는 사항들을 참고로 비교 선택하는 것이 바람직하다. </p><br/>
				<ul>
					<li>조단백질 (CP) 함량은 20% 이상이 되어야 하며 단백질 공급 원료는 유제품인 것이 좋다. 단백질 공급원에 따른 송아지 성장율을 비교해 보면 다음 &lt;표 57&gt;과 같다. </li>
					<li>지방 함량은 10∼20% 사이면 되나 추운 기온에서 사육상자 (calf hutch)를 이용할 때는 지방 함량이 높은 것이 유리하다. 지방 성분 공급원으로는 동물성 지방이 식물성 지방보다 좋다. </li>
					<li> 조섬유의 함량이 높은 것은 곡류가 많이 함유된 저질의 대용유다. 질이 좋은 대용유는 조섬유 함량이 0.25% 이내며, 많아도 1%를 초과하지 않는다 (&lt;표 58&gt;).<br/><br/>
						<p><b>&lt;표 57&gt; 대용유의 단백질 공급원에 따른 성장률 비교</b></p>
						<table className="tablestyle">
							<tr>
								<td>단백질 공급원</td>
								<td>성장률 비교</td>
							</tr>
							<tr>
								<td>우유단백질</td>
								<td>100</td>
							</tr>
							<tr>
								<td>가공된 콩가루</td>
								<td>86</td>
							</tr>
							<tr>
								<td>미가공 콩가루</td>
								<td>72</td>
							</tr>
							<tr>
								<td>육 즙</td>
								<td>66</td>
							</tr>
							<tr>
								<td>곡 류</td>
								<td>54</td>
							</tr>
						</table>
						<p>주 : 우유단백의 경우를 100으로 보았을 때의 비교수치임</p><br/>
						<p><b>&lt;표 58&gt; 일령별 대용유의 조섬유 수준</b></p>
						<table className="tablestyle">
							<tr>
								<td>일 령</td>
								<td>조섬유 함량</td>
							</tr>
							<tr>
								<td>4∼13일</td>
								<td>0.25% 이하</td>
							</tr>
							<tr>
								<td>14∼20일</td>
								<td>0.50% 이하</td>
							</tr>
							<tr>
								<td>21일 ∼</td>
								<td>1.00% 이하</td>
							</tr>
						</table><br/>
					</li>
					<li>탄수화물의 공급원으로는 유당(lactose)이 가장 우수하며 포도당(glucose)으로 대체 이용할 수 있다. 그러나 전분이나 설탕은 어린 송아지가 소화하기 어렵다. 전분의 과다 섭취는 21일령 이내의 송아지에서 설사의 주원인이 되므로 전분이 함유된 대용유는 3주령이내 송아지에게는 급여하지 않는 것이 좋다. </li>
					<li>무기물과 비타민은 이미 대용유에 포함되어 있으므로 일반적으로 첨가급여는 필요치 않다. 그렇지만 많은 농가는 미량 광물질 첨가제를 자유 채식시킨다. 초유를 충분히 먹지 못한 송아지를 구입한 경우에 비타만 A, D와 E를 주사하면 송아지를 건강하게 키울 수 있기도 하다.</li>
				</ul>
			</div>
  );
}

export default Do57;
