const BOARD_CODE = {
  EVENT: '1001',
  NEW: '1002',
  FOREIGN_TOPICS: '1003',
  TECH_INFO: '1004',
  INDUSTRY_NEWS: '1005',
  COW_TEST_NEW: '1020',
  IMPROVE_INFO: '1021',
  TEST_BIS_REPORT: '1022',
  TEST_DTL_PUSH_PLAN: '1023',
  MONTH_FARM_BEST: '1025',
  LOCSTAT: '1026',
  VIDEO: '1037',
};

const BOARD_NAME = {
  EVENT: '알림&행사',
  NEW: '주요소식',
  FOREIGN_TOPICS: '해외토픽',
  TECH_INFO: '기술정보',
  INDUSTRY_NEWS: '업계소식',
  COW_TEST_NEW: '검정뉴스',
  IMPROVE_INFO: '개량정보',
  TEST_BIS_REPORT: '검정사업보고서',
  TEST_DTL_PUSH_PLAN: '검정사업 세부 추진계획',
  MONTH_FARM_BEST: '이달의 검정농가',
  LOCSTAT: '행정구역별 검정사업 현황',
  VIDEO: '홍보동영상',
};
Object.freeze(BOARD_CODE);
Object.freeze(BOARD_NAME);

export { BOARD_CODE, BOARD_NAME };
