import React from 'react';
import DieoutCowAnalysis from 'containers/web/performance/dieoutCowAnalysis/DieoutCowAnalysis';
import { Helmet } from 'react-helmet-async';

function DieoutCowAnalysisPage() {
  return (
    <>
      <Helmet>
        <title>경제수명 및 도태우분석</title>
      </Helmet>
      <DieoutCowAnalysis />
    </>
  );
}

export default DieoutCowAnalysisPage;
