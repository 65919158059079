import React from 'react';

// 컴포넌트

// scss
import './webpage_list.scss';

// 이미지

import imgB1 from 'assets/images/containers/data/relatedSites/breeding/imgSite02_1.jpg';
import imgB3 from 'assets/images/containers/data/relatedSites/breeding/imgSite02_3.jpg';
import imgB5 from 'assets/images/containers/data/relatedSites/breeding/imgSite02_5.jpg';
import imgNO from 'assets/images/containers/data/relatedSites/imgSite_no.gif';

function Breeding() {
  return (
    <div className="Breeding">
      <div className="webpage">
        <dl className="webpage_list">
          <dd>
            <a
              href="http://www.rkde.com/covista/"
              target="_blank"
              rel="noreferrer"
            >
              <div className="webpage_banner">
                <img src={imgB1} alt="Covista Holsteins - Ontario, Canada" />
              </div>
              <p>Covista Holsteins - Ontario, Canada</p>
            </a>
          </dd>

          {/* <dd>
            <div className="webpage_banner">
              <img src={imgNO} alt="Milkworth Holsteins - Middlesex, NY" />
            </div>
            <p>Milkworth Holsteins - Middlesex, NY</p>
          </dd> */}
          <dd>
            <a
              href="https://carrouselfarms.com/"
              target="_blank"
              rel="noreferrer"
            >
              <div className="webpage_banner">
                <img
                  src={imgB3}
                  alt="Carrousel Farms - Orangeville, Illinois"
                />
              </div>
              <p>Carrousel Farms - Orangeville, Illinois</p>
            </a>
          </dd>
          {/* <dd>
            <div className="webpage_banner">
              <img src={imgNO} alt="Owenlea Holsteins - Homerville, Ohio" />
            </div>
            <p>Owenlea Holsteins - Homerville, Ohio</p>
          </dd>
          <dd>
            <div className="webpage_banner">
              <img src={imgB5} alt="Cook Farm - Hadley, Mass" />
            </div>
            <p>Cook Farm - Hadley, Mass</p>
          </dd>
          <dd>
            <div className="webpage_banner">
              <img src={imgNO} alt="Solid Gold Holsteins - Penn" />
            </div>
            <p>Solid Gold Holsteins - Penn</p>
          </dd> */}
          <dd>
            <a
              href="https://www.greenmeadowfarms.com/"
              target="_blank"
              rel="noreferrer"
            >
              <div className="webpage_banner">
                <img src={imgNO} alt="Green Meadow Farms - Elsie, MI" />
              </div>
              <p>Green Meadow Farms - Elsie, MI</p>
            </a>
          </dd>
          {/* <dd>
            <div className="webpage_banner">
              <img src={imgNO} alt="Stouder Holsteins" />
            </div>
            <p>Stouder Holsteins</p>
          </dd>
          <dd>
            <div className="webpage_banner">
              <img src={imgNO} alt="Kings Ransom Farms - Scuhylerville, NY" />
            </div>
            <p>Kings Ransom Farms - Scuhylerville, NY</p>
          </dd> */}
        </dl>
      </div>
    </div>
  );
}

export default Breeding;
