import React, { useState , useLayoutEffect , useRef } from 'react';

// scss
import './banner.scss';

const Link = [
  {
    name: '한국유가공협회',
    url: 'http://www.koreadia.or.kr/'
  },
  {
    name: '농림축산식품부',
    url: 'https://www.mafra.go.kr/sites/home/index.do'
  },
  {
    name: '농협중앙회',
    url: 'https://www.nonghyup.com/main/psniMain.do'
  },
  {
    name: '국립축산과학원',
    url: 'https://www.nias.go.kr/front/main.do'
  },
  {
    name: '농촌진흥청',
    url: 'https://www.rda.go.kr/main/mainPage.do?null'
  },
  {
    name: '국립농산물품질관리원',
    url: 'https://www.naqs.go.kr/main/main.do'
  },
  {
    name: '기상청',
    url: 'https://www.weather.go.kr/w/index.do'
  },
  {
    name: '낙농진흥회',
    url: 'https://www.dairy.or.kr/kor/main/'
  },
  {
    name: '농림축산검역본부',
    url: 'https://www.qia.go.kr/listindexWebAction.do'
  },
  {
    name: '식품 의약품 안전처',
    url: 'https://www.mfds.go.kr/index.do'
  },
  {
    name: '통계청',
    url: 'https://kostat.go.kr/ansk/'
  },
  {
    name: '한국낙농육우협회',
    url: 'http://www.naknong.or.kr/'
  },
  {
    name: '한국 농촌경제 연구원',
    url: 'https://www.krei.re.kr/krei/index.do'
  }
];

function Banner() {

  const list = useRef(Link);

  // 모든 li 의 width
  const listRef = useRef(null);
  const [liWidth, setLiWidth] = useState(0);

  useLayoutEffect(() => {
    if (listRef.current) {
      const gap = 30 * (list.current.length-1);
      const totalWidth = Array.from(listRef.current.children).reduce(
        (acc, child) => acc + child.clientWidth,
        0
      );
  
      setLiWidth(totalWidth + gap);
    }
  }, [listRef.current]);

  return (
    <aside className='banner'>
      <h3 className='hidden'>배너 영역</h3>
      <ul ref={listRef} style={{width: liWidth}}>
        {
          list.current &&
          list.current.map((tag) => (
            <li key={tag.name}>
              <a 
                href={tag.url} 
                target='_blank' 
                rel='noopener noreferrer'
                title={`${tag.name} 새창열기`}
              >
                <span className='hidden'>{tag.name} 페이지 새창열기</span>
              </a>
            </li>
          ))
        }
      </ul>
      <ul className="shadow" style={{width: liWidth}}>
          {
            list.current &&
            list.current.map((tag) => (
              <li key={tag.name}>
                <a 
                  href={tag.url} 
                  target='_blank' 
                  rel='noopener noreferrer'
                  title={`${tag.name} 새창열기`}  
                >
                  <span className='hidden'>{tag.name} 페이지 새창열기</span>
                </a>
              </li>
            ))
          }
      </ul>
    </aside>
  );
}

export default Banner;
