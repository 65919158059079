import React from 'react';

function Fo32() {

  return (
    <div className="techinfo_view">
				<p className="viewTitle">젖소의 질병</p>
				<p>유생산은 젖소에게 많은 스트레스를 동반하는 매우 집약적이고 부담스러운 생산임으로, 젖소는 많은 질병에 시달리게 되는데 그 중에는 위산독중, 고창증, 4위 전위, 간지방 증후군, 저혈중 마그네슘증, 창상성 질병, 케토시스, 유열, 후산정체 그리고 유방부종 등이 있다.</p>
				<dl>
					<dt>위산독증(젖산독증, Lactic acidosis)</dt>
					<dd>
						<p>위산독증이라 함은 소나 양에서의 대사성 질병이다. 반추동물이 조사료 위주의 사료로 부터 높은 농후사료 위주의 사료로 급작스레 변할 때, 반추위의 산성화와 무력증이 초래될 수 있다. 반추위내 미생물은 새로운 사료에 적응하기에 충분한 시간을 갖지 못하여 심각한 소화장애가 발생한다. 반추위내 산도는 계속 떨어져서는 유산의 생성이 급작스레 증가하며, 이러한 환경에서는 많은 미생물들이 생존할 수 없으므로 반추위내 미생물과 프로토조아의 수에 근본적인 변화가 생긴다. 이와 더불어 반추위의 운동이 정지되며 이러한 상황에 이르른 소는 허약함과 설사, 이상적인 불안함을 보이며 많은 경우에 죽기도 한다.</p>
						<p>예방 조치로는 젖소를 조사료 위주의 사료로 시작해서 서서히 조사료 양을 줄이며 농후사료의 양을 증가시키며, 불규칙적인 사료급여와 사료의 갑작스런 변화는 피해야 한다. 농후사료 위주의 사료 급여시 버퍼제의 이용도 도움이 된다.</p> 
						<p>여러종류의 치료 방법이 각기 다른 성과로 이용되어 왔으나, 가장 좋은 방법으로는 급여하는 사료의 양과 종류를 줄이며(최종 급여 사료의 양을 줄이며), 조사료 위주의 사료 급여를 하는 것이다.</p>
					</dd>
				</dl>
				
				<dl>
					<dt>고 창 증(Bloat)</dt>
					<dd>
						<p>고창중은 젖소를 포함한 모든 반추동물의 경우 발생할 수 있으며 젖소에게 농후사료나 급속하게 성장하는 두과 목초를 처음 급여시 고창증 문제가 발생된다. 일반적으로 트림에 의해 반추위내 발생된 가스는 밖으로 유출되는데 고창증의 경우 거품에 의해(반추위 내 물질에 가스가 가득 포함되었을 때) 이러한 공정이 방해되어 반추위내 가스의 압력이 증가하여 가축의 왼쪽 옆구리가 부풀어 오르기 시작, 결국 가축은 사료섭취를 일체 거부하거나 고통과 독성물질의 축적으로 급사하기도 한다.</p>
						<p>고창증에 걸린 소에게 반추위에 구멍을 내는 것은 마지막 수단으로 이용되어야 한다.</p>
					</dd>
				</dl>
				
				<dl>
					<dt>4위 전위증(Displaced Abomasum)</dt>
					<dd>최근 많은 낙농가가 4위 전위 발생율의 증가를 경험하는데, 건유우에게 농후사료를 자유급이 하는 것이 한가지 원인으로 지적되었다. 이는 부피가 적어 반추위의 만복감을 못시켜 주는 것이 반추위내 반사 신경을 약화시켜 4위가 전위되게 만든다고 하며, 이를 방지키 위해 반추위의 작용을 촉진시키기 위한 일정량의 조사료를 급여함이 바람직하다.</dd>
				</dl>
				
				<dl>
					<dt>지방간 증후군(Fatty Cow Syndrome, Fatty Liver Syndrome)</dt>
					<dd>
						<p>간지방 증후군의 소는 지방의 침착에 의한 간의 증대로 간의 기능이 저하된다. 건유우 중 과비된 소의 일반적 증세로는 질병 감염에 대한 저항력 감소와 더불어 케토시스와 같은 대사성 질병의 발생율이 증가하며 사료 섭취와 유량이 감소되며 체중이 감소된다.</p>
						<p>간내의 지방 함량은 분만 전 4∼5%까지 이르기도 하지만 일반적으로 매우 낮다(1∼2% 정도). 하지만 간지방 증후군의 경우는 간의 20% 이상이 지방 침착으로 이루어지는 경우도 있다.</p>
						<p>간지방 증후군의 발생은 비유말기나 건유기 동안 젖소의 과비를 방지하며 분만 후 사료섭취를 최대로 하는 사료를 급여함으로써 줄일 수 있다.</p>
					</dd>
				</dl>
				
				<dl>
					<dt>저혈중 마그네슘증(Grass Tetany, Hypomagnesemia)</dt>
					<dd>저혈중 마그네슘 증이란 대사성 이상으로서 특히 봄철에 왕성하게 성장하는 목초와 같이 질소 함량이 높은 초지에 방목시(특히 젖소의 경우) 마그네슘의 흡수가 저하되어 발생하는데, 이러한 상태에 이른 젖소는 걷는 자세가 경직되거나, 근육이 뒤틀리는 등, 경직된 상태로 보이며 죽기도 한다. 발생 확률이 높은 시기에 성장이 빠른 목초에 방목하는 젖소에게는 산화마그네슘을 보충해 주는 것이 권장된다.</dd>
				</dl>
				
				<dl>
					<dt>창상성 질병(Hardware disease)</dt>
					<dd>섭취된 이물질이 제2위에 상처를 입히거나 구멍을 내었을 때는 제2위 창상성 질병(Reticulitis, 혹은 Hardware disease)라 한다. 반추동물은 근본적으로 목초를 채식하지만 가끔 섭취에 있어서 무분별 하기도 하며 때대로 못이나 철사, 혹은 다른 이물질 들을 섭취하기도 한다. 이러한 이물질들은 위의 운동에 따라 제2위에 구멍을 내는 것과 같은 심각한 결과를 초래하기도 한다.</dd>
				</dl>
				
				<dl>
					<dt>케토시스(Ketosis, 저혈당증)</dt>
					<dd>
						<p>케토시스란 대사성 질병으로 유량의 감소, 혈중 낮은 glucerol 함량(hypoglycemia), 뇨 중 케톤체 증가(Ketonuria), 급격한 체중 감소 등으로 나타나며, 일반적으로 비유 초기 30일 이내에 발생한다. 완전한 예방 방법은 없는 반면 Propylene glycol이나 sodium propionate 등을 급여하는 것이 어떤 경우에는 예방에 도움을 줄 수 있다. 건유 마지막 2주일과 비유초기의 사료에 1일 6kg의 나이아신을 첨가하는 것이 케토시스 발생율을 낮추기도 하며, 건유말기 부터 제한된 양의 농후사료를 급여하는 것이 케토시스를 방지하는데 도움이 되기도 한다.</p>
						<p>만일 케토시스 발생의 결과로 소가 주저 앉으면 글루코스 용액을 정맥으로 주입, 회복을 꾀할 수도 있다.</p>
					</dd>
				</dl>
				
				<dl>
					<dt>유방염(Mastitis)</dt>
					<dd>유방염이란 여러가지의 미생물균 중 어떠한 1가지에 (가장 빈번한 경우로는 Staphyloccus나 Streptococcus) 유선이 감염되어 발생하는 것으로써 증상은 감염의 정도에 따라 다르다. 급성의 경우 유방이 부풀어 오르고 통증을 느끼며 사료섭취 거부도 종종 발생하나, 만성인 경우에도 유방은 약간 부어오르며, 우유내 작은 편들이 나타난다. 유방염은 사료로 인하여 발생되거나 치유되지 않으나 영양 공급이 갑자기 증가 되었을 때 갑작스런 유량의 증가로 젖소는 더 많은 스트레스 하에 있게 됨으로써 결국은 잠재성 유방염 등의 원인이 될 수 있다. 셀레늄이나 비타민 E의 첨가도 유방염 예방에 도움이 될 수 있다.</dd>
				</dl>
				
				<dl>
					<dt>유열(Milk Fever)</dt>
					<dd>
						<p>분만시나 분만직후(일반적으로 48∼72시간 이내)혈중 칼슘 농도의 급속한 저하(hypocalcemia)가 발생하는 경우가 있는데, 이러한 경우 젖소는 식욕을 잃어 버리며, 체온이 하강하고, 불안한 걸음걸이을 보인다. 이러한 증상은 긴장하거나, 결국은 주저않아 의식을 잃어 버리는 것으로 이어지는데 머리는 대부분 뒤쪽으로 향해진다. 유열이라 함은 체온이 정상 이하로 떨어지는 것을 고려할 때 잘못 명명 되어진 것이다. 이러한 혈중 칼슘 농도 저하의 원인은 많은 양의 칼슘이 요구되는 비유의 개시이다.</p>
						<p>건유 기간 중 사료관리를 통해 유열의 발생율을 많이 감소시킬 수가 있는데 유열이 자주 오는 개체에게는 건유기에 칼슘을 과다 급여하는 것을 피하여야 한다. 유열이 계속 문제가 되는 경우 어떤 영양학자는 고에너지 저칼슘(1일 15g 칼슘 이하)의 사료를 제한 급여 하는 것을 권장하기도 한다. 분만 후 사료내 칼슘 수준을 증가시켜 비유를 위한 증가된 요구량을 만족시켜야 한다. 최근 연구에서는 사료내 음이온을 첨가해 줌으로써 칼슘의 흡수와 이용율을 개선시켜 유열 발생을 줄일 수 있다하는데 아직 많은 연구가 필요하다.</p>
					</dd>
				</dl>
				
				<dl>
					<dt>후산정체(Retained Placenta)</dt>
					<dd>
						<p>정상적인 경우 후산은 분만 후 3∼6시간 이내에 축출되나 12시간이 지나도록 남아 있다면 후산은 도움을 필요로 한다.</p>
						<p>후산정체는 젖소에서 10% 정도의 발생율을 보이고 있는데 임신 기간이 너무 짧았거나, 혹은 너무 길었거나, 산차가 많은 소, 혹은 쌍둥이을 분만하는 경우 등에 발생율이 높다. 실험을 통하여 Glucocorticoid제재 등을 이용하는 조기 분만의 경우 후산정체의 발생율이 높아진다고 보고되었다.</p>
						<p>브르셀병(Brucellosis), Vibriosis 그리고 다른 균들에 의한 감염이 유산과 후산 정체를 일으킨다고 하는데 이러한 것만이 원인은 아니며, 분만시 유열이라든가 간지방 증후군의 경우에 또한 발생율이 높다. 영양적으로 볼 때는 비타민 A, 셀레늄, 구리, 혹은 요오드(Iodine)의 부족시 발생율이 높으며, 분만전 저 농도의 셀레늄을 주사하는 것이 후산정체의 발생율을 줄인다 한다. 그리고 다음과 같은 경우에도 후산정체의 발생 비율이 감소한다고 한다. 1) 분만후 송아지가 어미와 함께 12∼24시간 붙어 사육되는 경우 2) 젖소를 1년내에 방목시키는 경우, 과거에 후산정체를 경험한 개체에게는 재발생할 확률이 20%이다.</p>
						<p>후산이 정체되어 있는 경우 분만된 송아지는 허약하기 쉬우며, 후산정체는 불결한 환경을 만들어 자궁내의 조직을 파괴시킬 수도 있다. 이러한 것이 유량에 영향을 미치거나, 혹은 그러하지 않을 수도 있지만 정상적인 개체보다 수태율이 5∼10% 감소되므로, 후산정체가 의심도는 경우에는 수의사에 의한 올바른 조치가 이루어져야 한다. 일반적인 치료로는 항생제나 설파제(sulfonamide)를 자궁내에 직접 주입하거나, 다른 방법을 통하여(혹은 두경로 모두를 통하여) 주입하여야 하나, 정체된 후산을 강제로 제거하는 것은 바람직하지 않다. 만일 후산이 땅에 끌리게 된다면 무릎 정도의 높에서 잘라 주어야 하나, 정체된 후산에다 돌덩이나, 다른 물건등은 절대로 매달지 말아야 한다. 대부분의 경우 1내지 2주 후 자연적으로 후산은 떨어져 나갈 것이다.</p>
						<p>후산정체를 경험한 개체의 경우는 분만후 30일 경에 검진해 보는 것이 권장된다. 농이 존재한다면, estrogenic호르몬을 이용하여 발정을 유도한 후 자궁을 항생제나 희석된 lugol 액(요오드)를 이용하여 세척해 주는 것이 좋다. 이러한 점검과 치료가 정상적으로 발정주기가 돌아오게 하는데 시간을 절약할 수 있으며 높은 수태율을 가져온다.</p>
					</dd>
				</dl>
				
				<dl>
					<dt>유방부종(Udder Edema)</dt>
					<dd>유방부종이란 유방 내 세포간에 혹은 유방의 앞부분에 과잉의 수액이 축적되는 것으로 가끔 분만전 심각한 문제로 일으키기도 한다. 원인은 아직 정확하게 규명되어 있지 않지만 분만시 혈중 단백질 함량의 감소와 보완적인 림프의 제거없이 혈액의 흐름이 증가되는 것이 그 원인으로 제시되었다. 소금이나 Potasium chloride의 과잉급여가 정도를 심각하게 할 수도 있으며 소금 섭취를 제한시 심각함이 감소한다. 유방부종이 심각한 경우 유량이 줄 수도 있으며 늘어지는 유방의 원인이 된다.</dd>
				</dl>
				
				<dl>
					<dt>약제와 살충제</dt>
					<dd>살충제나 가축의 질병에 쓰이는 약들이 우유내에 분비되기도 하는데 이러한 약물질들이 섞인 우유는 식용으로 이용되지 말아야 한다. 우유내 항생제, 설파제 그리고 살충제가 존재하는 것은 위법이므로 낙농가는 이러한 잔유물 방지 프로그램을 따라야 한다. </dd>
				</dl>
			</div>
  );
}

export default Fo32;
