import React, { useState , useEffect } from 'react';
import { 
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  ZAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Cell,
  BarChart,
  Bar,
  LabelList
} from 'recharts';

// api
import { postState } from 'utils/api';
import { openCrownix6 } from 'utils/openCrownix';

// 이미지
import PrintPDF from 'assets/svgs/common/print_PDF.svg';

// 컴포넌트
import ErrorMessage from 'components/errorMessage/errorMessage';
import MonthCode from 'components/dcitest/monthCode/MonthCode';

// scss
import './NewGraphPari.scss';

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <p className="label">{`${payload[2].value}`}</p>
      </div>
    );
  }

  return null;
};

const CustomTooltip2 = ({ active, payload, label }) => {
  
  if (active && payload && payload.length) {
    
    return (
      <div className="custom-tooltip">
        
        <p className="label">{`${payload[0].payload.cntpari + payload[0].payload.rate}`}</p>
      </div>
    );
  }

  return null;
};

function NewGraphPari() {

  // 에러처리
  const [isModalOpen, setIsModalOpen] = useState(false); // 모달 추가
  const [errorMessage, setErrorMessage] = useState(''); 

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => {
    setIsModalOpen(false);
    setErrorMessage(''); // 모달이 닫힐 때 에러메세지 초기화
  };

  // 데이터 불러오기
  const [ pariData , setPariData ] = useState();
  const [ pariData2 , setPariData2 ] = useState();
  const [ tableData10 , setTableData10 ] = useState();

  const [ props , setProps] = useState({
    id:`${localStorage.getItem('cong')}${localStorage.getItem('farm')}`,
    passwd:localStorage.getItem('passwd'),
    date:localStorage.getItem('month')
  });
  const COLORS = ['green','yellow','blue','red','violet','orange','gray','cyan'];

  const baseDir='/chart/new_graph_pari/';
  
  useEffect(() => {

    postState(baseDir,props)
    .then(getdata => {      
      if (getdata !== undefined) {
    //   console.log ('#### props : ' ,props);
      setPariData(getdata.plot_data);   
      setPariData2(getdata.plot_data2);
      setTableData10(getdata.property2);
      }
    })
    .catch(error => {      
      setErrorMessage(error.message);
      openModal();
    //   console.error('Error:', error.message);      
    });
    
  }, [props]);

  return (
    <div className='pdfMainArea'>
      <ErrorMessage isOpen={isModalOpen} content={errorMessage} closeModal={closeModal}/>
      <div className='pdfMainHeader'>
        <button 
          type='button'
          onClick={()=> openCrownix6('New_graph_pari.mrd')}
        >
          <img src={PrintPDF} alt='PDF로 인쇄하기' />
          인쇄하기
        </button>
        {
          props && 
          <MonthCode 
            props={props}
            setProps={setProps}
            setErrorMessage={setErrorMessage}
            openModal={openModal}
          />
        }
      </div>
      <div className='pdfArea'>
        <div className='newGraphPariPdf'>
          <span>{props.date.substring(0,4)}년 {props.date.slice(-2)}월 그래프</span>
          <h1>산차별 분포도</h1>
          <div className='dotChartAreaDual'>
            <ResponsiveContainer width='70%' height={400}>
              <ScatterChart
                width="90%"
                height="85%"
                margin={{
                  top: 20,
                  right: 20,
                  bottom: 20,
                  left: 20,
                }}>
                <CartesianGrid strokeDasharray="3 3"/>
                <XAxis
                  type="number"
                  dataKey="day"
                  // interval={100}
                  name = "비유일수"
                  // interval={1}
                  tickCount='30'
                  label={{ value: '비 유 일 수 (일)', position: 'bottom' }}
                  unit="일"
                />
                <YAxis
                  type="number"
                  dataKey="pari"
                  name = "산차"
                  // interval={1}
                  tickCount='5'
                  label={{ value: '산 차', position: 'insideLeft', offset: '10', angle: -90 }}
                  unit="회"
                />
                <ZAxis               
                  dataKey="sname"                                                        
                  range={[0,0]}
                />
                <Tooltip cursor={{ strokeDasharray: '3 3' }} content={CustomTooltip} />
                
                <Scatter data={pariData} fill="green">
                  {
                    pariData &&
                    pariData.map((entry) => (
                      <Cell key={`cell-${entry.sname}`} fill={COLORS[entry.pari]}/>
                    ))
                  }
                </Scatter>
              </ScatterChart>
            </ResponsiveContainer>
            
            <ResponsiveContainer width='30%' height={400}>
              <BarChart 
                layout="vertical"
                width="90%"
                height="85%"
                data={pariData2}
                margin={{
                  top: 20,
                  right: 20,
                  bottom: 20,
                  left: 20,
                }}
                barSize={20}
                >
                <CartesianGrid strokeDasharray="3 3"/>
                <XAxis 
                  type="number"
                  dataKey="cntpari"           
                  label={{ value: '산차별두수(비율%)', position: 'bottom' }}
                />
                <YAxis
                  type="number"
                  dataKey="pari"
                  name = "산차"
                  reversed = 'True'
                  tickCount='5'
                  // label={{ value: '산 차', offset: '10', angle: -90 }}
                  unit="회"
                />
                
                <Tooltip cursor={{ strokeDasharray: '3 3' }} content={CustomTooltip2} />
                
                <Bar dataKey="cntpari" >
                
                {
                  pariData2 &&
                  pariData2.map((entry, index) => (
                    <Cell key={`cell-${entry.rate}`} fill={COLORS[index]} />              
                  ))
                }
                  <LabelList dataKey="cntpari" position="right"  />
                  <LabelList dataKey="rate" position="right" offset="30" />
                </Bar>
                  
              </BarChart>
            </ResponsiveContainer>
            
          </div>
          <table className='pdfTable'>
            <thead>
              <tr>
                <th>비유일수별 구분</th>
                <th>초기&#40;70일이하우&#41;</th>
                <th>중기&#40;70일초과150일미만우&#41;</th>
                <th>후기&#40;150일이상우&#41;</th>              
              </tr>
            </thead>
            <tbody>
              {
                tableData10 &&
                tableData10.map((rowData) => (
                  <tr key={rowData.rate70}>
                    <td>두수/비율</td>
                    <td>{rowData.rate70}</td>
                    <td>{rowData.rate150}</td>
                    <td>{rowData.rate600}</td>                
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>   
  );
}

export default NewGraphPari;
