import React from 'react';
import MainNews from 'containers/web/info/mainNews/MainNews';
import { Helmet } from 'react-helmet-async';

function MainNewsPage() {
  return (
    <>
      <Helmet>
        <title>주요소식</title>
      </Helmet>
      <MainNews />
    </>
  );
}

export default MainNewsPage;
