import React, { useState , useRef } from 'react';
import { fileState } from 'utils/api';

// 컴포넌트
import ErrorMessage from 'components/errorMessage/errorMessage';

// scss
import './DciDoc.scss';

function DciDoc() {

  const [isModalOpen, setIsModalOpen] = useState(false); // 모달 추가
  const [errorMessage, setErrorMessage] = useState('');

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => {
    setIsModalOpen(false);
    setErrorMessage(''); // 모달이 닫힐 때 에러메세지 초기화
  };

  const baseDir='/dcitest/dcidoc/';

  const [ props , setProps] = useState({
    id:`${localStorage.getItem('cong')}${localStorage.getItem('farm')}`,
    passwd:localStorage.getItem('passwd'),
    date:localStorage.getItem('month'),    
  });

  const inputRef = useRef(null);

  const numberRegex = /^[0-9]+$/; // 숫자로만 이루어진 정규표현식

  const validate = (input) => {
    
    if (input.trim() === '') {
      setErrorMessage('검정월을 입력하십시오.');
      openModal();
      inputRef.current.focus();
      return false;
    }
    
    if (!numberRegex.test(input) || input.length < 6) {
      setErrorMessage('6자리의 숫자만 입력하십시오.');
      openModal();
      inputRef.current.focus();
      return false;
    }
    
    return true; 
  };

  
  const submitHandler = () => {
    const input = inputRef.current.value;
    
    if (validate(input)) {
      setProps(prevProps => ({...prevProps, date:input}));
      fileState(baseDir,props)
    .then(getdata => {      
      if (getdata !== undefined) {
        
        const link = document.createElement('a'); // a 태그 생성
        link.href = window.URL.createObjectURL(getdata.data); // a 태그의 href 속성에 blob url을 삽입
        const filename = `${input}월 자료`; // 다운로드 파일명
        link.download = filename; // a 태그의 다운로드 기능에 이름을 설정
        link.click(); // 링크를 클릭한것 처럼 작동
        
        URL.revokeObjectURL(link.href); // 다운로드 후에는 링크 요소를 제거합니다.
      }
    })
    .catch(error => {      
      console.error('Error:', error.message);      
      setErrorMessage(error.message);
      openModal();
    });      
    //   console.log('데이터쏘기');
    }
  };

  return (
    <div className='dciDoc'>
      <ErrorMessage isOpen={isModalOpen} content={errorMessage} closeModal={closeModal}/>
      <div className='dciDocInner'>
        <p>자료 엑셀로 받기</p>
        <span>저장하실 검정월을 입력하세요.</span>
        <div className='docInput'>
          <label htmlFor='docInput'>검정월 입력</label>
          <input 
            type='text' 
            id='docInput' 
            name='docInput' 
            ref={inputRef}
          />
        </div>
        <button 
          type='button'
          onClick={()=> submitHandler()}
        >확인</button>
        <span>예 &#41; 2017년 12월 -&gt; 201712</span>
      </div>
    </div>
  );
}

export default DciDoc;
