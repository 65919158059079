import React from 'react';
import Business from 'containers/web/business/Business';

function BusinessPage() {
  return (
    <Business />
  );
}

export default BusinessPage;
