import React from 'react';
import { Helmet } from 'react-helmet-async';
import Bo from 'containers/bo/Bo';

function BoPage() {
  return (
    <>
      <Helmet>
        <title>BACKOFFICE</title>
      </Helmet>
      <Bo />
    </>
  );
}

export default BoPage;
