import React from 'react';
import Overseas from 'containers/web/info/overseas/Overseas';
import { Helmet } from 'react-helmet-async';

function OverseasPage() {
  return (
    <>
      <Helmet>
        <title>해외토픽</title>
      </Helmet>
      <Overseas />
    </>
  );
}

export default OverseasPage;
