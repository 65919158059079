import React from 'react';

// scss
import './webpage_list.scss';

// 이미지

import imgO1 from 'assets/images/containers/data/relatedSites/organization/imgSite03_1.jpg';
import imgO3 from 'assets/images/containers/data/relatedSites/organization/imgSite03_3.jpg';
import imgO4 from 'assets/images/containers/data/relatedSites/organization/imgSite03_4.jpg';
import imgO5 from 'assets/images/containers/data/relatedSites/organization/imgSite03_5.jpg';
import imgO6 from 'assets/images/containers/data/relatedSites/organization/imgSite03_6.jpg';
import imgO7 from 'assets/images/containers/data/relatedSites/organization/imgSite03_7.jpg';
// import imgO8 from 'assets/images/containers/data/relatedSites/organization/imgSite03_8.jpg';
import imgO9 from 'assets/images/containers/data/relatedSites/organization/imgSite03_9.jpg';
import imgO10 from 'assets/images/containers/data/relatedSites/organization/imgSite03_10.jpg';
// import imgO11 from 'assets/images/containers/data/relatedSites/organization/imgSite03_11.jpg';
import imgO12 from 'assets/images/containers/data/relatedSites/organization/imgSite03_12.jpg';
import imgO13 from 'assets/images/containers/data/relatedSites/organization/imgSite03_13.jpg';
// import imgO14 from 'assets/images/containers/data/relatedSites/organization/imgSite03_14.jpg';
import imgO15 from 'assets/images/containers/data/relatedSites/organization/imgSite03_15.jpg';
// import imgNO from 'assets/images/containers/data/relatedSites/imgSite_no.gif';

function Organization() {
  return (
    <div className="Organization">
      <div className="webpage">
        <dl className="webpage_list">
          <dd>
            <a
              href="https://www.mafra.go.kr/sites/home/index.do#none"
              target="_blank"
              rel="noreferrer"
            >
              <div className="webpage_banner">
                <img src={imgO4} alt="농림부축산식품부(MAF)" />
              </div>
              <p>농림부축산식품부(MAF)</p>
            </a>
          </dd>
          <dd>
            <a
              href="https://www.naqs.go.kr/main/main.do"
              target="_blank"
              rel="noreferrer"
            >
              <div className="webpage_banner">
                <img src={imgO1} alt="국립동물검역소" />
              </div>
              <p>국립동물검역소</p>
            </a>
          </dd>

          {/* <dd>
            <div className="webpage_banner">
              <img src={imgNO} alt="경남농촌진흥원" />
            </div>
            <p>경남농촌진흥원</p>
          </dd> */}
          <dd>
            <a href="https://www.ipet.re.kr/" target="_blank" rel="noreferrer">
              <div className="webpage_banner">
                <img src={imgO3} alt="농림기술관리센터" />
              </div>
              <p>농림기술관리센터</p>
            </a>
          </dd>
          <dd>
            <a
              href="https://www.at.or.kr/home/apko000000/index.action"
              target="_blank"
              rel="noreferrer"
            >
              <div className="webpage_banner">
                <img src={imgO5} alt="농수산물 유통공사" />
              </div>
              <p>농수산물 유통공사</p>
            </a>
          </dd>
          <dd>
            <a
              href="https://www.ibric.org/bric/index.do"
              target="_blank"
              rel="noreferrer"
            >
              <div className="webpage_banner">
                <img src={imgO6} alt="생물학연구 정보센터" />
              </div>
              <p>생물학연구 정보센터</p>
            </a>
          </dd>
          <dd>
            <a href="https://www.nongmin.com/" target="_blank" rel="noreferrer">
              <div className="webpage_banner">
                <img src={imgO7} alt="농민신문" />
              </div>
              <p>농민신문</p>
            </a>
          </dd>
          {/* <dd>
            <a
              href="https://www.assembly.go.kr/portal/main/main.do"
              target="_blank"
              rel="noreferrer"
            >
              <div className="webpage_banner">
                <img src={imgO8} alt="국회입법정보서비스" />
              </div>
              <p>국회입법정보서비스</p>
            </a>
          </dd> */}
          <dd>
            <a
              href="https://www.holsteinusa.com/"
              target="_blank"
              rel="noreferrer"
            >
              <div className="webpage_banner">
                <img src={imgO9} alt="Holstein Association USA Inc" />
              </div>
              <p>Holstein Association USA Inc</p>
            </a>
          </dd>

          <dd>
            <a
              href="https://www.holstein.ca/en/Public/"
              target="_blank"
              rel="noreferrer"
            >
              <div className="webpage_banner">
                <img src={imgO10} alt="Holstein Association of Canada" />
              </div>
              <p>Holstein Association of Canada</p>
            </a>
          </dd>
          {/* <dd>
            <div className="webpage_banner">
              <img src={imgO11} alt="Ontario Holstein Association" />
            </div>
            <p>Ontario Holstein Association</p>
          </dd> */}
          <dd>
            <a
              href="https://www.redandwhitecattle.com/"
              target="_blank"
              rel="noreferrer"
            >
              <div className="webpage_banner">
                <img src={imgO12} alt="Red & White Dairy Cattle Assoc." />
              </div>
              <p>Red & White Dairy Cattle Assoc.</p>
            </a>
          </dd>
          <dd>
            <a
              href="https://www.wisholsteins.com/"
              target="_blank"
              rel="noreferrer"
            >
              <div className="webpage_banner">
                <img src={imgO13} alt="Wisconsin Holstein Association" />
              </div>
              <p>Wisconsin Holstein Association</p>
            </a>
          </dd>
          {/* <dd>
            <a href="https://dairyshrine.org/" target="_blank" rel="noreferrer">
              <div className="webpage_banner">
                <img src={imgO14} alt="National Dairy Shrine" />
              </div>
              <p>National Dairy Shrine</p>
            </a>
          </dd> */}
          <dd>
            <a
              href="https://www.cobleskill.edu/"
              target="_blank"
              rel="noreferrer"
            >
              <div className="webpage_banner">
                <img
                  src={imgO15}
                  alt="State University of New York - Cobleskill"
                />
              </div>
              <p>State University of New York - Cobleskill</p>
            </a>
          </dd>
          {/* <dd>
            <div className="webpage_banner">
              <img src={imgNO} alt="Penn State's Dairy Cattle Nutrition Site" />
            </div>
            <p>Penn State&apos;s Dairy Cattle Nutrition Site</p>
          </dd>
          <dd>
            <div className="webpage_banner">
              <img src={imgNO} alt="University of Vermont - CREAM" />
            </div>
            <p>University of Vermont - CREAM</p>
          </dd> */}
        </dl>
      </div>
    </div>
  );
}

export default Organization;
