import React, { useState, useEffect } from 'react';

// react-query
import { useQuery } from '@tanstack/react-query';

// api
import { getState } from 'utils/api';

// components
import Pagination from 'components/bo/Pagination';

function CommonInfoList({
  title,
  brdCode,
  queryId,
  apiEndpoint,
  onRegister,
  onViewDetail,
}) {
  const [currentPage, setCurrentPage] = useState(1);

  const [apiParams, setApiParams] = useState({
    search_type: 'bdc_title',
    search_keyword: '',
    page_no: 1,
    brd_code: brdCode,
  });

  const { data, isLoading, isError, error } = useQuery({
    queryKey: [queryId, apiParams],
    queryFn: () => getState(apiEndpoint, apiParams),
  });

  console.log(data);

  const infoList = data?.result?.LIST || [];
  const totalCount = data?.result?.TOTAL_COUNT || 0;
  const totalPages = data?.result?.PAGE_COUNT || 0;

  const [searchCondition, setSearchCondition] = useState('bdc_title');
  const [searchValue, setSearchValue] = useState('');

  const handleSearch = () => {
    const newApiParams = {
      brd_code: brdCode,
      search_type: searchCondition,
      search_keyword: searchValue,
      page_no: 1,
    };
    setApiParams(newApiParams);
    setCurrentPage(1);
  };

  const handlePageChange = (pageNo) => {
    setCurrentPage(pageNo);
    setApiParams({
      ...apiParams,
      page_no: pageNo,
    });
  };

  const handleViewDetail = (item) => {
    if (onViewDetail) {
      onViewDetail(item);
    }
  };

  return (
    <div className="bo-page">
      <h2>{title}</h2>
      {/* 검색 */}
      <div className="searchbox">
        <select
          value={searchCondition}
          onChange={(e) => setSearchCondition(e.target.value)}
        >
          <option value="bdc_title">제목</option>
          <option value="bdc_wname">작성자</option>
        </select>
        <input
          type="text"
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
        />
        <button type="button" className="btn-search" onClick={handleSearch}>
          검색
        </button>
      </div>

      {/* 자료수 */}
      <div className="board-top">
        <p>
          총 자료수 : <strong>{totalCount}</strong>&nbsp;개, 페이지수{' '}
          {currentPage}/{totalPages}
        </p>
      </div>

      {/* 리스트 */}
      <div className="table-container">
        <table>
          <colgroup>
            <col width="10%" />
            <col width="*" />
            <col width="10%" />
            <col width="15%" />
            <col width="10%" />
          </colgroup>
          <thead>
            <tr>
              <th>번호</th>
              <th>제목</th>
              <th>작성자</th>
              <th>등록일</th>
              <th>조회수</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td className="loading-data" colSpan="5">
                  데이터를 불러오는 중입니다...
                </td>
              </tr>
            ) : isError ? (
              <tr>
                <td className="error-data" colSpan="5">
                  에러가 발생했습니다: {error.message}
                </td>
              </tr>
            ) : infoList.length === 0 ? (
              <tr>
                <td className="no-data" colSpan="5">
                  게시글이 없습니다.
                </td>
              </tr>
            ) : (
              infoList.map((item, index) => (
                <tr
                  key={item.BDC_CODE || index}
                  style={{
                    fontWeight: item.BDC_MATTER === 'Y' ? 'bold' : 'normal',
                  }}
                >
                  <td className="center">
                    {item.BDC_MATTER === 'Y' ? (
                      <strong>[공지]</strong>
                    ) : (
                      item.ROW_NUM ||
                      totalCount - (pageSize * (currentPage - 1) + index)
                    )}
                  </td>
                  <td
                    onClick={() => handleViewDetail(item)}
                    style={{ cursor: 'pointer' }}
                  >
                    {item.bdc_dept === 1 && (
                      <span style={{ marginLeft: '20px' }}></span>
                    )}
                    {item.bdc_dept === 2 && (
                      <span style={{ marginLeft: '40px' }}></span>
                    )}
                    {item.BDC_TITLE}
                  </td>
                  <td className="center">{item.BDC_WNAME}</td>
                  <td className="center">{item.BDC_RDT}</td>
                  <td className="center">{item.BDC_HIT}</td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>

      {/* 페이징 */}
      <div className="pagination-container">
        <Pagination
          currentPage={currentPage}
          totalPage={totalPages}
          onPageChange={handlePageChange}
        />
      </div>

      {/* 버튼 */}
      <div className="button-group">
        <button type="button" className="btn-l" onClick={onRegister}>
          <span>등록</span>
        </button>
      </div>
    </div>
  );
}

export default CommonInfoList;
