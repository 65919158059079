import React from 'react';
import { Link } from 'react-router-dom';

// 컴포넌트
import SubTitle from 'components/subTitle/SubTitle';

// scss
import './companyIntro.scss';

// 이미지
import backgroundMo from 'assets/images/containers/business/companyIntro/cowBackground_mo.png';
import backgroundPc from 'assets/images/containers/business/companyIntro/cowBackground_pc.png';
import _01Mo from 'assets/images/containers/business/companyIntro/01_mo.png';
import _01Pc from 'assets/images/containers/business/companyIntro/01_pc.png';
import _02Mo from 'assets/images/containers/business/companyIntro/02_mo.png';
import _02Pc from 'assets/images/containers/business/companyIntro/02_pc.png';
import _03Mo from 'assets/images/containers/business/companyIntro/03_mo.png';
import _03Pc from 'assets/images/containers/business/companyIntro/03_pc.png';
import _04Mo from 'assets/images/containers/business/companyIntro/04_mo.png';
import _04Pc from 'assets/images/containers/business/companyIntro/04_pc.png';

function CompanyIntro() {
  return (
    <div className="companyIntro subCon pullContent">
      <SubTitle />
      <dl className="introduce">
        <dt>
          뛰어난 품질과 혁신을 향한
          <br />
          농가의 파트너, 젖소개량사업소
        </dt>
        <dd>
          우리 젖소개량사업소는 오직 낙농가 조합원을 위해 봉사하고 희생한다는
          각오로 뛰고 있습니다. 젖소개량에 관한 모든 것, 언제든지 상의해
          주십시오. 젖소 개량사업소는 도움이 필요할 때 바로 농가 옆에 있습니다.
        </dd>
      </dl>
      <img
        className="introduceBack"
        srcSet={`${backgroundMo} 768w, ${backgroundPc} 769w`}
        sizes="(max-width: 768px) 100vw, 769px"
        alt="농장 사진"
      />
      <dl className="introduce">
        <dt>
          1962년의 시작 젖소개량사업소.
          <br />
          60년의 혁신과 비전
        </dt>
        <dd>
          끊임없는 도전과 발전을 통해, 젖소의 품질 향상과 낙농 산업의 미래를
          선도하고 있습니다. 젖소개량사업소는 현대 낙농의 발전을 위한 지속적인
          연구와 혁신으로 낙농가의 든든한 동반자가 되겠습니다.
        </dd>
      </dl>
      <div className="goHistory">
        <div className="goHistoryInner">
          <Link to="/Web/Business/History">연혁 보기</Link>
        </div>
      </div>
      <article className="technicalGuidance">
        <div className="technicalGuidanceInner">
          <h5>기술지도</h5>
          <p>우리 젖소개량사업소는 낙농가 조합원들의 성공을 위해 노력합니다.</p>
          <ul className="guidanceList">
            <li>
              <img
                srcSet={`${_01Mo} 768w, ${_01Pc} 769w`}
                sizes="(max-width: 768px) 100vw, 769px"
                alt="간행물발간 이미지"
              />
              <dl>
                <dt>간행물 발간</dt>
                <dd>
                  젖소개량정보, 젖소검정뉴스, 젖소씨수소 안내, 사업연차보고서 등
                  각종 홍보물을 정기적으로 낙농가에 보급하고 있습니다.
                </dd>
              </dl>
            </li>
            <li>
              <img
                srcSet={`${_02Mo} 768w, ${_02Pc} 769w`}
                sizes="(max-width: 768px) 100vw, 769px"
                alt="현장지도 이미지"
              />
              <dl>
                <dt>현장지도</dt>
                <dd>
                  전국 낙농가 및 후대를 위해 젖소 육종농가 교육을 실시합니다.
                </dd>
                <dd>
                  젖소개량기술 및 번식기술 교육으로 진행되며, 특히 번식기술의
                  경우 인공수정 실습과 임신감정 교육을 병행합니다.
                </dd>
              </dl>
            </li>
            <li>
              <img
                srcSet={`${_03Mo} 768w, ${_03Pc} 769w`}
                sizes="(max-width: 768px) 100vw, 769px"
                alt="인터넷 검정성적 조회 서비스 이미지"
              />
              <dl>
                <dt>인터넷 검정성적 조회 서비스</dt>
                <dd>
                  젖소 유우군 검정정보를 실시간으로 농가에서 조회할 수 있습니다.
                </dd>
                <dd>
                  필요한 기술과 해외 정보를 신속, 정확하게 제공되고 있으며 일일
                  접속건수가 약 5,000여회에 달합니다.
                </dd>
              </dl>
            </li>
            <li>
              <img
                srcSet={`${_04Mo} 768w, ${_04Pc} 769w`}
                sizes="(max-width: 768px) 100vw, 769px"
                alt="모바일 검정성적 조회 서비스 이미지"
              />
              <dl>
                <dt>모바일 검정성적 조회 서비스</dt>
                <dd>
                  농협정액 계획교배, 검정개체 찾기등 다양한 검정 및 개량정보를
                  스마트 기기를 통해 실시간 제공합니다.
                </dd>
              </dl>
            </li>
          </ul>
        </div>
      </article>
    </div>
  );
}

export default CompanyIntro;
