import React from 'react';
import Visit from 'containers/web/business/visit/Visit';
import { Helmet } from 'react-helmet-async';

function VisitPage() {
  return (
    <>
      <Helmet>
        <title>찾아오시는 길</title>
      </Helmet>
      <Visit />
    </>
  );
}

export default VisitPage;
