import React from 'react';
import Performance from 'containers/web/performance/Performance';

function PerformancePage() {
  return (
    <Performance />
  );
}

export default PerformancePage;
