import React from 'react';

import d3by27 from 'assets/images/sub/_images/3-27.jpg';
import d3by29 from 'assets/images/sub/_images/3-29.jpg';
import d3by31 from 'assets/images/sub/_images/3-31.jpg';
import d3by33 from 'assets/images/sub/_images/3-33.jpg';
import d3by37 from 'assets/images/sub/_images/3-37.jpg';
import d3by39 from 'assets/images/sub/_images/3-39.jpg';
import d3by41 from 'assets/images/sub/_images/3-41.jpg';

function Fo59() {

  return (
    <div className="techinfo_view">
				<p className="viewTitle">육성우의 시설</p>
				<h2>Super Calf hutch란?</h2>
				<ul className="listStyle04 mg15f">
					<li>Calf hutch로 포육후 2∼6개월령의 자우를 8두이하의 소두수군으로 사육하는 시설. </li>
					<li>소두수의 군사로 그 후의 다두수의 군사로 길들인다. </li>
					<li>1∼2개월 이상이나 월령이 다른 자우는 동일군에 넣지 않는다. </li>
				</ul>
				<div className="imgBox mg60f"><img src={d3by27} alt="Super calf hutch. " /></div>
				
				<h2>Super Calf hutch의 크기, 구조.</h2>
				<ul className="listStyle04 mg15f">
					<li>8두 이하를 수용하고, 폭 7.7m × 건물의 안길이 3.6m. </li>
					<li>전면 패널(Panel)에는 경첩을 붙여 개방 가능하게 한다. </li>
					<li>중앙부 입구의 높이도 동일하게 조정가능하게 한다. </li>
					<li>견인차등으로 견인하여 이동할 수 있게 기초부분에 썰매를 단다. </li>
					<li>외벽, 지붕은 방서효과가 높은 백색으로 도장(塗裝)한다. </li>
					<li>개방면을 남동으로 향하여 앞쪽을 목책으로 둘러싸인 급사, 운동장으로 한다. </li>
				</ul>
				<div className="imgBox mg60f"><img src={d3by29} alt="Super calf hutch의 개략도(MWPS-7) " /></div>
				
				<h2>육성용 Pan Barn ( 답압식(踏壓式) 우사)란? </h2>
				<ul className="listStyle04 mg15f">
					<li>우사내를 목책등으로 둘러 싸인 우리라 하는 구획 가운데 방사(放飼)하는 방식의 우사. 평평한 마루에 부료를 넣은 것을 답압식(踏壓式)이라 부른다. </li>
					<li>5개월령까지는 1두당 2.3∼2.8㎡, 그것 이후는 3.0∼3.7㎡정도의 면적이 필요.</li>
					<li>자유롭게 운동할 수 있는 패독(Paddock)을 나란히 설치하는 것이 바람직하다. </li>
					<li>풍부한 부료를 필요로 한다.</li>
				</ul>
				<div className="imgBox mg60f"><img src={d3by31} alt="육성.건유우용 Pan barn의 예" /></div>
				
				<h2>Free stall(개방식 우상) 육성우사란? </h2>
				<ul className="listStyle04 mg15f">
					<li>3∼4개월령 이후이면, Free stall(개방 우상식)에서 군사할 수 있다. </li>
					<li>단계를 거쳐 다두수의 군사로 해 갈 필요가 있다. </li>
					<li>개방식(繫放式)의 구우사를 개조하여 만드는 것도 가능하다. </li>
					<li>소량의 부료는 필요.</li>
					<li>분뇨는 빈빈히 반출하지 않으면 안된다. </li>
					<li>Sludge(슬러지), Semisolid(부료를 함유한 반고형상)으로서 분뇨를 처리하기 위해 성우사의 분뇨처리 방식과의 정합성을 생각할 필요가 있다.</li>
				</ul>
				<div className="imgBox mg60f"><img src={d3by33} alt="육성·건유우용. Free stall우사의 예." /></div>
				
				<h2>발육 단계별 Hutch. Barn, Stall의 크기.</h2>
				<p>월령과 시설 Type에 의해 필요한 크기는 다르다.</p>
				<div className="bgBox">
					<h3>자우용</h3>
					<ul className="listStyle01">
						<li>0∼2개월령 폭 길이
							<ul>
								<li>(단사 Pen) Calf hutch(1,200×1,800mm의 운동장) 1200 × 2400mm </li>
								<li>답압식(踏壓式) Pen 1200 × 2400mm </li>
								<li>Tie stall 600 × 1200mm </li>
							</ul>
						</li>
						<li>3∼5개월령
							<ul>
								<li>(6두까지의 그룹) Super Calf hutch 2.3∼2.8㎡/頭</li>
								<li>답압식(踏壓式) Pen 2.3∼2.8㎡/頭</li>
							</ul>
						</li>
					</ul>
				</div>
				<div className="tableWrap">
					<table className="tablestyle">
						<thead>
							<tr>
								<th className="first" rowSpan="2">약자우(若雌牛)용</th>
								<th colSpan="4">Stall폭 길이</th>
							</tr>
							<tr>
								<th>5∼8개월령</th>
								<th>9∼12개월령</th>
								<th>13∼15개월령</th>
								<th>16∼24개월</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>Free stall(mm)</td>
								<td>750 × 1500</td>
								<td>900 × 1650</td>
								<td>1050 × 1950</td>
								<td>1050 × 2100</td>
							</tr>
							<tr>
								<td>분뇨처리용통로의 폭(mm) </td>
								<td>2400 ∼ 3000</td>
								<td>2400 ∼ 3000</td>
								<td>2400 ∼ 3000</td>
								<td>2400 ∼ 3000</td>
							</tr>
							<tr>
								<td>휴식장소(㎡)</td>
								<td>2.3</td>
								<td>2.6</td>
								<td>3.0</td>
								<td>3.7</td>
							</tr>
							<tr>
								<td>옥외운동장(포장) (㎡)(stall 없음)</td>
								<td>3.3</td>
								<td>3.7</td>
								<td>4.2</td>
								<td>4.6</td>
							</tr>
							<tr>
								<td>휴식장소(깔짚있음)<sup>1)</sup> (㎡)</td>
								<td>2.3</td>
								<td>2.6</td>
								<td>3.0</td>
								<td>3.7</td>
							</tr>
							<tr>
								<td>Slatted floor (㎡)</td>
								<td>1.1</td>
								<td>1.2</td>
								<td>1.6</td>
								<td>2.3</td>
							</tr>
						</tbody>
					</table>
				</div>
				<ul className="refer mg10t mg60f">
					<li>1) 급사장(통로)으로의 연락통로를 3000mm폭과 가정. (MWPS - 7)</li>
				</ul>
				
				<h2>Counter slope우사란? </h2>
				<ul className="listStyle04 mg15f">
					<li>휴식장소, 급사장소 각각 한쪽으로만 경사지게 된 지붕을 달고 마루는 1/12정도의 경사를 내어 중앙의 집분통로로 분뇨가 모이도록 설계된 우사.</li>
					<li>휴식소는 남동으로 향한다. </li>
					<li>이유후 자우에게는 통상 부료(깔짚)를 사용하지 않는다.</li>
					<li>따뜻할 때 환기창은 열린 그대로.</li>
					<li>분은 소의 이동으로 인해 통로로 모이기 때문에 사육밀도가 낮으면 집분(분이 모일 수 없다.)할 수 없다.</li>
				</ul>
				<div className="imgBox mg15f"><img src={d3by37} alt="Counter slope 우사의 횡단면. " /></div>
				<ul className="mg60f">
					<li>※ 급사장소는 집분통로로 향하여 1/12의 경사를 낸다. 폭은 1.5∼2.4m의 것이 만들어 지고 있다. </li>
					<li>※ 집분 통로의 폭은 1.8∼2.7m. Gate(문)는 회전하여 휴식소의 전면을 닫고, 청소중은 소를 감금한다.</li>
					<li>※ 간막이 목책 또는 벽은 통상 3.6m마다 설치한다. 콘크리트마루는 집분통로로 향하여 1/12의 경사를 낸다.</li>
				</ul>
				
				<h2>Slatted floor(대나 띠로 발처럼 엮은 것: 대 또는 판자를 조금씩 사이를 두어 만든 튀마루)의 요점.</h2>
				<ul className="listStyle04 mg15f">
					<li>Barn type우사에는 바닥은 전면 Slat. </li>
					<li>Free stall 우사에는 통로만이 Slat. </li>
					<li>Slat는 소의 발꿉을 상하지 않도록 표면을 미끄럽지 않게 가공하여 정부(頂部)의 폭을 10∼20cm로 한다. Slat의 간격은 육성우에서 약 4cm, 자우는 3cm로 한다. </li>
					<li>부료는 대부분 필요없고, 6∼8개월마다 분뇨를 퍼내면 좋다. </li>
					<li>지하의 분뇨 저류조에 비용이 들어 건설비는 값이 비쌈.</li>
					<li>분뇨 저류조로부터의 가스가 우사내로 들어오지 않도록 강제배기할 필요가 있다.</li>
				</ul>
				<div className="imgBox mg60f"><img src={d3by39} alt="육성우용 전면 Slat우사의 예. " /></div>
				
				<h2>House 우사의 이점, 난점.</h2>
				<dl>
					<dt>이점(利點).</dt>
					<dd>
						<ul className="listStyle04">
							<li>건설비가 싸다. </li>
							<li>겨울의 대낮은 일사에 의해 온도가 오른다. </li>
							<li>마루는 건조하기 쉽다. </li>
						</ul>
					</dd>
				</dl>			
				<dl>
					<dt>난점(難點) </dt>
					<dd>
						<ul className="listStyle04">
							<li>여름은 적절한 차광(遮光) 대책을 하지 않으면 고온이 된다. </li>
							<li>야간은 방사 냉각에 의해 바깥 기온보다 저온이 되는 일이 있다. </li>
							<li>강도가 약하여 눈과 바람에는 약하다. </li>
						</ul>
					</dd>
				</dl>		
				<div className="imgBox"><img src={d3by41} alt="Pipe House의 포육사. " /></div>
			</div>
  );
}

export default Fo59;
