import React, { useState } from 'react';
import { Link , useNavigate } from 'react-router-dom';
import { DCIC_ROUTE_PAGE } from 'constants/constants';

// 이미지
import DciLogo from 'assets/images/common/dciHeader_logo.png';

// scss
import './dciHeader.scss';

function DciHeader() {

  // 네비게이션 오픈
  const [ navOpen , setNavOpen ] = useState(false);

  // 현재 선택된 메뉴
  const [ currentDepth , setCurrentDepth ] = useState('dciFarm');

  const renderNav = () => (
    Object.keys(DCIC_ROUTE_PAGE).map((twoDepth) => (
      <li key={twoDepth}>
        {
          DCIC_ROUTE_PAGE[twoDepth].child 
          ?    
          <button 
            type='button'
            className={currentDepth === twoDepth ? 'current' : ''}
            onClick={() => setCurrentDepth(twoDepth)}
          >
            {DCIC_ROUTE_PAGE[twoDepth].title}
          </button>
          :
          <Link 
            to={`${twoDepth}`}
            className={currentDepth === twoDepth ? 'current' : ''}
            onClick={() => setCurrentDepth(twoDepth)}
          >
            {DCIC_ROUTE_PAGE[twoDepth].title}
          </Link>
        }

        {
          currentDepth === twoDepth &&
          DCIC_ROUTE_PAGE[twoDepth].child &&
          <ul className='threeDepth'>
            {
              Object.keys(DCIC_ROUTE_PAGE[twoDepth].child)
              .map((threeDepth) => (
                <li key={threeDepth}>
                  <Link 
                    to={`${twoDepth}/${threeDepth}`}
                    onClick={()=> setNavOpen(false)}
                  >
                    {DCIC_ROUTE_PAGE[twoDepth].child[threeDepth]}
                  </Link>
                </li>
              ))
            }
          </ul>
        }

      </li>
    )
  ));

  const navigate = useNavigate();
  function logout() {
    if (window.confirm('로그아웃 하시겠습니까?')) {
      localStorage.removeItem('cong');
      localStorage.removeItem('farm');
      localStorage.removeItem('month');
      localStorage.removeItem('passwd');
  
      navigate('/web/main');
    }
  }

  return (
    <header className={`dciHeader ${navOpen ? 'open' : ''}`}>
      <h1>
        <Link to='/dcitest/dciFarm'>
          <img src={DciLogo} alt='젖소개량사업소' />
          <span>젖소개량사업소</span>  
        </Link>
      </h1>
      <nav className='nav'>
        <h2 className='hidden'>네비게이션 영역</h2>
        <ul className='twoDepth'>
          { renderNav && renderNav() }
        </ul>
      </nav>
      <button 
        type='button' 
        className='logout'
        onClick={logout}
      >로그아웃</button>
      <div className='totalView'>
        <ul>
          <li>
            <p>TODAY <span>350</span></p>  
          </li>
          <li>
            <p>TOTAL <span>2194690</span></p>
          </li>
        </ul>
      </div>
      <button 
        type='button'
        className='navBtn'
        onClick={() => setNavOpen(!navOpen)}
      >
        <span className={`arrow ${navOpen ? 'open' : ''}`}></span>
        <span className='hidden'>네비게이션 열기</span>
      </button>
    </header>
  );
}

export default DciHeader;
