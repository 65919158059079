import React from 'react';

import pic704 from 'assets/images/sub/_images/pic704.jpg';
import pic705 from 'assets/images/sub/_images/pic705.jpg';

function Do143() {

  return (
    <div className="techinfoview">
      <h1>비절농양</h1>
      <p className="pstyle">사진에서 불 수 있듯이 비절의 바깥쪽이 화농하여 붓는 경우를 종종 볼 수 있다. 이것은 소가 누어 있을 때 비절의 바깥 쪽이 바닥에 계속 맞닿음으로써 찰과상을 입게 되고 이곳을 통하여 세균 감염이 일어나 염증을 일으키고 결국은 고름 주머니를 형성하게 되기 때문이다.</p>
      <div className="leftpart">
        <h2>목장에서의 처치</h2>
        <ul>
          <li>오랜 시일이 지나야 고름주머니가 형성되므로 응급수단은 별도로 없다. </li>
          <li>때때로 따뜻한 물로 솔질하여 씻어주고 외상약으로 강옥도등을 발라준다. </li>
          <li>절뚝거릴때는 관절염이 생긴 것이므로 항생제를 관절강(關節腔)내 또는 주위에 주사한다</li>
        </ul>
      </div>
      <div className="rightpart">
        <img src={pic704} className="nonborder" alt=""/>
        <img src={pic705} className="nonborder" alt=""/>
      </div>
    </div>
  );
}

export default Do143;