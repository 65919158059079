import React from 'react';

function Do7() {

  return (
    <div className="techinfoview">
    <h1>선형외모심사(線形外貌審査)</h1>
    <ul>
        <li>체형의 심사는 왜 받는가?
            <ul>
                <li>젖소의 외모가 뭐 그리 대단한가? 젖소는 우유(牛乳)만 많이 생산하면 그만이지 하는 생각을 갖는 사람도 있다. 특히 학자들간에는 외모와 생산능력의 관계에 대하여 많은 이견을 가지고 있는 것도 사실이다. 그러나 일반적으로 체적(體積)이 커야 조사료를 많이 먹을 수 있고, 생산능력도 높다든지, 유방의 깊이가 앝고 앞유방붙임이 강해야 나이를 먹어도 쳐지지 않는다든지, 뒷다리의 굽이가 적절하고 발굽각도 커야 체중유지에 효과적이든지, 엉덩이가 넓어야 초산분만을 쉽게 할 수 있다든지 하는 것은 잘 알려진 사실이다. </li>
                <li>건강하고 우수한 젖소란?
                    <ul>
                        <li>우수한 낙농경영에 적합한 젖소란 다음과 같은 조건을 갖추어야 한다. 
                            <ul>
                                <li>비유능력이 우수하고 </li>
                                <li> 매년 건강한 송아지를 분만하고(분만간격 12~14개월) </li>
                                <li>오랫동안 많은 양의 우유를 생산하면서도 유방염이나 번식장애 없이 건강한 젖소를 말한다.</li>
                            </ul>
                        </li>
                        <li>이러한 젖소의 체형은 다음과 같아야 한다. 
                            <ul>
                                <li>용적(容積)이 크고, 높고 넓고 강하게 붙은 유방 </li>
                                <li>충분한 용적의 내장(소화기, 호흡기, 순환기 등)을 수용할 수 있는 체적</li>
                                <li>이러한 몸을 지탱할 수 있는 튼튼한 다리 </li>
                                <li>먹은 사료를 더 많이 우유로 변환시킬 수 있는 자질(젖소다운 날카로움 : 예각성)</li>
                            </ul>
                        </li>
                        <li>이러한 체형의 젖소는 비유최성기(泌乳最盛期 6~7세, 4~5산)까지의 사양을 너끈히 할 수 있고, 그 뒤에도 계속하여 젖을 많이 생산할 수 있을 것이다. </li>
                    </ul>
                </li>
                <li>지구성(持久性)등의 판단이 심사의 주된 역할
                    <ul>
                        <li>지구성이나 기능성(機能性)이 풍부한가의 여부를 판단하는데는 심사에 의하는 것이 간단하고 효율적이다. 물론 생애(生涯)동안에 검정한 결과를 보면 정확한 평가와 판단이 가능하지만, 그 소가 죽어버린 뒤나 노령이 된 뒤의 평가는 그의 우수한 자질(資質)을 활용 할 수 없거나 효율이 좋지 않게 된다. <br/>지구성 등에 대한 판단은 되도록 빨리 하는 것이 좋고, 이것을 알아내는 것이 선형 심사인 것이다. </li>
                    </ul>
                </li>
                <li>체형개량을 이룩하려면?
                    <p>체형의 개량은 생산능력의 개량과 같이</p>
                    <ul>
                        <li>사육우의 전부를 심사하여 각 젖소의 체형상의 장단점을 파악하고 </li>
                        <li> 종모우(種牡牛)의 유전정보를 사용하여 교배정액(交配精液)을 선정한다.</li>
                        <li>생산된 후대축의 개량효과를 심사로 확인하여 선발·도태하는 일이 필요하게 되며, 이상의 과정을 되풀이 함으로써 개량이 이루어 진다. 또한 수많은 암소들의 심사자료를 종모우별로 집계(集計), 분석(分析)하는 것에 의하여 종모우체형의 유전특징이 명확히 되고, 보다 적절한 종모우 선정이 가능하여 지는 것이다. </li>
                    </ul>
                </li>
                <li>선형심사는 왜 우군심사(牛群審査)인가?
                    <ul>
                        <li>전반적으로 보아 젖소 사육추세는 점차적으로 다두화(多頭化)의 경향이 있다. 따라서 우군내의 특정우 만을 개량하는 것은 비경제적이며 체형의 제일성(齊一性)을 이룩하기 어려워지게 된다. </li>
                    </ul>
                </li>
            </ul>
        </li>
        <li>선형심사 요령
            <ul>
                <li>선형(線型, Linear)이라는 말은 &quot;線&quot;이란 뜻이다. 젖소의 선형심사란 우유의 생산능력에 직접 또는 간접적으로 영향을 미치는 기능적체형형질(機能的體型形質)의 생물학적변이(生物學的變異)의 정도를 연속적인 눈금(1에서 50)을 사용하여 수치적(數値的)으로 표시한 것이라 하겠다.</li>
                <li>선형심사가 끝난 후에 심사표준에 따라 일반외모심사를 하는데 일반외모(15%), 유용특질(20%), 체적(10%), 지제(15%), 비유기관(40%)의 5가지 형질을 심사하는 것으로 이상형(理想型)에 얼마나 가까이 접근하는가를 평가한다.</li>
                <li>선형심사를 하는 근본적인 목적은 우유생산에 영향을 미치는 기능적체형의 장단점을 파악하여 그 후대를 개량하여 생애산유량(生涯産油量)을 늘리자는 것으로, 이는 기능적체형 형질이 유전력(遺傳力)은 그리 높지 않지만 분명히 후손에게 유전이 된다는 사실에 바탕을 두고 있다.</li>
                <li>심사받은 암소들은 아비(父)별로 분석하면 그것이 바로 아비의 체형유전 능력을 알게 되기 때문에 선진낙농국에서는 해마다 선형심사두수를 늘리고 같은 개체에 대해서도 미국과 같은 나라는 7개월마다 재심사하는 것이다.</li>
                <li>젖소의 기능적 체형중에서 생애산유량에 보다 많이 영향을 미치는 것을 1차형질, 이보다 영향이 적고 유전력이 낮은 것을 2차 형질로 구분하여 실시하는데 이들 형질은 다음과 같다.</li>
                <li>선형심사의 표현방법은 미국, 카나다, 영국 화란, 독일등 나라마다 조금씩 다르나 근본원리는 같다. 일본과 우리나라는 미국식방법을 채택하고 있고 이의 방법과 내용을 간단히 설명하면 다음과 같다.
                    <table className="tablestyle">
                        <tr>
                            <td>부위</td>
                            <td>1차형질</td>
                            <td>2차형질</td>
                        </tr>
                        <tr>
                            <td>전체외모</td>
                            <td>
                                <ul style={{float: 'left', textAlign: 'left' ,  marginLeft: '20px'}}>
                                    <li>키(십자부)</li>
                                    <li>강건성</li>
                                    <li>체심</li>
                                    <li>예각성</li>
                                </ul>
                            </td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>엉덩이</td>
                            <td>
                                <ul style={{float: 'left', textAlign: 'left', marginLeft: '20px'}}>
                                    <li>기울기</li>
                                    <li>너비</li>
                                </ul>
                            </td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>다리</td>
                            <td>
                                <ul style={{float: 'left', textAlign: 'left', marginLeft: '20px'}}>
                                    <li>옆에서 본 뒷다리의 상태</li>
                                    <li>발굽의 각도</li>
                                </ul>
                            </td>
                            <td>
                                <ul style={{float: 'left', textAlign: 'left', marginLeft: '20px'}}>
                                    <li>뒤에서 본 뒷다리의 상태</li>
                                    <li>발목</li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td>비유기관(유방)</td>
                            <td>
                                <ul style={{float: 'left', textAlign: 'left', marginLeft: '20px'}}>
                                    <li>앞유방의 부착강도</li>
                                    <li>뒷유방의 부착너비</li>
                                    <li>유방의 깊이</li>
                                    <li>유방의 정중제인대</li>
                                    <li>유방의 깊이</li>
                                </ul>
                            </td>
                            <td>
                                <ul style={{float: 'left', textAlign: 'left', marginLeft: '20px'}}>
                                    <li>유방의 균형</li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td>앞유부</td>
                            <td>
                                <ul style={{float: 'left', textAlign: 'left', marginLeft: '20px'}}>
                                    <li>뒤에서 본 배열 위치</li>
                                    <li>크기(길이)</li>
                                </ul>
                            </td>
                            <td></td>
                        </tr>
                    </table>
                </li>
            </ul>
        </li>
    </ul>
</div>
  );
}

export default Do7;
