import React from 'react';
import QnaTechnicalInfo from 'containers/web/data/qnaTechnicalInfo/QnaTechnicalInfo';
import { Helmet } from 'react-helmet-async';

function QnaTechnicalInfoPage() {
  return (
    <>
      <Helmet>
        <title>기술정보</title>
      </Helmet>
      <QnaTechnicalInfo />
    </>
  );
}

export default QnaTechnicalInfoPage;
