import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { getState, postState } from 'utils/api';

function BackupBreedBullEdit() {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const sid = searchParams.get('sid');

  const [formData, setFormData] = useState({
    s_code: '',
    s_sortname: '',
    s_fullname_e: '',
    s_birth: '',
    s_origin: '',
    s_sire_e: '',
    s_dam_e: '',
  });

  useEffect(() => {
    const fetchBreedBullData = async () => {
      if (sid) {
        try {
          const response = await getState('/board/select_backup_breed_bull', {
            sid,
          });

          if (response?.return_code === 'SUCCESS') {
            setFormData(response.result);
          }
        } catch (error) {
          console.error('Error fetching breed bull data:', error);
        }
      }
    };

    fetchBreedBullData();
  }, [sid]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    // Validation
    if (!formData.s_code) {
      alert('정액코드를 입력해주세요.');
      return;
    }

    if (!formData.s_sortname) {
      alert('단축명호를 입력해주세요.');
      return;
    }

    if (!formData.s_fullname_e) {
      alert('영문정식명칭을 입력해주세요.');
      return;
    }

    if (!formData.s_birth) {
      alert('생년월일을 입력해주세요.');
      return;
    }

    const confirmMessage = sid ? '수정하시겠습니까?' : '등록하시겠습니까?';
    if (!window.confirm(confirmMessage)) {
      return;
    }

    try {
      const endpoint = `/board/${sid ? 'update' : 'insert'}_backup_breed_bull/`;
      const response = await postState(endpoint, formData);

      if (response?.return_code === 'SUCCESS') {
        alert(`${sid ? '수정' : '등록'}되었습니다.`);
        navigate('/bo/breedBull/backupBreedBull/list');
      }
      if (response?.return_code === 'ERROR') {
        alert(response?.return_message);
      }
    } catch (error) {
      console.error('Error saving breed bull:', error);
      alert('저장 중 오류가 발생했습니다.');
    }
  };

  const handleDelete = async () => {
    if (!window.confirm('삭제하시겠습니까?')) {
      return;
    }

    try {
      const response = await postState('/board/delete_backup_breed_bull/', {
        sid,
      });

      if (response?.return_code === 'SUCCESS') {
        alert('삭제되었습니다.');
        navigate('/bo/breedBull/backupBreedBull/list');
      }
      if (response?.return_code === 'ERROR') {
        alert(response?.return_message);
      }
    } catch (error) {
      console.error('Error deleting breed bull:', error);
      alert('삭제 중 오류가 발생했습니다.');
    }
  };

  return (
    <div className="bo-page">
      <h2>후보 종모우 {sid ? '수정' : '등록'}</h2>

      <div
        className="table-container bold-border"
        style={{ marginBottom: '1rem' }}
      >
        <table>
          <colgroup>
            <col width="20%" />
            <col width="" />
          </colgroup>
          <tbody>
            <tr>
              <th>
                정액코드
                <span className="required">*</span>
              </th>
              <td className="nr">
                {sid ? (
                  <span>{formData?.s_code}</span>
                ) : (
                  <input
                    type="text"
                    name="s_code"
                    value={formData?.s_code}
                    onChange={handleInputChange}
                    maxLength={10}
                  />
                )}
              </td>
            </tr>
            <tr>
              <th>
                단축명호
                <span className="required">*</span>
              </th>
              <td className="nr">
                <input
                  type="text"
                  name="s_sortname"
                  value={formData?.s_sortname}
                  onChange={handleInputChange}
                  maxLength={100}
                />
              </td>
            </tr>
            <tr>
              <th>
                영문정식명칭
                <span className="required">*</span>
              </th>
              <td className="nr">
                <input
                  type="text"
                  name="s_fullname_e"
                  value={formData?.s_fullname_e}
                  onChange={handleInputChange}
                  maxLength={100}
                />
              </td>
            </tr>
            <tr>
              <th>
                생년월일
                <span className="required">*</span>
              </th>
              <td className="nr">
                <input
                  type="text"
                  name="s_birth"
                  value={formData?.s_birth}
                  onChange={handleInputChange}
                  maxLength={10}
                />
              </td>
            </tr>
            <tr>
              <th>원산지</th>
              <td className="nr">
                <input
                  type="text"
                  name="s_origin"
                  value={formData?.s_origin}
                  onChange={handleInputChange}
                  maxLength={3}
                />
              </td>
            </tr>
            <tr>
              <th>부(영문정식명칭)</th>
              <td className="nr">
                <input
                  type="text"
                  name="s_sire_e"
                  value={formData?.s_sire_e}
                  onChange={handleInputChange}
                  maxLength={100}
                />
              </td>
            </tr>
            <tr>
              <th>모(영문정식명칭)</th>
              <td className="nr">
                <input
                  type="text"
                  name="s_dam_e"
                  value={formData?.s_dam_e}
                  onChange={handleInputChange}
                  maxLength={100}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="button-group">
        <button type="button" className="btn-l" onClick={handleSubmit}>
          {sid ? '수정' : '등록'}
        </button>
        {sid && (
          <button type="button" className="btn-l" onClick={handleDelete}>
            삭제
          </button>
        )}
        <button
          type="button"
          className="btn-l"
          onClick={() => navigate('/bo/breedBull/backupBreedBull/list')}
        >
          목록
        </button>
      </div>
    </div>
  );
}

export default BackupBreedBullEdit;
