import React from 'react';

// constants
import { BOARD_CODE, BOARD_NAME } from 'constants/backOfficeBoard';

// components
import BoardView from '../_components/BoardView';

function CowTestNewView() {
  return (
    <BoardView
      boardCode={BOARD_CODE.COW_TEST_NEW}
      boardName={BOARD_NAME.COW_TEST_NEW}
      boardUrl="cowTestNew"
    />
  );
}

export default CowTestNewView;
