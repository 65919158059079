import React from 'react';

import pic714 from 'assets/images/sub/_images/pic714.jpg';
import pic715 from 'assets/images/sub/_images/pic715.jpg';
import pic716 from 'assets/images/sub/_images/pic716.jpg';

function Do147() {

  return (
    <div className="techinfoview">
      <h1>개선충 증</h1>
      <p className="pstyle">개선충증은 소의 옴벌레의 일종인 개선충에 의해 일어나는 피부염이다. 이것은 소버짐(피부진균증)과 함께 감염되기도 한다.</p>
      <div className="leftpart">
        <h2>증상</h2>
        <ul>
          <li>옴벌레의 의한 피부병의 공통된 증상은 다른 피부병에 비해 가려움이 심하다는 것이다.
            <ul>
              <li>처움에는 피부에 좁쌀 크기 정도로 발진이 솟아나고 가려움으로 주변의 물체에 비벼대며 피부가 거칠어지고 털이 빠진다.</li>
              <li>발생이 많은 부위는 엉덩이, 하복부, 유방주의 등이며 전신적으로 생긴다.</li>
              <li>증상이 악화되면 습진처럼 진물(삼출물)이 나오며 충혈과 출혈이 생기기도 한다.</li>
            </ul>
          </li>
        </ul>
        <h2>목장에서의 처치</h2>
        <ul>
          <li>개선충 구제연고(예:가나연고)를 병변부와 그 주위에 바른다.</li>
          <li>3일 간격으로 비누로 씻어내고 다시 바른다.</li>
          <li>감염 부위가 전신적으로 광범위할때는 1% 네구본 수용액, 0.1%아산톨 수용액 등을 가려움이 사라질 때까지 전신에 뿌려준다.(1∼2개월간)</li>
          <li>이후 일단 멈추었다가 다시 한번 1주일 정도 처치해 준다.</li>
        </ul>
      </div>
      <div className="rightpart">
        <img src={pic714} className="nonborder" alt=""/>
        <img src={pic715} className="nonborder" alt=""/>
        <img src={pic716} className="nonborder" alt=""/>
      </div>
    </div>
  );
}

export default Do147;