import React, { useState } from 'react';
import { Outlet, NavLink } from 'react-router-dom';

// 컴포넌트
import SubTitle from 'components/subTitle/SubTitle';
import Artificial from './Artificial';
import Breeding from './Breeding';
import Consultation from './Consultation';
import Fertilized from './Fertilized';
import International from './International';
import Medicine from './Medicine';
import Organization from './Organization';
import Processing from './Processing';

// scss
import './RelatedSites.scss';

function RelatedSites() {
  const [relatedTab, setRelatedTab] = useState('organization');

  const [selectedTab, SetSelectedTab] = useState('organization');

  return (
    <section className="relatedSites subCon">
      <SubTitle />

      <div className="relatedsites_list">
        <dl className="list_name">
          <dd>
            <NavLink
              onClick={() => {
                setRelatedTab('organization');
                SetSelectedTab('organization');
              }}
              style={{
                backgroundColor:
                  selectedTab === 'organization'
                    ? 'var(--main_green)'
                    : 'transparent',
                color: selectedTab === 'organization' ? 'white' : 'black',
                fontWeight: selectedTab === 'organization' ? 'bold' : 'normal',
              }}
            >
              관련기관·협회·대학
            </NavLink>
          </dd>

          <dd>
            <NavLink
              onClick={() => {
                setRelatedTab('processing');
                SetSelectedTab('processing');
              }}
              style={{
                backgroundColor:
                  selectedTab === 'processing'
                    ? 'var(--main_green)'
                    : 'transparent',
                color: selectedTab === 'processing' ? 'white' : 'black',
                fontWeight: selectedTab === 'processing' ? 'bold' : 'normal',
              }}
            >
              유가공업체
            </NavLink>
          </dd>

          <dd>
            <NavLink
              onClick={() => {
                setRelatedTab('consultation');
                SetSelectedTab('consultation');
              }}
              style={{
                backgroundColor:
                  selectedTab === 'consultation'
                    ? 'var(--main_green)'
                    : 'transparent',
                color: selectedTab === 'consultation' ? 'white' : 'black',
                fontWeight: selectedTab === 'consultation' ? 'bold' : 'normal',
              }}
            >
              낙농자문
            </NavLink>
          </dd>

          <dd>
            <NavLink
              onClick={() => {
                setRelatedTab('fertilized');
                SetSelectedTab('fertilized');
              }}
              style={{
                backgroundColor:
                  selectedTab === 'fertilized'
                    ? 'var(--main_green)'
                    : 'transparent',
                color: selectedTab === 'fertilized' ? 'white' : 'black',
                fontWeight: selectedTab === 'fertilized' ? 'bold' : 'normal',
              }}
            >
              수정란·육종
            </NavLink>
          </dd>
          <dd>
            <NavLink
              onClick={() => {
                setRelatedTab('breeding');
                SetSelectedTab('breeding');
              }}
              style={{
                backgroundColor:
                  selectedTab === 'breeding'
                    ? 'var(--main_green)'
                    : 'transparent',
                color: selectedTab === 'breeding' ? 'white' : 'black',
                fontWeight: selectedTab === 'breeding' ? 'bold' : 'normal',
              }}
            >
              젖소종축목장
            </NavLink>
          </dd>
          <dd>
            <NavLink
              onClick={() => {
                setRelatedTab('international');
                SetSelectedTab('international');
              }}
              style={{
                backgroundColor:
                  selectedTab === 'international'
                    ? 'var(--main_green)'
                    : 'transparent',
                color: selectedTab === 'international' ? 'white' : 'black',
                fontWeight: selectedTab === 'international' ? 'bold' : 'normal',
              }}
            >
              국제농업
            </NavLink>
          </dd>

          <dd>
            <NavLink
              onClick={() => {
                setRelatedTab('medicine');
                SetSelectedTab('medicine');
              }}
              style={{
                backgroundColor:
                  selectedTab === 'medicine'
                    ? 'var(--main_green)'
                    : 'transparent',
                color: selectedTab === 'medicine' ? 'white' : 'black',
                fontWeight: selectedTab === 'medicine' ? 'bold' : 'normal',
              }}
            >
              동물약품·사료회사
            </NavLink>
          </dd>
          {/* <dd>
            <NavLink
              className={selectedTab === 'artificial' ? 'active' : ''}
              onClick={() => {
                setRelatedTab('artificial');
                SetSelectedTab('artificial');
              }}
              style={{
                backgroundColor:
                  selectedTab === 'artificial'
                    ? 'var(--main_green)'
                    : 'transparent',
                color: selectedTab === 'artificial' ? 'white' : 'black',
                fontWeight: selectedTab === 'artificial' ? 'bold' : 'normal',
              }}
            >
              인공수정회사
            </NavLink>
          </dd> */}
        </dl>

        {/* {relatedTab === 'artificial' && <Artificial />} */}
        {relatedTab === 'breeding' && <Breeding />}
        {relatedTab === 'organization' && <Organization />}
        {relatedTab === 'processing' && <Processing />}
        {relatedTab === 'fertilized' && <Fertilized />}
        {relatedTab === 'consultation' && <Consultation />}
        {relatedTab === 'international' && <International />}
        {relatedTab === 'medicine' && <Medicine />}
      </div>

      <Outlet />
    </section>
  );
}

export default RelatedSites;
