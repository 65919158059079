import React from 'react';

function Fo44() {

  return (
    <div className="techinfo_view">
				<p className="viewTitle">간농양</p>
				<dl>
					<dt>발병대상</dt>
					<dd>소(특히 단기 비육중인 소)</dd>
				</dl>
				
				<dl>
					<dt>원인</dt>
					<dd>부제증을 일으키는 균과 동일한 Fusobacterium necrophorum이 간농양의 주된 원인균으로 생각되고 있다. 사료적 요인으로는 조사료 비율이 낮고 농후사료 비율이 높은 사료를 출하전에 소에 급여시 간농양의 발병확률이 높다. </dd>
				</dl>
				
				<dl>
					<dt>징후 및 증상</dt>
					<dd>일반적으로 간농양은 도살되기 전까지 잘 발견되지 않는다. 다만 사료섭취량이 감소되고 급여기간의 종료시 증체율이 하나의 판단 지표가 될 수 있다. </dd>
				</dl>
				
				<dl>
					<dt>질병분포와 손실규모</dt>
					<dd>간농양의 발생은 집약적인 비육우 생산체계에서 공히 일어나며 미국의 corn belt 지역과 서부 및 캐나다 서부지역에 흔하게 나타나고 있다. 간농양으로 인한 비육우 간의 12%를 폐기시켰다는 미농무성의 보고가 있다. 이러한 간농양은 단기 비육중인 소에 특히 발병율이 높아 18-20% 에 이르며 심한 곳의 경우는 90%에 까지 이른다. 체중 500kg인 소의 간 중량은 약 5.5kg에 이르기 때문에 간 폐기는 경제적으로 많은 손실을 주는데 이보다 사료효율의 감소와 증체율 저하는 더 큰 손실을 가져올 수 있다. </dd>
				</dl>
				
				<dl>
					<dt>치료</dt>
					<dd>급성 간농양의 치료는 수의사에게 맡겨 설파피리딘 또는 항생제를 투여토록 한다.</dd>
				</dl>
				
				<dl>
					<dt>대책</dt>
					<dd>최종 급여기간에 일부 항생제를 저수준으로 투여하면 효과가 있다.</dd>
				</dl>
				
				<dl>
					<dt>예방</dt>
					<dd>단기 비육중인 소의 간농양 발생은 조사료로부터 농후사료로의 전환을 서서히 하고 클로르테트라사이클린 또는 타이로신 등의 항생제를 두당 70mg의 수준으로 급여하므로써 줄일 수 있다.</dd>
				</dl>	
				
				<dl>
					<dt>참고</dt>
					<dd>간농양은 괴사로 인해 고름이 찬 간의 일부와 결합조직이 포함되며 도살시 오염된 간 부위는 식품으로 부적당하여 폐기해야 한다.</dd>
				</dl>	
			</div>
  );
}

export default Fo44;
