import React from 'react';
import OxSearch from 'containers/web/ox/oxSearch/OxSearch';
import { Helmet } from 'react-helmet-async';

function OxSearchPage() {
  return (
    <>
      <Helmet>
        <title>씨수소 검색</title>
      </Helmet>
      <OxSearch />
    </>
  );
}

export default OxSearchPage;
