import React from 'react';

function Fo35() {

  return (
    <div className="techinfo_view">
				<p className="viewTitle">비육시의 고창증</p>
				<dl>
					<dt>발병대상</dt>
					<dd>모든 반추가축</dd>
				</dl>
				
				<dl>
					<dt>원인</dt>
					<dd>고창증은 반추위의 제1위와 제2위에 과도한 가스가 축적되어 발생한다. 과다한 농후사료의 급여, 특히 곱게 분쇄된 사료의 급여로 인하여 반추위의 점액을 생성하蝥 박테리아를 증가시키는데 이때 점액과 같은 물질을 발효가스를 고이게 하여 고창증을 야기시킨다. 유전적 요인과 생리적인 부조화에서도 고창증은 발병한다</dd>
				</dl>
				
				<dl>
					<dt>징후 및 증상</dt>
					<dd>증세는 방목지 고창증과 같다. 소와 면양에게 과다한 농후사료와 저질 조사료를 계속적으로 급여(60일 또는 그 이상) 하였을 때에 발병한다</dd>
				</dl>
				
				<dl>
					<dt>질병분포와 손실규모</dt>
					<dd>Kansas feedlot의 조사에 의하면 고창증에 의한 손실은 사망 0.1%, 심한 증세가 0.2%, 중간정도 및 미약한 증상은 0.6%이었다</dd>
				</dl>
				
				<dl>
					<dt>치료</dt>
					<dd>가능한한 빨리 반추위내의 압력을 낮추어 주어야 한다. 위내로 튜브를 넣어서 압력을 낮추는 방법도 있으나 이 방법은 포말성 고창증에서는 실효를 거두기 어렵다. 거품방지제로 옥수수유나 땅콩기름 또는 대두유를 0.5ℓ정도 즉시 투여하거나 poloxalene을 사용설명서 대로 투여한다</dd>
				</dl>	
				
				<dl>
					<dt>참고</dt>
					<dd>일반적으로 인 결핍증은 방목지에서 발생한다. 인을 비료로써 초지에 주는 것도 하나의 대책이다</dd>
				</dl>
			</div>
  );
}

export default Fo35;
