export function CrownixView(Fname, params) {
  const fname = Fname;
  const queryParams = new URLSearchParams(params);
  const url = `${process.env.PUBLIC_URL}/crownix.html?${queryParams}`;
  window.open(url, '_blank', 'noopener, noreferrer');
}

export function openCrownix(Fname) {
  const fname = Fname;
  const congCode = localStorage.getItem('cong');
  const farmCode = localStorage.getItem('farm');
  const mon = localStorage.getItem('month');

  const queryParams = new URLSearchParams({
    fname,
    cong_code: congCode,
    farm_code: farmCode,
    mon,
  });

  const url = `${process.env.PUBLIC_URL}/crownix.html?${queryParams}`;
  window.open(url, '_blank', 'noopener, noreferrer');
}

export function openCrownix2(Fname, cowid, pari) {
  const fname = Fname;
  const congCode = localStorage.getItem('cong');
  const farmCode = localStorage.getItem('farm');
  const mon = localStorage.getItem('month');

  const queryParams = new URLSearchParams({
    fname,
    cong_code: congCode,
    farm_code: farmCode,
    mon,
    arg1: cowid,
    arg2: pari,
  });

  const url = `${process.env.PUBLIC_URL}/crownix.html?${queryParams}`;
  window.open(url, '_blank', 'noopener, noreferrer');
}

export function openCrownix3(Fname, jcode) {
  const fname = Fname;
  const congCode = localStorage.getItem('cong');
  const farmCode = localStorage.getItem('farm');
  const mon = localStorage.getItem('month');

  const queryParams = new URLSearchParams({
    fname,
    cong_code: congCode,
    farm_code: farmCode,
    mon,
    arg1: jcode,
  });

  const url = `${process.env.PUBLIC_URL}/crownix.html?${queryParams}`;
  window.open(url, '_blank', 'noopener, noreferrer');
}

export function openCrownix4(Fname, frpari, topari, frcumday, tocumday) {
  const fname = Fname;
  const congCode = localStorage.getItem('cong');
  const farmCode = localStorage.getItem('farm');
  const mon = localStorage.getItem('month');

  const queryParams = new URLSearchParams({
    fname,
    cong_code: congCode,
    farm_code: farmCode,
    mon,
    frpari,
    topari,
    frcumday,
    tocumday,
  });

  const url = `${process.env.PUBLIC_URL}/crownix.html?${queryParams}`;
  window.open(url, '_blank', 'noopener, noreferrer');
}

export function openCrownix5(Fname, jcode) {
  const fname = Fname;
  const congCode = localStorage.getItem('cong');
  const farmCode = localStorage.getItem('farm');

  const queryParams = new URLSearchParams({
    fname,
    cong_code: congCode,
    farm_code: farmCode,
    arg1: jcode,
  });

  const url = `${process.env.PUBLIC_URL}/crownix.html?${queryParams}`;
  window.open(url, '_blank', 'noopener, noreferrer');
}

export function openCrownix6(Fname, farmid) {
  const fname = Fname;
  const congCode = localStorage.getItem('cong');
  const farmCode = localStorage.getItem('farm');
  const mon = localStorage.getItem('month');

  const queryParams = new URLSearchParams({
    fname,
    cong_code: congCode,
    farm_code: farmCode,
    mon,
    farmid,
  });

  const url = `${process.env.PUBLIC_URL}/crownix.html?${queryParams}`;
  window.open(url, '_blank', 'noopener, noreferrer');
}

export default openCrownix;
