import React from 'react';

import Pic566 from 'assets/images/sub/_images/pic566.jpg';
import Pic567 from 'assets/images/sub/_images/pic567.jpg';

function Do105() {

  return (
    <div className="techinfoview">
				<h1>일사병과 열사병</h1>
				<p className="pstyle"></p>
				<div className="leftpart">
					<h2>원인</h2>
					<ul>
						<li>고온다습한 하절기에 환기불량한 축사내에서 사육될 때와 장거리 수송시의 스트레스 </li>
						<li>장시간에 걸쳐 직사광선에 노출될 때 </li>
						<li>바람한 점 없는 땡볕 아래서의 방목시 </li>
					</ul>
					<h2>증상</h2>
					<ul>
						<li>초기에는 뜨거운 숨을 몰아쉬며 입을 열고 혀를 내놓고 있다. </li>
						<li>호흡수는 1분간에 40∼90회 정도로 높으며 호흡소리가 크다. </li>
						<li> 체온은 40℃이상 상승하며 맥박수도 서서히 증가하여 1분간 100회 이상을 넘는 수가 있다. </li>
						<li> 증세가 더욱 심해지면 위 운동이 정지되고 설사변을 배설한다. </li>
						<li>호흡장해와 순환장해를 일으킨며 기립불능에 빠져 폐사한다. </li>
					</ul>
					<h2>목장에서의 처치</h2>
					<ul>
						<li>발견 초기에 서늘한 곳으로 옮겨 냉수로 관장을 실시한다(제 II장 간호와 응급처치 관장법 참고) </li>
						<li>몸체를 식힐 때는 심장마비에 주의하여 다리서부터 서서히 냉수로 끼얹어 식힌다. </li>
						<li>증상이 심할 때는 강심제와 7%중찬산나트륨과 5%포도당 액을 정맥주사한다. </li>
						<li>한 여름에는 운동장에 그늘막을 설치한다.</li>
					</ul>
				</div>
				<div className="rightpart">
					<img src={Pic566} className="nonborder" alt=""/>
					<img src={Pic567} className="nonborder" alt=""/>
				</div>
			</div>
  );
}

export default Do105;