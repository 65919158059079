import React from 'react';

function Do119() {

  return (
    <div className="techinfoview">
      <h1>브루셀라 병</h1>
      <p className="pstyle">브루셀라 병균의 감염으로 발병하여 전염성 유산과 불임증을 일으킨다. 사람에게도 감염되어 발병하는 인수(人獸)공통 전염병이다. </p>
      <div className="leftpart">
        <h2>원인</h2>
        <ul>
          <li>감염경로 
            <ul>
              <li>보균우와의 접촉(교배 등) </li>
              <li>유산한 태아. 태반 및 자궁배설물을 건강한 소가 핥아 먹었을 때 </li>
              <li>감염우의 젖을 먹거나 오염된 사료를 먹었을 때 </li>
              <li>피부의 상처, 결막, 비점막등을 통해 균이 침입할 때 </li>
            </ul>
          </li>
        </ul>
        <h2>증상</h2>
        <ul>
          <li>임신 7∼8개월에 이르러 유산을 한다. 이때 양수(羊水)는 전체적으로 혼탁하다. </li>
          <li>대개 후산이 정체되고 만성 자궁염을 앓게 되어 불임우가 된다.</li>
          <li>수소의 경우는 고환염을 일으킨다. </li>					
        </ul>
        <h2>목장에서의 처치</h2>
        <ul>
          <li>현재 우리 나라에서는 가축 보건소에서 부루셀라 검색을 실시하고 있어 브루셀라 양성(陽性)우는 도살처분하도록 되어 있다.</li>
        </ul>
      </div>
      <div className="rightpart">
      </div>
    </div>
  );
}

export default Do119;