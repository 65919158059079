import React from 'react';

import d1by55 from 'assets/images/sub/_images/1-55.gif';
import d1by57 from 'assets/images/sub/_images/1-57.jpg';
import d1by59 from 'assets/images/sub/_images/1-59.jpg';

function Fo23() {

  return (
    <div className="techinfo_view">
				<p className="viewTitle">수정기의 관리</p>
				<h2>발정은 월령보다 발육으로 일어난다.</h2>
				<ul className="listStyle04 mg15f">
					<li>고영양사양에 의해 발정은 빨리 온다.</li>
					<li>저영양사양에 의해 발정은 늦게 온다.</li>
					<li>일정 체중이 되면 발정이 온다.</li>
					<li>발정은 월령보다 발육에 의해 일어난다.</li>
					<li>저영양 사양은 발정도 늦어지고 초산때의 frame size(체격 크기)가 부족하여, 난산이 되기 쉽다.</li>
				</ul>
				<div className="tableWrap">
					<table className="tablestyle">
						<caption>표. 초발정발현의 일령과 체길이 값</caption>
						<thead>
							<tr>
								<th className="first" rowSpan="2">구별</th>
								<th rowSpan="2">18개월령까지의 일증체량</th>
								<th rowSpan="2">두수</th>
								<th colSpan="3">일 령(일)</th>
								<th colSpan="3">체 중(kg)</th>
								<th colSpan="3">체 고(cm)</th>
							</tr>
							<tr>
								<th>평균</th>
								<th>표준편차</th>
								<th>범위</th>
								<th>평균</th>
								<th>표준편차</th>
								<th>범위</th>
								<th>평균</th>
								<th>표준편차</th>
								<th>범위</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>고영양</td>
								<td>0.73∼0.89</td>
								<td>14</td>
								<td>250.6</td>
								<td>30.8</td>							
								<td>203∼301</td>
								<td>250.7</td>
								<td>39.1</td>
								<td>204∼312</td>
								<td>107.3</td>
								<td>6.5</td>
								<td>96∼117</td>
							</tr>
							<tr>
								<td>표준</td>
								<td>0.53∼0.67</td>
								<td>10</td>
								<td>343.7</td>
								<td>44.1</td>							
								<td>259~385</td>
								<td>270.4</td>
								<td>29.0</td>
								<td>223~315</td>
								<td>112.5</td>
								<td>2.6</td>
								<td>109~116</td>
							</tr>
							<tr>
								<td>저영양</td>
								<td>0.27~0.39</td>
								<td>5</td>
								<td>504</td>
								<td>60.9</td>							
								<td>413~560</td>
								<td>241.6</td>
								<td>24.6</td>
								<td>204~269</td>
								<td>113.2</td>
								<td>1.3</td>
								<td>112~115</td>
							</tr>
						</tbody>
					</table>
				</div>
				<ul className="refer mg60f" style={{marginTop: '-20px'}}>
					<li>* 560일령이 되어도 발정하지 않은 2두를 포함한다. (SORENSEN 1559) (북해도의 낙농기술) </li>
				</ul>
				
				<h2>발육이 좋은 소는 수정을 늦추지 않는다.! </h2>
				<ul className="listStyle04 mg15f">
					<li>발육이 좋은 육성우를 고의로 수정을 늦게 하면 수태하기가 어려워진다.</li>
					<li>발정을 정확하게 발현한다.</li>
					<li>발정우를 확실히 꽉 붙잡는다.</li>
					<li>수정의 목표
						<ul>
							<li>체고 : 127Cm 이상이 되면</li>
							<li>체중 : 350kg 이상이 되면 </li>
							<li>육성목장에 예탁하는 소는 400kg이 되면</li>
						</ul>
					</li>
				</ul>
				<div className="imgBox mg60f"><img src={d1by55} alt=""/></div>
				
				<h2>발정이 오지 않을 때는?</h2>
				<ul className="listStyle04 mg15f">
					<li>영양부족으로 인해 난소기능이 활발하지 않은가?</li>
					<li>과비만우는 영양과다로 인해 난소 농종이 되어 있지 않은가?</li>
					<li>발밑이 미끄러지기 쉽게 되어있지 않은가? 땅이 질퍽하여 stress를 받고 있지 않은가? (승가행동을 억제한다)</li>
					<li>과밀사양이 되어 있지 않은가?</li>
					<li>발정을 못보고 빠뜨리고 있지는 않은가?</li>
				</ul>
				<table className="tablestyle">
					<caption>표. 초발정발현의 일령과 체길이 값</caption>
					<thead>
						<tr>
							<th className="first">유우의 상태</th>
							<th>종부 회수</th>
							<th>임신 회수</th>
							<th>수태율(%)</th>
							<th>수태당 종부 회수</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>체중 감소</td>
							<td>554</td>
							<td>234</td>
							<td>44</td>
							<td>2.23</td>
						</tr>
						<tr>
							<td>체중 증가</td>
							<td>1,368</td>
							<td>911</td>
							<td>67</td>
							<td>1.50</td>
						</tr>
					</tbody>
				</table>
				<ul className="refer mg10t mg30f">
					<li>HOLLON and BRANTON. 1971. J. Dairy Sci. 54787 (CNFTKSDUSRN 36-8) </li>
				</ul>
				<div className="imgBox mg60f"><img src={d1by57} alt="" title="질척거리거나, 미끄러운 운동장에서는 스트레스(stress)가 높아져 발정에 영향을 준다."/></div>
				
				<h2>육성우에 육용종을 교배하는 것은 이익인가?</h2>
				<ul className="listStyle04 mg15f">
					<li>초산우의 난상을 경감하기 위해서 육용종을 교배하여 F 1 생산을 하는 것은 경영에 있어서 플러스가 되는가 검토가 필요
						<ul>
							<li>a) 유용종의 개량면에서부터 : 초산의 자우는 유전형질이 높은 경향이 있어 개량면으로부터 생각하면 1산을 쓸데없게 된다. 육성중 영양관리에 의해 난산은 경감할 수 있다.</li>
							<li>b) 육용소우의 관리면에서부터 : F1은 강하므로 관리하기 쉽다.</li>
						</ul>
					</li>
					<li>F1의 고기의 유리성(有利性)은 아직 불명확하여 수정란의 이식 방법이 육생산으로부터는 유리(有利性)이 있다.</li>
				</ul>
				<div className="imgBox"><img src={d1by59} alt=""/></div>
			</div>
  );
}

export default Fo23;
