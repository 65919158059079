import React, { useRef } from 'react';

// scss
import './monthCode.scss';

function MonthCode({
  props,
  setProps,
  setErrorMessage,
  openModal
}) {

  const monthCode = useRef(null);

  const validateMonth = (month) => {
    const monthRegex = /^\d{4}(0[1-9]|1[012])$/; // 검정월 입력 정규표현식

    if (month.length !== 6) {
      setErrorMessage('6자리 숫자를 입력해주세요.');
      return false;
    }

    if (!monthRegex.test(month)) {
      setErrorMessage('검정월에는 년도와 월이 입력되어야 합니다.');
      return false;
    }

    return true;
  };

  const setMonth = () => {
    const month = monthCode.current.value;

    if (!validateMonth(month)) {
      openModal();
      return;
    }

    setProps(prev => {
      const newMonth = {...prev , date: month};
      
      return newMonth;
    });
    
    localStorage.setItem('month', month);
  };

  return (
    <div className='monthCode'>
      <span>검정월</span>
      <input 
        type='text'
        maxLength='6'
        ref={monthCode}
        defaultValue={props.date}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            setMonth();
          }
        }}
      />
      <button 
        type='button'
        onClick={setMonth}
      >
        검색
      </button>
    </div>
  );
}

export default MonthCode;
