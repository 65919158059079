import React from 'react';
import RelatedSites from 'containers/web/data/relatedSites/RelatedSites';
import { Helmet } from 'react-helmet-async';

function RelatedSitesPage() {
  return (
    <>
      <Helmet>
        <title>관련사이트</title>
      </Helmet>
      <RelatedSites />
    </>
  );
}

export default RelatedSitesPage;
