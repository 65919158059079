import React from 'react';
import Email from 'containers/web/guide/email/Email';
import { Helmet } from 'react-helmet-async';

function EmailPage() {
  return (
    <>
      <Helmet>
        <title>이메일 무단수집거부</title>
      </Helmet>
      <Email />
    </>
  );
}

export default EmailPage;
