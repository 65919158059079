import React from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

// api
import { getState, postState } from 'utils/api';

function SlvCodeView() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const mCode = searchParams.get('m_code');
  const sCode = searchParams.get('s_code');

  const { data } = useQuery({
    queryKey: ['slvCode', mCode, sCode],
    queryFn: () =>
      getState('/board/select_slave_code', { m_code: mCode, s_code: sCode }),
    enabled: !!mCode && !!sCode,
  });

  const slvCode = data?.result;
  const registerFlag = !sCode ? '등록' : '수정';

  const handleDelete = async () => {
    if (window.confirm('부코드를 삭제 하시겠습니까?')) {
      try {
        const response = await postState('/board/delete_slave_code/', {
          m_code: mCode,
          s_code: sCode,
        });
        if (response?.return_code === 'SUCCESS') {
          alert('삭제되었습니다.');
          navigate(`/bo/code/slvCodeList?m_code=${mCode}`);
        }
        if (response?.return_code === 'ERROR') {
          alert(response?.return_message);
        }
      } catch (error) {
        console.error('Delete slv code error:', error);
        alert('부코드 삭제 중 오류가 발생했습니다.');
      }
    }
  };

  return (
    <div className="slv-code-view bo-content">
      <h2>부코드 정보</h2>
      <div className="table-container bold-border">
        <table>
          <tbody>
            <tr>
              <th width="20%">주 코드번호</th>
              <td width="80%">{slvCode?.m_code}</td>
            </tr>
            <tr>
              <th>부 코드번호</th>
              <td>{slvCode?.s_code}</td>
            </tr>
            <tr>
              <th>부 코드이름</th>
              <td>{slvCode?.s_name}</td>
            </tr>
            <tr>
              <th>부 코드설명</th>
              <td>{slvCode?.s_desc}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="button-group">
        <Link
          to={`/bo/code/slvCodeEdit?m_code=${mCode}&s_code=${sCode}`}
          className="btn-l"
        >
          부코드 {registerFlag}
        </Link>
        <button type="button" onClick={handleDelete} className="btn-l">
          부코드 삭제
        </button>
        <button
          type="button"
          onClick={() => navigate(`/bo/code/slvCodeList?m_code=${mCode}`)}
          className="btn-l"
        >
          부코드 목록
        </button>
      </div>
    </div>
  );
}

export default SlvCodeView;
