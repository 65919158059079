import React, { useState , useEffect } from 'react';
import {
  ResponsiveContainer,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
  Label
} from 'recharts';

// api
import { postState } from 'utils/api';
import { openCrownix } from 'utils/openCrownix';

// 이미지
import PrintPDF from 'assets/svgs/common/print_PDF.svg';
import Logo from 'assets/images/common/header_logo_pc.png';

// 컴포넌트
import ErrorMessage from 'components/errorMessage/errorMessage';

// scss
import './NewNoninschkview.scss';

function NewNoninschkview() {

  // 에러처리
  const [isModalOpen, setIsModalOpen] = useState(false); // 모달 추가
  const [errorMessage, setErrorMessage] = useState(''); 

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => {
    setIsModalOpen(false);
    setErrorMessage(''); // 모달이 닫힐 때 에러메세지 초기화
  };

  // 데이터 불러오기
  const baseDir='/dcitest/dciexpert/newnoninschkview/';
  const [ mainData , setMainData ] = useState();
  const [ props , setProps] = useState({
    id:`${localStorage.getItem('cong')}${localStorage.getItem('farm')}`,
    passwd:localStorage.getItem('passwd'),
    date:localStorage.getItem('month')
  });

  useEffect(() => {

    postState(baseDir,props)
    .then(getdata => {      
      if (getdata !== undefined) {
        setMainData(getdata);
        // console.log(getdata);
      }
    })
    .catch(error => {          
      setErrorMessage(error.message);
      openModal();
    });
    
  }, [props]);

  
  return (
    <div className='pdfMainArea'>
      <ErrorMessage isOpen={isModalOpen} content={errorMessage} closeModal={closeModal}/>
      <div className='pdfMainHeader'>
        <button 
          type='button'
          onClick={()=> openCrownix('New_noninschkview.mrd')}
        >
          <img src={PrintPDF} alt='PDF로 인쇄하기' />
          인쇄하기
        </button>
      </div>
      <div className='pdfArea'>
        <div className='newNoninschkviewPdf'>
          <h1>미수태우에 대한 검정성적</h1>           
          
          <table className='pdfTable'>
            <thead>
              <tr>
                <th rowSpan="2">구분</th>
                <th>산차</th>
                <th colSpan="3">1산차</th>
                <th colSpan="3">2산차</th>
                <th colSpan="3">3산차 이상</th>
                <th colSpan="3">합계</th>
              </tr>
              <tr>                
                <th>비유일수</th>
                <th>0~90일</th>
                <th>91~180일</th>
                <th>180일 이상</th>
                <th>0~90일</th>
                <th>91~180일</th>
                <th>180일 이상</th>
                <th>0~90일</th>
                <th>91~180일</th>
                <th>180일 이상</th>
                <th>0~90일</th>
                <th>91~180일</th>
                <th>180일 이상</th>
              </tr>
            </thead>
            <tbody>
              {
                mainData &&
                mainData.plot_data.map((item,index) => (                  
                  <tr>
                    {index%2===0 && <td rowSpan="2">감정</td>}
                    <td>{index%2===0 ? '두수' : '%'}</td>
                    <td>{index%2===0 ? item.a90 : item.a90.toFixed(1)}</td>
                    <td>{index%2===0 ? item.a180 : item.a180.toFixed(1)}</td>
                    <td>{index%2===0 ? item.a181 : item.a181.toFixed(1)}</td>                    
                    <td>{index%2===0 ? item.b90 : item.b90.toFixed(1)}</td>
                    <td>{index%2===0 ? item.b180 : item.b180.toFixed(1)}</td>
                    <td>{index%2===0 ? item.b181 : item.b181.toFixed(1)}</td>      
                    <td>{index%2===0 ? item.c90 : item.c90.toFixed(1)}</td>
                    <td>{index%2===0 ? item.c180 : item.c180.toFixed(1)}</td>
                    <td>{index%2===0 ? item.c181 : item.c181.toFixed(1)}</td>      
                    <td>{index%2===0 ? item.t1 : item.t1.toFixed(1)}</td>
                    <td>{index%2===0 ? item.t2 : item.t2.toFixed(1)}</td>
                    <td>{index%2===0 ? item.t3 : item.t3.toFixed(1)}</td>      
                  </tr>                                  
                ))
              }
            </tbody>
          </table>    
          {
            mainData && (
            <div className='barChartArea' >
              <ResponsiveContainer width="100%" height={500}>
                <BarChart
                  width="90%"
                  height="80%"
                  data={mainData.plot_data2}
                  margin={{              
                    top: 20,
                    right: 20,
                    bottom: 20,
                    left: 20,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="cumday">
                    <Label value="비 유 일 수" offset={-15} position="insideBottom" />
                  </XAxis>
                  <YAxis />
                  <Tooltip />
                  <Bar dataKey="gamj_1" fill="violet" name='감정1산' />
                  <Bar dataKey="gamj_2" fill="red" name='감정2산' />
                  <Bar dataKey="gamj_3" fill="green" name='감정3산'/>
                  <Bar dataKey="suj_1" fill="yellow" name='수정1산'/>
                  <Bar dataKey="suj_2" fill="blue" name='수정2산'/>
                  <Bar dataKey="suj_3" fill="gray" name='수정3산'/>
                  <Bar dataKey="misuj_1" fill="purple" name='미수정1산'/>
                  <Bar dataKey="misuj_2" fill="cyan" name='미수정2산'/>
                  <Bar dataKey="misuj_3" fill="darkblue" name='미수정3산'/>
                </BarChart>
              </ResponsiveContainer>   
              <ul className='noninschkLabel'>
                <li>감정 1산</li>
                <li>감정 2산</li>
                <li>감정 3산</li>
                <li>수정 1산</li>
                <li>수정 2산</li>
                <li>수정 3산</li>
                <li>미수정 1산</li>
                <li>미수정 2산</li>
                <li>미수정 3산</li>
              </ul>        
            </div> )
          }
          <p className="exp">
            ※참고사항<br/>
            ▶ 비유일수 70일 이하에서 유지율 3.3 및 유단백 2.9 이하인 개체에 대하여 급여수준 및 분만 후 사료 섭취량 증가 프로그램 수정 필요<br/>
            ▶ 비유일수 221일 이후 유지율 4.5 및 유단백 3.5 이상의 경우 과비 대상우 판정으로 급여프로그램 특히 TMR의 급여 수준 점검 필요<br/>
            ▶ CV 수준이 10% 이상일 경우 산차별, 개체별 관리 불량으로 개체 점검 및 급여량 차등화 필요            
          </p>
        
          <img src={Logo} alt='농협경제지주 젖소개량사업소 로고' className='pdfLogo'/>
        </div>
      </div>
    </div>    
    
  );
};

export default NewNoninschkview;
