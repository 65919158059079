import React from 'react';
import Data from 'containers/web/data/Data';

function DataPage() {
  return (
    <Data />
  );
}

export default DataPage;
