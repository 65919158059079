import React, { useState } from 'react';

// 컴포넌트
import SubTitle from 'components/subTitle/SubTitle';
import PublicationReport from './PublicationReport';

// scss
import './publication.scss';

function Publication() {
  const [currentTab, setCurrentTab] = useState('report');

  return (
    <div className="publication subCon">
      <SubTitle />
      <ul className="publicationTab">
        <li>
          <button
            type="button"
            onClick={() => setCurrentTab('report')}
            className={currentTab === 'report' ? 'current' : ''}
          >
            젖소검정사업보고서
          </button>
        </li>
      </ul>
      {currentTab === 'report' && <PublicationReport />}
    </div>
  );
}

export default Publication;
