import React, { useState , useEffect } from 'react';
import {
  ResponsiveContainer,
  ReferenceLine,
  ScatterChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Scatter,
  Cell
} from 'recharts';

// api
import { postState } from 'utils/api';
import { openCrownix2,openCrownix6 } from 'utils/openCrownix';

// 이미지
import PrintPDF from 'assets/svgs/common/print_PDF.svg';

// 컴포넌트
import ErrorMessage from 'components/errorMessage/errorMessage';
import MonthCode from 'components/dcitest/monthCode/MonthCode';

// scss
import './NewGraphFatDprot.scss';

function NewGraphFatDprot() {

  // 에러처리
  const [isModalOpen, setIsModalOpen] = useState(false); // 모달 추가
  const [errorMessage, setErrorMessage] = useState(''); 

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => {
    setIsModalOpen(false);
    setErrorMessage(''); // 모달이 닫힐 때 에러메세지 초기화
  };

  // 데이터 불러오기
  const baseDir='/chart/new_graph_fatdprot/';
  const [ mainData , setMainData ] = useState();


  const [ props , setProps] = useState({
    id:`${localStorage.getItem('cong')}${localStorage.getItem('farm')}`,
    passwd:localStorage.getItem('passwd'),
    date:localStorage.getItem('month')
  });

  useEffect(() => {

    postState(baseDir,props)
    .then(getdata => {      
      if (getdata !== undefined) {
      setMainData(getdata);
      }
    })
    .catch(error => {           
      setErrorMessage(error.message);
      openModal();
    });
    
  }, [props]);

  // 차트
  const COLORS = ['blue','red','purple'];
  const classifyNumber = (y) => {
    if ( y < 1.05) return 1;
    if ( y >= 1.35 ) return 2;
    return 0;
  };

  const CustomClick = (e) => {
    openCrownix2('New_ch_cow1.mrd',e.cowid , e.pari);
    // alert(e.sname2);
    };
  return (
    
    <div className='pdfMainArea'>
      <ErrorMessage isOpen={isModalOpen} content={errorMessage} closeModal={closeModal}/>
      <div className='pdfMainHeader'>
        <button 
          type='button'
          onClick={()=> openCrownix6('New_graph_fatDprot.mrd')}
        >
          <img src={PrintPDF} alt='PDF로 인쇄하기' />
          인쇄하기
        </button>
        {
          props && 
          <MonthCode 
            props={props}
            setProps={setProps}
            setErrorMessage={setErrorMessage}
            openModal={openModal}
          />
        }
      </div>
      <div className='pdfArea'>
        <div className='newGraphFatDprot'>
        <span>{props.date.substring(0,4)}년 {props.date.slice(-2)}월 그래프</span>
          <h1>유지방 : 유단백</h1>
          <div className='dotChartArea' >
            <ResponsiveContainer width="100%" height={400}>
              <ScatterChart
                width="90%"
                height="85%"
                margin={{              
                  top: 20,
                  right: 20,
                  bottom: 20,
                  left: 20,
                  }}>
                <CartesianGrid />
                <XAxis
                  dataKey="day"
                  type='number'
                  label={{ value: '비 유 일 수 [일]', position: 'bottom' }}
                />
                <YAxis
                  dataKey="value"
                  type='number'
                  label={{ value: '유지율 : 유단백', position: 'insideLeft', offset: '-10', angle: -90 }}
                  domain={[dataMin => Math.floor(dataMin*10)/10, dataMax => Math.ceil(dataMax*10)/10]}
                />
                <Tooltip cursor={{ strokeDasharray: '3 3' }} />
                <Scatter data={mainData && mainData.plot_data} fill="green" onClick={CustomClick}>
                  {
                    mainData &&
                    mainData.plot_data.map((entry) => (
                      <Cell key={`cell-${entry.day+entry.value+entry.z}`} fill={COLORS[classifyNumber(entry.value)]}/>
                    ))
                  }
                </Scatter>
                <ReferenceLine x={70} stroke="green" />
                <ReferenceLine x={150} stroke="green" />
                <ReferenceLine y={1.35} stroke="red" />     
                <ReferenceLine y={1.05} stroke="red" />     
              </ScatterChart>
            </ResponsiveContainer>
            <ul className='newGraphFatDprotLabel'>
              <li>정상우</li>
              <li>섬유소부족 및 농후사료과다</li>
              <li>농후사료부족 및 에너지부족</li>
            </ul>
          </div>
          <table className='pdfTable'>
            <thead>
              <tr>
                <th>유지방 : 유단백 비율</th>
                <th>사양관리 및 개선 방향</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1.05미만</td>
                <td>섬유소 부족 및 농후사료 과다</td>
              </tr>
              <tr>
                <td>1.05 ~ 1.35</td>
                <td>발정과 수정상태의 양호수치</td>
              </tr>
              <tr>
                <td>1.05미만</td>
                <td>농후사료 부족 및 에너지부족</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      
    </div> 
  );
}

export default NewGraphFatDprot;
