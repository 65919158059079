import React from 'react';

import pic597 from 'assets/images/sub/_images/pic597.jpg';
import pic598 from 'assets/images/sub/_images/pic598.jpg';
import pic599 from 'assets/images/sub/_images/pic599.jpg';
import pic600 from 'assets/images/sub/_images/pic600.jpg';
import pic601 from 'assets/images/sub/_images/pic601.jpg';

function Do114() {

  return (
    <div className="techinfoview">
      <h1>산욕열</h1>
      <p className="pstyle">분만시에 산도(産道)에 생긴 상처를 통해 병원성이 강한 세균(환오간균등)이 혈류로 들어가 전신 증상을 일으키는 일이 있다. 이것을 일컬어 산욕열이라고 한다. </p>
      <div className="leftpart">
        <h2>원인</h2>
        <ul>
          <li>분만시 불결한 위생관리에 의해 세균이 체내 침투하여 증식 </li>
          <li>분만시 특히 난산으로 인해 산도(産道)가 상처를 입었을 때 </li>
          <li>침입하는 세균은 대장균, 화농간균, 녹농균, 용혈성 포도구균 등이다. </li>
        </ul>
        <h2>증상</h2>
        <ul>
          <li>식욕, 반추, 비유가 중지 또는 감소한다. </li>
          <li>맥박이 빠르나(80∼120회/1분)약하다. </li>
          <li>체온은 초기에는 상승하지만 증상이 진행되면 평열(平熱)내지는 그 이하로 떨어진다. </li>
          <li>정상이하의 체온은 통상 사망직전에 볼 수 있다. </li>
          <li>자세가 불안전하고 전신을 떤다 </li>
          <li>설사 또는 변비가 반복된다.</li>
        </ul>
        <h2>목장에서의 처치</h2>
        <ul>
          <li>정상분만시에도 분만후 1주일은 매일 체온을 측정하여 체온상승을 경계한다.  </li>
          <li>통상 경과는 2∼3일 계속되며 그 사이 회복 또는 죽음에 이른다.(1∼2주일 이상 계속되는 일도 있다.) </li>
          <li>자궁의 맛사지와 세척은 유효한 방법이긴 하나 자칫 증상을 악화시켜 폐혈증을 일으킬 우려가 있으므로 신중히 실시해야 한다. </li>
          <li> 테라마이신 등 광범위 항생제 등의 자궁내 주입은 전신감염 방지에 유효하다.</li>
        </ul>
      </div>
      <div className="rightpart">
        <img src={pic597} className="nonborder" alt=""/>
        <img src={pic598} className="nonborder" alt=""/>
        <img src={pic599} className="nonborder" alt=""/>
        <img src={pic600} className="nonborder" alt=""/>
        <img src={pic601} className="nonborder" alt=""/>
      </div>
    </div>
  );
}

export default Do114;