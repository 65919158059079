import React, { useState , useEffect } from 'react';
import {
  ResponsiveContainer,
  ReferenceLine,
  ScatterChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Scatter,
  Cell
} from 'recharts';

// api
import { postState } from 'utils/api';
import { openCrownix2,openCrownix6 } from 'utils/openCrownix';

// 이미지
import PrintPDF from 'assets/svgs/common/print_PDF.svg';

// 컴포넌트
import ErrorMessage from 'components/errorMessage/errorMessage';
import MonthCode from 'components/dcitest/monthCode/MonthCode';

// scss
import './NewGraphFat01.scss';

function NewGraphFat01() {

  // 에러처리
  const [isModalOpen, setIsModalOpen] = useState(false); // 모달 추가
  const [errorMessage, setErrorMessage] = useState(''); 

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => {
    setIsModalOpen(false);
    setErrorMessage(''); // 모달이 닫힐 때 에러메세지 초기화
  };

  // 데이터 불러오기
  const baseDir='/chart/new_graph_fat/';
  const [ mainData , setMainData ] = useState();

  const [ props , setProps] = useState({
    id:`${localStorage.getItem('cong')}${localStorage.getItem('farm')}`,
    passwd:localStorage.getItem('passwd'),
    date:localStorage.getItem('month')
  });

  useEffect(() => {

    postState(baseDir,props)
    .then(getdata => {      
      if (getdata !== undefined) {
      setMainData(getdata);
      }
    })
    .catch(error => {           
      setErrorMessage(error.message);
      openModal();
    });
    
  }, [props]);

  // 차트
  const COLORS = ['blue','purple','red'];
  const classifyNumber = (x, y) => {
    if ( y < 3.4 ) return 1;
    if (x < 70 && y >= 5.5) return 2;
    return 0;
  };

  const CustomClick = (e) => {
    openCrownix2('New_ch_cow1.mrd',e.cowid , e.pari);
    // alert(e.sname2);
    };

  return (
    <div className='pdfMainArea'>
      <ErrorMessage isOpen={isModalOpen} content={errorMessage} closeModal={closeModal}/>
      <div className='pdfMainHeader'>
        <button 
          type='button'
          onClick={()=> openCrownix6('New_graph_fat_01.mrd')}
        >
          <img src={PrintPDF} alt='PDF로 인쇄하기' />
          인쇄하기
        </button>
        {
          props && 
          <MonthCode 
            props={props}
            setProps={setProps}
            setErrorMessage={setErrorMessage}
            openModal={openModal}
          />
        }
      </div>
      <div className='pdfArea'>
        <div className='newGraphFat01'>
          <div className='graphHead'>
            <span>{props.date.substring(0,4)}년 {props.date.slice(-2)}월 그래프</span>
            <button
              type='button'
              onClick={() => openCrownix6('New_graph_fat_4m.mrd')}
            >
              3개월보기
            </button>
          </div>
          <h1>비유일수별 유지방 분포도</h1>
          <div className='dotChartArea' >
            <ResponsiveContainer width="100%" height={400}>
              <ScatterChart
                width="90%"
                height="85%"
                margin={{              
                  top: 20,
                  right: 20,
                  bottom: 20,
                  left: 20,
                  }}>
                <CartesianGrid strokeDasharray="3 3" />
                
                <XAxis
                  dataKey="day"
                  type='number'
                  label={{ value: '비 유 일 수 [일]', position: 'bottom' }}
                />
                <YAxis
                  dataKey="fatp"
                  type='number'
                  label={{ value: '유지방 (%)', position: 'insideLeft', offset: '-10', angle: -90 }}
                  domain={[dataMin => Math.floor(dataMin*2)/2, dataMax => Math.ceil(dataMax*2)/2]}
                />
                <Tooltip cursor={{ strokeDasharray: '3 3' }} />
                <Scatter data={mainData && mainData.plot_data} fill="green" onClick={CustomClick}>
                  {
                    mainData &&
                    mainData.plot_data.map((entry) => (
                      <Cell key={`cell-${entry.day+entry.fatp+entry.z}`} fill={COLORS[classifyNumber(entry.day , entry.fatp)]}/>
                    ))
                  }
                </Scatter>
                <ReferenceLine x={70} stroke="green" />
                <ReferenceLine x={150} stroke="green" />
                <ReferenceLine y={3.4} stroke="red" />
              </ScatterChart>
            </ResponsiveContainer>
            <ul className='fatLabel'>
              <li>정상우</li>
              <li>케토시스, 전위위험대상우</li>
              <li>에너지과잉, 과산증위험</li>
            </ul>
          </div>
          <table className='pdfTable'>
            <thead>
              <tr>
                <th>구분</th>
                <th>사양관리 및 개선 방향</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>비유일수 70미만, 유지방 5.5이상</td>
                <td>케토시스, 전위 위험 대상우 &#40; 에너지부족 - 체지방분해 &#41;</td>
              </tr>
              <tr>
                <td>유지방 3.4 미만</td>
                <td>에너지 &#40;곡류&#41; 과잉, 괴산증 위험</td>
              </tr>
            </tbody>
          </table>  
        </div>
      </div>
      
    </div>   
  );
}

export default NewGraphFat01;
