import React from 'react';

function Do175() {

  return (
    <div className="techinfoview">
      <h1>조치조성 및 관리이용 기술대책</h1>
      <h2>초지조성의 성공요건</h2>
      <ul>
        <li>대상지 선정
          <p className="pstyle">알맞은 초지조성 대상지는 토심이 깊고 너무 척박하지 않으며 암석이나 자갈이 적은 곳일수록 좋다. 특히 목초는 무더위에 약하므로 남향보다는 북향이 유리하며 서향이나 동향도 남향에 비하여 초지조성 대상지로서 적합하다. 남향경사지에 초지조성을 할 경우에는 가급적 그늘이 될만한 나무들을 적당히 남겨 두어 강렬한 햇볕으로부터 목초를 차광보호하며 수분증발을 억제시키는 것이 바람직하다.<br/><br/>다음과 같은 곳은 특히 초지조성에 부적당하므로 피하는 것이 좋다.</p>
          <ul>
            <li><b>지하수가 높아 습하거나 배수가 잘 안되는 곳</b> : 오차드그라스는 특히 습한 곳에 견디는 힘이 약하므로 반드시 배수를 해 주어야 한다.</li>
            <li><b>경사가 너무 급한 곳</b> : 초지를 성공적으로 조성했다 해도 관리와 이용이 어려워 계속 유지하기가 곤란하다.</li>
            <li><b>양지바르고 메마른 곳</b> : 이러한 곳은 목초의 하고현상이 더욱 심하게 나타나며 잡초의 피해가 크다.</li>
          </ul>
        </li>
        <li>초지조성 방법
          <ul>
            <li>겉뿌림법의 장단점
              <p>겉뿌림 초지조성 방법은 개간을 하지 않고 장애물만을 제거한 다음 땅표면에 종자를 뿌려서 조성하는 방법으로서 다음과 같은 이점이 있다.</p>
              <ul>
                <li>나무뿌리를 캐어내거나 개간을 하지 않아도 되므로 품삯이 적데 들고. </li>
                <li>경사가 심하거나 장애물이 많은 곳에서도 성공적으로 초지를 조성할 수 있으며, </li>
                <li>사태가 날 위험이 저고, </li>
                <li>지면이 단단해서 반드시 다져주기를 할 필요가 없고 초지조성후 방목을 시켜도 발굽에 의하여 초지가 망가질 염려가 별로 없다.</li>
              </ul>
              <p>그러나 다음과 같은 단점도 있다.</p>
              <ul>
                <li>땅표면에 직접 파종하므로 날씨가 가물때에는 종자가 싹트기 전후해서 말라죽을 염려가 있고, </li>
                <li>종자가 흙에 덮이지 않기 때문에 싹트는 기간이 길고 새나 개미등에 의한 피해를 받기 쉽고, </li>
                <li>초지조성후 관리가 소홀하면 야초나 나무들이 재상하여 목초가 억압되기 쉽다.</li>
              </ul>
            </li>
            <li>겉뿌림법의 성공요건
              <ul>
                <li>토양산도의 교정
                  <p>우리나라의 산지토영은 대부분이 산성토양이므로 목초의 생육에 부적합하다. 따라서 초지조성전에 반드시 토양검정을 실시하고 석회 소요량을 사용하여 토양산도를 교정해 주어야 한다. 토양검정은 해당시군 농촌지도소에 의뢰하면 된다.</p>
                </li>
                <li> 제초제 살포
                  <p>초지조성 대상지에 분포되어 있는 잡관목과 산야초는 대부분 영년생 숙근성이므로 베어내고 불을 지른다 해도 다시 재생된다. 그러므로 단번에 초지조성을 성공적으로 하기 위해서는 이행성 제초제, 즉 뿌리까지 죽이는 제초제를 미리 살포하는 것이 좋다. 초지조성용 제초제로서는 근사미를단보당 400ml 살포한다.</p>
                </li>
                <li>적기파종
                  <p>초지조성시기는 봄보다 늦여름이 유리하며 가급적 파종기를 전후하여 비가와서 토양수분이 충분할 때가 좋다. 부득이 봄철에 파종하고자 할 때는 해빙직후 빠를수록 좋다.</p>
                </li>
                <li>관리방목의 실시
                  <p>목초 파종후 초고가 15cm정도 자랐을 때 가볍게 방목을 시켜주는 것이 좋다. 이는 목초를 이용한다는 의미보다는 초지의 알맞은 식생유지를 위해서 필요한 작업이다. 관리방목의 목적은</p>
                  <ul>
                    <li>재생되는 잡초, 산야초에 의하여 어린 목초가 억압되는 것을 방지하며, </li>
                    <li>초기 생육이 늦은 목초의 생육을 도와주며 하번초의 식생을 유지시켜 주고 </li>
                    <li> 목초가 연약하게 웃자라는 것을 막고 분열(가지치기)을 촉진하므로,</li>
                    <li> 어린 목초를 건실하게 키워 월동율을 높여주는데 있다.</li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
      <h2>초지관리이용 기술대책</h2>
      <ul>
        <li>적기 적정이용
          <ul>
            <li>초지 유지관리면에서의 조기이용
              <p>&nbsp;&nbsp;&nbsp;초지의 이용적기는 이용목적과 계절, 관리실제 등에 따라 차이가 있으나 어떠한 경우라도 목초의 밑에 잎이 마르거나 썩어 못쓰게 되도록 초지를 늦게 이용해서는 안된다. 초지의 이용시기가 늦어지면 목초의 밑에 잎이 마르고 동화능력을 상실하여 이용후 재상이 극히 불량해지고 분얼이 억제되어 빈 땅이 많이 생기게 되므로 결국 부실초지가 되는 주원인이 된다. 특히 첫 번째 이용시기는 개화기를 넘기지 않는 것이 목초의 식생밀도를 높게 유지하고 초지의 부실화를 막는 면에서 중요하다. 또한 현재 초지의 주초중인 오드그라스는 특히 낮은 예취에 견디지 못함으로 반드시 5~6cm이상으로 높게 이용하는 것이 좋다.<br/>&nbsp;&nbsp;&nbsp;그러나 낫으로 목초를 예취할대 흔히 너무 낮게 깎아내게 되므로 가급적 예취높이를 조절할수 있는 소형 에취기를 사용하는 것이 초지의 유지관리를 위해서 필요하다 하겠다. 과도한 방목이나 너무 자주 예취하면 하번초 특히 라디노클로버가 우점되기 쉬우므로 초지를 이용한 후에는 계절에 따라 차이가 있으나 약 30일 정도의 재생기간을 두는 것이 좋다.</p>
            </li>
            <li>목초의 영양가면에서 조기이용
              <p>&nbsp;&nbsp;&nbsp;일반농가의 초지이용시기를 보면 지나치게 늦은 경향이 있다. 초지의 이용시기는 빠를수록 목초의 품질이 우수하고 초지 유지면에서도 유리하다. 목초의 이용시기가 늦어지면 늦어질수록 영양가가 급격히 감소할 뿐 아니라 거칠어져서 채식량이 적어지게 되므로 목초에서 산유량을 기대할 수가 없게 된다.<br/>&nbsp;&nbsp;&nbsp;목초의 이용시기가 늦어지면 젖소 한 마리당 산유량에서 막대한 손해를 보게된다. 즉 목초를 일찍 이용했을대 젖소 한 마리당 1일 채식량은 건물 13Kg이고 산유량은 13.5Kg인데 비하여 늦게 이용했을때에는 채식량이 8Kg이고 산유량은 0.5Kg으로 떨어지는 것을 볼 수 있다.<br/>&nbsp;&nbsp;&nbsp;이용시기가 늦어짐에 따라 목초의 품질이 나빠지는 정도는 &lt;표 1&gt;에서 보는바와 같이 건물 1Kg당 우유생산에 필요한 정미에너지(NETMJ≒4% 유지율 우유 1Kg당 3.17메가주울 소요)함량 뿐만아니라 건물율의 증가에 따른 채식량의 제한 내지는 소화율의 감소등 복합적인 손해를 보게 된다.<br/>&nbsp;&nbsp;&nbsp;따라서 초지는 목초의 초고가 약 20cm정도로 자라면 지체없이 방목시키는 것이 초지의 유지면에서 보나 목초의 품질면에서 매우 중요하다.</p><br/>
              <p><b>&lt;표 1&gt; 이용시기에 따른 목초품질의 변화</b></p>
              <table className="tablestyle">
                <tr>
                  <td>이용시기</td>
                  <td>건물 1kg당 건물 함량</td>
                  <td>청초1kg당 건물 함량</td>
                  <td>가소화 조단백질</td>
                  <td>산유 정미에너지</td>
                  <td>소화율</td>
                </tr>
                <tr>
                  <td>1차 이용시</td>
                  <td rowSpan="9">6.81MJ<br/>6.31<br/>5.65<br/>4.94<br/>6.12<br/>5.83<br/>5.17</td>
                  <td rowSpan="9">16.0%<br/>17.5<br/>22.0<br/>24.0<br/>16.0<br/>18.0<br/>20.0</td>
                  <td rowSpan="9">2.9g<br/>2.6<br/>3.1<br/>2.5<br/>3.1<br/>3.0<br/>2.7</td>
                  <td rowSpan="9"><br/>1.09MJ<br/>1.10<br/>1.24<br/>1.19<br/>0.98<br/>1.05<br/>1.03</td>
                  <td rowSpan="9">76%<br/>71<br/>63<br/>60<br/>73<br/>68<br/>65</td>
                </tr>
                <tr>
                  <td>출수전</td>
                </tr>
                <tr>
                  <td>출수기</td>
                </tr>
                <tr>
                  <td>개화중기</td>
                </tr>
                <tr>
                  <td>개화말기</td>
                </tr>
                <tr>
                  <td>2차이후재생목초</td>
                </tr>
                <tr>
                  <td>4주이내</td>
                </tr>
                <tr>
                  <td>4∼6주</td>
                </tr>
                <tr>
                  <td>6주이상</td>
                </tr>
              </table>
            </li>
          </ul>
        </li>
        <li>계속적인 보파
          <p>초지에 빈 땅이 생기게 되면 여기에 잡초가 생기게 되고 따라서 초지의 수명이 더욱 단축된다. 그러므로 목초종자를 더욱 보파하여 항상 초지내에 발아할 수 있는 목초종자를 확보시켜 두고 빈땅이 생겼을 경우 다시 목초가 돋아날 수 있도록 하는 것이 초지의 영구적인 유지를 위해 필요하다.</p>
        </li>
        <li>상습지의 배수
          <p>저지대나 물이 잘 빠지지 않는 곳에는 오차드그라스가 견디지 못하고 소멸하는 반면 라디노클로버가 번성하여 클로버 우점초지가 되기 쉽다.<br/>따라서 상습지에는 배수시설을 해야 오차드그라스 위주의 초지를 유지할 수 있다.</p>
        </li>
        <li>잡초방제
          <p>초지의 잡초는 주어진 자연환경(기후, 토양)과 관리이용 조건을 가장 정확히 표현해 주는 지표가 된다. 즉 초지에 어떤 잡초가 발생하면 반드시 그 잡초가 발생하기에 합당한 환경조건이 되어있기 때문이다.<br/>따라서 잡초를 보고, 그 잡초의 발생특성을 판단하여 문제점이 되는 발생원인을 제거 또는 개선해 주는 것이 바로 근본적인 초지부실화방지 대책이라 하겠다.<br/>예를들어 야기수영이 발생한 토지는 산성이며, 척박한 증거이므로 제초제로 제거한 후 석회를 사용하여 산도를 교정해 주고 충분히 관리비료를 사용하는 것이 필요하다. 초지의 잡초문제에 대하여는 다음 장에서 상세히 살펴보기로 한다. </p>
        </li>
      </ul>
      <h2>초지의 하고기 관리대책</h2>
      <ul>
        <li>목초하고현상의 원인
          <p>&nbsp;&nbsp;&nbsp;목초의 생육적온은 15∼21℃로서 여름철 기온이 이보다 높아지면 목초의 뿌리활력이 감퇴하여 수분흡수에 지장을 받게 되고 반면에 높은 기온에 의하여 잎에서늬 수분증발량은 더욱 많아져서 목초의 생육이 극히 부진해지고 심한 경우에는 말라 죽게 되는데 이러한 현상을 소위 목초의 하고현상이라 한다.<br/>&nbsp;&nbsp;&nbsp;목초의 하고현상은 높은 기온과 가뭄 등 기상조건에 크게 영향을 받기 때문에 근본적으로 방지하는 것은 어렵다해도 기술적으로 알맞은 초지관리를 해주므로써 피해를 상당히 줄일 수 있다. 따라서 우리나라의 초지농업은 하고현상 때문에 안된다는 이론은 잘못된 편견이라 할 수 있다.</p>
        </li>
        <li>여름철 목초의 예취높이
          <p>&nbsp;&nbsp;&nbsp;무더운 여름철에는 목초를 너무 낮게 베어내거나 지나친 방목을 시켜서는 안된다. 목초를 짧게 깎아내면 직사광선이 땅표면에 직접 투과되어 땅의 온도를 높여주고 수분증발을 촉진시켜 목초의 하고현상이 더욱 심하게 나타나게 되며 또한 햇빛에 의하여 발아하는 성질이 있는 잡초의 발생을 좋게 하여 초지를 망가뜨리게 된다. 따라서 여름철 고온기에는 목초의 예취높이를 9∼19cm정도로 높게 우지해 주는 것이 초지관리의 가장 기본적인 요령이다.<br/>&nbsp;&nbsp;&nbsp;여름철 고온기간중 예취높이에 따른 땅 표면의 온도를 측정해 본결과 3cm낮게 예취했을 때는 33.7℃이고 9cm높게 예취했을 때는 30.7℃로서 3℃의 차이를 보였다. 따라서 3cm로 낮게 예취했을 때는 목초의 뿌리활력이 떨어져 하고현상이 더욱 크게 나타나게 된다. 반면에 목초를 9cm정도로 높게 예취해 주면 땅의 온도가 올라가는 것을 방지해 주고 목초 뿌리의 활력을 좋게 해주어 목초가 재생이 잘되는 효과가 있다.<br/>또한 예취높이별 목초고사율을 보면 3cm로 낮게 예취했을 때는 고사율이 47.9%였으나 9cm로 높게 예취했을 때는 16.8%로 크게 감소하게 된다. 잡초발생율에 있어서도 낮게 예취할수록 잡초 비율이 높아지고 높게 예취해주면 감소하는 것을 볼 수 있다.<br/>&nbsp;&nbsp;&nbsp;결론적으로 여름철 고온기에는 목초의 예취높이를 높게 해주므로써 하고지수(연평균 일당생산량÷고온기간중 일당생산량)를 크게 낮출수가 있으며 초지의 부실화를 방지할 수 있다.</p><br/>
          <p><b>&lt;표 2&gt; 고온기 예취높이에 따른 지온,재생량 및 뿌리활력비교</b></p>
          <table className="tablestyle">
            <tr>
              <td rowSpan="2">예취높이</td>
              <td rowSpan="2">지표온도</td>
              <td rowSpan="2">지중온도</td>
              <td rowSpan="2">재생초장</td>
              <td colSpan="2">재생건물수량(kg/10a)</td>
              <td rowSpan="2">뿌리활력</td>
            </tr>
            <tr style={{background: '#273143',color:'#fff'}}>
              <td>4차</td>
              <td>5차</td>
            </tr>
            <tr>
              <td>cm</td>
              <td>℃</td>
              <td>℃</td>
              <td>cm</td>
              <td></td>
              <td></td>
              <td>α-NAA<br/>mg/g/hr</td>
            </tr>
            <tr>
              <td>3</td>
              <td>33.7</td>
              <td>27.9</td>
              <td>11.8</td>
              <td>117</td>
              <td>144</td>
              <td>0.487</td>
            </tr>
            <tr>
              <td>6</td>
              <td>31.8</td>
              <td>27.0</td>
              <td>15.4</td>
              <td>156</td>
              <td>167</td>
              <td>0.683</td>
            </tr>
            <tr>
              <td>9</td>
              <td>30.7</td>
              <td>26.6</td>
              <td>20.1</td>
              <td>200</td>
              <td>174</td>
              <td>0.865</td>
            </tr>
          </table>
          <p>* 지중온도는 10cm깊이에서 측정</p><br/>
          <p><b>&lt;표 3&gt; 고온기 예취높이에 따른 목초고사, 잡초발생 및 목초생산량과 하고지수</b></p>
          <table className="tablestyle">
            <tr>
              <td>예취높이</td>
              <td>목초고사율</td>
              <td>잡초발생율</td>
              <td>년평균 일당생산량</td>
              <td>고온기간 중 일당생산량</td>
              <td>하고지수</td>
            </tr>
            <tr>
              <td>cm</td>
              <td>%</td>
              <td>%</td>
              <td>kg/10a</td>
              <td>kg/10a</td>
              <td></td>
            </tr>
            <tr>
              <td>3</td>
              <td>47.9</td>
              <td>27.0</td>
              <td>5.65</td>
              <td>2.74</td>
              <td>2.06</td>
            </tr>
            <tr>
              <td>6</td>
              <td>27.6</td>
              <td>16.0</td>
              <td>5.77</td>
              <td>3.71</td>
              <td>1.55</td>
            </tr>
            <tr>
              <td>9</td>
              <td>16.8</td>
              <td>9.1</td>
              <td>5.80</td>
              <td>4.73</td>
              <td>1.23</td>
            </tr>
          </table>
        </li>
        <li>고온건조기의 관수효과
          <p>&nbsp;&nbsp;&nbsp;우리나라의 초지토양은 위에서 설명한 바와 같이 유기물 함량이 적고 사질토양이 많으뿐 아니라 경사지이기 때문에 보수력(물을머금고 있는 힘=문지님힘)이 불량하여 가뭄피해를 입기 쉽다. 목초는 무덥고 가물게 되면 뿌리의 활력이 감퇴하여 비료를 잘 이용할 수 없을 뿐 아니라 세포조직이 오물어 들어 잎도 마르고 생욱에 큰지장을 받게 된다.<br/>&nbsp;&nbsp;&nbsp;고온건조기에 경사초지에 스프링클러 관수방법으로 관수를 해준 결과를보면 무관수에 비하여 지온이 1.6∼4.4℃가 낮아지고 목초의 생육이 좋아져서 재생수량이 36∼50%정도 증가하였다. 또한 초지에 관수를 할 경우에는 수량이 높아지게 되므로 이에 상응하는 비료를 더 추가해서 주는 것이 관수효과를 더욱 크게 하는 요령이다.</p><br/>
          <p><b>&lt;표 4&gt; 한발기간중 초지의 관수효과</b></p>
          <table className="tablestyle">
            <tr>
              <td>관수여부</td>
              <td>지표온도</td>
              <td>지주온도</td>
              <td>초장</td>
              <td>생초수량</td>
              <td>건물수량</td>
            </tr>
            <tr>
              <td></td>
              <td>℃</td>
              <td>℃</td>
              <td>cm</td>
              <td>kg/10a</td>
              <td>kg/10a</td>
            </tr>
            <tr>
              <td>관수</td>
              <td>32.5</td>
              <td>25.0</td>
              <td>64</td>
              <td>3541(150)</td>
              <td>589(136)</td>
            </tr>
            <tr>
              <td>무관수</td>
              <td>36.9</td>
              <td>26.6</td>
              <td>50</td>
              <td>2363(100)</td>
              <td>435(100)</td>
            </tr>
            <tr>
              <td>효과</td>
              <td>-4.4</td>
              <td>-1.6</td>
              <td>14</td>
              <td>1178</td>
              <td>154</td>
            </tr>
          </table>
          <p>* () : 상대지수</p><br/>
          <p><b>&lt;표 5&gt; 관수에 따른 질소시비수준별 목초의 생육 및 수량비교</b></p>
          <table className="tablestyle">
            <tr>
              <td rowSpan="2">년간 질소수준</td>
              <td colSpan="2">초장(cm) </td>
              <td colSpan="2">생초수량(kg/10a)</td>
              <td colSpan="2">건물수량(kg/10a)</td>
            </tr>
            <tr style={{background: '#273143',color:'#fff'}}>
              <td>관수</td>
              <td>무관수</td>
              <td>관수</td>
              <td>무관수</td>
              <td>관수</td>
              <td>무관수</td>
            </tr>
            <tr>
              <td>kg/10a</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>14</td>
              <td>56</td>
              <td>44</td>
              <td>1502</td>
              <td>913</td>
              <td>249</td>
              <td>166</td>
            </tr>
            <tr>
              <td>28</td>
              <td>62</td>
              <td>52</td>
              <td>1750</td>
              <td>1324</td>
              <td>292</td>
              <td>239</td>
            </tr>
            <tr>
              <td>42</td>
              <td>73</td>
              <td>55</td>
              <td>2059</td>
              <td>1318</td>
              <td>343</td>
              <td>247</td>
            </tr>
          </table>
        </li>
        <li>장마전후의 초지관리요령
          <p>보통 1차 예취후에 재생된 목초는 출수하지 않고 잎만 무성하게 자라기 때문에 식물체가 연약해지기 쉽다. 이런 상태로 장마철에 들어가면 무성한 잎이 땅에 쳐져서 밑의 잎들은 햇빛을 전혀받지 못하고 통풍이 안되기 때문에 썩어버리기 쉽고 심하면 목초가 뿌리채 섞어 버릴 염려가 있다. 따라서 초지는 장마전에 일단 이용을 해서 이런 현상이 생기지 않도록 해야 한다.</p><br/>
          <p><b>&lt;표 6&gt; 장마전후 예취에 따른 목초의 고사지표피복율 및 건물수량 비교</b></p>
          <table className="tablestyle">
            <tr>
              <td rowSpan="2">예취시기</td>
              <td rowSpan="2">목초고사율</td>
              <td colSpan="3">지표피복율(%)</td>
              <td rowSpan="2">3차건물수량</td>
            </tr>
            <tr style={{background: '#273143',color:'#fff'}}>
              <td>목초</td>
              <td>잡초</td>
              <td>나지</td>
            </tr>
            <tr>
              <td></td>
              <td>%</td>
              <td></td>
              <td></td>
              <td></td>
              <td>kg/10a</td>
            </tr>
            <tr>
              <td>장마전예취</td>
              <td>0</td>
              <td>82</td>
              <td>8</td>
              <td>10</td>
              <td>214</td>
            </tr>
            <tr>
              <td>장마후예취</td>
              <td>30</td>
              <td>54</td>
              <td>31</td>
              <td>15</td>
              <td>78</td>
            </tr>
          </table><br/>
          <p className="pstyle">윗 표에서 보는 바와 같이 장마전에 초지를 이용해 주면 목초의 고사가 없었으나 잎이 무성한 상태로 장마를 지난후 예취하면 목초가 30%나 죽어버리는 것을 볼 수 있다. 이 영향으로 목초의 식생밀도가 54%로 급격히 떨어지고 반면에 잡초와 빈 땅의 비율이 높아져 결국은 초지가 망가지게 된다. 장마전에 초지를 이용하지 않고 장마기에 들어가게 되면 목초는 웃자라게 되어 대부분 도복된 상태로 장마기를 지나게 되기 때문에 이때 바로 목초를 예취해 주지 않으면 초지에 피해를 입게 된다. 목초가 도복된 후 즉시 예취한 것과 상당한 시일이 경과된 후에 예취한 초지의 상태를 비교해 보면 다음 표와 같다</p><br/>
          <p><b>&lt;표 7&gt; 고온기간중 목초도복후 예취시기에 따른 생육 및 수량비교</b></p>
          <table className="tablestyle">
            <tr>
              <td rowSpan="2">예취시기</td>
              <td colSpan="2">예취당시목초수량(kg/10a)</td>
              <td rowSpan="2">목초고사율</td>
              <td rowSpan="2">재생수량</td>
              <td colSpan="3">식생비율(%)</td>
            </tr>
            <tr style={{background: '#273143',color:'#fff'}}>
              <td>생존량</td>
              <td>고사량</td>
              <td>화본과</td>
              <td>두과</td>
              <td>잡초 </td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td>%</td>
              <td>kg/10a</td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>도복후 바로예취</td>
              <td>376990.2)</td>
              <td>41(10.8)</td>
              <td>26.3</td>
              <td>173(100)</td>
              <td>77.8</td>
              <td>21.6</td>
              <td>0.6</td>
            </tr>
            <tr>
              <td>도복후 10일예취</td>
              <td>197(47.7)</td>
              <td>221(52.3)</td>
              <td>41.7</td>
              <td>126(73)</td>
              <td>45.5</td>
              <td>50.3</td>
              <td>4.2</td>
            </tr>
            <tr>
              <td>도복후 20일예취</td>
              <td>185?(39.1)</td>
              <td>290(60.9)</td>
              <td>46.7</td>
              <td>105(61)</td>
              <td>36.5</td>
              <td>61.7</td>
              <td>1.8</td>
            </tr>
          </table><br/>
          <p className="pstyle">&lt;표 7&gt;에서 보는 바와 같이 도복후 기일이 오래 경과될수록 목초의 고사율이 높아지고 재생수량이 떨어지며 식생구성이 나빠지게 딘다. 특히 이러한 피해는 오차드그라스등 품질이 우수한 하분과 목초에서 더욱 심하게 나타나 빈 땅이 맣이 생기세 되고 여기에 하고에 강한 라디노클로버가 번성하게 되어 여름철 라디노클로버 우점처지가 되는 주원인이 되고 있다. 따라서 여름철 하고기간중이라도 목초가 웃자라 쓸어지게 되면 즉시 높게 예취해 주거나 가볍게 방목을 시켜 초지내에 통풍이 되도록 하여 밑의 잎들이 썩어 망가지는 것을 예방하여야 한다. </p>
        </li>
        <li>고온건조기에 비료주는 요령
          <p className="pstyle">&nbsp;&nbsp;&nbsp;초지를 이용한 후에는 목초의 재생을 위해서 비료를 주는 것이 원칙이다. 그러나 고온건조기에는 하고현상이 나타나 목초의 생육이 거의 중지나며 뿌리가 비료를 흡수하는 힘이 떨어져 오히려 목초에 해를 입히기도 하며 반면에 무더운 날씨에 왕성하게 생육하는 잡초만 더욱 무성하게 할 염려가 있다.<br/>&nbsp;&nbsp;&nbsp;또한 목초는 여름철 고온기에 양분동화량보다 소모량이 더 많아 생육이 극히 불량해지게 된느대 이때 질소비료를 많이 주면 생리적으로 목초에 치명적인 피해를 줄수도 있다. 따라서 고온건조기에는 질소비료를 많이 주면 안되며 주더라도 적은 양만 주어야 한다.</p>
        </li>
      </ul>
    </div>
  );
}

export default Do175;