import React from 'react';
import { useNavigate } from 'react-router-dom';

// constants
import { BOARD_CODE, BOARD_NAME } from 'constants/backOfficeBoard';

// components
import CommonInfoList from '../_components/CommonInfoList';

function InfoEventList() {
  const navigate = useNavigate();

  const handleRegister = () => {
    navigate('/bo/info/infoEvent/edit');
  };

  const handleViewDetail = (item) => {
    navigate(
      `/bo/info/InfoEvent/view?bdc_code=${item.BDC_CODE}&brd_code=${BOARD_CODE.EVENT}`,
    );
  };
  return (
    <CommonInfoList
      title={BOARD_NAME.EVENT}
      brdCode={BOARD_CODE.EVENT}
      queryId="infoEvent"
      apiEndpoint="/board/select_info_list"
      onRegister={handleRegister}
      onViewDetail={handleViewDetail}
    />
  );
}

export default InfoEventList;
