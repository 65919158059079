import React from 'react';

// constants
import { BOARD_CODE, BOARD_NAME } from 'constants/backOfficeBoard';

// components
import BoardList from '../_components/BoardList';

function CowTestNewList() {
  return (
    <BoardList
      boardCode={BOARD_CODE.COW_TEST_NEW}
      boardName={BOARD_NAME.COW_TEST_NEW}
      editPath="/bo/board/cowTestNew/edit"
      viewPath="/bo/board/cowTestNew/view"
    />
  );
}

export default CowTestNewList;
