import React from 'react';
import CompanyIntro from 'containers/web/business/companyIntro/CompanyIntro';
import { Helmet } from 'react-helmet-async';

function CompanyIntroPage() {
  return (
    <>
      <Helmet>
        <title>젖소개량사업소 소개</title>
      </Helmet>
      <CompanyIntro />
    </>
  );
}

export default CompanyIntroPage;
