import React from 'react';
import { useLocation } from 'react-router-dom';
import { ROUTE_PAGE } from 'constants/constants';

// scss
import './subTitle.scss';

function SubTitle() {

  const location = useLocation();
  const [ oneDepth , twoDepth , threeDepth ] = location.pathname.split('/').filter(Boolean);
  const title = ROUTE_PAGE[twoDepth].child[threeDepth];

  return (
      <h4 className='subTitle'>{title}</h4>
  );
}

export default SubTitle;
