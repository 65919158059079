import React, { useState } from 'react';

// api
import { getState } from 'utils/api';

// react-query
import { useQuery } from '@tanstack/react-query';

// 이미지
import report from 'assets/images/containers/data/publication/report.png';

// scss
import './publicationReport.scss';

function PublicationReport() {
  const {
    data: years,
    isLoading,
    error,
  } = useQuery({
    queryKey: ['publicationReport'],
    queryFn: () => getState('/info/publicationyear'),
  });

  return (
    <div className="publicationReport">
      <ul>
        {isLoading ? (
          <li>
            <div className="leftArea">
              <p>
                데이터를 불러오는 중입니다...
                <br />
                잠시만 기다려주세요
              </p>
            </div>
            <img src={report} alt="한국 유우군 검정사업 보고서" />
          </li>
        ) : error ? (
          <li>
            <div className="leftArea">
              <p>
                데이터를 불러올 수 없었습니다
                <br />
                잠시 후 다시 시도해주세요
              </p>
            </div>
            <img src={report} alt="한국 유우군 검정사업 보고서" />
          </li>
        ) : (
          years?.result &&
          [...years.result].reverse().map((year) => (
            <li key={year.BDC_WNAME}>
              <div className="leftArea">
                <p>
                  {year.BDC_WNAME}년도 한국 유우군
                  <br />
                  검정사업 보고서
                </p>
                <a
                  href={`${process.env.PUBLIC_URL}/statics/_uploadFiles/_annual_report/${year.BDC_WNAME}_main.pdf`}
                  target="_blank"
                  rel="noreferrer"
                >
                  PDF 보기
                </a>
              </div>
              <img src={report} alt="한국 유우군 검정사업 보고서" />
            </li>
          ))
        )}
      </ul>
    </div>
  );
}

export default PublicationReport;
