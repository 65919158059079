import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// api
import { getState, postState } from 'utils/api';

function ExptEngView() {
  const [expert, setExpert] = useState({
    ep_id: '',
    ep_name: '',
    ep_position: '',
    ep_title: '',
    ep_tel1: '',
    ep_tel2: '',
    ep_field: '',
    ep_email: '',
    ep_etc: '',
    ep_career: '',
    ep_board_id: '',
    ep_del_fl: 0,
    ep_img_ext: '',
    ep_fname: '',
    is_first: 0,
    is_last: 0,
  });

  const navigate = useNavigate();
  const epId = new URLSearchParams(window.location.search).get('ep_id');

  useEffect(() => {
    const fetchExpert = async () => {
      try {
        const response = await getState('/board/select_expert_e', {
          ep_id: epId,
        });

        console.log(response);

        if (response?.return_code === 'SUCCESS') {
          setExpert(response.result);
        }
        if (response?.return_code === 'ERROR') {
          alert(response?.return_message);
        }
      } catch (error) {
        console.error('Error fetching expert:', error);
      }
    };

    if (epId) {
      fetchExpert();
    }
  }, [epId]);

  const handleDelete = async () => {
    if (window.confirm('전문가를 삭제하시겠습니까?')) {
      try {
        const response = await postState('/board/delete_expert_e/', {
          ep_id: epId,
        });

        if (response?.return_code === 'SUCCESS') {
          alert('삭제되었습니다.');
          navigate('/bo/expt/exptEng/list');
        }
        if (response?.return_code === 'ERROR') {
          alert(response?.return_message);
        }
      } catch (error) {
        console.error('Error deleting expert:', error);
        alert('삭제 중 오류가 발생했습니다.');
      }
    }
  };

  const handleEdit = () => {
    navigate(`/bo/expt/exptEng/edit?ep_id=${epId}`);
  };

  const handleOrder = async (direction) => {
    try {
      const response = await postState(
        `/board/update_expert_${direction}_order_e/`,
        {
          ep_id: epId,
        },
      );

      if (response?.return_code === 'SUCCESS') {
        alert('순서가 변경되었습니다.');
        navigate('/bo/expt/exptEng/list');
      }
      if (response?.return_code === 'ERROR') {
        alert(response?.return_message);
      }
    } catch (error) {
      console.error('Error changing order:', error);
      alert('순서 변경 중 오류가 발생했습니다.');
    }
  };

  const handleToggleActive = async () => {
    try {
      const response = await postState('/board/update_expert_del_fl_e/', {
        ep_id: epId,
      });
      if (response?.return_code === 'SUCCESS') {
        alert(`${expert.ep_del_fl === 1 ? '활성' : '비활성'} 처리되었습니다.`);
        window.location.reload();
      }
      if (response?.return_code === 'ERROR') {
        alert(response?.return_message);
      }
    } catch (error) {
      console.error('Error toggling active status:', error);
      alert('상태 변경 중 오류가 발생했습니다.');
    }
  };

  if (!expert) return <div>Loading...</div>;

  return (
    <div className="expert-view bo-page">
      <h2>전문가&#40;영문&#41; 정보</h2>
      <div className="table-container bold-border">
        <table>
          <colgroup>
            <col width="20%" />
            <col width="30%" />
            <col width="20%" />
            <col width="30%" />
          </colgroup>
          <tbody>
            <tr>
              <th>성명</th>
              <td colSpan="2" className="nr">
                {expert.ep_name}
              </td>
              <td rowSpan="5" style={{ textAlign: 'center' }}>
                <img
                  src={`${process.env.REACT_APP_STATICS_URL}/_uploadFiles/_qna/${expert.ep_fname}`}
                  style={{ width: '150px', height: '150px' }}
                  alt={expert.ep_name}
                />
              </td>
            </tr>
            <tr>
              <th>소속</th>
              <td colSpan="2" className="nr">
                {expert.ep_position}
              </td>
            </tr>
            <tr>
              <th>직함</th>
              <td colSpan="2" className="nr">
                {expert.ep_title}
              </td>
            </tr>
            <tr>
              <th>연락처1</th>
              <td colSpan="2" className="nr">
                {expert.ep_tel1}
              </td>
            </tr>
            <tr>
              <th>연락처2</th>
              <td colSpan="2" className="nr">
                {expert.ep_tel2}
              </td>
            </tr>
            <tr>
              <th>분야</th>
              <td className="nr">{expert.ep_field}</td>
              <th>E-MAIL</th>
              <td className="nr">{expert.ep_email}</td>
            </tr>
            <tr>
              <th>기타</th>
              <td className="nr">{expert.ep_etc}</td>
              <th>경력</th>
              <td className="nr">{expert.ep_career}</td>
            </tr>
            <tr>
              <th>게시판코드</th>
              <td className="nr" colSpan="3">
                {expert.ep_board_id}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="button-group">
        <button type="button" onClick={handleEdit} className="btn-l">
          {epId ? '수정' : '등록'}
        </button>
        <button
          type="button"
          onClick={() => handleOrder('up')}
          className="btn-l"
          disabled={expert.is_first === 1}
        >
          ↑
        </button>
        <button
          type="button"
          onClick={() => handleOrder('down')}
          className="btn-l"
          disabled={expert.is_last === 1}
        >
          ↓
        </button>
        <button type="button" onClick={handleToggleActive} className="btn-l">
          {expert.ep_del_fl === 1 ? '활성' : '비활성'}
        </button>
        <button type="button" onClick={handleDelete} className="btn-l">
          삭제
        </button>
        <button
          type="button"
          onClick={() => navigate('/bo/expt/exptEng/list')}
          className="btn-l"
        >
          목록
        </button>
      </div>
    </div>
  );
}

export default ExptEngView;
