import React, { useState , useEffect } from 'react';

// api
import { getState } from 'utils/api';
import { openCrownix } from 'utils/openCrownix';

// 이미지
import PrintPDF from 'assets/svgs/common/print_PDF.svg';
import Logo from 'assets/images/common/header_logo_pc.png';

// 컴포넌트
import ErrorMessage from 'components/errorMessage/errorMessage';

// scss
import './NewGentype20.scss';

function NewGentype20() {

    // 에러처리
  const [isModalOpen, setIsModalOpen] = useState(false); // 모달 추가
  const [errorMessage, setErrorMessage] = useState(''); 

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => {
    setIsModalOpen(false);
    setErrorMessage(''); // 모달이 닫힐 때 에러메세지 초기화
  };

    // 데이터 불러오기
  const baseDir='/dcitest/dcieval/newgentype20/';
  const [ mainData , setMainData ] = useState();
  const [ props , setProps] = useState({
    id:`${localStorage.getItem('cong')}${localStorage.getItem('farm')}`,
    passwd:localStorage.getItem('passwd'),
    date:localStorage.getItem('month')
  });
  useEffect(() => {

    getState(baseDir,props)
    .then(getdata => {      
      if (getdata !== undefined) {
      setMainData(getdata);
      }
    })
    .catch(error => {          
      setErrorMessage(error.message);
      openModal();
    });
    
  }, [props]);

  // 페이지 네이션
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20;

  // Calculate the index range for the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = mainData && mainData.slice(indexOfFirstItem, indexOfLastItem);
  const maxPage = Math.ceil(mainData && mainData.length / itemsPerPage);

  return (
    <div className='pdfMainArea'>
      <ErrorMessage isOpen={isModalOpen} content={errorMessage} closeModal={closeModal}/>
      <div className='pdfMainHeader'>
        <button 
          type='button'
          onClick={()=> openCrownix('New_gentype20.mrd')}
        >
          <img src={PrintPDF} alt='PDF로 인쇄하기' />
          인쇄하기
        </button>
        <div className='pdfPagenation'>
          <p>{currentPage} / {maxPage}</p>
          <button 
            type='button' 
            onClick={() => { 
              if (currentPage !== 1 ) setCurrentPage(currentPage - 1);
            }}
            >이전</button>
          <button 
            type='button' 
            onClick={() => {
              if (currentPage !== maxPage) setCurrentPage(currentPage + 1);
            }}
            >다음</button>
        </div>
      </div>
      <div className='pdfArea'>
        <div className='newGentypePdf'>
          <h1>정액 GENOTYPES(10MS)</h1>           
          <p className="exp">※10개 마커 항목은 국제표준</p>

          <table className='pdfTable'>
            <thead>
              <tr>
                <th className="tablestart"><p style={{textAlign:'right'}}>마커</p><p style={{textAlign:'left'}}>정액코드</p></th>
                <th>명호</th>
                <th colSpan='2'>BM1824</th>
                <th colSpan='2'>BM2113</th>
                <th colSpan='2'>ETH10</th>
                <th colSpan='2'>ETH225</th>
                <th colSpan='2'>ETH3</th>
                <th colSpan='2'>SPS115</th>
                <th colSpan='2'>TGLA126</th>
                <th colSpan='2'>TGLA122</th>
                <th colSpan='2'>TGLA227</th>
                <th colSpan='2'>MGTG4B</th>                

              </tr>            
            </thead>
            <tbody>
              {
                currentItems &&
                currentItems.map((item, index) => (
                  <tr key={index}>
                    <td>{item.gname}</td>
                    <td>{item.name}</td>
                    <td>{item.bm1824a}</td>
                    <td>{item.bm1824b}</td>
                    <td>{item.bm2113a}</td>
                    <td>{item.bm2113b}</td>
                    <td>{item.eth10a}</td>
                    <td>{item.eth10b}</td>
                    <td>{item.eth225a}</td>
                    <td>{item.eth225b}</td>
                    <td>{item.eth3a}</td>
                    <td>{item.eth3b}</td>
                    <td>{item.sps115a}</td>
                    <td>{item.sps115b}</td>
                    <td>{item.tgla126a}</td>
                    <td>{item.tgla122b}</td>
                    <td>{item.tgla122a}</td>
                    <td>{item.tgla122b}</td>
                    <td>{item.tgla227a}</td>
                    <td>{item.tgla227b}</td>
                    <td>{item.mgtg4ba}</td>
                    <td>{item.mgtg4bb}</td>
                                     
                  </tr>
                ))
              }
            </tbody>
          </table>          
                
          <img src={Logo} alt='농협경제지주 젖소개량사업소 로고' className='pdfLogo'/>
        </div>
      </div>
    </div>    
    
  );
}

export default NewGentype20;
