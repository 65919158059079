import React from 'react';

function Fo49() {

  return (
    <div className="techinfo_view">
				<p className="viewTitle">회백 뇌척수염</p>
				<dl>
					<dt>발병대상</dt>
					<dd>소, 양, 염소, 사슴</dd>
				</dl>
				
				<dl>
					<dt>원인</dt>
					<dd>원인은 분명하지 않지만 이 증상은 치아민 투여에 민감한 반응을 나타낸 점으로 미루어 급성 치아민 결핍이 주 요인일것이라는 가설이 신빙성이 높다. 그러나 치아민 섭취량이 적당량 이상임에도 불구하고 어떻게 하여 치아민 결핍이 발생하는지에 대해서는 잘 알려져 있지 않으며 하나의 이론으로 특수 식물 혹은 미생물 중에 치아미나제 효소가 지나치게 많이 함유되어 있는데, 이것이 치아민의 흡수가 이루어지기 전에 비타민을 파괴하기 때문이라고 생각하고 있다.</dd>
				</dl>
				
				<dl>
					<dt>징후 및 증상</dt>
					<dd>가축이 갑작스럽게 죽는다. 일단 발병하면 흥분을 하며 시력장애가 오고 가축을 몰 때 이들 가축은 경련으로 쓰러진다. 대개 3개월령에서 2살된 비육 및 방목중인 소에 감염되며 육용종 양의 경우 5-8개월령의 자양에서 발병율이 가장 높지만 방목중인 경우라도 과방목에서 새로운 방목지로 전환할 때 방목중인 경우라도 과방목에서 새로운 방목지로 전환할 때 발병할 수 있다. 그리고 방목지에서 포유중인 염소에서도 발병할 수 있다. </dd>
				</dl>
				
				<dl>
					<dt>질병분포와 손실규모</dt>
					<dd>비육우에 흔하며 세계적으로 분포되어 있다. 양의 경우 발병율이 약 10%이상까지 이르며 감염동물의 50%가량이 죽는다. </dd>
				</dl>
				
				<dl>
					<dt>치료</dt>
					<dd>kg당 2-4mg의 치아민을 하루에 두번씩 2일간 정맥 또는 근육주사한다. 회복율은 발병인지 시간과 치아민의 투여에 직접적으로 달려 있다. 일반적으로 발병후 24시간 이상 진행되면 치료효과를 기대하기 어렵다.</dd>
				</dl>
				
				<dl>
					<dt>대책</dt>
					<dd>고에너지 사료로 변경해 주기전에 약 5일간은 곡류사료의 비율을 줄이고 양질의 조사료를 늘려주도록 하며 적당한 간호가 필요하다. </dd>
				</dl>
				
				<dl>
					<dt>예방</dt>
					<dd>원인이 밝혀질 때까지는 특별한 예방조치가 불가능하며 다만 질 좋은 사료를 공급해 줄 수 밖에 없다. </dd>
				</dl>	
				
				<dl>
					<dt>참고</dt>
					<dd>반추동물은 대개 반추위 미생물에 의하여 적당량의 치아민을 합성하기 때문에 결핍증상이 일어난다면 이는 반추위 미생물 또는 기타 사료내 효소에 의하여 치아민이 파괴되기 때문인 것으로 생각된다. </dd>
				</dl>	
			</div>
  );
}

export default Fo49;
