import React from 'react';

import Pic160 from 'assets/images/sub/_images/pic160.gif';
import Pic161 from 'assets/images/sub/_images/pic161.gif';
import Pic162 from 'assets/images/sub/_images/pic162.gif';
import Pic163 from 'assets/images/sub/_images/pic163.gif';
import Pic164 from 'assets/images/sub/_images/pic164.gif';

function Do23() {

  return (
    <div className="techinfoview">
				<h1>번식장애</h1>
				<p style={{lineHeight: '200%'}}>&nbsp;&nbsp;&nbsp;번식장애는 아무리 사양관리를 잘한다 하더라도 발생하기 마련이다. 이러한 번식장애의 발생실태를 살펴보고 이들 중 특히 중요한 몇가지 중요한 발병사례 및 치료법을 살펴보기로 한다. 주의할 점은 정확한 이론, 즉 신체상의 내분비 기능을 혹실하게 숙지된 사람에 한해서 시도해야 하며 정확한 진단과 치료는 전문 수의사 처방에 따르는 것이 좋다. <br/>&nbsp;&nbsp;&nbsp;번식장애를 일으키는 원인은 조사한 바에 의해 살펴보면 국내의 경우 한우에서의 번식장애 발생율은 7.7 - 16.2%, 젖소의 경우 12.3 - 31.3%인데 비햐여 도입육우는 38.8%로 보고되고 있다. 육우가 이렇게 국내에 도입된 후 번식장애가 많은 것은 주로 곡류 위주의 사양과 운동부족이 주요 원인인 것으로 판단된다.</p>
				<p>[번식장애 발생비율]</p>
				<table className="tablestyle">
					<tr>
						<td>구분</td>
						<td>조사두수</td>
						<td>발생두수</td>
						<td>발생률</td>
						<td>조사지역</td>
						<td>비고</td>
					</tr>
					<tr>
						<td rowSpan="4">한우</td>
						<td>1,584</td>
						<td>188</td>
						<td>11.9</td>
						<td>전국</td>
						<td>축산시험장 : 76 - 81년</td>
					</tr>
					<tr>
						<td>879</td>
						<td>117</td>
						<td>13.3</td>
						<td>일반농가</td>
						<td>〃</td>
					</tr>
					<tr>
						<td>365</td>
						<td>28</td>
						<td>7.7</td>
						<td>국,공립기관</td>
						<td>〃</td>
					</tr>
					<tr>
						<td>457</td>
						<td>74</td>
						<td>16.2</td>
						<td>제주도</td>
						<td>제주대학(80년)</td>
					</tr>
					<tr>
						<td rowSpan="3">육우</td>
						<td>5,658</td>
						<td>1,437</td>
						<td>25.4</td>
						<td>미국1</td>
						<td></td>
					</tr>
					<tr>
						<td>882</td>
						<td>204</td>
						<td>23.1</td>
						<td>미국2</td>
						<td></td>
					</tr>
					<tr>
						<td>402</td>
						<td>137</td>
						<td>9.5</td>
						<td>미국3</td>
						<td></td>
					</tr>
				</table><br/>
				<p>[한우에 있어서 번식장애의 증상별 발생비율(축시 : 76 - 81)]</p>
				<table className="tablestyle">
					<tr>
						<td>구분</td>
						<td>조사두수</td>
						<td>무발정</td>
						<td>미약발정</td>
						<td>계속발정</td>
						<td>저수태우</td>
						<td>계</td>
					</tr>
					<tr>
						<td>우시장 소</td>
						<td>99두</td>
						<td>67.7%</td>
						<td>9.2%</td>
						<td>10.1%</td>
						<td>13.1%</td>
						<td>100%</td>
					</tr>
					<tr>
						<td>사육장 소</td>
						<td>188두</td>
						<td>61.2%</td>
						<td>9.6%</td>
						<td>9.0%</td>
						<td>20.2%</td>
						<td>100%</td>
					</tr>
				</table><br/>
				<p style={{lineHeight: '200%'}}>즉 모든 번식장애를 품종별, 원인별로 번식장애를 100%로 볼 때 난소질환에 기인한는 번식장애우가 한우의 경우 82.4%, 젖소의 경우 67.8%, 도입육우는 93.0%에 이른다. 이와같이 번식장애의 대부분이 난소의 이상에서 오는 경우가 많다. 기타 세균성 질환이나 영양장애 및 해부학적 이상등도 번식장애의 원인이 되기도 하나 그리 중요치 않다. 따라서 난소의 기능에 기인하는 번식장애만 치료한다면 모든 번식장애의 70 - 80%는 치료하는 셈이다.</p>
				<h2>번식장애의 원인</h2>
				<ul>
					<li>교배실패
						<ul>
							<li>미경산우
								<ul>
									<li>난소형성 불충분, 프리마틴</li>
									<li>성장지연(영양장애)</li>
									<li>발정발견 실패</li>
								</ul>
							</li>
							<li>경산우
								<ul>
									<li>분만 후 발정 미재귀(영양장애, 난소낭종, 난소위축)</li>
									<li>발정발견 실패</li>
								</ul>
							</li>
						</ul>
					</li>
					<li>수정실패
						<ul>
							<li>난관 형성부진, 난관폐쇄, 난관염</li>
							<li>난포세포이상 </li>
							<li>수정액 이상, 수정실패(시기,부위)</li>
						</ul>
					</li>
					<li>임신실패
						<ul>
							<li>태아의 조기 사망 </li>
							<li> 유산( 감염, 독성물질, 물리적 충격. 내분비 이상)</li>
						</ul>
					</li>
					<li>송아지사망
						<ul>
							<li>난산 등 물리적 손상 </li>
							<li>세균감염</li>
						</ul>
					</li>
				</ul>
				<h2>불임의 발생원인</h2>
				<ul>
					<li>불임의 원인은 여러 요인이 있으나 그중 60 - 70%는 주로 사양관리의 불량에 기인한다. </li>
					<li>난소의 발육 부진이나 기능장애로 인한 무발정, 이상발정, 수정불능, 수정란의 착상장애 </li>
					<li>과도한 영양실조나 과비 등 영양장애로 인한 난자 생산, 난소 난포발육, 배란, 수태, 임신유지, 태아발육 등의 이상 </li>
					<li>각종 성Hormone의 분비이상으로 생식기관의 발육불량 및 기능이상</li>
					<li>분만중 미생물들 감염으로 인한 생식기관의 질병 </li>
					<li>선천적인 생식기 기형이나 프리마틴 같은 해부학적 이상 </li>
					<li>종부 또는 인공수정 방법의 잘못등<br/>
						<img src={Pic160} style={{border: '0px', padding: '0px', borderRadius: '0%',  width: '45%'}} alt=""/>
						<p style={{width: '45%', textAlign: 'center'}}>외부환경온도가 경산우 및 초임우의 1회 수정시 수태율에 미치는 영향</p>
						<img src={Pic161} style={{border: '0px', padding: '0px', borderRadius: '0%',  width: '45%'}} alt=""/>
						<p style={{width: '45%', textAlign: 'center'}}>번식장애를 일으키는 사양관리상의 원인</p>
						<img src={Pic162} style={{border: '0px', padding: '0px', borderRadius: '0%',  width: '45%'}} alt=""/>
						<p style={{width: '45%', textAlign: 'center'}}>수척과 과비가 번식장애 발생에 미치는 영향</p>
						<img src={Pic163} style={{border: '0px', padding: '0px', borderRadius: '0%',  width: '45%'}} alt=""/>
						<p style={{width: '45%', textAlign: 'center'}}>저수태우 발생요인</p>
					</li>
				</ul>
				<h2>자주 발생하는 번식장애</h2>
				<ul>
					<li>난소질환
						<ul>
							<li>난소 발육부진 
								<ul>
									<li>생후 일정기간이 지나면 난포는 발육하여 발정을 일으키지만 사양관리가 나쁘거나 영양이 부족할 때 (TDN, 단백질, Vitamin A, B, 칼슘, 인산염) 난소가 발육을 못하여 난포가 정상적으로 자라지 못한다. </li>
								</ul>
							</li>
							<li>난소휴지 
								<ul>
									<li>난소는 보통 크기이고 난포가 발육하지 않으며 황체가 없기 때문에 직장검사로 촉진함으로써 알 수 있다. 고능력우에서 많이 나타난다. </li>
								</ul>
							</li>
							<li>난소위축 
								<ul>
									<li>난소는 작고 탄력성이 없으며 난포는 발육하지 않고 발정은 정지된다. 고능력우에서 많이 발생하며 특히 겨울철에서 봄철에 이르는 계절에 발생확률이 높다.</li>
								</ul>
								<img src={Pic164} style={{width: '50%'}} alt=""/>
								<p style={{width: '50%', textAlign:'center'}}>번식장애의 원인</p>
							</li>
							<li>미약발정 또는 둔성발정 
								<ul>
									<li>무발정 증상과 비슷하나 미약발정은 배란이 없는 것으르 말하며 둔성발정은 배란이 있는 것을 말한다. 특히, 소를 축사내에서 사육하는 기간이 길어서 운동량이 부족한 겨울철과 고능력우에서 발생하기 쉽다. </li>
								</ul>
							</li>
							<li>무배란성 발정
								<ul>
									<li>발정은 정상이나 배란이 일어나지 않는 것을 말하는데 이것은 뇌하수체 전엽의 LH가 부족하기 때문에 일어나고 난포는 도중에 페쇄 소실된다.</li>
								</ul>
							</li>
							<li>배란잔유 
								<ul>
									<li>Hormone 관계의 부조화로 배란이 지연되는 경우이다. </li>
								</ul>
							</li>
							<li>황체잔유(영구황체) 
								<ul>
									<li>황체는 생리적으로 임신시 이외는 일정기간이 경과하면 퇴행되는 것이 정상이지만 Hormone관계의 부조화로서 오랫동안 잔류하여 다음 발정을 억제하거나 자궁내 이물질이 있을 때에도 황체는 잔류한다. 비유량이 많은 소에서 발생한다. </li>
								</ul>
							</li>
							<li>지속성 발정 
								<ul>
									<li>지속시간은 20시간 전후이나 한도를 넘어서 발정이 지속되는 것을 말하며 난포낭종의 대부분이 이와 같은 증상을 보인다. </li>
								</ul>
							</li>
							<li>난포낭종
								<ul>
									<li>난포가 어떤 원인에 의해 배란되지 않고 성숙난포 이상의 크기에 달하여 난자가 사멸하거나 난포액이 흡수되지 않고 남아있게 되어 계속적으로 다량의 Estrogen이 방출되고 상사병을 일으킨다. 난포낭종 걸린 개체중 무발정으로 나타나는 경우는 약 20%정도 되는데 난포의 내협막세포가 변성 퇴화되어 발정이 오지 않는다. </li>
								</ul>
							</li>
							<li>황체 낭종 
								<ul>
									<li>난포가 파열 후 난포액이 완전히 배출되지 못한체 황체가 형성되어 발생되는 증상으로 LTH, LH등의 Hormone 부족에서 기인된다.</li>
								</ul>
							</li>
						</ul>
					</li>
					<li>자궁질환
						<ul>
							<li>자궁경관의 협착 
								<ul>
									<li>선천성 협착은 치유가 되지 않아 인공수정이 불가능하나 발정시 개장이 불완전한 것은 40 - 42℃ 온수나 생리식염수를 자궁질부에 관주한 후 자궁질부와 경부를 마사아지 하여 인공수정을 하면 효과가 있다. </li>
								</ul>
							</li>
							<li>자궁경관염 
								<ul>
									<li>세균의 감염에 의해서 발생하며 분만시의 창상, 인공수정시 오염된 주입기사용 및 무리한 주입등으로 일어난다. </li>
								</ul>
							</li>
							<li>자궁의 위축 
								<ul>
									<li>직장검사시 자궁은 작고 유연하여 수축력이 약하고 자궁벽이 얇다. 난소 위축등 난소기능장애와 수반되는데 주로 비유량이 많은 소, 영양이 부족한 소, 노령우 등에서 나타나는데 이때는 사양관리개선과 자궁을 마사지 해주어 난소기능 부진에 대한 치료를 해야 한다. </li>
								</ul>
							</li>
							<li>자궁내막염 
								<ul>
									<li>난산, 조산시, 후산정체 제거할 때 소독 불충분, 산후 외음부 및 우사가 불결 할 때, 인공수정시 오염된 주입기 사용 등으로 일어난다.</li>
								</ul>
							</li>
							<li>자궁축농증
								<ul>
									<li>자궁경의 폐쇄에 의해 농이 자궁내 저류되어 있어 직장검사시 자궁각의 대칭이 없어지고 같은 크기로 종대되어 있으며, 자궁벽은 두껍고 난소에는 가끔 황체가 남아있다.</li>
								</ul>
							</li>
						</ul>
					</li>
					<li>선천성 생식기 기형 및 freematrin 등</li>
				</ul>
				<h2>번식장애우 치료 및 예방대책</h2>
				<ul>
					<li>번식장애, 특히 난소장애는 근본적으로 호르몬과 깊은 관련이 있다. 따라서 번식장애의 원인을 찾아내고, 치료대책을 마련하기 위해서는 내분비에 관한 기본적인 사항을 이해하고 있어야 한다. 시상하부- 뇌하수체 - 성선 - 부생식계와 시상하부 - 뇌하수체 - 갑상선과 부신피질 - 체세포계 및 시상하부 - 태반 - 자궁계의 내분비적 상관관계를 잘 알아야 한다.
						<ul>
							<li>사양표준에 의한 사료급여로 지나친 저영양이나 과비를 방지</li>
							<li>양질의 조사료 위주 사양과 농후사료 제한 급여 </li>
							<li>충분한 운동과 철저한 개체 관리 </li>
							<li>철저한 위생관리</li>
						</ul>
					</li>
				</ul>
			</div>
  );
}

export default Do23;
