import React from 'react';
import MilkPlanMating from 'containers/milk/milkplanmating/MilkPlanMating';

function MilkPlanMatingPage() {
  return (
    <MilkPlanMating />
  );
}

export default MilkPlanMatingPage;
