import React from 'react';
import Greetings from 'containers/web/business/greetings/Greetings';
import { Helmet } from 'react-helmet-async';

function GreetingsPage() {
  return (
    <>
      <Helmet>
        <title>인사말</title>
      </Helmet>
      <Greetings />
    </>
  );
}

export default GreetingsPage;
