import React from 'react';

function Fo34() {

  return (
    <div className="techinfo_view">
				<p className="viewTitle">식이성 인 결핍증</p>
				<dl>
					<dt>발병대상</dt>
					<dd>주로 소에서 발명하며 면양에서는 드물다</dd>
				</dl>
				
				<dl>
					<dt>원인</dt>
					<dd>사료중에 함유되어 있는 인의 이용성이 낮기 때문에 발생된다</dd>
				</dl>
				
				<dl>
					<dt>징후 및 증상</dt>
					<dd>소의 경우에는 성장률 저하, 사료효율 저하, 식율저하(뼈, 나무, 털, 넝마등을 씹음), 관절의 경직, 골연화증 등이 발생한다. 유우의 경우는 유열이 발생하기 쉽다</dd>
				</dl>
				
				<dl>
					<dt>질병분포와 손실규모</dt>
					<dd>전세계를 걸쳐 발생한다</dd>
				</dl>
				
				<dl>
					<dt>치료</dt>
					<dd>적당한 인 용액의 정맥내 투여 또는 사료에 골분, 인산칼슘과 인을 공급원을 섞어 준다</dd>
				</dl>
				
				<dl>
					<dt>대책</dt>
					<dd>사료에 섞어주거나 자유 섭취하도록 인 공급원을 급여한다</dd>
				</dl>
				
				<dl>
					<dt>예방</dt>
					<dd>인 공급원을 미네랄 블록 형태로 자유 급여하거나 사료에 섞어 급여한다. 칼슘과 인의 비율은 4:1에서 1:1사이를 유지해준다</dd>
				</dl>	
				
				<dl>
					<dt>참고</dt>
					<dd>일반적으로 인 결핍증은 방목지에서 발생한다. 인을 비료로써 초지에 주는 것도 하나의 대책이다</dd>
				</dl>
			</div>
  );
}

export default Fo34;
