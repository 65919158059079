import React, { useState, useEffect } from 'react';
import { useNavigate, useOutletContext, useLocation } from 'react-router-dom';
import { getState } from 'utils/api';

function BuyBoardList() {
  const navigate = useNavigate();
  const location = useLocation();
  const { setOrderInfoPasswordArea, setOrderInfoPwd, setOrderInfoId } =
    useOutletContext();

  const [semenData, setSemenData] = useState(null);
  const [totalPages, setTotalPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  const baseDir = location.pathname.toLowerCase().replace('web/', '');
  const props = { page: currentPage };

  const fetchSemenData = () => {
    getState(baseDir, props)
      .then((getdata) => {
        setTotalPages(getdata.count);
        setSemenData(getdata.results);
      })
      .catch((error) => {
        console.log(error.response);
        console.log(error.message);
      });
  };

  useEffect(() => {
    fetchSemenData();
  }, [currentPage]);

  // 페이지 네이션
  const itemsPerPage = 10;
  const pagesToShow = 10;

  const currentPageGroup = Math.ceil(currentPage / pagesToShow);
  const startPage = (currentPageGroup - 1) * pagesToShow + 1;
  const endPage = Math.min(
    currentPageGroup * pagesToShow,
    Math.ceil(totalPages / itemsPerPage),
  );
  const pageNumbers = Array.from(
    { length: endPage - startPage + 1 },
    (_, index) => startPage + index,
  );

  return (
    <div className="buyBoardList">
      <div className="tablebox">
        <table>
          <thead>
            <tr>
              <th>성명</th>
              <th>주문일자</th>
              <th>공급희망</th>
              <th>씨수소수</th>
              <th>주문량</th>
              <th>금액&#40;만원&#41;</th>
              <th>처리여부</th>
            </tr>
          </thead>
          <tbody>
            {semenData &&
              semenData.map((orderList, i) => (
                <tr key={i}>
                  <td>
                    <button
                      type="button"
                      onClick={() => {
                        setOrderInfoPasswordArea(true);
                        setOrderInfoPwd(orderList.비밀번호);
                        setOrderInfoId(orderList.주문번호);
                      }}
                    >
                      {`${orderList.주문자명.slice(0, -2)}**`}
                    </button>
                  </td>
                  <td>{orderList.주문일자}</td>
                  <td>{orderList.공급희망일}</td>
                  <td>{orderList.씨수소수}</td>
                  <td>{orderList.주문량}</td>
                  <td>{orderList.금액 / 10000}</td>
                  <td>
                    {orderList.처리여부 === '0' ? '처리대기' : '처리완료'}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <div className="buySemenBtn">
        <div className="pagenationBtn">
          <button
            type="button"
            className="goFirst"
            onClick={() => setCurrentPage(1)}
          >
            <span className="hidden">맨 처음으로</span>
          </button>
          <button
            type="button"
            onClick={() => setCurrentPage(currentPage - 1)}
            className="goPrev"
            disabled={currentPage === 1}
          >
            <span className="hidden">이전 페이지로</span>
          </button>
          {pageNumbers.map((page) => (
            <button
              type="button"
              key={page}
              onClick={() => setCurrentPage(page)}
              className={currentPage === page ? 'current' : ''}
            >
              {page}
            </button>
          ))}
          <button
            type="button"
            onClick={() => setCurrentPage(currentPage + 1)}
            className="goNext"
            disabled={Math.ceil(totalPages / 10) <= currentPage}
          >
            <span className="hidden">다음 페이지로</span>
          </button>
          <button
            type="button"
            className="goEnd"
            onClick={() => setCurrentPage(Math.ceil(totalPages / itemsPerPage))}
          >
            <span className="hidden">맨 끝으로</span>
          </button>
        </div>
        <button
          type="button"
          className="buySemenOrderBtn"
          onClick={() => navigate('order')}
        >
          온라인 주문하기
        </button>
      </div>
    </div>
  );
}

export default BuyBoardList;
