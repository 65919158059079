import React from 'react';


function Do16() {

  return (
    <div className="techinfoview">
				<h1>인공수정이란</h1>
				<h2>인공수정의 정의</h2>
				<p>인공수정이란 난자와 정자의 결합을 자연교미에 의하지 않고, 인위적으로 수가축의 정액을 암가축의 생식기내에 주입하여 수태시키는 것을 의미한다.</p>
				<h2>인공수정의 장/단점</h2>
				<ul>
					<li>장점
						<ul>
							<li>가축 개량 촉진 생산성 향상 및 우량 종모축의 이용 범위가 확대 우량 종모축의 엄격한 선발을 통하여 특정 경제형질의 이용 범위가 확대 될 수 있어 가축개량을 조기에 이룰 수 있다. 자연교미에 의해서는 일회의 사정 량을 그대로 이용할 때는 암소에 약 2두 밖에 수태시키지 못하나 인공수정으로 는 일회의 사정량으로 수백두의 암소를 수정시켜 우수한 종축능력을 단기간에 확대 보급할 수 있어 가축개량을 현저하게 촉진할 수 있다.</li>
							<li>종모축의 사양 관리 및 노동력 절감 적은 수의 종축으로서 많은 수의 암소를 수태시킬 수 있으므로 종모축 사육두수를 감소시켜 종모축 사육에 소요되는 사료지 절감, 이에따른 노동력 절감을 통해 생산성을 높일 수 있다.</li>
							<li>종모축의 유전능력 조기 판정 어느 종모축의 유전자를 이어 받은 자축이 단기간 내에 많은 수의 자손이 태어나므로 아비의 유전 능력을 조기에 판정할 수 있다. 젖소나 한우의 후대 검정사업에서 실시하는 다두의 검정용 송아지를 생산하여 보증 종모우를 선정할 수 있는 것도 인공수정의 장점이다.</li>
							<li>전염성 생식기 질병 예방 지연교미에 의한 종모축의 직접적인 생식기 접촉으로 전염되는 트리코나므스병, 비브리오병, 브루셀라병 및 질염등 각종 생식기 질병을 예방할 수 있다. 인공수정을 축산에 도입한 최초의 동기는 가축개량보다는 생식기 질병을 예방한다느 목표에서 시작되었다고 해도 과언이 아닐성 싶다.</li>
							<li>수태율 향상 암소의 한 발정기에 2-3회의 반복수정이 가능하므로 자연교배보다 수태율을 높일 수 있다. 또한 봄, 가을은 정자 생산력이 왕성하고 정액 성상이 양호한 시기에 채취하여 냉동보존한 후 하절기나 동절기에도 이용이 가능하다.</li>
							<li>자연 교미가 불가능한 개체의 번식이용 가능 종모축의 접촉기피 및 후구빈약, 질병등으로 자연교미가 불가능한 경우 또는 생리적인 결합이나 생식기 구조의 이상으로 어려움을 겪을 때 인공수정을 실시함으로써 암소의 이용 효율을 증대 시킬 수 있다.</li>
							<li>정액의 원거리 수송 가능 교미를 위한 가축의 수소이 불필요하며 거리가 멀어도 정액을 간편하고 신속히 운송하여 수정을 실시할 수 있고, 국제무역으로 수정란이 도입되어 수정되는 것도 인공수정의 응용이라 볼 수 있다.</li>
							<li>학문 연구 수단 가축의 번식에 이용될 뿐만 아니라 생물학 연구의 수단으로도 이용가치가 있다. 체외수정을 통한 정자의 생리학적 연구와 종간 잡종의 조성 수단으로서 또는 물리적, 화학적 처리 연구 등이 행해질 수도 있다.</li>
						</ul>
					</li>
					<li>단점
						<ul>
							<li>숙련된 기술자와 시설이 필요 인공수정 훈련과 교육을 통한 숙련된 인공수정사가 필요하며 정액저장을 위한 액체질소소통, 수정용 스트로 주입기 및 절단기등 수정기구를 갖추어야 한다.</li>
							<li>자연교미보다 1회 수정시 많은 시간 소요 한 번의 교배를 위한 냉동정액의 융해 조작과 암소의 생식기에 정액을 주입하는 작업을 하는데 자연교배보다 많은 시간과 노력이 필요하다.</li>
							<li>생식기 전염병 발생 및 생식기 점막 손상 기구 세척과 소독 부주의, 정액 처리의 부주의, 기술의 결함등으로 질병감염 확산시킬 우려가 있고 특히 미숙련자의 기술 숙련도가 낮을 경우 생식기의 손상등으로 질병이 발생할 우려가 높다.</li>
							<li>종모축 이상(잘못) 선발시 불량 유전형질의 조기 확산 및 수습 곤란 정액 자체의 유전형질이 나쁠때나 질병의 전염원이 있을 경우 암소를 통한 질병의 확산으로 많은 피해가 우려된다. 소의 경우 1회 채취된 정액량으로 적게는 200두 많게는 400두까지도 수정시킬 수 있는 양이므로 각별한 주의를 요한다.</li>
						</ul>
					</li>
				</ul>
				<h2>인공수정의 역사</h2>
				<ul>
					<li>국내
						<p>우리나라 가축 인공수정은 처음에는 돼지를 중심으로 보급되었으나 그후 낙농업의 급속한 성장과 더불어 인공수정의 중심도 돼지에서 젖소를 발전되기에 이르렀다. 최근에는 이러한 기술 발달로 육우는 물론 한우의 인공수정 보급이 확산되었다. 축협중앙회의 통계에 의하면 한우, 육우, 유우, 교잡우 및 돼지의 인공수정 실적을 살펴보면 다음과 같다.<br/><br/><b>[연도별 가축인공수정 실적(국내)]</b></p>
						<table className="tablestyle">
							<tr>
								<td>연도</td>
								<td>한우(두)</td>
								<td>젖소(두)</td>
								<td>육우(두)</td>
								<td>교잡우(두)</td>
								<td>돼지(두)</td>
							</tr>
							<tr>
								<td>1962</td>
								<td></td>
								<td>758</td>
								<td></td>
								<td></td>
								<td>30,745</td>
							</tr>
							<tr>
								<td>1965</td>
								<td>1,270</td>
								<td>2,597</td>
								<td></td>
								<td></td>
								<td>46,277</td>
							</tr>
							<tr>
								<td>1970</td>
								<td>34,490</td>
								<td>9,129</td>
								<td></td>
								<td></td>
								<td>11,674</td>
							</tr>
							<tr>
								<td>1975</td>
								<td>106,310</td>
								<td>33,926</td>
								<td></td>
								<td></td>
								<td>46,185</td>
							</tr>
							<tr>
								<td>1980</td>
								<td>274,636</td>
								<td>136,117</td>
								<td>9,862</td>
								<td>3,625</td>
								<td>38,520</td>
							</tr>
							<tr>
								<td>1981</td>
								<td>351,760</td>
								<td>145,501</td>
								<td>10,299</td>
								<td>5,882</td>
								<td>48,234</td>
							</tr>
							<tr>
								<td>1982</td>
								<td>506,304</td>
								<td>163,470</td>
								<td>11,541</td>
								<td>10,737</td>
								<td>44,564</td>
							</tr>
						</table><br/>
						<ul>
							<li>우리나라 최초 인공수정 기술 보급 : 1954년</li>
							<li>1945-1956년 : 이용빈 교수 - 미국 미네소타대학에서 동결정액에 대한 산기술로 인공수정 보급에 노력</li>
							<li>1958년 : 박항균 교수 - 강원도 인공수정 보급 노력 </li>
							<li>1961년 : 오대균, 임경순 - 농사원 축산부 가축과에 인공수정계 설림 인근 부락 인공수정 보급(한우 30두, 돼지 124두) </li>
							<li>1968년 : 국립종축장에 인공수정과, 축산시험장에 가축번식연구실 설치 </li>
							<li>1962년 : 가축의 본격적인 인공수정 실시</li>
							<li>1962년 : 김선환 박사 - 농협중앙회 가축인공수정소 설치 </li>
							<li>1966년 : 각도 지소 설치 및 시,군에 인공수정소 설치 (전국망으로 확대) </li>
							<li>1976년 : 동결정액을 보급하는 체계를 갖추게 됨 </li>
							<li>1979년 : 축산진흥회 가축개량사업소로 전환</li>
							<li>1982년 : 축협중앙회 유우개량사업소, 한우개량사업소로 개칭 </li>
							<li>1996년 : 현재 축협중앙회 개량사업본부로 개칭된 후 젖소 및 한우 동결 정액 공급</li>
						</ul><br/>
						<p><b>[주요국가의 소 인공수정 보급률 추정치]</b></p>
						<table className="tablestyle">
							<tr>
								<td>국가</td>
								<td>보급률추정치(%)</td>
								<td>국가</td>
								<td>보급률추정치(%)</td>
							</tr>
							<tr>
								<td>이스라엘</td>
								<td>99</td>
								<td>프랑스</td>
								<td>70</td>
							</tr>
							<tr>
								<td>핀란드</td>
								<td>99</td>
								<td>독일</td>
								<td>70</td>
							</tr>
							<tr>
								<td>일본</td>
								<td>96</td>
								<td>구소련</td>
								<td>70</td>
							</tr>
							<tr>
								<td>체코</td>
								<td>94</td>
								<td>네덜란드</td>
								<td>70</td>
							</tr>
							<tr>
								<td>불가리아</td>
								<td>92</td>
								<td>호주</td>
								<td>70</td>
							</tr>
							<tr>
								<td>덴마크</td>
								<td>90</td>
								<td>영국</td>
								<td>65</td>
							</tr>
							<tr>
								<td>뉴질랜드</td>
								<td>90</td>
								<td>캐나다</td>
								<td>60(젖소) 11(육우)</td>
							</tr>
							<tr>
								<td>노르웨이</td>
								<td>87</td>
								<td>미국</td>
								<td>65(젖소) 6(육우)</td>
							</tr>
							<tr>
								<td>폴란드</td>
								<td>75</td>
								<td>이란</td>
								<td>55</td>
							</tr>
						</table><br/>
					</li>
					<li>외국
						<ul>
							<li>1320년 : 아라비아 - 암말 생식기내 솜 삽입 - 인공수정의 시초 </li>
							<li>1780년 : 이탈리아 - 개 30두 인공수정 18두 임신 - 최초 성공 기록 </li>
							<li>1890년 : 유럽 - 말 인공수정 실시 </li>
							<li> 말의 정액을 우유로 희석, 자궁에 주입 </li>
							<li>덴마크, 암말 8두에 인공수정 보급 시사 </li>
							<li>1902년 : 축산학회 - 가축개량을 위하여 인공수정 보급 시사 </li>
							<li>1936년 : 가축 인공수정협회 창립 - 젖소 인공수정, 전염병 방지 </li>
							<li> 1907년 : 소련 - 이바노프 인공수정 도입 개발 (창시자) </li>
							<li>1912년 : 일본 - 이바노프부터 연수한 석천 </li>
							<li>1939년 : 미국 - 뉴저지, 인공수정협회 설립 </li>
							<li>1970년 : 동결정액 연구, 소 인공수정 산업화 추진</li>
						</ul>
					</li>
				</ul>
				<h2>인공수정 관련 축산 법규</h2>
				<ul>
					<li>축산법의 목적과 배경
						<p>가축의 개량과 보호 그리고 축산물의 수급과 가격 안정에 관한 사항을 규정함으로서 축산업의 발전을 도모하기 위하여 축산법을 개정 공포(1986년 6월26일)</p>
					</li>
					<li>인공수정사의 면허
						<ul>
							<li>자격
								<ul>
									<li>대학 및 전문대 축산학과 졸업자로 가축번식 또는 인공수정과목을 이수한 자</li>
									<li>수의사</li>
									<li>농림부령이 정하는 바에 따른 시도지사가 시행하는 수정사 시험에 합격한 자</li>
								</ul>
							</li>
							<li>신청서류
								<ul>
									<li>소정 서식(관련 시, 도청)</li>
									<li>수의사 면허증, 수정사 시험 합격증, 가축번식학 이수 학력증명서 등</li>
									<li>건강 진단서</li>
								</ul>
							</li>
							<li>면허증 분실 및 훼손시
								<ul>
									<li>재교부 신청서 및 분실사유서 첨부하여 도지사에 제출</li>
								</ul>
							</li>
							<li>수정사의 시험
								<ul>
									<li>실시 기관 : 시,도지사</li>
									<li>시험 공고 : 시험 시행일 30일전 공고 </li>
									<li>공고 내용 : 시험일시, 장소, 시험과목, 응시절차, 기타 필요사항</li>
								</ul>
							</li>
							<li>응시자 요건
								<ul>
									<li>농림부 장관이 지정한 기관에서 1개월이상 인공수정에 관한 교육을 이수한 자</li>
									<li> 외국에서 1개월 이상 가축인공수정에 관한 교육을 이수한 자 </li>
									<li> 외국에서 수정사의 면허증을 받은 자</li>
								</ul>
							</li>
							<li>시험 과목
								<ul>
									<li>축산법, 가축 전염병 예방법 </li>
									<li>가축 번식학, 가축 위생학 </li>
									<li>가욱 인공수정 실기</li>
								</ul>
							</li>
							<li>수정사의 결격요건 (법 제 16조 2)
								<ul>
									<li>금치산자, 한정치산자 </li>
									<li>정신병 질환자, 마약 중독자 </li>
									<li>신체 장애로 업무 수행 불가자</li>
								</ul>
							</li>
							<li>수정사의 면허 취소 (법 제 17조)
								<ul>
									<li>면허의 취소 및 6개월 이하의 기간에 업무의 정지 </li>
									<li>법 제 16조 2항에 해당할 때</li>
									<li>인공수정소의 적합한 시설 기준 위배시 (법 제 18조) </li>
									<li>정액의 사용 제한을 위배시 (법 제 22조)
										<ul>
											<li>정액 증명서가 없는 정액의 사용</li>
											<li>농림부 기준에 미달되는 정액의 사용 </li>
										</ul>
									</li>
									<li>행정 명령이나 처분에 대한 위배, 검사 거부 방해 또는 기피시</li>
								</ul>
							</li>
							<li>인공수정소의 등록 및 절차 (법 제 18조)
								<ul>
									<li>농림부령 기준에 적합한 시설을 갖추고 시장, 군수, 구청장에게 등록</li>
								</ul>
							</li>
							<li>수정소의 등록 취소 (법 제 19조)
								<ul>
									<li>시설 기준에 미달될 때 </li>
									<li>수정소를 개설한 수정사의 면허가 취소될 때</li>
								</ul>
							</li>
							<li>가축 인공수정사 및 정액 공급의 제한 (법 제 20조)
								<ul>
									<li>수정사가 아니면 정액을 채취 처리하거나 인공수정을 해서는 아니됨(단,학술용과 자가 사육 가축에 대해서는 예외) </li>
									<li>수정소가 채취 처리된 정액 또는 수입한 정액이 아니면 타인에게 공급, 판매 금지</li>
								</ul>
							</li>
							<li>정액 및 인공수정 증명서 발급 (법 제 21조)
								<ul>
									<li>정액을 채취 처리하는 자는 정액증명서를 발급 </li>
									<li>수정사가 인공수정시 가축 인공수정 증명서를 발급</li>
								</ul>
							</li>
							<li>정액의 사용 제한 (법 제 22조)
								<ul>
									<li>정액 증명서가 없는 것 </li>
									<li> 기준에 미달되는 정액</li>
								</ul>
							</li>
							<li>가축 인공수정사의 감독 (법 제 23조)
								<ul>
									<li> 도지사, 시장, 군수, 구청장은 수정사 및 수정소에 대하여 가축개량에 필요한 사항 및 소속 공무원에게 당해 시설, 장부, 서류 및 기타 필요 물건을 검사케 할 수 있음.</li>
								</ul>
							</li>
						</ul>
					</li>
					<li>자가인공수정농가의 실행
						<p style={{lineHeight: '200%'}}>상업적인 인공수정은 면허를 소지한 자에 한하여 가능하다. 그런데 근래에 국내 축산농가에서의 자가 인공수정 제도가 급속히 보급되고 있다. 송아지 생산비는 물론 농후사료의 가격 상승으로 인한 사육비 증가등 많은 어려움을 겪고 있다. 이로 인하여 일반 양축가들은 점점 자연 종부로 송아지 생산을 확산하고 있으며 인공수정을 등한시 하고 있는 실정이다. 인공수정을 시술하려면 풍부한 이론과 숙달된 기술을 필요로 하고, 일정한 장비를 구비해야 가능하다. 미숙한 시도로 예상치 못한 결과를 초래하기도 한다. 이러한 문제점등을 사전에 인식하고 인공수정을 배우려는 사람에게는 다음과 같은 기본자세를 갖추는 것이 중요하다. 자가인공수정을 실시하려는 농가의 기본자세를 살펴보면 사양관리 철저, 번식기록부의 작성, 번식관리 및 발정확인 철저, 번식장애우 관리 철저, 신중한 정액 선택, 정확한 정액의 융해 및 취급, 수정결과의 속단 금물등을 들 수 있다.</p>
					</li>
				</ul>
			</div>
  );
}

export default Do16;
