import React from 'react';

import pic423 from 'assets/images/sub/_images/pic421.gif';

function Do218() {

  return (
    <div className="techinfoview">
      <h1>분뇨의 수거</h1>
      <p className="pstyle">&nbsp;&nbsp;&nbsp;분뇨를 수거하고 운반하는 방법에는 몇 가지가 있는데, 방법을 선택할 때는 ①우사의 형태 ②노동력 요구정도 ③자본 투자정도 ④분뇨 처리체계 등을 고려하여야 한다. 일반적으로 후리스톨 우사의 분뇨는 반고체 상태로 취급되며 우사밖의 저장시설에 저장된다. 우사 밖으로 분뇨를 제거할 때는 ①기계식이나 트랙터 스크래퍼 ②수세식 물받이 통로(flushing gutter) ③자연 유출식 배출로(gravity-flow channel) 등을 이용한다. <br/>&nbsp;&nbsp;&nbsp;소들이 착유실로 이동할 때마다 후리스톨 통로의 분뇨를 제거하여 우사의 청결을 우지시키면, 유방염의 감염발생과 착유시 유방을 세척하는 시간을 감소시킬 수 있다. 운동장 등과 같은 개방된 시설의 분뇨는 고체나 반고체 형태로 수거 저장되고, 이들 시설로부터 유출되는 분뇨, 흙, 부스러기, 화학물질 등이 섞인 빗물(runoff)은 분뇨나 폐수 저장시설로 유입되도록 한다. 그러나 지붕, 차도, 작물재배지 등으로부터 유출되는 빗물은 상대적으로 깨끗한 상태이기 때문에 분뇨나 폐수 저장시설로 유입되지 않도록 한다. </p>
      <ul>
        <li>트랙터 스크래퍼 
          <ul>
            <li>후리스톨 통로, 루즈반 및 운동장의 분뇨를 수거할 때는, 일반적으로 날(blade), 버켓(bucket), 또는 스키드 로더(skid laoder)가 부착된 트랙터를 이용한다. 콘크리트 바닥이 매끈해지는 것을 줄이기 위해 트랙터 타이어로 만든 고무 스크래퍼를 이용할 수 있으나 겨울철에 얼어붙은 분뇨는 끝이 철로 처리된 버켓이나 날을 이용하여 제거한다. </li>
          </ul>
        </li>
        <li>기계식 통로 스크래퍼 (자동스크래퍼) 
          <ul>
            <li>기계식 통로 스크래퍼 1∼2개의 날, 날을 당기는 케이블이나 체인, 0.5∼1짜리 전기모터 및 제어기로 구성된다. 날과 통로의 폭을 같게 하여 한 번의 작동으로 통로를 완전히 스크리핑할 수 있도록 하며, 일일 2∼4회 작동되도록 시간 조절을 해 놓을 수 있다. 날씨인 경우에는 계속해서 스크래퍼가 작동되도록 하여 날이 바닥에 얼어붙지 않도록 한다. 날씨가 아주 추울 때는 기계식 스크래퍼로는 얼어붙은 분뇨를 제거하기 어려우므로 대안으로 트랙터 스크래핑으로 분뇨를 제거할 수 있어야 한다. 기계식 스크래퍼는 노동력을 절감할 수 있는 장점이 있으나 부식이나 고장에 따른 유지비가 많이 드는 단점이 있다. </li>
          </ul>
        </li>
        <li>수세식 통로 
          <ul>
            <li>후리스톨 통로, 착유실 및 대기장은 수세식으로 분뇨를 제거할 수 있다. 통로의 폭과 기울기에 따라 수세물의 요구량이 표 92에 나타난 바와 같이 달라지는데, 분뇨를 잘 제거시키기 위해서는 통로의 기울기에 따라 적절한 수심과 유속이 유지되도록 신경을 써야 한다.  후리스톨 통로를 수세식으로 청소하려면 많은 물이 소모되므로, 고체 분리시설로부터 분리되는 액체를 재순환하여 후리스톨 통로를 청소할 수 있다. 그러나 착유실과 대기장은 재순환 물로 청소해서는 안된다. 착유실 바닥의 기울기를 2%로 하면 1%로 했을 때보다 수세물 소모량을 40%∼50% 감소시킬 수 있다. </li>
          </ul>
          <p><b>&lt;표 92&gt; 수세식 통로의 물소모량 (10초 동안의 유속이 5fps로 가정) </b></p>
          <table className="tablestyle">
            <tr>
              <td>통로기울기,%</td>
              <td>수심, in</td>
              <td>유출율, gpm/ft (통로쪽)</td>
              <td>물소모량, 갤론/ft (통로쪽)</td>
            </tr>
            <tr>
              <td>1.0</td>
              <td>7.0</td>
              <td>1,306</td>
              <td>220</td>
            </tr>
            <tr>
              <td>1.5</td>
              <td>5.0</td>
              <td>933</td>
              <td>156</td>
            </tr>
            <tr>
              <td>2.0</td>
              <td>4.0</td>
              <td>747</td>
              <td>125</td>
            </tr>
            <tr>
              <td>2.5</td>
              <td>3.4</td>
              <td>635</td>
              <td>106</td>
            </tr>
            <tr>
              <td>3.0</td>
              <td>3.0</td>
              <td>560</td>
              <td>94</td>
            </tr>
          </table>
        </li>
        <li>틈바닥/슬랫(slotted floor) 
          <ul>
            <li>틈바닥을 사용하면 분뇨를 빠르게 제거할 수 있고 노동력이 적게 든다. 바닥의 틈새를 통하여 떨어진 분뇨는 바닥 밑에 설치된 수거조(reception pit)에 모아진 후, 자연 유출식 도관, 수세체계, 기계식 스크래퍼 등으로 치운다. 기계식 통로 스크래퍼는 설치와 보수가 곤란하기 때문에 권장되지 않는다. <br/>슬랫 밑의 수거조는 분뇨수거 뿐만 아니라 저장하는 장소로 이용할 수 있으나 우사내에 분뇨가스와 수분을 축적시켜 설비를 부식시킨다. 따라서 수거조가 있는 슬랫식 분뇨제거 시설이 되어있는 우사는 환기시설이 잘 갖추어져야 한다. 분뇨를 8개월 이상 장기 저장할 수 있는 큰 수거조는 분뇨 교반이 곤란하고 건축비용이 많이 든다. <br/>슬랫 밑으로 떨어진 분뇨를 수세식으로 제거할 수 있는데, 이 방법의 장점은 소가 수세물로부터 분리되기 때문에 보다 청결하다는 것과 수세물이 슬랫 밑에서 얼기 때문에 소가 덜 미끄러지게 된다는 것이다. </li>
          </ul>
        </li>
        <li>자연흐름/중력식 분뇨조(gravity fldw channel) 
          <ul>
            <li>슬랫 밑에 설치할 수 있는 자연 흐름식 배출로는 분뇨와 폐수를 경사진 배출로를 통하여 수거조에 흘러 들어가도록 한 후 배출파이프를 통하여 저장시설로 보내는 시설이다. 이 시설은 분뇨와 폐수를 수거하고 운반하는데 최소한의 노동력만이 요구되며, 발효작용이 분뇨의 액화를 도와주어 흐름이 유지된다. <br/>자연 흐름식 배출로의 기본 구성요소가 그림 94에 나타나 있는데, 분뇨의 이동을 도와주는 액체층이 따로 방출되는 것을 막기 위하여 분뇨가 배출되는 각 배출로 끝부분에 6 높이의 계단댐(step dam)을 설치한다. <br/>건현(乾舷; freeboard), 계단댐 및 슬랫(slat) 두께가 각각인 경우, 배출로의 깊이는 배출로와 길이에 의하여 결정되는데, 다음과 같은 공식으로 산출된다. <br/><br/>배출로 깊이, ft = 배출로의 길이, ft × 0.03 ft + 1.5&apos;<br/><br/>따라서, 배출로의 길이가 80&apos;이면, 배출로의 깊이는 3.9&apos;가 되어야 한다. 길이가 132&apos;인 배출로도 이용되긴 하지만, 배출로의 최대 권장 길이는 80&apos;이다. 따라서, 큰 후리스톨 우사의 경우에는 여러개의 계단댐을 설치하고, 중앙에 배출구를 만들어서 각 배출로의 길이가 80&apos;가 넘지 않도록 하여야 한다(그림 94). 콘크리트 블럭, 강철판, 또는 단단한 목재를 이용하거나 콘크리트를 직접 부어서 계단댐을 설치할 수 있다. 배출로를 가동하기 전에 윤활수 역할을 하는 깊이의 물층이 형성될 때까지 배출로에 물을 채운다.<br/>
              <img src={pic423} className="nonborder" width="60%" alt=""/>
              <p>그림 94. 하수식 분뇨배출로</p>
            미세하게 절단된 깔짚만을 이용하는 경우 깔짚의 사용은 두당 일당 1.4kg으로 제한한다. 착유실로부터 유출되는 살균제가 섞인 폐수가 유입되면 발효균을 사멸시킬 수 있으므로 유의해야 한다. <br/>①여름철 중에서 습도가 낮은 기간 동안에 분뇨가 건조해지거나 ②겨울철에 분뇨가 얼어붙거나 ③분뇨가 측벽에 붙게 되면, 분뇨의 흐름이 늦어지거나 중단된다. 이런 경우에는, 모든 배출로의 계단 끝부분에 물을 부어 분뇨가 다시 흐르도록 하거나, 영하의 기온인 겨울철 2∼3주 동안에는 배출로에 분뇨가 쌓여 얼도록 방치했다가, 날씨가 온화해지면 재가동시켜도 된다.	
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
}

export default Do218;