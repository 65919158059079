import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

// api
import { getState, postState } from 'utils/api';

function EditEmployee() {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const bdcCode = searchParams.get('bdc_code');

  const [employeeData, setEmployeeData] = useState({
    bdc_name: '',
    bdc_group: '',
    bdc_part: '',
    bdc_position: '',
    bdc_tel: '',
    bdc_etc: '',
  });

  useEffect(() => {
    const fetchEmployeeData = async () => {
      if (bdcCode) {
        try {
          const response = await getState('/board/select_employee', {
            bdc_code: bdcCode,
          });

          if (response?.return_code === 'SUCCESS') {
            setEmployeeData({
              bdc_name: response.result.bdc_name,
              bdc_group: response.result.bdc_group,
              bdc_part: response.result.bdc_part,
              bdc_position: response.result.bdc_position,
              bdc_tel: response.result.bdc_tel,
              bdc_etc: response.result.bdc_etc,
            });
          }
        } catch (error) {
          console.error('Error fetching employee data:', error);
        }
      }
    };

    fetchEmployeeData();
  }, [bdcCode]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEmployeeData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    // Validation
    if (!employeeData?.bdc_name) {
      alert('이름은 필수입력 항목입니다.');
      return;
    }
    if (!employeeData?.bdc_position) {
      alert('직급은 필수입력 항목입니다.');
      return;
    }

    const isEdit = !!bdcCode;
    const confirmMessage = isEdit
      ? '수정 정보를 저장하시겠습니까?'
      : '직원 정보를 등록하시겠습니까?';

    if (!window.confirm(confirmMessage)) {
      return;
    }

    try {
      const endpoint = isEdit
        ? '/board/update_employee/'
        : '/board/insert_employee/';

      const data = isEdit
        ? { ...employeeData, bdc_code: bdcCode }
        : employeeData;

      const response = await postState(endpoint, data);

      if (response?.return_code === 'SUCCESS') {
        if (isEdit) {
          alert('수정이 완료되었습니다.');
          navigate(`/bo/employee/view?bdc_code=${bdcCode}`);
        } else {
          alert('등록이 완료되었습니다.');
          navigate('/bo/employee/list');
        }
      }
      if (response?.return_code === 'ERROR') {
        alert(response?.return_message);
      }
    } catch (error) {
      console.error(
        isEdit ? 'Error updating employee:' : 'Error adding employee:',
        error,
      );
      alert(
        isEdit
          ? '수정 중 오류가 발생했습니다.'
          : '등록 중 오류가 발생했습니다.',
      );
    }
  };

  const handleList = () => {
    const message = bdcCode
      ? '수정 정보가 저장되지 않습니다. 목록으로 돌아가시겠습니까?'
      : '직원 등록이 완료되지 않았습니다. 목록으로 돌아가시겠습니까?';

    if (window.confirm(message)) {
      navigate('/bo/employee/list');
    }
  };

  return (
    <div className="employee-edit">
      <h2>{bdcCode ? '직원 정보 수정' : '직원 등록'}</h2>
      <div className="table-container bold-border">
        <table>
          <tbody>
            <tr>
              <th>
                이름
                <span className="required">*</span>
              </th>
              <td>
                <input
                  type="text"
                  id="bdc_name"
                  name="bdc_name"
                  value={employeeData?.bdc_name}
                  onChange={handleChange}
                />
              </td>
            </tr>
            <tr>
              <th>소속부서</th>
              <td>
                <input
                  type="text"
                  id="bdc_group"
                  name="bdc_group"
                  placeholder="ex)개량부"
                  value={employeeData?.bdc_group}
                  onChange={handleChange}
                />
              </td>
            </tr>
            <tr>
              <th>소속팀</th>
              <td>
                <input
                  type="text"
                  id="bdc_part"
                  name="bdc_part"
                  placeholder="ex)능력검정팀"
                  value={employeeData?.bdc_part}
                  onChange={handleChange}
                />
              </td>
            </tr>
            <tr>
              <th>
                직급
                <span className="required">*</span>
              </th>
              <td>
                <input
                  type="text"
                  id="bdc_position"
                  name="bdc_position"
                  placeholder="ex)과장"
                  value={employeeData?.bdc_position}
                  onChange={handleChange}
                />
              </td>
            </tr>
            <tr>
              <th>전화</th>
              <td>
                <input
                  type="text"
                  id="bdc_tel"
                  name="bdc_tel"
                  value={employeeData?.bdc_tel}
                  onChange={handleChange}
                />
              </td>
            </tr>
            <tr>
              <th>담당업무</th>
              <td>
                <input
                  type="text"
                  id="bdc_etc"
                  name="bdc_etc"
                  value={employeeData?.bdc_etc}
                  onChange={handleChange}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="button-group">
        <button type="button" onClick={handleSubmit} className="btn-l">
          {bdcCode ? '수정' : '등록'}
        </button>
        <button type="button" onClick={handleList} className="btn-l">
          목록
        </button>
      </div>
    </div>
  );
}

export default EditEmployee;
