import React from 'react';

import d3by3 from 'assets/images/sub/_images/3-3.jpg';
import d3by5 from 'assets/images/sub/_images/3-5.jpg';
import d3by7 from 'assets/images/sub/_images/3-7.jpg';
import d3by9by1 from 'assets/images/sub/_images/3-9-1.jpg';
import d3by9by2 from 'assets/images/sub/_images/3-9-2.jpg';
import d3by13 from 'assets/images/sub/_images/3-13.jpg';
import d3by15 from 'assets/images/sub/_images/3-15.jpg';
import d3by17 from 'assets/images/sub/_images/3-17.jpg';
import d3by19 from 'assets/images/sub/_images/3-19.jpg';
import d3by21 from 'assets/images/sub/_images/3-21.jpg';
import d3by23 from 'assets/images/sub/_images/3-23.jpg';
import d3by25 from 'assets/images/sub/_images/3-25.jpg';

function Fo58() {

  return (
    <div className="techinfo_view">
				<p className="viewTitle">송아지 상자</p>
				
				<h2>Calf hutch(송아지 상자)란?</h2>
				<ul className="listStyle04 mg15f">
					<li>막 출생한 자우를 약 2개월령까지 옥외에서 단사하는 시설.</li>
					<li>성우부터 격리함과 동시에 다른 자우로 부터도 격리한다. </li>
					<li>생후, 초유를 주어 모(毛)가 마르면 될수 있는 한 빨리 자우를 Hutch로 옮긴다. </li>
					<li>하리와 호흡기계의 질병 발생을 억제할 수도 있다.</li>
					<li>청결한 공기와 함께 추워도 자우는 건강하게 기른다.</li>
				</ul>
				<div className="imgBox mg60f"><img src={d3by3} alt="Calf hutch(송아지 상자) " /></div>
				
				<h2>Calf hutch(송아지 상자)의 재료는? </h2>
				<ul className="listStyle04 mg15f">
					<li>목재, FRP와도 같은 효과를 얻을 수 있다.</li>
					<li>폐재의 이용도 가능, 적절한 크기, 바람과 일사(日射)를 차단하는 것이 요건</li>
					<li>FRP의 Hutch는 일사를 통과시키지 않는 것을 선택한다. </li>
					<li>목제(木製)의 조립식 Kit도 있다. </li>
				</ul>
				<table className="tablestyle mg60f">
					<thead>
						<tr>
							<th className="first">FRP제</th>
							<th>조립식 Kit(재료)</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>여름은 개방하여 시원하게 겨울은 닫아서 풍(風)을 피해 미늘창식이므로 들이치지 않는다. </td>
							<td>폐재이용의 예.</td>
						</tr>
						<tr>
							<td colSpan="2"><img src={d3by5} alt=""/></td>
						</tr>
					</tbody>
				</table>
				
				<h2>Calf hutch(송아지 상자)의 크기, 구조.</h2>
				<ul className="listStyle04 mg15f">
					<li>크기는 폭 1.2m × 건물의 안길이 2.4m. </li>
					<li>외풍이 들어오지 않도록 접착제도 사용하여 기밀(氣密)로 만든다. </li>
					<li>안쪽 벽만큼은 서열(暑熱)시에 피하도록 두송(杜松):노간주나무로 막는다. </li>
				</ul>
				<p>또는 내벽의 상부에 개폐가능할 창을 붙인다. 창은 큰 쪽이 좋고 닫을 때의 기밀성을 보존하고 유지하는 것도 중요. </p>
				<ul className="listStyle04 mg15f">
					<li>기둥과 보강제(補强劑)는 외측(外側)에 대어서 내측의 요철(凹凸)을 없게 하여 세정(洗淨), 소독(消毒)을 용이하게 한다. </li>
					<li>지붕, 외벽은 방서(防暑) 효과(效果)가 높은 백색으로 도장한다. </li>
					<li>바닥에는 배수를 위해 모래를 넣는다. </li>
					<li>운동장은 반드시 취한다. </li>
				</ul>
				<div className="imgBox mg60f"><img src={d3by7} alt=""/></div>
				
				<h2>Calf hutch(송아지 상자)의 급사, 급수조.</h2>
				<ul className="listStyle04 mg15f">
					<li>포유병, 급사조, 급수조와 함께 Hutch내에 설치할 수 있도록 한다. </li>
					<li>잡아 당겨 여는 식의 창을 내어 외부로부터 포유, 급사, 급수를 가능하게 한다. </li>
				</ul>
				<div className="imgBox mg60f"><img src={d3by9by1} alt=""/> <img src={d3by9by2} alt="" className="mg20l" /></div>
				
				<h2>Calf hutch(송아지 상자)의 전면을 막아도 좋은가? </h2>
				<ul className="listStyle04 mg60f">
					<li>Hutch는 공기의 청결이 목적. 할 수 있는 한 열린 채로 한다. </li>
					<li>1/2이상 덮어서는 안된다</li>
					<li>운동장으로 나가기 어렵게 막지 않는다. </li>
				</ul>
				
				<h2>Calf hutch(송아지 상자)의 설치장소와 배치</h2>
				<ul className="listStyle04 mg15f">
					<li>배수, 양지바른 곳을 선택한다. </li>
					<li>우사와 패독(Paddock)에 가까운 곳은 피한다. 우사의 환기용 선풍기(환풍기)의 옆은 절대 좋지 않음. </li>
					<li>빈번히 관찰할 수 있는 장소가 바람직하다. </li>
					<li>빗물이 Hutch내에 고이지 않도록 입구측의 지면을 조금 낮게 한다. </li>
					<li>적설지역에서는 눈이 불어 쌓일 수 있으므로 겨울은 건물옆에 두지 않는다. </li>
					<li>Hutch의 간격은 1.2m이상 떼어 놓는다. </li>
				</ul>
				<div className="imgBox mg60f"><img src={d3by13} alt="적절히 열거된 Hutch." /></div>
				
				<h2>Calf hutch(송아지 상자)의 난점은? </h2>
				<ul className="listStyle04 mg15f">
					<li>일상 작업에 시간이 걸린다. 특히, 비, 눈등 악천기후시의 급사, 급수. </li>
					<li>강설시의 제·배설. </li>
				</ul>
				<div className="imgBox mg60f"><img src={d3by15} alt="눈에 파묻힌 Calf Hutch. " /></div>
				
				<h2>Calf hutch(송아지 상자)의 작업성 향상 대책. </h2>
				<ul className="listStyle04 mg15f">
					<li>환기가 빈틈없는 건물내에 Hutch를 설치한다. 이 경우도 Hutch의 간격은 1.2m이상 사이를 둔다. </li>
					<li>연쇄식으로 하여 지붕 아래에 작업용 통로를 설치한다. 연쇄식으로 한 경우 사이 사이의 목책은 밧줄이 아니라 높이 1.2m 이상의 판자로 한다.</li>
				</ul>
				<div className="imgBox mg60f"><img src={d3by17} alt="연쇄식으로 한 예. 겨울은 덮고 여름은 연다 밧줄이 아니라 판자로 한다.  콘크리트 경사 " /></div>
			
				<h2>Calf hutch(송아지 상자)의 여름, 겨울의 주의. </h2>
				<dl>
					<dt>하기(夏期) </dt>
					<dd>
						<ul className="listStyle04">
							<li>통풍이 좋은 장소, 나무그늘등에 Hutch를 옮겨 놓는다. </li>
							<li>Hutch의 입구를 동쪽으로 향한다. </li>
							<li>속의 벽을 떼어 내어 철망이든지 목책을 붙여 통풍을 좋게 한다. </li>
							<li>속의 벽이 빠지지 않고, 창(문)을 가지고 있지 않을 경우는 Hutch의 후부를 조금 띄운다.</li>
						</ul>
					</dd>
				</dl>			
				<dl>
					<dt>동기(冬期) </dt>
					<dd>
						<ul className="listStyle04">
							<li>바람이 Hutch의 바로 뒤부터 맞게 방향으로 옮겨 놓는다. </li>
							<li>Hutch의 입구를 막지 않는다.</li>
							<li>자주 마른 부료(깔짚)를 많이 넣는다. </li>
						</ul>
					</dd>
				</dl>		
				<div className="imgBox mg60f"><img src={d3by19} alt="여름의 배치예. 겨울의 배치예. " /></div>
				
				<h2>Calf hutch(송아지 상자)의 눈(雪)의 외풍 방지.</h2>
				<ul className="listStyle04 mg15f">
					<li>전면에 불어 들어오는 것의 방지용 조절판을 붙인다. </li>
					<li>바람이 Hutch의 바로 뒤부터 맞게 끔 방향으로 둔다.  </li>
					<li>눈이 불어 쌓이는 것을 피하기위해 건물 근처에 Hutch를 두지 않는다.</li>
					<li>눈이 불어 쌓인다 하더라도 운동장으로 나가기 어렵도록 입구를 막아서는 안된다. </li>
				</ul>
				<div className="imgBox mg60f"><img src={d3by21} alt="바람, 눈이 불어 쌓이는 것의 방지용 조절판을 붙인 Calf Hutch. " /></div>
				
				<h2>Calf hutch(송아지 상자)는 어디에 있는가? </h2>
				<ul className="listStyle04 mg15f">
					<li>추울 때 바람을 피하기 위해 Hutch를 이용한다. 바람이 강할 때일수록 안에 있는 시간이 길어 안쪽에 있다. 그 때문에 Hutch의 기밀성은 중요.</li>
					<li>추울 때라도 바람이 없으면 Hutch의 밖에 있다. 그러므로 겨울도 운동장은 필요. </li>
					<li>비를 막는 덮개, 햇빛을 가리기 위한 덮개(遮陽)으로서도 이용한다</li>
				</ul>
				<div className="imgBox mg60f"><img src={d3by23} alt="바람이 약할 때는 쭉 밖에 있는 일도 있다." /></div>
				
				<h2>Calf hutch(송아지 상자)의 소독, 관리. </h2>
				<ul className="listStyle04 mg15f">
					<li>새로운 자우를 들이기 전에 낡은 부료를 제거하여 Hutch를 세정(洗淨)한다. </li>
					<li>세정후 적어도 1주간은 Hutch를 사용하지 않고, 건조, 일광소독을 행한다.</li>
					<li>Hutch의 설치장소는 될수 있으면 이동한다. </li>
				</ul>
				<div className="imgBox mg60f"><img src={d3by25} alt="건조, 일광소독중의 Calf hutch. "/></div>
			</div>
  );
}

export default Fo58;
