import React from 'react';

import pic353 from 'assets/images/sub/_images/pic353.gif';

function Do202() {

  return (
    <div className="techinfoview">
      <h1>후보축을 위한 우사 및 시설</h1>
      <p className="pstyle">후보축의 성장단계에 따라서 사육환경을 적절하게 변화시켜야 한다. 후보축이 어린시기에는 질병전염을 막기 위하여 격리사육을 실시한다. 어느 정도 성장한 후에는 질병에 대한 저항력을 향상시키고 수정을 위하여 군사육을 실시한다. 결국, 후보축은 수정 및 분만을 겨쳐 착유우가 되는데, 각 성장단계별로 적절한 사육시설을 제공하여야 한다. </p>
      <h2>설계에 영향을 미치는 관리요소 </h2>
      <p className="pstyle">&nbsp;&nbsp;&nbsp;위생적인 환경을 제공해 주기 위해서는 착유우 우사로부터 멀리 떨어진 곳에 후보축 사육시설을 마련한다. 사육시설이 좋아야 최고의 관리기법을 적용시킬 수 있다. 각 군별로 적절한 공간, 시설, 환경, 사료 등이 제공될 수 있도록 사육시설을 설계하여야 하는데, 특히 관리가 용이하도록 설계하여야 한다. 후보축 사육시설을 설계할 때는 다음과 같은 사항을 준수하여야 한다. 1) 쾌적한 환경에서 휴식을 취하고 운동할 수 있는 공간을 갖추도록 한다. 2)샛바람은 피하되 항상 신선한 공기가 통하도록 한다. 3) 사료와 물 공급을 위한 충분한 공간이 있도록 한다. 4) 후보축의 나이나 크기에 따라서 공간을 확보한다. 5) 위생적인 면을 고려하여 깨끗한 부지를 선정한다. 6) 후보축을 관찰 할 수 있는 공간이 있도록 한다. 7) 후보축을 묶어 둘 공간이 확보된 치료시설이 있어야 한다. <br/>&nbsp;&nbsp;&nbsp;설계나 관리가 잘못된 사육시설은 질병 및 사고발생을 증가시킨다. 높은 습도는 가축의 건강에 치명적이다. 폐렴과 설사는 체내 주요기관에 영구적으로 손상을 줄 수 있기 때문에, 후보축의 잠재적 비유능력을 감소시킨다. 따라서 조악한 환경에서 사육된 후보축은 결코 잠재적 비유능력을 최대한 발휘할 수 있다. </p>
      <h2>시설관리 </h2>
      <p className="pstyle">우수하고 건강한 후보축을 보유하기 위해서는 좋은 사육시설과 후보축의 요구를 충족시킬 수 있는 관리계획을 마련하여야 한다. 방역위생, 질병치료, 환기조절, 사료급여, 자세한 관찰 등은 모두 중요한 관리사항들이다. 일상적인 관리 사항인 사료급여 및 분뇨 치우기는 가능하면 시간에 맞춰 실시한다. </p>
      <h2>사육규모 및 우군 편성 </h2>
      <p className="pstyle">일반적으로 사육규모는 착유우 두수만을 나타내거나 성축 두수로 나타내는데, 여기서는 성축두수로 나타냈다. 사육규모에 따른 전형적인 우군 편성을 나타낸 표 을 이용하여 다음과 같은 각 후보우군을 위한 사육시설을 결정한다. </p>
      <ul>
        <li>어린송아지 사육시설 (0-2 개월령) </li>
        <li>전환기 송아지 사육시설 (3-5 개월령) </li>
        <li>처녀소 사육시설 (6-8 개월령, 9-12 개월령, 13-15 개월령, 16-24 개월령) </li>
      </ul>
      <p>후보축의 두수는 착유 및 임신중인 소의 두수에 따라서 결정한다. 후보축을 위한 시설의 확장없이 무리하게 전체 사육두수를 증가시키면 사고와 질병이 증가하고 증체율이 감소한다.</p>
      <h2>우군별 관리 </h2>
      <p className="pstyle">나이, 크기 또는 특별한 관리의 필요에 따라 후보축을 분리하면 각 우군의 특성에 맞게 관리 할 수 있는 이점이 있다. 어린송아지와 전환기 송아지를 위한 우리(pen)의 크기는 표 에 나타나 있고, 월령에 따른 각 우군별 휴식장의 크기가 표 에 나타나 있다. 각 우군별 관리가 가능하다면 같은 건물내에 1개 우군 이상의 사육시설을 설치할 수 있다. 우군별 사육은 다음과 같은 이점이 있다. </p>
      <ul>
        <li>어린가축의 질병감염을 최소화시킨다. </li>
        <li>사료섭취시 경쟁을 감소시킬 수 있다. </li>
        <li>분만시 (24 개월령) 적절한 체중과 크기에 도달한다. </li>
        <li>나이에 따른 적절한 사료를 급여할 수 있다. </li>
        <li>분뇨의 처리가 용이하다. </li>
        <li> 적절한 환경을 제공할 수 있다. </li>
        <li>적절한 휴식공간을 제공할 수 있다. </li>
      </ul>
      <p><b>&lt;표 74&gt; 어린송아지와 전환기 송아지를 위한 사육시설 </b></p>
      <table className="tablestyle">
        <tr>
          <td>시설형태</td>
          <td>우리의 크기</td>
        </tr>
        <tr>
          <td colSpan="2">0∼2월령(독우방)</td>
        </tr>
        <tr>
          <td>독우상자(calf hutch)</td>
          <td>4&apos;×8&apos;(운동장 : 4&apos;×6&apos;)</td>
        </tr>
        <tr>
          <td>깔짚우리(bedded pen)</td>
          <td>4&apos;×7&apos;</td>
        </tr>
        <tr>
          <td>타이스톨(tie stall : 폐쇄식 우사일 경우)</td>
          <td>2&apos;×4&apos;</td>
        </tr>
        <tr>
          <td colSpan="2">3∼5월령(6두까지 수용)</td> 
        </tr>
        <tr>
          <td>수퍼 송아지 사육상자(super calf hutch)</td>
          <td>25∼30ft2/두</td>
        </tr>
        <tr>
          <td>깔짚우리(bedded pen)</td>
          <td>25∼30ft2/두</td>
        </tr>
      </table>
      <p>주 : 1&apos; = 30.48㎝ </p><br/>
      <p className="pstyle">사육시설이 차지하는 공간은 우사형태 및 휴식장으로부터 급수시설과 사료급여조 까지의 거리에 의하여 결정된다. 휴식장의 크기는 사육규모에 준하여 결정하고, 통로, 급수공간 및 휴식공간은 후보축이 불편을 느끼지 않도록 적절한 공간을 마련한다.  휴식장내에 사료급여시설의 설치는 분뇨축적량을 증가시키고, 이에 따라 깔짚이 더욱 필요해 지기 때문에 사료급여시설과 휴식장은 분리되어야 한다.</p>
      <h2>휴식장</h2>
      <p>적절한 휴식장의 제공은 효율적인 성장에 영향을 미치는 주요 요소이다. 표75와 76에 후보축을 위한 휴식장의 크기와 후리스톨의 크기가 각각 나타나 있다.</p><br/>
      <p><b>&lt;표 75&gt; 후보축의 나이에 따른 휴식장의 크기</b></p>
      <table className="tablestyle">
        <tr>
          <td>나이</td>
          <td>체중(㎏)</td>
          <td>자가세척 <br/>휴식장a, ft2</td>
          <td>깔짚휴식 <br/>장b, ft2</td>
          <td>틈판바닥, <br/>0ft2</td>
          <td>포장된 사외<br/>휴식장, ft2</td>
        </tr>
        <tr>
          <td>0∼2c </td>
          <td>45∼85 </td>
          <td>사용 안함</td>
          <td>32(4&apos;×8&apos;상자) <br/>28(4&apos;×7&apos;우리) </td>
          <td>사용 안함</td>
          <td>사용 안함</td>
        </tr>
        <tr>
          <td>3∼5 </td>
          <td>85∼160 </td>
          <td>사용 안함</td>
          <td>28</td>
          <td>사용 안함</td>
          <td>사용 안함</td>
        </tr>
        <tr>
          <td>6∼8 </td>
          <td>160∼225 </td>
          <td>10</td>
          <td>25</td>
          <td>12</td>
          <td>35</td>
        </tr>
        <tr>
          <td>9∼12 </td>
          <td>225∼290 </td>
          <td>12</td>
          <td>28</td>
          <td>13</td>
          <td>40</td>
        </tr>
        <tr>
          <td>13∼15 </td>
          <td>290∼360 </td>
          <td>15</td>
          <td>32</td>
          <td>17</td>
          <td>45</td>
        </tr>
        <tr>
          <td>16∼24 </td>
          <td>360∼540 </td>
          <td>18d</td>
          <td>40</td>
          <td>25</td>
          <td>50</td>
        </tr>
        <tr>
          <td>건유우</td>
          <td>&gt; 540</td>
          <td>20d</td>
          <td>50</td>
          <td>35</td>
          <td>55</td>
        </tr>
      </table>
      <p>a 8% 기울기 (1/ft) 주:1&apos;= 30.48 ㎝ 1=2.54 ㎝ <br/>b 10&apos; 폭의 사료급여 통로에 접근가능 <br/>c 독우상자 또는 우리 사용 <br/>d 세척을 위한 바닥의 경사 때문에, 임신말기의 소가 머리를 경사진 아래쪽을 향하여 누워 있으면 호흡곤란을 일으킬 수 있다. </p><br/>
      <p><b>&lt;표 76&gt; 후보축을 위한 후리스톨 (free stall)의 크기 </b></p>
      <table className="tablestyle">
        <tr>
          <td rowSpan="2">나이(월령)</td>
          <td rowSpan="2">체중(Kg)</td>
          <td colSpan="2">후리스톨 크기</td>
          <td colSpan="2">전진방지 가로책(neck rail)</td>
        </tr>
        <tr style={{background: '#273143', color: '#fff'}}>
          <td>폭(in)</td>
          <td>길이(in)</td>
          <td>우상으로부터 높이(in)</td>
          <td>뒷턱(back curb)으로부터 거리(in)</td>
        </tr>
        <tr>
          <td>6∼8</td>
          <td>160~225</td>
          <td>30</td>
          <td>60</td>
          <td>28</td>
          <td>46</td>
        </tr>
        <tr>
          <td>9~12</td>
          <td>225~290</td>
          <td>33</td>
          <td>64</td>
          <td>30</td>
          <td>49</td>
        </tr>
        <tr>
          <td>13~15</td>
          <td>290~360</td>
          <td>37</td>
          <td>72</td>
          <td>34</td>
          <td>57</td>
        </tr>
        <tr>
          <td>16~24</td>
          <td>360~540</td>
          <td>42</td>
          <td>78</td>
          <td>37</td>
          <td>62</td>
        </tr>
        <tr>
          <td>건유우</td>
          <td>540</td>
          <td>45</td>
          <td>84</td>
          <td>40</td>
          <td>66</td>
        </tr>
      </table>
      <h2>사료급여와 급수 시설</h2>
      <p className="pstyle">적절한 사료급여 공간을 마련하여 후보축간에 사료섭취 투쟁이 일어나지 않도록 한다. 최적의 사료급여공간은 사료의 종류, 급여횟수 및 후보축의 크기에 따라서 결정된다(그림 24, 표 77와 78). 급수조는 최소한 20 두당 1개가 설치되어야 한다. 건유우는 체중 45㎏당 일일 3.8-5.7 리터의 물을 섭취한다.</p>
      <img src={pic353} className="nonborder" width="30%" alt=""/>
      <p>그림 24. 사료급여 울타리</p><br/>
      <p><b>&lt;표 77&gt; 사조구분책 </b></p>
      <table className="tablestyle">
        <tr>
          <td>나이(월령)</td>
          <td>체중(Kg)</td>
          <td>목높이(in)</td>
          <td>전진방지 가로책높이(in)</td>
        </tr>
        <tr>
          <td>6∼8</td>
          <td>160∼220</td>
          <td>14</td>
          <td>28</td>
        </tr>
        <tr>
          <td>9∼12</td>
          <td>220∼290 </td>
          <td>16</td>
          <td>30</td>
        </tr>
        <tr>
          <td>13∼15</td>
          <td>290∼350</td>
          <td>17</td>
          <td>34</td>
        </tr>
        <tr>
          <td>16∼24</td>
          <td>350∼540</td>
          <td>19</td>
          <td>41</td>
        </tr>
        <tr>
          <td>성축</td>
          <td>540∼675</td>
          <td>21</td>
          <td>48</td>
        </tr>
      </table>
      <p>주 : 1&apos; = 30.48㎝ </p><br/>
      <p><b>&lt;표 78&gt; 사료급여 공간의 크기 </b></p>
      <table className="tablestyle">
        <tr>
          <td rowSpan="2">형 태</td>
          <td colSpan="6">나이 (월령)</td>
        </tr>
        <tr style={{background: '#273143',color: '#fff'}}>
          <td>3∼4 </td>
          <td>5~8</td>
          <td>9~12</td>
          <td>13~15</td>
          <td>16~24</td>
          <td>성축</td>
        </tr>
        <tr>
          <td>자가급여조(self feeder)</td>
          <td colSpan="6">------------------------in/두------------------------</td>
        </tr>
        <tr>
          <td>사일리지 또는 건초</td>
          <td>4</td>
          <td>4</td>
          <td>5</td>
          <td>6</td>
          <td>6</td>
          <td>6</td>
        </tr>
        <tr>
          <td>곡류 또는 배합사료</td>
          <td>12</td>
          <td>12</td>
          <td>15</td>
          <td>18</td>
          <td>18</td>
          <td>18</td>
        </tr>
        <tr>
          <td>일일 1회 급여</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>건초,사일리지,배합사료</td>
          <td>12</td>
          <td>18</td>
          <td>22</td>
          <td>26</td>
          <td>26</td>
          <td>26~30</td>
        </tr>
      </table>
      <h2>특수관리 및 치료 시설 </h2>
      <p className="pstyle">후보축에 대한 예방접종, 인공수정, 임신검사, 구충, 제각, 진찰 등의 처치를 안전하고 쉽게 하기 위하여 별도의 공간을 마련하여야한다. 동물의 취급 및 치료에 필요한 시설물로는 자동 잠금장치가 되어 있는 사료급여 계류장치(stanchion), 출입구, 울타리, 보정틀(squeeze chute/ breeding chute) 등이 있다. </p>
      <h2>다용도실 </h2>
      <p className="pstyle">다용도실은 후보축 우사에 가까이 위치하도록 한다. 다용도실은 착유용 진공 펌프, 온수기, 세척시설 등이 갖추도록 하며, 사료, 대용유 및 청소도구의 보관장소로도 이용된다. </p>
      <h2>사료 및 깔짚 저장실 </h2>
      <p className="pstyle">사료와 깔짚의 저장은 우사에서 가까운 곳에 하며, 1-2주 분량을 저장할 수 있도록 공간을 확보한다. 저장실의 크기는 사육두수, 사료급여횟수 및 사료수급상태에 따라서 결정한다. </p>
      <h2>개방식 우사(cold housing) </h2>
      <p className="pstyle">후보우를 육성하는 데는 개방식 우사가 좋다. 겨울에는 건조한 샛바람을 막아주며 여름에는 통풍이 잘될 뿐만 아니라 그늘을 제공해 주기 때문이다. 개방식우사는 단열시공이 되어 있지 않으며 구조적으로 자연환기가 잘 되도록 설계되어 우사내와 바깥 온도의 차가 거의 없다. 건축비가 저렴하고, 환기장치와 난방시설이 필요치 않으며, 질병발생이 적은 것이 개방식 우사의 장점이다. 그러나 겨울철에는 분뇨가 얼고, 급수조가 어는 것을 방지해야 하고 송아지의 귀에 동상이 걸릴 우려가 있으며 체온을 유지하기 위해 사료섭취량이 증가하는 등의 단점이 있다. </p>
      <h2>폐쇄식 우사(warm housing) </h2>
      <p className="pstyle">폐쇄식 우사에는 완벽한 단열처리를 하고 밖의 신선한 공기를 유입하기 위해 인위적으로 조절할 수 있는 기계식 환기시스템이 되어 있다. 이와 같이, 폐쇄식은 환경을 제어하기 때문에 관리가 소홀하면 질병발생이 쉽고 정상적인 성장에 지장을 초래한다. 따라서 폐쇄식 우사는 개방식에 비하여 후보축의 사육에 덜 적합하다. 젖소에 대한 환기 요구량은 표 79와 같으며, 환기장치의 규모는 사육두수 밀도에 따라 결정한다.</p><br/>
      <p><b>&lt;표 79&gt; 축사 및 착유실을 위한 환기율</b></p>
      <table className="tablestyle">
        <tr>
          <td>항 목</td>
          <td>저 온</td>
          <td>적 온</td>
          <td>고 온</td>
        </tr>
        <tr>
          <td></td>
          <td colSpan="3">--------------cm / 두-------------</td>
        </tr>
        <tr>
          <td>0-2 월령</td>
          <td>15</td>
          <td>50</td>
          <td>100</td>
        </tr>
        <tr>
          <td>2-12 월령</td>
          <td>20</td>
          <td>60</td>
          <td>130</td>
        </tr>
        <tr>
          <td>12-24 월령</td>
          <td>30</td>
          <td>80</td>
          <td>180</td>
        </tr>
        <tr>
          <td>635 Kg (착유우)</td>
          <td>50</td>
          <td>170</td>
          <td>470</td>
        </tr>
        <tr>
          <td></td>
          <td colSpan="3">----------------cfm---------------</td>
        </tr>
        <tr>
          <td>우유저장실</td>
          <td>--------</td>
          <td>--------</td>
          <td>600</td>
        </tr>
        <tr>
          <td>착유실(cfm/우상)</td>
          <td>--------</td>
          <td>100</td>
          <td>400</td>
        </tr>
      </table>
    </div>
  );
}

export default Do202;