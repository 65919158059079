import React from 'react';
import './Pagination.scss';

function Pagination({ currentPage, totalPages, onPageChange }) {
  const pageGroupSize = 10; // Number of pages per group
  const currentGroup = Math.ceil(currentPage / pageGroupSize);
  const lastGroup = Math.ceil(totalPages / pageGroupSize);

  const groupStart = (currentGroup - 1) * pageGroupSize + 1;
  const groupEnd = Math.min(currentGroup * pageGroupSize, totalPages);

  const pages = Array.from(
    { length: groupEnd - groupStart + 1 },
    (_, i) => groupStart + i,
  );

  const isLastGroup = groupEnd === totalPages;
  const nextGroupStart = groupEnd + 1;

  return (
    <div className="boardpagenationBtn">
      <button
        type="button"
        onClick={() => onPageChange(1)}
        disabled={currentPage === 1}
        className="goFirst"
      >
        <span className="hidden">맨 처음으로</span>
      </button>

      <button
        type="button"
        onClick={() => onPageChange(groupStart - 1)}
        disabled={currentGroup === 1}
        className="goPrev"
      >
        <span className="hidden">이전 페이지로</span>
      </button>

      {pages.map((page) => (
        <button
          key={page}
          type="button"
          onClick={() => onPageChange(page)}
          className={currentPage === page ? 'current' : ''}
        >
          {page}
        </button>
      ))}

      <button
        type="button"
        onClick={() => onPageChange(nextGroupStart)}
        disabled={isLastGroup}
        className="goNext"
      >
        <span className="hidden">다음 페이지로</span>
      </button>

      <button
        type="button"
        onClick={() => onPageChange(totalPages)}
        disabled={currentPage === totalPages || isLastGroup}
        className="goEnd"
      >
        <span className="hidden">맨 끝으로</span>
      </button>
    </div>
  );
}

export default Pagination;
