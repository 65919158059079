import React, { useState , useEffect } from 'react';
import { getState } from 'utils/api';

import './NewSemenSelection.scss';

function NewSemenSelection() {

  
  const [activeItems, setActiveItems] = useState([]);

//   useEffect(() => {
//     console.log(activeItems);
//   },[activeItems]);
  
  // 에러처리
  const [isModalOpen, setIsModalOpen] = useState(false); // 모달 추가
  const [errorMessage, setErrorMessage] = useState(''); 

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => {
    setIsModalOpen(false);
    setErrorMessage(''); // 모달이 닫힐 때 에러메세지 초기화
  };

  // 데이터 불러오기
  // eslint-disable-next-line no-restricted-globals 
  const baseDir='/ox/oxlist/';  
  const [ mainData , setMainData ] = useState();
  const prop = {type:'alive'};
  useEffect(() => {
    getState(baseDir,prop)
    .then(getdata => {      
      if (getdata !== undefined) {
        // console.log('#### all data : ', getdata.filter((item) => item.s_origin==='K'));
        setMainData({
          kr:getdata.filter((item) => item.s_origin==='K'),
          us:getdata.filter((item) => item.s_origin==='A'),
          ca:getdata.filter((item) => item.s_origin==='C')
        });
        }
      
    })
    .catch(error => {           
      setErrorMessage(error.message);
      openModal();
    });
    
  }, []);

  const handleItemClick = (item) => {
    
    if (activeItems.includes(item)){             
    setActiveItems(prevActiveItems => 
      prevActiveItems.filter(activeItem => activeItem !== item));
      } else {           
      if (activeItems.length >=3 ) {
        alert('정액선택은 최대 3개까지 가능합니다.\n정액코드를 다시 누르시면 취소가 가능합니다.');
        return;
      }
            
      setActiveItems(prevActiveItems => [...prevActiveItems, item]);
    }    
  };    
  
  const [ props , setProps] = useState({
    fname:'New_semen_selection.mrd',
    cong_code:localStorage.getItem('cong'),
    farm_code:localStorage.getItem('farm'),
    mon:localStorage.getItem('month'),
    farmid:localStorage.getItem('farmid')
  });

  const isItemActive = (item) =>
      activeItems.includes(item);

    const resetColor = () => {
     setActiveItems([]);
    };
             
  const handleOpenOzReport=() => {    
    // const fname = 'New_do.mrd';    
    if ( activeItems.length === 0) {
      window.alert('선택된 정액이 없습니다. 정액을 선택해 주세요.');
      return false;
    }

    if ( activeItems.length > 0) {
      const newProps = { ...props };
      activeItems.forEach((item, index) => {
        newProps[`arg${index + 1}`] = item;
      });

      const queryParams = new URLSearchParams(newProps);

    //   console.log('#### options :' , queryParams);
      const url = `${process.env.PUBLIC_URL}/crownix.html?${queryParams}`;    
      window.open(url, '_blank', 'noopener, noreferrer');
    }    
  
    return true;
  };       
             
         
  return (
    <div className="NewSemenSelection">
      <div className="semenSelection">
        <ul className="nation">
          <li>
            <span className="nationName">한국형</span>
            <ul className="semenList">
              {
                mainData &&
                mainData.kr.map((item) => (
                  <li onClick={()=> handleItemClick(item.s_code)} style={{color : isItemActive(item.s_code) ? 'var(--main_red)' : 'var(--main_black)'}}>
                  {item.s_code}
                  </li>
                ))
              }              
            </ul>
          </li>
          <li>              
            <span className="nationName">미국형</span>
            <ul className="semenList">
            {
                mainData &&
                mainData.us.map((item) => (
                  <li onClick={()=> handleItemClick(item.s_code)} style={{color : isItemActive(item.s_code) ? 'var(--main_red)' : 'var(--main_black)'}}>
                  {item.s_code}
                  </li>
                ))
              }        
            </ul>
          </li>
          <li>
            <span className="nationName">캐나다형</span>
            <ul className="semenList">
            {
                mainData &&
                mainData.ca.map((item) => (
                  <li onClick={()=> handleItemClick(item.s_code)} style={{color : isItemActive(item.s_code) ? 'var(--main_red)' : 'var(--main_black)'}}>
                  {item.s_code}
                  </li>
                ))
              }
            </ul>
          </li>
        </ul>
        
      </div>
      <div className="buttons">
          <button type="button" onClick={handleOpenOzReport} >조회</button>
          <button type="button" onClick={resetColor}>재선정</button>
      </div>
    </div>
  );
};

export default NewSemenSelection;

