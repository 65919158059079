import React from 'react';

// SCSS
import './errorMessage.scss';

function errorMessage({isOpen,content,closeModal}) {
  return (
    isOpen && (
    <div className="modalBackground">
      <div className="errorModal" style = {{display:isOpen ? 'block' : 'none'}}>
        <p>{content}</p>
      <button type="button" className="closeButton" onClick={closeModal}>닫기</button>
      </div>
    </div>
    )
  );
}

export default errorMessage;