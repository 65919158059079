import React from 'react';
import { Outlet } from 'react-router-dom';
import SubMain from 'components/subMain/SubMain';
// scss


function Performance() {
  return (
    <div className="performance">
      <SubMain />
      <Outlet />
    </div>
  );
}

export default Performance;
