import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

// api
import { getState } from 'utils/api';

// components
import Pagination from 'components/bo/Pagination';

function BoardList({ boardCode, boardName, editPath, viewPath }) {
  const [searchState, setSearchState] = useState({
    search_type: 'bdc_title',
    search_keyword: '',
    page_no: 1,
    brd_code: boardCode,
  });

  // Fetch board list
  const { data, isLoading, isError, error } = useQuery({
    queryKey: ['boardList', searchState],
    queryFn: () => getState('/board/select_cow_test_info_list', searchState),
  });

  const items = data?.result?.list || [];
  const totalCount = data?.result?.total_count || 0;
  const totalPages = data?.result?.page_count || 0;

  const handleSearch = (e) => {
    e.preventDefault();
    setSearchState({
      ...searchState,
      page_no: 1,
    });
  };

  const handlePageChange = (pageNo) => {
    setSearchState({
      ...searchState,
      page_no: pageNo,
    });
  };

  return (
    <div className="bo-page">
      <h2>{boardName}</h2>
      <div className="searchbox">
        <select
          value={searchState.search_type}
          onChange={(e) =>
            setSearchState({
              ...searchState,
              search_type: e.target.value,
            })
          }
        >
          <option value="bdc_title">제목</option>
          <option value="bdc_wname">작성자</option>
        </select>
        <input
          type="text"
          value={searchState.search_keyword}
          onChange={(e) =>
            setSearchState({
              ...searchState,
              search_keyword: e.target.value,
            })
          }
        />
        <button type="button" className="btn-search" onClick={handleSearch}>
          검색
        </button>
      </div>

      <div className="board-top">
        <p>
          총 자료수: <strong>{totalCount}</strong> 개, 페이지{' '}
          {searchState.page_no}/{totalPages}
        </p>
      </div>

      <div className="table-container">
        <table>
          <colgroup>
            <col width="10%" />
            <col width="*" />
            <col width="15%" />
            <col width="15%" />
          </colgroup>
          <thead>
            <tr>
              <th>번호</th>
              <th>제목</th>
              <th>년도</th>
              <th>등록일</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td colSpan="4" className="loading-data">
                  데이터를 불러오는 중입니다...
                </td>
              </tr>
            ) : isError ? (
              <tr>
                <td colSpan="4" className="error-data">
                  에러가 발생했습니다: {error.message}
                </td>
              </tr>
            ) : items.length === 0 ? (
              <tr>
                <td colSpan="4" className="no-data">
                  게시글이 없습니다.
                </td>
              </tr>
            ) : (
              items.map((item) => (
                <tr key={item.bdc_code}>
                  <td className="center">{item.row_num}</td>
                  <td>
                    <Link to={`${viewPath}?bdc_code=${item.bdc_code}`}>
                      {item.bdc_title}
                    </Link>
                  </td>
                  <td className="center">{item.bdc_wname}</td>
                  <td className="center">{item.bdc_rdt}</td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>

      <Pagination
        currentPage={searchState.page_no}
        totalPage={totalPages}
        onPageChange={handlePageChange}
      />

      <div className="button-group">
        <Link to={editPath} className="btn-l">
          등록
        </Link>
      </div>
    </div>
  );
}

export default BoardList;
