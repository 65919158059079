import React from 'react';


function Do33() {

  return (
    <div className="techinfoview">
				<h1>TMR의 정의 및 장ㆍ단점</h1>
				<h2>TMR의 정의</h2>
				<p className="pstyle">&nbsp;&nbsp;&nbsp;사양관리의 목표는 젖소로 하여금 요구하는 균형 잡힌 영양소를 최대로 섭취하게 함으로서 산유량 및 번식효율을 높여 낙농의 수익성을 최대화하는데 있다. 어느 한가지 사양관리 방 법을 모든 농가에서 일률적으로 적용할 수는 없으나 소규모나 대규모 낙농가에서 폭넓게 채 택되고 있는 사료급여 방법이 TMR사양이다. TMR이란 total mixed ration의 약자로 우리말 로 번역한다면 완전혼합사료 또는 완전배합사료라 할 수 있다. 즉 TMR은 영어의 COMPLETE RATION, COMPLETE FEED, COMPLETE DIET, BLENDED RATION이란 말과 동의어로 젖소에게 영양소를 고루 필요한 양만큼 공급할 목적으로 곡류, 강피류, 박류, 기타 농산가공 및 식품부산물, 건초, 볏짚, 사일리지, 광물질, 비타민, 각종 첨가제를 영양균 형에 맞는 비율로 계량하여 완전히 혼합하여 젖소가 자유로이 채식하도록 한 것이다. 다시 말해서, TMR 사양방법은 젖소가 필요로 하는 영양소를 충족시키기 위해서 농후사료, 조사 료, 미네랄, 비타민, 기타 첨가제를 혼합하여 자유 채식시키는 형태를 말한다. 이 TMR 방식 은 일반적으로 사료원료의 자급도가 낮은 지역에서 식품가공부산물이나 기타 부존사료자원 을 큰 무리 없이 이용할 수 있는 이점이 있다.</p>
				<h2>TMR의 장ㆍ단점</h2>
				<ul>
					<li>TMR의 장점
						<ul>
							<li>젖소가 사료를 골라 먹는 것을 방지할 수 잇다. 즉 젖소는 언제나 농후사료와 조사료의 구성비가 일정하고 각종 영양소의 균형이 완벽한 사료를 섭취하게 된다. 분리급여의 경우에 발생 할 수 있는 편식으로 인한 영양소 불균형이나 사료의 낭비를 방지한다. </li>
							<li>건물섭취량이 많아져 산유량이 증가되고 분리급여시 보다 높은 유지율을 유지한다. 특히 비유초기에 건물 섭취량을 많게 하여 젖소가 산유량능력을 충분히 발휘할 수 있게 하며 이 상적인 비유곡선을 형성케 한다. 표 11에는 분리급여와 TMR 급여시의 사료섭취량과 산유 량 및 유지율의 차이가 나타나 있다.<br/><br/>
								<p><b>&lt;표 11&gt; 분리급여와 TMR급여시의 사료섭취량과 산유량 차이</b></p>
								<table className="tablestyle">
									<tr>
										<td>항목</td>
										<td></td>
										<td>비유전기</td>
										<td>비유중기</td>
										<td>비유말기</td>
									</tr>
									<tr>
										<td>건물섭취량<br/>(kg/일)</td>
										<td>분리급여<br/>TMR</td>
										<td>20.0<br/>22.6</td>
										<td>18.5<br/>21.5</td>
										<td>15.5<br/>21.9</td>
									</tr>
									<tr>
										<td>산유량<br/>(kg/일)</td>
										<td>분리급여<br/>TMR</td>
										<td>25.5<br/>27.3</td>
										<td>18.6<br/>20.9</td>
										<td>11.9<br/>15.3</td>
									</tr>
									<tr>
										<td>유지율(%)</td>
										<td>분리급여<br/>TMR</td>
										<td>3.38<br/>3.59</td>
										<td>3.37<br/>3.56</td>
										<td>3.82<br/>3.68</td>
									</tr>
								</table>
							</li>
							<li>젖소가 섭취하는 건물량을 정확히 알수 있다. 따라서 사려급여량 관리가 용이하여 사료 효율을 높임으로서 사료비가 절감된다. 조사료와 농후사료를 분리해서 먹이는 경우에는 농 후사료의 변화에 따라 조사료의 섭취량이 달라지기 때문에 결과를 예측하기가 쉽지 않은데 반하여 TMR의 경우는 사료성분의 변화로부터 기인하는 결과의 예측이 용이하다. </li>
							<li>TMR사양은 자유채식 체계이기 때문에 젖서가 한 번에 20-30분씩 하루에 10-12회 이상 사료를 섭취하므로 반추위내 발효가 안정되어 대사성 질병의 발생률이 낮으며 사료를 안먹 는 경우나 소화장애의 빈도가 상대적으로 낮다. 또한 소량씩 여러번 나누어 섭취하기 때문 에 에너지 이용효율이 증대된다. </li>
							<li>균형된 적정수준의 조섬유가 섭취되므로 반추위내 산도(pH)의 안정 및 각 휘발성지방산 의 생산이 안정되어 유지율의 저하(변동)를 최소화 한다. </li>
							<li>기호성이 낮은 사료나 값이 저렴한 부산물을 사용할 수 있기 때문에 사료 선택의 폭이 넓어진다. 즉 육골분, 혈분, 어분 및 탤로우 등의 부산물을 무리없이 사용할 수 잇다. 뿐만 아니라 단미사료가격의 변동이 있을 때 반추위내 미생물에 큰 영향을 미치지 않으면서 배합 비를 바꿀 수 있는 장점이 있다. 그러나 기호성이 낮은 단미사료를 사용할 경우에는 전체 TMR의 기호성이 떨어진다는 사실을 잊어서는 안된다.</li>
							<li>TMR을 급여함으로서 대사장애, 유열, 케톤증, 후산정체 및 4위전위 등을 발생을 줄여 젖소의 번식효율과 건강이 개선된다. </li>
							<li>착유실에서 농후사료를 급여할 필요가 없기 때믄에 착유실내 사료급여 시설비 및 유지비 가 절감되며, 먼지가 줄고, 청소를 용이하게 할 수 있다. 또한 착유자는 착유에만 전념할 수 있으며 젖소가 조용히 서 있을 뿐만 아니라 착유중 배설량이 적어지고, 착유가 끝난 후 퇴 장속도가 빨라 착유능률이 향상된다. </li>
							<li>사료급여에 소요되는 노동시간을 줄일 수 있다.</li>
						</ul><br/>
					</li>
					<li>TMR의 단점
						<ul>
							<li>이해부족으로 잘못 제조된 TMR을 급여하면 권장량에 맞는 영양소를 공급이 제대로 이 루어지지않아 큰 손실을 초래한다. </li>
							<li>우사개축 및 배합기 구입등 시설투자비가 많이 소요된다. </li>
							<li>베일 형태의 긴 건초(볏짚 포함)는 배합기에 넣기 전에 세절하여야 한다. </li>
							<li>사료배합을 잘못했을 경우 대사장애나 번식장애는 물론 경제적 피해가 클 수 있다. </li>
							<li>낙농가는 기존의 사양철학을 대폭 수정하고 TMR에 관련된 사양지식을 교육이나 경험 또는 문헌을 통해 습득해야 한다.</li>
							<li> 우군의 분리가 필수적이다. 우군의 분리가 제대로 되어 있지 않으면 과비우나 마른소가 나올 수 있다. </li>
							<li>정기적인 사료 성분분석을 실시하여야 한다. 특히 원료사료가 바뀌거나 수분함량이 변했 을 경우에는 반드시 다시 분석해야 한다.</li>
						</ul>
					</li>
				</ul>
			</div>
  );
}

export default Do33;
