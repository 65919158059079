import React from 'react';
import Notice from 'containers/web/data/notice/Notice';
import { Helmet } from 'react-helmet-async';

function NoticePage() {
  return (
    <>
      <Helmet>
        <title>공지사항</title>
      </Helmet>
      <Notice />
    </>
  );
}

export default NoticePage;
