import React from 'react';


function Do25() {

  return (
    <div className="techinfoview">
    <h1>건유우의 사양관리</h1>
    <p className="pstyle">&nbsp;&nbsp;&nbsp;건유기간은 한 비유기가 끝난 시기가 아니라 다음 비유기가 시작되는 준비기간으로 보아야 한다. 건유우의 적절한 사양관리를 위해서는 최근 비유기간중의 우유생산량, 건유시킬 때의 소의 영양상태. 예상 건유일수, 유방염을 포함한 질병문제 등을 감안해야 한다. 건유기간중의 합리적인 사양관리는 다음 산유기간중의 젖소의 건강이나 우유 생산을 크게 증진시킨다. </p>
    <h2>건유 및 건유우관리</h2>
    <ul>
      <li>건유기간은 6∼8주가 적당하다. 우유생산으로 피로해진 유선조직의 회복과 결핍된 광물질등 일부 영양소의 체내비축수준을 정상으로 하기 위해서는 최소한 6주의 건유기간이 필요하다. 연구결과에 의하면 우유생산량이 40%까지 감소된다고 한다. 그리고 건유기간이 지나치게 짧은 경우도 다음 산유기간의 우유생산이 크게 감소되며 반대로 건유기간이 너무 길면 과다로 인한 난산 등의 문제가 발생한다. 초산우의 경우는 건유기간을 최소한 8주 정도로 하는 것이 좋다. 건유기간이 짧으면 다음 비유기 즉 2산때 우유생산 증가를 제한하게 된다.</li>
      <li>건유를 시키는 최선의 방법은 착유를 중단하는 것이다. 고능력우의 경우는 건유하기 몇일 전부터 농후사료 급여량을 줄이고 물의 섭취도 제한하는 것이 좋다. 그러나 여름철에는 물의 섭취를 지나치게 제한하지 않도록 주의해야 한다. 착유 중단후 6∼7일경 유방이 상당히 수축되었을 때 한 번 완전히 착유해 주어야 한다. 건유시 15kg 이상의 우유를 생산하는 개체의 경우는 착유 중단 2일후에 다시 착유해야 한다. 한편 건유시기에 20kg 이상의 우유를 생산하는 젖소의 경우는 다즙사료, 농후사료, 물의 급여량을 제한하면서 착유회수를 서서히 줄이다가 건유하는 것이 좋다.</li>
      <li>건유를 위해 마직막으로 착유한 후에는 유방과 유두를 닦아주고 유방염에 걸렸던 경우는 치료를 목적으로, 그렇치 않은 경우는 예방을 위해 모든 유두에 유방염 연고(Biodry등)을 각 유두당 10ml씩 주입한 후 소독(teatdipping)한다. 유방염 치료 및 예방을 위해 분만 4주전까지 유방염 연고 주입을 계속하는 것이 좋다. 특히 건유초기에는 유방염 등의 이상유무를 알아보기 위해 자주 관찰해야 한다. 건유우 유방염은 건유 첫주에 발생하는 경우가 많다.</li>
      <li>건유우는 착유우와 격리수용하는 것이 좋다. 이는 건유우의 영양소 요구량에 맞는 사료를 급여하고 또 과학적인 관리를 위해 필요하다.</li>
      <li>구충을 철저히 하는 것이 좋다. 건유기간중에 구충을 하게되면 우유생산 피크기에 기생충 피해가 없게 된다는 이점이 있으며 고능력우 일수록 이에 대한 효과가 크다.</li>
      <li>청결하고 안락한 분만실을 마련하여 분만시 스트레스를 가능한한 줄이도록 해야한다. </li>
    </ul>
    <h2>건유우의 사양</h2>
    <ul>
      <li>건유우의 단백질 및 에너지등 영양소요구량은 상대적으로 낮은 편이다. 풍건물기준 사료섭취량은 체중의 2% 내외가 보통이며, 농후사료와 조사료를 합한 건유우사료의 주요 영양소 함량은 다음과 같다.
        <table className="tablestyle16">
          <tr>
            <td>조단백질</td>
            <td>(건물기준 함량,%)</td>
            <td>11~12</td>
          </tr>
          <tr>
            <td>정미에너지</td>
            <td>(건물기준 함량, Mcal/kg)</td>
            <td>1.10~1.32</td>
          </tr>
          <tr>
            <td>가소화양분총량</td>
            <td>(건물기준 함량,%)</td>
            <td>56~60</td>
          </tr>
          <tr>
            <td>칼슘</td>
            <td>(건물기준 함량,%)</td>
            <td>0.4~0.8</td>
          </tr>
          <tr>
            <td>인</td>
            <td>(건물기준 함량,%)</td>
            <td>0.24~0.30</td>
          </tr>
        </table>
      </li>
      <li>양질의 혼합목초나 연맥사이레지 등을 주로 급여할 때는 단백질 또는 에너지사료의 추가급여가 필요없다. 옥수수사이레지, 옥수수대 등을 급여할 때는 단백질사료의 첨가가 필요하며 볏짚을 주 조사료로 할 경우는 단백질 첨가는 물론 에너지 보충을 위한 농후사료를 급여해야 한다. 건유기간중의 농후사료 급여는 조사료의 질과 물리적 형태, 젖소의 영양상태 및 건유기간의 장단등에 따라 결정된다.</li>
      <li>에너지 수준은 분만시 젖소의 살이 알맞게 붙은 상태가 되도록 조절해야 한다.(신체충실지수, Body Condition Score, BCS:3∼3.5). 과비는 절대 금물이다. 실제로 젖소의 알맞은 영양상태는 비유후기에 이루어지도록 해야 한다. 그리고 건유기간 중에는 비유후기에 이루어진 영양상태를 유지하도록 하며 이 기간중에 체중증가는 40∼45kg 정도가 되도록 하는 것이 좋다. 이 정도는 태중에 있는 송아지의 발육을 위해 필요하다.
        <p><b>&lt;표 1&gt; 건유우의 1일 영양소요구량</b></p>
        <table className="tablestyle">
          <tr>
            <td rowSpan="2">체중</td>
            <td colSpan="2">에너지</td>
            <td rowSpan="2">조단백질</td>
            <td rowSpan="2">칼슘</td>
            <td rowSpan="2">인</td>
            <td colSpan="2">비타민</td>
          </tr>
          <tr style={{background: '#273143' ,color:'#fff'}}>
            <td>정미에너지</td>
            <td>가소화양분총량</td>
            <td>A</td>
            <td>D</td>
          </tr>
          <tr>
            <td>Kg</td>
            <td>Mcal</td>
            <td>kg</td>
            <td>g</td>
            <td>g</td>
            <td>g</td>
            <td>1000IU</td>
            <td>1000IU</td>
          </tr>
          <tr>
            <td>400</td>
            <td>9.30</td>
            <td>4.15</td>
            <td>875</td>
            <td>26</td>
            <td>16</td>
            <td>30</td>
            <td>12</td>
          </tr>
          <tr>
            <td>450</td>
            <td>10.16</td>
            <td>4.50</td>
            <td>928</td>
            <td>30</td>
            <td>18</td>
            <td>34</td>
            <td>14</td>
          </tr>
          <tr>
            <td>500</td>
            <td>11.00</td>
            <td>4.90</td>
            <td>978</td>
            <td>33</td>
            <td>20</td>
            <td>38</td>
            <td>15</td>
          </tr>
          <tr>
            <td>550</td>
            <td>11.81</td>
            <td>5.27</td>
            <td>1027</td>
            <td>36</td>
            <td>22</td>
            <td>42</td>
            <td>17</td>
          </tr>
          <tr>
            <td>600</td>
            <td>12.61</td>
            <td>5.62</td>
            <td>1074</td>
            <td>39</td>
            <td>24</td>
            <td>46</td>
            <td>18</td>
          </tr>
          <tr>
            <td>650</td>
            <td>13.39</td>
            <td>5.97</td>
            <td>1120</td>
            <td>43</td>
            <td>26</td>
            <td>50</td>
            <td>20</td>
          </tr>
          <tr>
            <td>700</td>
            <td>14.15</td>
            <td>6.31</td>
            <td>1165</td>
            <td>46</td>
            <td>28</td>
            <td>53</td>
            <td>21</td>
          </tr>
        </table>
      </li>
      <li>비유후기에 과비 또는 지나치게 마른 상태를 건유기간중에 교정하기는 매우 어렵다. 비유후기에 과다된 젖소의 경우 건유기간중에 체중을 줄이기 위해 사료급여를 지나치게 제한하면 오히려 스트레스의 요인이 될 수 있다.</li>
      <li>연구결과에 의하면 건유기간중에 높은 수준의 농후사료 급여가 다음 비유기의 우유생산을 높이는 필수조건이 아니라고 한다.</li>
      <li>분만예정 2주전을 기점으로 농후사료의 급여량을 점차적으로 늘려 분만때에는 농후사료 급여량이 체중의 0.75∼1%수준에 이르도록 하는 것이 좋다. 농후사료 섭취량이 체중의 1%수준을 넘으면 4위 전위증을 위시한 대사성질병의 발생빈도가 높아진다.</li>
      <li>건유기간중에는, 조사료를 충분히 급여하되 자르지 않은 건초 또는 길게 자른 건초 및 암모니아처리볏짚 등 부피가 크고 거친 조사료를 1일에 두당 최소한 2∼3kg 정도 급여하는 것이 좋다.</li>
      <li>최근의 연구에서 밝혀진 바에 의하면 건유기간중에 건초중심의 사료를 급여한 경우가 옥수수사이레지 위주의 사료를 급여한 경우보다 분만후 9주 동안의 사료섭취량이 많았으며 우유생산량도 높았다고 한다.</li>
      <li>건유기간중의 일일 두당 칼슘(Ca)과 인(P)의 섭취량은 매우 중요하다. 이들의 균형도 중요하지만 실제 섭취량이 더 문제가 된다. 이 기간중의 칼슘과 인의 섭취량과 비율이 분만후 유열(milk fever)발생과 밀접한 관계가 있기 때문이다. 일반적으로 홀스타인의 경우 건유기간중의 일일 두당 칼슘과 인의 급여량은 각각 60∼100g, 35∼40g 정도가 알맞다. 따라서 칼슘과 인의 섭취량이 각각 100g, 40g을 넘지 않도록 하는 것이 좋다.</li>
      <li>칼슘 섭취량은 조사료의 종류에 따라 달라진다. 즉 알팔파를 주종으로 하는 사료를 급여하면 칼슘의 섭취량이 많아 유열이 발생할 위혐이 뒤따르게 된다. 그러나 옥수수사이레지만을 급여할 경우는 칼슘이 모자라게 되니 따로 첨가 급여해야 한다. 따라서 알팔파와 옥수수사이레지를 적정비율로 혼합급여하거나 화본과 목초나 또는 연맥사이레지만을 급여하는 것이 이상적이다.</li>
      <li>조사료 위주의 건유우를 사양할 때는 인의 보충이 필요하다.</li>
      <li>유열예방을 위해서는 건유말기 2주 즉 분만전 2주동안 칼슘 결핍사료를 급여하는 것이 좋다. 이때 홀스타인의 경우 일일 두당 섭취량이 20g 정도가 되도록 하는 것이 효과적이다.</li>
      <li>소에게 광물질이 많이 들어 있는 경수(센물)를 급여할 때는 칼슘섭취량에 특히 조심해야 한다. 즉 젖소가 칼슘성분이 많이 들어 있는 물을 마시게 되면 하루에 10∼15g의 칼슘을 섭취하게 되기 때문에 농후사료와 조사료에 들어있는 칼슘량을 합하면 실제 섭취해야 할 양보다 많아지게 되는 것이다.</li>
      <li>건유우는 상당량의 비타민 A, D,E를 섭취해야 한다. 건강한 송아지를 생산하게 하고 또 후산정체를 예방하기 위해서는 일일 두당 40,000∼60,000IU의 비타민 A를 섭취토록 해야 한다. 그리고 비타민 D 섭취량도 일일 두당 10,000∼20,000IU가 되어야 비타민 D 결핍으로 인한 유열발생을 막고 칼슘과 인의 이용을 원할하게 할 수 있다. 부족한 경우는 필요한 량을 근육에 주사해 주어도 좋다.</li>
      <li>비타민 E와 셀레니움(selenium)은 후산정체를 예방하고 자궁내막염 발생을 줄이는데 매우 중요한 역할을 한다. 최근 미국 아이오와대학(ISU)에서 연구한 바에 의하면 비타민 E 와 셀레니움 섭취수준을 높힌 결과 후산정체, 자궁내막염 및 난소낭종의 발생빈도가 크게 감소되었다고 하며, 그 결과 번식성적이 좋아진 것으로 보고되었다. 분만 3주전에 셀레니움 50mg과 비타민 E 680IU를 주사해도 좋다. </li>
    </ul>
    <h2>분만전후의 사료급여</h2>
    <ul>
      <li>건유중의 젖소의 분만시기에 가까워지면 분만후 비유초기 사양에 적응할 수 있도록 하되 갑작스럽게 사료를 바꾸는 것을 삼가고 사료섭취량을 최대한으로 늘리고 문제가 되는 주요한 영양소를 부족되지 않게 공급해야 한다.</li>
      <li>분만예정일이 다가오면 깨끗한 물을 충분히 섭취할 수 있게 하며, 양질의 조사료를 급여하고, 충분히 운동할 수 있는 공간을 마려해 주어 사료섭취를 자극하는 것이 좋다.</li>
      <li>그리고 농후사료 급여량은 체중의 0.75∼1%정도, 즉 일일 두당 5∼6kg 정도로 하는 것이 좋다.</li>
      <li>이러한 농후사료 급여수준을 분만후 3∼5일까지 그대로 유지하는 것이 좋다. 이렇게 하면 소가 식욕을 되찾아 조사료를 많이 섭취하게 된다. 이는 또한 유방의 부기를 가라앉게 하는데도 도움이 되며 전체적인 사료섭취량도 높이는 효과를 가져온다.</li>
      <li>고능력우의 우유생산능력을 최대한 발휘하도록 하기 위해 지방을 첨가급여할 경우는 분만 2주전부터 일일 두당 110g의 지방을 급여하여 지방섭취이용에 익숙하도록 한다.</li>
    </ul>
  </div>
  );
}

export default Do25;
