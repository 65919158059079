import React from 'react';

function Fo41() {

  return (
    <div className="techinfo_view">
				<p className="viewTitle">저마그네슘 혈증</p>
				<dl>
					<dt>발병대상</dt>
					<dd>소(미국에서 저마그네슘 혈증은 유우보다 육우에서 발병율이 높다). 때때로 면양과 염소에서도 소와 같은 임상중 상을 </dd>
				</dl>
				
				<dl>
					<dt>원인</dt>
					<dd>
						<p>저마그네슘 혈증은 혈액내 마그네슘 농도의 저하에 의한 영양적 질병이다. 이 질병은 봄철에 무성하게 자라는 목 초중에 마그네슘 함량이 0.2% 이하이고 질소의 함량은 4%(단백질 함량 25%) 그리고 칼륨 함량이 3%이상인 목초 를 섭취한 유우에서 발병하기 쉽다. 목초 중에 질소와 칼륨 함량이 높으면 마그네슘 함량은 0.25% 이하가 되기 쉽다. 이와같은 저마그네슘 방목초지는 방목개시 처음 2주간 동안에 주로 발생하지만 늦은 방목 계절에서도 안게 낀 날씨와 우기에도 자주 발생한다. 때때로 이 질병은 과방목초지에서 방목하다가 갑자기 무성한 초지로 이동시켜 방복하였을 때에도 자주 발생한다. 초장이 짧은 방목초지(밀, 귀, 호밀, 대맥)에서 특히 문제가 되며 또한 마그네슘 함량이 적은 저질 건초, 짚, 옥수수대 등을 급여하였을 때에도 발병한다. 일반적으로 두과식물 초지에 방복한 가축 에서는 발생율이 거의 없는데 그 이유는 두과식물이 같은 토양에서 자란 화본과 목초보다 마그네슘 함량이 거의 두배나 많기 때문이다.</p>
						<p>가축의 마그네슘 대사에 악 영향을 미치는 요인은 여러 가지가 있으며 이로 인하여 grass tetany가 발생되기도 한다. 즉 봄철 온도의 심한 변화, 흐린 날씨의 지속, 식물체의 유기물 함량과 고급 지방선 분포정도, 가축의 호르몬 분비 상태와 에너지 섭취량 그 밖의 기챙충이나 찬비 또는 가축물이용 개로 인한 스트레스 등을 들 수 있다.</p>
						<p>Grass tetany 의 발생은 이용가능한 마그네슘 함량은 적고 반면에 칼륨함량이 많은 토양에서 자란 목초로 인하여 야기될 수 있다. 마그네슘 뿐만 아니라 칼슘 함량이 적을 때도 tetany의 발생 빈도가 높다. 그러므로 이때 토양 분 석을 실시해 놓으면 tetany발생 가능성에 관한 정보를 제공받고 동시에 적절한 시비를 할 수 있으며 또한 어느 지 역 ? 특정 지역에서의 grass tateny발생 기록을 아는데도 상당한 도움을 줄 것이다.</p>
					</dd>
				</dl>
				
				<dl>
					<dt>징후 및 증상</dt>
					<dd>
						<p>마그네슘 초기 결핍 증상은 신경질적이고 귀를 쫑긋거리며 산유량이 감소한다. 경험과 관찰력이 있는 사람이라면 이때 예방조치가 가능하다. 증상이 심해지면 무리로부터 이탈하며 걸음걸이가 뻣뻣해지고 식욕저하와 빈뇨현상을 보인다. 그리고 신경질적이며 귀와 머리를 세우고 시선을 고정 시킨채 또한 비틀거리며 얼굴과 귀 및 옆구리 쪽의 피부가 일그러지고 자주 누웠다 일어섰다 한다. 이러한 동물은 흥분을 잘하며 공격적인 양상을 보여 가까운 거리 에 있는 가까운 거리에 있는 사람에게 덤비거나 습격하기도 한다. 2-3일이 경과하면 격렬한 흥분과 경련을 일우키 기도 하며 옆으로 길게 누워 앞다리를 간헐적으로 휘젓는다. 그리고 침을 흘리고 숨이 가빠지며 심장이 빠르게 뛴 다. 이 상태에서 방치하면 거의 예외 없이 경련중 혹은 경련후에 죽는다. 다양한 증상을 나타내는 grass tetany발 생 가축은 수의근과 불수의 근을 제어하는 신경계에 타격을 입는다. 종종 임상징후를 나타내지 않고 죽는 경우도 있다. 만성 grass tetany는 서서히 진행되며 근육 감염은 경련을 일으키거나 걸음걸이가 뻣뻣한 정도 혹은 비대한 모습을 나타내는데 그치지만 가축을 거칠게 몰거나 다루면 경련을 일으킬 수도 있다.</p>
						<p>Grass tetany 에 걸릴 가능성은 1-2회 분만 경력의 소보다 마그네슘 저장량과 이용효율이 떨어지는 나이든 소일 수록 높다. 건유 중인 소나 황소의 정우에는 걸릴 가능성은 거의 없다.</p>
						<p>혈장내 정상 마그네슘 함량은 100ml당 1.8-2.0mg이며 1.0-1.2mg/100ml에 이르면 마그네슘 결핍중상을 나타낸다. 그러나 혈장내 마그네슘 함량이 낮다고 하여 모든 가축이 tetany에 걸리는 것은 아니고 또한 감염된 가축이라 하 더라도 경련을 하는동안 혈장내 수준이 정상치에 가까워지기도 하므로 진단시 혈액측정에만 의존하여 판단할 수 는 없다. 혈장내 마그네슘함량이 100ml당 1.8mg에 도달할 때 비로서 신장에서 저장을 시작하기 때문에 grass tetany의 보다 확실한 진단방법의 하나는 뇨중 마그네슘 함량을 측정하는 것이다.</p>
						<p>Grass tetany는 질산염 중독증이나 칼슘 결핍증과 혼동되어서는 안 된다. 질산염 중독증의 경우 혈액은 갈색으로 변색된다. 칼슘 결핍증에서는 마그네슘 결핍증에서와 같이 신경질적이기 보다는 오히려 둔해진다. 또한 가축이 동 시에 칼슘 및 마그네슘 결핍증에 걸릴 수도 있는데 이럴 때는 마그네슘 결핍증상이 나타나지 않게 된다. 이는 칼 슘과 마그네슘이 부족한 동물에서 밀 방목시의 중독으로 나타난다.</p>
					</dd>
				</dl>
				
				<dl>
					<dt>질병분포와 손실규모</dt>
					<dd>Grass tetany는 세계 어느지역에서나, 예측할 수 없이 간헐적으로 일어난다. 미국의 경우 이 질병이 가축사망의 주 된 원인이 되고 있어 온대지역에서 가축의 1-3%가 사망하는 것으로 추정되고 있다. </dd>
				</dl>
				
				<dl>
					<dt>치료</dt>
					<dd>
						<p>tetany의 치료는 감염동물을 심하게 다루지 않고 또한 조기에 발견하면 치료가 가능하다. 이때 만약 치료가 8-12 시간 정도 지연되면 회복이 늦어지기 때문에 수의사에게 의뢰하는 것이 좋다. 방목상태에서의 가축의 피부에 살균 포화용액인 황산 마그네슘을 200cc주사하면 15분 경과 후에 혈액내 마그네슘 농도가 증가한다(주사시에 한 부위에 50cc이상을 넘지 않도록 할 것). 흥분한 동불을 진정시키기 위해 chloral hydrate 또는 황산 마그네슘을 정맥내 주 사하고 이어서 칼슘-마그네슘 글루코내이트 용액을 주사하기오 한다. 만약 가축이 다시 경련을 하게되면 칼슘-마그네슘 글루코내이트 용액의 재주입이 필요하게 될지도 모른다. 정맥내 주입이 급격하게 진행될 경우 심장마비의 위험성이 있으므로 숙련가에 의하여 서서히 주입하도록 한다(500cc의 경우 약 15분간에 걸쳐 주입). 300g의 불에 60g의 엽화 마그네슘을 혼합한 관장액을 항문에 25cm깊이로 탐색자를 집어넣고 calf feeder로 투여하는 것도 효과 적이다. 주입된 마그네슘은 대장과 그 이하 장기에서 ?수된다. 마그네슘을 정맥내 혹은 관장에 의한 방법대신 경 구 투여하면 이것이 ?수되는 장기까지 도달하는 시간이 많이 소요되기 때문에 효과적인 방법이 되지 못한다. 축 군내 가축의 치료는 황산 마그네슘을 물에 타서 급여할 수도 잇다. 이때 설사를 유발하기도 하지만 문제될 바는 아니다. 보다 효과적인 방법으로는 마그네슘 처리 탱크를 유일한 급수원으로 두는 방법이 것이다.</p>
						<ul className="listStyle04">
							<li>주의 : 이 처리 방법은 음수량의 감소로 생산성이 저하된다. 차선의 치료 방법으로는 tetany발생 방목지로부터 모 든 가축을 격리시켜 알팔파 건초를 급여하다(필요하다면 농후사료도 함께 급여). 이외에도 각 동물에게 매일 30g의 마그네슘을 1-2주 동안에 걸쳐 비교적 기호성이 우수한 보충제와 함께 급여하되 필요하다면 강제 급여 방식도 동 원한다. Tetany에 걸렸던 가축은 비슷한 시기에, 또 해마다 재말 가능성이 높다.</li>
							<li>독성 : 마그네슘 중독증은 정상적인 사료를 섭취한 가축에서는 발생되지 않지만 170-350g의 마그네슘을 더 보충 하여 주면 해로운 영향을 미칠수 있으며 NRC에서는 최대 허용량을 사료의 0.4%정도로 정하였다. 과다 급여로 인 한 중독증상으로는 식욕 부진과 생산성 감소 및 잦은 설사 등을 들 수 있다. 또한 중독증상을 보였던 가축은 반사 작용이 부족하거나 호흡저하를 나타낼 수도 있다. </li>
						</ul>  
					</dd>
				</dl>
				
				<dl>
					<dt>대책</dt>
					<dd>통상 이용되고 있는 사료의 마그네슘 함량이나 이용율이 사료마다 크게 다르다. 즉 곡류사료의 대부분은 마그네슘 함량이 0.12-0.18%정도이며 동물성 단백질원의 마그네슘 함량은 식물성 단백질원의 0.3-0.6%에 비하여 매 우 낮다. 지방은 tetany의 상태에서는 소화기관내에서 칼슘 및 마그네슘과 결합하여 이용효율을 떨어뜨리기 때문에 이때는 탄수화물보다도 에너지원으로 좋지 못하다. 또한 목초의 마그네슘 함량도 상당히 달라서 보통 두과가 화본 과보다 함유량이 높다. 에너지 또는 단백질원을 마그네슘 보충제와 함께 급여하면 기호성을 개선시키는 효과가 있 다.</dd>
				</dl>
				
				<dl>
					<dt>예방</dt>
					<dd>
						<p>Grass tetany의 예방은 늘 치료에 우선한다. 특히 감염의 위험성이 높은 기간에 마그네슘을 공급하여 주면 미세한 양이라하더라도 체내축적이 되기 때문에 예방 효과가 있다. 유의사항 : 축내내에서 tetany발생직후 행하는 응급 급여 계획은 이미 진행된 질병을 막는 조치로는 부적당하다. 마그네슘의 보충은 grass tetany가 한 지역에서 관찰되기 이전에 충분히 적응되도록 하기 위해서 30일 전부터 시작하는 것이 좋다. 산화 마그네슘이나 황산 마그네슘은 기호성이 좋지 않기 때문에 가축이 충분한 양을 섭취할 수 없다.</p>
						<p>비육용 젖소의 경우에는 마그네슘 요구량은 건유기에 10g 그리고 포유중인 경우에는 20-25g을 매일 급여해 주므로써 충족시킬 수 있다. 젖소의 경우에는 매일 30g을 권장하고 있으며 송아지의 경우에는 연령에 따라 4-8g을 급여하도록 한다. 그리고 tetany감염 가능성이 가장 높은 분만직후의 암양과 기타 암컷들은 약 3g의 마그네슘을 매일 공급하여야 한다. 알루미늄과 칼륨, 인 및 칼슘 등의 함량이 높아지면 마그네슘의 섭취할 수 있도록 하여준다.</p> 
						<p>실제로 마그네슘 함량이 높은 사료를 선택하여 다음과 같이 적용하고 있다. 즉, </p>
						<ul className="mg15f">
							<li>1) 알팔파 또는 두과 건초 : 평균 알팔파 건초 10kg은 약 30g의 마그네슘을 공급해 줄 수 있으며 이외에도 두과 건초는 부가적인 에너지 공급효과가 있다.</li>
							<li>2) 단백질 함량이 10%인 목초외에 분쇄 곡류 65%(옥수수, 보리, 수수)와 산화 마그네슘 20% 및 요오드화 염을 15%의 비율로 하여 적당량의 물과 함께 공급하여 준다. 청초는 단백질 함량이 높기 때문에 곡류 사료를 보충해 주는 것이 유박류단백질보다 휠씬 좋다. 1일 두당 250g의 비율로 섭취하였을 때 마그네슘 섭취량은 1일 27kg이 되는 셈이다.</li>
							<li>3) 저단백질 함유 목초와 65%의 면실박(또는 대두박) 그리고 산화 마그네슘을 35%로 하여 적당량의 물과 함께 급여한다. 이것을 1일 두당 170g의 비율로 섭취하면 1일 31kg이 마그네슘을 섭취하는 셈이 된다.</li>
							<li>4) 위의 2번 또는 3번을 이용하여 자유급여하되 2번의 요오드화 염을 제외시킨채 250g을 섭취하도록 한다면 1일 32g의 마그네슘을 공급해 줄 수 있다.</li>
							<li>5) 액체 당밀은 4%의 황산 마그네슘으로 보강하여 준다(액체 당밀 톤당 황산 마그네슘 40kg). 1일 두당 1kg의 섭취는 7.2g의 마그네슘을 공급하여 주는 결과가 된다.</li>
						</ul>
						<p>무기물의 형태로 하여 주로 소에 공급하는 주요 마그네슘은 다음 표에 나타나 있다.</p>
						<table className="tablestyle mg15f">
							<thead>
								<tr>
									<th>항목</th>
									<th>% Mg</th>
									<th>산화 마그네슘 15kg당으로 환산하였을 때</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>경품 및 중품의 마그네슘 (중품이 보다 안정되고 혼합이 용이함)</td>
									<td>60.32</td>
									<td>15.0</td>
								</tr>
								<tr>
									<td>수산화 마그네슘</td>
									<td>41.69</td>
									<td>21.7</td>
								</tr>
								<tr>
									<td>탄산 마그네슘</td>
									<td>28.8</td>
									<td>31.4</td>
								</tr>
								<tr>
									<td>수산화 탄산 마그네슘</td>
									<td>27.0</td>
									<td>33.5</td>
								</tr>
								<tr>
									<td>황산 마그네슘</td>
									<td>20.2</td>
									<td>44.8</td>
								</tr>
								<tr>
									<td>황산 마그네슘 칼륨</td>
									<td>11.6</td>
									<td>78.0</td>
								</tr>
								<tr>
									<td>초산 마그네슘</td>
									<td>11.34</td>
									<td>79.8</td>
								</tr>
							</tbody>
						</table>
						<p>백운석질 석회의 마그네슘은 다른 염보다 소와 양 및 염소에서 이용성이 떨어진다. 아래의 미네랄 보충제는 우수하다.</p>
						<ul className="mg15f">
							<li>1) 산화 마그네슘과 요오드화 염 및 대두유박 또는 면식박을 각각 1/3씩 혼합한 미네랄 믹스를 미네랄 공급제로 사용한다. 이의 1/3은 30g의 마그네슘을 공급하는 효과가 있다.</li>
							<li>2) 산화 마그네슘과 요오드화 염 및 골분을 각각 30%씩으로 하여 10%의 건조 당밀을 혼합한 미네랄 믹스로써 이의 1/3은 27g의 마그네슘을 공급하여 준다.</li>
							<li>3) 산화 마그네슘 2/3와 염 1/3을 혼합한 미네랄 믹스로 1/3만으로 3g의 마그네슘을 공급해 줄 수 있다.</li>
							<li>4) 기호성이 높여주기 위해 당밀과 곡류 또는 기타 물질을 함유한 고 마그네슘제제를 블록이나 미네랄 염의 형태로 공급하는데 1일 두당 250g에서 750g까지 급여하면 10-15g의 마그네슘을 공급하는 셈이다.</li>
						</ul>
						<p>유의사항 : 기타 염을 보충하여 주지 않은 상태에서 블록 또는 미네랄 믹스로의 공급은 효과가 좋다. 염의 공급수준은 품종간 및 계절에 따라 달라지기 때문에 염 함량이 높은 것만으로는 마그네슘 요구량을 충족시키지 못하게 된다. 그런데 소는 반드시 규칙적으로 마그네슘을 섭취하여야만 하기 때문에 마그네슘을 공급하여 줄 때는 다음의 사항을 고려해야만 한다.</p>
						<ul className="listStyle04 mg10f">
							<li>사료중의 마그네슘 함량</li>
							<li>보충제의 1일 섭취량</li>
						</ul>
						<p>이들 2가지 요인으로부터 1일 마그네슘 섭취량을 측정하여 가축의 요구량과 비교한다. 마그네슘 염은 기호성이 떨어지기 때문에 섭취량을 자주 체크하여야 한다. 대개 섭취량은 곡류 또는 면실박이나 대두박의 첨가제로 증가될 수 있다.</p>
						<p>Grass tetany가 발생된 적이 있는 목장의 경우 미네랄 믹스를 자유 급여시켜 1일 25g의 마그네슘을 섭취시킴으로써 grass tetany발생을 억제시킬 수 있다. 즉 절반은 섭취하고 있는 목초 혹은 기타 사료로부터 그리고 나머지 반은 미네랄 보충제로부터 획득한다.</p>
						<p>이른 봄 목초의 성장이 빠르고 무성한 지역 또는 질소와 칼륨의 시비량이 많은 목초지에 방목중인 분만직전의 소는 grass tetany에 걸릴 우려가 높으므로 마그네슘 요구량을 보충해 주어야 한다. 보충하여 주는 이유는 첫째, 분만직전의 소는 비유시 마그네슘 요구량이 가장 높고 둘째, 질소와 칼륨은 마그네슘과 길항작용을 하며 셋째, 이른 봄의 목초는 마그네슘이용효율이 낮을 뿐만 아니라 목초만으로는 에너지 요구량을 충족시키지 못하기 때문이다. 그러므로 마그네슘 급여수준이 1일 35g정도 되도록 하여 곡류를 자유섭취 또는 손으로 급여해 주거나, 단백질-미네랄 보충제를 급여하면 grass tetany의 발병을 막을 수 있다.</p>
						<p>마그네슘 보충제는 가축이 자주 모이고 서성거리는 그늘진 곳 또는 급수원 근처에 설치하여 자유롭게 접근토록 해주어야 한다. 그리고 손으로 마그네슘 보충제를 급여코자 하려면 모든 가축이 접근할 수 있는 공간을 확보해주는 것이 중요하다. Grass tetany 발생이 특히 심한 지역에서는 예방수단으로</p>
						<ul className="listStyle04">
							<li>마그네슘 비료와 백운석질 석회를 시비하거나</li>
							<li>또는 목초지에 산화 마그네슘을 살포한다.</li>
						</ul>
					</dd>
				</dl>	
				
				<dl>
					<dt>참고</dt>
					<dd>발병된 가축은 적극적으로 일어나려고 하기 때문에 관찰을 요한다.</dd>
				</dl>
			</div>
  );
}

export default Fo41;
