import React from 'react';

import './NewCowreportavg.scss';

function NewCowreportavg() {
  return (
    <h4 className='newCowreportavg'>도태우 자료가 포함된 기존의 자료를 보다 새롭게 개선하여 서비스할 예정입니다.</h4>   
  );
}

export default NewCowreportavg;
