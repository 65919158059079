import React from 'react';


function Do34() {

  return (
    <div className="techinfoview">
				<h1>TMR의 영양적 구비요건 - 건물섭취량</h1>
				<h2>TMR의 영양적 구비요건</h2>
				<p className="pstyle">&nbsp;&nbsp;&nbsp;가장 이상적인 TMR은 반추동물의 영양생리에 맞으면서 생산을 최대화할 수 있도록 균형 있는 영양소 공급을 가능케 하는데, 이를 위해서는 우군별 사료의 영양소 조성과 균일도가 중요하다. 그리고 TMR의 영양소 조성을 결정하기 위해서는 먼저 건물, 에너지, 단백질, 그 리고 섬유질의 요구량을 알아야 하는데, TMR제조시 영양소 수준의 권장량은 표 12와 같다.</p><br/>
				<p><b>&lt;표 12&gt; TMR 급여시의 영양소 권장량(건물중 %)</b></p>
				<table className="tablestyle">
					<tr>
						<td>항목</td>
						<td>건유기</td>
						<td>비유전기</td>
						<td>비유중기</td>
						<td>비유말기</td>
					</tr>
					<tr>
						<td>기준일수(일)</td>
						<td>60</td>
						<td>120</td>
						<td>120</td>
						<td>90</td>
					</tr>
					<tr>
						<td>평균유량(kg/일)</td>
						<td>- </td>
						<td>35</td>
						<td>25</td>
						<td>15</td>
					</tr>
					<tr>
						<td>TDN수준 </td>
						<td>63</td>
						<td>75-72 </td>
						<td>70-65</td>
						<td>65</td>
					</tr>
					<tr>
						<td>조단백질 수준</td>
						<td>10</td>
						<td>17</td>
						<td>14</td>
						<td>14</td>
					</tr>
					<tr>
						<td>조섬유</td>
						<td>25</td>
						<td>17</td>
						<td>20</td>
						<td>22</td>
					</tr>
					<tr>
						<td>Ca</td>
						<td>0.4</td>
						<td>0.6</td>
						<td>0.5</td>
						<td>0.4</td>
					</tr>
					<tr>
						<td>P</td>
						<td>0.3</td>
						<td>0.4</td>
						<td>0.4</td>
						<td>0.3</td>
					</tr>
				</table><br/>
				<ul>
					<li>건물섭취량
						<p className="pstyle">&nbsp;&nbsp;&nbsp;고능력우는 대체로 건물섭취량이 많은데, 건물섭취량을 기준으로 TMR의 영양소함량을 결 정할 수 있기 때문에 TMR을 급여할 때 건물섭취량을 관찰하는 것은 매우 중여한 일이다. 즉, 조단백질(CP), 가소화영양소총량(TDN), 칼슘, 인 등과 같은 영양소가 TMR에 얼마나 포함되어 있는가 보다는 실제로 이들 영양소를 얼마나 섭취하였는가 하는 것이 중요한 것이 다. 따라서 정확한 건물섭취량의 예측이야말로 사료배합시 이들 영양소의 함량을 어느 수준 으로 할 것인가를 계산하는 열쇠가 된다. 건물섭취량에 영향을 미치는 요인은 사료의 수분 함량, 조사료의 질, 사료조의 길이와 관리, 환경(더위, 환기 등) 여러 가지가 있으나 가장 중 요한 요인은 체중, 산유량 및 유지율, 비유단계(stage of lactation) 등이다.</p>
						<ul>
							<li>건물섭취량 수준
								<p className="pstyle">&nbsp;&nbsp;&nbsp;젖소가 우유생산의 유전능력을 최대로 발휘하는데 필요한 영양소를 합리적으로 공급하기 위해서는 젖소의 건물섭취량을 정확하게 파악해야 한다. 건물섭취량에 관한 정확한 자료가 없이는 필요한 영양소를 공급할 수 잇는 사료배합표를 작성할 수 없기 때문이다. 따라서 그 사료급여량과 잔량을 달고 기록하여 사료(건물)섭취량을 파악해야 한다. 그리고 그날 그날의 실제 건물섭취량을 사료배합시에 사용했던 기준 즉, 다음의 추정식에 의해 계산된 기준치와 비교해 봐야 한다. 실제 건물섭취량과 사료배합에 사용된 기준이 5% 차이일 때는 그 원인 을 찾아 교정한 다음에 사료배합을 다시 해야 한다.<br/><br/>&nbsp;&nbsp;&nbsp;<b>2산 이상의 경산우</b> : 건물섭취량(kg/일) = 0.959 + 1.051 × 주령 - 0.42 × 주령2 + 0.0005 × 주령3 + 0.012 × 체중(kg) + 0.354 × 4%지방보정산유량(FCM;kg/일) - 1.966 × 유지방% + 0.941 × 유단백%<br/><br/>&nbsp;&nbsp;&nbsp;<b>초산우</b> : 섭취량(kg/일) = -2.21 + 0.882 × 주령 -0.31 × 주령2 + 0.0003 × 주령3 + 0.016 × 체중(kg) + 0.351 × 4%지방보정산유량(kg/일) - 1.51 × 유지방(%) + 0.752 × 유단백%<br/><br/>여기서, 주령 = 착유주(週)수<br/><br/>젖소의 체중, 산유량 및 우군분리에 따른 건물섭취량의 예상 수준을 보면 다음과 같다.</p><br/>
								<p><b>&lt;표 13&gt; 체중 산유량 별 TMR 건물섭취량(단일 우군인 경우)</b></p>
								<table className="tablestyle">
									<tr>
										<td rowSpan="2">체중(kg)</td>
										<td colSpan="5">산유량(kg/일)</td>
									</tr>
									<tr style={{background: '#273143', color: '#fff'}}>
										<td>25</td>
										<td>30</td>
										<td>35</td>
										<td>40</td>
										<td>45</td>
									</tr>
									<tr>
										<td></td>
										<td colSpan="5">-------체중%-------</td>
									</tr>
									<tr>
										<td>650</td>
										<td>3.2</td>
										<td>3.3</td>
										<td>3.5</td>
										<td>3.6</td>
										<td>3.8</td>
									</tr>
									<tr>
										<td>600</td>
										<td>3.4</td>
										<td>3.5</td>
										<td>3.7</td>
										<td>3.8</td>
										<td>4.1</td>
									</tr>
									<tr>
										<td>550</td>
										<td>3.5</td>
										<td>3.6</td>
										<td>3.9</td>
										<td>4.1</td>
										<td>4.3</td>
									</tr>
								</table>
								<p>주 : 유지율 3.75%, 분만 후 70일 이후 기준.</p><br/>
								<p><b>&lt;표 14&gt; 체중 및 산유량별 예상 TMR 건물섭취량(2개 우군인경우)</b></p>
								<table className="tablestyle">
									<tr>
										<td rowSpan="3">체중(kg)</td>
										<td colSpan="6">산유량(kg/일)</td>
									</tr>
									<tr style={{background: '#273143',color:'#fff'}}>
										<td colSpan="3">고능력 우군</td>
										<td colSpan="3">중 저능력 우군</td>
									</tr>
									<tr style={{background: '#273143',color:'#fff'}}>
										<td>40</td>
										<td>45</td>
										<td>50</td>
										<td>25</td>
										<td>30</td>
										<td>35</td>
									</tr>
									<tr>
										<td></td>
										<td colSpan="5">-------체중%-------</td>
									</tr>
									<tr>
										<td>650</td>
										<td>3.7</td>
										<td>3.8</td>
										<td>4.0</td>
										<td>3.2</td>
										<td>3.3</td>
										<td>3.5</td>
									</tr>
									<tr>
										<td>600</td>
										<td>3.7</td>
										<td>4.1</td>
										<td>4.2</td>
										<td>3.3</td>
										<td>3.5</td>
										<td>3.7</td>
									</tr>
									<tr>
										<td>550</td>
										<td>3.9</td>
										<td>4.3</td>
										<td>4.5</td>
										<td>3.5</td>
										<td>3.6</td>
										<td>3.9</td>
									</tr>
								</table>
								<p>주 : 유지율 3.75%, 분만 후 70일 이후 기준.</p><br/>
							</li>
							<li>실제 건물섭취량이 감소할 경우 점검사항 
								<p>TMR급여의 장점중의 하나가 건물섭취량을 정확하게 파악할 수 있다는 것이다. TMR의 실제섭취량이 상기의 예상섭취량과 차이가 발생한 경우 다음 사항을 살펴보아야 한다.</p>
								<ul>
									<li>TMR의 수분함량과 조사료와 농후사료(곡류)의 배합비는 적절한가?</li>
									<li>TMR중 조사료에 함유된 조섬유(NDF와 ADF)함량은 적당한가?</li>
									<li>사료급여횟수, 급여량, 계기의 정확도, 사료배합과정이 올바른지?</li>
									<li>사료조가 완전히 비었을 때 사료를 주었는지 또는, 사료섭취를 촉진하는 방식으로 사료 를 급여하였는가?</li>
									<li>완전히 발효되지 않은 사일리지, 육골분, 탤로우 등 기호성이 낮은 단미사료가 과다하게 첨가되었는지의 여부?</li>
									<li>발효된 사료를 기초로하는 TMR의 수분함량이 50%를 넘는지?</li>
									<li>사료조의 길이가 우군에 충분한지, 그리고 잘 관리되고 있는지?</li>
									<li> 젖소가 편안한자, 고열에 의한 스트레스 및 적절한 환기 여부?</li>
									<li>적정량의 단백질과 소금이 공급되고 있는지?</li>
									<li>보호단백질(bypass protain)이 과다 또는 과소급여 되는지?</li>
									<li>물의 신선도와 공급량은 충분한지?</li>
								</ul>
							</li>
						</ul>
					</li>
				</ul>
			</div>
  );
}

export default Do34;
