import React from 'react';

function Fo45() {

  return (
    <div className="techinfo_view">
				<p className="viewTitle">유열</p>
				<dl>
					<dt>발병대상</dt>
					<dd>소, 면양, 염소, 대사성 질병인 유열은 젖소나 암양 및 그 밖의 암컷 등 대부분의 동물에서 유사하게 나타나고 있다. 그러므로 가축의 크기를 고려하여 치료와 예방을 하면 되므로 여기서는 젖소에 한하여 기술한다.</dd>
				</dl>
				
				<dl>
					<dt>원인</dt>
					<dd>혈액내 칼슘 함량의 저하가 주요 원인인데 유열이란 용어는 실제로 열을 수반하지 않기 때문에 잘못 쓰인 표현이다. 비유초기에 우유로 분비되는 칼슘량으로 인해 칼슘균형이 깨지면서 발생된다. 모든 젖소는 분만시 약간 저칼슘혈증을 나타내기는 하지만 심한 가축의 경우를 유열이라고 한다. </dd>
				</dl>
				
				<dl>
					<dt>징후 및 증상</dt>
					<dd>유열은 대개 분만후 3주 이내의 소와 고능력우에서 흔히 발생하며 초산우에서도 드물게 발생한다. 초기 증상으로는 식욕부진과 변비 및 무기력 등을 들 수 있고 이차적으로 신경이 예민해지고 점점 쇠약해지면서 혼수 상태에 빠진다. 그리고 머리를 늘 옆구리 쪽으로 돌린다. 유열의 발생율은 나이가 들수록 휠씬 증가하며 품종별로는 건지와 저어지 품종에서 가장 발병이 높다.</dd>
				</dl>
				
				<dl>
					<dt>질병분포와 손실규모</dt>
					<dd>착유우에서 널리 분포되어 있으며 규모로 8%이상이 단일 축군으로는 80%이상까지 감염되기도 한다. 치료를 하지 않으면 죽기도 하지만 손실은 그렇게 크지는 않다. </dd>
				</dl>
				
				<dl>
					<dt>치료</dt>
					<dd>유열에 감염된 젖소는 응급을 요하며 가능한한 속히 치료하도록 해야 한다. 기본 치료법은 증상이 나타나자 마자 칼슘붕산-글루콘산염(calcium borogluconate)을 정맥주사하여 준다.</dd>
					<dd>(주의) 칼슘염의 과다 투여는 갑작스런 심장마비를 일으킬 수 있으므로 투여량을 신중하게 계산하여 정맥내로 서서히 주입시켜야 한다. 대개 쓰러져 있는 경우에는 치료후 1-2시간이면 일어날 수 있다.</dd>
				</dl>
				
				<dl>
					<dt>대책 및 예방</dt>
					<dd>
						<p>다음의 조치는 유열의 발생을 완화시킬 수 있다.</p>
						<ul className="listStyle04">
							<li>건유기간에 저칼슘 상태 유지-건유우에 칼슘 함량이 높은 사료를 급여하는 것은 병을 악화시킬 가능성이 있으므로 건유기간에 저칼슘 상태로 유지시키는 것이 중요하다. 즉 칼슘 함량은 낮게(1일 100g이하) 그리고 인 함량은 높게(1일 40g이상)하여 급여한다. 분만전에는 1일 45g이하의 칼슘을 급여하는 것이 유열을 예방할 수 있다.</li>
							<li>칼슘 충격요법 - 분만 예정일로부터 2주전에는 매일 저칼슘, 고인 사료(칼슘 : 15-20g)를 급여하되 이 기간동안에 칼슘섭취량은 억제하지 말고 다소 증가시키면서 유지하여 준다. 이는 다소 칼슘부족을 일으켜 생리적 현상에 의하여 체내에서 활성형 비타민 D의 생산을 자극하며 계속해서 이 비타민은 뼈와 장기로부터 칼슘과 인을 공급하도록 자극한다. 따라서 칼슘과 인의 요구량이 많아지는 분만시에 뼈와 장기로부터 활성화되어 요구량을 충족시킬 수 있게 되면 결국 유열을 막을 수 있다.</li>
							<li>칼슘 : 인의 비율과 양-고형물 기준으로 칼슘 0.5%와 인 0.25%의 비율로 함유된 사료는 유열의 발생을 억제할 수 있다.</li>
							<li>고수준의 비타민 D - 분만 5일전부터 1일 두당 2천만 I.U.에 달하는 대량의 비타민 D를 분만후 1일째까지 최대 7일간 투여하면 유열 방지에 효과적이다. 그러나 정확한 분만일을 예측하기 어렵기 때문에 실제로는 치료효과가 다소 떨어진다.</li>
							<li>지나친 비만을 피할 것 - 지나친 비만이나 혹은 분만시 사료섭취량을 떨어뜨리는 요인들이 유열의 발생빈도를 높인다.</li>
						</ul>
					</dd>
				</dl>
				
				<dl>
					<dt>참고</dt>
					<dd>유열이란 용어는 열을 동반하지 않고 오히려 정상이하로 떨어지기 때문에 잘못된 표현이다.</dd>
				</dl>	
			</div>
  );
}

export default Fo45;
