import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getState, postState } from 'utils/api';
import { openOxPrint } from 'utils/OpenOxPrint';

// 컴포넌트
import ReactDatePicker from 'react-datepicker';
import ErrorMessage from 'components/errorMessage/errorMessage';
import 'react-datepicker/dist/react-datepicker.css';

// scss
import './orderSemen.scss';

function EditOrderSemen() {
  const navigate = useNavigate();
  const { sJNo } = useParams();

  // orderInfo 상태들
  const [Oname, setOname] = useState(''); // 주문자명
  const [Otel, setOtel] = useState(''); // 전화번호
  const [Jaddr, setJaddr] = useState(''); // 주소
  const [Jname, setJName] = useState(''); // 공급받을곳
  const [Jtel, setJtel] = useState(''); // 공급받을곳 전화
  const [StartDate, setStartDate] = useState(new Date()); // 공급희망일
  const [Jmemo, setJmemo] = useState(''); // 메모
  const [Jpw, setJpw] = useState(''); // 비밀번호
  const [Jpw2, setJpw2] = useState(''); // 비밀번호 확인
  const [orderList, setOrderList] = useState({}); // 주문 리스트
  const [mainData, setMainData] = useState(); // 씨수소 데이터

  const OnameRef = useRef(null);
  const OtelRef = useRef(null);
  const JaddrRef = useRef(null);
  const JnameRef = useRef(null);
  const JtelRef = useRef(null);
  const JpwRef = useRef(null);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => {
    setIsModalOpen(false);
    setErrorMessage('');
  };

  // 씨수소 데이터 불러오기
  useEffect(() => {
    getState('/board/select_semen_jumoon_list_for_update', { s_j_no: sJNo })
      .then((getdata) => {
        console.log(getdata);
        if (getdata !== undefined) {
          // result2에서 씨수소 데이터를 가져옴
          setMainData(getdata.result2);

          // result1에서 주문 정보를 가져와 상태 초기화
          if (getdata.result1) {
            setOname(getdata.result1.S_J_NAME || '');
            setOtel(getdata.result1.S_J_TEL || '');
            setJaddr(getdata.result1.S_J_ADDR || '');
            setJName(getdata.result1.S_J_NAME || '');
            setJtel(getdata.result1.S_B_TEL || '');
            setStartDate(
              getdata.result1.S_B_DATE
                ? new Date(getdata.result1.S_B_DATE)
                : new Date(),
            );
            setJmemo(getdata.result1.S_J_MEMO || '');
          }

          // 주문량 데이터를 orderList에 설정
          const initialOrderList = {};
          getdata.result2.forEach((cow) => {
            if (cow.S_STR) {
              initialOrderList[cow.S_CODE] = cow.S_STR.toString();
            }
          });
          setOrderList(initialOrderList);
        }
      })
      .catch((error) => {
        setErrorMessage(error.message);
        openModal();
      });
  }, []);

  const validateOrderData = () => {
    if (Object.values(orderList).length === 0) {
      setErrorMessage('주문량이 입력되지 않았습니다.');
      openModal();
      return false;
    }

    // 주문량이 숫자가 아닌 경우 체크 추가
    if (
      !Object.values(orderList).every((value) => !Number.isNaN(Number(value)))
    ) {
      setErrorMessage('주문량은 숫자만 입력 가능합니다.');
      openModal();
      return false;
    }

    // 총 주문량 체크
    const totalOrderVolume = Object.values(orderList).reduce(
      (sum, curr) => sum + Number(curr),
      0,
    );
    if (totalOrderVolume === 0) {
      setErrorMessage('주문이 하나도 입력되지 않았습니다.');
      openModal();
      return false;
    }

    if (totalOrderVolume > 10000) {
      setErrorMessage('주문의 총량은 10000스트로를 넘길 수 없습니다.');
      openModal();
      return false;
    }

    if (!Object.values(orderList).every((count) => count % 10 === 0)) {
      setErrorMessage('주문량은 10스트로 단위로 입력해주세요.');
      openModal();
      return false;
    }

    // 필수 입력값 체크
    if (!Oname) {
      setErrorMessage('주문자명이 입력되지 않았습니다.');
      openModal();
      OnameRef.current?.focus();
      return false;
    }
    if (!Otel) {
      setErrorMessage('전화번호가 입력되지 않았습니다.');
      openModal();
      OtelRef.current?.focus();
      return false;
    }
    if (!Jaddr) {
      setErrorMessage('주소가 입력되지 않았습니다.');
      openModal();
      JaddrRef.current?.focus();
      return false;
    }
    if (!Jname) {
      setErrorMessage('공급받을곳이 입력되지 않았습니다.');
      openModal();
      JnameRef.current?.focus();
      return false;
    }
    if (!Jtel) {
      setErrorMessage('전화받을곳이 입력되지 않았습니다.');
      openModal();
      JtelRef.current?.focus();
      return false;
    }
    if (!Jpw) {
      setErrorMessage('비밀번호가 입력되지 않았습니다.');
      openModal();
      JpwRef.current?.focus();
      return false;
    }
    if (Jpw !== Jpw2) {
      setErrorMessage('비밀번호가 일치하지 않습니다.');
      openModal();
      return false;
    }

    return true;
  };

  const handleSubmit = () => {
    if (!validateOrderData()) {
      return;
    }

    // orderData 구조를 OrderSemen과 동일하게 수정
    const updateOrderList = mainData
      .filter((cow) => orderList[cow.S_CODE])
      .map((cow) => ({
        s_code: cow.S_CODE,
        orderVolume: orderList[cow.S_CODE],
      }));

    const orderData = {
      oname: Oname,
      otel: Otel,
      jaddr: Jaddr,
      jname: Jname,
      jtel: Jtel,
      startDate: StartDate.toLocaleDateString(),
      jmemo: Jmemo,
      jpw: Jpw,
      orderList: updateOrderList,
      s_j_no: sJNo,
    };

    console.log(orderData);

    postState('/board/update_semen_jumoon/', orderData)
      .then((res) => {
        console.log('res', res);
        window.alert('주문이 수정되었습니다.');
        navigate('/bo/semen/semenList/');
      })
      .catch((error) => {
        setErrorMessage(error.message || '주문 수정 중 오류가 발생했습니다.');
        openModal();
      });
  };

  return (
    <div className="orderSemen">
      <ErrorMessage
        isOpen={isModalOpen}
        content={errorMessage}
        closeModal={closeModal}
      />
      <p>
        오른쪽의 공란에 주문량을 적어주세요.
        <span>&#40;10스트로 단위의 주문만 받습니다.&#41;</span>
      </p>
      <div className="tablebox">
        <table>
          <thead>
            <tr>
              <th>코드</th>
              <th>명호</th>
              <th>아비혈통</th>
              <th>종합지수</th>
              <th>유량</th>
              <th>지방</th>
              <th>단백질</th>
              <th>체형</th>
              <th>유방</th>
              <th>&#40;가격&#41;</th>
              <th>주문량</th>
            </tr>
          </thead>
          <tbody>
            {mainData?.map((cow) => (
              <tr key={cow.S_CODE}>
                <td>
                  <button type="button" onClick={() => openOxPrint(cow.S_CODE)}>
                    {cow.S_FULLCODE || cow.S_CODE}
                  </button>
                </td>
                <td>{cow.S_SORTNAME}</td>
                <td>{cow.S_SSORTNAME}</td>
                <td>{cow.S_ORIGIN_TPI}</td>
                <td>{cow.S_PTAM}</td>
                <td>{cow.S_PTAF}</td>
                <td>{cow.S_PTAP}</td>
                <td>{cow.S_PTAT}</td>
                <td>{cow.S_UDC}</td>
                <td>{cow.S_DANGA?.toLocaleString()}</td>
                <td>
                  <input
                    type="text"
                    maxLength={4}
                    value={orderList[cow.S_CODE] || ''}
                    onChange={(e) => {
                      setOrderList({
                        ...orderList,
                        [cow.S_CODE]: e.target.value,
                      });
                    }}
                    placeholder="0"
                    className={
                      orderList[cow.S_CODE] && orderList[cow.S_CODE] !== '0'
                        ? 'has-order'
                        : ''
                    }
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <ul className="orderInfo">
        <li className="orderTwo">
          <ul>
            <li>
              <label htmlFor="oname">
                <span>*</span>주문자명
              </label>
              <input
                type="text"
                id="oname"
                value={Oname}
                ref={OnameRef}
                onChange={(e) => setOname(e.target.value)}
                placeholder="주문자명을 입력해주세요."
                maxLength="10"
              />
            </li>
            <li>
              <label htmlFor="otel">
                <span>*</span>전화번호
              </label>
              <input
                type="text"
                id="otel"
                value={Otel}
                ref={OtelRef}
                onChange={(e) => setOtel(e.target.value)}
                placeholder="전화번호를 입력해주세요."
                maxLength="20"
              />
            </li>
          </ul>
        </li>
        <li>
          <label htmlFor="jaddr">
            <span>*</span>주소
          </label>
          <input
            type="text"
            id="jaddr"
            value={Jaddr}
            ref={JaddrRef}
            onChange={(e) => setJaddr(e.target.value)}
            placeholder="주소를 입력해주세요."
            maxLength="100"
          />
        </li>
        <li className="orderTwo">
          <ul>
            <li>
              <label htmlFor="jname">
                <span>*</span>공급받을곳
              </label>
              <input
                type="text"
                id="jname"
                value={Jname}
                ref={JnameRef}
                onChange={(e) => setJName(e.target.value)}
                placeholder="공급받을곳을 입력해주세요."
                maxLength="50"
              />
            </li>
            <li>
              <label htmlFor="jtel">
                <span>*</span>공급받을곳 전화
              </label>
              <input
                type="text"
                id="jtel"
                value={Jtel}
                ref={JtelRef}
                onChange={(e) => setJtel(e.target.value)}
                placeholder="공급받을곳 전화를 입력해주세요."
                maxLength="20"
              />
            </li>
          </ul>
        </li>
        <li>
          <label htmlFor="datePicker">공급희망일</label>
          <ReactDatePicker
            dateFormat="yyyy/MM/dd"
            selected={StartDate}
            onChange={(date) => setStartDate(date)}
          />
        </li>
        <li>
          <label htmlFor="jmemo">메모</label>
          <textarea
            id="jmemo"
            value={Jmemo}
            onChange={(e) => setJmemo(e.target.value)}
            placeholder="메모를 입력해주세요."
          />
        </li>
        <li className="orderTwo">
          <ul>
            <li>
              <label htmlFor="jpw">
                <span>*</span>비밀번호
              </label>
              <input
                type="password"
                id="jpw"
                value={Jpw}
                ref={JpwRef}
                onChange={(e) => setJpw(e.target.value)}
                placeholder="비밀번호를 입력해주세요."
                maxLength="4"
              />
            </li>
            <li>
              <label htmlFor="jpw2">
                <span>*</span>비밀번호확인
              </label>
              <input
                type="password"
                id="jpw2"
                value={Jpw2}
                onChange={(e) => setJpw2(e.target.value)}
                placeholder="비밀번호 확인"
                maxLength="4"
              />
            </li>
          </ul>
        </li>
      </ul>
      <strong>* 비밀번호는 4자리의 숫자로 지정해주세요.</strong>
      <ul className="orderSemenBtn">
        <li>
          <button type="button" onClick={handleSubmit}>
            주문 수정하기
          </button>
        </li>
        <li>
          <button
            type="button"
            onClick={() => {
              if (
                window.confirm(
                  '변경된 정보가 저장되지 않습니다. 돌아가시겠습니까?',
                )
              ) {
                navigate('/Web/Ox/BuySemen');
              }
            }}
          >
            목록으로 돌아가기
          </button>
        </li>
      </ul>
    </div>
  );
}

export default EditOrderSemen;
