import React from 'react';

import Pic460 from 'assets/images/sub/_images/pic460.jpg';
import Pic461 from 'assets/images/sub/_images/pic461.jpg';
import Pic462 from 'assets/images/sub/_images/pic462.jpg';
import Pic463 from 'assets/images/sub/_images/pic463.jpg';
import Pic464 from 'assets/images/sub/_images/pic464.jpg';
import Pic465 from 'assets/images/sub/_images/pic465.jpg';
import Pic466 from 'assets/images/sub/_images/pic466.jpg';

function Do74() {

  return (
    <div className="techinfoview">
				<h1>케토시스(Ketosis)</h1>
				<p className="pstyle">채내에 당분이 모자라거나 당(糖)대사가 불완전한 까닭에 혈액 내 케톤체가 다량 축적되어 소화기 장해와 신경증상을 일으키는 상태를 말하며 대부분 비유극기 직전(분만 6∼7주경)에 다발한다. 뇨를 채취하여 조사한다.</p>
				<div className="leftpart">
					<h2>원인</h2>
					<ul>
						<li>체내의 당(糖)이 부족하거나 당의대사가 불완전하기 때문에 케톤체가 증가한다. 
							<ul>
								<li>혈통이 우수하고 영양상태가 양호한 젖소에서 농후사료의 과잉급여로 지나치게 살찌고 운동이 부족할 때 많이 발생</li>
								<li>전위(1,2,3위)기능부전, 당뇨병등도 원인이 된다.</li>
								<li>소화불량이나 유방염, 창상성 심냥염, 자궁 내막염, 난소낭종에도 수반되기 쉽다.</li>
							</ul>
						</li>						
					</ul>
					<table className="tablestyle16">
						<tr>
							<td>혈중</td>
							<td>정상</td>
							<td>0.6mg∼6.0mgdl</td>
						</tr>
						<tr>
							<td></td>
							<td>케토시스</td>
							<td>20∼30mg/dl</td>
						</tr>
						<tr>
							<td>뇨중</td>
							<td>정상</td>
							<td>0.3∼3.0mgdl</td>
						</tr>
						<tr>
							<td></td>
							<td>케토시스</td>
							<td> 25∼100mg/dl </td>
						</tr>
					</table>
					<h2>증상</h2>
					<ul>
						<li>대개 비유 극기 직적에 발생율이 높다.</li>
						<li>혈액중의 아세톤이 뇨 중에 검출되며 특히한 냄새(아세톤 냄새)를 낸다. </li>
						<li>케토시스에는 소화기형, 신경형, 유열형이 있고 불임이나 2등유에 관계한다.
							<ul>
								<li>케토시스의 대부분은 소화기형으로 분만 2주이내 다발, 원기가 없고, 식욕부진, 되새김이 감퇴, 유량이 감소한다. 소화기형은 케토시스중에서 발생률이 높은 형태이며 농후사료나 엔실레지는 먹지 않고 건초만 즐겨 채식한다. </li>
								<li>신경형의 것은 전신증상이 갑자기 악화하며 흥분과 전신경련으로 무릎을 절며 보행한다.</li>
							</ul>
						</li>
					</ul>
					<h2>예방</h2>
					<ul>
						<li>평소 사양관리를 좋게 하는 것이 케토시스 예방의 지름길이다.
							<ul>
								<li>분만전∼분만후 1개월까지 당밀을 급여하면 케토시스의 예방에 도움이 된다.</li>
								<li>비유기간 중에는 소가 너무 살찌지 않게 한다.</li>
							</ul>
						</li>
					</ul>
					<h2>목장에서의 처치</h2>
					<ul>
						<li>증상이 가벼운 것은 포도당을 주사하면 완전히 낫는다.
							<ul>
								<li>정맥 또는 피하주사를 1주일동안 매일 계속한다. </li>
								<li>신경형으로 의심될 경우 글루콘산 칼슘(시중판매 칼슘제)액을 수화 정맥주사한다. </li>
								<li>운동으로 혈액순환을 촉진시키고 간장의 활력을 증가시킨다.</li>
								<li>분만후 1개월간 당밀이나 프로필렌 그리콜을 먹인다. </li>
							</ul>
						</li>
					</ul>
				</div>
				<div className="rightpart">
					<img src={Pic460} className="nonborder" alt=""/>
					<p>소화기형 케토시스에 걸린 소:복부가 홀쭉하고 수척하다.</p>
					<img src={Pic461} className="nonborder" alt=""/>
					<img src={Pic462} className="nonborder" alt=""/>
					<img src={Pic463} className="nonborder" alt=""/>
					<p>지방간으로 인한 케토시스로 기립불능된 소</p>
					<img src={Pic464} className="nonborder" alt=""/>
					<img src={Pic465} className="nonborder" alt=""/>
					<img src={Pic466} className="nonborder" alt=""/>
				</div>
			</div>
  );
}

export default Do74;