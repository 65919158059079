import React from 'react';

import pic224 from 'assets/images/sub/_images/pic224.png';
import pic225 from 'assets/images/sub/_images/pic225.gif';
import pic226 from 'assets/images/sub/_images/pic226.png';

function Do173() {

  return (
    <div className="techinfoview">
      <h1>초지조성 대상지 환경조사 및 설계서 작성요령</h1>
      <h2>조사대상지 현황</h2>
      <ul>
        <li>소재지
          <ul>
            <li>초지조성 대상지의 소재지를 도, 시, 군, 읍, 면, 리 및 번지까지 자세히 표기한다.</li>
          </ul>
        </li>
        <li>소유구분
          <ul>
            <li>토지의 소유를 국유, 공유 및 사유 등으로 구분 기재한다.</li>
          </ul>
        </li>
        <li>필지수
          <ul>
            <li>초지 대상지가 몇 필지로 되어 있는가를 기재한다. 지목은 답, 전, 과수, 임지 등으로 구분하고 지적을 기입한다. </li>
          </ul>
        </li>
      </ul>
      <h2>토양조건</h2>
      <ul>
        <li>표고 
          <ul>
            <li>표고는 지형에 준하거나 고도계로 실측하여 m로 표시하되 0∼200, 200∼400, 400∼600, 600∼800, 800m이상으로 구분하여 해당란에 ○표 한다.</li>
          </ul>
        </li>
        <li>지형
          <ul>
            <li>지형은 토양생성에 미치는 영향이 매우 큰바 직접적으로는 침식에 의하여 토양의 성질에 영향을 미친다.<br/>일반적으로 잘 발달된 토양은 기복이 약한 저구릉지 및 구릉지에 분포되며 하천변의 평탄지나 경사가 심한 구릉지에서는 토양발달이 불충분하다. 지형은 다음과 같이 크게 나눌 수 있다. </li>
          </ul>
        </li>
        <li>
          <ul>
            <li></li>
          </ul>
        </li>
        <li>경사
          <ul>
            <li>토양환경중 경사는 토양의 유효토심과 관계되며 일반적으로 경사가 완만한 지역에서는 유효토심이 매우 깊은 반면 경사가 심한 산악지에서는 토양 침식으로 인하여 표토의 유실이 심하므로 유효토심이 매우 얕다. 경사도의 측정은 경사도계를 이용하여 측정하며 경사의 등급은 도로 표시되어 왔으나 현재는 세계각국에서 퍼센트(%)로 표시하고 있다.<br/><br/>경사도의 표시에서 도와 퍼센트와의 관계는 경사도의 약 2배갸 경사 퍼센트라고 생각하면 된다. <br/>예를 들면 5。의 경사는 실제 8.8%의 경사에 해당하나 약 10%라고 보면 된다.<br/>우리나라의 정밀토양조사에서 사용하고 있는 경사도의 구분은 다음과 같다. <br/><br/>
              <p>토양경사명 및 경사율</p>
              <table className="tablestyle">
                <tr>
                  <td>부호</td>
                  <td>용어</td>
                  <td>경사(%)</td>
                  <td>경사(도)</td>
                </tr>
                <tr>
                  <td>A</td>
                  <td>평탄</td>
                  <td>0∼2</td>
                  <td>0∼1°9′</td>
                </tr>
                <tr>
                  <td>B</td>
                  <td>매우 약한 경사 </td>
                  <td>2∼7</td>
                  <td>1°9′∼4°</td>
                </tr>
                <tr>
                  <td>C</td>
                  <td>약한 경사</td>
                  <td>7∼15</td>
                  <td>4°∼8°32′</td>
                </tr>
                <tr>
                  <td>D</td>
                  <td>경사</td>
                  <td>15∼30</td>
                  <td>8°32′∼16°42′</td>
                </tr>
                <tr>
                  <td>E</td>
                  <td>심한 경사</td>
                  <td>30∼60</td>
                  <td>16°42′∼31°</td>
                </tr>
                <tr>
                  <td>F</td>
                  <td>매우 심한 경사</td>
                  <td>&gt; 60</td>
                  <td>&gt; 31°</td>
                </tr>
              </table><br/>
              <p>그러나 초지토양 적지선정에는 편의상 다음과 같이 구분 기준을 설정하여 사용하기도 한다.</p>
              <table className="tablestyle">
                <tr>
                  <td>부호</td>
                  <td>용어</td>
                  <td>경사(%)</td>
                  <td>경사(도)</td>
                </tr>
                <tr>
                  <td>A</td>
                  <td>약한 경사</td>
                  <td>0∼15</td>
                  <td>0∼8°32′</td>
                </tr>
                <tr>
                  <td>B</td>
                  <td>경사</td>
                  <td>15∼30</td>
                  <td>8°32′∼16°42′</td>
                </tr>
                <tr>
                  <td>C</td>
                  <td>심한 경사</td>
                  <td>30∼50</td>
                  <td>16°42′∼26°34′</td>
                </tr>
                <tr>
                  <td>D</td>
                  <td>매우 심한 경사</td>
                  <td>50∼70</td>
                  <td>26°34′∼35°</td>
                </tr>
                <tr>
                  <td>E</td>
                  <td>급준지</td>
                  <td>&gt; 70</td>
                  <td>&gt; 35°</td>
                </tr>
              </table><br/>
              <p>경사도의 각도와 퍼센트(%)와의 관계</p>
              <img src={pic224} className="nonborder" alt=""/>
            </li>
          </ul>
        </li>
        <li>토양배수
          <ul>
            <li>매우 양호
              <ul>
                <li>물이 매우 빨리 빠지며 셩사가 심하거나 토층이 얕고 사질토양으로써 공극이 많은 토양에서 볼 수 있다. 주 토색은 갈색, 황색 및 적색계로써 지하수위가 150㎝이하에 있으며 회색반문은 없다. 해수욕장의 모래사장이나 경사가 심한 산악지의 토심이 얕은 토양이 이에 속한다.</li>
              </ul>
            </li>
            <li>양호
              <ul>
                <li>물이 쉽세 빠지고 토성은 비교적 중립질이며 기층하부 혹은 100㎝이하의 깊이에서 회색반문이 생길 수도 있다. 주 토색은 갈색, 황색 및 적색계로써 지하수위는 150㎝이하이고 셩사가 심하지 않은 구릉지 토양등이 이에 속할 수 있으며 토양이용은 대부분 밭과수, 상전 및 초지로 이용되는 토양들이다.</li>
              </ul>
            </li>
            <li>약간 양호
              <ul>
                <li>배수가 약간 느리며 토양단면이 잠시 습한 상태로 남아 있다. 주 토색은 갈색, 황색 및 적색계로써 회색반문이 전층을 통하여 있거나 심토의 어느 부분에만 있을 수 있으며 지하수위는 100∼150ｍ에 있다. 토성에는 관계되지 않으나 선상지,대지,평탄지 및 곡간지 토양이 이에 속하며 토지이용을 보면 주로 2모작논이 많다.</li>
              </ul>
            </li>
            <li>약간 불량
              <ul>
                <li>배수가 느려서 상당한 기간동안 토양이 습한 상태로 남아있다. 주 토색은 회갈색 및 회색계 토양으로써 단면 전층을 통하여 황색 및 황색계의 반문이 2%이상 함유되어 있다. 지하수위는 50∼100㎝에 있으며 토성은 관계없으나 비교적 세입질 토양으로써 평탄지 저지 및 곡간지에 있는 토양이 이에 속한다.</li>
              </ul>
            </li>
            <li>불량
              <ul>
                <li>배수가 매우 느려서 지하수위가 연중 지표상부에 또는 표토근처에 머물러 있다. 주 토색은 회색 및 청회색계로써 담황, 오리브 및 회색계의 반문이 없거나 2%이하이다. 조성에는 관계없으나 비료적 세립질 토양으로써 평탄저지, 하상저지 및 곡산지에 있는 토양이며 특히 모래가 많은 토양이라 할지라도 지하수위가 높으면 토양배수가 불량하다.</li>
              </ul>
            </li>
            <li>자갈함량
              <ul>
                <li>돌 및 자갈은 적경 2㎜이상의 것을 말하며 돌과 자갈이 있고 없는 것은 그 토양의 농업, 임업 및 초지 이용에 상당한 영향이 있을 뿐만아니라 자갈 및 돌의 성질을 알아내므로써 그 토양의 특성을 알 수 있다.<br/>따라서 자갈이나 돌이 많은 토양에서는 농기계의 사용이 불편하고 작업능률도 떨어지며 농작물 목초류 및 과수의 생육에도 관계된다.<br/>돌 및 자갈의 조사에는 종류, 풍하정도, 모양, 크기 및 양 등을 조사하여야 하나 여기서는 양과 크기에 대하여 기술하면 다음과 같다. <br/><br/>
                <p>돌 및 자갈의 함량별 구분기준</p>
                <table className="tablestyle">
                  <tr>
                    <td>부호</td>
                    <td> 함량(용량,%)</td>
                    <td>명칭</td>
                    <td>비고</td>
                  </tr>
                  <tr>
                    <td>1</td>
                    <td>3이하</td>
                    <td>없음</td>
                    <td rowSpan="4">자갈 : 직경0.2∼7.5㎝<br/>돌 : 직경7.5∼60㎝<br/>바위 : 직경 60㎝이상</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>3∼10</td>
                    <td>약간 있음</td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>10∼35</td>
                    <td>있음</td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td>35이상</td>
                    <td>많음</td>
                  </tr>
                </table>
                <p>초지조성할 때는 자갈함량도 문제가 되지만 암반의 노출도 역시 문제가 되므로 암반의 노출정도를 기재하지 않으면 안된다. 암반의 노출정도의 표시는 자갈함량에 준하여 기재토록 한다.</p>
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li>유효토심
          <ul>
            <li>토심은 토양의 길이를 말하며 유효토심은 작물의 생육에 있어서 뿌리의 신장에 제한을 받지 않는 토양의 깊이라 볼 수 있다.<br/>유료토심에 함유되지 않은 토양층은 조사질층, 석력질충, 경반층, 유산염집적층. 염류집적층, 지하수위로 인한 청회색층, 암반층 등이다.<br/>유효토심의 깊이에 따라 토양의 깊이를 다음과 같이 구분하고 있다. <br/><br/>
              <p>유효토심의 구분기존</p>
              <table className="tablestyle">
                <tr>
                  <td>구분</td>
                  <td>토심(㎝)</td>
                  <td>구분</td>
                  <td>토심(㎝)</td>
                </tr>
                <tr>
                  <td>매우 얕음</td>
                  <td>0∼20</td>
                  <td rowSpan="3">깊음 <br/>매우깊음</td>
                  <td rowSpan="3">100∼150 <br/>150이상</td>
                </tr>
                <tr>
                  <td>얕음</td>
                  <td>20∼50</td>
                </tr>
                <tr>
                  <td>보통</td>
                  <td>50~100</td>
                </tr>
              </table>
            </li>
          </ul>
        </li>
        <li>토양침식
          <ul>
            <li>물에 의한 침식
              <ul>
                <li>물에 의한 토양침식은 토양유실 정도의 차이에 따라 4가지 등급으로 나누어 설명할 수 있다.</li>
              </ul>
            </li>
            <li><b>1급</b> : 지표에 미세한 세류식 협구가 있거나 침식을 받은 흔적은 있지만 A층의 두께나 성질이 크게 변화되지는 않느낟. A층의 두꼐나 성질이 크게 변화되지는 않는다. A층이 매우 엷은 토양을 제외하고는 대부분의 토양은 A층이 표토로 되어 있다.<br/><br/>유실된 표토층의 양은 25%이하이며 잔류표토층의 두께가 15㎝이상이다. 또 침식을 받지 않은 토양과 동일한 이용과 관리를 하여도 무방하다.</li>
            <li><b>2급</b> : 보통 농기계를 사용하였을 때 B층 상부까지 경운될 정도로 A층이 침식된 토양이 이에 해당되며 일번적으로 작토층은 A급과 그 바로 밑에 있는 층위가 혼합되어서 이루어진다. 때로는 얕은 침구가 있을 경우도 있으며 표토층의 25∼75%가 유실되어 잔류A층이 두께가 12㎝이하이다.</li>
            <li><b>3급</b> : A층이 대부분 유실되어 작토층은 주로 B층이나 혹은 그이하의 층위로 된다. 얕은 침식구나 또는 상당히 깊은 침식구가 흔히 보인다. 표토층의 75%이상이 유실되어 있고 때로는 A층 이외에 B층의일부 또는 전부 그리고 C층의 상부까지 유실되어 있다.</li>
            <li><b>4급</b> : 깊은 협곡침식이 생겨서 복잡한 지형을 가진 토양이 이에 해당한다. 협곡과 협곡사이의 좁으 SeKD을 제외하고는 토양단면이 붕괴되어 있다. 침식된 그 형태로는 작물을 재배할 수 없으나 토양특성이 양호할 경우에는 침6식을 잘 방지하면 개간해도 무방할 때가 있다.</li>
            <li>바람에 의한 침식
              <ul>
                <li>우리나라에서는 바람에 의한 침식은 많지 않으며 바람에 의하여 운반 퇴적된 미세한 토양을 로-스 라고 한다. </li>
              </ul>
            </li>
          </ul>
        </li>
        <li>토성
          <ul>
            <li>노양입자 즉 점토(직경 : 0.002㎜이하),미사(직경 0.002∼0.005㎜),모래(직경 0.005∼2㎜)의 배합룰이 토성이며 토성은 식물의 갱육에 있어서 중요할 뿐만 아니라 토양의 자연분류이 최저단위 기준으로도 중요한 성질이다. 현재 우리나라에서 사용하고 있는 기준은 미국 농무성 방법을 사용하며 토성구분은 다음 삼각형에 의하여 결정한다.<br/>
              <img src={pic225} alt=""/>
              <p>이상의 삼각도표에서는 토성이 12개로 구분 표시되어 있으나 편의상 다음 표와 같이 5개의 토성군으로 구분 기재한다. </p><br/>
              <p>토양군구분</p>
              <table className="tablestyle">
                <tr>
                  <td>구분</td>
                  <td>기본적인 토성명</td>
                </tr>
                <tr>
                  <td>사토계 토양</td>
                  <td>사토</td>
                </tr>
                <tr>
                  <td>사야토계 노양</td>
                  <td>사양토</td>
                </tr>
                <tr>
                  <td>양토계 토양</td>
                  <td>양토, 미세질양토, 미사토 </td>
                </tr>
                <tr>
                  <td>식양토계 토양</td>
                  <td>식양노, 사질식양토, 미사질식양토</td>
                </tr>
                <tr>
                  <td>식토계 토양</td>
                  <td>식토, 미사질식토, 사질식토</td>
                </tr>
              </table>
            </li>
          </ul>
        </li>
        <li>경사방향
          <ul>
            <li>경사방향은 돈서남북의 방향을 표시하되 지역에서 경사방향이 여러 곳으로 되어 있을 때는 방향과 면적을 개략적으로 기입한다. </li>
          </ul>
        </li>
      </ul>
      <h2>식생</h2>
      <p>식생군은 그 지역의 기후와 토양성질에 크게 영향을 받아 형성되는 것이므로 지역 및 지대에 따라 식생군이 다르다.<br/>식생군은 생육환경이 달라지면 그에 적응할 수 있는 식생군으로 변천하는 것이므로 고정상태로 언제나 있는 것이 아니고 인위적으로 변화시킬 수도 있다.</p>
      <ul>
        <li>우리나라의 산지 식생
          <p>&nbsp;&nbsp;&nbsp;현재 우리나라 산지의 점유식생을 보면 수목, 관목, 초본으로 크게 나눌 수 있으며 초본은 장초형, 단초형으로 구분된다.<br/>&nbsp;&nbsp;&nbsp;이들 식물의 분포를 보면 수목이나 관목류는 주로 표고가 높은곳에 분포되어 있고 초본은 나무를 벌채하거나 산불등으로 나무가 죽었을 때 음생식물이 없어지고 양생초본이 새로 나타나는 것으로써 사람의 손이 잘 미치는 산기슭이나 구릉지 및 부락 주변에 주로 분표한다.<br/>&nbsp;&nbsp;&nbsp;초본류가 우점된 지역중에서 단초형은 토양비옥도가 낮고 건조한 토양에 분포되어 있으며 장초형인 새, 억새, 솔새 등은 토양 수분이 풍부하고 비옥도가 보다 높은 곳에 분포되어 있다.<br/>&nbsp;&nbsp;&nbsp;또한 수목의 종류에 따라 초본류의 분포가 달라진다. 특히 소나무, 잣나무 등의 상록침엽소가 밀생된 곳에는 김의 털, 까치수염 등의 단초형이 분포되며 낙엽수인 참나무류, 떡갈나무 등의 활렵수의 식생밀도가 높은 곳에는 장초형인 새, 억새, 솔새 등의 분포가 많다. </p><br/>
          <p>우리나라 산지의 우점 식생</p>
          <table className="tablestyle">
            <tr>
              <td colSpan="2"></td>
              <td>식생</td>
            </tr>
            <tr>
              <td colSpan="2">수목</td>
              <td>소나무,떡갈나무,갈참나무,상수리나무,아카시아,갈참나무,오리나무,일본잎 갈나무,보리수,다름나무</td>
            </tr>
            <tr>
              <td colSpan="2">관목</td>
              <td>싸리, 진달래, 철쭉, 참싸리, 찔레, 국수나무, 생강나무, 청미레덩국, 조릿대</td>
            </tr>
            <tr>
              <td rowSpan="2">초본</td>
              <td>장초</td>
              <td>새, 개솔새, 억새, 솔새, 기름새, 큰 기름새, 바랭이, 개망초,고삼, 개억새, 엉겅퀴</td>
            </tr>
            <tr>
              <td>단초</td>
              <td>잔디, 김의털, 제비쑥, 망초, 까치수염, 매듭풀, 고사리, 양지꽃, 도라지</td>
            </tr>
          </table>
        </li>
        <li>식생조사 요령
          <p>식생조사법에는 단위면적당의 식생종류별 개체수를 직접 조사하는 방법과 임의의 일정 거리 및 방향에 대한 식생종류별 발생빈도를 조사하여 모집단을 대표하는등 여러가직 있으나 본 조사에서는 관찰에 의하여 주요 수목과 주요 야초의 식생밀도를 많은 순위에 따라 숫자를 기입한다.</p>
          <ul>
            <li><b>소나무류 </b> : 소나무, 젓나무, 낙엽송 등을 포함항 발생밀도를 조사</li>
            <li><b>참나무류 </b> : 떡갈나무, 갈참나무, 상수리나무, 졸참나무를 포함하여 조사</li>
            <li><b>관목류 </b> : 싸리, 진달래, 철쭉, 참싸리, 국수나무, 생강나무, 조릿대 등 수고가 낮은 종류의 나무 전체를 포함 조사</li>
            <li><b>기타 </b> : 밤나무, 아카시아, 자작나무, 물푸레나무 등의 조사항목에 없는 것을 포함하여 순위를 결정한다.</li>
            <li><b>주요 야초</b> : 새, 억세, 솔새류, 띠, 사초류 등은 우점 식생 순위를 기재하고 기타 야초류는 전체를 포함하여 순위를 기재한다. </li>
          </ul>
        </li>
      </ul>
      <h2>초지조성법 적용</h2>
      <p>초지조성법은 경운조성법고 불경운조성법으로 크게 나눠진다. 이두가지의 초지조성방법은 경사도, 토양유실의 정도, 나무의 크기 및 밀도, 암석 및 조성농가의 조성비의 사정 등 여러 가지 조건에 따라 선별 결정하게 된다. </p>
      <ul>
        <li>경운조성법이 유리한 대상지
          <ul>
            <li>경사도 15°(33%)미만인 곳 </li>
            <li>석력함량이 30%이하로 경운이 가능한 곳 </li>
          </ul>
        </li>
        <li>불경운조성이 유리한 곳
          <ul>
            <li>경사도 15°(33%)이상으로 경운이 어려운 곳 </li>
            <li>석력함량이 30%이사응로 경운이 불가능한 곳 </li>
            <li>경사도가 15°미만이지만 표층 또는 지하에 암석이 많아 경운이 불가능한 곳 </li>
            <li>지면이 고르지 못하여 경운이 불가능한 곳 </li>
            <li>경사 15°미만이라도 거목이 밀생되어 발조작업이 어려운 곳 </li>
          </ul>
        </li>
      </ul>
      <h2>토양시료 채취 </h2>
      <p>토양시료는 필지별로 최소한 9개소 이상에서 채취하여 모두 한데 잘 혼합, 한 점의 혼합시료를 만드나. 단, 한 필지의 면적이 10㏊ 이상인 곳은 지형과 토성등을 고려하여 대상지를 약 10㏊단위로 나누어 위와 같은 요령으로 10㏊당 1점씩의 혼합시료를 채취한다. </p>
      <h2>조사자의 의견 </h2>
      <p>본 조사는 초지 및 토양전문직의 공동조사이므로 초지 및 토양면에서의 조사의견을 기입하고 날인한다. <br/><br/>초지조성 대상지 환경조사서</p>
      <img src={pic226} className="nonborder" alt=""/>
      <p>(4) 적용조성 방법별 면적 : 경운조성( ㏊)<br/><br/>불경운조성( ㏊) <br/>제외면적(부적지)( ㏊) </p><br/>
      <p>(5)입지조건</p>
      <table className="tablestyle">
        <tr>
          <td>도로요개설거리 </td>
          <td style={{textAlign: 'right', marginRight: '10px;'}}>m</td>
          <td>적기요가설거리</td>
          <td style={{textAlign: 'right', marginRight: '10px'}}>m</td>
        </tr>
        <tr>
          <td>수자원</td>
          <td>풍부</td>
          <td>보통</td>
          <td>부족</td>
        </tr>
      </table>
      <p>(6)토양시료 채취 약도<br/>(7)조사자 의견</p>
      <table className="tablestyle16" style={{width: '50%'}}>
        <tr>
          <td style={{width: '50%'}}>(가)초지분야<br/>소속<br/>직명<br/>성명</td>
          <td></td>
        </tr>
        <tr>
          <td>(나)토양분야<br/>소속<br/>직명<br/>성명</td>
          <td></td>
        </tr>
      </table>
    </div>
  );
}

export default Do173;