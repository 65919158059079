import React, { useState, useRef } from 'react';
import { postState } from 'utils/api';

// 컴포넌트
import SubTitle from 'components/subTitle/SubTitle';
import ErrorMessage from 'components/errorMessage/errorMessage';
import Department from '../common/Department';

// scss
import '../common/common.scss';

function DieoutCowAnalysis() {
  const farmCodeRef = useRef(null); // 조합 농가코드
  const passwordRef = useRef('0000'); // 비밀번호

  const [isModalOpen, setIsModalOpen] = useState(false); // 모달 추가
  const [errorMessage, setErrorMessage] = useState('');

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => {
    setIsModalOpen(false);
    setErrorMessage(''); // 모달이 닫힐 때 에러메세지 초기화
  };

  async function submitHandler(event) {
    event.preventDefault();

    const inputid = farmCodeRef.current.value; // 조합 농가코드
    const pwd = passwordRef.current.value; // 비밀번호

    const numberRegex = /^[0-9]+$/; // 숫자로만 이루어진 정규표현식

    if (inputid.trim() === '') {
      setErrorMessage('조합 농가코드를 입력하십시오.');
      openModal();
      farmCodeRef.current.focus();
      return;
    }

    if (!numberRegex.test(inputid) || inputid.length < 8) {
      setErrorMessage('조합 농가코드 숫자 8자리를 입력해주세요.');
      openModal();
      farmCodeRef.current.focus();
      return;
    }

    if (pwd.trim() === '') {
      setErrorMessage('비밀번호를 입력하십시오.');
      openModal();
      passwordRef.current.focus();
      return;
    }

    if (pwd.length < 4) {
      setErrorMessage('비밀번호 4자리를 입력해주세요.');
      openModal();
      passwordRef.current.focus();
      return;
    }

    const baseDir = '/user/login/';
    const props = {
      id: inputid,
      passwd: pwd,
    };

    console.log(
      '*** components/pages/login/submitHandler - user_id : ',
      inputid,
    );
    console.log('*** components/pages/login/submitHandler - user_pwd : ', pwd);

    postState(baseDir, props)
      .then((getdata) => {
        if (getdata.result === false) {
          setErrorMessage('입력정보와 일치하는 유저를 찾을 수 없습니다.');
          openModal();
          farmCodeRef.current.focus();
          return;
        }

        if (getdata.result !== false) {
          // console.log('### is login', getdata.result);
          localStorage.setItem('cong', getdata.result.ccode);
          localStorage.setItem('farm', getdata.result.fcode);
          localStorage.setItem('passwd', pwd);

          localStorage.removeItem('month');
          // console.log('### is login', islogin);

          window.open('/dcitest/dciExpert/NewCowAnal101', '_blank');
        }
      })
      .catch((error) => {
        alert(error.message);
      });

    closeModal();
  }

  return (
    <div className="dieoutCowAnalysis subCon pullContent">
      <ErrorMessage
        isOpen={isModalOpen}
        content={errorMessage}
        closeModal={closeModal}
      />
      <div className="commonInner">
        <div className="commonInfo">
          <SubTitle />
          <strong>
            조합 농가코드는 우편으로 발송되는 검정성적표 우측 상단을 참고하세요
          </strong>
          <ul>
            <li>1. 조합 농가코드 &#40;8자리&#41; : 1234-5678 에서 12345678</li>
            <li>
              2. 검정월 &#40;6자리&#41; : 2017년 11월 자료를 보고 싶으면, 201711
            </li>
          </ul>
        </div>
        <div className="commonForm">
          <ul>
            <li>
              <label htmlFor="code">조합 및 농가코드</label>
              <input ref={farmCodeRef} type="text" id="code" maxLength="8" />
            </li>
            <li>
              <label htmlFor="password">비밀번호</label>
              <input
                ref={passwordRef}
                type="password"
                id="password"
                maxLength="4"
                defaultValue="0000"
              />
            </li>
          </ul>
          <div className="commonFormBtn">
            <button type="button" onClick={(event) => submitHandler(event)}>
              <span>조회</span>
            </button>
          </div>
        </div>
      </div>
      <Department
        phone="031-929-1064,74"
        department="젖소개량사업소 육종검정팀"
      />
    </div>
  );
}

export default DieoutCowAnalysis;
