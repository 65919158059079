import React from 'react';

import pic237 from 'assets/images/sub/_images/pic237.jpg';
import pic238 from 'assets/images/sub/_images/pic238.jpg';
import pic239 from 'assets/images/sub/_images/pic239.gif';

function Do192() {

  return (
    <div className="techinfoview">
      <h1>스위트 클로버</h1>
      <p>영명 : Sweet clover <br/>학명 : Melioltus spp. </p>
      <div style={{width: '60%',float: 'left'}}>
        <h2>원산지</h2>
        <p className="pstyle">&nbsp;&nbsp;&nbsp;스위트클로버는 1739년부터 북미에서 재배된 기록이 있으나, 1875년 이래 재배된 목초로 알려졌다. 성장속도가 빠른 두과목초로서 청초, 건초, 싸일레지로 조제 이용하고 토양을 비옥하게 하는 토양개량효과도 크다. 꽃은 질이 우수한 꿀을 만드는데 좋은 재료가 된다.</p>
        <h2>성상</h2>
        <p className="pstyle">&nbsp;&nbsp;&nbsp;생육초년도에도 줄기 밑동으로부터 새싹이 일부 나오고, 일반적으로 표토에 가까운 지하에 주로 퍼진다. 2년재 봄 새싹은 생육이 강하며, 성장속도가 빠르고 성장속도가 빠른 줄기는 알팔파 보다도 키가 크고 거칠다.<br/>&nbsp;&nbsp;&nbsp;스위트클로버는 흰꽃이 피는 것과 노란색을 피는 것 2가지로 구분된다. 흰색의 메리로터스 알바데사이어(Meliiotus alba Desr)는 키가 크고 줄기가 거칠며, 노란색꽃의 스위트클로버보다 잎이 거칠다.<br/>&nbsp;&nbsp;&nbsp;노란색꽃의 스위트클로버(M. officinalis L.)는 가뭄에 강하고, 파종시 강건한 유식물이 생산되며 개화 시기가 빠르고, 종피에 연한 반점이 있다.<br/>&nbsp;&nbsp;&nbsp;뿌리는 깊이 뻗으며, 강한 직근은 토양을 갈라지게하고 통기성을 양호하게 한다.<br/>&nbsp;&nbsp;&nbsp;뿌리는 많으며, 죽은 뿌리는 토양중에 많은 유기물의 공급원이 되고 토양을 신속히 비옥하게 한다.<br/>&nbsp;&nbsp;&nbsp;스위트클로버의 종자는 견고한 종피를 갖고 있어 파종전에 종피에 상처를 주는 것이 좋다. 그러므로, 종자회사에서 종자를 판매할때는 종피를 마모시킨 것을 판매한다. 때로는 종자가 수년동안 토양중에 발아를 하지 않은 상태로 있는 것도 있다.<br/>&nbsp;&nbsp;&nbsp;그러므로 토양개량을 위하여 혹자는 스위트클로버를 파종하는 경우도 있다. 스위트클로버를 처음 파종할 때는 뿌리혹 세균을 접종하여야 한다.</p>
        <h2>재배</h2>
        <p className="pstyle">&nbsp;&nbsp;&nbsp;내한성이 강하며 특히 비옥하고 건조한 양토나 점질양토에서 수량이 많다. 또한 사양토나 중점질양토와 같은 토양에서도 재배가 가능하다.<br/>&nbsp;&nbsp;&nbsp;비옥한 토양과 알칼리성 토양에서 생육이 가장 잘되며 특히 두과목초중 알카리성 토양에서 가장 잘 자라는 목초이고 홍수 지역이나 산선토양에는 좋지 않으나 가뭄에 잘 견디므로 건조지역에서 재배하기가 좋다.알팔파와 같이 산성토양에 민감하여 pH6.0이하에서는 생육이 불량하다. 또한 봄에 생육이 시작되기전 장마로 인한 수일 동안의 침수에도 견디나 생육이 왕성한 시기에는 장마에 그렇게 강하지는 못하다.<br/>&nbsp;&nbsp;&nbsp;알팔파와 같이 물속에 침수되거나 배수 불량한 토양에서 생육이 좋지 않다.<br/>&nbsp;&nbsp;&nbsp;스위트 클로버는 코마린(Coumarin)함량이 많아 다른 두과목초보다 다소 기호성이 떨어지나 근래에는 코마린(Coumarin)함량이 낮은 새로운 품종이 육종되고 있다. 성숙기에 다다르면 질이 다소 떨어지고 줄기가 목질화된다. 스위트 클로버 바구미는 때때로 종자에 피해를 주며, 때로는 유식물에 거세미나 메뚜기 같은 해충의 피해를 받을 때도 있다.</p>
        <h2>이용</h2>
        <ul>
          <li>건초
            <p>건초수량은 많으나, 수확하기가 어렵고 질이 좋은 건초를 조제하려면 개화기전에 예취하여야 한다. 개화기에 예취를 하면 줄기가 목질화 되며, 질이 낮은 건초가 되고 줄기를 말리기 위하여는 건조기간이 장기간이 필요하며 건초가 부서져서 좋지 않다. 스위트 클로버에는 정상성분의 하나인 코마린(Coumarin)이 있으며, 이것이 곰팡이에 의해서 유독성인 디큐마롤(Dicumarol)로 변하여 중독증을 일으켜며 &quot;클로버병&quot;이라 한다. 중독은 혈액 응고 장해에서 기인되는 급성출혈성빈혈, 혈액 응고시간의 지연 증상이 나타나게 되고 가축은 갑자기 폐사하는 경우도 있다. 그러므로 건초를 조제할 때에는 곰팡이가 피지 않도록 주의하여야하고 곰팡이가 핀 것은 먹이지 않도록 하여야 한다. 건초조제후 재생은 주로 줄기에서 새싹이 나온다. 그러므로 2번초를 위하여 최소한 30cm 이상 높이에서 예취를 하여야 한다.</p>
          </li>
          <li>방목 및 청예
            <p>스위트 클로버중 화이트 스위트 클로버는 특히 생산량이 많고, 초봄부터 생육을 시작하여 6∼7월에 수확이 용이하나, 여름철에 30cm 정도로 남겨놓고 예취를 하여야 재생이 빠르고 질도 양호하다. 이러한 예취높이는 녹색잎으로 하여금 충분한 탄소동화작용을 하게하고, 줄기 기부까지 태양빛이 비칠 수 있게 하여 재생을 위한 새싹의 활발하게 하기 위한 것이다. 재배시 줄기가 목질화되고 키가 너무 크지 않도록 관리를 해주어야하며 건초를 조제하기 위하여 예취한 후 재생되는 것을 가축에게 방목 이용할 수 있다.</p>
          </li>
        </ul>
      </div>
      <div style={{width: '40%', float: 'left'}}>
        <table>
          <tr>
            <td><img src={pic237} className="nonborder2" alt=""/></td>
          </tr>
          <tr>
            <td><p style={{textAlign: 'center'}}>흰꽃스위트 클로버</p></td>
          </tr>
          <tr>
            <td><img src={pic238} className="nonborder2" alt=""/></td>
          </tr>
          <tr>
            <td><p style={{textAlign: 'center'}}>노란꽃스위트 클로버</p></td>
          </tr>
          <tr>
            <td><img src={pic239} className="nonborder2" alt=""/></td>
          </tr>
          <tr>
            <td><p style={{textAlign: 'center'}}>스위트 클로버의 잎의 모양 </p></td>
          </tr>
        </table>
      </div>
    </div>
  );
}

export default Do192;