import React, { useState , useEffect } from 'react';

// api
import { postState } from 'utils/api';
import { openCrownix3 , openCrownix } from 'utils/openCrownix';

// 이미지
import PrintPDF from 'assets/svgs/common/print_PDF.svg';
import Logo from 'assets/images/common/header_logo_pc.png';

// 컴포넌트
import ErrorMessage from 'components/errorMessage/errorMessage';
import MonthCode from 'components/dcitest/monthCode/MonthCode';

// scss
import './NewAnal01.scss';

function NewAnla01() {

  // 에러처리
  const [isModalOpen, setIsModalOpen] = useState(false); // 모달 추가
  const [errorMessage, setErrorMessage] = useState(''); 

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => {
    setIsModalOpen(false);
    setErrorMessage(''); // 모달이 닫힐 때 에러메세지 초기화
  };

  // 데이터 불러오기
  const baseDir='/dcitest/dciexpert/newanal01/';
  const [ mainData , setMainData ] = useState();
  const [ props , setProps] = useState({
    id:`${localStorage.getItem('cong')}${localStorage.getItem('farm')}`,
    passwd:localStorage.getItem('passwd'),
    date:localStorage.getItem('month')
  });

  useEffect(() => {

    postState(baseDir,props)
    .then(getdata => {      
      if (getdata !== undefined) {
        setMainData(getdata);
        // console.log(getdata);
      }
    })
    .catch(error => {       
      setErrorMessage(error.message);
      openModal();
    });
    
  }, [props]);
  
  // 페이지 네이션
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20;

  // Calculate the index range for the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = mainData && mainData.plot_data.slice(indexOfFirstItem, indexOfLastItem);
  const maxPage = Math.ceil(mainData && mainData.plot_data.length / itemsPerPage);

  return (
    <div className='pdfMainArea'>
      <ErrorMessage isOpen={isModalOpen} content={errorMessage} closeModal={closeModal}/>
      <div className='pdfMainHeader'>
        <button 
          type='button'
          onClick={()=> openCrownix('New_ANAL01.mrd')}
        >
          <img src={PrintPDF} alt='PDF로 인쇄하기' />
          인쇄하기
        </button>
        {
          props && 
          <MonthCode 
            props={props}
            setProps={setProps}
            setErrorMessage={setErrorMessage}
            openModal={openModal}
          />
        }
        <div className='pdfPagenation'>
          <p>{currentPage} / {maxPage}</p>
          <button 
            type='button' 
            onClick={() => { 
              if (currentPage !== 1 ) setCurrentPage(currentPage - 1);
            }}
            >이전</button>
          <button 
            type='button' 
            onClick={() => {
              if (currentPage !== maxPage) setCurrentPage(currentPage + 1);
            }}
            >다음</button>
        </div>
      </div>
      <div className='pdfArea'>
        <div className='newAnal01Pdf'>
          <h1>{props.date.substring(0,4)}년 {props.date.slice(-2)}월 종합 기술분석표</h1>           
          <ul className='newAnal01Info'>           
            <li>
              목장명 : {mainData && mainData.farm_property[0].fname}
            </li>   
            <li>
              축주명 : {mainData && mainData.farm_property[0].fdname}
            </li>
            <li>
              주소 : {mainData && mainData.farm_property[0].fadd}
            </li>          
          </ul>
          <p className="exp">※이 자료는 젖소개량사업소에서 수행하고 있는 유우군 검정사업에 의해 산출된 결과로 당부의 검정전산기록에 의해 확인된 결과입니다.</p>

          <table className='pdfTable'>
            <thead>
              <tr>
                <th rowSpan = "2">관리번호</th>
                <th rowSpan = "2">산차</th>
                <th rowSpan = "2">착유일수</th>
                <th rowSpan = "2">305일 유량</th>
                <th rowSpan = "2">최고유량</th>
                <th rowSpan = "2">최고유량도달일</th>
                <th rowSpan = "2">최소유량</th>
                <th rowSpan = "2">비유지속성</th>
                <th colSpan = "3">체세포</th>
                <th colSpan = "3">비유70이하평균</th>
                <th rowSpan = "2">분만간격</th>
                <th rowSpan = "2">건유일수</th>                             
                <th rowSpan = "2">수정횟수</th>
                <th rowSpan = "2">공태일수</th>
                <th rowSpan = "2">초종부일수</th>
                <th rowSpan = "2">정액코드</th>                
              </tr>

              <tr>
                <th>평균(천)</th>
                <th>10만이하(회)</th>
                <th>50만이상(회)</th>
                <th>MUN</th>
                <th>유지(%)</th>
                <th>단백(%)</th>
              </tr>
            </thead>
            <tbody>
              {
                currentItems &&
                currentItems.sort((a, b) => a.sname - b.sname).sort((a, b) => b.milk305 - a.milk305).map((item, index) => (
                  <tr key={index}>
                    <td>{item.sname}</td>
                    <td>
                      <button
                        type='button'
                        onClick={() => openCrownix3('New_sang_jcode.mrd' , item.jcode)}
                      >
                        {item.pari}
                      </button>
                    </td>
                    <td>{item.cumday}</td>
                    <td className='numberData'>{item.milk305 && item.milk305.toLocaleString()}</td>
                    <td className='numberData'>{item.maxmilk}</td>
                    <td className='numberData'>{item.maxcum}</td>
                    <td className='numberData'>{item.minmilk}</td>
                    <td className='numberData'>{item.pper}</td>
                    <td className='numberData'>{item.avgscc}</td>
                    <td className='numberData'>{item.scc10}</td>
                    <td className='numberData'>{item.scc50}</td>
                    <td className='numberData'>{item.avgmun}</td>
                    <td className='numberData'>{item.avgfatp}</td>
                    <td className='numberData'>{item.avgprotp}</td>
                    <td className='numberData'>{item.tintday}</td>
                    <td className='numberData'>{item.gundno}</td>
                    <td className='numberData'>{item.linsno}</td>
                    <td className='numberData'>{item.gongdno}</td>
                    <td className='numberData'>{item.chojongday}</td>
                    <td>{item.lsemencode}</td>
                  </tr>
                ))
              }
            </tbody>
          </table>
          <img src={Logo} alt='농협경제지주 젖소개량사업소 로고' className='pdfLogo'/>
        </div>
      </div>
    </div>    
  );
}

export default NewAnla01;
