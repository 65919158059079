import React from 'react';

import pic389 from 'assets/images/sub/_images/pic389.gif';
import pic390 from 'assets/images/sub/_images/pic390.gif';
import pic391 from 'assets/images/sub/_images/pic391.gif';

function Do209() {

  return (
    <div className="techinfoview">
      <h1>대기장</h1>
      <p className="pstyle">&nbsp;&nbsp;&nbsp;대기장은 착유전 소를 수용하는 장소로서 착유장의 일부이며 우사와 연결되어 있거나 매우 가까운 거리에 위치한다. 대기장의 크기는 착유실의 규모를 고려하여 결정해야 하며 1두를 위한 대기장의 면적은 0.4평/15ft²이다. 대기장에서 소가 1시간 이상 머물지 않도록 하고 그룹을 바꿔가며 계속해서 착유할 경우에는 중복되게 머무는 것을 감안하여 대기장의 면적을 25% 증가시켜야 한다. <br/>&nbsp;&nbsp;&nbsp;대기장과 착유실 사이에는 벽대신에 오버헤드 도어(overhead door)를 설치하여, 소가 대기장에서 다른 소들이 착유되는 것을 볼 수 있게 함으로서, 소의 이동을 돕는다. 또한, 어버헤드 도어의 사용은 착유자가 착유신에서 대기장으로 이동할 때 경사로의 이용을 용이하게 한다. 만약 사료급여통로나 휴식통로를 대기장으로 사용하는 경우는 벽을 남겨두는 것이 좋은데, 착유장과 직접 통하면, 특히 겨울철에 온도가 너무 낮아 좋지 않다. 그리고 소는 발산하는 열이 있기 때문에, 몹시 추운 겨울철 외에는 대기장에 단열처리를 할 필요가 없다. 여름철에는 벽을 열어 환?이 잘 되게 하여 소의 열기를 식히도록 하고 겨울철에는 벽을 천막등으로 덮어 샛바람을 막아준다.</p>
      <img src={pic389} className="nonborder" width="30%" alt=""/>
      <p>그림 60. 착유시설내 경사로</p>
      <img src={pic390} className="nonborder" width="30%" alt=""/>
      <p>그림 61. 경사로 단면도</p>
      <img src={pic391} className="nonborder" width="30%" alt=""/>
      <p>그림 62. 착유자 피트의 단면도</p>
    </div>
  );
}

export default Do209;