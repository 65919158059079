import React, { useState , useRef, useEffect } from 'react';
import { getState } from 'utils/api';

// 컴포넌트
import ErrorMessage from 'components/errorMessage/errorMessage';
import SubTitle from 'components/subTitle/SubTitle';


// scss
import './superiorCowSearch.scss';

  
function SuperiorCowSearch() {

  // 에러처리
  const [isModalOpen, setIsModalOpen] = useState(false); // 모달 추가
  const [errorMessage, setErrorMessage] = useState(''); 

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => {
    setIsModalOpen(false);
    setErrorMessage(''); // 모달이 닫힐 때 에러메세지 초기화
  };

  // 데이터 불러오기
  // eslint-disable-next-line no-restricted-globals 
  const baseDir=`${location.pathname.toLowerCase()}/`.replace('web','');  
  const [ mainData , setMainData ] = useState();
  const [ data , setData ] = useState();
  const [ props , setProps] = useState({  
  });

  useEffect(() => {

    getState(baseDir,props)
    .then(getdata => {      
      if (getdata !== undefined) {
        // console.log('#### get data :' , getdata.result);
        setMainData(getdata.result);
        setData(getdata.result);
      }
    })
    .catch(error => {           
      setErrorMessage(error.message);
      openModal();
    });
    
  }, [props]);


  // 받아온 데이터
  const tableRef = useRef(null);
  
  const searchCodeRef = useRef(null); // 정액코드
  const searchStartYearRef = useRef(null); // 공급년도 시작
  const searchEndYearRef = useRef(null); // 공급년도 끝
  const searchDegreeRef = useRef(null); // 차수
  const searchConditionRef = useRef(null); // 후대검정 진행여부

  const searchSuperCow = () => {
    let newTableData = mainData;

    const code = searchCodeRef.current.value; // 정액코드
    const startYear = searchStartYearRef.current.value; // 공급년도 시작
    const endYear = searchEndYearRef.current.value; // 공급년도 끝
    const degree = searchDegreeRef.current.value; // 차수
    const condition = searchConditionRef.current.value; // 후대검정 진행여부
    
    if (code) {
      const inputCode = `H-${code}`;
      newTableData = [...newTableData].filter((table) => table.JPRO_CODE.includes(inputCode));
    }

    if (startYear && endYear) {
      newTableData = [...newTableData].filter((table) => {
        const start = new Date(`${startYear}-01`);
        const end = new  Date(`${endYear}-01`);
        const month = new Date(`${table.BAEBUMONTH}-01`);
        
        return month >= start && month <= end;
      });
    }

    if (degree) {
      newTableData = [...newTableData].filter((table) => table.JPRO_SELECTNO === Number(degree));
    }

    if (condition !== '선택') {
      newTableData = [...newTableData].filter((table) => table.condition === condition);
    }

    setData(newTableData);

    window.scrollTo({
      top: tableRef.current.offsetTop - 100,
      behavior: 'smooth'
    });
  };

 

  return (
    <div className="superiorCowSearch subCon pullContent">    
      <ErrorMessage isOpen={isModalOpen} content={errorMessage} closeModal={closeModal}/>
      <div className='superiorCowSearchInner'>
        <SubTitle />
        <ul className='superiorCowSearchForm'>
          <li>
            <label htmlFor="searchCode" className='searchCode'>정액코드</label>
            <input 
              ref={searchCodeRef} 
              id="searchCode" 
              placeholder="'208HO'제외 뒤 세자리만 입력" 
              type="text" 
              maxLength='3'
            />
          </li>
          <li>
            <label htmlFor="searchYearS" className='searchYears'>정액공급년도</label>
            <div className='inputs'>
              <input 
                ref={searchStartYearRef} 
                id="searchYearS" 
                placeholder='202308' 
                type="text" 
                maxLength='6'
              />
              <span>~</span>
              <input 
                ref={searchEndYearRef} 
                placeholder='202310' 
                type="text" 
                maxLength='6'
              />
            </div>
          </li>
          <li>
            <label htmlFor="searchDegree" className='searchDegree'>차수</label>
            <input 
              ref={searchDegreeRef} 
              placeholder='숫자로 입력' 
              id="searchDegree" 
              type="text" 
            />
          </li>
          <li>
            <label htmlFor='searchCondition' className='searchCondition'>후대검정진행여부</label>
            <select ref={searchConditionRef} id="searchCondition" name="searchCondition" defaultValue='선택'>
              <option value="선택">선택</option>
              <option value="진행중">진행중</option>
              <option value="진행완료">진행완료</option>
            </select>
          </li>
        </ul>
        <div className='superiorCowBtn'>
          <button onClick={()=> searchSuperCow()} type='button'>
            <span>조회</span>
          </button>
        </div>
      </div>
      <div ref={tableRef} className='tablebox superiorCowTable'>
        <table>
          <thead>
            <tr>
              <th rowSpan="2">정액코드</th>
              <th rowSpan="2">명칭</th>
              <th>혈통&#40;부&#41;</th>
              <th>생년월일</th>
              <th>차수</th>
            </tr>
            <tr>
              <th>혈통&#40;모&#41;</th>
              <th>정액공급월</th>
              <th>진행유무</th>
            </tr>
          </thead>
          <tbody>
            {
              data && data.length === 0 
              ? 
              <tr>
                <td colSpan='5'>일치하는 검색 결과가 없습니다.</td>
              </tr>
              :
              data &&
              data.map((table) => (
                <React.Fragment key={table.JPRO_CODE}>
                  <tr>
                    <td rowSpan="2">{table.JPRO_CODE}<br></br>
                    ({table.JPRO_REGNO})</td>
                    <td rowSpan="2">{table.JPRO_KNAME}</td>
                    <td>{table.JPRO_FNAME}</td>
                    <td>{table.JPRO_BIRTHDAY}</td>
                    <td>{table.JPRO_SELECTNO}</td>
                  </tr>
                  <tr>
                    <td>{table.JPRO_MNAME}</td>
                    <td>{table.BAEBUMONTH}</td>
                    <td>{table.condition}</td>
                  </tr>
                </React.Fragment>
              ))
            }
          </tbody>
        </table>
      </div>  
    </div>
  );
}

export default SuperiorCowSearch;
