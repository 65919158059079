import React from 'react';

function Fo50() {

  return (
    <div className="techinfo_view">
				<p className="viewTitle">폐기종</p>
				<dl>
					<dt>발병대상</dt>
					<dd>소</dd>
				</dl>
				
				<dl>
					<dt>원인</dt>
					<dd>목초의 성장이 빠르고 풍부한 지역에 방목하였을 때 섭취한 트립토판등의 아미노산 양 때문인것 같다. 건조 사료에서 싱싱한 목초로 바꾸어 급여하면 반추위내에서 clostridium균의 지나친 성장을 촉진시키는 기회를 줄 수 있다. 이 미생물은 트립토판을 3-methylindole로 전환시키는데 이러한 대사산물이 혈류로 지나치게 많이 흡수되면 폐기종을 유발할 수 있다. 폐기종은 또한 폐렴으로 인해 발생될 수도 있으며 혹은 폐기생 유충이나 진균흡입 또는 자극성 가스 흡입으로 인한 알러지 반응으로 발생되기도 한다. </dd>
				</dl>
				
				<dl>
					<dt>징후 및 증상</dt>
					<dd>호흡곤란이 일반적인 증상이다. 심한 경우 헐떡거리고 기침과 타액 분비량이 많아지며 움직이기 싫어한다. 그리고 쇄약해지며 급격하게 상태가 악화되어 발병 수시간내 사망하기도 한다. </dd>
				</dl>
				
				<dl>
					<dt>질병분포와 손실규모</dt>
					<dd>전 세계적으로 발생하는데 감염된 축군내에서 대개 20%이상이 폐기종에 걸리며 이중 10%정도는 죽는다.</dd>
				</dl>
				
				<dl>
					<dt>치료</dt>
					<dd>
						<p>특별한 처방은 없으나 다음의 치료방법을 권하고 있다.</p>
						<ul className="listStyle04">
							<li>목초가 풍부한 지역으로 부터 격리시켜 건초를 급여한다.</li>
							<li>호흡곤란을 완화시켜주기 위해 항히스타민제와 스테로이드 및 기타 제제를 투여한다.</li>
							<li>2차 감염을 막기 위해 항생제와 설폰아마이드를 사용한다.</li>
						</ul>  
					</dd>
				</dl>
				
				<dl>
					<dt>대책</dt>
					<dd>목초가 풍부한 지역에서 우사로 옮겨 건초를 급여하면 질병을 통제할 수 있다. </dd>
				</dl>
				
				<dl>
					<dt>예방</dt>
					<dd>
						<p>다음의 조치가 효과적이다. 즉 </p>
						<ul className="listStyle">
							<li>여름철 방목시키기 전에 어느 정도 목초가 건조되도록 방치해 둔다.</li>
							<li>목초가 마른 지역에서 풍부한 지역으로의 방목전환은 서서히 진행시키도록 한다.</li>
							<li>방목하고 있는 동안 지속적으로 건초나 고간류를 급여한다.</li>
						</ul>
					</dd>
				</dl>	
				
				<dl>
					<dt>참고</dt>
					<dd>폐기종은 다른 원인도 있기는 하지만 주로 영양적인 면에서 발생되는 질병이다</dd>
				</dl>
			</div>
  );
}

export default Fo50;
