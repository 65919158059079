import React from 'react';

import Pic201 from 'assets/images/sub/_images/pic201.gif';
import Pic202 from 'assets/images/sub/_images/pic202.gif';
import Pic203 from 'assets/images/sub/_images/pic203.gif';

function Do51() {

  return (
    <div className="techinfoview">
				<h1>여름철의 착유우 사양관리</h1>
				<h2>여름철 젖소의 적정 사양관리 </h2>
				<p className="pstyle">여름철의 고온과 다습은 젖소에게 흔히 가장 큰 스트레스 요인이며 젖소 스스로가 여기에 적응하기 위해서 활동을 줄이고 또 제1위에서 발생되는 발효열을 줄이기 위해서 사료 섭취량이 크게 감소하게 된다. 이와같이 식욕감퇴와 더불어 체온조절을 위해서 많은 양의 에너지를 소모하게 되므로 우유를 생산할 수 있는 영양소와 에너지가 결핍하게 되고 이로 인해 산유량이 크게 떨어지게 된다. </p>
				<ul>
					<li>여름철의 사료와 급여 방법 
						<ul>
							<li>여름철에는 최대한으로 고온 스트레스를 줄여 필요한 영양소의 섭취량을 증가시키고 동시에 제1위의 기능을 최적 상태로 유지하여 사료의 발효를 정상으로 유지하는 영양관리가 중요하다. 더운 여름철에 섭취량이 떨어지는 것은 생리적으로 피할 수 없는 현산임을 인식하고 여기에 맞추어 사양계힉을 수립한여야 하낟. 사료섭취량 감소로 인해서 함량을 높이고 동시에 섭취량 감소에 따른 다른 영양소의 함량도 증가시켜야 한다. <br/>예를 들면 635kg의 젖소가 1일 체유지를 위해 0.77kg의 조 단백질이 1일 34kg의 산유량을 유지하기 위해서 2.54kg의 조단백질이 필요하다면 1일 3.31kg 의 조단백질을 섭취하여야 한다. 이 젖소가 1일 20kg의 고형물을 섭취한다면 이 사료중에 16.6%(3.32/20)의 조단백질이 포함되어 있어야 한다. 그러나 이 젖소가 더위 때문에 18kg밖에 섭취할 수 없을 때는 젖소가 1일 필요로 하는 조단백질 3.31kg을 섭취하도록 하기위해서 사료중 조 단백질 함량이 18.4%가 되어야 한다. <br/>이와같이 사료섭취량에 따라 영양소 함량을 조절하지 않으면 우유 생산에 필요한 영양소가 결핍되어 산유량이 떨어지게 된다. <br/>여름철 사료급여 요령을 보면 다음과 같다. 
								<ul>
									<li><b>급여하는 사료의 선택</b> : 사료중 에너지 농도를 증가시켜 급여하는 사료의 kg당 이용가능 정미에너지를 증가시켜야 한다. 이는 젖소 사료에 있어서는 농후사료의 이용을 증가시키면 되나 제 1위의 정상발효를 촉진시키기 위해서 적당량의 조사료를 급여하여야 하며 급여하는 조사료의 품질이 우수한 것을 사용하여야 한다. </li>
									<li><b>사료의 배합비율 변경</b> : 조사료의 급여 비율을 감소시킴으로서 급여하는 사료의 전체 소화율을 높이고 동시에 발효에 의한 열량증가를 감소시킬 수 있다. </li>
									<li><b>급여하는 사료의 물리적 형태</b> : 사료의 물리적 형태를 변경시킴으로서 사료가치를 향상시킬 수 있다. 조사료의 경우도 입자를 줄임으로서 소화율은 크게 변하지 않으나 사료의 통과 속도를 향상시킨다. 양질 조사료의 입은 크게 변하지 않으나 시료의 통과 속도를 향상시킨다. 양질 조라료의 입자를 너무 곱게 분쇄하는 것을 권장한다. 또한 저질 조사료를 여름철 사료에 포함시킬 때에 입자도를 중리면 큰 도움이 된다. </li>
									<li><b>급여수준 및 급여량</b> :  급여수준 및 급여량을 변경하므로서 제 1위에서의사료의 발효 상태를 조절할 수 있다 </li>
									<li><b>급여회수 </b> : 대부분의 경우 젖소는 하루에 두 번 급식하고 있으나 이럴 경우 한꺼번에 발효열이 두 번 발생하며 정상 상태하에서는 별 이상이 없으나 고온 스트레스를 받고 있는 젖소에게는 커다란 무리를 주게 된다. 그러나 하루 24시간 동안 4-6번으로 나누어서 주거나 또는 계석해서 자유로이 먹고 싶을 때 먹도록하면 발효열 생산을 최소로 줄일 수 있다. </li>
									<li><b>급여시기 </b> : 한낮의 더운 때에 사료를 급여하는 대신에 이른 아침이나 저녁때 다소 시원한 시간에 사료를 급여하는 것이 도움이 된다. 여름철에 급여할 사료가 갖추어야 할 영양소 함량을 요약햐여 보면 표 37과 같다. </li>
								</ul>
								<p><b>&lt;표 37&gt; 평상시와 여름철 사료의 조건비교</b></p>
								<table className="tablestyle">
									<tr>
										<td colSpan="2">항목</td>
										<td>평상시 사료</td>
										<td>여름철 사료</td>
									</tr>
									<tr>
										<td colSpan="2">농후사료 급여비율</td>
										<td>35∼55%</td>
										<td>55∼75%</td>
									</tr>
									<tr>
										<td colSpan="2">소화율</td>
										<td>65∼68 %</td>
										<td>70∼75%</td>
									</tr>
									<tr>
										<td rowSpan="8">영양소함량</td>
										<td>조섬유</td>
										<td>18∼20%</td>
										<td></td>
									</tr>
									<tr>
										<td>조섬유(분리급여시)</td>
										<td>16∼20%</td>
										<td>13∼15%</td>
									</tr>
									<tr>
										<td>조단백</td>
										<td>12∼14%</td>
										<td>14∼16%</td>
									</tr>
									<tr>
										<td>Ca</td>
										<td>0.7 </td>
										<td>0.7 </td>
									</tr>
									<tr>
										<td>P</td>
										<td>0.5</td>
										<td>0.5</td>
									</tr>
									<tr>
										<td>Mg</td>
										<td>0.25</td>
										<td>0.25</td>
									</tr>
									<tr>
										<td>S</td>
										<td>0.2∼0.3</td>
										<td>0.2∼0.3</td>
									</tr>
									<tr>
										<td>소금(농후사료)</td>
										<td>0.5∼1.0</td>
										<td>1.0∼1.5</td>
									</tr>
								</table><br/>
								<p className="pstyle">즉 여름철에는 평상시보다 농후사료의 급여 비율을 높여서 가소화에너지를 높여주고 조섬유 함량을 줄여 줌으로서 식욕감퇴에 의한 섭취량의 감소를 보상하여 주어야 한다. 그리고 고온에 의한 음수량 증가와 증발 및 오줌량의 증가로 상당량의 소금이 유실되므로 급여하는 농후사료에 소금의 첨가량을 1.0∼1.5%까지 증가시켜주면 좋다. 사일리지 등의 수분이 많은 발효사료는 오래두면 변질되기 쉬우므로 1∼1.5시간내에 다 먹을 수 있는 분량으로 자주 주는 것이 좋다. </p>
							</li>
						</ul><br/>
					</li>
					<li>우사의 환기 
						<ul>
							<li>겨울철에는 우사 천정에 물이 응고 된다든가 벽에 얼음이 언다은가 혹은 높은 암모니아와 습도로 냄새도 나기 때문에 환기문제를 쉽게 인식할 수 있으나 여름절에는 이러한 사실들이 감지 되지 않기 때문에 환기 문제를 잘 인식하지 못하는 경우가 많다. 그러나 환기불량은 겨울철 보다도 여름철에 젖소에게 더 큰 장해를 입히고 이로 인해 젖소가 비유능력을 발휘 할 수 없게 되는 결과를 초래하게 되므로 여름철의 우사환기네 각별히 유의하여야 한다. <br/>사실 출입문과 창문만으로 여름철의 환기는 불충분하다. 창문 또는 출입문에 가까이 있는 젖소는 안락감을 느낄지 모르나 우사 안쪼에 있는 젖소는 환기불량으로 고쩷을 느끼게 된다. 환풍은 젖소의 생체중 450kg당 200cfm ( cubic feet per minute)로서 635kg의 젖소에게는 280cfm dl 필요하다. <br/>우사는 지연환기 우사가 가장 비용이 적게 소요되므로 설계상 여름철의 젖소의 안락을 위해서 잘 고려하여 건축하는 것이 무엇보다도 중요하다. 지붕밑에 단열재를 입힘으로서 복사열을 크게 줄일 수 있고 또 겨울철의 보온 역할도 하게 된다. 자연 화기 우사의 벽과 지붕에 화기구를 설치하는 것은 공기의 유통과열의 발산에 큰 도움이 된다. </li>
						</ul><br/>
					</li>
					<li>그늘막 설치 
						<ul>
							<li>젖소는 직접 햇빛을 쪼이거나 또는 주위의 복사열에 의해서 열을 흡수하게 되므로 젖소가 노는 운덩장 떠는 방목장에 그늘막을 설치하는 것은 여름철의 고온 스트레스를 줄이는데 커다란 도움이 된다. <br/>그늘은 복사열을 막는 가장 기본적인 방법이며 그늘발을 설치함으로서 직접햇빛을 받는 경우보다 복사열을 30% 가량 줄일 수 있다. 그늘막을 설치할 때 고려하여야 할 중요한 사항은 다음과 같다. 
								<ul>
									<li>그늘막의 위치와 방향</li>
									<li>높 이</li>
									<li>바닥 면적</li>
									<li>환 기</li>
									<li>지붕설계</li>
									<li>사료 및 급수 시설</li>
									<li>분뇨처리시설</li>
								</ul>
								<p>그늘막 긴쪽의 길이가 동서로 향하게 하면 여름철에 그늘의 크기가 크고 또 햇빛이 내부에도 비치어 충분히 바닥을 건조시킬 수 있다.(그림13) </p>
								<img src={Pic201} style={{width: '50%'}} alt=""/>
								<p style={{width: '50%' , textAlign: 'center'}}>그림 13. 그늘막</p><br/>
								<p className="pstyle">바닥 면적은 1마리당 최소 20∼25평방피트 그리고 높이는 10、∼13、로 세워야 한다. <br/>지붕재료는 알미늄 또는 흰도금을 한 금속판을 사영하고 그 바로 밑에1의 두께로 단열재를 설치하는 것이 젖소에게 열의 부담을 줄여준다. 알미늄을 사용하지 않어라도 지붕 표면을 흰생으로 칠하는 것이 좋으며 이것은 흰색이 햇빛을 반사해 버리기 때문에 열이 흡수되는 것을 방지하여 주기 때문이다. 그러나 알미늄이 흰색을 칠하는 것보다 훨씬 좋다. <br/>그늘막 설치의 최대의 잇점을 사리기 위해서는 그늘 내부에 사료 급여조와 급수조를 설치하여야 하낟. 그리고 분뇨처리 시설도 신중히 고려하여야 하고 일반적으로 바닥을 콘크리트로 하는 것이 청소하기에 편리하다. <br/>플로리다 대학교의 연구팀에 의하면 그늘을 설치함으로서 그늘을 설치하지 않았을 경우보다 건구 온도와 복사열 및 풍속을 다같이 종합하여 나타내는 Black Globe 온도와 젖소의 직장온도 및 호흡수가 그게 떨어지고 &lt;표 38&gt; 동시에 산유량도 그림 14에서와 같이 10%이상 향상됨을 보여주고 있다. </p><br/>
								<p><b>&lt;표 38&gt; 그늘의 설치와 Black Globe온도, 젖소의 직장온도 및 호흡수 </b></p>
								<table className="tablestyle">
									<tr>
										<td>항 목</td>
										<td>그 늘 치 기</td>
										<td>그늘이 없을 때</td>
									</tr>
									<tr>
										<td>Black Globe온도 (℃)</td>
										<td>30</td>
										<td>39</td>
									</tr>
									<tr>
										<td>직장온도(℃)</td>
										<td>38.8</td>
										<td>40</td>
									</tr>
									<tr>
										<td>호흡수(분당)</td>
										<td>77</td>
										<td>114</td>
									</tr>
								</table>
								<img src={Pic202} style={{width: '50%'}} alt=""/>
								<p style={{width: '50%', textAlign: 'center'}}>그림 14. 그늘의 설치가 1일 산유량에 미치는 영향</p><br/>
								<p className="pstyle">건유우의 경우도 고열에 의한 스트레스를 받으면 분만후에 산유량이 크게 떨어진다. 이것은 수태후에 태아와 그리고 어미소 자체에 미치는 고온의 영향 때문으로 믿는다. 여러 학자들의 연구결과에서도 임산후에 후르몬의 영향으로 유방의 발달 정도와 분만시기 및 분만후의 번식 능력이 영향을 받고 또고열 스트레스는 수태후에 호르몬의 생산에 영향을 미치는 것으로 인정되고 잇다.譫15는 플로리다 대학교에서 과거 23년 동안 1,749두의 분만 홀스타인과 저지젖소를가지고 분만 송아지의 체중과 산유량을 비교한 결과이다. <br/>여기서 보는 바와같이 송아지의 생시 체중이 무거우면 무거울수록 어미 젖소의 산유량이 높다. 이것은 송아지의 체중이 직접적으로 어미 젖소의 우유생산을 자극하기 때문이 아니고 이것이 수태의 기능과 어미 젖소의 산유 능력을 자극하기 때문인 것으로 믿는다. <br/>그리고 일반적으로 그늘을 제공한 젖소가 분만한 송아지의 체중은 그렇지 않은 젖소의 송아지보다 약 3.3kg 정도 더 무겁다. </p>
								<img src={Pic203} style={{width: '50%'}} alt=""/>
								<p style={{width: '50%', textAlign: 'center'}}>그림 15. 분만시 송아지의 체중과 산유량</p><br/>
								<p>그늘을 제공받은 젖소가 분만한 후에 생산하는 산유량은 그늘을 제공받지 않는 젖소가 분만한 후에 생산하는 산유량보다 훨씬 높고 체중을 교정한 산유량의 겅우도 470kg이나 더 높으므로 여름철에 그늘의 제공이 얼마나 중요한가를 알 수 있다. </p><br/>
								<p><b>&lt;표 39&gt; 그늘설치와 산유량 (kg) </b></p>
								<table className="tablestyle">
									<tr>
										<td>기간</td>
										<td>그늘</td>
										<td>그늘이 없을때</td>
										<td>차이</td>
									</tr>
									<tr>
										<td>처음 100일간</td>
										<td>2,666</td>
										<td>2,221</td>
										<td>448</td>
									</tr>
									<tr>
										<td>305일간</td>
										<td>6,740</td>
										<td>5,936</td>
										<td>804</td>
									</tr>
									<tr>
										<td>305일간(체중교정)</td>
										<td>6,505</td>
										<td>6,037</td>
										<td>468</td>
									</tr>
								</table>
							</li>
						</ul><br/>
					</li>
					<li>그늘막 제공과 번식 
						<ul>
							<li>젖소의 번식장애는 낙농가에게 커다란 경제적 손실을 가져온다. 그리고적기에 수태가 되지 않으면 번식주기가 길어지기 때문에 또한 손실이 막대하다. 번식장애의 원인은 많고 또 일정하지 않으나 이것은 질병 또는 사양관리의 부적당 때문이다. 덥고 습한 여름 기후는 어느 요소 못지 않게 번식 효율에 영향을 미치며 특히 수태율을 떨어뜨리는 결과를 가져온다. 체온이 39.4∼40.5℃로 올라가게 되면 수정된 난자일지라도 조기에 죽어버리기 때문에 임신을 유지할 수 없으므로 이러한 젖소들은 가능한 대로 다른 소드로가 분리해서 그늘이 있는 곳이나 또는 환기가 잘 되는 우사에서 사육하므로고온 다습의 영향을 경감시킬수 있다. <br/>덥고 다습한 여름철에는 발정 증세를 감지하기가 어렵고 또 짧으므로 각별히 유의하여야 한다. 대부분의 젖소는 오후6시에서 오전 6시 사이에 발정 징후를 나타내게 되므로 이 기간 동안에 집중적으로 관찰하여야 하고 인공수정도 하루 24시간 중에서 가장 시원한 시간에 실시하여야 한다. </li>
						</ul><br/>
					</li>
					<li>신선한 물의 공급 
						<ul>
							<li>신선한 물은 젖소로 하여금 안락감을 주고 더 나아가 사료효율을 증진하게 되므로 우사내에서 또는 운동장에서 언제든지 자유롭게 마실수 있도록 해 주어야 한다. 젖소가 필요로 하는 물의 양은 외기곤도, 사료의 종류, 산유량과 급수하는 물의온도에 따라 다르나 보통 고형물 섭취량 1kg에 대하여3-4kg 또는 우유 1kg생산당 4∼5kg의 물을 필요로 하며, 하루에 두 번 급수한 젖소바도 언제든지 자유로이 물을 마실수 있도록 한 젖소는 음수량이 18%증가하였고 그리고 산유량도가 3.5% 높았으며 또 여름기간 동안에 시원한 물을 공급받은 소는 1일 증체증량이 0.14kg 더 높았다는 연구 결과도 있다. </li>
						</ul>
					</li>
					<li>파리와 모기의 구제 
						<ul>
							<li>파리를 제대로 구제하지 못하면 5∼10%의 산유량 감소를 초래하게 되고 동시에 살충용 기구 구입 및 노동력에 의한 우유생산비 증가를 초래한다. <br/>우사 주위에 많은 파리떼가 존재하면 젖소와 관리자를 괴롭힘은 물론 위생적인 문제도 야기되므로 사용이 허용된 살충제로 효과적으로 파리와 모기를 제거하는 것이 중요하다. </li>
						</ul><br/>
					</li>
					<li>우유 변질 방지 
						<ul>
							<li>여름철의 고온다습은 착유우의 변질을 초래하게 되므로 착유 직후 우유를 2∼4℃로 낸각시킬 수 있는 시설을 갖추고 착유 직후 우사 및 착유기를 깨끗이 씻고 소독하여야 한다. </li>
						</ul><br/>
					</li>
					<li>고창증 및 일사병 방지 
						<ul>
							<li>고창증은 4계절 어느때나 발생하지만 특히 여름철에 발생빈도가 높으므로 고창증 예방에 유의하여야 한다. <br/>일사병은 뜨거운 햇빛에 오애 노출되면 젖소의 두뇌가 과열반응에 의해서 불안, 흥분 및 근육의 경련을 일의키게 되며 갑자기 폐사하거나 또는 서서히 호흡마비를 일으켜 죽는 경우가 있으므로 직사광선을 받지 않도록 차광 장치를 설치하여야 한다. </li>
						</ul>
					</li>
				</ul>
				<h2>여름철 사양관리 요약 </h2>
				<ul>
					<li>여름철 고온 다습에 의한 고온 스트레스로부터 최대한으로 젖소를 보호하고 정상산유능력과 번식을 유지하기 위하여 필요한 사양관리상으 주요점을 보면 아래와 같다. 
						<ul>
							<li>최대한으로 열을 받지 않도록 한다. </li>
							<li>인공 그늘막을 설치한다. </li>
							<li>더운 낮에 젖소로 하여금 먼거리를 걷지 않도록 한다. </li>
							<li>젖소를 하여금 그늘이 없는 곳이나 너무 비좁은 장소에 간제로 서 있도록 하지 않는다. </li>
							<li>환기는 잘되는 우사에 둔다. </li>
							<li>물은 깨끗하고 시원한 것을 공급하고 급수시설에 그늘을 제공한다. </li>
							<li>조사료의 양을 중리고 농후사료의 급여 비율을 증가시켜 에너지 섭취량을 늘인다. </li>
							<li>조섬유 함량이 낮은 양질의 조사료를 급여한다. </li>
							<li>파리나 기타 곤충이 젖소를 괴롭히니 않도록 한다. </li>
							<li>사료를 24시간 동안에 4∼6 번 나누어서 주거나 또는 시원한 아침과 저녁에 먹도록 한다. </li>
							<li>사일리지나 청초는 소량씩 자주 급여 한다. 특히 사일리지는 더운 여름철에 변질되기 쉬우니 유의한다. </li>
							<li>시원한 시간에 발정 여부를 집중적으로 관찰하고 인공수정은 아침 일찍 실시한다.</li>
						</ul>
					</li>
				</ul>
			</div>
  );
}

export default Do51;
