import React from 'react';
import { Link } from 'react-router-dom';
// scss
import './footer.scss';

// 이미지
import FooterLogo from 'assets/images/common/footer_logo.png';


function Footer() {
  return (
    <footer id='footer' className='footer'>
      <div className='footerInner'>
        <div className='footerLogo'>
          <img src={FooterLogo} alt='젖소개량사업소 로고'/>
        </div>
        <address> 
          <ul>
            <li>
              <p>&#40;10292&#41; 경기도 고양시 덕양구 서삼릉길 233-127</p>
            </li>
            <li>
              <p>대표전화 : <a href="tel:031-929-1080">031-929-1080</a></p>
            </li>
            <li>
              <p>팩스 : 031-929-1090</p>
            </li>
          </ul>
          <p>Copyrights &#169; 2023 PNC. All Rights Reserved.</p>
        </address>
        <ul className="corp">
          <li>
            <Link to='/Web/Guide/PersonalInfo'>개인정보처리방침</Link>
          </li>
          <li>
            <Link to='/Web/Guide/Email'>이메일무단수집거부</Link>
          </li>
        </ul>
      </div>
    </footer>
  );
}

export default Footer;
