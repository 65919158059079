import React from 'react';
import NotiAndEvents from 'containers/web/info/notiAndEvents/NotiAndEvents';
import { Helmet } from 'react-helmet-async';

function NotiAndEventsPage() {
  return (
    <>
      <Helmet>
        <title>알림&행사</title>
      </Helmet>
      <NotiAndEvents />
    </>
  );
}

export default NotiAndEventsPage;
