import React, { useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

// api
import { getState, postState } from 'utils/api';

// components
import Pagination from 'components/bo/Pagination';

function UserList() {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const grpIdSnn = searchParams.get('grp_id');

  const [searchState, setSearchState] = useState({
    search_type: 'aid',
    search_keyword: '',
    page_no: 1,
    grp_id: grpIdSnn,
  });

  const { data, isLoading, isError, error } = useQuery({
    queryKey: ['users', searchState, grpIdSnn],
    queryFn: () =>
      getState('/board/select_user_list', {
        ...searchState,
        grp_id: grpIdSnn,
      }),
  });

  const users = data?.result?.LIST || [];
  const totalCount = data?.result?.TOTAL_COUNT || 0;
  const totalPages = data?.result?.PAGE_COUNT || 0;

  const handleSearch = (e) => {
    e.preventDefault();
    setSearchState({
      ...searchState,
      page_no: 1,
    });
  };

  const handlePageChange = (pageNo) => {
    setSearchState({
      ...searchState,
      page_no: pageNo,
    });
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearch(e);
    }
  };

  const handleUserDelete = async () => {
    try {
      const response = await postState('/board/delete_group/', {
        grp_id: grpIdSnn,
      });

      if (response?.return_code === 'SUCCESS') {
        alert('그룹이 삭제되었습니다.');
        navigate('/bo/user/groupList');
      }
      if (response?.return_code === 'ERROR') {
        alert(response?.return_message);
      }
    } catch (err) {
      console.error('Error deleting group:', err);
    }
  };

  const handleGroupAction = (action) => {
    if (action === 'list') {
      navigate('/bo/user/groupList');
    }
    if (action === 'edit' && grpIdSnn) {
      navigate(`/bo/user/groupEdit?grp_id=${grpIdSnn}`);
    }
    if (action === 'delete' && grpIdSnn) {
      if (window.confirm(`${grpIdSnn} 그룹을 삭제하시겠습니까?`)) {
        handleUserDelete();
      }
    }
  };

  return (
    <div className="bo-page">
      <h2>사용자 관리 아이디 목록</h2>
      <div className="searchbox">
        <select
          value={searchState.search_type}
          onChange={(e) =>
            setSearchState({
              ...searchState,
              search_type: e.target.value,
            })
          }
        >
          <option value="aid">아이디</option>
          <option value="user_name">사용자명</option>
        </select>
        <input
          type="text"
          value={searchState.search_keyword}
          onChange={(e) =>
            setSearchState({
              ...searchState,
              search_keyword: e.target.value,
            })
          }
        />
        <button type="button" className="btn-search" onClick={handleSearch}>
          검색
        </button>
      </div>

      <div className="board-top">
        <p>
          총 자료수: <strong>{totalCount}</strong> 개, 페이지{' '}
          {searchState.page_no}/{totalPages}
          {grpIdSnn && (
            <span style={{ float: 'right' }}>사용자 그룹: {grpIdSnn}</span>
          )}
        </p>
      </div>

      <div className="table-container">
        <table>
          <colgroup>
            <col width="5%" />
            <col width="15%" />
            <col width="15%" />
            <col width="15%" />
            <col width="*" />
            <col width="15%" />
            <col width="10%" />
          </colgroup>
          <thead>
            <tr>
              <th>번호</th>
              <th>아이디</th>
              <th>그룹 아이디</th>
              <th>사용자명</th>
              <th>설명</th>
              <th>등록일</th>
              <th>상태</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td colSpan="7" className="loading-data">
                  데이터를 불러오는 중입니다...
                </td>
              </tr>
            ) : isError ? (
              <tr>
                <td colSpan="7" className="error-data">
                  에러가 발생했습니다: {error.message}
                </td>
              </tr>
            ) : users.length === 0 ? (
              <tr>
                <td colSpan="7" className="no-data">
                  게시글이 없습니다.
                </td>
              </tr>
            ) : (
              users.map((user) => (
                <tr key={user.AID}>
                  <td className="center">{user.ROW_NUM}</td>
                  <td className="center">
                    <Link to={`/bo/user/userView?aid=${user.AID}`}>
                      {user.AID}
                    </Link>
                  </td>
                  <td className="center">{user.GRP_ID}</td>
                  <td className="center">{user.USR_NAME}</td>
                  <td className="center">{user.USR_DESC}</td>
                  <td className="center">{user.USR_REG_DT}</td>
                  <td className="center">{user.USR_STAT}</td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>

      {/* 페이지네이션 */}
      <Pagination
        currentPage={searchState.page_no}
        totalPage={totalPages}
        onPageChange={handlePageChange}
      />

      <div className="button-group">
        <button
          type="button"
          className="btn-l"
          onClick={() => handleGroupAction('list')}
        >
          그룹 목록
        </button>
        {grpIdSnn && (
          <>
            <button
              type="button"
              className="btn-l"
              onClick={() => handleGroupAction('edit')}
            >
              그룹 수정
            </button>
            <button
              type="button"
              className="btn-l"
              onClick={() => handleGroupAction('delete')}
            >
              그룹 삭제
            </button>
            <Link to={`/bo/user/userEdit?grp_id=${grpIdSnn}`} className="btn-l">
              사용자 등록
            </Link>
          </>
        )}
      </div>
    </div>
  );
}

export default UserList;
