import React, { useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

// api
import { getState, postState } from 'utils/api';

// components
import Pagination from 'components/bo/Pagination';

function SlvCodeList() {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const mCode = searchParams.get('m_code');

  const [searchState, setSearchState] = useState({
    search_type: 's_code',
    search_keyword: '',
    page_no: 1,
    m_code: mCode,
  });

  const { data, isLoading, isError, error } = useQuery({
    queryKey: ['slvCodes', searchState],
    queryFn: () =>
      getState('/board/select_slave_code_list', {
        ...searchState,
      }),
  });

  const slvCodes = data?.result?.list || [];
  const totalCount = data?.result?.total_count || 0;
  const totalPages = data?.result?.page_count || 0;

  const handleSearch = (e) => {
    e.preventDefault();
    setSearchState({
      ...searchState,
      page_no: 1,
    });
  };

  const handlePageChange = (pageNo) => {
    setSearchState({
      ...searchState,
      page_no: pageNo,
    });
  };

  const handleMstCodeDelete = async () => {
    if (!mCode) return;

    try {
      if (window.confirm(`${mCode} 주코드를 삭제 하시겠습니까?`)) {
        const response = await postState('/board/delete_master_code/', {
          m_code: mCode,
        });

        if (response?.return_code === 'SUCCESS') {
          alert('주코드가 삭제되었습니다.');
          navigate('/bo/code/mstCodeList');
        }
        if (response?.return_code === 'ERROR') {
          alert(response?.return_message);
        }
      }
    } catch (err) {
      console.error('Error deleting master code:', err);
      alert('시스템 에러가 발생했습니다.');
    }
  };

  const handleGroupAction = (action) => {
    if (action === 'list') {
      navigate('/bo/code/mstCodeList');
    }
    if (action === 'edit' && mCode) {
      navigate(`/bo/code/mstCodeEdit?m_code=${mCode}`);
    }
    if (action === 'delete' && mCode) {
      handleMstCodeDelete();
    }
    if (action === 'add' && mCode) {
      navigate(`/bo/code/slvCodeEdit?m_code=${mCode}`);
    }
  };

  return (
    <div className="bo-page">
      <h2>부코드 목록</h2>
      <div className="searchbox">
        <select
          value={searchState.search_type}
          onChange={(e) =>
            setSearchState({
              ...searchState,
              search_type: e.target.value,
            })
          }
        >
          <option value="s_code">부 코드번호</option>
          <option value="s_name">부 코드이름</option>
        </select>
        <input
          type="text"
          value={searchState.search_keyword}
          onChange={(e) =>
            setSearchState({
              ...searchState,
              search_keyword: e.target.value,
            })
          }
        />
        <button type="button" className="btn-search" onClick={handleSearch}>
          검색
        </button>
      </div>

      <div className="board-top">
        <p>
          총 자료수: <strong>{totalCount}</strong> 개, 페이지{' '}
          {searchState.page_no}/{totalPages}
        </p>
        {mCode && (
          <p>
            주 코드번호: <strong>{mCode}</strong>
          </p>
        )}
      </div>

      <div className="table-container">
        <table>
          <colgroup>
            <col width="5%" />
            <col width="15%" />
            <col width="15%" />
            <col width="*" />
          </colgroup>
          <thead>
            <tr>
              <th>번호</th>
              <th>부 코드번호</th>
              <th>부 코드이름</th>
              <th>부 코드설명</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td colSpan="4" className="loading-data">
                  데이터를 불러오는 중입니다...
                </td>
              </tr>
            ) : isError ? (
              <tr>
                <td colSpan="4" className="error-data">
                  에러가 발생했습니다: {error.message}
                </td>
              </tr>
            ) : slvCodes.length === 0 ? (
              <tr>
                <td colSpan="4" className="no-data">
                  게시글이 없습니다.
                </td>
              </tr>
            ) : (
              slvCodes.map((code) => (
                <tr key={code.s_code}>
                  <td className="center">{code.row_num}</td>
                  <td className="center">{code.s_code}</td>
                  <td className="center">
                    <Link
                      to={`/bo/code/slvCodeView?m_code=${code.m_code}&s_code=${code.s_code}`}
                    >
                      {code.s_name}
                    </Link>
                  </td>
                  <td>{code.s_desc}</td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>

      <Pagination
        currentPage={searchState.page_no}
        totalPage={totalPages}
        onPageChange={handlePageChange}
      />

      <div className="button-group">
        <button
          type="button"
          className="btn-l"
          onClick={() => handleGroupAction('list')}
        >
          주코드 목록
        </button>
        {mCode && (
          <>
            <button
              type="button"
              className="btn-l"
              onClick={() => handleGroupAction('edit')}
            >
              주코드 수정
            </button>
            <button
              type="button"
              className="btn-l"
              onClick={() => handleGroupAction('delete')}
            >
              주코드 삭제
            </button>
            <button
              type="button"
              className="btn-l"
              onClick={() => handleGroupAction('add')}
            >
              부코드 등록
            </button>
          </>
        )}
      </div>
    </div>
  );
}

export default SlvCodeList;
