import React from 'react';

import Pic195 from 'assets/images/sub/_images/pic195.gif';

function Do45() {

  return (
    <div className="techinfoview">
				<h1>사료급여와 대사성질병 및 번식</h1>
				<p className="pstyle">&nbsp;&nbsp;&nbsp;젖소 특히 고능력우는 분만후에 대대적인 영양소대사 조정이 불가피하게 된다 이는 체유지나 태아의 발육을 위한 낮은 수준의 영양소 요구량에서 우유 생산과 체유지를 위한 많은 양의 영양소가 요구되는 시기로 돌입하기 때문이다 주로 이시기에 대사성 질병이 많이 발생한다 건유기에 대사성 질병이 많이 발생한다 건유기에는 영양소 섭취량이 요구량보보다 많아 체내에 에너지를 축적하게 되나 분만후 많은 양의 우유생산이 이루어질때는 사료를 통해 섭취하는 영양소량이 우유합성이나 체유지를 위해 필요한 영양소량보다 적어 체내에 있는 영양소를 이용하게 되고 이에 따라 체중감소가 이루어진다 실제로 고능력의 경우 이시기에 필요한 양의 영양소를 외부로부터 충분히 공급해준다는 것은 불가능한 일이다 그래서 비유말기 또는 건유기에 필요한 양보다 많은 양의 에너지를 공급 적당히 살이 오르게 하므로서 분만후에 에너지 음균형에 대비하도록 한다 분만당시 과비상태의 젖소는 또한 분만후에 식욕이 크게 떨어지거나 4위 전위증 발생빈도가 높다 유방염은 물론 관절 등의 발생율도 높다 분만시 과비된 젖소는 또한 난산이나 후산정체 등의 문제를 일으킨다 이러한 모든 문제들은 사양관리를 철저히 하므로서 그 발생빈도를 크게 줄이거나 또는 완전히 예방할 수 있다. <br/>&nbsp;&nbsp;&nbsp;분만후 젖소의 사료섭취량(건물기준), 우유생산량 및 체중과의 관계는 다음 그림 6에서 보는 바와 같다. 그리고 비유주기별 에너지균형문제에 따른 각종 스트레스의 빈도가 높은 시기를 표시하면 그림 7과 같다. </p>
				<h2>과비증(fat cow syndrome)</h2>
				<p>원인 이는 비유말기와 건유기에 과다한 에너지섭취로 인해 발생한다 농후사료를 급여하거나 오구수사이레지 또는 양질의 건초를 무제한 자유체식할 경우 에너지섭취량이 요구량보다 많아 과비현상을 초래하게 된다 그리고 분만전 2주내외에 실시하는 농후사료 돋아먹이기(lead feedinf)를너무 일찍 시작하거나 급여량을 체중의 1% 수준이상으로 할 경우에도 과비현상이 나타난다. </p>
				<img src={Pic195} style={{width: '50%'}} alt="" />
				<p style={{width: '50%', textAlign: 'center'}}>그림 6. 비유기간중의 우유생산량, 건물섭취량 및 체중변화 </p><br/>
				<ul>
					<li><b>증상</b> : 과비된 젖소는 몸 각부위에 많은 양의 지방이 축적되어 있다 특히 간 콩팥 골반부위등에 지방에 지방이 많이 축적되게 된다 이러한 과비 문제는 세균감영이나 대사성질환을 동반하衁는 경우가 많다 지나치게 살찐 소는 분만전부터 식욕이 감소하기도 하고 특히 분만후 식욕을 회복하는까지는 기간이 오래 걸린다 그리고 분만후의 발정이 늦어지며 급격한 체중감소로부터오는 스트레스로 말미암아 임신이 늦어진다. <br/></li>
					<li><b>치료</b> : 대사성질환이나 세균감염에 의한 질병에 대한 치료반응이 매우 약하다 예를들어 유열이 발생한 소가 지나치게 살이 쪄있는 상태일 경우 칼슘제 외에 포도당을 함께써야 하고 염화콜린를 하루에50g 경구 투여 하며 메치오닌을 투입하지 않으면 안되다 그리고 착유사료를 중단하고건초를 주는게 좋다.
						<table className="tablestyle17">
							<tr>
								<td colSpan="2" rowSpan="2"></td>
								<td colSpan="4">비 유 주 기</td>
							</tr>
							<tr>
								<td>Ⅲ</td>
								<td>Ⅳ</td>
								<td>Ⅰ</td>
								<td>Ⅱ</td>
							</tr>
							<tr>
								<td rowSpan="2">에너지 수준</td>
								<td> 에너지 과다*</td>
								<td colSpan="2">&lt;―─―─―─―─―──―─―─&gt;</td>
								<td></td>
								<td></td>
							</tr>
							<tr>
								<td>에너지 부족**</td>
								<td></td>
								<td></td>
								<td colSpan="2">&lt;―─―─―─―─―──―─―─&gt;</td>
							</tr>
							<tr>
								<td rowSpan="7">대사성 질병</td>
								<td>식욕부진</td>
								<td></td>
								<td></td>
								<td>&lt;―──―─―─&gt;</td>
								<td></td>
							</tr>
							<tr>
								<td>유열</td>
								<td></td>
								<td></td>
								<td>&lt;―──―─&gt;</td>
								<td></td>
							</tr>
							<tr>
								<td>후산정체</td>
								<td></td>
								<td></td>
								<td>&lt;―──&gt;</td>
								<td></td>
							</tr>
							<tr>
								<td>관절염</td>
								<td></td>
								<td></td>
								<td>&lt;―──―&gt;</td>
								<td></td>
							</tr>
							<tr>
								<td>제4위전위</td>
								<td></td>
								<td></td>
								<td colSpan="2">&lt;―──―─―─―──――──―&gt;</td>
							</tr>
							<tr>
								<td>케토시스</td>
								<td></td>
								<td></td>
								<td>&lt;―──―─―─&gt;</td>
								<td></td>
							</tr>
							<tr>
								<td>번식(수태)</td>
								<td></td>
								<td></td>
								<td colSpan="2" style={{textAlign: 'right'}}>&lt;―──―─―─―─&gt;</td>
							</tr>
						</table>
						<p>그림7. 에너지 균형과 대사성 질병</p>
						<p>* 비유말기와 건유기에 에너지 급여량이 많으면 분만시에 지나치게 살이 찐 상태에 이른다. <br/>** 비유초기의 에너지 부족은 많은 양의 에너지가 우유합성에 필요하기 때문이다. 이러한 현상은 과비상태 젖소의 경우 분만후 사료를 먹지 않거나 사료급여 관행이 좋지 않을 때 더욱 심화된다. </p>
						<br/>
					</li>
					<li><b>예방</b> : 특히 비유말기 및 건유기간 동안에 요구량보다 지나치게 많은 에너지를 급여하지 않도록 하는 것이 최선의 예방책이다. 이를 위해 건유우는 착유우와 분리하여 사료섭취를 조절할 수 있도록 해야 한다. </li>
				</ul>
				<h2>유열(milk fever) </h2>
				<p className="pstyle">&nbsp;&nbsp;&nbsp;이는 젖소의 연령에 따라 발생빈도가 다른 것 특징이다 즉 초산우에 유열이 발생하는 경우는 거의 없으며 2산때 발생하는 경우도 드물고 3산 4산으로 갈수록 발생률이 높아진다 그리고 유열현상이 나타나는 시점은 분만전에 일어나는 경우가3% 분만시에 발생하는 경우가 6% 분만후 1시간에서부터 24시간내에 걸리는 비율이 75%로 가장 높으며 분만후 25~48시간 사이에 12% 그리고 그후에 발생하는 경우가 4%정도라고 한다. </p>
				<ul>
					<li><b>증상</b> : 초기 증상중의 하나는 식욕이 줄어 사료섭취량이 감소되는 것이다 일반적으로 보통의경우는 분만일의 사료섭취량은 그전 7일동안의 평균섭취량의 65∼70% 수준이나 유열에 걸리게 되면 섭취량이량이 0~25%로 감소되고 소화기관의 활동이 저하된다 그리고 침울 및 의기소침 근육경련 멍하니 서있는 전신마비현상이오며 귀가 차가와지고 비경주위가 마른다. </li>
					<li><b>예방을 위한 사료급여방법</b> : 에너지와 마찬가지로 건유기간중에 칼슘(Ca) 섭취량이 적은 경우보다 과다한 경우에 발생율이 높다. 일반적으로 건유기간중의 칼슘과 인의 요구량은 혼합건초 11kg(건물기준)정도, 그리고 옥수수사이레지 14kg (건물기준)을 급여하면 충족될 수있다. <br/>이제까지 알려진 바에 의하면 우유합성을 본격적으로 시작함에 따라 혈장내의 칼슘주준이 낮아지고, 분만시 혈액내 칼슘농도가 낮아지는 것으로 보고 있다. 즉 분만전 태아에 축적되는 칼슘은 일당 5∼35kg인데 비해 초유분비를 시작하면 우유합성을 위해 일당 15∼35kg의 칼슘이 필요하게 된다. 이렇게 증가된 칼슘은 혈장내의 것으로 충당되거나, 사료에서 섭취한 것을 이용하거나 또는 몸에 저장되어 있는 칼슘으로 보충해야 하는 것이다. <br/>건유기간중에 요구량보다 낮은 수준의 칼슘을 섭취하게 되면 뼈속에 저장되어 있는 칼슘을 이용하게 된다. 따라서 건유기간중에 칼슘급여량을 제한하면 체내에 저장된 칼슘의 이용이 이때부터 시작되기 때문에 분만후 우유합성에 필요한 많은 양의 칼슘섭취량을 제한하는 것이 효과적인 바 이기간중의 칼슘섭취량과 유열발생과의 관계를 보면 &lt;표 28&gt;과 같다. <br/>유열을 일으키는 원인은 다 알려지지 않았으나 적절한 사료급여 관행을 도입하면 유열의 발생을 예방하거나 줄일 수 있다. 
						<ul>
							<li>건유기간중에 칼슘섭취량을 제한한다. 적당한 양의 인을 섭취하도록 하면서 칼슘과 인의 적정비유에 관계없이 칼슘섭취량을 일당 80∼100g 이하로 제한 하는 것이다. </li>
							<li>만일 유열의 발생빈도가 높아 문제가 되는 경우는 분만 2주전부터 칼슘섭취량을 NRC사양표준의 2분의 1 수준으로 제한하면 상당한 효과가 있다. 이렇게 하면 뼈에 저장된 칼슘과 인의 대사를 촉진하게 된다. 물론 분만후에는 충분한 양의 칼슘을 공급하도록 해야 한다. </li>
							<li>비타민 A 와 D 그리고 단백질을 충분한 양 섭취토록 한다. </li>
							<li> 과비를 방지한다. 건유기간중의 에너지 섭취량을 조절하면 식욕을 증진시켜 분만후에 사료섭취량을 많게 한다. </li>
							<li>소가 적당히 운동을 할 수 있도록 해주고 안락한 분만실을 마련해 준다. </li>
							<li> 또한 유열에 걸렸던 소에 분만 2∼7일 사이에 1,000∼3,000만 단위의 비타민 D를 주입해 주는 것도 좋다. 그러나 믿을 만한 처방은 못되는 것으로 알려지고 있다. </li>
							<li>비타민 D 대사물질인 25-OHD3와 1, 25-(OH)2 D3가 유열예방에 효과가 있는 것으로 알려지고 있으나 아직 실용화 단계는 아니다. </li>
							<li>분만후 불완전착유 (incomplete milking)나 분만전 착유(prepartum milking)는 유열방지에 도움이 되지 않는다. </li>
						</ul>
					</li>
					<li><b>치료</b> : 앞에서 소개한 예방대책에 소홀함이 없다 하더라도 유열을 100% 방지한다는 것은 불가능한 일이다. 그러나 다행이도 적기에 치료하면 치유될 수 있다. 치료는 칼슘제 즉, 칼슘 구루코네이트 (calcium gluconate)등을 정맥 또는 피하 주사하면 된다. 그리고 재발하였을 경우는 칼슘, 인, 마그네슘 및 포도당 용액을 주입한다. 최근 영국에서 발표된 바에 의하면 처음 치료시는 칼슘용액만을 사용하므로서 재발을 줄일 수 있었다고 한다.
						<br/><br/><p><b>&lt;표28&gt; 칼슘 및 인의 섭취량과 유열발생 빈도와의 관계</b> </p>
						<table className="tablestyle">
							<tr>
								<td rowSpan="2">공시두수</td>
								<td colSpan="2">1일 섭취량(g)</td>
								<td rowSpan="2">비율</td>
								<td rowSpan="2">유열발생율(%)</td>
							</tr>
							<tr style={{background: '#273143', color: '#fff'}}>
								<td>(칼슘)</td>
								<td>(인)</td>
							</tr>
							<tr>
								<td>15</td>
								<td>206</td>
								<td>50</td>
								<td>4.12:1</td>
								<td>44</td>
							</tr>
							<tr>
								<td>59</td>
								<td>189</td>
								<td>51</td>
								<td>3.70.:1</td>
								<td>74</td>
							</tr>
							<tr>
								<td>10</td>
								<td>140</td>
								<td>28</td>
								<td>5.00:1</td>
								<td>40</td>
							</tr>
							<tr>
								<td>59</td>
								<td>137</td>
								<td>85</td>
								<td>1.60:1</td>
								<td>16</td>
							</tr>
							<tr>
								<td>18</td>
								<td>125</td>
								<td>55</td>
								<td>2.30:1</td>
								<td>5</td>
							</tr>
							<tr>
								<td>19</td>
								<td>120</td>
								<td>20</td>
								<td>6.00:1</td>
								<td>26</td>
							</tr>
							<tr>
								<td>15</td>
								<td>105</td>
								<td>111</td>
								<td>0.95:1</td>
								<td>40</td>
							</tr>
							<tr>
								<td>94</td>
								<td>119</td>
								<td>43</td>
								<td>2.30:1</td>
								<td>2</td>
							</tr>
							<tr>
								<td>82</td>
								<td>89</td>
								<td>53</td>
								<td>1.70:1 </td>
								<td>13</td>
							</tr>
							<tr>
								<td>94</td>
								<td>83</td>
								<td>48</td>
								<td>1.80:1</td>
								<td>19</td>
							</tr>
							<tr>
								<td>12</td>
								<td>73</td>
								<td>31</td>
								<td>2.30:1</td>
								<td>75</td>
							</tr>
							<tr>
								<td>88</td>
								<td>72</td>
								<td>48</td>
								<td>1.50:1</td>
								<td>8</td>
							</tr>
							<tr>
								<td>14</td>
								<td>36</td>
								<td>31</td>
								<td>1.16:1</td>
								<td>50</td>
							</tr>
							<tr>
								<td>3</td>
								<td>34</td>
								<td>28</td>
								<td>1.21:1</td>
								<td>33</td>
							</tr>
							<tr>
								<td>20</td>
								<td>34</td>
								<td>34</td>
								<td>1.00:1</td>
								<td>15</td>
							</tr>
							<tr>
								<td>19</td>
								<td>11</td>
								<td>74</td>
								<td>0.14:1</td>
								<td>10</td>
							</tr>
							<tr>
								<td>26</td>
								<td>11</td>
								<td>44</td>
								<td>0.25:1</td>
								<td>8</td>
							</tr>
							<tr>
								<td>10</td>
								<td>11</td>
								<td>24</td>
								<td>0.45:1</td>
								<td>0</td>
							</tr>
							<tr>
								<td>49</td>
								<td>9</td>
								<td>90</td>
								<td>0.10:1 </td>
								<td>6</td>
							</tr>
							<tr>
								<td>16</td>
								<td>6</td>
								<td>20</td>
								<td>0.30:1</td>
								<td>0</td>
							</tr>
							<tr>
								<td>5</td>
								<td>6</td>
								<td>24</td>
								<td>0.25:1</td>
								<td>1</td>
							</tr>
						</table>
					</li>
				</ul>
				<h2>케톤증(ketosis) </h2>
				<p className="pstyle">케톤증은 대사장애로 인해 체액내에 케톤체 (ketone bodies)의 농도가 높아지기 때문에 일어나는 현상이다. 주로 케톤증의 분만후 8주 사이에 발생하며 약 3주경에 발생율이 높다. 앞의 그림 6에서 보는 바와 같이 이 때가 사료로 섭취하는 에너지보다 우유로 나가는 에너지의 양이 훨씬 많은 시기이다. 일반적으로 케톤증중 3분의 1 이상이 다른 질환을 동반한다. 그리고 상당한 경우 케톤증의 후산정체, 관절염 및 창상성질환의 2차증상으로 나타나기도 한다. </p>
				<ul>
					<li><b>증상</b> : 뚜렷한 증상을 열거하기는 어려우나 대개 다음과 같은 증상을 보인다. 즉 ① 여위고 침울 또는 의기 소침, 때로는 흥분하기도 하며, ② 제1위의 수축작용이 불규칙하고, ③ 제1위 내용물이 단단히 뭉치며, ④ 마른 변을 배설하고, ⑤ 식욕이 감소되어 우선 농후사료를 먹지 않고 다음은 사이레지를 섭취하지 않으며 건초만 먹는다. ⑥ 그리고 체중이 준다. </li>
					<li><b>예방</b> : 케톤증은 과비 또는 분만후의 에너지 부족으로 인해 악화된다. 모든 젖소는 분만후 비유초기에 에너지 불균형 현상을 겪게 되나 분만시 지나치게 살이 찐 소의 경우는 식욕의 감퇴로 인한 사료섭취량 감소로 말미암아 에너지 불균형 현상이 악화된다. 따라서 더 많은 양의 체조직에 축적된 에너지를 동원하게 된다. 케톤증을 예방하기 위해서는 분만전에는 요구량을 충족할정도로 하는 것이다 사료섭취량을 늘이기 위해서는 분만후의 사료에 단백질 비타민 및 광물질 등이 적정수준 들이 있어야 한다.<br/>구체적인 예방대책을 열거하면 1.분만시 과비를 방지해야하며 2. 분만전후에 양질의 조사료를 급여함은 물론 비유초기에 전체사료중 조사료건물이 차지하는 비중이 40~50%가 되도록 하여야 하는 바 조사료는 길게 자른 것이 좋고 3. 분만전후에 급여하는 사료의 종류를 갑작스럽게 바꾸지말고 4. 수분함량이 많은 사이레지 급여를 삼가고 5. 기호성이 높은 사료를 급여하며 6. 분만후 6~8주까지는 매주 우유검사로 케톤증 유무를 조사하여 문제가 있는 소에게는 매일 125~250g 의 프로필렌 그라이콜을 급여한다 </li>
				</ul>
				<h2>영양상태와 번식효율 </h2>
				<p className="pstyle">&nbsp;&nbsp;&nbsp;에너지 섭취수준과 번식성적과의 관계는 1960년대 육용우 헤어포드를 대상으로한 연구에서 밝혀졌다 그후 1970년대에 홀론과 브랜톤등의 연구에 의해 젓소의 경우에도 비유초기의 에너지 균형과 번식성적간에 밀접한 관계가 있음을 밝혀냈다 즉 비유초기에체중감소를 보이는 젖의 경우는 수태율이 낮으며 1회 수태당 수정횟수가 많다는 것이다 즉 &lt;표29&gt;에 나타난 바와 같이 분만후 30일에서 90일사이에서 체중이 감소되는 우군의 경우는 첫 번째 수정에 의한 수태율이 44%에 불과한데 반해 이기간중에 체중이 증가한 소의 경우는 첫 번째 수정에 의한 수 태율이 64%인 것으로 보아 비유초기의 영양상태가 번식성적에 영향을 미치는 것을 알 수 있다. </p><br/>
				<p><b>&lt;표 29&gt; 비유초기 체중변화와 번식성적</b></p>
				<table className="tablestyle">
					<tr>
						<td>체중</td>
						<td>수정회수</td>
						<td>수태두수</td>
						<td>수태율(%) </td>
						<td>수태당 수정회수</td>
					</tr>
					<tr>
						<td>감소</td>
						<td>544</td>
						<td>234</td>
						<td>44</td>
						<td>2.32</td>
					</tr>
					<tr>
						<td>증가</td>
						<td>1368</td>
						<td>911</td>
						<td>64</td>
						<td>1.50</td>
					</tr>
				</table><br/>
				<p className="pstyle">다른 많은 연구에서도 영양수준과 번식울간의 상관관계를 확인한 바 있다. 그러나 건유기간에 필요이상의에너지를 급여할 필요는 없는 것으로 밝혀지고 있다. 따라서 분만후에 에너지, 단백질, 비타민 및 광물질 등이 고루 함유되어 있고 기호성이 높은 사료를 급여하여 비유추기에 에너지 균형이 이루어지도록 노력하면 번식성적이 좋아지게 되는 것이다. </p>
			</div>
  );
}

export default Do45;
