import React, { useState , useEffect } from 'react';
import {
  ResponsiveContainer,
  BarChart,
  ComposedChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
  Line
} from 'recharts';

// api
import { postState } from 'utils/api';
import { openCrownix } from 'utils/openCrownix';

// 이미지
import PrintPDF from 'assets/svgs/common/print_PDF.svg';

// scss
import './NewFarmgraph.scss';

function NewFarmgraph() {

  // 데이터 불러오기
  const baseDir='/dcitest/dcifarm/newfarmgraph/';
  const [ plotData1 , setPlotData1 ] = useState([]);
  const [ plotData2 , setPlotData2 ] = useState([]);

  const [ props , setProps] = useState({
    id:`${localStorage.getItem('cong')}${localStorage.getItem('farm')}`,
    passwd:localStorage.getItem('passwd'),
    date:localStorage.getItem('month')
  });

  useEffect(() => {

    postState(baseDir,props)
    .then(getdata => {      
      if (getdata !== undefined) {
      setPlotData1(getdata.plot_data);
      setPlotData2(getdata.plot_data2);
      }
    })
    .catch(error => {           
      setErrorMessage(error.message);
      openModal();
    });
    
  }, [props]);

  return (
    <div className='pdfMainArea'>
      <div className='pdfMainHeader'>
        <button 
          type='button'
          onClick={()=> openCrownix('New_farmgraph.mrd')}
        >
          <img src={PrintPDF} alt='PDF로 인쇄하기' />
          인쇄하기
        </button>
      </div>
      <div className='pdfArea'>
        <ul className='newFarmgraph'>
          <li>
            <h1>검정월별 검정두수 분포</h1>
            <div className='barChartArea' >
              <ResponsiveContainer width="100%" height={400}>
                <BarChart
                  width="90%"
                  height="85%"
                  data={plotData1}
                  margin={{              
                    top: 20,
                    right: 20,
                    bottom: 20,
                    left: 20,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis                     
                    dataKey="mon" />
                  <YAxis />
                  <Tooltip />
                  <Bar dataKey="tmdcno" fill="blue" />
                  <Bar dataKey="mcowno" fill="red" />
                  <Bar dataKey="fpnosum" fill="yellow" />
                </BarChart>
              </ResponsiveContainer>
              <ul className='fatLabel'>
                <li>경산우</li>
                <li>착유우</li>
                <li>초산우</li>
              </ul>
            </div>
          </li>
          <li>
            <h1>검정월별 유량, 체세포 분포</h1>
            <div className='barChartArea' >
              <ResponsiveContainer width="100%" height={400}>
                <ComposedChart
                  width="90%"
                  height="85%"
                  data={plotData2}
                  margin={{              
                    top: 20,
                    right: 20,
                    bottom: 20,
                    left: 20,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="tmon" />
                  <YAxis />
                  <Tooltip />
                  <Line type= "monotone" dataKey="avgscc" stroke="#8884d8"/>
                  <Line type= "monotone" dataKey="avgmilk" stroke="#ff7300"/>
                  <Bar dataKey="milk" barSize={20} fill="#413ea0" />
                </ComposedChart>
              </ResponsiveContainer>
              <ul className='fatLabel compose'>
                <li>체세포평균</li>
                <li>유량평균</li>
                <li>최근 1년간 유량</li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </div>   
  );
}

export default NewFarmgraph;
