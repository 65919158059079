import React from 'react';

import Arrow from 'assets/images/sub/_images/arrow.jpg';
import d2by3by1 from 'assets/images/sub/_images/2-3-1.jpg';
import d2by3by2 from 'assets/images/sub/_images/2-3-2.jpg';
import d2by5 from 'assets/images/sub/_images/2-5.jpg';
import d2by9by1 from 'assets/images/sub/_images/2-9-1.jpg';
import d2by9by2 from 'assets/images/sub/_images/2-9-2.jpg';
import d2by13 from 'assets/images/sub/_images/2-13.jpg';
import d2by15by1 from 'assets/images/sub/_images/2-15-1.jpg';
import d2by15by2 from 'assets/images/sub/_images/2-15-2.jpg';
import d2by17by1 from 'assets/images/sub/_images/2-17-1.jpg';
import d2by17by2 from 'assets/images/sub/_images/2-17-2.jpg';
import d2by17by3 from 'assets/images/sub/_images/2-17-3.jpg';

function Fo26() {

  return (
    <div className="techinfo_view">
				<p className="viewTitle">초유관리</p>
				<h2>초유는 생후15분이내에 ! 3일간 확실히!</h2>
				<ul className="listStyle04 mg15f">
					<li>생후 1회째 초유급여는 가능한 한 빨리: 분만시 일련(一連)의 작업중의 15분이내에!</li>
					<li>출산(出産)직후에 2ℓ, 다음은 12시간이내에 2ℓ
						<ul>
							<li>: 1회에 2ℓ 마실 수 없는 경우는 분할하여 12시간 이내에 4ℓ 급여한다.</li>
							<li>: 초유의 급여량은 12시간 이내에 체중의 8∼10%가 목표</li>
						</ul>
					</li>
					<li>2회째부터는 1회 2ℓ씩 2회 급여 또는 3회 급여</li>
					<li>모우가 초산의 경우, 경산우의 초유를 등량(等量) 혼입(混入)하는 것이 바람직하다.(경산우의 초유만으로도 좋음.)</li>
					<li>온도는 체온정도(실온 정도라도 좋음.)</li>
				</ul>
				<div className="imgBox mg60f"><img src={d2by3by1} alt=""/> <img src={d2by3by2} className="mg20l" alt="" title="강제포유기 초유는 빨리 확실히 먹여야 한다." /></div>
				
				<h2>초유는 왜 주는가?</h2>
				<ul className="listStyle04 mg15f">
					<li>자우는 면역물질을 가지지 않고 태어나기 때문에 잡균(雜菌)에 대하여 무방비</li>
					<li>초유부터 면역물질(면역 Globulin)을 섭취한다</li>
					<li>잡균보다 빨리 면역물질을 체내에 섭취하는 것이 중요(빨리 초유를 급여하는 이유)</li>
					<li>항체의 흡수능력은 생후 경과시간마다 저하한다
						<ul>
							<li>: 24시간후에는 대부분 거의 흡수하지 않는다.!! </li>
						</ul>
					</li>
				</ul>
				<div className="imgBox mg60f"><img src={d2by5} alt="" title="초유중 면역 Globulin의 소장(消長)." /></div>
				
				<h2>양질의 초유란?</h2>
				<ul className="listStyle04 mg60f">
					<li>충분한 건유기간(45일이상)을 경과한 경산우로부터의 초유
						<ul>
							<li>: 경산우는 초산우에 비해 각종 면역 글로블린(Globulin)을 함유하고 있다.!!</li>
						</ul>
					</li>
					<li>분만 직후의 착유 1∼2회의 것. 또는 12시간이내에 착유한 것</li>
					<li>유방염(乳房炎)이 걸려있지 않은 것</li>
					<li>발효초유보다는 냉동초유쪽이 좋다</li>
					<li>우유가 새고 있지 않은 것
						<ul>
							<li>: 우유가 새고 있으면 성분이 적어질 가능성이 있다</li>
						</ul>
					</li>
					<li>항생물질(抗生物質)을 함유하지 않은 것</li>
				</ul>
				
				<h2>발효초유의 만드는 방법</h2>
				<ul className="listStyle04 mg15f">
					<li>초유의 유효 이용법(보존이 용이)으로 함유된 면역 글로블린에 의해 하리의 억제효과도 있다</li>
					<li>기구는 대형 폴리에틸렌제 물통(18ℓ)과 폴리에틸렌 용기(90ℓ)등 뚜껑이 있어 교반(攪拌)이 가능한 것</li>
					<li>용기에 초유를 넣어 내실이 청결한 장소에 방치하여 매일 1∼2회는 반드시 교반한다. 발효 전부터 계속 이용할 수 있다
						<ul>
							<li>: 유방염유, 혈유(피가 섞인 우유), 항생물질 혼입의 초유는 불가!!</li>
						</ul>
					</li>
					<li>보존은 15∼26℃로 일광이 들지 않는 차고 어두운 곳. 2주간 정도 한냉조건이면 더욱더 길게 이용할 수 있다</li>
					<li>더울 때에는 방부제(防腐劑)(프로피온산(Propionic acid)을 초유의 1%, 또는 초산을 0.7%)가 유효(有效)</li>
				</ul>
				<div className="imgBox mg60f"><img src={d2by9by1} alt="" title="대형 폴리에틸렌 용기와 교반용구(목욕통) 발효한 초유" /> <img src={d2by9by2} className="mg20l" alt="" title="대형 폴리에틸렌 용기와 교반용구(목욕통) 발효한 초유" /></div>			
				
				<h2>발효초유의 주는 방법</h2>
				<ul className="listStyle04 mg60f">
					<li>분만후 1, 2, 3회째 까지의 착유에 의한 발효초유는 [ 온수 1 : 발효초유 2 ]의 비율로 희석(稀釋)해 준다</li>
					<li>초유 6회분만큼을 하나로 모아 발효시킨 것은 [ 온수 1 : 발효초유 3 ]의 비율로 희석해준다</li>
					<li>희석한 온수는 50℃이하가 좋다. 열탕에서는 단백질이 변성하기 때문에 좋지 않다</li>
					<li>급여 방법은 다른 액상사료와 동일 (포유병, 물통등)</li>
				</ul>
				
				<h2>냉동초유의 만드는 방법</h2>
				<ul className="listStyle04 mg15f">
					<li>초유의 저장방법으로서는 면역 Globulin확보라는 점으로 이상적인 방법이다</li>
					<li>경산우의 분만후 착유 1∼2회째의 양질초유를 사용한다</li>
					<li>이용하기 쉬운 양(1∼2ℓ)마다 폴리에틸렌 봉지로 밀봉(密封)하여 냉동 저장한다</li>
					<li>해동할 때는 항체변성을 피하기 위해서 50℃이상으로 가열하지 않는다. 자연해동이라도 좋다</li>
					<li>급여 온도는 체온 정도가 무난하다</li>
				</ul>
				<div className="imgBox mg60f"><img src={d2by13} alt=""/></div>
				
				<h2>포유기구는 어는 것이 좋은가?</h2>
				<ul className="listStyle04 mg15f">
					<li>물통, 유두 부착성 물통, 포유병의 어느 것이라도 자우의 발육, 질병에 차이는 없다</li>
					<li>청결을 유지하는 것이 가장 중요하고 기구는 어느것이라도 좋다</li>
				</ul>
				<div className="imgBox mg60f"><img src={d2by15by1} alt="" title="병으로 급여" /> <img src={d2by15by2} className="mg20l" alt="" title="물통으로 급여" /></div>
				
				<h2>포유기구는 청결히!</h2>
				<ul className="listStyle04 mg15f">
					<li>포유기구의 위생관리를 개선한 결과 하리가 1/10로 격감한 예가 있다</li>
					<li>세정방법은 착유기구와 동일</li>
				</ul>
				<div className="tableWrap">
					<table className="tablestyle">
						<thead>
							<tr>
								<th colSpan="6">매일</th>
								<th colSpan="7">(3~4일에 1번)</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>수세</td>
								<td><img src={d2by3by1} alt="" style={{width: '50%'}}/></td>
								<td>알카리 세제 0.5%</td>
								<td><img src={Arrow} alt="" style={{width: '50%'}}/></td>
								<td>수세</td>
								<td><img src={Arrow} alt="" style={{width: '50%'}}/></td>
								<td>산성 세제 1.0%</td>
								<td><img src={Arrow} alt="" style={{width: '50%'}}/></td>
								<td>수세</td>
								<td><img src={Arrow} alt="" style={{width: '50%'}}/></td>
								<td>알카리 세제 0.5%</td>
								<td><img src={Arrow} alt="" style={{width: '50%'}}/></td>
								<td>수세</td>
							</tr>
							<tr>
								<td colSpan="2">40℃전후</td>
								<td>70℃전후</td>
								<td></td>
								<td colSpan="2">40℃전후</td>
								<td>70℃전후</td>
								<td></td>
								<td colSpan="2">40℃전후</td>
								<td>70℃전후</td>
								<td></td>
								<td>40℃전후</td>
							</tr>
						</tbody>
					</table>
				</div>
				<ul className="listStyle04 mg15f">
					<li>보관은 가능한 한 빨리 건조하여 청결한 장소에(일광이 드는 곳이 좋다.)</li>
					<li>흡습성(吸濕性)이 있는 것(타울, 목제대등)의 위는 좋지 않다.(세균의 번식원이 된다.)</li>
				</ul>
				<div className="imgBox"><img src={d2by17by1} alt="" title="일광 소독과 청결한 보관" /> <img src={d2by17by2} className="mg20l" alt="" title="목제대와 타울은 세균의 번식원" /> <img src={d2by17by3} className="mg20l" alt="" title="목제대와 타울은 세균의 번식원" /></div>
			</div>
  );
}

export default Fo26;
