import React from 'react';

import pic755 from 'assets/images/sub/_images/pic755.jpg';
import pic756 from 'assets/images/sub/_images/pic756.jpg';
import pic757 from 'assets/images/sub/_images/pic757.jpg';

function Do162() {

  return (
    <div className="techinfoview">
      <h1>대형 파이로플라즈마병</h1>
      <div className="leftpart">
      <p className="pstyle">이 병은 소형 파이로 플라즈마 병과 똑같이 진드기가 매개 하는 원충성병으로 병원체는 바베시아 오바타(Babesia Ovata)이다. 이 바베시아 원충은 소 이외의 동물에는 거의 감염되지 않고 소형 파이로플라즈마병보다 발생직역이 좁다. 소형 파이로플라즈마나 아나플리즈마. 리켓챠와의 혼합감염을 흔히 볼 수 있다.  </p>
        <h2>증상</h2>
        <ul>
          <li>대형 파이로플라즈마 단독 감염에서는 증상이 심하지 않다. </li>
          <li>체온 상승(40℃이상), 빈혈과 황달이 나타나며 적갈색의 뇨를 배설한다. </li>
          <li>송아지는 성우에 비해 저항성이 강하다. </li>
          <li>이병으로 사망한 소의 해부소견은 전신 빈혈과 황달이 나타나 있고 비장(脾臟)이 현저히 부어 있다. </li>
        </ul>
        <h2>목장에서의 처치</h2>
        <ul>
          <li>우선 절대안정이 필요하며 가능한 한 현지에서 스트레스를 줄여야한다. </li>
          <li> 목초지는 오염될 가능성이 크므로 진드기 살충제를 정기적으로 살포하고 목초지를 개량한다. </li>
          <li>빈혈이 심하면 먼저 강심제를 주사하고 수혈요법 실시 및 수액과 영양제를 주사한다. </li>
          <li>수의사이 지시에 따라 원충(原蟲)을 죽일 수 있는 약제를 주사한다.</li>
        </ul>
      </div>
      <div className="rightpart">
        <img src={pic755} className="nonborder" alt=""/>
        <img src={pic756} className="nonborder" alt=""/>
        <img src={pic757} className="nonborder" alt=""/>
      </div>
    </div>
  );
}

export default Do162;