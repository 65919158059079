import React from 'react';

import Pic1 from 'assets/images/sub/_images/pic1.png';
import Pic2 from 'assets/images/sub/_images/pic2.png';
import Pic3 from 'assets/images/sub/_images/pic3.gif';
import Pic4 from 'assets/images/sub/_images/pic4.png';
import Pic5 from 'assets/images/sub/_images/pic5.png';
import Pic6 from 'assets/images/sub/_images/pic6.gif';
import Pic7 from 'assets/images/sub/_images/pic7.png';



function Do1() {

  return (
    <div className="techinfoview">
				<h1>젖소 개량사업 현황 및 농가 개량 방안</h1>
				<h2>낙농업 현황</h2>
				<ul>
					<li className="1depth"><span className="toctext">젖소사육동향 </span>
						<ul>
							<li className="2depth"><span className="toctext">총사육두수는 최근 3년간 비슷한 수준 유지(550천두)</span>
								<ul>
									<li>암소두수:(&apos;95)553천두→(&apos;97.12) 544천두</li>
								</ul>
							</li>
							<li className="3depth"><span className="toctext">전체 낙농가수는 감소 추세이나 호당 사육두수는 증가</span>
								<ul>
									<li>농가수:(&apos;95) 22천호→(&apos;97.12월) 17천호</li>
								</ul>
							</li>
						</ul>
					</li>
					<li className="1depth"><span className="toctext">젖소산지 가격동향</span>
						<ul>
							<li className="2depth"><span className="toctext">소값하락 추세에 한우 가격보다 더 큰 폭으로 가격하락</span>
								<ul>
									<li className="3depth">초임만삭 가격:(&apos;95) 3,057천원→(&apos;98.1) 1,660천원(젖소 45.7%, 한우 30.1% 하락)
										<ul>
											<li className="4depth">초유떼기 가격:(&apos;95.12) 879천원→(&apos;98.1) 160천원</li>
										</ul>
									</li>
								</ul>
							</li>
						</ul>
					</li>
					<li className="1depth"><span className="toctext">우유소비동향</span>
						<ul>
							<li className="2depth"><span className="toctext">소득 향상에 의한 우유 소비량 꾸준한 증가세</span>
								<ul>
									<li className="3depth">우유소비량 증가세(&apos;90-&apos;96):27.4%(1인당 11.7kg)
										<ul>
											<li className="4depth">1인당 우유 소비량:(&apos;90) 42.79kg→(&apos;96) 77.6%
												<ul>
													<li className="5depth">※ 국내유가공장수: 62개소(축협 18, 민간 44)</li>
												</ul>
											</li>
										</ul>
									</li>
								</ul>
							</li>
						</ul>	
					</li>
				</ul>
				<h2>2천년대 낙농업 발전방향</h2>
				<div>
					<table id="tectinfoview1">
						<tbody>
							<tr>
								<td colSpan="2" rowSpan="2">구분</td>
								<td colSpan="2">발전지표</td>
								<td rowSpan="2">발전전략</td>
							</tr>
							<tr>
								<td>현재(&apos;97)</td>
								<td>목표(2004)</td>
							</tr>
							<tr>
								<td>사육규모</td>
								<td>농가호수 <br/>사육두수 <br/>호당규모</td>
								<td>18천호 <br/>551천두 <br/>30두</td>
								<td>12 <br/>580 <br/>40</td>
								<td>。 전업농 육성 <br/>-규모화, 집단화 <br/>-투자 효율 제고</td>
							</tr>
							<tr>
								<td>생산성</td>
								<td>산유량 <br/>번식간격</td>
								<td>5,959kg/두(&apos;96) <br/>14.4개월</td>
								<td>7,300 <br/>13.5</td>
								<td>。 능력 및 사양개선 <br/>-생산비 절감</td>
							</tr>
							<tr>
								<td>수금</td>
								<td>생산량 <br/>1인소비량 <br/>(자급율)</td>
								<td>1,991천톤 <br/>54.4kg/년<br/>(81%)</td>
								<td>2,318 <br/>64.2 <br/>(75)</td>
								<td>。 고품질 우유생산 <br/>-소비확대 <br/>-수입품 관리강화</td>
							</tr>
							<tr>
								<td>제도</td>
								<td>수급, 가격 <br/>원유검사</td>
								<td>정부 <br/>민간</td>
								<td>민간 <br/>공영</td>
								<td>。 낙농제도 개선 <br/>-자율수급관리</td>
							</tr>
						</tbody>
					</table>
				</div>
				<h2>젖소 개량 사업 현황</h2>
				<ul>
					<li>주요 사업 연혁
						<ul>
							<li>
								<ul>
									<li>1962 가축인공수정사업 착수</li>
									<li>1966 혈통등록 개시</li>
									<li>1976 냉동정액 생산 공급</li>
									<li>1979 산유능력검정사업 시작</li>
									<li>1987 후대검정사업 착수</li>
									<li>1988 수정란 이식 수송아지 생산</li>
									<li>1995 한국형 보증종모우 선발</li>
								</ul>
							</li>
						</ul>
					</li>
					<li>
						사업체계 및 규모
						<ul>
							<li>정액 생산 공급
								<ul>
									<li>사업체계:생산(축협래량사업본부),공급(축협도지회)→시술(수정사,농가)
										<ul>
											<li>축협정액 공급체계<br/><img src={Pic1} alt="축협정액 공급체계" /></li>
										</ul>
									</li>
									<li>공급물량<br/>
										<table className="tablestyle">
											<tr>
												<td colSpan="2">구분</td>
												<td>&apos;90</td>
												<td>&apos;95</td>
												<td>&apos;97</td>
											</tr>
											<tr>
												<td rowSpan="3">젖소</td>
												<td>축협</td>
												<td>724천스트로</td>
												<td>705</td>
												<td>604</td>
											</tr>
											<tr>
												<td>수입</td>
												<td>97</td>
												<td>262</td>
												<td>220</td>
											</tr>
											<tr>
												<td>(계)</td>
												<td>(821)</td>
												<td>(967)</td>
												<td>(824)</td>
											</tr>
										</table>
										<p>※ 수입정액 점유비 : (&apos;90) 12%→(&apos;97)26.6%</p>
									</li>
								</ul>
							</li>
							<li>젖소산유능력검정 : 젖소개량기반 구축 및 농가 경영개선
								<ul>
									<li>사업규모
										<table className="tablestyle">
											<tr>
												<td>구분</td>
												<td>&apos;90</td>
												<td>&apos;95</td>
												<td>&apos;97</td>
											</tr>
											<tr>
												<td>검정농가수</td>
												<td>768농가</td>
												<td>995</td>
												<td>2,163</td>
											</tr>	
											<tr>
												<td>검정두수</td>
												<td>11,385두</td>
												<td>22,268</td>
												<td>53,450</td>
											</tr>
											<tr>
												<td>농가당 두수</td>
												<td>14.8두</td>
												<td>22.4</td>
												<td>24.7</td>
											</tr>
										</table>
										<p>※검정보급율 : 한국 17%, 미국 49%, 일본 44%, 덴마크 83%</p>
										<img src={Pic2} alt="검정보급율" />
									</li>	
									<li>검정유량 성적증가(&apos;90-&apos;96) : 12.0%
										<ul>
											<li>두당 우유생산량 : (&apos;90) 6,335kg→(&apos;96) ,098kg
												<table className="tablestyle">
													<tr>
														<td>구분</td>
														<td>&apos;90</td>
														<td>&apos;95</td>
														<td>&apos;97</td>
													</tr>
													<tr>
														<td>검정농가유량</td>
														<td>6,335kg</td>
														<td>6,953</td>
														<td>7,098</td>
													</tr>	
													<tr>
														<td>일반검정농가</td>
														<td>5,363</td>
														<td>5,836</td>
														<td>5,959</td>
													</tr>
													<tr>
														<td>차이</td>
														<td>972</td>
														<td>1,117</td>
														<td>1,139</td>
													</tr>
												</table>
												<p>※ 외국 유량수준 : 미국 7,432kg, 일본 6,981kg, 화란 6,769kg</p>
											</li>
										</ul>
									</li>
								</ul>
							</li>
							<li>후대검정 및 보증종모우 선발
								<ul>
									<li>사업규모 : 년 15두 검정→3두 종모우 선발(20%)</li>
									<li>사업체계<br/>
										<img src={Pic3} alt="사업체계" />
										<p>※ 후보종모우 : 국내우 생산능력 5% 이내 암소 계획교배, 외국산(미국, 캐나다) 도입</p>
									</li>
								</ul>
							</li>
							<li>혈통등록 및 외모심사
								<ul>
									<li>사업규모
										<ul>
											<table className="tablestyle">
												<tr>
													<td>구분</td>
													<td>&apos;90</td>
													<td>&apos;95</td>
													<td>&apos;96</td>
												</tr>
												<tr>
													<td>혈통등록</td>
													<td>10,655두</td>
													<td>22,737</td>
													<td>24,518</td>
												</tr>
												<tr>
													<td>선형심사</td>
													<td>5,798두</td>
													<td>7,163</td>
													<td>8,086</td>
												</tr>
											</table>
										</ul>
									</li>
									<li>사업체계<br/>
										<img src={Pic4} alt="사업체계" />
									</li>
								</ul>
							</li>
						</ul>
					</li>
				</ul>
				<h2>젖소개량사업 활성화 방안</h2>
				<ul>
					<li>정액공급
						<ul>
							<li>문제점
								<ul>
									<li>수입정액에 대한 무분별한 수요 및 공급체계</li>	
									<li>농가의 자발적인 정액선별 의지 부족</li>
									<li>원하는 정액을 쉽게 공급할 수 있는 체제 미흡</li>
								</ul>
							</li>
							<li>대책
								<ul>
									<li>농가에 대한 지속적인 개량관련 교육 및 농가노력</li>
									<li>다양한 정보 및 기술전달 체계 운영(인터넷망 등)</li>
									<li>정액공급 체계 개선</li>
								</ul>
							</li>
						</ul>
					</li>
					<li>산유능력 검정사업
						<ul>
							<li>문제점
								<ul>
									<li>경산우 대비 산유능력 검정비율의 저조(17%내외)<br/>※각국 검정 참여율(&apos;95년) : 미국 49.3%, 일본 44.1%, 영국 54.5%</li>
									<li>능력검정 사업에 대한 농가 인식 부족</li>
								</ul>
							</li>
							<li>대책
								<ul>
									<li>산유 능력검정 두수의 획기적 증대(2001년 170천두)</li>
									<li>산유능력검정 체계의 일원화 및 분석 자료 다양화</li>
								</ul>
							</li>
						</ul>
					</li>
					<li>후대검정사업
						<ul>
							<li>문제점
								<ul>
									<li>산유 능력검정 참여 두수의 부족으로 후대검정 규모 확대 제약</li>
									<li>능력검정 참여우 중 혈통 확인 가능우 저조</li>
									<li>후대검정 형질 다양화 및 차별화 필요</li>
								</ul>
							</li>
							<li>대책
								<ul>
									<li>산유 능력검정 두수의 증대</li>
									<li>혈통등록 확대 및 체계적 이용방안 모색</li>
									<li>유전능력 평가 형질의 한국형 모델 개발 등</li>
								</ul>
							</li>
						</ul>
					</li>
					<li>혈통등록사업
						<ul>
							<li>문제점
								<ul>
									<li>농가의 자발적 등록 참여 저조 및 활용 미흡</li>
									<li>정확한 개체 식별 방식 미비</li>
									<li>자료관리의 정확도 미흡</li>
								</ul>
							</li>
							<li>대책
								<ul>
									<li>혈통등록증활용방안 적극 강구(예 : 정액증명서 활용 병행 등)</li>
									<li>국가 개량전산망 연계 체계적 관리 등</li>
								</ul>
							</li>
						</ul>
					</li>
				</ul>
				<h2>젖소개량의 실제 및 낙농경영</h2>
				<ul>
					<li>낙농경영 목표
						<table id="tablestyle1">
							<tr>
								<td rowSpan="2">구분</td>
								<td rowSpan="2"></td>
								<td colSpan="2">발전지표</td>
								<td rowSpan="2">비고</td>
							</tr>
							<tr>
								<td>현재(&apos;97)</td>
								<td>목표(2004)</td>
							</tr>
							<tr>
								<td rowSpan="3">사육규모</td>
								<td>농가호수</td>
								<td>18천호</td>
								<td>12</td>
								<td rowSpan="3"></td>
							</tr>
							<tr>
								<td>사육두수</td>
								<td>551천두</td>
								<td>580</td>
							</tr>
							<tr>
								<td>호당규모</td>
								<td>30두</td>
								<td>40</td>
							</tr>
							<tr>
								<td rowSpan="2">생산성</td>
								<td>산유량</td>
								<td>5959kg/두</td>
								<td>7300</td>
								<td rowSpan="2">(&apos;96)</td>
							</tr>
							<tr>
								<td>번식간격</td>
								<td>14.4개월</td>
								<td>13.5</td>
							</tr>
						</table>
					</li>
					<li>목표 달성을 위한 개량사업적 수단
						<ul>
							<li>개량 목표의 확실한 선정
								<ul>
									<li>농가 실정에 맞는 개량방향 설정(조사료 여건, 노동력, 시설 등)</li>
									<li>개체별 개량에서 우군개량으로 전환</li>
									<li>개량목표 형질의 정확한 선정 및 접근</li>
								</ul>
							</li>
							<li>능력검정사업 참여 및 등록심사의 체계적 운용
								<ul>
									<li>능력검정(입회 또는 자가) 및 등록심사 성적 활용</li>
									<li>고능력우 차별화 및 기록 유지<br/>* 고능력우 경매 대회 등 활용</li>
								</ul>
							</li>
							<li>인공수정용 정액 사전선정 및 계획 교배
								<ul>
									<li>종모우 사전 선정 및 철저한 사전 교배 계획<br/>* 근친교배의 철저한 사전 차단</li>
									<li>정액사전 공급 및 풀링(Pooling)제 활용</li>
								</ul>
							</li>
							<li>교배 확인 및 개량도 평가
								<ul>
									<li>교배정액 확인 및 생산우에 대한 기록 관리</li>
								</ul>
							</li>
						</ul>
					</li>
					<li>젖소개량의 실례
						<ul>
							<li>능력검정 최고 능력우
								<ul>
									<li>산유량
										<ul>
											<li>&apos;96년 천안 샤론 목장 63호 16,186Kg/sus(305일, 2회, 성년형)</li>
											<li>&apos;97년 평택 야곱목장 138호 16,237Kg/sus(305일, 2회, 성년형)</li>
										</ul>
									</li>
									<li>유대수입 : 서울우유 설문목장 29호 7,277천원/년(&apos;96년)</li>
								</ul>
							</li>
							<li>&apos;95년 능력검정 최고목장
								<ul>
									<li>목장명 : 경기도 평택 야곱 목장(축주명 : 최옥연)</li>
									<li>능력검정 성적 : 20두 우군 평균 11,158Kg/년</li>
									<li>두당 년간 유대수입 : 4,954천원</li>
									<li>총 유대 수입 : 99,080천원</li>
								</ul>
							</li>
						</ul>
					</li>
					<li>농가의 젖소개량 실제
						<ul>
							<li>젖소 개량시 종모우의 중요성</li>
							<img src={Pic5} alt="종모우" />
							<li>계획교배 요령
								<ul>
									<li>계획교배의 순서
										<table id="tablestyle3">
											<tr>
												<td>축군에 대한 철저한 분석</td>
												<td>→</td>
												<td>축군의 개량 목표 설정</td>
												<td>←</td>
												<td>종모우에 대한 자료 수집</td>
											</tr>
											<tr>
												<td colSpan="5">↓</td>
											</tr>
											<tr>
												<td colSpan="5">
													[개량목표에 부합되는 암소별 종모우 선정]<br/>
													①근친교배를 피한다.<br/>
													②산유능력이 우수한 정액을 선정<br/>
													③불량한 체형 부위를 개선할 정액을 선정<br/>
													④정액의 가격을 고려
												</td>
											</tr>
										</table>
									</li>
									<li>축군자료 정리 예
										<table className="tablestyle">
											<tr>
												<td>명호</td>
												<td>산차</td>
												<td>305일 성년</td>
												<td>유지율</td>
												<td>아비</td>
												<td>외조부</td>
												<td>개량대상 체형</td>
											</tr>
											<tr>
												<td>1호</td>
												<td>5</td>
												<td>9500</td>
												<td>3.5</td>
												<td>H-177</td>
												<td>H-058</td>
												<td>유방깊이</td>
											</tr>
											<tr>
												<td>2호</td>
												<td>5</td>
												<td>7650</td>
												<td>3.7</td>
												<td>H-177</td>
												<td>H-076</td>
												<td>뒷다리 각도</td>
											</tr>
											<tr>
												<td>3호</td>
												<td>4</td>
												<td>6735</td>
												<td>3.6</td>
												<td>H-157</td>
												<td>.</td>
												<td>발굽 및 다리</td>
											</tr>
											<tr>
												<td>~</td>
												<td>~</td>
												<td>~</td>
												<td>~</td>
												<td>~</td>
												<td>~</td>
												<td>~</td>
											</tr>
											<tr>
												<td>9호</td>
												<td>1</td>
												<td>8760</td>
												<td>3.6</td>
												<td>H-186</td>
												<td>H-156</td>
												<td>체형전반</td>
											</tr>
											<tr>
												<td>10호</td>
												<td>1</td>
												<td>10235</td>
												<td>2.7</td>
												<td>H-186</td>
												<td>H-156</td>
												<td>엉덩이부위</td>
											</tr>
											<tr>
												<td>평균</td>
												<td>2.7</td>
												<td>7349</td>
												<td>3.3</td>
												<td></td>
												<td></td>
												<td></td>
											</tr>
										</table>
									</li>
									<li>개체별 개량 방향 설정<br/>[생산능력개량방향]<br/>
										<img src={Pic6} alt="개량방향" /><br/>
										주 : →군별 개량 지향 방향
										<ul>
											<li>(제1군) : 이상적인 젖소개량군</li>
											<li>(제2군) : 유생산 개량필요군</li>
											<li>(제3군) : 유지방 개량필요군</li>
											<li>(제4군) : 유생산 및 유지방 개량필요군</li>
										</ul>
										[체형능력개량방향]
										<table className="tablestyle">
											<tr>
												<td>구분</td>
												<td>유방부위 개량</td>
												<td>체형 전반 개량</td>
												<td>발굽 및 다리 개량</td>
												<td>비고</td>
											</tr>
											<tr>
												<td>해당암소</td>
												<td>1호, 7호</td>
												<td>2호, 9호 10호</td>
												<td>2호, 3호</td>
												<td></td>
											</tr>
										</table>
									</li>
									<li>종모우 선택요령
										<ul>
											<li>선정 종모우와 근친 우려 종모우(수정 대상 암소의 아비소가 동일시)는 피한다.</li>
											<li>여러가지 형질을 한꺼번에 개량하기보다는 중점개량 형질 우선하여 종모우 선정한다.</li>
											<li>젖소 종모우 총괄표를 활용한다.</li>
										</ul>
									</li>
									<li>기타 알아두셔야 할 사항
										<ul>
											<li>가급적 수정대상 종모우를 여유있게 선정한다.</li>
											<li>년초 교배계획을 작성하여 수정사나 조합에 미리 신청한다.</li>
											<li>인공수정시 수정증명서를 반드시 발급받아 확인ㆍ보관한다.</li>
											<li>종모우에 대한 정보에 항상 유의한다.<br/>※근친 계수 1% 증가로 인한 피해
												<table className="tablestyle">
													<tr>
														<td>형질</td>
														<td>피해정도</td>
														<td>형질</td>
														<td>피해정도</td>
													</tr>
													<tr>
														<td>유량</td>
														<td>22.7kg</td>
														<td>체고</td>
														<td>약간감소</td>
													</tr>
													<tr>
														<td>유지량</td>
														<td>-0.68kg</td>
														<td>흉위</td>
														<td>약간감소</td>
													</tr>
													<tr>
														<td>유지율(%)</td>
														<td>+0.005%</td>
														<td>초산까지 폐사율(비근친대비%)</td>
														<td>+2%</td>
													</tr>
													<tr>
														<td>체중생시</td>
														<td>-0.12kg</td>
														<td>번식사항 수태까지의 수정횟수</td>
														<td>+0.05회</td>
													</tr>
													<tr>
														<td>1세시</td>
														<td>-0.68kg</td>
														<td>첫 수정 후 수태까지의 일수</td>
														<td>+3일</td>
													</tr>
													<tr>
														<td>2세시</td>
														<td>-1.36kg</td>
														<td>임신 실패율(%)</td>
														<td>0.5%</td>
													</tr>
													<tr>
														<td>4세시</td>
														<td>-2.27kg</td>
														<td>성 성숙 연령</td>
														<td>지연</td>
													</tr>
												</table>
												예) 아비(H-171)와 딸(1호)간의 교배(근교계수:25%)<br/>
												<img src={Pic7} alt="근교" /><br/>
												※국내 젖소종모우 주요 혈통
												<table className="tablestyle">
													<tr>
														<td>포니 파암 아린다 치프</td>
														<td>그렌델 아린다치프, 미루 베티 아이반호 치프, 와파 아린다 치프, 워크웨이 치프 마크</td>
													</tr>
													<tr>
														<td>라운드 옥 랙 애플 엘러 베이션</td>
														<td>마쉬필드 엘리베이션 토니, 스위트 헤븐 트레디션, 로칼리 선 오브 보바, 스트레이트 파인 엘러ㅓ베이션 피잇, 라임 할로우 엘리베이션 마스, 오션뷰 섹새이션</td>
													</tr>
													<tr>
														<td>오스번 데일 아이반호</td>
														<td>프리리지 모니터, 펜스테이트 아이반호 스타 등</td>
													</tr>
												</table>
											</li>
										</ul>
									</li>
								</ul>
							</li>
						</ul>
					</li>
				</ul>
				<h2>각국의 젖소개량 전략</h2>
				<ul>
					<li>각국의 종모우 선발
						<ul>
							<li>국가적 젖소개량 전략 : 개량목표의 설정, 육종체계의 정립(종빈우 선발과 후대검정시 선발율, MOET기법 도입 등) </li>
							<li>각국의 젖소 후대 검정 규모
								<ul>
									<li>
										미국 : 매년 후보우 1,000두 검정 보증종모우 100두 선발(sire summary 5,000두 등재, 1년에 4회 평가)<br/>
										* 최근 20년간 유량 증가량 2,300kg : 유전적 요인 2/3, 환경요인 1/3
									</li>
									<li>네덜란드 : 매년 후보우 300두 검정(수송아지 500두), 보증종모우 10두 선발</li>
									<li>덴마크 : 매년 후보우 280두 검정</li>
									<li>일본 : 매년 후보우 180두 검정</li>
									<li>독일 : 매년 후보우 800두 검정(수송아지 1,000두), 보증종모우 100두 선발(20두 활용)</li>
									<li>캐나다 : 매년 후보우 600두 검정</li>
								</ul>
							</li>
							<li>각국의 선발지수
								<ul>
									<li>미국 : TPI(유단백 3 : 유지방 1 : 체형 1 : 유방 10</li>
									<li>
										캐나다 : LPI(유성분 6 : 체형 4)<br/>
										* 유성분 : 유지방 3 + 유단백 8, 체형 : 점수 3 + 유방 4 + 지제 2 + 체적
									</li>
									<li>일본 : 경제효과</li>
									<li>네덜란드 : INNET(-0.15 유량 + 2 유지방 + 12유단백)</li>
									<li>영국 : PIN(-0.039 유량 + 0.94 유지방 + 2.75 유단백)</li>
								</ul>
							</li>
						</ul>
					</li>
					<li>종모우 능력 평가치 이해
						<ul>
							<li>PTA(에산전달능력) : 해당 종모우가 각 형질에 대하여 타종모우에 비해 어느 정도 우수한가를 수치로 나타내는 것으로, 예상전달능력이 2,000파운드인 종모우 정액을 사용할 경우 예상전달느역이 1,500파운드인 종모우정액을 사용하는 것보다 딸소유량이 년간 약 500파운드 정도 더 얻을 수 있다는 것을 예측할 수 있다.</li>
							<li>UDC(유방지수): 6개 유방부위에 대한 평가치에 일정 가중치를 주어 합계한 지수, 유방전반에 대한 평가치이므로 높은 수치를 갖는 것이 좋음.<br/>* 가중치: 유방깊이 0.30, 앞유방부착 0.16, 앞유두배열 0.16, 후유방부착높이 0.16, 후유방너비 0.12, 정중제인대 0.10 </li>
							<li>ETA(추정전달능력): PTA와 비슷한 개념(캐나다등에서 사용)</li>
							<li>FC(최종점수): 딸소들의 체형점수에 근거하여 종모우의 체형에 대한 유전자 전달능력을 백분율로 표시</li>
							<li>MS(유방부위점수): 유방의 우수성을 백분율로 표시</li>
							<li>신뢰도(REL): 종모우 평가에 이용된 자료의 양을 나타내는 수치로서, PTA 값의 정확도를 의미한다. 따라서 신뢰도가 높은 종모우일수록 해당 수치의 정확도가 높다고 할 수 있다.</li>
							<li>체형에 대한 PTA: 체형에 대한 PTA는 종모우의 딸소들에 대한 선형심사를 통해 딸소들의 체형점수를 기준으로 평가한 수치이다.</li>
							<li>순수익지수(NET MERIT DOLLARS): 현재 미국에서 통용되는 이 순수익지수(NM$)는 유량, 지방량, 단백질량. 체세포 점수(SCS), 생산수명(PL)에 대한 예상유전 전달능력(PTA)를 종합적으로 분석하여 하나의 수익개념으로 산정한 값이다.<br/> ※ 카나다는 종합경제가(TEV, Total Economic Value)로 수익지수를 표기하며 생산형질에 64%, 우군수명에 26%, 체세포 점수에 10%정도 비율로 산정한 값이다. </li>
							<li>체세포점수(SOMATIC CELL SCORE): 유방염에 저항정도를 수치화한 것으로 평균점수가 3.2이며 평균점수보다 낮을수록 저항력이 강함을 나타낸다.</li>
							<li>생산수명(Productive Life): 생산량과 선형점수, 특히 유방형질에 대하여 아주 높은 상관관계가 있다. </li>
							<li> 낭우후대검정성적: 해당 종모우 딸소들의 실제 년간 평균유량 및 유지율, 유단백질을 표시한다. 이 수치는 305일 성년형 보정성적으로 표시하였다. 카나다 후대검정성적은 해당 종모우 딸소들의 초산평균 유량 및 유지율을 표시하여 미국의 성년형과는 상당한 차이가 있다. 대체로 초산성적을 성년형으로 환산하기 위해서는 초산유량에 약 1.3정도를 곱해주면 능력수준을 알 수 있다. </li>
							<li>체형유전능력 형질: 종모우가 생산한 딸소들의 선형심사 부위별 명칭을 말한다.이들 부위 젖소의 생산능력 및 장수성등에 가장 중요한 형질들로 각 형질별 평균을 기준으로하여 ±3(미국) 또는 ±15(카나다)의 수치범위내에 &quot;***&quot;표시로 개량능력을 나타내고 있으며 후보종모우가 생산한 암소의 체형상 특징을 의미하개 되므로 이 수치를 참고로 체형개량용 종모우를 선정하게 된다. </li>
							<li>수치(STA, Stanard Transmitting Ability): 표준전달능력이라고 표기할 수 있으며 이 수치는 종모우 딸소들의 체형 특성을 선형심사에 의해 조사된 자료들을 근거로 계산한다. 수치의 범위는 -3에서 +3사이가 대부분이며 그 이상 또는 이하는 거의 없으나 있다고 해도 극도로 치우친 체형을 의미하므로 바람직하지 못하다. STA 값은 형질에 따라 큰 수치가 좋으나 뒤에서 본 유두위치, 뒷다리 각도, 발굽 각도, 엉덩이 경사도등은 -1에서 +1사이가 바람직하다. <br/>※ 카나다의 경우 수치범위가 -15에서 +15사이가 대부분이며 형질에 따라 큰 수치가 좋으나 뒷다리각도, 엉덩이 경사도, 유방깊이, 앞유두길이는 1-9범위가 적다?고 5정도가 가장 이상적인 점수이다. </li>
						</ul>
					</li>
					<li>각국의 낙농 현황 및 능력검정
						<table className="tablestyle">
							<tr>
								<td>구분</td>
								<td colSpan="3">낙농현황</td>
								<td colSpan="4">우군검정상황</td>
								<td colSpan="4">검정성적</td>
							</tr>
							<tr>
								<td>국가별</td>
								<td>경산우두수</td>
								<td>낙농가호수</td>
								<td>평균사육두수</td>
								<td>검정우두수</td>
								<td>검정농호수</td>
								<td>검정농가평균두수</td>
								<td>검정우율(%)</td>
								<td>착유일수</td>
								<td>유량(kg)</td>
								<td>유지율(%)</td>
								<td>유단백질(%)</td>
							</tr>
							<tr>
								<td>미국</td>
								<td>9461000</td>
								<td>140000</td>
								<td>67.5</td>
								<td>4582448</td>
								<td>13458</td>
								<td>105.0</td>
								<td>48.4</td>
								<td>305</td>
								<td>8639</td>
								<td>3.69</td>
								<td>3.22</td>
							</tr>
							<tr>
								<td>캐나다</td>
								<td>1279000</td>
								<td>25700</td>
								<td>49.8</td>
								<td>764567</td>
								<td>16216</td>
								<td>47.4</td>
								<td>58.8</td>
								<td>305</td>
								<td>8251</td>
								<td>3.74</td>
								<td>3.24</td>
							</tr>
							<tr>
								<td>일본</td>
								<td>1214000</td>
								<td>40300</td>
								<td>30.0</td>
								<td>528434</td>
								<td>13755</td>
								<td>38.4</td>
								<td>43.6</td>
								<td>305</td>
								<td>8264</td>
								<td>3.80</td>
								<td>3.16</td>
							</tr>
							<tr>
								<td>화란</td>
								<td>1707875</td>
								<td>37465</td>
								<td>45.6</td>
								<td>1361879</td>
								<td>26599</td>
								<td>51.2</td>
								<td>79.7</td>
								<td>328</td>
								<td>7170</td>
								<td>4343</td>
								<td>3.48</td>
							</tr>
							<tr>
								<td>이스라엘</td>
								<td>120654</td>
								<td>1708</td>
								<td>70.6</td>
								<td>96038</td>
								<td>849</td>
								<td>112</td>
								<td>78.8</td>
								<td>334</td>
								<td>9767</td>
								<td>3.6</td>
								<td>3.00</td>
							</tr>
							<tr>
								<td>오지리</td>
								<td>704919</td>
								<td>91000</td>
								<td>7.7</td>
								<td>346486</td>
								<td>31868</td>
								<td>10.9</td>
								<td>49.2</td>
								<td>-</td>
								<td>5187</td>
								<td>4.15</td>
								<td>3.31</td>
							</tr>
							<tr>
								<td>덴마크</td>
								<td>700000</td>
								<td>10552</td>
								<td>42.3</td>
								<td>597904</td>
								<td>11410</td>
								<td>52.4</td>
								<td>88.0</td>
								<td>365</td>
								<td>6593</td>
								<td>4.44</td>
								<td>3.48</td>
							</tr>
							<tr>
								<td>폴란드</td>
								<td>4754000</td>
								<td>153000</td>
								<td>31.0</td>
								<td>617000</td>
								<td>69027</td>
								<td>37.9</td>
								<td>55.6</td>
								<td>303</td>
								<td>6222</td>
								<td>4.06</td>
								<td>3.32</td>
							</tr>
							<tr>
								<td>독일</td>
								<td>5229396</td>
								<td>195580</td>
								<td>22.3</td>
								<td>3915731</td>
								<td>100278</td>
								<td>39.0</td>
								<td>74.9</td>
								<td>318</td>
								<td>6084</td>
								<td>4.26</td>
								<td>3.42</td>
							</tr>
							<tr>
								<td>뉴질랜드</td>
								<td>2904000</td>
								<td>14700</td>
								<td>197.0</td>
								<td>2473833</td>
								<td>12446</td>
								<td>199.0</td>
								<td>87.4</td>
								<td>228</td>
								<td>3357</td>
								<td>4.77</td>
								<td>3.64</td>
							</tr>
							<tr>
								<td>한국</td>
								<td>315518</td>
								<td>21129</td>
								<td>15.0</td>
								<td>28882</td>
								<td>1177</td>
								<td>25.0</td>
								<td>9.2</td>
								<td>305</td>
								<td>7038</td>
								<td>3.61</td>
								<td>3.25</td>
							</tr>
						</table>
						* 자료 : ICAR(1995), 한국1996<br/><br/>※각국의 전국평균 산유량과 검정농가 산유량 비교
						<table id="tablestyle2">
							<tr>
								<td rowSpan="2">구분</td>
								<td colSpan="3">산유량(305일, kg)</td>
								<td colSpan="2">유지율(%)</td>
							</tr>
							<tr>
								<td>검정농가</td>
								<td>전국평균</td>
								<td>차이</td>
								<td>검정농가</td>
								<td>전국평균</td>
							</tr>
							<tr>
								<td>한국</td>
								<td>6868</td>
								<td>5836</td>
								<td>1032</td>
								<td>3.56</td>
								<td>-</td>
							</tr>
							<tr>
								<td>미국</td>
								<td>8382</td>
								<td>7787</td>
								<td>595</td>
								<td>3.70</td>
								<td>3.65</td>
							</tr>
							<tr>
								<td>일본</td>
								<td>8130</td>
								<td>6764</td>
								<td>1366</td>
								<td>3.81</td>
								<td>3.73</td>
							</tr>
							<tr>
								<td>이스라엘</td>
								<td>10136</td>
								<td>9200</td>
								<td>936</td>
								<td>3.09</td>
								<td>-</td>
							</tr>
						</table>
						* 자료 : ICAR(1994), 한국(1996)
					</li>
				</ul>
			</div>
  );
}

export default Do1;
