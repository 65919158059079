import axios from 'axios';

const PUBLIC_API_KEY = process.env.REACT_APP_API_URL
  ? process.env.REACT_APP_API_URL
  : 'https://pncstai.duckdns.org/api';

export { PUBLIC_API_KEY };

export async function fileState(baseUrl, props) {
  const apiUrl = PUBLIC_API_KEY + baseUrl;
  const response = await axios.post(apiUrl, props, {
    responseType: 'blob', // Important for handling the binary data
  });
  return response;
}

export async function postState(baseUrl, props) {
  const apiUrl = PUBLIC_API_KEY + baseUrl;

  console.log(apiUrl, props);

  // FormData인 경우 특별한 헤더 설정
  const config =
    props instanceof FormData
      ? {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      : {};

  const response = await axios.post(apiUrl, props, config);
  return response.data;
}

export async function getState(baseUrl, props) {
  const apiUrl = PUBLIC_API_KEY + baseUrl;
  const params = { params: props };

  console.log(apiUrl, params);

  const response = await axios.get(apiUrl, params);
  // If the request is successful, the data will be in response.data
  return response.data;
}

export async function delState(baseUrl, props) {
  const apiUrl = PUBLIC_API_KEY + baseUrl;
  const params = { data: props };
  const response = await axios.delete(apiUrl, params);
  return response.data;
}

export async function patState(baseUrl, props) {
  const apiUrl = PUBLIC_API_KEY + baseUrl;
  const params = { data: props };
  const response = await axios.patch(apiUrl, params);
  return response.data;
}

export default postState;
