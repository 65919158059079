import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';

// hooks
import useWindowSize from 'hooks/useWindowSize';

// api
import { getState, postState } from 'utils/api';

// assets
import searchIcon from 'assets/svgs/containers/performance/search_Icon.svg';
import AttachFile from 'assets/svgs/common/attachedFile.svg';

// components
import Pagination from './Pagination';

// scss
import './commonBoard.scss';

function CommonBoardApi() {
  // hooks
  const windowSize = useWindowSize();
  const location = useLocation();

  // data
  const [Data, setData] = useState(null);

  // 페이지네이션
  const [totalPages, setTotalPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  // 검색
  const searchInputRef = useRef(null);
  const searchSelectRef = useRef(null);

  const baseDir = location.pathname.toLowerCase().replace('web/', '');
  const [props, setProps] = useState({
    page: currentPage,
  });

  const fetchApiData = async () => {
    getState(baseDir, props)
      .then((getdata) => {
        console.log(getdata);
        setTotalPages(Math.floor(getdata.count / 10));
        setData(getdata);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchApiData();
  }, [props]);

  useEffect(() => {
    setProps((prevProps) => ({
      ...prevProps,
      page: currentPage,
    }));
  }, [currentPage]);

  // 검색하깅
  const searchData = () => {
    setProps((prevProps) => ({
      ...prevProps,
      search: searchInputRef?.current?.value,
      filter: searchSelectRef?.current?.value,
    }));
  };

  const checkEnterKeyPress = (e) => {
    if (e.key === 'Enter') {
      searchData();
    }
  };

  // Update hit count function
  const updateHitCount = async (bdcCode) => {
    try {
      await postState('/board/update_info_hit/', { bdc_code: bdcCode });
    } catch (error) {
      console.error('Failed to update hit count:', error);
    }
  };

  // Function to check if the current date is between bdc_sdate and bdc_edate
  const isNotice = (bdcSDate, bdcEDate) => {
    const currentDate = new Date();
    const startDate = new Date(bdcSDate);
    const endDate = new Date(bdcEDate);
    return currentDate >= startDate && currentDate <= endDate;
  };

  return (
    <>
      <div className="boardHeader">
        <ul className="boardTotal">
          <li>
            총 게시물 <span>{Data && Data.count}</span>
          </li>
          <li>
            페이지 <span>{Data && `${currentPage} / ${Data.total_pages}`}</span>
          </li>
        </ul>

        <div className="boardSearch">
          <div className="searchInput">
            <select ref={searchSelectRef} defaultValue="bdc_title">
              <option value="bdc_title">제목</option>
              {/* <option value='bdc_body'>내용</option> */}
              <option value="bdc_wname">작성자</option>
            </select>
            <input
              type="text"
              ref={searchInputRef}
              onKeyDown={(e) => checkEnterKeyPress(e)}
            />
          </div>

          <button type="button" onClick={() => searchData()}>
            <img src={searchIcon} alt="검색버튼" />
            <span>검색</span>
          </button>
        </div>
      </div>

      <div className="boardTable">
        {windowSize.width >= 768 && (
          <ul className="boardTableHead">
            {windowSize.width >= 1440 && <li className="number">번호</li>}
            <li className="title">제목</li>
            <li className="write">작성자</li>
            <li className="date">작성일</li>
            {windowSize.width >= 1440 && <li className="view">조회수</li>}
            {windowSize.width >= 1440 && <li className="attach">첨부</li>}
          </ul>
        )}

        <ul className="boardTableBody">
          {Data?.results.length === 0 ? (
            <li className="noSearch">검색된 게시글이 없습니다.</li>
          ) : (
            Data?.results.map((boardItem, i) => (
              <li key={i}>
                <ul
                  className="boardTableItem"
                  style={{
                    fontWeight: isNotice(boardItem.bdcSDate, boardItem.bdcEDate)
                      ? 'bold'
                      : 'normal',
                  }}
                >
                  {windowSize.width >= 1440 && (
                    <li className="number">
                      {isNotice(boardItem.bdcSDate, boardItem.bdcEDate) ? (
                        <span>&#91;공지&#93;</span>
                      ) : (
                        boardItem.bdc_code
                      )}
                    </li>
                  )}
                  <li className="title">
                    {
                      // 타이틀 링크로 이동 or 내용보기 or 젖소감정사업보고서 페이지
                      location.pathname.includes('Publication') ? (
                        <a
                          href={`/statics/_uploadFiles/_data/${boardItem.file_list[0].bdf_fname}`}
                          download="젖소검정사업보고서"
                          className="download"
                          onClick={() => updateHitCount(boardItem.bdc_code)}
                        >
                          <span>{boardItem.bdc_title}</span>
                        </a>
                      ) : boardItem.bdc_body.startsWith('http') ? (
                        <a
                          href={boardItem.bdc_body}
                          target="_blank"
                          rel="noreferrer"
                          onClick={() => updateHitCount(boardItem.bdc_code)}
                        >
                          <span>{boardItem.bdc_title}</span>
                        </a>
                      ) : (
                        <Link
                          to={`detail/${boardItem.bdc_code}`}
                          className={boardItem.bdc_dept > 0 ? 'answer' : ''}
                          style={{
                            paddingLeft: `${boardItem.bdc_dept * 40}px`,
                          }}
                          onClick={() => updateHitCount(boardItem.bdc_code)}
                        >
                          {boardItem.bdc_title}
                        </Link>
                      )
                    }
                  </li>
                  <li className="write">{boardItem.bdc_wname}</li>
                  <li className="date">
                    {boardItem.bdc_rdt ? boardItem.bdc_rdt.split('T')[0] : '-'}
                  </li>
                  {
                    // 조회수
                    windowSize.width >= 1440 && (
                      <li className="view">{boardItem.bdc_hit}</li>
                    )
                  }
                  {
                    // 첨부파일
                    windowSize.width >= 1440 && (
                      <li className="attach">
                        {boardItem.file_list.length > 0 && (
                          <img src={AttachFile} alt="첨부파일" />
                        )}
                      </li>
                    )
                  }
                </ul>
              </li>
            ))
          )}
        </ul>
      </div>
      {(location.pathname.includes('BuyAndSell') ||
        location.pathname.includes('Qna')) && (
        <ul className="boardBtn">
          <li>
            <button type="button" onClick={() => {}}>
              글쓰기
            </button>
          </li>
        </ul>
      )}
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={setCurrentPage}
      />
    </>
  );
}

export default CommonBoardApi;
