import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

// api
import { getState } from 'utils/api';

// components
import Pagination from 'components/bo/Pagination';

function EmployeeList() {
  const [searchParams, setSearchParams] = useState({
    search_type: 'bdc_group', // 'bdc_group', 'bdc_part', or 'bdc_name'
    search_keyword: '',
    page_no: 1,
  });

  const { data, isLoading, isError, error } = useQuery({
    queryKey: ['employees', searchParams],
    queryFn: () => getState('/board/select_employee_list', searchParams),
  });

  const employees = data?.result?.list || [];
  const totalCount = data?.result?.total_count || 0;
  const totalPages = data?.result?.page_count || 0;

  const handleSearch = (e) => {
    e.preventDefault();
    setSearchParams({
      ...searchParams,
      page_no: 1,
    });
  };

  const handlePageChange = (pageNo) => {
    setSearchParams({
      ...searchParams,
      page_no: pageNo,
    });
  };

  return (
    <div className="bo-page">
      <h2>직원 목록</h2>
      {/* 검색 영역 */}
      <div className="searchbox">
        <select
          value={searchParams.search_type}
          onChange={(e) =>
            setSearchParams({
              ...searchParams,
              search_type: e.target.value,
            })
          }
        >
          <option value="bdc_group">소속부서</option>
          <option value="bdc_part">소속팀</option>
          <option value="bdc_name">이름</option>
        </select>
        <input
          type="text"
          value={searchParams.search_keyword}
          onChange={(e) =>
            setSearchParams({
              ...searchParams,
              search_keyword: e.target.value,
            })
          }
        />
        <button type="button" onClick={handleSearch} className="btn-search">
          검색
        </button>
      </div>

      {/* 총 직원수 */}
      <div className="board-top">
        <p>
          총 직원수: <strong>{totalCount}</strong> 명, 페이지{' '}
          {searchParams.page_no}/{totalPages}
        </p>
      </div>

      {/* 리스트 테이블 */}
      <div className="table-container">
        <table>
          <colgroup>
            <col width="15%" />
            <col width="15%" />
            <col width="15%" />
            <col width="15%" />
            <col width="15%" />
            <col width="*" />
          </colgroup>
          <thead>
            <tr>
              <th>이름</th>
              <th>소속부서</th>
              <th>소속팀</th>
              <th>직급</th>
              <th>전화</th>
              <th>담당업무</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td colSpan="6" className="loading-data">
                  데이터를 불러오는 중입니다...
                </td>
              </tr>
            ) : isError ? (
              <tr>
                <td colSpan="6" className="error-data">
                  에러가 발생했습니다: {error.message}
                </td>
              </tr>
            ) : employees.length === 0 ? (
              <tr>
                <td colSpan="6" className="no-data">
                  검색조건과 일치하는 직원이 없습니다.
                </td>
              </tr>
            ) : (
              employees.map((employee) => (
                <tr key={employee.bdc_code}>
                  <td className="center">
                    <Link
                      to={`/bo/employee/view?bdc_code=${employee.bdc_code}`}
                    >
                      {employee.bdc_name}
                    </Link>
                  </td>
                  <td className="center">{employee.bdc_group}</td>
                  <td className="center">{employee.bdc_part}</td>
                  <td className="center">{employee.bdc_position}</td>
                  <td className="center">{employee.bdc_tel}</td>
                  <td className="center">{employee.bdc_etc}</td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>

      {/* 페이지네이션 */}
      <Pagination
        currentPage={searchParams.page_no}
        totalPage={totalPages}
        onPageChange={handlePageChange}
      />

      {/* 버튼 영역 */}
      <div className="button-group">
        <Link to="/bo/employee/edit" className="btn-l">
          등록
        </Link>
      </div>
    </div>
  );
}

export default EmployeeList;
