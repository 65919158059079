import React from 'react';
import Ox from 'containers/web/ox/Ox';

function OxPage() {
  return (
   <Ox />
  );
}

export default OxPage;
