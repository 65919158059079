import React from 'react';

import pic638 from 'assets/images/sub/_images/pic631.jpg';
import pic639 from 'assets/images/sub/_images/pic632.jpg';

function Do126() {

  return (
    <div className="techinfoview">
      <h1>혈유증</h1>
      <p className="pstyle">유즙에 혈액이 섞여 나오는 상태를 혈유증이라 한다. 분만후 비유개시시기의 생리적인 것과 유방의 외상 또는 유방염 등에 의한 병적인 것이 있다.</p>
      <div className="leftpart">
        <h2>원인</h2>
        <ul>
          <li>생리적 혈유 
            <ul>
              <li>분만후 급속히 비유가 개시됨에 따라 대량의 혈액이 유선의 혈관에 유입되어 모세혈관이 확장 충혈되어 혈과벽으로 적혈구가 누출되거나 일부의 혈과이 파열하여 혈액이 유즙중에 섞여 발생(유방의 임신성 부종이 심할 때 많이 발생한다.) </li>
            </ul>
          </li>
          <li>병적 혈유 
            <ul>
              <li>유방 또는 유두의 외상(外傷)에 의한 출혈 </li>
              <li> 유방염에 의한 조직 장해에 의한 출혈 </li>
              <li>후산정체, 태아 미아라 변성, 케토시스시에 혈유를 수반하는 일이 있다. </li>
            </ul>
          </li>
        </ul>
        <h2>증상</h2>
        <ul>
          <li>생리적 혈유증에서는 전신증상은 나타나지 않는다. </li>
          <li>급성 유방염과 외상에 의한 혈유증에서는 유방의 종창, 발적, 열감, 통증, 잠막창백증 등이 나타난다. </li>
        </ul>
        <h2>목장에서의 처치</h2>
        <ul>
          <li>생리적 혈유-착유를 되풀이 해 감에 따라 감소하며 보통 수일내 회복한다. 
            <ul>
              <li>고단백질 사료, 청예, 다즙질 사료를 줄여 수일간 유량감소를 시도한다. </li>
              <li>분만 후 수일간 냉습포를 실시한다. </li>
              <li>전신 지혈제를 투여할 수 있다. </li>
              <li>포르말린액 10-15ml를 물 500-1000ml 에 희석하여 1일 1회 2∼3일 간격으로 2∼3회 먹이거나 포르말린 10ml 5%포도당 또는 생리식염수 500ml에 타서 서서히 정맥내 주사한다. </li>
            </ul>
          </li>
        </ul>
      </div>
      <div className="rightpart">
        <img src={pic638} className="nonborder" alt=""/>
        <img src={pic639} className="nonborder" alt=""/>
      </div>
    </div>
  );
}

export default Do126;