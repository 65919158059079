import React from 'react';

// constants
import { BOARD_CODE, BOARD_NAME } from 'constants/backOfficeBoard';

// components
import BoardList from '../_components/BoardList';

function ImproveInfoList() {
  return (
    <BoardList
      boardCode={BOARD_CODE.IMPROVE_INFO}
      boardName={BOARD_NAME.IMPROVE_INFO}
      editPath="/bo/board/improveInfo/edit"
      viewPath="/bo/board/improveInfo/view"
    />
  );
}

export default ImproveInfoList;
