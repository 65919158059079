import React from 'react';
import { useParams } from 'react-router-dom';

// 컴포넌트
import Fo1 from './Fo1';
import Fo2 from './Fo2';
import Fo3 from './Fo3';
import Fo4 from './Fo4';
import Fo5 from './Fo5';
import Fo6 from './Fo6';
import Fo7 from './Fo7';
import Fo8 from './Fo8';
import Fo9 from './Fo9';
import Fo10 from './Fo10';
import Fo11 from './Fo11';
import Fo12 from './Fo12';
import Fo13 from './Fo13';
import Fo14 from './Fo14';
import Fo15 from './Fo15';
import Fo16 from './Fo16';
import Fo17 from './Fo17';
import Fo18 from './Fo18';
import Fo19 from './Fo19';
import Fo20 from './Fo20';
import Fo21 from './Fo21';
import Fo22 from './Fo22';
import Fo23 from './Fo23';
import Fo24 from './Fo24';
import Fo25 from './Fo25';
import Fo26 from './Fo26';
import Fo27 from './Fo27';
import Fo28 from './Fo28';
import Fo29 from './Fo29';
import Fo30 from './Fo30';
import Fo31 from './Fo31';
import Fo32 from './Fo32';
import Fo33 from './Fo33';
import Fo34 from './Fo34';
import Fo35 from './Fo35';
import Fo36 from './Fo36';
import Fo37 from './Fo37';
import Fo38 from './Fo38';
import Fo39 from './Fo39';
import Fo40 from './Fo40';
import Fo41 from './Fo41';
import Fo42 from './Fo42';
import Fo43 from './Fo43';
import Fo44 from './Fo44';
import Fo45 from './Fo45';
import Fo46 from './Fo46';
import Fo47 from './Fo47';
import Fo48 from './Fo48';
import Fo49 from './Fo49';
import Fo50 from './Fo50';
import Fo51 from './Fo51';
import Fo52 from './Fo52';
import Fo53 from './Fo53';
import Fo54 from './Fo54';
import Fo55 from './Fo55';
import Fo56 from './Fo56';
import Fo57 from './Fo57';
import Fo58 from './Fo58';
import Fo59 from './Fo59';
import Fo60 from './Fo60';
import Fo61 from './Fo61';

function FoIndex() {

  const params = useParams();

  return (
    <>
    { params.id === '1' && <Fo1 />}
      { params.id === '2' && <Fo2 />}
      { params.id === '3' && <Fo3 />}
      { params.id === '4' && <Fo4 />}
      { params.id === '5' && <Fo5 />}
      { params.id === '6' && <Fo6 />}
      { params.id === '7' && <Fo7 />}
      { params.id === '8' && <Fo8 />}
      { params.id === '9' && <Fo9 />}
      { params.id === '10' && <Fo10 />}
      { params.id === '11' && <Fo11 />}
      { params.id === '12' && <Fo12 />}
      { params.id === '13' && <Fo13 />}
      { params.id === '14' && <Fo14 />}
      { params.id === '15' && <Fo15 />}
      { params.id === '16' && <Fo16 />}
      { params.id === '17' && <Fo17 />}
      { params.id === '18' && <Fo18 />}
      { params.id === '19' && <Fo19 />}
      { params.id === '20' && <Fo20 />}
      { params.id === '21' && <Fo21 />}
      { params.id === '22' && <Fo22 />}
      { params.id === '23' && <Fo23 />}
      { params.id === '24' && <Fo24 />}
      { params.id === '25' && <Fo25 />}
      { params.id === '26' && <Fo26 />}
      { params.id === '27' && <Fo27 />}
      { params.id === '28' && <Fo28 />}
      { params.id === '29' && <Fo29 />}
      { params.id === '30' && <Fo30 />}
      { params.id === '31' && <Fo31 />}
      { params.id === '32' && <Fo32 />}
      { params.id === '33' && <Fo33 />}
      { params.id === '34' && <Fo34 />}
      { params.id === '35' && <Fo35 />}
      { params.id === '36' && <Fo36 />}
      { params.id === '37' && <Fo37 />}
      { params.id === '38' && <Fo38 />}
      { params.id === '39' && <Fo39 />}
      { params.id === '40' && <Fo40 />}
      { params.id === '41' && <Fo41 />}
      { params.id === '42' && <Fo42 />}
      { params.id === '43' && <Fo43 />}
      { params.id === '44' && <Fo44 />}
      { params.id === '45' && <Fo45 />}
      { params.id === '46' && <Fo46 />}
      { params.id === '47' && <Fo47 />}
      { params.id === '48' && <Fo48 />}
      { params.id === '49' && <Fo49 />}
      { params.id === '50' && <Fo50 />}
      { params.id === '51' && <Fo51 />}
      { params.id === '52' && <Fo52 />}
      { params.id === '53' && <Fo53 />}
      { params.id === '54' && <Fo54 />}
      { params.id === '55' && <Fo55 />}
      { params.id === '56' && <Fo56 />}
      { params.id === '57' && <Fo57 />}
      { params.id === '58' && <Fo58 />}
      { params.id === '59' && <Fo59 />}
      { params.id === '60' && <Fo60 />}
      { params.id === '61' && <Fo61 />}
    </>
  );
}

export default FoIndex;
