import React from 'react';

// 컴포넌트
import DciEval from 'containers/dcitest/dciEval/DciEval';

function DciEvalPage() {
  return (
    <DciEval />  
  );
}

export default DciEvalPage;
