// Main.js
import React, { useRef, useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

import './main.scss';

// 이미지 임포트
// import bannerSlide1 from 'assets/images/containers/main/newBanner2.jpg';
import bannerSlide3 from 'assets/images/containers/main/newBanner7.jpg';
import bannerSlide2 from 'assets/images/containers/main/newBanner4.jpg';
// import bannerSlide4 from 'assets/images/containers/main/newBanner4.jpg';
// import bannerSlide5 from 'assets/images/containers/main/newBanner5.jpg';
// import bannerSlide6 from 'assets/images/containers/main/newBanner6.jpg';
// import bannerSlide7 from 'assets/images/containers/main/newBanner7.jpg';
import bannerSlide8 from 'assets/images/containers/main/newBanner8.png';

import bluecircle from 'assets/images/containers/main/bluecircle.png';
import favorite1 from 'assets/images/containers/main/favorite1.png';
import favorite2 from 'assets/images/containers/main/favorite2.png';
import favorite3 from 'assets/images/containers/main/favorite3.png';
import favorite4 from 'assets/images/containers/main/favorite4.png';
import favorite5 from 'assets/images/containers/main/favorite5.png';
import favorite6 from 'assets/images/containers/main/favorite6.png';
import mainbusiness1 from 'assets/images/containers/main/mainBusniess1.png';
import mainbusiness2 from 'assets/images/containers/main/mainBusniess2.png';
import mainbusiness3 from 'assets/images/containers/main/mainBusniess3.png';
import mainbusiness4 from 'assets/images/containers/main/mainBusniess4.png';
// import scrollIcon from 'assets/images/containers/main/scrollIcon.svg';
// import circleitem1 from 'assets/images/containers/main/circle1.png';

// 컴포넌트
import BannerSlider from './mainitem/BannerSlider';
import FavoriteMenu from './mainitem/FavoriteMenu';
import Notice from './mainitem/Notice';
import Slideshow from './mainitem/Slideshow';
import MainBusiness from './mainitem/MainBusiness';
import MainNews from './mainitem/MainNews';
import AlertZone from './mainitem/AlertZone';
import VideoGallery from './mainitem/VideoGallery';
import QuickMenu from './mainitem/QuickMenu';

function Main() {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: false,
    });
  }, []);

  const titleConRef = useRef(null);

  const scrollToTitleCon = () => {
    if (titleConRef.current) {
      titleConRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };
  const bannerImages = [bannerSlide8, bannerSlide2, bannerSlide3];
  const menuItems = [
    {
      title: '검정성적조회',
      icon: favorite1,
      // 2025-02-05 추가
      // link: '/Web/Performance/PerformanceCheck',
      link: 'http://www2.dcic.co.kr/performace_02.do',
      isFeatured: false,
    },
    {
      title: '이달의 검정농가',
      icon: favorite2,
      link: '/Web/Performance/MonthFarmhouse',
      isFeatured: true,
    },
    {
      title: '경제수명 및 도태우 분석',
      icon: favorite3,
      link: '/Web/Performance/DieoutCowAnalysis',
      isFeatured: false,
    },
    {
      title: '유전평가 및 계획교배',
      icon: favorite4,
      link: '/Web/Performance/PlannedMatingCow',
      isFeatured: false,
    },
    {
      title: '유전체 분석',
      icon: favorite5,
      link: '/Web/Performance/GeneAnalysis',
      isFeatured: false,
    },
    {
      title: '정액주문서비스',
      icon: favorite6,
      link: '/Web/Ox/BuySemen',
      isFeatured: false,
    },
  ];
  const cowsData = [
    {
      name: 'H-544 럭키',
      country: 'Korea',
      registrationNumber: '12345',
      overallIndex: '95',
      keyTrait: '유량',
      evaluationTime: '2023년 1월',
      fullName:
        'GOLDWYN MAN-O-MAN SUNHAE LUCKY-ET골드윈 맨-오-맨 순해 럭키-ET1',
      image1: 'assets/images/containers/main/youcow.png',
      image2: 'assets/images/containers/main/defaultCow.png',
    },
    {
      name: 'B-231 스타',
      country: 'USA',
      registrationNumber: '123456',
      overallIndex: '951',
      keyTrait: '유량있음',
      evaluationTime: '2023년 12월',
      fullName:
        'GOLDWYN MAN-O-MAN SUNHAE LUCKY-ET골드윈 맨-오-맨 순해 럭키-ET2',
      image1: 'assets/images/containers/main/youcow.png',
      image2: 'assets/images/containers/main/defaultCow.png',
    },
    {
      name: 'C-789 메이플',
      country: 'Canada',
      registrationNumber: '123456',
      overallIndex: '951',
      keyTrait: '유량있음',
      evaluationTime: '2023년 12월',
      fullName:
        'GOLDWYN MAN-O-MAN SUNHAE LUCKY-ET골드윈 맨-오-맨 순해 럭키-ET3',
      image1: 'assets/images/containers/main/youcow.png',
      image2: 'assets/images/containers/main/defaultCow.png',
    },
  ];
  const slides = [
    {
      title: '유우군 능력 검정사업',
      description:
        '젖소개량사업소는 1979년부터 젖소능력검정 및 후대검정사업을 실시하고 있습니다.',
      infoList: [
        '유우군 능력검정 시행방법',
        '젖소검정성적표 확인하기',
        '농가검정성적표 확인하기',
      ],
      images: [mainbusiness1, mainbusiness2],
    },

    {
      title: '후대검정 사업',
      description:
        '젖소개량사업소는 1979년부터 젖소능력검정 및 후대검정사업을 실시하고 있습니다.',
      infoList: [
        '한국형 보증씨수소의 선발',
        '청정육종농가란?',
        '후대검정농가란?',
      ],
      images: [mainbusiness1, mainbusiness2],
    },
    {
      title: '씨수소 사양관리',
      description:
        '젖소개량사업소는 과학적인 사양관리로, 세계 최고 수준의 씨수소를 만듭니다.',
      infoList: ['씨수소 사양관리', '씨수소 사양시험 연구', '조사료 생산'],
      images: [mainbusiness3, mainbusiness4],
    },
  ];

  return (
    <div className="mainPage">
      {/* BannerSlider 컴포넌트 */}
      <div className="banner-slider">
        <BannerSlider images={bannerImages} />
        <div
          className="animation"
          onClick={scrollToTitleCon}
          role="button"
          tabIndex={0}
        >
          <div className="scroll-icon">
            <div className="circle">
              <div className="dot"></div>
            </div>
          </div>
        </div>
      </div>
      {/* 인사말 컴포넌트 */}
      <div className="titleCon" ref={titleConRef}>
        <div data-aos="fade-up" className="title_box">
          <h2 className="title_item">
            풍요로운 낙농선진국의 <span>미래를 위한,</span>
            <br />
            끊임없는 노력 끊임없는 열정!
          </h2>
          <p data-aos="fade-up" className="title_item">
            젖소개량사업소는,
            <br />
            지난 60여년을 낙농가 여러분의 애정과 관심속에서
            <br />
            지속적으로 우리 낙농가 젖소개량에 이바지하여 왔습니다.
          </p>
        </div>
        <span className="bluecircle">
          <img src={bluecircle} alt="blueicon" />
        </span>
      </div>
      {/* 즐겨찾는 메뉴 컴포넌트 */}
      <div data-aos="fade-up">
        <FavoriteMenu menuItems={menuItems} />
      </div>
      {/* Notice 컴포넌트 */}
      <div className="notice-and-slideshow-row" data-aos="fade-up">
        <Notice />
        <Slideshow cows={cowsData} />
      </div>
      {/* business 컴포넌트 */}
      <div data-aos="fade-up">
        <MainBusiness slides={slides} />
      </div>
      <div className="news-and-alert-row" data-aos="fade-up">
        {/* 디지털 뉴스 컴포넌트 */}
        <MainNews />
        <AlertZone />
        {/* 알림존 컴포넌트 */}
        <div className="bg-con"></div>
        <span className="shape"></span>
      </div>
      <div data-aos="fade-up">
        <VideoGallery />
      </div>
      {/* <span className="circle-item">
        <img src={circleitem1} alt="" />
      </span> */}
      <QuickMenu />
      {/* <ThemedMenu /> */}
    </div>
  );
}

export default Main;
