import React, { useEffect } from 'react';
import Dcitest from 'containers/dcitest/DciTest';

function DcitestPage() {
  //   useEffect(() => {
  //     alert(
  //       '검정성적 조회 서비스는 농협 젖소개량사업소 홈페이지에서 이용하실 수 있습니다.',
  //     );
  //     window.location.href = 'http://www2.dcic.co.kr/performace_02.do';
  //   }, []);

  return <Dcitest />;
}

export default DcitestPage;
