import React from 'react';

// 컴포넌트
import SubTitle from 'components/subTitle/SubTitle';
import freezeMo from 'assets/images/containers/business/freezeSemen/freeze_mo.png';
import freezePc from 'assets/images/containers/business/freezeSemen/freeze_pc.png';
import asMo from 'assets/images/containers/business/freezeSemen/as_mo.png';
import asPc from 'assets/images/containers/business/freezeSemen/as_pc.png';
import oxMo from 'assets/images/containers/business/freezeSemen/ox_mo.png';
import oxPc from 'assets/images/containers/business/freezeSemen/ox_pc.png';

// scss
import './freezeSemen.scss';

function FreezeSemen() {
  return (
    <div className="freezeSemen subCon">
      <SubTitle />
      <dl className='semenTitle'>
        <dt>한국 젖소 개량의 산실,<span>그 자부심을 지켜갑니다.</span></dt>
        <dd>유전자 산업은 고부가치의 제품을 생산하는 미래의 가장 유명한 사업이며, 세계는 앞다투어 투자에 박차를 가하고 있습니다.</dd>
        <dd>바로 우리, 젖소개량사업소가 한국 젖소 개량의 전초기지라는 자부심으로 끊임없이 도전하고 있습니다.</dd>
      </dl>
      <ul>
        <li>
          <img 
            srcSet={`${freezeMo} 768w, ${freezePc} 769w`}
            sizes='(max-width: 768px) 100vw, 50vw'
            alt='고품질 동결정액 생산 이미지'
          />
          <dl>
            <dt>고품질 동결정액 생산</dt>
            <dd>종축보호지역으로 지정된 청정한 지역에서 사육단계 HACCP 젖소인증을 통해 씨수소의 건강상태를  최상의 조건으로 유지하고 외부와의 차단 방역을 통해 질병이 없는 우수한 품질의 정액을 생산합니다.</dd>
            <dd>각종 최첨단 장비를 이용하여 정액을 검사하여 2,000만 ~ 3,000만의 정자를 자동 충전 봉합하고, -196℃의 초 저온에서 동결하여 반영구적으로 보존할 수 있는 고품질 동결 정액을 연간 30만 스트로 생산합니다.</dd>
          </dl>
        </li>
        <li>
          <img 
            srcSet={`${asMo} 768w, ${asPc} 769w`} 
            sizes='(max-width: 768px) 100vw, 50vw'
            alt='철저한 품질검사와 판매 A/S 강화' 
          />
          <dl>
            <dt>철저한 품질검사와 판매 A/S 강화</dt>
            <dd>생산정액, 보관정액, 유통정액으로 세분화하여 정기적인 품질검사로 정액의 품질관리에 만전을 기하는 국내 유일의 기관입니다.</dd>
            <dd>24시간 인터넷 주문체계 및 지역별 판매팀 순회 공급체계를 운영하고 있습니다. 계획교배를 위한 정액선정의 자문과 불량정액 교환 등 고객감동차원의 서비스를 제공합니다.</dd>
          </dl>
        </li>
        <li>
          <img 
            srcSet={`${oxMo} 768w, ${oxPc} 769w`}
            sizes='(max-width: 768px) 100vw, 50vw'
            alt='개량속도를 가속화하는 한국형 씨수소'  
          />
          <dl>
            <dt>개량속도를 가속화 하는 한국형 씨수소</dt>
            <dd>한국형씨수소 생산을 위하여 북미에서 고능력수정란&#40;유전능력 상위 0.1%의 암소 이용&#41;을 도입, 국내 청정육종농가에 이식하거나 국내 우수 암소에 대하여 직접 채란합니다.</dd>
            <dd>태어난 암송아지는 젖소개량 기간을 단축하여 농가소득에 기여토록 하고, 수송아지는 한국형 후보 씨수소로 활용합니다.</dd>
          </dl>
        </li>
      </ul>
    </div>
  );
}

export default FreezeSemen;
