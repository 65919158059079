import React, { useRef } from 'react';

// scss
import './farmcongCode.scss';

function FarmcongCode({
  props,
  setProps,
  setErrorMessage,
  openModal
}) {

  const farmcodeRef = useRef(null); // 농가코드
  const congcodeRef = useRef(null); // 조합코드

  const validateNumber = (code) => {
    if (code.length !== 6) {
      setErrorMessage('조합,농가코드에 4자리 숫자를 입력해주세요.');
      return false;
    }



    return true;
  };

  const setFarmcong = () => {
    const farm = farmcodeRef.current.value;
    const cong = congcodeRef.current.value;

    if (!validateNumber(farm)) {
      openModal();
      return;
    }

    if (!validateNumber(cong)) {
      openModal();
      return;
    }

    setProps(prev => {
      const newProps = {...prev , 
        id: `${cong}${farm}`
      };
      
      return newProps;
    });
    
    localStorage.setItem('farm', farm);
    localStorage.setItem('cong', cong);
  };

  return (
    <div className='farmcongCode'>
      <ul>
        <li>
          <span>조합코드</span>
          <input 
            type='text'
            maxLength='4'
            ref={congcodeRef}
            defaultValue={props.id.substring(0, 4)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                setFarmcong();
              }
            }}
          />
        </li>
        <li>
          <span>농가코드</span>
          <input 
            type='text'
            maxLength='4'
            ref={farmcodeRef}
            defaultValue={props.id.slice(4)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                setFarmcong();
              }
            }}
          />
        </li>
      </ul>
      <button
        type='button'
        onClick={setFarmcong}
      >
        검색
      </button>
    </div>
  );
}

export default FarmcongCode;
