import React from 'react';

function Fo33() {

  return (
    <div className="techinfo_view">
				<p className="viewTitle">산성중독증</p>
				<dl>
					<dt>발병대상</dt>
					<dd>소, 양, 특히 단기 비육중인 소와 양</dd>
				</dl>
				
				<dl>
					<dt>원인</dt>
					<dd>산중독은 유산을 생성하는 미생물의 증강와 유산(D, L형)의 급격한 증식으로 비롯된다. 조사료의 비율이 높은 상태에서 고농후사료로 갑자기 전환하였을 때 잘 발생한다. 또한 고에너지 사료로 사육하는 소는 반추위 미생물에 의한 유산생성으로 산중독의 가능성이 있다. 그러므로 원료의 갑작스런 변경과 곡류의 엉성한 배합 및 부적절한 사양이 산중독증을 유발시킬 수 있다.</dd>
				</dl>
				
				<dl>
					<dt>징후 및 증상</dt>
					<dd>미약한 산중독증은 생산 저하와 사료 섭취량이 일관성 없이 변하는 특징이 있다. 만일 원료의 갑작스런 변경 또는 부적절한 사양이 지속된다면 급성 산중독증을 나타내며 제엽염을 유발한다. 그리고 결국에는 &quot;ski shoe&quot;소를 만든다. 심한 경우에는 반추위가 운동을 하지 않고 맥박과 호흡율이 증가하며 직장의 온도가 일정치 않다. 그리고 눈이 움푹들어가고, 피부의 탄력을 ?으며 비틀거리고, 혼수상태에 빠졌다가 죽는다.</dd>
				</dl>
				
				<dl>
					<dt>질병분포와 손실규모</dt>
					<dd>산중독증은 비육우 또는 젖소 그리고 양 등에 발생하며 특히 농후사료 함량이 높은 사료의 섭취 시에 발생한다. 산중독으로 인한 연간 손실량은 생산량의 약 1%정도 된다.</dd>
				</dl>
				
				<dl>
					<dt>치료</dt>
					<dd>
						<p>다양한 치료방법이 있다.</p>
						<ul>
							<li>1) 가축에 정상적인 사료를 급여하므로서 반추위의 내용물을 바꾸어 준다.</li>
							<li>2) 유산 생성균을 억제하는 항생제를 고수준으로 투여한다.</li>
							<li>3) 중탄산나트륨 용액을 물에 타서 충분한 양을 급여하여 산 -염기 균형을 환원시켜 준다.</li>
							<li>4) 중독과 제엽염 방지를 위하여 항히스타민제와 부신피질 스테로이드를 며칠간에 걸쳐 매일 근육주사한다. 그리고 사료의 종류와 양을 바꾸어준다(사료의 총 급여량을 줄이고 조사료의 급여량을 늘려준다).</li>
						</ul>  
					</dd>
				</dl>
				
				<dl>
					<dt>대책</dt>
					<dd>
						<p>산중독증은 다음의 방법으로 예방할 수 있다.</p>
						<ul>
							<li>1) 가축에 갑작스럽게 많은 양의 농후사료 급여를 피해야 한다.</li>
							<li>2)농후사료의 급여는 점차적으로 늘려나간다.</li>
							<li>3) 중탄산나트륨과 같은 중화제를 사료에 첨가하여 준다.</li>
						</ul>
					</dd>
				</dl>
				
				<dl>
					<dt>예방</dt>
					<dd>조사료 위주의 급여 가축에 사료 변경 시는 서서히 조사료 비율을 줄이고 농후사료를 증사시키도록 하고 부적절한 사양관리는 피하여야 한다</dd>
				</dl>	
				
				<dl>
					<dt>참고</dt>
					<dd>고에너지 사료를 급여하거나 도는 갑자기 사료를 바꾸거나 한 기록이 유지되어 있으면 정확한 진단에 도움을 준다. 반추위의 산중독과 요소 중독 진단 및 구별을 위해 즉흥적인 필드 테스트가 이용될 수 있다. 시료는 stomach tube 또는 죽은 후에 채취한다. 일반적으로 반추위액의 pH가 5.0또는 그 이하이면 산중독에 걸린 것이며 요소나 비단백태질소의 중독시 반추위액의 pH는 7.5이상이다</dd>
				</dl>				
			</div>
  );
}

export default Fo33;
