import React, { useState , useEffect } from 'react';

// api
import { postState } from 'utils/api';
import { openCrownix3 , openCrownix5 , openCrownix } from 'utils/openCrownix';

// 이미지
import PrintPDF from 'assets/svgs/common/print_PDF.svg';
import Logo from 'assets/images/common/header_logo_pc.png';

// 컴포넌트
import ErrorMessage from 'components/errorMessage/errorMessage';

// scss
import './NewCowAnal101.scss';

function NewCowAnal101() {

  // 에러처리
  const [isModalOpen, setIsModalOpen] = useState(false); // 모달 추가
  const [errorMessage, setErrorMessage] = useState(''); 

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => {
    setIsModalOpen(false);
    setErrorMessage(''); // 모달이 닫힐 때 에러메세지 초기화
  };

  // 데이터 불러오기
  const baseDir='/dcitest/dciexpert/newcowanal101/';
  const [ mainData , setMainData ] = useState();
  const [ props , setProps] = useState({
    id:`${localStorage.getItem('cong')}${localStorage.getItem('farm')}`,
    passwd:localStorage.getItem('passwd'),
    date:localStorage.getItem('month')
  });

  useEffect(() => {

    postState(baseDir,props)
    .then(getdata => {      
      if (getdata !== undefined) {
      console.log ('#### props : ' ,props);
      setMainData(getdata);
      console.log ('#### data : ' ,getdata);
      }
    })
    .catch(error => {      
      console.error('Error:', error.message);      
      setErrorMessage(error.message);
      openModal();
    });
    
  }, [props]);
  
  // 페이지 네이션
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = currentPage === 1 ? 11 : 18;

  // Calculate the index range for the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = mainData && mainData.plot_data3.slice(indexOfFirstItem, indexOfLastItem);
  const maxPage = Math.ceil(mainData && mainData.plot_data3.length / itemsPerPage);

  return (
    <div className='pdfMainArea'>
      <ErrorMessage isOpen={isModalOpen} content={errorMessage} closeModal={closeModal}/>
      <div className='pdfMainHeader'>
        <button 
          type='button'
          onClick={()=> openCrownix('New_cow_Anal01.mrd')}
        >
          <img src={PrintPDF} alt='PDF로 인쇄하기' />
          인쇄하기
        </button>
        <div className='pdfPagenation'>
          <p>{currentPage} / {maxPage}</p>
          <button 
            type='button' 
            onClick={() => { 
              if (currentPage !== 1 ) setCurrentPage(currentPage - 1);
            }}
            >이전</button>
          <button 
            type='button' 
            onClick={() => {
              if (currentPage !== maxPage) setCurrentPage(currentPage + 1);
            }}
            >다음</button>
        </div>
      </div>
      <div className='pdfArea'>
        <div className='newCowAnal101Pdf'>
          <p className="standardMonth">기준월 : {props.date}</p>
          <ul className='newCowAnal101Info'>
            <li>
              농가명 : {mainData && mainData.farm_property[0].fname}
            </li>
            <li>
              축주명 : {mainData && mainData.farm_property[0].fdname}
            </li>
            <li>
              주소 : {mainData && mainData.farm_property[0].fadd}
            </li>
          </ul>
          {
            currentPage === 1 &&
            <>
              <h1>{mainData && mainData.farm_property[0].fname}목장 경제수명 분석</h1>
              <table className='pdfTable titleTable'>
                <thead>
                  <tr>
                    <th>전국평균</th>
                    <th>두수</th>
                    <th>도태산차</th>
                    <th>착유두수</th>
                    <th>305일유량</th>
                    <th>2산생존율(%)</th>
                    <th>3산생존율(%)</th>
                    <th>4산생존율(%)</th>
                    <th>산차지수</th>
                    <th>4산달성율(%)</th>
                    <th>두당수익(천원)</th>
                  </tr>              
                </thead>
                <tbody>
                  {
                    mainData &&
                    mainData.plot_data.map((item,index) => (
                      <tr key={index}>
                        <td>{item.farm_name}</td>
                        <td className='numberData'>{item.cow.toLocaleString()}</td>
                        <td className='numberData'>{item.median}</td>
                        <td className='numberData'>{item.mcowno}</td>
                        <td className='numberData'>{item.avm305.toLocaleString()}</td>
                        <td className='numberData'>{item.p2}</td>
                        <td className='numberData'>{item.p3}</td>
                        <td className='numberData'>{item.p4}</td>
                        <td className='numberData'>{item.index1}</td>
                        <td className='numberData'>{item.index2}</td>
                        <td className='numberData'>{item.incom.toLocaleString()}</td>
                      </tr>
                    )
                    )
                  }
                </tbody>
              </table>
              <p className="summary">
                1. 산차지수 : 50두의 착유소가 4산까지 마칠 경우를 (50두×4산 = 200산) 목표치로 설정시 목장별 실제 달성 가능 산차를 계산한 지수값<br/>
                2. 4산달성율 : 목장내 착유소가 4산까지 도달할 확률 <br/>
                3. 두당수익 : 유대(1,000원)×착유일수(400일)×실수익(40%)을 가정
              </p>
            </>
          }
          
          <h1>{mainData && mainData.farm_property[0].fname}목장 최근 3년간 도태우 분석</h1>
          <table className="pdfTable">
            <thead>
              <tr>
                <th rowSpan="3">구분</th>
                <th rowSpan="3">산차</th>
                <th colSpan="3">도태시기</th>
                <th colSpan="4">번식장애</th>
                <th colSpan="5">대사성 장애</th>
                <th colSpan="5">유방장애</th>
                <th colSpan="3" rowSpan="2">도태정보</th>
                <th colSpan="3" rowSpan="2">분만내역</th>
              </tr>
              <tr>
                <th colSpan="3">분만 후 일수</th>
                <th colSpan="4">일</th>
                <th colSpan="5">일, 유량(M), 지방(F), 단백(P)</th>
                <th colSpan="5">체세포(만cell), 횟수</th>
              </tr>
              <tr>
                <th>~120</th>
                <th>120~ 280</th>
                <th>280~</th>
                <th>공태 220~</th>
                <th>착유500~ 수정5회~</th>
                <th>초종부 150~</th>
                <th>분만간격 500~</th>
                <th>~70 P/F역조</th>
                <th>~70F ＜3.0, P＜2.2</th>
                <th>~70 M*F＞2.0</th>
                <th>~120F＜3.3</th>
                <th>~120 CVM＞20</th>
                <th>평균 40~, 50~ (5회~)</th>
                <th>~120일, 50~</th>
                <th>120~ 280일, 50~</th>
                <th>~280일, 50만</th>
                <th>체세포 평균</th>
                <th>도태 사유</th>
                <th>도태일</th>
                <th>분만후 도태일수</th>
                <th>분만일</th>
                <th>분만난이</th>
                <th>분만간격</th>
              </tr>
            </thead>
            <tbody>
            {
                currentItems &&
                currentItems.map((item,index) => (
                    <tr key={index}>
                      <td>
                        <button
                          type='button'
                        onClick={() => openCrownix5('New_cow_Anal01_all.mrd', item.cowid)}
                      >
                        {item.sname}
                      </button>
                    </td>
                    <td>
                      <button
                        type='button'
                        onClick={() => openCrownix3('New_sang_jcode.mrd', item.cowid)}
                      >
                        {item.pari}
                      </button>
                    </td>
                    <td>{item.p_init01}</td>
                    <td>{item.p_init02}</td>
                    <td>{item.p_init03}</td>
                    <td>{item.p_rep01}</td>
                    <td>{item.p_rep02}</td>
                    <td>{item.p_rep03}</td>
                    <td>{item.p_rep04}</td>                    
                    <td>{item.p_met01}</td>
                    <td>{item.p_met02}</td>
                    <td>{item.p_met03}</td>
                    <td>{item.p_met04}</td>
                    <td>{item.cvmilk120}</td>
                    <td>{item.p_scc}</td>
                    <td>{item.scc501}</td>
                    <td>{item.scc502}</td>
                    <td>{item.scc503}</td>
                    <td>{item.sccavg}</td>
                    <td className='tdBorderLeft'>{item.description}</td>
                    <td>{item.qdate}</td>
                    <td>{item.ddate}</td>
                    <td>{item.bundate}</td>
                    <td>{item.cdiff}</td>
                    <td>{item.tintday.toLocaleString()}</td>

                  </tr>
                ))
              }
            </tbody>
          </table>
          <img src={Logo} alt='농협경제지주 젖소개량사업소 로고' className='pdfLogo'/>
        </div>
      </div>
    </div>  
  );
}

export default NewCowAnal101;
