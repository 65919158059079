import React from 'react';

import pic720 from 'assets/images/sub/_images/pic720.jpg';
import pic721 from 'assets/images/sub/_images/pic721.jpg';
import pic722 from 'assets/images/sub/_images/pic722.jpg';

function Do149() {

  return (
    <div className="techinfoview">
      <h1>광선과민증</h1>
      <p className="pstyle">광선 과민물질이 피부에 침착(沈着)되어 있는 소가 방목 또는 옥외 사육에서 햇빛에 노출될 때 백모(白毛)부분의 피부가 가렵고 충혈되어 점차 진물(염증산물)이 스며나와 환부의 피부가 괴사, 탈락하는 피부병을 말한다.</p>
      <div className="leftpart">
        <h2>증상</h2>
        <ul>
          <li>피부병은 백모(白毛)부위에만 나타나며 초기에는 (발병후 1∼2일째)벌겋게 부어 오르며 만지면 통증을 보인다.</li>
          <li> 병세가 더욱 심해지면(발병후 3∼7일째)피부 깊숙한 조직이 괴사된다.</li>
          <li>증상이 가볍게 지나가는 예에서는 표피가 약간씩 탈락하며 회복한다.</li>
          <li>증상이 심할때는 피부가 검붉어지고 딱딱해져 판자처럼 탈락하며 출혈이 있기도 하지만 결국에는 회복된다.</li>
        </ul>
        <h2>목장에서의 처치</h2>
        <ul>
          <li>발병하면 그 즉시 우사내로 옮기고 물을 충부니 급여하고 사료를 청초에서 건초로 바꾼다.</li>
          <li>화상과 똑같이 취급하여 피부에는 바세린 연고 등을 바른다.</li>
          <li> 다른 소에 전염되는 일이 없다. 방목지에 그늘을 만들어 주고 병우는 일광이 닿지 않은 우사내에서 사양관리 하는 것이 예방의 찻길이다.</li>
        </ul>
      </div>
      <div className="rightpart">
        <img src={pic720} className="nonborder" alt=""/>
        <img src={pic721} className="nonborder" alt=""/>
        <img src={pic722} className="nonborder" alt=""/>
      </div>
    </div>
  );
}

export default Do149;