import React from 'react';
import { HISTORY } from 'constants/constants';

// 연혁 랜더링 함수
// constants.js 에 있는 HISTORY 와 일치하는 년도를 입력하면 해당 년도가 렌더링됩니다.

export default function renderHistory(currentYear) {
  return (
    <div className={`historyCon _${currentYear.split('~').join('')}`}>
      <p className='titleYear'>{currentYear}</p>
      {
        HISTORY[currentYear] &&
        HISTORY[currentYear].map((yearList) => (
          <dl key={yearList.year}>
            <dt>{yearList.year}</dt>
            {
              yearList.list.map((monthList) => (
                <dd key={monthList.info}><span>{monthList.month}.</span><p>{monthList.info}</p></dd>
              ))
            }
          </dl>
        ))
      }
    </div>
  );
}