import React from 'react';

import Pic785 from 'assets/images/sub/_images/pic785.jpg';
import Pic786 from 'assets/images/sub/_images/pic786.jpg';
import Pic787 from 'assets/images/sub/_images/pic787.jpg';
import Pic788 from 'assets/images/sub/_images/pic788.jpg';
import Pic789 from 'assets/images/sub/_images/pic789.jpg';
import Pic790 from 'assets/images/sub/_images/pic790.jpg';
import Pic791 from 'assets/images/sub/_images/pic791.jpg';
import Pic792 from 'assets/images/sub/_images/pic792.jpg';
import Pic793 from 'assets/images/sub/_images/pic793.jpg';
import Pic794 from 'assets/images/sub/_images/pic794.jpg';
import Pic795 from 'assets/images/sub/_images/pic795.jpg';
import Pic796 from 'assets/images/sub/_images/pic796.jpg';
import Pic797 from 'assets/images/sub/_images/pic797.jpg';
import Pic798 from 'assets/images/sub/_images/pic798.jpg';
import Pic799 from 'assets/images/sub/_images/pic799.jpg';

function Fo1() {

  return (
    <div className="techinfo_view">
				<p className="viewTitle">낙농현황과 젖소개량의 의미</p>
				<h2>낙농가 호수와 사양두수</h2>
				<p>[그림3]은 낙농가 호수, 유우사양두수(주3) 및 원유생산량의 추이를 보인 것이다. 소위 말하는 유축농업의 진흥에 의하여 &quot;젖소를 사양하는 농가&quot;인 낙농가는 가장 피크에 달했던 1963년에는 42만호까지 기록되었다. 그러나 경종농업에 있어서 기계화와 화학비료의 보급 등에 따라 감소하기 시작한 것이 1997년에는 4만호로 줄었다.</p>
				<p>사양두수는 오일 숏크에 의한 사료가격 앙등 등에 의하여 1970년대의 전반기에 일시 감소했던 것이, 후반기에는 증가하는 기조로 되었다. 그러나 그 시기에는 생산이 수요에 따라 갔고, 1979년도에 계획생산이 개시하기에 이르렀고, 그후는 단기적인 증감을 반복하면서, 점증되는 추이를 보였다.</p>
				<p>(표 3)은 최근 10년간의 변화를 표시해 본 것이다. 낙농가호수는 북해도에서 년 3.9%, 도부현(道府懸)에서 년 7.0% 감소하고 있다. 성축사양두수는 북해도에서 30.4두에서 49.6두로, 도부현에서 15.8두에서 27.3두로 각각 증가하고 있다.</p>
				<p className="mg30f">이와 같은 상황중 원유생산량은 북해도에서 년 3.1%. 사양두수가 감소하고 있는 도부현에서도 년 0.8% 증가하고 있다. </p>
				
				<div className="imgBox"><img src={Pic785} alt=''/></div>
				<div className="imgBox w100p"><img src={Pic786}  alt=' '/> <img src={Pic787} className="mg20l" alt=' '/></div>
				
				<div className="tableWrap mg60f">
				<table className="tablestyle">
					<caption>(표3) 최근 10년간에 있어서 낙농가 호수, 사양두수 변화(일본) </caption>
					<thead>
						<tr>
							<th colSpan="2" className="first">구분</th>
							<th>낙농가호수</th>
							<th>성축사양두수(천두)</th>
							<th>1호당</th>
							<th>원유생산량(천톤)</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td rowSpan="3">전 국</td>
							<td>1987년</td>
							<td>74,500</td>
							<td>1,417</td>
							<td>19.0</td>
							<td>(1986년도) 7,361</td>
						</tr>
						<tr>
							<td>1997년</td>
							<td>39,400 </td>
							<td>1,320</td>
							<td>33.5</td>
							<td>(1996년도) 8,658</td>
						</tr>
						<tr>
							<td>년율(%)</td>
							<td>△6.2</td>
							<td>△0.7 </td>
							<td>+5.9</td>
							<td>+1.6</td>
						</tr>
						<tr>
							<td rowSpan="3">북해도</td>
							<td>1987년</td>
							<td>16,300</td>
							<td>496</td>
							<td>30.4</td>
							<td>(1986년도) 2,617</td>
						</tr>
						<tr>
							<td>1997년도</td>
							<td>11,000</td>
							<td>546</td>
							<td>49.6</td>
							<td>(1996년도) 3,539</td>
						</tr>
						<tr>
							<td>년율(%)</td>
							<td>△3.9</td>
							<td>+1.0 </td>
							<td>+5.0</td>
							<td>+ 3.1</td>
						</tr>
						<tr>
							<td rowSpan="3">도부현</td>
							<td>1987년</td>
							<td>58,200 </td>
							<td>921 </td>
							<td>15.8</td>
							<td>(1986년도) 4,774</td>
						</tr>
						<tr>
							<td>1997년도</td>
							<td>28,300</td>
							<td>773</td>
							<td>27.3</td>
							<td>(1998년도) 5,119</td>
						</tr>
						<tr>
							<td>년율(%)</td>
							<td>△7.0</td>
							<td>△1.7</td>
							<td>+5.6</td>
							<td>+0.8</td>
						</tr>
					</tbody>
				</table>
				</div>
				
				<h2>경영형태와 규모</h2>
				<p>(표 4)는 낙농가의 경영형태별의 비율을 표시한 것이다. 전업농가중 낙농단일경영(농업수입에서 점유하는 낙농비율이 80%이상의 경영)은 겅의 낙농을 생업으로 하고 있다. 이하 여기서는 이러한 낙농가를 &quot;전업적 경영&quot;이라 부른다.</p>
				<p className="mg30f">낙농은, 예를 들면 1두가 있어도 아침 저녁의 착유라든지 사료급여 등의 작업으로 년중 구속된다. 거기에 더하여 착유기계나 우사 등에 많은 액수의 투자를 요하는 것부터 일정 규모가 아니면 효율이 오르지 않는다. 또한 북해도에서는 경종농업이 어려운 지역으로서의 발전 배경도 있다.</p>

				<table className="tablestyle">
					<caption>(표 4) 경영형태별로 본 낙농가의 비율 [1993년 일본]</caption>
					<thead>
						<tr>
							<th className="first">구 분</th>
							<th>전업농가</th>
							<th>낙농단일경영 (전업적 경영)</th>
							<th>제 1종겸업농가</th>
							<th>낙농단일경영</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>전 국</td>
							<td>68.9</td>
							<td>57.1</td>
							<td>24.4</td>
							<td>16.4</td>
						</tr>
						<tr>
							<td>북 해 도</td>
							<td>88.9</td>
							<td>74.3</td>
							<td>11.0</td>
							<td>7.8</td>
						</tr>
						<tr>
							<td>도 부 현</td>
							<td>61.6</td>
							<td>50.6</td>
							<td>29.5</td>
							<td>19.6</td>
						</tr>
					</tbody>
				</table>
				<ul className="refer mg10t mg30f">
					<li>(자료) 농림수산성 &quot;농업구조동태조사-낙농부문구조&quot;</li>
				</ul>
				
				<p>그 때문에 전업화가 진전되어 1984년에는 전국 낙농가의 반수가 전업적 경영으로 되었는데, 1993년에는 북해도의 약 3/4, 도부현에서도 반수이상이 전업적 경영으로 되어 있다. 즉 거의 절반 이상의 낙농가가 낙농으로 생계를 유지해 나가고 있어, 이를 위하여 필요한 경영규모를 가져가고 있다.</p>
				<p>전업적 경영의 규모는 가족구성과 노동력 등에 딸라 폭이 넓은데, 도부현의 경우에는 경영주가 고령인 낙농가를 중심으로하여 비교적 적은 경우가 적지 않다. 그러나 절반 이상은 사양두수가 성축으로 30두 이상 이다.</p>
				<p>농업에서 번번히 쓰여지고 있는 &quot;규모확대&quot;가 생산성 향상을 위하여 전업 경영에 필요한 경영규모로 확대하므로서, 결국 선진제국 정도의 규모에 근접하는 것을 의미한다고 하면 낙농은 이미 최초단계를 벗어나고 있는 것이다. 그런데, 방목중심의 호주, 뉴질랜드라든지, 기업적 경영이 증가하는 미국 등에는 미치지 못하지만, 유럽의 대부분 나라를 추월하고, 북해도만을 보면 조건이 매우 비슷한 유럽 제 2위에 있는 화란이나 카나다 정도까지 도달하고 있다.</p>
				<p>농업의 생산성 향상에는 크게는 단수의 개선(단위당 생산성 향상)과 규모 혹대에 의한 스케일 메릿트 추구의 두가지 길이 있겠다. 이에 비하여 낙농의 경우에는 후에 서술할 낙농가 분포를 보면 단수개선, 즉 1두당 생산성 향상의 여지가 아직도 있다고 생각된다.</p>
				<p className="mg30f">한편, 규모확대에 따라 전업적 경영에서는 노동력에 변화가 없는 한, 다액의 투자를 동반하는 성력적 사양관리 방식으로의 변경이 필요하다고 생각된다. 이와 더불어 성축사양두수 30∼49도 규모 낙농가의 착유방식은 약 50%가 파이프라인 방식으로 하고 있으나, 80두이상으로 되면 밀킹파라방식이 30%에 가깝다. </p>
				
				<div className="tableWrap">
				<table className="tablestyle">
					<caption>(표 5) 주요 낙농국의 생산구조 (1995년) </caption>
					<thead>
						<tr>
							<th className="first"> </th>
							<th>일 본</th>
							<th>북해도</th>
							<th>미 국</th>
							<th>캐나다</th>
							<th>영 국</th>
							<th>화 란</th>
							<th>호 주</th>
							<th>뉴질랜드</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<th>낙농가호수(호)</th>
							<td>40,300</td>
							<td>11,000</td>
							<td>140,090</td>
							<td>25,700</td>
							<td>27,192</td>
							<td>37,465</td>
							<td>14,166</td>
							<td>14,700</td>
						</tr>
						<tr>
							<th>경산우사양두수(천두)</th>
							<td>1,211</td>
							<td>487</td>
							<td>9,461</td>
							<td>1,279</td>
							<td>2,107</td>
							<td>1,708</td>
							<td>1,877</td>
							<td>2,904</td>
						</tr>
						<tr>
							<th>1호당(두)</th>
							<td>30.0</td>
							<td>44.3</td>
							<td>67.5</td>
							<td>49.8</td>
							<td>77.5</td>
							<td>45.6</td>
							<td>132.5</td>
							<td>197.0</td>
						</tr>
					</tbody>
				</table>
				</div>
				<ul className="refer mg10t mg30f">
					<li>(자료) ICAR 조사연보(1996), 단 호주는 ADC &quot; Dairy Compendium&quot; </li>
				</ul>
				
				<div className="tableWrap">
				<table className="tablestyle">
					<caption>(표 6) 경영주의 년령과 후계자의 유무별로 본 낙농가 비율(1993년, 일본) </caption>
					<thead>
						<tr>
							<th rowSpan="2" className="first">성축사양두수</th>
							<th colSpan="4">경영주의 연령과 후계자 유무</th>
							<th rowSpan="2">경영주가 50세미만 또는 후계자 유</th>
						</tr>
						<tr>
							<th>40세미만</th>
							<th>40-49세</th>
							<th>50-59세(이중후계자유)</th>
							<th>60세이상(이중후계자유)</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>30두미만</td>
							<td>12.1</td>
							<td>23.7</td>
							<td>30.9(8.4)</td>
							<td>33.3(8.8)</td>
							<td>54.3</td>
						</tr>
						<tr>
							<td>30-49두</td>
							<td>26.1</td>
							<td>33.7</td>
							<td>27.4 (16.0)</td>
							<td>12.7(8.9)</td>
							<td>84.7</td>
						</tr>
						<tr>
							<td>50두이상</td>
							<td>28.9</td>
							<td>35.5</td>
							<td>26.3 (20.6)</td>
							<td>9.2(8.3)</td>
							<td>93.3</td>
						</tr>
						<tr>
							<td>계</td>
							<td>18.8</td>
							<td>28.4</td>
							<td>29.2 (12.5)</td>
							<td>23.5(8.8)</td>
							<td>68.5</td>
						</tr>
					</tbody>
				</table>
				</div>
				<ul className="refer mg10t mg30f">
					<li>(자료) 농림수산성 &quot;농업구조동태조사 - 낙농부문구조&quot;</li>
				</ul>
				
				<p>이러한 것들을 종합해서 생각하면 전업적 경영에 의한 규모확대는 생산성 향상의 수단이라 하기보다는, 생산성 향상보다 앞서서 생각해야할 사항이며, 경영의 새로운 발전수단으로서 채택되어야 하는 것이 아닌가 한다.</p>
				<p>다시 (표 6)은 경영주의 년령과 후계자의 유무별로 낙농가의 비율을 본 것이다. 경영주가 50세미만이나, 50세이상이라도 후계자가 결정되어 있는 낙농가의 비율은 성축사양두수 30만두미만에서는 54.3%이나, 전업적 경영이 대부분을 차지하는 30-49두 규모에서 84.7%에 달하고, 50두이상에서는 93.3%가 되고 있다.</p>
				<p className="mg60f">1두당 생산성 향상에는 여러 가지 기술의 향상이 불가결하다고 생각되나, 낙농은 타작목에 비하면 전업적 경영이 많고, 경영주도 젊고 후계자도 있다. 그 때문에 대응은 충분히 가능하다고 생각된다. </p>
				
				<h2>젖소능력</h2>
				<p>(그림4)는 경산우 1두당 년간 유량과 유지율의 추이를 표시한 것이다. 수급이나 기상조건 등의 영항에 의하여 약간의 요철은 있으나, 유량, 유지율 다 함께 착실히 신장하고 있다. 최근 10년간을 보아도 유량이 5,677에서부터 7,170kg로, 유지율이 3.65%에서 3.84%로 증가 및 상승하고 있다. 이런 신장의 배경에는 뒤에 서술하는 것과 같이 젖소의 유전적 능력의 향상과 사양관리의 개선이라는 양면의 조화가 있었다. 젖소의 생산성에 관여하는 형질로서는 비유이외에, 강건성이나 번식성등이 중요하다.(표7)</p>
				<p>최근, 유량의 증가가 현저한 한편, 그것을 뒷받침하는 지제라든지 유기의 개량이 반듯이 충분하지 않았다든지 원유수집에 있어서 유질기준의 강화 등에 의하여 젖소의 공용년수에 단축이 눈에 뜨인다. 폐용되는 산차는 분명하지 않지만, 착유우의 평균 산차를 보면, 1986년도의 3.0산에서 1996년도에는 2.7산으로 저하되고 있다. 쇠고기의 수입자유화에 따라, 젖소폐우 가격의 저하에 의하여 생산비에서 차지하는 젖소상각비가 증대되고 있는 것도 있어서 금후의 생산성 향상을 위해서는 공용년수의 연장이 큰 과제가 되고 있다.</p>		 
				<div className="imgBox"><img src={Pic788} alt=' '/></div>
				 
				<table className="tablestyle">
					<caption>(표 7) 최근 10년간 우군검정우의 평균산차와 번식성적의 변화(일본)</caption>
					<thead>
					 	<tr>
					 		<th className="first">구 분</th>
					 		<th>1986년도</th>
					 		<th>1996년도</th>
					 	</tr>
					 </thead>
					 <tbody>
					 	<tr>
					 		<th>평 균 년 령</th>
					 		<td>4.6세</td>
					 		<td>4.2</td>
					 	</tr>
					 	<tr>
					 		<th>평 균 산 차</th>
					 		<td>3.0산</td>
					 		<td>2.7</td>
					 	</tr>
					 	<tr>
					 		<th>초 산 월 령</th>
					 		<td>28월</td>
					 		<td>27</td>
					 	</tr>
					 	<tr>
					 		<th>미경산우 초회 수정월령</th>
					 		<td>17월</td>
					 		<td>16</td>
					 	</tr>
					 	<tr>
					 		<th>분만후 초회 수정일수</th>
					 		<td>90일</td>
					 		<td>94</td>
					 	</tr>
					 	<tr>
					 		<th>수태에 요하는 수정회수 </th>
					 		<td>1.9회</td>
					 		<td>2.1</td>
					 	</tr>
					 	<tr>
					 		<th>분 만 간 격</th>
					 		<td>402일</td>
					 		<td>418</td>
					 	</tr>
					 	<tr>
					 		<th>공 태 일 수</th>
					 		<td>134일</td>
					 		<td>146</td>
					 	</tr>
					 	<tr>
					 		<th>착 유 일 수</th>
					 		<td>331일</td>
					 		<td>347</td>
					 	</tr>
					 	<tr>
					 		<th>건 유 일 수</th>
					 		<td>71일</td>
					 		<td>71</td>
					 	</tr>
				 	</tbody>
				 </table>
				 <ul className="refer mg10t mg30f">
					<li>(자료) (사) 가축개량사업단 &quot;유용우군 능력검정성적 종합&quot;</li>
				</ul>
				 
				 <p>또한, 번식성에 대하여는 초산월령이 착실히 개선되고 있으나, 수태에 요하는 수정회수 등이 나빠지고 있고 분만간격은 신장되고 있는 경향에 있다. 유량이 증대한 지금에 와서는 착유 10개월, 건유 2개월 약 1년에 1산까지는 가지 않더라도 &quot;개량증식목표&quot;에 제시된 13개월 정도까지는 개선할 필요가 있겠다.</p>
				 <p className="mg30f">육성기간이나 건유기간(혹은 원래 불필요한 비유말기의 저유량기간)은 비생산기간에 해당되므로 그의 단축은 공용년수의 연장과 함께 중요한 과제이다. </p>
				 <div className="imgBox mg60f"><img src={Pic789} alt=' '/></div>
				 
				 <h2>생산비</h2>
				 <p>일본에서는 농림수산성의 &quot;생산비 조사&quot;에 의하여 전국적인 생산비가 계속적으로 파악되고 있다. 생산비에 관한 것에 대한 전국적인 조사는 다른 여러 외국에서는 거의 볼 수가 없는 일이다.</p>
				 <p>생산비 조사에는 그림 5와 같이 생산비를 노동비, 물재비, 이자, 토지이용료로 기록되는데, 노동비와 물재비의 계를 비용합계로 정의하고 있다. &quot;낙육기본방침&quot;을 포함한 생산비라고 하면 이것의 비용합계를 지칭하는 경우가 많다.</p>
				 <p>노동비는, 가족노동의 평가액인 가족노동비와 헬퍼나 연수생 등을 포함한 고용노동에 대한 가치인 고용노동비로부터 구성된다.</p>
				 <p>물재비는 종부료, 사료비, 깔집대, 광열수도료, 동력비, 기타 제재로비, 수의사·의약품비, 임차료·요금, 물건세·공과제부담, 젖소상각비, 건물비, 농기구비, 생산관리비로 구성되어 있다. 이 가운데, 젖소상각비는 경산우에는 자가육성 및 도입의 경우가 있기 때문에, 초산분만시의 평가액에 대한 감가상각비와 폐용시의 상각잔액으로 계산된다. 또한 건물비와 농기구비로서는 감가상각비(내용년수가 설정된 것) 또는 구입비와 수선에 요하는 경비가 계상되어 있다.</p>
				 <p>이자·지대는 자기자본이나 자작지에 대한 평가액과 차입금이나 농지임대금에 대하여 실제로 지불한 부분(지불한 이자·지대)으로부터 이루어진다.</p>
				 <p>비용 합계 가운데 가족 노동비는 소득의 일부이다. 그 때문에 비용합계로부터 가족노동비를제외한 액(=고용노임+물재비)에다가 지불한 이자·지대를 가산할 것이 말하자면 지출되는 경비로 보는 것이다. 이하 이글에서는 이렇게 지출되는 부분을 &quot;실비&quot;라고 칭하기로 한다. 유대(송아지의 판매상당액을 포함)로부터 이 &quot;실비&quot;를 빼고 남는 것이 소득이 된다.</p>
				 <p>그런데, 생산비조사는 유지율 3.5% 환산 유량으로 집계된 것으로 원유 1kg이란 환산유량 1kg을 의미한다는 것에 주의해 주기 바란다.</p>
				 <p className="mg30f">(표 8)은 원유1kg당 원유생산비에 대한 최근 10년간의 변화를 표시한 것이다. 경산우 1두당 유량의 증가든지, 엥고에 따라 과반수를 점하고 있는 사료비가 감소된 것 등에 의하여 비용합계에서 약 10%, 실비에서 약 15% 저하되고 있다. 비용합계의 저하율이 낮은 것은, 노동시간의 단축을 사회하는 정도로 노임단가가 상승(1.4배)하므로서 노동비가 증가한 것이 큰 비중을 차지하고 있다.</p>
				 
				 <div className="tableWrap">
				 <table className="tablestyle">
					<caption>(표 8) 최근 10년간 원유 1kg(유지율 3.5% 환산)당 생산비의 변화 (일본)</caption>
					<thead>
					 	<tr>
					 		<th rowSpan="2" colSpan="2" className="first">구분</th>
					 		<th colSpan="5">비용합계</th>
					 		<th rowSpan="2">실비</th>
					 	</tr>
					 	<tr>
					 		<th> </th>
					 		<th>노동비</th>
					 		<th>사료비</th>
					 		<th>젖소상각비</th>
					 		<th>기타</th>
					 	</tr>
					 </thead>
					 <tbody>
					 	<tr>
					 		<td rowSpan="3">전 국</td>
					 		<td>1986년</td>
					 		<td>83.2</td>
					 		<td>22.6</td>
					 		<td>43.9</td>
					 		<td>5.8</td>
					 		<td>10.9</td>
					 		<td>63.5</td>
					 	</tr>
					 	<tr>
					 		<td>1996년</td>
					 		<td>74.9</td>
					 		<td>23.4</td>
					 		<td>30.9</td>
					 		<td>9.0</td>
					 		<td>11.6</td>
					 		<td>53.8</td>
					 	</tr>
					 	<tr>
					 		<td>증감액 </td>
					 		<td>△8.3</td>
					 		<td>+0.8</td>
					 		<td>△13.0</td>
					 		<td>+3.2</td>
					 		<td>+0.7</td>
					 		<td>△9.7</td>
					 	</tr>
					 	<tr>
					 		<td rowSpan="3">북 해 도</td>
					 		<td>1986년</td>
					 		<td>72.4</td>
					 		<td>17.2</td>
					 		<td>39.9</td>
					 		<td>5.5</td>
					 		<td>9.8</td>
					 		<td>59.6</td>
					 	</tr>
					 	<tr>
					 		<td>1996년</td>
					 		<td>64.4</td>
					 		<td>19.0</td>
					 		<td>26.3</td>
					 		<td>8.5</td>
					 		<td>10.6</td>
					 		<td>47.9</td>
					 	</tr>
					 	<tr>
					 		<td>증감액 </td>
					 		<td>△8.0</td>
					 		<td>+1.8</td>
					 		<td>△13.6</td>
					 		<td>+3.0</td>
					 		<td>+0.8</td>
					 		<td>△11.7</td>
					 	</tr>
					 	<tr>
					 		<td rowSpan="3">도 부 현</td>
					 		<td>1986년</td>
					 		<td>89.2</td>
					 		<td>25.6</td>
					 		<td>46.0</td>
					 		<td>6.0</td>
					 		<td>11.5</td>
					 		<td>65.4</td>
					 	</tr>
					 	<tr>
					 		<td>1996년</td>
					 		<td>82.4</td>
					 		<td>26.5</td>
					 		<td>34.2</td>
					 		<td>9.4</td>
					 		<td>12.3</td>
					 		<td>57.9</td>
					 	</tr>
					 	<tr>
					 		<td>증감액 </td>
					 		<td>△6.8</td>
					 		<td>+0.9</td>
					 		<td>△11.8</td>
					 		<td>+3.4</td>
					 		<td>+0.8</td>
					 		<td>△7.5</td>
					 	</tr>
					 </tbody>
				</table>
				</div>
				<ul className="refer mg10t mg30f">
					<li>(주) 법정내용년수나 집계방법의 변경이 있었기 때문에 엄밀하게 비교는 안됨</li>
					<li>(자료) 농림 수산성 &quot;축산물생산비조사&quot; (일부추계)</li>
				</ul>
				 
				<p className="mg60f">내역을 보면, 쇠고기의 수입자유화에 따라 폐우가격의 저하나 내용년수의 단축에 의하여 젖소상각비가 1kg당 3엔 가량 높아졌다. 가장 많이 증가된 1994년에는 11엔(5엔높음)을 점유한 경우도 있어, 생산비의 저감을 위해서는 젖소상각비의 개선도 큰 과제로 생각된다. </p>
				 
				<p className="viewTitle">낙농경영의 현황과 전개방향</p>
				<h2>사양두수와 생산비, 소득, 노동시간의 관계</h2>
				<p className="mg30f">&lt;그림 6&gt;은 &quot;생산비조사&quot;의 조사농가에 대하여 횡축(옆으로된 선)에 경산우 사양두수, 종축(수직으로된 선)에 원유1kg당 실비를 나타내어, 그 분포를 본 것이다. 사양두수가 증가함에 따라 실비가 높은 낙농가는 감소하는 경향이 보이나 변폭이 매우 커서 전업적 경영이 일반적 규모로 되어 있는 북해도의 40∼50두층, 도부현의 30∼40두층을 보아도, 각각 35∼95엔, 35∼90엔의 광범위한 분포를 하고 있음을 엿볼 수 있다.</p>
				<div className="imgBox"><img src={Pic790} alt=' '/></div>
				
				<p className="mg30f">&lt;그림 7&gt;은 종축에 년간 소득을 넣고, 분포를 본 것이다. 상단은 경산우 1두당, 하단은 낙농가 1호당의 소득을 표시하고 있다. 경산우 1두당 년간소득과 사양두수간에는 거의 관계가 없고 전체적으로 상당히 변폭이 있는 것을 볼 수 있다. 그 때문에 1호당으로는 오른쪽으로 올라가고 있는 것, 즉 예를 들면 1천만엥 전후의 소득을 올리고 있는 낙농가의 사양두수는 북해도가 30∼90두, 도부현은 20∼60두로 상당한 폭이 있다.</p>
				<div className="imgBox"><img src={Pic791} alt=' '/> <img src={Pic792} className="mg20l" alt=' '/></div>
				
				<p className="mg30f">&lt;그림 8&gt;은 종축에 경산우 1두당 사양관리에 요하는 년간 노동시간(사료생산이나 포육 및 육성 등을 포함하지 않음)을 놓고서 분포를 본 것이다. 사양두수가 증가함에 따라 노동시간은 단축되는 경향이 있으나, 20∼30두 이상이 되면 두수에 관계없이 60∼70시간이 최저 수준이 되고 있다. 그 때문에 이 수준이 종래의 사양관리방식에 대한 노동시간의 한계라는 것을 알 수 있다. 그림 가운데는 북해도의 120두 규모의 낙농가는 밀킹파라방식을 이용하고 있다고 보이나, 이 경여에서는 50시간으로 한정되고 있다.</p>
				<div className="imgBox"><img src={Pic793} alt=' '/></div>

				<h2>경영내용의 파악상황</h2>
				<p className="mg30f">&lt;표 9&gt;는 1995년도에 전 낙농가를 대상으로 실시한 앙케이트(낙농전국기초조사)에 있어서, 경영의 기장(記帳)상황을 질문한 결과이다. 50%의 낙농가가 청색 신고 가능한 기장을 실시하고 있는 한편, 20%이상의 낙농가는 입출하에 대한 기록조차 하고 있지 않음을 알 수 있다.</p>			 				
 				<div className="tableWrap mg30f">
 				<table className="tablestyle">
 					<caption>〈표 9〉경영기장의 상황에 관한 앙케이트(1995년, 일본) </caption>
 					<thead>
	 					<tr>
	 						<th rowSpan="2" className="first">구 분</th>
	 						<th rowSpan="2">회답낙농가호수</th>
	 						<th colSpan="5">회 답 별 비 율(%)</th>
	 					</tr>
	 					<tr>	
	 						<th>대차대조표의 <br/>작성가능한 기장</th>
	 						<th>청색신고·손익계산서 <br/>작성이 가능한 기장</th>
	 						<th>입출하에 대한 <br/>간단한 기록</th>
	 						<th>특히 아무기록도 <br/>하지 않는 경우</th>
	 						<th>무 회 답</th>
	 					</tr>
	 				</thead>
	 				<tbody>
	 					<tr>
	 						<td>전 국</td>
	 						<td>30,285</td>
	 						<td>5.2</td>
	 						<td>44.9</td>
	 						<td>23.7</td>
	 						<td>22.7</td>
	 						<td>3.5</td>
	 					</tr>
	 					<tr>
	 						<td>북해도</td>
	 						<td>5,876</td>
	 						<td>5.2</td>
	 						<td>41.1</td>
	 						<td>25.5</td>
	 						<td>25.5</td>
	 						<td>2.7</td>
	 					</tr>
	 					<tr>
	 						<td>도부현</td>
	 						<td>24,409</td>
	 						<td>5.2</td>
	 						<td>45.9</td>
	 						<td>23.3</td>
	 						<td>22.0</td>
	 						<td>3.7</td>
	 					</tr>
 					</tbody>
 				</table>		
 				</div>		
				<p className="mg60f">&lt;표 10&gt;은 같은 모양으로 원유1kg당 생산비를 파악하고 있는지 않는지를 질문한 결과이다. &quot;파악하고 있다.&quot;로 회답한 낙농가는 9.5%,&quot;현금 수지 계산이면 파악하고 있다&quot;로 회답한 낙농가는 11.2%로 되어 있다. 〈표 9〉와 같이 50%의 낙농가가 청색신고가 가능한 기장을 하고 있기 때문에 나머지의 79.3%의 낙농가 대부분이 가계와의 &quot;혼합계산&quot;이라는 것은 아니지만, 적어도 원유1kg당 생산비까지는 파악하고 있지 않다는 결론이 된다. </p>

				<h2>경영전개방향</h2> 
				<p className="mg30f">&lt;그림 9&gt;는 &lt;그림 6&gt;의 북해도 낙농가의 분포에 소득수준을 나타내는 곡선을 그려 추가한 것이다. 유대로부터 실비를 뺀 원유1kg당 소득에, 경산우 1두당 년간 유량과 경산우 사양두수를 곱하면 1호당 년간 소득이 계산되므로 500만엥, 1,000만엥,1,500만엥이 되는 실비 수준을 나타낸 것이다. 유대는 75엥, 유량은 평균치인 7,800kg(실유량으로 7,100kg)으로 가정하고 있다. </p>
				<div className="imgBox"><img src={Pic794} alt="경영전개방향" /></div>
				
				<div className="tableWrap">
				<table className="tablestyle">
 					<caption>〈표 10〉 원유 1kg당 생산비의 파악상황에 관한 앙케이트 (1995년, 일본)</caption>
 					<thead>
	 					<tr>
	 						<th rowSpan="2" className="first">구 분</th>
	 						<th rowSpan="2">회답낙농가 호수</th>
	 						<th colSpan="4">회 답 별 비 율(%)</th>
	 					</tr>
	 					<tr style={{background: '#273143' , color: '#fff'}}>
	 						<th>파악하고 있음</th>
	 						<th>현금수지계산 이면 알고 있음</th>
	 						<th>파악하고 있지 않음</th>
	 						<th>무 회 답</th>
	 					</tr>
	 				</thead>
	 				<tbody>
	 					<tr>
	 						<td>전 국</td>
	 						<td>30,285</td>
	 						<td>9.5</td>
	 						<td>11.2</td>
	 						<td>67.5</td>
	 						<td>11.8</td>
	 					</tr>
	 					<tr>
	 						<td>북해도</td>
	 						<td>5,876</td>
	 						<td>9.9</td>
	 						<td>12.9</td>
	 						<td>67.6</td>
	 						<td>9.6</td>
	 					</tr>
	 					<tr>
	 						<td>도부현</td>
	 						<td>24,409</td>
	 						<td>9.4</td>
	 						<td>10.8</td>
	 						<td>67.5</td>
	 						<td>12.3</td>
	 					</tr>
	 				</tbody>
	 			</table>
	 			</div>
	 			<ul className="refer mg10t mg30f">
					<li>(자료) (사) 중앙낙농회의 &quot;낙농 전국기초조사&quot;</li>
				</ul>
				<p>그림을 보면 알수 있는 바와 같이 경산우 50두 규모라면 약 50엥의 실비로 소득이 1,000만엥이 된다. 즉 (75엥 - 50엥)x 7,800kg x 50두 = 975만엥이 된다.</p>
				<p>그림 가운데에 표시한 경산우 50두로 소득 500만엥을 올리는 낙농가의 경우, 실비는 62엥이지만, 이 낙농가가 소득을 1,000만엥으로 증가하기 위한 경영전개방향에 대하여 생각해 보자.</p>
				<p>먼저, 하나의 방향으로서 규모 확대가 고려된다. 그러나, 그 경우에는 62엥의 실비를 유지했다고 해도 100두까지 확대할 필요가 있다. 규모 확대할 경우에는 두수 증가 때문에 보통때이면 후계우로는 하지 않는 저능력우도 남기거나 또는 외부로부터의 도입이 필요하게 된다. 또한 100두가 되면 후리스톨·밀킹파라방식의 도입이 필요하게 되어 신규투자도 다액으로 이른다. 이와 더불어 계류사양의 개체관리로부터 군관리로 사양관리방식도 크게 변한다. 이 때문에 일반적으로는 실비는 상승하는 것으로 생각할 수 밖에 없다. 실비가 상승하면 결국은 생각한 것 만큼 소득은 증가하지 않는다. 가령 유가가 내리는 일이라도 있으면 매우 위험한 상황에 처하게 되지 않을 수 없다.</p>
				<p className="mg60f">규모는 적지 않으면서 소득은 확보되지 못하는 낙농가는 생산비가 높기 때문이므로, 먼저 생산비를 낮출 필요가 있다. 그러기 위해서는 자기의 경영에서 생산비를 파악하여 어느 부분이 생산비를 올리는 요인이 되고 있는지를 분석하여, 그의 절감방법을 생각할 필요가 있다. 이러한 일은 이미 일정 수준의 생산비를 달성하고 있는 낙농가가 다시 소득향상을 시도하는 경우에도 기본이 될 것이다. </p>

				<p className="viewTitle">생산비의 변동원인</p>
				<h2>경산우 1두당 년간 유량과 생산비의 관계</h2>
				<p>원유 1kg당 생산비는, 경산우 1두당 생산비를 유량으로 나눈 것이다. 생산비 가운데, 사료비 이외의 비목은 유량에 거의 영향을 미치지 않는다. 사료비에 대해서도, 유량에 관계하지 않는 유지에너지가 1/3이상을 차지하고 있다.</p>
				<p className="mg30f">그 때문에 경산우 1두당 년간 유량은 생산비의 변폭에 대한 최대원인이 되고, 그의 증가가 생산비의 절감에는 가장 중요한 것이 된다.</p>
				<div className="imgBox"><img src={Pic795} alt=' '/> <img src={Pic796} alt="" className="mg20l" /> <img src={Pic797} alt="" className="mg30t" /></div>
				
				<p>&lt;그림 10&gt;은 경산우 1두당 년간 유량과 실비 등의 관계를 낙농가의 분포 ①과 유량 계층별의 평균치 ②에 의하여 표시한 것이다. ②의 상단은 생유 1kg당 실비와 소득을 표시한다. 유량증가에 따라 실비는 저하하고 그 부분만큼의 소득은 향상되고 있다.</p>
				<p>그러면서도 ①을 보면, 실제로는 같은 수준의 유량이 나와도 큰 변폭이 존재하고 있다. 생산비의 절감을 위해서는 1두당 유량을 증가시키는 것이 가장 중요하지만, 단지 단순히 이 유량만을 증가시키는 것이 반드시 좋다고는 할 수 없음을 알 수 있다.</p>
				<p>②의 하단은 경산우 1두당 실비와 소득을 나타낸다. 유량이 증가할수록 사료비가 증가하기 때문에 실비가 증대하는 것이므로, 그것을 상회해서 소득이 늘어난다는 사실을 알 수 있다. 이와같이 유량 증가는 원유1kg당 실비 저하와 생산량의 증가라는 양면으로부터 상승적으로 소득증가를 가져오는 것으로 기대된다.</p>
				<p className="mg60f">그러나, 소득확대를 위해서는 1두당 년간유량뿐이 아니고 생산비와 사양두수의 세가지를 배려할 필요가 있다는 것을 잊어서는 안되겠다. 그와 더불어, 그림10 ①의 북해도의 낙농가 분포 중에 경산우 사양두수 50두와 80두의 경우에 소득이 1,000만엥이 되는 수준을 표시하고 있다. 유량 1,000kg마다 6개의 표를 붙였지만, 어느 경우에도 소득은 1,000만엥이 된다. 유량 8,000kg(실유량 7,300kg)선을 보면, 30두의 차이가 약 4엥의 차이가 기장에서 사라진다는 사실을 알 수 있다. </p>

				<h2>경산우 1두당 및 원유 1kg당 사료비 </h2>
				<p className="mg30f">&lt;표 8&gt;에서와 같이 실비의 약 60%은 사료비로 되어 있다. 거기서 원유 1kg당 실비가 높은 경영과 낮은 경영으로 나누어서 경산우 1두당 및 원유1kg당 사료비를 본 것이 &lt;그림11&gt;이다.</p>
				<div className="imgBox"><img src={Pic798} alt="경산우 1두당 및 원유 1kg당 사료비" /></div>
				<p>경산우 1두당 사료비에는 현저한 차이가 보이지 않으나, 원유 1kg당 사료비로 계산되면 약 30엔선에서 명확히 갈라진다. 즉, 유량에 관계없이 1두당 같은 사료비를 곱한 결과, 유량이 많은 경우에는 저비용으로, 적은 경우에는 고비용으로 되어있다는 사실을 알 수 있다.</p>
				<p>왜 유량이 적은데도 사료비가 같은 정도 들었는가? 상세한 분석을 해 보아도 자급사료와 구입사료의 비율에는 영향이 인정되지 않는다. 사료비는 자금사료 주체로 되어 있어도 비싼 경우와 싼 경우가 있으며 구입사료 주체로 되어 있어도 역시 비싼 경우와 싼 경우를 엿볼 수 있다.</p>
				<p className="mg60f">이 때문에 고비용이 되는 큰 요인으로서는, 우군의 능력을 넘어서 필요량 이상으로 쓸데없이 사료를 많이 급여하고 있거나, 자급, 구입에 관계없이 그 단가가 높은 것으로 추측된다.( 주: 자급사료비에는 생산에 요한 가족노동비를 포함하기 때문에 그것을 제하면 자급사료쪽이 싼값이 된다.) </p>

				<h2>공용년수와 젖소상각비</h2>
				<p>사료비 이외의 비목에 대해서는 유량의 증가가 그대로 저하되는 것에 연결되지만, 그러나 경산우 1두당 요하는 경비를 절감하는 것도 중요하다.</p>
				<p>특히, 근년의 젖소상각비가 증대되고 있는 것을 보면 알 수 있듯이, 공용년수의 연장은 극히 중요하다고 생각된다. 초산분만시의 평가액이 40만엔, 폐용우로서의 매각액이 10만엔이라 하면, 1년간의 상각비는 공용년수가 4년의 경우에 75,000엔, 5년의 경우에는 60,000엔으로 15,000엔의 차이가 난다. 이것은 원유 1kg당 2엔정도의 차이가 나는 것으로 된다.</p>
				<p className="mg60f">다시, 비유의 피 - 크는 5산 이후에 있다는 것으로 알려지고 있다. 현재의 공용산차는 4산 정도로 추정되지만, 만약 그렇다면 대부분의 젖소는 생물로서의 생리적으로 피 - 크보다는 그 이전에 폐용되고 있다는 셈이 된다. 공용년수의 연장은 젖소상각비의 절감 뿐만이 아니고, 우량증가의 면에서도 유효하다고 생각된다. </p>

				<p className="viewTitle">젖소개량의 의의</p>
				<p className="mg30f">상식적인 이야기이지만, 생산비 절감을 위해서는 젖소의 유전적 능력에 대응해서 값싼 사료를 허실없이 급여하는 것이 제일이다. 이 사실을 그림으로 표시하면 〈그림12〉와 같이 된다. 기초가 되는 유전적 능력을 효율적인 사양관리로 100% 끌어내는 것을 실선으로 표시한 정삼각형이 그러진다.(①) 사양관리가 불적절하면, 유전적 능력이 있어도 생산성이 저하한다(②). 그래서 개량에 의한 기초가 되는 유전적 능력을 높여서 커진 기초토대에 알맞은 적절한 사양관리를 함으로써 생산성이 다시 향상된다(③). 그러나 유전적 능력을 높히지 않고, 비싼 사료를 이용하는 것 드으이 상양관리만으로 생산량을 증가시키고저 하면, 그부분은 비효율적이기 때문에 생산성은 저하할 수 밖에 없다(④).</p>
				<div className="imgBox"><img src={Pic799} alt="젖소개량" /></div>
				<p className="mg60f">즉, 생산의 기초 토대는 젖소의 유전능력이므로, 생산성 향상의 기초는 젖소의 개량에 있다는 것을 단언할 수 있다. 그러나, 어떤 형질에 대하여 젖소를 개량해 나가야 좋겠는가? 첫째는 유량을 시작으로 하는 비유형질이 먼저 고려되어야 할 것이다. 그것에 더하여 공용년수의 연장을 위하여 강건성의 지표가 되는 체형형질이 중요하다. 다시 더하여 체세포수라든지, 효율적인 사양관리의 실시에 연결되는 기질(氣質), 착유성등도 필요하다고 생각된다. </p>

				<p className="viewTitle">낙농가의 의향 </p>
				<p>생산성 향상에 대하여 낙농가는 어떻게 생각하고 있을까.</p>
				<p>낙농가에 대하여 실시한 앙케이트(낙농전국기초조사)에 의하면 〈표11〉과 같이 회답을 한 낙농가 약3만호 가운데 80%는 &quot;금후에도 낙농을 계속함&quot; 또는 &quot;당분간 중단할 예정은 없음&quot;으로 회답하고 있다.</p>
				<p>〈표 11〉금후의 경영전개에 관한 앙케이트(1995년 일본)</p>	
				 
				<ul>
					<li>① 경영의 계속 여부에 대하여
					  <div className="tableWrap mg30f">
				 	<table className="tablestyle mg10t">
				 		<thead>
		 					<tr>
		 						<th rowSpan="2" className="first">구 분</th>
		 						<th rowSpan="2">회답낙농가 호수</th>
		 						<th colSpan="4">회답별 비율(%)</th>
		 					</tr>
		 					<tr>
		 						<th>계속할 예정</th>
		 						<th>당분간 중지할 예정은 없음</th>
		 						<th>5년 이내에 중지할 예정</th>
		 						<th>무 회 답</th>
		 					</tr>
		 				</thead>
		 				<tbody>
		 					<tr>
		 						<td>전 국</td>
		 						<td>30,285</td>
		 						<td>32.1</td>
		 						<td>48.2</td>
		 						<td>18.2</td>
		 						<td>1.5</td>
		 					</tr>
		 					<tr>
		 						<td>북해도</td>
		 						<td>5,876</td>
		 						<td>41.8</td>
		 						<td>47.1</td>
		 						<td>10.0</td>
		 						<td>1.1</td>
		 					</tr>
		 					<tr>
		 						<td>도부현</td>
		 						<td>24,409</td>
		 						<td>29.7</td>
		 						<td>48.5</td>
		 						<td>20.1</td>
		 						<td>1.7</td>
		 					</tr>
		 				</tbody>
				 	</table>
				 	  </div>
          </li>
				 	<li>② &quot;경영계속 및 당분간 중지할 예정 없음&quot;으로 회답한 낙농가의 당면한 경영전개방향에 대하여
            <div className="tableWrap mg30f">
            <table className="tablestyle mg10t">
              <thead>
                <tr>
                  <th rowSpan="2" className="first">구 분</th>
                  <th rowSpan="2">회답낙농가 호수</th>
                  <th colSpan="6">회답별 비율(%)</th>
                </tr>
                <tr>
                  <th>축산 등산 증축에 <br/>의한 규모확대</th>
                  <th>현재시설(약간의 <br/>개축포함)로 증두수</th>
                  <th>증두수 하지 않고<br/> 비용삭감</th>
                  <th>복합부문의 도입 강화 </th>
                  <th>기 타</th>
                  <th>무 회 답</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>전 국</td>
                  <td>24,316</td>
                  <td>10.5</td>
                  <td>24.8</td>
                  <td>49.5</td>
                  <td>5.7</td>
                  <td>3.7</td>
                  <td>5.8</td>
                </tr>
                <tr>
                  <td>북해도</td>
                  <td>5,225</td>
                  <td>15.8</td>
                  <td>30.9</td>
                  <td>42.8</td>
                  <td>3.7</td>
                  <td>3.1</td>
                  <td>3.6</td>
                </tr>
                <tr>
                  <td>도부현</td>
                  <td>19,091</td>
                  <td>9.1</td>
                  <td>23.1</td>
                  <td>51.3</td>
                  <td>6.2</td>
                  <td>3.9</td>
                  <td>6.4</td>
                </tr>
              </tbody>
            </table>
            </div>
           </li>
				 	
				 	<li>③ &quot;증두수 하지 않고 비용삭감&quot;으로 회답한 낙농가의 구체적인 계획
            <div className="tableWrap mg30f">
            <table className="tablestyle mg10t">
              <thead>
                <tr>
                  <th rowSpan="2" className="first">구 분</th>
                  <th rowSpan="2">회답낙농가 호수</th>
                  <th colSpan="7">회답별 비율(%)</th>
                </tr>
                <tr>
                  <th>우군개량에 의한<br/> 생산량 증대</th>
                  <th>공용년수의 연장</th>
                  <th>자가경신의 확대</th>
                  <th>사료급여 <br/>기술의 향상</th>
                  <th>기계공동보유 등 <br/>조사료생산 효율화</th>
                  <th>방목활용</th>
                  <th>부산물의 이용 <br/>및 자가배합</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>전 국</td>
                  <td>12,035</td>
                  <td>55.3</td>
                  <td>52.2</td>
                  <td>28.4</td>
                  <td>50.5</td>
                  <td>11.9</td>
                  <td>8.5</td>
                  <td>6.2</td>
                </tr>
                <tr>
                  <td>북해도</td>
                  <td>2,238</td>
                  <td>52.7</td>
                  <td>53.5</td>
                  <td>21.1</td>
                  <td>54.8</td>
                  <td>14.1</td>
                  <td>28.4</td>
                  <td>3.7</td>
                </tr>
                <tr>
                  <td>도부현</td>
                  <td>9,797</td>
                  <td>55.9</td>
                  <td>51.9</td>
                  <td>30.1</td>
                  <td>49.5</td>
                  <td>11.4</td>
                  <td>4.0</td>
                  <td>6.8</td>
                </tr>
              </tbody>
            </table>
            </div>
				 	</li>
				 </ul>
				 <p>이들 낙농가에 대하여 당면한 경영전개방향을 질문한 결과 &quot;증두수 하지 않고 비용삭감&quot;이 가장 많아 49.5%를 차지하였고, 다음으로 &quot;현재시설(약간개축을 포함)로 증두수&quot;가 24.8%를 점유하고 있다. &quot;신규투자를 수반하는 축사 등의 신증설에 의한 규모확대&quot;는 10.5%에 불과하였다.</p>
				 <p>이러한 것을 경영을 발전시키고저 하는 의욕의 저하라든지 비관적으로 보는 관계자도 있으나, 앞서 말한 바와 같이 전업적 경영이 과반수를 넘는 상황에 있다는 사실을 감안한다면 비관적으로 볼 필요는 없다고 생각된다.</p>
				 <p>다시 &quot;증두수 없이 비용삭감&quot; 으로 회답한 낙농가에게 어떤 방법으로 비용삭감을 계획중에 있는지 해당되는 방법을 대부분 선정해 보도록 했더니, &quot;우군개량에 의한 생산량 주&#34679;대&quot;(55.3%), &quot;공용년수의 연장&quot;(52.2%), &quot;사료급여기술의 향상&quot;(50.5%)가 반수를 넘고, &quot; 자가 경신의 확대&quot;도 28.4%가 되고 있다.</p>
				 <p>자가경신의 확대는 초임우가격의 앙등 등의 영향을 피하고저 하는 의미도 있겠지만, 젖소개량을 추진하기 위한 기본이기도 하다. 다시 말하면, 이 결과는 낙농가가 개랑에 의한 비유형질과 강건성(체형형질)의 유전적 능력을 높여서 사료급여기술의 항상등과 사양관리기술의 개선에 의하여 1두당 생산성을 높여나갈 의향이 있음을 표시하는 것으로 말할 수 있다. 그렇다면 낙농가의 의향은 이미 지금까지 이야기해 온 방향에 일치한다고 볼 수 있다.</p>
				 <p>(주3) 통계나 자료에 의하여 젖소의 사양두수 집계가 총두수(1,897천두), 성축(1,319천두), 경산우(1,204천두), 착유우(1,031천두)오는 다르다는 것에 주의를 요한다.</p>
				 <p>( )내는 1997년의 수치이다.</p>
			</div>
  );
}

export default Fo1;
