import React from 'react';
import DescendantCow from 'containers/web/business/descendantCow/DescendantCow';
import { Helmet } from 'react-helmet-async';


function DescendantCowPage() {
  return (
    <>
      <Helmet>
        <title>후대검정</title>
      </Helmet>
      <DescendantCow />
    </>
  );
}

export default DescendantCowPage;
