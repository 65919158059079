import React from 'react';

// constants
import { BOARD_CODE, BOARD_NAME } from 'constants/backOfficeBoard';

// components
import BoardView from '../_components/BoardView';

function TestBisReportView() {
  return (
    <BoardView
      boardCode={BOARD_CODE.TEST_BIS_REPORT}
      boardName={BOARD_NAME.TEST_BIS_REPORT}
      boardUrl="testBisReport"
    />
  );
}

export default TestBisReportView;
