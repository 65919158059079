import React from 'react';
import BuySemen from 'containers/web/ox/buySemen/BuySemen';
import { Helmet } from 'react-helmet-async';

function BuySemenPage() {
  return (
    <>
      <Helmet>
        <title>정액구입</title>
      </Helmet>
      <BuySemen />
    </>
  );
}

export default BuySemenPage;
