import React from 'react';

import pic666 from 'assets/images/sub/_images/pic666.jpg';
import pic667 from 'assets/images/sub/_images/pic667.jpg';
import pic668 from 'assets/images/sub/_images/pic668.jpg';

function Do132() {

  return (
    <div className="techinfoview">
      <h1>아까바네 병</h1>
      <p className="pstyle">이 병은 임신한 어미소가 아까바네 바이러스에 감염되고 다시 그 태아로 감염되어 일어나는 태아 또는 갓난 송아지의 병으로 1978∼80년 사이에 국내에 다수 발생 보고가 있었으며 현재에도 발생하고 있는 것으로 추측된다. 우리나라에서는 태풍을 타고 날아온 모기에 의해 전파된다. </p>
      <div className="leftpart">
        <h2>원인</h2>
        <ul>
          <li>감염은 아까바네 바이러스를 가진 흡혈곤충(주로 모기)이 임신한 소를 흡혈함으로써 이루어 진다. </li>
        </ul>
        <h2>증상</h2>
        <ul>
          <li>이 바이러스가 소에 감염되는 시기는 초여름에서 초가을 사이(특히 8∼9월)이다. 
            <ul>
              <li>다른 계절에는 감염되지 않는다. </li>
              <li>8월부터 유산이 일어나고 9월부터는 사산 및 기형태아가 나오면 다음해 3월까지 발생된다. </li>
              <li>감염된 모우(母牛)는 아무런 이상이 없다.</li>
              <li>기형태아로 인해 분만시에 종종 난산을 유발한다. </li>
              <li>이 병의 가장 특징적인 것은 기형 송아지에서 사지만곡, 척추이상, 비뚤어진 목등을 볼 수 있는 일이며, 정상 예정일 분만시에도 체중 20kg 정도의 허약한 송아지를 볼 수 있다. </li>
            </ul>
          </li>
        </ul>
        <h2>목장에서의 처치</h2>
        <ul>
          <li>방충망의 설치나 살충제 살포로 모기와 그 외의 흡혈곤충의 우사내 침입을 방지하면 어느정도 예방효과를 볼 수 있다. </li>
          <li>본 병은 바이러스 병이기 때문에 특효약은 없으나 매년 이 바이러스가 유생하기 시작하는 7월 전에 임신우와 임신 가능우에 백신을 접종하면 예방 할 수 있다.</li>
        </ul>
      </div>
      <div className="rightpart">
        <img src={pic666} className="nonborder" alt=""/>
        <img src={pic667} className="nonborder" alt=""/>
        <img src={pic668} className="nonborder" alt=""/>
      </div>
    </div>
  );
}

export default Do132;