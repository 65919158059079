import React from 'react';
import OxPrint from 'containers/oxPrint/OxPrint';

function OxPrintPage() {
  return (
    <OxPrint />    
  );
}

export default OxPrintPage;
