import React, { useState , useEffect } from 'react';

// api
import { postState } from 'utils/api';
import { openCrownix3 , openCrownix6 } from 'utils/openCrownix';

// 이미지
import PrintPDF from 'assets/svgs/common/print_PDF.svg';

// 컴포넌트
import ErrorMessage from 'components/errorMessage/errorMessage';
import MonthCode from 'components/dcitest/monthCode/MonthCode';

// scss
import './NewNoticeCowlistV1.scss';

function NewNoticeCowlistV1() {

  // 에러처리
  const [isModalOpen, setIsModalOpen] = useState(false); // 모달 추가
  const [errorMessage, setErrorMessage] = useState(''); 

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => {
    setIsModalOpen(false);
    setErrorMessage(''); // 모달이 닫힐 때 에러메세지 초기화
  };

  // 데이터 불러오기
  const baseDir='/chart/new_notice_cowlist/';
  const [ mainData , setMainData ] = useState();

  const [ props , setProps] = useState({
    id:`${localStorage.getItem('cong')}${localStorage.getItem('farm')}`,
    passwd:localStorage.getItem('passwd'),
    date:localStorage.getItem('month')
  });
  useEffect(() => {

    postState(baseDir,props)
    .then(getdata => {      
      if (getdata !== undefined) {
        // console.log(getdata);
        setMainData(getdata);
      }
    })
    .catch(error => {           
      setErrorMessage(error.message);
      openModal();
    });
    
  }, [props]);  
  return (
    <div className='pdfMainArea'>
      <ErrorMessage isOpen={isModalOpen} content={errorMessage} closeModal={closeModal}/>
      <div className='pdfMainHeader'>
        <button 
          type='button'
          onClick={()=> openCrownix6('New_notice_cowlist.mrd')}
        >
          <img src={PrintPDF} alt='PDF로 인쇄하기' />
          인쇄하기
        </button>
        {
          props && 
          <MonthCode 
            props={props}
            setProps={setProps}
            setErrorMessage={setErrorMessage}
            openModal={openModal}
          />
        }
      </div>
      <div className='pdfArea'>
        <div className='newNoticeCowlistV1'>
          <h1>주의개체 전체현황 보기</h1>
          <table className='pdfTable'>
            <thead> 
              <tr>
                <th rowSpan='2'>관리번호</th>
                <th rowSpan='2'>체세포 50만 이상</th>
                <th colSpan='2'>유지방</th>
                <th colSpan='3'>유단백</th>
                <th colSpan='2'>유단백 : 유지방</th>
                <th colSpan='2'>유지방 : 유단백</th>
                <th rowSpan='2'>고형율 8.3 이하</th>
                <th rowSpan='2'>유지방 + 유단백 6.5미만</th>
              </tr>
              <tr>
                <th>비유일 70미만 유지방 5.5이상</th>
                <th>유지방 3.4미만</th>
                <th>비유일 70미만 유단백 3.0이하</th>
                <th>비유일 230미만 유지방 3.0이상</th>
                <th>유단백 3.1이하</th>
                <th>0.85미만</th>
                <th>0.95이상</th>
                <th>1.05미만</th>
                <th>1.35이상</th>
              </tr>
            </thead>
            <tbody>
              {
                mainData &&
                mainData.plot_data.map((item, index) => (
                  <tr key={index}>
                    <td>
                      <button
                        type='button'
                        onClick={() =>  openCrownix3('New_sang_jcode.mrd', item.jcode)}
                      >
                        {item.sname}
                      </button>
                    </td>
                    <td>{item.scc}</td>
                    <td>{item.fatp1}</td>
                    <td>{item.fatp2}</td>
                    <td>{item.protp1}</td>
                    <td>{item.protp2}</td>
                    <td>{item.protp3}</td>
                    <td>{item.rate_pf1}</td>
                    <td>{item.rate_pf2}</td>
                    <td>{item.rate_fp1}</td>
                    <td>{item.rate_fp2}</td>
                    <td>{item.snfp}</td>
                    <td>{item.plus_fp}</td>
                  </tr>
                ))
              }
            </tbody>

          </table>
          <ul>
            <li>
              <h2>비유일수별 체세포 - 체세포 50만 이상의 개체</h2>
              <table className='pdfTable'>
                <thead> 
                  <tr>
                    <th>관리번호</th>
                    <th>비유일수</th>
                    <th>체세포수(천)</th>                
                  </tr>              
                </thead>
                <tbody>
                  {
                    mainData &&
                    mainData.plot_data2.map((item, index) => (
                      <tr key={index}>
                        <td>{item.rnum}</td>
                        <td>{item.cumday}</td>
                        <td>{item.scc}</td>                    
                      </tr>
                    ))
                  }
                </tbody>

              </table>
            </li>
            <li>
              <h2>비유일수별 유지방 주의개체 리스트</h2>
              <table className='pdfTable'>
                <thead> 
                  <tr>
                    <th rowSpan='2'>관리번호</th>
                    <th rowSpan='2'>비유일수</th>
                    <th colSpan='2'>유지방</th>                
                  </tr>
                  <tr>                                
                    <th>비유일수 70미만 유지방 5.5 이상</th>
                    <th>유지방 3.4 미만</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    mainData &&
                    mainData.plot_data3.map((item, index) => (
                      <tr key={index}>
                        <td>{item.sname}</td>
                        <td>{item.cumday}</td>
                        <td>{item.fatp1}</td>
                        <td>{item.fatp2}</td>                  
                      </tr>
                    ))
                  }
                </tbody>

              </table>
            </li>
            <li>
              <h2>비유일수별 유단백 주의개체 리스트</h2>
              <table className='pdfTable'>
                <thead> 
                  <tr>
                    <th rowSpan='2'>관리번호</th>
                    <th rowSpan='2'>비유일수</th>
                    <th colSpan='3'>유지방</th>                
                  </tr>
                  <tr>                                
                    <th>비유일수 70미만 유단백 3.0 이하</th>
                    <th>비유일수 23이상 유단백 3.0 이상</th>
                    <th>유단백 3.1 미만</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    mainData &&
                    mainData.plot_data4.map((item, index) => (
                      <tr key={index}>
                        <td>{item.sname}</td>
                        <td>{item.cumday}</td>
                        <td>{item.protp1}</td>
                        <td>{item.protp2}</td>                  
                        <td>{item.protp3}</td>                  
                      </tr>
                    ))
                  }
                </tbody>

              </table>
            </li>
            <li>
              <h2>비유일수별 고형물 주의개체 리스트</h2>
              <table className='pdfTable'>
                <thead> 
                  <tr>
                    <th>관리번호</th>
                    <th>비유일수</th>
                    <th>무지고형율 8.3이하</th>                
                  </tr>              
                </thead>
                <tbody>
                  {
                    mainData &&
                    mainData.plot_data5.map((item, index) => (
                      <tr key={index}>
                        <td>{item.sname}</td>
                        <td>{item.cumday}</td>
                        <td>{item.snfp}</td>                    
                      </tr>
                    ))
                  }
                </tbody>

              </table>
            </li>
          </ul>
        </div>
      </div>
      
    </div>   
  );
}

export default NewNoticeCowlistV1;

