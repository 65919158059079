import React, { useState , useEffect } from 'react';

// api
import { postState } from 'utils/api';
import { openCrownix4 , openCrownix } from 'utils/openCrownix';

// 이미지
import PrintPDF from 'assets/svgs/common/print_PDF.svg';
import Logo from 'assets/images/common/header_logo_pc.png';

// 컴포넌트
import ErrorMessage from 'components/errorMessage/errorMessage';
import MonthCode from 'components/dcitest/monthCode/MonthCode';

// scss
import './NewCowDcicAnal.scss';

function NewCowDcicAnal() {

  // 에러처리
  const [isModalOpen, setIsModalOpen] = useState(false); // 모달 추가
  const [errorMessage, setErrorMessage] = useState(''); 

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => {
    setIsModalOpen(false);
    setErrorMessage(''); // 모달이 닫힐 때 에러메세지 초기화
  };

  // 데이터 불러오기
  const baseDir='/dcitest/dciexpert/newcowdcicanal/';
  const [ mainData , setMainData ] = useState();

  const [ props , setProps] = useState({
    id:`${localStorage.getItem('cong')}${localStorage.getItem('farm')}`,
    passwd:localStorage.getItem('passwd'),
    date:localStorage.getItem('month')
  });

  useEffect(() => {

    postState(baseDir,props)
    .then(getdata => {      
      if (getdata !== undefined) {
    //   console.log ('#### props : ' ,props);
      setMainData(getdata);
    //   console.log ('#### data : ' ,getdata);
      }
    })
    .catch(error => {      
      console.error('Error:', error.message);      
      setErrorMessage(error.message);
      openModal();
    });
    
  }, [props]);


  return (
    <div className='pdfMainArea'>
      <ErrorMessage isOpen={isModalOpen} content={errorMessage} closeModal={closeModal}/>
      <div className='pdfMainHeader'>
        <button 
          type='button'
          onClick={()=> openCrownix('New_cow_dcicAnal.mrd')}
        >
          <img src={PrintPDF} alt='PDF로 인쇄하기' />
          인쇄하기
        </button>
        { 
          props && 
          <MonthCode 
            props={props}
            setProps={setProps}
            setErrorMessage={setErrorMessage}
            openModal={openModal}
          />
        }
      </div>
      <div className='pdfArea'>
        <div className='newCowDcicAnalPdf'>
          <h1>검정성적 종합분석 보고서</h1>           
          
          <table className='pdfTable'>
            <thead>
              <tr>
                <th rowSpan="2" className="tablestart"><p style={{textAlign:'right'}}>착유일수</p><p style={{textAlign:'left'}}>유량수준</p></th>
                <th rowSpan="2">두수</th>
                <th colSpan="5">1산우</th>
                <th colSpan="5">2산 이상우</th>
                <th colSpan="3">합계</th>                                                       
              </tr>
              <tr>                
                <th>70일 이하</th>
                <th>71일~</th>
                <th>151일~</th>
                <th>221일~</th>
                <th>306일~</th>
                <th>70일 이하</th>
                <th>71일~</th>
                <th>151일~</th>
                <th>221일~</th>
                <th>306일~</th>
                <th>1산</th>
                <th>2산 이상</th>
                <th>계</th>
              </tr>
            </thead>
            <tbody>
              {
                mainData &&
                mainData.plot_data.map((item, index) => (
                  <tr key={index}>
                    <td>{item.grade}</td>
                    <td>{item.mgrad_cno}</td>
                    <td>{item.mgrad_d45}</td>
                    <td>{item.mgrad_d46}</td>
                    <td>{item.mgrad_d100}</td>
                    <td>{item.mgrad_d200}</td>
                    <td>{item.mgrad_d300}</td>
                    <td>{item.mgrad_d451}</td>
                    <td>{item.mgrad_d461}</td>
                    <td>{item.mgrad_d1001}</td>
                    <td>{item.mgrad_d2001}</td>
                    <td>{item.mgrad_d3001}</td>
                    <td>{item.lac1}</td>
                    <td>{item.lac2}</td>
                    <td>{item.lac1+item.lac2}</td>                    
                  </tr>
                ))
              }
              {
                mainData &&
                mainData.plot_data2.map((item, index) => (
                  <tr key={index}>
                    <td>평균유량</td>
                    <td>{item.mgrad_cno}</td>
                    <td className='numberData'>{item.mgrad_d45}</td>
                    <td className='numberData'>{item.mgrad_d46}</td>
                    <td className='numberData'>{item.mgrad_d100}</td>
                    <td className='numberData'>{item.mgrad_d200}</td>
                    <td className='numberData'>{item.mgrad_d300}</td>
                    <td className='numberData'>{item.mgrad_d451}</td>
                    <td className='numberData'>{item.mgrad_d461}</td>
                    <td className='numberData'>{item.mgrad_d1001}</td>
                    <td className='numberData'>{item.mgrad_d2001}</td>
                    <td className='numberData'>{item.mgrad_d3001}</td>
                    <td className='numberData'>{item.avgmilk1.toFixed(1)}</td>
                    <td className='numberData'>{item.avgmilk2.toFixed(1)}</td>
                    <td className='numberData'>{item.tavgmilk.toFixed(1)}</td>                    
                  </tr>
                ))
              }
              {
                mainData &&
                mainData.plot_data2.map((item, index) => (
                  <tr key={index}>
                    <td>CV</td>
                    <td></td>
                    <td className='numberData'>{item.milk_cv1_70}</td>
                    <td className='numberData'>{item.milk_cv1_150}</td>
                    <td className='numberData'>{item.milk_cv1_220}</td>
                    <td className='numberData'>{item.milk_cv1_306}</td>
                    <td className='numberData'>{item.milk_cv1_600}</td>
                    <td className='numberData'>{item.milk_cv2_70}</td>
                    <td className='numberData'>{item.milk_cv2_150}</td>
                    <td className='numberData'>{item.milk_cv2_220}</td>
                    <td className='numberData'>{item.milk_cv2_306}</td>
                    <td className='numberData'>{item.milk_cv2_600}</td>
                    <td></td>
                    <td></td>
                    <td></td>                    
                  </tr>
                ))
              }
              {
                mainData &&
                mainData.plot_data3.map((item, index) => (
                  <tr key={index}>
                    <td>유지율</td>
                    <td>{item.mgrad_cno}</td>
                    <td className='numberData'>{item.mgrad_d45}</td>
                    <td className='numberData'>{item.mgrad_d46}</td>
                    <td className='numberData'>{item.mgrad_d100}</td>
                    <td className='numberData'>{item.mgrad_d200}</td>
                    <td className='numberData'>{item.mgrad_d300}</td>
                    <td className='numberData'>{item.mgrad_d451}</td>
                    <td className='numberData'>{item.mgrad_d461}</td>
                    <td className='numberData'>{item.mgrad_d1001}</td>
                    <td className='numberData'>{item.mgrad_d2001}</td>
                    <td className='numberData'>{item.mgrad_d3001}</td>
                    <td className='numberData'>{item.avgfatp1.toFixed(1)}</td>
                    <td className='numberData'>{item.avgfatp2.toFixed(1)}</td>
                    <td className='numberData'>{item.tavgfatp.toFixed(1)}</td>                    
                  </tr>
                ))
              }
              {
                mainData &&
                mainData.plot_data3.map((item, index) => (
                  <tr key={index}>
                    <td>CV</td>
                    <td></td>
                    <td className='numberData'>{item.fatp_cv1_70}</td>
                    <td className='numberData'>{item.fatp_cv1_150}</td>
                    <td className='numberData'>{item.fatp_cv1_220}</td>
                    <td className='numberData'>{item.fatp_cv1_306}</td>
                    <td className='numberData'>{item.fatp_cv1_600}</td>
                    <td className='numberData'>{item.fatp_cv2_70}</td>
                    <td className='numberData'>{item.fatp_cv2_150}</td>
                    <td className='numberData'>{item.fatp_cv2_220}</td>
                    <td className='numberData'>{item.fatp_cv2_306}</td>
                    <td className='numberData'>{item.fatp_cv2_600}</td>
                    <td></td>
                    <td></td>
                    <td></td>                    
                  </tr>
                ))
              }
              {
                mainData &&
                mainData.plot_data4.map((item, index) => (
                  <tr key={index}>
                    <td>단백율</td>
                    <td>{item.mgrad_cno}</td>
                    <td className='numberData'>{item.mgrad_d45}</td>
                    <td className='numberData'>{item.mgrad_d46}</td>
                    <td className='numberData'>{item.mgrad_d100}</td>
                    <td className='numberData'>{item.mgrad_d200}</td>
                    <td className='numberData'>{item.mgrad_d300}</td>
                    <td className='numberData'>{item.mgrad_d451}</td>
                    <td className='numberData'>{item.mgrad_d461}</td>
                    <td className='numberData'>{item.mgrad_d1001}</td>
                    <td className='numberData'>{item.mgrad_d2001}</td>
                    <td className='numberData'>{item.mgrad_d3001}</td>
                    <td className='numberData'>{item.avgprotp1.toFixed(1)}</td>
                    <td className='numberData'>{item.avgprotp2.toFixed(1)}</td>
                    <td className='numberData'>{item.tavgprotp.toFixed(1)}</td>                    
                  </tr>
                ))
              }
              {
                mainData &&
                mainData.plot_data4.map((item, index) => (
                  <tr key={index}>
                    <td>CV</td>
                    <td></td>
                    <td className='numberData'>{item.protp_cv1_70}</td>
                    <td className='numberData'>{item.protp_cv1_150}</td>
                    <td className='numberData'>{item.protp_cv1_220}</td>
                    <td className='numberData'>{item.protp_cv1_306}</td>
                    <td className='numberData'>{item.protp_cv1_600}</td>
                    <td className='numberData'>{item.protp_cv2_70}</td>
                    <td className='numberData'>{item.protp_cv2_150}</td>
                    <td className='numberData'>{item.protp_cv2_220}</td>
                    <td className='numberData'>{item.protp_cv2_306}</td>
                    <td className='numberData'>{item.protp_cv2_600}</td>
                    <td></td>
                    <td></td>
                    <td></td>                    
                  </tr>
                ))
              }
              {
                mainData &&
                mainData.plot_data5.map((item, index) => (
                  <tr key={index}>
                    <td>무지고형율</td>
                    <td>{item.mgrad_cno}</td>
                    <td className='numberData'>{item.mgrad_d45}</td>
                    <td className='numberData'>{item.mgrad_d46}</td>
                    <td className='numberData'>{item.mgrad_d100}</td>
                    <td className='numberData'>{item.mgrad_d200}</td>
                    <td className='numberData'>{item.mgrad_d300}</td>
                    <td className='numberData'>{item.mgrad_d451}</td>
                    <td className='numberData'>{item.mgrad_d461}</td>
                    <td className='numberData'>{item.mgrad_d1001}</td>
                    <td className='numberData'>{item.mgrad_d2001}</td>
                    <td className='numberData'>{item.mgrad_d3001}</td>
                    <td className='numberData'>{item.avgsnfp1.toFixed(1)}</td>
                    <td className='numberData'>{item.avgsnfp2.toFixed(1)}</td>
                    <td className='numberData'>{item.tavgsnfp.toFixed(1)}</td>                    
                  </tr>
                ))
              }
              {
                mainData &&
                mainData.plot_data5.map((item, index) => (
                  <tr key={index}>
                    <td>CV</td>
                    <td></td>
                    <td className='numberData'>{item.snfp_cv1_70}</td>
                    <td className='numberData'>{item.snfp_cv1_150}</td>
                    <td className='numberData'>{item.snfp_cv1_220}</td>
                    <td className='numberData'>{item.snfp_cv1_306}</td>
                    <td className='numberData'>{item.snfp_cv1_600}</td>
                    <td className='numberData'>{item.snfp_cv2_70}</td>
                    <td className='numberData'>{item.snfp_cv2_150}</td>
                    <td className='numberData'>{item.snfp_cv2_220}</td>
                    <td className='numberData'>{item.snfp_cv2_306}</td>
                    <td className='numberData'>{item.snfp_cv2_600}</td>
                    <td></td>
                    <td></td>
                    <td></td>                    
                  </tr>
                ))
              }
              {
                mainData &&
                mainData.plot_data6.map((item, index) => (
                  <tr key={index}>
                    <td>체세포수(천)</td>
                    <td></td>
                    <td className='numberData'>{item.sc1_70}</td>
                    <td className='numberData'>{item.sc1_150}</td>
                    <td className='numberData'>{item.sc1_220}</td>
                    <td className='numberData'>{item.sc1_305}</td>
                    <td className='numberData'>{item.sc1_306}</td>
                    <td className='numberData'>{item.sc2_70}</td>
                    <td className='numberData'>{item.sc2_150}</td>
                    <td className='numberData'>{item.sc2_220}</td>
                    <td className='numberData'>{item.sc2_305}</td>
                    <td className='numberData'>{item.sc2_306}</td>
                    <td className='numberData'>{item.sc1}</td>
                    <td className='numberData'>{item.sc2}</td>
                    <td className='numberData'>{item.sc}</td>                    
                  </tr>
                ))
              }              
            </tbody>
          </table>
          
          <p className="exp">
            ※참고사항<br/>
            ▶ 비유일수 70일 이하에서 유지율 3.3 및 유단백 2.9 이하인 개체에 대하여 급여수준 및 분만 후 사료 섭취량 증가 프로그램 수정 필요<br/>
            ▶ 비유일수 221일 이후 유지율 4.5 및 유단백 3.5 이상의 경우 과비 대상우 판정으로 급여프로그램 특히 TMR의 급여 수준 점검 필요<br/>
            ▶ CV 수준이 10% 이상일 경우 산차별, 개체별 관리 불량으로 개체 점검 및 급여량 차등화 필요            
          </p>
        
          <img src={Logo} alt='농협경제지주 젖소개량사업소 로고' className='pdfLogo'/>
        </div>
      </div>
    </div>    
    
  );
}

export default NewCowDcicAnal;
