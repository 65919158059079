import React from 'react';

import d1by8 from 'assets/images/sub/_images/1_8.gif';
import d1by37 from 'assets/images/sub/_images/1-37.jpg';
import d1by39 from 'assets/images/sub/_images/1-39.jpg';
import d1by41 from 'assets/images/sub/_images/1-41.jpg';
import d1by43 from 'assets/images/sub/_images/1-43.jpg';

function Fo21() {

  return (
    <div className="techinfo_view">
      <p className="viewTitle">생후 2~9개월의 관리</p>
      <h2>통증은 조기에!</h2>
      <ul className="listStyle04 mg15f">
        <li>제각(除角)은 뿔이 거슬리게 되면 빨리 처리한다</li>
        <li>부유두는 될 수 있는 한 빨리 예리한 가위로 제거하여 소독한다</li>
        <li>소제(削蹄: 발꿉을 깎는 것)는 부제형 지세를 바로 잡는 정도가 좋지만 소제에 익숙해지는 것이 중요.(발꿉을 자르는 것과 다리를 드는 것에 익숙해진다.)</li>
      </ul>
      <div className="imgBox mg60f"><img src={d1by37} alt=""/></div>
      
      <h2>언제까지나 1두로 단사(單飼)을 하지 않는다.! </h2>
      <ul className="listStyle04 mg15f">
        <li>이유가 종료하고 빠는 것을 잊었다면 2∼3두의 소군으로 하여 먹는 경쟁과 사회행동을 깨닫게 한다. 빨리 먹을 수 있는 것도 채식능력을 향상시키는데 중요하고, 먹는 것에 지지않는 소를 만든다.</li>
        <li>밀집사육은 오염되기 쉬워지기 때문에 1두당 2.3∼2.8㎡의 공간을 확보한다</li>
        <li>폐염(肺炎), 하리, 피부병(皮膚病)에 걸린 자우는 격리(隔離)하여 치료(治療)한다</li>
      </ul>
      <div className="imgBox mg60f"><img src={d1by39} alt=""/></div>
      
      <h2>매어서 사육하는 것(繫放)을 크게 하지 않는다.!</h2>
      <ul className="listStyle04 mg15f">
        <li>밧줄로 매어둠에 의해 스트레스(Stress)는 크고 발육이 대폭적으로 정체된다.</li>
        <li>육성사의 공간이 없을 경우는 Calf hutch(송아지 상자)와 Super calf hutch(대형 송아지 상자)를 이용한다</li>
        <li>조기부터 Stanchion(스탄촌)으로 사육하면 어깨가 벌어지기 쉬워진다</li>
      </ul>
      <div className="imgBox mg60f"><img src={d1by8} alt=""/></div>
      
      <h2>잊혀진 존재가 되어 있지는 않은가?</h2>
      <ul className="listStyle04 mg15f">
        <li>포유기간을 지나면 시간도 걸리지 않게되므로 잊혀지는 존재가 되기 쉽다. 다음 점을 체크(Check)한다.
          <ul>
            <li>a: 월령에 부응하는 발육을 하고 있는가?</li>
            <li>b: 열악한 조사료를 급여하고 있지 않은가?</li>
            <li>c: 조사료가 포식되고 있는가? (필요한 농후사료를 먹게 한다면 조사료는 포식하게 된다.)</li>
            <li>d: 어둑어둑하고, 축축한 곳에 과밀하게 사육하고 있지 않은가?</li>
            <li>e: 방목초, Sailage는 건물이 부족하지 않은가?</li>
          </ul>
        </li>					
      </ul>
      <div className="imgBox mg60f"><img src={d1by41} alt="" /> <img src={d1by43} title="후계우는 밝은 장소에서 건전하게 기르자!!" alt="" className="mg20l" /></div>
      
    </div>
  );
}

export default Fo21;
