import React from 'react';
import { Outlet } from 'react-router-dom';

function DciFarm() {
  return (
    <div className='dciFarmCon'>
      <Outlet />
    </div>   
  );
}

export default DciFarm;
