import React from 'react';

import Pic815 from 'assets/images/sub/_images/pic815.jpg';
import Pic816 from 'assets/images/sub/_images/pic816.jpg';
import Pic817 from 'assets/images/sub/_images/pic817.jpg';
import Pic818 from 'assets/images/sub/_images/pic818.png';
import Pic819 from 'assets/images/sub/_images/pic819.jpg';
import Pic820 from 'assets/images/sub/_images/pic820.jpg';
import Pic821 from 'assets/images/sub/_images/pic821.jpg';
import Pic822 from 'assets/images/sub/_images/pic822.jpg';

function Fo5() {

  return (
    <div className="techinfo_view">
				<p className="viewTitle">젖소의 유전적 능력평가와 그 활용</p>
				<h2>평가대상</h2> 
				<dl>
					<dt>경제형질</dt>
					<dd>
						<p>유량이라든지 유방의 부착도(현수)라든지 하는 사상(事象)을 &quot;형질&quot;이라 부른다. 또한 비유에 관한형질을 일괄해서 &quot;비유형질&quot;이라 부르며, 다시 경제성에 관한 형질전체를 &quot;경제형질&quot;로 부르고 있다.</p>
						<p>젖소의 경제형질은 크게 나누어서 비유형질, 체형형질, 기타의 셋으로 되어 있다. 기타에는 착유속도, 기질 , 분만난이 체세포수등이 포함된다.</p>
						<p>하나하나의 형질에는 각각에 다수의 유전자가 관여하고 있다. 장래에는 대부분의 유전자가 해명될는지 모르지만, 현시점에서는 불명하다. 그 때문에, 유전자의 총체적인 효과를 유전적 능력으로서 평가하고 있다.</p>
						<p>그리고, BLAD(소 백혈구점착성결핍증)과 같이 특정한 유전자가 관여하는 질병등에 대해서는 그 유전자의 유무에 의한 진단이 가능하게 되어 있다. </p>
					</dd>
				</dl>
				<dl>
					<dt>양적형질과 질적형질</dt>
					<dd>
						<p>유량이나 유성분량은 7,200kg과 같이 량으로 표현되는 형질이므로 &quot;양적형질&quot; 로 불리운다.  이에 대하여 난산이나 순산(順産)과 같이 분류되는 형질은 &quot;질적형질&quot;이라 불리운다.  착유속도는 실제로는 시간을 측정하면 양적형질이 되지만, 빠르다든지 더디다는 식으로 분류한 경우에는 질적형질이 된다.</p>
						<p>체형형질은, 몸높이라면 직접 측정할 수도 있지만, 예각성(銳角性)등은 직접 측정할 수가 없다. 그 때문에 많은 나라에서는 선형형질(線形形質)이라 하여 1-50 혹은 1-9까지 스코아로 표시하여 대부분 양적형질로서 취급하고 있다. </p>
					</dd>
				</dl>
				<dl>
					<dt>BV와 ETA</dt>
					<dd>
						<p>젖소의 경제형질 대부분은 다수의 유전자가 관여하기 때문에 자우(子牛)의 유전적 능력이 양친의 유전적 능력의 중간에 분포한다. 즉 전형제(全形弟, Fullsib)우가 다수 있으면 그 중에는 좋은 것도 있고, 나쁜 것도 있으나, 양친의 유전적능력의 중간에 가까운 것이 많고, 평균으로 하면 중간에 일치한다.</p>
						<p>이와같은 형질의 유전적 능력을 BV (Breeding Value, 育種家의 약칭)라 불리운다. 또한 자우에 전달되는 유전적 능력의 평균치를 ETA (Estimated Transmitting Ability, 推定 傳達能力의 약칭)라 부른다.</p>
						<p>다시 말하면 EAT = 1/2BV의 관계에 있어 자우의 BV = 아비소의 EAT+ 어미소의 ETA =1/2(아비소의 BV + 어미소의 BV)로 표시할 수가 있다.</p>
					</dd>
				</dl>	
				<table className="tablestyle">
					<caption>&lt;표 24&gt; 유전적 능력평가를 위한 분만월령 보정계수(유량)</caption>
					<thead>
						<tr>
							<th className="first">분만월령</th>
							<th>계 수 </th>
							<th>분만월령</th>
							<th>계 수 </th>
							<th>분만월령</th>
							<th>계 수 </th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td colSpan="2">(초 산)</td>
							<td>39</td>
							<td>0.853</td>
							<td>60</td>
							<td>0.777</td>
						</tr>
						<tr>
							<td>20~22</td>
							<td>1.045</td>
							<td>40</td>
							<td>0.845</td>
							<td>61</td>
							<td>0.775</td>
						</tr>
						<tr>
							<td>23</td>
							<td>1.037</td>
							<td>41</td>
							<td>0.839</td>
							<td>62</td>
							<td>0.774</td>
						</tr>
						<tr>
							<td>24</td>
							<td>1.025</td>
							<td>42</td>
							<td>0.833</td>
							<td>63</td>
							<td>0.773</td>
						</tr>
						<tr>
							<td>25</td>
							<td>1.013</td>
							<td>43</td>
							<td>0.827</td>
							<td>64</td>
							<td>0.772</td>
						</tr>
						<tr>
							<td>26</td>
							<td>1.000</td>
							<td>44</td>
							<td>0.822</td>
							<td>65</td>
							<td>0.770</td>
						</tr>
						<tr>
							<td>27</td>
							<td>0.988</td>
							<td>45</td>
							<td>0.817</td>
							<td>66</td>
							<td>0.769</td>
						</tr>
						<tr>
							<td>28</td>
							<td>0.977</td>
							<td>46</td>
							<td>0.813</td>
							<td>67</td>
							<td>0.768</td>
						</tr>
						<tr>
							<td>29</td>
							<td>0.967</td>
							<td>47</td>
							<td>0.809</td>
							<td>68</td>
							<td>0.768</td>
						</tr>
						<tr>
							<td>30</td>
							<td>0.959</td>
							<td>48</td>
							<td>0.805</td>
							<td>69</td>
							<td>0.767</td>
						</tr>
						<tr>
							<td>31</td>
							<td>0.951</td>
							<td>49</td>
							<td>0.802</td>
							<td>70</td>
							<td>0.766</td>
						</tr>
						<tr>
							<td>32</td>
							<td>0.944</td>
							<td>50</td>
							<td>0.798</td>
							<td>71</td>
							<td>0.765</td>
						</tr>
						<tr>
							<td>33</td>
							<td>0.938</td>
							<td>51</td>
							<td>0.795</td>
							<td>72</td>
							<td>0.764</td>
						</tr>
						<tr>
							<td>34</td>
							<td>0.931</td>
							<td>52</td>
							<td>0.793</td>
							<td>73</td>
							<td>0.764</td>
						</tr>
						<tr>
							<td>35이후</td>
							<td>0.923</td>
							<td>53</td>
							<td>0.790</td>
							<td>74</td>
							<td>0.763</td>
						</tr>
						<tr>
							<td colSpan="2">(2~5산)</td>
							<td>54</td>
							<td>0.788</td>
							<td>75~76</td>
							<td>0.762</td>
						</tr>
						<tr>
							<td>34이전</td>
							<td>0.902</td>
							<td>55</td>
							<td>0.786</td>
							<td>77~78</td>
							<td>0.761</td>
						</tr>
						<tr>
							<td>35</td>
							<td>0.890</td>
							<td>56</td>
							<td>0.784</td>
							<td>79~81</td>
							<td>0.760</td>
						</tr>
						<tr>
							<td>36</td>
							<td>0.879</td>
							<td>57</td>
							<td>0.782</td>
							<td>82~84</td>
							<td>0.759</td>
						</tr>
						<tr>
							<td>37</td>
							<td>0.870</td>
							<td>58</td>
							<td>0.780</td>
							<td>85~88</td>
							<td>0.758</td>
						</tr>
						<tr>
							<td>38</td>
							<td>0.861</td>
							<td>59</td>
							<td>0.778</td>
							<td>89이후</td>
							<td>0.757</td>
						</tr>
					</tbody>
				</table>
				<ul className="refer mg10t mg60f">
					<li>(자료) 가축개량센타교육</li>
				</ul>

				<h2>에니멀 모델(Animal Model)</h2>
				<dl>
					<dt>경제형질에 영향하는 요인</dt>
					<dd>
						<p>젖소의 경제형질에는 유전적능력뿐만 아니라, 여러가지 외부요인이 영향을 미친다.</p>
						<p>유량을 예로 들면, 사양관리나 계절등의 사양환경, 년령등의 생리적인 것, 또는 육성시의 환경등도 영향한다.</p>
						<p>이와 같은 외부요인 가운데, 착유기간이나 착유회수와 같은 분명한 조건의 차이는 보정계수등으로 조건을 같게 할 수가 있다. 비유형질의 경우, 일반으로는 305일간·2회 착유로 조건을 맞추고 있다.</p>
						<p>또한 가축개량센타에서의 유전적능력평가에서는 년령과 산차에 대해서도 초산 26개월령분만으로 환산하고 있다. (표24). 이하, 본편에서는 305일간·2회착유·초산 26개월령 분만 환산유량을 &quot;305일 유량&quot;이라 한다. </p>
					</dd>
				</dl>
				<dl className="mg60f">
					<dt>평가모델</dt>
					<dd>
						<div className="imgBox"><img src={Pic815} alt=""/></div>
						<p>305일 유량은 &lt;그림 25&gt;와 같이, 유전적인 영향을 받는 부분, 사양환경의 영향을 받는 부분과 같이 분해해서 취급할 수가 있다. 유전적인 것이라면 암소자체도, 사양환경이라면 우군자체에, 각각의 영향을 받는 부분에 대하여 플러스 또는 마이너스의 효과가 있다(유전적인 효과=유전적능력), 그 때문에 다음과 같이, 전체의 평균(전평균)에 대한 요인등의 효과의 합으로 표시할 수가 있다.</p>					
						<p>305일 유량=전평균+사양환경의 효과+유전적능력+기타효과</p>
						<p>305일 유량을 y, 전 평균을 μ, 사양환경의 효과를 h, 유전적 능력을 u, 기타효과를 e라 하면, y=μ+h+u+e로 된다. 유전적 능력평가는 이 식으로부터 u를 추정할 수밖에 없다.</p>
						<p>이러한 식을 평가모델이라 부르는데, 특히 젖소개체의 유전적 능력(u), 즉 BV를 포함하는 평가모델을 개체(아니멀)모델이라 칭한다. </p>
					</dd>
				</dl>
					
				<h2>BLUP법</h2> 
				<dl>
					<dt>방정식의 작성</dt>
					<dd>
						<p>다음과 같이, 사양환경의 다른 두가지 우군의 5두분에 대한 305일 유량기록이 있다고 하자.</p>
					</dd>
				</dl>
				<table className="tablestyle" style={{marginTop: '-15px'}}>
					<thead>
						<tr>
							<th className="first">우군 A</th>
							<th>검정우 1 7,000kg</th>
							<th>우군 B</th>
							<th>검정우 4 8500kg</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td></td>
							<td>검정우 2 6,500kg</td>
							<td></td>
							<td>검정우 5 8,00kg</td>
						</tr>
						<tr>
							<td></td>
							<td>검정우 3 8,500kg</td>
							<td></td>
							<td></td>
						</tr>
					</tbody>
				</table>
				<p className="mg15t">이 기록을 아니멀 모델로 표시한다. 우군A의 사양환경효과를 hA와 같이 표시하면, 다음과 같이 5개의 식으로부터 방정식이 나온다.</p>
				<ul>
					<li>7,000=μ+ hA + u1+e1</li> 
					<li>6,500=μ+ hA + u2+e2</li>
					<li>7,500=μ+ hA + u3+e3</li>
					<li>8,500=μ+ hB + u4+e4</li>
					<li>8,000=μ+ hA + u5+e5</li>
				</ul>
				<p>(주) e는 1기록마다의 기타효과를 표시함. </p>
							
				<dl>
					<dt>방정식의 해법</dt>
					<dd>
						<p>이 방정식은 다음과 같은 중학교에서 배운 연립방정식과 닮아 있다.</p>
						<ul className="mg15f">
							<li>25=5X + 3Y </li>
							<li>34=2X + 6Y(해답은 X=2, Y=5)</li>
						</ul>
						<p>즉 연립방정식으로 풀면, hA나 U1의 해답이 나오게 된다. 그런데, ①의 방정식에서는 X나 Y와 같은 미지수가 식의 수보다 많다. 5개의 식에 대하여 13개이기 때문에 풀 수가 없다.</p>
						<p>그 때문에 보다 정확한 추정치를 얻기 위한 방법이 연구되어 있다. 현재, 가장 정확한 추정치를 얻는 방법이 BLUP(Best Linear Unbiased Prediction, 최적선형불편기 예측법의 약칭)법이라 불리워지는 방법이다. </p>
					</dd>
				</dl>
				
				<dl className="mg60f">
					<dt>종모우의 유전적 능력</dt>
					<dd>
						<div className="imgBox"><img src={Pic816} alt=""/></div>
						<p>또한, 숫소는 비유하지 않기 때문에, 방정식중에는 종모우의 유전적능력을 표시하는 미지수가 없다. 즉 유전적능력이 계산되지 않게 된다. 그러나, BLUP법으로는 비유성적이 없는 개체도, 혈연관계를 써서 유전적능력의 추정이 이루어진다.</p>
						<p>예를들면, 검정우(♀) 1~ 5에는 &lt;그림26&gt;의 좌측과 같은 혈연관계가 있다고 하자, 혈연비율은 친자에서 1/2, n대 가면 1/2n이 되지만, 이것은 우측과 같은 행렬(行列)로 표시할 수 있게 된다. BLUP법에서는 &quot;분자혈연행렬&quot;이라 불리우는 이 행렬을 방정식 중에 대입함에 의하여, 혈연관계가 있는 종모우의 유전적능력도 계산된다. 또한, 그것에 의하여 검정우의 유전적능력 추정도 보다 정확해진다.</p>
						<p>그런데, 유전적능력평가를 실시하기 위한 데이터는 지역이나 시기에 편기되지 않게, 가능한한 많은 쪽이 좋다. 그러기 위해서는 일본에서는 현존하는 검정우뿐만이 아니라, 우군검정 개시 당초부터의 전기록이 쓰이고 있다. 이것에 의하여 현존하는 검정의 이미 폐용된 검정우, 혈연상으로만 나타나는 암소, 그들의 아비소인 종모우와 수백만두의 젖소의 유전적능력이 계산되고 있다. </p>
					</dd>
				</dl>

				<h2>기존방법과의 비교</h2> 
				<dl>
				<dt>MGS모델</dt>
					<dd>
						<p>일본에서는 1992년 봄까지 평가모델에는 에니멀모델이 없었고, 종모우 어미쪽 조부(MGS)모델이 사용되어 왔다. 이것은 암소의 유전적능력(BV)에다가, 아비소 및 어미쪽 조부로부터 유전되는 유전적능력의 평균치(ETA)를 더한 것이다. 아비소의 ETA를 S, 어미쪽 조부의 ETA를 mgs라 하면 y=μ+h+(s+1/2mgs)+e가 된다.</p>
						<p>MGS모델의 경우에는 암소의 BV는 계산되지 않고, 아비소 또는 어미쪽 조부인 종모우의 ETA가 계산되는 셈이다. </p>
					</dd>
				</dl>
				<dl>
					<dt>에니멀모델과 MGS모델의 차이점</dt>
					<dd>
						<p>에니멀모델과 MGS모델의 차이를 개념적으로 표시하면 &lt;그림 27&gt;과 같이 된다. MGS모델에는 딸소의 비유성적으로부터 아비소 또는 어미쪽 조부인 종모우의 ETA가 추정된다. 이것에 대하여 에니멀모델에서는 딸소뿐만아니라 모든 암소의 비유성적으로부터 암소뿐만 아니라 혈연관계에 있는 종모우를 포함하는 전 개체의 BV가 추정된다.</p>
					</dd>
				</dl>	
				<div className="imgBox"><img src={Pic817} alt=""/></div>
				<p>이것을 종합하면, 2개의 평가모델에는 다음과 같은 차이가 있다. 즉 현재에서는 유전적능력평가를 실시하고 있는 대부분의 나라에서 에니멀모델이 채용되고 있다.</p>
				<ul className="listStyle04" style={{marginBottom: '60px'}}>
					<li>에니멀모델이 암수표시평가법인데 대하여, MGS모델은 종모우평가법이다.</li>
					<li>에니멀모델에서는 BV가 추정되는데 대하여, MGS모델에서는 ETA가 추정된다.</li>
					<li>MGS모델은 어미쪽 조모로부터의 유전이 고려되지 않는다는 것이라든지, 암소에 실제로 전달된 유전적능력과 ETA에 차이가 있기 때문에, 에니멀모델에 비하여 오차가 크다.(에니멀모델쪽이 정확하다)</li>
					<li>에니멀모델에서는 암소의 유전적능력도 계산하기 때문에, 종모우만의 MGS모델보다도 계산량이 대폭 늘어난다. </li>
				</ul>
					
				<p className="viewTitle">유전능력평가의 실제</p>
				<h2>실시기관과 평가과정</h2>
				<p>종모우와 암소(우군검정우)의 유전적능력평가는 현재, 가축개량센타에서 년2회 실시되어, 2월과 8월에 공표되고 있다. 유전적능력평가는 말하자면 개인의 재산을 평가하는 것이나 다름없기 때문에, 여러 외국에서도 공적기관이 실시하고 있는 경우가 많다.</p>
				<p>유전적능력평가에 쓰여지는 데이터는 크게 나누면 우군검정데이타(비유형질, 분만난이), 체형조사 데이터(체형형질, 기질, 착유성). 혈연데이타의 세가지로 이루어진다.</p>
				<p>우군검정데이타는 (사)가축개량사업단에서, 체형조사데이타와 혈연데이타는 (사)일본 홀스타인 등록협회에서 정리되어, 가축개량센타로 송부된다.</p>
				<p>가축개량센타에서는, 첵크한 다음, 평가에 쓰는 데이터를 작성하여 계산을 실시한다. 그 결과는 종보우에 대한 것은 &quot;유용종모우평가성적&quot;(아까모토)등에 게재되고, 우군검정우에 대해서는 &quot;우군개량정보&quot;로 발간되어 각 우군검정농가에 통지되고 있다.</p>
				<p className="mg60f">또한, 가축개량센타에서는 평가개요 뿐만이 아니고 그것에 대한 상세한 관련정보등에 관하여 &quot;가축개랴야센타 유용우평가보고&quot;로 발간하여 공표하고 있다. </p>

				<h2>평가형질</h2>
				<p>1997년 제 2회 평가시점에서, 유전적능력평가가 이루어지고 있는 형질은, 〈표25〉와 같이 비유 7개형질, 체형 21개형질, 관리형질 3개형질(종모우것)이다.</p>
				<p>비유형질에는 1~5산의 데이터가 사용되고 있으나, 초산 26개월분만성적으로 환산되어, 초산시의 비유형질로서 평가되고 있다. 또한 유지율, 무지고형분율, 유단백질율은 이전에는 각각 직접 평가되었으나, 현재는 유량과 각각의 성분량으로부터 간접적으로 계산되고 있다.</p>
				<p>체형형질은 초산시의 심사에 기초하고 있다. 이번부터는 종래에 하던 &quot;일반외모&quot;난이 없어지고, 외모, 지제(肢蹄), 유두(乳頭)의 길이가 새로히 첨가되었다.</p>
				<p>또한, 관리형질의 평가는, 종모우에 대한 것은 1997년 제 1회 평가부터 실시되고 있다. 분만난이는 딸소의 분만난이(分娩難易)가 아니라, 그 종모우를 교배하여 수태한 경우의 어미소의 초산분만에 대한 난이를 말한다. 즉, 태내자우(胎內子牛)의 아비소에 의한 효과이다. 기질(氣質)과 착유성도 초산시의 것이다.</p>
				<p>관리형질은 어느경우이든 질적형질이며, 분만난이는 순산(順産)과 난산(難産), 기질은 &quot;포악 및 신경질&quot;과 &quot;온화&quot;, 착유성은 &quot;빠르다&quot;, &quot;보통&quot;, &quot;더디다&quot;로 분류된 데이터에 기초하여 분석되고 있다.</p>
				<p>그런데, 유전율은 유전적으로 영향을 어느정도 받는 것인가를 나타내는 지표로서, 0~1의 수치를 준다. 1이면 완전유전, 0이면 유전적인 영향을 전혀 받지 않는 것을 의미한다. 그 때문에 유전율이 낮은 형질일수록 유전적능력에 관심을 가질 의미가 낮을뿐 아니라 또한, 평가치의 정확성도 낮아진다. 분만난이의 0.04는 극히 낮은 수치이고, 가축개량센타에서도 참고정도로 이용하는 것으로 하고 있다.</p>
				
				<div className="tableWrap">
					<table className="tablestyle">
						<caption>&lt;표 25&gt; 평가형질(1997년 제2회 평가)</caption>
						<thead>
							<tr>
								<th className="first">구 분</th>
								<th>유전율(반복율)</th>
								<th>구 분</th>
								<th>유전율(반복율)</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>비유형질</td>
								<td></td>
								<td rowSpan="9">체심<br/>예각성<br/>엉덩이각도<br/>엉덩이폭<br/>후지측방<br/>제각도<br/>전유방부착<br/>후유방높이<br/>후유방폭<br/>유방부착(현수)<br/>유방깊이<br/>후두배치(후방)<br/>유두길이</td>
								<td rowSpan="9">0.31<br/>0.15<br/>0.17<br/>0.26<br/>0.17<br/>0.08<br/>0.12<br/>0.14<br/>0.20<br/>0.10<br/>0.23<br/>0.22<br/>0.52</td>
							</tr>
							<tr>
								<td>유량 </td>
								<td rowSpan="4">0.30(0.52)</td>
							</tr>
							<tr>
								<td>유지량 </td>
							</tr>
							<tr>
								<td>무지고형분량</td>
							</tr>
							<tr>
								<td>유단백질량</td>
							</tr>
							<tr>
								<td>유지율</td>
								<td rowSpan="3">유량과 각성분 양으로부터 <br/>간접적으로 계산</td>
							</tr>
							<tr>
								<td>무지고형분율</td>
							</tr>
							<tr>
								<td>유단백질율 </td>
							</tr>
							<tr>
								<td>체형형질(득점)</td>
								<td></td>
							</tr>
							<tr>
								<td>외모</td>
								<td>0.38</td>
								<td rowSpan="6">관리형질-종모우별</td>
								<td rowSpan="6"></td>
							</tr>
							<tr>
								<td>지제</td>
								<td>0.17</td>
							</tr>
							<tr>
								<td>유용우의 특질</td>
								<td>0.17</td>
							</tr>
							<tr>
								<td>체적</td>
								<td>0.32</td>
							</tr>
							<tr>
								<td>유기</td>
								<td>0.10</td>
							</tr>
							<tr>
								<td>결정득점</td>
								<td>0.23</td>
							</tr>
							<tr>
								<td>체형형질(선형)</td>
								<td></td>
								<td rowSpan="3">분만난이(태내자우의아비소)<br/>기질<br/>착유성(속도)</td>
								<td rowSpan="3">0.04<br/>0.11<br/>0.20</td>
							</tr>
							<tr>
								<td>높이</td>
								<td>0.36</td>
							</tr>
							<tr>
								<td>강건성</td>
								<td>0.24</td>
							</tr>
						</tbody>
					</table>
				</div>
				<p className="mg60f">또한, 여러 외국에서는 이 이외에도 유전적능력평가가 실시되고 있는 형질이 있다. 그들 가운데, 체세포수에 대하여는 일본에서도 데이터의 축적이 진행되어 왔기 때문에, 가축개량센타에서도, 이에 대한 유전적능력평가의 준비가 진행되고 있다. </p>

				<h2>평가모델</h2>
				<p>어떤 형질도 분석에는 BLUP법이 쓰여지고 있다. 또한 관리형질에 있어서 질적형질에 대응한 &quot;특수모델&quot;을 써서 고도한 수법으로 분석하고 있다.</p>
				<p>평가모델은 양적형질과 질적형질의 차이, 영향을 미치는 요인의 차이, 데이터의 질과 량의 차이등이 보정된 후, 비유형질, 체형형질, 기질·착유성, 분만난이 4가지로 구분된다. 비유형질과 체형형질은 에니멀모델로 하지만, 기지리과 착유성에는 MGS모델이 쓰이고 있다. 또한, 분만난이에 대해서는 유전적능력평가의 대상은 태내 자우의 아비소이지만, 어미소를 통하여 그 아비소도 영향을 받기 때문에, 그 효과가 고려되는 &quot;모성효과 대응모델&quot;이 쓰여지고 있다.</p>
				<p>구체적인 형질별 평가모델은 &lt;그림 28&gt;과 같다. 유전적능력의 평가는 일생의 기록으로부터 유전적능력이외의 요인의 영향을 가능한 한 제외하므로 정확성이 높아진다. 가축개량센타에서는 여러 가지 분석을 실시하여, 현시점에서 가장 정확성이 높아지도록 평가모델을 설정하고 있다.</p>
				<p className="mg30f">평가모델에 취택되고 있는 요인에 대해서는, 유전적능력에서와 같은 모양으로 평가치가 산출된다. 예를들면, 관리 그룹별 사양환경의 효과를 제거하면, 그 평가치가 산출된다. 이하, 형질별로 약간씩 보완한다. </p>

				<dl>
					<dt>비유형질</dt>
					<dd>
						<div className="imgBox"><img src={Pic818} alt=""/><br/>(그림 28) 평가모델&lt;1997년 제 2회 평가&gt;</div>
						<p>산차와 분만월령의 효과는 초산 26개월 분만에 맞추어 보정계수를 써서 미리 보정한다. 그 때문에 평가모델에는 개체의 BV이외, 관리그룹의 효과, 계절의 효과, 항구적 환경효과등이 제거된다.</p>
						<p>어떤 낙농가에서 같은 시기에 분만한 암소는 유기 내내 거의 같은 사양환경에 있다고 생각된다. 단지, 초산은 능력이 불명하거나 성장과정에 있다는 것 때문에, 초산과 2산이후에는 다른 경우도 생각할 수 있다. 그 때문에 사양환경의 효과를 보정하기 위한 관리그룹은 낙농가별, 분만년별, 산차(초산 혹은 2산이후)별로 구분되고 있다. A낙농가에서 금년 초산 분만한 소가 3두가 있다고 하면, 2,3두는 같은 사양환경의 효과를 받는다고 생각한다.</p>
						<p>&quot;계절에 의한 차이도 있기 때문에 같은 해에 출산되면 같은 사양환경이라 생각하는 것은 너무 조잡하지 않느냐&quot;하는 우려도 있지만, 너무 세분하면 반대로 부정확 해지기 때문에, 계절의 효과는 별도로 취급하여 보정하고 있다. 그 계절의 효과는 북해도와 도부현별로 분만월별로 24개로 구분되고 있다. 항구적환경효과라는 것은 육성의 영향과 같이 유전적인 것은 아니지만, 그 암소의 전산차에 공통되는 효과이다. </p>
					</dd>
				</dl>
				
				<dl>
					<dt>체형형질</dt>
					<dd>체형형질에서는 개체의 BV이외에, 심사그룹의 효과, 심사일의 월령효과, 심사시의 비유기의 효과가 제거되고 있다. 데이터가 1두당 초산심사의 1기록을 의미하기 때문에, 항구적환경효과는 제거될 수 없다. 심사그룹의 효과에는 태내자우의 아비소의 ETA와 모성효과의 일부인 본개체의 아비소의 ETA가 제거되고 있다. 종모우평가의 대상은 태내자우의 아비소의 ETA이다. 또한 외부요인으로서는 낙농가별, 분만년별로 구분한 관리 그룹의 효과, 분만시월령의 효과, 산자의 성별 효과가 제거되어 있다.</dd>
				</dl>
				
				<dl className="mg60f">
					<dt>기질과 착유성</dt>
					<dd>기질과 착유성의 평가모델에는 심사우의 아비소와 어미쪽 조부의 ETA이 외에, 외부요인으로서는 데이터가 체형심사시에 청취응답식으로 기록되는 것이므로, 체형형질에서와 같이 심사그룹효과, 심사일의 월령효과, 심사일의 비유기의 효과가 제거되고 있다.</dd>
				</dl>
				
				<p className="viewTitle">유전적능력평가치등의 정보</p>
				<h2>제공되는 정보</h2>
				<dl>
					<dt>유용종모우평가성적</dt>
					<dd>년2회의 유전적능력평가 결과중, 종모우에 대해서는 정확성의 관점에서 설치한 기준(5호이상의 낙농가에 15두 - 분만난이별 50두 - 이상의 딸소가 있을 것)을 만족시키는 것이 가축개량센타에서 발표되어 &quot;유용종모우 평가서적&quot;으로, 우군검정농가를 시작으로 하여 관계자에게 통지되고 있다. <br/>(그림 29)는 &quot;유용종모우 평가성적(1997-Ⅱ)&quot;에서 1두분의 정보를 예시한 것이다.</dd>
				</dl> 
				<div className="imgBox"><img src={Pic819} alt=""/></div>
				<ul className="listStyle04">
					<li>A는 종모우명호, 약호, 등록번호, 생년월일, BLAD의 검사결과, 체형득점</li>
					<li>B는 아비소 및 어미쪽 조부의 등록번호와 명호이다.</li>
					<li>C가 비유형질의 유전적능력평가치이다. 유량(M), 유지량(F), 유지율(F%), 무지고형 분량(SNF%), 무지고형분율(SNF%), 유단백질량(P), 유단백질율(P%)의 유전적능력이 ETA±신뢰폭으로 표시되어 있다.</li>
					<li>D유량의 신뢰도</li>
					<li>E는 유량 및 유지방, SNF, 유단백질 각각의 데이터를 가진 딸소가 있는 우군(낙농가)수와 딸소의 두수, 그와 함께 경과중 기록율(딸소 중 초산유기 도중에 있는 것의 비율)로 되어 있다. 신뢰도가 낮은 경우라든지 딸소수가 적은 경우, 경과중 기록율이 높은 경우에는 다음번 평가에서 성적이 변동할 가능성이 상대적으로 높다.</li>
					<li>F~H에는 체형형질 가운데 득점관계에 대하여 같은 모양으로 표시되어 있다. 우군수 및 딸소두수는 선형형질도 같은 수로 되어 있다.</li>
					<li>I은 유량과 각 성분율, 전체형형질의 STA((나)참조)를 그래프로 표시한 것이다.</li>
					<li>STA의 수치는 그래프가 오른쪽이면 플러스, 왼쪽이면 마이너스이다.</li>
					<li>J는 분만난이, 기질, 착유성의 유전적능력지수(뒤에 이야기 함)를 표시한 것이다.</li>
					<li>K는 각 형질의 ETADP 중첩해서 계산되는 경제효과와 총합지수(NPT)이다.</li>
				</ul>
				<p className="mg15t">그런데 1998년 제 1회 평가부터는 뒤에 이야기하는 바와 같이 ETA가 EBV로, 경제효과가 유대효과로 변하기 때문에, 다소 평가결과도 변하기 마련이다. </p>

				<dl className="mg60f">
					<dt>우군개량정보</dt> 
					<dd>우군검정우의 평가치는 &quot;우군개량정보&quot;로써, 각각의 소유자인 낙농가에 통지된다. 검정우 1두별의 최근산차, 검정일수 등과 함께 비유형질의 ETA, EPA(추정생산능력의 약칭), 경제효과, 생산효과, 총합지수(NTP)와 전국의 검정우중에서의 순위(상위로부터의 %)가 표시된다. 우군의 개량상황을 나타내는 우군내 생년별 평군치도 제공된다. 또한 분만년별의 관리그룹과(+전 평균)가, 사양관리수준의 지표로써 제공된다.  </dd>
				</dl>
				
				<h2>정보의 내용과 보는 방법</h2> 
				<dl>
					<dt>유전적능력평가치(ETA로부터 EBV으로의 변경등)</dt>
					<dd>ETA에 대해서는, 1998년 제 1회 평가부터 아래와 같이 표시법이 변경된다. 이 변경은 5편 (3)의 후대검정 강화의 일환으로서, 인터 - 볼의 권고(1990)에 연유한 것이다. 그래서 1997년 여름시점에 있어서 여러 외국의 표시법등을 종합하면 (표26)과 같이 되어 있다.</dd>
				</dl>
				<dl>
					<dt>이동베이스로부터 스테·와이즈·베이스로의 변경</dt>
					<dd>
						<p>유전적능력은 이용하기 쉽게, 기준(±0의 점)을 정해서, 그로부터의 플러스·마이너스량으로 표시된다. 이 기준을 유전베이스라 부르지만 종래에는 8년전에 출생된 암소의 평균치로 되어 있다. 이와 같이 매년 이동하는 방식을 이동베이스라 칭하는데, 인터 - 볼에서는 5년마다에 이동시키는 스테·와이즈·베이스를 추천하고 있다.</p>
						<p>변경에 의하여, 1999년까지는 1990년에 출생된 암소의 평균치, 2000년부터 2004년까지는 1995년에 출생된 암소의 평균치가 베이스로 된다.</p>
						<p>이것에 의하여, 종래에는 같은 종모우 혹은 암소의 평가치가 매년 조금씩 내려가는 현상이 보였으나, 금후 5년간은 움직이지 않게 된다. 즉 지금까지 평가치의 변동이 딸소수의 증가등에 의하여 정확성이 향상된 것이지, 베이스 이동에 의한 것인지 불명료했던 점이 해소되었다.</p>
					</dd>
				</dl>
										
				<table className="tablestyle mg30f">
					<caption>&lt;표 26&gt; 여러 외국에 있어서 비유형질의 유전적능력평가치의 표시법 등</caption>
					<thead>
						<tr>
							<th className="first">구 분</th>
							<th>표 시</th>
							<th>유 전 베 이 스</th>
							<th>공표회수</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>일 본</td>
							<td>ETA</td>
							<td>이 동 (평가의 8년전에 출생된 베베이스 베이스 암소의 평균)</td>
							<td>년 2회</td>
						</tr>
						<tr>
							<td>미 국</td>
							<td>ETA(PTA)</td>
							<td>스 테· (1990년에 출생된 암소의 와이즈· 평균 : 5년마다 이동) 베이스</td>
							<td>년 4회</td>
						</tr>
						<tr>
							<td>카나다</td>
							<td>BV(EBV)</td>
							<td>이 동 (평가의 2년전에 초산분만 베이스 한 암소의 평균)</td>
							<td>년 4회</td>
						</tr>
						<tr>
							<td>화 란</td>
							<td>BV</td>
							<td>스 테· (1990년에 출생된 암소의  와이즈· 평균 : 5년마다 이동)</td>
							<td>년 2회</td>
						</tr>
						<tr>
							<td>불란서</td>
							<td>BV</td>
							<td>이 동 (평가의 7~10년전에 베이스 후대검정에 들어간 종모우의 딸소 평균)</td>
							<td>년 3회</td>
						</tr>
						<tr>
							<td>인터 -볼의 추천</td>
							<td>BV</td>
							<td>스 테· (1990년에 출생된 암소의 와이즈· 평균 : 5년마다 이동) 베이스</td>
							<td>년 2회이상</td>
						</tr>
					</tbody>
				</table>
				
				<dl>
					<dt> ETA로부터 EBV로 변경</dt>
					<dd>
						<p>유전적능력의 평가치는 종래의 ETA에 의한 표시로부터 EBV(추정BV)에 의한 표시로 변경된다. 대체로 일본에서는 1992년 제 1회 평가까지는 ETA와 BV가 같이 쓰였다. 그후, 에니멀 모델이 채용된 1992년 제 2회 평가에서는 ETA로 통일되어 현재에 이르고 있다.</p>
						<p>아니멀모델에서는 BV가 산출되는 것, 또는 이미 인터 - 볼의 권고에서는 BV가 추천되어 있었다는 사실을 생각하면 ETA로의 통일은 기술적으로도 우스운 일이었다. 그러나, 미국이 PTA표시를 했던 일도 있고, 관계자가 PTA와 같은 ETA로의 통일을 강력히 요망하였기 때문에 이러한 조치를 취하게 되었던 것이다.</p>
					</dd>
				</dl>
				<dl>
					<dt>STA로부터 SBV로의 변경</dt>
					<dd>
						<p>ETA로부터 EBV로의 변경에 따라 STA(Standerized Transmitting Ability, 표준화전달능력의 약칭)는 SBV(Standerized Breeding Value, 표준화육종가의 약칭)로 변경된다. SBV는 다음식에 의하여 구해진다. STA=SBV가 되기 때문에 실질적으로는 용어가 바뀐것에 불과하다. SBV는 전종모우의 평균보다 플러스인가 마이너스인가, 그 정도를 표시하는 것으로 이론적으로는 ±1의 범위에 약 70%, ±2의 범위에 95%가 포함된다.</p>
						<p>즉 +2.0이라면, 높은쪽으로부터 2~3%의 성적인 것을 표시하고 있다.</p>
						<p>SBV = 해당종모우의 EBV-전종모우의 EBV의 평균치</p>
						<p>전종모우의 EBV의 표준편차</p>
					</dd>
				</dl>
				<dl>
					<dt>신뢰도 및 신뢰폭</dt>
					<dd>
						<p>유전적능력평가치는 하나의 수치로서 산출되지만, 추정치이지 진정한 값(眞價)은 아니다. 그 때문에 정확성의 지표로서, 신뢰도나 신뢰폭이 동시에 표시되고 있다.</p>
						<p>신뢰도는 1~99%로 표시되고, 수치가 클수록 정확서이 높다는 것을 의미한다. 100%이면 진정한 값이다. 또한 신뢰폭은 (표27)과 같이 신뢰도로부터 산출되는데, 신뢰도가 높을수록 폭이 적어진다. 신뢰폭은 그 범위내에 약 70%의 확률로 진정한 값이 있다는 것을 표시하고 있다.</p>
						<p>그리고, 평가치의 정확성은 딸소(혹은 혈연우)의 두수와 그들의 데이터에 편기가 없는것에 의하여 결정되지만, 신뢰도나 신뢰폭은 데이터에 편기가 없다는 것을 전제로 하여 산출된다. 그 때문에 데이터에 편기가 있는 경우에는 예를 들어 신뢰도가 99%로 계산되어도, 실제로는 정확하지 않은 것으로 된다.(4편 (3)의 ② 참조)</p>
					</dd>
				</dl>
				
				<p><strong>신뢰폭√390,773×(1 - 신뢰도/100)</strong></p>
				<table className="tablestyle mg30f">
					<caption>&lt;표 27&gt; 일본의 유전적능력평가에 있어서 신뢰도와 신뢰폭의 관계(유량)</caption>
					<thead>
						<tr>
							<th className="first">신 뢰 도</th>
							<th>EBV 신뢰도 </th>
							<th>딸소수의 목표</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>60% </td>
							<td>395kg </td>
							<td>-</td>
						</tr>
						<tr>
							<td>65% </td>
							<td>370kg </td>
							<td>15두  </td>
						</tr>
						<tr>
							<td>70% </td>
							<td>342kg </td>
							<td>-&nbsp;&nbsp;&nbsp;&apos;89 ~&apos;92후검의 실적두수</td>
						</tr>
						<tr>
							<td>75% </td>
							<td>313kg </td>
							<td>28두&nbsp;&nbsp;&nbsp;&apos;89 ~&apos;92후검의 실적두수</td>
						</tr>
						<tr>
							<td>80%</td>
							<td>280kg </td>
							<td>36두&nbsp;&nbsp;&nbsp;&apos;94후검까지의 계획두수</td>
						</tr>
						<tr>
							<td>85% </td>
							<td>242kg </td>
							<td>50두&nbsp;&nbsp;&nbsp; &apos;95후검이후의 계획두수</td>
						</tr>
						<tr>
							<td>80%</td>
							<td>198kg </td>
							<td>50두&nbsp;&nbsp;&nbsp;&apos;95후검이후의 계획두수</td>
						</tr>
						<tr>
							<td>95% </td>
							<td>140kg </td>
							<td>-</td>
						</tr>
						<tr>
							<td>99%</td>
							<td>63kg</td>
							<td>-</td>
						</tr>
					</tbody>
				</table>
				
				<dl>
					<dt>관리형질의 평가치</dt>
					<dd>
						<p>분만난이, 기질, 착유성에 대하여는 데이터가 청취한 것으로 되어 있어, 다분히 감각적인 것이거나, 분만난이에 대해서는 유전율이 극히 낮은 경우가 있어서, 가축개량센타에서는 이들 평가치에 의한 선발은 적당하지 않고, 보조적인 정보로서 이용하도록 조치되고 있다.</p>
						<p>그 때문에 유전적능력평가치의 표시는 ETA나 STA가 아니고, 평균 ±1 표준편차의 범위를 100, 평균 ±3 표준편차 이상을 103으로 하는 것처럼, 97 ~103까지의 7단계로 표시하고 있다. 각 형질의 평가치에 대한 보는 방법은 다음과 같다. 확률적으로는 102이상, 98이하의 종모우는 각각 2 ~3%이다.</p>
					</dd>
				</dl>
				<table className="tablestyle mg30f">
					<thead>
						<tr>
							<th className="first">구 분</th>
							<th>분만난이</th>
							<th>기 질</th>
							<th>착 유 성</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>102 ~103</td>
							<td>미경산우에 교배한 경우 난산이 비교적 적음</td>
							<td>온순성이 비교적 높음</td>
							<td>착유가 비교적 빠름</td>
						</tr>
						<tr>
							<td>99 ~ 101</td>
							<td>보 통</td>
							<td>보 통</td>
							<td>보 통</td>
						</tr>
						<tr>
							<td>97 ~ 98</td>
							<td>미경산우에 교배한 경우 난산이 비교적 많음</td>
							<td>온순성이 비교적 낮음</td>
							<td>착유가 비교적 더딤</td>
						</tr>
					</tbody>
				</table>
				<div className="imgBox"><img src={Pic820} alt=""/></div>

				<dl>
					<dt>경제효과(유대효과로 변경)</dt>
					<dd>
						<p>종래, 형질별의 ETA이외에, 유량, 유지량,무지고형분량의 ETA에 비중을 두어 만든 지수로서 경제효과가 산출되어 왔다.</p>
						<p>이 비중은 1989년도에 유용종모우 평가를 개시할 때, 무지고형분을 고려한 유성분베이스의 거래가 진전될 것을 예상하여, 우유·유제품의 가격으로부터 산출되는 각각의 가치등에 기초하여 설정되었다.</p>
						<p>그 후, 유가변동등에 대응한 계수의 수정이 이루어져서, 1994년도에 유성분등 평가거래추진위원회가 이를 제시하였고, 그후, 전국적으로 도입된 원유거래방식과 비교하면, 유지량에 비중을 둔 지수가 되어 있다.</p>
						<p>또한 경제효과로 부르는 용어에 대해서는, 본래 유대(乳代)로 환산한 경우의 효과뿐 아니고, 강건성이나 체세포수등의 효과도 고려되기 마련이다. 가축개량센타에서 그러한 지수개발을 해 줄 것을 기대하는 목소리도 있다.</p>
						<p>그 때문에, ETA를 EBV로 변경하는 것등에 따라 용어를 순수하게 유대로 환산한 것이라 하는 의미로 유대효과라하여, 산출식을 현재 도입이 추진되고 있는 원유거래에 대응한 대책으로 내놓게 되었는데 개요는 다음과 같다.</p>
					</dd>
				</dl> 

				<table className="tablestyle mg30f">
					<caption>(유성분등 평가거래추진위원회가 제시한 거래기준)</caption>
					<thead>
						<tr>
							<th className="first">구 분</th>
							<th>유 지 율</th>
							<th>SNF율</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>기 준 치 </td>
							<td>3.5% </td>
							<td>8.3% </td>
						</tr>
						<tr>
							<td>격차금(0.1%)</td>
							<td>0.4엔</td>
							<td>0.4엔</td>
						</tr>
					</tbody>
				</table>
				<p>(산출식) <br/>경제효과=11.91×ETA·M+882×ETA·F+422×ETA·S <br/>유대효과=EBV·M×기준치환산유가+〔EBV·F×(EBV·F%+F%90-3.5)+M90×EBV·F%〕×4 +〔EBV·M×(EBV·S% + S%90-8.3)+M90×EBV·S%〕×4</p>
				<p>(주)M은 유량, F는 유지량, S는 무지고형분량, F% 유지율, S%는 무지고형분율, 또한 M90, F%90, S%90은 각각 베이스년(1990년)에 출생된 암소의 평균유량, 평균유지율, 평균무지고형분율을 표시함.</p>
				<p>산출식은 어렵게 보이지만, (그림 30)에서 보는 바와 같이, 유량이 유전적으로 우수한 부분과 유지율 및 무지고형분율이 우수한 부분에 상응하는 유대를 서로 합한 형태의 것이다.</p>
				<p>경제효과에 비하한, 유량과 무지고형분의 비중의 약간 커지고, 대신 유지방의 비중이 약간 저하한다. 그런데 이미, 앞서 말한 원유거래는 과반수의 도부현에서 실시되고 있다. 또한 북해도에서는 독자적인 유성분거리가 실시되고 있어, 유지율의 비중이 무지고형분율보다 약간 커져 있지만, 계산식을 변경해도 거의 차이가 없기 때문에, 실용상의 문제는 없다고 생각된다. </p>

				<dl>
					<dt>총합지수(NPT)</dt>
					<dd>(사)일본홀스타인 등록협회가 개발을 추진하여, 1996년부터 산출되고 있는 NPT(7편 참조)에 대해서는, 금후에도 종래대로 표시된다. </dd>
				</dl>
				<dl className="mg60f">
					<dt>암소평가에 수반하는 기타 정보</dt>
					<dd>
						<p>암소에 대해서는, EBV와 항국적환경효과의 합으로 이루어지는 EPA(Estimated Production Ability, 추정생산능력의 약칭)가 산출되고 있다. EPA는 사양환경등의 조건이 같을 경우 그 암소의 생산성을 나타내는 것이다. EBV가 낮으면 후계우 생산에는 적합하지 않으나, EPA가 높으면 그 암소 자신의 생산능력을 높은 것으로 된다.</p>
						<p>또한, EPA에 기초하여, 경제효과와 같은 모양의 비중을 두므로서 생산효과가 산출되고 있다. 생산효과는, 유대효과로의 변경함에 따라 산출식이 변경되지만, 용어는 그대로 쓰여질 예정이다. </p>
					</dd>
				</dl>
				
				<p className="viewTitle">1997년 제 2회 평가 결과 </p>
				<h2>평가 개요</h2>
				<p>평가에는 비유형질에서 약 200만두의 약 500만 유기(乳期), 체형형질에서 약 30만두, 분만난이, 기질, 착유성에서 약20만두의 데이터가 이용되고 있다. 유전적능력이 추정된 종모우는 비유형질에서 6,624만두, 그 가운데 공표기준을 만족시킨 것이 1,810두, 암소는 208만두, 그 가운데 현존하는 검정우는 39만두였다.</p>
				<p className="mg60f">이들의 평가결과에 대해서는, 가축개량센타 유용우평가보고등에 공표되고 있지만, 이하, 유량을 예로하여 일부를 소개한다. </p>

				<h2>유전적능력의 분포와 개량량</h2> 
				<p>현 검정우와 정액공급 가능한 종모우의 유량에 대한 ETA는 (표 28)과 같이 분포하고 있다. 현 검정우는 +0 ~+400kg범위 내에 약 2/3가 집중되어 있고, 종모우는 75두가 +600kg이상 이었다. 이 결과로 보아, 현재 공용되고 있는 종모우로 국내의 암소들을 충분히 개량할 수 있다는 사실을 알 수 있다.</p>

				<table className="tablestyle">
					<caption>&lt;표 28&gt; 1997년 제 2회 평가에 있어서 ETA의 분포(유량)</caption>
					<thead>
						<tr>
							<th className="first">구 분</th>
							<th>현 검 정 우</th>
							<th>정액공급가능 종모우</th>
						</tr>
					</thead>
					<tfoot>	
						<tr>
							<td>계</td>
							<td>387,944(100.0)</td>
							<td>139(100.0)</td>
						</tr>
					</tfoot>
					<tbody>
						<tr>
							<td>+1,000kg이상 </td>
							<td>68(0.0) </td>
							<td>0 </td>
						</tr>
						<tr>
							<td>+800 ~+1,000kg </td>
							<td>558(0.1) </td>
							<td>16(11.5) </td>
						</tr>
						<tr>
							<td>+600 ~+800kg </td>
							<td>7,013(1.8) </td>
							<td>59(42.4) </td>
						</tr>
						<tr>
							<td>+400 ~+600kg </td>
							<td>50,372(13.0) </td>
							<td>53(38.1) </td>
						</tr>
						<tr>
							<td>+200 ~+400kg </td>
							<td>128,452(33.1) </td>
							<td>7(5.0) </td>
						</tr>
						<tr>
							<td>0 ~+200kg</td>
							<td>125,704(32.4) </td>
							<td>4(2.9) </td>
						</tr>
						<tr>
							<td>-200 ~ 0kg </td>
							<td>60,467(15.6) </td>
							<td>0 </td>
						</tr>
						<tr>
							<td>-400 ~-200kg</td>
							<td>13,764(3.5) </td>
							<td>0 </td>
						</tr>
						<tr>
							<td>-600 ~-400kg</td>
							<td>1,457(0.4) </td>
							<td>0 </td>
						</tr>
						<tr>
							<td>-600kg미만</td>
							<td>89(0.0)</td>
							<td>0 </td>
						</tr>
					</tbody>
				</table>
				<ul className="refer mg10t mg30f">
					<li>(자료) 가축개량센타 육용우 평가보고(1997 - Ⅱ)</li>
				</ul>
				
				<table className="tablestyle">
					<caption>&lt;표 20&gt; 총합검정개시후 암소 년당 유전적개량량</caption>
					<thead>
						<tr>
							<th className="first">구 분</th>
							<th>종모우평가개시이전(1998 ~1989년생)</th>
							<th>종모우평가개시이후 (1989 ~1993년생)</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>유 량(kg) </td>
							<td>57</td>
							<td>126</td>
						</tr>
						<tr>
							<td>유 지 량(kg) </td>
							<td>3.9</td>
							<td>4.7</td>
						</tr>
						<tr>
							<td>유 단 백 질 량(kg) </td>
							<td>1.7</td>
							<td>3.8</td>
						</tr>
						<tr>
							<td>무 지 고 형 분 량(kg) </td>
							<td>5.2</td>
							<td>11.0</td>
						</tr>
						<tr>
							<td>유 지 율(%) </td>
							<td>0.02</td>
							<td>0.00</td>
						</tr>
						<tr>
							<td>유 단 백 질 율(%)</td>
							<td>0.00</td>
							<td>0.00</td>
						</tr>
						<tr>
							<td>무 지 고 형 분 율(%)</td>
							<td>0.00</td>
							<td>0.00</td>
						</tr>
						<tr>
							<td>결 정 득 점 </td>
							<td>0.01</td>
							<td>0.01</td>
						</tr>
						<tr>
							<td>유 기</td>
							<td>0.02</td>
							<td>0.03</td>
						</tr>
						<tr>
							<td>전 유 방 부 착 </td>
							<td>0.00</td>
							<td>0.02</td>
						</tr>
						<tr>
							<td>후 유 방 높 이 </td>
							<td>0.05</td>
							<td>0.12</td>
						</tr>
						<tr>
							<td>후 유 방 폭 </td>
							<td>0.06</td>
							<td>0.05</td>
						</tr>
						<tr>
							<td>유 방 헌 수 </td>
							<td>0.03</td>
							<td>0.08</td>
						</tr>
						<tr>
							<td>유 방 깊 이</td>
							<td>-0.04</td>
							<td>-0.01</td>
						</tr>
						<tr>
							<td>유 두 위 치</td>
							<td>0.06</td>
							<td>0.07</td>
						</tr>
						<tr>
							<td>(참고) 유방성분(주) </td>
							<td>0.00</td>
							<td>0.01</td>
						</tr>
					</tbody>
				</table>
				<ul className="refer mg10t mg30f">
					<li>(주) NPT(7편(2)의 (가)참조)의 유방성분으로 환산한 것</li>
					<li>(자료) 가축개량센타 유용우평가보고(1997 -Ⅱ)</li>
				</ul>
				<p>또한, 3편 (4)에서 이야기한바와 같이, 전국통일의 종모우평가가 개시도니 1989년을 경계로 하여, 일본 젖소의 개량량은 비약적으로 향상하였다. (그림 31)은 유량에 대하여 암소의 유전적능력의 생년별 평균치를 표시한 것이다. 이 그림이 결국 젖소개량의 실태를 보여주고 있다.</p>
				<div className="imgBox"><img src={Pic821} alt=""/></div>
				<p className="mg60f">(표 29)에는 비유형질과 체형형질의 주된 것(NPT산출에 쓰이는 형질과 유기)에 대하여 년당 개량량을 표시하였다. 1989년을 경계로 하여, 비유형질에서는 유성분율이 유지되는 한편, 유성분량의 개량량은 유량과 비례적으로 증가하고 있다. </p>
			
				<h2>사양환경의 효과</h2>
				<p>평가모델에 고려되고 있는 요인에 대해서는 각각의 효과에 대한 추정치가 산출된다. 우군별의 사양환경효과인 관리그룹의 효과는, 각각의 낙농가에게, 사양관리수준의 지표(관리그룹의 효과+전평균)로서 제공되고 있다.</p>
				<div className="imgBox"><img src={Pic822} alt=""/></div>
				<p>분만년별 전국평균치의 추이를 나타내자면 (그림32)와 같이 된다. 농후사료의 급여량이 증가하기 시작한 1981년이후, 해마다 신장해 왔으나, 근년에는 신장세가 둔화되고 있음을 알 수 있다. 이것은 1987년의 원유거래에 있어서 유지율기준을 인상함에 따라 농후사료급여량의 증가에 브레이크거 걸린 사실이라든지, 계획생산의 강화 혹은 빈번한 이상기상등의 출현등에서 기인한 것으로 생각된다.</p>
				<p>1두당 유량은 근년에도 순조롭게 신장하고 있으나, (그림 16)을 보면 알 수 있는바와 같이, 근년에는 사양관리의 개선없이, 주로 유전적능력의 개량에 의하여 늘어나고 있다. 한편 북해도·도부현별의 분만월효과는 (표30)에 나타난 대로이다.</p>
				<p>북해도에서는 12월분만과 8월분만에서 486kg, 도부현에서는 2월분만과 8월분만에서 422kg의 차가 있다는 것을 알 수 있다.</p>
				<table className="tablestyle">
					<caption>&lt;표 30&gt; 북해도·도부현별 분만월의 효과(유량)</caption>
					<thead>
						<tr>
							<th className="first"> </th>
							<th>북 해 도</th>
							<th>도 부 현</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>1월</td>
							<td>130</td>
							<td>103</td>
						</tr>
						<tr>
							<td>2월</td>
							<td>109</td>
							<td>116</td>
						</tr>
						<tr>
							<td>3월</td>
							<td>70</td>
							<td>105</td>
						</tr>
						<tr>
							<td>4월</td>
							<td>0</td>
							<td>65</td>
						</tr>
						<tr>
							<td>5월</td>
							<td>-84</td>
							<td>-21</td>
						</tr>
						<tr>
							<td>6월</td>
							<td>-171</td>
							<td>-110</td>
						</tr>
						<tr>
							<td>7월</td>
							<td>-263</td>
							<td>-215</td>
						</tr>
						<tr>
							<td>8월</td>
							<td>-326</td>
							<td>-306</td>
						</tr>
						<tr>
							<td>9월</td>
							<td>-197</td>
							<td>-244</td>
						</tr>
						<tr>
							<td>10월</td>
							<td>-17</td>
							<td>-127</td>
						</tr>
						<tr>
							<td>11월</td>
							<td>110</td>
							<td>2</td>
						</tr>
						<tr>
							<td>12월</td>
							<td>160</td>
							<td>102</td>
						</tr>
					</tbody>
				</table>
				<ul className="refer mg10t mg60f">
					<li>(주) 북해도의 4월분만을 베이스로 표시한 것</li>
					<li>(자료) 가축개량센타유용우 평가보고(1997 - Ⅱ) </li>
				</ul>

				<h2>지역별상황</h2>
				<p>(표31)은 현 검정우의 ETA, EPA, 사양환경효과의 지역별 평균이다.</p>
				<p>우군검정우의 유량에는 북해도와 도부현의 대부분 지역에 큰 차이가 없다. 이에 대하여, ETA나 EPA는 북해도가 높고, 관리그룹효과는 도부현이 높아져 있다. 이것은 도부현에서는 암소의 유전적능력이 낮은 분을, 농후사료의 다급등 사양관리면에서 보완되고 있다는 사실을 감지할 수 있다. </p>

				<table className="tablestyle">
					<caption>&lt;표 31&gt; 지역별로 본 젖소능력과 사양환경의 효과</caption>
					<thead>
						<tr>
							<th rowSpan="2" className="first">구 분</th>
							<th colSpan="2">우군검정(1996년도)</th>
							<th colSpan="3">현검정우·검정농가의 평가결과(유량)</th>
						</tr>
						<tr>
							<th>보급율 (%)</th>
							<th>유량(kg)</th>
							<th>ETA(kg)</th>
							<th>EPA (kg)</th>
							<th>사양환경의 효과(kg)</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>북해도</td>
							<td>65.5</td>
							<td>8,496</td>
							<td>+7</td>
							<td>+27</td>
							<td>-86</td>
						</tr>
						<tr>
							<td>동 북 </td>
							<td>24.1</td>
							<td>8,484</td>
							<td>-50</td>
							<td>-107</td>
							<td>+45</td>
						</tr>
						<tr>
							<td>관 동 </td>
							<td>26.8</td>
							<td>8,535</td>
							<td>-34</td>
							<td>-50</td>
							<td>+196</td>
						</tr>
						<tr>
							<td>북 륙 </td>
							<td>18.0</td>
							<td>8,682</td>
							<td>-7</td>
							<td>-19</td>
							<td>+413</td>
						</tr>
						<tr>
							<td>동 해 </td>
							<td>20.8</td>
							<td>8,583</td>
							<td>-4</td>
							<td>-15</td>
							<td>+320</td>
						</tr>
						<tr>
							<td>근 기 </td>
							<td>28.7</td>
							<td>8,144</td>
							<td>-17</td>
							<td>-42</td>
							<td>-77</td>
						</tr>
						<tr>
							<td>중사국 </td>
							<td>38.5</td>
							<td>8,541</td>
							<td>-7</td>
							<td>-22</td>
							<td>+217</td>
						</tr>
						<tr>
							<td>구주·노티나와</td>
							<td>43.3</td>
							<td>8,157</td>
							<td>-32</td>
							<td>-74</td>
							<td>-79</td>
						</tr>
						<tr>
							<td>전 국</td>
							<td>44.4</td>
							<td>8,464</td>
							<td>±0</td>
							<td>±0</td>
							<td>±0</td>
						</tr>
					</tbody>
				</table>
				<ul className="refer mg10t mg60f">
					<li>(주) ETA, EPA 사양환경의 효과는 전국 평균을 ±0으로 하여 표시함</li>
					<li>(자료) (사)가축개량사업단 &quot;유용우군 능력검정성적 마무리&quot; 가축개량센타 유용우 평가보고(1997 -Ⅱ) </li>
				</ul>

				<p className="viewTitle">유전적능력가치의 이용방법 </p>
				<h2>교배종모우의 선정</h2>
				<p>같은 시기의 암소와 종모우의 평가치는 동일한 지상환경의 것이기 때문에 비교가 가능하다. 그 때문에, 출산되는 딸소의 유전적능력을 예측하면서, 교배하는 종모우를 가려낼 수가 있다. 이하, EBV(ETA로도 같음)로 표시되는데, 딸소의 EBV=1/2(어미소의 EBV+ 아비소의 EBV)로 예측된다. 이것은 많은 딸소가 있는 경우의 평균이므로, 개중에는 좋은 경우도 있고, 나쁜 경우도 있다. 그러나 1두별의 좋고 나쁜 것이 쌓이기 때문에, 우군 전체를 보면, 기대한 결과가 얻어지게 되는 것이다.</p>
				<p>예를 들면, 2두의 암소와 3두의 종모우의 교배에 의한 딸소의 예측치는 다음과 같이 된다.</p>
				<table className="tablestyle mg30f">
					<tbody>
						<tr>
							<th>EBV·M EBV·P%</th>
							<td className="left">어미1 +300kg -0.2% 딸소의 예측치</td>
						</tr>
						<tr>
							<th>X EBV·M EBV·P%</th>
							<td className="left">종모우A 0kg +0.6% → +150kg +0.2% <br/>종모우B +300kg +0.2% → +300kg 0.0% <br/>종모우C +700kg 0.0% → +500kg -0.1%</td>
						</tr>
						<tr>
							<th>EBV·M EBV·P%</th>
							<td className="left">어미2 +100kg +0.2% 딸소의 예측치</td>
						</tr>
						<tr>
							<th>X EBV·M EBV·P%</th>
							<td className="left">종모우A 0kg +0.6% → + 50kg +0.4%<br/>종모우B 300kg +0.2% → +200kg +0.2%<br/>종모우C 700kg 0.0% → +400kg +0.1</td>
						</tr>
					</tbody>
				</table>
				<p>어미소와 딸소의 EBV차가 기대되는 딸소의 개량량이 된다. 유량에 대하여, 종모우B를 교배한 경우 딸소의 개량량은 어미소1에서는 ±0kg(어미소+300kg→딸소+300kg)이지만, 어미소2의 경우에는 +100kg(어미소+300kg→딸소+200kg)로 된다. 즉 어떤 종모우가 모든 어미소를 동등하게 개량한다고는 할 수 없다는 점에 주의를 요한다. 개량되는 것은 어미소에 비하여 종모우쪽이 우수한 형질만이다.</p>
				<p className="mg60f">또한 실제로 딸소가 출산되어, 그의 유전적능력이 판명되는 것은 4년정도 후이기 때문에, 그 동안에 유전베이스가 움직이면, 예측한대로였다고 해도, EBV의 값은 달라진다. 단지, 그 시점의 어미소의 EBV의 차이는 변하지 않으므로, 개량량은 검증이 가능하다. </p>

				<h2>암소의 선발·도태와 도입</h2> 
				<p>EBV의 절반이 딸소에게 전달된다고 생각해도 좋기 때문에, 후계우 생산에는 EBV의 상위에 있는 것부터 선발하면 좋다.</p>
				<p>또한, EPA는 사양관리등의 환경이 같은 조건의 경우의 생산량을 표시하는 것이기 때문에, 후계우생산에 쓰지 않는 암소 가운데, F1생산등을 실행해 나가면서 원유생산을 &apos;계속하는 것과 도태하는 것을 선별하는 지표가 된다.</p>
				<p>이에 더하여, EBV, EPA는 전국적으로 통일했기 때문에, 도입우를 선정하는 경우에, 자기 소유우와의 비교가 가능하다.</p>
				<p className="mg60f">단지, 암소에 대해서는 종모우처럼 딸소가 다수 있는 것이 아니기 때문에, EBV, EPA의 신뢰도는 낮다. 또한 몸의 컨디션을 무너뜨렸다 든가, 개개의 암소만에 작용한 요인이 완전 제거되지 않기 때문에, 같은 신뢰도에 있다 해도 종모우보다도 정확성은 낮은 것으로 생각하지 않으면 안된다. 그 때문에, 개개 암소의 과거 및 현재의 상태도 병합해서 참고할 필요가 있다. </p>

				<h2>기타</h2> 
				<dl>
					<dt>우군의 유전적능력이나 사양관리수준의 확인</dt>
					<dd>우군의 EBV와 EPA의 평균이나, 사양관리수준의 지표를 검정조합 평균이나 도도부현 평균과 비교하므로서, 우군의 능력이나 사양관리에서 우수한 형질, 열등한 형질을 파악할 수 있게 된다. 또한 년별의 평균치를 보므로써, 우군의 개량이나, 사양관리의 개선에 대한 진척도가 첵크된다. 단지, 사양관리 수준의 지표에는 선선한 여름이나, 맹령한 더위등 경영외의 영향도 포함된다는 점에 주의를 요한다.</dd>
				</dl>
				<dl>
					<dt>생산예측</dt>
					<dd>
						<p>암소별로, 다음산차의 유량등을 다음식에 의하여 예측할 수가 있다. </p>
						<table className="tablestyle14 mg15f">
							<tbody>
								<tr>
									<td rowSpan="2">다음 산차 305일 유량=</td>
									<td>직전의 사양관리수준의 지표+분만월(예정)효과+EPA〕 </td>
								</tr>
								<tr>
									<td> 산차·분만(예정)월령의 보정계수 </td>
								</tr>
							</tbody>
						</table>
						<p>예를 들면, 북해도의 낙농가에서, 직전의 사양관리수준의 지표가 +7,000kg, 분만예정이 3월, EPA가 +800kg, 분만예정월령이 48개월이라 하면, (표 24) 및 (표 30)을 써서, (7,000+70+800)/0.805=9,776으로 예측된다.</p>
						<p>또한, 초산우에 대해서는 EPA가 불명하지만, 항구적환경효과를 ±0kg로 가정하여 EPA대신 EBV의 기대치〔=1/2(아비소의 EBV+ 아비소의 EBV〕를 대입함으로써 예측이 된다. </p>
					</dd>
				</dl>
				<dl>
					<dt>국산종모우의 생산</dt>
					<dd>전국적인 암소의 유전적능력평가가 개시되므로써, 유전적능력이 우수한 암소의 검색이 가능하게 됨과 함께, 유전적능력을 예측하면서, 계획적으로 후보종모우를 생산하는 것이 가능하게 되었다. &apos;94후검의 후보종모우부터 이 방법에 의한 생산이 이루어지고 있다.</dd>
				</dl>
			</div>
  );
}

export default Fo5;
