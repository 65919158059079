import React from 'react';

import pic281 from 'assets/images/sub/_images/pic281.jpg';
import pic282 from 'assets/images/sub/_images/pic282.jpg';
import pic283 from 'assets/images/sub/_images/pic283.jpg';
import pic284 from 'assets/images/sub/_images/pic284.jpg';
import pic285 from 'assets/images/sub/_images/pic285.jpg';

function Do182() {

  return (
    <div className="techinfoview">
      <h1>오차드 그라스</h1>
      <p>영명 : Orchardgrass(Cocksfoot) <br/>학명 : Dactylis glomerata. L </p>
      <div style={{width: '60%',float: 'left'}}>
        <h2>원산지</h2>
        <p className="pstyle">&nbsp;&nbsp;&nbsp;원산지는 유럽이지만 200여년이 넘도록 북미에서 재배되어 왔다. 1760년경에 북미에서 처음으로 재배되어 온 이래 건초나 청초용으로 주요한 목초가 되었다. 과수원 같은 그늘에서 흔히 잘 자라서 오차드 그라스(과수원풀)라 부르게 되었다.</p>
        <h2>성상</h2>
        <p className="pstyle">&nbsp;&nbsp;&nbsp;다년생 화본과 목초로서 뿌리는 거친 섬유 모양의 뿌리를 깊이 뻗어 60∼90cm 이상 자라지만 토심 18cm 범위내에 가장 많이 분포한다. 포기를 지으면서 성장하고 아래 부위의 잎은 엷은 녹색이며 잎을 전달하면 &quot;V&quot;자 형을 이루고 잎이 성숙하면 사포와 같은 약간 거치른 촉감이 있다. 초장은 90∼150cm 정도이고 이삭은 원추화서로서 길이는 8∼15cm 정도이다. 번식은 종자로 하며 분얼에 의하여서도 한다. 분얼경은 계속하여 생성되며 포장에서 생성되는 분얼경은 수개월에서 수년간 살아 남는다.  </p>
        <h2>재배</h2>
        <p className="pstyle">&nbsp;&nbsp;&nbsp;년중 강수량이 500mm 이상되는 곳 에서 수량이 많고 배수가 잘되는 곳에서 재배가 적합하다. 오차드 그라스는 관리에 세심한 주의를 하여야 하며, 특히 시비관리에 주의를 하여야 한다. 산성토양에 강한 편이며 그늘에서는 화본과 목초 중에서 제일 강하다. 가뭄에는 티모시보다 더욱 강하나 스므스 부람그라스 보다는 약하다. 가뭄애 강한 이유는 많이 퍼지는 뿌리의 특성에 기인한다. 배수가 잘되는 토양조건을 좋아해 이러한 토양에서는 재배가 잘된다. 시비는 질소와 인산의 효과가 커서 청초 수량을 높이기 위하여서는 질소와 인산질을 많이 사용하여야 한다. 염해지에서는 약간 강한 편이나 강알칼리 토양에는 약한 편이고 단기간의 가뭄에는 잘 견디나 장기간의 가뭄에는 고사하게 된다. 사토질에서는 생육이 저조하나 강수량이 많을 때에는 생육에 좋다. 10a당 청초 수량은 5,000kg 정도로 현재 재배되고 있는 품종은 스털링(Sterling), 포토막(Potomac), 케이(Kay), 아이에스아이 신세틱A(ISI SynA), 레이터(Later) 등이 있다.  </p>
        <h2>이용</h2>
        <ul>
          <li>건초
            <p>수확하기에 좋을 만큼 초장이 크며 1번초 수량이 많다. 레드클로버, 알팔파, 라디노클리버등과 혼파를 하면 두과 목초도 새육이 좋아 단위면적당 생산량을 높일 수 있다. 재생력도 강하여 알팔파와 같이 혼파하면 2번초수량도 크게 올릴 수 있다.</p>
          </li>
          <li>방목 및 청예
            <p>예취 후 재생력이 좋기 때문에 청예용 목초로서도 좋다. 초봄 일찍부터 생육을 하고 수량도 많아 이용하기가 쉽고 다른 목초가 하고기에 생육이 느릴 때 다른 목초 보다는 생장속도가 빠르며 다른 초종은 늦여름에나 가을철에 생육 정도가 다소 느리나 오차드 그라스는 생육이 잘 된다. 재생력이 빠르기 때문에 다른 목초와 혼파하는 것이 좋다. 기호성은 대단히 좋고 과방목을 방지하기 위하여 초지를 목책으로 구분하여윤환 방목을 하는 것이 좋다. 과방목은 피하여야 한다. 방목시기는 오차드 그라스의 초장이 20∼25cm 정도 자랐을 때 시작하여야 하고 지상 약 10cm 정도 남겨놓고 채초나 방목을 하여야 한다.</p>
          </li>
        </ul>
      </div>
      <div style={{width: '40%', float: 'left'}}>
        <table>
          <tr>
            <td><img src={pic281} className="nonborder2" alt=""/></td>
          </tr> 
          <tr>
            <td><img src={pic282} className="nonborder2" alt=""/></td>
          </tr>
          <tr>
            <td><p style={{textAlign: 'center'}}>엽설(왼쪽)과 엽이(오른쪽)  분열경이 나오는 모양</p></td>
          </tr>
          <tr>
            <td><img src={pic283} className="nonborder2" alt=""/></td>
          </tr>
          <tr>
            <td><p style={{textAlign: 'center'}}>유식물의 생육과정</p></td>
          </tr>
          <tr>
            <td><img src={pic284} className="nonborder2" alt=""/></td>
          </tr>
          <tr>
            <td><p style={{textAlign: 'center'}}>출수과정</p></td>
          </tr>
          <tr>
            <td><img src={pic285} className="nonborder2" alt=""/></td>
          </tr>
          <tr>
            <td><p style={{textAlign: 'center'}}>종자(왼쪽) 및 개화(오른쪽)</p></td>
          </tr>  
        </table>
      </div>
    </div>
  );
}

export default Do182;