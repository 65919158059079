import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { getState, delState, postState } from 'utils/api';

// 컴포넌트
import ErrorMessage from 'components/errorMessage/errorMessage';

// scss
import './commonView.scss';

function CommonViewApi({
  setAnswer,
  setBoardJuhyeonTab,
  setBoardHyeonseongTab,
  setBoardGihunTab,
  setBoardSeokhyeonTab,
}) {
  // 경로 받기
  const navigate = useNavigate();
  const location = useLocation();

  // 에러처리
  const [isModalOpen, setIsModalOpen] = useState(false); // 모달 추가
  const [errorMessage, setErrorMessage] = useState('');

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => {
    setIsModalOpen(false);
    setErrorMessage(''); // 모달이 닫힐 때 에러메세지 초기화
  };

  // data
  const [Data, setData] = useState(null);

  const baseDir = location.pathname
    .toLowerCase()
    .replace('web/', '')
    .replace('/detail', '');

  const [props, setProps] = useState({});

  const fetchApiData = async () => {
    getState(baseDir, props)
      .then((getdata) => {
        setData(getdata);
        console.log(getdata);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchApiData();
  }, [baseDir]);

  // 받아온 테이블에서 width height 삭제하기
  const [modifiedHtmlContent, setModifiedHtmlContent] = useState('');

  useEffect(() => {
    let initialHtmlContent = Data?.bdc_body;

    if (typeof initialHtmlContent === 'string') {
      initialHtmlContent = initialHtmlContent
        .replaceAll('&lt;', '<')
        .replaceAll('&gt;', '>');
    }
    // Create a temporary div element
    const tempElement = document.createElement('div');
    tempElement.innerHTML = initialHtmlContent;

    // Find all elements with width and height attributes and remove them
    const elementsWithAttributes =
      tempElement.querySelectorAll('[width], [height]');
    elementsWithAttributes.forEach((element) => {
      element.removeAttribute('width');
      element.removeAttribute('height');
    });

    const imgElements = tempElement.querySelectorAll('img');
    imgElements.forEach((img) => {
      img.removeAttribute('style');
    });

    // Get the modified HTML content
    const modifiedHtml = tempElement.innerHTML;

    // Set the modified HTML content to the state
    setModifiedHtmlContent(modifiedHtml);
  }, [Data]); // Empty dependency array to run the effect only once on mount
  // width height 삭제하기 끝

  // 글 삭제, 수정 비밀번호
  const [boardPassword, setBoardPassword] = useState('');
  const [boardType, setBoardType] = useState('');

  const boardPasswordRef = useRef(null);

  const moveBoardUpdate = () => {
    if (setBoardJuhyeonTab) setBoardJuhyeonTab('update');
    if (setBoardHyeonseongTab) setBoardHyeonseongTab('update');
    if (setBoardGihunTab) setBoardGihunTab('update');
    if (setBoardSeokhyeonTab) setBoardSeokhyeonTab('update');
  };

  const checkBoardPassword = () => {
    const password = boardPasswordRef.current.value;
    if (boardType === 'update') moveBoardUpdate();
    if (boardType === 'delete') {
      delState(baseDir, { password })
        .then((getdata) => {
          console.log('### base dir :', baseDir);
          if (window.confirm('삭제 되었습니다.')) {
            setBoardPassword(false);
            setBoardType('board');
            navigate(location.pathname.split('/detail')[0]);
          }
        })
        .catch((error) => {
          console.log(error);
          if (error.response && error.response.status === 401) {
            setErrorMessage(
              '비밀번호가 일치하지 않습니다. 다시 입력해 주세요.',
            );
            openModal();
            // 401 에러 처리 로직 추가
          }
        });
    }
  };

  const delPageNum = (text, num) => {
    const sep = '/';
    const array = text.split(sep);
    array.pop();
    return `${array.join(sep)}${sep}${num}`;
  };

  return (
    <div className="commonView">
      <ErrorMessage
        isOpen={isModalOpen}
        content={errorMessage}
        closeModal={closeModal}
      />
      <div className="commonViewInner">
        <div className="viewHead">
          <h5>{Data && Data.bdc_title}</h5>
          <div className="viewHeadInfo">
            <ul>
              <li>{Data && Data.bdc_wname}</li>
              <li>{Data && Data.bdc_rdt ? Data.bdc_rdt.split('T')[0] : '-'}</li>
            </ul>
            <span>조회수 {Data && Data.bdc_hit}</span>
          </div>
        </div>
        {/*
         *
         ** 리뉴얼 이전 게시글은 이 tablestyle23 이 들어간 table을 사용해서 보여줍니다.
         *
         */}
        <div className="viewBody">
          <div className="fileAttachment">
            {Data &&
              Data.file_list.length > 0 &&
              Data.file_list.map((file, index) => (
                <a
                  key={index}
                  href={`${process.env.REACT_APP_STATICS_URL}/_uploadFiles/_data/${file.bdf_fname}`}
                  className="attach"
                  download
                >
                  {file.bdf_sname}
                </a>
              ))}
          </div>
          <table className="tablestyle23">
            <tbody
              dangerouslySetInnerHTML={{ __html: modifiedHtmlContent }}
            ></tbody>
          </table>
        </div>

        <ul className="viewFoot">
          <li>
            <span>다음글</span>
            {Data && (
              <Link
                to={
                  Data.next_con
                    ? delPageNum(location.pathname, Data.next_con)
                    : location.pathname
                }
              >
                {Data &&
                  (Data.next_title ? Data.next_title : '다음글이 없습니다.')}
              </Link>
            )}
          </li>
          <li>
            <span>이전글</span>
            {Data && (
              <Link
                to={
                  Data.prev_con
                    ? delPageNum(location.pathname, Data.prev_con)
                    : location.pathname
                }
              >
                {Data &&
                  (Data.prev_title ? Data.prev_title : '이전글이 없습니다.')}
              </Link>
            )}
          </li>
        </ul>
      </div>

      <ul className="viewBtn">
        {(location.pathname.includes('Qna') ||
          location.pathname.includes('BuyAndSell')) && (
          <>
            <li>
              <button
                type="button"
                onClick={() => {
                  // console.log(setAnswer , Data);
                  if (setAnswer && Data) {
                    setAnswer((prevState) => ({
                      ...prevState,
                      isTrue: true,
                      grp_id: Data.bdc_grp_id,
                      depth: Data.bdc_dept,
                      grp_seq: Data.bdc_grp_seq,
                    }));
                    if (setBoardJuhyeonTab) setBoardJuhyeonTab('write');
                    if (setBoardHyeonseongTab) setBoardHyeonseongTab('write');
                    if (setBoardGihunTab) setBoardGihunTab('write');
                    if (setBoardSeokhyeonTab) setBoardSeokhyeonTab('write');
                  }
                }}
              >
                답글
              </button>
            </li>
            <li>
              <button
                type="button"
                onClick={() => {
                  setBoardPassword(true);
                  setBoardType('update');
                }}
              >
                수정
              </button>
            </li>
            <li>
              <button
                type="button"
                onClick={() => {
                  setBoardPassword(true);
                  setBoardType('delete');
                }}
              >
                삭제
              </button>
            </li>
          </>
        )}
        <li>
          <Link
            to={location.pathname.split('/detail')[0]}
            onClick={() => {
              if (setBoardJuhyeonTab) setBoardJuhyeonTab('board');
              if (setBoardHyeonseongTab) setBoardHyeonseongTab('board');
            }}
          >
            목록
          </Link>
        </li>
      </ul>
      {boardPassword && (
        <div className="boardPassword">
          <div className="boardPasswordInner">
            <p>글 등록시 입력한 비밀번호를 입력해 주세요.</p>
            <div className="boardinputArea">
              <input ref={boardPasswordRef} type="password" maxLength={4} />
              <button type="button" onClick={() => checkBoardPassword()}>
                비밀번호 확인
              </button>
            </div>
            <button
              type="button"
              className="close"
              onClick={() => setBoardPassword(false)}
            >
              <span className="hidden">닫기</span>
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default CommonViewApi;
