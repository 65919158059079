import React from 'react';

import pic770 from 'assets/images/sub/_images/pic770.jpg';
import pic771 from 'assets/images/sub/_images/pic771.jpg';
import pic772 from 'assets/images/sub/_images/pic772.jpg';

function Do168() {

  return (
    <div className="techinfoview">
      <h1>창상성 비장염</h1>
      <p className="pstyle">제2위를 찌른 금속이물이 다시 좌상방(左上方)으로 나아가 비장(지라)을 찌르게 됨으로써 생기는 화농성 병변을 주징후로 하는 비장의 질환이다. </p>
      <div className="leftpart">
        <h2>증상</h2>
        <ul>
          <li>외부적으로는 뚜렷한 변화가 없음에도 불구하고 식욕이 떨어지거나 정지한 채 3∼4주일 계속되며 급격히 쇠약해진다. </li>
          <li>조석(朝夕)간의 체온차는 0.5∼1.0℃로 건강한 소에 비해 차이가 크다. </li>
          <li>결막, 질점막 등의 가시점막이 창백하고 빈혈이 나타난다. </li>
        </ul>
        <h2>목장에서의 처치</h2>
        <ul>
          <li>특별한 응급처치는없다. 
            <ul>
              <li>본증은 결과가 좋지 않은 질환으로 항생제나 수액제의 투여에도 별다른 효과를 얻을 수 없다. </li>
              <li>본증과 같은 금속성 이물에 기인하는 질환의 예방에는 막대자석이 대단히 유효하므로 반드시 자석을 먹여두어 사전예방에 힘써야 한다.</li>
            </ul>
          </li>
        </ul>
      </div>
      <div className="rightpart">
        <img src={pic770} className="nonborder" alt=""/>
        <img src={pic771} className="nonborder" alt=""/>
        <img src={pic772} className="nonborder" alt=""/>
      </div>
    </div>
  );
}

export default Do168;