import React, { useState , useEffect } from 'react';
import { useLocation , useNavigate } from 'react-router-dom';
import { postState } from 'utils/api';

// 컴포넌트
import ErrorMessage from 'components/errorMessage/errorMessage';

// scss
import './semenSelect.scss';

function SemenSelect() {

  // 에러처리
  const [isModalOpen, setIsModalOpen] = useState(false); // 모달 추가
  const [errorMessage, setErrorMessage] = useState(''); 

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => {
    setIsModalOpen(false);
    setErrorMessage(''); // 모달이 닫힐 때 에러메세지 초기화
  };
  
  const [ selectSemen , setSelectSemen ] = useState([]);
  const validateSemen = (regno) => {
    if (selectSemen.includes(regno)) {
      setSelectSemen(
        selectSemen.filter((item) => item !== regno)
      );
      return false;
    }

    const newSelectSemen = [...selectSemen, regno];

    if (newSelectSemen.length === 4) {
      setErrorMessage('정액은 최대 3개까지 선택 가능합니다.');
      openModal();
      return false;
    }

    setSelectSemen(newSelectSemen);
    return true;
  };

  // 데이터 불러오기
  // eslint-disable-next-line no-restricted-globals
  const location = useLocation();
  const baseDir=`${location.pathname.toLowerCase()}/`;   

  const [ mainData , setMainData ] = useState(null);

  const [ props , setProps] = useState({
    id: localStorage.getItem('milkFarmCode'),
    passwd: localStorage.getItem('milkFarmPwd'),    
  });

  useEffect(() => {
    postState(baseDir,props)
    .then(getdata => {      
      if (getdata !== undefined) {                  
        setMainData(getdata);
      }
    })
    .catch(error => {           
      setErrorMessage(error.message);
      openModal();
    });    
  }, [props]);

  const navigate = useNavigate();
  const saveSelectSemen = () => {
    if (selectSemen.length === 0) {
      setErrorMessage('정액을 선택해 주세요.');
      openModal();
      return false;
    }

    if (selectSemen.length > 0) {
      localStorage.setItem('selectSemen', selectSemen);

      if (window.confirm('정액이 저장되었습니다. 암소선정 화면으로 이동합니다.')) {
        navigate('/milk/girlcowselect');
      }

    }

    return true;
  };

  return (
    <div className='semenSelect'>
      <ErrorMessage isOpen={isModalOpen} content={errorMessage} closeModal={closeModal}/>
      <h2>정액선정</h2>
      <ul>
        
        <li>
          <p>개량목표 가중치 설정</p>
          <div className='semenSelectTable'>
            <table>
              <thead>
                <tr>
                  <th rowSpan='2'>구분</th>
                  <th colSpan='4'>유성분&#40;EBV&#41;</th>
                  <th colSpan='3'>체형&#40;EBV&#41;</th>
                  <th rowSpan='2'>KTPI&#40;EBV&#41;</th>
                </tr>
                <tr>
                  <th>유량&#40;kg&#41;</th>
                  <th>유지방&#40;kg&#41;</th>
                  <th>유단백&#40;kg&#41;</th>
                  <th>체세포&#40;천/Cell&#41;</th>
                  <th>종합점수</th>
                  <th>유방</th>
                  <th>지제</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>표준점수</th>
                  <td>{mainData && mainData.plot_data[0].mc.toFixed(2)}</td>
                  <td>{mainData && mainData.plot_data[0].fc.toFixed(2)}</td>
                  <td>{mainData && mainData.plot_data[0].pc.toFixed(2)}</td>
                  <td>{mainData && mainData.plot_data[0].sc.toFixed(2)}</td>
                  <td>{mainData && mainData.plot_data[0].cc.toFixed(2)}</td>
                  <td>{mainData && mainData.plot_data[0].udc.toFixed(2)}</td>
                  <td>{mainData && mainData.plot_data[0].flc.toFixed(2)}</td>
                  <td>{mainData && mainData.plot_data[0].ktpi.toFixed(2)}</td>
                </tr>
                <tr>
                  <th>개량목표</th>
                  {
                    mainData && 
                    Object.keys(mainData.plot_data2[0]).map((key, index) => (
                      <td key={index}>{mainData.plot_data2[0][key]}</td>
                    ))
                  }
                </tr>
              </tbody>
            </table>
          </div>
          <span>※ 우선순위 개량항목을 가장 높게 설정&#40;전국평균 0대비 표준점수 값이 높을 수록 개량도가 높음, 단 체세포는 낮을수록 개량도가 높음&#41;</span>
        </li>
        
        <li>
          <div className='semenSelectBtn'>
            <p>정액 선택<span>※ 정렬기준 : 설정된 개량목표에 맞는 최적정액 순&#40;최대 3개 선택 가능&#41;</span></p>
            <button type='button' onClick={() => saveSelectSemen()}>선택정액 저장</button>
          </div>
          <div className='semenSelectTable'>
            <table>
              <thead>
                <tr>
                  <th rowSpan='2'>선정</th>
                  <th rowSpan='2'>정액코드</th>
                  <th colSpan='4'>유성분&#40;EBV&#41;</th>
                  <th colSpan='3'>체형&#40;EBV&#41;</th>
                  <th rowSpan='2'>KTPI&#40;EBV&#41;</th>
                  <th rowSpan='2'>근친두수&#40;6.25이상&#41;</th>
                </tr>
                <tr>
                  <th>유량&#40;kg&#41;</th>
                  <th>유지방&#40;kg&#41;</th>
                  <th>유단백&#40;kg&#41;</th>
                  <th>체세포&#40;천/Cell&#41;</th>
                  <th>종합점수</th>
                  <th>유방</th>
                  <th>지제</th>
                </tr>
              </thead>
            </table>
            <ul className='selectTableList'>
                {
                  mainData && 
                  mainData.plot_data3.map((cow, index) => (
                    <li key={index}>
                      <ul>
                        <li>
                          <input 
                            type='checkbox' 
                            checked={selectSemen.includes(cow.regno)}
                            onChange={()=> validateSemen(cow.regno)}
                          />
                        </li>
                        <li>{cow.scode}</li>
                        <li className='numberCon'>{Number(cow.milk).toFixed(2)}</li>
                        <li className='numberCon'>{Number(cow.fat).toFixed(2)}</li>
                        <li className='numberCon'>{Number(cow.prot).toFixed(2)}</li>
                        <li className='numberCon'>{Number(cow.scc).toFixed(2)}</li>
                        <li className='numberCon'>{Number(cow.ocs).toFixed(2)}</li>
                        <li className='numberCon'>{Number(cow.udc).toFixed(2)}</li>
                        <li className='numberCon'>{Number(cow.flc).toFixed(2)}</li>
                        <li className='numberCon'>{Number(cow.ktpi).toFixed(2)}</li>
                        <li className='numberCon'>{Number(cow.no).toFixed(2)}</li>
                      </ul>
                    </li>
                  ))
                }
              </ul>
          </div>
        </li>
      </ul>
    </div>
  );
}

export default SemenSelect;
