import React from 'react';

import Pic61 from 'assets/images/sub/_images/pic61.gif';
import Pic62 from 'assets/images/sub/_images/pic62.gif';
import Pic63 from 'assets/images/sub/_images/pic63.gif';
import Pic64 from 'assets/images/sub/_images/pic64.gif';
import Pic65 from 'assets/images/sub/_images/pic65.gif';
import Pic66 from 'assets/images/sub/_images/pic66.gif';
import Pic67 from 'assets/images/sub/_images/pic67.gif';
import Pic68 from 'assets/images/sub/_images/pic68.gif';
import Pic69 from 'assets/images/sub/_images/pic69.gif';
import Pic70 from 'assets/images/sub/_images/pic70.gif';
import Pic71 from 'assets/images/sub/_images/pic71.gif';
import Pic72 from 'assets/images/sub/_images/pic72.gif';
import Pic73 from 'assets/images/sub/_images/pic73.gif';
import Pic74 from 'assets/images/sub/_images/pic74.gif';
import Pic75 from 'assets/images/sub/_images/pic75.gif';

function Do11() {

  return (
    <div className="techinfoview">
				<h1>골격(일반외모)에 대하여</h1>
				<p style={{lineHeight: '200%'}}>&nbsp;&nbsp;&nbsp;심사 초보자로서, 소의 많은 부위와 그것들이 장수성, 기능성 및 최종점수와 어떻게 관련 이 있는지 등이 처음에는 당황스러울 수 있다. 하지만 어떻게 소를 심사할 수 있는지 선형 점수를 가지고 우선 살펴보기로 하자.<br/>&nbsp;&nbsp;&nbsp;우선 16가지의 선형형질이 심사원들에 의해서 어떻게 평가되는지를 보기로 하겠다.. 여기 에는 5가지의 중요 등급형질(골격 15점, 유용성 20점, 체적 10점, 지제 15점, 유방 40점)과 심사표준의 세부항목을 나타내었다. 다시 말해서, 각각의 선형형질이 중요 등급형질과 어떠 한 관련이 있는지 설명되었다. 그림 위쪽의 자막은 선형점수를 나타낸다.<br/>&nbsp;&nbsp;&nbsp;강건성은 골격, 체심 및 유용성에 기여하는 형질이지만 공식적으로 여기에서 채점되어 진 다. 이 형질을 평가할 때에는 뼈구조의 강건성과 가슴의 너비 및 비경의 너비를 관찰하라. 대부분의 형질들과 마찬가지로, 점수가 높을수록 좋다고 할 수 있다. 그러나 엉덩이 기울기 는 그렇지 않은데, 이상적인 점수대는 25점으로 요각에서 좌골로 약간 기운 상태를 말한다. 엉덩이 너비는 좌골의 너비를 측정하는 것으로, 심사원이 최종점수에 골격을 반영할 경우에 는 엉덩이 전체를 고려해야 한다. 엉덩이가 넓으면 분만도 쉽게 할 수 있고, 뒷유방이 넓게 붙을 수 있도록 해준다.<br/>&nbsp;&nbsp;&nbsp;골격을 평가할 때 키도 심사하나 품종간에 차이도 있고 하여, 사진으로는 나타내지 않았다. (미국은 5개 젖소 품종이 있음) 키는 땅으로부터 십자부(등선 중앙과 양 요각의 연장선 이 만나는 지점)까지의 수직적 거리를 나타낸다. 홀스타인종인 경우 키와 배점은 아래와 같다.</p><br/>
				<table className="tablestyle tablestyle5">
					<tr>
						<td>점수</td>
						<td>45</td>
						<td>35</td>
						<td>25</td>
						<td>15</td>
						<td>5</td>
					</tr>
					<tr style={{background: '#EDEDFE'}}>
						<td>키(Cm)</td>
						<td>150</td>
						<td>145</td>
						<td>140</td>
						<td>135</td>
						<td>130</td>
					</tr>
				</table><br/><br/>
				<table className="tablestyle6">
					<tr>
						<td colSpan="5"><b>strength 강건성</b></td>
					</tr>
					<tr>
						<td>&gt;45 극히 강하고 넓음</td>
						<td>38~32 강함</td>
						<td>28~22 중간정도</td>
						<td>18~12 좁고 약함</td>
						<td>&lt;5 극히 좁고 약함</td>
					</tr>
					<tr>
						<td><img src={Pic61} alt="강건성 정도"/></td>
						<td><img src={Pic62} alt="강건성 정도"/></td>
						<td><img src={Pic63} alt="강건성 정도"/></td>
						<td><img src={Pic64} alt="강건성 정도"/></td>
						<td><img src={Pic65} alt="강건성 정도"/></td>
					</tr>
				</table>
				<br/><br/>
				<table className="tablestyle6">
					<tr>
						<td colSpan="5"><b>rump angle 엉덩이 기울기</b></td>
					</tr>
					<tr>
						<td>&gt;45 좌골쪽으로 극히 경사짐</td>
						<td>38~32 중간 정도로 경사짐</td>
						<td>28~22 약간 경사짐</td>
						<td>18~12 좌골과 요각이 수평임</td>
						<td>&lt;5 좌골이 요각보다 높음</td>
					</tr>
					<tr>
						<td><img src={Pic66} alt="엉덩이 기울기 정도"/></td>
						<td><img src={Pic67} alt="엉덩이 기울기 정도"/></td>
						<td><img src={Pic68} alt="엉덩이 기울기 정도"/></td>
						<td><img src={Pic69} alt="엉덩이 기울기 정도"/></td>
						<td><img src={Pic70} alt="엉덩이 기울기 정도"/></td>
					</tr>
				</table>
				<br/><br/>
				<table className="tablestyle6">
					<tr>
						<td colSpan="5"><b>rump angle 엉덩이 기울기</b></td>
					</tr>
					<tr>
						<td>&gt;45 극히 넓음</td>
						<td>38~32 넓음</td>
						<td>28~22 중간 정도 너비</td>
						<td>18~12 약간 좁음</td>
						<td>&lt;5 극히 좁음</td>
					</tr>
					<tr>
						<td><img src={Pic71} alt="엉덩이 기울기 정도"/></td>
						<td><img src={Pic72} alt="엉덩이 기울기 정도"/></td>
						<td><img src={Pic73} alt="엉덩이 기울기 정도"/></td>
						<td><img src={Pic74} alt="엉덩이 기울기 정도"/></td>
						<td><img src={Pic75} alt="엉덩이 기울기 정도"/></td>
					</tr>
				</table>
			</div>
  );
}

export default Do11;
