import React from 'react';

// 컴포넌트
import SubTitle from 'components/subTitle/SubTitle';

// scss
import './PersonalInfo.scss';

// 이미지
import Pimage01 from 'assets/images/containers/guide/personalInfo/personal.png';



function PersonalInfo() {
  return (
    <div className="personalInfo subCon">
      <SubTitle />

      <div className='personalInfo_notice'>
        <img src={Pimage01} alt="개인정보보호법 이미지"/>      
        <p>
        농협 젖소개량사업소는 「개인정보 보호법」 제30조에 따라 정보주체의 개인정보를 보호하고 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리방침을 수립·공개합니다.
        </p>
      </div>

      <div className='personalInfo_contents'>
      
      <h2>제1조(개인정보의 처리목적)</h2>
				<p>농협경제지주는 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며, 이용 목적이 변경되는 경우에는 「개인정보 보호법」 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.</p>
				<ul className="line1">
					<li>1. 축산법 제 3조(축산발전시책의 강구), 제5조(개량목표의 설정), 제7조(가축의 검정) 및 제47조(기금의 용도) 법령에 의하여 정부의 젖소능력검정(정액생산,공급) 및 유우군능력검정사업을 위하여 사업추진을 위한 연락·통지, 검정처리결과 통보, 우수 사례 발표 등의 목적으로 개인정보를 처리합니다. </li>
				</ul>
				
				<h2>제2조(개인정보의 처리 및 보유기간)</h2>
				<ul className="line1">
					<li>① 농협 젖소개량사업소는 법령에 의거 개량사업 참여목적으로 수집된 개인정보를 사업참여일로 부터 사업 참여 종료일까지 위의 제1조 개인정보 처리목적으로만 보유·이용됩니다. 단, 사업참여 종료 후에는 민원처리 및 법령상 의무이행만을 위하여 보유, 이동됩니다.</li>
				</ul>

        <h2>제3조(개인정보의 제3자 제공)</h2>
				<ul className="line1">
					<li>① 농협 젖소개량사업소는 다음과 같이 개인정보를 제3자에게 제공하고 있습니다.
						<ul className="line2">
							<li>1. 개인정보를 제공받는 자 : 농림축산식품부, 국립축산과학원 </li>
							<li>2. 제공받는 자의 개인정보 이용목적 : 공공기관에서 정책자료로 활용</li>
							<li>3. 제공하는 개인정보 항목 : 개인식별을 위한 정보</li>
							<li>4. 제공받는 자의 보유·이용기간 : 개인정보는 제공된 날로부터 제공 목적이 달성될 때까지 보유·이용됩니다.<br/>다만, 동의 철회 또는 제공 목적 달성 후에는 법령상 의무이행만을 위하여 보유·이용됩니다.</li>
						</ul>
					</li>
				</ul>
				
				<h2>제4조(개인정보처리의 위탁)</h2>
				<ul className="line1">
					<li>① 농협경제지주는 원활한 개인정보 업무처리를 위하여 다음과 같이 개인정보 처리업무를 위탁하고 있습니다.
						<ul className="line2">
							<li>1. 인터넷 홈페이지 관리
								<ul className="line3">
									<li>위탁받는 자 (수탁자) : ㈜ 투비텍</li>
									<li>위탁하는 업무의 내용 : 농협 젖소개량사업소 인터넷 홈페이지 관리 및 유지·보수 등</li>
								</ul>
							</li>
							<li>2. IT전산시스템의 관리
								<ul className="line3">
									<li>위탁받는 자 (수탁자) : ㈜넥스코어, ㈜ 바토스</li>
									<li>위탁하는 업무의 내용 : 젖소개량사업소 전산시스템</li>
								</ul>
							</li>							
						</ul>
					</li>
					<li>② 농협 젖소개량사업소는 위탁계약 체결시 「개인정보 보호법」 제25조에 따라 위탁업무 수행목적 외 개인정보 처리금지, 기술적·관리적 보호조치, 재위탁 제한, 수탁자에 대한 관리·감독, 손해배상 등 책임에 관한 사항을 계약서 등 문서에 명시하고, 수탁자가 개인정보를 안전하게 처리하는지를 감독하고 있습니다. </li>
					<li>③ 위탁업무의 내용이나 수탁자가 변경될 경우에는 지체없이 본 개인정보 처리방침을 통하여 공개하도록 하겠습니다.</li>
				</ul>

        <h2>제5조(정보주체의 권리·의무 및 행사방법)</h2>
				<ul className="line1">
					<li>① 정보주체는 농협 젖소개량사업소에 대해 언제든지 다음 각 호의 개인정보 보호 관련 권리를 행사할 수 있습니다.
						<ul className="line2">
							<li>1. 개인정보 열람요구</li>
							<li>2. 오류 등이 있을 경우 정정 요구</li>
							<li>3. 처리정지 요구</li>
						</ul>
					</li>
					<li>② 제1항에 따른 권리 행사는 농협 젖소개량사업소에 대해 서면, 전화, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며 농협 젖소개량사업소는 이에 대해 지체없이 조치하겠습니다.</li>
					<li>③ 정보주체가 개인정보의 오류 등에 대한 정정를 요구한 경우에는 농협 젖소개량사업소는 정정을 완료할 때까지 해당 개인정보를 이용하거나 제공하지 않습니다.</li>
					<li>④ 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다. 이 경우 「개인정보 보호법」 시행규칙 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.</li>
					<li>⑤ 정보주체는 「개인정보 보호법」등 관계법령을 위반하여 농협경제지주가 처리하고 있는 정보주체 본인이나 타인의 개인정보 및 사생활을 침해하여서는 아니됩니다.</li>
				</ul>
				
				<h2>제6조(처리하는 개인정보 항목)</h2>
				<p>농협 젖소개량사업소는 다음의 개인정보 항목을 처리하고 있습니다. </p>
				<ul className="line1">
					<li>1. 인터넷 서비스 이용과정에서 아래 개인정보 항목이 자동으로 생성되어 수집될 수 있습니다.
						<ul className="line3">
							<li>쿠키</li>
						</ul>
					</li>
					<li>2. 법령에의한 개량사업 업무처리
						<ul className="line3">
							<li>필수항목 : 성명, 전화번호, 주소</li>
						</ul>
					</li>
				</ul>
				
				<h2>제7조(개인정보의 파기)</h2>
				<ul className="line1">
					<li>① 농협 젖소개량사업소는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다.</li>
					<li>② 정보주체로부터 동의받은 개인정보 보유기간이 경과하거나 처리목적이 달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속 보존하여야 하는 경우에는, 해당 개인정보(또는 개인정보파일)를 별도의 데이터베이스(DB)로 옮기거나 보관장소를 달리하여 보존합니다.</li>
					<li>③ 개인정보 파기의 절차 및 방법은 다음과 같습니다.
						<ul className="line2">
							<li>
								1. 파기절차<br/>
								농협 젖소개량사업소는 파기하여야 하는 개인정보(또는 개인정보파일)에 대해 개인정보 파기계획을 수립하여 파기합니다. 농협 젖소개량사업소는 파기 사유가 발생한 개인정보(또는 개인정보파일)를 선정하고, 농협 젖소개량사업소는 개인정보 보호책임자의 승인을 받아 개인정보(또는 개인정보파일)를 파기합니다. 
							</li>
							<li>
								2. 파기방법<br/>
								농협 젖소개량사업소는 전자적 파일 형태로 기록·저장된 개인정보는 기록을 재생할 수 없도록 기술적 방법을 이용하여 파기하며, 종이 문서에 기록·저장된 개인정보는 분쇄기로 분쇄하거나 소각하여 파기합니다. 
							</li>
						</ul>
					</li>
				</ul>
				
				<h2>제8조(개인정보의 안전성 확보조치)</h2>
				<p>농협 젖소개량사업소는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다. </p>
				<ul className="line1">
					<li>1. 관리적 조치 : 내부관리계획 수립·시행, 정기적 직원 교육 등</li>
					<li>2. 기술적 조치 : 개인정보처리시스템 등의 접근권한 관리, 접근통제시스템 설치, 고유식별정보 등의 암호화, 보안프로그램 설치</li>
					<li>3. 물리적 조치 : 전산실, 자료보관실 등의 접근통제</li>
				</ul>
				
				<h2>제9조(개인정보 보호책임자)</h2>
				<ul className="line1">
					<li>① 농협 젖소개량사업소는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.
						<ul className="line2">
							<li>1. 개인정보 보호책임자 
								<ul className="line3">
									<li>성명 : 정호상</li>
									<li>직책 : 소장</li>
									<li>직급 : 3급</li>
									<li>연락처 : Tel. 031-929-1080, Fax. 031-929-1090</li>
								</ul>
							</li>
							<li>2. 개인정보 보호 담당부서
								<ul className="line3">
									<li>부서명 : 종축부</li>
									<li>담당자 : 이동수 팀장</li>
									<li>연락처 : Tel. 031-929-1010, Fax. 031-929-1090</li>
								</ul>
							</li>
						</ul>
					</li>
					<li>② 정보주체께서는 농협 젖소개량사업소의 서비스(또는 사업)를 이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자 및 담당부서로 문의하실 수 있습니다. 농협 젖소개량사업소는 정보주체의 문의에 대해 지체없이 답변 및 처리해드릴 것입니다. </li>
				</ul>
				
				<h2>제10조(개인정보 열람청구)</h2>
				<p>정보주체는 「개인정보 보호법」 제35조에 따른 개인정보의 열람 청구를 아래의 부서에 할 수 있습니다. 농협 젖소개량사업소는 정보주체의 개인정보 열람청구가 신속하게 처리되도록 노력하겠습니다.</p>
				<ul className="line1">
					<li>1. (민원처리) 개인정보 열람청구 접수·처리 부서
						<ul className="line3">
							<li>부서명 : 가축개량원 판매부(정액판매관련)</li>
							<li>담당자 : 민원처리 담당 책임자</li>
							<li>연락처 : Tel. 041-661-4661~4663, Fax. 041-663-4669</li>
						</ul>
					</li>
					<li>2. (젖소능력검정 및 유우군능력검정사업) 개인정보 열람청구 접수·처리 부서
						<ul className="line3">
							<li>부서명 : 젖소개량사업소 개량부(검정관련)</li>
							<li>담당자 : 검정사업 담당자</li>
							<li>연락처 : Tel. 031-929-1070, 1078, 1062, Fax. 031-929-1090</li>
						</ul>
					</li>
				</ul>
				
				<h2>제11조(권익침해 구제방법)</h2>
				<p>정보주체는 아래의 기관에 대해 개인정보 침해에 대한 피해구제, 상담 등을 문의하실 수 있습니다. </p>
				<p>&lt;아래의 기관은 농협 젖소개량사업소와는 별개의 기관으로서, 농협 젖소개량사업소의 자체적인 개인정보 불만처리, 피해구제 결과에 만족하지 못하시거나 보다 자세한 도움이 필요하시면 문의하여 주시기 바랍니다&gt;</p>
				<ul className="line1">
					<li>1. 개인정보 침해신고센터 (한국인터넷진흥원 운영)
						<ul className="line3">
							<li>소관업무 : 개인정보 침해사실 신고, 상담 신청</li>
							<li>홈페이지 : privacy.kisa.or.kr</li>
							<li>전화 : (국번없이) 118</li>
							<li>주소 : (138-950) 서울시 송파구 중대로 135 한국인터넷진흥원 개인정보침해신고센터</li>
						</ul>
					</li>
					<li>2. 개인정보 분쟁조정위원회 (한국인터넷진흥원 운영)
						<ul className="line3">
							<li>소관업무 : 개인정보 분쟁조정신청, 집단분쟁조정 (민사적 해결)</li>
							<li>홈페이지 : privacy.kisa.or.kr</li>
							<li>전화 : (국번없이) 118</li>
							<li>주소 : (138-950) 서울시 송파구 중대로 135 한국인터넷진흥원 개인정보침해신고센터</li>
						</ul>
					</li>
					<li>3. 대검찰청 사이버범죄수사단 : 02-3480-3573 (www.spo.go.kr)</li>
					<li>4. 경찰청 사이버테러대응센터 : 1566-0112 (www.netan.go.kr)</li>
				</ul>
				
				<h2>제12조(영상정보처리기기 설치·운영)</h2>
				<p>농협 젖소개량사업소는 아래와 같이 영상정보처리기기를 설치·운영하고 있습니다.</p>
				<ul className="line1">
					<li>1. 영상정보처리기기 설치근거·목적 : 농협경제지주의 시설안전 및 화재예방, 방역시설 출입통제</li>
					<li>2. 설치 대수, 설치 위치, 촬영 범위 
						<ul className="line3">
							<li>고양사업소(29대) : 정문1, 후보우사13, 검정우사 13, 정액창고 2</li>
							<li>영양사업소(40대) : 정문1, 차량소독조1, 후보우수32, 격리우사4, 수정란센터2</li>							
						</ul>
					</li>
					<li>3. 관리책임자, 담당부서 및 영상정보에 대한 접근권한자
						<ul className="line3">
							<li>관리책임자 : 정호상 소장</li>
							<li>접근권한자 : 김판수 과장</li>
						</ul>
					</li>
					<li>4. 영상정보 촬영시간, 보관기간, 보관장소, 처리방법 
						<ul className="line3">
							<li>촬영시간 : 24시간 촬영</li>
							<li>보관기간 : 촬영시부터 60일</li>
							<li>보관장소 및 처리방법 : 사양팀</li>
						</ul>
					</li>
					<li>5. 영상정보 확인 방법 및 장소
						<ul className="line3">
							<li>확인방법 : 영상정보 관리책임자에게 요구</li>
							<li>확인장소 : 젖소개량사업소</li>
						</ul>
					</li>
					<li>6. 정보주체의 영상정보 열람 등 요구에 대한 조치 : 개인영상정보 열람·존재확인 청구서로 신청하여야 하며, 정보주체 자신이 촬영된 경우 또는 명백히 정보주체의 생명·신체·재산 이익을 위해 필요한 경우에 한해 열람을 허용함</li>
					<li>7. 영상정보 보호를 위한 기술적·관리적·물리적 조치 : 내부관리계획 수립, 접근통제 및 접근권한 제한, 영상정보의 안전한 저장·전송기술 적용, 처리기록 보관 및 위·변조 방지조치, 보관시설 마련 및 잠금장치 설치 등</li>
				</ul>
				
				<h2>제13조(개인정보 처리방침 변경)</h2>
				<p>현 개인정보취급방침은 2012년 7월 19일에 제정된 내용이며 정보의 정책 또는 보안기술의 변경에 따라 내용의 추가 삭제 및 수정이 있을 시에는 최소 7일 전부터 홈페이지의 [공지]란을 통해 고지할 것입니다.</p>
				
				<h2>제14조(개인정보 자동 수집 장치의 설치·운영 및 거부)</h2>
				<p>농협 젖소개량사업소는 이용자에게 개별적인 맞춤서비스를 제공하기 위해 이용정보를 저장하고 수시로 불러오는 ‘쿠기(cookie)’를 사용합니다.</p>
				<ul className="line1">
					<li>1. 쿠키는 웹사이트를 운영하는데 이용되는 서버(http)가 이용자의 컴퓨터 브라우저에게 보내는 소량의 정보이며 이용자들의 PC 컴퓨터내의 하드디스크에 저장되기도 합니다.
						<ul className="line3">
							<li>쿠키의 사용목적: 이용자가 방문한 각 서비스와 웹 사이트들에 대한 방문 및 이용형태, 인기 검색어, 보안접속 여부, 등을 파악 하여 이용자에게 최적화된 정보 제공을 위해 사용됩니다.</li>
							<li>쿠키의 설치·운영 및 거부 : 웹브라우저 상단의 도구-인터넷 옵션-개인정보 메뉴의 옵션 설정을 통해 쿠키 저장을 거부 할 수 있습니다.</li>							
							<li>쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수 있습니다.</li>
						</ul>
					</li>
				</ul>


      

      </div>


    </div>
  );
}

export default PersonalInfo;
