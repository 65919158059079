import React from 'react';

import pic419 from 'assets/images/sub/_images/pic419.gif';
import pic420 from 'assets/images/sub/_images/pic420.gif';
import pic421 from 'assets/images/sub/_images/pic421.gif';
import pic422 from 'assets/images/sub/_images/pic422.gif';

function Do216() {

  return (
    <div className="techinfoview">
      <h1>축사환경-자연환기시스템</h1>
      <p className="pstyle">&nbsp;&nbsp;&nbsp;자연 환기방식에 비하여 축사 내부의 온도와 공기의 흐름을 더욱 인위적으로 조절할 수 있는 것이 기계식 환기방식의 장점이다. 기계식 환기방식은 스탄쉥 우사, 착유실, 우유저장실, 창고 등의 시설에 이용되며, 음압 및 양압 환기방식으로 구분된다. 일반적으로 음압 환기방식이 이용되며, 양압 환기방식은 자연환기방식을 보완하는데 주로 이용된다.</p>
      <ul>
        <li>음압 환기방식
          <ul>
            <li>배기팬을 작동시켜 공기를 축사 밖으로 뽑아냄에 따라서, 축사내에 음압이 형성되어 입기구를 통하여 신선한 공기가 유입되어 고르게 퍼진다. 입기구는 양쪽 측면 벽에 설치한다. 축사의 길이 150′당 팬군(group fans)은 1개가 되도록 한다. 입기구 이외의 틈새나 구멍을 통해 공기가 유입되어 공기의 고른 분포와 음압형성을 방해하지 않도록, 다음과 같은 틈새를 봉하도록 한다. 
              <ul>
                <li>열린 문, 창문 및 물받이 통로의 축사 밖으로 연결된 부분(한 지역에서 너무 많은 공기가 유입되면 다른 지역의 공기는 정체된다) </li>
                <li>벽, 천장, 문과 창문 주의의 갈라진 틈새(적은 양의 공기 유입도 공기의 고른 분포와 음압형성을 방해한다) </li>
                <li>외부나 옆 건물로 연결되는 수세통로(제거할 수 있는 문이나 무거운 커튼을 설치하여 틈새를 막는다) </li>
              </ul>
            </li>
          </ul>
        </li>
        <li>팬 선택 요령
          <ul>
            <li>최소한 1/8″(0.125″)정지압에 대하여 작동할 수 있는 팬을 선택하여야 하며, 추운 겨울철의 환기요구량에 대비하여 용량이 풍부한 고정된 속도의 팬이나 2가지 또는 그 이상 여러 가지 속도를 낼 수 있는 팬을 사용한다. 반드시 검사필의 팬을 사용하며, 우사용으로 특별히 고안된 팬을 선택하도록 한다. 하나의 모터휴즈가 나갔을 경우, 전체 환기체계가 중지되는 것을 방지하기 위하여, 각 팬은 각각의 분리된 배선판에 연결시키도록 한다. 각 팬에 시간지연 휴즈를 설치하며, 팬전류량의 25% 이상 초과시 휴즈가 나가도록 한다.  팬모터는 과열이 방지되고 손으로 스위치를 재조절하는 것으로 선택하여, 검사 중에 모터가 작동하거나 모터가 자주 자동으로 꺼졌다가 작동되는 일이 없도록 한다. 정상적인 환기를 위하여, 정기적으로 팬벨트를 교체하고, 팬, 셔터 및 기타 장비의 청소와 윤활유 첨가를 실시하여야 한다.</li>
          </ul>
        </li>
      </ul>
      <h2>기타 축사환경 </h2>
      <ul>
        <li>분뇨수거조(manure pit)의 환기 
          <ul>
            <li>분뇨수거조에 환기장치의 설치는, 분뇨가스 및 냄새 발생을 감소시킬 수 있고, 공기순환을 향상시키며, 바닥을 건조시키는데 도움을 준다. 틈바닥 지주의 아랫부분과 분뇨상층과의 사이는 최소한 12″간격이 유지되도록 한다. 다양한 속도로 작동되는 팬을 사용할 필요는 없으나, 방부처리된 팬을 사용해야 하고, 환기장치가 수거조의 유해 가스를 다 제거할 수 없으므로 늘 주의하여야 한다. </li>
          </ul>
        </li>
        <li>천정공간의 환기
          <ul>
            <li>날씨변화에 의하여 발생되는 습기의 축적을 최소화시키기 위하여, 천정 ft²당 1 in²크기의 지붕창을 설치하여야 한다. 천정환기구가 가축으로부터 발생되는 습기 많고 따뜻한 공기를 잡아 두지 않도록 유의하여야 한다. </li>
          </ul>
        </li>
        <li>전열기 
          <ul>
            <li>적절한 축사내 온도를 유지시키기 위해서 부수적으로 전열기를 사용할 필요가 있다. 전열기는 환기와 전도를 통하여 축사 밖으로 빠져나가는 열량을 충당시킬 만큼의 크기로 결정하여야 하며, 다음과 같은 공식을 이용하여 전열기의 대략적인 크기를 결정한다.<br/><br/>HEAT = Q × TD × 1.1 <br/>HEAT = 전열기 크기, Btu/시간&nbsp;&nbsp;&nbsp;Q = 환기율, cfm <br/>TD = 온도차이, Ti-To, ℃&nbsp;&nbsp;&nbsp;To = 축사 밖의 온도, 9/5℃+32 <br/>Ti = 적절한 축사내 온도, 9/5℃+32  </li>
          </ul>
        </li>
        <li>단열처리 
          <ul>
            <li>한쪽에서 다른 쪽으로 열이 전달되는 것을 감소시키는 재질을 단열재라 한다. 단열재의 단열시키는 정도를 나타내는 값을 단열가라 하며 R로 표시하는데, R 값이 높을수록 단열치가 높은 것이다. 
              <ul>
                <li>단열수준 
                  <ul>
                    <li>착유장의 단열수준은 겨울철에 열이 손실되는 정도를 바탕으로 결정된다. 최소한 벽과 천장의 단열수준은 각각 R값이 11과 19가 되도록 한다. 개방식 우사에서는 단열처리를 할 필요가 없지만, 지붕을 단열처리 할 경우에는 R값이 5이상인 단열재를 사용한다. </li>
                  </ul>
                </li>
                <li>단열재의 설치 
                  <ul>
                    <li>천장, 지붕, 벽 및 건물기초에 대한 단열재 설치의 예가 그림 90-93에 나타나 있다. 단지 단열만을 위해서는 땅 밑 24″까지의 콘크리트 기초에 단열재를 설치하며, 설치류의 공격까지 방지하기 위해서는 땅 밑 36″까지의 콘크리트 기초에 단열재를 설치한다. 건물기초에 사용되는 단열재는 섬유유리로 강화된 플라스틱이나 건물기초용 합판으로 보호된 단단한 재질이어야 한다.</li>
                  </ul>
                  <img src={pic419} width="30%" alt=""/>
                  <p>그림 90. 천정의 단열처리</p>
                  <img src={pic420} width="60%" alt=""/>
                  <p>그림 91. 단열재의 설치</p>
                </li>
                <li> 방습막(vapor barrier) 
                  <ul>
                    <li>단열재에 수분이 차면 열손실이 증가하고 건물에 손상을 준다. 방습막은 벽, 천정 및 지붕을 통하여 스며드는 습기를 차단해 주는 역할을 하므로서 단열호과를 극대화 시킨다. 방습막은 4∼6mili 되는 폴리에틸렌을 사용하여 단열처리된 벽의 내부면에 설치한다. </li>
                  </ul>
                </li>
                <li>단열재의 방화처리
                  <ul>
                    <li>일반적으로 단열재는 화재위험이 매우 높기 때문에 방화재료로 코팅되어야 한다. 우사와 같이 습도가 높은 곳에는 코딩재료로 석회석판을 사용하지 말고, 다음과 같은 재료를 이용한다. <br/>1/2″두께의 시멘트반죽 <br/>1/4″두께의 마그네슘 산화염소(magnesium oxychloride; 27㎏/ft3) <br/>1/2″두께의 합판 </li>
                  </ul>
                </li>
                <li>단열을 위한 문과 창문의 설치 
                  <ul>
                    <li>문은 바람이 불어가는 쪽에 설치하며, 단열처리가 되어있고 틈마개가 부착되어 있어야 한다. 바람이 불어오는 쪽에는 이중으로 문을 설치하여 공간을 마련함으로써 찬바람이 직접 건물 내로 유입되지 않도록 하여야 하는데, 이렇게 마련된 공간은 사무실의 일부, 창고, 또는 세면실로 사용하여 건물바닥의 낭비를 막을 수도 있다. 축사의 창문과 채광창은 최소화시켜야 하는데, 특히 폐쇄식 축사의 경우에 창문과 채광창을 통하여 겨울철에 열이 많이 손실되므로 유의하여야 한다. 창문의 단열치 (R)는 벽의 단열치(R=13∼15)보다 훨씬 낮은 1-2.5 이다. 개방식 축사의 경우에 채광창을 통하여 빗물이 새고 열이 유입될 수도 있다. 따라서, 개축할 경우에 모든 창문을 단열처리되어 있고 여름철에 개방이 가능한 이동식 패널(panel)이나 영구적인 벽으로 교체하는 것을 고려할 만하다.</li>
                  </ul>
                  <img src={pic421} className="nonborder" width="60%" alt=""/>
                  <p>그림 92. 벽 단열처리</p>
                  <img src={pic422} className="nonborder" width="30%" alt=""/>
                  <p>그림 93. 건물기초의 단열처리</p>
                </li>
                <li>새와 설치류로부터의 단열재 보호
                  <ul>
                    <li>단열재가 새와 설치류에 의하여 손상되는 것을 방지하기 위하여 내부덧판(inside liner)을 사용할 수 있는데, 단열재의 끝부분이 보호되도록 유의하여야 하며, 알루미늄호일로 단열재를 덮어 주는 것만으로는 단열재를 충분히 보호할 수 없다. 노출된 단열재 둘레를 보호덧판으로 덮어 줌으로써, 단열재를 보호할 수 있는데, 덧판의 재질로 고밀도 섬유소유리로 강화된 플라스틱을 사용하는 것이 좋다. 덧판재질로 3/8&quot; 합판을 사용하면, 수분 유입이나 물리적 손상을 방지할 수 있지만 설치류에 의한 손상을 방지할 수 없는 단점이 있다. 설치류의 공격을 예방하기 위하여 벽과 지붕에 생긴 구멍과 틈새를 봉하여야 한다.  우사의 환기구에 망을 설치함으로써 새의 침입을 예방할 수 있는데, 단단한 3/4 천이나 플라스틱 망을 설치할 수 있다. 겨울철에는 환기구에 설치된 망에 얼음이 쌓이면 제거하여야 한다.</li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
}

export default Do216;