import React from 'react';

import Pic13 from 'assets/images/sub/_images/pic13.png';
import Pic14 from 'assets/images/sub/_images/pic14.png';
import Pic15 from 'assets/images/sub/_images/pic15.png';
import Pic16 from 'assets/images/sub/_images/pic16.png';
import Pic17 from 'assets/images/sub/_images/pic17.png';
import Pic18 from 'assets/images/sub/_images/pic18.png';

function Do5() {

  return (
    <div className="techinfoview">
    <h1>사양관리</h1>
    <h2>머리말</h2>
    <p>가축사양이란 가축의 영양원리와 사료급여, 질병 및 위생관리, 번식, 육종, 생리, 경영과 함께 종합자연과학분야로 그 범위가 매우 넓다. 좁게 이야기하면 가축의 건강을 유지하고 유전능력을 최대한 발휘할 수 있도록 사료급여 및 환경을 조성하는데 있다. </p>
    <h2>사료의 영양소</h2>
    <ul>
      <li>사료의 성분
        <ul>
          <li>탄수화물(Carbohydrates)
            <p>탄수화물은 식물체의 주성분으로 조섬유와 가용무질소물로 되어 있다. 동물체의 에너지원이며 지방산 및 아미노산 생합성의 기본원료이다. </p>
            <ul>
              <li>조섬유
                <p>일반적으로 가축의 소화액에는 섬유소를 분해하는 효소가 존재하지 않고 초식가축에 있어서 미생물들이 이를 분해하여 에너지를 얻고 있다. 반추동물에서는 1,2위에서 단위성 초식동물에서는 맹장에서 이러한 일이 일어난다. 미생물들이 Cellulise(포도당의 연속결합체)를 분해하여 휘발성지방상(V.F.A)를 생산한다. </p>
              </li>
              <li>가용무질소물
                <p>전분, 당류 등 식물성사료 중에 세포질성 탄수화물로서 소화율이 높고 에너지의 대부분을 이 성분으로 충당한다. </p>
                <img src={Pic13} alt="가용무질소물"/>
              </li>
            </ul>
          </li>
          <li>조단백질(Proteins)
            <p>사료중의 단백질은 가용성단백과 불용성단백으로 구별할 수 있다. 가용성 단백이란 제1위에서 용해되어 미생물에 의해 파괴되는 단백질을 말한다. 이에는 수용성인 질소화합물도 포함되어 있다. 미생물에 의해 분해된 암모니아(NH3)와 아미노산(Aminoacid)을 미생물으로면 제4위와 소장을 경유하면서 단위동물과 같은 소화과정을 거쳐 흡수된다. </p>
            <img src={Pic14} alt="조단백질의 소화"/><br/>&lt;조단백질의 소화&gt;
          </li>
          <li>조지방(Lipids)
            <p>사료중의 조지방이 제 1위내에 들어가면 미생물에 의하여 지방산과 글리셀린으로 분해된다. </p>
            <img src={Pic15} alt="조지방의 소화"/><br/>&lt;조지방의 소화&gt;
          </li>
          <li>무기물
            <ul>
              <li>소금(NaCl)<p>소금중의 NaCl은 조직내 이온형태로 존재하며 혈액과 태액의 구성성분이다. 삼투압 유지, 근육수축, 신경전달, 아미노산의 흡수, 칼슘흡수 및 위산(HCl) 생성에 관여하고 있다. 부족하면 이물질을 먹으며 사료섭취량과 체중이 감소되고 눈에 광택과 털의 윤기가 없어지며 우유생산량이 감소된다. </p></li>
              <li>황(S)<p>단백질합성 및 섬유소 소화에 필수적이며 결핍시 식욕감퇴 산유량, 체중이 감소된다. 요소와 같은 비단백태질소화합물을 단백질 공급원으로 사용하면 황의 요구량이 증가됨으로 요소첨가시 N과 S의 비율이 12:1이 되도록 해야된다. </p></li>
              <li>철(Fe)<p>혈구생성의 필수요소로서 체내 산화과정에 필요한 산소의 운반, 이용 및 세포호흡작용에 관여한다. </p></li>
              <li>Co : VitaminB12 구성인자</li>
              <li>구리(Cu)<p>골격형성과 번식에 필요하며 효소의 구성성분이다.</p></li>
              <li>망간(Mn)<p>탄수화물대사작용 연골형성에 관한 역할을 하며 결핍된 사료를 급여하면 숫송아지 분만 비율이 높다. </p></li>
              <li>아연(An)<p>아연조직과 선조직내에 많이 함유되어 있으며 번식에 중요한 역할을 한다. </p></li>
              <li>요오드(I)<p>갑상선호르몬 Thyroxine합성 </p></li>
              <li>번식과 성장 및 비타민 E 결핍으로 인한 질병예방</li>
            </ul>
          </li>
          <li>비타민
            <p>소량으로 동물의 정상적인 발육이나 생명의 유지에 필수적인 유기화합물이라고 정의할 수 있다. 소량으로 생명의 유지나 에너지의 이용대사작용의 조절 체조직에서의 여러 화합물의 생합성에 관여한다. 반추동물은 반추위내에서 세균의 작용으로 비타민 B 군과 C, K합성 되므로 어린 송아지를 제외하고는 이러한 비타민을 별도로 급여할 필요가 없다. </p><br/>
            &lt;비타민의 종류&gt;
            <table className="tablestyle">
              <tr>
                <td>구분</td>
                <td>종류</td>
              </tr>
              <tr>
                <td>수용성비타민</td>
                <td>B1, B2, 니아신, B6, 판토산, 바이오틴, B12</td>
              </tr>
              <tr>
                <td>지용성</td>
                <td>A, D, E, K</td>
              </tr>
              <tr>
                <td>기타</td>
                <td>C, 콜린, 이노시톨</td>
              </tr>
            </table>
          </li>
        </ul>
      </li>
      <li>사료가치 표시법
        <ul>
          <li>가소화양분 총량(TDN)
            <p>TDN = 가소화유기영양소(조단백질 + 조섬유소 + 가용무질소물 + 지방×2.25)</p>
          </li>
          <li>총에너지(G.E)</li>
          <li>가소화에너지(D.E)</li>
          <li>대사에너지(M.E)</li>
          <li>정미에너지(N.E)
            <p>NE = GE - 분에너지 - 요에너지ㆍ가연성가스 - 열발생량<br/>ME = 유지정미에너지 + 열량증가 + 생산정미에너지</p>
          </li>
        </ul>
      </li>
    </ul>
    <h2>송아지 사양관리</h2>
    <ul>
      <li>초유급여
        <p>갓난 송아지에 급여하는 첫 번째 사료는 반드시 초유여야 한다. 왜냐하면 초유에는 갓난 송아지가 질병에 걸리지 않도록 해주는 면역물질 즉 항체가 들어있으며 각종 영양소가 많이 들어오고 초유는 또한 유지방의 소화와 대사를 돕는 작용과 태변을 배설시키는 작용을 하기 때문이다. 초유를 먹이는 시간은 송아지 출생후 빠를수록 좋으므로 30분이내에 먹이도록 한다. 분만후 시간이 경과할수록 초유중의 항체를 흡수하는 송아지의 능력이 떨어지는 때문이다. <br/>&nbsp;&nbsp;&nbsp; 급여량은 하루에 송아지 체중의 8-10% 정도로 하고 2회로 나누어 규칙적으로 급여한다. 초유를 먹이지 못할 경우(어미소사고, 분만전 유루, 심한 혈류, 유방염에 걸린 경우, 너무 묽은 초유)에 비슷한 시기에 분만한 다른 소의 초유를 먹인다. 다른 초유도 구하지 못하면 대용초유를 먹이도록 한다. <br/>&nbsp;&nbsp;&nbsp;1회분 대용초유: 우유 0.6l+끊인물 0.3l+계란흰자1개+식용유2g </p>
        <img src={Pic16} alt="초유급여"/><br/>
        &lt;출생 첫날 초유 급여시기와 급여량&gt;
        <table className="tablestyle">
          <tr>
            <td rowSpan="2">생시부터 시간</td>
            <td rowSpan="2">초유종류</td>
            <td colSpan="2">급여량</td>
          </tr>
          <tr style={{background:'#273143', color:'#fff'}}>
            <td>소형종</td>
            <td>대형종</td>
          </tr>
          <tr>
            <td>생시</td>
            <td>처음 짠 초유</td>
            <td>1.4</td>
            <td>2.0</td>
          </tr>
          <tr>
            <td>6</td>
            <td></td>
            <td>1.4</td>
            <td>2.0</td>
          </tr>
          <tr>
            <td>12</td>
            <td></td>
            <td>1.5</td>
            <td>2.0</td>
          </tr>
          <tr>
            <td>18</td>
            <td>처음 또는 두번째 짠 초유</td>
            <td>1.5</td>
            <td>2.0</td>
          </tr>
        </table>
      </li>
      <li>초유후 - 이유시까지
        <p>포유기 급여하는 액상사료를 목장의 사정에 따라 전유, 대용유 두가지 중 하나를 선택하여 급여한다.</p><br/>&lt;전유급여&gt; 단위 : kg
        <table className="tablestyle">
          <tr>
            <td rowSpan="2">나이</td>
            <td colSpan="3">생시체중 37kg이상</td>
            <td colSpan="3">생시체중 37kg이하</td>
          </tr>
          <tr style={{background:'#273143', color:'#fff'}}>
            <td>8주령이유</td>
            <td>6주령이유</td>
            <td>5주령이유</td>
            <td>11주령이유</td>
            <td>8주령이유</td>
            <td>7주령이유</td>
          </tr>
          <tr>
            <td>1-3일</td>
            <td>초유</td>
            <td>초유</td>
            <td>초유</td>
            <td>초유</td>
            <td>초유</td>
            <td>초유</td>
          </tr>
          <tr>
            <td>4-7일</td>
            <td>3.6</td>
            <td>3.6</td>
            <td>3.2</td>
            <td>2.3</td>
            <td>2.3</td>
            <td>2.3</td>
          </tr>
          <tr>
            <td>2주</td>
            <td>4.1</td>
            <td>4.1</td>
            <td>3.2</td>
            <td>2.7</td>
            <td>2.7</td>
            <td>2.3</td>
          </tr>
          <tr>
            <td>3주</td>
            <td>4.5</td>
            <td>4.5</td>
            <td>3.2</td>
            <td>3.2</td>
            <td>3.2</td>
            <td>2.3</td>
          </tr>
          <tr>
            <td>4주</td>
            <td>4.0</td>
            <td>4.0</td>
            <td>3.2</td>
            <td>3.2</td>
            <td>3.2</td>
            <td>2.3</td>
          </tr>
          <tr>
            <td>5주</td>
            <td>3.2</td>
            <td>2.3</td>
            <td>-</td>
            <td>2.7</td>
            <td>2.3</td>
            <td>1.8</td>
          </tr>
          <tr>
            <td>6주</td>
            <td>2.7</td>
            <td>-</td>
            <td>-</td>
            <td>2.7</td>
            <td>2.3</td>
            <td>1.8</td>
          </tr>
          <tr>
            <td>7주</td>
            <td>1.8</td>
            <td>-</td>
            <td>-</td>
            <td>2.3</td>
            <td>1.4</td>
            <td>-</td>
          </tr>
          <tr>
            <td>8주</td>
            <td>-</td>
            <td>-</td>
            <td>1.8</td>
            <td>-</td>
            <td>-</td>
            <td>2.3</td>
          </tr>
          <tr>
            <td>9주</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>1.4</td>
            <td>-</td>
            <td>-</td>
          </tr>
          <tr>
            <td>10주</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>1.4</td>
            <td>-</td>
            <td>-</td>
          </tr>
          <tr>
            <td>총급여량</td>
            <td>312</td>
            <td>224</td>
            <td>158</td>
            <td>312</td>
            <td>225</td>
            <td>163</td>
          </tr>
        </table><br/>
        &lt;대용유급여&gt; 단위 : kg
        <table className="tablestyle">
          <tr>
            <td rowSpan="2">나이</td>
            <td colSpan="2">출생시 37kg 이상</td>
            <td colSpan="2">출생시 37kg 이하</td>
          </tr>
          <tr style={{background:'#273143', color:'#fff'}}>
            <td>대용유</td>
            <td>물</td>
            <td>대용유</td>
            <td>물</td>
          </tr>
          <tr>
            <td>1-3일경</td>
            <td>초유</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
          </tr>
          <tr>
            <td>4-7일경</td>
            <td>0.36</td>
            <td>-</td>
            <td>초유</td>
            <td>-</td>
          </tr>
          <tr>
            <td>2주령</td>
            <td>0.46</td>
            <td>3.16</td>
            <td>0.28</td>
            <td>2.7</td>
          </tr>
          <tr>
            <td>3주령</td>
            <td>0.46</td>
            <td>3.16</td>
            <td>0.28</td>
            <td>2.7</td>
          </tr>
          <tr>
            <td>4주령</td>
            <td>0.46</td>
            <td>3.16</td>
            <td>0.36</td>
            <td>2.7</td>
          </tr>
          <tr>
            <td>6주령</td>
            <td>-</td>
            <td>3.16</td>
            <td>0.36</td>
            <td>2.7</td>
          </tr>
          <tr>
            <td>총급여량</td>
            <td>24.2</td>
            <td></td>
            <td>24.8</td>
            <td></td>
          </tr>
        </table>
      </li>
      <li>이유
        <p>대체로 송아지는 5-8주령에 젖을 떼는데 주의하여야 할 점은 이 시기의 송아지의 일당증체량은 0.5-0.7kg 정도가 되어야 하고, 일일 500g 정도의 스타터를 섭취해야하며, 이 시기는 출생후 5-8주령이 적정한 것으로 알려지고 있다. 만약 조기 이유를 원할 경우 대용유급여시 처음부터 대용유급여량을 고정시켜 고형사료의 섭취량을 늘려야 하며 급수를 신선하도록 유지하는 것은 송아지의 일당증체량이 498-860g 정도를 유지하고 폐사율은 5%이 하로하여 조기 이유를 시키는 것이 합당한 사양관리 기법이다. </p><br/>
        &lt;이유를 위한 보조 사료 급여량&gt;
        <table className="tablestyle">
          <tr>
            <td>나이</td>
            <td>인공유(g)</td>
            <td>건추</td>
            <td>물(L)</td>
          </tr>
          <tr>
            <td>8-10일경</td>
            <td>20-50</td>
            <td>-</td>
            <td>-</td>
          </tr>
          <tr>
            <td>11-15</td>
            <td>30-60</td>
            <td>4-7</td>
            <td>-</td>
          </tr>
          <tr>
            <td>16-20</td>
            <td>40-70</td>
            <td>10-20</td>
            <td>-</td>
          </tr>
          <tr>
            <td>21-25</td>
            <td>100-500</td>
            <td>20-50</td>
            <td>0.5</td>
          </tr>
          <tr>
            <td>26-30</td>
            <td>500-700</td>
            <td>30-70</td>
            <td>1-3</td>
          </tr>
          <tr>
            <td>31-35</td>
            <td>700-1000</td>
            <td>40-80</td>
            <td>1-5</td>
          </tr>
          <tr>
            <td>36-40</td>
            <td>1000-26000</td>
            <td>50-150</td>
            <td>1.5-자유채식</td>
          </tr>
          <tr>
            <td>41-70</td>
            <td>-</td>
            <td>200-300</td>
            <td>자유채식</td>
          </tr>
        </table><br/>
      </li>
      <li>포유기의 일반관리
        <ul>
          <li>개체표시
            <p>개체표시을 하는 방법은 여러 가지가 있다. 이표표시법, 냉동식자법, 알미늄라벨 부착법등이있는데 이표를 부착하는 것이 가장 손쉬운 방법이다. <br/>개체 표식은 여러두의 송아지 사육시에도 그 소의 혈통과 능력 그리고 등록 등의 각종 기록유지에 필요하고 개체관리를 수행하는 차원에서 보면 매우 중요한 작업이다. </p>
          </li>
          <li>부유두 제거
            <p>착유우가 부유두를 가지고 생활할 경우 유방염에 감염될 확률리 높고 기계착유시에 비효율적이므로 제거하는 것이 좋다. 적정한 시기는 생후 2-6주령이다. </p>
          </li>
          <li>제각
            <p>제각시는 보통 1-2주령에 실시하며 늦어도 30일을 넘기지 않도록 한다. <br/> &nbsp;&nbsp;&nbsp;제각방법은 첫째주 수산화 나트륨을 뿔자리를 찾아 주위 털을 깎고 바세린이나 구리스를 둥그렇고 바르고 뿔생장점 부위에 돌리면서 문지른다. 겉부분이 부드럽게 되면 다른쪽 뿔에 문지르고 번갈아가면서 반복한다. 약제는 극독성이므로 세심한 주위와 보관에 신경을 쓴다. 둘째로 전기제각기를 사용하여 뿔자리를 찾아 지져준다. 2-4주령 사이에 실시하는 것이 좋다. </p><br/>&lt;월령별 체중과 적정 증체수준&gt;
            <table className="tablestyle">
              <tr>
                <td>월령</td>
                <td>체중(kg)</td>
                <td>일당증체량 kg/일</td>
              </tr>
              <tr>
                <td>생시</td>
                <td>43</td>
                <td>-</td>
              </tr>
              <tr>
                <td>1</td>
                <td>53</td>
                <td>0.33</td>
              </tr>
              <tr>
                <td>2</td>
                <td>72</td>
                <td>0.64</td>
              </tr>
              <tr>
                <td>3</td>
                <td>97</td>
                <td>0.82</td>
              </tr>
              <tr>
                <td>4</td>
                <td>122</td>
                <td>0.84</td>
              </tr>
            </table>
            <p>※생시-4개월령까지 평균 일당 증체량 0.68kg</p>
          </li>
        </ul>
      </li>
      <li>이유 후 조산까지의 사양관리
        <p>이 시기 육성우의 사양관리는 실제로 소의 사양관리기간중 가장 중요한 시기로 보여진다. 이 시기의 사양관리 형태가 소의 평생동안의 생산성과 밀접한 관련을 맺고 있기 때문이다. 이 시기의 사양관리 주요요점은 첫째로 성장에 관련된 모든 기록을 반드시 유지해야하고 둘째는 균형적인 사료를 급여해야 하며 셋째는 개체관리에 만전을 기해야 하며 마지막으로 소홀히 하기 쉬운 우사환기여건과 우상의 청결성을 유지하여 소가 강건성을 갖도록 해야 하는 것이다. </p>
        <ul>
          <li>이유 후 번식기까지의 성장률
            <p>이 시기 일당증체량은 680-906g 정도를 유지하는 것이 적당하다. 그 이유는 만약 680g 미만을 성장할 경우 초발정이 오는 시기가 늦어지므로 경제적인 손실이 있게 되고 따라서 초산월령이 지연된다. 한편 906g 보다 일당증체량이 높을 경우 성성숙의 지연, 대사성질병의 제고, 분만장애, 수정율 감소 및 유선조직의 발당이 저해되는 결과를 가져온다. 중요한 것은 이러한 육성가의 성장률에 따라 영향은 영구적이라는 것이다. 따라서 다음과 같은 성장표준을 지키기 위해 아래의 표와 같은 영양소 요구량을 지키는 것이 선행되어야 한다. </p>
            <ul>
              <li>0-4개월령 : 450-700g 일당증체량</li>
              <li>4개월 - 춘기발동기 : 700-810g 일당증체량</li>
              <li>교배적기 - 송아지분만 : 700-1000g 일당증체량
                <p>&lt;송아지 및 육성우시기의 영양소 요구량&gt;</p>
                <table className="tablestyle">
                  <tr>
                    <td rowSpan="2">체중(kg)</td>
                    <td rowSpan="2">평균건물섭취량(kg)</td>
                    <td colSpan="2">가소화영양소총량(TDN)</td>
                    <td colSpan="2">조단백질(CP)</td>
                  </tr>
                  <tr style={{background: '#273143', color:'#fff'}}>
                    <td>%</td>
                    <td>kg</td>
                    <td>%</td>
                    <td>kg</td>
                  </tr>
                  <tr>
                    <td>90</td>
                    <td>2.6</td>
                    <td>70</td>
                    <td>2</td>
                    <td>16</td>
                    <td>0.4</td>
                  </tr>
                  <tr>
                    <td>140</td>
                    <td>3.4</td>
                    <td>69</td>
                    <td>2.5</td>
                    <td>16</td>
                    <td>0.6</td>
                  </tr>
                  <tr>
                    <td>180</td>
                    <td>4.6</td>
                    <td>68</td>
                    <td>3.1</td>
                    <td>15.9</td>
                    <td>0.7</td>
                  </tr>
                  <tr>
                    <td>220</td>
                    <td>5.5</td>
                    <td>66</td>
                    <td>3.6</td>
                    <td>13.1</td>
                    <td>0.7</td>
                  </tr>
                  <tr>
                    <td>270</td>
                    <td>6.5</td>
                    <td>65</td>
                    <td>4.1</td>
                    <td>12.0</td>
                    <td>0.7</td>
                  </tr>
                  <tr>
                    <td>330</td>
                    <td>7.5</td>
                    <td>64</td>
                    <td>4.7</td>
                    <td>12.0</td>
                    <td>0.9</td>
                  </tr>
                  <tr>
                    <td>350</td>
                    <td>8.5</td>
                    <td>62</td>
                    <td>5.0</td>
                    <td>12.0</td>
                    <td>1.0</td>
                  </tr>
                </table>
                <p>주) NRC 사양표준 기준</p>
              </li>
              <li>미경산우의 군별 사양
                <p>미경산우는 반드시 개체관리가 용이하도록 군별사양을 해야한다. 군별사양은 이유전단계(0-2개월), 전환기(-3개월), 대기군(4-12개월), 번식군(Breeding Age) 및 분만 건유군으로 분리한다. <br/>&nbsp;&nbsp;&nbsp;4-12개월령에 해당하는 대기군의 경우 발육부진이나 과비가 되지 않도록 일당증체량을 600-800g으로 유지한다. 6개월령까지는 가급적 청초, 사일리지와 같은 다즙질 사료는 급여하지 않은 것이 좋으며 4-5kg 정도의 건초위주 사양이 바람직하며 농후사료는 체중의 1%정도 급여를 하고 조사료의 질이 좋지 않을 경우 약 1.5kg 정도 급여한다. <br/>&nbsp;&nbsp;&nbsp;번식기에 들어선 큰송아지 사양관리를 아주 중요하다. 체중이 270kg-340kg정도 되었을 때 영양공급이 부족하면 초발정이 지연되거나 미약발정이 되기 쉽다. 이 시기에는 반드시 질이 좋은 조사료를 급여하는 것이 중요한다. </p><br/>
                &lt;조사료 품질에 따른 육성우 사양&gt;
                <table className="tablestyle">
                  <tr>
                    <td rowSpan="3">연령(월)</td>
                    <td rowSpan="3">체중(kg)</td>
                    <td colSpan="3">조사료 품질</td>
                  </tr>
                  <tr style={{background: '#273143' , color: '#fff'}}>
                    <td>아주좋음</td>
                    <td>보통</td>
                    <td>아주나쁨</td>
                  </tr>
                  <tr style={{background: '#273143', color:'#fff'}}>
                    <td>조:농 비율</td>
                    <td>조:농 비율</td>
                    <td>조:농 비율</td>
                  </tr>
                  <tr>
                    <td>4-6</td>
                    <td>135</td>
                    <td>60:40</td>
                    <td>50:50</td>
                    <td>40:60</td>
                  </tr>
                  <tr>
                    <td>7-12</td>
                    <td>225</td>
                    <td>90:10</td>
                    <td>80:20</td>
                    <td>60:40</td>
                  </tr>
                  <tr>
                    <td>13-18</td>
                    <td>360</td>
                    <td>100:0</td>
                    <td>80:20</td>
                    <td>65:35</td>
                  </tr>
                  <tr>
                    <td>19-22</td>
                    <td>500</td>
                    <td>100:0</td>
                    <td>90:10</td>
                    <td>75:25</td>
                  </tr>
                </table>
              </li>
              <li>번식기까지 사양관리
                <p>육성우는 정상적인 성장률의 유지가 매우 중요하다. 즉 680-906의 일당증체량 유지가 매우 주요한 사양관리의 키포인트며. 이렇게 사양했을 경우 140-18개월령에 체중 340kg에 수정이 가능해진다. 수정이 확인된 후는 일당증체량을 약 680-770g정도로 유지시켜 과비하지 않도록 하고 태아가 성장할 수 있도록 사양을 하되 영양가가 균형을 이루어야 한다. 따라서 초산분만후 적정한 사양의 지표는 초산우 체중이544-566kg 정도가 적당하고 바디콘디션 지수는 약 3.5정도가 적당하다. 따라서 사양관리는 면밀한 관찰과 보호로서 개체 관리가 이루어져야 하며 각종 부산물 등을 이용한 사료 경제성을 제고시켜야 한다. </p>
              </li>
              <li>성공적인 육성우 사양을 위한 표준
                <ul>
                  <li>출생후 정확한 초유급여 지침 이행</li>
                  <li>조기이유</li>
                  <li>성장률과 병력 등의 자세한 기록</li>
                  <li>일당증체량의 적정수준 유지</li>
                  <li>분만 2주령 착유사료 영양수준에 적응</li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
    <h2>임신기간 중 사양관리</h2>
    <p>첫 수태를 한 육성우는 몸의 발육이 계속됨으로 충분한 사료를 공급하고 운동장에서 자유롭게 운동을 시킨다. 초산 및 2산우의 경우에는 영양소 요구량을 성장을 위해 10-15% 더 추가 급여한다. </p><br/>&lt;조사품질에 따른 초임우 배합사료 일당 급여량&gt;
    <table className="tablestyle">
      <tr>
        <td rowSpan="2">조사료 품질</td>
        <td rowSpan="2">유기물소화율(%)</td>
        <td>조사료(예)</td>
        <td colSpan="5">분만전(주)</td>
        <td rowSpan="2">평균급여량</td>
      </tr>
      <tr style={{background: '#273143', color:'#fff'}}>
        <td></td>
        <td>5</td>
        <td>4</td>
        <td>3</td>
        <td>2</td>
        <td>1</td>
      </tr>
      <tr>
        <td>가</td>
        <td>53</td>
        <td>저질(싸일이, 건초)</td>
        <td>0.9</td>
        <td>1.8</td>
        <td>3.2</td>
        <td>4.5</td>
        <td>5.4</td>
        <td>3.2kg/5주</td>
      </tr>
      <tr>
        <td>양</td>
        <td>63</td>
        <td>양질(싸일리지, 건초)</td>
        <td>-</td>
        <td>-</td>
        <td>1.4</td>
        <td>2.7</td>
        <td>4.1</td>
        <td>2.7kg/3주</td>
      </tr>
      <tr>
        <td>우</td>
        <td>70</td>
        <td>우수한(싸일리지, 건초)</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td>0.9</td>
        <td>8</td>
        <td>1.4kg/2주</td>
      </tr>
    </table><br/>
    <h2>건유기 사양관리</h2>
    <p>합리적인 젖소의 사양관리에 있어 건유기가 매우 중요하다. 건유기에 충분한 영영소가 축적되지 못하면 분만후 다음 산유기 동안 약 500-900kg의 우유생산량 감소가 있다고 보고가 있고 분만후 대사성장에는 건유기 동안 영양소 공급량이나 균형이 잘못되어 오는 경우가 대부분이다. </p><br/> &lt;건유기 사료급여 계획&gt;
    <table className="tablestyle">
      <tr>
        <td>일수</td>
        <td>건유시작</td>
        <td>건유기(60일)⇒14일←</td>
      </tr>
      <tr>
        <td>사료급여</td>
        <td style={{textAlign: 'left', paddingLeft: '20px'}}>
          <ul>
            <li>조사료 위주 사양</li>
            <li>농후사료 건유우사료로 전환(착유사료보다 TDN, CP 등이 낮음)</li>
            <li>과비하지 않도록 함</li>
          </ul>
        </td>
        <td style={{textAlign: 'left', paddingLeft: '20px'}}>
          <ul>
            <li>분만 후 사료에 쉽게 적용토록 유도 사양</li>
            <li>농후사료는 착유사료로 전환</li>
            <li>농후사료 급여량은 1일 300-500g 씩 서서히 증가시켜 분만시에 체중의 1%까지 증가시킴</li>
          </ul>
        </td>
      </tr>
    </table><br/>
    <ul>
      <li>건유의 목적
        <ul>
          <li>유선조직의 재생, 증식을 위한 유식시간</li>
          <li>영양소의 보충과 축적</li>
          <li>소화기관의 휴식기</li>
          <li>태아의 발육</li>
        </ul>
      </li>
      <li>건유시기 및 기간
        <p>건유시기는 젖소의 건강상태, 예상분만일 등을 고려하여 결정하여야 한다. 기간은 일반적으로 60일이내외가 적당하다.</p>
        <img src={Pic17} alt="건유시기 및 기간"/>
        <img src={Pic18} alt="건유시기 및 기간"/>
      </li>
    </ul>
    <h2>착유우의 사양관리</h2>
    <p>비유중에 있는 젖소의 양분요구량은 주로 체중과 우유생산량과 유지방생산 수준에 의해 결정된다. 실제로 양분요구량은 우유 생산이 피크에 달하는 비유초기에 가장 많으며 시일이 경과하여 우유생산량이 감소됨에 따라 양분요구량도 감소하게 된다. </p>
    <ul>
      <li>비유초기 사양
        <p>일이 우유생산이 최고수준에 달하는 시기는 분만후 6주 전후이다. 그러나 사료 섭취 최고수준에 이르는 시기는 분만후 12-14주경이다. 이와 같이 사료섭취량이 최고에 이르는 시기가 우유생산이 최고에 이르는 6-8주 후에 도달되기 때문에 비유초기의 젖소는 심한 영양결핍상태에 놓이게 된다. 이 시기에는 체내에 축적되어 있는 양분을 사용하게 되어 60-100kg의 체중감소가 있다. 비유초기에는 사료의 양분함량 수준을 높여야 하며 단백질 공급은 대두박 등 자연단백 위주로 하는 것이 좋다. </p>
      </li>
      <li>비유중기 사양
        <p>사료섭취량이 최대로 증가하는 시기이다. 이때 조사료의 섭취량은 건물기준으로 최소한 체중의 1%가 되도록 하며 농후사료는 체중의 2.5%까지 급여할 수 있다. 이 시기에는 충분한 양의 사료를 섭취하기 때문에 사료중의 에너지 및 단백질함량이 비유초기와 같이 높을 필요가 없다. </p>
      </li>
      <li>비유후기 사양
        <p>실제 요구량보다 약간 많은 양의 에너지가 공급될 수 있도록 사료를 급여하여 비유초기에 사용한 체내 축적에너지 수준을 회복하도록 하는 것이 좋다. 건유기간 보다는 비육후기에 에너지등 양분을 보다 효과적으로 체내에 비축한다고 한다. </p>
      </li>
    </ul>
    <h2>착유관리</h2>
    <p>착유는 생산성을 높이기 위한 유전능력의 개량을 위한 노력은 물론 합리적인 사양관리 건강관리 및 관리 등의 과실을 수확하는 과정이다. 따라서 유방염의 방지는 물론 우유 생산증대를 기할 뿐만 아니라 오염되지 않은 우유를 생산할 수 있는 완전한 착유 방법을 익히고 실천해야 한다. </p>
    <ul>
      <li>착유요령
        <ul>
          <li>유방세척은 깨끗한 우유를 생산하기 위해서 뿐만아니라 젖내림을 돕기위해 꼭 필요한 과정이다. 유방세척의 자극이 중추신경을 통해 뇌에 전달되어 뇌하수체후엽에서 옥시톡신(Oxytoxin)이라는 젖내림을 촉진하는 호르몬이 분비되어 동맥을 통해 유방에 도달하게 된다. 옥시톡신(oxytoxin)은 근상세포를 자극, 수축시켜 유선포내의 우유를 유선소관으로 보내고 다시 유선관을 통해 젖내림이 이루어지도록 한다. 이러한 과정에 소요되는 시간은 대게 유방세척후 20-30초 내외 이다. 지속시간 7-10분간 지속되나 최대기능은 4-5분간이다. 시간이 경과되면 신장과 간장에서 옥시톡신(Oxytoxin)을 혈류에서 제거하는 작용을 하게 된다. 동시에 부신피질에서 아드네날린이란 호르몬이 분비되어 젖내림을 억제하게 된다. 그런데 착유된 또는 착유중에도 소를 함부로 다루거나 놀라서 흥분했을 때나 착유를 정기적으로 하지 못하고 착유시간이 지체되었을 때 젖내림준비가 안된 상태에서 착유기를 부착했을 때, 젖내림을 자극한 후 1분 이상 지났을 때, 착유를 시작했을 때 아드레날린 분비량이 증가되어 우유생산을 방해한다. 
            <p><br/>&lt;착유시 때렸을 때 산유량에 미치는 영향&gt;</p>
            <table className="tablestyle">
              <tr>
                <td>구분</td>
                <td>산유량(kg)</td>
                <td>유지율(%)</td>
              </tr>
              <tr>
                <td>때린날</td>
                <td>11</td>
                <td>2.8</td>
              </tr>
              <tr>
                <td>때린 3일후</td>
                <td>15.8</td>
                <td>3.2</td>
              </tr>
              <tr>
                <td>차이</td>
                <td>4.8</td>
                <td>0.4</td>
              </tr>
            </table><br/>
          </li>
          <li>손으로 전착유해서 유방염감염여부를 검사함과 아울러 세균이 많이 감염되어 있는 유두조에 우유를 버림으로써 보다 좋은 질의 우유를 생산하도록 한다. </li>
          <li>유방과 유두의 모양을 유지하도록 착유기를 부착하고 유두컵, 라이나 사이로 공기가 유입되지 않도록 조정한다. </li>
          <li> 후착유를 한후 진공을 끈 후 조심스레 유두컵을 떼낸다. </li>
          <li>유두컵을 떼낸 즉시 베타딘 용액등으로 유두를 적셔준다.</li>
          <li>착유한 우유는 여과기에 여과한 다음 곧 냉각시켜야 하는데 냉각온도는 10-15℃가 적당하다. <br/>
          <br/>&lt;착유경과 시간별 세포의 증식상태&gt;
          <table className="tablestyle">
            <tr>
              <td>착유후 경과시간</td>
              <td>21℃ 보존했을 때의 세균수(1cc중)</td>
              <td>10℃ 보존했을 때의 세균수(1cc중)</td>
            </tr>
            <tr>
              <td>0</td>
              <td>3,000</td>
              <td>3,000</td>
            </tr>
            <tr>
              <td>2</td>
              <td>14,000</td>
              <td>4,600</td>
            </tr>
            <tr>
              <td>24</td>
              <td>4,477,000</td>
              <td>13,500</td>
            </tr>
            <tr>
              <td>36</td>
              <td>149,650,000</td>
              <td>140,000</td>
            </tr>
            <tr>
              <td>96</td>
              <td>응고</td>
              <td>2,200,000</td>
            </tr>
            <tr>
              <td>315</td>
              <td>심하게 응고</td>
              <td>응고</td>
            </tr>
          </table>
          </li>
        </ul>
      </li>
    </ul>
    <h2>분만관리</h2>
    <p>낙농을 하는 목적이 우유 생산이라고 볼 때 젖을 생산하기 위하여는 반드시 송아지의 분만이 이루어져야 한다. 또한 송아지 생산은 낙농가에게 주요한 수입원이고 효율적인 젖생산을 위하여는 분만전후 관리를 철저히하여 분만사고가 일어나지 않도록 하는 것이 좋다. 젖소의 번식은 항상 사람의 관리하에서 이루어지므로 분만시 조산에 필요한 각종 준비물을 분만전에 준비하여 난산사고를 미연에 방지하고 각종세균의 생식기 감염에 의한 질병예방을 해야한다. </p>
    <ul>
      <li>분만 전 어미소 관리
        <p>건강한 송아지를 분만하고 미유량 증가를 위하여 분만전의 철저한 관리가 요망된다. 태아는 분만 약 2개월전에 체중이 늘어나므로 이 시기에 균형이 있는 영양소를 충분히 섭취시켜야 한다. 즉 농후사료는 적량급여하고 조사료는 자유채식시켜 과비하지 않도록 해야 한다. 젖소는 또한 건유기에 유선조직을 재생시키고 유선세포로 하여금 쉴 수 있도록 해주어야 하는데 이시기에는 반드시 충분한 영야분을 보충시켜야한다. 어떠한 경우라도 어미소는 적당한 운동을 시켜 태아의 정상적인 발육을 도모하고 분만예정일 2-3일전에는 과격한 운동을 제한하고 운동장에서 스스로 움직일 수 있도록 해준다. 분만이 가까워지면 어미소를 군에서 격리시켜 청결하고 깨끗한 장소에서 송아지를 분만하게 해주어야 한다. 분만시 소란스럽거나 불안하게 할 경우 분만장애를 초래할 수 있다. </p>
      </li>
      <li>분만 전 조산 준비
        <p>어미소는 분만예정 1주전에 분만실(혹은 격리실)로 옮기는 것이 좋으며 분만실 크기는 4평정도로서 사람 2-3정도가 분만을 도울 수 있는 공간이면 충분하다. </p>&lt;분만준비&gt;
        <ul>
          <li>전등 : 야간 또는 빛이 들지 않을 경우 분만실에 전등을 설치하고 손전 등을 준비한다. </li>
          <li>보온 : 겨울철에는 따뜻하게 해주고 여름철에는 통풍이 잘되도록 도와준다. </li>
          <li>밧줄 : 직경 1츠정도의 부드러운 밧줄을 2-3개나 3-4개 준비하여 필요시 송아지발을 묶을 수 있도록 한다.</li>
          <li>식용유 : 양수가 모자라 송아지가 잘 나오지 못할 때를 대비하여 약 4L 정도는 준비한다. </li>
          <li>소독약 : 손을 씻거나 분만실 주변소독을 위해 강옥도나 조레줄을 준비한다. </li>
          <li>수건 : 송아지 양수 및 점액 등을 닦아주기 위하여 깨끗한 수건이나 천을 준비한다. </li>
          <li>온수 : 소독약제조 및 손을 씻기 위하여 준비한다. </li>
          <li>비누, 가위, 실등도 준비하는 것이 좋다. </li>
        </ul>
      </li>
      <li>분만 시 징후
        <ul>
          <li>분만이 가까워오면 외음부가 붓고 충혈되며 외음부주변이 부드러워진다.</li>
          <li>초임우는 임신 5개월부터 유선조직이 발달한다. 초임우의 경우 임신말기 배꼽주변에 부종이 생길 수 있으나 염려할 필요는 없다.</li>
          <li>분만 1주전 외음부로부터 탄력성 있고 점도가 높은 황백색 점액이 흐른다. </li>
          <li>꼬리 좌우의 미근부 인대가 늘어져 함몰된다. </li>
          <li>분만이 임박하고 식욕을 상실하고 오줌을 자주 조금씩 누고 불안해진다. </li>
        </ul>
      </li>
      <li>정상분만의 과정
        <ul>
          <li>개구진흥기
            <ul>
              <li>개구기는 진통이 시작되면 안정성을 잃고 수시로 뒤를 돌아보며 꼬리를 흔들어 불안해한다.</li>
              <li>최초 진통은 약 15분 간격으로 10-30초간 지속되나 시간이 경과하면서 진통 간격이 짧아지고 자궁수축빈도가 증가하고 3-5분 간격으로 진통이 나타난다.</li>
              <li>수축운동이 강해짐에 따라 뇨막융모막이 형성되고 자궁경관을 확장시키면서 시프톤운동을 하다가 외음부나 밖에서 파열한다.(제 1파수) </li>
              <li>개구진통기는 액 3-6시간정도 지속되며 제 1파수가 되면서 양막안의 태아를 자궁경과까지 이동시켜 태아를 신장시켜 분만이 용이토록한다. 
</li>
            </ul>
          </li>
          <li>만출기
            <ul>
              <li> 제1파수후 태아가 만출되는 기간으로 30분-3시간 가까이 소요된다. </li>
              <li>제 1파수후 반투명의 황백색 양막이 외부로 드러나는 시기는 20-30분 정도로 태아의 머리와 발굽이 멀리 나오게 된다. 이때 자궁수축은 격렬해져서 2-3분 간격에 80-100초 정도로 길어진다. </li>
              <li>진통이 빨라지면서 양막이 파열하는데 이를 제 2파수라고 한다. </li>
              <li>태아의 앞다리와 머리가 완전히 빠져나오면 잠시 휴식후 다시 어미소는 힘을 준다. </li>
              <li>태아의 만출이 완료되고 나면 탯줄은 5-8cm 길이로 잘라서 매달아 둔다. </li>
            </ul>
          </li>
          <li>후산기
            <ul>
              <li>송아지가 분만된후 태아태반이 외음부밖으로 길게 나오는데 이를 후산진통에 의해서 배출된다. </li>
              <li>대개 3-6시간 후에 배출되는데 12시간 후까지 배출되지 않을 경우 후산정체가 리스 이것의 부패로 인하여 여러 가지 질병을 일으킬 수 있다. </li>
              <li>후산정체는 예방하는 것은 임신중 운동을 충분히 시키고, 균형된 영양을 공급하는일이다. </li>
            </ul>
          </li>
        </ul>
      </li>
      <li>정상분만의 간호
        <ul>
          <li>분만환경이 청결해야 하고, 개구기가 6-12시간 이상 경과했거나 만출기가 2-3시간이상 결과시는 반드시 사람의 도움이 필요하다. </li>
          <li>태아가 산도 통과시 정상적이지 못할때도 사람이 도와주어야 하며 복부수축이 약할 경우에도 조산해야 한다. </li>
          <li>분만이 정상적인 경우 인위적인 파수는 금한다. </li>
          <li>조기파수로 인하여 인공양수가 필요할 경우 식욕유를 대체한다. </li>
          <li>조산이 필요한 경우 송아지 구절에 올가미나 밧줄을 씌우고 골반축과 같은 방향으로 한쪽다리씩 교대로 견인하여 조산한다. </li>
        </ul>
      </li>
      <li>난산 발견요령
        <ul>
          <li>개구진통이 약하게 시작된후 6시간이 경과해도 진통간격이 짧아지지 않고 진통이 강하지 않을 경우 </li>
          <li> 만출기가 3시간이 경과되어도 송아지의 만출이 순조롭지 않을 경우 </li>
          <li> 진통은 있으나 태포가 나타나지 않을 경우 </li>
          <li> 1 파수후 그태포가 나타나지 않을 경우 </li>
          <li> 제2태포가 나와도 앞발이 한 개만 보이거나 머리만 만져질 때 </li>
          <li> 4개의 발이 다 나올 경우 </li>
          <li> 송아지는 보이지 않고 피만 흐를 경우 
            <p><br/>&lt;난산과 송아지 폐사율과의 관계&gt;</p>
            <table className="tablestyle">
              <tr>
                <td rowSpan="2">분만방법</td>
                <td colSpan="2">분표비율(%)</td>
                <td colSpan="2">송아지 폐사율</td>
              </tr>
              <tr style={{background: '#273143', color:'#fff'}}>
                <td>초산</td>
                <td>경산</td>
                <td>초산</td>
                <td>경산</td>
              </tr>
              <tr>
                <td>자연분만</td>
                <td>45</td>
                <td>79</td>
                <td>8</td>
                <td>6</td>
              </tr>
              <tr>
                <td>조산(가볍게 당김)</td>
                <td>30</td>
                <td>15</td>
                <td>10</td>
                <td>8</td>
              </tr>
              <tr>
                <td>조산(세게 당김)</td>
                <td>14</td>
                <td>3</td>
                <td>35</td>
                <td>24</td>
              </tr>
              <tr>
                <td>난산(기구 사용)</td>
                <td>7</td>
                <td>1</td>
                <td>55</td>
                <td>66</td>
              </tr>
              <tr>
                <td>난산(수의사 도움)</td>
                <td>4</td>
                <td>1</td>
                <td>48</td>
                <td>65</td>
              </tr>
              <tr>
                <td>계</td>
                <td>100</td>
                <td>100</td>
                <td>17</td>
                <td>8</td>
              </tr>
            </table>
          </li>
        </ul>
      </li>
    </ul>
  </div>
  );
}

export default Do5;
