import React from 'react';

// 컴포넌트
import SubTitle from 'components/subTitle/SubTitle';

// 이미지
import superiorCow from 'assets/images/containers/business/superiorCow/superiorCow.png';
import superiorCowBack from 'assets/images/containers/business/superiorCow/superiorCow_back.png';
import superiorCowBack768 from 'assets/images/containers/business/superiorCow/superiorCow_back_768.png';

// scss
import './superiorCow.scss';

function SuperiorCow() {
  return (
    <div className="superiorCow subCon pullContent">
      <SubTitle />
      <dl>
        <dt>과학적인 사양관리로<span>세계 최고 수준의 씨수소를 만듭니다.</span></dt>
        <dd>세계적으로 인정받는 개량기관에서 우수 씨수소를 도입하고 우리 풍토에 맞는 세계 최고 수준의 한국형 보증씨수소도 만듭니다.</dd>
      </dl>
      <div className='infoImage'>
        <img src={superiorCow} alt='씨수소 사양관리 이미지' />
      </div>
      <ul className='superiorInfo'>
        <li>
          <dl>
            <dt><span>01</span> 씨수소 사양관리</dt>
            <dd>
              <ul>
                <li>최상의 컨디션을 유지하여 최고의 정액을 생산하도록 체계적이고 과학적인 방법으로 씨수소를 사양관리 합니다.</li>
                <li>연 2회의 종합검진을 통하여 국제적 기준인 질병위생관리 조건&#40;CSS&#41;을 준수하고 있습니다.</li>
              </ul>
            </dd>
          </dl>
        </li>
        <li>
          <dl>
            <dt><span>02</span> 씨수소 사양시험 연구</dt>
            <dd>
              <ul>
                <li>씨수소의 정액 생산성 향상을 위한 씨수소 사양 표준과 광물질 효과 규명을 위하여 씨수소 사양시험에도 매진하고 있습니다.</li>
              </ul>
            </dd>
          </dl>
        </li>
        <li>
          <dl>
            <dt><span>03</span> 조사료 생산</dt>
            <dd>
              <ul>
                <li>씨수소의 생산성 향상을 위해서 매년 38Ha의 초지에서 양질의 건초와 헤일리지를 직접 생산하여 급여하고 있습니다.</li>
                <li>속성된 구비를 초지에 환원함으로써 지력 증강 및 친환경적인 초지관리를 합니다.</li>
              </ul>
            </dd>
          </dl>
        </li>
      </ul>
      <img 
        className='backImage' 
        srcSet={`${superiorCowBack} 768w, ${superiorCowBack768} 769w`}
        sizes='(max-width: 768px) 100vw, 769px' 
        alt='씨수소 사양관리 배경 이미지'
      />
    </div>
  );
}

export default SuperiorCow;
