import React from 'react';
import PersonalInfo from 'containers/web/guide/personalInfo/PersonalInfo';
import { Helmet } from 'react-helmet-async';

function PersonalInfoPage() {
  return (
    <>
      <Helmet>
        <title>개인정보처리방침</title>
      </Helmet>
      <PersonalInfo />
    </>
  );
}

export default PersonalInfoPage;
