import React from 'react';

// 이미지
import oxBook from 'assets/images/containers/ox/oxData/ox2_book.png';
import oxCode from 'assets/images/containers/ox/oxData/ox2_code.png';

function Ox2() {
  return (
    <div className='ox2'>
        <strong>씨수소 안내책자 보는 요령</strong>
        <div className='oxBook'>
          <img src={oxBook} alt='씨수소 안내책자'/>
        </div>
        <ul>
          <li>
            <dl>
              <dt><span>1</span>씨수소 이름과 정액코드</dt>
              <dd>씨수소명과 농협 젖소 씨수소의 미국종축협회&#40; NAAB, National Association of Animal Breeders&#41; 인증번호로 씨수소의 번호. 208번은 한국 농협 젖소개량사업소를 뜻하며 HO는 홀스타인 젖소를 뜻한다&#40;미국 최대 정액생산기관인 Select Sire사는 7번, 캐나다 최대기관인 C.I.A.Q사는 73번 인증 번호를 사용&#41;. 씨수소번호는 정액스트로 표면에 인쇄되는 번호로 5자리되어 있으며 한국형 씨수소 중 첫 두자리가 “10”이면 한국산 후보우, “04”이면 외국산 후보우를 뜻한다. 정액스트로 표면 인쇄내용은 다음과 같다.</dd>
              <dd><img src={oxCode} alt='씨수소 코드설명'/></dd>
            </dl>
          </li>
          <li>
            <dl>
              <dt><span>2</span>개량형질</dt>
              <dd>씨수소의 유전능력 성적이 우수한 형질로 생산 될 딸소의 개량부위로 선택</dd>
            </dl>
          </li>
          <li>
            <dl>
              <dt><span>3</span>등록번호</dt>
              <dd>한국종축개량협회에서 부여한 우리나라 등록번호.<br/>괄호 내는 도입국&#40;“한”은 한국,“미”는 미국, “캐”는 캐나다, “이”는 이탈리아 등&#41;과 도입국에서 부여한 등록번호.</dd>
            </dl>
          </li>
          <li>
            <dl>
              <dt><span>4</span>종합지수</dt>
              <dd>각 국별 개량방향이나 농가수익 방식에 따라  차이가 있어 일률적인 비교는 할 수 없다. 이 능력으로 씨수소 순위 결정이 가능하다.</dd>
              <dd className='unique'><span>한국형 체형 능력종합지수 &#40;KTPI, Korea Type-Production Index&#41;</span>유지방 37, 유단백 27, 체형 15, 유방종합지수 10, 발굽과 다리 6, 체세포 5의 비율로 계산된다.</dd>
              <dd className='unique'><span>미국형 체형 능력종합지수&#40;TPI, Total Performance Index&#41; 또는 GTPI&#40;Genomic Total Performance Index&#41; 그리고 CTPI &#40;Conformation Total Performance Index&#41;</span>유지방 37, 유단백 27, 체형 15, 유방종합지수 10, 발굽과 다리 6, 체세포 5의 비율로 계산된다. </dd>
            </dl>
          </li>
          <li>
            <dl>
              <dt><span>5</span>혈통&#40;Pedigree&#41;</dt>
              <dd>혈통은 계획교배용 씨수소를 선정할 때 필수적인 고려사항으로 어미보다는 인공수정이 보편화된 아비소의 혈통을 고려하여야 한다. 통상적으로 3대&#40;조부&#41;혈통까지 교배용 씨수소와 농가암소가 중복되는 혈통이 없을 시 근친의 우려가 적다.</dd>
            </dl>
          </li>
          <li>
            <dl>
              <dt><span>6</span>생산유전능력</dt>
              <dd className='unique'><span>추정유전전달능력&#40;PTA, Predicted Transmitting Ability&#41;</span>씨수소가 자식에게 전달할 수 있는 유전적 개량도에 대한 추정치로 암소와 수소 모두 계산된다. 미국의 경우 2010년, 한국의 경우 2005년 태어난 암소 평균을 “0”로 하여 계산한다. 유량&#40;M&#41; 추정유전전달능력이 1,000kg인 씨수소 정액을 사용할 경우 500kg인 씨수소정액을 사용하는 것보다 딸소 유량이 연간 약 500kg정도 더 얻을 수 있다는 것을 뜻하며, 유량&#40;PTAM&#41;, 유지량&#40;PTAF&#41;, 유단백량&#40;PTAP&#41;, 체형&#40;PTAT&#41;등을 표시하는데 쓰이며, 한국은 kg, 미국은 lb&#40;파운드&#41;로 단위를 표시하나 여기서는 모든 단위를 kg으로 환산하여 표기한다.</dd>
              <dd className='unique'><span>추정육종가&#40;EBV, Estimated Breeding Value&#41;</span>캐나다 생산능력은 성년형 환산 kg 기준으로 추정 육종가로 표시되는데 추정유전전달능력으로의 환산은 생산량을 2로 나누면 된다. 캐나다는 매년 유전평가기준을 변경하는데 현재는 2009년부터 2011년 사이&#40;7년전&#41;에 태어난 암소를 포함한 평균능력을 “0”으로 하여 유량&#40;EBVM&#41;, 유지량&#40;EBVF&#41;, 유단백량&#40;EBVP&#41;을 계산하고 있다.</dd>
            </dl>
          </li>
          <li>
            <dl>
              <dt><span>7</span>신뢰도</dt>
              <dd>유전적 평가의 정확도를 나타내며 1 - 99% 범위를 나타낸다. 99%에 가까울수록 추정전달유전능력의 정확도가 높다.</dd>
            </dl>
          </li>
          <li>
            <dl>
              <dt><span>8</span>체형유전능력</dt>
              <dd>씨수소가 생산한 딸소들의 선형심사 부위별 유전능력을 나타낸 것으로 이들 부위는 젖소의 생산능력 및 장수성 등에 가장 중요한 형질들로 각 형질별 평균을 기준으로 하여 미국은 ±3, 캐나다는 ±15의 수치범위 내에 개량치를 나타내고 있다. 특히 관련되는 체형부위 중 우군경제수명이나 장수성 등에 밀접한 여러 가지 형질에 일정한 가중치를 부여하여 계산하는 체형종합지수&#40;Composite Index&#41;를 계산하기도 한다. 미국의 경우 축군 수명&#40;Herd life&#41;에 밀접한 관련이 있는 유방종합지수&#40;Udder Composite Index&#41;는 &#91;&#40;0.35*유방깊이&#41; + &#40;0.16*앞유방붙임&#41; + &#40;0.16*뒷유방높이&#41; + &#40;0.12*뒷유방너비&#41; + &#40;0.09*정중제인대&#41; + &#40;0.05*앞유두배열&#41; - &#40;0.07*뒷유두배열&#41;&#93;로 계산하며, 지제종합지수&#40;Feet and Leg Composite&#41;는 &#91;0.48*발굽각도 + 0.37*&#40;뒷다리 자세 - 뒤에서 본 뒷다리&#41; - 0.15*&#40;뒷다리 자세- 옆에서 본 뒷다리&#41;&#93; 등 다리관련 3가지 형질의 STA 값을 1/2로 나누고 지제점수를 1/2로 나눈 수치를 합산하여 계산한다. 특히 지제종합지수는 최근에 젖소 선발에 필수적인 5가지 형질 중에 하나로 이용되고 있다. 이외에도 체고, 체심, 엉덩이기울기, 엉덩이너비 등을 고려하여 계산하는 체구종합지수&#40;Body Form Composite Index&#41;, 유용체구, 강건성 등을 고려하여 계산하는 유용체적종합지수&#40;Dairy Capacity Composite Index&#41;등을 발표하고 있으며, 캐나다는 주요 체형형질로 유용자질, 체적, 엉덩이, 지제, 유방계, 전유방, 후유방 등에 대한 유전능력을 활용하고 있다.</dd>
            </dl>
          </li>
          <li>
            <dl>
              <dt><span>9</span>보조적인 특징</dt>
              <dd className='unique'><span>생산수명&#40;Productive Life&#41;</span>다른 씨수소 딸소들&#40;평균 3산&#41;에 비한 착유수명이 얼마나 더 긴지를 나타내는 것으로 7세까지의 실제 10개월 착유월수와 각 형질별 체형 선형심사성적 특히 유방관련성적을 고려하여 –7.0 ~ +7.0의 일반적인 범위&#40;±개월&#41;로 계산한다. 캐나다산 씨수소는 축군 수명&#40;Herd Life&#41;으로 나타내며 축군 내 4산&#40;6살 기준&#41;까지 머물 수 있느냐를 나타내며 평균 100&#40;4산차 생존율 31%&#41;을 기준으로 85&#40;20%이하&#41;~115&#40;50%이상&#41;의 범위에서 수치가 높을수록 4산차 생존율이 높아진다는 의미이다.</dd>
              <dd className='unique'><span>분만 난이도&#40;Calving Ease&#41;</span>해당 씨수소의 딸소가 첫 분만 시 난산할 확률을 나타낸 것으로 미국은 DCE&#40;Daughter Calving Ease&#41;로, 씨수소 정액을 사용한 암소가 첫 분만할 때 난산할 확률&#40;대략 8%&#41;을 SCE&#40;Sire Calving Ease&#41;로 나타내며 수치가 낮을수록 난산확률이 낮아진다. 캐나다는 씨수소의 딸소가 분만 시 순산 정도를 나타내는 DCA&#40;Daughter Calving Ability&#41;와 씨수소정액을 사용한 암소가 분만할 때 순산 정도를 나타내는 CA&#40;Calving Ability&#41;로 표시하며 평균 100을 기준으로 85~115의 범위에서 수치가 높을수록 순산할 확률이 높다는 의미이다.</dd>
              <dd className='unique'><span>수태율&#40;Fertility&#41;</span>수태율은 착유소의 출산-수정 간격을 21일 기간 동안 임신한 사실 여부을 알아보기 위해 암소의 인공수정 횟수 대비 임신한 암소의 비율이다. 젖소의 생식력의 차이로 번식에 대한 잠재성을 보기에, 미국산 딸소 임신율 DPR&#40;Daughter Pregnancy Rate&#41;은 수치&#40;기준 ±0%&#41;가 1% 증가&#40;감소&#41;할 때마다 발정재귀일수가 4일정도 감소&#40;증가&#41;하여 임신을 예측하고, 캐나다산 수태율 DF&#40;Daughter Fertility&#41;는 딸소 재귀발정률&#40;NRR&#41;의 평균 66%&#40;육성우 76%, 경산우 61%&#41;을 기준으로 지표 85~115&#40;NRR 55~75%&#41;강도를 통해서 수치가 높을수록 딸소의 임신능력에 현저히 개량시킬 수 있다.</dd>
              <dd className='unique'><span>체세포 점수&#40;Somatic Cell Score&#41;</span>유방염에 저항정도를 수치화한 것으로 미국산은 평균 3.2이며, 캐나다산은 3.0으로 평균점수보다 낮을수록 저항력이 강하나 유전력은 높지 않다.</dd>
              <dd className='unique'><span>비유 지속성&#40;Lactation persistency&#41;</span>60일 유량과 비교한 280일의 산유량에 대한 씨수소 유전능력을 기반으로 1산&#40;50%가중치&#41;, 2산&#40;25%&#41;, 그리고 3산차&#40;25%&#41;의 평균가치를 백분율의 관점에서 표현한 값이다. 한 산차에 280일 산유량에 대한 유전력 잠재성은 60일 유량의 67%정도이기에 지표100&#40;67%&#41;을 기준으로 둔다. 85&#40;58%&#41;~115&#40;76%&#41;의 범위에서 수치가 높을수록 280일 산유량의 유전력 잠재성이 높아진다는 의미이다.</dd>
              <dd className='unique'><span>유방염 저항성&#40;Mastitis Resistance&#41;</span>유방염은 임신과 출산의 반복으로 면역저항력이 떨어지면서 생기는 질병이다. 유방염 저항성은 임상형 유방염 1산, 다산차, 그리고 체세포수를 각 33%씩 가중치로 더하여 환산한 지표로서, 평균 100을 기준으로 유방염 저항성수치가 높을수록 세균이나 곰팡이가 젖소 유방 내에 침입하여 질병이 야기될 확률이 낮아진다.</dd>
              <dd className='unique'><span>착유속도&#40;Milking Speed&#41;</span>암소들의 착유 속도를 5단계&#40;매우느림, 느림, 평균, 빠름, 매우 빠름&#41;로 나누어서 ‘평균’ 또는 ‘빠름’이상 평가된 1산차 딸소 수를 백분율로 환산한 값으로 착유속도가 빠른 씨수소의 경우 수치가 높다.</dd>
              <dd className='unique'><span>A2 Milk 생산 씨수소&#40;A2A2&#41;</span>베타-카제인 단백질&#40;β-casein protein&#41;인 A2타입만을 유생산할 수 있는 능력을 딸소에게 전달한다. 단, 암소에도 A2타입일 경우에 한해서 A2타입의 딸소가 생산 가능하다.</dd>
            </dl>
          </li>
          <li>
            <dl>
              <dt><span>10</span>세부적인 체형특징&#40;STA, Standard Transmitting Ability&#41;</dt>
              <dd>세부적인 체형특징은 표준화 유전 전달능력&#40;STA, Standard Transmitting Ability&#41;의 값으로 한국이나 미국은 -3에서 +3의 범위로, 캐나다는 -15에서 +15값의 범위로 나타나고 있다. 표준화 유전전달능력은 씨수소 딸소들의 체형심사 성적을 상대적으로 비교하기 위해 표준화한 것으로 각각의 능력이 평균으로부터 떨어진 거리를 나타내게 된다. 수치의 범위이내가 대부분이며 그 이상 또는 이하는 거의 없으나 있다고 해도 극도로 치우친 체형을 의미하므로 바람직하지 못하며 STA 값은 형질에 따라 큰 수치가 좋기도 하나 유방깊이, 뒷다리 각도, 발굽 각도, 엉덩이 경사도, 유두길이 등은 중간정도의 수치가 이상적이다.</dd>
              <dd className='unique'><span>강건성</span> : 앞다리 사이의 가슴바닥의 너비를 평가하는 것으로 이 부위는 심장과 폐가 위치하는 곳으로 심폐장기를 담기 위한 충분한 공간을 수용할 수 있도록 넓은 것이 좋다.</dd>
              <dd className='unique'><span>체심</span> : 체심은 등선에서 하복부&#40;뒷갈비의 가장 깊은 부위&#41;까지의 수직적인 거리를 의미하는 것으로 반추위와 기타 소화관을 수용하기 위해 중요한 부위로 깊은 것이 좋다.</dd>
              <dd className='unique'><span>예각성</span> : 예각성은 우유생산과 매우 관련이 깊은 형질로 예각성이 좋은 소들은 갈비사이의 개장이 넓고 뒷 갈비의 방향은 뒤쪽으로 모양은 활처럼 휘어져 있으며, 뼈 자체는 둥글지 않고 편평하다. 따라서 예각성은 갈비뼈의 개장도, 뒷갈비의 방향, 갈비 활력, 갈비뼈의 편평성을 점수로 평가한다.</dd>
            </dl>
          </li>
          <li>
            <dl>
              <dt><span>11</span>⑪ 국제유전능력평가&#40;MACE, Multi-trait Across Country Evaluation&#41;</dt>
              <dd>젖소의 유전능력&#40;육종가&#41;은 평가집단 내 개체간의 상대적 유전적 가치를 나타낸다. 국가유전능력평가는 국제가축기록위원회&#40;ICAR&#41;산하기구인 인터불&#40;INTERBULL&#41;에서 27개 회원국의 젖소 유전가치에 대한 정기평가결과를 형질별로 분석하여, 순위 계산한 것이다. 인터불평가는 각 참여국에서 유전 평가된 결과를 바탕으로 국제유전능력평가를 실시함으로써 자국 내 환경에서 종축의 유전적 능력 추정이 가능하다. 따라서 참여국가 내에서 평가순위는 다르게 나타날 수 있다. 한국도 2011년부터 국제유전평가에 참여하고 있으며, 인터불 회원국이 보유한 모든 씨수소를 대상으로 한국낙농환경에서 유전적 능력을 평가하여 매년 3회에 걸쳐 발표하고 있다.</dd>
              <dd className='unique'><span>체형종합점수&#40;OCS ; Overall Conformation Score&#41; : 선형심사최종점수&#40;Final Score&#41;</span></dd>
              <dd className='unique'><span>유대수익지수</span>&#40;MFP&#41; : 유량, 유지량, 유단백량의 경제적 가중치를 고려한 지수</dd>
            </dl>
          </li>
        </ul>
      </div>
  );
}

export default Ox2;
