import React from 'react';

import pic634 from 'assets/images/sub/_images/pic631.jpg';

function Do123() {

  return (
    <div className="techinfoview">
      <h1>유방의 피부염</h1>
      <p className="pstyle">유방과 유두피부에 국한하여 발생하는 발진성(發疹性) 피부질환이다. </p>
      <div className="leftpart">
        <h2>원인</h2>
        <ul>
          <li>접촉에 의하여 전염되고 유방세척수건, 착유자의 손, 착유기로 전파된다. </li>
        </ul>
        <h2>증상</h2>
        <ul>
          <li>발진 자체는 4-5일 후 대개 낫지만 피부가 짓무르기 시작하면 착유를 기피하므로 즉시 치료해야 한다. </li>
        </ul>
        <h2>목장에서의 처치</h2>
        <ul>
          <li>착유종료후 2-3% 크레졸 소독액으로 깨끗이 세척하여 주면 1주일 후면 회복된다.</li>
        </ul>
      </div>
      <div className="rightpart">
        <img src={pic634} className="nonborder" alt=""/>
      </div>
    </div>
  );
}

export default Do123;