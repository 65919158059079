import React, { useState } from 'react';
import { Link } from 'react-router-dom';

function NavPcGnbItem({ itemkey, title, child }) {
  const [isHovered, setIsHovered] = useState(false);

  /**
   * 2024-12-27 추가
   * 검정성적조회 > 농가별 검정성적조회 클릭시 기존 홈페이지 url 로 이동요청
   * itemKey : 메뉴 이름
   * childKey : 메뉴 하위 이름
   * */
  const getItemLink = (itemKey, childKey) => {
    if (itemKey === 'Performance' && childKey === 'PerformanceCheck') {
      return 'http://www2.dcic.co.kr/performace_02.do';
    }
    return `/Web/${itemKey}/${childKey}`;
  };

  return (
    <li
      className={`gnbTwoDepth ${isHovered ? 'on' : ''}`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Link
        to={`/Web/${itemkey}/${
          //   Object.keys(child)[`${itemkey === 'Performance' ? 1 : 0}`]
          Object.keys(child)[0]
        }`}
        onClick={() => setIsHovered(false)}
      >
        {title}
      </Link>
      <div className="gnbThreeDepth">
        <div className="gnbThreeDepthInner">
          <span>{title}</span>
          <ul>
            {Object.entries(child).map(([childKey, value]) => {
              const link = getItemLink(itemkey, childKey);

              // 외부 링크인 경우
              if (link.startsWith('http')) {
                return (
                  <li key={childKey}>
                    <a
                      href={link}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={() => setIsHovered(false)}
                    >
                      {value}
                    </a>
                  </li>
                );
              }

              // 내부 링크인 경우
              return (
                <li key={childKey}>
                  <Link to={link} onClick={() => setIsHovered(false)}>
                    {value}
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </li>
  );
}

export default NavPcGnbItem;
