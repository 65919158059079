import React from 'react';

import d1by19 from 'assets/images/sub/_images/1-19.gif';
import d1by21 from 'assets/images/sub/_images/1-21.gif';
import d1by23by1 from 'assets/images/sub/_images/1-23-1.jpg';
import d1by23by2 from 'assets/images/sub/_images/1-23-2.jpg';

function Fo19() {

  return (
    <div className="techinfo_view">
				<p className="viewTitle">출생시의 관리</p>
				<h2>출생할 쯤의 자우(仔牛)에게 무엇을 해야 할까?</h2>
				<ul className="listStyle04 mg15f">
					<li>입, 목구멍의 점액을 제거하여 호흡을 하게 한다.</li>
					<li>체(몸)을 &#39011;고 신속하게 말린다.</li>
					<li>배꼽을 옥도정기(희요드라도 좋다.)로 침지소독(沈漬消毒)한다. </li>
					<li>초유를 신속하게 2ℓ정도 마시게 한다. 
						<ul>
							<li>a: 분만 개조의 일련의 작업중에서 태어나고 나서 15분이내에 </li>
							<li>b: 빨지 않는 자우에게는 강제포유기로 포유하게 한다. </li>
							<li>c: 초산우와 병우(病牛)의 자우에는 냉동초유를 활용한다. </li>
							<li>d: 사람의 손으로 확실하게 마시게 한다. </li>
						</ul>
					</li>
				</ul>
				<div className="imgBox"><img src={d1by19} alt="탯줄의 처리(상). 배꼽에 옥도정기 침지 소독(하)" /></div>
				
				<table className="tablestyle mg60f">
					<caption>자우의 포유능력조사(자연포유) </caption>
					<thead>
						<tr>
							<th className="first" rowSpan="2"> </th>
							<th rowSpan="2">2시간이내에 기립</th>
							<th colSpan="3">자력으로 초유를 빨아들임</th>
						</tr>
						<tr>
							<th>2시간 이내</th>
							<th>3~10시간</th>
							<th>10시간 이상</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>두수</td>
							<td>10</td>
							<td>4</td>
							<td>3</td>
							<td>3</td>
						</tr>
					</tbody>
				</table>
				
				<h2>출생할 쯤의 자우를 어디에 둘까?</h2>
				<ul className="listStyle04 mg15f">
					<li>신선한 공기가 들어오는 곳. Calf Hutch(송아지 상자)는 최적</li>
					<li>부료(敷料: 깔짚)를 충분히 넣은 곳</li>
					<li>배기구(排氣口)의 옆과 통로는 좋지 않음</li>
					<li>환기(換氣)는 자주 하지만 외풍(外風)은 좋지 않음</li>
					<li>결점을 부지런히 처리하고, 자우의 배를 차게 하지 않도록 한다.</li>
					<li>자가산(自家産)의 자우는 연속한 Calf Hutch라도 좋다.</li>
					<li>도입자우등 도입해서 멀지 않은 부모로부터 태어난 자우는 다른자우와 직접 접촉하지 않도록 CalfHutch(송아지 상자)을 떼어 놓는다.</li>
					<li>Calf Hutch는 건조해 있는 곳. 또는 건조하기 쉬운 곳에 둔다.</li>
				</ul>
				<div className="imgBox mg60f"><img src={d1by21} alt="비닐하우수와 Hutch를 짜맞추어 비와 눈이 올때의 작업이 편리." /></div>
				
				<h2>자우운반차(Calf cart)로 이동을 용이하게!</h2>
				<ul className="listStyle04 mg15f">
					<li>운반, 보호, 안정등 다목적으로 사용하는 자우 운반차(Calf cart)를 1대 준비하여 두면 편리</li>
				</ul>
				<dl>
					<dt>자우 운반차(Calf cart)의 크기</dt>
					<dd>
						<div className="imgBox"><img src={d1by23by1} alt="" /> <img src={d1by23by2} className="mg20l" alt="" /> </div>
						<ul>
							<li>높이: 91cm</li>
							<li>횡폭: 38cm</li>
							<li>길이: 89cm</li>
						</ul>
					</dd>
				</dl>
			</div>
  );
}

export default Fo19;
