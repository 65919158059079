import React from 'react';

// scss
import './oxPrint.scss';

function OxPrintChart({
  data
}) {

// console.log(data);

  let maxValue = 0;
  
  for (let i = 0; i < 21; i+=1) {
    const key = `s_sta${i}`;
    const value = Number(data[key]);
    if (Math.abs(value) > maxValue) {
      maxValue = Math.abs(value);
    }
  }

  const percentageValues = {};
  Object.keys(data).forEach((key) => {
    const absoluteValue = Math.abs(Number(data[key]));
    const percentage = Math.floor((absoluteValue / maxValue) * 50);
    percentageValues[key] = percentage;
  });

  return (
    <li className='oxChart'>
            <h3>세부적인 체형 특징</h3>
            <ul>

              <li className='oxChartHead'>
                <div className='oxChartLeft'>
                  <span>체형형질</span>
                  <span>정도</span>
                </div>
                <div className='oxBarChart'>
                  <span className='oxBar'></span>
                </div>
                <div className='oxChartRight'>
                  <span>정도</span>
                  <span>STA</span>
                </div>
              </li>

              <li>

                <div className='oxChartLeft'>
                  <span>정중제인대</span>
                  <span>약함</span>
                </div>
                <div className='oxBarChart'>
                  <span 
                    className='oxBar'
                    style={{
                      width: `${Number(percentageValues.s_sta1)}%`,
                      [Number(data.s_sta1) < 0 ? 'right' : 'left']: '50%',
                      backgroundColor: Number(data.s_sta1) < 0 ? '#34495E' : '#4E995A'
                    }}
                  ></span>
                </div>
                <div className='oxChartRight'>
                  <span>강함</span>
                  <span>{data.s_sta1}</span>
                </div>
              </li>

              <li>
                <div className='oxChartLeft'>
                  <span>뒷유방부착높이</span>
                  <span>낮음</span>
                </div>
                <div className='oxBarChart'>
                  <span 
                    className='oxBar'
                    style={{
                      width: `${percentageValues.s_sta2}%`,
                      [data.s_sta2 < 0 ? 'right' : 'left']: '50%',
                      backgroundColor: data.s_sta2 < 0 ? '#34495E' : '#4E995A'
                    }}  
                  ></span>
                </div>
                <div className='oxChartRight'>
                  <span>높음</span>
                  <span>{data.s_sta2}</span>
                </div>
              </li>

              <li>
                <div className='oxChartLeft'>
                  <span>뒷유방너비</span>
                  <span>좁음</span>
                </div>
                <div className='oxBarChart'>
                  <span 
                    className='oxBar'
                    style={{
                      width: `${percentageValues.s_sta3}%`,
                      [data.s_sta3 < 0 ? 'right' : 'left']: '50%',
                      backgroundColor: data.s_sta3 < 0 ? '#34495E' : '#4E995A'
                    }}  
                  ></span>
                </div>
                <div className='oxChartRight'>
                  <span>넓음</span>
                  <span>{data.s_sta3}</span>
                </div>
              </li>

              <li>
                <div className='oxChartLeft'>
                  <span>유방깊이</span>
                  <span>처진유방</span>
                </div>
                <div className='oxBarChart'>
                  <span 
                    className='oxBar'
                    style={{
                      width: `${percentageValues.s_sta4}%`,
                      [data.s_sta4 < 0 ? 'right' : 'left']: '50%',
                      backgroundColor: data.s_sta4 < 0 ? '#34495E' : '#4E995A'
                    }}  
                  ></span>
                </div>
                <div className='oxChartRight'>
                  <span>높이붙음</span>
                  <span>{data.s_sta4}</span>
                </div>
              </li>

              <li>
                <div className='oxChartLeft'>
                  <span>앞유방부착</span>
                  <span>약함</span>
                </div>
                 <div className='oxBarChart'>
                  <span 
                    className='oxBar'
                    style={{
                      width: `${percentageValues.s_sta5}%`,
                      [data.s_sta5 < 0 ? 'right' : 'left']: '50%',
                      backgroundColor: data.s_sta5 < 0 ? '#34495E' : '#4E995A'
                    }}  
                  ></span>
                </div>
                <div className='oxChartRight'>
                  <span>강함</span>
                  <span>{data.s_sta5}</span>
                </div>
              </li>

              <li>
                <div className='oxChartLeft'>
                  <span>유두길이</span>
                  <span>짧음</span>
                </div>
                <div className='oxBarChart'>
                  <span 
                    className='oxBar'
                    style={{
                      width: `${percentageValues.s_sta6}%`,
                      [data.s_sta6 < 0 ? 'right' : 'left']: '50%',
                      backgroundColor: data.s_sta6 < 0 ? '#34495E' : '#4E995A'
                    }}  
                  ></span>
                </div>
                <div className='oxChartRight'>
                  <span>길음</span>
                  <span>{data.s_sta6}</span>
                </div>
              </li>

              <li>
                <div className='oxChartLeft'>
                  <span>뒷다리각도</span>
                  <span>곧음</span>
                </div>
                <div className='oxBarChart'>
                  <span 
                    className='oxBar'
                    style={{
                      width: `${percentageValues.s_sta7}%`,
                      [data.s_sta7 < 0 ? 'right' : 'left']: '50%',
                      backgroundColor: data.s_sta7 < 0 ? '#34495E' : '#4E995A'
                    }}  
                  ></span>
                </div>
                <div className='oxChartRight'>
                  <span>굽음</span>
                  <span>{data.s_sta7}</span>
                </div>
              </li>

              <li>
                <div className='oxChartLeft'>
                  <span>발굽경사</span>
                  <span>누움</span>
                </div> 
                <div className='oxBarChart'>
                  <span 
                    className='oxBar'
                    style={{
                      width: `${percentageValues.s_sta8}%`,
                      [data.s_sta8 < 0 ? 'right' : 'left']: '50%',
                      backgroundColor: data.s_sta8 < 0 ? '#34495E' : '#4E995A'
                    }}  
                  ></span>
                </div>
                <div className='oxChartRight'>
                  <span>곧음</span>
                  <span>{data.s_sta8}</span>
                </div>
              </li>

              <li>
                <div className='oxChartLeft'>
                  <span>엉덩이각도</span>
                  <span>좌골높음</span>
                </div>
                <div className='oxBarChart'>
                  <span 
                    className='oxBar'
                    style={{
                      width: `${percentageValues.s_sta9}%`,
                      [data.s_sta9 < 0 ? 'right' : 'left']: '50%',
                      backgroundColor: data.s_sta9 < 0 ? '#34495E' : '#4E995A'
                    }}  
                  ></span>
                </div>
                <div className='oxChartRight'>
                  <span>좌골낮음</span>
                  <span>{data.s_sta9}</span>
                </div>
              </li>

              <li>
                <div className='oxChartLeft'>
                  <span>엉덩이너비</span>
                  <span>좁음</span>
                </div>
                <div className='oxBarChart'>
                  <span 
                    className='oxBar'
                    style={{
                      width: `${percentageValues.s_sta10}%`,
                      [data.s_sta10 < 0 ? 'right' : 'left']: '50%',
                      backgroundColor: data.s_sta10 < 0 ? '#34495E' : '#4E995A'
                    }}  
                  ></span>
                </div>
                <div className='oxChartRight'>
                  <span>넓음</span>
                  <span>{data.s_sta10}</span>
                </div>
              </li>

              <li>
                <div className='oxChartLeft'>
                  <span>체심</span>
                  <span>얕음</span>
                </div>
                <div className='oxBarChart'>
                  <span 
                    className='oxBar'
                    style={{
                      width: `${percentageValues.s_sta11}%`,
                      [data.s_sta11 < 0 ? 'right' : 'left']: '50%',
                      backgroundColor: data.s_sta11 < 0 ? '#34495E' : '#4E995A'
                    }}  
                  ></span>
                </div>
                <div className='oxChartRight'>
                  <span>깊음</span>
                  <span>{data.s_sta11}</span>
                </div>
              </li>

              <li>
                <div className='oxChartLeft'>
                  <span>강건성</span>
                  <span>약함</span>
                </div>
                <div className='oxBarChart'>
                  <span 
                    className='oxBar'
                    style={{
                      width: `${percentageValues.s_sta12}%`,
                      [data.s_sta12 < 0 ? 'right' : 'left']: '50%',
                      backgroundColor: data.s_sta12 < 0 ? '#34495E' : '#4E995A'
                    }}  
                  ></span>
                </div>
                <div className='oxChartRight'>
                  <span>강함</span>
                  <span>{data.s_sta12}</span>
                </div>
              </li>

              <li>
                <div className='oxChartLeft'>
                  <span>키</span>
                  <span>작음</span>
                </div>
                 <div className='oxBarChart'>
                  <span 
                    className='oxBar'
                    style={{
                      width: `${percentageValues.s_sta13}%`,
                      [data.s_sta13 < 0 ? 'right' : 'left']: '50%',
                      backgroundColor: data.s_sta13 < 0 ? '#34495E' : '#4E995A'
                    }}  
                  ></span>
                </div>
                <div className='oxChartRight'>
                  <span>큼</span>
                  <span>{data.s_sta13}</span>
                </div>
              </li>
            </ul>
    </li>
  );
}

export default OxPrintChart;
