import React from 'react';

import pic748 from 'assets/images/sub/_images/pic748.jpg';
import pic749 from 'assets/images/sub/_images/pic749.jpg';
import pic750 from 'assets/images/sub/_images/pic750.jpg';

function Do159() {

  return (
    <div className="techinfoview">
      <h1>탄저병</h1>
      <div className="leftpart">
      <p className="pstyle">탄저병은 탄저균에 의하여 발생하며, 우리나라에서는 제 1종 법정전염병으로서 지정되어 있는 인수공통점염병이다. 탄저균은 공기중에서는 아포(芽胞)를 형성하여 존재하며 온도와 약제에 저항력이 강해 토양속에서 20년이상 생존하며 감염력을 갖는다.</p>
        <h2>증상</h2>
        <ul>
          <li>감염후 발병될 때까지는 5일 정도 걸리며, 갑자기 높은 발열이 시작되어 24시간 만에 사망한다. </li>
          <li>사망시에는 대개 항문, 입, 코 등에서 출혈이 있고 해부하여 보면 피하에 제라틴 모양의 삼출액을 볼 수 있는 부종이 있다. </li>
          <li>혈액은 암적색을 띠며 응고가 잘 안되고. 죽은 소는 뻣뻣해지지 않는다. </li>
          <li>사람에게 감염되면 위그림과 같이 피부에 탄저양(瘍)이 생긴다.</li>
        </ul>
        <h2>목장에서의 처치</h2>
        <ul>
          <li>탄저병은 생전에 진단하기 어려우므로 일단 사망시에 천연공(눈, 코, 입, 귀, 항문 등)으로부터 출혈이 있고 혈액의 응고가 되지 않으면 지체 없이 가축병원이나 가축위생시험고등에 신고해야 한다. </li>
          <li>병이 발생하면 축사와 토양등의 환경오염의 방지에 힘써야 하며 사망우와 접촉한 기구와 볏짚등은 그 자리에서 소각 처리해야 한다.</li>
        </ul>
      </div>
      <div className="rightpart">
        <img src={pic748} className="nonborder" alt=""/>
        <img src={pic749} className="nonborder" alt=""/>
        <img src={pic750} className="nonborder" alt=""/>
      </div>
    </div>
  );
}

export default Do159;