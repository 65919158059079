import React from 'react';

// 컴포넌트
import DciExpert from 'containers/dcitest/dciExpert/DciExpert';

function DciExpertPage() {
  return (
    <DciExpert />   
  );
}

export default DciExpertPage;
