import React, { useState, Fragment, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { postState } from 'utils/api';

// 컴포넌트
import ErrorMessage from 'components/errorMessage/errorMessage';

// scss
import './milkPlanMating.scss';

function MilkPlanMating() {
  // 에러처리
  const [isModalOpen, setIsModalOpen] = useState(false); // 모달 추가
  const [errorMessage, setErrorMessage] = useState('');

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => {
    setIsModalOpen(false);
    setErrorMessage(''); // 모달이 닫힐 때 에러메세지 초기화
  };

  // 데이터 불러오기
  // eslint-disable-next-line no-restricted-globals
  const location = useLocation();
  const baseDir = `${location.pathname.toLowerCase()}/`;
  const [girlCowMatchData, setGirlCowMatchData] = useState(null); // 원래 암소정보
  const [originSemenData, setOriginSemenData] = useState(null); // 원래 정액정보
  const [selectSemenData, setSelectSemenData] = useState(null); // 정액합친정보

  const [props, setProps] = useState({
    cow: localStorage.getItem('selectGirlCow'),
    ox: localStorage.getItem('selectSemen'),
  }); // 암소 props

  useEffect(() => {
    // 암소나 정액이 선택되지 않은 경우 API 호출하지 않음
    if (!props.cow || !props.ox) {
      setErrorMessage('정액혹은 암소가 선정되지 않았습니다.');
      openModal();
      return;
    }

    // console.log(props);
    postState(baseDir, props)
      .then((getdata) => {
        if (getdata !== undefined) {
          // console.log(getdata);
          const { length } = getdata.plot_data2;
          setSelectSemenData(getdata.plot_data2); // 암소원래정보저장
          setGirlCowMatchData(
            // 암소정보저장
            getdata.plot_data2.map((cow, index) => ({
              ...cow,
              semenList: getdata.plot_data.slice(
                index * length,
                index * length + length,
              ), // 암소정보에 정액데이터 추가가
            })),
          );
          setOriginSemenData(getdata.plot_data3); // 원래 정액정보저장

          localStorage.removeItem('selectGirlCow');
          localStorage.removeItem('selectSemen');
        }
      })
      .catch((error) => {
        setErrorMessage('정액혹은 암소가 선정되지 않았습니다.');
        openModal();
      });
  }, [props]);

  // 암소평균
  const [filterGirlCow, setFilterGirlCow] = useState([]);
  const [girlCowavg, setGirlcowavg] = useState(null);

  // 데이터를 새로 불러올때마다 filterGirlCow에 저장
  useEffect(() => {
    setFilterGirlCow(girlCowMatchData);
  }, [girlCowMatchData]);

  // filterGirlCow가 변경될 때마다 실행 ( 조회를 누를때마다 )
  useEffect(() => {
    if (!girlCowMatchData || filterGirlCow.length === 0 || !filterGirlCow)
      return;

    setGirlcowavg({
      mc: (
        filterGirlCow.reduce((acc, cur) => acc + cur.mc, 0) /
        filterGirlCow.length
      ).toFixed(2),
      fc: (
        filterGirlCow.reduce((acc, cur) => acc + cur.fc, 0) /
        filterGirlCow.length
      ).toFixed(2),
      pc: (
        filterGirlCow.reduce((acc, cur) => acc + cur.pc, 0) /
        filterGirlCow.length
      ).toFixed(2),
      sc: (
        filterGirlCow.reduce((acc, cur) => acc + cur.sc, 0) /
        filterGirlCow.length
      ).toFixed(2),
      cc: (
        filterGirlCow.reduce((acc, cur) => acc + cur.cc, 0) /
        filterGirlCow.length
      ).toFixed(2),
      udc: (
        filterGirlCow.reduce((acc, cur) => acc + cur.udc, 0) /
        filterGirlCow.length
      ).toFixed(2),
      flc: (
        filterGirlCow.reduce((acc, cur) => acc + cur.flc, 0) /
        filterGirlCow.length
      ).toFixed(2),
      ktpi: (
        filterGirlCow.reduce((acc, cur) => acc + cur.ktpi, 0) /
        filterGirlCow.length
      ).toFixed(2),
      inb: (
        filterGirlCow.reduce((acc, cur) => acc + cur.inb, 0) /
        filterGirlCow.length
      ).toFixed(2),
    });
  }, [filterGirlCow]);

  // 정액 평균
  const [filterSelectSemen, setFilterSelectSemen] = useState([]);
  const [selectSemenAvg, setSelectSemenAvg] = useState(null);

  // 데이터를 새로 불러올때마다 filterGirlCow에 저장
  useEffect(() => {
    if (girlCowMatchData) {
      setFilterSelectSemen(girlCowMatchData.map((cow) => cow.semenList).flat());
    }
  }, [girlCowMatchData]);

  // filterSelectSemen가 변경될 때마다 실행 ( 조회를 누를때마다 )
  useEffect(() => {
    if (
      !selectSemenData ||
      filterSelectSemen.length === 0 ||
      !filterSelectSemen
    )
      return;

    setSelectSemenAvg({
      mc: (
        filterSelectSemen.reduce((acc, cur) => acc + cur.mc, 0) /
        filterSelectSemen.length
      ).toFixed(2),
      fc: (
        filterSelectSemen.reduce((acc, cur) => acc + cur.fc, 0) /
        filterSelectSemen.length
      ).toFixed(2),
      pc: (
        filterSelectSemen.reduce((acc, cur) => acc + cur.pc, 0) /
        filterSelectSemen.length
      ).toFixed(2),
      sc: (
        filterSelectSemen.reduce((acc, cur) => acc + cur.sc, 0) /
        filterSelectSemen.length
      ).toFixed(2),
      cc: (
        filterSelectSemen.reduce((acc, cur) => acc + cur.cc, 0) /
        filterSelectSemen.length
      ).toFixed(2),
      udc: (
        filterSelectSemen.reduce((acc, cur) => acc + cur.udc, 0) /
        filterSelectSemen.length
      ).toFixed(2),
      flc: (
        filterSelectSemen.reduce((acc, cur) => acc + cur.flc, 0) /
        filterSelectSemen.length
      ).toFixed(2),
      ktpi: (
        filterSelectSemen.reduce((acc, cur) => acc + cur.ktpi, 0) /
        filterSelectSemen.length
      ).toFixed(2),
      inb: (
        filterSelectSemen.reduce((acc, cur) => acc + cur.inb, 0) /
        filterSelectSemen.length
      ).toFixed(2),
    });
  }, [filterSelectSemen]);

  // 정액 조회 개별선택
  const [checkSemen, setCheckSemen] = useState({});

  function validateCheckSemen(regno, semencode) {
    if (checkSemen[regno] === undefined) {
      setCheckSemen({
        ...checkSemen,
        [regno]: semencode,
      });
      return;
    }

    if (checkSemen[regno] && checkSemen[regno] !== semencode) {
      alert('암소 한마리당 하나의 선택정액만 가능합니다.');
      return;
    }

    if (checkSemen[regno] === semencode) {
      setCheckSemen((prev) => {
        const newCheckSemen = { ...prev };
        delete newCheckSemen[regno];
        return newCheckSemen;
      });
    }
  }

  function semeninquiry() {
    if (Object.keys(checkSemen).length === 0) {
      alert('선택된 정액이 없습니다.');
      return;
    }

    const girlCowList = Object.keys(checkSemen);
    const selectSemenList = Object.values(checkSemen);

    setFilterGirlCow(
      girlCowMatchData.filter((data) =>
        girlCowList.includes(String(data.regno)),
      ),
    );
    setFilterSelectSemen(
      girlCowList.map((regno, index) => {
        const cowData = girlCowMatchData.find(
          (data) => data.regno === Number(regno),
        );
        return cowData.semenList.find(
          (data) => data.scode === selectSemenList[index],
        );
      }),
    );
  }

  return (
    <div className="milkPlanMating">
      <ErrorMessage
        isOpen={isModalOpen}
        content={errorMessage}
        closeModal={closeModal}
      />
      <h2>계획교배</h2>
      <ul className="milkPlanBtn">
        <li>
          <button type="button">인쇄</button>
        </li>
        <li>
          <button type="button" onClick={semeninquiry}>
            조회
          </button>
        </li>
        <li>
          <button
            type="button"
            onClick={() => {
              setFilterGirlCow(girlCowMatchData);
              setFilterSelectSemen(
                girlCowMatchData.map((cow) => cow.semenList).flat(),
              );
              setCheckSemen({});
            }}
          >
            전체조회
          </button>
        </li>
      </ul>
      <div className="planMatingTable">
        <table className="mating">
          <thead>
            <tr>
              <th>관리번호</th>
              <th rowSpan="2">정액</th>
              <th colSpan="4">유성분&#40;EBV&#41;</th>
              <th colSpan="3">체형&#40;EBV&#41;</th>
              <th rowSpan="2">KTPI&#40;EBV&#41;</th>
              <th rowSpan="2">근친계수</th>
            </tr>
            <tr>
              <th>바코드</th>
              <th>유량&#40;kg&#41;</th>
              <th>유지방&#40;%&#41;</th>
              <th>유단백&#40;%&#41;</th>
              <th>체세포&#40;천/Cell&#41;</th>
              <th>종합점수</th>
              <th>유방</th>
              <th>지제</th>
            </tr>
          </thead>
          <tbody>
            {girlCowMatchData &&
              girlCowMatchData.map((data, index) => (
                <Fragment key={index}>
                  <tr>
                    <th rowSpan={data.semenList.length}>{data.sname}</th>
                    {data.semenList.length > 0 && (
                      <>
                        <td className="matingCheck">
                          <input
                            type="checkbox"
                            onChange={() =>
                              validateCheckSemen(
                                data.regno,
                                data.semenList[0].scode,
                              )
                            }
                            checked={
                              checkSemen[data.regno] === data.semenList[0].scode
                            }
                          />
                          {data.semenList[0].scode}
                        </td>
                        <td className="numberCon">
                          {data.semenList[0].mc.toFixed(2)}
                        </td>
                        <td className="numberCon">
                          {data.semenList[0].fc.toFixed(2)}
                        </td>
                        <td className="numberCon">
                          {data.semenList[0].pc.toFixed(2)}
                        </td>
                        <td className="numberCon">
                          {data.semenList[0].sc.toFixed(2)}
                        </td>
                        <td className="numberCon">
                          {data.semenList[0].cc.toFixed(2)}
                        </td>
                        <td className="numberCon">
                          {data.semenList[0].udc.toFixed(2)}
                        </td>
                        <td className="numberCon">
                          {data.semenList[0].flc.toFixed(2)}
                        </td>
                        <td className="numberCon">
                          {data.semenList[0].ktpi.toFixed(2)}
                        </td>
                        <td className="numberCon">
                          {data.semenList[0].inb.toFixed(2)}
                        </td>
                      </>
                    )}
                  </tr>
                  {data.semenList &&
                    data.semenList.slice(1).map((semenData, semenIndex) => (
                      <tr key={semenIndex}>
                        <td className="matingCheck">
                          <input
                            type="checkbox"
                            onChange={() =>
                              validateCheckSemen(data.regno, semenData.scode)
                            }
                            checked={checkSemen[data.regno] === semenData.scode}
                          />
                          {semenData.scode}
                        </td>
                        <td className="numberCon">{semenData.mc.toFixed(2)}</td>
                        <td className="numberCon">{semenData.fc.toFixed(2)}</td>
                        <td className="numberCon">{semenData.pc.toFixed(2)}</td>
                        <td className="numberCon">{semenData.sc.toFixed(2)}</td>
                        <td className="numberCon">{semenData.cc.toFixed(2)}</td>
                        <td className="numberCon">
                          {semenData.udc.toFixed(2)}
                        </td>
                        <td className="numberCon">
                          {semenData.flc.toFixed(2)}
                        </td>
                        <td className="numberCon">
                          {semenData.ktpi.toFixed(2)}
                        </td>
                        <td className="numberCon">
                          {semenData.inb.toFixed(2)}
                        </td>
                      </tr>
                    ))}
                </Fragment>
              ))}
          </tbody>
        </table>
        <table className="result">
          <thead>
            <tr>
              <th>암소정보</th>
              <th>유량 &#40;kg&#41;</th>
              <th>유지방 &#40;%&#41;</th>
              <th>유단백 &#40;%&#41;</th>
              <th>체세포 &#40;천/Cell&#41;</th>
              <th>종합점수</th>
              <th>유방</th>
              <th>지제</th>
              <th>KTPI</th>
              <th>근친계수</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>
                암소평균&#40;{filterGirlCow && filterGirlCow.length}두&#41;
              </th>
              <td className="numberCon">{girlCowavg && girlCowavg.mc}</td>
              <td className="numberCon">{girlCowavg && girlCowavg.fc}</td>
              <td className="numberCon">{girlCowavg && girlCowavg.pc}</td>
              <td className="numberCon">{girlCowavg && girlCowavg.sc}</td>
              <td className="numberCon">{girlCowavg && girlCowavg.cc}</td>
              <td className="numberCon">{girlCowavg && girlCowavg.udc}</td>
              <td className="numberCon">{girlCowavg && girlCowavg.flc}</td>
              <td className="numberCon">{girlCowavg && girlCowavg.ktpi}</td>
              <td className="numberCon">{girlCowavg && girlCowavg.inb}</td>
            </tr>
            <tr>
              <th>
                정액평균&#40;{filterSelectSemen && filterSelectSemen.length}
                개&#41;
              </th>
              <td className="numberCon">
                {selectSemenAvg && selectSemenAvg.mc}
              </td>
              <td className="numberCon">
                {selectSemenAvg && selectSemenAvg.fc}
              </td>
              <td className="numberCon">
                {selectSemenAvg && selectSemenAvg.pc}
              </td>
              <td className="numberCon">
                {selectSemenAvg && selectSemenAvg.sc}
              </td>
              <td className="numberCon">
                {selectSemenAvg && selectSemenAvg.cc}
              </td>
              <td className="numberCon">
                {selectSemenAvg && selectSemenAvg.udc}
              </td>
              <td className="numberCon">
                {selectSemenAvg && selectSemenAvg.flc}
              </td>
              <td className="numberCon">
                {selectSemenAvg && selectSemenAvg.ktpi}
              </td>
              <td className="numberCon">
                {selectSemenAvg && selectSemenAvg.inb}
              </td>
            </tr>
            <tr className="dif">
              <th>차이</th>
              <td className="numberCon">
                {selectSemenAvg &&
                  (selectSemenAvg.mc - girlCowavg.mc).toFixed(2)}
              </td>
              <td className="numberCon">
                {selectSemenAvg &&
                  (selectSemenAvg.fc - girlCowavg.fc).toFixed(2)}
              </td>
              <td className="numberCon">
                {selectSemenAvg &&
                  (selectSemenAvg.pc - girlCowavg.pc).toFixed(2)}
              </td>
              <td className="numberCon">
                {selectSemenAvg &&
                  (selectSemenAvg.sc - girlCowavg.sc).toFixed(2)}
              </td>
              <td className="numberCon">
                {selectSemenAvg &&
                  (selectSemenAvg.cc - girlCowavg.cc).toFixed(2)}
              </td>
              <td className="numberCon">
                {selectSemenAvg &&
                  (selectSemenAvg.udc - girlCowavg.udc).toFixed(2)}
              </td>
              <td className="numberCon">
                {selectSemenAvg &&
                  (selectSemenAvg.flc - girlCowavg.flc).toFixed(2)}
              </td>
              <td className="numberCon">
                {selectSemenAvg &&
                  (selectSemenAvg.ktpi - girlCowavg.ktpi).toFixed(2)}
              </td>
              <td className="numberCon">
                {selectSemenAvg &&
                  (selectSemenAvg.inb - girlCowavg.inb).toFixed(2)}
              </td>
            </tr>
            {originSemenData &&
              originSemenData.map((data, index) => (
                <Fragment key={index}>
                  <tr>
                    <th>{data.scode}</th>
                    <td className="numberCon">{Number(data.mc).toFixed(2)}</td>
                    <td className="numberCon">{Number(data.fc).toFixed(2)}</td>
                    <td className="numberCon">{Number(data.pc).toFixed(2)}</td>
                    <td className="numberCon">{Number(data.sc).toFixed(2)}</td>
                    <td className="numberCon">{Number(data.cc).toFixed(2)}</td>
                    <td className="numberCon">{Number(data.udc).toFixed(2)}</td>
                    <td className="numberCon">{Number(data.flc).toFixed(2)}</td>
                    <td className="numberCon">
                      {Number(data.ktpi).toFixed(2)}
                    </td>
                  </tr>
                  <tr className="dif">
                    <th>차이</th>
                    <td className="numberCon">
                      {selectSemenAvg && (data.mc - girlCowavg.mc).toFixed(2)}
                    </td>
                    <td className="numberCon">
                      {selectSemenAvg && (data.fc - girlCowavg.fc).toFixed(2)}
                    </td>
                    <td className="numberCon">
                      {selectSemenAvg && (data.pc - girlCowavg.pc).toFixed(2)}
                    </td>
                    <td className="numberCon">
                      {selectSemenAvg && (data.sc - girlCowavg.sc).toFixed(2)}
                    </td>
                    <td className="numberCon">
                      {selectSemenAvg && (data.cc - girlCowavg.cc).toFixed(2)}
                    </td>
                    <td className="numberCon">
                      {selectSemenAvg && (data.udc - girlCowavg.udc).toFixed(2)}
                    </td>
                    <td className="numberCon">
                      {selectSemenAvg && (data.flc - girlCowavg.flc).toFixed(2)}
                    </td>
                    <td className="numberCon">
                      {selectSemenAvg &&
                        (data.ktpi - girlCowavg.ktpi).toFixed(2)}
                    </td>
                    <td className="numberCon">
                      {selectSemenAvg && (data.inb - girlCowavg.inb).toFixed(2)}
                    </td>
                  </tr>
                </Fragment>
              ))}
          </tbody>
        </table>
      </div>
      <ul>
        <li>
          <span>
            - 암소평균 : 선택된 암소중 정액을 선택해서 조회한 경우 암소들의
            평균입니다.
          </span>
        </li>
        <li>
          <span>
            - 정액평균 : 체크박스로 선택된 정액의 평균입니다. 전체조회시
            전체정액의 평균입니다.
          </span>
        </li>
        <li>
          <span>- 차이 : 암소평균과 비교한 정액의 차이입니다.</span>
        </li>
      </ul>
    </div>
  );
}

export default MilkPlanMating;
