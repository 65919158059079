import React, { useState , useRef , useEffect} from 'react';

// API
import { postState } from 'utils/api';
import moment from 'moment';

// 컴포넌트
import ErrorMessage from 'components/errorMessage/errorMessage';

// scss
import './geneResult.scss';

function GeneResult() {

  // 에러처리
  const [isModalOpen, setIsModalOpen] = useState(false); // 모달 추가
  const [errorMessage, setErrorMessage] = useState(''); 

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => {
    setIsModalOpen(false);
    setErrorMessage(''); // 모달이 닫힐 때 에러메세지 초기화
  };

  // 데이터 불러오기
  // eslint-disable-next-line no-restricted-globals 
  const baseDir=`${location.pathname.toLowerCase()}/`;  
  const [ mainData , setMainData ] = useState(null);
  const [ filterData , setFilterData ] = useState([]);

  const [ props , setProps] = useState({
    id:`${localStorage.getItem('geneFarmCode')}`,
    passwd:localStorage.getItem('geneFarmPwd '),    
    date: 202310
  });
  
  useEffect(() => {
    postState(baseDir,props)
    .then(getdata => {      
      if (getdata !== undefined) {
      setMainData(getdata);
    //   console.log('#### mainData :', getdata);
      }
    })
    .catch(error => {         
        // console.log('#### error :', error);  
      setErrorMessage(error.message);
      openModal();
    });
  }, [props]);

  useEffect(() => {
    if (mainData) {
      setFilterData(mainData.plot_data);
    }
  },[mainData]);

  // 검색
  const selectRef = useRef(null);
  const inputRef = useRef(null);

  const filterResult = () => {
    const select = selectRef.current.value;
    const input = inputRef.current.value;
  
    const result = mainData.plot_data.filter((item) => {
      if(select === 'all') {
        return item.sname.includes(input);
      }
      if(select === 'die') {
        return item.sname.includes(input) && item.qdate !== null;
      }
       if(select === 'life') {
        return item.sname.includes(input) && item.qdate === null;
      }
      return false;
    });
    
    setFilterData(result);
    return true;
  };

  // 정렬
  const [ currentSort , setCurrentSort ] = useState('sname');
  const sortResult = (type) => {
    setFilterData((prevData) => {
      const sortedCowList = [...prevData];
      
      // 현재 정렬된 상태 확인
      const isAscending = currentSort === type;
  
      sortedCowList.sort((a, b) => {
        if (typeof a[type] === 'string' && typeof b[type] === 'string') {
          // 문자열인 경우 localeCompare()를 사용하여 정렬
          if (isAscending) {
            return b[type].localeCompare(a[type]);
          }
          return a[type].localeCompare(b[type]);
        }
        
        // 숫자인 경우 숫자 비교로 정렬
        if (isAscending) {
          return b[type] - a[type];
        }
        return a[type] - b[type];
      });
  
      // 현재 정렬된 상태에 따라 토글
      setCurrentSort(isAscending ? `-${type}` : type);
      return sortedCowList;
    });
  };

  const openGenePrint = (sname) => {
    const url = `/geneprint/?sname=${sname}`;
    window.open(url, '유전체 분석 결과', 'width=1200, height=800');
  };
    
  
  return (
    <div className='geneResult'>
      <ErrorMessage isOpen={isModalOpen} content={errorMessage} closeModal={closeModal}/>
      <div className='geneResultInner'>
        <h2>{mainData && mainData.farm_property[0].fname} 목장 유전체 분석 결과</h2>
        <div className='geneSearch'>
          <span>구분</span>
          <ul>
            <li>
              <select 
                defaultValue='all'
                ref={selectRef}
              >
                <option value='all'>전체</option>
                <option value='die' >재적우</option>
                <option value='life'>생존우</option>
              </select>
            </li>
            <li>
              <select defaultValue='manageNum'>
                <option value='manageNum'>관리번호</option>
              </select>
            </li>
          </ul>
          <div className='searchBox'>
            <input 
              type='text'
              placeholder='관리번호로 검색하세요'
              maxLength='8'
              ref={inputRef}
              onKeyDown={(e) => {
                if (e.key === 'Enter') filterResult();
              }}
            />
            <button
              type='button'
              onClick={filterResult}
            >
              검색
            </button>
          </div>
        </div>
        <div className='geneTable'>
          <table>
            <thead>
              <tr>
                <th>
                  <button
                    type='button'
                    onClick={() => {
                      sortResult('sname');
                      setCurrentSort('sname');
                    }}
                    className={currentSort.includes('sname') ? 'current' : ''}
                  >
                  관리번호
                  </button></th>
                <th>
                  <button
                    type='button'
                    onClick={() => {
                      sortResult('birthday');
                      setCurrentSort('birthday');
                    }}
                    className={currentSort.includes('birthday') ? 'current' : ''}
                  >
                    생년월일
                  </button></th>  
                <th>
                  <button
                    type='button'
                    onClick={() => {
                      sortResult('milk');
                      setCurrentSort('milk');
                    }}
                    className={currentSort.includes('milk') ? 'current' : ''}
                  >
                  1산유량
                </button></th>
                <th>
                  <button
                    type='button'
                    onClick={() => {
                      sortResult('class');
                      setCurrentSort('class');
                    }}
                    className={currentSort.includes('class') ? 'current' : ''}
                  >
                  구분
                </button></th>
                <th>
                  <button
                    type='button'
                    onClick={() => {
                      sortResult('mkg');
                      setCurrentSort('mkg');
                    }}
                    className={currentSort.includes('mkg') ? 'current' : ''}
                  >
                  유량
                </button></th>
                <th>
                  <button
                    type='button'
                    onClick={() => {
                      sortResult('fkg');
                      setCurrentSort('fkg');
                    }}
                    className={currentSort.includes('fkg') ? 'current' : ''}
                  >
                  유지방
                </button></th>
                <th>
                  <button
                    type='button'
                    onClick={() => {
                      sortResult('pkg');
                      setCurrentSort('pkg');
                    }}
                    className={currentSort.includes('pkg') ? 'current' : ''}
                  >
                  유단백
                </button></th>
                <th>
                  <button
                    type='button'
                    onClick={() => {
                      sortResult('scs');
                      setCurrentSort('scs');
                    }}
                    className={currentSort.includes('scs') ? 'current' : ''}
                  >
                  체세포
                </button></th>
                <th>
                  <button
                    type='button'
                    onClick={() => {
                      sortResult('ocs');
                      setCurrentSort('ocs');
                    }}
                    className={currentSort.includes('ocs') ? 'current' : ''}
                  >
                  체형종합
                </button></th>
                <th>
                  <button
                    type='button'
                    onClick={() => {
                      sortResult('udc');
                      setCurrentSort('udc');
                    }}
                    className={currentSort.includes('udc') ? 'current' : ''}
                  >
                  유방종합
                </button></th>
                <th>
                  <button
                    type='button'
                    onClick={() => {
                      sortResult('flc');
                      setCurrentSort('flc');
                    }}
                    className={currentSort.includes('flc') ? 'current' : ''}
                  >
                  지제종합
                </button></th>
                <th>
                  <button
                    type='button'
                    onClick={() => {
                      sortResult('ktpi');
                      setCurrentSort('ktpi');
                    }}
                    className={currentSort.includes('ktpi') ? 'current' : ''}
                  >
                  KTPI
                </button></th>
                <th>
                  <button
                    type='button'
                    onClick={() => {
                      sortResult('a2');
                      setCurrentSort('a2');
                    }}
                    className={currentSort.includes('a2') ? 'current' : ''}
                  >
                  A2
                </button></th>
              </tr>
            </thead>
          </table>
          <ul className='tableBody'>
          {
            filterData.length !== 0 
            ?
            filterData.map((item, index) => (
              <li key={index}>
                <ul>
                  <li>
                    <button
                      type='button'
                      onClick={() => openGenePrint(item.sname)}
                    >
                    {item.sname}
                    </button> 
                  </li>
                  <li>{moment(item.birthday , 'YYYYMMDD').format('YYYY-MM-DD')}</li>
                  <li>{Number(item.milk).toLocaleString()}</li>
                  <li>{item.class}</li>
                  <li>{Number(item.mkg).toLocaleString()}</li>
                  <li>{item.fkg}</li>
                  <li>{item.pkg}</li>
                  <li>{item.scs}</li>
                  <li>{item.ocs}</li>
                  <li>{item.udc}</li>
                  <li>{item.flc}</li>
                  <li>{Number(item.ktpi).toLocaleString()}</li>
                  <li>{item.a2}</li>
                </ul>
              </li>
            ))
            :
            <li>검색 결과가 없습니다.</li>
          }
          </ul>
        </div>
      </div>
    </div>
  );
}

export default GeneResult;
