import React from 'react';
import SuperiorCowSearch from 'containers/web/progency/superiorCowSearch/SuperiorCowSearch';
import { Helmet } from 'react-helmet-async';

function SuperiorCowSearchPage() {
  return (
    <>
      <Helmet>
        <title>한국형 후보씨수소 조회</title>
      </Helmet>
      <SuperiorCowSearch />
    </>
  );
}

export default SuperiorCowSearchPage;
