import React from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';

// api
import { getState } from 'utils/api';

// react-query
import { useQuery } from '@tanstack/react-query';

// scss
import './orderInfoSemen.scss';

// utils
import { openOxPrint } from 'utils/OpenOxPrint';

function OrderInfoSemen() {
  // 이 페이지는 정액주문 페이지에서 이름을 누르고 비밀번호를 입력하면 나오는 페이지입니다.

  const { orderInfoId, orderInfoPwd } = useOutletContext();
  const navigate = useNavigate();

  const { data: orderInfo } = useQuery({
    queryKey: ['orderInfo', orderInfoId],
    queryFn: () =>
      getState('/ox/ordered_semen_list', {
        id: orderInfoId,
        password: orderInfoPwd,
      }),
  });

  console.log(orderInfo);

  return (
    <div className="orderInfoSemen">
      <div className="tablebox">
        <table>
          <thead>
            <tr>
              <th>코드</th>
              <th>명호</th>
              <th>아비혈통</th>
              <th>종합지수</th>
              <th>유량</th>
              <th>지방</th>
              <th>단백질</th>
              <th>체형</th>
              <th>유방</th>
              <th>가격&#40;원&#41;</th>
              <th>주문량</th>
              <th>주문금액&#40;만원&#41;</th>
            </tr>
          </thead>
          <tbody>
            {orderInfo?.result2 && (
              <>
                {orderInfo?.result2?.map((cow, i) => {
                  if (cow.SUM_S_STR) {
                    return (
                      <tr key={i}>
                        <td colSpan={10}></td>
                        <td className="yellow">{cow.SUM_S_STR}</td>
                        <td className="yellow">{cow.SUM_S_PRICE}</td>
                      </tr>
                    );
                  }

                  return (
                    <tr key={cow.S_CODE}>
                      <td>
                        <button
                          type="button"
                          onClick={() => openOxPrint(cow.S_CODE)}
                        >
                          {cow.S_CODE && cow.S_CODE}
                        </button>
                      </td>
                      <td>{cow.S_SORTNAME && cow.S_SORTNAME}</td>
                      <td>{cow.S_SSORTNAME && cow.S_SSORTNAME}</td>
                      <td>{cow.S_ORIGIN_TPI && cow.S_ORIGIN_TPI}</td>
                      <td>{cow.S_PTAM && cow.S_PTAM}</td>
                      <td>{cow.S_PTAF && cow.S_PTAF}</td>
                      <td>{cow.S_PTAP && cow.S_PTAP}</td>
                      <td>{cow.S_PTAT && cow.S_PTAT}</td>
                      <td>{cow.S_UDC && cow.S_UDC}</td>
                      <td>{String(cow.S_DANGA).toLocaleString()}</td>
                      <td className="yellow">{cow.S_STR && cow.S_STR}</td>
                      <td className="yellow">{cow.S_PRICE && cow.S_PRICE}</td>
                    </tr>
                  );
                })}
              </>
            )}
          </tbody>
        </table>
      </div>
      <ul className="orderInfo">
        <li className="orderTwo">
          <ul>
            <li>
              <span>주문자명</span>
              <p>{orderInfo && orderInfo?.result1.S_J_NAME}</p>
            </li>
            <li>
              <span>전화번호</span>
              <p>{orderInfo && orderInfo?.result1.S_J_TEL}</p>
            </li>
          </ul>
        </li>
        <li>
          <span>주소</span>
          <p>{orderInfo && orderInfo?.result1.S_J_ADDR}</p>
        </li>
        <li className="orderTwo">
          <ul>
            <li>
              <span>공급받을곳</span>
              <p>{orderInfo && orderInfo?.result1.S_J_NAME}</p>
            </li>
            <li>
              <span>공급받을곳 전화</span>
              <p>{orderInfo && orderInfo?.result1.S_B_TEL}</p>
            </li>
          </ul>
        </li>
        <li>
          <span>공급희망일</span>
          <p>{orderInfo && orderInfo?.result1.S_B_DATE}</p>
        </li>
        <li>
          <span>메모</span>
          <p>{orderInfo && orderInfo?.result1.S_J_MEMO}</p>
        </li>
      </ul>
      <div className="orderInfoSemenBtn">
        <button type="button" onClick={() => navigate('/Web/Ox/BuySemen')}>
          목록으로 돌아가기
        </button>
      </div>
    </div>
  );
}

export default OrderInfoSemen;
