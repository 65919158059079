import React, { useState, useEffect } from 'react';

const ImageComponent = ({ imageUrl }) => {
  const [base64Image, setBase64Image] = useState('');

  useEffect(() => {
    fetch(imageUrl)
      .then(response => response.blob())
      .then(blob => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          setBase64Image(reader.result);
        };
        
      })
      .catch(error => {
        console.error('Error fetching and encoding image:', error);
      });
  }, [imageUrl]);

  return (
    <div>
      {base64Image ? <img src={base64Image} alt="Encoded" /> : <p>Loading...</p>}
    </div>
  );
};

export default ImageComponent;