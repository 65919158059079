import React from 'react';

import pic694 from 'assets/images/sub/_images/pic694.jpg';
import pic695 from 'assets/images/sub/_images/pic695.jpg';
import pic696 from 'assets/images/sub/_images/pic696.jpg';

function Do140() {

  return (
    <div className="techinfoview">
      <h1>파상풍</h1>
      <p className="pstyle">파상풍균이 외부의 상처를 통해 체내에 침입하여 독소(毒素)를 생성하므로써 일어나는 병으로 인수(人獸)공통 전염병이다. 파상풍균에 의해 생성된 독소는 중추신경장애를 일으켜 호흡곤란과 질식사를 유발한다. </p>
      <div className="leftpart">
        <h2>증상</h2>
        <ul>
          <li>잠복기는 통상 1∼3주일정도이며 발병후 5∼10일 이면 폐사하는 일이 많다. </li>
          <li>전신의 근육이 뻣뻣해지면서 경련이 일어나고 움직이기 싫어하며, 식욕은 있으나 섭취한 음식물을 삼키지 못하고 침만 흘린다. </li>
          <li>소리나 접촉등의 자극을 주면 전신의 강직성 경련과 함께 순막을 노출시킨다. </li>
          <li>병세가 더욱 악화되면 머리를 길게 내뻗고 콧구명을 벌리며 입을 꽉 다문채 괴로운 모습을 보인다. </li>
          <li>증상의 말기에는 전신이 뻣뻣해지면서 호흡곤란을 나타내며 사망한다.</li>
        </ul>
        <h2>목장에서의 처치</h2>
        <ul>
          <li>병우는 어둡고 조용한 곳으로 격리하여 외상여부를 확인한 다음, 외상이 있다면 절개하여 배농하고 이물을 제거한다. </li>
          <li>상처부위는 과산화수소로 소독한다. </li>
          <li> 근육 강직을 완화하기 위해 20%황산 마그네슘 100∼200ml를 피하 또는 근육주사한다. 이때 페니실린 주사를 병용할 수 있다.</li>
        </ul>
      </div>
      <div className="rightpart">
        <img src={pic694} className="nonborder" alt=""/>
        <img src={pic695} className="nonborder" alt=""/>
        <img src={pic696} className="nonborder" alt=""/>
      </div>
    </div>
  );
}

export default Do140;