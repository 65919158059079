import React from 'react';

import pic272 from 'assets/images/sub/_images/pic272.jpg';
import pic273 from 'assets/images/sub/_images/pic273.jpg';
import pic274 from 'assets/images/sub/_images/pic274.jpg';
import pic275 from 'assets/images/sub/_images/pic275.gif';

function Do184() {

  return (
    <div className="techinfoview">
      <h1>티모시</h1>
      <p>영명 : Timothy <br/>학명 : Phleum pratense L. </p>
      <div style={{width: '60%',float: 'left'}}>
        <h2>원산지</h2>
        <p className="pstyle">&nbsp;&nbsp;&nbsp;유럽으로부터 전파되었으며, 1711년경에 발견되어 &quot;하드그라스&quot;라 불리어졌다. 그 후 1747년경에 &quot;티모시&quot; 라는 이름이 붙여졌으며, 질이 좋은 건초용 목초가 이용하게 됐다.</p>
        <h2>성상</h2>
        <p className="pstyle">&nbsp;&nbsp;&nbsp;티모시는 2가지의 계통이 있는데 한 계통은 초장이 적은 잔디형의 푸레울 버터로니 디씨(Phleum bertoloni D.C.) 와 원래의 푸레움 노도솜(Phleum nodosum L.)라고 하는 2배체(2N=14)의 티모시가 있으며 다른 계통으로는 푸레움 푸라텐스(Phleum pratense L.)=(2n=42)가 있어 서로 다르다. 잔디형은 잎이 부드럽고, 초장이 짧아 초지나 운동장에 많이 이용되고 있으며, 일반적으로 우리가 티모시라고 하는 초종은 초장이 크며 잎은 넓고, 종자에 짧은 까락이 붙어 있다. 티모시는 영년생 화본과 목초로서 천근성이고 섬유근이 지하 120cm정도 벋는다. 지하경은 인경을 가지고 있고, 줄기 밑부분의 잎이 수량의 주축을 이루며, 초장은 수경까지 약 50∼100cm 정도이다. 잎은 부드럽고 엷은 녹색이다. 티모시의 개체 문얼경은 1∼2년의 단년생이나, 오래된 마디에서 새로운 분얼경이 계속 자라기 때문에 영년생인 특성을 가지게 된다. 서늘한 시후와 습한 지역에서 재배가 잘되며 특히 추위에 강하기 때문에 높은 지대에서도 얼어죽지 않는다.</p>
        <h2>재배</h2>
        <p className="pstyle">&nbsp;&nbsp;&nbsp;산성토양에 강하고 침수에도 강하며 저지대나 수분이 포화된 지대에서도 잘 자란다. 식토나 양토에서도 잘되며 사질토양에서도 수분만 있으면 재배가 용이하다. 내한성이 강할 뿐만 아니라 병충해는 그리 염려할 필요가 없다. 그러므로 티모시의 재배조건은 토양이나 기후조건이 과범위하게 적용된다. 종자로부터 발아된 유식물은 잘 자라므로 초지조성에 적합하며 화본과 목초 중에서 생산량이 많은 초종 중의 하나이다. 가뭄에는 특히 약하고 염해지와 알칼리 토양 및 점질토양에는 재배가 잘 안된다. 종자는 작으므로 앝게 파종하여야 하는데 목초 파종기로 파종하기도 사실상 어려울 정도이다. 생육기간중 그늘이나 장기간 침수할 경우에는 저항성이 약해지며, 겨울동안네는 지하경과 뿌리가 부패하기 쉽다. 10a당 수량은 2,500∼3,500kg으로 품종은 크라이막스(Climax), 크레어(Clair), 오덴발델(Odenvider), 헤일브링크(Heil brink), 카후(Kahu)등이 있다. </p>
        <h2>이용</h2>
        <ul>
          <li>건초
            <p>건초를 조제하기에 적합한 목초이다. 직립성이므로 수확하기가 쉬우며, 두과목초와 혼파할 때 영양분에 대한 경쟁니 적으므로 수량이 많다. 단파보다는 알사익 클로버나, 레드클로버와 같이 혼파하는 것이 좋으며 알팔파와 혼파하는 것이 더욱 좋다. 개화기 이전에 건초를 조제하지 않으면 질이 좋지 않은 건초가 되는 것이 하나의 결점이므로 두과목초와 혼파하는 것이 좋으며, 개화기 전에 일찍 예취하여 건초를 조제하면 이러한 결점을 보완할 수 있다. 척박한 토양에서도 재배가 잘되나, 특히 질소질을 많이 사용했을 때는 수량과 단백질을 높일 수 있다. 개화 최성기에 건초를 조제하면 높은 건물수량을 얻을 수 있으나 단백질 함량이 낮다. </p>
          </li>
          <li>방목 및 청예
            <p>주로 고산지에서 초지조성이 초지조성용으로 많이 이용된다. 봄 일찍부터 생육이 되지 않고 수량과 가축의 기호성도 그리 좋지는 않으나 잎의 질은 양호한 편이다. 생육기간은 주로 초여름이며, 초장이 긴 새로운 분얼경이 많기 때문에 과방목 되기가 쉽다. 윤환방목을 하면 7월 중순경 이후 생육이 저하되는 것을 방지할 수 있고, 과방목을 하였을 때는 초지가 쉽게 황폐된다.</p>
          </li>
        </ul>
      </div>
      <div style={{width: '40%', float: 'left'}}>
        <table>
          <tr>
            <td><img src={pic272} className="nonborder2" alt=""/></td>
          </tr> 
          <tr>
            <td><img src={pic273} className="nonborder2" alt=""/></td>
          </tr>
          <tr>
            <td><p style={{textAlign: 'center'}}>유식물의 생육과정</p></td>
          </tr>
          <tr>
            <td><img src={pic274} className="nonborder2" alt=""/></td>
          </tr>
          <tr>
            <td><p style={{textAlign: 'center'}}>종 자</p></td>
          </tr>
          <tr>
            <td><img src={pic275} className="nonborder2" alt=""/></td>
          </tr>
          <tr>
            <td><p style={{textAlign: 'center'}}>식물체의 모양 :<br/>인경(왼쪽), 꽃(오른쪽) </p></td>
          </tr>  
        </table>
      </div>
    </div>
  );
}

export default Do184;