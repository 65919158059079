import React from 'react';


function Do62() {

  return (
    <div className="techinfoview">
				<h1>큰송아지 사양관리(12개월령~초산2개월전까지)</h1>
				<h2>번식을 위한 관리 </h2>
				<ul>
					<li>체중이 270kg 정도에 이르면 육성우가 발정기에 이른다. 영양 공급이 부족하면 초발정이 지연되어 번식과분만이 지연된다. </li>
					<li>15개월령에 체중이 350kg 이상되도록 관리하여 이때에 수태시키면 24개월령에 초산할수 있다. </li>
					<li>초산 2개월전 (약 22개월령)에는 체중이 520 ∼ 540kg이 되도록 관리한다. </li>
					<li>위와 같은 체중에 도달시키기 위해서 3개월령 - 분만전 2개월 까지 하루 증체 목표를 0.7∼0.8kg으로 한다. </li>
					<li> 이 기간동안의 영양소 요구량이나 정상발육에 관하여는 앞의 &lt;표 50&gt;과 &lt;표 51&gt;을 참고한다. </li>
				</ul>
				<h2>사료급여</h2>
				<ul>
					<li>질이 좋은 조사료를 충분히 급여한다면 농후사료를 급여하지 않고도 정상성장을 할 수있다,. 예를 들어 청초기에 체중의 10∼12%만큼 청초를 섭취하거나 사사기에 혼합목건초를 체중의 2.5∼3% 섭취한다면 농후사료의 급여가 필요없다. </li>
					<li> 조사료의 섭취량은 조사료의 종류와 질에 따라서 크게 차이가 난다. 특히 같은 종류의 조사료에서도 질에 따라 큰 차이가 나므로 항시 양질의 조사료를 준비하는데 힘써야 한다. 표 69는 조사료의 종류에 따른 섭취가능량을, 표 70은 조사료의 질에 따른 섭취량의 차이를 보여주고 있다. </li>
					<li>조사료의 질이 좋지 못하거나 섭취량이 부족할 때는 농후사료(큰송아지사료)를 체중의 0.5∼1%보충 급여한다. 특히 볏짚이나 야건초등 저질조사료 급여시에는 조단백질 함량이 14∼16%되는 육성용 배합사료를 체중의 1%정도 급여하며 A,D,E 등도 보충해 준다. 비타민을 첨가제로 급여 하는것도 번거로울때는 겨울철 사사기동안에 2∼3개월 간격으로 비타민제를 근육주사하면 된다.</li>
				</ul>
				<p><b>&lt;표 69&gt; 조사료의 섭취 가능량</b></p>
				<table className="tablestyle">
					<tr>
						<td>종 류</td>
						<td>섭취가능량</td>
					</tr>
					<tr>
						<td>청 초</td>
						<td>체중의 10∼20%</td>
					</tr>
					<tr>
						<td>청예작물</td>
						<td>체중의 8∼10%</td> 
					</tr>
					<tr>
						<td>근 채 류</td>
						<td>체중의 6 ∼8%</td> 
					</tr>
					<tr>
						<td>사일레지</td>
						<td>체중의 5 ∼6%</td> 
					</tr>
					<tr>
						<td>건 초</td>
						<td>체중의 2 ∼3%</td> 
					</tr>
				</table><br/>
				<p><b>&lt;표 70&gt; 조사료의 질과 섭취량변화</b></p>
				<table className="tablestyle">
					<tr>
						<td>조사료의 질</td>
						<td>섭취량</td>
					</tr>
					<tr>
						<td>수</td>
						<td>체중의 3.0%</td>
					</tr>
					<tr>
						<td>우</td>
						<td>체중의 2.5%</td> 
					</tr>
					<tr>
						<td>미</td>
						<td>체중의 2.0%</td> 
					</tr>
					<tr>
						<td>양</td>
						<td>체중의 1.5%</td> 
					</tr>
					<tr>
						<td>가</td>
						<td>체중의 1.0%</td> 
					</tr>
				</table>
			</div>
  );
}

export default Do62;
