import React from 'react';

function Fo48() {

  return (
    <div className="techinfo_view">
				<p className="viewTitle">광감작</p>
				<dl>
					<dt>발병대상</dt>
					<dd>소, 말, 양, 돼지</dd>
				</dl>
				
				<dl>
					<dt>원인</dt>
					<dd>햇빛에 의한 연한 피부의 과민반응으로 발생된다. 사료와 목초 그리고 약품중 어느 것은 피부의 감작을 유발하는 물질이 함유되어 있다(일차 광감작). 그외 정상적인 경우라도 체외로 배설되는 대사산물이 간기능의 부전으로 인하여 체내에 축적되어 발생되는 수도 있다(간에서 비롯하는 광감작). 일차 광감작은 식물이 푸르고 풍부하며 성장속도가 빠른 봄철에 항상 발생된다. 고추나물속(St. John&apos;s Wort)과 메밀이 광감작을 유발하는 물질을 함유하고 있는 대표적인 식물이며 또한 평지 식물과 클라인 그라스, 양배추, 트레포일(잔개자리), 알팔파, 알사이크 클로버, 스웨디쉬 클로버, 명아주과(lamb&apos;s tongue) 및 질경이풀 등이 광감작과 관련되어 있다. </dd>
				</dl>
				
				<dl>
					<dt>징후 및 증상</dt>
					<dd>햇빛에 심하게 노출됨으로써 발생되며 백색 또는 엷은 착색지역과 피부의 노출부위에 한정하여 감염된다. 즉 비구부(주둥이)와 눈, 뺨 및 등쪽의 빛이 닿는 부위가 제일 먼저 감염되고 배와 유방부위도 가축이 누워 있을 때 태양에 노출되어 감염될 수 있다. 초기증상은 감염부위가 붉어지며 피부가 부풀어 오른다. 이후 감염부위로부터 조직액이 삼출되고 피부에 딱지가 생겨 결과적으로 털이 엉키게 된다. 심한 경우 눈까풀과 비공이 붓고 여기서 더 진행되면 피부의 탈락과 괴저가 일어난다.</dd>
				</dl>
				
				<dl>
					<dt>질병분포와 손실규모</dt>
					<dd>광감작은 전 세계적으로 일어나며 사망율은 소보다 양에서 더 높다. 체중감소, 유방과 유두의 손상, 시력장애와 이차감염 및 왜소한 새끼를 분만하는 등 경제적인 타격이 크다. </dd>
				</dl>
				
				<dl>
					<dt>치료</dt>
					<dd>장애를 일으키는 목초의 급여를 중단하고 햇빛으로부터 격리시킨다. 심한 경우는 정맥주사와 항생제 및 기타 의약품 등의 부가적 치료가 요구된다.</dd>
				</dl>
				
				<dl>
					<dt>대책</dt>
					<dd>장애을 주는 식물의 접근을 금하게 하고 가축을 그늘진 곳에 유지시킨다.</dd>
				</dl>
				
				<dl>
					<dt>예방</dt>
					<dd>방목지와 방목조건의 적절한 관리가 광감작을 예방할 수 있다. </dd>
				</dl>	
				
				<dl>
					<dt>참고</dt>
					<dd>광감작은 자외선에 지나치게 노출되어 정상적인 가축의 백색 및 착색도가 엷은 피부에 염증이 발생되는 sunburn과는 구분되어야 한다.</dd>
				</dl>	
			</div>
  );
}

export default Fo48;
