import React from 'react';

// constants
import { BOARD_CODE, BOARD_NAME } from 'constants/backOfficeBoard';

// components
import BoardView from '../_components/BoardView';

function ImproveInfoView() {
  return (
    <BoardView
      boardCode={BOARD_CODE.IMPROVE_INFO}
      boardName={BOARD_NAME.IMPROVE_INFO}
      boardUrl="improveInfo"
    />
  );
}

export default ImproveInfoView;
