import React from 'react';

import Pic126 from 'assets/images/sub/_images/pic126.gif';
import Pic127 from 'assets/images/sub/_images/pic127.gif';
import Pic128 from 'assets/images/sub/_images/pic128.gif';
import Pic129 from 'assets/images/sub/_images/pic129.gif';
import Pic130 from 'assets/images/sub/_images/pic130.gif';
import Pic131 from 'assets/images/sub/_images/pic131.gif';

function Do14() {

  return (
    <div className="techinfoview">
				<h1>체적에 대하여</h1>
				<p style={{lineHeight: '200%'}}>&nbsp;&nbsp;&nbsp;체적의 심사표준에 가장 작게 반영되어 있으나, 물려받은 유전능력을 최고로 발휘하기 위 해서는 결정적인 역할을 하는 것이 또한 이 형질이다. 왜? 반추동물과 우유를 생산하는 젖 소로서는 많은 양의 조사료를 소화해 낼 수 있어야 하는데, 적절한 체적이 뒷받침되지 않으 면 높은 산유량을 달성하기가 어렵게 되다. 체적을 쉽고 빠르게 이해하려면, 몸퉁이 길이×깊 이×폭으로 나타나는 부피를 생각하면 될 것이다.<br/>&nbsp;&nbsp;&nbsp;체적은 100점중 10점을 반영하는 것으로, 선형형질로는 체심 한 형질만 평가된다. 간단하게 설명하면 체심은 갈비의 상하 길이(깊이)를 평가하는 것이다. 체심이란 기갑부 뒤쪽으로 갈비들의 길이를 추적함으로 평가가 가능하며, 또한 깊이는 뒤쪽으로 갈수록 깊어 져야 한다(넓어져야 한다). 체심 하나만으로는 체적을 정확하게 표현하지 못한다. 최종점수 에서 체적을 정확히 평가하기 위해서는, 소의 후방에서 보았을 때 가장 잘 관찰할 수 있는 갈비의 개장도(開張度)도 포함시켜야 한다.
				하단 끝 두가지 그림은 양쪽 끝이 갈비의 개장도를 나타내는 것이다. 왼쪽 그림의 소는 개장도도 좋고 갈비도 깊게 발달된 것을 보여주는 반면, 오른쪽 그림의 소는 갈비의 개장도 나 깊이 모두가 좋은 않는 개체이다. 일반적으로 체적은 나이가 들수록 증가하지만, 갈비의 개장도는 변하지 않는다.<br/>&nbsp;&nbsp;&nbsp;체적의 최종점수 반영에는 흉폭(흉곽과 폭)도 고려된다. 체심의 점수가 낮은 소는 실질적 으로 흉폭이 매우 좁고, 반면에 높은 점수를 받은 소들도 흉폭도 넓고 전체적으로 강건성도 뛰어난 것을 볼 수 있다. 체심이 얕고(아래위로 체폭이 좁은 것) 약한 소는 물려받은 유전능 력을 충분히 발휘할 수 있을 큼 먹어 주지를 못한다.</p><br/>
				<table className="tablestyle6">
					<tr>
						<td colSpan="5"><b>body depth 체 심</b></td>
					</tr>
					<tr>
						<td>&gt;45 극히 깊은 체심</td>
						<td>38∼32 싶은 체심</td>
						<td>28∼22 중간 정도의 체심</td>
					</tr>
					<tr>
						<td><img src={Pic126} alt="체심 정도"/></td>
						<td><img src={Pic127} alt="체심 정도"/></td>
						<td><img src={Pic128} alt="체심 정도"/></td>
					</tr>
					<tr>
						<td>28~22 얕은 체심</td>
						<td>18~12 극히 얕은 체심&lt;</td>
						<td>깊고 잘 벌어진 갈비, 얕고 개장이 나쁜갈비</td>
					</tr>
					<tr>
						<td><img src={Pic129} alt="체심 정도"/></td>
						<td><img src={Pic130} alt="체심 정도"/></td>
						<td><img src={Pic131} alt="체심 정도"/></td>
					</tr>
				</table>
				
				<br/>
			</div>
  );
}

export default Do14;
