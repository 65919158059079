import React from 'react';

import pic631 from 'assets/images/sub/_images/pic631.jpg';
import pic632 from 'assets/images/sub/_images/pic632.jpg';
import pic633 from 'assets/images/sub/_images/pic633.jpg';

function Do122() {

  return (
    <div className="techinfoview">
      <h1>유방부종</h1>
      <p className="pstyle">유방염은 병원균이 유두공(乳頭孔)을 통하거나 유두 또는 유방에 생긴 상처를 통해 유선(乳腺)내에 침입함으로써 유선 조직에 염증(炎症)이 생긴 상태를 말한다.</p>
      <div className="leftpart">
        <h2>원인</h2>
        <ul>
          <li>분만후 우유를 만들기 위해 많은 양의 혈약이 급격히 유선(乳腺)내로 들어간다. 이로 인해 유방내 혈압이 상승하는데 비해 정액과 임파계가 이에 대응치 못해 혈관에서 스며나온 액체 성분이 대량으로 피하에 축적하여 유방의 부종을 일으킨다.</li>
        </ul>
        <h2>증상</h2>
        <ul>
          <li>일반적으로 고능력우에서 많이 발생하며 </li>
          <li>분만 1개월 전부터 분만일에 가까워짐에 따라 증상이 나타나는 일이 많다. </li>
          <li>유방부종시 유방의 피부는 긴장되어 광택이 있고 손가락으로 유방을 누르면 눌린 자국이 한동안 남는다. </li>
          <li>유두는 두터워지고 짧아진다. </li>
          <li>유방염을 병발(倂發)하면 병세는 현저히 악화된다.</li>
        </ul>
        <h2>목장에서의 처치</h2>
        <ul>
          <li>분만 수일후부터 운동을 시키면 좋아진다. </li>
          <li>유방의 혈액순환을 돕기 위해 따뜻한 물을 사용하여 유방을 조심스럽게 주무르며 자주 짜준다. (1일 5∼10회 정도) </li>
          <li>분만후 가능한 한 조기에 이뇨제를 투여하면 좋은 치료효과를 얻을 수 있다. </li>
          <li>부신피질 호르몬제를 초기에 한해 병용하여 사용하면 도움이 된다. </li>
        </ul>
      </div>
      <div className="rightpart">
        <img src={pic631} className="nonborder" alt=""/>
        <img src={pic632} className="nonborder" alt=""/>
        <img src={pic633} className="nonborder" alt=""/>
      </div>
    </div>
  );
}

export default Do122;