import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { getState } from 'utils/api';

// 이미지
import emptyGirl from 'assets/images/oxPrint/empty_girl.png';
import empryBoy from 'assets/images/oxPrint/empty_boy.png';
import America from 'assets/images/containers/main/america.png';

// 컴포넌트
import ErrorMessage from 'components/errorMessage/errorMessage';
import OxPrintChart from './OxPrintChart';

// scss
import './oxPrint.scss';

function OxPrint() {
  function searchParam(key) {
    const location = useLocation();
    return new URLSearchParams(location.search).get(key);
  }

  const code = searchParam('code');

  // 에러처리
  const [isModalOpen, setIsModalOpen] = useState(false); // 모달 추가
  const [errorMessage, setErrorMessage] = useState('');

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => {
    setIsModalOpen(false);
    setErrorMessage(''); // 모달이 닫힐 때 에러메세지 초기화
  };

  // 데이터 불러오기
  // eslint-disable-next-line no-restricted-globals
  const baseDir = `/ox/oxlist/${searchParam('code')}`;

  const [mainData, setMainData] = useState();

  const [props, setProps] = useState({ code });

  useEffect(() => {
    getState(baseDir, props)
      .then((getdata) => {
        if (getdata !== undefined) {
          setMainData(getdata);
        }
      })
      .catch((error) => {
        setErrorMessage(error.message);
        openModal();
      });
  }, [props]);

  return (
    <article className="oxPrint">
      <ErrorMessage
        isOpen={isModalOpen}
        content={errorMessage}
        closeModal={closeModal}
      />
      <h1>
        씨수소 검색 <span>씨수소 정보검색</span>
      </h1>
      <div className="oxPrintInner">
        <h2>
          {mainData && mainData.s_code} &#40;{mainData && mainData.s_fullcode}
          &#41; {mainData && mainData.s_sortname}{' '}
          <img src={America} alt="미국국기" />
        </h2>
        <ul className="oxPrintInfo">
          <li>등록번호 : {mainData && mainData.s_reg}</li>
          <li>종합지수&#40;TPI&#41; : {mainData && mainData.s_tpi}</li>
          <li>중점개량형질 : {mainData && mainData.s_spec}</li>
          <li>평가시기 : 202308</li>
        </ul>
        <ul className="oxPic">
          <li>{mainData && mainData.s_sortname}</li>
          <li>{mainData && mainData.s_sortname} 딸소</li>
        </ul>
        <ul className="oxPic">
          <li>
            {mainData && (
              <img
                src={
                  mainData.s_sajin_url
                    ? `${process.env.REACT_APP_STATICS_URL}/_ox/${mainData.s_sajin_url}`
                    : `${empryBoy}`
                }
                alt={`${mainData.s_sortname} 1`}
              />
            )}
          </li>
          <li>
            {mainData && (
              <img
                src={
                  mainData.s_dt_sajin_url
                    ? `${process.env.REACT_APP_STATICS_URL}/_ox/${mainData.s_dt_sajin_url}`
                    : `${emptyGirl}`
                }
                alt={`${mainData.s_sortname} 1`}
              />
            )}
          </li>
        </ul>
        <ul className="oxDetail">
          <li>
            <h3>명칭</h3>
            <p>
              {mainData && mainData.s_fullname_e}
              <br />
              {mainData && mainData.s_fullname_k}
            </p>
          </li>
          <li className="tablebox">
            <h3>혈통</h3>
            <table>
              <tbody>
                <tr>
                  <th>부</th>
                  <td>
                    {mainData && mainData.s_sire_k}
                    <br />
                    &#40;{mainData && mainData.s_sire_e}&#41;
                  </td>
                </tr>
                <tr>
                  <th>모</th>
                  <td>
                    {mainData && mainData.s_dam_k}
                    <br />
                    &#40;{mainData && mainData.s_dam_e}&#41;
                  </td>
                </tr>
                <tr>
                  <th>외조부</th>
                  <td>
                    {mainData && mainData.s_mgs_k}
                    <br />
                    &#40;{mainData && mainData.s_mgs_e}&#41;
                  </td>
                </tr>
              </tbody>
            </table>
          </li>
          <div className="rowtable">
            <li className="tablebox">
              <h3>
                종모우 생산능력&#40;PTA&#41;{' '}
                <span>신뢰도: {mainData && mainData.s_pta_rel}%</span>
              </h3>
              <table>
                <tbody>
                  <tr>
                    <th>유량&#40;PTAM&#41;</th>
                    <td>{mainData && mainData.s_ptam} KG</td>
                  </tr>
                  <tr>
                    <th>유지량&#40;PTAF&#41;</th>
                    <td>{mainData && mainData.s_ptaf} KG</td>
                  </tr>
                  <tr>
                    <th>유단백&#40;PTAP&#41;</th>
                    <td>{mainData && mainData.s_ptap} KG</td>
                  </tr>
                </tbody>
              </table>
            </li>
            <li className="tablebox">
              <h3>
                체형 유전 능력{' '}
                <span>신뢰도: {mainData && mainData.s_ptat_rel}%</span>
              </h3>
              <table>
                <tbody>
                  <tr>
                    <th>체형&#40;PTAT&#41;</th>
                    <td>{mainData && mainData.s_ptat}</td>
                  </tr>
                  <tr>
                    <th>유용능력&#40;DF&#41;</th>
                    <td>{mainData && mainData.s_sta15}</td>
                  </tr>
                  <tr>
                    <th>신체형태&#40;BD&#41;</th>
                    <td>{mainData && mainData.s_sta16}</td>
                  </tr>
                  <tr>
                    <th>발굽과다리&#40;FLC&#41;</th>
                    <td>{mainData && mainData.s_sta9}</td>
                  </tr>
                  <tr>
                    <th>유방종합지수&#40;UDC&#41;</th>
                    <td>{mainData && mainData.s_udc}</td>
                  </tr>
                </tbody>
              </table>
            </li>
            <li className="oxDetailSub">
              <h3>보조적인 특징</h3>
              <p>체세포점수&#40;SCS&#41;: {mainData && mainData.s_scs}</p>
              <p>난산율&#40;DBH&#41;: {mainData && mainData.s_sta21}%</p>
              <p>생산수명&#40;PL&#41;: {mainData && mainData.s_sta20}</p>
            </li>
          </div>
          {mainData && <OxPrintChart data={mainData} />}
        </ul>
      </div>
    </article>
  );
}

export default OxPrint;
