import React from 'react';

import pic310 from 'assets/images/sub/_images/pic310.gif';
import pic311 from 'assets/images/sub/_images/pic311.gif';
import pic312 from 'assets/images/sub/_images/pic312.gif';
import pic313 from 'assets/images/sub/_images/pic313.gif';
import pic314 from 'assets/images/sub/_images/pic314.gif';
import pic315 from 'assets/images/sub/_images/pic315.png';
import pic316 from 'assets/images/sub/_images/pic316.gif';
import pic317 from 'assets/images/sub/_images/pic317.gif';
import pic318 from 'assets/images/sub/_images/pic318.gif';
import pic319 from 'assets/images/sub/_images/pic319.gif';
import pic320 from 'assets/images/sub/_images/pic320.gif';
import pic321 from 'assets/images/sub/_images/pic321.gif';
import pic322 from 'assets/images/sub/_images/pic322.gif';
import pic323 from 'assets/images/sub/_images/pic323.gif';

function Do180() {

  return (
    <div className="techinfoview">
      <h1>사료작물 재배 및 초지관리의 주요점</h1>
      <h2>사료작물</h2>
      <ul>
        <li>옥수수
          <ul>
            <li>품종의 선택
              <ul>
                <li>옥수수는 양질의 사일리지를 제조하기 위하여 그 지역에 알맞는 품종을 선택한다.
                  <p><b>품종별 재배 적지</b></p>
                  <table className="tablestyle">
                    <tr>
                      <td>품 종 명</td>
                      <td>A</td>
                      <td>B</td>
                      <td>C</td>
                      <td>D</td>
                      <td>E</td>
                      <td>F</td>
                      <td>G</td>
                      <td>H</td>
                    </tr>
                    <tr>
                      <td>수원19호</td>
                      <td>○</td>
                      <td>○</td>
                      <td>○</td>
                      <td>△</td>
                      <td>×</td>
                      <td>×</td>
                      <td>△</td>
                      <td>×</td>
                    </tr>
                    <tr>
                      <td>진 주 옥</td>
                      <td>×</td>
                      <td>×</td>
                      <td>△</td>
                      <td>○</td>
                      <td>○</td>
                      <td>○</td>
                      <td>△</td>
                      <td>○</td>
                    </tr>
                    <tr>
                      <td>DK729</td>
                      <td>○</td>
                      <td>○</td>
                      <td>○</td>
                      <td>○</td>
                      <td>○</td>
                      <td>○</td>
                      <td>○</td>
                      <td>○</td>
                    </tr>
                    <tr>
                      <td>DK689</td>
                      <td>○</td>
                      <td>○</td>
                      <td>○</td>
                      <td>○</td>
                      <td>○</td>
                      <td>○</td>
                      <td>○</td>
                      <td>○</td>
                    </tr>
                    <tr>
                      <td>G4743</td>
                      <td>○</td>
                      <td>○</td>
                      <td>○</td>
                      <td>○</td>
                      <td>○</td>
                      <td>○</td>
                      <td>○</td>
                      <td>○</td>
                    </tr>
                    <tr>
                      <td>P3160</td>
                      <td>○</td>
                      <td>○</td>
                      <td>○</td>
                      <td>○</td>
                      <td>○</td>
                      <td>○</td>
                      <td>○</td>
                      <td>○</td>
                    </tr>
                    <tr>
                      <td>P3282</td>
                      <td>○</td>
                      <td>○</td>
                      <td>○</td>
                      <td>○</td>
                      <td>○</td>
                      <td>○</td>
                      <td>○</td>
                      <td>○</td>
                    </tr>
                    <tr>
                      <td>P3352</td>
                      <td>○</td>
                      <td>○</td>
                      <td>○</td>
                      <td>○</td>
                      <td>○</td>
                      <td>○</td>
                      <td>○</td>
                      <td>○</td>
                    </tr>
                    <tr>
                      <td>XL- 394</td>
                      <td>○</td>
                      <td>○</td>
                      <td>○</td>
                      <td>○</td>
                      <td>○</td>
                      <td>○</td>
                      <td>○</td>
                      <td>○</td>
                    </tr>
                  </table>
                  <p>주) △ : 남부지역<br/>△ : 북부지역</p>
                  <img src={pic310} style={{width: '40%'}} alt=""/> 
                </li>
              </ul>
            </li>
            <li>파종기
              <p>적기 조기 파종이 다수확의 첫째조건 !<br/><br/>일평균 기온이 10℃일 때 적기에 조기 파종하는 시기이다. 이 경우에는 3cm정도로 꼭 복토한다. 그래야 늦서리에 생장점이 피해를 입지 않는다.</p>
              <img src={pic311} style={{width: '50%'}} alt=""/>
            </li>
            <li>시비량 
              <p>옥수수는 비료요구량이 많은 작물이므로 시비가 다수확의 비결이다.</p>
              <table className="tablestyle">
                <tr>
                  <td>요 소</td>
                  <td>용성인비(용인)</td>
                  <td>염화칼리</td>
                  <td>고 토</td>
                  <td>퇴 비</td>
                </tr>
                <tr>
                  <td>40∼45</td>
                  <td>70 ∼ 80kg</td>
                  <td>25 ∼ 30kg</td>
                  <td>(3kg)</td>
                  <td>3 ∼ 5t</td>
                </tr>
              </table>
              <p>10a당 시비량 (사일리지용)</p>
            </li>
            <li>종자의 크기
              <p>옥수수 종자의 크기는 대립(L), 중립(M), 소립(S)으로 표시한다.</p>
              <table className="tablestyle">
                <tr>
                  <td>종자의 크기</td>
                  <td>LL</td>
                  <td>L</td>
                  <td>M</td>
                  <td>S</td>
                </tr>
                <tr>
                  <td>종자의 직경</td>
                  <td>15mm</td>
                  <td>14mm</td>
                  <td>13mm</td>
                  <td>12mm</td>
                </tr>
                <tr>
                  <td>1kg당 입수 </td>
                  <td>2.000 이하</td>
                  <td>2,000</td>
                  <td>3,000</td>
                  <td>4,000</td>
                </tr>
              </table>
            </li>
            <li>재식 밀도와 파종량
              <p>품종특성을 최대 발휘하고 최고 수량을 올리려면 적정 본수를 확보해야 한다. (10a당 주수)</p>
              <table className="tablestyle">
                <tr>
                  <td>주간 휴폭</td>
                  <td>16cm</td>
                  <td>18cm</td>
                  <td>20cm </td>
                  <td>22cm</td>
                  <td>24cm</td>
                  <td>26cm</td>
                  <td>28cm</td>
                  <td>30cm</td>
                </tr>
                <tr>
                  <td>65cm</td>
                  <td>9,600</td>
                  <td>8,500</td>
                  <td>7,700</td>
                  <td>7,000</td>
                  <td>6,400</td>
                  <td>5,900</td>
                  <td>5,500</td>
                  <td>5,100</td>
                </tr>
                <tr>
                  <td>70cm</td>
                  <td>8,900</td>
                  <td>7,900</td>
                  <td>7,100</td>
                  <td>6,500</td>
                  <td>6,000</td>
                  <td>5,500</td>
                  <td>5,100</td>
                  <td>4,800</td>
                </tr>
                <tr>
                  <td>75cm</td>
                  <td>8,300</td>
                  <td>7,700</td>
                  <td>6,700</td>
                  <td>6,000</td>
                  <td>5,600</td>
                  <td>5,100</td>
                  <td>4,800</td>
                  <td>4,400</td>
                </tr>
                <tr>
                  <td>80cm</td>
                  <td>7,800</td>
                  <td>6,900</td>
                  <td>6,300</td>
                  <td>5,700</td>
                  <td>5,200</td>
                  <td>4,800</td>
                  <td>4,500</td>
                  <td>4,200</td>
                </tr>
              </table>
              <p>파종량과 채직본수</p>
              <table className="tablestyle">
                <tr>
                  <td rowSpan="2">적정재식 분수</td>
                  <td colSpan="3">파종량</td>
                </tr>
                <tr style={{background: '#273143',color:'#fff'}}>
                  <td>소립종</td>
                  <td>중립종</td>
                  <td>대립종</td>
                </tr>
                <tr>
                  <td>ha당</td>
                  <td>kg</td>
                  <td>kg</td>
                  <td>kg</td>
                </tr>
                <tr>
                  <td>70,000주</td>
                  <td>14 ∼ 18</td>
                  <td>21 ∼ 23</td>
                  <td>30 ∼ 35</td>
                </tr>
                <tr>
                  <td>80,000주</td>
                  <td>16 ∼ 20</td>
                  <td>23 ∼ 27</td>
                  <td>32 ∼ 40</td>
                </tr>
              </table>
              <p>종자크기는 동일 품종이라하더라도 채종년도와 기후에 따라 다르므로 매년 파종전에 확인한다.</p>
            </li>
            <li>수확적기
              <p>청초수량은 유숙기와 호숙기에 최대이지만 건물수량은 호숙기를 지나면서 증가한다. 양질과 고영양 사일리지를 안전하게 조제하려면 예취시기는 건물률 25∼30% 범위에서 수확하는 것이 필요하다. 가시적으로는 황숙기가 된다.</p><br/>
              <p>옥수수 예취시기와 수량성</p>
              <table className="tablestyle">
                <tr>
                  <td>예취시기</td>
                  <td>10a당 청초량 </td>
                  <td>10a당 건물량</td>
                  <td>10a당 TDN</td>
                  <td>1일당 TDN</td>
                  <td>TDN</td>
                  <td>건물률</td>
                </tr>
                <tr>
                  <td>출 수 기</td>
                  <td>5.3t</td>
                  <td>0.9t</td>
                  <td>501kg</td>
                  <td>8.6kg</td>
                  <td>9.4% </td>
                  <td>16.5%</td>
                </tr>
                <tr>
                  <td>유 숙 기</td>
                  <td>6.2</td>
                  <td>1.3</td>
                  <td>694</td>
                  <td>7.8</td>
                  <td>11.2</td>
                  <td>21.0</td>
                </tr>
                <tr>
                  <td>호 숙 기</td>
                  <td>5.5</td>
                  <td>1.4</td>
                  <td>765</td>
                  <td>8.7</td>
                  <td>13.9</td>
                  <td>24.6</td>
                </tr>
                <tr>
                  <td>황 숙 기</td>
                  <td>4.9</td>
                  <td>1.4</td>
                  <td>859</td>
                  <td>7.6</td>
                  <td>17.7</td>
                  <td>27.8</td>
                </tr>
              </table><br/>
              <p>작부체계의 예</p>
              <img src={pic312} className="nonborder" style={{width: '50%'}} alt=""/>
            </li>
            <li>잡초방지
              <ul>
                <li>옥수수를 안전하게 다수확하려면 제초제 산포는 불가결한 작업이다. 재초제 산포는 파종직후 산포가 원칙이다. </li>
                <li>토양면이 凹凸이 있을때는 제초제 효과가 적어짐으로 파종후 로라로 진압을 하여 지표면을 고르게 하여야 한다. </li>
                <li>비가와서 토양이 습할 때 산포할때는 물의 희석 배수를 줄이고 한발시에는 희석하는 물의 양을 증가하여 전면 산포한다. </li>
                <li>제초제는 대상 잡초에 따라 단용하는 것보다 광엽잡초에 적합한 제초제와 화본과 잡초에 적합한 제초제를 혼용하는 것이 효과가 높다.</li>
              </ul><br/>
              <p>교자방법에 따른 종자이용률 비교 </p>
              <ul>
                <li>복교잡 : 가장 정상적인 형태로서 편형 낱알이 대부분을 차지하며 종자 이용률이 매우 높다.</li>
                <li>3계 교잡 : 역시 매우 표준적인 형태로 편형 낱알의 비율이 약간 낮아진 반면 원형비율이 높아지고 불량률은 다소 낮다. </li>
                <li>특수 단교잡 : 복교잡 및 3계교잡에 비하여 뚜렸하게 불량한 모습을 보이고 있다. 원형률이 편형률보다 높아지고 불량률이 높아졌다. </li>
                <li>단교잡A : 이 같은 형태가 종자 생산에 있어 가장 문제가 되고 있으며, 내교잡으로 인하여 편형률이 낮고 따라서 종자 이용률이 저조하다. </li>
                <li>단교잡B : 상기의 단교잡A에 비하여 편형률이 높으나 불량률 또한 높다.
                  <p>양질의 사일리지 조제 방법</p>
                  <img src={pic313} style={{width: '60%'}} alt=""/>
                  <ul>
                    <li>황숙기에 수확한다.</li>
                    <li>황숙기가 영양수량이 최고이다. </li>
                    <li>이때가 사일리지 조제에 적합한 수분이다.</li>
                  </ul>
                </li>
                <li>황숙후기에 수확할 때 수분이 70%정도로 최대 양질의 사일리지를 조제할 수 있다. </li>
                <li>황숙기 이전에 수확할 때는 수분조절제(볏짚, 비트펄프 등)을 첨가하여 수분을 조절한다. </li>
              </ul><br/>
              <p>황숙후기의 눈으로 판단방법</p>
              <ul>
                <li>암이삭 껍데기가 황갈색으로 변하여 있다. </li>
                <li>암이삭 중간 종자를 따면 따낸 자국에 검정이 있다. </li>
                <li>하엽이 4개정도 고사한다. 
                  <ul>
                    <li>2차발효(호기성부패)방지 </li>
                    <li>2차발효가 일어나면 기호성, 영양가가저하되고 부패한다.</li>
                  </ul>
                  <p>&lt;2차 발효가 일어나는 이유&gt;</p>
                  <img src={pic314} className="nonborder" style={{width: '50%'}} alt=""/>
                  <p>2차 발효를 방지하려면 원점으로 돌아가 조제 원칙을 지켜야 한다.</p>
                </li>
              </ul>
            </li>
          </ul><br/>
        </li>
        <li>수단그라스
          <p>수단그라스 잡종 품종의 생육특성(1987 ∼ 88축시 )</p>
          <table className="tablestyle">
            <tr>
              <td rowSpan="2">품 종 명</td>
              <td rowSpan="2">초장 (cm)</td>
              <td rowSpan="2">잎의 크기</td>
              <td rowSpan="2">분얼 경수 (개/m2)</td>
              <td rowSpan="2">줄기의 직경 (mm)</td>
              <td rowSpan="2">잎의 비율 (DM%)</td>
              <td rowSpan="2">즙액 정도 (1-9)★</td>
              <td rowSpan="2">당도(%)</td>
              <td rowSpan="2">재생력 (1-9)★</td>
              <td colSpan="2">출수기(월,일)</td>
            </tr>
            <tr style={{background: '#273143',color:'#fff'}}>
              <td>수원</td>
              <td>광주</td>
            </tr>
            <tr>
              <td>P988</td>
              <td>239</td>
              <td>중</td>
              <td>91</td>
              <td>12.9</td>
              <td>30.6</td>
              <td>4</td>
              <td>3.6</td>
              <td>3</td>
              <td>7.26</td>
              <td>7.16</td>
            </tr>
            <tr>
              <td>에스엑스17(SX-17)</td>
              <td>235</td>
              <td>광</td>
              <td>75</td>
              <td>12.0</td>
              <td>33.6</td>
              <td>4</td>
              <td>1.5</td>
              <td>4</td>
              <td>7.30</td>
              <td>7.19</td>
            </tr>
            <tr>
              <td>엔시855(NC+855)</td>
              <td>248</td>
              <td>중</td>
              <td>127</td>
              <td>10.1</td>
              <td>32.3</td>
              <td>5</td>
              <td>2.4</td>
              <td>1</td>
              <td>7.12</td>
              <td>7.11</td>
            </tr>
            <tr>
              <td>지더불유9110지(GW9110G)</td>
              <td>232</td>
              <td>협</td>
              <td>101</td>
              <td>12.1</td>
              <td>30.0</td>
              <td>5</td>
              <td>1.9</td>
              <td>1</td>
              <td>7.18</td>
              <td>7.19</td>
            </tr>
            <tr>
              <td>G83F(G83F)</td>
              <td>233</td>
              <td>협</td>
              <td>79</td>
              <td>11.1</td>
              <td>25.3</td>
              <td>4</td>
              <td>1.4</td>
              <td>3</td>
              <td>7.23</td>
              <td>7.18</td>
            </tr>
            <tr>
              <td>P855F(P855F)</td>
              <td>231</td>
              <td>광</td>
              <td>87</td>
              <td>12.9</td>
              <td>31.4</td>
              <td>3</td>
              <td>2.0</td>
              <td>1</td>
              <td>7.24</td>
              <td>7.18</td>
            </tr>
            <tr>
              <td>점보(Jumbo)</td>
              <td>251</td>
              <td>중</td>
              <td>80</td>
              <td>13.2</td>
              <td>40.9</td>
              <td>2</td>
              <td>1.8</td>
              <td>3</td>
              <td>출수않음</td>
              <td>출수않음</td>
            </tr>
            <tr>
              <td>스피드피드(Speed feed) </td>
              <td>239</td>
              <td>중</td>
              <td>88</td>
              <td>12.9</td>
              <td>27.4</td>
              <td>-</td>
              <td>-</td>
              <td>4</td>
              <td>7.22</td>
              <td>-</td>
            </tr>
          </table><br/>
          <table className="tablestyle">
            <tr>
              <td rowSpan="2">품종명</td>
              <td colSpan="4">청초수량(kg/10a)</td>
              <td colSpan="4">건물수량(kg/10a)</td>
            </tr>
            <tr style={{background: '#273143',color:'#fff'}}>
              <td>수원</td>
              <td>이리</td>
              <td>광주</td>
              <td>진주</td>
              <td>수원</td>
              <td>이리</td>
              <td>광주</td>
              <td>진주</td>
            </tr>
            <tr>
              <td>P988(P988)</td>
              <td>8,864</td>
              <td>7,634</td>
              <td>14,317</td>
              <td>14,317</td>
              <td>1,600</td>
              <td>1,634</td>
              <td>1,819</td>
              <td>2,667</td>
            </tr>
            <tr>
              <td>에스엑스17(SX-17)</td>
              <td>8,383</td>
              <td>8,175</td>
              <td>14,607</td>
              <td>9,147</td>
              <td>1,414</td>
              <td>1,685</td>
              <td>1,524</td>
              <td>2,705</td>
            </tr>
            <tr>
              <td>엔시855(NC+855)</td>
              <td>6,952</td>
              <td>7,064</td>
              <td>11,800</td>
              <td>6,718</td>
              <td>1,434</td>
              <td>1,544</td>
              <td>1,413</td>
              <td>2,485</td>
            </tr>
            <tr>
              <td>지더블유9110지(GW9110G)</td>
              <td>7,529</td>
              <td>8,106</td>
              <td>12,534</td>
              <td>10,405</td>
              <td>1,389</td>
              <td>1,683</td>
              <td>1,649</td>
              <td>2,649</td>
            </tr>
            <tr>
              <td>G83F(G83F)</td>
              <td>8,126</td>
              <td>6,672</td>
              <td>12,534</td>
              <td>7,623</td>
              <td>1,531</td>
              <td>1,352</td>
              <td>1,450</td>
              <td>2,521</td>
            </tr>
            <tr>
              <td>P855F(P855F)</td>
              <td>9,424</td>
              <td>8,005</td>
              <td>14,427</td>
              <td>8,890</td>
              <td>1,561</td>
              <td>1,567</td>
              <td>1,594</td>
              <td>2,767</td>
            </tr>
            <tr>
              <td>점보(Jumbo)</td>
              <td>9,006</td>
              <td>8,235</td>
              <td>14,373</td>
              <td>10,930</td>
              <td>1,290</td>
              <td>1,540</td>
              <td>1,652</td>
              <td>2,640</td>
            </tr>
          </table>
          <p>* 1 : 많거나 양호 9 : 적거나 불량 ** 스피드 피드는 87년 성적임 </p><br/>
          <p>수단그라스 잡종 품종의 생육특성과 생산성(1979∼81, 서울농대)</p>
          <table className="tablestyle">
            <tr>
              <td rowSpan="2">품종명</td>
              <td rowSpan="2">잎의길이(cm)</td>
              <td rowSpan="2">잎의너비(cm)</td>
              <td rowSpan="2">초장(cm)</td>
              <td>건물수량</td>
              <td>(kg/10a)</td>
            </tr>
            <tr style={{background: '#273143',color:'#fff'}}>
              <td>1979</td>
              <td>1981</td>
            </tr>
            <tr>
              <td>P988</td>
              <td>77</td>
              <td>5.2</td>
              <td>164</td>
              <td>1,213</td>
              <td>1,549</td>
            </tr>
            <tr>
              <td>티-이 헤이그레이져</td>
              <td>75</td>
              <td>4.1</td>
              <td>161</td>
              <td>1,235</td>
              <td>1,393</td>
            </tr>
            <tr>
              <td>티-이 헤이그레이져 알</td>
              <td>70</td>
              <td>3.9</td>
              <td>167</td>
              <td>1,000</td>
              <td>1,352</td>
            </tr>
          </table><br/>
          <p>수단그라스 잡종 품종의 지역적응시험(&apos;87/&apos;88)<br/><br/>생육특성조사</p>
          <table className="tablestyle">
            <tr>
              <td rowSpan="2">품종명</td>
              <td rowSpan="2">엽의 광협</td>
              <td rowSpan="2">재생력(1-9)</td>
              <td colSpan="5">매문병(1-9)</td>
              <td colSpan="3">출수기(월일)</td>
            </tr>
            <tr style={{background: '#273143',color:'#fff'}}>
              <td>수원</td>
              <td>이리</td>
              <td>광주</td>
              <td>진주</td>
              <td>제주</td>
              <td>수원</td>
              <td>광주</td>
              <td>진주</td>
            </tr>
            <tr>
              <td>P988</td>
              <td>중</td>
              <td>3</td>
              <td>5</td>
              <td>3</td>
              <td>9</td>
              <td>5</td>
              <td>6</td>
              <td>7.26</td>
              <td>7.16</td>
              <td>7.12</td>
            </tr>
            <tr>
              <td>SX-17</td>
              <td>광</td>
              <td>4</td>
              <td>3</td>
              <td>3</td>
              <td>9</td>
              <td>4</td>
              <td>5</td>
              <td>7.30</td>
              <td>7.19</td>
              <td>7.15</td>
            </tr>
            <tr>
              <td>TE-Hay grazer Ⅱ</td>
              <td>중</td>
              <td>3</td>
              <td>5</td>
              <td>3</td>
              <td>9</td>
              <td>4</td>
              <td>5</td>
              <td>7.26</td>
              <td>7.18</td>
              <td>7.18</td>
            </tr>
            <tr>
              <td>NC855</td>
              <td>중</td>
              <td>1</td>
              <td>6</td>
              <td>3</td>
              <td>9</td>
              <td>4</td>
              <td>6</td>
              <td>7.12</td>
              <td>7.11</td>
              <td>7.11</td>
            </tr>
            <tr>
              <td>GW9110G</td>
              <td>협</td>
              <td>1</td>
              <td>5</td>
              <td>3</td>
              <td>9</td>
              <td>4</td>
              <td>5</td>
              <td>7.18</td>
              <td>7.19</td>
              <td>7.19</td>
            </tr>
            <tr>
              <td>G83F </td>
              <td>협</td>
              <td>3</td>
              <td>4</td>
              <td>3</td>
              <td>9</td>
              <td>4</td>
              <td>5</td>
              <td>7.23</td>
              <td>7.18</td>
              <td>7.18</td>
            </tr>
            <tr>
              <td>P855F</td>
              <td>광</td>
              <td>1</td>
              <td>3</td>
              <td>1</td>
              <td>5</td>
              <td>4</td>
              <td>4</td>
              <td>7.24</td>
              <td>-</td>
              <td>-</td>
            </tr>
            <tr>
              <td>jumbo</td>
              <td>중</td>
              <td>3</td>
              <td>3</td>
              <td>1</td>
              <td>9</td>
              <td>4</td>
              <td>3</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
            </tr>
          </table><br/>
          <p>수단그라스 교잡종 생산력 검정시험(수원)</p>
          <table className="tablestyle">
            <tr>
              <td>품종</td>
              <td>초장(cm)</td>
              <td>엽폭</td>
              <td>엽비율(%)</td>
              <td>경의직경(mm)</td>
              <td>즙액정도(1-9)</td>
              <td>당도(%)</td>
              <td>출수기(월일)</td>
            </tr>
            <tr>
              <td>P988</td>
              <td>250</td>
              <td>협</td>
              <td>32.1</td>
              <td>9.6</td>
              <td>4</td>
              <td>3.6</td>
              <td>7.15-26</td>
            </tr>
            <tr>
              <td>NC-855</td>
              <td>308</td>
              <td>광</td>
              <td>29.2</td>
              <td>9.7</td>
              <td>5</td>
              <td>2.4</td>
              <td>7.6-12</td>
            </tr>
            <tr>
              <td>T.E.Hay graze Ⅱ</td>
              <td>255</td>
              <td>협</td>
              <td>34.1</td>
              <td>10.6</td>
              <td>3</td>
              <td>2.1</td>
              <td>7.14-26</td>
            </tr>
            <tr>
              <td>GW 9110G</td>
              <td>278</td>
              <td>협</td>
              <td>30.0</td>
              <td>10.5</td>
              <td>5</td>
              <td>1.9</td>
              <td>7.14-18</td>
            </tr>
            <tr>
              <td>G83F</td>
              <td>257</td>
              <td>중</td>
              <td>36.4</td>
              <td>10.8</td>
              <td>4</td>
              <td>1.4</td>
              <td>1.17-23</td>
            </tr>
            <tr>
              <td>P855F</td>
              <td>264</td>
              <td>광</td>
              <td>32.8</td>
              <td>11.3</td>
              <td>3</td>
              <td>2.9</td>
              <td>7.20-24</td>
            </tr>
            <tr>
              <td>SX-17</td>
              <td>269</td>
              <td>광</td>
              <td>29.7</td>
              <td>11.4</td>
              <td>4</td>
              <td>1.5</td>
              <td>7.21-30</td>
            </tr>
            <tr>
              <td>Jumbo</td>
              <td>263</td>
              <td>중</td>
              <td>37.3</td>
              <td>11.1</td>
              <td>2</td>
              <td>1.8</td>
              <td>-</td>
            </tr>
            <tr>
              <td>Speed feed</td>
              <td>-</td>
              <td>-</td>
              <td>27.4</td>
              <td>12.9</td>
              <td>-</td>
              <td>-</td>
              <td>7.16</td>
            </tr>
          </table><br/>
          <img src={pic315} className="nonborder" style={{width: '70%'}} alt=""/>
          <br/><br/><p>청초, 건초, 사일리지용</p>
          <ul>
            <li>연맥의 특성
              <ul>
                <li>생육기간이 짧기 때문에 2기작 재배이용: 생육기간-60∼80 </li>
                <li>건물수량이 많다: 10a당 1.2∼1.3M/T </li>
                <li>가축의 기호성 양호 </li>
                <li>봄 가을 2회 파종이 가능</li>
              </ul>
            </li>
            <li>재배·이용의 주요점
              <ul>
                <li>파종량: 산파 및 조파-파종량 6-8kg/10a </li>
                <li>봄파종-해동과 즉시, 가을파종-옥수수 수확후(내한성이 약하므로 월동이 불가함) </li>
                <li>10a 당 질소 12, 인산 8, 칼리 10kg시용 
                  <p>작부체계와 후작</p>
                  <img src={pic316} style={{width: '50%'}} alt=""/>
                </li>
              </ul>
            </li>
          </ul><br/>
        </li>
        <li>호맥
          <ul>
            <li>특성
              <ul>
                <li> 내동복성에 강함</li>
                <li>줄기가 가늘고 기호성이 좋음 </li>
                <li>초봄생육이 빠르고 단기간내 다수확이 가능 </li>
                <li>내한성, 설부병 저항성이 강함</li>
                <li>청초, 건초, 사일리지등 이용범위가 넓음 </li>
              </ul>
            </li>
            <li>재배·이용기술의 주요점
              <ul>
                <li>파종량: 밭 80∼120kg, 답리작 150∼200kg/ha </li>
                <li>시비량: 요소 100∼150, 용인(용과린)500∼600, 염화칼리 200∼250kg/ha </li>
                <li>청초용: 초장 60∼70cm(출수전), 높이 벨때는 재생 이용도 가능하며 내한성이 강하여 월동전 1회 1용 가능함.</li>
                <li>사일리지용: 출수시∼출수기에 예취하여 예건후 사일리지 조제 </li>
                <li>건초용: 출수시∼출수기에 예취 건조 </li>							
              </ul>
              <p>※ 호맥종자는 보관상태에 따라 발아율이 저하될 수 있으며 바구미 등 해충발생 염려가 있으므로 통풍이 잘되고 건조한 장소에 저장하여야 한다.<br/></p><br/>
              <p>작부체계</p>
              <img src={pic317} className="nonborder" width="50%" alt=""/><br/><br/>
              <p>생육단계별 영양가</p>
              <p>호맥의 출수전이 영양가가 높다.</p>
              <table className="tablestyle">
                <tr>
                  <td>단계별</td>
                  <td>수분</td>
                  <td>조단백질</td>
                  <td>NFE</td>
                  <td>TDN(건물중)</td>
                  <td>DCP(건물중)</td>
                </tr>
                <tr>
                  <td>출수전 </td>
                  <td>87.1</td>
                  <td>3.0</td>
                  <td>4.7</td>
                  <td>72.1</td>
                  <td>17.8</td>
                </tr>
                <tr>
                  <td>출수기</td>
                  <td>85.2</td>
                  <td>2.1</td>
                  <td>6.6</td>
                  <td>68.2</td>
                  <td>9.5</td>
                </tr>
                <tr>
                  <td>개화기</td>
                  <td>75.9</td>
                  <td>11.2</td>
                  <td>11.2</td>
                  <td>60.6</td>
                  <td>5.0</td>
                </tr>
              </table>
            </li>
          </ul>
        </li>
      </ul>
      <h2>초지조성 및 관리</h2>
      <ul>
        <li>우량초지조성 기술의 주요점
          <ul>
            <li>우량초종, 품종의 선택
              <ul>
                <li>재배지역에 적합하고 이용목적에 적합한 초종, 품종을 선택하여 두과율을 20∼30% 혼파율을 유지토록 한다.</li>
              </ul>
            </li>
            <li> 토양개량재의 투입량(kg/10a)
              <ul>
                <li>퇴비량: 4∼6 M/T </li>
                <li>고토석회: 200∼300kg </li>
                <li>용인: 80∼120(최소한 인산흡수계 수의 (5%) </li>									
              </ul>
              <p>* 토양분석을 실시하여 적정량을 시용한다. </p><br/>
              <p>PHI도 상승에 요구하는 석회량(kg/10a)</p>
              <table className="tablestyle">
                <tr>
                  <td rowSpan="2">토성별</td>
                  <td colSpan="3">부식량</td>
                </tr>
                <tr style={{background: '#273143',color:'#fff'}}>
                  <td>소(갈색흙)</td>
                  <td>중(흑갈색흙) </td>
                  <td>대(검정색흙)</td>
                </tr>
                <tr>
                  <td>사토</td>
                  <td>60</td>
                  <td>120</td>
                  <td>160~240</td>
                </tr>
                <tr>
                  <td>양토</td>
                  <td>180</td>
                  <td>240</td>
                  <td>320~400</td>
                </tr>
                <tr>
                  <td>식토</td>
                  <td>300</td>
                  <td>360</td>
                  <td>480~500</td>
                </tr>
              </table><br/>
            </li>
            <li>비료의 시용량
              <p>시비기준(기비) (kg/10a)</p>
              <table className="tablestyle">
                <tr>
                  <td>질소</td>
                  <td>인산</td>
                  <td>가리</td>
                </tr>
                <tr>
                  <td>5∼8</td>
                  <td>20∼30</td>
                  <td>5∼10</td>
                </tr>
              </table><br/>
              <p>토양개량제 시용시기</p>
              <img src={pic318} width="60%" alt=""/>
            </li>
            <li>제초제의 이용 
              <table className="tablestyle16">
                <tr>
                  <td rowSpan="2">초지갱신전</td>
                  <td>전면갱신전</td>
                  <td>근사미</td>
                </tr>
                <tr>
                  <td>간이갱신전</td>
                  <td>그라목손액제</td>
                </tr>
                <tr>
                  <td rowSpan="2">초지조성시</td>
                  <td>일반초지</td>
                  <td>MCPB.그라막손, 근사미</td>
                </tr>
                <tr>
                  <td>알파파 단파초지</td>
                  <td>DMBP</td>
                </tr>
                <tr>
                  <td>유지관리시</td>
                  <td>굼벵이 구제</td>
                  <td>아시란제, 다이야톤입제</td>
                </tr>
              </table>
            </li>
          </ul><br/>
        </li>
        <li>목초재배 이용기술
          <ul>
            <li>노후초지의 적극적 갱신 (다수확 및 생산비저하)<br/>
              <img src={pic319} className="nonborder" alt=""/>
              <p>목초의 파종 후 경과년수<br/>초지의 경과년수와 생산량</p>
              <ul>
                <li>노후초지는 기호성이 불량함→목초채식량 감소로 유량저하
                  <p>우량초지와 노후초지의 채식 영양량 비교(%)</p>
                  <img src={pic320} className="nonborder" width="50%" alt=""/>
                </li>
              </ul>
            </li>
            <li>노후 초지의 갱신 지표
              <ul>
                <li>불량식생(不良植生) (광엽초나 레드톱등 화본과의 관부피도와 나지의 합계치)이 30% 이상일 때 초지 갱신한다.</li>
                <li>불량식생 10% 이하일때와 토양 PH5.5미만인 경우 갱신을 검토한다.(갱신이전에 배수대책이나 기반정비등이 필요하다)</li>
              </ul>
            </li>
          </ul>
        </li>
        <li>이타리안라이그라스의 최적재배ㆍ이용법
          <ul>
            <li>품종의 특성을 활용한 재배가 주요<br/>
              <img src={pic321} className="nonborder" width="50%" alt=""/>
            </li>
            <li>이용목적에 따라 수확, 조제한다. <br/>
              <p>2년후부터의 초지비배관리 주요점</p>
              <img src={pic322} className="nonborder" width="60%" alt=""/>
              <p>주요사항</p>
              <ul>
                <li>입모중파종으로 성력화 및 다수확 재배법
                  <p>수도작등 답리작 재배시 입모중파종시에는 경운을 하지 않고 이타리안라이그라스를 파종함으로 성력적으로 재배할 수 있다. </p>
                  <ul>
                    <li>파종적기는 9월하순∼10월 상순이나 벼베기 15일전에 파종한다. </li>
                    <li>정착본수를 확보하기 위하여 관행 파종량의 2배정도(5kg/10a)로 파종한다. 파종후 긁어 주든가 밟아주면 더욱 효과가 좋다. </li>
                    <li>이타리안라이그라스 발아후 전작물이 재생하여 차광상태가 될 때는 일광부족현상이 나타나므로 이때는 전작물 재생분을 예취를 해주어 생육정착을 양호하게 한다. </li>
                  </ul>
                </li>
                <li>이타리안의 건초조제는 건조시설이 필요<br/>
                  <img src={pic323} className="nonborder" width="50%" alt=""/>
                </li>
                <li>양질의 사일리지 조제기술
                  <table className="tablestyle16">
                    <tr>
                      <td>1번초</td>
                      <td style={{textAlign:'left',paddingLeft:'10px'}}>- 당분함량이 높고 사일리지조제 재료가 우수하다 <br/>- 수분함량이 높음으로 70∼75%로 맞추기 위하여 예건, 수분조절제(볏짚)등을 첨가한다.</td>
                    </tr>
                    <tr>
                      <td>2번초이후</td>
                      <td style={{textAlign:'left',paddingLeft:'10px'}}>- 2번초 이후는 당분함량이 점차 저하되므로 시일이 지날수록 불리하다. <br/>- 5%정도 출수됐을 때 수확 조제한다. 세절, 답압, 조기밀봉이 기본이다.</td>
                    </tr>
                  </table>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
      <h2>목초 및 사료작물의 장려 품종</h2>
      <ul>
        <li>목초종자
          <p>※ #표시를 한 품종이 개인특허 품종임</p>
          <table className="tablestyle">
            <tr>
              <td>초종명</td>
              <td>장려품종명</td>
            </tr>
            <tr>
              <td>오차드그라스<br/>(Orchard grass)</td>
              <td>포토막(Potomac), 프로드(Frode), #프론티어(Frontier), #엠버서더(Ambassador), 합성2호, 홀마크(Hallmark), syn DO.2 (7품종)</td>
            </tr>
            <tr>
              <td>톨페스큐<br/>(Tall Fescue)</td>
              <td>화운(Fawn), 알타(Alta), 에스170(S170), #페스토리나(Festorina), 페로파(Felopa), #에이유-트라이엄프(Au-Triummph) (6품종)</td>
            </tr>
            <tr>
              <td>티모시(Timothy)</td>
              <td>클라이맥스(Climax), 크래어(Clair), 오덴웰더(Odenwalder), 혜일브링크(Heilbrink), 에버리스트위스-에스51(Aberystwyth S-51), 호큐오(Hokuo) (6품종)</td>
            </tr>
            <tr>
              <td>페레니얼라이그라스<br/>(Perenialryegrass)</td>
              <td>노레아(Norlea), #탭토(Taptoe), #리베일(Reveille), #바손(Bastion), 엘레트(Ellett), 바이슨(Bison), 테트레라이트(Tetrelite) (7품종)</td>
            </tr>
            <tr>
              <td>켄터키부루그라스<br/>(Kentucky bluegrass)</td>
              <td>켄브루(Kenblue), 아키라(Aquila), 모노폴리(Monopoly) (3품종)</td>
            </tr>
            <tr>
              <td>레드톱(Red top)</td>
              <td>스트리커(Streaker) (1품종)</td>
            </tr>
            <tr>
              <td>레드카나리그라스<br/>(Reed canary grass)</td>
              <td>프론티어(Frontier), 벤취어(Venture), 밴타지(Vantage) (3품종)</td>
            </tr>
            <tr>
              <td>알파파(Alfalfa)</td>
              <td>페이서(Pacer), 스카웃(Scout), 팀(Team), 루나(Luna), 버닐(Vernal), #5444, 드럼머(Drummor) (7품종)</td>
            </tr>
            <tr>
              <td>화이트클로버<br/>(White colver)</td>
              <td>캘리포니아(California), 레갈(Regal) (2품종)</td>
            </tr>
            <tr>
              <td>레드클로버<br/>(Red clover)</td>
              <td>캔랜드(Kenland), 티투스(Titus), 아틀라스(Atlas) (3품종)</td>
            </tr>
            <tr>
              <td>버즈풋트레포일<br/>(Birdsfoot terfoil)</td>
              <td>엠파이어(Empire), 바이킹(Viking) (2품종)</td>
            </tr>
            <tr>
              <td>데도우페스큐<br/>(Deadow fescue)</td>
              <td>조마(Joma) (1품종)</td>
            </tr>
            <tr>
              <td>브롬그라스<br/>(Brome grass)</td>
              <td>레가(Regar) (1품종)</td>
            </tr>
            <tr>
              <td></td>
              <td>(총49개)</td>
            </tr>
          </table><br/>
        </li>
        <li>사료작물종자
          <table className="tablestyle">
            <tr>
              <td>작물명</td>
              <td>장려품종명</td>
            </tr>
            <tr>
              <td>옥수수(Corn)</td>
              <td>국산종: 수원 19, 횡성옥, 진주옥, 광안옥, 수원99호 <br/>외국종: #파이오니아 3160(P-3160), #디케이-729(DK729), #G4743P-3352, #디케이-689(DK689), #엑스엘-394(XL-394), #파이오니아3282(P-3282) (12품종)</td>
            </tr>
            <tr>
              <td>수단그라스 교잡종<br/>(Sorghum×Sudan Hybrid)</td>
              <td>#파이오니아989(P-988), #지83에프(G83F), #점보(Jumbo), #티이헤이그레이져(T.E-haygrazer), #P855F, #소르단79(Sordan79), #티이헤이그레이져알(T.E-haygrazer R), #지디불유9110지(Q9110G), #엔시855(NC855), #에스엑스17(SX-17), #스피드휘드(Speed Feed) (11품종)</td>
            </tr>
            
            <tr>
              <td>수수 교잡종(Sorglum×Sorghun Hybrid)</td>
              <td>#파이오니아-931(P-931), #파이오니아947(P-947), #파이오니아956(P-956), #엔케이-367(NK-367), #사일로마일로(Silomilo), #티이사일로메이커(T.E-silomaker) (6품종)</td>
            </tr>
            <tr>
              <td>호맥(Winter Rye)</td>
              <td>국산종: 팔당, 신기, 금산재래, 진안재래<br/>외국종: #쿨크레이져(Kool grazer), 엘본(Elbon), 바이타-그레이즈(Vita-graze), 본낼(Bonel), 아댄스애부루지(Athens abruzzi), 마톤(Maton), 랜스애부루지(Wrens 뮤?캬), #코디악(Kodiak), #위인턴모아(Wintermore), #단조(Danko), #춘추호맥 (15품종)</td>
            </tr>
            <tr>
              <td>연맥(Oat)</td>
              <td>카이유스(Cayuse), #매그님(Magnum), #푸트힐(Poothill), #삼절귀리, #메커리 (5품종)</td>
            </tr>
            <tr>
              <td>사료용 유채(Forage Rapes)</td>
              <td>#아케라(Akela), #베록스(Velox), #라몬(Ramon), #스파르타(Sparta), #바부(Bar Bn7137) (3품종)</td>
            </tr>
            <tr>
              <td>이타리안라이그라스(Italian ryegrass)</td>
              <td>다리타(Dalita), #테트론(Tetrone), #바물트라(Barmultra), 테트라프로럼(Tetraflorum), #베티나(Bettina), #웬케(Wencke), 골도(Gordo), 씨갬(Sikem), #발티시모(Bartissimo), 윌로(Wilo) (10품종)</td>
            </tr>
            <tr>
              <td></td>
              <td>(총62개)</td>
            </tr>
          </table>
        </li>
      </ul>
    </div>
  );
}

export default Do180;