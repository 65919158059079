import React from 'react';

// 컴포넌트

// scss
import './webpage_list.scss';

// 이미지

import imgP1 from 'assets/images/containers/data/relatedSites/processing/imgSite04_1.jpg';
import imgP3 from 'assets/images/containers/data/relatedSites/processing/imgSite04_3.jpg';
// import imgP4 from 'assets/images/containers/data/relatedSites/processing/imgSite04_4.jpg';
// import imgP7 from 'assets/images/containers/data/relatedSites/processing/imgSite04_7.jpg';
// import imgNO from 'assets/images/containers/data/relatedSites/imgSite_no.gif';

function Processing() {
  return (
    <div className="Processing">
      <div className="webpage">
        <dl className="webpage_list">
          <dd>
            <a
              href="https://www.seoulmilk.co.kr/enterprise/main.sm"
              target="_blank"
              rel="noreferrer"
            >
              <div className="webpage_banner">
                <img src={imgP3} alt="서울우유" />
              </div>
              <p>서울우유</p>
            </a>
          </dd>
          <dd>
            <a
              href="https://www.busanmilk.com/html/00_main/"
              target="_blank"
              rel="noreferrer"
            >
              <div className="webpage_banner">
                <img src={imgP1} alt="부산우유" />
              </div>
              <p>부산우유</p>
            </a>
          </dd>

          {/* <dd>
                    <div className="webpage_banner">
                        <img src={imgNO} alt="Milk Rite Inc."/>
                    </div>
                    <p>Milk Rite Inc.</p>                    
                </dd>
               
                <dd>
                    <div className="webpage_banner">
                        <img src={imgP4} alt="Performance Products"/>
                    </div>
                    <p>Performance Products</p>                    
                </dd>
                <dd>
                    <div className="webpage_banner">
                        <img src={imgNO} alt="American Protein - calf nutrition"/>
                    </div>
                    <p>American Protein - calf nutrition</p> 
                </dd>
                <dd>
                      <div className="webpage_banner">
                        <img src={imgNO} alt="SoyPLUS"/>
                    </div>
                    <p>SoyPLUS</p>
                </dd>
                <dd>
                  <a href="https://www.bonniemohr.com/" target="_blank" rel="noreferrer"> 
                    <div className="webpage_banner">
                        <img src={imgP7} alt="Bonnie Mohr Studios"/>
                    </div>
                    <p>Bonnie Mohr Studios</p>                    
                  </a>
                </dd> */}
        </dl>
      </div>
    </div>
  );
}

export default Processing;
