import React from 'react';

import Pic560 from 'assets/images/sub/_images/pic560.jpg';
import Pic561 from 'assets/images/sub/_images/pic561.jpg';
import Pic562 from 'assets/images/sub/_images/pic562.jpg';

function Do103() {

  return (
    <div className="techinfoview">
      <h1>폐염</h1>
      <p className="pstyle">현저한 호흡장해를 일으키는 폐의 염증성 질환으로 특히 송아지에서 많이 볼 수 있고 큰 피해를 준다. </p>
      <div className="leftpart">
        <h2>원인</h2>
        <ul>
          <li>바이러스, 세균, 곰팡이 등에 의한 기도감염 </li>
          <li>화농병소(유방염, 자궁내막염 등)에서 병원균이 혈액을 통해 폐에 도달해 염증을 일으키는 화농성 폐염 </li>
          <li>약물을 강제로 먹이거나 출생시 양수를 잘못 삼킨 경우 이것이 기관으로 들어가서 이물성(異物性)폐염을 일으킨다. </li>
        </ul>
        <h2>증상</h2>
        <ul>
          <li>초기에는 원기와 식욕이 없고 열이나며 호흡이 빠르다. </li>
          <li>청진하면 폐포음이 거칠게 들리면, </li>
          <li>농(膿)과 같은 콧물을 배출한다.</li>
          <li>증상이 더욱 심해지면 입을 벌리고 복식호흡을 하며 호흡곤란 상태에 이른다. </li>
        </ul>
        <h2>목장에서의 처치</h2>
        <ul>
          <li>축사내 온도, 습도, 환기에 유의하며 병우를 안정시킨다. </li>
          <li>감기 등 가벼운 호흡기 질환이 발생한 경우에는 병든 소를 격리하고 폐염으로 악화되지 않도록 조기에 철저히 조치하는 것이 좋다. </li>
          <li>허약한 소에 약물을 먹일때는 무리하지 말고 머리를 수평으로 유지한 채 소가 스스로 삼킬 수 있도록 천천히 먹인다. 또는 고무관을 식도로 집어넣어 이것을 통해 약물을 먹인다. </li>
          <li>항생물질을 전신 투여하고 소염제를 병용하면 효과가 좋다.</li>
        </ul>
      </div>
      <div className="rightpart">
        <img src={Pic560} className="nonborder" alt=""/>
        <img src={Pic561} className="nonborder" alt=""/>
        <img src={Pic562} className="nonborder" alt=""/>
      </div>
    </div>
  );
}

export default Do103;