import React from 'react';

import d3by43 from 'assets/images/sub/_images/3-43.jpg';
import d3by45 from 'assets/images/sub/_images/3-45.jpg';
import d3by47 from 'assets/images/sub/_images/3-47.jpg';
import d3by49 from 'assets/images/sub/_images/3-49.jpg';
import d3by51 from 'assets/images/sub/_images/3-51.jpg';
import d3by53 from 'assets/images/sub/_images/3-53.jpg';
import d3by55 from 'assets/images/sub/_images/3-55.jpg';
import d3by59 from 'assets/images/sub/_images/3-59.jpg';
import d3by61by1 from 'assets/images/sub/_images/3-61-1.jpg';
import d3by61by2 from 'assets/images/sub/_images/3-61-2.jpg';
import d3by63 from 'assets/images/sub/_images/3-63.jpg';
import d3by65by1 from 'assets/images/sub/_images/3-65-1.jpg';
import d3by65by2 from 'assets/images/sub/_images/3-65-2.jpg';
import d3by65by3 from 'assets/images/sub/_images/3-65-3.jpg';

function Fo60() {

  return (
    <div className="techinfo_view">
				<p className="viewTitle">육성우사의 환경</p>
				<h2>따로 사육하는가, 같이 사육하는가? </h2>
				<ul className="listStyle04 mg15f">
					<li>포육수, 육성우와 성우는 따로 따로 사육한다.</li>
					<li>포육우는 질병에 대한 면역성이 특히 작아 성우가 가진 질병균에 감염되기 쉽다. </li>
					<li>발육 단계에 의해 사료, 관리 작업이 다르다. </li>
					<li>신, 개축일 때는 사육하는 방법의 방침을 결정할 기회. </li>
				</ul>
				<div className="imgBox mg60f"><img src={d3by43} alt="따로 사육하기 위한 시설의 예" /></div>
				
				<h2>자우를 우사의 어디에서 기르는가? </h2>
				<ul className="listStyle04 mg15f">
					<li>공기 환경의 면에서는 자우는 성우와 다른 우사에서 기르는 것이 바람직하다. </li>
					<li>동일 우사에서 사육할 때는 될 수 있으면 성우와의 상이에 간막이를 설치한다.  </li>
					<li>최저한 자우에게는 공기의 흐름을 상류로 햇빛이 좋은 장소를 확보한다. </li>
				</ul>
				<div className="imgBox mg60f"><img src={d3by45} alt="될 수 있으면 칸막이를. 공기의 흐름을 상류측으로 햇빛이 비치는 장소."/></div>
				
				<h2>자우를 길러서는 좋지 않은 장소.</h2>
				<ul className="listStyle04 mg15f">
					<li>Barn cleaner(분뇨 자동 반출기) 출구부근등 외풍이 들어오는 곳. </li>
					<li>성우에 의해 오염된 공기가 통하는 배기용 환풍기옆. </li>
					<li>채광이 나쁘고 햇빛이 들지 않는 곳.</li>
					<li>하리, 폐염의 원인이 된다. 질척거리는 바닥. </li>
				</ul>
				<div className="imgBox mg60f"><img src={d3by47} alt=""/></div>
				
				<h2>포육용 Pen(우방)의 요점.</h2>
				<ul className="listStyle04 mg15f">
					<li>Pen(우방)은 단사용으로 한다. </li>
					<li>외풍과 자우사이의 접촉감염의 방지를 위해 Pen(우방)의 칸막이는 목책이 아니라 판자로 한다.</li>
					<li>청소, 세정하기 쉬운 구조로 한다.</li>
					<li>새로운 자우를 들이기전에 세정하고 적어도 1주간 건조하여 사용한다.</li>
				</ul>
				<div className="imgBox mg60f"><img src={d3by49} alt="단사포육 Barn"/></div>
				
				<h2>분만용 Pen(우방)의 요점. </h2>
				<ul className="listStyle04 mg15f">
					<li>출생직후 자우를 다른 우군의 감염으로부터 지키기 위해 Veneer(베니어)등으로 벽을 설치하여 격리한다.</li>
					<li>성우 25두 : 1 공간의 비율로 설치, 크기는 3.6 × 3.6m정도. </li>
					<li>강제 환기우사로는 환기계도 따로 한다. 따로 할 수 없는 경우 공기의 흐름을 상류로 설치한다. </li>
					<li>부료를 충분히 넣는다. </li>
					<li>이와 같은 요점으로 부터도 다른 용도의 Pen(우방)과의 겸용은 좋지 않음.</li>
				</ul>
				<div className="imgBox mg60f"><img src={d3by51} alt="운반용 우리은 전용으로 준비한다. "/></div>
				
				<h2>왜 환기가 필요한가? </h2>
				<ul className="listStyle04 mg15f">
					<li>수증기, 먼지, 병원균등을 우사밖으로 나가게 한다.</li>
					<li>우사내의 공기의 질을 양호하게 보존한다. </li>
					<li>우사내의 온도의 상승을 억제한다. </li>
					<li>서열기(暑熱期)에는 우사내의 온도의 상승을 억제한다. </li>
				</ul>
				<div className="imgBox mg60f"><img src={d3by53} alt=""/></div>
				
				<h2>환기부족으로 인한 폐해(弊害)는?</h2>
				<ul className="listStyle04 mg15f">
					<li>질병의 발생을 조장한다. </li>
					<li>결로(結露), 결상(結霜)이 발생, 육성우의 건강에 악영향을 미치고 우사의 내용(內容)변수(기계가 사용에 견디는 것)도 줄인다.</li>
				</ul>
				<div className="imgBox"><img src={d3by55} alt="환기부족으로 인한 폐해(弊害)의 실험예. "/></div>
				<p className="mg60f">1주령의 소 80두를 일반농가로부터 모아 20두씩 4무리로 나누어 수용하여 매일 호흡기장해 지표를 조사했다. 4그룹을 2그룹씩으로 나누어 환기회수를 4회/시 와 1회/시로 하여 함께 연속 환기로 했다. 다른 조건은 동일. 건강상태에 따라 치료를 행했다. 1두당 치료비는 4회/시 구역은 600엔, 1회/시구는 1000엔이 였다. 실험종료후 각각 12두씩 추출하여 해부했다. 폐의 검사에 의해 4회/시구에서는 세균성 폐염의 진행이 저지되고 있었다. 또 호흡기병의 이병율(罹病率)이 실험 개시때에 절정을 나타내지만 그 후 조금 급감하고 종료시때에는 0%였다. 1회/시구에서는 호흡기병이 이병율은 계속 증가하여 실험개시후 6주간에 거의 100%에 달했다. </p>
				
				
				<h2>육성우사의 필요 환기량. </h2>
				<ul className="listStyle04 mg15f">
					<li>발육 단계, 계절에 의해 1두당 필요량은 다르다. </li>
					<li>간이(簡易)한 목표량은 한냉기에서는 1시간에 4회, 서열기에서는 1시간에 40∼60회 우사내의 공기가 모두 교체하는 양. </li>
				</ul>
				<div className="tableWrap">
					<table className="tablestyle">
						<caption>발육 단계, 계절마다 필요 환기량.</caption>
						<thead>
							<tr>
								<th className="first" rowSpan="2"> </th>
								<th colSpan="3">환기량(換氣量)</th>
							</tr>
							<tr>
								<th>한냉(寒冷) 기후</th>
								<th>온난(溫暖) 기후</th>
								<th>서열(暑熱) 기후</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>폭육우 (0∼2개월령)</td>
								<td>0.405</td>
								<td>+0.945=1.35</td>
								<td>+1.35=2.70</td>
							</tr>
							<tr>
								<td>육성우 (2∼12개월령)</td>
								<td>0.540</td>
								<td>+1.08=1.62</td>
								<td>+1.89=3.51</td>
							</tr>
							<tr>
								<td>(12∼24개월령)</td>
								<td>0.810 </td>
								<td>+1.35=2.16</td>
								<td>+2.70=4.86</td>
							</tr>
							<tr>
								<td>성우 590kg</td>
								<td>1.35</td>
								<td>+3.24=4.59</td>
								<td>+8.10=12.7</td>
							</tr>
						</tbody>
					</table>
				</div>
				<p className="mg60f">환기 System의 Size는 축사용적을 기초로 계산한다. 표의 값을 가산식으로 되어 있고 예를 들면 온난 기후의 1두당 환기량은 0.405 + 0.945 = 1.35㎥/분이 된다. (MWPS - 7) </p>
				
				<h2>자연환기 방식의 환기량.</h2>
				<ul className="listStyle04 mg15f">
					<li>자연 환기는 바람 힘과 우사내외의 온도차에 의해 행해진다. </li>
					<li>무풍시 이외는 바람 힘의 영향쪽이 크다. </li>
					<li>흡입구, 배기구의 크기가 일정하면 풍속에 대부분 비례하여 환기량은 커진다.</li>
				</ul>
				<div className="imgBox"><img src={d3by59}alt="" /></div>
				<p className="mg60f">배기구로 처마밑 입기의 자연 환기우사의 환기량을 열의 수입과 지출로부터 추정된 결과 온난기는 한냉기보다도 처미밑 입기구가 크게 열려 있다. </p>
			
				<h2>입기구, 배기구란?</h2>
				<ul className="listStyle04 mg15f">
					<li>입기구는 바깥부터 공기가 들어오는 개구부로 창이 그 역할을 다하거나 처마밑에 특별히 설치하기도 한다. </li>
					<li>배기구는 우사내의 공기를 바깥으로 나가게 하는 개구부로 Open ridge, Moniter등이 있다. 양측벽의 창을 열린 경우에는 강제환기 방식에는 환풍기가 배기구. </li>
					<li>입기구, 배기구 양방이 어울려 비로소 처음으로 효과적인 환기가 행해진다. 한쪽만으로는 좋지 않음. </li>
				</ul>
				<div className="imgBox mg60f"><img src={d3by61by1} alt="처마밑의 개폐 가능한 입기구"/> <img src={d3by61by2} alt="Open ridge(배기구)" className="mg20l"/></div>
			
				<h2>Open ridge 항상 유효한가? </h2>
				<ul className="listStyle04 mg15f">
					<li>측벽의 면적이 1/2이상이 개방되고 있는 경우에는 우사를 가로지는 공기의 움직임이 중심이 되어 Open ridge의 효과는 별로 크지 않다. </li>
					<li>측벽의 개방면적이 적을 때에는 항상 유효하다. </li>
					<li>창을 그다지 열지 않는 동기간조차 효과는 크다. 그러므로 겨울은 Open ridge를 막아서는 안된다.</li>
				</ul>
				<div className="imgBox mg60f"><img src={d3by63} alt="입기구가 작을 때 만큼 Open ridge의 효과는 크다. (MWPS - 7, 일부개변)"/></div>
				
				<h2>Open ridge의 폭과 비를 막는 덮개.</h2>
				<ul className="listStyle04 mg15f">
					<li>열린 폭은 우사의 횡폭 3m에 따라 5cm. 가옥등의 정면의 폭이 12cm의 우사에서는 20cm.</li>
					<li>비를 막는 덮개의 방법은 용마루부분에 지면에 우뚝 솟아 있는 것을 붙이던가 내부에 물받이를 설치한다. Open ridge의 위에 덮개를 붙이는 것은 바람직하지 않다.</li>
				</ul>
				<div className="imgBox"><img src={d3by65by1} alt="용마루부분의 우뚝 솟아 있는것"/> <img src={d3by65by2} alt="골함석 또는 함석판 건물의 끝 또는 통로로 향햐 경사" className="mg20l"/></div>
				<div className="imgBox mg60f"><img src={d3by65by3} alt="내부에 설치된 비받이"/></div>
			
			</div>
  );
}

export default Fo60;
