import React from 'react';
import Web from 'containers/web/Web';

function WebPage() {
  return (
    <Web />    
  );
}

export default WebPage;
