import React from 'react';

import pic778 from 'assets/images/sub/_images/pic778.jpg';
import pic779 from 'assets/images/sub/_images/pic779.jpg';
import pic780 from 'assets/images/sub/_images/pic780.jpg';
import pic781 from 'assets/images/sub/_images/pic781.jpg';

function Do171() {

  return (
    <div className="techinfoview">
      <h1>황달</h1>
      <p className="pstyle">황달이라 함은 혈액중에 담즙색소 (Birilubin)가 정상치 이상으로 증가하여 이로 인해 점막과 피부가 누렇게 물든 상태를 a라하며 여러 가지 질환 특히 간(肝), 담도(膽道)의 질환에 수반하는 증상이다.</p>
      <div className="leftpart">
        <h2>원인</h2>
        <ul>
          <li>위장염, 식중염, 전염병, 간질충 등에 의해 간장과 담도의 기능장애가 오고 그로인해 황달이 유발된다.</li>
        </ul>
        <h2>증상</h2>
        <ul>
          <li>결막, 피부, 혈관, 구강 및 점막이 황색을 나타낸다.</li>
          <li>뇨(尿)와 유즙(乳汁)의 색도 짙은 황색으로 바뀌어 간다.</li>
          <li>간장에서 소화관으로 분비되는 담즙색소(Birilubin)가 혈관으로 들어감으로써 상대적으로 위내의 소화액 부족으로 인해 소화불량과 그로 인한 설사가 일어나고 점차 야윈다.</li>
          <li>증상이 계속되면 의식장애와 발열이 나타난다.(심장압박을 줄이기 위해)</li>
        </ul>
        <h2>목장에서의 처치</h2>
        <ul>
          <li>특별히 가능한 방법은 없으며, 조기발견하여 도태해야 한다.</li>
          <li>증체율이 나쁘면서 청색증과 호흡곤란을 보일때는 즉시 수의사의 진찰을 받도록 한다. </li>
        </ul>
      </div>
      <div className="rightpart">
        <img src={pic778} className="nonborder" alt=""/>
        <img src={pic779} className="nonborder" alt=""/>
        <img src={pic780} className="nonborder" alt=""/>
        <img src={pic781} className="nonborder" alt=""/>
      </div>
    </div>
  );
}

export default Do171;