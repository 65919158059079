import React from 'react';

import Figure1by2 from 'assets/images/sub/_images/figure_1_2.gif';
import Figure1by3 from 'assets/images/sub/_images/figure_1_3.gif';
import Figure1by4 from 'assets/images/sub/_images/figure_1_4.gif';
import Figure1by5 from 'assets/images/sub/_images/figure_1_5.gif';
import Figure1by6 from 'assets/images/sub/_images/figure_1_6.gif';


function Fo8() {

  return (
    <div className="techinfo_view">
				<p className="viewTitle">소의 번식기관</p>
				<div>정상적인 주기로 송아지의 생산을 이루기 위하여 소의 번식기관은 아래의 다섯 단계를 성공적으로 거쳐야 한다.
					<ol>
						<li>1. 유용한 난자의 생산하여야 한다. </li>
						<li>2. 수정을 위한 장소로 운반되어야 한다(난관의 기능) </li>
						<li>3. 수정과 수정후 수정란의 발달, 수정란의 자궁에의 착상, 태아로의 발육에 적절한 환경을 제공하여하한다. </li>
						<li>4. 분만시에 살아있고, 건강한 송아지를분만하여야 한다. </li>
						<li>5. 후산의 배출과 분만시 커졌던 산도의 크기가 정상적인 크기로 되돌아 와야 하며, 앞의 과정이 다시 반복되어야 한다.</li>
					</ol>
				</div>
				
				<div>
					소의 번식기관은 소화기관의 마지막 기관인 직장의 아래에 위치한다. 번식기관의 대부분은 직장 촉지법에 의해 간접적으로 촉지가 가능하다.
					<ul className="listStyle04">
						<li>자궁경관은 인공수정작업중에 조작 가능함</li>
						<li>난소의 구조를 확인가능 </li>
						<li>자궁을 촉지하여 임신감정가능 </li>
					</ul>		
				</div>
				<p className="mg30f">자궁, 난관 그리고 난소는 복강속에서 자궁광 인대에 매달려 있다. 이러한 구조는 임신시 자궁안에서 태아가 성장하는데 적절한 구조이다. </p>		
				
				<dl>
					<dt>외음부</dt>
					<dd>외음부는 소의 외부에서 관찰이 가능하며 번식기관의 가장후부에 위치한 기관이다. 이 외음부는 발정기동안 호르몬의 반응중 외음부는 에스트로젠에 반응하여 붉그스름한 분홍색을 띄게된다. </dd>	
				</dl>
				<dl>
					<dt>질</dt>
					<dd>
						<p>질은 약 30cm에 달하는 평평한 관이다. 골반강 밑쪽에 형성된 치골위에 위치한다. </p>
						<p>자연교미시에 정액을 사출하는 위치이다. 질은 인공수정시 인공수정기의 통로가 되며, 분만시 산도가 되는 통로이다. 질의 상피세포는 점액을 분비하는데, 분비되는 점액은 깨끗한 수분이 많이 함유된 물질로써 질의 내부를 미끄럽게 하며 전염성 세균 등을 포함하는 외부물질을 밖으로 반출시키는 역할을 하여 염증 및 전염성 병을 방어한다. 이러한 방어에도 불구하고 발정기간과 분만직후에는 질의 오염이 잘되므로 유의해야 한다.</p>
					</dd>	
				</dl>
				<dl>
					<dt>자궁경관</dt>
					<dd>
						<div className="imgBox"><img src={Figure1by2} alt=""/></div>
						<p>자궁경관은 자궁으로의 출입을 통제하는 기관으로 근육층과 환상형의 결합조직으로 구성되어 있다. 길이는 10-13cm, 직경은 2.5-5cm이다. 자궁경관의 내벽에는 융기가 돌출되어 있는데 이 관의 내부강은 매우 굴곡된 추벽의 형태를 나타내며, 이 좁은 내부강에 의하여 자궁에 연결되어 있다. 이러한 추벽은 외부에서 침입하는 물질의 진행을 차단하는 기능을 수행하여 질과 자궁의 사이에 위치하여 외부물질을 통제하는 문의 역할을 수행하고 있다. 하지만 발정기간에 조금 자궁경관이 열리며, 분만시에는 자궁경관이 많이 이완되어 산도로써 기능을 수행한다.</p>
						<p>임신기간에는 다른 기간에 비하여 더욱 완벽하게 자궁경관이 닫혀져 있다. </p>
						<p>자궁경관은 질쪽의 방향으로 점액을 분비한다. 임신기간에는 자궁경관의 분비물은 딱딱해져 는 교화마개를 생성시켜 자궁이 외부의 영향으로부터 차단되도록 자궁경관을 완전히 닫는다. </p>
						<p>자궁경관의 추벽과 융선은 직장검사에 의해 촉지가 가능하다.</p>						
					</dd>	
				</dl>
				<dl>
					<dt>자궁</dt>
					<dd>
						<div className="imgBox"><img src={Figure1by3} alt=""/></div>
						<p>자궁은 번식기관중 태반이 형성되는 중요한 기관으로 임신이 되지 않은 소에서는 자궁체는 5cm미만이다. 그리고 좌·우측 자궁각은 숫양의 뿔처럼 양쪽으로 구부러져 있으며, 자궁각은 자궁체의 길이보다 길며, 점점 좁아져 난관과 연결되어 있다.(그림 1.2)</p>
						<p>자궁은 임신중 성장하는 태아를 수용할수 있도록 거대하게 팽창할수 있는 근육으로 구성되어 있다. 임신말기에 태아의 몸무게는 35-45kg 이며, 양수의 무게는 20-30kg 그리고 5kg의 태반을 모두 자궁이 지탱하고 있다. 등뼈에 연결된 자궁광 인대에 의해 자궁복강안에서 태반은 지탱되어 진다.</p> 						
						<p>자궁을 둘러싸고 있는 근육은 평활근이다. 옥시토신에 의하여 자궁의 수축운동이 조절되어 분만시에 태아를 밖으로 밀어내는 작용을 한다. </p>				
						<p>분만후에 자궁은 다시 수축하여 임신하기 전의 상태로 돌아가는데 대략 40일 정도 걸린다. </p>						
						<p>이 현상을 자궁 퇴축이라고 한다. </p>
					</dd>	
				</dl>
				<dl>
					<dt>난관</dt>
					<dd>
						<p>난관은 난소와 자궁각을 연결하는 관상기관이다. 난관의 길이는 약 20cm 이며, 직경은 0.6cm으로 작은 관이다. </p>
						<p>자궁과 난관의 연접부는 수정에 의하여 정자가 난관으로 이동할때와 수정란이 자궁으로 이동하는 짧은 시간 외에는 거의 강하게 닫혀져있다.</p> 
						<p>난관의 다른끝은 난소에 연결되어 있는 아니고, 난관 누두부라고 불리우는 난소를 덮을수 있는 깔때기 모양의 구조를 가지고 있다. 배란시에 난소를 난관누두부가 둘러싸고 있어 배란된 난자를 난관으로 받아들이느 역할을 한다. 난관의 섬모들은 난자를 난관내에서 이동하도록 도와 난관의 윗부분에서 정자를 만나 수정이 이루어지게 된다. </p>
						<p>일반적으로 하나의 정자가 난자에 침투하면 정자와 난자의 유전물질의 융합이 이루어져 하나의 세포운 개체로 발전하는데 이과정을 수정이라고 하며 배란후 11시간 경에 일어난다.</p>
					</dd>	
				</dl>
				<dl>
					<dt>난소</dt>
					<dd className="aftercr">
						<div className="imgBox"><img src={Figure1by4} alt=""/></div>
						
						<div className="fl w70p">
							<p>임신이 안된 소에서 난소는 타원형이며 길이는 4-6cm이고, 직경은 2-4cm 이다. 이처럼 비교적 크기가 작지만 수천개의 난자를 포함하고 있다(그림 1.4) 이러한 난소는 분만전의 태아시절에 생성되며, 난자도 번식수명동안 단지 작은 숫자만이 배란된다. </p>
			
							난소의 중요한 기능은 아래와 같다. 
							<ol className="mg20f">
								<li>1. 정상적인 배란주기의 21일째에 성숙된 난자를 배란한다. </li>
								<li>2. 호르몬을 분비하여
									<ul className="listStyle04">
										<li>난소내의 난자의 성장을 조절한다.</li>
										<li>발정기간동안 소의 행동을 변화시킨다.</li>
										<li>임신이 가능하도록 번식기관을 준비한다.</li>
									</ul>
								</li>
							</ol>
							
							난소에서의 호르몬의 생산은 여러면에서 중요하다. 
							<ul className="listStyle04">
								<li>임신을 위한 번식기관의 준비</li>
								<li>발정주기를 중단</li>
								<li>수정이 일어난 뒤 임신을 지속 유지시킨다. </li>
								<li>분만시 태아를 산도를 따라 분만할수 있도록 한다.</li>
							</ul>
						</div>
					</dd>
					<dd>	
						<p>발정주기의 후반부에는 난소의 난자는 성숙되어 있으며, 수없이 많은 작은세포로 둘러싸여져 있으며, 또한 영양물질로 둘러싸여져 있는데 이구조를 난포라고 한다(그림 1.5)</p> 
						<p>배란이되면 난소는 난소에서 난관으로 이동하는데 배란된후 난포주위의 세포들은 빠르게 노란색을 띄는 황체를 형성한다. 그리고 황체는 프로제스테론을 분비한다. </p>
						<p>황체의 형성은 교미와 수정의 여부에 상관없이 배란 후에 생성된다.</p>
					</dd>
				</dl>
				
				<dl>
					<dt>난자</dt>
					<dd>
						<p>난자는 암컷 생식세포이다. 일반적으로 동물의 체세포는 유전정보를 지닌 염색체를 2배체를 가지고 있는데 난자는 1배체의 염색체를 가지고 있다. </p>
						<p>난자는 분만전의 태아에서도 발견되지만, 성숙된 난자는 성성숙후에 발견할수 있다. </p>
						<p>발정주기동안 많은 난자를 포함하고 있는 난포가 성장을 하는데 그중 단지 하나의 난포만이 완전한 성숙을 이루며 배란된다(그림 1.5, 1.6). </p>
						<div className="imgBox"><img src={Figure1by5} alt=""/></div> <div className="imgBox mg20l"><img src={Figure1by6} alt=""/></div>
					</dd>
				</dl>
			</div>
  );
}

export default Fo8;
