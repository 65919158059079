import React from 'react';

import Pic563 from 'assets/images/sub/_images/pic563.jpg';
import Pic564 from 'assets/images/sub/_images/pic564.jpg';
import Pic565 from 'assets/images/sub/_images/pic565.jpg';

function Do104() {

  return (
    <div className="techinfoview">
				<h1>폐충증</h1>
				<div className="leftpart">
					<h2>원인</h2>
					<ul>
						<li>우폐충 이라는 선충(腺蟲)이 소의 기관지내에 기생하여 일어나는 기생충 질병으로 젖소에서 그 피해가 크다. 
							<ul>
								<li>성충은 기관지내에서 다량 산란(産卵)하고, 이 충란은 기침을 통해 입으로 나와 다시 소화관내로 이동하여 분변으로 배설된다.</li>
								<li>송아지(4∼10개월령)가 이 병에 걸리면 중증(重症)이 되기 쉽고 늦가을부터 겨울에 걸쳐 방목하는 소에 다발하는 일종의 방목병이다.</li> 
							</ul>
						</li>
					</ul>
					<h2>증상</h2>
					<ul>
						<li>기침에 자주하고 복식(腹式)호흡을 한다. </li>
						<li>열이나며 콧물을 흘린다. 침흘림, 식욕감퇴, 설사를 볼 수 있다. </li>
						<li>특징적인 모습은 머리를 앞쪽으로 내밀어 입을 벌리고 혀를 내놓으며 마치 토할 것같은 모습으로 기침을 연발하는 것이다. </li>
					</ul>
					<h2>목장에서의 처치</h2>
					<ul>
						<li>증상이 가벼울 때는 구충제를 먹이도록 한다. </li>
						<li>오염된 방목지는 청정화(淸淨化)할 필요가 있다. </li>
						<li>매년 정기적인 프로그램에 의해 구충을 실시한다. </li>
						<li>방목하기전에 분변검사를 실시하여 충란을 갖고 있는 소를 구충하면 방목지 오염을 줄일 수 있다. </li>
						<li>폐충의 구충제로서는 레바미졸(상품명:테트라미졸)을 체중 100kg 당 7.5g을 투여하는 것이 가장 효과가 좋다고 알려져 있다.</li>
					</ul>
				</div>
				<div className="rightpart">
					<img src={Pic563} className="nonborder" alt=""/>
					<img src={Pic564} className="nonborder" alt=""/>
					<img src={Pic565} className="nonborder" alt=""/>
				</div>
			</div>
  );
}

export default Do104;