import React from 'react';
import './quickmenu.scss';
import { Link } from 'react-router-dom';

// 이미지 임포트
import quick1 from 'assets/images/containers/main/quick1.png';
import quick2 from 'assets/images/containers/main/quick2.png';
import quick3 from 'assets/images/containers/main/quick3.png';
import quick4 from 'assets/images/containers/main/quick4.png';
import quick5 from 'assets/images/containers/main/quick5.png';
import quick6 from 'assets/images/containers/main/quick6.png';

const QuickMenu = () => {
  // 메뉴 아이템 데이터 정의
  const menuItems = [
    {
      title: '씨수소 정보보기',
      icon: quick1,
      link: '/Web/Ox/OxSearch',
    },
    {
      title: '한국형 씨수소 정보 조회',
      icon: quick2,
      link: '/Web/Progency/SuperiorCowSearch',
    },
    {
      title: '검정개체찾기',
      icon: quick3,
      link: '/Web/Performance/FindCow',
    },
    {
      title: '젖소검정사업보고서',
      icon: quick4,
      link: '/Web/Data/Publication',
    },
    // {
    //   title: '전문가 상담실',
    //   icon: quick5,
    //   link: '/Web/Qna/Expert',
    // },
    {
      title: '공지사항',
      icon: quick6,
      link: '/Web/Data/Notice',
    },
  ];

  return (
    <div className="quickMenuContainer">
      <div className="rowCon">
        <div className="quickMenuHeader">
          <strong>빠른 서비스 메뉴</strong>
          <h2>
            주요 서비스 <span>안내</span>
          </h2>
          <p>
            다양한 분석기법을 통해 공익성을 기반으로
            <br /> 운영하고 있는 젖소개량사업소의 서비스입니다.
          </p>
        </div>
        <div className="quickMenuGrid">
          {menuItems.map((item, index) => (
            <Link key={index} to={item.link} className="quickMenuItem">
              <div className="quickMenuIcon">
                <img src={item.icon} alt={item.title} />
              </div>
              <p className="quickMenuTitle">{item.title}</p>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default QuickMenu;
