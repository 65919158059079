import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

// api
import { getState } from 'utils/api';

// components
import Pagination from 'components/bo/Pagination';

function DairyDicList() {
  const [searchState, setSearchState] = useState({
    search_type: 'sjc_name',
    search_keyword: '',
    page_no: 1,
    sjc_pid: '',
    sjc_id: '',
  });

  // Fetch categories data
  const { data: categoriesData } = useQuery({
    queryKey: ['dairyDicGroup1'],
    queryFn: () => getState('/board/select_dictionary_group1'),
  });

  // Fetch subcategories based on selected category
  const { data: subCategoriesData } = useQuery({
    queryKey: ['dairyDicGroup2', searchState.sjc_pid],
    queryFn: () =>
      searchState.sjc_pid
        ? getState('/board/select_dictionary_group2', {
            sjc_pid: searchState.sjc_pid,
          })
        : null,
    enabled: !!searchState.sjc_pid,
  });

  console.log(subCategoriesData);

  // Fetch dictionary list
  const { data, isLoading, isError, error } = useQuery({
    queryKey: ['dairyDicList', searchState],
    queryFn: () => getState('/board/select_dictionary_list', searchState),
  });

  console.log(data);

  const items = data?.result?.list || [];
  const totalCount = data?.result?.total_count || 0;
  const totalPages = data?.result?.page_count || 0;

  const handleSearch = (e) => {
    e.preventDefault();
    setSearchState({
      ...searchState,
      page_no: 1,
    });
  };

  const handlePageChange = (pageNo) => {
    setSearchState({
      ...searchState,
      page_no: pageNo,
    });
  };

  const handleCategoryChange = (e) => {
    setSearchState({
      ...searchState,
      sjc_pid: e.target.value,
      sjc_id: '',
      page_no: 1,
    });
  };

  return (
    <div className="bo-page">
      <h2>낙농사전</h2>
      <div className="searchbox">
        <select value={searchState.sjc_pid} onChange={handleCategoryChange}>
          <option value="">==전체==</option>
          {categoriesData?.result?.map((category) => (
            <option key={category.sjc_id} value={category.sjc_id}>
              {category.sjc_name}
            </option>
          ))}
        </select>
        <select
          value={searchState.sjc_id}
          onChange={(e) =>
            setSearchState({
              ...searchState,
              sjc_id: e.target.value,
              page_no: 1,
            })
          }
        >
          <option value="">==전체==</option>
          {subCategoriesData?.result?.map((subCategory) => (
            <option key={subCategory.sjc_id} value={subCategory.sjc_id}>
              {subCategory.sjc_name}
            </option>
          ))}
        </select>
        <select
          value={searchState.search_type}
          onChange={(e) =>
            setSearchState({
              ...searchState,
              search_type: e.target.value,
            })
          }
        >
          <option value="sjc_name">분류</option>
          <option value="sj_subject">명칭</option>
        </select>
        <input
          type="text"
          value={searchState.search_keyword}
          onChange={(e) =>
            setSearchState({
              ...searchState,
              search_keyword: e.target.value,
            })
          }
        />
        <button type="button" className="btn-search" onClick={handleSearch}>
          검색
        </button>
      </div>

      <div className="board-top">
        <p>
          총 자료수: <strong>{totalCount}</strong> 개, 페이지{' '}
          {searchState.page_no}/{totalPages}
        </p>
      </div>

      <div className="table-container">
        <table>
          <colgroup>
            <col width="10%" />
            <col width="*" />
            <col width="15%" />
          </colgroup>
          <thead>
            <tr>
              <th>번호</th>
              <th>명칭</th>
              <th>분류</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td colSpan="3" className="loading-data">
                  데이터를 불러오는 중입니다...
                </td>
              </tr>
            ) : isError ? (
              <tr>
                <td colSpan="3" className="error-data">
                  에러가 발생했습니다: {error.message}
                </td>
              </tr>
            ) : items.length === 0 ? (
              <tr>
                <td colSpan="3" className="no-data">
                  게시글이 없습니다.
                </td>
              </tr>
            ) : (
              items.map((item) => (
                <tr key={item.sj_id}>
                  <td className="center">{item.row_num}</td>
                  <td>
                    <Link to={`/bo/board/dairyDic/view?sj_id=${item.sj_id}`}>
                      {item.sj_subject}
                    </Link>
                  </td>
                  <td className="center">{item.sjc_name}</td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>

      <Pagination
        currentPage={searchState.page_no}
        totalPage={totalPages}
        onPageChange={handlePageChange}
      />

      <div className="button-group">
        <Link to="/bo/board/dairyDic/edit" className="btn-l">
          등록
        </Link>
      </div>
    </div>
  );
}

export default DairyDicList;
