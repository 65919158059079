import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

// api
import { getState } from 'utils/api';

// components
import Pagination from 'components/bo/Pagination';

function BackupBreedBullList() {
  const navigate = useNavigate();

  const [searchState, setSearchState] = useState({
    search_type: 's_code',
    search_keyword: '',
    page_no: 1,
    // expt_gubun: 'main',
  });

  const { data, isLoading, isError, error } = useQuery({
    queryKey: ['backupBreedBulls', searchState],
    queryFn: () =>
      getState('/board/select_backup_breed_bull_list', { ...searchState }),
  });

  const breedBulls = data?.result?.LIST || [];
  const totalCount = data?.result?.TOTAL_COUNT || 0;
  const totalPages = data?.result?.PAGE_COUNT || 0;

  const handleSearch = (e) => {
    e.preventDefault();
    setSearchState({
      ...searchState,
      page_no: 1,
    });
  };

  const handlePageChange = (pageNo) => {
    setSearchState({
      ...searchState,
      page_no: pageNo,
    });
  };

  const handleEdit = () => {
    navigate('/bo/breedBull/backupBreedBull/edit');
  };

  return (
    <div className="bo-page">
      <h2>후보 종모우 관리</h2>
      <div className="searchbox">
        <select
          value={searchState.search_type}
          onChange={(e) =>
            setSearchState({
              ...searchState,
              search_type: e.target.value,
            })
          }
        >
          <option value="s_code">종모우 코드</option>
          <option value="s_name">종모우 이름</option>
        </select>
        <input
          type="text"
          value={searchState.search_keyword}
          onChange={(e) =>
            setSearchState({
              ...searchState,
              search_keyword: e.target.value,
            })
          }
        />
        <button type="button" className="btn-search" onClick={handleSearch}>
          검색
        </button>
      </div>

      <div className="board-top">
        <p>
          총 자료수: <strong>{totalCount}</strong> 개, 페이지{' '}
          {searchState.page_no}/{totalPages}
        </p>
      </div>

      <div className="table-container">
        <table>
          <colgroup>
            <col width="5%" />
            <col width="12%" />
            <col width="*" />
            <col width="10%" />
            <col width="5%" />
            <col width="25%" />
            <col width="25%" />
          </colgroup>
          <thead>
            <tr>
              <th>번호</th>
              <th>정액코드</th>
              <th>단축명호</th>
              <th>생년월일</th>
              <th>원산지</th>
              <th>부</th>
              <th>모</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td colSpan="7" className="loading-data">
                  데이터를 불러오는 중입니다...
                </td>
              </tr>
            ) : isError ? (
              <tr>
                <td colSpan="7" className="error-data">
                  에러가 발생했습니다: {error.message}
                </td>
              </tr>
            ) : breedBulls.length === 0 ? (
              <tr>
                <td colSpan="7" className="no-data">
                  게시글이 없습니다.
                </td>
              </tr>
            ) : (
              breedBulls.map((bull, index) => (
                <tr key={bull.sid || bull.s_code}>
                  <td className="center">
                    {totalCount - ((searchState.page_no - 1) * 10 + index)}
                  </td>
                  <td className="center">
                    <Link
                      to={`/bo/breedBull/backupBreedBull/view?sid=${bull.sid}`}
                    >
                      {bull.s_code}
                    </Link>
                  </td>
                  <td className="center">{bull.s_sortname}</td>
                  <td className="center">{bull.s_birth}</td>
                  <td className="center">{bull.s_origin}</td>
                  <td className="center">{bull.s_sire_e}</td>
                  <td className="center">{bull.s_dam_e}</td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>

      <Pagination
        currentPage={searchState.page_no}
        totalPage={totalPages}
        onPageChange={handlePageChange}
      />

      <div className="button-group">
        <button type="button" className="btn-l" onClick={handleEdit}>
          등록
        </button>
      </div>
    </div>
  );
}

export default BackupBreedBullList;
