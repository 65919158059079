import React from 'react';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './bannerSlider.scss';

const BannerSlider = ({ images }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    // arrows: true,
  };

  return (
    <div className="banner-slider">
      <Slider {...settings}>
        {images.map((image, index) => (
          <div key={index}>
            <img src={image} alt={`Slide ${index}`} />
          </div>
        ))}
      </Slider>
      <div className="bannerTitle">
        <strong>better bulls, better future!</strong>
        <h2 className="titleItem">농장의 발전, 더 풍요로운 내일로</h2>
        <br />
        <p className="titleItem">
          젖소개량사업소는 낙농가의 미래를 위해 노력합니다.
        </p>

        <button type="button" className="business-button">
          <Link to="/Web/Business/CompanyIntro" className="buttonColor">
            사업안내
          </Link>
        </button>
      </div>
    </div>
  );
};

export default BannerSlider;
