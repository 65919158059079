import React from 'react';

function Do217() {

  return (
    <div className="techinfoview">
      <h1>분뇨 및 폐수처리</h1>
      <h2>분뇨처리 계획</h2>
      <p className="pstyle">&nbsp;&nbsp;&nbsp;분뇨와 폐수의 방출은 공기, 수질 및 토질을 오염시키므로, 목장의 규모에 관계없이, 분뇨를 적절하게 처리하는 것은 목장관리에 필수적인 사항이다. 분뇨처리 과정은 ①수거, ②운반, ③저장, ④처리, ⑤살포, ⑥사료로서 재이용 등이 있다. 분뇨를 적절히 처리하면, ①위생적인 시설을 마련함으로서 소의 건강과 유질을 유지시키고, ②수질오염을 막아주며, ③냄새와 먼지를 감소시키고, ④분뇨를 퇴비로 만들며, ⑤분뇨처리 규정 준수를 가능하게 한다. <br/>&nbsp;&nbsp;&nbsp;적절한 분뇨처리 계획은 환경오염 방지를 위하여 필수적인데, 분뇨처리 시설을 선택할 때는 ①목장의 규모, ②축사형태, ③깔짚의 사용유무, ④목장의 지형, ⑤수로와 이웃과의 근접정도, ⑥개인적 선호 등을 고려해야 한다. <br/>&nbsp;&nbsp;&nbsp;일반적으로 목장주는 분뇨를 퇴비화하여 작물재배의 영양소원으로 이용하기를 원하는데, 이를 위해서는 ①적절한 분뇨처리시설을 갖춰야 하고, ②분뇨의 연생산량과, ③영양소의 연생산량을 추산하여야 하며, ④퇴비가 이용될 수 있는 작물재배 면적을 산출하여야 한다. </p>
      <h2>분뇨와 폐수 생산량</h2>
      <p><b>&lt;표 87&gt; 젖소의 체중에 따른 일일 분뇨 생산량 </b></p>
      <table className="tablestyle">
        <tr>
          <td rowSpan="2">체 중(kg)</td>
          <td colSpan="2">생 산 량</td>
        </tr>
        <tr style={{background: '#273143',color: '#fff'}}>
          <td>ft 3 /일</td>
          <td>리터/일</td>
        </tr>
        <tr>
          <td>65</td>
          <td>0.2</td>
          <td>5.7</td>
        </tr>
        <tr>
          <td>115</td>
          <td>0.3-0.4</td>
          <td>8.7-11.4</td>
        </tr>
        <tr>
          <td>230</td>
          <td>0.7-0.8</td>
          <td>20.1-22.8</td>
        </tr>
        <tr>
          <td>455</td>
          <td>1.2-1.6</td>
          <td>33.8-45.6</td>
        </tr>
        <tr>
          <td>635</td>
          <td>1.7-2.2</td>
          <td>47.5-62.3</td>
        </tr>
      </table>
      <p className="pstyle"> 분뇨처리 계획을 수립할 때는 수거하여 저장하여야 하는 분뇨와 폐수 생산량을 먼저 추산하여야 한다. (표 87 참조) 분뇨와 폐수 생산량을 산출할 때는 ①분뇨 생산량 ②깔짚 소모량 ③착유장으로부터의 폐수 생산량 ④개방식 분뇨저장소로 유입되는 빗물의 양 ⑤축사 청소물의 양 ⑥사일리지로부터 유출되는 수분의 양을 모두 합한 값에서 증발되는 수분량을 제외시켜 계산한다. 이 같은 산출량과 설정된 저장기간을 바탕으로 분뇨저장소의 크기를 결정한다. </p>
      <ul>
        <li>분뇨 생산량 
          <ul>
            <li>젖소는 체중 450kg당 일일 1.2∼1.6 ft2 (31∼46kg)만큼의 분뇨를 생산한다. 분뇨의 용적과 고형물 함량은 사료의 종류, 나이, 산유량 등에 따라서 차이가 생기는데, 일반적으로 갓 배설된 분뇨의 고형분 함량은 12%∼14% 정도이다. 젖소의 체중에 따른 배설된 분뇨의 일일 생산량이 표 87에서 보는 바와 같다. </li>
          </ul>
        </li>
        <li>깔짚 소모량 <br/>
          <p><b>&lt;표 88&gt; 축사형태에 따른 젖소의 깔짚 소모량</b></p>
          <table className="tablestyle">
            <tr>
              <td>깔짚종류</td>
              <td>후리스톨 우사</td>
              <td>깔짚 우사</td>
            </tr>
            <tr>
              <td colSpan="3">--체중 450kg당 일일 소모량(kg)--</td>
            </tr>
            <tr>
              <td>세절짚</td>
              <td>1.2</td>
              <td>4.96</td>
            </tr>
            <tr>
              <td>긴짚</td>
              <td>권장되지 않음</td>
              <td>4.2</td>
            </tr>
            <tr>
              <td>대팻밥</td>
              <td>1.4</td>
              <td>권장되지 않음</td>
            </tr>
          </table><br/>
          <p><b>&lt;표 89&gt; 일반적으로 이용되는 깔짚의 대략적인 용적밀도</b></p>
          <table className="tablestyle">
            <tr>
              <td>깔짚종류</td>
              <td>용적밀도, kg/ft3</td>
            </tr>
            <tr>
              <td>풀린짚(loose straw)</td>
              <td>0.9∼1.3</td>
            </tr>
            <tr>
              <td>대팻밥</td>
              <td>4.1</td>
            </tr>
            <tr>
              <td>톱밥</td>
              <td>5.4</td>
            </tr>
            <tr>
              <td>짚베일(baledstraw)</td>
              <td>1.8~2.25</td>
            </tr>
            <tr>
              <td>절단짚</td>
              <td>2.7~3.6</td>
            </tr>
          </table>
          <p className="pstyle">축사형태에 따른 유기질 깔짚의 소모량이 표 88에 나타나 있다. 깔짚은 분에 붙게 되어 분의 고형물 함량을 증가시킨다. 루즈반이나 깔짚 우사의 경우에는 분뇨가 고형상태로 수거되고 저장되지만, 후리스톨 우사로부터의 분뇨는 반고형 형태인표9. 일반적으로 이용되는 깔짚의 대략적인 용적밀도슬러리 상태로 수거되어 저장된다.  분뇨의 수분을 깔짚이 흡수하기 때문에, 깔짚사용으로 인한 분뇨 저장소의 용적의 증가는 일반적으로 소모된 전체 깔짚 부피의 1/2에 해 당하도록 한다. 깔짚 소모량은 깔짚의 용적밀도 &lt;표 89&gt;를 감안하여 추산한다. </p>
        </li>
        <li>분뇨와 깔짚의 부피 추산 
          <ul>
            <li>저장해야할 분뇨와 깔짚의 부피를 표 91을 이용하여 다음과 같이 저장 부피를 구할 수 있다.<br/><br/>분뇨와 깔짚의 저장부피 = 소 두수×두당 체중×전체 부피 (표 90 참조)×저장기간 <br/><br/>계산예 : 모든 소의 체중이 630kg이라고 가정하고, 절단짚을 깔짚으로 이용할 경우 소 100 두를 위한 분뇨와 깔짚의 저장 부피는 다음과 같이 계산된다. <br/><br/>후리스톨 우사의 경우 : <br/>저장부피 = 100(두)×630(kg/두)×1.8(ft3/일/450kg)×365(일) = 91,980ft3 <br/><br/>루즈반의 경우 : <br/>저장부피 = 100(두)×630(kg/두)×2.4(ft3/일/450kg)×365(일) = 122,640ft3 </li>							
          </ul>
          <p><b>&lt;표 90&gt; 분뇨의 배출 부피와 깔짚의 소모 부피(생시체중 기준)</b></p>
          <table className="tablestyle">
            <tr>
              <td>우사형태</td>
              <td>분뇨 부피</td>
              <td>깔짚 부피</td>
              <td>전체 부피</td>
            </tr>
            <tr>
              <td></td>
              <td colSpan="3">--------------- ft3/일/450kg-----------------</td>
            </tr>
            <tr>
              <td>후리스톨</td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>모래깔짚</td>
              <td>1.6</td>
              <td>0.5</td>
              <td>2.1</td>
            </tr>
            <tr>
              <td>유기질</td>
              <td>1.6</td>
              <td>0.2</td>
              <td>1.8</td>
            </tr>
            <tr>
              <td>루즈반</td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>세절짚</td>
              <td>1.6</td>
              <td>0.8</td>
              <td>2.4</td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>긴짚</td>
              <td>1.6</td>
              <td>1.9</td>
              <td>3.5</td>
            </tr>
          </table>
        </li>
        <li>착유장으로부터 배출되는 폐수의 양
          <ul>
            <li>표 91에 나타나 있는 바와 같이, 착유장으로 배출되는 폐수량은 유방과 바닥의 세척방법과 착유실이 형태에 따라서 결정된다.  이를테면, 착유실을 호스로 청소할 경우에는 일일 304ℓ의 물이 소비되지만, 수세식 방법을 택할 경우에는 일일 1520ℓ의 물이 소비된다. 소규모의 착유장일수록 바닥과 장비를 청소하는데 소모되는 물의 양이 감소하지만 일일 두당으로 계산하면 물 소모량은 증가한다. 전냉각기(pre-cooler)로부터 방출되는 물을 바닥을 청소하거나 소의 음수용으로 사용하므로써 착유장으로부터 배출되는 폐수의 양을 감소시킬 수 있다. </li>
          </ul>
          <p><b>&lt;표 91&gt; 착유장의 폐수 생산량</b></p>
          <table className="tablestyle">
            <tr>
              <td colSpan="2">시 설</td>
              <td>세정수 생산량</td>
            </tr>
            <tr>
              <td rowSpan="2">벌크 탱크 세척</td>
              <td>자동식</td>
              <td>50-60 갤론 </td>
            </tr>
            <tr>
              <td>수동식</td>
              <td>30-40 갤론</td>
            </tr>
            <tr>
              <td colSpan="2">착유 파이프라인 세척<br/>(스탄 우사의 파이프 라인은 길기 때문에 폐수량 증가)</td>
              <td>75-125 갤론</td>
            </tr>
            <tr>
              <td colSpan="2">양동이 착유기(pail milker) 세척</td>
              <td>30-40 갤론</td>
            </tr>
            <tr>
              <td colSpan="2">기타 장비 세척</td>
              <td>30 갤론/일</td>
            </tr>
            <tr>
              <td rowSpan="3">젖소 세척 </td>
              <td>자동식 </td>
              <td>1-4.5 갤론/두 </td>
            </tr>
            <tr>
              <td>추산된 평균치 </td>
              <td>2 갤론/두</td>
            </tr>
            <tr>
              <td>수동식</td>
              <td>0.25-0.5 갤론/&apos;두</td>
            </tr>
            <tr>
              <td rowSpan="2">착유실 바닥 청소 </td>
              <td>호스로 청소 </td>
              <td>20-40 갤론/착유 </td>
            </tr>
            <tr>
              <td>수세식 청소</td>
              <td>800-2,000갤론/착유</td>
            </tr>
            <tr>
              <td colSpan="2">예냉기(pre-cooler) 지하수</td>
              <td>2 갤론/냉각된 우유갤론</td>
            </tr>
            <tr>
              <td colSpan="2">우유 저장실 바닥 청소</td>
              <td>10-20 갤론/일</td>
            </tr>
            <tr>
              <td colSpan="2">화장실 청소</td>
              <td>5갤론/수세</td>
            </tr>
          </table>
        </li>
        <li>분뇨 저장시설로 유입되는 빗물량 
          <ul>
            <li>빗물은 뚜껑이 설치되어 있지 않은 분뇨 저장시설에 직접 또는 흘러서 유입되므로, 저장부피를 계산할 때 반드시 예상되는 빗물 유입량을 추산하여야 한다. 빗물 유입량은 ①폐기물 저장기간 ②저장기간 중의 평균 강우량 ③저장표면의 증발 등에 의하여 결정된다. <br/>젖소의 분중에는 섬유소 함량이 높기 때문에, 폐기물 저장시설 내부 표면에 딱딱한 표피층이 형성된다. 이런 경우에는 저장시설의 표면을 통하여 수분이 증발되는 양이 적기 때문에 수분 증발량을 저장부피 계산에 첨가하지 않는다. <br/>그러나 저장시설 내로 증발되는 수분의 양이 많아진다. 이런 경우 저장시설의 저장부피를 결정할 때는 증발량을 고려하여야 한다. 저장시설로 유입되는 빗물의 양은 다음과 같이 계산한다. 
              <ul>
                <li>딱딱한 분층이 생기는 저장시설의 경우 : <br/>빗물 유입량 = 연평균 강우량(mm) × 저장기간(년) </li>
                <li> 딱딱한 분층이 생기지 않는 저장시설의 경우 : <br/>빗물 유입량 = 연평균 강우량(mm) × 저장기간(년) - 연평균 호수 증발량(mm/년) × 5월과 10월 사이 증발되는 비율(%)/100 × 저장기간(년)</li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
      <h2>분뇨의 취급형태</h2>
      <ul>
        <li>고체분뇨 
          <ul>
            <li>분뇨가 충분히 마르거나 깔짚과 혼합되어 고형물의 함량이 18% 이상일 경우에 고체분뇨라 한다. 갓 배출된 분뇨 45kg(1.67 ft) 깔짚 5.4kg이 섞여야 분뇨를 고체상태로 취급할 수 있다. 트랙터나 기계식 스크래퍼로 고체분뇨를 수거하여 박스(box-type)살포기로 토양에 뿌린다. </li>
          </ul>
        </li>
        <li>반고체분뇨 
          <ul>
            <li>반고체분뇨의 고형물 함량은 10%∼16%, 펌프를 이용하여 반고체분뇨를 퍼낼 수 있다. 수거되어 저장된 반고체 분뇨는 박스 살포기를 사용하여 토양에 뿌릴 수도 있지만, 후레일 형태(flail-type)나 V-바닥 살포기를 사용하면 더욱 고르게 뿌릴 수 있다. </li>
          </ul>
        </li>
        <li>슬러리분뇨 
          <ul>
            <li>분뇨 380ℓ(13.4 ft3)당 빗물이나 착유장의 폐수 114ℓ가 첨가되어 분뇨의 고형물 함량이 10% 이하로 된 상태를 슬러리분뇨라 한다. 펌프를 이용하여 퍼낼 수 있으며, 직접분무기를 사용하거나 트럭이나 짐차에 슬러리 분뇨탱크를 장착시켜 토양에 살포할 수 있다. </li>
          </ul>
        </li>
        <li>고액분리분뇨 
          <ul>
            <li>액체분뇨는 고체와 액체를 분리할 수 있는 시설을 거친 액체부분이나, 착유장, 또는 액체폐수 저장 못으로부터 흘러나오는 액체로 고체와 분리된 상태를 말한다. 고체와 액체를 분리할 수 없을 경우에는, 분뇨 380ℓ당 빗물이나 착유장으나 폐수 950ℓ가 첨가되어 분뇨의 고형물 함량이 4% 이하로 된 상태를 액체분뇨라 한다. 액체용 펌프를 이용하여 퍼낼 수 있으며, 직접분무기, 탱크가 장착된 짐차나 트럭, 또는 관개용 장비를 이용하여 토양에 살포할 수 있다.</li>
          </ul>
        </li>
      </ul>
    </div>
  );
}

export default Do217;