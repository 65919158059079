import React from 'react';
import { Link } from 'react-router-dom';

const SiteMapItem = ({ page }) => {
  /**
   * 2024-12-27 추가
   * 농가별 검정성적조회인 경우 외부 URL 사용
   */
  const isExternalPerformanceCheck =
    page.title === '농가별 검정성적조회' &&
    page.path === '/Web/Performance/PerformanceCheck';

  return (
    <li>
      {isExternalPerformanceCheck ? (
        <a
          href="http://www2.dcic.co.kr/performace_02.do"
          target="_blank"
          rel="noopener noreferrer"
        >
          {page.title}
        </a>
      ) : (
        <Link to={page.path}>{page.title}</Link>
      )}
      {page.subpages && (
        <ul className="sitemap_small_menu">
          {page.subpages.map((subpage, index) => (
            <SiteMapItem key={index} page={subpage} />
          ))}
        </ul>
      )}
    </li>
  );
};

export default SiteMapItem;
