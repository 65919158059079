import React from 'react';

import d2by31by1 from 'assets/images/sub/_images/2-31-1.jpg';
import d2by31by2 from 'assets/images/sub/_images/2-31-2.jpg';
import d2by31by3 from 'assets/images/sub/_images/2-31-3.jpg';
import d2by33 from 'assets/images/sub/_images/2-33.jpg';

function Fo28() {

  return (
    <div className="techinfo_view">
				<p className="viewTitle">고형사료를 주는 방법</p>
				<h2>고형사료(固形飼料)란?</h2>
				<ul className="listStyle01 mg15f">
					<li>인공유(Starter)
						<ul className="listStyle04">
							<li>조기 이유용의 농후사료이다</li>
							<li>제 1위내의 융모의 발달과 액상사료에 대한 영양 공급이 주목적</li>
						</ul>
					</li>
					<li>건초
						<ul className="listStyle04">
							<li>이유전의 건초급여의 목적은 영양공급보다는 제 1위를 물리적으로 확장하여 반추작용을 충실히 하는 것에 있다</li>
						</ul>
					</li>
				</ul>
				<div className="imgBox mg60f">
					<span className="mg20l"><img src={d2by31by1} alt=""/><br/>건초. 오차드 1번</span>
					<span className="mg20l"><img src={d2by31by2} alt=""/><br/>티모시 1번</span>
					<span className="mg20l"><img src={d2by31by3} alt=""/><br/>티모시 2번</span>
				</div>
				
				<h2>고형사료(固形飼料)란?</h2>
				<ul className="listStyle04 mg15f">
					<li>액상사료의 급여량이 인공유 채식의 결정요인이다. 일량 6ℓ 액상사료를 급여한 경우에는 30일령이라도 인공유의 채식은 얼마안될 것이다</li>
					<li>액상사료를 4ℓ의 정량급여로 한 경우에는 생후 4∼5일부터 먹기시작하기 때문에 먹을 수 있는 양(처음에는 100g이하)을 급여하여 채식량 증대와 동시에 증급(增給)한다. 1일 1회 급여, 잔사는 제거한다</li>
					<li>CP(Crude protein: 조단백질)20% 정도의 것이 바람직하다</li>
					<li>손으로 입에 넣어 주는 것도 채식을 촉진하지만, 적당한 정도의 공복(空腹)이 있으면 자연히 먹기 시작한다</li>
				</ul>
				<div className="imgBox mg60f"><img src={d2by33} alt=""/><br/>인공유를 손으로 입에 밀어 넣어 준다.</div>
				
				<h2>이유시기</h2>
				<ul className="listStyle04 mg15f">
					<li>1일 600g의 인공유를 채식할 수 있으면 4주 이유도 가능하다</li>
					<li>액상사료가 4ℓ/일이면 6주령에서는 인공유의 채식량은 대부분이 1kg/일이하가 된다</li>
					<li>인공유의 채식량이 적은 것은 
						<ul>
							<li>① 액상사료의 급여가 너무 많다</li>
							<li>② 물이 충분히 급여되고 있지 않다가 주요 요인</li>
						</ul>
					</li>
					<li>액상사료의 정지방법은 단숨에 완전 중지한다. 조금씩 줄이는 것보다 빨리 액상사료를 잊게하는 쪽이 좋다</li>
				</ul>
				<div className="tableWrap">
					<table className="tablestyle">
						<caption>표. 이유시간의 차이와 발육 성적(6개월령시)</caption>
						<thead>
							<tr>
								<th className="first" colSpan="2">공시우등</th>
								<th>체중(kg)</th>
								<th>흉위(cm)</th>
								<th>체고(cm)</th>
								<th>체장(cm)</th>
								<th>적요</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td colSpan="2">170번</td>
								<td>211.0</td>
								<td>138</td>
								<td>111</td>
								<td>113</td>
								<td className="left">인공유 0.6kg 채식시 이유(33일)</td>
							</tr>
							<tr>
								<td colSpan="2">171번</td>
								<td>193.0</td>
								<td>142</td>
								<td>98</td>
								<td>107</td>
								<td className="left">인공유 1.0kg 채식시 이유(48일) </td>
							</tr>
							<tr>
								<td colSpan="2">172번</td>
								<td>195.0</td>
								<td>141</td>
								<td>110</td>
								<td>116</td>
								<td className="left" rowSpan="4">농대방식으로 인한 이유(60일)</td>
							</tr>
							<tr>
								<td rowSpan="3">농대</td>
								<td>61년</td>
								<td>180.2</td>
								<td>137.0</td>
								<td>106.2</td>
								<td></td>
							</tr>
							<tr>
								<td>62년</td>
								<td>213.1</td>
								<td>134.3</td>
								<td>110.5</td>
								<td></td>
							</tr>
							<tr>
								<td>63년</td>
								<td>212.0</td>
								<td>135.3</td>
								<td>106</td>
								<td></td>
							</tr>
							<tr>
								<td colSpan="2">Holstein협회 표준</td>
								<td>193.0</td>
								<td>132.0</td>
								<td>107.1</td>
								<td></td>
								<td></td>
							</tr>
						</tbody>
					</table>
				</div>
				<ul className="refer mg60f" style={{marginTop: '-20px'}}>
					<li>(북해도 입농업대학교 Project활동 기록집 1988, 天內康幸외)</li>
				</ul>
				
				<h2>6주 이유를 위한 5가지 단계</h2>
				<ul className="listStyle04 mg15f">
					<li>장기포유의 개념을 전부 잊을 것(미경험자의 쪽이 능숙하다.)</li>
					<li>액상사료는 정량급여로 하여 2.5ℓ/일 이상은 급여하지 않는다.</li>
					<li>인공유는 양질인 것을 급여한다. (특히 단백질 20% 정도는 필요) </li>
					<li>인공유는 생후 4∼5일에 100g이하부터 급여하여 2.5kg/일(한냉조건에서는 3kg/일)까지는 먹는 만큼 급여한다</li>
					<li>물은 생후 바로 자유롭게 먹을 수 있도록 해 둔다. 먹지 않아도 먹을 수 있도록 해 두는 것이 중요</li>
				</ul>
				<table className="tablestyle">
					<caption>표. 이유시기 조사</caption>
					<thead>
						<tr>
							<th className="first">시기</th>
							<th>고유량 농가</th>
							<th>평균 유량농가</th>
							<th>1978년</th>
						</tr>
					</thead>
					<tfoot>
						<tr>
							<td>계</td>
							<td>23</td>
							<td>26</td>
							<td></td>
						</tr>
					</tfoot>
					<tbody>
						<tr>
							<td>30일 이내</td>
							<td>1 (5)</td>
							<td>1 (4)</td>
							<td></td>
						</tr>
						<tr>
							<td>31∼40</td>
							<td>1 (5)</td>
							<td>-</td>
							<td>(2)</td>
						</tr>
						<tr>
							<td>41∼70</td>
							<td>10 (47)</td>
							<td>7 (27)</td>
							<td></td>
						</tr>
						<tr>
							<td>71∼90</td>
							<td>-</td>
							<td>-</td>
							<td>(6)</td>
						</tr>
						<tr>
							<td>91∼120</td>
							<td>6 (28)</td>
							<td>9 (35)</td>
							<td>(11)</td>
						</tr>
						<tr>
							<td>121∼150</td>
							<td>2 (10)</td>
							<td>6 (23)</td>
							<td>(19)</td>
						</tr>
						<tr>
							<td>161∼180</td>
							<td>-</td>
							<td>2 (8)</td>
							<td>(40)</td>
						</tr>
						<tr>
							<td>180일 이후</td>
							<td>1 (5)</td>
							<td>1 (4)</td>
							<td>(22)</td>
						</tr>
					</tbody>
				</table>
				<ul className="refer mg10t">
					<li>주: 戶數, ( )내는 %</li>
					<li>(쇼와 63년도 북해도 토카치지정 농업개량보급원축산 부회직장연수보고서, 1978년은 북해도 토카치농협연조사)</li>
				</ul>
				
			</div>
  );
}

export default Fo28;
