import React from 'react';
import GeneGrade from 'containers/gene/genegrade/GeneGrade';

function GeneGradePage() {
  return (
    <GeneGrade />
  );
}

export default GeneGradePage;
