/* eslint import/prefer-default-export: "off" */

// 브라우저 환경 체크
const isBrowser = typeof window !== 'undefined';

/**
 * 씨수소 정보를 새 창에서 출력합니다
 * @param {string} code - 씨수소 코드
 */

export const openOxPrint = (code) => {
  if (!isBrowser || !code) return;

  const queryString = new URLSearchParams({ code }).toString();

  window.open(`/OxPrint/?${queryString}`, '_blank', 'width=1000,height=900');
};
