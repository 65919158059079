import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

// api
import { getState } from 'utils/api';

// components
import Pagination from 'components/bo/Pagination';

function ExptEngList() {
  const [searchParams, setSearchParams] = useState({
    search_type: 'ep_name', // 'ep_name' or 'ep_position'
    search_keyword: '',
    page_no: 1,
  });

  const { data, isLoading, isError, error } = useQuery({
    queryKey: ['engExperts', searchParams],
    queryFn: () => getState('/board/select_expert_list_e', searchParams),
  });

  const experts = data?.result?.list || [];
  const totalCount = data?.result?.total_count || 0;
  const totalPages = data?.result?.page_count || 0;

  const handleSearch = (e) => {
    e.preventDefault();
    setSearchParams({
      ...searchParams,
      page_no: 1,
    });
  };

  const handlePageChange = (pageNo) => {
    setSearchParams({
      ...searchParams,
      page_no: pageNo,
    });
  };

  return (
    <div className="bo-page">
      <h2>전문가 목록&#40;영문&#41; </h2>
      {/* 검색 영역 */}
      <div className="searchbox">
        <select
          value={searchParams.search_type}
          onChange={(e) =>
            setSearchParams({
              ...searchParams,
              search_type: e.target.value,
            })
          }
        >
          <option value="ep_name">성명</option>
          <option value="ep_position">소속</option>
        </select>
        <input
          type="text"
          value={searchParams.search_keyword}
          onChange={(e) =>
            setSearchParams({
              ...searchParams,
              search_keyword: e.target.value,
            })
          }
        />
        <button type="button" onClick={handleSearch} className="btn-search">
          검색
        </button>
      </div>

      {/* 총 자료수 */}
      <div className="board-top">
        <p>
          총 자료수: <strong>{totalCount}</strong> 개, 페이지{' '}
          {searchParams.page_no}/{totalPages}
        </p>
      </div>

      {/* 리스트 테이블 */}
      <div className="table-container">
        <table>
          <colgroup>
            <col width="5%" />
            <col width="7%" />
            <col width="7%" />
            <col width="7%" />
            <col width="*" />
            <col width="10%" />
            <col width="10%" />
            <col width="25%" />
          </colgroup>
          <thead>
            <tr>
              <th>번호</th>
              <th>활성</th>
              <th>성명</th>
              <th>직함</th>
              <th>소속</th>
              <th>연락처</th>
              <th>E-MAIL</th>
              <th>분야</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td colSpan="8" className="loading-data">
                  데이터를 불러오는 중입니다...
                </td>
              </tr>
            ) : isError ? (
              <tr>
                <td colSpan="8" className="error-data">
                  에러가 발생했습니다: {error.message}
                </td>
              </tr>
            ) : experts.length === 0 ? (
              <tr>
                <td colSpan="8" className="no-data">
                  게시글이 없습니다.
                </td>
              </tr>
            ) : (
              experts.map((expert, index) => (
                <tr key={expert.ep_id}>
                  <td className="center">
                    {totalCount - ((searchParams.page_no - 1) * 10 + index)}
                  </td>
                  <td className="center">{expert.ep_del_fl}</td>
                  <td className="center">
                    <Link to={`/bo/expt/exptEng/view?ep_id=${expert.ep_id}`}>
                      {expert.ep_name}
                    </Link>
                  </td>
                  <td className="center">{expert.ep_title}</td>
                  <td className="center wrap">{expert.ep_position}</td>
                  <td className="center">{expert.ep_tel1}</td>
                  <td className="center">{expert.ep_email}</td>
                  <td className="center wrap">{expert.ep_field}</td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>

      {/* 페이지네이션 */}
      <Pagination
        currentPage={searchParams.page_no}
        totalPage={totalPages}
        onPageChange={handlePageChange}
      />

      {/* 버튼 영역 */}
      <div className="button-group">
        <Link to="/bo/expt/exptEng/edit" className="btn-l">
          등록
        </Link>
      </div>
    </div>
  );
}

export default ExptEngList;
