import React from 'react';
import PromotionalVideo from 'containers/web/info/promotionalVideo/PromotionalVideo';
import { Helmet } from 'react-helmet-async';

function PromotionalVideoPage() {
  return (
    <>
      <Helmet>
        <title>홍보동영상</title>
      </Helmet>
      <PromotionalVideo />
    </>
  );
}

export default PromotionalVideoPage;
