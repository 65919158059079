import React from 'react';


function Do61() {

  return (
    <div className="techinfoview">
				<h1>중송아지 사양관리(4개월령~12개월령)</h1>
				<h2>정상발육과 영양소요구량 </h2>
				<p className="pstyle">발육부진이나 과도한 비육이 되지 않도록 하기 위해서 일당증체량을 0.6∼0.8Akg으로 유지한다. 월령에 따른 정상 성장 여부를 알 수 있는 월령별 체중과 체고를 보면 표 65와 같다. 이와 같이 정상발육을 도모하기 위해서는 영양소의 균형있는 공급이 필요한데 육성우의 일당증체량에 따른 영양소요구량을 보면 &lt;표 66&gt;과 같다.</p><br/>
				<p><b>&lt;표 65&gt; 육성우 발육표준</b></p>
				<table className="tablestyle">
					<tr>
						<td>월령</td>
						<td>체중(kg)	</td>
						<td>체고(cm)</td>
					</tr>
					<tr>
						<td>0</td>
						<td>43</td>
						<td>74</td>
					</tr>
					<tr>
						<td>2</td>
						<td>77</td>
						<td>86</td>
					</tr>
					<tr>
						<td>4</td>
						<td>122</td>
						<td>99</td>
					</tr>
					<tr>
						<td>6</td>
						<td>168</td>
						<td>112</td>
					</tr>
					<tr>
						<td>8</td>
						<td>225</td>
						<td>117</td>
					</tr>
					<tr>
						<td>10</td>
						<td>270</td>
						<td>122</td>
					</tr>
					<tr>
						<td>12</td>
						<td>315</td>
						<td>127</td>
					</tr>
					<tr>
						<td>14</td>
						<td>360</td>
						<td>130</td>
					</tr>
					<tr>
						<td>16</td>
						<td>405</td>
						<td>132</td>
					</tr>
					<tr>
						<td>18</td>
						<td>446</td>
						<td>135</td>
					</tr>
					<tr>
						<td>20</td>
						<td>473</td>
						<td>137</td>
					</tr>
					<tr>
						<td>22</td>
						<td>529</td>
						<td>140</td>
					</tr>
					<tr>
						<td>24</td>
						<td>585</td>
						<td>142</td>
					</tr>
				</table>
				<p>(주)1. 에너지의 과다공급, 단백질공급부족은(특히 생식∼성성숙시까지)체고는 작은데도 체중은 많이 나가는 과비현상을 초래한다.<br/>2. 14∼16개월령에 적정체중에 도달하면 수태시킨다. </p>
				<h2>사료 급여 </h2>
				<ul>
					<li> 6개월령이 될 때까지는 청소나 사일레지등 수분이 많은 조사료는 제한 급여하고(일당 4∼5kg이내) 건초위주로 사육한다. </li>
					<li>조사료는 부드러우며 단백질 함량이 높은 것을 급여한다. 볏짚만을 급여하는 것은 바람직 하지 못하며 부득이 볏짚, 산야초등 저질사료를 급여할 때는 깻묵등 단백질사료 비타민 첨가제를 별도로 보충한다. </li>
					<li>이 시기에는 아직 어리고 반추위의 발달이 완전하지 못하므로 조사료 이외에 중송아지 사료를 보충 급여한다. 조사료의 질이 좋을 때는 농후사료를 체중의 1%정도, 조사료의 질이 나쁠때는 1.5% 정도 보충 급여한다. </li>
					<li>10개월령 이후에는 질이 좋은 조사료를 충분히 급여하면 농후사료를 보충하지 않고도 정상성장이 가능하다. 그러나 저질 조사료 급여시에는 농후사료를 체중의 1∼1.5% 보충급여한다.<br/>
						<p><b>&lt;표 66&gt; 육성우의 영양소요구량(NRC) </b></p>
						<table className="tablestyle">
							<tr>
								<td rowSpan="2">체중</td>
								<td rowSpan="2">일당증체</td>
								<td rowSpan="2">건물량</td>
								<td rowSpan="2">가소화 영양소총량</td>
								<td rowSpan="2">조단백질</td>
								<td colSpan="2">무기물</td>
								<td colSpan="2">비타민</td>
							</tr>
							<tr style={{background: '#273143',color:'#fff'}}>
								<td>칼슘</td>
								<td>인</td>
								<td>A</td>
								<td>D</td>
							</tr>
							<tr>
								<td colSpan="5">··············································· kg ·············································</td>
								<td colSpan="2">··········· g ··········</td>
								<td>1,000IU</td>
								<td>IU</td>
							</tr>
							<tr>
								<td>100</td>
								<td>0.6</td>
								<td>2.80</td>
								<td>2.00</td>
								<td>0.381</td>
								<td>7</td>
								<td>9</td>
								<td>4.2</td>
								<td>660</td>
							</tr>
							<tr>
								<td>150</td>
								<td>0.6</td>
								<td>4.00</td>
								<td>2.64</td>
								<td>0.491</td>
								<td>18</td>
								<td>11</td>
								<td>6.4</td>
								<td>990</td>
							</tr>
							<tr>
								<td>200</td>
								<td>0.7</td>
								<td>5.20</td>
								<td>3.45</td>
								<td>0.620</td>
								<td>21</td>
								<td>14</td>
								<td>8.5</td>
								<td>1,320</td>
							</tr>
							<tr>
								<td>250</td>
								<td>0.7</td>
								<td>6.30</td>
								<td>4.05</td>
								<td>0.704</td>
								<td>23</td>
								<td>17</td>
								<td>10.6</td>
								<td>1,650</td>
							</tr>
							<tr>
								<td>300</td>
								<td>0.7</td>
								<td>7.20</td>
								<td>4.56</td>
								<td>0.771</td>
								<td>24</td>
								<td>18</td>
								<td>12.7</td>
								<td>1,980</td>
							</tr>
							<tr>
								<td>350</td>
								<td>0.7</td>
								<td>8.00</td>
								<td>5.05</td>
								<td>0.826</td>
								<td>25</td>
								<td>19</td>
								<td>14.8</td>
								<td>2,310</td>
							</tr>
							<tr>
								<td>400</td>
								<td>0.7</td>
								<td>8.60</td>
								<td>5.45</td>
								<td>0.864</td>
								<td>25</td>
								<td>20</td>
								<td>17.0</td>
								<td>2,640</td>
							</tr>
							<tr>
								<td>450</td>
								<td>0.7</td>
								<td>9.10</td>
								<td>5.82</td>
								<td>0.892</td>
								<td>27</td>
								<td>21</td>
								<td>19.1</td>
								<td>2,970</td>
							</tr>
							<tr>
								<td>500</td>
								<td>0.6</td>
								<td>9.50</td>
								<td>5.96</td>
								<td>0.903</td>
								<td>27</td>
								<td>21</td>
								<td>21.2</td>
								<td>3,300</td>
							</tr>
						</table>
					</li>
					<li>6개월령 이내의 육성중인 송아지에게 큰송아지사료, 비육용사료, 착유우사료 등을 먹이지 않도록 한다. 이런 사료들은 단백질 함량이 낮고 요소가 들어있으므로 발육이 감소하기 때문이다. </li>
					<li>사육단계별로 급여할 사료중 적정 영양 수준은 &lt;표 67&gt;과같고 시판되는 송아지와 육성우용 사료의 영양성분은 &lt;표 68&gt;과 같다. <br/>
						<p><b>&lt;표 67&gt; 육성우에 대한 완전배합사료의 영양소 권장수준 </b></p>
						<table className="tablestyle">
							<tr>
								<td>월령 기준체중(kg)<br/>구분</td>
								<td>4-6<br/>135</td>
								<td>7-12<br/>250</td>
								<td>13-18<br/>360</td>
								<td>19-22<br/>495</td>
							</tr>
							<tr>
								<td rowSpan="2">건물섭취량(kg)<br/>체중비(%)</td>
								<td>3.2∼4.1</td>
								<td>5.4∼7.2</td>
								<td>7.7∼9.5 </td>
								<td>9.9∼11.7</td>
							</tr>
							<tr>
								<td>2.7∼3.0</td>
								<td>2.7</td>
								<td>2.5</td>
								<td>2.0</td>
							</tr>
							<tr>
								<td></td>
								<td colSpan="4">영양소함량 (건물기준%)</td>
							</tr>
							<tr>
								<td>조 단 백 질</td>
								<td>15~16</td>
								<td>14~15</td>
								<td>12</td>
								<td>12</td>
							</tr>
							<tr>
								<td>가소화영양소총량</td>
								<td>68∼74</td>
								<td>62∼68</td>
								<td>60∼63</td>
								<td>58∼60</td>
							</tr>
							<tr>
								<td>칼 슘(Ca)</td>
								<td>0.60∼0.75</td>
								<td>0.50∼0.60</td>
								<td>0.50∼0.60</td>
								<td>0.40∼0.50</td>
							</tr>
							<tr>
								<td>인(P)</td>
								<td>0.35∼0.40</td>
								<td>0.32∼0.35</td>
								<td>0.28∼0.32</td>
								<td>0.28∼0.30</td>
							</tr>
							<tr>
								<td>조 섬 유</td>
								<td>15</td>
								<td>15</td>
								<td>18</td>
								<td>20</td>
							</tr>
							<tr>
								<td>조사료비율(최저)</td>
								<td>20~60</td>
								<td>30~90</td>
								<td>40~100</td>
								<td>40~100</td>
							</tr>
							<tr>
								<td>비타민A(IU/kg건물)</td>
								<td>450</td>
								<td>450</td>
								<td>450</td>
								<td>450</td>
							</tr>
							<tr>
								<td>비타민D(IU/kg건물)</td>
								<td>63</td>
								<td>63</td>
								<td>63</td>
								<td>63</td>
							</tr>
						</table><br/>
						<p><b>&lt;표 68&gt; 시판 육성용사료의 조성분 </b></p>
						<table className="tablestyle">
							<tr>
								<td>구 분</td>
								<td>용 도</td>
								<td>조단백질</td>
								<td>가소화영양소총량</td>
								<td>칼슘 </td>
								<td>인</td>
							</tr>
							<tr>
								<td></td>
								<td></td>
								<td colSpan="4">···································· % ····································</td>
							</tr>
							<tr>
								<td>어린송아지</td>
								<td>3개월령이하</td>
								<td>19</td>
								<td>73</td>
								<td>0.6</td>
								<td>0.4</td>
							</tr>
							<tr>
								<td>중 송아지</td>
								<td>4∼10개월령 </td>
								<td>16</td>
								<td>72</td>
								<td>0.6</td>
								<td>0.4</td>
							</tr>
							<tr>
								<td>큰 송아지</td>
								<td>11개월∼임신</td>
								<td>13</td>
								<td>68</td>
								<td>0.6</td>
								<td>0.4</td>
							</tr>
							<tr>
								<td>임 신 우 </td>
								<td>임 신 우</td>
								<td>14</td>
								<td>67</td>
								<td>0.7</td>
								<td>0.5</td>
							</tr>
							<tr>
								<td>농축 사료</td>
								<td>자가배합용</td>
								<td>12</td>
								<td>79</td>
								<td>0.5</td>
								<td>0.3</td>
							</tr>
						</table>
					</li>
				</ul>
			</div>
  );
}

export default Do61;
