import React from 'react';

import Pic504 from 'assets/images/sub/_images/pic504.jpg';
import Pic505 from 'assets/images/sub/_images/pic505.jpg';
import Pic506 from 'assets/images/sub/_images/pic506.jpg';

function Do88() {

  return (
    <div className="techinfoview">
				<h1>제 1위 식체</h1>
				<p className="pstyle">젖소의 소화기병 중에 가장 다발하는 질병으로 과식이나 불량사료 또는 소화하기 어려운 이물(예:나일론줄, 비닐등)을 섭취하여 제1위내에 사료가 충만함으로써 1위가 급격히 확장하여 수축력을 잃고 소화장애를 일으키는 것이다.</p>
				<div className="leftpart">
					<h2>원인</h2>
					<ul>
						<li>체중 300kg, 2세의 홀스타인에 밀기울 10kg을 투여(6kg은 자유섭식, 4kg은 강제투여)하여 1위 식체를 유발시킴. 식욕, 반추, 1위 운동정지, 복부팽대의 증상을 보였다. </li>
					</ul>
					<h2>증상</h2>
					<ul>
						<li>급성
							<ul>
								<li>식후 2∼3시간이 지나면 기력이 떨어지고 반추운동이 없고, 일어선채로 괴로워하며 신음소리를 낸다. </li>
								<li>제1위가 탄탄하게 부풀어 좌복부가 튀어나온다. 직장(直腸)에 손을 넣어보면 단단해진 1위벽이 골반강(骨盤腔)내까지 밀려나온 것을 알 수 있다. </li>
								<li> 증상이 심해지면 호흡이 얕고 빨라지면 눈의 결막은 암자색(暗紫色)을 띤다.</li>
							</ul>
						</li>
						<li>만성
							<ul>
								<li>우사내 바람막이용 비닐이나 끈등을 많이 먹을 경우는 대개 특별한 외부증상이 없고 식욕만이 감소한다.</li>
							</ul>
						</li>
					</ul>
					<h2>목장에서의 처치</h2>
					<ul>
						<li>1∼2일간 굶기면서 제1위의 맛사아지와 가벼운 운동을 시킴으로써 위의 운동을 촉진시킨다. </li>
						<li>하제(下劑)를 먹이든가 관장을 실시하여 위 내용물을 배설 시킨 뒤 건위정장제를 먹인다. </li>
						<li>중증(重症)일때는 수의사에 의뢰하여 조치를 받는다. 1위 절개술에 의한 처치도 가능하다. </li>
					</ul>
				</div>
				<div className="rightpart">
					<img src={Pic504} className="nonborder" alt=""/>
					<p>제1위 식체에 걸린 소</p>
					<img src={Pic505} className="nonborder" alt=""/>
					<p>제1위 식체증에 의해 팽창된 위를 절개한 모습</p>
					<img src={Pic506} className="nonborder" alt=""/>
					<p>제1위 식체증에 의해 확장된 제1위</p>
				</div>
			</div>
  );
}

export default Do88;