export const ROUTE_PAGE = {
  Business: {
    title: '사업안내',
    child: {
      Companyintro: '젖소개량사업소 소개',
      Greetings: '인사말',
      History: '연혁 및 일반현황',
      FreezeSemen: '동결정액생산·공급',
      BestCowTest: '유우군능력검정',
      DescendantCow: '후대검정',
      SuperiorCow: '씨수소 사양관리',
      Staff: '직원현황',
      Visit: '찾아오시는 길',
    },
  },
  Performance: {
    title: '검정성적조회',
    child: {
      MonthFarmhouse: '이달의 검정농가',
      PerformanceCheck: '농가별 검정성적조회',
      DieoutCowAnalysis: '경제수명 및 도태우분석',
      FindCow: '개체찾기',
      PlannedMatingCow: '유전평가 계획교배',
      ProvinceStatus: '행정구역별 검정현황',
      GeneAnalysis: '유전체 분석',
    },
  },
  Progency: {
    title: '후대검정조회',
    child: {
      SuperiorCowSearch: '한국형 후보씨수소 조회',
    },
  },
  Ox: {
    title: '씨수소안내',
    child: {
      OxSearch: '씨수소 검색',
      BuySemen: '정액구입',
      OxData: '씨수소 자료해설',
    },
  },
  Info: {
    title: '최신정보',
    child: {
      MainNews: '주요소식',
      NotiAndEvents: '알림&행사',
      Overseas: '해외토픽',
      TechnicalInfo: '기술정보',
      IndustryNews: '업계소식',
      PromotionalVideo: '홍보동영상',
    },
  },
  Data: {
    title: '정보창고',
    child: {
      Notice: '공지사항',
      FieldPicture: '현장포토',
      Publication: '젖소검정사업보고서',
      QnaTechnicalInfo: '기술정보',
      HowtoReport: '젖소검정성적표보는법',
      Dictionary: '낙농용어사전',
      RelatedSites: '관련사이트',
    },
  },
  // 2024 - 02 - 20 고객사 요청으로 잠시 숨김
  // Album: {
  //   title: '청정육종농가',
  //   child: {
  //     Neulmok: '늘목목장',
  //     Sunhae: '순해목장',
  //     Wonju: '원주목장',
  //     Seonggang: '성강목장',
  //     LAndH: 'L&H제네틱스',
  //     Guwon: '구원목장',
  //     Icheol: '이철목장',
  //     Dupyeong: '두평목장',
  //   },
  // },
  Guide: {
    title: '이용안내',
    child: {
      SiteMap: '사이트맵',
      PersonalInfo: '개인정보 처리방침',
      Email: '이메일무단수집거부',
    },
  },
};

export const DCIC_ROUTE_PAGE = {
  dciFarm: {
    title: '농가용',
    child: {
      NewMain: '26개월 우군 검정성적 변화',
      NewCowList: '월별 개체 검정성적',
      Graph: '검정성적에 의한 유성분 분석보고서',
      NewPlan: '월간 우군관리 계획표',
      NewCowBun: '번식성적 분석보기',
      OxList: '씨수소 혈통 및 성적조회',
      NewSemenSelection: '농협정액 계획교배 서비스',
      NewFarmgraph: '월별 검정현황 그래프',
      NewFarmSemen: '아비(정액)별 딸소 조회',
      NewFarmMcow: '어미별 딸소 조회',
    },
  },
  dciExpert: {
    title: '전문가용',
    child: {
      NewCowDcicAnal: '검정성적 종합분석 보고서',
      NewAnal01: '종합 기술분석표',
      NewNoninschkview: '미수태우에 대한 검정성적',
      NewFCMFPCMECM: 'FCM, FPCM, ECM을 이용한 분석',
      NewBmonthcomp: '전월 대비 검정성적',
      NewCowAnal101: '도태우 분석 보고서',
      NewTotalprint: '컨설팅 전체 현황 리포트',
    },
  },
  dciEval: {
    title: '유전능력 평가보기',
    child: {
      NewGentype20: '정액 GENTYPES(10MS) 보기',
      NewGentype45: '정액 GENTYPES(11MS) 보기',
      NewCowreportavg: '농가 유전능력 평가보기',
    },
  },
  dciDoc: {
    title: '검정자료 받기',
  },
  dciInfo: {
    title: '제3자 정보 제공 동의',
  },
};

export const HISTORY = {
  '현재~2015': [
    {
      year: '2024',
      list: [
        {
          month: 12,
          info: '한국형 씨수소 아웃터불(208HO00880), 샤인(208HO00878) 선발',
        },
        {
          month: 10,
          info: '젖소 경제수명 및 도태분석 알고리즘 고도화 용역 추진(한국인공지능학회)',
        },
        {
          month: 8,
          info: '유우군능력검정 효율성 증대 용역 추진(한국농수산대학교)',
        },
        {
          month: 6,
          info: '한국형 씨수소 밀크(208HO00850), 제니스(208HO00853), 레드불(208HO00865), 타우러스(208HO00842) 선발',
        },
        {
          month: 6,
          info: '청정육종농가 제2024-21 토끼실목장 선정',
        },
      ],
    },
    {
      year: '2023',
      list: [
        {
          month: 12,
          info: '한국형 씨수소  아크(208HO00809), 베네핏(208HO00831), 엣지(208HO00829) 선발2024',
        },
        {
          month: 8,
          info: '305일 유량 보정계수 고도화 용역 추진',
        },
        {
          month: 6,
          info: '한국형 씨수소 보스(208HO00763), 비트박스(208HO00778), 굿프렌드(208HO00775) 선발',
        },
        {
          month: 3,
          info: '젖소 정액 수출(네팔 2 ,000Str, 키르키스탄 3,000str)',
        },
      ],
    },
    {
      year: '2022',
      list: [
        {
          month: 12,
          info: '한국형 씨수소 봄베로(208HO00712), 타겟(208HO00714), 빅쇼(208HO726) 선발',
        },
        {
          month: 12,
          info: '젖소 정액 수출(네팔, 2,000Str)',
        },
        {
          month: 6,
          info: '젖소 정액 수출(우즈베키스탄수량, 2 ,000Str)',
        },
        {
          month: 6,
          info: '한국형 씨수소 볼케이노 (208HO00693), 킹덤(208HO00690) 선발',
        },
        {
          month: 3,
          info: '젖소 정액 수출(키르키스스탄 2 ,000Str)',
        },
        {
          month: 1,
          info: '청정육종농가 제2022-20 준목장 선정',
        },
      ],
    },
    {
      year: '2021',
      list: [
        {
          month: 12,
          info: '한국형씨수소 굿쉐이프(208HO00668), 롤렉스(208HO00684), 티오피(208HO00682) 선발',
        },
        {
          month: 8,
          info: '젖소 정액 수출(파키스탄, 20,000Str)',
        },
        {
          month: 6,
          info: '한국형씨수소 백신(208HO00663), 쿼트로(208HO00669) 선발',
        },
        {
          month: 5,
          info: '젖소 정액 수출(에티오피아, 15,000Str)',
        },
        {
          month: 3,
          info: '검정데이터 연계MOU 체결(젖소개량사업소, 천안공주낙협, 키우소 방성보 대표)',
        },
        {
          month: 1,
          info: '청정육종농가 제2020-19 석진목장 선정',
        },
      ],
    },
    {
      year: '2020',
      list: [
        {
          month: 12,
          info: '청정육종농가 제2020-18 대흥목장 선정 젖소 유전체참조집단 수집 및 분석(씨수소102두, 암소 590두(10호)) 한국형씨수소 싸이몬(208HO10617), 싼타페(208HO10601) 선발',
        },
        {
          month: 11,
          info: '젖소 경제수명 및 도태분석 알고리즘 개발(서울대학교 통계학과 김용대교수)',
        },
        {
          month: 8,
          info: '젖소개량 빅데이터 클라우드 데이터베이스 구축',
        },
        {
          month: 6,
          info: '한국형 씨수소 로또(208HO10607), 히어로(208HO10623), 썬샤인(208HO10624) 선발',
        },
        {
          month: 4,
          info: '젖소 유전체분석칩 농협NHseekV1 개발',
        },
      ],
    },
    {
      year: '2019~2018',
      list: [
        {
          month: 12,
          info: '한국형씨수소 엘사, 제네시스 선발',
        },
        {
          month: 11,
          info: '유전평가 계획교배 웹서비스 게시',
        },
        {
          month: 10,
          info: '신규 청정육종농가 동북목장(경기 이천시)',
        },
        {
          month: 6,
          info: '한국형 씨수소 비티에스,고스트,알엠,스페셜 선발',
        },
        {
          month: 12,
          info: '한국형씨수소 크라운,그란데,모하비,럭키 선발',
        },
        {
          month: 5,
          info: '한국형씨수소 골디 선발',
        },
      ],
    },
    {
      year: '2017',
      list: [
        {
          month: 12,
          info: '한국형씨수소 니아스,아이언,젠틀맨 선발 말레이시아 한국형 동결정액 최조 수출 아프리카 우간다, 한국형 동결정액 4차 수출',
        },
        {
          month: 10,
          info: '제2회 젖소개량발전포럼 개최',
        },
        {
          month: 4,
          info: '한국형씨수소 디시젼,제우스 선발',
        },
      ],
    },
    {
      year: '2016',
      list: [
        {
          month: 12,
          info: '한국형씨수소 크로스,패트롤 선발',
        },
        {
          month: 11,
          info: '아프리카 우간다, 한국형 동결정액 3차 수출',
        },
        {
          month: 4,
          info: '한국형씨수소 에이스,초이스 선발 제 1회 젖소개량발전포럼 개최',
        },
        {
          month: 2,
          info: '신규 청정육종농가 서울대학교(강원 평창군)',
        },
      ],
    },
    {
      year: '2015',
      list: [
        {
          month: 7,
          info: '아프리카 우간다, 한국형 동결정액 연속 수출',
        },
        {
          month: 4,
          info: '한국형씨수소 나이트,비스토,킹스맨,스타트,싸이먼 선발',
        },
        {
          month: 2,
          info: '신규 청정육종농가 곰 목장(강원 철원군)',
        },
      ],
    },
  ],
  '2014~2010': [
    {
      year: '2014',
      list: [
        {
          month: 10,
          info: '한국형씨수소 발란스 선발',
        },
        {
          month: 7,
          info: '아프리카 우간다, 한국형 동결정액 첫 수출',
        },
        {
          month: 4,
          info: '한국형 씨수소 신선봉 선발',
        },
      ],
    },
    {
      year: '2013',
      list: [
        {
          month: 10,
          info: '한국형씨수소 망운대,남대봉 선발',
        },
        {
          month: 5,
          info: '신규청정육종농가 두평 목장 (경기 안성시)',
        },
        {
          month: 2,
          info: '젖소 국제유전능력평가 참여(체험형질)',
        },
      ],
    },
    {
      year: '2012',
      list: [
        {
          month: 12,
          info: '유우군능력검정검우 16kg 초과 생산우 3두 최초탄생',
        },
        {
          month: 9,
          info: '한국형씨수소 국사봉 선발 젖소개량사업소 종축분산사업장 준공(경북 영양군)',
        },
        {
          month: 5,
          info: '신규 청정육종농가 구원 목장 (경북 김천시), 이철목장(경기용인시)',
        },
      ],
    },
    {
      year: '2011',
      list: [
        {
          month: 12,
          info: '젖소 국제유전능력펑가 참여 (생산형질) 한국형씨수소 지구 선발',
        },
        {
          month: 9,
          info: '성감별 정액 생산 공급',
        },
        {
          month: 5,
          info: '신규 청정육종농가 엘엔에이치(경기 안성시)',
        },
      ],
    },
    {
      year: '2010',
      list: [
        {
          month: 12,
          info: '한국형씨수소 유진,유철,유리 선발',
        },
        {
          month: 5,
          info: '신규 청정육종농가 성강 목장(전남 순천시) ',
        },
      ],
    },
  ],
  '2009~2000': [
    {
      year: '2009',
      list: [
        {
          month: 6,
          info: '한국형씨수소 선도 선발',
        },
        {
          month: 5,
          info: '신규 청정육종농가 늘목 목장(경기 연천군), 순해목장(경기 포천시), 새벽 목장(경기 파주시), 희둘영 목장(경기 연천군),원주 목장(경기 양주시),미윤 목장(충남 부여군), 대원목장(경기 양주시)',
        },
      ],
    },
    {
      year: '2008',
      list: [
        {
          month: 8,
          info: '한국형씨수소 포비든 선발',
        },
        {
          month: 5,
          info: '한국형씨수소 생산을 위한 청정육종농가 사업개시 서울우유 생명공학연구소(경기 양평군), 국립축산과학원(충남 천안시)',
        },
      ],
    },
    {
      year: '2007',
      list: [
        {
          month: 6,
          info: '사육단계 HACCP 젖소인증 최초획득',
        },
        {
          month: 5,
          info: '한국형씨수소 나단,태즈 선발',
        },
      ],
    },
    {
      year: '2006',
      list: [
        {
          month: 7,
          info: '한국형씨수소 자스펜,라멘 선발',
        },
      ],
    },
    {
      year: '2005',
      list: [
        {
          month: 5,
          info: '한국형씨수소 더글라스 선발 한국형씨수소 잔쯔,새로미,새보미 선발',
        },
      ],
    },
    {
      year: '2004',
      list: [
        {
          month: 9,
          info: '세계 최초 검정성적 휴대폰서비스 개시',
        },
      ],
    },
    {
      year: '2003',
      list: [
        {
          month: 12,
          info: '한국형씨수소 대청 선발',
        },
      ],
    },
    {
      year: '2002',
      list: [
        {
          month: 7,
          info: '한국형씨수소 알란 선발',
        },
      ],
    },
    {
      year: '2001',
      list: [
        {
          month: 12,
          info: '유우군능력검정우 10만kg초과 생산우 2두 최초탄생 한국형씨수소 캐쉬 선발',
        },
        {
          month: 5,
          info: '한국형씨수소 존 선발',
        },
      ],
    },
    {
      year: '2000',
      list: [
        {
          month: 11,
          info: '한국형씨수소 신지도,독도 선발',
        },
        {
          month: 5,
          info: '검정성적 인테넷조회서비스 최초개시 ',
        },
      ],
    },
  ],
  '1999~1980': [
    {
      year: '1999',
      list: [
        {
          month: 10,
          info: '한국형씨수소 제주도 선발',
        },
        {
          month: 5,
          info: '한국형씨수소 루카스 선발',
        },
        {
          month: 3,
          info: '젖소동결정액 해외수출',
        },
      ],
    },
    {
      year: '1998',
      list: [
        {
          month: 9,
          info: '한국형씨수소 구스타프, 팩터 선발',
        },
      ],
    },
    {
      year: '1997',
      list: [
        {
          month: 9,
          info: '한국형씨수소 벤커 선발',
        },
        {
          month: 4,
          info: '한국형씨수소 물푸레 선발',
        },
      ],
    },
    {
      year: '1996',
      list: [
        {
          month: 7,
          info: '한국형씨수소 래비선발',
        },
      ],
    },
    {
      year: '1995',
      list: [
        {
          month: 10,
          info: '한국형씨수소 아브리함,리빌,커스터,브루스터 선발',
        },
        {
          month: 3,
          info: '한국형보증씨수소 최최선발(한강, HK-001) 칼리스트,금강,카슨 선발',
        },
      ],
    },
    {
      year: '1992',
      list: [
        {
          month: 9,
          info: '자가 인공수정교육 실시',
        },
        {
          month: 7,
          info: '검정성적처리 프로그램 자체 개발',
        },
      ],
    },
    {
      year: '1987',
      list: [
        {
          month: 4,
          info: '축협중앙회 개량과에서 유우개량사업소로 업무 이관',
        },
        {
          month: 1,
          info: '후대검정사업 시행',
        },
      ],
    },
    {
      year: '1980',
      list: [
        {
          month: 1,
          info: '스트로우 동결정액 생산',
        },
      ],
    },
  ],
  '1979~1960': [
    {
      year: '1979',
      list: [
        {
          month: 9,
          info: '젖소산유능력검정(유우군능력검정사업) 착수',
        },
        {
          month: 2,
          info: '유우군능력검정사업 실시기관 지정(농림수산부고시 제 3049호)',
        },
        {
          month: 1,
          info: '후대검정필 씨수소 최초 도입 ',
        },
      ],
    },
    {
      year: '1975',
      list: [
        {
          month: 1,
          info: '가축개량사업소 서삼릉목장 통합',
        },
      ],
    },
    {
      year: '1974',
      list: [
        {
          month: 9,
          info: '한국형씨수소 벤커 선발',
        },
      ],
    },
    {
      year: '1973',
      list: [
        {
          month: 1,
          info: '앰플 냉동정액 생산 공급',
        },
      ],
    },
    {
      year: '1970',
      list: [
        {
          month: 3,
          info: '정액 희석액(T3)자체 개발',
        },
      ],
    },
    {
      year: '1969',
      list: [
        {
          month: 6,
          info: '서삼릉 목장 준공',
        },
      ],
    },
    {
      year: '1968',
      list: [
        {
          month: 9,
          info: '가축인공수정소 사옥이전(경기도 고양시)',
        },
      ],
    },
    {
      year: '1963',
      list: [
        {
          month: 1,
          info: '젖소 액상 정액 최초 생산',
        },
      ],
    },
    {
      year: '1962',
      list: [
        {
          month: 10,
          info: '젖소 씨수소 도입(키퍼,H-001)',
        },
        {
          month: 9,
          info: '가축인공수정교육 최초실시',
        },
        {
          month: 1,
          info: '가축인공수정교육 최초실시',
        },
      ],
    },
  ],
};

export const buyContact = [
  {
    type: '경기',
    area: '경기북부',
    name: '우종원 팀장',
    phone: '017-631-4492',
  },
  {
    type: '경기',
    area: '경기북부',
    name: '대리점 김정열(김호철)',
    phone: '010-3791-8152',
  },
  {
    type: '경기',
    area: '경기남부',
    name: '배형진 팀장',
    phone: '010-6511-1358',
  },
  {
    type: '경기',
    area: '경기남부',
    name: '대리점 허병무',
    phone: '010-4509-8527',
  },
  {
    type: '강원',
    area: '강원지역',
    name: '안정호 팀장',
    phone: '010-7347-9465',
  },
  {
    type: '충북',
    area: '충북지역',
    name: '김홍룡 팀장',
    phone: '010-9556-6256',
  },
  {
    type: '충남',
    area: '본부',
    name: '판매팀',
    phone: '041-661-4661~3',
  },
  {
    type: '충남',
    area: '충남광역',
    name: '전창우 팀장',
    phone: '010-7652-0980',
  },
  {
    type: '충남',
    area: '충남지역',
    name: '박 준 팀장',
    phone: '010-7466-6038',
  },
  {
    type: '경북',
    area: '경북동부',
    name: '오성민 팀장',
    phone: '010-9494-3079',
  },
  {
    type: '경북',
    area: '경북서부',
    name: '변원석 팀장',
    phone: '010-9516-1848',
  },
  {
    type: '경남',
    area: '경남지역',
    name: '최성원 팀장',
    phone: '010-9326-3203',
  },
  {
    type: '경남',
    area: '호남지역',
    name: '대리점 여진구',
    phone: '010-6288-6587',
  },
  {
    type: '경남',
    area: '영남지역',
    name: '대리점 심덕용',
    phone: '010-2590-1651',
  },
  {
    type: '전북',
    area: '전북지역',
    name: '지재호 팀장',
    phone: '010-7548-0102',
  },
  {
    type: '전남',
    area: '전남지역',
    name: '서재덕 팀장',
    phone: '010-6609-7846',
  },
];
