import React from 'react';

import pic642 from 'assets/images/sub/_images/pic642.jpg';
import pic643 from 'assets/images/sub/_images/pic643.jpg';
import pic644 from 'assets/images/sub/_images/pic644.jpg';
import pic645 from 'assets/images/sub/_images/pic645.jpg';
import pic646 from 'assets/images/sub/_images/pic646.jpg';

function Do128() {

  return (
    <div className="techinfoview">
      <h1>송아지 배꼽병</h1>
      <p className="pstyle">탯줄을 통해 세균감염으로 인해 배꼽과 그 주위에 화농(化膿)을 일으키는 염증성 질환이다. 주로 6개월 미만의 송아지에 발생한다. </p>
      <div className="leftpart">
        <h2>원인</h2>
        <ul>
          <li>탯줄 단면의 습기는 세균감염을 조장한다. 
            <ul>
              <li>탯줄은 생후 10일 정도면 건조 탈락하지만 뇨색(尿索) 폐쇄가 불완전하면 오줌이 계속 스며나와 젖어 있다. </li>
              <li>분만시에 탯줄이 자연스레 끊어지지 않을 때 건조가 늦어 감염을 일으키기 쉽다. </li>
              <li>숫송아지는 배뇨(排尿)로 인해 배꼽부위가 젖기 쉽다.</li>
            </ul>
          </li>
          <li>동거하는 (다른)송아지끼리 배꼽을 빨므로써 감염되기 쉽다. </li>
          <li>파리 등의 해충에 의한 오염으로 감염되기 쉽다. </li>
        </ul>
        <h2>증상</h2>
        <ul>
          <li>탯줄 단면이 축축하고 부어 있으며, 흑갈색으로 변색되고, 고름이 나오며, 악취가 난다. </li>
          <li>염증부위에 열이 나고 통증을 보인다. </li>
          <li>탯줄 단면의 썩은 부분이 떨어져 나간 뒤 궤양이나 피하에 농양이 형성되기도 한다. </li>
          <li>송아지의 행동은 기운이 없고, 포유에 관심이 없으며, 자주 드러눕는 등 전신증상을 보인다. </li>
        </ul>
        <h2>목장에서의 처치</h2>
        <ul>
          <li>탯줄은 손으로 끊도록 하며 가위나 칼로 자르는 것은 좋지 않다. </li>
          <li>탯줄이 끊어진 후 마르기 전에 배꼽줄을 펴고 강옥도 2cc를 우측과 같은 주사기로 주입한다. </li>
          <li>사전예방을 위해서 출생 직후부터 개별우리에서 격리 사유한다. </li>
          <li>일단 염증이 발생하면 배꼽 주위의 털을 깎고 소독약으로 세척한 다음 절개하여 고름과 썩은 부위를    제거한다. </li>
          <li>전신증상이 나타날때는 설파제나 항생제를 주사해야 한다.</li>
        </ul>
      </div>
      <div className="rightpart">
        <img src={pic642} className="nonborder" alt=""/>
        <img src={pic643} className="nonborder" alt=""/>
        <img src={pic644} className="nonborder" alt=""/>
        <img src={pic645} className="nonborder" alt=""/>
        <img src={pic646} className="nonborder" alt=""/>
      </div>
    </div>
  );
}

export default Do128;