import React from 'react';

import Pic571 from 'assets/images/sub/_images/pic571.jpg';
import Pic572 from 'assets/images/sub/_images/pic572.jpg';
import Pic573 from 'assets/images/sub/_images/pic573.jpg';

function Do107() {

  return (
    <div className="techinfoview">
      <h1>소 유행열</h1>
      <p className="pstyle">일명 유행성 감기라고도 알려져 있는 열성전염병으로 감염된 소와의 직접, 간접의 접촉에 의해 전파되고 전염속도가 빠르며 갑자기 고열을 나타내는 것이 특징이다.  병원체는 유행열 바이러스로서 대개 8∼11월 사이에 유행한다. </p>
      <div className="leftpart">
        <h2>증상</h2>
        <ul>
          <li>40℃이상의 고열이 갑자기 나타난다. </li>
          <li>고열과 더불어 유량이 저하한다. </li>
          <li>식욕감퇴와 콧물을 흘리며 기침을 한다. </li>
          <li>오한으로 부들부들떤다. </li>
          <li>증세가 더욱 악화되면 인후와 식도가 마비되어 음식물을 삼킬 수 없게 된다. </li>
          <li>심한 관절통을 일으켜 기립불능이 된다. </li>
        </ul>
        <h2>목장에서의 처치</h2>
        <ul>
          <li>충분히 깔짚을 깔아주며, 등에 모포를 씌우고 따뜻히 하여 안정시킨다. </li>
          <li>열이 높을때는 페니실린 등 항생제를 주사한다. </li>
          <li>인후두 마비증으로 음식물을 먹지 못할때는 카테타를 이용하여 수분과 영양분을 공급한다. </li>
          <li>기립불능시는 욕창이 생기지 않도록 2∼3시간 마다 소를 돌아눕힌다.</li>
        </ul>
      </div>
      <div className="rightpart">
        <img src={Pic571} className="nonborder" alt=""/>
        <img src={Pic572} className="nonborder" alt=""/>
        <img src={Pic573} className="nonborder" alt=""/>
      </div>
    </div>
  );
}

export default Do107;