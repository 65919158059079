import React from 'react';

function Fo57() {

  return (
    <div className="techinfo_view">
				<p className="viewTitle">백색근육증</p>
				<dl>
					<dt>발병대상</dt>
					<dd>송아지, 새끼양, 새끼 말</dd>
				</dl>
				
				<dl>
					<dt>원인</dt>
					<dd>셀레늄 함량이 0.02ppm 이하인 사료를 계속 섭취하므로써 셀레늄 결핍이 일어난다. </dd>
				</dl>
				
				<dl>
					<dt>징후 및 증상</dt>
					<dd>송아지의 경우 백색 근육증은 쇠약과 기립불능 및 심장마비 등을 일으킨다. 새끼양의 경우는 움직일 때 주로 후구에 경직 현상이 오며 때로는 앞다리나 어깨에까지 나타나는 수도 있다. 그러나 등이 굽으면 성장에 저해를 받는다. 성장 발육이 빠른 어린양에 특히 발병율이 높다.</dd>
					<dd>보통 새끼양이나 망아지보다 송아지에서 심부전증이 더 발생되며 특히 갑작스런 운동시에는 치명적이다. 송아지와 새끼양은 심장과 다른 근육 부위에 유사한 병리학적 장애가 나타난다. </dd>
				</dl>
				
				<dl>
					<dt>질병분포와 손실규모</dt>
					<dd>백색근육증은 지리적으로 호주, 캐나다, 핀랜드, 이탈리아, 일본, 뉴질랜드, 노르웨이, 스코틀랜드, 아프리카 남부, 스웨덴, 미국, 소련 및 유고슬라비아 등지에서 보고되고 있다.</dd>
					<dd>미국에서 이 질병은 광범위하게 나타나지만 특히 동서부 연안지방에서 심하게 나타난다. 미국에서 셀레늄 함량을 기준으로 저, 중, 고 함유 지역 모두에서 나타남을 알 수 있다. 송아지나 새끼양에서 폐사나 생존율 저하 및 예방 프로그램시의 높은 비용때문에 경제적 손실이 크다. 폐사율은 50%에 달하며 평균 15%이고 치료를 하지 않은 가축의 경우 폐사율이 80%에 이른다. </dd>
				</dl>
				
				<dl>
					<dt>치료</dt>
					<dd>이런 증상이 있는 가축에는 조기에 셀레늄염 또는 비타민 E를 kg체중당 0.25mg의 비율로 근육내 주사한다. 이 치료는 2주동안 반복하며 4주를 초과하지 않게 한다. (주의) 연방정부 법규에는 수의사 면허가 있는 사람이 셀레늄을 주입하도록 규정하고 있다. 다만 도살 30일 전에는 사용하면 안된다. </dd>
				</dl>
				
				<dl>
					<dt>대책</dt>
					<dd>
						<p>셀레늄 요구량을</p>
						<ul className="listStyle04">
							<li>암소나 암양의 경우 임신 후기 1/3기간 동안과 수유초기에 셀레늄염의 형태로 건물기준으로 0.3ppm을 보중해 준다.</li>
							<li>암소나 암양은 분만 1개월전에 상기의 셀레늄 또는 비타민 E 비율대로 근육 주사한다.</li>
						</ul>
					</dd>
				</dl>
				
				<dl>
					<dt>예방</dt>
					<dd>셀레늄을 사료에 첨가해 준다. 1987년 FDA에서는 소, 면양, 돼지, 가금 사료에 0.3ppm의 셀레늄을 첨가하도록 권장하고 있다. 또한 셀레늄이 포함된 미네랄 믹수를 셀래늄 결핍 지역에서 자유 급여하는 것이 유용하다. </dd>
				</dl>	
				
				<dl>
					<dt>참고</dt>
					<dd>
						<p>백색근육증은 대부분</p>
						<ul className="listStyle04 mg15f">
							<li>육성중인 송아지나 새끼양</li>
							<li>목초가 무성하고 셀레늄이 결핍된 토양에서 사양한 송아지나 새끼양에서 나타난다.</li>
						</ul>
						<p>이는 근육에 손상을 주기 때문에 심하면 어린 가축은 기아나 심부전증에 의해 폐사한다. 백색근육증은 비타민 E를 함유하지 않더라도 사료중에 불포화지방산 함량이 높으면 발생하지 않는다.</p>
					</dd>
				</dl>
				
			</div>
  );
}

export default Fo57;
