import React from 'react';

import pic614 from 'assets/images/sub/_images/pic614.jpg';
import pic615 from 'assets/images/sub/_images/pic615.jpg';
import pic616 from 'assets/images/sub/_images/pic616.jpg';
import pic617 from 'assets/images/sub/_images/pic617.jpg';
import pic618 from 'assets/images/sub/_images/pic618.jpg';
import pic619 from 'assets/images/sub/_images/pic619.jpg';
import pic620 from 'assets/images/sub/_images/pic620.jpg';
import pic621 from 'assets/images/sub/_images/pic621.jpg';
import pic622 from 'assets/images/sub/_images/pic622.jpg';
import pic623 from 'assets/images/sub/_images/pic623.jpg';
import pic624 from 'assets/images/sub/_images/pic624.jpg';
import pic625 from 'assets/images/sub/_images/pic625.jpg';
import pic626 from 'assets/images/sub/_images/pic626.jpg';
import pic627 from 'assets/images/sub/_images/pic627.jpg';
import pic628 from 'assets/images/sub/_images/pic628.jpg';
import pic629 from 'assets/images/sub/_images/pic629.jpg';
import pic630 from 'assets/images/sub/_images/pic630.jpg';

function Do121() {

  return (
    <div className="techinfoview">
      <h1>유방염</h1>
      <p className="pstyle">유방염은 병원균이 유두공(乳頭孔)을 통하거나 유두 또는 유방에 생긴 상처를 통해 유선(乳腺)내에 침입함으로써 유선 조직에 염증(炎症)이 생긴 상태를 말한다.</p>
      <div className="leftpart">
        <h2>원인</h2>
        <ul>
          <li>유방염을 일으키는 주요 원인균으로는 연쇄상구균, 포도상구균, 대장균, 곰팡이 등이 있다.</li>
          <li>세균은 유두공 또는 상처를 통해 유조(乳槽), 유선세포로 파급되고 유선 점막에 염증을 일으킨다. </li>
          <li>유즙은 분해되어 유산(乳酸)을 생성하여 염증을 악화시킨다. </li>
          <li>끝 젖을 완전히 짜내지 않았을 때는 유선관내에 유즙이 남아 이것이 응고 또는 변성되어 점막을 자극하여 유방염에 걸리기 쉬운 상태가 된다. </li>
          <li>착유기를 잘못 사용했을 경우(과착유, 부적당한 진공압과 맥동수 등)에도 유방염에 걸리기 쉽다.</li>
        </ul>
        <h2>증상</h2>
        <p>유방염은 증상 유무(有無)에 따라 임상형 유방염과 준임상형 유방염으로 나눌수 있고 증상도 이에 따라 다양하다. </p>
        <ul>
          <li>임상형 유방염
            <ul>
              <li>염증이 심할수록 유방이 부어 올라 통증이 심하다.</li>
              <li>유방내 실질 조직의 염증으로 유방에 열감이 있다. </li>
              <li>유즙은 산성이 되어 맛이 시며 색깔은 누렇거나 때로는 혈액이 섞여 적색을 띠며 세포점막의 탈락이나 유즙의 응고에 의해 하얀 혼탁물이 생긴다. </li>
              <li>유방염에 걸리면 유방 간질이 증식하여 유방이 경결되고 유량이 줄어든다. </li>
              <li>체온이 40℃이상으로 올라가면 식욕이 없어지기도 한다.</li>
            </ul>
          </li>	
          <li>준임상형 유방염
            <ul>
              <li>준임상형 유방염은 쉽게 임상형이 될 수 있기 때문에 명확히 구분하기 곤란한 때도 있으나 대체로 증상이 눈이 띄지 않으므로 정기적인 검사를 하여야 한다. </li>
              <li>외견상 임상 증상이 나타나지 않는다. </li>
              <li>유방내에 크고 작은 경결 부위(응어리)가 만져진다. </li>
              <li>C.M.T(씨.엠.티)검사 결과 양성 반응이 나타난다. </li>
            </ul>
          </li>
        </ul>
        <h2>진단</h2>
        <p className="pstyle">유방염의 발견이 지연되면, 증상이 악화되고 치료가 곤란하게 된다. 낙농가 대부분이 유방염을 조기에 발견하지 못하여 증상이 만성화 되는 수도 있고, 또한 생체의 반응에 따라서 급성으로 되었다가 만성화되는 유방염이 많은 것으로 생각된다. 그러므로 조기발견, 조기치료의 필요성이 늘 강조되고 있는 것이다. 유방염 특히 준임상형 유방염을 조기에 발견하기 위해서 농가에서 쉽게 검사할 수 있는 진단 방법은 다음과 같다. </p>
        <ul>
          <li>진단의 종류
            <ul>
              <li>스트립컵 검사법(Strip-cup-test)또는 흑포법(黑布法) </li>
              <li>씨. 엠. 티 검사법(C.M.T 검사법) </li>
              <li>PH(산도)검사(PH, 시험지법 등) </li>
              <li>전기 전도율 측정법 </li>
              <li>체세포 검사법</li>
            </ul>
          </li>
          <li>진단방법-제 I장 건강 진단편 참조</li>
        </ul>
        <h2>예방</h2>
        <p className="pstyle">젖소 유방염의 예방대책은 유방염의 발생을 억제시키는 가장 중요하고 기본적인 방법이며 경제적으로 보아도 가장 심한 손실을 막을 수 있는 법이라 할 수 있다. 예방관리의 요점은 일반적으로 목장에서 실시하고 있는 작업을 기준에 맞추어 하는 것이다. 즉 위생적인 착유, 사양관리, 착유후 유두 침지 소독, 착유전 유즙검사, 수의사의 정기검진, 유방염우에 대한 적절하고 빠른 조치, 그리고 착유기에 대한 정기적인 점검 등을 하면 되는 것이다. 그러므로 이러한 사항들은 젖소를 관리하는 사육자가 충분히 알고 있어야 한다. </p>
        <ul>
          <li>착유전 유즙검사
            <ul>
              <li>유방을 세척하기 전에 첫젖을 짜서 C.M.T. 검사나, 스트립컵검사 등을 매 착유전 실시한다. </li>
              <li>첫젖을 짜냄으로 유질 행상에 도움을 준다.</li>
              <li>첫젖을 짜는 요령은 그림과 같이 엄지와 집게 손가락으로 유두부착 부위를 꽉 쥐어 유두조의 우유가 유조로 역류하지 못하게 한다.</li>
            </ul>
          </li>
          <li>위생적인 착유
            <ul>
              <li>유방의 세척과 건조는 마리당 1장의 젖은 수건과 1장의 마른수건을 각각 사용하여 유방 전체를 씻지 말고 유두만을 씻고 건조시킨다.</li>
              <li>유방염은 착유중에 전염되므로 착유순서는 초산우, 건강우부터 시작하여 감염우는 맨 나중에 착유한다. </li>
              <li>후착유를 철저히 하여 잔유가 남지 않게 한다. </li>
              <li>후착유는 그림과 같이 밀크 크로우 위에 손은 얹고 지긋이 힘을 주면서 소의 앞쪽으로 15°각도로 당기며 15-30초 정도 유지 한 후 손을 떼어 준다.</li>
            </ul>
          </li>
          <li>착유 후 유두 침지 소독
            <ul>
              <li>착유후 유두공은 바로 닫히지 않고 4-6시간 동안 열려 있으므로 착유 직후 곧 바로 유두 침지를 해 주어야 한다. </li>
              <li>산전 유방염예방을 위해 유두 침지를 건유후 2주동안, 분만 2주전부터 계속해 주어야 한다. </li>
              <li>유두 침지제는 유두에 자극을 주지 않고 소독 효과가 좋아야 한다. 
                <ul>
                  <li>클로르 헥사딘(chlorhexadine) 0.5% 용액 </li>
                  <li>요오드(Iodine) 0.5∼1%용액 </li>
                  <li>하이포 클로라이드(Hypochloride) 4% 용액</li>
                </ul>
              </li>
            </ul>
          </li>
          <li>건유 연고 주입
            <ul>
              <li>건유 시키는 모든소에 건유 연고를 주입한다. </li>
              <li>연고제 주입시는 반드시 알콜 솜으로 유두공 주위를 철저히 닦고 주입한다. </li>
              <li>건유기 치료의 잇점
                <ul>
                  <li>일반적으로 건유기 치료 효과가 비유기 치료효과보다 좋다. </li>
                  <li>건유중의 유방염 발생율을 줄인다.(새로운 유방염을 막는다.) </li>
                  <li>손상된 유선 조직이 분만전에 재생한다. </li>
                  <li>분만시 임상형 유방염 발생의 감소(산전 유방염 예방) </li>
                  <li>판매되는 우유가 약제에 오염되지 않는다. </li>
                </ul>
              </li>
            </ul>
          </li>
          <li>사육 환경의 개선 : 영양과 관리가 조잡한 소는 세균에 대한 저항력이 떨어져 유방염의 간접적인 원인이 된다.  
            <ul>
              <li>목초 중심의 사육 </li>
              <li>방목과 일광욕 </li>
              <li>기술에 알맞는 능력우의 사육 </li>
              <li>우사의 정기적인 소독</li>
              <li>우상의 건조</li>
            </ul>
          </li>
          <li>올바른 착유기 사용
            <ul>
              <li>모든 착유기는 500시간 사용시마다 착유기 검사기로 성능 검사를 받아 보아야 하며(각 지도소에 착유기 검사기구 비치)착유기를 항상 청소하고 소독해야 한다. </li>
              <li>진공 펌프 용량: 바켓츠 타입의 겨우, 최소 필요량은 동시 착유 두수(unit 수)×60l+50l이다. </li>
              <li>진공 조절기의 용량은 진공펌프의 용량과 최소한 같거나 커야 한다. </li>
              <li>맥동수는 1분에 45∼60회가 적당하며 맥동비는 50:50∼60:40이 좋다. </li>
              <li>유두컵은 무게는 약 3kg이 적당하다. </li>
              <li>라이나는 내경이 넓은 것 보다는 좁은 것이 좋으며 약 2.2cm가 적당하다.</li>
            </ul>
          </li>
          <li>기타
            <ul>
              <li>큰 유방의 보호(늘어진 유방은 늘어지지 않도록 천으로 잡아맨다.</li>
              <li>월 1회 이상 정기적인 CMT검사를 한다.</li>
            </ul>
          </li>
        </ul>
      </div>
      <div className="rightpart">
        <img src={pic614} className="nonborder" alt=""/>
        <img src={pic615} className="nonborder" alt=""/>
        <img src={pic616} className="nonborder" alt=""/>
        <img src={pic617} className="nonborder" alt=""/>
        <img src={pic618} className="nonborder" alt=""/>
        <img src={pic619} className="nonborder" alt=""/>
        <img src={pic620} className="nonborder" alt=""/>
        <img src={pic621} className="nonborder" alt=""/>
        <img src={pic622} className="nonborder" alt=""/>
        <img src={pic623} className="nonborder" alt=""/>
        <img src={pic624} className="nonborder" alt=""/>
        <img src={pic625} className="nonborder" alt=""/>
        <img src={pic626} className="nonborder" alt=""/>
        <img src={pic627} className="nonborder" alt=""/>
        <img src={pic628} className="nonborder" alt=""/>
        <img src={pic629} className="nonborder" alt=""/>
        <img src={pic630} className="nonborder" alt=""/>
      </div>
    </div>
  );
}

export default Do121;