import React from 'react';

import pic433 from 'assets/images/sub/_images/pic433.gif';
import pic434 from 'assets/images/sub/_images/pic434.gif';
import pic435 from 'assets/images/sub/_images/pic435.gif';
import pic436 from 'assets/images/sub/_images/pic436.gif';
import pic437 from 'assets/images/sub/_images/pic437.gif';
import pic438 from 'assets/images/sub/_images/pic438.gif';
import pic439 from 'assets/images/sub/_images/pic439.gif';

function Do223() {

  return (
    <div className="techinfoview">
      <h1>사료급여 및 저장시설-사료급여체계의 계획</h1>
      <p className="pstyle">&nbsp;&nbsp;&nbsp;사료급여체계를 선택할 경우, 일반적으로 노동력 및 사료급여 시간의 절감이 주요 결정요소가 된다. 사료급여체계를 계획할 때는,사육규모, 사료급여량, 취급 및 배합장비의 종류, 사료저장시설의 크기, 위치, 형태 등을 고려하여야 한다. <br/>&nbsp;&nbsp;&nbsp;장래 목장규모의 확장과 급여사료의 형태가 바뀔 것을 염두에 두어, 신축성이 있는 사료취급 및 저장시설을 마련하여야 한다. 일반적으로 사료급여체계를 기계화하면 할수록, 초기 자본과 유지비가 많이 소요된다. 그러나, 기계화는 노동력과 사료급여 시간을 절감시키는 장점이 있다. <br/>&nbsp;&nbsp;&nbsp;영양소 균형이 맞게 사료를 급여하기 위해서는, 소가 얼마만큼의 고형물을 섭취하는지 알아야 한다. 고형물 섭취량의 측정에는 단순한 추측, 농후사료의 무게는 측정하고 조사료의 섭취량은 추측, 농후사료와 조사료의 무게를 모두 측정하는 방법이 있다. <br/>&nbsp;&nbsp;&nbsp;사람이 편식을 하는 것처럼, 소도 개체에 따라 특정한 사료를 선호하기 때문에, 영양소를 균형 있게 섭취하지 못할 수도 있다. 따사서, TMR 사료를 급여하면 균형된 사료를 확실하게 섭취시킬 수 있다. <br/>&nbsp;&nbsp;&nbsp;관리가 소홀한 방목지의 목초를 소가 섭취할 경우, 목초의 양과 질을 파악할 수 없어 농후사료의 급여량을 결정하기가 무척 어렵다. 따라서, 방목지를 윤번으로 이용하게 하면, 목초의 양과 질의 파악이 쉬워져 농후사료 급여을 결정하기가 더 용이해 진다. </p>
      <h2>사료급여 체계의 계획 </h2>
      <p className="pstyle">먼저 축사, 사일로, 사료저장시설, 울타리, 급수시설, 배수시설,전기배선 상세도를 마련하고, 기존 건물과 시설물의 장래 이용 혹은 철거유무를 결정한다. 현재 상황에만 맞게 사료급여체계를 구축하면, 장래에 확장이 어렵고 경비가 많이 소요될 수 있으므로, 가능하면 향후 5년 후에 목장의 규모가 2배로 증가할 것을 고려하여 계획을 수립하는 것이 좋다. </p>
      <ul>
        <li>조사료 저장시설 
          <ul>
            <li>사일리지 저장시설
              <ul>
                <li>사일로의 형태와 관리는 사일리지의 질과 저장중 손실에 영향을 미친다. 사일로의 형태에 따른 사일리지의 저장중 손실이 표 95에 나타나 있는데, 어떤 경우에는 고형물이 손실은 적은 반면 사일리지의 질이 현저하게 손상된다.  소규모 목장에는 백 (bag)이나 스택 (stack) 사일로를 이용하는 것이 보다 경제적이며, 중규모 목장에는 직립사일로가 적합하고, 대규모 목장에는 수평형 사일로가 주로 이용된다. </li>
              </ul>
            </li>
            <li>수평형사일로 
              <ul>
                <li>직립사일로에 비하여, 건축비가 적게 소요되는 장점이 있으나, 저장손실이 많고, 바람, 비, 눈, 새 및 설치류에 의하여 손상을 입을 수 있는 단점이 있다. 수평형사일로는 사육규모가 큰 목장에서 경제적으로 사용할 수 있고, 사일리지를 채우고 빼내는데 시간이 적게 걸린다. 일반적으로 측면벽의 높이는 8´∼14´이며, 넓이는 30´∼60´이다. 이론적으로 적정한 사일리지재료의 길이는 3/8이며, 수분함량은 60%-70%이다. 사일로 바닥은 배수가 되도록 경사지게 만들고, 입구는 트랙터를 이용하여 단단하게 다진다(그림 106). 사일리지늬 표면은 물이 흐를 수 있도록 입구 쪽으로 경사지게 만들어져야 한다. 다지는 작업 중에 트랙터가 전복되는 사고가 발생할 수 있으므로 각별히 유의하여야 한다. </li>
              </ul>
              <p><b>&lt;표 95&gt; 수확과 저장 중에 소실되는 사일리지양의 추정</b></p>
              <table className="tablestyle">
                <tr>
                  <td>사일로 형태</td>
                  <td>수분</td>
                  <td>수확</td>
                  <td>삼출</td>
                  <td>가스</td>
                  <td>표면</td>
                  <td>합계</td>
                </tr>
                <tr>
                  <td rowSpan="6">전통적인 탑형 사일로</td>
                  <td colSpan="6">건물,%</td>
                </tr>
                <tr>
                  <td>80</td>
                  <td>2</td>
                  <td>7</td>
                  <td>9</td>
                  <td>3</td>
                  <td>21</td>
                </tr>
                <tr>
                  <td>70</td>
                  <td>2</td>
                  <td>1</td>
                  <td>8</td>
                  <td>4</td>
                  <td>15</td>
                </tr>
                <tr>
                  <td>65</td>
                  <td>4</td>
                  <td>-</td>
                  <td>8</td>
                  <td>3</td>
                  <td>15</td>
                </tr>
                <tr>
                  <td>60</td>
                  <td>6</td>
                  <td>-</td>
                  <td>6</td>
                  <td>3</td>
                  <td>15</td>
                </tr>
                <tr>
                  <td>50</td>
                  <td>8</td>
                  <td>-</td>
                  <td>5</td>
                  <td>3</td>
                  <td>16</td>
                </tr>
                <tr>
                  <td rowSpan="4">기밀 탑형사일로</td>
                  <td>70</td>
                  <td>2</td>
                  <td>1</td>
                  <td>7</td>
                  <td>-</td>
                  <td>10</td>
                </tr>
                <tr>
                  <td>60</td>
                  <td>6</td>
                  <td>-</td>
                  <td>5</td>
                  <td>-</td>
                  <td>11</td>
                </tr>
                <tr>
                  <td>50</td>
                  <td>8</td>
                  <td>-</td>
                  <td>4</td>
                  <td>-</td>
                  <td>12</td>
                </tr>
                <tr>
                  <td>40</td>
                  <td>11</td>
                  <td>-</td>
                  <td>4</td>
                  <td>-</td>
                  <td>15</td>
                </tr>
                <tr>
                  <td rowSpan="2">트렌지 또는 벙커 사일로<br/>(무포장)</td>
                  <td>80</td>
                  <td>2</td>
                  <td>6</td>
                  <td>10</td>
                  <td>6</td>
                  <td>24</td>
                </tr>
                <tr>
                  <td>70</td>
                  <td>2</td>
                  <td>1</td>
                  <td>9</td>
                  <td>9</td>
                  <td>21</td>
                </tr>
                <tr>
                  <td rowSpan="2">트렌지 또는 벙커 사일로<br/>(포장)</td>
                  <td>80</td>
                  <td>2</td>
                  <td>4</td>
                  <td>9</td>
                  <td>2</td>
                  <td>17</td>
                </tr>
                <tr>
                  <td>70</td>
                  <td>2</td>
                  <td>1</td>
                  <td>7</td>
                  <td>3</td>
                  <td>13</td>
                </tr>
                <tr>
                  <td rowSpan="2">스택사일로<br/>(무포장)</td>
                  <td>80</td>
                  <td>2</td>
                  <td>7</td>
                  <td>10</td>
                  <td>11</td>
                  <td>30</td>
                </tr>
                <tr>
                  <td>70</td>
                  <td>2</td>
                  <td>1</td>
                  <td>11</td>
                  <td>19</td>
                  <td>33</td>
                </tr>
                <tr>
                  <td rowSpan="2">스택사일로<br/>(포장)</td>
                  <td>80</td>
                  <td>2</td>
                  <td>5</td>
                  <td>8</td>
                  <td>2</td>
                  <td>17</td>
                </tr>
                <tr>
                  <td>70</td>
                  <td>2</td>
                  <td>-</td>
                  <td>7</td>
                  <td>4</td>
                  <td>13</td>
                </tr>
              </table>
              <p className="pstyle">사일리지를 채운 후, 검든 플라스틱 커버 (6 mil)로 사일리지 표면을 덮고, 타이어나 모래주머니 등과 같은 무거운 것을 커버 위에 올려놓아 커버를 단단하게 고정시켜 공기의 유입을 막고 커버가 바람에 날리지 않도록 한다. 타이어를 사용할 경우, 반으로 잘린 타이어를 사용함으로서 타이어의 필요 숫자를 줄이고 빗물이 타이어에 고이는 것을 방지할 수 있으며, 또한 타이어가 경사면으로 흘러 내려가지 않도록 고정시켜야 한다. 사일리지 급여를 위해 개봉할 경우, 가능한 한 개봉면적을 줄여 공기에 노출되는 부위를 최소화시켜 사일리지 부패를 줄인다 (그림 107; 표 96)</p>
              <img src={pic433} width="40%" alt=""/>
              <p>그림 106. 수평사일로에 사일리지 채우기</p>
              <img src={pic434} width="40%" alt=""/>
              <p>그림 107. 수평 사일로로부터 사일리지 꺼내기</p>
              <img src={pic435} width="40%" alt=""/>
              <p>그림 108. 사일리지 자루</p><br/>
              <p><b>&lt;표 96&gt; 사료급여율 </b></p>
              <table className="tablestyle">
                <tr>
                  <td rowSpan="2">사일로 형태</td>
                  <td colSpan="2">사료금여율, in/일</td>
                </tr>
                <tr style={{background: '#273143',color: '#fff'}}>
                  <td>더운 날씨</td>
                  <td>추운날씨</td>
                </tr>
                <tr>
                  <td>전통적인 사일로(꼭대기 배출식)</td>
                  <td>4</td>
                  <td>2</td>
                </tr>
                <tr>
                  <td>기밀사일로(밑바닥 배출식)</td>
                  <td>2</td>
                  <td>2</td>
                </tr>
                <tr>
                  <td>백사일리지</td>
                  <td>6</td>
                  <td>4</td>
                </tr>
                <tr>
                  <td>스택사일리지</td>
                  <td>6</td>
                  <td>4</td>
                </tr>
                <tr>
                  <td>수평형사일로</td>
                  <td>6</td>
                  <td>4</td>
                </tr>
              </table>
            </li>
            <li>사일리지백(silage bag)
              <ul>
                <li>사일리지용 백은 장기저장 또는, 비상용 사일리지제조에 이용된다 (그림 108). 지름이 8&apos;-9&apos;이고 길이가 150&apos;인 자루에 휴대용 기계를 이용하여 사일리지를 채운 후, 공기유입에 의한 사일리지 손실을 막기 위하여 백을 철저히 봉해야 한다. 사일리지의 수분함량은 60%-70%로 하는데, 수분함량이 높으면 결빙 문제가 발생할 수 있으므로 주의하여야 한다.  백은 재사용할 수 없으며 구멍이 생기지 않도록 햐여야 한다. 일반적으로 백사일리지는 ① 설치류에 의하여 구멍이 생기거나, 봉재선이 터지거나, 바닥에 있는 뾰족한 물체에 의하여 구멍이 나거나, 아미면 날씨에 의하여 손상을 받아서 부패될 수 있는데, 자루에 구멍이 난 경우에는 테입을 이용하여 막는다. 겨울철 눈이 잘 녹도록 사일리지 백이 남북을 향하도록 하고 자루를 놓을 바닥의 기울기는 5%로 하여 배수가 잘 되도록 한다. 바닥을 포장하면, 비오는 날에도 사일리지를 꺼내거나 자가급이가 가능하며, 후에 벽을 설치하여 벙커사일로를 만들 수도 있다. 사료급여 울타리를 잘 계획하면, 30두 정도 자가급이가 가능하다. </li>
              </ul>
            </li>
            <li>직립사일로 (upright silo) 
              <ul>
                <li>직립사일로는 사일로 꼭대기에 사일리지를 꺼내는 기계장치가 되어 있는 사일로와 밑에 사일리지를 꺼내는 기계장치가 되어있는 기밀사이로로 구분되다. 꼭대기 배출식의 경우에는 채우는 중에는 사일리지를 꺼낼 수 없으며, 오랜 기간에 걸쳐 이용하면 공기의 유입에 의한 사일리지 부패손실이 많아진다. 이에반해, 밑바닥 배출식 기밀사일로의 경우에는 채우는 중에도 사일리지를 꺼낼 수 있고 공기유입에 의한 저장손실이 적다. 사일리지를 꺼낸 후에는 뚜껑을 철저히 덮어 공기의 유입을 막아야 한다. 공기의 유입에 의한 사일리지 손상은 슬러지(sludge)로 나타나기 때문에, 발견하기 힘들어 손상된 사일리지는 정상적인 것과 섞여져 배출된다. 밑바닥 배출식 기밀 사일로는 꼭대기 배출식 사일로로 전환하거나 아니면 아예 사용을 포기하는 경우도 있는데 이는 배출 기계장치를 자주 교체하여야 하고 관리비용이 많이 들기 때문이다. </li>
              </ul>
            </li>
            <li>스택사일리지 (silage stack)
              <ul>
                <li>사일리지를 단기, 장기, 또는 비상용으로 저장할 목적으로 스택사일로를 이용할 수 있다 (그림 109). 수분함량 70%의 옥수수 사일리지이면 스택사일로로도 훌륭한 사일리지를 만들 수 있다. 검정 플라스틱 커버로 사일리지 스택을 덮으며, 장기저장할 경우에는 바닥을 콘크리트 등의 단단한 재질로 처리하고, 사일리지를 꺼낼 수 있는 접근시설을 마련하여야 한다. 스택사일로의 외벽은 ①백사일로, 베일, 또는 다른 스택사일리지 더미를 이용하여 만들 수 있다.</li>
              </ul>
              <img src={pic436} className="nonborder" style={{width: '40%'}} alt=""/>
              <p>그림 109. 사일리지 스택</p>
            </li>
          </ul>
        </li>
      </ul>
      <h2> 건초 저장시설 </h2>
      <p className="pstyle">그림 110과 같은 실내 건초저장소를 이용하면 건초의 질을 보존시키며 고형물 손실을 줄여준다. 연평균 강수량이 20 이상인 지역에서는 옥내 건초저장소를 설치하도록 한다. 옥외 건초저장소를 설치할 경우, 배수와 공기유통을 위하여 바닥은 약간 경사지게 하고 건초더미나 베일 사이는 최소한 1&apos;의 간격을 두어야 한다. 지면의 빗물이 유입되지 않도록 하며, 사료급여 지역에 가깝게 위치하도록 한다. 건초더미나 베일의 질, 저장방법, 강수량 등에 따라 건초의 저장손실이 달라지는데, 저장중 손실이 50%까지 달하는 경우도 있다. </p>
      <img src={pic437} className="nonborder" width="30%" style={{float:'left'}} alt=""/>
      <img src={pic438} className="nonborder2" style={{width: '30%'}} alt=""/><br/><br/><br/><br/><br/>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;그림 110. 건초 저장 시설&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;그림 111. 호퍼 배출식 빈</p>
     
      <p className="pstyle">&nbsp;&nbsp;&nbsp;수확 또는 구입된 곡류는 빈에 보관할수 있는데, 빈은 여러 형태의 곡류를 저장할 수 있도록 융통성이 있어야 한다. 신선한 곡류를 이용하여야 하므로, 가장 큰 빈은 곡류 연소비량의 1/2 이상이 저장되지 않는 크기이어야 한다. 필요할 때마다 구입하는 경우에는 소형 빈을 설치한다. 갓 수확된 곡류의 저장을 위해서는, 온도상승을 억제시킬 수 있도록 통기장치를 갖추어야 한다. <br/>&nbsp;&nbsp;&nbsp;가공 전이나 후의 곡류는 밑바닥에 호퍼 (hopper)가 설치된 빈에 보관하여 자연낙하식으로 배출된다. 브리지를 형성하는 경향이 많은 분쇄된 곡류 등의 경우에는 측면 배출식 (side draw) 빈에 보관하는데, 각도를 높히면 곡류의 흐름을 더욱 원활히 할 수 있다. 유동성이 있는 곡류의 경우에는 중앙 배출식 (center draw) 빈이 사용되는데, 빈 내에 곡류가 불균형하게 분포되어 측벽에 움푹 파이는 곳이 생기는 경우도 있다 (그림 111).</p>
      <img src={pic439} className="nonborder" width="30%" alt=""/>
      <p>그림 112. 부산물원료사료 저장시설</p>
      <h2>유통사료 저장시설 (commodity storage)</h2>
      <p className="pstyle">&nbsp;&nbsp;&nbsp;많은 유통사료는 어는 정도 유동성이 있기 때문에 밑바닥에 호퍼가 설치된 빈에 보관할 수 있다. 사료의 펠렛화는 유동성을 향상시킬 수 있다.<br/>&nbsp;&nbsp;&nbsp;그러나, 통면실은 유동성이 전혀 없기 때문에 어거 (auger)로 운반될 수 없고, 자연 배출식 빈에 보관될 수도 없으며, 벨트 또는 진공 컨베이어를 이용하여 운반될 수 있으나 설치비가 비싸 실용적이지 않다. <br/>&nbsp;&nbsp;&nbsp;유통사료의 특성은 수분함량, 가공형태, 저장형태 및 저장기간에 의하여 시시각각으로 변하기 때문에, 평지 저장시설 (flat storag)이 가장 적합하다. 그림 112와 같이 여러 칸으로 나뉘어진 유통사료 저장창고는 통면실 등의 유통사료 저장에 적합하다. 창고의 벽은 사료를 넣을 때 트랙터가 밀어도 견딜수 있을 정도로 튼튼하게 축초하여야 한다. 창고는 각 칸은 사료트럭이 접근할 수 있는 높이와 넓이가 되로록 하여야 하며, 각 칸 앞에는 널판을 깔아 트럭이 사료를 완전히 덤프할 수 있도록 하여야 한다.</p>
    </div>
  );
}

export default Do223;