import React from 'react';

import Figure1by7 from 'assets/images/sub/_images/figure_1_7.gif';

function Fo9() {

  return (
    <div className="techinfo_view">
      <p className="viewTitle">발정주기</p>
      <p>발정주기는 발정과 다음발정사이의 시간을 말하는데(그림 1.7) 발정주기의 기간은 18-24일의 범위를 가지지만 평균적으로 21일이다. 그리고 발정주기는 비정상적으로 짧거나 길게 나타나기도 하는데 비교적 짧은 주기(10-16일)는 잘못된 발정확인으로 인한 경우가 많은데 소의 행동을 기준으로하여 발정을 관찰시 실제로는 발정이 오지 않았을때에도 발정 행위를 하는 경우를 의미한다. 하지만 다음번의 발정관찰에서도 짧은 주기로 나타난다면 그소는 발정주기가 짧다고 할수 있을 것이다. 이와는 반대로 발정주기가 30-35일로 평균보다 길게 나타난다면 이것또한 잘못된 발정확인으로 생각할수 있으며, 또한 수정란이 초기에 죽어서 나타나는 현상이라고 할수 있다.</p>
      <p className="mg30f">결론적으로 발정주기의 이상은 우선적으로 발정확인의 오류라고 생각되어진다. </p>
      
      <dl>
        <dt>발정주기의 단계</dt>
        <dd>발정주기는 발정전기, 발정기, 발정후기 그리고 발정휴지기의 4단계로 나눌수 있으며 각 단계는 연속적으로 이루어 진다.</dd>
      </dl>
      
      <dl>
        <dt>발정전기</dt>
        <dd>발정전기는 발정휴지기로부터 발정기로 이행하는 중간과정, 즉 발정이 시작되기 직전의 단계로서 발정주기의 지속시간은 하루이며 발정전기는 황체의 완전한 퇴행과 난소에서 난자를 배출시키기 위한 준비로 많은 난포의 성장이 일어난다.</dd>
      </dl>
      <div className="imgBox"><img src={Figure1by7} alt=""/></div>
      
      <dl>
        <dt>발정기</dt>
        <dd>
          발정기는 8-30시간정도 지속되며 이기간에는 수컷의 교미를 허용하지만 (그림 1.7에서 day 1) 종간에의 발정기의 행동에는 차이를 보인다. <br/>
          하지만 수컷의 승가행위동안 승가를 허용하는 행위는 발정확인의 가장좋은 방법이다. <br/>
          발정기 동안 난자를 포함하는 난자는 발육의 최종단계까지 발달한다. 
        </dd>
      </dl>
      
      <dl>
        <dt>발정후기</dt>
        <dd>발정후기의 가장 큰 특징은 배란이 일어나 난자가 난소로부터 난관으로 이동하는데 배란은 발정기 후 10-14시간에 이루어진다. 난포가 터져 배란이 되고 난후 난포주위의 세포들이 황체로 발달하기 시작한다. 발정후기의 지속시간은 대략 3일정도이다(발정주기의 2일에서 5일). </dd>
      </dl>
      
      <dl>
        <dt>발정휴지기</dt>
        <dd>
          <p>발정휴지기는 12-15일정도 지속된다(발정주기의 6일에서 18일). 이기간에 황체는 커지고 황체의 기능이 발현되는 시기이다. 이기간에 난포의 성숙을 볼수 있는데, 황체가 퇴행되기 전까지 이러한 난포는 완전한 발달을 하지는 못한다. </p>
          <p>발정주기의 1 또는 2일째에는 단지 몇 개의 난포가 발달을 시작하며, 하나의 난포가 대난포로 발달하는데, 이 대난포는 발정주기 11∼12일에 퇴행한다. 이러한 현상은 발정주기동안 난포의 주기는 2-4회를 가져 황체가 존재하는 황체기에 성장한 난포는 배란되지 않고 퇴행하는 것으로 설명할수 있다. 하지만 황체가 퇴행한 난포기에는 대난포는 성숙을 완전히 이루어 배란된다.</p>
          <p>발정주기의 16∼18일째에는 소의 자궁은 수정란의 유무를 확인가능하며, 임신이 되지 않았을 때에는 자궁은 황체에게 신호를 보내어 발정주기가 계속되도록 이시기에 황체가 퇴행하기 시작한다. 이에 반하여 수정이 이루어지고 임신이 되었을때에는 황체는 퇴행되지 않고 계속하여 난포의 성숙을 방해하는 호르몬을 생성하여 임신을 유지한다.</p>
        </dd>
      </dl>				
    </div>
  );
}

export default Fo9;
