import React from 'react';
import GeneResult from 'containers/gene/generesult/GeneResult';

function GeneResultPage() {
  return (
    <GeneResult />
  );
}

export default GeneResultPage;
