import React from 'react';

function Fo42() {

  return (
    <div className="techinfo_view">
				<p className="viewTitle">요오드 결핍증</p>
				<dl>
					<dt>발병대상</dt>
					<dd>모든 가축과 사람</dd>
				</dl>
				
				<dl>
					<dt>원인</dt>
					<dd>갑상선이 thyroxin을 형성할 만큼의 충분한 옥소를 체내에서 획득하지 못하여 일어난다.</dd>
				</dl>
				
				<dl>
					<dt>징후 및 증상</dt>
					<dd>갑상선종은 송아지와 새끼양 그리고 새끼 염소 및 사람에게 요오드 결핍으로 생기는 질병으로 번식장애와 후대 절손의 원인이 될 수도 있다. 자돈의 경우 무모상태로 출생하거나 어깨와 목 부위에 수종이 나타나기도 한다. 새끼말은 약하게 태어나기도 한다. </dd>
				</dl>
				
				<dl>
					<dt>질병분포와 손실규모</dt>
					<dd>요오드 결핍증은 세계적으로 발생되고 있으며 특히 토양중의 요오드 함량이 결핍되어 있는 지역에서 가축의 요구량을 충족시키지 못하기 때문에 발생된다. 발병율이 가장 높은 지역으로는 알프스산맥과 피레네 산맥, 히말라야 산맥, 뉴질랜드의 일부지역, 미국의 중남부, 4대호수, 태평양 북서부 지역 등이 이에 속한다.</dd>
				</dl>
				
				<dl>
					<dt>치료</dt>
					<dd>경미한 갑상선 확대현상은 수주일내에 사라진다. 일단 요오드 결핍증이 발생되면 효과적인 치료수단이 없다.</dd>
				</dl>
				
				<dl>
					<dt>대책</dt>
					<dd>초기 요오드 결핍증상에는 안정화된 요오드염을 모든 가축에 급여하여야 한다</dd>
				</dl>
				
				<dl>
					<dt>예방</dt>
					<dd>요오드 결핍지역에서는 연중 모든 가축에 요오드화 칼륨을 0.01% 함유한 안정화된 요오드염으로 급여한다. 요오드화물의 유기적 형태가 요오드의 이상적인 공급원이 될 수 있지만 고가인 흠이 있다. </dd>
				</dl>	
				
				<dl>
					<dt>참고</dt>
					<dd>갑상선 비대는 요오드 결핍증이 존재하는 지역에서 갑상선 호르몬인 thyroxin을 충분하게 만들어 내려는 자연적인 현상으로 생긴다. 임신말기의 암말이 과량의 요오드(48mg 또는 그 이상)를 섭취하게 되면 갑상선이 비대하는 새끼를 분만할 수도 있으며 암말도 역시 갑상선종에 걸릴 수 있다.</dd>
				</dl>	
			</div>
  );
}

export default Fo42;
