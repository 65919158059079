import React from 'react';

import pic775 from 'assets/images/sub/_images/pic775.jpg';
import pic776 from 'assets/images/sub/_images/pic776.jpg';
import pic777 from 'assets/images/sub/_images/pic777.jpg';

function Do170() {

  return (
    <div className="techinfoview">
      <h1>췌질증</h1>
      <p className="pstyle">소형 췌질충이라는 기생충이 췌장(이자)액이 분비되는 췌관에 기생하는 췌액의 분비장애를 일으키는 질환이다. </p>
      <div className="leftpart">
        <h2>증상</h2>
        <ul>
          <li>췌액의 분비 장애로 전신의 대사장애와 소화장애를 일으켜, 영양불량 상태에 빠진다. 
            <ul>
              <li>감염된 기생충이 적은 경우에는 별다른 증상이 없다. </li>
              <li>중증으로 감염된 경우는 췌장의 기능이 현저히 저하하여 영양상태가 나빠지며 육성우인 경우 발육이 늦어진다. </li>
              <li>눈, 입술, 질 점막을 조사해 보면 핏기가 없고 빈혈을 볼 수 있다. </li>
              <li>분변 검사로 충란이 검출되면 본병을 확진할 수있다. </li>
            </ul>
          </li>
        </ul>
        <h2>목장에서의 처치</h2>
        <ul>
          <li>방목지에서 야위고 발육불량한 젖소를 발견하면 즉시 우사 내에 수용해서 충란검사를 실시하고 본증의 이환여부를 확인한다. </li>
          <li>심하게 야윈 개체에 대해서는 먼저 영양 수액을 공급한 다음 구충제를 투여해야 한다.</li>
        </ul>
      </div>
      <div className="rightpart">
        <img src={pic775} className="nonborder" alt=""/>
        <img src={pic776} className="nonborder" alt=""/>
        <img src={pic777} className="nonborder" alt=""/>
      </div>
    </div>
  );
}

export default Do170;