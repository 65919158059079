import React from 'react';
import SemenSelect from 'containers/milk/semenselect/SemenSelect';

function SemenSelectPage() {
  return (
    <SemenSelect />
  );
}

export default SemenSelectPage;
