import React from 'react';

import pic304 from 'assets/images/sub/_images/pic304.gif';
import pic305 from 'assets/images/sub/_images/pic305.gif';
import pic306 from 'assets/images/sub/_images/pic306.gif';
import pic307 from 'assets/images/sub/_images/pic307.gif';
import pic308 from 'assets/images/sub/_images/pic308.gif';

function Do178() {

  return (
    <div className="techinfoview">
      <h1>가축의 조사료 이용</h1>
      <h2>초식가축에 대한 목초 및 사료작물의 중요성</h2>
      <p className="pstyle">젖소나 한우 또는 고기소등 초식가축에 대한 양질 조사료의 중요성은 아무리 강조해도 지나침이 없다. 목초 및 사료작물은 특히 대표적인 조사료로서 경제적인 측면 뿐만 아니라 소화생리적인 면에서도 더욱 중요성이 강조되는데 이러한 조사료의 일반적특성을 보면 다음과 같다.</p>
      <ul>
        <li>목초 및 사료작물의 일반적인 특성
          <ul>
            <li>젖생산이나 살찌는데 중요한 에너지 함량이 낮고 조섬유가 18%이상 들어있다.</li>
            <li>보존이나 저장기간에 따라 사료의 영양가가 차이가 난다.</li>
            <li>반추가축에 양적사료 역할도 있다.</li>
            <li> 농후사료에 비해 칼슘(Ca)과 미량 광물질함량이 높다.</li>
            <li>두과조사료에는 단백질과 비타민이 많이 들어있다.</li>
            <li>지용성비타민의 주요 사료공급원이다.</li>
            <li>기호성이 좋다.</li>
            <li>젖소의 유지생산에 적합하다</li>
            <li>수확시기에 제조방법에 따라 영양가가 달라진다.</li> 							
          </ul>
          <img src={pic304} style={{width: '50%'}} alt=""/>
          <p>(그림1) 퍼레니알라이그라스의 생육기별 소화율 및 유기물 건물 생산량(Herkess : 1977)</p>
        </li>
      </ul>
      <h2>젖소의 육성</h2>
      <ul>
        <li>송아지
          <ul>
            <li>어린송아지는 질병에도 약할 뿐만아니라 반추위는 가지고 있으나 풀을 소화할 만큼 위의 기능이 발달되지 않았다.</li>
          </ul>
        </li>
        <li>풀을 소화할 만큼 위의 기능이 발달되지 않았다.
          <p>따라서 생후 3개월령까지의 송아지 사육은 기본적으로</p>
          <ul>
            <li>질병에 대한 저항력 증진과</li>
            <li>단위가축에 준한 소요 영양분 공급</li>
            <li>그리고 반추위 발육촉진등에 중점을 두고 실시되는데 첫째, 어린송아지를 건강하게 키우려면 생후부터 3일령이내에 충분한 첫젖을 급여해야 한다.</li>
          </ul>
          <p>일반적으로 1개월령 이후 부터는 송아지에서 자체생산되는 면역물질에 의거 질병저항력을 얻게된다.(그림 2)</p>
          <img src={pic305} style={{width: '50%'}} alt=""/>
          <p>(그림 2) 초유급여와 송아지 혈액내 면역물질</p><br/>
          <p>둘째, 생후 4일령부터 20∼40일령까지 포유기에는 우유나 대용유를 주로 하여 사육하되 인공유와 건초등을 같이 급여하여 적절한 발육을 유도하며 아울러 반추위 발달에 도움을 준다. 셋째로, 젖뗀후 (20∼40일령)부터 3개월령 까지는 인공유와 건초등만 가지고 송아지를 경제적으로 사육한다. </p>
          <ul>
            <li>송아지에 대한 조사료의 역할
              <ul>
                <li>질이 좋은 조사료는 송아지에 단백질, 비타민, 무기질들도 일부 공급하지만 위내 휘발성지방산의 생성과 반추위 발달을 촉진 시킨다.<br/>
                  <p><b>&lt;표 1&gt; 사료의 조성에 따른 제1위 조직발달(Foldager:1979) </b></p>
                  <table className="tablestyle">
                    <tr>
                      <td rowSpan="2">사료조건</td>
                      <td rowSpan="2">주령</td>
                      <td colSpan="2">g/dm2 (데시방메타)</td>
                    </tr>
                    <tr style={{background: '#273143',color:'#fff'}}>
                      <td>상피조직</td>
                      <td>근육</td>
                    </tr>
                    <tr>
                      <td>90%농후사료</td>
                      <td>16령</td>
                      <td>3.00</td>
                      <td>2.33</td>
                    </tr>
                    <tr>
                      <td>90%건초</td>
                      <td>16</td>
                      <td>1.97</td>
                      <td>2.50</td>
                    </tr>
                    <tr>
                      <td>농후사료→우유</td>
                      <td>33</td>
                      <td>0.83</td>
                      <td>1.45</td>
                    </tr>
                    <tr>
                      <td>건초→우유</td>
                      <td>35</td>
                      <td>1.14</td>
                      <td>2.08</td>
                    </tr>
                    <tr>
                      <td>대패밥</td>
                      <td>47</td>
                      <td>0.85</td>
                      <td>2.28</td>
                    </tr>
                    <tr>
                      <td>우유전기간사양</td>
                      <td>27</td>
                      <td>0.61</td>
                      <td>1.36</td>
                    </tr>
                  </table>
                  <p>특히 건초등을 일찍부터 급여하면 반추위유두의 발달이 빨라서 위 발달이 잘 된다.</p><br/>
                  <p><b>&lt;표 2&gt; 반추위 유두의 발달 (Tamate 등, 1962)</b></p>
                  <table className="tablestyle">
                    <tr>
                      <td rowSpan="2">사양조건</td>
                      <td rowSpan="2">주령</td>
                      <td colSpan="2">반추유두크기(mm)</td>
                      <td rowSpan="2">반추유두수<br/>/㎠</td>
                    </tr>
                    <tr style={{background: '#273143',color:'#fff'}}>
                      <td>최고</td>
                      <td>평균</td>
                    </tr>
                    <tr>
                      <td rowSpan="4">체중의 12% 우유 급여</td>
                      <td>1∼3일령</td>
                      <td>2.6</td>
                      <td>0.99</td>
                      <td>1,392</td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>1.6</td>
                      <td>0.53</td>
                      <td>601</td>
                    </tr>
                    <tr>
                      <td>8</td>
                      <td>1.2</td>
                      <td>0.48</td>
                      <td>605</td>
                    </tr>
                    <tr>
                      <td>12</td>
                      <td>1.3</td>
                      <td>0.46</td>
                      <td>528</td>
                    </tr>
                    <tr>
                      <td rowSpan="3">체중의 10% 우유와 <br/>건초 및 농후사료 자유채식</td>
                      <td>4</td>
                      <td>2.5</td>
                      <td>0.79</td>
                      <td>529</td>
                    </tr>
                    <tr>
                      <td>8</td>
                      <td>6.2</td>
                      <td>1.54</td>
                      <td>245</td>
                    </tr>
                    <tr>
                      <td>12</td>
                      <td>6.8</td>
                      <td>2.46</td>
                      <td>173</td>
                    </tr>
                  </table>
                </li>
              </ul><br/>
            </li>
            <li>조사료의 소요량 및 급여요령
              <ul>
                <li>생후 1주령부터 질이 좋고 부드러운 건초를 풀시렁에 넣어서 자유채식 시킨다. 신선한 건초를 매일 급여하는 것이 좋으며 너무 오래된 건초는 가급적 피하는 것이 좋다. 특히 햇볕에 잘 말린 건초, 싸일레지등 하루에 1kg이상 먹이는 경우엔 비타민D를 별도로 공급해 줄 필요가 없다. 생풀은 그대로 급여하는 것 보다 몇시간 정도 예건해서 주는 것이 좋다. </li>
              </ul>
            </li>
          </ul>
        </li>
        <li>4개월∼10개월령 육성우 
          <p>체중 100∼250kg로서 인공유나 송아지 사료에 의한 사육이 끝나고 육성기 사료에 의한 사육기이다.</p>
          <ul>
            <li>1일 적정 증체량 : 0.45∼0.70kg</li>
            <li>1일 사료 섭취량 : 2.8∼6.3kg</li>
          </ul>
          <p>제1위의 용적이 아직 충분히 발달하지 못하였으므로 조사료만으로 필요한 양분을 다 충족시키기가 어렵다. 따라서 조사료 이외에 농후사료를 일부 보충해 주어야 한다.</p>
          <ul>
            <li> 청초 급여기
              <ul>
                <li>청초기에는 방목 또는 베어다 급여하는데 너무 어릴 때 방목을 하여 풀을 많이 섭취하면 농후사료의 보충급여가 어려우므로 이점 유의해야 한다. 특히 6개월 이내에서는 고기소와 달리 어미소와 함께 방목하거나 기존 방목지에서의 방목은 피하는 것이 좋다. 기간중 농후사료의 보충은 방목지에서 해 주거나 방목 후에 야간에 축사안에서 먹도록 해 준다. 4개월령 육성우에 농후사료 보충량은 조사료의 질에 따라 다소 차이가 나지만 체중의 1%범위에서 급여하면 된다.</li>
              </ul>
            </li>
            <li>사사기
              <ul>
                <li>육성초기 즉 4개월령에 축사안에서 질이 좋은 건초를 급여할 때는 단백질이 12% 수준인 농후사료를 1일 1.5∼2.0kg보충해 주는 것이 좋다. 싸일레지는 하루 1∼2kg정도가 좋으며 서서히 급여량을 증가시킨다. 급여시 곰팡이가 나거나 부패한 것은 피하는 것이 좋다.</li>
              </ul>
            </li>
          </ul>
        </li>
        <li>10개월∼초산2개월전 육성우
          <p>육성우가 10개월령 이상되면 소화기관이 어느 정도 충분히 발달하므로 농후사료 보충없이 목초 등 조사료만 가지고도 필요한 양분을 섭취할 수가 있다. 특히 이 기간 중 사육시엔 너무 살이 찌지 않도록 유의한다. 일일 적정증체량은 0.5∼0.75kg이며 1일 사료건물섭취량은 6.0∼8.0kg으로서 어느정도 제한사양 하는 것이 초산시 산유량이 높다.</p><br/>
          <p><b>&lt;표 3&gt; 육성기 영양수준별 증체 및 산유량(Hansson:1956)</b></p>
          <table className="tablestyle">
            <tr>
              <td colSpan="2">영양수준</td>
              <td rowSpan="2">1일 증체량<br/>(1~19개월령)</td>
              <td colSpan="2">1산차</td>
            </tr>
            <tr style={{background: '#273143',color:'#fff'}}>
              <td>수준</td>
              <td>섭취량(FU)</td>
              <td>4%FCM*</td>
              <td>지수</td>
            </tr>
            <tr>
              <td>62%</td>
              <td>1.646</td>
              <td>0.44kg</td>
              <td>3.177</td>
              <td>103</td>
            </tr>
            <tr>
              <td>80</td>
              <td>2.137</td>
              <td>0.54</td>
              <td>3.145</td>
              <td>104</td>
            </tr>
            <tr>
              <td>100</td>
              <td>2.667</td>
              <td>0.62</td>
              <td>3.016</td>
              <td>100</td>
            </tr>
            <tr>
              <td>117</td>
              <td>3.111</td>
              <td>0.69</td>
              <td>2.866</td>
              <td>95</td>
            </tr>
            <tr>
              <td>126</td>
              <td>3.348</td>
              <td>0.69</td>
              <td>2.573</td>
              <td>85</td>
            </tr>
          </table>
          <p>* 유지율 4%로 보정한 유량<br/>그러나 반대로 너무 양분공급이 부족하여 1일증체량이 0.3kg이하로 발육이 부진하면 번식장애가 생기기 쉬우므로 이점에도 유의해야 한다.</p>
          <ul>
            <li>청초기
              <p className="pstyle">체중의 10∼12%의 청초를 젖소가 섭취하면 농후사료없이 하루 0.45∼0.65kg의 증체가 가능하므로 젖소 육성우에 질이 좋은 두과목초나 두과화본과 혼합목초를 가지고 기르는 것이 좋으며 만일 초생이 불량하거나 질이 낮은 청초를 급여할 경우엔 야간에 질이 좋은 건초를 급여하거나 농후사료를 1∼2kg 보충해 준다. 청초급여시에는 신선한 물을 항시 먹을 수 있도록 해 주고 소금과 골분과 패분 등 무기물을 비치하여 먹을 수 있도록 해야한다.</p>
            </li>
            <li>사시기
              <p>조사료는 종류와 질에 따라 섭취량의 차이가 많다. 일반적으로 종류별로 보면 청초의 섭취량이 가장 높으며 건초의 섭취량은 낮은편이다.</p><br/>
              <p><b>&lt;표 4&gt; 조사료 종류별 섭취가능량</b></p>
              <table className="tablestyle">
                <tr>
                  <td>종류</td>
                  <td>섭취가능량(체중비)</td>
                </tr>
                <tr>
                  <td>청초</td>
                  <td>10∼12%</td>
                </tr>
                <tr>
                  <td>건초</td>
                  <td>2∼3</td>
                </tr>
                <tr>
                  <td>싸일레지</td>
                  <td>5∼6</td>
                </tr>
                <tr>
                  <td>청애작물</td>
                  <td>8∼10</td>
                </tr>
                <tr>
                  <td>근채류</td>
                  <td>6∼8</td>
                </tr>
              </table>
              <p>그러나 같은 종류의 조사료에서는 질이 좋은 것이 섭취량이 높고 질이 낮은 것이 떨어진다. </p><br/>
              <p><b>&lt;표 5&gt; 조사료의 질과 섭취량 변화(Jurgens:1978)</b></p>
              <table className="tablestyle">
                <tr>
                  <td>조사료의 질</td>
                  <td>1일 섭취량(%, 체중비)</td>
                </tr>
                <tr>
                  <td>수</td>
                  <td>3.0</td>
                </tr>
                <tr>
                  <td>우</td>
                  <td>2.5</td>
                </tr>
                <tr>
                  <td>미</td>
                  <td>2.0</td>
                </tr>
                <tr>
                  <td>양</td>
                  <td>1.5</td>
                </tr>
                <tr>
                  <td>가</td>
                  <td>1.0</td>
                </tr>
              </table><br/>
              <p className="pstyle">&nbsp;&nbsp;&nbsp;따라서 사사기에 육성우 사육에 있어서 조사료의 질을 감안할 때 양질의 두과건초나 두과화본과 혼합 목건초를 체중의 2.5∼3.0%까지 급여시에는 농후사료를 보충해주지 않아도 된다. 그러나 저질의 두과건초 급여시엔 단백질이 10%인 농후사료 2kg정도 보충해주며 저질 혼합목건초 급여시엔 단백질 12%인 농후사료를 보충해 준다.<br/>&nbsp;&nbsp;&nbsp;특히 저질의 야건초나 짚을 먹일때는 단백질이 14∼16%의 농후사료를 체중의 1%정도 보충해 주어야 한다. 목초 싸일레지의 경우 소화율이 68∼70%인 것은 농후사료 보충없이 육성우에 이용하여도 정상적인 사육이 가능하다.<br/>&nbsp;&nbsp;&nbsp;풀 위주 사육시 유의할 점은 풀의 질에 따라 단백질이나 에너지가 부족하거나 비타민A 및 인(P) 등의 함량이 낮아서 발정이나 수태율이 저하되는 경우가 생기므로 육성우 사육시엔 이에 대하여 유의해야 한다. </p>
            </li>
          </ul>
        </li>
        <li>초산 2개월전∼초임우
          <p>임신후반기가 되면 양분요구량이 높아지기 때문에 농후사료의 보충이 필요하게 된다. 일반적으로</p>
          <ul>
            <li>조사료의 질이 보통일때는 4kg정도의 농후사료를 보충해 주어야 하며</li>
            <li>조사료의 질이 우수하면 농후사료는 1일 2kg정도 보충해 준다.</li>
          </ul>
        </li>
      </ul>
      <h2>착유우의 사양</h2>
      <ul>
        <li>착유우의 특성
          <p className="pstyle">&nbsp;&nbsp;&nbsp;젖소의 산유량은 분만후 점차 증가하기 시작하여 40∼60일경에 가면 최고비유기에 도달하게 되며 그 이후 매주 2∼2.5%씩 산유량이 감소하여 일정한 기간후에 건유를 하게 된다.<br/>&nbsp;&nbsp;&nbsp;한편 산유기간 중 젖소의 사료섭취능력 변화를 보면 산유량에 따라 증감이 되지않고 유량이 높은 분만초기에는 섭취능력이 낮고 3∼6개월경에 가장 높다가 이후에 점차 낮아진다.<br/>&nbsp;&nbsp;&nbsp;이에 따라서 젖소의 체중은 산유량이 높은데 비해 사료섭취량이 낮은 즉 분만후 1∼2개월령에 감소하게 되며 그이후 사료섭취능력이 점차 높아지면서 체중도 회복되어 간다.<br/>&nbsp;&nbsp;&nbsp;특히 분만초기에 체중이 감소하는 것은 젖생산은 높은데 사료섭취량이 낮으므로 부족되는 젖생산 소요영양분을 몸에 있는 체지방을 동원하여 보충하는데 기인되는 현상이다.</p>
          <img src={pic306} style={{width: '50%'}} alt=""/>
          <p>(그림3) 산유기간중 산유량 사료섭취량 및 체중변화</p><br/>
          <p><b>&lt;표 6&gt; 산유기간 중 사료섭취량 변화(Castle : 1979)</b></p>
          <table className="tablestyle">
            <tr>
              <td>월별</td>
              <td>1</td>
              <td>2</td>
              <td>3</td>
              <td>4</td>
              <td>5</td>
              <td>6</td>
              <td>7</td>
              <td>8</td>
              <td>9</td>
              <td>10</td>
            </tr>
            <tr>
              <td>섭취량(%)</td>
              <td>81</td>
              <td>98</td>
              <td>107</td>
              <td>109</td>
              <td>109</td>
              <td>108</td>
              <td>101</td>
              <td>99</td>
              <td>97</td>
              <td>93</td>
            </tr>
          </table>
        </li>
        <li>산유주기
          <p>젖소의 산유주기는 산유량 변화를 고려하여 산유초기, 산유중기, 산유후기로 구분한다.</p>
          <ul>
            <li>산유초기는 분만후 최고 비유기를 포함한 약70일간을 의미하는데 특징은 산유량에 비해 사료섭취능력이 낮은 영양부족기라는 점이다. 따라서 앞에서 설명한 바와같이 체중감소 현상이 일어나는데 바람직한 1일체중 감소량은 0.5kg 정도로서 기간중 총 감소량은 35∼40kg 정도 된다. 만일 이 기간중 과도한 체중감소가 일어나면 번식에 지장이 있으므로 급여사료의 종류와 질에 특히 유의해야 한다.</li>
            <li>산유중기는 산유초기이후 3개월 전후로서 산유량은 더 증가하지 않고 감소추세에 있는 반면 사료섭취량이 높아서 젖 생산에 필요한 양분을 사료에서 공급할 수 있는 양분균형기이다. 산유중기는 산유지속성에 따라 기간의 차이가 있는데 산유량이 높고 지속성이 높으면 90일이상 되며 그렇지 않는 경우 70일 정도가 된다.<br/>
              <img src={pic307} className="nonborder" alt=""/>
              <p>(그림4) 산유기간 중 영양소 이동 관계</p>
            </li>
            <li>산유후기는 일반적으로 임신에 의해 산유량이 낮아지는 중기 이후부터 건유전까지이다. 산유후기에는 (그림5)에서 보는 바와 같이 양분공급을 증가시켜도 체지방 증가에는 효과적이나 우유생산에는 크게 도움이 되지 않는다.<br/>
              <img src={pic308} className="nonborder" alt=""/>
              <p>(그림5) 산유기별 양분공급과 우유생산 효과(Broster : 1969)</p><br/> 
              <p>산유후기에 체중증가는 1일 0.5∼0.6kg이 바람직하며 기간중 증체는 35∼40kg이 적당하다.</p>
            </li>
            <li>산유후기 이후에는 건유를 하게 된다. 일반적으로 건유는 분만전 50∼60일간 실시하는데 이 기간중에 젖소의 피로호복 및 유방조직의 휴식 내지 파괴된 조직의 재생이 이루어지고 송아지 발육에 양분공급이 순조로와 진다. 특히 분만후 산유능력이 제대로 발휘시키면 반드시 일정기간의 건유을 해 주어야 한다.</li>
          </ul>
        </li>
        <li>사료급여
          <p>산유기간 중 젖소의 사료는 육성우에서와 마찬가지로 기본사료는 조사료이며 농후사료는 산유량이 증가함에 따라 비중이 높아진다.<br/>조사료는 종류와 숙기등에 따라 영양가의 차이가 있으므로 &lt;표 7&gt; 이용전에 품질에 대하여 정확히 알고 이용하는 것이 좋다. </p><br/>
          <p><b>&lt;표 7&gt; 목건초와 싸일레지 등급별 영양수준</b></p>
          <table className="tablestyle">
            <tr>
              <td>조사료</td>
              <td>등급</td>
              <td>가소화양분총량</td>
              <td>조단백질</td>
            </tr>
            <tr>
              <td rowSpan="4">두과목초</td>
              <td>수</td>
              <td>65%이상</td>
              <td>18%이상</td>
            </tr>
            <tr>
              <td>우</td>
              <td>58~65%</td>
              <td>15%</td>
            </tr>
            <tr>
              <td>미</td>
              <td>54~58%</td>
              <td>12%</td>
            </tr>
            <tr>
              <td>양</td>
              <td>54%이하</td>
              <td>10%이하</td>
            </tr>
            <tr>
              <td rowSpan="4">화본과건초</td>
              <td>수</td>
              <td>65%이상</td>
              <td>15%이상</td>
            </tr>
            <tr>
              <td>우</td>
              <td>58~65%</td>
              <td>12%</td>
            </tr>
            <tr>
              <td>미</td>
              <td>54~59%</td>
              <td>10%</td>
            </tr>
            <tr>
              <td>양</td>
              <td>54%이하</td>
              <td>10%이하</td>
            </tr>
            <tr>
              <td rowSpan="4">목초싸일레지</td>
              <td>수</td>
              <td>65%이상</td>
              <td>16%이상</td>
            </tr>
            <tr>
              <td>우</td>
              <td>60~65%</td>
              <td>12~16%</td>
            </tr>
            <tr>
              <td>미</td>
              <td>54~59%</td>
              <td>12%</td>
            </tr>
            <tr>
              <td>양</td>
              <td>54%이하</td>
              <td>12%이하</td>
            </tr>
          </table>
          <p>왜냐하면 조사료의 질에 따라 보충해줄 농후사료의 영양가와 급여량이 결정되기 때문이다.</p><br/>
          <p><b>&lt;표 8&gt; 조사료원별 농후사료의 단백질과 무기질 급여 수준</b></p>
          <table className="tablestyle">
            <tr>
              <td rowSpan="3">조사료</td>
              <td colSpan="4">건물 90% 기준</td>
            </tr>
            <tr style={{background: '#273143',color:'#fff'}}>
              <td rowSpan="2">조사료의 조단백질</td>
              <td colSpan="3">농후사료의 영양수준</td>
            </tr>
            <tr style={{background: '#273143',color:'#fff'}}>
              <td>조단백질(%)</td>
              <td>칼슘(%) </td>
              <td>인(%)</td>
            </tr>
            <tr>
              <td>두과건초</td>
              <td>16% 또는 이상</td>
              <td>12</td>
              <td>0</td>
              <td>0.7</td>
            </tr>
            <tr>
              <td>두과+화본과건초</td>
              <td>12∼16</td>
              <td>14~18</td>
              <td>0.5</td>
              <td>0.7</td>
            </tr>
            <tr>
              <td>두과+옥수수싸일레지</td>
              <td>12∼16</td>
              <td>14~18</td>
              <td>0.5</td>
              <td>0.7</td>
            </tr>
            <tr>
              <td>옥수수싸일레지 또는 화본과건초</td>
              <td>6∼10</td>
              <td>18~25</td>
              <td>1.0</td>
              <td>0.7</td>
            </tr>
            <tr>
              <td>요소 첨가 옥수수싸일레지</td>
              <td>10∼12</td>
              <td>16~18</td>
              <td>1.0</td>
              <td>0.7</td>
            </tr>
          </table>
          <p className="pstyle">&nbsp;&nbsp;&nbsp;산유기간중 조사료의 급여량은 산유량에 따라 크게 좌우 되는데 건유기나 우유생산량이 낮은 산유후기에는 질이 좋은 조사료만 가지고 필요한 양분을 충분히 공급할 수가 있다. 그러나 산유후기라도 조사료의 질이 평균 이하 일때는 산유량 10kg당 1∼2kg의 농후사료를 보충해 주는 것이 좋다. 그러나 반대로 산유량은 높으나 사료섭취 능력이 낮은 산유초기에는 급여사료 단위당 영양가가 높아야 되므로 산유량 10kg당 5kg정도의 농후사료를 급여해야 된다. 산유초기라도 건물기준으로 체중의 1%정도 조사료를 급여해야 유지율이 저하되는 것을 방지할 수 있다.<br/>&nbsp;&nbsp;&nbsp;젖소의 산유량과 유지율면에서 가장 유리한 최적사료의 조사료와 농후사료의 급여비율은 60:40이다. &lt;표 10&gt;에 따라서 양분균형기인 산유중기부터는 가급적 적절하게 조농비율을 맞추어 먹이는 것이 좋다. 이때 급여하는 조사료중 50%는 청초나 싸일레지 그리고 나머지 50%는 건초등을 준다.</p><br/>
          <p><b>&lt;표 9&gt; 유지방생산량과 조사료 등급별 농후사료 급여량</b></p>
          <table className="tablestyle">
            <tr>
            <td rowSpan="3">1일산유량</td>
            <td colSpan="3">조사료의 등급</td>
            <td rowSpan="3">비고</td>
            </tr>
            <tr style={{backgroun: '#273143',color: '#fff'}}>
              <td>하</td>
              <td>중</td>
              <td>상</td>
            </tr>
            <tr style={{backgroun: '#273143',color: '#fff'}}>
              <td colSpan="3">농후사료급여량(kg/1일)</td>
            </tr>
            <tr>
              <td>13.5</td>
              <td>2.5</td>
              <td>1.0</td>
              <td>0</td>
              <td rowSpan="6"></td>
            </tr>
            <tr>
              <td>13.5~19.0</td>
              <td>5.0</td>
              <td>4.0</td>
              <td>3.0</td>
            </tr>
            <tr>
              <td>19.0~25.0</td>
              <td>8.0</td>
              <td>7.0</td>
              <td>6.0</td>
            </tr>
            <tr>
              <td>25.0~30.0</td>
              <td>11.0</td>
              <td>10.0</td>
              <td>9.0</td>
            </tr>
            <tr>
              <td>30.0~36.0</td>
              <td>11.0</td>
              <td>13.0</td>
              <td>12.0</td>
            </tr>
            <tr>
              <td>36.0이상</td>
              <td colSpan="3">자유채식</td>
            </tr>
          </table><br/>
          <p><b>&lt;표 10&gt; 조농비율과 유량 및 유지율(Ronning : 1966)</b></p>
          <table className="tablestyle">
            <tr>
              <td rowSpan="2">구분</td>
              <td colSpan="4">조농비율</td>
            </tr>
            <tr style={{background: '#273143',color:'#fff'}}>
              <td>90 : 10</td>
              <td>60 : 40</td>
              <td>35 : 70</td>
              <td>0 : 100</td>
            </tr>
            <tr>
              <td>평균유량(kg)</td>
              <td>14.2</td>
              <td>17.5</td>
              <td>17.1</td>
              <td>17.5</td>
            </tr>
            <tr>
              <td>유지율</td>
              <td>3.6</td>
              <td>3.6</td>
              <td>3.5</td>
              <td>2.4</td>
            </tr>
            <tr>
              <td>FCM유량(kg)*</td>
              <td>13.6</td>
              <td>16.0</td>
              <td>15.5</td>
              <td>13.5</td>
            </tr>
            <tr>
              <td>무지고형분(%)</td>
              <td>&gt;0.8</td>
              <td>9.2</td>
              <td>9.1</td>
              <td>8.9</td>
            </tr>
          </table>
          <p>* 4%지방 보정 유량<br/><br/>일반적으로 체중 550kg에 연간산유량 5,200kg인 경우 산유기별 양분 요구량 및 1일 건물섭취량은 &lt;표 11&gt;과 같다.</p><br/>
          <p><b>&lt;표 11&gt; 사료양분요구량 및 섭취량 (체중 550kg, 유량 5,200kg, 유지방 3.5%)</b></p>
          <table className="tablestyle">
            <tr>
              <td rowSpan="2">주기</td>
              <td rowSpan="2">산유기간(일)</td>
              <td rowSpan="2">1일산유량(kg)</td>
              <td colSpan="2">1일양분요구량</td>
              <td colSpan="2">1일건물섭취량</td>
            </tr>
            <tr style={{background: '#273143',color:'#fff'}}>
              <td>TDN</td>
              <td>CP</td>
              <td>양</td>
              <td>체중비</td>
            </tr>
            <tr>
              <td>산유초기</td>
              <td>0∼70</td>
              <td>27</td>
              <td>12.2</td>
              <td>2.68</td>
              <td>15.4</td>
              <td>2.8</td>
            </tr>
            <tr>
              <td>산유중기</td>
              <td>70∼140</td>
              <td>24</td>
              <td>11.3</td>
              <td>2.43</td>
              <td>17.1</td>
              <td>3.1</td>
            </tr>
            <tr>
              <td>산유후기</td>
              <td>140∼305</td>
              <td>10</td>
              <td>7.0</td>
              <td>1.28</td>
              <td>13.2&lt;</td>
              <td>2.4</td>
            </tr>
            <tr>
              <td>건유기</td>
              <td>306∼365</td>
              <td>-</td>
              <td>5.2</td>
              <td>0.88</td>
              <td>9.4</td>
              <td>1.7</td>
            </tr>
          </table>
          <p>그리고 연간 조사료 소요량을 추정해 보면 아래 &lt;표 12&gt;와 같다.</p><br/>
          <p><b>&lt;표 12&gt; 산유기별 1일 사료 소요량</b></p>
          <table className="tablestyle">
            <tr>
              <td rowSpan="2">주기</td>
              <td rowSpan="2">기간(일)</td>
              <td rowSpan="2">1일건물섭취량</td>
              <td colSpan="3">청초기(1일)</td>
              <td colSpan="3">사사기(1일)</td>
            </tr>
            <tr style={{background: '#273143',color:'#fff'}}>
              <td>청초(kg)</td>
              <td>건초(kg)</td>
              <td>농후사료(kg)</td>
              <td>싸일레지(kg)</td>
              <td>건초(kg)</td>
              <td>농후사료(kg)</td>
            </tr>
            <tr>
              <td>산유초기</td>
              <td>0~70</td>
              <td>15.4</td>
              <td>16.0</td>
              <td>3.0</td>
              <td>10.0</td>
              <td>10.0</td>
              <td>2.0</td>
              <td>12.0</td>
            </tr>
            <tr>
              <td>산유중기</td>
              <td>70∼140</td>
              <td>17.1</td>
              <td>34.0</td>
              <td>4.0</td>
              <td>8.0</td>
              <td>20.0</td>
              <td>4.4</td>
              <td>9.0</td>
            </tr>
            <tr>
              <td>산유후기</td>
              <td>140∼305</td>
              <td>13.2</td>
              <td>46.0</td>
              <td>7.0</td>
              <td>-</td>
              <td>33.0</td>
              <td>7.0</td>
              <td>-</td>
            </tr>
            <tr>
              <td>건유기</td>
              <td>306∼365</td>
              <td>9.4</td>
              <td>30.0</td>
              <td>4.5</td>
              <td>-</td>
              <td>22.0</td>
              <td>5.5</td>
              <td>-</td>
            </tr>
          </table>
        </li>
      </ul>
    </div>
  );
}

export default Do178;