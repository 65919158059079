import React from 'react';

function Do120() {

  return (
    <div className="techinfoview">
      <h1>자궁의 종양</h1>
      <p className="pstyle">소에서는 생식기 종양이 드물지만 때로는 자궁과 질, 외음부 등에 발생하여 불임의 원인이 되는 일이 있다. </p>
      <div className="leftpart">
        <h2>원인</h2>
        <ul>
          <li> 종양의 발생원인은 대부분 불명이다. </li>
          <li>외음부에 생긴 섬유 유두종(사마귀와 같은 것)이나 자궁에 볼 수 있는 백혈병성종양은 바이러스 감염이 원인이 되는 경우가 있다. </li>
        </ul>
        <h2>증상</h2>
        <ul>
          <li>대부분 식욕과 원기 등 외부징후는 변화가 없다. </li>
          <li>난소과립막 세포종에서는 다량의 난포호르몬이 분비되는 일이 있어 이 영향으로 지속성 발정이나 사모광증을 보이며, 처녀소가 비유를 시작하는 경우가 있다. </li>
          <li>백혈병성의 종양이 자궁으로 옮겨간 예에서는 체표면에도 종양을 볼 수 있는 경우가 있다. 혈액검사도 참고가 된다. </li>	
          <li>난소와 자국의 종양은 직장검사에 의해 발견된다. </li>				
          <li>난소가 귤의 크기(직경 10cm)이상으로 된 경우는 거의 종양으로 생각해도 좋다. </li>				
        </ul>
        <h2>목장에서의 처치</h2>
        <ul>
          <li>응급처치 방법은 없고 이상이 발견되면 수의사의 완진을 의뢰하여 정확한 진단과 치료를 받는다. </li>
          <li>백혈병이나 악성종양일때는 조기 도태해서 경제적 손실을 줄여야 한다. </li>
        </ul>
      </div>
      <div className="rightpart">
      </div>
    </div>
  );
}

export default Do120;