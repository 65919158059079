import React from 'react';


function Do56() {

  return (
    <div className="techinfoview">
				<h1>젖먹이 송아지 사양관리(4일령~이유시까지)-냉동초유와 발효초유 이용</h1>
				<ul>
					<li>송아지에게 먹이고 남은 초유나 분만후 6회까지 짠 우유는 마시거나 납유할 수 없게 되어 있는데 이것을 버리지 말고 냉동 또는 발효시켜 저장했다가 이용하면 다음과 같은 이점이 있으며 송아지의 발육에도 좋은 효과가 있다. (&lt;표 52&gt;). 
						<ul>
							<li>초유의 영양가는 다른 액상사료(대용유) 보다 높다. </li>
							<li>초유로서 전유를 완전히 대체할 수 있다. </li>
							<li>다른 액상 사료를 급여한 경우보다 발육성적이 좋다. </li>
							<li>포유기간중 송아지 설사의 빈도가 낮다. </li>
							<li>상품 가치가 없는 초유를 급여하므로 써 송아지 육성 비용을 절감한다. </li>
						</ul><br/>
						<p><b>&lt;표 52&gt; 전유와 냉동 저장한 초유급여가 송아지 발육에 미치는 영향</b></p>
						<table className="tablestyle">
							<tr>
								<td>항목</td>
								<td>전유</td>
								<td>냉동저장한 초유</td>
								<td>증가율</td>
							</tr>
							<tr>
								<td>일당증체량 (kg)</td>
								<td></td>
								<td></td>
								<td></td>
							</tr>
							<tr>
								<td>생후 3주 평균</td>
								<td>0.19</td>
								<td>0.30</td>
								<td>+57%</td>
							</tr>
							<tr>
								<td>생후 6주 평균 </td>
								<td>0.29</td>
								<td>0.38</td>
								<td>+35%</td>
							</tr>
							<tr>
								<td>생후 12주 평균</td>
								<td>0.44</td>
								<td>0.55</td>
								<td>+25%</td>
							</tr>
							<tr>
								<td>마리당 설사한 일수 (일)</td>
								<td>3.1</td>
								<td>3.6</td>
								<td>-</td>
							</tr>
							<tr>
								<td>폐 사 율</td>
								<td>1/24</td>
								<td>1/24</td>
								<td>-</td>
							</tr>
						</table>
						<p>주 : 덥혀서 급여한 경우(대조구)나 차게 급여한 경우(시험구)의 송아지 발육성적에 유의적인 차이가 없었음. <br/><br/>한편 초유에 의한 발육 성적은 초유의 저장 방법에 따라 다른데 &lt;표 53&gt;에서 보면 냉동 저장한 초유를 급여한 송아지의 증체가 자연 발효에 의한 저장한 초유를 급여한 경우보다 높았다. <br/><br/></p>
						<p><b>&lt;표 53&gt; 초유의 저장 방법에 따른 송아지 발육 성적 </b></p>
						<table className="tablestyle">
							<tr>
								<td rowSpan="2">초유의 종류</td>
								<td colSpan="2">두 당 총 증 체 량</td>
							</tr>
							<tr style={{background: '#273143', color: '#fff'}}>
								<td>생후 21일령까지</td>
								<td>생후 40일령까지</td>
							</tr>
							<tr>
								<td></td>
								<td colSpan="2">……………………………………… kg ………………………………………</td>
							</tr>
							<tr>
								<td>냉동저장한 초유 전유</td>
								<td>4.0</td>
								<td>14.7A</td>
							</tr>
							<tr>
								<td>자연발효에 의해</td>
								<td>3.5 (85%)</td>
								<td>13.2 (90%)</td>
							</tr>
							<tr>
								<td>저 장 한 초 유</td>
								<td>3.5 (87%)</td>
								<td>11.9 (81%)B </td>
							</tr>
						</table>
						<p> A, B는 5% 수준에서 유의적이었음 </p><br/>
					</li>
				</ul><br/>
				<ul>
					<li>냉동 초유 이용 
						<p>송아지에게 먹이고 남은 초유를 냉동 저장했다가 녹여서 먹이는 방법으로서 초유의 성분을 크게 변화시키지 않고 손쉽게 이용할 수 있다. 특히 어미소의 초유에 이상이 있어서 대신 먹여야 할 경우에 사용할 수 있으며 전유나 대용유 대신에 급여하면 성장도 빠르고 경제적이다 (&lt;표 53&gt; 참조). <br/>냉동 저장하는 방법은 1회 급여량에 해당하는 양(1.6∼2.0kg)을 그릇에 담아 그림과 같이 얼려서 보관하며 이용할 때는 실온에서 천천히 녹여서 먹인다. 갑자기 가열해서 녹이면 항체의 역가가 떨이지기 때문에 좋지 않다. </p>
					</li>
					<li>발효 초유 이용
						<p><b>&lt;표 54&gt;  전유와 발효초유 급여시 일당증체량 비교 </b></p>
						<table className="tablestyle">
							<tr>
								<td>주령</td>
								<td>전유</td>
								<td>자연 발효</td>
								<td>산처리 발효</td>
							</tr>
							<tr>
								<td></td>
								<td colSpan="3">…………………………………………… kg ………………………………………………</td>
							</tr>
							<tr>
								<td>0-4주</td>
								<td>0.59</td>
								<td>0.46</td>
								<td>0.53</td>
							</tr>
							<tr>
								<td>0-5주</td>
								<td>0.66</td>
								<td>0.66</td>
								<td>0.59</td>
							</tr>
						</table>
						<p>* 프로피온산 1% 처리</p><br/>
						<p>남은 초유를 실온에 저장하여 발효시켜서 이용하는 방법으로 자연 발효와 산처리 발효 방법이 있다. 전유를 급여한 경우보다 송아지의 발육이 약간 되지기는 하지만 시판 대용유를 먹인 경우보다 좋다. 미국의 미네소타 대학에서 연구한 전유와 발효초유급여시 송아지 발육 성적을 보면 &lt;표 54&gt;와 같다. </p>
						<br/>
					</li>
					<li>발효 초유 이용의 실제
						<ul>
							<li>양질의 신선한 초유만을 이용한다. 측 혈유나 항생제 처리된 우유는 섞지 않는다. 이러한 비정상 초유는 발효를 방해하기 때문이다. </li>
							<li>저장 용기는 산에 잘 견디는 플라스틱 제품을 이용하는 것이 좋으며 밀봉한다. </li>
							<li>10∼15℃의 실온에서 보관한다. 이보다 기온이 높으면 젖산발효가 너무 활발정해져서 유단백의 급격한 파괴가 일어나며 낮으면 발효가 잘되지 않는다. </li>
							<li>새로 짠 초유를 섞을 때는 천천히 혼합한다.</li>
							<li>송아지에게 먹일 때는 잘 저은 뒤에 떠내고 다시 밀봉해 둔다. </li>
							<li>발효와 물을 3 : 1의 비율로 희석해서 먹인다. </li>
							<li>먹이는 양은 송아지 체중의 8∼10% 정도가 좋다 (희석한 초유 1일 급여량).·분만후 48시간 이내에는 먹이지 않는다. 
								<ul>
									<li>발효 과정에서 초유의 항체 농도가 떨어지고 산에 의해서 소화 기능에 이상이 생기기 때문이다. </li>
									<li>생후 3일째부터는 급여해도 좋다. </li>
								</ul>
							</li>
							<li>발효 초유는 3∼4주 이내에 소비해야 한다. 
								<ul>
									<li>장기간 저장시는 영양소 특히 단백질이 파괴되기 때문이다. </li>
								</ul>
							</li>
							<li>곰팡이가 핀 발효 초유는 급여하지 않는다. </li>
							<li>21℃ 이상 고온 저장시 등에는 프로피온산 또는 초산을 초유의 1% 정도 첨가한다. 이를 산처리 발효 초산을 한다.</li>
						</ul>
					</li>
				</ul>
			</div>
  );
}

export default Do56;
