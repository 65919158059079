import React from 'react';

import pic309 from 'assets/images/sub/_images/pic309.png';

function Do179() {

  return (
    <div className="techinfoview">
      <h1>사료의 급여요령</h1>
      <h2>사료의 영양가치 표시방법 </h2>
      <p>사료의 영양가치는 조성분의 함량을 보아 어느정도 알 수 있으나 조성분에 따라서 젖소에 대한 이용성이 다르므로 조성분 중에서 실제로 소화 흡수되어 생산에 이용되는 즉 가소화성분이 중요하다.</p>
      <ul>
        <li>가소화양분
          <ul>
            <li>사료의 각 성분에다 각 성분의 소화율을 곱한 것을 말하는데 실제로 젖소에 이용되는 성분이다.<br/>가소화양분 = 사료의 조성분함량(%) × 소화율(%) </li>
          </ul>
        </li>
        <li>가소화양분 총량(TDN)
          <ul>
            <li>단백질, 지방, 탄수화물 등의 가소화 양분을 총계한 것으로 가소화 조지방에 2.25를 곱한 것은 지방의 에너지함량이 단백질이나 탄수화물에 비해 약 2.25배가 많기 때문이다.<br/>TDN(%)가소화조단백질(%) + 가소화조지방(%) × 2.25 + 가소화조섬유(%) + 가소화가용물질소물(%) </li>
          </ul>
        </li>
        <li>가소화 조단백질(DCP) 
          <ul>
            <li>가축이 섭취하여 소화가 가능한 사료중의 조단백질 함량을 나타내는 것으로 순단백질과 비단백태 질소화합물(NPN)을 총칭하여 조단백질이라 한다.</li>
          </ul>
        </li>
        <li>가용무질소물(NFE) 
          <ul>
            <li>사료의 전체함량에서 수분, 조단백질, 조지방, 조섬유 및 조회분을 뺀 나머지를 가용무질소물이라 하며 백분율로 나타내는데 NFE는 전분담류, 덱스트린 등으로 되어 있고 식물성 사료에 함량이 높으며 비교적 값이 싸고 소화가 잘 된다.</li>
          </ul>
        </li>
        <li>비단백태 질소화합물(NPN)
          <ul>
            <li> NPN은 단백질은 아니나 질소를 함유하는 화합물로서 반추위의 미생물에 의해 단백질 합성에 이용된다. NPN에는 요소, 아마이드 등이 있다.</li>
          </ul>
          <p>사료의 에너지가 표시방법으로는 위에서 서술한 TDN외에도 여러 가지가 있으며 최근에는 TDN이 갖는 문제점 때문에 점차 정미에너지에 의한 평가방법으로 전환되고 있다.</p>
          <br/>
        </li>
      </ul>
      <h2>사료의 급여량 결정</h2>
      <ul>
        <li>젖소의 사료급여량을 계산한다는 것은 무척 까다롭고 어렵다. 또한 정확한 영양소 요구량을 산출해도 사양의 설제에 있어서 이론적인 수치와 꼭 맞추기는 매우 어려우니 낙농을 합리적으로 경영하고 건강한 우군을 유지시키기 위하여는 사료의 적정급여량의 근사치를 구하여야 하며 급여방법도 적기 적량의 사양이 이루어질 수 있도록 해야한다.</li>
        <li>우선 급여할 사료의 양은 젖소가 얼마나 섭취할 수 있는 것인가를 결정할 수 있으며 사료섭취량은 건물량을 기준으로 하는데 건물(DM)이라 함은 일반적으로 사료중의 수분함량을 제외한 부분을 지칭하며 고형물이라고도 한다.</li>
        <li>청초나 사일리지 같은 다즙사료는 수분함량이 많기 때문에 섭취량을 계산하기 위하여 건물량을 측정하여야 하며 일반 풍건상태의 건조된 조사료도 건물량이나 DCP, TDN 등을 알 수 있어야 적정급여기준을 설정할 수 있다.</li>
        <li>일반적으로 젖소의 건물요구량은 체중의 약 3% 정도 섭취해야 하므로 조사료와 농후사료의 비율은 총 필요건물량중 1/3은 조사료, 또 1/3은 농후사료, 나머지 1/3은 두 사료중 하나를 급여하면 되는데 급여조사료의 질이 나쁠 경우는 농후사료로 급여하고 조사료의 질이 좋으면 나머지 1/3량도 같은 조사료로서 공급하여도 무방하다. </li>
        <li>일반적으로 젖소는 조사료 급여 수준을 체중의 1.8% 정도의 건물량을 섭취하면 좋다.</li>
      </ul><br/>
      <p><b>&lt;표 13&gt; 일반사료의 건물함량(%)</b></p>
      <table className="tablestyle">
        <tr>
          <td>사료명</td>
          <td>건물량</td>
          <td>사료명</td>
          <td>건물량</td>
        </tr>
        <tr>
          <td>곡류</td>
          <td>86∼90</td>
          <td>청초</td>
          <td>15∼40</td>
        </tr>
        <tr>
          <td>피, 밀기울</td>
          <td>86∼87</td>
          <td>근채류</td>
          <td>10∼30</td>
        </tr>
        <tr>
          <td>깻묵류</td>
          <td>87∼89</td>
          <td>엽채류</td>
          <td>10∼20</td>
        </tr>
        <tr>
          <td>건초</td>
          <td>85∼90</td>
          <td>사일리지</td>
          <td>20∼30</td>
        </tr>
        <tr>
          <td>짚</td>
          <td>85∼87</td>
          <td>비지, 전분박</td>
          <td>10∼20</td>
        </tr>
      </table>
      <h2>사료의 계산법</h2>
      <ul>
        <li>영양소 요구량 산출
          <ul>
            <li>젖소가 필요한 사료의 급여량을 산출하려면 먼저 젖소가 필요로 하는 영양소 요구량을 알아야 한다.</li>
            <li>젖소용 사료성분을 사양표준의 양분 구분을 이용한다.
              <br/><p><b>&lt;표 14&gt; 생육기별 양분구분</b></p>
              <table className="tablestyle">
                <tr>
                  <td>구분</td>
                  <td>양분</td>
                </tr>
                <tr>
                  <td>건유기(임신하지 않은) 암소유지</td>
                  <td>성빈우 유지양분 </td>
                </tr>
                <tr>
                  <td>젖생산을 할 경우</td>
                  <td>성빈우 유지양분 + 산유양분</td>
                </tr>
                <tr>
                  <td>분만전 2∼3개월</td>
                  <td>성빈우 유지양분 + 임신양분</td>
                </tr>
              </table>
              <p>※ 예 : 체중 600kg, 유지율 3.5%, 산유량 2.0kg의 착유우 양분 요구량은 위 &lt;표 14&gt; 2번항목에 해당되며 이 항목을 구해서 합하면 된다. </p>
            </li>
            <li>여러분들이 갖고 있는 사양표준을 보면 체중과 유량에 따른 DCP, TDN량을 찾아서 합계하면 DCP : 1.23kg, TDN : 10.63kg이 되며 이 양분이 체중 600kg, 유지율 3.5%, 산유량 20kg의 착유우 양분 요구량이 된다.</li>
            <li>또한 유지율이 3.5%가 아닐 경우에는 구해진 양분총량에 유지방률(%)에 따른 양분을 가감하면 된다.</li>
            <li>육성우는 성장중인 젖소의 사양표준 란에서 체중별로 DCP, TDN량을 구하면 된다.</li>
          </ul>
        </li>
        <li>사료급여량 계산
          <ul>
            <li>일단 젖소가 필요로 하는 영양소 요구량이 구해졌으면 이어서 사료급여량을 결정하여야 하며 앞에서 언급한 바와 같이 젖소가 필요로 하는 건물요구량을 계산하여야 하는데 일반적으로 체중의 3%정도만 급여하면 되나 산유량 유지방 및 체중에 따라 섭취량이 달라진다.</li>
            <li>건물섭취량 계산<br/>체중 600kg, 유지율 3.5%, 산유량 20kg의 착유우일 경우 건물요구량은?<br/>
              <p><b>&lt;표 15&gt; 착유우의 체중 및 유지방, 산유량에 따른 최대 건물 요구량</b></p>
              <img src={pic309} className="nonborder" alt=""/>
              <p>&lt;표 15&gt;에서 보는 바와 같이 건물요구량은 15.6kg임을 알 수 있다.(농후사료 및 조사료 포함)</p>
            </li>
            <li>급여사료의 종류<br/>야건초, 볏짚, 사일리지, 착유3호를 급여한다고 하면 각 사료의 조성분(%)은 젖소용 사료성분을 참고하여 구하면 된다.
              <p><b>&lt;표 16&gt; 사료의 조성분(%)</b></p>
              <table className="tablestyle">
                <tr>
                  <td>구분</td>
                  <td>건물(DM)</td>
                  <td>DCP</td>
                  <td>TDN</td>
                </tr>
                <tr>
                  <td>야건초</td>
                  <td>85.9</td>
                  <td>1.9</td>
                  <td>40.0</td>
                </tr>
                <tr>
                  <td>볏짚</td>
                  <td>88.0</td>
                  <td>1.7</td>
                  <td>37.5</td>
                </tr>
                <tr>
                  <td>옥수수사일리지(황숙기)</td>
                  <td>24.9</td>
                  <td>1.2</td>
                  <td>16.7</td>
                </tr>
                <tr>
                  <td>착유3호</td>
                  <td>87.0</td>
                  <td>13.3</td>
                  <td>70.8</td>
                </tr>
              </table>
              <ul>
                <li>조사료 요구량(건물)
                  <p>조사료는 체중의 1.8% 정도의 건물을 섭취해야 하므로<br/>600kg × 0.018 = 10.8 ----- 조사료 건물함량 </p>
                  <ul>
                    <li>야건초, 볏짚, 옥수수사일리지는 조사료에 해당함</li>
                  </ul>
                </li>
                <li>농후사료 요구량(건물)
                  <p>총건물요구량 - 조사료건물요구량 = 농후사료건물요구량<br/>15.6 - 10.8 = 4.8 ------- 농후사료건물요구량</p>
                  <ul>
                    <li>착유3호는 농후사료임. </li>
                  </ul>
                </li>
              </ul>
            </li>
            <li>급여량 산출
              <ul>
                <li> 사일리지를 18kg, 볏짚을 3kg 급여한다고 하면 사일리지 1kg 중에는 건물이 24.9%가 되므로 18 × 0.249 = 4.48kg(건물량)</li>
                <li>또한 볏짚 1kg중에는 건물이 88%가 되므로 3kg × 0.88 = 2.64kg(건물량)</li>
                <li>따라서 야건초 급여량은 총 조사료 건물량에서 사일리지, 볏짚, 건물량을 제외한 값을 야건초를 급여하면 된다.
                  <br/><p>10.8 - (4.48 + 2.64) = 3.68(야건초 건물량)</p><br/>
                  <p>그러므로 실제 급여량은 야건초의 DM이 85.9%이므로<br/>3.68 ÷ 0.859 = 4.3kg이 된다.</p><br/>
                  <p>※ 위의 조사료 급여량을 사료성분표로부터 각 사료의 DM(건물), DCP(가소화조단백질), TDN(가소화 양분 총량)의 성분량을 곱하여 산출된 영양소와 영양소 요구량을 비교하여 보면 다음과 같다. </p><br/>
                  <p><b>&lt;표 17&gt; 영양소와 영양소 요구량 계산 &quot;예&quot;</b></p>
                  <table className="tablestyle">
                    <tr>
                      <td colSpan="2">구분</td>
                      <td>급여량</td>
                      <td>건물</td>
                      <td>DCP </td>
                      <td>TDN</td>
                    </tr>
                    <tr>
                      <td colSpan="2">영양소요구량</td>
                      <td></td>
                      <td>15.6kg</td>
                      <td>1.23kg</td>
                      <td>10.63kg</td>
                    </tr>
                    <tr>
                      <td rowSpan="4">영양소공급량</td>
                      <td>야건초</td>
                      <td>4.3kg</td>
                      <td>3.69</td>
                      <td>0.0817</td>
                      <td>6.720</td>
                    </tr>
                    <tr>
                      <td>볏짚</td>
                      <td>3</td>
                      <td>2.64</td>
                      <td>0.451</td>
                      <td>6.125</td>
                    </tr>
                    <tr>
                      <td>사일리지</td>
                      <td>18</td>
                      <td>4.48</td>
                      <td>0.216</td>
                      <td>3.006</td>
                    </tr>
                    <tr>
                      <td>계</td>
                      <td></td>
                      <td>10.81</td>
                      <td>0.3487</td>
                      <td>5.851</td>
                    </tr>
                    <tr>
                      <td colSpan="2">과부족(배합사료보충량)</td>
                      <td></td>
                      <td>4.79</td>
                      <td>0.8813</td>
                      <td>4.779</td>
                    </tr>
                    <tr>
                      <td colSpan="2">배합사료(착유3호)</td>
                      <td>6.75</td>
                      <td>5.87</td>
                      <td>0.8977</td>
                      <td>4.779</td>
                    </tr>
                  </table><br/>
                  <p className="pstyle">위 표에서 보는바와 같이 조사료 급여량과 영양소 요구량을 비교해 보면 DCP, TDN 각각0.8813kg, 4,779kg이 부족됨을 알수 있으며 이의 보충을 위해서는 배합사료 (착유3호)를 6.75kg을 보충해 주어야 한다.<br/>TDN이 4,779kg이 부족하므로 4,779kg÷0.708 =6,75kg(착유3호 1kg에 대한 TDN은 70.8% 정도임)</p><br/>
                  <p><b>&lt;표18&gt; 앞에서 계산된 사료의 충족도 </b></p>
                  <table className="tablestyle">
                    <tr>
                      <td>구 분</td>
                      <td>건 물</td>
                      <td>D C P</td>
                      <td>T D N</td>
                    </tr>
                    <tr>
                      <td>영양소요구량</td>
                      <td>15.6kg</td>
                      <td>1.23kg</td>
                      <td>10.63kg</td>
                    </tr>
                    <tr>
                      <td>급 여 량</td>
                      <td>16.68</td>
                      <td>1.2464</td>
                      <td>10.63</td>
                    </tr>
                    <tr>
                      <td>충 족 도</td>
                      <td>107</td>
                      <td>101</td>
                      <td>100</td>
                    </tr>
                  </table>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
}

export default Do179;