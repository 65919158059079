import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getState, postState, delState } from 'utils/api';
import { useQueryClient } from '@tanstack/react-query';

function UserEdit() {
  const [searchParams] = useSearchParams();
  const aid = searchParams.get('aid');
  const grpId = searchParams.get('grp_id');

  const [user, setUser] = useState(null);
  const [formData, setFormData] = useState({
    aid: '',
    passwd: '',
    usr_name: '',
    usr_desc: '',
    usr_stat: '0',
    usr_menu: '0',
    grp_id: grpId || '',
  });
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await getState('/board/select_user', { aid });
        if (response?.return_code === 'SUCCESS') {
          setUser(response.result);
          setFormData({
            aid: response.result.AID,
            passwd: response.result.PASSWD,
            usr_name: response.result.USR_NAME,
            usr_desc: response.result.USR_DESC,
            usr_stat: String(response.result.USR_STAT),
            usr_menu: String(response.result.USR_MENU),
            grp_id: response.result.GRP_ID || grpId,
          });
        }
      } catch (error) {
        console.error('Error fetching user:', error);
      }
    };

    if (aid) {
      fetchUser();
    } else if (grpId) {
      setFormData((prev) => ({ ...prev, grp_id: grpId }));
    }
  }, [aid, grpId]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validation
    if (!formData.aid) {
      alert('사용자 ID를 입력해주세요.');
      return;
    }
    if (!formData.passwd) {
      alert('비밀번호를 입력해주세요.');
      return;
    }
    if (!formData.usr_name) {
      alert('사용자명을 입력해주세요.');
      return;
    }

    try {
      const endpoint = aid ? '/board/update_user/' : '/board/insert_user/';
      const submitData = aid
        ? {
            aid: formData.aid,
            passwd: formData.passwd,
            usr_name: formData.usr_name,
            usr_desc: formData.usr_desc,
            usr_stat: formData.usr_stat,
            usr_menu: formData.usr_menu,
          }
        : formData;

      const response = await postState(endpoint, submitData);
      if (response?.return_code === 'SUCCESS') {
        queryClient.invalidateQueries(['users']);
        alert(aid ? '수정되었습니다.' : '등록되었습니다.');
        navigate(
          aid
            ? `/bo/user/userView?aid=${aid}`
            : `/bo/user/userList?grp_id=${grpId}`,
        );
      }
      if (response?.return_code === 'ERROR') {
        alert(response?.return_message);
      }
    } catch (error) {
      console.error('Error saving user:', error);
      alert('저장 중 오류가 발생했습니다.');
    }
  };

  const handleDelete = async () => {
    if (window.confirm('정말 삭제하시겠습니까?')) {
      try {
        const response = await postState('/board/delete_user/', { aid });
        if (response?.return_code === 'SUCCESS') {
          queryClient.invalidateQueries(['users']);
          alert('삭제되었습니다.');
          navigate(`/bo/user/userList?grp_id=${grpId}`);
        }
        if (response?.return_code === 'ERROR') {
          alert(response?.return_message);
        }
      } catch (error) {
        console.error('Error deleting user:', error);
        alert('삭제 중 오류가 발생했습니다.');
      }
    }
  };

  return (
    <div className="bo-content">
      <h2>사용자 {aid ? '수정' : '등록'}</h2>
      <div className="table-container bold-border">
        <table>
          <tbody>
            <tr>
              <th>
                사용자 아이디<span className="required">*</span>
              </th>
              <td className="nr">
                {aid ? (
                  formData.aid
                ) : (
                  <>
                    <input
                      type="text"
                      value={formData.aid}
                      onChange={(e) =>
                        setFormData({ ...formData, aid: e.target.value })
                      }
                      maxLength={50}
                    />
                  </>
                )}
              </td>
            </tr>
            <tr>
              <th>
                비밀번호<span className="required">*</span>
              </th>
              <td className="nr">
                <input
                  type="password"
                  value={formData.passwd || ''}
                  onChange={(e) =>
                    setFormData({ ...formData, passwd: e.target.value })
                  }
                  maxLength={15}
                />
              </td>
            </tr>
            <tr>
              <th>
                사용자명<span className="required">*</span>
              </th>
              <td className="nr">
                <input
                  type="text"
                  value={formData.usr_name || ''}
                  onChange={(e) =>
                    setFormData({ ...formData, usr_name: e.target.value })
                  }
                  maxLength={50}
                />
              </td>
            </tr>
            <tr>
              <th>설명</th>
              <td className="nr">
                <input
                  type="text"
                  value={formData.usr_desc || ''}
                  onChange={(e) =>
                    setFormData({ ...formData, usr_desc: e.target.value })
                  }
                  maxLength={50}
                />
              </td>
            </tr>
            <tr>
              <th>
                상태<span className="required">*</span>
              </th>
              <td className="nr">
                <label>
                  <input
                    type="radio"
                    name="usr_stat"
                    value="0"
                    checked={formData.usr_stat === '0'}
                    onChange={(e) =>
                      setFormData({ ...formData, usr_stat: e.target.value })
                    }
                  />
                  정상
                </label>
                <label>
                  <input
                    type="radio"
                    name="usr_stat"
                    value="1"
                    checked={formData.usr_stat === '1'}
                    onChange={(e) =>
                      setFormData({ ...formData, usr_stat: e.target.value })
                    }
                  />
                  사용불가
                </label>
              </td>
            </tr>
            <tr>
              <th>
                권한<span className="required">*</span>
              </th>
              <td className="nr">
                {[
                  '전체',
                  '정액관리',
                  '전문가상담실',
                  '뉴스그룹',
                  '청정육종농가',
                ].map((label, index) => (
                  <label key={index}>
                    <input
                      type="radio"
                      name="usr_menu"
                      value={index.toString()}
                      checked={formData.usr_menu === index.toString()}
                      onChange={(e) =>
                        setFormData({ ...formData, usr_menu: e.target.value })
                      }
                    />
                    {label}
                  </label>
                ))}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="button-group">
        <button type="button" onClick={handleSubmit} className="btn-l">
          사용자 {aid ? '수정' : '등록'}
        </button>
        {aid && (
          <button type="button" onClick={handleDelete} className="btn-l">
            사용자 삭제
          </button>
        )}
        <button
          type="button"
          onClick={() => navigate(`/bo/user/userList?grp_id=${grpId}`)}
          className="btn-l"
        >
          사용자 목록
        </button>
      </div>
    </div>
  );
}

export default UserEdit;
