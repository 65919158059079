import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

// react-query
import { useQuery, useQueryClient } from '@tanstack/react-query';

// api
import { getState, postState } from 'utils/api';

// constants
import { BOARD_NAME } from 'constants/backOfficeBoard';

function MonthFarmBestView() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const bdcCode = searchParams.get('bdc_code');
  const brdCode = searchParams.get('brd_code');

  const { data } = useQuery({
    queryKey: ['farmBest', bdcCode],
    queryFn: () =>
      getState('/board/select_farm_test', {
        bdc_code: bdcCode,
        brd_code: brdCode,
      }),
    enabled: !!bdcCode,
  });

  const farmBest = data?.result;

  const handleList = () => {
    navigate('/bo/farmTest/monthFarmBestList');
  };

  const handleDelete = async () => {
    if (window.confirm('농가 정보를 삭제 하시겠습니까?')) {
      try {
        const response = await postState('/board/delete_farm_test/', {
          bdc_code: bdcCode,
          brd_code: brdCode,
        });
        if (response?.return_code === 'SUCCESS') {
          alert('삭제 완료');
          queryClient.invalidateQueries({
            queryKey: ['farmBest'],
          });
          handleList();
        }
        if (response?.return_code === 'ERROR') {
          alert(response?.return_message);
        }
      } catch (error) {
        console.error('Delete farm test error:', error);
        alert('삭제 중 오류가 발생했습니다.');
      }
    }
  };

  const handleEdit = () => {
    navigate(`/bo/farmTest/EditmonthFarmBest/${bdcCode}`, {
      state: { bdc_wname: farmBest?.bdc_wname, brd_code: brdCode },
    });
  };

  return (
    <div className="bo-content">
      <h2>{BOARD_NAME.MONTH_FARM_BEST} 정보</h2>

      <div className="table-container bold-border">
        <table>
          <tbody>
            <tr>
              <th width="20%">제목</th>
              <td style={{ textAlign: 'start' }}>{farmBest?.bdc_title}</td>
            </tr>
            <tr>
              <th>년월</th>
              <td style={{ textAlign: 'start' }}>{farmBest?.bdc_wname}</td>
            </tr>
            <tr>
              <th>등록일</th>
              <td style={{ textAlign: 'start' }}>{farmBest?.bdc_rdt}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="button-group">
        <button type="button" onClick={handleEdit} className="btn-l">
          {bdcCode ? '수정' : '등록'}
        </button>
        <button type="button" onClick={handleDelete} className="btn-l">
          삭제
        </button>
        <button type="button" onClick={handleList} className="btn-l">
          목록
        </button>
      </div>
    </div>
  );
}

export default MonthFarmBestView;
