import React, { useState , useEffect } from 'react';
import moment from 'moment';

// api
import { postState } from 'utils/api';
import { openCrownix6 } from 'utils/openCrownix';

// 이미지
import PrintPDF from 'assets/svgs/common/print_PDF.svg';
import Logo from 'assets/images/common/header_logo_pc.png';

// 컴포넌트
import ErrorMessage from 'components/errorMessage/errorMessage';

// scss
import './NewPlan.scss';

function NewPlan() {

  // 에러처리
  const [isModalOpen, setIsModalOpen] = useState(false); // 모달 추가
  const [errorMessage, setErrorMessage] = useState(''); 

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => {
    setIsModalOpen(false);
    setErrorMessage(''); // 모달이 닫힐 때 에러메세지 초기화
  };

  // 데이터 불러오기
  const baseDir='/dcitest/dcifarm/newplan/';
  const [ mainData , setMainData ] = useState();
  const [ props , setProps] = useState({
    id:`${localStorage.getItem('cong')}${localStorage.getItem('farm')}`,
    passwd:localStorage.getItem('passwd'),
    date:localStorage.getItem('month')
  });

  useEffect(() => {

    postState(baseDir,props)
    .then(getdata => {      
      if (getdata !== undefined) {
    //   console.log ('#### props : ' ,props);
      setMainData(getdata);
    //   console.log ('#### data : ' ,getdata);
      }
    })
    .catch(error => {      
      console.error('Error:', error.message);      
      setErrorMessage(error.message);
      openModal();
    });
    
  }, [props]);
  
  return (
    <div className='pdfMainArea'>
      <ErrorMessage isOpen={isModalOpen} content={errorMessage} closeModal={closeModal}/>
      <div className='pdfMainHeader'>
        <button 
          type='button'
          onClick={() => openCrownix6('New_plan.mrd')}
        >
          <img src={PrintPDF} alt='PDF로 인쇄하기' />
          인쇄하기
        </button>
      </div>
      <div className='pdfArea'>
        <div className='newPlanPdf'>
          <h1>월간 우군관리 계획표</h1>
          <ul className='newPlanInfo'>
            <li>
              농가명 : {mainData && mainData.farm_property[0].fname}
            </li>
            <li>
              축주명 : {mainData && mainData.farm_property[0].fdname}
            </li>
            <li>
              주소 : {mainData && mainData.farm_property[0].fadd}
            </li>
          </ul>
          <ul className='newPlanTabels'>
            <li>
              <span>분만대상우</span>
              <table className='pdfTable'>
                <thead>
                  <tr>
                    <th>관리번호</th>
                    <th>분만예정일</th>
                    <th>산차</th>
                    <th>등록번호</th>
                    <th>생년월일</th>
                    <th>상태</th>
                    <th>분만/건유일</th>
                    <th>최종수정일</th>
                    <th>최종정액코드</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    mainData &&
                    mainData.plot_data.map((item) => (
                      <tr>
                        <td>{item.sname}</td>
                        <td>{moment(item.prdate).format('YYYY-MM-DD')}</td>
                        <td>{item.pari}</td>
                        <td>{item.regno}</td>
                        <td>{item.birth}</td>
                        <td>{item.stat}</td>
                        <td>{moment(item.cddate).format('YYYY-MM-DD')}</td>
                        <td>{moment(item.lisdate).format('YYYY-MM-DD')}</td>       
                        <td>{item.semen}</td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </li>
            <li>
              <span>건유대상우</span>
              <table className='pdfTable'>
                <thead>
                  <tr>
                    <th>관리번호</th>
                    <th>건유예정일</th>
                    <th>산차</th>
                    <th>등록번호</th>
                    <th>생년월일</th>
                    <th>상태</th>
                    <th>분만/건유일</th>
                    <th>최종수정일</th>
                    <th>최종정액코드</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    mainData &&
                    mainData.plot_data2.map((item) => (
                      <tr>
                        <td>{item.sname}</td>
                        <td>{moment(item.prdate).format('YYYY-MM-DD')}</td>
                        <td>{item.pari}</td>
                        <td>{item.regno}</td>
                        <td>{item.birth}</td>
                        <td>{item.stat}</td>
                        <td>{moment(item.cddate).format('YYYY-MM-DD')}</td>
                        <td>{moment(item.lisdate).format('YYYY-MM-DD')}</td>       
                        <td>{item.semen}</td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </li>
            <li>
              <span>감정대상우</span>
              <table className='pdfTable'>
                <thead>
                  <tr>
                    <th>관리번호</th>
                    <th>감정예정일</th>
                    <th>산차</th>
                    <th>등록번호</th>
                    <th>생년월일</th>
                    <th>상태</th>
                    <th>분만/건유일</th>
                    <th>최종수정일</th>
                    <th>최종정액코드</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    mainData &&
                    mainData.plot_data3.map((item) => (
                      <tr>
                        <td>{item.sname}</td>
                        <td>{moment(item.prdate).format('YYYY-MM-DD')}</td>
                        <td>{item.pari}</td>
                        <td>{item.regno}</td>
                        <td>{item.birth}</td>
                        <td>{item.stat}</td>
                        <td>{moment(item.cddate).format('YYYY-MM-DD')}</td>
                        <td>{moment(item.lisdate).format('YYYY-MM-DD')}</td>                        
                        <td>{item.semen}</td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </li>
            <li>
              <span>유도대상우</span>
              <table className='pdfTable'>
                <thead>
                  <tr>
                    <th>관리번호</th>
                    <th>유도사양시작일</th>
                    <th>산차</th>
                    <th>등록번호</th>
                    <th>생년월일</th>
                    <th>상태</th>
                    <th>분만/건유일</th>
                    <th>최종수정일</th>
                    <th>최종정액코드</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    mainData &&
                    mainData.plot_data4.map((item) => (
                      <tr>
                        <td>{item.sname}</td>
                        <td>{moment(item.prdate).format('YYYY-MM-DD')}</td>
                        <td>{item.pari}</td>
                        <td>{item.regno}</td>
                        <td>{item.birth}</td>
                        <td>{item.stat}</td>
                        <td>{moment(item.cddate).format('YYYY-MM-DD')}</td>
                        <td>{moment(item.lisdate).format('YYYY-MM-DD')}</td>       
                        <td>{item.semen}</td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </li>
            <li>
              <span>재발정 관찰 대상우</span>
              <table className='pdfTable'>
                <thead>
                  <tr>
                    <th>관리번호</th>
                    <th>관찰일 
                      (부터 ~ 까지)
                    </th>
                    <th>산차</th>
                    <th>등록번호</th>
                    <th>생년월일</th>
                    <th>상태</th>
                    <th>분만/건유일</th>
                    <th>최종수정일</th>
                    <th>최종정액코드</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    mainData &&
                    mainData.plot_data5.map((item) => (
                      <tr>
                        <td>{item.sname}</td>
                        <td>{moment(item.fdate).format('YYYY-MM-DD')} ~ {moment(item.todate).format('YYYY-MM-DD')}</td>
                        <td>{item.pari}</td>
                        <td>{item.regno}</td>
                        <td>{item.birth}</td>
                        <td>{item.stat}</td>
                        <td>{moment(item.cddate).format('YYYY-MM-DD')}</td>
                        <td>{moment(item.lisdate).format('YYYY-MM-DD')}</td>       
                        <td>{item.semen}</td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </li>
          </ul>
          <img src={Logo} alt='농협경제지주 젖소개량사업소 로고' className='pdfLogo'/>
        </div>
      </div>
    </div> 
  );
}

export default NewPlan;
