import React from 'react';
import PlannedMatingCow from 'containers/web/performance/plannedMatingCow/PlannedMatingCow';
import { Helmet } from 'react-helmet-async';

function PlannedMatingCowPage() {
  return (
    <>
      <Helmet>
        <title>유전평가 계획교배</title>
      </Helmet>
      <PlannedMatingCow />
    </>
  );
}

export default PlannedMatingCowPage;
