import React from 'react';

import pic636 from 'assets/images/sub/_images/pic631.jpg';
import pic637 from 'assets/images/sub/_images/pic632.jpg';

function Do125() {

  return (
    <div className="techinfoview">
      <h1>이등유</h1>
      <p className="pstyle">68% 알콜 시험에서 응고하는 유즙의 총칭이지만 보통은 소위 저산도 이등유(알콜 불안정유)를 가르킨다. 발생율은 1∼2% 정도이자만 특정 우사에 돌발적으로 여러마리의 발생을 나타내는 일이 있다. </p>
      <div className="leftpart"><h2>목장에서의 처치</h2>
        <ul>
          <li>여름철 고온다습한 환경하에서 우사내 환기와 온도를 낮추도록 힘써야 한다. </li>
          <li>각 질병을 수반한 때는 원인질병을 치료한다. </li>
          <li>사료의 갑작스런 변화 금지, 조사료와 농후사료 균형, 불량사료 급여에 주의 </li>
          <li>약물요법
            <ul>
              <li>구연산나트륨을 1일 150g 씩 3회로 분할하여 1주일간 계속 급여한다. </li>
              <li>10% 구연산나트륨액을 1일 300ml 씩 피하주사로 수일간 계속한다. </li>
              <li>제2인산 나트륨을 1일 40-70g을 1주일간 급여</li>
            </ul>
          </li>
        </ul>
      </div>
      <div className="rightpart">
        <img src={pic636} className="nonborder" alt=""/>
        <img src={pic637} className="nonborder" alt=""/>
      </div>
    </div>
  );
}

export default Do125;