import React from 'react';
import GeneHowto from 'containers/gene/genehowto/GeneHowto';

function GeneHowtoPage() {
  return (
    <GeneHowto />
  );
}

export default GeneHowtoPage;
