import React from 'react';
import Pedigree from 'containers/milk/pedigree/Pedigree';

function PedigreePage() {
  return (
    <Pedigree />
  );
}

export default PedigreePage;
