import React, { useEffect, useState } from 'react';
import { getState } from 'utils/api';
import { openOxPrint } from 'utils/OpenOxPrint';

// scss
import './oxNow.scss';

function OxNow() {
  const [oxData, setOxData] = useState(null);
  const [OxNowData, setOxNowData] = useState(null);
  const [currentTab, setCurrentTab] = useState('미국형');
  const [currentSort, setCurrentSort] = useState('');
  const [countryIndex, setCountryIndex] = useState('USA');

  const baseDir = '/ox/oxinfo';
  const props = {};

  useEffect(() => {
    getState(baseDir, props)
      .then((getdata) => {
        console.log(getdata);
        // 받아온 데이터의 각 country 그룹의 cowList를 코드 기준으로 내림차순 정렬
        const sortedData = getdata.result.map((countryGroup) => ({
          ...countryGroup,
          cowList: [...countryGroup.cowList].sort((a, b) => {
            const getCodeNumber = (cow) => parseInt(cow.코드.split('-')[1], 10);
            return getCodeNumber(b) - getCodeNumber(a);
          }),
        }));
        setOxData(sortedData);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if (oxData) {
      setOxNowData(() => {
        const filterData = oxData.find(
          (result) => result.country === currentTab,
        );

        return filterData;
      });
    }
  }, [oxData, currentTab]);

  const sortCode = (code) => {
    setOxNowData((prevData) => {
      const sortedCowList = [...prevData.cowList];

      const isAscending = currentSort === code;

      sortedCowList.sort((a, b) => {
        const getCodeNumber = (cow) => parseInt(cow[code].split('-')[1], 10);
        const codeNumberA = getCodeNumber(a);
        const codeNumberB = getCodeNumber(b);

        if (isAscending) {
          return codeNumberB - codeNumberA;
        }

        return codeNumberA - codeNumberB;
      });

      setCurrentSort(isAscending ? `-${code}` : code);

      return {
        ...prevData,
        cowList: sortedCowList,
      };
    });
  };

  const sortType = (type) => {
    setOxNowData((prevData) => {
      const sortedCowList = [...prevData.cowList];

      // 현재 정렬된 상태 확인
      const isAscending = currentSort === type;

      sortedCowList.sort((a, b) => {
        if (isAscending) {
          return b[type] - a[type];
        }

        return a[type] - b[type];
      });

      // 현재 정렬된 상태에 따라 토글
      setCurrentSort(isAscending ? `-${type}` : type);

      return {
        ...prevData,
        cowList: sortedCowList,
      };
    });
  };

  // 천단위 구분 포맷팅 함수
  const formatNumber = (value) => {
    if (value === null || value === undefined || Number.isNaN(Number(value)))
      return value;
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  // 지제 값 포맷팅 함수 수정
  const formatFL = (value, country) => {
    if (value === null || value === undefined || Number.isNaN(Number(value)))
      return value;
    // 캐나다형일 경우 소수점 제거
    if (country === '캐나다형') {
      return Math.round(value);
    }
    // 한국형일 경우 + 기호 제거
    if (country === '한국형' && typeof value === 'string') {
      return value.replace(/^\+/, '');
    }
    return value;
  };

  // 국가별로 항목 다르게 하기
  const handleTabClick = (tabNumber) => {
    setCountryIndex(tabNumber);
  };

  return (
    <>
      <p className="thirdTitle">씨수소 정보검색</p>
      <div className="oxNow">
        <ul className="oxNowTab">
          <li>
            <button
              type="button"
              onClick={() => {
                setCurrentTab('미국형');
                setCurrentSort('');
                handleTabClick('USA');
              }}
              className={currentTab === '미국형' ? 'current' : ''}
            >
              미국형
            </button>
          </li>
          <li>
            <button
              type="button"
              onClick={() => {
                setCurrentTab('캐나다형');
                setCurrentSort('');
                handleTabClick('CAN');
              }}
              className={currentTab === '캐나다형' ? 'current' : ''}
            >
              캐나다형
            </button>
          </li>
          <li>
            <button
              type="button"
              onClick={() => {
                setCurrentTab('한국형');
                setCurrentSort('');
                handleTabClick('KOR');
              }}
              className={currentTab === '한국형' ? 'current' : ''}
            >
              한국형
            </button>
          </li>
        </ul>
        <p className="tableName">{currentTab} 씨수소 총괄 능력표</p>
        <div className="tablebox">
          <table>
            <thead>
              <tr>
                <th>
                  <button
                    type="button"
                    onClick={() => {
                      sortCode('코드');
                      setCurrentSort('코드');
                    }}
                    className={currentSort.includes('코드') ? 'current' : ''}
                  >
                    코드
                  </button>
                </th>
                <th>명호</th>
                <th>아비혈통</th>
                <th>
                  <button
                    type="button"
                    onClick={() => {
                      sortType('종합지수');
                      setCurrentSort('종합지수');
                    }}
                    className={
                      currentSort.includes('종합지수') ? 'current' : ''
                    }
                  >
                    종합지수
                  </button>
                </th>
                <th>
                  <button
                    type="button"
                    onClick={() => {
                      sortType('유량');
                      setCurrentSort('유량');
                    }}
                    className={currentSort.includes('유량') ? 'current' : ''}
                  >
                    유량
                  </button>
                </th>
                <th>
                  <button
                    type="button"
                    onClick={() => {
                      sortType('지방');
                      setCurrentSort('지방');
                    }}
                    className={currentSort.includes('지방') ? 'current' : ''}
                  >
                    지방
                  </button>
                </th>
                <th>
                  <button
                    type="button"
                    onClick={() => {
                      sortType('단백질');
                      setCurrentSort('단백질');
                    }}
                    className={currentSort.includes('단백질') ? 'current' : ''}
                  >
                    단백질
                  </button>
                </th>
                <th>
                  <button
                    type="button"
                    onClick={() => {
                      sortType('체형');
                      setCurrentSort('체형');
                    }}
                    className={currentSort.includes('체형') ? 'current' : ''}
                  >
                    체형
                  </button>
                </th>
                <th>
                  <button
                    type="button"
                    onClick={() => {
                      sortType('유방');
                      setCurrentSort('유방');
                    }}
                    className={currentSort.includes('유방') ? 'current' : ''}
                  >
                    유방
                  </button>
                </th>
                <th>
                  <button
                    type="button"
                    onClick={() => {
                      sortType('지제');
                      setCurrentSort('지제');
                    }}
                    className={currentSort.includes('지제') ? 'current' : ''}
                  >
                    지제
                  </button>
                </th>
                <th>
                  <button
                    type="button"
                    onClick={() => {
                      sortType('가격');
                      setCurrentSort('가격');
                    }}
                    className={currentSort.includes('가격') ? 'current' : ''}
                  >
                    가격
                  </button>
                </th>
                <th>중점개량형질</th>
              </tr>
              <tr>
                <th>CODE</th>
                <th>NAME</th>
                <th>SIRE</th>
                <th>
                  {countryIndex === 'USA'
                    ? 'TPI'
                    : countryIndex === 'CAN'
                      ? 'LPI'
                      : 'KTPI'}
                </th>
                <th>
                  {countryIndex === 'USA' ? (
                    <>
                      PTAM
                      <br />
                      (kg)
                    </>
                  ) : (
                    <>
                      EBVM
                      <br />
                      (kg)
                    </>
                  )}
                </th>
                <th>
                  {countryIndex === 'USA' ? (
                    <>
                      PTAF
                      <br />
                      (kg)
                    </>
                  ) : (
                    <>
                      EBVF
                      <br />
                      (kg)
                    </>
                  )}
                </th>
                <th>
                  {countryIndex === 'USA' ? (
                    <>
                      PTAP
                      <br />
                      (kg)
                    </>
                  ) : (
                    <>
                      EBVP
                      <br />
                      (kg)
                    </>
                  )}
                </th>
                <th>{countryIndex === 'CAN' ? 'CONF' : 'PTAT'}</th>
                <th>{countryIndex === 'CAN' ? 'MSYS' : 'UDC'}</th>
                <th>{countryIndex === 'CAN' ? 'FL' : 'FLC'}</th>
                <th>
                  PRICE
                  <br />
                  &#40;천원&#41;
                </th>
                <th>중점개량형질</th>
              </tr>
            </thead>
            <tbody>
              {OxNowData &&
                OxNowData.cowList.map((cow) => (
                  <tr key={cow.코드}>
                    <td>
                      <button
                        type="button"
                        onClick={() => openOxPrint(cow.코드)}
                      >
                        {cow.코드}
                      </button>
                    </td>
                    <td>{cow.명호}</td>
                    <td>{cow.아비혈통}</td>
                    <td>{formatNumber(cow.종합지수)}</td>
                    <td>{formatNumber(cow.유량)}</td>
                    <td>{cow.지방}</td>
                    <td>{cow.단백질}</td>
                    <td>{cow.체형}</td>
                    <td>{cow.유방}</td>
                    <td>{formatFL(cow.지제, currentTab)}</td>
                    <td>{formatNumber(cow.가격)}</td>
                    <td>{cow.중점개량형질}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

export default OxNow;
