import React, { useState, useRef, useEffect } from 'react';
import { getState } from 'utils/api';

// api
import { openCrownix3 } from 'utils/openCrownix';

// 컴포넌트
import SubTitle from 'components/subTitle/SubTitle';
import ErrorMessage from 'components/errorMessage/errorMessage';
import Department from '../common/Department';
import TableCowInfo from './TableCowInfo';
import TableMilkPerformance from './TableMilkPerformance';
import TableReproductivePerformance from './TableReproductivePerformance';

// scss
import '../common/common.scss';
import './findCow.scss';

function FindCow() {
  const findNumberRef = useRef(null);

  const [cowInfo, setCowInfo] = useState(null);

  const [milkPerformance, setMilkPerformance] = useState(null);
  const [reproductivePerformance, setReproductivePerformance] = useState(null);

  // 생애 유생산 성적, 생애 번식성적 탭
  const [tab, setTab] = useState('milkPerformance');

  // 등록제코드 이력 Ref
  const numRef = useRef('addNum');

  // 에러처리
  const [isModalOpen, setIsModalOpen] = useState(false); // 모달 추가
  const [errorMessage, setErrorMessage] = useState('');

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => {
    setIsModalOpen(false);
    setErrorMessage(''); // 모달이 닫힐 때 에러메세지 초기화
  };

  // 정보 보내기
  async function submitHandler(event) {
    event.preventDefault();
    const id = findNumberRef.current.value;

    const baseDir = '/ox/oxlife';
    const props = {
      id,
      type: numRef.current.value,
    };

    // console.log(props);
    // console.log('*** components/pages/login/submitHandler - id : ', id);

    getState(baseDir, props)
      .then((getdata) => {
        // console.log('result', getdata);
        if (getdata !== undefined) {
          setCowInfo(getdata.result1);
          setMilkPerformance(getdata.result2);
          setReproductivePerformance(getdata.result3);
        }
      })
      .catch((error) => {
        setErrorMessage('검색된 등록번호 혹은 이력제코드가 없습니다.');
        setCowInfo(null);
        openModal();
      });

    closeModal();
  }

  return (
    <div className="findCow subCon pullContent">
      <ErrorMessage
        isOpen={isModalOpen}
        content={errorMessage}
        closeModal={closeModal}
      />
      <div className="commonInner">
        <div className="commonInfo">
          <SubTitle />
          <strong>
            등록번호 12자리를 연속으로 입력
            <br />
            -이력제코드는 앞 세자리 &#40;000,001,002&#41;를 제외 입력
          </strong>
        </div>
        <div className="commonForm">
          <div className="findCowForm">
            <select ref={numRef} defaultValue="recordNum">
              <option value="recordNum">이력코드</option>
              <option value="addNum">등록번호</option>
            </select>
            <input
              ref={findNumberRef}
              type="text"
              id="findNumber"
              placeholder="조회버튼 눌러보세요"
              maxLength="12"
            />
          </div>
          <div className="commonFormBtn">
            <button
              type="button"
              onClick={(event) => {
                submitHandler(event);
              }}
            >
              <span>조회</span>
            </button>
            <button
              type="button"
              onClick={() =>
                openCrownix3('New_sang_jcode.mrd', findNumberRef.current.value)
              }
            >
              <span>인쇄하기</span>
            </button>
          </div>
        </div>
      </div>
      {cowInfo && (
        <>
          <TableCowInfo cowInfo={cowInfo} />
          <ul className="findCowTab">
            <li>
              <button
                type="button"
                onClick={() => setTab('milkPerformance')}
                className={tab === 'milkPerformance' ? 'current' : ''}
              >
                생애 유생산 성적
              </button>
            </li>
            <li>
              <button
                type="button"
                onClick={() => setTab('reproductivePerformance')}
                className={tab === 'reproductivePerformance' ? 'current' : ''}
              >
                생애 번식성적
              </button>
            </li>
          </ul>
          {tab === 'milkPerformance' && (
            <TableMilkPerformance milkPerformance={milkPerformance} />
          )}
          {tab === 'reproductivePerformance' && (
            <TableReproductivePerformance
              reproductivePerformance={reproductivePerformance}
            />
          )}
        </>
      )}
      <Department
        phone="031-929-1064,74"
        department="젖소개량사업소 육종검정팀"
      />
    </div>
  );
}

export default FindCow;
