import React, { useState, useEffect } from 'react';

// api
import { postState } from 'utils/api';
import { openCrownix, openCrownix6 } from 'utils/openCrownix';

// 이미지
import PrintPDF from 'assets/svgs/common/print_PDF.svg';
import Logo from 'assets/images/common/header_logo_pc.png';

//  컴포넌트
import ErrorMessage from 'components/errorMessage/errorMessage';
import MonthCode from 'components/dcitest/monthCode/MonthCode';

// scss
import './NewMain.scss';

function NewMain() {
  // 에러처리
  const [isModalOpen, setIsModalOpen] = useState(false); // 모달 추가
  const [errorMessage, setErrorMessage] = useState('');

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => {
    setIsModalOpen(false);
    setErrorMessage(''); // 모달이 닫힐 때 에러메세지 초기화
  };

  // 데이터 불러오기
  // eslint-disable-next-line no-restricted-globals
  const baseDir = `${location.pathname.toLowerCase()}/`;
  const [mainData, setMainData] = useState();

  const [props, setProps] = useState({
    id: `${localStorage.getItem('cong')}${localStorage.getItem('farm')}`,
    passwd: localStorage.getItem('passwd'),
    date: localStorage.getItem('month'),
  });

  useEffect(() => {
    postState(baseDir, props)
      .then((getdata) => {
        console.log(getdata);
        if (getdata !== undefined) {
          // console.log(getdata);
          setMainData(getdata);
        }
      })
      .catch((error) => {
        setErrorMessage(error.message);
        openModal();
      });
  }, [props]);

  // 페이지 네이션
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 26;

  // Calculate the index range for the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems =
    mainData && mainData.plot_data.slice(indexOfFirstItem, indexOfLastItem);
  const maxPage = Math.ceil(
    mainData && mainData.plot_data.length / itemsPerPage,
  );

  return (
    <div className="pdfMainArea">
      <ErrorMessage
        isOpen={isModalOpen}
        content={errorMessage}
        closeModal={closeModal}
      />
      <div className="pdfMainHeader">
        <button
          type="button"
          onClick={() =>
            openCrownix6(
              'New_main.mrd',
              mainData && mainData.farm_property[0].farmid,
            )
          }
        >
          <img src={PrintPDF} alt="PDF로 인쇄하기" />
          인쇄하기
        </button>
        {props && (
          <MonthCode
            props={props}
            setProps={setProps}
            setErrorMessage={setErrorMessage}
            openModal={openModal}
          />
        )}
        <div className="pdfPagenation">
          <p>
            {currentPage} / {maxPage}
          </p>
          <button
            type="button"
            onClick={() => {
              if (currentPage !== 1) setCurrentPage(currentPage - 1);
            }}
          >
            이전
          </button>
          <button
            type="button"
            onClick={() => {
              if (currentPage !== maxPage) setCurrentPage(currentPage + 1);
            }}
          >
            다음
          </button>
        </div>
      </div>
      <div className="pdfArea">
        <div className="newMainPdf">
          <h1>26개월 우군 검정성적 변화</h1>
          <ul className="newMainFarmInfo">
            <li>농가명 : {mainData && mainData.farm_property[0].fname}</li>
            <li>축주명 : {mainData && mainData.farm_property[0].fdname}</li>
            <li>주소 : {mainData && mainData.farm_property[0].fadd}</li>
          </ul>
          <table className="pdfTable">
            <thead>
              <th>검정월</th>
              <th>경산두수</th>
              <th>착유두수</th>
              <th>미경산두수</th>
              <th>분만두수</th>
              <th>초산두수</th>
              <th>유량&#40;Kg&#41;</th>
              <th>유지&#40;%&#41;</th>
              <th>단백&#40;%&#41;</th>
              <th>고형&#40;%&#41;</th>
              <th>체세포&#40;천/Cell&#41;</th>
              <th>305일 평균 유량</th>
              <th>수정두수</th>
              <th>임신두수</th>
              <th>공태일수</th>
              <th>착유일수</th>
              <th>건유일수</th>
              <th>평균산차</th>
              <th>분만간격</th>
            </thead>
            <tbody>
              {currentItems &&
                currentItems.map((info, index) => (
                  <tr key={index}>
                    <td>
                      <button
                        type="button"
                        onClick={() => openCrownix('New_cow_list.mrd')}
                      >
                        {info.tdate}
                      </button>
                    </td>
                    <td>{info.tcow}</td>
                    <td>{info.mcow}</td>
                    <td>{info.ncow}</td>
                    <td>{info.bunno}</td>
                    <td>{info.chono}</td>
                    <td>
                      {info.milk_org && (
                        <button
                          type="button"
                          onClick={() => openCrownix('New_graph_milk.mrd')}
                        >
                          {info.milk_org.toFixed(1)}
                        </button>
                      )}
                    </td>
                    <td>
                      <button
                        type="button"
                        onClick={() => openCrownix('New_graph_fat_01.mrd')}
                      >
                        {info.fatp}
                      </button>
                    </td>
                    <td>
                      <button
                        type="button"
                        onClick={() => openCrownix('New_graph_prot_01.mrd')}
                      >
                        {info.protp}
                      </button>
                    </td>
                    <td>{info.snfp}</td>
                    <td>{info.scc.toLocaleString()}</td>
                    <td>
                      {info.avg305 && Math.floor(info.avg305).toLocaleString()}
                    </td>
                    <td>
                      <button
                        type="button"
                        onClick={() => openCrownix('New_cow_bun.mrd')}
                      >
                        {info.isno}
                      </button>
                    </td>
                    <td>{info.imno}</td>
                    <td>{info.gongno}</td>
                    <td>{info.avgcum}</td>
                    <td>{info.gunno}</td>
                    <td>{info.avgpari && info.avgpari.toFixed()}</td>
                    <td>{info.avgbun && info.avgbun.toFixed()}</td>
                  </tr>
                ))}
            </tbody>
          </table>
          <img
            src={Logo}
            alt="농협경제지주 젖소개량사업소 로고"
            className="pdfLogo"
          />
        </div>
      </div>
    </div>
  );
}

export default NewMain;
