import React from 'react';

import pic745 from 'assets/images/sub/_images/pic745.jpg';
import pic746 from 'assets/images/sub/_images/pic746.jpg';
import pic747 from 'assets/images/sub/_images/pic747.jpg';

function Do158() {

  return (
    <div className="techinfoview">
      <h1>후대정맥 혈전증</h1>
      <div className="leftpart">
      <p className="pstyle">간장에서 심장으로 향하는 혈관(후대정맥)에 혈전(血栓)이 생기고, 이것이 박리 또는 파쇄되어 혈류를 따라 들어가 폐동맥을 막는 질병이다. <br/>※ 혈전: 혈관내에서 혈액이 응고된 덩어리 </p>
        <h2>증상</h2>
        <ul>
          <li>병의 초기에는 기침과 호흡곤란 증세가 수일 또는 수주일 계속되며 열이 오른다.<br/>(이 시점에서 단순한 폐염으로 오해되기 쉽다) </li>
          <li>사망시에는 대개 항문, 입, 코 등에서 출혈이 있고 해부하여 보면 피하에 제라틴 모양의 삼출액을 볼 수 있는 부종이 있다.</li>
          <li>갑자기 증상이 악화되면 격렬한 호흡곤란을 일으키며 코와 입에서 피를 쏟고 폐사한다. </li>
          <li>폐동맥내 혈전형성은 서서히 이루어지지만 혈액검사에서는 만성 화농성 염증시의 특징이 나타난다. </li>
        </ul>
        <h2>목장에서의 처치</h2>
        <ul>
          <li>소를 안정시키는 외에 별다른 방법이 없다. </li>
          <li>위에서 설명한 객혈(喀血) 증상이 나타날때는 이미 늦어 치료법은 없다.</li>
        </ul>
      </div>
      <div className="rightpart">
        <img src={pic745} className="nonborder" alt=""/>
        <img src={pic746} className="nonborder" alt=""/>
        <img src={pic747} className="nonborder" alt=""/>
      </div>
    </div>
  );
}

export default Do158;