import React, { useState } from 'react';
import { Link, useLocation, useParams , useNavigate} from 'react-router-dom';

// 컴포넌트
import Do1 from './qnaHomeItem/Do1';
import Do2 from './qnaHomeItem/Do2';
import Do3 from './qnaHomeItem/Do3';
import Do4 from './qnaHomeItem/Do4';
import Do5 from './qnaHomeItem/Do5';
import Do6 from './qnaHomeItem/Do6';
import Do7 from './qnaHomeItem/Do7';
import Do8 from './qnaHomeItem/Do8';
import Do9 from './qnaHomeItem/Do9';
import Do10 from './qnaHomeItem/Do10';
import Do11 from './qnaHomeItem/Do11';
import Do12 from './qnaHomeItem/Do12';
import Do13 from './qnaHomeItem/Do13';
import Do14 from './qnaHomeItem/Do14';
import Do15 from './qnaHomeItem/Do15';

import Do16 from './qnaHomeItem/Do16';
import Do17 from './qnaHomeItem/Do17';
import Do18 from './qnaHomeItem/Do18';
import Do19 from './qnaHomeItem/Do19';
import Do20 from './qnaHomeItem/Do20';
import Do21 from './qnaHomeItem/Do21';
import Do22 from './qnaHomeItem/Do22';
import Do23 from './qnaHomeItem/Do23';
import Do24 from './qnaHomeItem/Do24';

import Do25 from './qnaHomeItem/Do25';
import Do26 from './qnaHomeItem/Do26';
import Do27 from './qnaHomeItem/Do27';
import Do28 from './qnaHomeItem/Do28';
import Do29 from './qnaHomeItem/Do29';
import Do30 from './qnaHomeItem/Do30';
import Do31 from './qnaHomeItem/Do31';
import Do32 from './qnaHomeItem/Do32';
import Do33 from './qnaHomeItem/Do33';
import Do34 from './qnaHomeItem/Do34';
import Do35 from './qnaHomeItem/Do35';
import Do36 from './qnaHomeItem/Do36';
import Do37 from './qnaHomeItem/Do37';
import Do38 from './qnaHomeItem/Do38';
import Do39 from './qnaHomeItem/Do39';
import Do40 from './qnaHomeItem/Do40';
import Do41 from './qnaHomeItem/Do41';
import Do42 from './qnaHomeItem/Do42';
import Do43 from './qnaHomeItem/Do43';
import Do44 from './qnaHomeItem/Do44';
import Do45 from './qnaHomeItem/Do45';
import Do46 from './qnaHomeItem/Do46';
import Do47 from './qnaHomeItem/Do47';
import Do48 from './qnaHomeItem/Do48';
import Do49 from './qnaHomeItem/Do49';
import Do50 from './qnaHomeItem/Do50';
import Do51 from './qnaHomeItem/Do51';
import Do52 from './qnaHomeItem/Do52';
import Do53 from './qnaHomeItem/Do53';
import Do54 from './qnaHomeItem/Do54';
import Do55 from './qnaHomeItem/Do55';
import Do56 from './qnaHomeItem/Do56';
import Do57 from './qnaHomeItem/Do57';
import Do58 from './qnaHomeItem/Do58';
import Do59 from './qnaHomeItem/Do59';
import Do60 from './qnaHomeItem/Do60';
import Do61 from './qnaHomeItem/Do61';
import Do62 from './qnaHomeItem/Do62';
import Do63 from './qnaHomeItem/Do63';
import Do64 from './qnaHomeItem/Do64';

import Do65 from './qnaHomeItem/Do65';
import Do66 from './qnaHomeItem/Do66';
import Do67 from './qnaHomeItem/Do67';
import Do68 from './qnaHomeItem/Do68';
import Do69 from './qnaHomeItem/Do69';
import Do70 from './qnaHomeItem/Do70';
import Do71 from './qnaHomeItem/Do71';

import Do72 from './qnaHomeItem/Do72';
import Do73 from './qnaHomeItem/Do73';
import Do74 from './qnaHomeItem/Do74';
import Do75 from './qnaHomeItem/Do75';
import Do76 from './qnaHomeItem/Do76';
import Do77 from './qnaHomeItem/Do77';
import Do78 from './qnaHomeItem/Do78';
import Do79 from './qnaHomeItem/Do79';
import Do80 from './qnaHomeItem/Do80';
import Do81 from './qnaHomeItem/Do81';
import Do82 from './qnaHomeItem/Do82';
import Do83 from './qnaHomeItem/Do83';
import Do84 from './qnaHomeItem/Do84';
import Do85 from './qnaHomeItem/Do85';
import Do86 from './qnaHomeItem/Do86';
import Do87 from './qnaHomeItem/Do87';
import Do88 from './qnaHomeItem/Do88';
import Do89 from './qnaHomeItem/Do89';
import Do90 from './qnaHomeItem/Do90';
import Do91 from './qnaHomeItem/Do91';
import Do92 from './qnaHomeItem/Do92';
import Do93 from './qnaHomeItem/Do93';
import Do94 from './qnaHomeItem/Do94';
import Do95 from './qnaHomeItem/Do95';
import Do96 from './qnaHomeItem/Do96';
import Do97 from './qnaHomeItem/Do97';
import Do98 from './qnaHomeItem/Do98';
import Do99 from './qnaHomeItem/Do99';
import Do100 from './qnaHomeItem/Do100';
import Do101 from './qnaHomeItem/Do101';
import Do102 from './qnaHomeItem/Do102';
import Do103 from './qnaHomeItem/Do103';
import Do104 from './qnaHomeItem/Do104';
import Do105 from './qnaHomeItem/Do105';
import Do106 from './qnaHomeItem/Do106';
import Do107 from './qnaHomeItem/Do107';
import Do108 from './qnaHomeItem/Do108';
import Do109 from './qnaHomeItem/Do109';
import Do110 from './qnaHomeItem/Do110';
import Do111 from './qnaHomeItem/Do111';
import Do112 from './qnaHomeItem/Do112';
import Do113 from './qnaHomeItem/Do113';
import Do114 from './qnaHomeItem/Do114';
import Do115 from './qnaHomeItem/Do115';
import Do116 from './qnaHomeItem/Do116';
import Do117 from './qnaHomeItem/Do117';
import Do118 from './qnaHomeItem/Do118';
import Do119 from './qnaHomeItem/Do119';
import Do120 from './qnaHomeItem/Do120';
import Do121 from './qnaHomeItem/Do121';
import Do122 from './qnaHomeItem/Do122';
import Do123 from './qnaHomeItem/Do123';
import Do124 from './qnaHomeItem/Do124';
import Do125 from './qnaHomeItem/Do125';
import Do126 from './qnaHomeItem/Do126';
import Do127 from './qnaHomeItem/Do127';
import Do128 from './qnaHomeItem/Do128';
import Do129 from './qnaHomeItem/Do129';
import Do130 from './qnaHomeItem/Do130';
import Do131 from './qnaHomeItem/Do131';
import Do132 from './qnaHomeItem/Do132';
import Do133 from './qnaHomeItem/Do133';
import Do134 from './qnaHomeItem/Do134';
import Do135 from './qnaHomeItem/Do135';
import Do136 from './qnaHomeItem/Do136';
import Do137 from './qnaHomeItem/Do137';
import Do138 from './qnaHomeItem/Do138';
import Do139 from './qnaHomeItem/Do139';
import Do140 from './qnaHomeItem/Do140';
import Do141 from './qnaHomeItem/Do141';
import Do142 from './qnaHomeItem/Do142';
import Do143 from './qnaHomeItem/Do143';
import Do144 from './qnaHomeItem/Do144';
import Do145 from './qnaHomeItem/Do145';
import Do146 from './qnaHomeItem/Do146';
import Do147 from './qnaHomeItem/Do147';
import Do148 from './qnaHomeItem/Do148';
import Do149 from './qnaHomeItem/Do149';
import Do150 from './qnaHomeItem/Do150';
import Do151 from './qnaHomeItem/Do151';
import Do152 from './qnaHomeItem/Do152';
import Do153 from './qnaHomeItem/Do153';
import Do154 from './qnaHomeItem/Do154';
import Do155 from './qnaHomeItem/Do155';
import Do156 from './qnaHomeItem/Do156';
import Do157 from './qnaHomeItem/Do157';
import Do158 from './qnaHomeItem/Do158';
import Do159 from './qnaHomeItem/Do159';
import Do160 from './qnaHomeItem/Do160';
import Do161 from './qnaHomeItem/Do161';
import Do162 from './qnaHomeItem/Do162';
import Do163 from './qnaHomeItem/Do163';
import Do164 from './qnaHomeItem/Do164';
import Do165 from './qnaHomeItem/Do165';
import Do166 from './qnaHomeItem/Do166';
import Do167 from './qnaHomeItem/Do167';
import Do168 from './qnaHomeItem/Do168';
import Do169 from './qnaHomeItem/Do169';
import Do170 from './qnaHomeItem/Do170';
import Do171 from './qnaHomeItem/Do171';
import Do172 from './qnaHomeItem/Do172';

import Do173 from './qnaHomeItem/Do173';
import Do174 from './qnaHomeItem/Do174';
import Do175 from './qnaHomeItem/Do175';
import Do176 from './qnaHomeItem/Do176';
import Do177 from './qnaHomeItem/Do177';
import Do178 from './qnaHomeItem/Do178';
import Do179 from './qnaHomeItem/Do179';
import Do180 from './qnaHomeItem/Do180';
import Do181 from './qnaHomeItem/Do181';
import Do182 from './qnaHomeItem/Do182';
import Do183 from './qnaHomeItem/Do183';
import Do184 from './qnaHomeItem/Do184';
import Do185 from './qnaHomeItem/Do185';
import Do186 from './qnaHomeItem/Do186';
import Do187 from './qnaHomeItem/Do187';
import Do188 from './qnaHomeItem/Do188';
import Do189 from './qnaHomeItem/Do189';
import Do190 from './qnaHomeItem/Do190';
import Do191 from './qnaHomeItem/Do191';
import Do192 from './qnaHomeItem/Do192';
import Do193 from './qnaHomeItem/Do193';
import Do194 from './qnaHomeItem/Do194';
import Do195 from './qnaHomeItem/Do195';

import Do196 from './qnaHomeItem/Do196';
import Do197 from './qnaHomeItem/Do197';
import Do198 from './qnaHomeItem/Do198';
import Do199 from './qnaHomeItem/Do199';
import Do200 from './qnaHomeItem/Do200';
import Do201 from './qnaHomeItem/Do201';
import Do202 from './qnaHomeItem/Do202';
import Do203 from './qnaHomeItem/Do203';
import Do204 from './qnaHomeItem/Do204';
import Do205 from './qnaHomeItem/Do205';
import Do206 from './qnaHomeItem/Do206';
import Do207 from './qnaHomeItem/Do207';
import Do208 from './qnaHomeItem/Do208';
import Do209 from './qnaHomeItem/Do209';
import Do210 from './qnaHomeItem/Do210';
import Do211 from './qnaHomeItem/Do211';
import Do212 from './qnaHomeItem/Do212';
import Do213 from './qnaHomeItem/Do213';
import Do214 from './qnaHomeItem/Do214';
import Do215 from './qnaHomeItem/Do215';
import Do216 from './qnaHomeItem/Do216';
import Do217 from './qnaHomeItem/Do217';
import Do218 from './qnaHomeItem/Do218';
import Do219 from './qnaHomeItem/Do219';
import Do220 from './qnaHomeItem/Do220';
import Do221 from './qnaHomeItem/Do221';
import Do222 from './qnaHomeItem/Do222';
import Do223 from './qnaHomeItem/Do223';
import Do224 from './qnaHomeItem/Do224';
import Do225 from './qnaHomeItem/Do225';
import Do226 from './qnaHomeItem/Do226';
import Do227 from './qnaHomeItem/Do227';
import Do228 from './qnaHomeItem/Do228';
import Do229 from './qnaHomeItem/Do229';

const data = [
  [
    '젖소 개량사업 현황 및 농가 개량 방향(젖소개량과 번식향상/농협중앙회)',
    '수태율향상방안(젖소개량과 번식향상/농협중앙회)',
    '자연종부의 폐해(젖소개량과 번식향상/농협중앙회)',
    '가축 위생과 질병(젖소개량과 번식향상/농협중앙회)',
    '사양관리(젖소개량과 번식향상/농협중앙회)',
    '개량목표 및 체계(젖소개량과 번식향상/농협중앙회)',
    '선형외모심사(線形外貌審査)(젖소 선형심사 표준 및 활용/서울우유협동조합)',
    '선형심사(線形審査) : 1차형질(젖소 선형심사 표준 및 활용/서울우유협동조합)',
    '홀스타인 빈우 외모심사표준(젖소 선형심사 표준 및 활용/서울우유협동조합)',
    '홀스타인 체형심사표준(젖소 선형심사 표준 및 활용/서울우유협동조합)',
    '골격(일반외모)에 대하여(젖소 선형심사 표준 및 활용/서울우유협동조합)',
    '유방에 대하여(젖소 선형심사 표준 및 활용/서울우유협동조합)',
    '지제에 대하여(젖소 선형심사 표준 및 활용/서울우유협동조합)',
    '체적에 대하여(젖소 선형심사 표준 및 활용/서울우유협동조합)',
    '유용성에 대하여(젖소 선형심사 표준 및 활용/서울우유협동조합)'
  ],
  [
    '인공수정이란 (나도 할 수 있다 인공수정, 농협중앙회)',
    '소의 생식기관 (나도 할 수 있다 인공수정, 농협중앙회)',
    '생식세포의 형태 및 생리 (나도 할 수 있다 인공수정, 농협중앙회)',
    '정액제조 및 검사 (나도 할 수 있다 인공수정, 농협중앙회)',
    '정액의 희석 및 보존 (나도 할 수 있다 인공수정, 농협중앙회)',
    '종빈우의 사양관리 (나도 할 수 있다 인공수정, 농협중앙회)',
    '번식과 호르몬 (나도 할 수 있다 인공수정, 농협중앙회)',
    '번식장애 (나도 할 수 있다 인공수정, 농협중앙회)',
    '임신과 분만 (나도 할 수 있다 인공수정, 농협중앙회)'
  ],
  [
    '건유우의 사양관리 (젖소사양가이드/미국사료곡물협회)',
    '착유우의 사료계산 (젖소사양가이드/미국사료곡물협회)',
    '착유우의 사양표준과 사료성분표 건유우의 사양관리 (젖소사양가이드/미국사료곡물협회)',
    '착유우의 가소화영양소 총량과 정미에너지 (젖소사양가이드/미국사료곡물협회)',
    '젖소의 단백질 분해도 (젖소사양가이드/미국사료곡물협회)',
    '젖소의 요소사양 (젖소사양가이드/미국사료곡물협회)',
    '착유우의 완충제 (젖소사양가이드/미국사료곡물협회)',
    '착유우의 사료급여방법 (젖소사양가이드/미국사료곡물협회)',
    'TMR의 정의 및 장·단점 (젖소사양가이드/미국사료곡물협회)',
    'TMR의 영양적 구비요건-건물섭취량 (젖소사양가이드/미국사료곡물협회)',
    'TMR의 영양적 구비요건-조섬유 및 조사료의 최저수준 (젖소사양가이드/미국사료곡물협회)',
    'TMR의 영양적 구비요건-비구조 탄수화물 (젖소사양가이드/미국사료곡물협회)',
    'TMR의 영양적 구비요건-지방 (젖소사양가이드/미국사료곡물협회)',
    'TMR의 영양적 구비요건-에너지 및 단백질 (젖소사양가이드/미국사료곡물협회)',
    'TMR의 영양적 구비요건-광물질과 비타민 (젖소사양가이드/미국사료곡물협회)',
    'TMR의 제조 (젖소사양가이드/미국사료곡물협회)',
    'TMR 급여시의 유의점과 경제성 (젖소사양가이드/미국사료곡물협회)',
    '착유우의 사료첨가제 (젖소사양가이드/미국사료곡물협회)',
    'bsT의 투여시 사양관리 (젖소사양가이드/미국사료곡물협회)',
    '비유주기별 사양관리 (젖소사양가이드/미국사료곡물협회)',
    '사료급여와 대사성질병 및 번식 (젖소사양가이드/미국사료곡물협회)',
    '고품질 원유생산을 위한 유질관리 (젖소사양가이드/미국사료곡물협회)',
    '유방염 관리 (젖소사양가이드/미국사료곡물협회)',
    '착유 관리 (젖소사양가이드/미국사료곡물협회)',
    '번식관리 (젖소사양가이드/미국사료곡물협회)',
    '더위가 젖소의 생리 및 유생산에 미치는 영향 (젖소사양가이드/미국사료곡물협회)',
    '여름철의 착유우의 사양관리 (젖소사양가이드/미국사료곡물협회)',
    '후보축 육성의 중요성과 선발 (젖소사양가이드/미국사료곡물협회)',
    '분만관리 (젖소사양가이드/미국사료곡물협회)',
    '갓난 송아지 관리[출생~3일령] (젖소사양가이드/미국사료곡물협회)',
    '젖먹이 송아지 사양관리[4일령~이유시까지]-인공포유 (젖소사양가이드/미국사료곡물협회)',
    '젖먹이 송아지 사양관리[4일령~이유시까지]-냉동초유와 발효초유 이용 (젖소사양가이드/미국사료곡물협회)',
    '젖먹이 송아지 사양관리[4일령~이유시까지]-대용유 급여 (젖소사양가이드/미국사료곡물협회)',
    '젖먹이 송아지 사양관리[4일령~이유시까지]-젖떼기와 이유사료급여 (젖소사양가이드/미국사료곡물협회)',
    '젖먹이 송아지 사양관리[4일령~이유시까지]-조사료급여와 기타관리 (젖소사양가이드/미국사료곡물협회)',
    '어린 송아지 사양관리[이유~4개월령] (젖소사양가이드/미국사료곡물협회)',
    '중송아지 사양관리[4개월~12개월령] (젖소사양가이드/미국사료곡물협회)',
    '큰송아지 사양관리[12개월~초산2개월까지] (젖소사양가이드/미국사료곡물협회)',
    '질병예방 관리-질병의 조기발견 (젖소사양가이드/미국사료곡물협회)',
    '질병예방 관리-기타 질병 관리 (젖소사양가이드/미국사료곡물협회)'
  ],
  [
    '저비용 고효율의 사양관리 1000x30x30 전략 (경상대학교 축산학부 이정규 교수)',
    '원유검사-관능검사, 이화학적 검사 (유방염 관리를 통한 고품질 우유 생산 기본전략/농림부 국립수의과학검역원)',
    '원유검사-세균수 검사 (유방염 관리를 통한 고품질 우유 생산 기본전략/농림부 국립수의과학검역원)',
    '원유검사-체세포수 검사 (유방염 관리를 통한 고품질 우유 생산 기본전략/농림부 국립수의과학검역원)',
    '원유검사-항균물질 검사 (유방염 관리를 통한 고품질 우유 생산 기본전략/농림부 국립수의과학검역원)',
    '외국의 원유검사 및 유대지급제도 (유방염 관리를 통한 고품질 우유 생산 기본전략/농림부 국립수의과학검역원)',
    '우리나라의 원유위생등급제도 및 유질 현황 (유방염 관리를 통한 고품질 우유 생산 기본전략/농림부 국립수의과학검역원)'
  ],
  [
    '창상성 제2위 횡격막염(젖소질병 원색도감/한국낙농육우협회)',
    '제4위 전위증(젖소질병 원색도감/한국낙농육우협회)',
    '케토시스(젖소질병 원색도감/한국낙농육우협회)',
    '제4위 궤양(젖소질병 원색도감/한국낙농육우협회)',
    '제4위 식체(젖소질병 원색도감/한국낙농육우협회)',
    '창상성 심낭염(젖소질병 원색도감/한국낙농육우협회)',
    '지방괴사증(젖소질병 원색도감/한국낙농육우협회)',
    '방선균병(젖소질병 원색도감/한국낙농육우협회)',
    '맹장 염전과 확장증(젖소질병 원색도감/한국낙농육우협회)',
    '제1위 과산증(젖소질병 원색도감/한국낙농육우협회)',
    '복막염(젖소질병 원색도감/한국낙농육우협회)',
    '자궁염전(젖소질병 원색도감/한국낙농육우협회)',
    '장중첩(젖소질병 원색도감/한국낙농육우협회)',
    '급성고창증(젖소질병 원색도감/한국낙농육우협회)',
    '만성고창증(젖소질병 원색도감/한국낙농육우협회)',
    '비만우 증후군(젖소질병 원색도감/한국낙농육우협회)',
    '제1위 식체(젖소질병 원색도감/한국낙농육우협회)',
    '태막수종(젖소질병 원색도감/한국낙농육우협회)',
    '구내염(젖소질병 원색도감/한국낙농육우협회)',
    '식도경색(젖소질병 원색도감/한국낙농육우협회)',
    '액티노 바실러스증(젖소질병 원색도감/한국낙농육우협회)',
    '요소중독(젖소질병 원색도감/한국낙농육우협회)',
    '이바라기병(젖소질병 원색도감/한국낙농육우협회)',
    '소의 바이러스성 설사 ·점막병(BVD·MD) (젖소질병 원색도감/한국낙농육우협회)',
    '급성위장염(젖소질병 원색도감/한국낙농육우협회)',
    '만성위장염(젖소질병 원색도감/한국낙농육우협회)',
    '송아지 설사병(젖소질병 원색도감/한국낙농육우협회)',
    '아밀로이드 증(젖소질병 원색도감/한국낙농육우협회)',
    '존씨병(젖소질병 원색도감/한국낙농육우협회)',
    '기생성의 위장염(젖소질병 원색도감/한국낙농육우협회)',
    '콕시듐병(젖소질병 원색도감/한국낙농육우협회)',
    '폐염 (젖소질병 원색도감/한국낙농육우협회)',
    '폐충증(젖소질병 원색도감/한국낙농육우협회)',
    '일사병과 열사병(젖소질병 원색도감/한국낙농육우협회)',
    '소 전염성 비기관염(젖소질병 원색도감/한국낙농육우협회)',
    '소 유행열(젖소질병 원색도감/한국낙농육우협회)',
    '악성 카탈열(젖소질병 원색도감/한국낙농육우협회)',
    '우결핵병(젖소질병 원색도감/한국낙농육우협회)',
    '유열(젖소질병 원색도감/한국낙농육우협회)',
    '산전 산후 기립불능증(젖소질병 원색도감/한국낙농육우협회)',
    '후산정체(젖소질병 원색도감/한국낙농육우협회)',
    '자궁내막염(젖소질병 원색도감/한국낙농육우협회)',
    '산욕열(젖소질병 원색도감/한국낙농육우협회)',
    '난소낭종(젖소질병 원색도감/한국낙농육우협회)',
    '질탈(젖소질병 원색도감/한국낙농육우협회)',
    '자궁탈(젖소질병 원색도감/한국낙농육우협회)',
    '유산과 사산(젖소질병 원색도감/한국낙농육우협회)',
    '브루셀라 병(젖소질병 원색도감/한국낙농육우협회)',
    '자궁의 종양(젖소질병 원색도감/한국낙농육우협회)',
    '유방염(젖소질병 원색도감/한국낙농육우협회)',
    '유방부종(젖소질병 원색도감/한국낙농육우협회)',
    '유방의 피부염(젖소질병 원색도감/한국낙농육우협회)',
    '유방습진(젖소질병 원색도감/한국낙농육우협회)',
    '이등유(젖소질병 원색도감/한국낙농육우협회)',
    '혈유증(젖소질병 원색도감/한국낙농육우협회)',
    '갓난 송아지 가사증(젖소질병 원색도감/한국낙농육우협회)',
    '송아지 배꼽병(젖소질병 원색도감/한국낙농육우협회)',
    '갓난 송아지의 변비증/태변정체(젖소질병 원색도감/한국낙농육우협회)',
    '배꼽 헤르니아(젖소질병 원색도감/한국낙농육우협회)',
    '프리마틴(젖소질병 원색도감/한국낙농육우협회)',
    '아까바네병(젖소질병 원색도감/한국낙농육우협회)',
    '변형 적혈수 증가증(젖소질병 원색도감/한국낙농육우협회)',
    '송아지 백혈병(젖소질병 원색도감/한국낙농육우협회)',
    '요석증(젖소질병 원색도감/한국낙농육우협회)',
    '물중독(젖소질병 원색도감/한국낙농육우협회)',
    '부제병(젖소질병 원색도감/한국낙농육우협회)',
    '제엽염(젖소질병 원색도감/한국낙농육우협회)',
    '리스테리아증(젖소질병 원색도감/한국낙농육우협회)',
    '파상풍(젖소질병 원색도감/한국낙농육우협회)',
    '골연증(젖소질병 원색도감/한국낙농육우협회)',
    '슬류(젖소질병 원색도감/한국낙농육우협회)',
    '비절농양(젖소질병 원색도감/한국낙농육우협회)',
    '골절(젖소질병 원색도감/한국낙농육우협회)',
    '피부진균증(젖소질병 원색도감/한국낙농육우협회)',
    '유두종(젖소질병 원색도감/한국낙농육우협회)',
    '개선충 증(젖소질병 원색도감/한국낙농육우협회)',
    '소의 모낭충증(젖소질병 원색도감/한국낙농육우협회)',
    '광선과민증(젖소질병 원색도감/한국낙농육우협회)',
    '백혈병(젖소질병 원색도감/한국낙농육우협회)',
    '몰리브덴중독(젖소질병 원색도감/한국낙농육우협회)',
    '전염성 각 결막염(젖소질병 원색도감/한국낙농육우협회)',
    '비타민A 결핍증(젖소질병 원색도감/한국낙농육우협회)',
    '눈의 암(젖소질병 원색도감/한국낙농육우협회)',
    '안충증(젖소질병 원색도감/한국낙농육우협회)',
    '괴저성 유방염(젖소질병 원색도감/한국낙농육우협회)',
    '질산염 중독(젖소질병 원색도감/한국낙농육우협회)',
    '후대정맥 혈전증(젖소질병 원색도감/한국낙농육우협회)',
    '탄저병(젖소질병 원색도감/한국낙농육우협회)',
    '기종저(젖소질병 원색도감/한국낙농육우협회)',
    '청산중독(젖소질병 원색도감/한국낙농육우협회)',
    '대형 파이로플라즈마병(젖소질병 원색도감/한국낙농육우협회)',
    '소형파이로플라즈마병(젖소질병 원색도감/한국낙농육우협회)',
    '고사리 중독(젖소질병 원색도감/한국낙농육우협회)',
    '미경산우 유방염(젖소질병 원색도감/한국낙농육우협회)',
    '그라스 테타니:저마그네슘 혈증(젖소질병 원색도감/한국낙농육우협회)',
    '심내막염(젖소질병 원색도감/한국낙농육우협회)',
    '창상성 비장염(젖소질병 원색도감/한국낙농육우협회)',
    '간질증(젖소질병 원색도감/한국낙농육우협회)',
    '췌질증(젖소질병 원색도감/한국낙농육우협회)',
    '황달(젖소질병 원색도감/한국낙농육우협회)',
    '선천성 심장기형(젖소질병 원색도감/한국낙농육우협회)'
  ],
  [
    '초지조성 대상지 환경조사 및 설계서 작성요령(축산물 수입개방에 대비한 조사료 생산/농림부)',
    '초지의 필요성(축산물 수입개방에 대비한 조사료 생산/농림부)',
    '초지조성 및 관리이용 기술대책(축산물 수입개방에 대비한 조사료 생산/농림부)',
    '초지의 잡초발생과 대책(축산물 수입개방에 대비한 조사료 생산/농림부)',
    '초지의 분뇨사용(축산물 수입개방에 대비한 조사료 생산/농림부)',
    '가축의 조사료 이용(축산물 수입개방에 대비한 조사료 생산/농림부)',
    '사료의 급여요령(축산물 수입개방에 대비한 조사료 생산/농림부)',
    '사료작물 재배 및 초지관리의 주요점(축산물 수입개방에 대비한 조사료 생산/농림부)',
    '병충해(주요목초원색도감/농협중앙회)',
    '화본과 목초 - 오차드 그라스(주요목초원색도감/농협중앙회)',
    '화본과 목초 - 톨 훼스큐(주요목초원색도감/농협중앙회)',
    '화본과 목초 - 티모시(주요목초원색도감/농협중앙회)',
    '화본과 목초 - 페레니얼 라이그라스(주요목초원색도감/농협중앙회)',
    '화본과 목초 - 리드 카나리그라스(주요목초원색도감/농협중앙회)',
    '화본과 목초 - 켄터키부루그라스(주요목초원색도감/농협중앙회)',
    '화본과 목초 - 스므스부롬그라스(주요목초원색도감/농협중앙회)',
    '두과 목초 - 알팔파(주요목초원색도감/농협중앙회)',
    '두과 목초 - 화이트 클로버(주요목초원색도감/농협중앙회)',
    '두과 목초 - 레드 클로버(주요목초원색도감/농협중앙회)',
    '두과 목초 - 스위트 클로버(주요목초원색도감/농협중앙회)',
    '두과 목초 - 알사익 클로버(주요목초원색도감/농협중앙회)',
    '두과 목초 - 버드스 트레포일(주요목초원색도감/농협중앙회)',
    '두과 목초 - 크림손 클로버(주요목초원색도감/농협중앙회)'
  ],
  [
    '후리스톨우사란 (낙농헬퍼이론교육교재/농협중앙회)',
    '후리스톨우사 내부의 각 부문별 설치기준 (낙농헬퍼이론교육교재/농협중앙회)',
    '후리스톨 우사의 배치와 동선체계 (낙농헬퍼이론교육교재/농협중앙회)',
    '후리스톨 우사의 건물 (낙농헬퍼이론교육교재/농협중앙회)',
    '환기용 개구부의 규격과 설계 (낙농헬퍼이론교육교재/농협중앙회)',
    '목장시설 (젖소사양가이드/미국사료곡물협회)',
    '후보축을 위한 우사 및 시설 (젖소사양가이드/미국사료곡물협회)',
    '송아지 사육시설 (젖소사양가이드/미국사료곡물협회)',
    '전환기 우사시설 (젖소사양가이드/미국사료곡물협회)',
    '처녀소 우사 (젖소사양가이드/미국사료곡물협회)',
    '착유우를 위한 우사 및 시설-계류식우사 (젖소사양가이드/미국사료곡물협회)',
    '착유우를 위한 우사 및 시설-후리스톨우사 (젖소사양가이드/미국사료곡물협회)',
    '착유실 (젖소사양가이드/미국사료곡물협회)',
    '대기장 (젖소사양가이드/미국사료곡물협회)',
    '기타 착유시설물 (젖소사양가이드/미국사료곡물협회)',
    '착유장의 환경 (젖소사양가이드/미국사료곡물협회)',
    '착유장비 (젖소사양가이드/미국사료곡물협회)',
    '착유장의 건축 (젖소사양가이드/미국사료곡물협회)',
    '특수관리 및 치료시설 (젖소사양가이드/미국사료곡물협회)',
    '축사환경-자연환기시스템 (젖소사양가이드/미국사료곡물협회)',
    '축사환경-기계식환기시스템 (젖소사양가이드/미국사료곡물협회)',
    '분뇨 및 폐수처리 (젖소사양가이드/미국사료곡물협회)',
    '분뇨수거 (젖소사양가이드/미국사료곡물협회)',
    '저장시설로의 분뇨운반 (젖소사양가이드/미국사료곡물협회)',
    '분뇨 저장시설 (젖소사양가이드/미국사료곡물협회)',
    '착유장 세정수의 수거와 저장 (젖소사양가이드/미국사료곡물협회)',
    '운동장 빗물의 처리 (젖소사양가이드/미국사료곡물협회)',
    '사료급여 및 저장시설-사료급여체계의 계획 (젖소사양가이드/미국사료곡물협회)',
    '사료급여 및 저장시설-완전배합사료(TMR)의 급여 (젖소사양가이드/미국사료곡물협회)',
    '사료급여 및 저장시설-사료 제조시설의 설계 (젖소사양가이드/미국사료곡물협회)',
    '부대시설-급수시설 (젖소사양가이드/미국사료곡물협회)',
    '부대시설-전기시설 (젖소사양가이드/미국사료곡물협회)',
    '시설자재-콘크리트 (젖소사양가이드/미국사료곡물협회)',
    '시설자재-울타리시설 (젖소사양가이드/미국사료곡물협회)'
  ]
];

function QnaHome() {

  const [ qnaHomeTab , setQnaHomeTab ] = useState(0);
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <div className="qnaHome">
      <ul className='qnaHomeTab'>
        <li>
          <button 
            type='button' 
            onClick={()=> {
              setQnaHomeTab(0);
              navigate('/web/Data/QnaTechnicalInfo/QnaHome');
            }}
            className={qnaHomeTab === 0 ? 'current' : ''}
          >개량</button>
        </li>
        <li>
          <button 
            type='button' 
            onClick={()=> {
              setQnaHomeTab(1);
              navigate('/web/Data/QnaTechnicalInfo/QnaHome');
            }}
            className={qnaHomeTab === 1 ? 'current' : ''}
          >번식</button>
        </li>
        <li>
          <button 
            type='button' 
            onClick={()=> {
              setQnaHomeTab(2);
              navigate('/web/Data/QnaTechnicalInfo/QnaHome');
            }}
            className={qnaHomeTab === 2 ? 'current' : ''}
          >사양</button>
        </li>
        <li>
        <button 
            type='button' 
            onClick={()=> {
              setQnaHomeTab(3);
              navigate('/web/Data/QnaTechnicalInfo/QnaHome');
            }}
            className={qnaHomeTab === 3 ? 'current' : ''}
          >유방염컨트롤</button>
        </li>
        <li>
          <button 
            type='button' 
            onClick={()=> {
              setQnaHomeTab(4);
              navigate('/web/Data/QnaTechnicalInfo/QnaHome');
            }}
            className={qnaHomeTab === 4 ? 'current' : ''}
          >질병&middot;위생</button>
        </li>
        <li>
          <button 
            type='button' 
            onClick={()=> {
              setQnaHomeTab(5);
              navigate('/web/Data/QnaTechnicalInfo/QnaHome');
            }}
            className={qnaHomeTab === 5 ? 'current' : ''}
          >조사료&middot;사료작물</button>
        </li>
        <li>
          <button 
            type='button' 
            onClick={()=> {
              setQnaHomeTab(6);
              navigate('/web/Data/QnaTechnicalInfo/QnaHome');
            }}
            className={qnaHomeTab === 6 ? 'current' : ''}
          >시설상비</button>
        </li>
      </ul>
      <ul className='qnaHomeList'>
        {
          !location.pathname.includes('detail') && 
          data[qnaHomeTab].map((depth,i) => {
            let page = 0;

            if (qnaHomeTab === 0) {
              page = i+1;
            }

            if (qnaHomeTab !== 0) {
              for (let index = 0; index < qnaHomeTab ; index += 1) {
                page += data[index].length;
              }
            
              page += (i+1);
            }

            return (
              <li key={depth}>
                <Link to={`detail/${page}`}>{depth}</Link>
              </li>
            );
          })
        }
      </ul>
      { params.id === '1' && <Do1 /> }
      { params.id === '2' && <Do2 /> }
      { params.id === '3' && <Do3 /> }
      { params.id === '4' && <Do4 /> }
      { params.id === '5' && <Do5 /> }
      { params.id === '6' && <Do6 /> }
      { params.id === '7' && <Do7 /> }
      { params.id === '8' && <Do8 /> }
      { params.id === '9' && <Do9 /> }
      { params.id === '10' && <Do10 /> }
      { params.id === '11' && <Do11 /> }
      { params.id === '12' && <Do12 /> }
      { params.id === '13' && <Do13 /> }
      { params.id === '14' && <Do14 /> }
      { params.id === '15' && <Do15 /> }

      { params.id === '16' && <Do16 /> }
      { params.id === '17' && <Do17 /> }
      { params.id === '18' && <Do18 /> }
      { params.id === '19' && <Do19 /> }
      { params.id === '20' && <Do20 /> }
      { params.id === '21' && <Do21 /> }
      { params.id === '22' && <Do22 /> }
      { params.id === '23' && <Do23 /> }
      { params.id === '24' && <Do24 /> }

      { params.id === '25' && <Do25 /> }
      { params.id === '26' && <Do26 /> }
      { params.id === '27' && <Do27 /> }
      { params.id === '28' && <Do28 /> }
      { params.id === '29' && <Do29 /> }
      { params.id === '30' && <Do30 /> }
      { params.id === '31' && <Do31 /> }
      { params.id === '32' && <Do32 /> }
      { params.id === '33' && <Do33 /> }
      { params.id === '34' && <Do34 /> }
      { params.id === '35' && <Do35 /> }
      { params.id === '36' && <Do36 /> }
      { params.id === '37' && <Do37 /> }
      { params.id === '38' && <Do38 /> }
      { params.id === '39' && <Do39 /> }
      { params.id === '40' && <Do40 /> }
      { params.id === '41' && <Do41 /> }
      { params.id === '42' && <Do42 /> }
      { params.id === '43' && <Do43 /> }
      { params.id === '44' && <Do44 /> }
      { params.id === '45' && <Do45 /> }
      { params.id === '46' && <Do46 /> }
      { params.id === '47' && <Do47 /> }
      { params.id === '48' && <Do48 /> }
      { params.id === '49' && <Do49 /> }
      { params.id === '50' && <Do50 /> }
      { params.id === '51' && <Do51 /> }
      { params.id === '52' && <Do52 /> }
      { params.id === '53' && <Do53 /> }
      { params.id === '54' && <Do54 /> }
      { params.id === '55' && <Do55 /> }
      { params.id === '56' && <Do56 /> }
      { params.id === '57' && <Do57 /> }
      { params.id === '58' && <Do58 /> }
      { params.id === '59' && <Do59 /> }
      { params.id === '60' && <Do60 /> }
      { params.id === '61' && <Do61 /> }
      { params.id === '62' && <Do62 /> }
      { params.id === '63' && <Do63 /> }
      { params.id === '64' && <Do64 /> }

      { params.id === '65' && <Do65 /> }
      { params.id === '66' && <Do66 /> }
      { params.id === '67' && <Do67 /> }
      { params.id === '68' && <Do68 /> }
      { params.id === '69' && <Do69 /> }
      { params.id === '70' && <Do70 /> }
      { params.id === '71' && <Do71 /> }

      { params.id === '72' && <Do72 /> }
      { params.id === '73' && <Do73 /> }
      { params.id === '74' && <Do74 /> }
      { params.id === '75' && <Do75 /> }
      { params.id === '76' && <Do76 /> }
      { params.id === '77' && <Do77 /> }
      { params.id === '78' && <Do78 /> }
      { params.id === '79' && <Do79 /> }
      { params.id === '80' && <Do80 /> }
      { params.id === '81' && <Do81 /> }
      { params.id === '82' && <Do82 /> }
      { params.id === '83' && <Do83 /> }
      { params.id === '84' && <Do84 /> }
      { params.id === '85' && <Do85 /> }
      { params.id === '86' && <Do86 /> }
      { params.id === '87' && <Do87 /> }
      { params.id === '88' && <Do88 /> }
      { params.id === '89' && <Do89 /> }
      { params.id === '90' && <Do90 /> }
      { params.id === '91' && <Do91 /> }
      { params.id === '92' && <Do92 /> }
      { params.id === '93' && <Do93 /> }
      { params.id === '94' && <Do94 /> }
      { params.id === '95' && <Do95 /> }
      { params.id === '96' && <Do96 /> }
      { params.id === '97' && <Do97 /> }
      { params.id === '98' && <Do98 /> }
      { params.id === '99' && <Do99 /> }
      { params.id === '100' && <Do100 /> }
      { params.id === '101' && <Do101 /> }
      { params.id === '102' && <Do102 /> }
      { params.id === '103' && <Do103 /> }
      { params.id === '104' && <Do104 /> }
      { params.id === '105' && <Do105 /> }
      { params.id === '106' && <Do106 /> }
      { params.id === '107' && <Do107 /> }
      { params.id === '108' && <Do108 /> }
      { params.id === '109' && <Do109 /> }
      { params.id === '110' && <Do110 /> }
      { params.id === '111' && <Do111 /> }
      { params.id === '112' && <Do112 /> }
      { params.id === '113' && <Do113 /> }
      { params.id === '114' && <Do114 /> }
      { params.id === '115' && <Do115 /> }
      { params.id === '116' && <Do116 /> }
      { params.id === '117' && <Do117 /> }
      { params.id === '118' && <Do118 /> }
      { params.id === '119' && <Do119 /> }
      { params.id === '120' && <Do120 /> }
      { params.id === '121' && <Do121 /> }
      { params.id === '122' && <Do122 /> }
      { params.id === '123' && <Do123 /> }
      { params.id === '124' && <Do124 /> }
      { params.id === '125' && <Do125 /> }
      { params.id === '126' && <Do126 /> }
      { params.id === '127' && <Do127 /> }
      { params.id === '128' && <Do128 /> }
      { params.id === '129' && <Do129 /> }
      { params.id === '130' && <Do130 /> }
      { params.id === '131' && <Do131 /> }
      { params.id === '132' && <Do132 /> }
      { params.id === '133' && <Do133 /> }
      { params.id === '134' && <Do134 /> }
      { params.id === '135' && <Do135 /> }
      { params.id === '136' && <Do136 /> }
      { params.id === '137' && <Do137 /> }
      { params.id === '138' && <Do138 /> }
      { params.id === '139' && <Do139 /> }
      { params.id === '140' && <Do140 /> }
      { params.id === '141' && <Do141 /> }
      { params.id === '142' && <Do142 /> }
      { params.id === '143' && <Do143 /> }
      { params.id === '144' && <Do144 /> }
      { params.id === '145' && <Do145 /> }
      { params.id === '146' && <Do146 /> }
      { params.id === '147' && <Do147 /> }
      { params.id === '148' && <Do148 /> }
      { params.id === '149' && <Do149 /> }
      { params.id === '150' && <Do150 /> }
      { params.id === '151' && <Do151 /> }
      { params.id === '152' && <Do152 /> }
      { params.id === '153' && <Do153 /> }
      { params.id === '154' && <Do154 /> }
      { params.id === '155' && <Do155 /> }
      { params.id === '156' && <Do156 /> }
      { params.id === '157' && <Do157 /> }
      { params.id === '158' && <Do158 /> }
      { params.id === '159' && <Do159 /> }
      { params.id === '160' && <Do160 /> }
      { params.id === '161' && <Do161 /> }
      { params.id === '162' && <Do162 /> }
      { params.id === '163' && <Do163 /> }
      { params.id === '164' && <Do164 /> }
      { params.id === '165' && <Do165 /> }
      { params.id === '166' && <Do166 /> }
      { params.id === '167' && <Do167 /> }
      { params.id === '168' && <Do168 /> }
      { params.id === '169' && <Do169 /> }
      { params.id === '170' && <Do170 /> }
      { params.id === '171' && <Do171 /> }
      { params.id === '172' && <Do172 /> }
      
      { params.id === '173' && <Do173 /> }
      { params.id === '174' && <Do174 /> }
      { params.id === '175' && <Do175 /> }
      { params.id === '176' && <Do176 /> }
      { params.id === '177' && <Do177 /> }
      { params.id === '178' && <Do178 /> }
      { params.id === '179' && <Do179 /> }
      { params.id === '180' && <Do180 /> }
      { params.id === '181' && <Do181 /> }
      { params.id === '182' && <Do182 /> }
      { params.id === '183' && <Do183 /> }
      { params.id === '184' && <Do184 /> }
      { params.id === '185' && <Do185 /> }
      { params.id === '186' && <Do186 /> }
      { params.id === '187' && <Do187 /> }
      { params.id === '188' && <Do188 /> }
      { params.id === '189' && <Do189 /> }
      { params.id === '190' && <Do190 /> }
      { params.id === '191' && <Do191 /> }
      { params.id === '192' && <Do192 /> }
      { params.id === '193' && <Do193 /> }
      { params.id === '194' && <Do194 /> }
      { params.id === '195' && <Do195 /> }
      
      { params.id === '196' && <Do196 /> }
      { params.id === '197' && <Do197 /> }
      { params.id === '198' && <Do198 /> }
      { params.id === '199' && <Do199 /> }
      { params.id === '200' && <Do200 /> }
      { params.id === '201' && <Do201 /> }
      { params.id === '202' && <Do202 /> }
      { params.id === '203' && <Do203 /> }
      { params.id === '204' && <Do204 /> }
      { params.id === '205' && <Do205 /> }
      { params.id === '206' && <Do206 /> }
      { params.id === '207' && <Do207 /> }
      { params.id === '208' && <Do208 /> }
      { params.id === '209' && <Do209 /> }
      { params.id === '210' && <Do210 /> }
      { params.id === '211' && <Do211 /> }
      { params.id === '212' && <Do212 /> }
      { params.id === '213' && <Do213 /> }
      { params.id === '214' && <Do214 /> }
      { params.id === '215' && <Do215 /> }
      { params.id === '216' && <Do216 /> }
      { params.id === '217' && <Do217 /> }
      { params.id === '218' && <Do218 /> }
      { params.id === '219' && <Do219 /> }
      { params.id === '220' && <Do220 /> }
      { params.id === '221' && <Do221 /> }
      { params.id === '222' && <Do222 /> }
      { params.id === '223' && <Do223 /> }
      { params.id === '224' && <Do224 /> }
      { params.id === '225' && <Do225 /> }
      { params.id === '226' && <Do226 /> }
      { params.id === '227' && <Do227 /> }
      { params.id === '228' && <Do228 /> }
      { params.id === '229' && <Do229 /> }

    </div>
  );
}

export default QnaHome;
