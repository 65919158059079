import React, { useState, useEffect, useRef } from 'react';
import { getState, postState } from 'utils/api';
import { openOxPrint } from 'utils/OpenOxPrint';
import { useNavigate, useOutletContext } from 'react-router-dom';

// 컴포넌트
import ReactDatePicker from 'react-datepicker';
import ErrorMessage from 'components/errorMessage/errorMessage';
import 'react-datepicker/dist/react-datepicker.css';

// scss
import './orderSemen.scss';

function OrderSemen() {
  const { setSemenData } = useOutletContext();
  const navigate = useNavigate();

  // 주문할 정보가 담긴 state
  const [orderData, setOrderData] = useState(null);
  useEffect(() => {
    console.log(orderData);
  }, [orderData]);

  // 주문량들 formData의 code 이름별로 주문량이 저장됩니다.

  // orderInfo 상태들
  const [Oname, setOname] = useState(''); // 주문자명
  const [Otel, setOtel] = useState(''); // 전화번호
  const [Jaddr, setJaddr] = useState(''); // 주소
  const [Jname, setJName] = useState(''); // 공급받을곳
  const [Jtel, setJtel] = useState(''); // 공급받을곳 전화
  const [StartDate, setStartDate] = useState(new Date()); // 공급희망일
  const [Jmemo, setJmemo] = useState(''); // 메모
  const [Jpw, setJpw] = useState(''); // 비밀번호
  const [Jpw2, setJpw2] = useState(''); // 비밀번호 확인
  const [orderList, setOrderList] = useState({}); // 주문 리스트

  const OnameRef = useRef(null); // 주문자명
  const OtelRef = useRef(null); // 전화번호
  const JaddrRef = useRef(null); // 주소
  const JnameRef = useRef(null); // 공급받을곳
  const JtelRef = useRef(null); // 공급받을곳 전화
  const JpwRef = useRef(null); // 비밀번호

  const [isModalOpen, setIsModalOpen] = useState(false); // 모달 추가
  const [errorMessage, setErrorMessage] = useState('');

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => {
    setIsModalOpen(false);
    setErrorMessage(''); // 모달이 닫힐 때 에러메세지 초기화
  };

  // 데이터 불러오기
  // eslint-disable-next-line no-restricted-globals
  const baseDir = '/ox/oxlist/';
  const [mainData, setMainData] = useState();
  const [props, setProps] = useState({ type: 'alive' });

  useEffect(() => {
    getState(baseDir, props)
      .then((getdata) => {
        if (getdata !== undefined) {
          setMainData(getdata);
        }
      })
      .catch((error) => {
        setErrorMessage(error.message);
        openModal();
      });
  }, [props]);

  const validateOrderData = () => {
    if (Object.values(orderList).length === 0) {
      setErrorMessage('주문량이 입력되지 않았습니다.');
      openModal();
      return false;
    }

    if (!Object.values(orderList).every((count) => count % 10 === 0)) {
      setErrorMessage('주문량은 10스트로 단위로 입력해주세요.');
      openModal();
      return false;
    }

    if (OnameRef.current.value === '') {
      setErrorMessage('주문자명이 입력되지 않았습니다.');
      openModal();
      OnameRef.current.focus();
      return false;
    }
    if (OtelRef.current.value === '') {
      setErrorMessage('전화번호가 입력되지 않았습니다.');
      openModal();
      OtelRef.current.focus();
      return false;
    }
    if (JaddrRef.current.value === '') {
      setErrorMessage('주소가 입력되지 않았습니다.');
      openModal();
      JaddrRef.current.focus();
      return false;
    }
    if (JnameRef.current.value === '') {
      setErrorMessage('공급받을곳이 입력되지 않았습니다.');
      openModal();
      JnameRef.current.focus();
      return false;
    }
    if (JtelRef.current.value === '') {
      setErrorMessage('전화받을곳이 입력되지 않았습니다.');
      openModal();
      JtelRef.current.focus();
      return false;
    }
    if (JpwRef.current.value === '') {
      setErrorMessage('비밀번호가 입력되지 않았습니다.');
      openModal();
      JpwRef.current.focus();
      return false;
    }

    if (Jpw !== Jpw2) {
      setErrorMessage('비밀번호가 일치하지 않습니다.');
      openModal();
      JpwRef.current.focus();
      return false;
    }

    if (Object.keys(orderList).length === 0) {
      setErrorMessage('주문이 하나도 입력되지 않았습니다.');
      openModal();
      return false;
    }

    return true;
  };

  const addOrderData = () => {
    if (!validateOrderData()) {
      return false;
    }

    // orderData 구조를 EditOrderSemen과 동일하게 수정
    const updateOrderList = mainData
      .filter((cow) => orderList[cow.s_code])
      .map((cow) => ({
        s_code: cow.s_code,
        orderVolume: orderList[cow.s_code],
      }));

    const newOrderData = {
      oname: Oname,
      otel: Otel,
      jaddr: Jaddr,
      jname: Jname,
      jtel: Jtel,
      startDate: StartDate.toLocaleDateString(),
      jmemo: Jmemo,
      jpw: Jpw,
      orderList: updateOrderList,
    };

    postState('/ox/order_semen/', newOrderData)
      .then(() => {
        window.alert('주문이 성공적으로 완료되었습니다.');
        navigate('/Web/Ox/BuySemen');
      })
      .catch((error) => {
        setErrorMessage(error.message || '주문 처리 중 오류가 발생했습니다.');
        openModal();
      });

    return true;
  };

  return (
    <div className="orderSemen">
      <ErrorMessage
        isOpen={isModalOpen}
        content={errorMessage}
        closeModal={closeModal}
      />
      <p>
        오른쪽의 공란에 주문량을 적어주세요.
        <span>&#40;10스트로 단위의 주문만 받습니다.&#41;</span>
      </p>
      <form className="tablebox">
        <table>
          <thead>
            <tr>
              <th>코드</th>
              <th>명호</th>
              <th>아비혈통</th>
              <th>종합지수</th>
              <th>유량</th>
              <th>지방</th>
              <th>단백질</th>
              <th>체형</th>
              <th>유방</th>
              <th>&#40;가격&#41;</th>
              <th>주문량</th>
            </tr>
          </thead>
          <tbody>
            {mainData &&
              mainData.map((cow) => (
                <tr key={cow.s_code}>
                  <td>
                    <button
                      type="button"
                      onClick={() => openOxPrint(cow.s_code)}
                    >
                      {cow.s_fullcode}
                    </button>
                  </td>
                  <td>{cow.s_sortname}</td>
                  <td>{cow.s_ssortname}</td>
                  <td>
                    {cow.s_origin} {cow.s_tpi}
                  </td>
                  <td>{cow.s_ptam}</td>
                  <td>{cow.s_ptaf}</td>
                  <td>{cow.s_ptap}</td>
                  <td>{cow.s_ptat}</td>
                  <td>{cow.s_udc}</td>
                  <td>{cow.s_danga.toLocaleString()}</td>
                  <td>
                    <input
                      type="text"
                      placeholder="0"
                      maxLength={4}
                      value={orderList[cow.s_code] || ''}
                      onChange={(e) =>
                        setOrderList((prev) => ({
                          ...prev,
                          [cow.s_code]: e.target.value,
                        }))
                      }
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </form>
      <ul className="orderInfo">
        <li className="orderTwo">
          <ul>
            <li>
              <label htmlFor="oname">
                <span>*</span>주문자명
              </label>
              <input
                type="text"
                id="oname"
                value={Oname}
                ref={OnameRef}
                required
                onChange={(e) => setOname(e.target.value)}
                placeholder="주문자명을 입력해주세요."
                maxLength="10"
              />
            </li>
            <li>
              <label htmlFor="otel">
                <span>*</span>전화번호
              </label>
              <input
                type="text"
                id="otel"
                value={Otel}
                ref={OtelRef}
                required
                onChange={(e) => setOtel(e.target.value)}
                placeholder="전화번호를 입력해주세요."
                maxLength="20"
              />
            </li>
          </ul>
        </li>
        <li>
          <label htmlFor="jaddr">
            <span>*</span>주소
          </label>
          <input
            type="text"
            id="jaddr"
            value={Jaddr}
            ref={JaddrRef}
            required
            onChange={(e) => setJaddr(e.target.value)}
            placeholder="주소를 입력해주세요."
            maxLength="100"
          />
        </li>
        <li className="orderTwo">
          <ul>
            <li>
              <label htmlFor="jname">
                <span>*</span>공급받을곳
              </label>
              <input
                type="text"
                id="jname"
                value={Jname}
                ref={JnameRef}
                required
                onChange={(e) => setJName(e.target.value)}
                placeholder="공급받을곳을 입력해주세요."
                maxLength="20"
              />
            </li>
            <li>
              <label htmlFor="jtel">
                <span>*</span>공급받을곳 전화
              </label>
              <input
                type="text"
                id="jtel"
                value={Jtel}
                ref={JtelRef}
                required
                onChange={(e) => setJtel(e.target.value)}
                placeholder="공급받을곳 전화를 입력해주세요."
                maxLength="20"
              />
            </li>
          </ul>
        </li>
        <li>
          <label htmlFor="datePicker">공급희망일</label>
          <ReactDatePicker
            dateFormat="yyyy/MM/dd"
            selected={StartDate}
            onChange={(date) => setStartDate(date)}
          />
        </li>
        <li>
          <label htmlFor="jmemo">메모</label>
          <textarea
            id="jmemo"
            placeholder="메모를 입력해주세요."
            value={Jmemo}
            onChange={(e) => setJmemo(e.target.value)}
          ></textarea>
        </li>
        <li className="orderTwo">
          <ul>
            <li>
              <label htmlFor="jpw">
                <span>*</span>비밀번호
              </label>
              <input
                type="password"
                id="jpw"
                value={Jpw}
                ref={JpwRef}
                required
                onChange={(e) => setJpw(e.target.value)}
                placeholder="비밀번호를 입력해주세요."
                maxLength="4"
              />
            </li>
            <li>
              <label htmlFor="jpw2">
                <span>*</span>비밀번호확인
              </label>
              <input
                type="password"
                id="jpw2"
                value={Jpw2}
                required
                onChange={(e) => setJpw2(e.target.value)}
                placeholder="비밀번호 확인"
                maxLength="4"
              />
            </li>
          </ul>
        </li>
      </ul>
      <strong>* 비밀번호는 4자리의 숫자로 지정해주세요.</strong>
      <ul className="orderSemenBtn">
        <li>
          <button type="button" onClick={() => addOrderData()}>
            온라인 주문하기
          </button>
        </li>
        <li>
          <button type="button" onClick={() => navigate('/Web/Ox/BuySemen')}>
            목록으로 돌아가기
          </button>
        </li>
      </ul>
    </div>
  );
}

export default OrderSemen;
