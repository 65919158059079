import React from 'react';

import Pic519 from 'assets/images/sub/_images/pic519.jpg';
import Pic520 from 'assets/images/sub/_images/pic520.jpg';
import Pic521 from 'assets/images/sub/_images/pic521.jpg';

function Do92() {

  return (
    <div className="techinfoview">
      <h1>액티노 바실러스증</h1>
      <div className="leftpart">
        <h2>원인</h2>
        <ul>
          <li>액티노 바실러스라는 균이 소의 아랫턱, 이하선(耳下腺)부근의 임파절, 때로는 어깨와 겸부의 체표 임파절등 연한 조직에 침입하여 혹과 같은 융기물을 형성하는 것이 이병의 특징이다. 방선균증(액티노마이코시스)과는 달리 뼈에 침입하는 일은 없다.</li>
        </ul>
        <h2>증상</h2>
        <ul>
          <li>입술과 콧등 등의 안면에 다발하고 단단한 콩알 크기의 결절이 만져지며 아랫턱이나 이하선(耳下腺)부근에서는 더욱 큰 결절을 볼 수 있다. </li>
          <li>이 균에 의해 혀(舌)에 결절이 생기면 소위 목설병(木舌病)이라 불려지는 혀마비증세가 나타난다. </li>
          <li>결절을 절개해보면 사진에서와 같은 여러 가지 모양의 수많은 소결절을 볼 수 있다.</li>
        </ul>
        <h2>목장에서의 처치</h2>
        <ul>
          <li>피부와 점막의 상처를 통해 균이 침입하므로 외상(外傷)의 방지가 가장 중요하다. </li>
          <li>단단하고 억센 줄기의 급여를 삼가고 시설이나 구조물 등에서 외상의 원인이라고 생각되는 것은 제거한다. </li>
          <li>병든 소의 침이나 배농액(排膿液)을 통해서 전파되므로 건강한 소와 격리 수용한다. </li>
          <li>외과적으로 결절을 절제하고 방법과 항생 물질의 병용이 유일한 치료방법이다.</li>
        </ul>
      </div>
      <div className="rightpart">
        <img src={Pic519} className="nonborder" alt=""/>
        <img src={Pic520} className="nonborder" alt=""/>
        <img src={Pic521} className="nonborder" alt=""/>
      </div>
    </div>
  );
}

export default Do92;