import React from 'react';

function Fo37() {

  return (
    <div className="techinfo_view">
				<p className="viewTitle">만곡증</p>
				<dl>
					<dt>발병대상</dt>
					<dd>어린 송아지</dd>
				</dl>
				
				<dl>
					<dt>원인</dt>
					<dd>1) 마그네슘의 결핍</dd>
					<dd>2) 알카로이드를 함유한 루핀의 섭취</dd>
				</dl>
				
				<dl>
					<dt>징후 및 증상</dt>
					<dd>송아지가 태어날때 등이 굽거나 다리가 휘어져 있다.</dd>
				</dl>
				
				<dl>
					<dt>질병분포와 손실규모</dt>
					<dd>미국 북서지방에서 마그네슘 결핍으로 발생한다.</dd>
				</dl>
				
				<dl>
					<dt>치료</dt>
					<dd>치료방법이 없다.</dd>
				</dl>
				
				<dl>
					<dt>대책</dt>
					<dd>필요한 만큼 마그네슘을 공급하고, 방목지에 루핀이 많이 자라고 있는 지역에 임신중에 가축의 방목을 피한다</dd>
				</dl>
				
				<dl>
					<dt>예방</dt>
					<dd>사료에 의한 조절(30ppm 의 탄산 마그네슘 또는 황산 마그네슘을 사용한다)</dd>
				</dl>	
				
				<dl>
					<dt>참고</dt>
					<dd>만곡증은 마그네슘 결핍에 기인하므로 필요한 양을 충분히 공급하여 주는 것이 요구된다. </dd>
				</dl>
			</div>
  );
}

export default Fo37;
