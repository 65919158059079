import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

// api
import { getState, postState } from 'utils/api';

function ExptEngEdit() {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const epId = searchParams.get('ep_id');

  const [expertData, setExpertData] = useState({
    ep_name: '',
    ep_position: '',
    ep_title: '',
    ep_tel1: '',
    ep_tel2: '',
    ep_field: '',
    ep_email: '',
    ep_etc: '',
    ep_career: '',
    ep_board_id: '',
    ep_file: '',
    ep_fname: '',
  });

  const [file, setFile] = useState(null);
  const [deleteFile, setDeleteFile] = useState('');
  const [addFile, setAddFile] = useState(null);

  useEffect(() => {
    const fetchExpertData = async () => {
      if (epId) {
        try {
          const response = await getState('/board/select_expert_e', {
            ep_id: epId,
          });

          console.log(response);

          if (response?.return_code === 'SUCCESS') {
            setExpertData({
              ep_name: response.result.ep_name,
              ep_position: response.result.ep_position,
              ep_title: response.result.ep_title,
              ep_tel1: response.result.ep_tel1,
              ep_tel2: response.result.ep_tel2,
              ep_field: response.result.ep_field,
              ep_email: response.result.ep_email,
              ep_etc: response.result.ep_etc,
              ep_career: response.result.ep_career,
              ep_board_id: response.result.ep_board_id,
              ep_fname: response.result.ep_fname,
            });
          }
        } catch (error) {
          console.error('Error fetching expert data:', error);
        }
      }
    };

    fetchExpertData();
  }, [epId]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Handle numeric validation for ep_board_id
    if (name === 'ep_board_id' && !/^\d*$/.test(value)) {
      alert('숫자만 입력해주세요.');
      return;
    }

    setExpertData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const { files: uploadedFiles } = e.target;

    if (!uploadedFiles || !uploadedFiles[0]) {
      setExpertData((prev) => ({
        ...prev,
        ep_file: null,
        ep_fname: '',
      }));
      return;
    }

    const newFile = uploadedFiles[0];

    setFile(newFile);
    setAddFile(newFile);

    setExpertData((prev) => ({
      ...prev,
      ep_file: newFile,
      ep_fname: newFile.name,
    }));
  };

  const handleFileDelete = () => {
    setExpertData((prev) => ({
      ...prev,
      ep_file: '',
      ep_fname: '',
    }));
    setDeleteFile('ep_fname');
    setAddFile(null);
    setFile(null);
  };

  const handleSubmit = async () => {
    // Validation
    if (!expertData.ep_name) {
      alert('성명을 입력해주세요.');
      return;
    }

    const isEdit = !!epId;
    const confirmMessage = isEdit
      ? '수정 정보를 저장하시겠습니까?'
      : '전문가 정보를 등록하시겠습니까?';

    if (!window.confirm(confirmMessage)) {
      return;
    }

    try {
      const formData = new FormData();
      Object.keys(expertData).forEach((key) => {
        if (key !== 'ep_file') {
          formData.append(key, expertData[key]);
        }
      });

      console.log(epId);

      if (epId) {
        console.log(addFile, deleteFile);
        formData.append('ep_id', epId);
        formData.append('ep_add_file', addFile);
        formData.append('ep_delete_file', deleteFile);
      }

      if (!epId) {
        formData.append('ep_file', file);
      }

      const endpoint = isEdit
        ? '/board/update_expert_e/'
        : '/board/insert_expert_e/';

      Array.from(formData.entries()).forEach(([key, value]) => {
        console.log(key, value);
      });

      const response = await postState(endpoint, formData);

      if (response?.return_code === 'SUCCESS') {
        alert(isEdit ? '수정이 완료되었습니다.' : '등록이 완료되었습니다.');
        navigate('/bo/expt/exptEng/list');
      } else {
        alert(response?.return_message || '처리 중 오류가 발생했습니다.');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('처리 중 오류가 발생했습니다.');
    }
  };

  const handleDelete = async () => {
    if (!window.confirm('삭제하시겠습니까?')) {
      return;
    }

    try {
      const endpoint = '/board/delete_expert_e/';

      const response = await postState(endpoint, { ep_id: epId });

      if (response?.return_code === 'SUCCESS') {
        alert('삭제가 완료되었습니다.');
        navigate('/bo/expt/exptEng/list');
      } else {
        alert(response?.return_message || '삭제 중 오류가 발생했습니다.');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('삭제 중 오류가 발생했습니다.');
    }
  };

  const handleList = () => {
    const message = epId
      ? '수정 정보가 저장되지 않습니다. 목록으로 돌아가시겠습니까?'
      : '전문가 등록이 완료되지 않았습니다. 목록으로 돌아가시겠습니까?';

    if (window.confirm(message)) {
      navigate('/bo/expt/exptEng/list');
    }
  };

  return (
    <div className="bo-content">
      <h2>전문가&#40;영문&#41; {epId ? '정보 수정' : '등록'}</h2>
      <div className="table-container bold-border">
        <table>
          <tbody>
            <tr>
              <th>
                성명
                <span className="required">*</span>
              </th>
              <td colSpan="3">
                <input
                  type="text"
                  name="ep_name"
                  value={expertData.ep_name}
                  onChange={handleChange}
                  maxLength={10}
                />
              </td>
            </tr>
            <tr>
              <th>소속</th>
              <td>
                <input
                  type="text"
                  name="ep_position"
                  value={expertData.ep_position}
                  onChange={handleChange}
                  maxLength={75}
                />
              </td>
              <th>직함</th>
              <td>
                <input
                  type="text"
                  name="ep_title"
                  value={expertData.ep_title}
                  onChange={handleChange}
                  maxLength={75}
                />
              </td>
            </tr>
            <tr>
              <th>연락처1</th>
              <td>
                <input
                  type="text"
                  name="ep_tel1"
                  value={expertData.ep_tel1}
                  onChange={handleChange}
                  maxLength={20}
                  style={{ imeMode: 'disabled' }}
                />
              </td>
              <th>연락처2</th>
              <td>
                <input
                  type="text"
                  name="ep_tel2"
                  value={expertData.ep_tel2}
                  onChange={handleChange}
                  maxLength={20}
                  style={{ imeMode: 'disabled' }}
                />
              </td>
            </tr>
            <tr>
              <th>분야</th>
              <td>
                <input
                  type="text"
                  name="ep_field"
                  value={expertData.ep_field}
                  onChange={handleChange}
                  maxLength={10}
                />
              </td>
              <th>E-MAIL</th>
              <td>
                <input
                  type="text"
                  name="ep_email"
                  value={expertData.ep_email}
                  onChange={handleChange}
                  maxLength={100}
                />
              </td>
            </tr>
            <tr>
              <th>기타</th>
              <td>
                <input
                  type="text"
                  name="ep_etc"
                  value={expertData.ep_etc}
                  onChange={handleChange}
                  maxLength={20}
                />
              </td>
              <th>경력</th>
              <td>
                <input
                  type="text"
                  name="ep_career"
                  value={expertData.ep_career}
                  onChange={handleChange}
                  maxLength={20}
                />
              </td>
            </tr>
            <tr>
              <th>게시판코드</th>
              <td colSpan="3">
                <input
                  type="text"
                  name="ep_board_id"
                  value={expertData.ep_board_id}
                  onChange={handleChange}
                  maxLength={10}
                  style={{ imeMode: 'disabled' }}
                />
              </td>
            </tr>
            <tr>
              <th>사진</th>
              <td colSpan="3">
                <div className="file-input-container">
                  <input
                    type="file"
                    id="ep_file"
                    name="ep_file"
                    onChange={handleFileChange}
                    accept="image/*"
                    style={{ display: 'none' }}
                  />
                  <label
                    htmlFor="ep_file"
                    className="file-input-button"
                    style={{
                      display: 'inline-block',
                      padding: '8px 16px',
                      backgroundColor: '#f0f0f0',
                      border: '1px solid #ddd',
                      borderRadius: '4px',
                      cursor: 'pointer',
                      marginRight: '10px',
                    }}
                  >
                    파일선택
                  </label>
                  <span style={{ marginRight: '10px' }}>
                    {expertData.ep_fname || '선택된 파일이 없습니다.'}
                  </span>
                  {expertData.ep_fname && (
                    <button
                      type="button"
                      onClick={() => handleFileDelete()}
                      style={{
                        display: 'inline-block',
                        padding: '8px 16px',
                        backgroundColor: '#f0f0f0',
                        border: '1px solid #ddd',
                        borderRadius: '4px',
                        cursor: 'pointer',
                      }}
                    >
                      삭제
                    </button>
                  )}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="button-group">
        <button type="button" onClick={handleSubmit} className="btn-l">
          {epId ? '수정' : '등록'}
        </button>
        {epId && (
          <button type="button" onClick={handleDelete} className="btn-l">
            삭제
          </button>
        )}
        <button type="button" onClick={handleList} className="btn-l">
          목록
        </button>
      </div>
    </div>
  );
}

export default ExptEngEdit;
