import React, { useState , useEffect } from 'react';

// api
import { postState } from 'utils/api';
import { openCrownix } from 'utils/openCrownix';

// 이미지
import PrintPDF from 'assets/svgs/common/print_PDF.svg';
import Logo from 'assets/images/common/header_logo_pc.png';

// 컴포넌트
import ErrorMessage from 'components/errorMessage/errorMessage';
import MonthCode from 'components/dcitest/monthCode/MonthCode';

// scss
import './NewBmonthcomp.scss';

function NewBmonthcomp() {

  // 에러처리
  const [isModalOpen, setIsModalOpen] = useState(false); // 모달 추가
  const [errorMessage, setErrorMessage] = useState(''); 

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => {
    setIsModalOpen(false);
    setErrorMessage(''); // 모달이 닫힐 때 에러메세지 초기화
  };
  
  // 데이터 불러오기
  const baseDir='/dcitest/dciexpert/newbmonthcomp/';
  const [ mainData , setMainData ] = useState();

  const [ props , setProps] = useState({
    id:`${localStorage.getItem('cong')}${localStorage.getItem('farm')}`,
    passwd:localStorage.getItem('passwd'),
    date:localStorage.getItem('month')
  });

  
  useEffect(() => {

    postState(baseDir,props)
    .then(getdata => {      
      if (getdata !== undefined) {
        setMainData(getdata);
      }
    })
    .catch(error => {           
      setErrorMessage(error.message);
      openModal();
    });
    
  }, [props]);
  
  // 페이지 네이션
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20;

  // Calculate the index range for the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = mainData && mainData.plot_data.slice(indexOfFirstItem, indexOfLastItem);
  const maxPage = Math.ceil(mainData && mainData.plot_data.length / itemsPerPage);

  return (
    <div className='pdfMainArea'>
      <ErrorMessage isOpen={isModalOpen} content={errorMessage} closeModal={closeModal}/>
      <div className='pdfMainHeader'>
        <button 
          type='button'
          onClick={()=> openCrownix('New_bmonthcomp.mrd')}
        >
          <img src={PrintPDF} alt='PDF로 인쇄하기' />
          인쇄하기
        </button>
        {
          props && 
          <MonthCode 
            props={props}
            setProps={setProps}
            setErrorMessage={setErrorMessage}
            openModal={openModal}
          />
        }
        <div className='pdfPagenation'>
          <p>{currentPage} / {maxPage}</p>
          <button 
            type='button' 
            onClick={() => { 
              if (currentPage !== 1 ) setCurrentPage(currentPage - 1);
            }}
            >이전</button>
          <button 
            type='button' 
            onClick={() => {
              if (currentPage !== maxPage) setCurrentPage(currentPage + 1);
            }}
            >다음</button>
        </div>
      </div>
      <div className='pdfArea'>
        <div className='newBmonthcompPdf'>
          <h1>{props.date.substring(0,4)}년 {props.date.slice(-2)} 월 전월 대비 검정성적</h1>
          <ul className='newBmonthcompInfo'>
            <li>
              농가명 : {mainData && mainData.farm_property[0].fname}
            </li>
            <li>
              축주명 : {mainData && mainData.farm_property[0].fdname}
            </li>
            <li>
              주소 : {mainData && mainData.farm_property[0].fadd}
            </li>
          </ul>
          <table className='pdfTable'>
            <thead>
              <tr>
                <th rowSpan='2'>관리번호</th>
                <th rowSpan='2'>산차</th>
                <th rowSpan='2'>착유일수</th>
                <th rowSpan='2'>임신여부</th>
                <th rowSpan='2'>최고유량</th>
                <th colSpan='2'>유량</th>
                <th colSpan='2'>유지</th>
                <th colSpan='2'>단백</th>
                <th colSpan='2'>고형</th>
                <th colSpan='2'>체세포</th>
                <th rowSpan='2'>305일유량</th>
                <th rowSpan='2'>305일유지량</th>
              </tr>
              <tr>
                <th>Kg</th>
                <th>차이</th>
                <th>%</th>
                <th>차이</th>
                <th>%</th>
                <th>차이</th>
                <th>%</th>
                <th>차이</th>
                <th>천/Cell</th>
                <th>차이</th>
              </tr>
            </thead>
            <tbody>
              {
                currentItems &&
                currentItems.map((item) => (
                  <tr>
                    <td>{item.sname}</td>
                    <td>{item.pari}</td>
                    <td>{item.cumday}</td>
                    <td>{item.imgu}</td>
                    <td className='numberData'>{item.maxmilk}</td>
                    <td className='numberData'>{item.milk}</td>
                    <td className='numberData'>{item.imilk}</td>
                    <td className='numberData'>{item.fatp}</td>
                    <td className='numberData'>{item.ifatp}</td>
                    <td className='numberData'>{item.protp}</td>
                    <td className='numberData'>{item.iprotp}</td>
                    <td className='numberData'>{item.snfp}</td>
                    <td className='numberData'>{item.isnfp}</td>
                    <td className='numberData'>{item.scc}</td>
                    <td className='numberData'>{item.iscc}</td>
                    <td className='numberData'>{item.milk305.toLocaleString()}</td>
                    <td className='numberData'>{item.fat305}</td>
                  </tr>
                ))
              }
              {
                mainData &&
                mainData.plot_data2.map((item) => (
              <tr>
                    <td colSpan='4'>전체평균</td>                    
                    <td></td>
                    <td>{item.data1.toFixed(1)}</td>
                    <td></td>
                    <td>{item.data2.toFixed(1)}</td>
                    <td></td>
                    <td>{item.data3.toFixed(1)}</td>
                    <td></td>
                    <td>{item.data4.toFixed(1)}</td>
                    <td></td>
                    <td>{item.data5.toFixed(1)}</td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                ))
            }
            </tbody>
          </table>
          <img src={Logo} alt='농협경제지주 젖소개량사업소 로고' className='pdfLogo'/>
        </div>
      </div>
    </div>  
  );
}

export default NewBmonthcomp;
