import React, { useState, useEffect } from 'react';
import { getState } from 'utils/api';
import { openOxPrint } from 'utils/OpenOxPrint';

// scss
import './oxPast.scss';

function OxPast() {
  const [pastData, setPastData] = useState(null);
  const [pastNowData, setPastNowData] = useState(null);
  const [currentTab, setCurrentTab] = useState('미국형');
  const [compositeIndex, setCompositeIndex] = useState('TPI');

  const baseDir = '/ox/oxinfop';
  const props = {};

  useEffect(() => {
    getState(baseDir, props)
      .then((getdata) => {
        // 받아온 데이터의 각 country 그룹의 cowList를 코드 기준으로 내림차순 정렬
        const sortedData = getdata.result.map((countryGroup) => ({
          ...countryGroup,
          cowList: [...countryGroup.cowList].sort((a, b) => {
            const getCodeNumber = (cow) => parseInt(cow.코드.split('-')[1], 10);
            return getCodeNumber(b) - getCodeNumber(a);
          }),
        }));
        setPastData(sortedData);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if (pastData) {
      setPastNowData(() => {
        const filterData = pastData.find(
          (result) => result.country === currentTab,
        );

        return filterData;
      });
    }
  }, [pastData, currentTab]);

  // 천단위 구분 포맷팅 함수
  const formatNumber = (value) => {
    if (value === null || value === undefined || Number.isNaN(Number(value)))
      return value;
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  // 종합지수 국가별 코드 다르게 하기
  const handleTabClick = (tabNumber) => {
    setCompositeIndex(tabNumber);
  };

  return (
    <>
      <p className="thirdTitle">지난 씨수소 정보검색</p>
      <div className="oxPast">
        <ul className="oxPastTab">
          <li>
            <button
              type="button"
              onClick={() => {
                setCurrentTab('미국형');
                handleTabClick('TPI');
              }}
              className={currentTab === '미국형' ? 'current' : ''}
            >
              미국형
            </button>
          </li>
          <li>
            <button
              type="button"
              onClick={() => {
                setCurrentTab('캐나다형');
                handleTabClick('LPI');
              }}
              className={currentTab === '캐나다형' ? 'current' : ''}
            >
              캐나다형
            </button>
          </li>
          <li>
            <button
              type="button"
              onClick={() => {
                setCurrentTab('한국형');
                handleTabClick('KTPI');
              }}
              className={currentTab === '한국형' ? 'current' : ''}
            >
              한국형
            </button>
          </li>
        </ul>
        <p className="tableName">{currentTab} 씨수소 총괄 능력표</p>
        <div className="tablebox">
          <table>
            <thead>
              <tr>
                <th>코드</th>
                <th>명호</th>
                <th>아비혈통</th>
                <th>종합지수</th>
              </tr>
              <tr>
                <th>code</th>
                <th>name</th>
                <th>sire</th>
                <th>
                  {compositeIndex === 'TPI'
                    ? 'TPI'
                    : compositeIndex === 'LPI'
                      ? 'LPI'
                      : 'KTPI'}
                </th>
              </tr>
            </thead>
            <tbody>
              {pastNowData &&
                pastNowData.cowList.map((cow) => (
                  <tr key={cow.코드}>
                    <td>
                      <button
                        type="button"
                        onClick={() => openOxPrint(cow.코드)}
                      >
                        {cow.코드}
                      </button>
                    </td>
                    <td>
                      {cow.명호}
                      {cow.미확정 && <span>{cow.미확정}</span>}
                    </td>
                    <td>{cow.아비혈통}</td>
                    <td>{formatNumber(cow.종합지수)}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

export default OxPast;
