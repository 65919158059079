import React from 'react';

function Do118() {

  return (
    <div className="techinfoview">
      <h1>유산과 사산</h1>
      <p className="pstyle">분만예정일 보다 먼저 태아가 배출되는 것을 유산, 분만기가 되어 태아가 죽어서 만출되는 것을 사산(死産)이라고 한다.</p>
      <div className="leftpart">
        <h2>원인</h2>
        <ul>
          <li>감염성 원인
            <ul>
              <li>세균이나 바이러스의 감염으로 일어나는 것으로 특히 전파력이 강한 경우 전염성 유산이라 한다. </li>
              <li>아카바네 바이러스에 의한 이상산(異常産:유산, 조산, 기형송아지)과 소 전염성 비기관염(IBR)에 의한 유산</li>
              <li>포도구균, 연쇄구균, 대장균등의 상재균에 의한 유산</li>
              <li>곰팡이류에 의한 사상균성 유산</li>
            </ul>
          </li>
          <li>비감염성 유산
            <ul>
              <li>감염에 의한 것보다 훨씬 많이 발생하며 중요하다.</li>
              <li>사양관리상 부주의로 인한 경우(임신우의 복부압박, 넘어짐, 뿔에 받힘등에 의한 태반박리로 유산) </li>
              <li>갑작스런 기후 변동 및 놀라거나 흥분하면 반사적으로 자궁의 수축을 일으켜 유산한다. </li>
              <li>발효하기 쉬운 사료, 곰팡이 핀 사료, 얼어붙은 사료를 다량 섭취 후 고창증이나 설사를 일으킬 때 유산이 일어나기 쉽다. </li>
              <li> 유독물질(예:중독)에 의한 유산 </li>
              <li>임신할 때마다 거의 일정한 월령에 이르면 유산하는 습관성 유산의 원인은 모체의 임신황체에서의 황체 호르몬 분비 부족이 의심된다. </li>
            </ul>
          </li>
        </ul>
        <h2>증상</h2>
        <ul>
          <li> 임신 전반기의 유산은 거의 아무런 징후도 없이 갑자기 일어난다. </li>
          <li>임신 후반기에는 유산이 일어나기 수일전부터 음부에서 오로나 유방이 팽창하는 등의 징후가 나타나는 일이 있다. </li>
        </ul>
        <h2>목장에서의 처치</h2>
        <ul>
          <li>유산의 징후가 나타나면 이미 때가 늦으므로 유산을 저지할 방법이 없다. </li>
          <li>전염성 유산이 의심 될 때는 모우의 핼액 및 태아를 무균적으로 채취, 냉장 보관하였다가 수의사와 상의하여 인근 가축 위생시험소나 안양 가축위생 연구소로 검사 의뢰한다. </li>
          <li>유산이나 사산을 일으킨 어미소의 몸에 이상이 있을때는 항생물질 주사와 그외의 필요한 치료를 행한다. </li>
          <li>습관성 유산에서는 유산 위험시기의 약 1개월전부터 지속성 황체 호르몬제를 1∼2주간 마다 수회 주사함으로써 유산을 예방할 수 있다.</li>
        </ul>
      </div>
      <div className="rightpart">
      </div>
    </div>
  );
}

export default Do118;