import React from 'react';


function Do20() {

  return (
    <div className="techinfoview">
				<h1>정액의 희석 및 보존</h1>
				<h2>정액의 희석</h2>
				<p style={{lineHeight: '200%'}}>&nbsp;&nbsp;&nbsp;정자는 자신이 이용할 수 있는 대사기질을 전부 이용해 버리면 결국 사멸하게 된다. 따라서 희석의 목적은 원정액이 갖고 있는 불리한 조건을 제거하여 정자의 생존에 유리한 조건을 부여하며 정액량을 증가시켜 다두 수정을 가능하게 하고, 보존기간 동안에 정자의 활력 및 생존률에 최적의 조건으로 수정능력을 연장하는데 있다. 즉 정자를 장기간 보전하고 싶을 때에는 외부로부터 대사기질을 첨가함과 동시에 정자의 운동을 최소한으로 억제하여 에너지의 소비를 줄일 수 밖에 없다. 이러한 이유 때문에 정액을 보존할 때에는 적당한 희석제로 희석하여 저온에 보존하고 있는 것이다.</p><br/>
				<ul>
					<li>희석액의 구비 조건
						<ul>
							<li>정자의 생존에 유리한 작용을 하여야 함</li>
							<li>외부충격에 대한 완충효과가 있어야 함</li>
							<li>삼투압 및 pH가 정액과 같게 유지되어야 함</li>
							<li>세균증식을 억제하고 영양물질을 공급하는 에너지원이 함유되어야 함</li>
						</ul>
					</li>
				</ul><br/>
				<p style={{lineHeight: '200%'}}>&nbsp;&nbsp;&nbsp;인공질 및 기타의 방법에 의하여 체위된 정액 중에는 비록 건강한 수소로부터 채취한 정액이라도 상당수의 세균이 혼입되어 있다. 흔히 관찰되는 세균으로서는 싸구균, 연쇄상구균, 포도상구균 및 대장균, 고초균 등으로서 세균의 혼입에 의하여 야기되는 피해를 방지하기 위해서는 정액의 채취로부터 주입에 이르는 일련의 과정을 위생적으로 처리해야 함은 물론 희석액 중에는 항균성물질을 첨가해야 한다.</p><br/>
				<p style={{lineHeight: '200%'}}>&nbsp;&nbsp;&nbsp;현재 세계적으로 사용되고 있는 항생물질은 린코스펙틴, 타이로신, 겐타마이신이며 이는 세균의 발육을 억제할 뿐만 아니라 보존중에 있는 정자의 대사를 억제하여 그 수명을 연장시킨다. 또 항생물질은 위에서 열거한 일반적인 세균외에도 비브리오균이나 부르셀라균과 같이 유산이나 불임의 원인이 되고 있는 모든 세균에 대해서도 유효하게 작용한다. 그리하여 결과적으로 수태율을 향상시키는 효과를 발휘하게 된다.</p><br/>
				<ul>
					<li>정액희석온도 : 35℃</li>
					<li>5℃까지 냉각시간 : 4 -5 시간
						<table className="tablestyle7">
							<tr>
								<th>① 정액채취</th>
								<th>→</th>
								<th>② 정액검사 및 희석</th>
								<th>→</th>
								<th>③ 냉각</th>
								<th>→</th>
								<th>④ 2차 희석 </th>
								<th>→</th>
							</tr>
							<tr>
								<td>
									<p>(1회 채취시 평균)</p>
									<ul>										
										<li>정자수 : 6-15억/cc</li>
										<li>정액량 : 5cc</li>
									</ul>
								</td>
								<td></td>
								<td>
									<ul>
										<li>정자수 계산</li>
										<li>정자활력 및 생존율</li>
										<li>일반성상 검사</li>
										<li>10ml에 기초화석</li>
									</ul>
								</td>
								<td></td>
								<td>
									<ul>
										<li>10ml희석액 + 정액</li>
										<li>희석정액 5℃로 냉각(4시간 소요)</li>
									</ul>
								</td>
								<td></td>
								<td>
									<ul>
										<li>5℃에서 기초 희석후 1차와 동량의 2차액</li>
										<li>20분 간격으로 4회에 나누어 분주(첨가)</li>
									</ul>
								</td>
								<td></td>
							</tr>
							<tr>
								<th>⑤ 3차 희석</th>
								<th>→</th>
								<th>⑥ 글리세린 평형</th>
								<th>→</th>
								<th>⑦ 예비 동결융해 검사</th>
								<th>→</th>
								<th>⑧ 정액제조(봉함)</th>
								<th>→</th>
							</tr>
							<tr>
								<td>
									<ul>
										<li>0.5ml에 A( )급 생존</li>
										<li>정자 2,000 ∼ 2,500만 조정 희석(융해후 생존정자)</li>
									</ul>
								</td>
								<td></td>
								<td>
									<ul>
										<li>5℃에서 1시간 이상 글리 세린 평형실시</li>
									</ul>
								</td>
								<td></td>
								<td>
									<ul>
										<li>-140℃까지 12-14분에 걸쳐 예비동결후 액체 질소내에 10분간 침척(컴퓨터에 의한 자동연결)</li>
									</ul>
								</td>
								<td></td>
								<td>
									<ul>
										<li>종축별 개체별 분류</li>
										<li>스트로당 2,000 - 2,500만 충전 자동제조</li>
										<li>0.5ml 스토로에 자동분주</li>
									</ul>
								</td>
								<td></td>
							</tr>
							<tr>
								<th>⑨ 동결 정액포장 및 검사</th>
								<th>→</th>
								<th>⑩ 검 사</th>
								<th>→</th>
								<th>⑪</th>
								<th> </th>
								<th> </th>
								<th> </th>
							</tr>
							<tr>
								<td>
									<ul>
										<li>⑦번과 동일 방법으로 정액동결</li>
										<li>자동 포장기에 의한 포장</li>
										<li>최종 품질검사 후 인계</li>
									</ul>
								</td>
								<td></td>
								<td>
									<ul>
										<li>35℃에서 20초간 융해</li>
										<li>검사판정 : A(+++)급 생존정자 45%이상</li> 
									</ul>
								</td>
								<td></td>
								<td>
									<ul>
										<li>정액 공급</li> 
									</ul>
								</td>
								<td></td>
								<td></td>
								<td></td>
							</tr>
						</table>
					</li>
					<li>희석 및 동결처리방법 
						<ul>
							<li>정액 + 기초희석액 (10ml) </li>
							<li>5℃로 냉각후 1차액을 가하여 40ml이 되도록 한다. </li>
							<li> 5℃에서 2차액을 40ml를 20분간 간격으로 4회를 나누어 10ml씩 분주 희석한다. </li>
							<li>3차 희석 실시 
								<p>이때 예비동결하여 활력 및 생존율이 A(+++)급 45%이상의 정자 2,500만 (실제 융해후 생존 정자)이 되도록 희석(3차액 첨가)한다. </p>
								<ul>
									<li>1차액 : 글리세린 0% </li>
									<li>2차액 : 글리세린 15%미만 </li>
									<li>3차액 : 글리세린 5 - 8%</li>
								</ul>
							</li>
							<li>글리세린 평형 : 3 -5시간 실시 </li>
							<li>동결 : -79℃에서 10분간 예비동결 후 LN2(-196℃)에 본 동결후 보존 (개체별로 품질 검사)</li>
						</ul>
					</li>
				</ul>
				<h2>정액 보관</h2>
				<ul>
					<li>액상 보존 
						<ul>
							<li>37℃ 전후의 체온에서는 에너지의 소모가 빨라 그만큼 정자의 수명도 단축되므로 초기에는 희석한 정액을 4-5℃ 전후의 저온에서 보존하는 액상 보존법이 채용되었다. 돼지는 10-15℃ 상온에서 보존하여 이용하고 있다.</li>
						</ul>
					</li>
					<li>동결 보존 
						<ul>
							<li>정액을 항 동해제인 글리세롤 등을 함유한 희석액으로 희석한 다음 스트로에 분주하여 예비 동결을 거쳐 -196℃의 액체 질소에 넣어 동결 보존한다. 소의 경우 앰플로된 액상 보존에서 지금은 스트로로 동결 보존하여 사용하고 있다.</li>
						</ul>
					</li>
				</ul>
				<h2>냉동정액의 관리</h2>
				<ul>
					<li>액체질소
						<ul>
							<li>액체질소는 콘테이너 깊이의 1/3수준까지 충전되어 있으면 정액에는 별 지장을 초래하지 않는다. 그러나 간혹 바쁘게 수정을 하다보면 위험수의 아래로 떨어지는 경우와 액체질소가 없는 경우가 생기면 전자는 정액품질에 떨어져 수태율이 낮아지고, 후자는 정액을 사용하지 못한다. 액체질소 관리카드를 비치하여 주기적으로 점검을 해야 한다.</li>						
						</ul>
						<p>[액체질소 관리카드]</p>
						<table className="tablestyle">
							<tr>
								<td>확인회수</td>
								<td>안전<br/>깊이수준</td>
								<td>충진1</td>
								<td>충진2</td>
								<td>충진3</td>
								<td>충진4</td>
							</tr>
							<tr>
								<td rowSpan="2">질소<br/>충진일</td>
								<td rowSpan="2">30cm</td>
								<td>월일 97. 1. 3</td>
								<td>97. 4. 25</td>
								<td>97. 8. 15</td>
								<td>97. 12. 5</td>
							</tr>
							<tr>
								<td>깊이 31cm</td>
								<td>30cm</td>
								<td>32.5cm</td>
								<td>30cm</td>
							</tr>
							<tr>
								<td rowSpan="2">질소<br/>확인1</td>
								<td rowSpan="2">23cm</td>
								<td>97. 1 31</td>
								<td>97. 5. 22</td>
								<td>97. 9. 12</td>
								<td>98. 1. 2</td>
							</tr>
							<tr>
								<td>24cm</td>
								<td>23.5m</td>
								<td>23cm</td>
								<td>24.5cm</td>
							</tr>
							<tr>
								<td rowSpan="2">질소<br/>확인2</td>
								<td rowSpan="2">18cm</td>
								<td>97. 2. 28</td>
								<td>97. 6. 20</td>
								<td>97. 10. 10</td>
								<td>98. 1. 30</td>
							</tr>
							<tr>
								<td>19cm</td>
								<td>18cm</td>
								<td>17cm</td>
								<td>19cm</td>
							</tr>
							<tr>
								<td rowSpan="2">질소<br/>확인3</td>
								<td rowSpan="2">1013cm</td>
								<td>97. 3. 28</td>
								<td>97. 7. 18</td>
								<td>97. 11. 7</td>
								<td>98. 2. 27</td>
							</tr>
							<tr>
								<td>15cm</td>
								<td>13cm</td>
								<td>12.5cm</td>
								<td>14cm</td>
							</tr>
							<tr>
								<td rowSpan="2">충진일</td>
								<td rowSpan="2">58cm</td>
								<td>97. 4. 25</td>
								<td>97. 8. 15</td>
								<td>97. 12. 5</td>
								<td></td>
							</tr>
							<tr>
								<td>10cm</td>
								<td>7cm</td>
								<td>7cm</td>
								<td></td>
							</tr>
						</table><br/>
					</li>
					<li>정액 관리
						<ul>
							<li>양축가로부터 수정의뢰가 왔을 때 수정소에서 융해해가거나 소형콘테이너로 운반하여 현지에서 발정상태 관찰 후 융해하여 수정시키는 경우가 있는데 현장으로 뛰어다니다 보면 정액 관리가 소홀해질 경우가 있지만 그때 그때 정액 관리 카드에 체크만 하면 품종별 개체별 수량이 파악되는 콘테이너안의 정액이 쉽게 파악된다.</li>							
						</ul>
						<p>[정액관리 명세표]</p>
						<table className="tablestyle8">
							<tr>
								<td>캐니스타 6번 (젖소) </td>
								<td>캐니스타 2번 (한우) </td>
							</tr>
							<tr>
								<td>1. H-159 Φ Φ Φ Φ Φ Φ Φ Ο Ο Ο</td>
								<td>1. KPN-159 Φ Φ Φ Φ Φ Φ Ο Ο Ο Ο</td>
							</tr>
							<tr>
								<td>2. H-170 Φ Φ Ο Ο Ο Ο Ο Ο Ο Ο</td>
								<td>2. KPN-170 Φ Φ Ο Ο Ο Ο Ο Ο Ο Ο</td>
							</tr>
							<tr>
								<td>3. H-171 Φ Φ Φ Ο Ο Ο Ο Ο Ο Ο</td>
								<td>3. KPN-171 Φ Φ Φ Ο Ο Ο Ο Ο Ο Ο</td>
							</tr>
							<tr>
								<td>4. H-186 Φ Φ Φ Φ Ο Ο Ο Ο Ο Ο</td>
								<td>4. KPN-186 Φ Φ Φ Φ Ο Ο Ο Ο Ο Ο</td>
							</tr>
							<tr>
								<td>5. H-187 Φ Φ Ο Ο Ο Ο Ο Ο Ο Ο</td>
								<td>5. KPN-187 Φ Φ Ο Ο Ο Ο Ο Ο Ο Ο</td>
							</tr>
							<tr>
								<td>6. H-206 Φ Ο Ο Ο Ο Ο Ο Ο Ο Ο </td>
								<td>6. KPN-206 Φ Ο Ο Ο Ο Ο Ο Ο Ο Ο </td>
							</tr>
						</table><br/>
					</li>
					<li>스토로당 정자수와 수태율
						<ul>
							<li>축산기술연구소에서 &apos;86~&apos;87년도에 시험한 성적을 보면 스트로당 총 정자수를 3,000만까지 감소시켜도 한우와 젖소에서 수태율에 큰 차이가 없었으며, 종모우 1두당 67% 정도의 동결정액 생산을 높일 수 있는 기술이 확립되었다.
								<p>[정자수에 따른 수태율]</p>
								<table className="tablestyle">
									<tr>
										<td>융해온도</td>
										<td>활력 및 생존율<br/>(3시간 평균)</td>
										<td>정상 Acrosome(%)<br/>(3시간 평균)</td>
									</tr>
									<tr>
										<td>5℃</td>
										<td>30.3%</td>
										<td>31.2%</td>
									</tr>
									<tr>
										<td>24℃(공기중에서)</td>
										<td>21.3%</td>
										<td>26.4%</td>
									</tr>
									<tr>
										<td>35 - 40℃의 온수</td>
										<td>51.4%</td>
										<td>61.0%</td>
									</tr>
									<tr>
										<td>5 - 35℃사이</td>
										<td>41.4%</td>
										<td>44.6%</td>
									</tr>
								</table>
							</li>
						</ul><br/>
					</li>
					<li>동결정액의 융해온도와 수태율
						<ul>
							<li>융해온도 : 35 ∼38℃</li>
							<li>융해시간 : 20 ∼ 25초</li>
							<li>융해 후 사용시간 : 가급적 빨리 사용
								<p>[융해 온도에 따른 정자의 회복율]</p>
								<table className="tablestyle">
									<tr>
										<td>융해온도</td>
										<td>활력 및 생존율<br/>(3시간 평균)</td>
										<td>정상 Acrosome(%)<br/>(3시간 평균)</td>
									</tr>
									<tr>
										<td>5℃</td>
										<td>30.3%</td>
										<td>31.2%</td>
									</tr>
									<tr>
										<td>24℃(공기중에서)</td>
										<td>21.3%</td>
										<td>26.4%</td>
									</tr>
									<tr>
										<td>35 - 40℃의 온수</td>
										<td>51.4%</td>
										<td>61.0%</td>
									</tr>
									<tr>
										<td>5 - 35℃사이</td>
										<td>41.4%</td>
										<td>44.6%</td>
									</tr>
								</table><br/>
								<p>[융해 후 경과시간에 따른 수태율]</p>
								<table className="tablestyle">
									<tr>
										<td>국가</td>
										<td>구분</td>
										<td colSpan="5">융해 후 경과시간</td>
									</tr>
									<tr>
										<td rowSpan="2">일본1</td>
										<td>경과시간</td>
										<td>3시간</td>
										<td>4 - 8</td>
										<td>8 - 12</td>
										<td>12시간이상</td>
										<td rowSpan="2"></td>
									</tr>
									<tr>
										<td>수태율(%)</td>
										<td>77.6</td>
										<td>73.7</td>
										<td>78.5</td>
										<td>64.4</td>
									</tr>
									<tr>
										<td rowSpan="2">일본2</td>
										<td>경과시간</td>
										<td>0.5 - 8</td>
										<td>9 - 16</td>
										<td>17 - 24</td>
										<td>25 - 36</td>
										<td>37 - 48</td>
									</tr>
									<tr>
										<td>수태율(%)</td>
										<td>67.2</td>
										<td>72.0</td>
										<td>60.3</td>
										<td>73.0</td>
										<td>66.2</td>
									</tr>
									<tr>
										<td rowSpan="2">국내</td>
										<td>경과시간</td>
										<td>1 - 6</td>
										<td>7 - 12</td>
										<td>13 - 24</td>
										<td>25 - 36</td>
										<td>37 - 48</td>
									</tr>
									<tr>
										<td>수태율(%)</td>
										<td>84.1</td>
										<td>76.0</td>
										<td>71.4</td>
										<td>70.0</td>
										<td>66.7</td>
									</tr>
								</table><br/>
								<p>[농가과 수정소 융해시 수태율 비교]</p>
								<table className="tablestyle">
									<tr>
										<td colSpan="2">농가 융해시</td>
										<td colSpan="2">수정소 융해시</td>
									</tr>
									<tr style={{background: '#CCDEF0'}}>
										<td>수정두수</td>
										<td>수태율(%)</td>
										<td>수정두수</td>
										<td>수태율(%)</td>
									</tr>
									<tr>
										<td>114두</td>
										<td>55.1</td>
										<td>105두</td>
										<td>52.4</td>
									</tr>
									<tr>
										<td>455</td>
										<td>54.9</td>
										<td>297</td>
										<td>45.8</td>
									</tr>
									<tr>
										<td>504</td>
										<td>60.4</td>
										<td>720</td>
										<td>28.0</td>
									</tr>
									<tr>
										<td>2,143</td>
										<td>63.2</td>
										<td>722</td>
										<td>59.5</td>
									</tr>
									<tr>
										<td>1,649</td>
										<td>64.6</td>
										<td>626</td>
										<td>53.7</td>
									</tr>
									<tr>
										<td>3,319</td>
										<td>62.6</td>
										<td>1,076</td>
										<td>57.8</td>
									</tr>
								</table><br/>
								<ul>
									<li>숙지 사항
										<ul>
											<li>세심한 주의를 기울일 것 (지시 사항을 준수 이행할 것) </li>
											<li>냉동정액의 외기노출을 피할 것 </li>
											<li>38℃온수에서 충격을 피할 것 </li>
											<li>정액의 온도충격을 피할 것 </li>
											<li>금연, 장갑착용, 유류접촉 엄금 </li>
											<li>태양열, 복사열, 화기를 피하고 시원한 곳에서 할 것 </li>
											<li>신속하게 이동할 것</li>
											<li>융해중인 정액은 흔들지 말 것</li>
										</ul>
									</li>
								</ul>
							</li>
						</ul>
					</li>
				</ul>
			</div>
  );
}

export default Do20;
