import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ROUTE_PAGE } from 'constants/constants';

// scss
import './NavMobile.scss';

function NavMobile({ navOpen, setNavOpen }) {
  // 카테고리 설정 ,
  const location = useLocation();
  const route = location.pathname.split('/').filter(Boolean)[1];
  const [category, setCategory] = useState(route);

  // 현재 경로가 변경될때마다 category 현재 경로로 바꿔주기
  useEffect(() => {
    const newRoute = location.pathname.split('/').filter(Boolean)[1];
    setCategory(newRoute);
  }, [location.pathname]);

  // 처음 컴포넌트가 생성될 때 ROUTE_PAGE에 따라 twoDepth 를 생성한다.
  const renderTwoDepth = () => (
    <ul className="twoDepth">
      {Object.keys(ROUTE_PAGE).map((categoryName) => (
        <li key={categoryName}>
          <button
            className={categoryName === category ? 'current' : ''}
            type="button"
            onClick={() => setCategory(categoryName)}
          >
            <span>{ROUTE_PAGE[categoryName].title}</span>
          </button>
        </li>
      ))}
    </ul>
  );

  // 카테고리가 변경될 때마다 threeDepth 를 변경한다.
  const [threeDepth, setThreeDepth] = useState([]);

  useEffect(() => {
    const renderThreeDepth = () => {
      const categoryData = ROUTE_PAGE[category];
      if (categoryData) {
        const links = Object.entries(categoryData.child).map(([key, value]) => {
          // 2024-12-27 추가
          // 검정성적조회 > 농가별 검정성적조회인 경우 외부 URL로 이동
          if (category === 'Performance' && key === 'PerformanceCheck') {
            return (
              <li key={key}>
                <a
                  href="http://www2.dcic.co.kr/performace_02.do"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => setNavOpen(false)}
                >
                  <span>{value}</span>
                </a>
              </li>
            );
          }

          // 기존 내부 라우팅
          return (
            <li key={key}>
              <Link
                to={`/Web/${category}/${key}`}
                onClick={() => setNavOpen(false)}
              >
                <span>{value}</span>
              </Link>
            </li>
          );
        });
        setThreeDepth(links);
      }
    };

    renderThreeDepth();
  }, [category]);

  return (
    <nav className={`mobileNav ${navOpen ? 'open' : ''}`}>
      <h2 className="hidden">네비게이션 영역</h2>
      <div className="head">
        <button type="button" onClick={() => setNavOpen(false)}>
          <span className="hidden">네비게이션 닫기</span>
        </button>
      </div>
      <div className="body">
        {renderTwoDepth()}
        <ul className="threeDepth">{threeDepth}</ul>
      </div>
      <div className={`mobileNavBack ${navOpen ? 'open' : ''}`}></div>
    </nav>
  );
}

export default NavMobile;
