import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getState, postState } from 'utils/api';

function SemenEdit() {
  const [searchParams] = useSearchParams();
  const bdcCode = searchParams.get('bdc_code');

  const [formData, setFormData] = useState({
    bdc_code: '',
    bdc_wname: '',
  });

  const navigate = useNavigate();

  useEffect(() => {
    const fetchSemen = async () => {
      try {
        const response = await getState('/board/select_semen', {
          bdc_code: bdcCode,
        });
        if (response?.return_code === 'SUCCESS') {
          setFormData({
            bdc_code: response.result.BDC_CODE,
            bdc_wname: response.result.BDC_WNAME,
          });
        }
      } catch (error) {
        console.error('Error fetching semen:', error);
      }
    };

    if (bdcCode) {
      fetchSemen();
    }
  }, [bdcCode]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.bdc_wname) {
      alert('년월을 입력해주세요.');
      return;
    }

    try {
      const endpoint = bdcCode
        ? '/board/update_semen/'
        : '/board/insert_semen/';
      const response = await postState(endpoint, formData);

      if (response?.return_code === 'SUCCESS') {
        alert(bdcCode ? '수정되었습니다.' : '등록되었습니다.');
        navigate('/bo/semen/semenList');
      }
      if (response?.return_code === 'ERROR') {
        alert(response?.return_message);
      }
    } catch (error) {
      console.error('Error saving semen:', error);
      alert('저장 중 오류가 발생했습니다.');
    }
  };

  const handleDelete = async () => {
    if (window.confirm('정말 삭제하시겠습니까?')) {
      try {
        const response = await postState('/board/delete_semen/', {
          bdc_code: bdcCode,
        });
        if (response?.return_code === 'SUCCESS') {
          alert('삭제되었습니다.');
          navigate('/bo/semen/semenList');
        }
        if (response?.return_code === 'ERROR') {
          alert(response?.return_message);
        }
      } catch (error) {
        console.error('Error deleting semen:', error);
        alert('삭제 중 오류가 발생했습니다.');
      }
    }
  };

  return (
    <div className="bo-content">
      <h2>정액 {bdcCode ? '수정' : '등록'}</h2>
      <div className="table-container bold-border">
        <table>
          <tbody>
            <tr>
              <th>
                년월<span className="required">*</span>
              </th>
              <td className="nr">
                <input
                  type="text"
                  value={formData.bdc_wname || ''}
                  onChange={(e) =>
                    setFormData({ ...formData, bdc_wname: e.target.value })
                  }
                  maxLength={11}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="button-group">
        <button type="button" onClick={handleSubmit} className="btn-l">
          {bdcCode ? '수정' : '등록'}
        </button>
        {bdcCode && (
          <button type="button" onClick={handleDelete} className="btn-l">
            삭제
          </button>
        )}
        <button
          type="button"
          onClick={() => navigate('/bo/semen/semenList')}
          className="btn-l"
        >
          목록
        </button>
      </div>
    </div>
  );
}

export default SemenEdit;
