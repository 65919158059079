import React from 'react';
import { Outlet } from 'react-router-dom';

// 컴포넌트
import SubMain from 'components/subMain/SubMain';

// scss


function Business() {
  return (
    <div className="business">
      <SubMain />
      <Outlet />
    </div>
  );
}

export default Business;
