import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';

// api
import { getState } from 'utils/api';

// components
import Pagination from 'components/bo/Pagination';

function ApiResultStatusList() {
  const [searchState, setSearchState] = useState({
    page_no: 1,
  });

  const { data, isLoading, isError, error } = useQuery({
    queryKey: ['apiResultStatus', searchState],
    queryFn: () =>
      getState('/board/select_api_result_status_list', {
        ...searchState,
      }),
  });

  const resultList = data?.result?.list || [];
  const totalCount = data?.result?.totalCount || 0;
  const totalPages = data?.result?.pageCount || 0;

  const handlePageChange = (pageNo) => {
    setSearchState({
      ...searchState,
      page_no: pageNo,
    });
  };

  return (
    <div className="bo-page">
      <h2>축산경제통합시스템 API 결과 상태 목록</h2>

      <div className="board-top">
        <p>
          총 자료수: <strong>{totalCount}</strong> 개, 페이지{' '}
          {searchState.page_no}/{totalPages}
        </p>
      </div>

      <div className="table-container">
        <table>
          <colgroup>
            <col width="4%" />
            <col width="13%" />
            <col width="7%" />
            <col width="7%" />
            <col width="8%" />
            <col width="*" />
            <col width="7%" />
            <col width="8%" />
            <col width="10%" />
          </colgroup>
          <thead>
            <tr>
              <th>번호</th>
              <th>구분</th>
              <th>검정일</th>
              <th>responseCode</th>
              <th>responseMessage</th>
              <th>처리결과내용</th>
              <th>응답코드</th>
              <th>응답메세지</th>
              <th>저장일자</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td colSpan="9" className="loading-data">
                  데이터를 불러오는 중입니다...
                </td>
              </tr>
            ) : isError ? (
              <tr>
                <td colSpan="9" className="error-data">
                  에러가 발생했습니다: {error.message}
                </td>
              </tr>
            ) : resultList.length === 0 ? (
              <tr>
                <td colSpan="9" className="no-data">
                  게시글이 없습니다.
                </td>
              </tr>
            ) : (
              resultList.map((item) => (
                <tr key={item.row_num}>
                  <td className="center">{item.row_num}</td>
                  <td className="center">{item.gubn_nm}</td>
                  <td className="center">{item.atz_ym}</td>
                  <td className="center">{item.responsecode}</td>
                  <td className="center">{item.responsemessage}</td>
                  <td className="center">{item.prcrzt_cntn}</td>
                  <td className="center">{item.code}</td>
                  <td className="center">{item.msg}</td>
                  <td className="center">{item.save_dt}</td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>

      {/* 페이지네이션 */}
      <Pagination
        currentPage={searchState.page_no}
        totalPage={totalPages}
        onPageChange={handlePageChange}
      />
    </div>
  );
}

export default ApiResultStatusList;
