import React from 'react';

import Pic494 from 'assets/images/sub/_images/pic494.jpg';
import Pic495 from 'assets/images/sub/_images/pic495.jpg';

function Do84() {

  return (
    <div className="techinfoview">
				<h1>장중첩</h1>
				<p className="pstyle">장(腸)의 일부가 안쪽으로 뒤집혀 이에 접해 있는 장의 내강(內腔)에 함입하여 그 함입부분의 폐쇄 및 울혈을 초래한 상태를 말한다. </p>
				<div className="leftpart">
					<h2>원인</h2>
					<ul>
						<li>차가운 음식물의 섭취, 급성장염, 장내기생충, 장내 이물등에 의한 장의 격렬한 역동운동항진에 의한다. </li>
					</ul>
					<h2>증상</h2>
					<ul>
						<li>특별한 징후 없이 다소심한 산통(疝痛)증상을 나타내며 뒷발로 배를 차거나 빈번히 머리를 흔들며 좌우 교대로 다리를 움직이며 불안한 모습을 보인다. </li>
						<li>식욕부진. 반추중지를 보인다. 초기에는 설사를 보이다가 말기에는 변비가 나타난다. 점액변 또는 혈변이 섞여 나오기도 한다.  </li>
						<li>산통증상은 6∼12시간 지속하다가 진정되며, 마치 완전히 치료된 것처럼 보인다. </li>
						<li>장 연동이 중지되며, 복부는 가스축적 때문에 점차 팽대해진다. </li>
						<li>경과가 긴 편이어서 6∼9일 후 폐사하나 2주일후에 폐사하는 것도 있다. </li>
					</ul>
					<h2>목장에서의 처치</h2>
					<ul>
						<li>수의사와 상의하여 수술을 받거나 도태 처분한다. </li>
					</ul>
				</div>
				<div className="rightpart">
					<img src={Pic494} className="nonborder" alt=""/>
					<p>완전히 반전되어 중첩된 맹장(절개하였음)/TD</p>
					<img src={Pic495} className="nonborder" alt=""/>
					<p>장이 중첩된 모습/TD</p>
				</div>
			</div>
  );
}

export default Do84;