import React from 'react';

import pic737 from 'assets/images/sub/_images/pic737.jpg';
import pic738 from 'assets/images/sub/_images/pic738.jpg';

function Do155() {

  return (
    <div className="techinfoview">
      <h1>안충증</h1>
      <div className="leftpart">
        <h2>원인</h2>
        <ul>
          <li>소에 기생하는 안충으로서는 로데시아 안충이 있고 눈꺼풀, 결막낭, 눈물샘등에 여러마리가 기생하며 눈병을 일으킨다.  안충의 수명은 6∼7개월로서 봄에 태어난 것은 그해 안에 사멸하나. 가을에 태어난 것은 그 다음해 봄까지 생존한다.</li>
        </ul>
        <h2>증상</h2>
        <ul>
          <li>성충이 눈결막에 서식하면 결막이 충혈되고 항상 눈물을 흘린다. 가벼운 결막염 증세를 일으킨다. </li>
          <li>안충 자체에 의한 피해는 가벼운 결막염 증세를 일으킨다. </li>
          <li>안충 자체에 의한 피해는 가벼운 편이지만 전염성 각결막염 등의 안질환이 있는 경우에는 합병증을 일으켜 중증이 되며 실명(失明)하게 된다. </li>
        </ul>
        <h2>목장에서의 처치</h2>
        <ul>
          <li>눈에서 기생충이 발견되면 생리식염수로 눈을 세척하여 기생충을 제거한다. </li>
          <li>1∼2% 의 크레오린액을 결막낭에 주입한다. </li>
          <li>증상이 진행되어 결막에 염증이 생기면 다른 눈병이 결막염 치료에 준하여 처치하면 된다. </li>
        </ul>
      </div>
      <div className="rightpart">
        <img src={pic737} className="nonborder" alt=""/>
        <img src={pic738} className="nonborder" alt=""/>
      </div>
    </div>
  );
}

export default Do155;