import React from 'react';

import pic229 from 'assets/images/sub/_images/pic229.jpg';

function Do195() {

  return (
    <div className="techinfoview">
      <h1>크림손 클로버</h1>
      <p>영명 : Crimson clover <br/>학명 : Trifolium incarnatum L.</p>
      <div style={{width: '60%' , float: 'left'}}>
        <h2>원산지</h2>
        <p>원산지는 유럽으로서 불란서, 헝가리, 남주 및 중앙 유럽에 넓게 재배되고 있다.</p>
        <h2>성상</h2>
        <p>직립형이며, 1년생 또는 월년생 두과목초로서 30∼90cm 정도 자란다. 가지 및 줄기의 기부와 첫마디 부위에 털이 많이 있다.<br/>잎은 3개의 잎조각으로 되어 있고 잎은 기부는 좁고 끝부분은 넓게 퍼져 타원형으로 되어 있으며 털이 조밀하게 나있다. 일반적으로 줄기와 잎들은 레드클로버(Red clover)와 비슷하다.<br/>각 줄기는 원통형과 같이 생긴 끝이 있으며, 긴 화두는 75∼125개 정도의 소화로 형성되어 있다. 꽃은 광택이 있는 선홍색으로 화두의 기부에서부터 끝까지 개화가 잘된다.</p>
        <h2>재배</h2>
        <p>비옥하고 배수가 잘되는 사양토양이나, 산성과 알카리 토양에서도 잘 자라며, 서늘하면서도 습하며 년간 강우량이 875mm 이상 되는 곳에서 잘 자란다. 이 목초는 다량의 인산질이 필요하며 비옥한 토양에서 특히 수량이 높다.</p>
        <h2>이용</h2>
        <p>채초, 건초용 및 토양개량용으로 이용하며 방목으로도 적합하다.</p>
      </div>
      <div style={{width: '40%', float: 'left'}}>
        <img src={pic229} className="nonborder2" alt=""/>
      </div>
    </div>
  );
}

export default Do195;