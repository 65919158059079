import React from 'react';

import Figure2by6 from 'assets/images/sub/_images/figure_2_6.gif';

function Fo13() {

  return (
    <div className="techinfo_view">
				<p className="viewTitle">발정관찰과 인공수정 - 자연교미</p>
				<p className="mg60f">수소를 이용한 자연교미는 인공수정이 효과적이라는 것이 증명되기 이전에는 전 지역에서 널리 이용되어졌다. 많은 농부들은 인공수정을 하는 것보다 임신율이 높다는 것을 확신했기기 때문일 것이다. 발정관찰이 정확하고 적기에 수정을 실시했을 경우에 인공수정과 자연교미도 비로서 성공을 거둘 수가 있었다. 정확한 번식기록은 조기에 발정을 관찰할 수 있고 문제을 발견할 수 있기 때문에 아주 중요하다. </p>
				
				<h2>자연교미를 실시해야할 경우 </h2>
				<p>성공적인 번식은 농장의 경제적 이익과 밀접한 관계가 있다. 한편으로 장기간의 인공수정을 통하여 이룩된 유전적 개량으로 경제적 이익을 얻을 수 있다. 유전적 개량효과측면에서 실시하는 인공수정에 비하면 자연교미는 역설적이다. 그러나 유우에서 자연교미를 실시해야 할 3가지 이유가 있다</p>
				<ul className="listStyle03 mg60f">
					<li>발정관찰과 인공수정을 할 수 있는 기술이 없을 경우</li>
					<li>장기간 동안의 개량효과가 미미하거나 그 중요성이 없을 경우</li>
					<li>인공수정을 할 수 있는 조건이 갖추어지지 않은 지역(동결정액, 액체질소 등의 공급이 되 지 않는 지역)</li>
				</ul>
				
				<h2>종모우</h2>
				<p>일반적으로 자연교미에 사용할 수소는 빌려오가나, 구입을 하는데, 질병이 감염된 상태로 농장으로 올 수가 있기 때문에 항상 유의해야 한다. 발정온 소를 구분하거나 번식할 수 있는 소의 능력은 교미욕, 정액의 질과 교미능력에 따라 좌우된다. 수소는 경험있는 수의사가 신체적, 번식능력을 검사받아야 한다. 어린소는 사람에게 덜 위험하고 교미욕은 강하지만, 번식장애가 있는 연령인지를 확인할 필요가 있다. 일반적으로 온대지역에서는 12개월 이상 아열대 지역에서는 14개월령에 성숙이 완료된다. </p>
				<dl>
					<dt>사료급여</dt>
					<dd>수소는 과비되었거나, 너무 야위면 종모우로서의 기능을 제대로 발휘할 수 없다. 젖소에는 칼슘과 단백질을 많아 공급하는데 공급비가 과도하면 다리와 발에 laminitis와 같은 병에 걸리기 쉽다.</dd>
				</dl>
				<dl className="mg60f">
					<dt>종부를 위한 시설 </dt>
					<dd>수소는 암소와 크기가 다르기 때문에 수정에 문제가 발생한다. 게다가 경사지, 젖은 땅, 불안한 곳에서는 종부를 하지 않는다. 건조한 땅에서는 신체적인 손상의 위험이 적다. 또한 사람 또는 개가 많고 소음이 심한곳에서는 교미욕이 억제된다. </dd>
				</dl>
			
				<h2>자연교미의 위험성</h2>
				<div className="imgBox"><img src={Figure2by6} alt=""/></div>
				<dl>
					<dt>생식기 전염병의 전파 </dt>
					<dd>소에 있어서 몇종의 생식기 질병은 아주 중요하다. 이둘중 캄필로박터병과 트리코모나스병이다. 두가지 병의 주감염원이 음경과 표피이다. 나이가 많은 소에서 만성적으로 감염이되며, 수소의 체내에 있지 않기 때문에 병의 감염징후가 뚜렷하지 않다. 결과적으로 병원균에 대한 면역반응 나타나지 않는다. 그러나, 자연교미시에 병원균은 쉽게 암소의 생식기내로 전파되며, 불임의 원인이 된다. 캄필로박터에 감염이 되면, 소는 불임이 되지만, 병원균에 대한 면역력을 가지게 된다. 정상적인 생식기능을 발휘하기 위해서는 4개월 정도가 갈리며. 임신이된 소는 유산이 된다. 트리코모나스의 경우는 임신이 되고 태아도 성장하지만, 태막에 감염이 되면 임신 3개월 정도에서 유산이 된다. </dd>
				</dl>
				<dl className="mg60f">
					<dt>Heat stress </dt>
					<dd>고온은 소의 수태에 영향을 미치지만, heat stress에 대한 감수성은 종에 따라 다양하다. 고온지역에서 사육된 수소를 온난한 지역으로 옮기면, 여름에는 수태율이 낮고 교미욕도 감소한다. 환경에 적응한 소들은 heat stress를 받지 않는다. 여름에 그늘을 만들어 주면 열에 대한 부의 반응이 감소한다. 수소에서 정자가 생산되는 시간이 필요하기 때문에 열에 대한 스트레스를 받는 1∼2개월은 수태율이 감소하며 정상적인 기능이 회복되는데 1∼2개월이 소요된다. </dd>
				</dl>
				
				<h2>요약</h2>
				<p>발정기 동안 소들 상호간의 변화는 아주 극적이라고 할 수가 있다. 우군에서 90％이상의 발정관찰을 하기 위해서는 이른 아침, 늦은 저녁까지 4∼5시간 간격으로 세심하게 발정관찰을 한다. 또한 90％의 발정이 온 소들은 수태가 되기 위해서는 3회 이하 수정이 필요하다.</p>
				<p>인공수정의 장점은 다음과 같다</p>
				<ul className="listStyle03 mg30f">
					<li>유우에 원하는 유전적인 특징을 전달하기 위해서는 선발된 종모우로 수정이 가능하다. </li>
					<li>농장에서 수소를 사육하는 비용과 위험 부담을 줄일 수 있다. </li>
					<li>열악한 유전적 능력을 가진 송아지를 생산을 배제할 수 있다. </li>
					<li>젊은 종모우에서 얻은 정액으로 수정을 시킬 수 있다. </li>
					<li>한세대에서 차세대까지 선발강도를 높임으로서 유전능력을 향상시킬 수 있다. </li>
				</ul>
				<p>자연종부는 다음과 같은 경우에 실시한다</p>
				<ul className="listStyle03 mg30f">
					<li>발정관찰과 인공수정을 할 수 있는 기술이 없을 경우</li>
					<li>장기간 동안의 개량효과가 미미하거나 그 중요성이 없을 경우</li>
					<li>인공수정을 할 수 있는 조건이 갖추어지지 않은 지역(동결정액, 액체질소 등의 공급이 되 지 않는 지역). </li>
				</ul>
				<p>자연종부에 이용하는 종모우는 다음과 같은 문제점과 단점이 있다 </p>
				<ul className="listStyle03">
					<li>농장에서 사육하는 수소는 위험하고, 치명적인 사고의 원인이 될 수 있다. 항상 안전을 최 상으로 고려해야 한다. </li>
					<li>수소의 사육에 비용이 많이 소요된다. </li>
					<li>인공수정시보다 질병전파의 위험이 많다.</li>
					<li>생식기 질병감염이 높다. 
						<ul className="listStyle04">
							<li>약 4개월 정도 불임이 될 수 있다.</li>
							<li>임신후 배의 조기사멸이 발생한다.</li>
						</ul>
					</li>
				</ul>
				
			</div>
  );
}

export default Fo13;
