import React from 'react';

function TestBisReportEdit() {
  return (
    <div className="bo-page">
      <h2>{BOARD_NAME.TEST_BIS_REPORT} 수정페이지</h2>
    </div>
  );
}

export default TestBisReportEdit;
