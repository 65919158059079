import React, { useState , useEffect } from 'react';
import { 
  ComposedChart,
  Scatter,
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  LabelList,
  ResponsiveContainer
} from 'recharts';

// api
import { postState } from 'utils/api';
import { openCrownix6 } from 'utils/openCrownix';

// 이미지
import PrintPDF from 'assets/svgs/common/print_PDF.svg';

// 컴포넌트
import ErrorMessage from 'components/errorMessage/errorMessage';
import MonthCode from 'components/dcitest/monthCode/MonthCode';

// scss
import './NewGraphMilk.scss';

function NewGraphMilk() {

  // 에러처리
  const [isModalOpen, setIsModalOpen] = useState(false); // 모달 추가
  const [errorMessage, setErrorMessage] = useState('');

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => {
    setIsModalOpen(false);
    setErrorMessage(''); // 모달이 닫힐 때 에러메세지 초기화
  };

  // 데이터 불러오기
  const [data13, setData13] = React.useState([]);
  const [data14, setData14] = React.useState([]);
  
  const [props , setProps] = useState({
    id:`${localStorage.getItem('cong')}${localStorage.getItem('farm')}`,
    passwd:localStorage.getItem('passwd'),
    date:localStorage.getItem('month')
  });

  const baseDir='/chart/new_graph_milk/';
  const COLORS = ['green','yellow','blue','red','violet','orange','gray','cyan'];
  useEffect(() => {

    postState(baseDir,props)
    .then(getdata => {   
      if (getdata !== undefined) {  
        setData13(getdata.plot_data);
        setData14(getdata.plot_data2);      
      // console.log('### new graph milk Property received:', getdata.farm_property);     
      // console.log('### new graph milk Plot Data received:', getdata.plot_data);          
      // console.log('### new graph milk Plot Data2 received:', getdata.plot_data2);     
      }
    })
    .catch(error => {      
      console.error('Error:', error.message);
      setErrorMessage(error.message);
      openModal();      
    });
    
  }, [props]);
  
  return (
    <div className='pdfMainArea'>
      <ErrorMessage isOpen={isModalOpen} content={errorMessage} closeModal={closeModal}/>
      <div className='pdfMainHeader'>
        <button 
          type='button'
          onClick={() => openCrownix6('New_graph_milk.mrd')}
        >
          <img src={PrintPDF} alt='PDF로 인쇄하기' />
          인쇄하기
        </button>
        {
          props && 
          <MonthCode 
            props={props}
            setProps={setProps}
            setErrorMessage={setErrorMessage}
            openModal={openModal}
          />
        }
      </div>
      <div className='pdfArea'>
        <div className='newGraphMilkPdf'>
          <span>{props.date.substring(0,4)}년 {props.date.slice(-2)}월 그래프</span>
          <h1>비유일수별 유량 분포도</h1>
          <div className='dotChartArea'>
            <ResponsiveContainer width="100%" height={300}>
              <ComposedChart             
                width="90%"
                height="85%"
                data={data13}
                margin={{
                  top: 20,
                  right: 20,
                  bottom: 20,
                  left: 20,
                }}
                barSize={8}
                >
                <CartesianGrid strokeDasharray="3 3"/>
                <XAxis 
                  type="number"
                  dataKey="day"   
                  tickCount='20'        
                  // label={{ value: '산차별두수(비율%)', position: 'bottom' }}
                />
                <YAxis
                  type="number"
                  // dataKey="bpmilk"
                  name = "산차"
                  
                  // interval={1}
                  tickCount='5'
                  label={{ value: '2산 유량(Kg/일)', position: 'insideLeft' , offset: '10', angle: -90 }}              
                />            
                <Tooltip cursor={{ strokeDasharray: '3 3' }} /* content={CustomTooltip2} */ />            
                <Scatter name="비교유량이 없거나 동일개체" dataKey="epmilk2" fill="green" />
                <Bar name="유량감소개체" dataKey="bpmilkdn" fill="red" >            
                </Bar>
                <Bar name="유량증가개체" dataKey="bpmilkup" fill="blue" >            
                </Bar>
                  
              </ComposedChart>
            </ResponsiveContainer>

            <ResponsiveContainer width="100%" height={300}>
              <ComposedChart             
                width="90%"
                height="85%"
                data={data14}
                margin={{
                  top: 20,
                  right: 20,
                  bottom: 20,
                  left: 20,
                }}
                barSize={8}
                >
                <CartesianGrid strokeDasharray="3 3"/>
                <XAxis 
                  type="number"
                  dataKey="day"     
                  tickCount='20'      
                  label={{ value: '비 유 일 수(일)', position: 'bottom' }}
                />
                <YAxis
                  type="number"
                  // dataKey="bpmilk"
                  name = "산차"
                  
                  // interval={1}
                  
                  label={{ value: '1산 유량(Kg/일)', position: 'insideLeft' , offset: '10', angle: -90 }}
                  
                />
                
                <Tooltip cursor={{ strokeDasharray: '3 3' }} /* content={CustomTooltip2} */ />
                <Scatter name="비교유량이 없거나 동일개체" dataKey="epmilk1" fill="green" />
                <Bar name="유량감소개체" dataKey="bpmilkdn" fill="red" >            
                </Bar>
                <Bar name="유량증가개체" dataKey="bpmilkup" fill="blue" >            
                </Bar>
                  
              </ComposedChart>
            </ResponsiveContainer>

          </div>
        </div>
      </div>
    </div>   
  );
}

export default NewGraphMilk;
