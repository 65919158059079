import React from 'react';

import pic594 from 'assets/images/sub/_images/pic594.jpg';
import pic595 from 'assets/images/sub/_images/pic595.jpg';
import pic596 from 'assets/images/sub/_images/pic596.jpg';

function Do113() {

  return (
    <div className="techinfoview">
      <h1>자궁내막염</h1>
      <p className="pstyle">자궁의 점막이 세균의 감염을 받아 염증을 일으킨 것이 자궁내막염으로 불임증(不姙症)의 일반적인 원인이 되고 있다. </p>
      <div className="leftpart">
        <h2>원인</h2>
        <ul>
          <li>자궁내막염을 일으키는 주된 세균은 연쇄구균, 포도구균, 대장균, 화농간균 등으로 특히 화농간균은 중증(重症)을 일으킨다. </li>
          <li>세균이 침입하기 쉬운 시기는 분만 후 산욕기간 중이다. 특히 유산, 사산, 난산, 후산 정체 등에 이어서 발생이 많다. </li>
          <li>인공수정시, 조산(助産)사의 비위생적인 조작 및 기구 사용에 의해서 발생 </li>
        </ul>
        <h2>증상</h2>
        <ul>
          <li>발정시에 발정 점액속에 농(膿)찌꺼기가 섞여 있다. </li>
          <li>증상이 심한 경우에는 자궁내에 화농성 삼출물이 존재하게 된다. </li>
          <li>발정주기와 간격은 대개 정상적이지만 급성 자궁 내막염일때는 주기가 8∼12일로 짧아 지는 일이 있다. </li>
          <li>수차례 수정을 시켜도 계속 재발이 오는 것은 자궁내막염을 의심할 수 있다. </li>
          <li>급성 일때는 체온상승, 삭욕감최, 유량감소 등을 볼 수 있다. </li>
        </ul>
        <h2>목장에서의 처치</h2>
        <ul>
          <li>조산(助産)이나 후산처치를 하고자 할 때는 산도(産道)에 손을 넣기 전에 항문과 외음부 주위를 따뜻한 물과 비누로 잘 씻고 실시자의 손과 팔을 소독한 후 멸균 비닐 장갑을 착용하여 실시한다. </li>
          <li>분만한지 2주일 후에도 오로(惡露)가 많든지, 점액이 깨끗치 않은 소는 자궁내막염에 걸려있는 일이 많아 수의사의 조기진단이 요청된다. </li>
          <li>주요 처치법은 자궁내의 불결한 내용물을 말끔히 씻어내고 약제를 자궁내에 주입하는 방법이다. </li>
          <li>증상이 가벼울 때는 자궁을 세척하지 않고 약액을 주입하는 방법이 있으며, 자궁내 주입약으로서는 수종류의 항생물질과 썰파제를 혼합한 것이 시판되고 있지만 우유중에 약물이 검출되는 경우가 있으므로 수의사의 지시를 지켜야 한다.</li>
        </ul>
      </div>
      <div className="rightpart">
        <img src={pic594} className="nonborder" alt=""/>
        <img src={pic595} className="nonborder" alt=""/>
        <img src={pic596} className="nonborder" alt=""/>
      </div>
    </div>
  );
}

export default Do113;