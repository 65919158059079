import React from 'react';

import pic782 from 'assets/images/sub/_images/pic782.jpg';
import pic783 from 'assets/images/sub/_images/pic783.jpg';
import pic784 from 'assets/images/sub/_images/pic784.jpg';

function Do172() {

  return (
    <div className="techinfoview">
      <h1>선천성 심장기형</h1>
      <p className="pstyle">임신중의 태아 형성시기나 발육단계에서 심장에 형태적 이상을 일으킨 경우를 선천성 심장기형이라고 하며 순환장애를 주징후로 하며 가축중에서는 젖소에서 가장 발생이 많다.</p>
      <div className="leftpart">
        <h2>원인</h2>
        <ul>
          <li>유전에 의한 경우</li>
          <li>임신초기에 어미소의 영양이 부족한 경우</li>
          <li>임신 초기에 어미소가 방사선 또는 어떤 종류의 화학물질에 노출(접촉)되었거나 중독되었을 때는 어떤 종류의 화학물질에 노출(접촉)되었거나 중독되었을 때</li>
          <li>임신 2-3개월 이내에 바이러스에 감염되었을 때</li>
        </ul>
        <h2>증상</h2>
        <ul>
          <li>일반적으로 식욕이 일정치 않으며 발육이 늦다.</li>
          <li>운동을 싫어하고 가벼운 운동 후에도 호흡곤란을 일으키며, 점막(입, 눈의 결막, 혀, 질점막 등)이 창백하다</li>
          <li>쉬고 있을때고 맥박이 빠르다.</li>
          <li>앞다리를 벌린채  서 있는 경우가 많다.(심장압박을 줄이기 위해)</li>
        </ul>
        <h2>목장에서의 처치</h2>
        <ul>
          <li>특별히 가능한 방법은 없으며, 조기발견하여 도태해야 한다.</li>
          <li>증체율이 나쁘면서 청색증과 호흡곤란을 보일 때는  즉시 수의사의 진찰을 받도록 한다.</li>
        </ul>
      </div>
      <div className="rightpart">
        <img src={pic782} className="nonborder" alt=""/>
        <img src={pic783} className="nonborder" alt=""/>
        <img src={pic784} className="nonborder" alt=""/>
      </div>
    </div>
  );
}

export default Do172;