import React from 'react';
import { useLocation } from 'react-router-dom';

// react-query
import { useQuery } from '@tanstack/react-query';

// api
import { getState } from 'utils/api';

// components
import CommonInfoView from '../_components/CommonInfoView';

function InfoTechInfoView() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const bdcCode = searchParams.get('bdc_code');
  const brdCode = searchParams.get('brd_code');

  const { data: boardViewVo } = useQuery({
    queryKey: ['boardViewVo', bdcCode, brdCode],
    queryFn: () => getState('/board/select_info', { bdc_code: bdcCode }),
  });

  return (
    <CommonInfoView
      boardViewVo={boardViewVo}
      brdCode={brdCode}
      deleteUrl="/board/delete_info/"
      returnUrl="/bo/info/infoTechInfo/list"
    />
  );
}

export default InfoTechInfoView;
