import React from 'react';

function Fo16() {

  return (
    <div className="techinfo_view">
				<p className="viewTitle">분만후의 관리</p>
				<p>분만후에 소의 번식기관은 새로운 임신을 준비하기위하여 많은 변화를 갖는다. </p>
				<p>분만후 75∼90일에는 다음 산차의 임신이 이루어져야 하는데, 목장경영에 있어 분만간격은 매우 중요한 경제적 요인이므로 분만후의 관리는 매우 중요하며 특히 발정의 제개가 늦어지지 않도록 문만 후의 변화와 스트레스를 이해하고 관리하는 무엇보다 중요하다고 하겠다. </p>
				
				<dl>
					<dt>자궁 수축</dt>
					<dd>
						<p>자궁은 분만후 바로 수축이 일어나기 시작하며 수축이 이루어지면 자궁의 무게는 9 kg에서 0.7 kg으로 줄어들며, 길이 또한 100 cm에서 33 cm로 작아진다. </p>
						<p>이기간에 임신기간동안 태아를 지탱해주었던 자궁의 세포층도 새로운 세포들로 치환이 일어나는데, 자궁의 근육과 과립세포층이 새로운 세포로 치환이 될 때까지 새로운 임신은 이루어 지지않는다. 자궁수축이 일어나는 기간은 21∼56일(3∼8주)의 기간이 소요되지만 분만시에 생긴 질병 및 오염등에 의해서 기간이 더욱 길어질수도 있다.</p>
					</dd>
				</dl>
				
				<dl>
					<dt>발정주기와 배란의 재개 </dt>
					<dd>
						<p>황체는 임신을 유지시키는데 매우 중요한 역할을 진행하며, 임신기간동안 퇴행되지 않고 유지 되어 있으나, 분만후에는 황체의 퇴행이 일어난다.</p>
						<p>정상적인 소에서 난포의 완전한 성숙이 일어나고 배란이 이루어지기까지 12-14일이 소요되며, 일반적으로 분만후에 발정주기동안 생성된 첫 번째 황체는 작으며 또한 황체기의 기간도 짧아 첫 번째와 두 번째 발정주기는 10-17일로 짧게 나타난다. 그후의 발정주기는 정상적인 21일을 주기를 가지게 된다. 평균적으로 첫 번째 배란은 분만후 15일, 두 번째 배란은 32일 그리고 세 번째 배란은 53일에 일어난다(표 3.3) </p>
						<p>하지만 분만시 및 분만후의 질병 및 오염등의 문제는 발정주기 및 배란의 재개를 불규칙적으로 하거나 늦어지게 한다.</p>
					</dd>
				</dl>
				
				<table className="tablestyle">
					<caption>표 3.3 분만후 발정 및 배란의 재개일수<sup>1</sup></caption>
					<thead>
						<tr>
							<th className="first" colSpan="2">배란</th>
							<th colSpan="2">발정행위의 유무(%)</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>순번</td>
							<td>분만후 일수</td>
							<td>없음<sup>2</sup></td>
							<td>있음<sup>2</sup></td>
						</tr>
						<tr>
							<td>첫번째</td>
							<td>15</td>
							<td>23</td>
							<td>76</td>
						</tr>
						<tr>
							<td>두번째</td>
							<td>32</td>
							<td>46</td>
							<td>54</td>
						</tr>
						<tr>
							<td>세번째</td>
							<td>53</td>
							<td>90</td>
							<td>10</td>
						</tr>
					</tbody>
				</table>
				<ul className="refer mg10t">
					<li>1. Adapted from Dairy Profit Series: Reproduction Your key to future profits. 1986. Extension</li>
					<li>2. Present=Standing heat; Absent= silent heat.</li>
				</ul>
			</div>
  );
}

export default Fo16;
