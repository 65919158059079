import React from 'react';

// 컴포넌트
import DciDoc from 'containers/dcitest/dciDoc/DciDoc';

function DciDocPage() {
  return (
    <DciDoc />  
  );
}

export default DciDocPage;
