import React, { useState, useRef } from 'react';

// scss
import './cooperPoint.scss';

function CooperPoint() {
  const [currentTab, setCurrentTab] = useState('');
  const cooperPointTabItem = [
    { name: 'seoul', kr: '서울, 경기' },
    { name: 'gangwon', kr: '강원도' },
    { name: 'chungbuk', kr: '충청북도' },
    { name: 'chungnam', kr: '충청남도' },
    { name: 'junbuk', kr: '전라북도' },
    { name: 'junnam', kr: '전라남도' },
    { name: 'gungbok', kr: '경상북도' },
    { name: 'gungnam', kr: '경상남도' },
    { name: 'jeju', kr: '제주도' },
  ];

  const cooperRef = useRef(null);

  return (
    <div className="cooperPointInner">
      <h5>전국 유우군 검정조합 위치</h5>
      <ul className="cooperPointTab">
        {cooperPointTabItem.map((tabItem) => (
          <li key={tabItem.name}>
            <button
              type="button"
              onClick={() => setCurrentTab(tabItem.name)}
              className={`${currentTab === tabItem.name ? 'current' : ''}`}
            >
              {tabItem.kr}
            </button>
          </li>
        ))}
      </ul>
      <p>
        젖소 유우군 검정에 참여하시려면 가까운 검정조합에 문의해주세요.
        <br />
        <strong>지역명</strong>을 누르시면 해당 유우군검정 조합을 확인하실 수
        있습니다.
      </p>
      <ul className="cooperPointTable" ref={cooperRef}>
        {currentTab === 'seoul' && (
          <li className="seoul">
            <p>서울·경기&#40;수도권&#41; 유우군검정조합</p>
            <div className="tablebox">
              <table>
                <thead>
                  <tr>
                    <th colSpan="2">검정조합</th>
                    <th>주소</th>
                    <th>전화번호</th>
                    <th>팩스</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td rowSpan="9">서울우유</td>
                    <td>본소</td>
                    <td>
                      우&#41;02118 서울시 중랑구 중랑천로 71&#40;상봉동&#41;
                    </td>
                    <td>02-490-8314</td>
                    <td>02-490-8369</td>
                  </tr>
                  <tr>
                    <td>동부</td>
                    <td>
                      우&#41;12242 경기도 남양주시 양정로 63&#40;일패동&#41;
                    </td>
                    <td>031-595-6027~8</td>
                    <td>031-595-2802</td>
                  </tr>
                  <tr>
                    <td>동북부</td>
                    <td>
                      우&#41;11127 경기도 포천시 영중면 호국로
                      3295-23&#40;성동리&#41;
                    </td>
                    <td>031-533-7470/80</td>
                    <td>031-536-1138</td>
                  </tr>
                  <tr>
                    <td>북부</td>
                    <td>우&#41;11461 경기도 양주시 고덕로 139번길 360</td>
                    <td>031-863-1051~2</td>
                    <td>031-861-2632</td>
                  </tr>
                  <tr>
                    <td>서부</td>
                    <td>
                      우&#41;10937 경기도 파주시 조리읍 봉천로
                      11&#40;봉일천리&#41;
                    </td>
                    <td>031-941-7626/4130</td>
                    <td>031-942-0936</td>
                  </tr>
                  <tr>
                    <td>경인</td>
                    <td>
                      우&#41;21316 인천시 부평구 부평북로 305&#40;갈산1동&#41;
                    </td>
                    <td>032-517-8343~4</td>
                    <td>032-524-0305</td>
                  </tr>
                  <tr>
                    <td>남부</td>
                    <td>
                      우&#41;17539 경기 안성시 안성맞춤대로 1421&#40;사곡동&#41;
                    </td>
                    <td>031-675-0658/0968</td>
                    <td>031-676-0998</td>
                  </tr>
                  <tr>
                    <td>동남부</td>
                    <td>
                      우&#41;17412 경기도 이천시 설성면 진상미로
                      802&#40;수산리&#41;
                    </td>
                    <td>031-643-1171~3</td>
                    <td>031-643-1174</td>
                  </tr>
                  <tr>
                    <td>안산</td>
                    <td>
                      우&#41;15407 경기도 안산시 단원구 성곡로
                      153&#40;신길동&#41;
                    </td>
                    <td>031-491-9637</td>
                    <td>031-495-3250</td>
                  </tr>
                  <tr>
                    <td colSpan="2">평택축협</td>
                    <td>
                      우&#41;17934 경기도 평택시 안중읍 서해로
                      1146-20&#40;안중리&#41;
                    </td>
                    <td>031-683-2121~2</td>
                    <td>031-683-2123</td>
                  </tr>
                  <tr>
                    <td colSpan="2">남양주축협</td>
                    <td>
                      우&#41;12257 경기도 남양주시 경춘로 476번길
                      24&#40;지금동&#41;
                    </td>
                    <td>031-560-4734</td>
                    <td>031-551-6877</td>
                  </tr>
                  <tr>
                    <td colSpan="2">이천축협</td>
                    <td>
                      우&#41;17377 경기도 이천시 남천로 76&#40;중리동&#41;
                    </td>
                    <td>031-634-6611</td>
                    <td>031-634-6616</td>
                  </tr>
                  <tr>
                    <td colSpan="2">파주연천축협</td>
                    <td>
                      우&#41;10804 경기도 파주시 적성면 어삼로
                      36&#40;어유지리&#41;
                    </td>
                    <td>031-948-1986</td>
                    <td>031-941-0171</td>
                  </tr>
                  <tr>
                    <td colSpan="2">포천축협</td>
                    <td>
                      우&#41;11140 경기도 포천시 신북면 중앙로
                      369&#40;가채리&#41;
                    </td>
                    <td>031-534-0042</td>
                    <td>031-533-0286</td>
                  </tr>
                  <tr>
                    <td rowSpan="2">한국종축개량협회</td>
                    <td>본소</td>
                    <td>
                      우&#41;06668 서울시 서처구 명달로 88&#40;서초3동&#41;
                    </td>
                    <td>02-588-9301</td>
                    <td>02-3471-3929</td>
                  </tr>
                  <tr>
                    <td>분석소</td>
                    <td>
                      우&#41;17579 경기도 안성시 중앙로 327&#40;한경대&#41;
                      <br />
                      그린낙농기술센터 111호 중앙유성분분석소
                    </td>
                    <td>031-677-9302</td>
                    <td>031-677-9355</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </li>
        )}
        {currentTab === 'gangwon' && (
          <li className="gangwon">
            <p>강원도 유우군검정조합</p>
            <div className="tablebox">
              <table clasName="table">
                <thead>
                  <tr>
                    <th>검정조합</th>
                    <th>주소</th>
                    <th>전화번호</th>
                    <th>팩스</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>원주축협</td>
                    <td>
                      우&#41;26413 강원도 원주시 원일로 233&#40;학성동&#41;
                    </td>
                    <td>033-738-6451</td>
                    <td>033-746-4795</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </li>
        )}
        {currentTab === 'chungbuk' && (
          <li className="chungbuk">
            <p>충청북도 유우군검정조합</p>
            <div className="tablebox">
              <table clasName="table">
                <thead>
                  <tr>
                    <th>검정조합</th>
                    <th>주소</th>
                    <th>전화번호</th>
                    <th>팩스</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>충북낙협</td>
                    <td>
                      우&#41;28136 충북 청주시 청원구 북이면 장재2길
                      66-10&#40;금암리&#41;
                    </td>
                    <td>043-215-9334~7</td>
                    <td>043-215-9338</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </li>
        )}
        {currentTab === 'chungnam' && (
          <li className="chungnam">
            <p>충청남도 유우군검정조합</p>
            <div className="tablebox">
              <table clasName="table">
                <thead>
                  <tr>
                    <th colSpan="2">검정조합</th>
                    <th>주소</th>
                    <th>전화번호</th>
                    <th>팩스</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td rowSpan="2">천안공주낙협</td>
                    <td>천안지소</td>
                    <td>
                      우&#41;31019 충남 천안시 성환읍 성환1로
                      116&#40;매주리&#41; 천안경제사업소
                    </td>
                    <td>041-581-4411~4</td>
                    <td>041-582-2868</td>
                  </tr>
                  <tr>
                    <td>공주지소</td>
                    <td>
                      우&#41;32529 충남 공주시 신덕마을길 5&#40;신관동&#41;
                      공주경제사업소
                    </td>
                    <td>041-586-2581</td>
                    <td>041-856-2583</td>
                  </tr>
                  <tr>
                    <td colSpan="2">서산축협</td>
                    <td>
                      우&#41;32011 충남 서산시 음암면 중앙로 678&#40;신장리&#41;
                      집유장
                    </td>
                    <td>041-669-7835</td>
                    <td>041-667-7501</td>
                  </tr>
                  <tr>
                    <td colSpan="2">천안축협</td>
                    <td>
                      우&#41;31198 충남 천안시 동남구 청수11로
                      12&#40;청당동&#41; 하나로마트 3층 검정소
                    </td>
                    <td>041-520-6022</td>
                    <td>041-556-0735</td>
                  </tr>
                  <tr>
                    <td colSpan="2">홍성낙협</td>
                    <td>
                      우&#41;32236 층남 홍성군 홍성읍 홍성천길
                      166&#40;오관리&#41;
                    </td>
                    <td>041-634-4490</td>
                    <td>041-633-0769</td>
                  </tr>
                  <tr>
                    <td colSpan="2">당진낙협</td>
                    <td>우&#41;31762 충남 당진시 원당로 183&#40;원당동&#41;</td>
                    <td>041-730-2234~8</td>
                    <td>041-352-0630</td>
                  </tr>
                  <tr>
                    <td colSpan="2">논산계룡축협</td>
                    <td>
                      우&#41;32989 충남 논산시 시민로194번길 1&#40;내동&#41;
                    </td>
                    <td>041-730-2234~8</td>
                    <td>041-730-2249</td>
                  </tr>
                  <tr>
                    <td colSpan="2">예산축협</td>
                    <td>
                      우&#41;32431 충남 예산시 예산읍 충렬사로
                      49&#40;주교리&#41;
                    </td>
                    <td>041-333-1512</td>
                    <td>041-335-7812</td>
                  </tr>
                  <tr>
                    <td colSpan="2">아산축협</td>
                    <td>
                      우&#41;31521 충남 아산시 번영로216번길 46&#40;모종동&#41;
                    </td>
                    <td>041-547-6834</td>
                    <td>041-544-7743</td>
                  </tr>
                  <tr>
                    <td rowSpan="2">대전충남우유</td>
                    <td>본소</td>
                    <td>
                      우&#41;34508 대전시 동구 동대전로 262&#40;가양2동&#41;
                    </td>
                    <td>042-623-0770</td>
                    <td>042-633-5869</td>
                  </tr>
                  <tr>
                    <td>백제지소</td>
                    <td>
                      우&#41;33166 충남 부여군 부여읍 계백로 304&#40;동남리&#41;
                    </td>
                    <td>041-837-1011</td>
                    <td>041-837-1014</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </li>
        )}
        {currentTab === 'junbuk' && (
          <li className="junbuk">
            <p>전라북도 유우군검정조합</p>
            <div className="tablebox">
              <table clasName="table">
                <thead>
                  <tr>
                    <th>검정조합</th>
                    <th>주소</th>
                    <th>전화번호</th>
                    <th>팩스</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>고창부안축협</td>
                    <td>
                      우&#41;56411 전북 고창군 흥덕면 부안로423&#40;신덕리&#41;
                    </td>
                    <td>063-561-2480</td>
                    <td>063-563-3096</td>
                  </tr>
                  <tr>
                    <td>전북지리산낙협</td>
                    <td>우&#41;55769 전북 남원시 의총로 28&#40;금동&#41;</td>
                    <td>063-631-4541~2</td>
                    <td>063-634-8945</td>
                  </tr>
                  <tr>
                    <td>임실치즈축협</td>
                    <td>
                      우&#41;55924 전북 임실군 임실읍 봉황로 329&#40;갈마리&#41;
                    </td>
                    <td>063-640-6461</td>
                    <td>063-642-0900</td>
                  </tr>
                  <tr>
                    <td>동진강낙협</td>
                    <td>
                      우&#41;54368 전북 김제시 콩쥐팥쥐로 125&#40;순동&#41;
                    </td>
                    <td>063-547-0998</td>
                    <td>063-545-0998</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </li>
        )}
        {currentTab === 'junnam' && (
          <li className="junnam">
            <p>전라남도 유우군검정조합</p>
            <div className="tablebox">
              <table table clasName="table">
                <thead>
                  <tr>
                    <th colSpan="2">검정조합</th>
                    <th>주소</th>
                    <th>전화번호</th>
                    <th>팩스</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td rowSpan="2">전남낙협</td>
                    <td>보성지소</td>
                    <td>
                      우&#41;59431 전남 보성군 벌교읍 남하로 158&#40;영등리&#41;
                    </td>
                    <td>061-858-6330~2</td>
                    <td>061-858-6333</td>
                  </tr>
                  <tr>
                    <td>광주지소</td>
                    <td>
                      우&#41;58213 전남 나주시 산포면 송림산제길
                      170&#40;산제리&#41;
                    </td>
                    <td>061-336-8166~8</td>
                    <td>061-336-8169</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </li>
        )}
        {currentTab === 'gungbok' && (
          <li className="gungbok">
            <p>경상북도 유우군검정조합</p>
            <div className="tablebox">
              <table clasName="table">
                <thead>
                  <tr>
                    <th colSpan="2">검정조합</th>
                    <th>주소</th>
                    <th>전화번호</th>
                    <th>팩스</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td rowSpan="3">경북대구낙협</td>
                    <td>본소</td>
                    <td>우&#41;38680 경북 경산시 성암로 46&#40;옥산동&#41;</td>
                    <td>053-813-3771~4</td>
                    <td>053-813-3785</td>
                  </tr>
                  <tr>
                    <td>경주지소</td>
                    <td>
                      우&#41;38006 경북 경주시 강돔면 인좌안길
                      6-18&#40;인동리&#41; 경주집유소
                    </td>
                    <td>054-762-3348</td>
                    <td>054-762-9237</td>
                  </tr>
                  <tr>
                    <td>대구지소</td>
                    <td>
                      우&#41;41406 대구시 북구 구리로1길 45-10&#40;학정동&#41;
                    </td>
                    <td>053-321-5051</td>
                    <td>053-321-6877</td>
                  </tr>
                  <tr>
                    <td colSpan="2">금오산낙협</td>
                    <td>
                      우&#41;39538 경북 김천시 공단1길 164&#40;대광동&#41;
                    </td>
                    <td>054-433-3130</td>
                    <td>054-433-1848</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </li>
        )}
        {currentTab === 'gungnam' && (
          <li className="gungnam">
            <p>경상남도 유우군검정조합</p>
            <div className="tablebox">
              <table className="table">
                <thead>
                  <tr>
                    <th colSpan="2" className="first">
                      검정조합
                    </th>
                    <th>주소</th>
                    <th>전화번호</th>
                    <th>팩스</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colSpan="2" className="first">
                      진주축협
                    </td>
                    <td>
                      우&#41;52792 경남 진주시 동진로 213&#40;상대2동&#41;
                    </td>
                    <td>055-772-2596</td>
                    <td>055-755-7042</td>
                  </tr>
                  <tr>
                    <td rowSpan="2" className="first">
                      부산우유
                    </td>
                    <td>양산지소</td>
                    <td>
                      우&#41;50559 경남 양산군 상북면 양산대로
                      1608&#40;석계리&#41;
                    </td>
                    <td>055-383-5881~3</td>
                    <td>055-384-0890</td>
                  </tr>
                  <tr>
                    <td>창원지소</td>
                    <td>
                      우&#41;52009 경남 함안군 칠서면 대부로 528&#40;대치리&#41;
                    </td>
                    <td>055-589-2660~8</td>
                    <td>055-587-8069</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </li>
        )}
        {currentTab === 'jeju' && (
          <li className="jeju">
            <p>제주도 유우군검정조합</p>
            <div className="tablebox">
              <table clasName="table">
                <thead>
                  <tr>
                    <th>검정조합</th>
                    <th>주소</th>
                    <th>전화번호</th>
                    <th>팩스</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>제주축협</td>
                    <td>
                      우&#41;63020 제주도 제주시 한림읍 한창로
                      1015&#40;금악리&#41;
                    </td>
                    <td>064-796-1811</td>
                    <td>064-753-3466</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </li>
        )}
      </ul>
    </div>
  );
}

export default CooperPoint;
