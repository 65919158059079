import React from 'react';

import Pic577 from 'assets/images/sub/_images/pic577.jpg';
import Pic578 from 'assets/images/sub/_images/pic578.jpg';
import Pic579 from 'assets/images/sub/_images/pic579.jpg';
import Pic580 from 'assets/images/sub/_images/pic580.jpg';

function Do109() {

  return (
    <div className="techinfoview">
      <h1>우결핵</h1>
      <div className="leftpart">
        <h2>원인</h2>
        <ul>
          <li>소의 결핵은 마이코박테리움(Myco bacterium)속의 세균감염에 의해 발생하는 만성전염병으로 우리나라에서는 법정가축전염병으로 지정되어 있다.  이 병은 전형적인 만성소모성 질환으로 일단 우군(牛群)이 오염되면 근절이 쉽지 않다.</li>
        </ul>
        <h2>증상</h2>
        <ul>
          <li>감염초기나 병성(病性)이 완만한 것은 거의 증상이 나타나지 않는다
            <ul>
              <li>병세가 진행됨에 따라 영양상태가 나빠지고, 가시점막(可視粘膜)의 빈혈, 식욕부진과 유량감소 등을 볼 수 있다.</li>
              <li>폐의 결핵병소가 광범위하는 퍼지면 기침이 심해지고, 하루중 체온의 차가 1℃이상이 되는 이장열(弛張熱)을 볼 수 있다. </li>
              <li>임파절은 붓고, 단단해진다. </li>
            </ul>
          </li>
        </ul>
        <h2>목장에서의 처치</h2>
        <ul>
          <li>정기적인 투베르클린 반응 검사로 병우를 조기에 적발, 도태하여 감염원을 근절하는 것이 최상의 예방법이다.-실제 검사는 국가적으로 이루어지고 있다</li>
          <li>본병은 법정가축전염병으로 정해져 있어 양성으로 판정되면 가축전염병 예방법에 의해 도살처분 하도록 되어 있다. </li>
          <li>이 병은 사람에게도 감염되는 인수(仁獸)공통 전염병으로 결핵에 걸린 젖소의 우유는 절대로 사람이 먹어서는 안된다. </li>
        </ul>
      </div>
      <div className="rightpart">
        <img src={Pic577} className="nonborder" alt=""/>
        <img src={Pic578} className="nonborder" alt=""/>
        <img src={Pic579} className="nonborder" alt=""/>
        <img src={Pic580} className="nonborder" alt=""/>
      </div>
    </div>
  );
}

export default Do109;