import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';

// api
import { getState, postState } from 'utils/api';

function DairyDicEdit() {
  const [searchParams] = useSearchParams();
  const sjId = searchParams.get('sj_id');
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [formData, setFormData] = useState({
    sj_subject: '',
    sjc_pid: '',
    sjc_id: '',
    sj_content: '',
  });

  useEffect(() => {
    console.log(formData);
  }, [formData]);

  // Fetch categories data
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);

  const handleCategoryChange = async (e) => {
    const selectedCategory = e.target.value;
    setFormData((prev) => ({
      ...prev,
      sjc_pid: selectedCategory,
    }));

    if (!selectedCategory) {
      setSubCategories([]);
      return;
    }

    try {
      const response = await getState('/board/select_dictionary_group2', {
        sjc_pid: selectedCategory,
      });
      if (response?.return_code === 'SUCCESS') {
        setSubCategories(response.result || []);
      }
    } catch (error) {
      console.error('Error fetching subcategories:', error);
    }
  };

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await getState('/board/select_dictionary_group1');
        if (response?.return_code === 'SUCCESS') {
          setCategories(response.result || []);
        }
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    fetchCategories();
  }, []);

  useEffect(() => {
    const fetchDairyDic = async () => {
      if (!sjId) return;

      try {
        const response = await getState('/board/select_dictionary', {
          sj_id: sjId,
        });
        if (response?.return_code === 'SUCCESS' && response.result) {
          setFormData({
            sj_subject: response.result.sj_subject,
            sjc_pid: response.result.sjc_pid,
            sjc_id: response.result.sjc_id,
            sj_content: response.result.sj_content,
          });

          // Fetch subcategories if main category exists
          if (response.result.sjc_pid) {
            await handleCategoryChange({
              target: { value: response.result.sjc_pid },
            });
          }
        }
      } catch (error) {
        console.error('Error fetching dairy dictionary:', error);
      }
    };

    fetchDairyDic();
  }, [sjId]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.sj_subject) {
      alert('명칭을 입력해주세요.');
      return;
    }
    if (!formData.sjc_id) {
      alert('분류를 선택해주세요.');
      return;
    }

    try {
      const endpoint = sjId
        ? '/board/update_dictionary/'
        : '/board/insert_dictionary/';
      const response = await postState(endpoint, {
        ...formData,
        sj_id: sjId,
      });

      if (response?.return_code === 'SUCCESS') {
        queryClient.invalidateQueries(['dairyDics']);
        alert(sjId ? '수정되었습니다.' : '등록되었습니다.');
        navigate('/bo/board/dairyDic/list');
      }
      if (response?.return_code === 'ERROR') {
        alert(response?.return_message);
      }
    } catch (error) {
      console.error('Error saving dairy dictionary:', error);
      alert('저장 중 오류가 발생했습니다.');
    }
  };

  const handleDelete = async () => {
    if (!window.confirm('삭제 하시겠습니까?')) return;

    try {
      const response = await postState('/board/delete_dairy_dic/', {
        sj_id: sjId,
      });
      if (response?.return_code === 'SUCCESS') {
        queryClient.invalidateQueries(['dairyDics']);
        alert('삭제되었습니다.');
        navigate('/bo/board/dairyDic/list');
      }
      if (response?.return_code === 'ERROR') {
        alert(response?.return_message);
      }
    } catch (error) {
      console.error('Delete dairy dictionary error:', error);
      alert('삭제 중 오류가 발생했습니다.');
    }
  };

  return (
    <div className="dairy-dic-edit bo-content">
      <h2>낙농사전 {sjId ? '수정' : '등록'}</h2>
      <div className="table-container bold-border">
        <table>
          <tbody>
            <tr>
              <th>
                명칭<span className="required">*</span>
              </th>
              <td className="nr">
                <input
                  type="text"
                  value={formData.sj_subject}
                  onChange={(e) =>
                    setFormData({ ...formData, sj_subject: e.target.value })
                  }
                  maxLength={100}
                />
              </td>
            </tr>
            <tr>
              <th>
                대분류<span className="required">*</span>
              </th>
              <td className="nr">
                <select
                  value={formData.sjc_pid}
                  onChange={handleCategoryChange}
                >
                  <option value="">==전체==</option>
                  {categories.map((category) => (
                    <option key={category.sjc_id} value={category.sjc_id}>
                      {category.sjc_name}
                    </option>
                  ))}
                </select>
              </td>
            </tr>
            <tr>
              <th>
                소분류<span className="required">*</span>
              </th>
              <td className="nr">
                <select
                  value={formData.sjc_id}
                  onChange={(e) =>
                    setFormData({ ...formData, sjc_id: e.target.value })
                  }
                >
                  <option value="">==전체==</option>
                  {subCategories.map((subCategory) => (
                    <option key={subCategory.sjc_id} value={subCategory.sjc_id}>
                      {subCategory.sjc_name}
                    </option>
                  ))}
                </select>
              </td>
            </tr>
            <tr>
              <th>내용</th>
              <td className="nr">
                <textarea
                  rows="10"
                  value={formData.sj_content}
                  onChange={(e) =>
                    setFormData({ ...formData, sj_content: e.target.value })
                  }
                  style={{
                    width: '100%',
                    resize: 'none',
                  }}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="button-group">
        <button type="button" onClick={handleSubmit} className="btn-l">
          {sjId ? '수정' : '등록'}
        </button>
        {sjId && (
          <button type="button" onClick={handleDelete} className="btn-l">
            삭제
          </button>
        )}
        <button
          type="button"
          onClick={() => navigate('/bo/board/dairyDic/list')}
          className="btn-l"
        >
          목록
        </button>
      </div>
    </div>
  );
}

export default DairyDicEdit;
