import React from 'react';

import Pic543 from 'assets/images/sub/_images/pic543.jpg';
import Pic544 from 'assets/images/sub/_images/pic544.jpg';
import Pic545 from 'assets/images/sub/_images/pic545.jpg';
import Pic546 from 'assets/images/sub/_images/pic546.jpg';

function Do99() {

  return (
    <div className="techinfoview">
      <h1>아밀로이드 증</h1>
      <p className="pstyle">「아밀로이드」라 불리우는 일종의 단백(蛋白)이 여러 장기의 혈관이나 그 주의 조직에 달라붙어 그들 장기(臟器)의 기능장애를 초래하는 질병이다. </p>
      <div className="leftpart">
        <h2>원인</h2>
        <ul>
          <li>아밀로이드라 불리우는 이상단백(異常蛋白)이 어떻게 만들어지는가는 아직 잘 알려져 있지 않다. </li>
        </ul>
        <h2>증상</h2>
        <ul>
          <li>젖소에서는 5세이상의 성우에 발생이 많다. 
            <ul>
              <li>식욕부진을 동반하는 물같은 설사변을 배설한다. </li>
              <li>설사 치료를 아무리 해도 잘 회복되지 않는 것이 이 병의 특징이다. </li>
              <li>지속되는 설사로 탈수증이 생기며 쇠약해진다. </li>
              <li> 흉수 하복부에 부종이 생기며 점차 뚜렷해진다.</li>
              <li> 체온은 정상이며 맥박수, 호흡수도 변화가 없다. </li>
              <li>오줌을 검사해보면 대량의 단백이 함유되어있다. </li>
            </ul>
          </li>
        </ul>
        <h2>목장에서의 처치</h2>
        <ul>
          <li>물을 제한하면 탈수가 더욱 심해지므로 자유롭게 물을 마실수 있게 한다.(그밖에는 특별한 방법이 없다) </li>
          <li>현재로서는 특별한 약이 개발되어 있지 않다. </li>
          <li>발견되면 육질이 악화되기 전에 즉시 도태한다.</li>
        </ul>
      </div>
      <div className="rightpart">
        <img src={Pic543} className="nonborder" alt=""/>
        <img src={Pic544} className="nonborder" alt=""/>
        <img src={Pic545} className="nonborder" alt=""/>
        <img src={Pic546} className="nonborder" alt=""/>
      </div>
    </div>
  );
}

export default Do99;