import React from 'react';
import GirlcowAbility from 'containers/milk/girlcowability/GirlcowAbility';

function GirlcowAbilityPage() {
  return (
    <GirlcowAbility />
  );
}

export default GirlcowAbilityPage;
