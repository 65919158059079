import React from 'react';

function TableCowInfo({ cowInfo }) {
  return (
    <div className="tablebox cowInfoTable">
      <table>
        <tbody>
          <tr>
            <th>명호</th>
            <td colSpan="3">{cowInfo.CNNAME}</td>
          </tr>
          <tr>
            <th>목장명</th>
            <td>{cowInfo.FNAME}</td>
            <th>축주명</th>
            <td>{cowInfo.DNAME_S}</td>
          </tr>
          <tr>
            <th>현산차</th>
            <td>{cowInfo.PARI}</td>
            <th>관리번호</th>
            <td>{cowInfo.CSNAME}</td>
          </tr>
          <tr>
            <th>생년월일</th>
            <td>{cowInfo.BIRTH}</td>
            <th>지역</th>
            <td>{cowInfo.ADDR1}</td>
          </tr>
          <tr>
            <th>등록번호</th>
            <td>{cowInfo.REGNO}</td>
            <th>바코드</th>
            <td>{cowInfo.BARCODE}</td>
          </tr>
          <tr>
            <th>부</th>
            <td>{cowInfo.FSEMENCODE}</td>
            <th>모</th>
            <td>{cowInfo.MREGNO}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default TableCowInfo;
