import React from 'react';
import { Outlet } from 'react-router-dom';

function ImproveInfo() {
  return (
    <div className="bo-page">
      <Outlet />
    </div>
  );
}

export default ImproveInfo;
