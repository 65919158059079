import React, { useState } from 'react';
import { Link } from 'react-router-dom'; // Make sure to import Link
import './videogallery.scss';

import { FaEllipsisH } from 'react-icons/fa';
import youtube from 'assets/images/containers/main/youtube.png';

const videos = [
  {
    title: 'Video 1',
    videoId: 'EehZk4_vQaM',
    icon: youtube,
  },
  {
    title: 'Video 2',
    videoId: 'qbyen8GtVvc',
    icon: youtube,
  },
  {
    title: 'Video 3',
    videoId: 'iiP3obPaIxM',
    icon: youtube,
  },
];

const SocialMediaGallery = () => {
  const [currentVideo, setCurrentVideo] = useState(null);

  const handleThumbnailClick = (videoId) => {
    setCurrentVideo(videoId);
  };

  return (
    <div className="video-con">
      <div className="social-media-gallery-container">
        <div className="video-header">
          <Link to="/Web/Info/PromotionalVideo" className="more-icon-link">
            <h2>
              소셜 <span>미디어</span>
            </h2>
          </Link>
          {/* Wrap the icon with Link to navigate to the promotional video page */}
          <Link to="/Web/Info/PromotionalVideo" className="more-icon-link">
            <FaEllipsisH className="more-icon" />
          </Link>
        </div>
        <div className="thumbnails-container">
          {videos.map((video, index) => (
            <div
              role="button"
              tabIndex={index}
              key={video.videoId}
              className="thumbnail"
              onClick={() => handleThumbnailClick(video.videoId)}
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  handleThumbnailClick(video.videoId);
                }
              }}
            >
              {currentVideo === video.videoId ? (
                <iframe
                  className="iframe"
                  width="560"
                  height="315"
                  src={`https://www.youtube.com/embed/${video.videoId}?autoplay=1`}
                  frameBorder="0"
                  allow="autoplay; encrypted-media"
                  allowFullScreen
                  title={video.title}
                />
              ) : (
                <>
                  <img
                    src={`https://img.youtube.com/vi/${video.videoId}/0.jpg`}
                    alt={video.title}
                  />
                  <div
                    className="overlay-icon"
                    style={{ backgroundImage: `url(${video.icon})` }}
                  ></div>
                </>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SocialMediaGallery;
