import React from 'react';

import pic398 from 'assets/images/sub/_images/pic398.gif';
import pic399 from 'assets/images/sub/_images/pic399.gif';
import pic400 from 'assets/images/sub/_images/pic400.gif';
import pic401 from 'assets/images/sub/_images/pic401.gif';
import pic402 from 'assets/images/sub/_images/pic402.gif';
import pic403 from 'assets/images/sub/_images/pic403.gif';
import pic404 from 'assets/images/sub/_images/pic404.gif';

function Do213() {

  return (
    <div className="techinfoview">
      <h1>착유장의 건축</h1>
      <p>착유장은 착유자의 활동, 소의 관찰 및 이동, 위생, 화재위헌, 온도 및 습도조절을 고려하여 설계하여야 한다. 적합한 재료를 이용하여 잘 건축하면 관리비용을 줄일 수 있고 운영을 효율적으로 할 수 있다. </p>
      <ul>
        <li>벽
          <ul>
            <li>일반적으로 기둥을 세워 벽을 만들고 섬유 유리솜으로 단열처리를 한다. 단열처리를 하지 않을 경우에는 벽돌을 쌓아 벽을 만드는 것이 견고하고 경제적이며, 벽의 두께는 4정도로 한다. 안쪽 벽면에 방수 페인트를 칠하거나 한 쪽면이 타일 처리된 벽돌을 이용하여 벽을 만들면 습기가 스며들지 못하고 청소하기 용이하다. 물론, 기둥식과 벽돌식을 혼용해서 벽을 만들 수도 있다. </li>
          </ul>
        </li>
        <li>안쪽 벽면의 처리 
          <ul>
            <li>착유실과 우유 저장실의 벽면은 1)쉽게 청소할 수 있어야 하고 2)세제와 산성용액에 견딜 수 있어야 하며 3)방수가 되며 4)견고해야 한다.  섬유유리로 강화된 플라스틱을 합판에 붙인 것이 벽면처리 재질로 좋으며, 비싸지만 청소하기 용이하고, 견고하고, 관리비용이 적게 소요되므로 투자가치가 있다. 벽면의 모든 이음새는 봉해야 하고, 바닥에서 36&quot;높이의 벽면에 가로책을 설치하면 소가 많이 왕래하는 지역의 벽면을 보호할 수 있다. 벽돌벽의 경우에 벽면처리는 상기에 언급된 것처럼 하면 된다. </li>
          </ul>
        </li>
        <li>단열처리 
          <ul>
            <li>단열처리 정도는 폐쇄식 우사의 경우처럼 하면 되는데, 폴리에틸렌(4-6 mil)을 이용하여 방습막(vapor barrier)을 설치한다. </li>
          </ul>
        </li>
        <li>바 닥 
          <ul>
            <li>콘크리트바닥은 견고하고, 청소하기 쉬우며, 방수도 되고, 안전하다. 바닥에 쓰이는 콘크리트의 압력강도는 최소한 4,000 psi가 되어야 한다. 콘크리트바닥에 특별한 처리를 할 필요는 없지만 착유장비와 저유조 우유배출구 아래 바닥에 타일을 박아서 콘크리트의 부식을 방지할 수 있다. 또한, 착유우상의 바닥에 검정색 타일을 박아서 처음 우유를 바닥으로 짜서 유방염을 검사하는데 이용할 수 있다. </li>
          </ul>
        </li>
        <li> 배 수 
          <ul>
            <li>그림 68에서 보는 바와 같이 물로서 배수구가 폐쇄되는 배수구(Deep-water-seal trap drain)는 유속이 빠르고, 연속적이며, 가스의 역류를 막아 준다. 배수구는 착유실과 우유저장실의 중앙에는 설치하지 말아야 하며 배수가 잘 되도록 물받이 통로나 구석에 설치하도록 한다. 배수구는 바닥이나 물받이 통로 보다 1/2만큼 낮게 위치하여야 하며, 벽쪽 배수구의 폭은 2-6 중앙에 설치할 때는 8-12로 하며, 바닥의 기울기는 두방향 이상으로 되지 않게 한다. (그림 69) <br/>우유저장소의 배수구는 착유장비와 저유조로 인하여 발생되는 폐수를 모두 처리할 수 있어야 한다. 저유조나 우유배출구 밑에 배수구설치는 삼가해야 한다. 청소하기 용이하도록 착유우상 바닥은 물받이 통로 쪽으로 경사지게 하며, 물받이 통로 바닥은 배수구 쪽으로 경사지게 해야 한다.(그림 70a와 그림 70b ) 전체 착유장의 배수시스템의 예가 그림 71에 나타나 있다. </li>
          </ul>
        </li>
        <li> 조 명 
          <ul>
            <li>바닥면적 100ft2 당 2줄의 형광등(4-40W)을 설치한다. 형광등의 설치는 2개의 형광등을 끼울수 있도록 되있는 고정틀을 이용한다. 착유실의 형광등은 착유실 길이를 따라서 두줄로 계속적으로 연결시켜 설치하며, 착유우상으로부터 12 높이로 착유자 공간위에 위치하도록 한다.  우유저장실에는 각각의 시설물 바로 위에 형광등을 설치하여 시설물 점검시 이용하도록 한다. 세척물이 들어가거나 소와 접촉하는 것을 방지하기 위하여 바닥으로부터 5&quot; 뚜껑이 있는 전기 콘센트를 설치한다. </li>
          </ul>
        </li>
        <li>공 전(stray boltage) 
          <ul>
            <li>접촉하고 있는 두 면에 작은 전압의 차가 생기는 것을 공전이라고 한다. 가장 보편적인 예는 그림 72에 나타난 바와 같이, 소가 접촉하고 있는 급수컵과 우상바닥 사이에 작은 전압차이가 생겨 전류가 소의 몸을 통해 바닥으로 흐른다. 소의 몸안으로 0.5볼트 이상의 전류가 흐르면 산유량이 심하게 감소한다. 공전문제가 발생하면, 적절한 장비를 이용하여 공전의 원인을 찾아낼 수 있다. 공전문제가 생기면 한전이나 축사전기시설에 익숙한 전기공에게 연락하여 문제를 해결해야 한다.<br/>
              <img src={pic398} width="40%" alt=""/>
              <p>그림 68. 물을 이용한 폐쇄식 배수구</p>
              <img src={pic399} width="40%" alt=""/>
              <p>그림 69. 우유저장실의 바닥기울기</p>
              <img src={pic400} width="40%" alt=""/>
              <p>그림 70. 착유시설의 배수체계</p>
              <img src={pic401} width="40%" alt=""/>
              <p>그림 71. 착유종합시설의 배수체계</p>
              <img src={pic402} width="60%" alt=""/>
              <p>그림 72. 공전의 예</p>
              <ul>
                <li>공전증세 
                  <p>공전의 충격은 산유량이 감소하고 유방염 발생의 원인이 된다. 공전은 다음과 같이 가축의 행동에 변화를 가져온다. </p>
                  <ul>										
                    <li>착유중 불안해 함</li>
                    <li>착유실에 진입을 회피 </li>
                    <li>급수시설 이용을 꺼림 </li>
                    <li>사료섭취량의 감소</li>
                    <li>우유 분비장애(poor milk letdown) </li>
                  </ul>									
                  <p>이러한 가축의 행동변화는 착유실의 고장, 착유시간의 변화, 부패사료의 급여, 또는 수질오염이 원인이 될 수 있으므로, 이들에 대한 문제점도 조사하여야 한다. </p>
                </li>
                <li>공전의 원인 
                  <p>공전문제는 목장 내 또는 밖에서 발생되는데, 공전발생 원인은 다음과 같다.<br/><br/>목장내 발생원인 </p>
                  <ul>
                    <li>접지 불량 </li>
                    <li>접지를 중립회로로 사용하거나, 중립회로를 접지로 사용할 경우 </li>
                    <li>전기울타리에 누전이 발생할 경우</li>
                  </ul>
                  <p>목장외 발생원인 </p>
                  <ul>
                    <li> 1차 중립에 전압이 발생할 경우 </li>
                    <li>이웃의 접지불량 </li>
                    <li>외부 전기배선체계 불량 </li>
                  </ul>
                  <p>적절한 장비를 갖춘 전기기술자로 하여금 공전원인을 규명토록 하여 문제를 해결하여야 한다. </p>
                </li>
                <li>접지불량에 의한 안전사고 <br/>
                  <p className="pstyle">일반적으로 목장관리자는 접지에 대하여 소홀한 경향이 있다. 접지불량은 공전문제를 발생시킬 뿐만 아니라 안전사고를 일으킬 수 있기 때문에 유의하여야 한다. 모든 전기장비나 장치가 정상적으로 작동할 경우에는 접지가 불량하더라도 문제가 발생하지 않으나, 모타 등에 결함이 발생하였을 경우, 접지상태가 불량하면 사람이나 가축이 전기충격을 받아 생명을 잃을 수 있다. 이러한 경우는 공전문제라기보다는 안전문제이다. 만약 관리자가 전기장치를 가동시킬 때나 금속부분을 접촉할 때 전기 충격을 느낀다면, 접지에 문제가 발생하였을 가능성이 있으며, 즉시 전기기술자에게 연락하여 문제를 해결하도록 한다. </p>
                </li>
                <li>등전위(equipotential plane)와 전압 경사로(voltage ramp)의 설치
                  <p className="pstyle">&nbsp;&nbsp;&nbsp;착유실 또는 대기장을 신축할 경우, 그림 73과 같이 등전위 바닥 및 전압 경사로를 설치하는 것이 바람직하다. 이러한 시설의 설치는 모든 바닥과 착유장비에 똑같은 전압이 유지되도록 하여, 젖소접촉 부위 사이에 전압차가 생기지 않게 한다. 따라서, 등전위바닥의 설치는 착유장에서 발생될 수 있는 공전현상을 방지하는 최선의 방법이라 할 수 있다. 물론 기존의 착유실에 이러한 시설을 설치할 수 있지만, 설치비가 많이 든다. 후리스톨 우사에는 이러한 시설을 설치할 필요가 없다. <br/>&nbsp;&nbsp;&nbsp;등전위 바닥을 설치할 때는 ①6×6, 10ga 용접된 철망과 15˝ o.c. 격자형태의 강철봉을 사용하며, ②콘크리트를 붓기 전에 강철봉을 모든 착유실보조대에 연결시키고, ③입선판에 접지장비를 설치한다. <br/>&nbsp;&nbsp;&nbsp;착유실보조대에 강철봉을 연결할 때는 클램프형태의 연결장비(clamp type connector)를 사용하며, 접지에 AWG 6- CU를 사용한다. 그림 74와 같이 등전위 바닥에 젖소가 발로 접촉하는 모든 곳에 전압 경사로를 설치한다. 나머지 부분보다 등전위 바닥의 전압이 높을 경우, 등전위 바닥을 따라 젖소가 움직임에 따라 전압 경사로는 점진적으로 전압을 상승시키는 역할을 한다. 따라서, 전압 경사로를 설치하지 않으면, 젖소가 전기충격을 받게 되므로 등전위 바닥에는 전압 경사로를 반드시 설치하여야 한다.</p>
                  <img src={pic403} width="60%" alt=""/>
                  <p>그림 73. 착유실내 등전위바닥 설치</p>
                  <img src={pic404} width="60%" alt=""/>
                  <p>그림 74. 전압경사로와 등전위바닥의 설치</p>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
}

export default Do213;