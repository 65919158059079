import React from 'react';

import d2by19 from 'assets/images/sub/_images/2-19.jpg';
import d2by23 from 'assets/images/sub/_images/2-23.jpg';
import d2by25 from 'assets/images/sub/_images/2-25.gif';
import d2by27 from 'assets/images/sub/_images/2-27.jpg';

function Fo27() {

  return (
    <div className="techinfo_view">
				<p className="viewTitle">액상사료를 주는 방법</p>
				<h2>생후 4일째부터의 액상 사료</h2>
				<ul className="listStyle04 mg15f">
					<li>초유 또는 발효초유가 남아 있는 사이는 계속 주어 충분히 활용한다
						<ul>
							<li>: 싼 가격으로 하리가 적다</li>
						</ul>
					</li>
					<li>액상 사료의 1회 급여량은 2ℓ정도</li>
					<li>1일 급여량은 4ℓ(2ℓ×2회) ---------------정량 방식
						<ul>
							<li>: 1일 급여량이 적으면 영양부족으로 발육불량, 질병이 발생하기 쉽다</li>
							<li>: 1일 급여량이 많으면 인공유의 채식이 늦어진다</li>
						</ul>
					</li>
					<li>온도는 체온정도가 바람직하지만 실온 정도라도 좋다
						<ul>
							<li>: 체온까지 가온하지 않아도 하리의 원인으로는 되지 않는다</li>
						</ul>
					</li>
				</ul>
				<div className="imgBox mg60f"><img src={d2by19} alt=""/></div>
				
				<h2>액상 사료의 종류</h2>
				<ul className="listStyle04 mg15f">
					<li>초유 - 분만후 착유2회까지 생유, 고영양, 하리는 하기 어렵다.(면역Globulin함유)</li>
					<li>발효초유 - 초유를 자연 발효시킨 것. 고양양, 하리는 하기 어렵다.(면역Globulin함유)</li>
					<li>전유 - 착유직후의 우유라면 가장 시간이 걸리지 않는다.</li>
					<li>대용유 - 탈지분유 주체로 우유와 같은 정도의 영양가. 우유보다 싼 가격(가공원료유가의 75%정도.) 분말 상태이므로 온탕에서 녹여서 급여한다.</li>
				</ul>
				<table className="tablestyle">
					<caption>액상 사료의 성분</caption>
					<thead>
						<tr>
							<th className="first"> </th>
							<th>* 초유(初乳)</th>
							<th>* 보통 우유</th>
							<th>** 대용유(A社)</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>지방</td>
							<td>10.0 ∼ 4.0</td>
							<td>3.0 ∼ 4.0</td>
							<td>3.6이상</td>
						</tr>
						<tr>
							<td>단백질</td>
							<td>14.0 ∼ 4.0</td>
							<td>2.8 ∼ 3.5</td>
							<td>3.5이상</td>
						</tr>
						<tr>
							<td>유당</td>
							<td>3.5 ∼ 4.8</td>
							<td>4.5 ∼ 5.0</td>
							<td>-</td>
						</tr>
						<tr>
							<td>회분</td>
							<td>1.3 ∼ 0.0</td>
							<td>0.7</td>
							<td>1.4이하</td>
						</tr>
					</tbody>
				</table>
				<ul className="refer mg10t mg30f">
					<li>** 7배로 용해한다.</li>
				</ul>
				
				<div className="tableWrap">
					<table className="tablestyle">
						<caption>액상 사료의 성분</caption>
						<thead>
							<tr>
								<th className="first" rowSpan="2"> </th>
								<th rowSpan="2">공식두수</th>
								<th colSpan="2">하리</th>
								<th colSpan="2">포유거부</th>
							</tr>
							<tr>
								<th>발생두수</th>
								<th>자우1두당 평균 일수</th>
								<th>발생두수</th>
								<th>자우 1두당 평균 회수</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>발효초유</td>
								<td>29</td>
								<td>8</td>
								<td>0.45</td>
								<td>3</td>
								<td>0.43</td>
							</tr>
							<tr>
								<td>대용유</td>
								<td>13</td>
								<td>8</td>
								<td>1.77</td>
								<td>0</td>
								<td>0</td>
							</tr>
						</tbody>
					</table>
				</div>
				<ul className="refer mg60f" style={{marginTop: '-20px'}}>
					<li>(昭和:쇼와 53년 북해도 농무부 지도참고)</li>
				</ul>
				
				<h2>전유(全乳)를 주는 단점, 장점은?</h2>
				<ul className="listStyle01 mg15f">
					<li>장점
						<ul className="listStyle04">
							<li>가장 이상적인 액상사료</li>
							<li>생력적(특히 착유시에 급여할 때.)</li>
							<li>3주령 이하의 자우에서는 전유의 쪽이 하리가 적은 것 같다</li>
						</ul>
					</li>
					<li>단점
						<ul className="listStyle04">
							<li>가격이 비싸다.(대용유는 생유의 75% 정도.)</li>
						</ul>
					</li>
				</ul>
				<div className="imgBox mg60f"><img src={d2by23} alt=""/></div>
				
				<h2>대용유의 만드는 방법</h2>
				<ul className="listStyle04 mg15f">
					<li>농도는 생유와 동일한 것이 원칙(생유는 건물 12%)</li>
					<li>[ 40℃의 온탕 6 : 대용유 1 ] 의 배율로 녹인다</li>
					<li>2ℓ 급여의 경우는 [ 온탕 1.7ℓ + 대용유 300g ]</li>
					<li>대용유의 지방구를 좋게 녹이기 위해서는 온탕이 필요</li>
				</ul>
				<div className="imgBox mg60f"><img src={d2by25} alt=""/></div>
				
				<h2>포유는 정온에서 ! 정시(定時)에!</h2>
				<ul className="listStyle04 mg15f">
					<li>좋은 온도는 체온(38℃)</li>
					<li>실온(15℃)으로부터 체온의 범위에 있으면 하리등의 원인에는 되지 않는다(저온이면 Energy lose(에너지 손실)이 있을지도 모른다.)</li>
					<li>대용유 급여의 경우는 40℃ 온탕이 바람직하다(지방분이 녹기 쉽다.)</li>
					<li>액상사료를 깨닫게 하기 위해서 일정한 온도가 바람직하다</li>
					<li>급여 시간의 불규칙은 스트레스(Stress), 종합적인 채식량의 저하로 이어진다</li>
				</ul>
				<div className="imgBox mg60f"><img src={d2by27} alt="" title="인간측의 사정으로 인한 불규칙 급여는 좋지 않음" /></div>
				
				<h2>포유는 몇회가 적당한가? </h2>
				<ul className="listStyle01 mg15f">
					<li>포유회수는 1일 1·2·3회의 방법이 있다
						<ul className="listStyle04">
							<li>1회
								<ul>
									<li>: 21일 이유의 경우, 6일째부터 사용할 수 있다</li>
									<li>: 1회의 급여량은 3ℓ가 상한(上限)하기 위해 몸의 유지정도의 영양섭취가 되어 증체가 늦어진다.(한냉시의 옥외 사육은 무리)</li>
								</ul>
							</li>
							<li>2회
								<ul>
									<li>: 가장 일반적, 42일 이유의 경우에 이용할 수 있다</li>
									<li>: 2ℓ × 2회 + 인공유의 체계에는 인공유의 채식, 증체도 양호하다</li>
								</ul>
							</li>
							<li>3회
								<ul>
									<li>: 채용자는 감소의 경향, 장기포유에 이용할 수 있는 것이 많다</li>
									<li>: 노력이 많이 들어 액상사료의 급여량이 많아지기 때문에 인공유의 채식은 늦어진다</li>
								</ul>
							</li>
						</ul>
					</li>
					<li>포유회수에 관계없이 1일 2회의 건강 체크는 필요</li>
				</ul>
				
				<div className="tableWrap">
					<table className="tablestyle">
						<caption>액상사료의 일급여회수 1978년 (戶數)</caption>
						<thead>
							<tr>
								<th className="first">생후일령<br/>일급여회수</th>
								<th>2주</th>
								<th>3주</th>
								<th>1개월</th>
								<th>2개월</th>
								<th>3개월</th>
								<th>4개월</th>
								<th>5개월</th>
								<th>6개월</th>
							</tr>
						</thead>
						<tfoot>							
							<tr>
								<td>계</td>
								<td>96</td>
								<td>96</td>
								<td>96</td>
								<td>95</td>
								<td>95</td>
								<td>88</td>
								<td>80</td>
								<td>64</td>
							</tr>
						</tfoot>
						<tbody>
							<tr>
								<td>3회</td>
								<td>30</td>
								<td>27</td>
								<td>26</td>
								<td>18</td>
								<td>12</td>
								<td>11</td>
								<td>10</td>
								<td>4</td>
							</tr>
							<tr>
								<td>2회</td>
								<td>66</td>
								<td>69</td>
								<td>70</td>
								<td>77</td>
								<td>83</td>
								<td>75</td>
								<td>68</td>
								<td>57</td>
							</tr>
							<tr>
								<td>1회</td>
								<td>-</td>
								<td>-</td>
								<td>-</td>
								<td>-</td>
								<td>-</td>
								<td>2</td>
								<td>2</td>
								<td>3</td>
							</tr>
						</tbody>
					</table>
				</div>
				
				<div className="tableWrap">
					<table className="tablestyle">
						<caption>액상사료의 일급여회수 1988년 (戶數)</caption>
						<thead>
							<tr>
								<th className="first">생후일령<br/>일급여회수</th>
								<th>2주</th>
								<th>3주</th>
								<th>1개월</th>
								<th>2개월</th>
								<th>3개월</th>
								<th>4개월</th>
								<th>5개월</th>
								<th>6개월</th>
							</tr>
						</thead>
						<tfoot>							
							<tr>
								<td>계</td>
								<td>49</td>
								<td>48</td>
								<td>49</td>
								<td>42</td>
								<td>28</td>
								<td>12</td>
								<td>5</td>
								<td>4</td>
							</tr>
						</tfoot>
						<tbody>
							<tr>
								<td>3회</td>
								<td>16</td>
								<td>7</td>
								<td>7</td>
								<td>5</td>
								<td>1</td>
								<td>-</td>
								<td>-</td>
								<td>-</td>
							</tr>
							<tr>
								<td>2회</td>
								<td>33</td>
								<td>41</td>
								<td>42</td>
								<td>35</td>
								<td>25</td>
								<td>10</td>
								<td>5</td>
								<td>4</td>
							</tr>
							<tr>
								<td>1회</td>
								<td>-</td>
								<td>-</td>
								<td>-</td>
								<td>2</td>
								<td>2</td>
								<td>2</td>
								<td>-</td>
								<td>-</td>
							</tr>
						</tbody>
					</table>
				</div>
				<ul className="refer" style={{marginTop: '-20px'}}>
					<li>(북해도 토카치 농협연조사, 쇼와 63년도 북해도 토카치 지정농업 개량보급원 축산부회 직장연수보고서)</li>
				</ul>
			</div>
  );
}

export default Fo27;
