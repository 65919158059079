import React from 'react';

function Fo40() {

  return (
    <div className="techinfo_view">
				<p className="viewTitle">제엽염</p>
				<dl>
					<dt>발병대상</dt>
					<dd>소, 말, 면양, 염소</dd>
				</dl>
				
				<dl>
					<dt>원인</dt>
					<dd>
						<ul>
							<li>1) 과다섭취와 갑작스런 곡류사료 섭취량의 증가</li>
							<li>2) 소화불량(장성중독증)</li>
							<li>3) 선천성 제엽염(foal founder)</li>
							<li>4) 양질 목초</li>
							<li>5) 진창증(road founder)</li>
						</ul>
						<p>등 여러가지 원인이 있다.</p>
					</dd>
				</dl>
				
				<dl>
					<dt>징후 및 증상</dt>
					<dd>극도의 고통과 고열(39.4-40℃) 및 활동 혐오등을 나타낸다. 가축은 계란 위를 걷는 것처럼 불안해하고 이 질병은 급성 또는 만성적으로 진행되며 다리를 변형시키어 절룩거리게 하며 이러한 변형이 계속되면 발굽뼈를 비틀리게 하고 발밑부분으로 뼈가 튀어나오게 된다.</dd>
				</dl>
				
				<dl>
					<dt>질병분포와 손실규모</dt>
					<dd>세계도처에 발생되며 이로 인하여 폐사하는 경우는 거의 없으나 만성적인 경우 가축을 못쓰게 만든다.</dd>
				</dl>
				
				<dl>
					<dt>치료</dt>
					<dd>
						<p>광범위하게 이용되고 있는 표준 치료 방법은 없지만 증세를 정확히 파악하고 있으면 다소 완화시킬 수는 있다. 급성 제엽염에 걸린 말의 치료방법에는 다음의 방법중 하나 또는 그 이상을 복합적으로 적용하고 있다.</p>
						<ul>
							<li>1) 미네랄 오일 : 과다하게 섭취한 사료를 하부소화기관으로 용이하게 통과시켜 주고 혈관내로의 유산과 내독소의 흡수를 막을 수 있다. 4.5리터의 미네랄 오일을 위 인입관을 통하여 넣어준다.(주의) : 폐렴 또는 분만 이상이 있는 경우에는 통변제를 사용하지 말아야 한다.</li>
							<li>2) 진통제를 투여한다.</li>
							<li>3) 항생제 : 조직의 괴사를 막을 수 있다.</li>
							<li>4) 항히스타제제 : 염증을 막는데 효과가 있다.</li>
							<li>5) 탄산나트륨(baking soda) : 염증을 유발시키는 산독성 물질을 중화시키는데 효과가 있다.</li>
							<li>6) 발에 일시적으로 신경을 죽이는 방법 : 말이 걸을 수 있도록 고통을 줄여주고 혈액순환을 원활하게 도와준다.</li>
							<li>7) 물비누 : 이전에 압박받은 혈관을 확장하기 위한 노력의 일환으로 혈액순환을 원활하게 하기 위하여 마사지하거나 자극하는 방법이다.</li>
							<li>8) 감염된 부위에 덮개 띄우기 : 상처난 발에 고통을 완화시키는 역할을 한다. 이것은 발굽뼈를 뒤틀리게 하여 발굽이 갈라지는 것을 어느 정도 막을 수 있다.</li>
						</ul>  
						<p>다른 방법으로는 때때로 cortisone(부신피질에서 분비되는 호르몬)과 메치오닌 및 함유황 아미노산을 사용하기도 한다.</p>
						<p>(주의) : 제엽염의 복잡성으로 인하여 위에 언급한 치료방법들로 효과가 없을 수 있다. 이 질병은 조기에 진단하여 완전히 치료하지 않으면 발굽뼈가 뒤틀리고 손상을 입어서 발굽조직이 다시는 회복될 수가 없다. 만성 제엽염의 치료방법으로는 뒷굽을 낮추어 주고 적절한 제각과 발바닥이 낮아지는 것을 막는 방법이 있다. 이 방법은 가죽 패드나 쇠로 만든 평평한 신발의 이용은 물론 숙련된 수의사에 의한 적절한 제각을 통해서 가능해 질 수 있다. 또한 부드러운 아크릴 플라스틱을 손상된 발굽과 뒤틀린 발굽뼈를 회복시키기 위하여 발바닥에 설치할 수 있다. 제엽염의 재발을 막기 위해선 체중조절이 매우 중요하며 또한 과다급여를 피하도록 하여야 한다. </p>
					</dd>
				</dl>
				
				<dl>
					<dt>대책</dt>
					<dd>발병 원인을 완화시킨다.</dd>
				</dl>
				
				<dl>
					<dt>예방</dt>
					<dd>1) 사료와 물(특히 여름철)의 과다섭취 및 분만후 자궁의 염증유발을 피할 것</dd>
					<dd>2) 12시간 이상 후산정체가 지속되면 수의적 치료를 행하여야 한다.</dd>
					<dd>3) 곡류 급여시 사양관리를 적절하게 행하면 소와 양 및 염소에서의 다양한 제엽염을 예방하는데 도움이 된다.</dd>
				</dl>	
				
				<dl>
					<dt>참고</dt>
					<dd>제엽염에 걸린 가축이 경제적 측면에서 그다지 큰 영향을 주지 않는다면 심한 경우 폐사시키는 것이 바람직하다. </dd>
				</dl>
			</div>
  );
}

export default Fo40;
