import React from 'react';

function Do227() {

  return (
    <div className="techinfoview">
      <h1>부대시설-전기시설</h1>
      <p className="pstyle">소요되는 전기용량은 사육규모와 사일리지 배출기, 배합사료기, 사료가공설비등과 같은 전기 장비의 크기에 따라 달라진다. <br/>적절한 배선을 위해서 지켜야할 사항은 다음과 같다.</p>
      <ul>
        <li>지역 한전과 협의하여 배전체계를 구축한다. </li>
        <li>규격에 맞는 전선을 올바르게 구입설치하고 규정을 준수하기 위해서는, 자격증을 소지한 전기기술자로부터 배선계획과 설치에 관하여 자문을 받는 것이 좋다. </li>
        <li>먼지유입이 안 되고 방수되는 조명등 고정틀과 습한 곳에 사용되는 전선을 구입할 때는 전기제품 공급업자와 의논하여 결정한다. 도매상에게 사전 주문을 해야 할지도 모르기 때문에, 어떠한 제품을 사용할 것인가를 미리 결정한다. </li>
        <li>보험회사와 의논하여 보험 적용범위를 결정한다. </li>
        <li>건축관련 부서로부터 자문을 구한다. </li>
      </ul>
      <p className="pstyle">가능하면, 충분한 회로 차단기를 갖춘 1개의 배전판을 마련한다. 부득이 여러 개의 배전판을 설치하여야 한다면, 배전판 앞에 주 차단스위치를 설치한다. 미국에서 적용되는 규정에 의하면, 축사시설에 사용되는 모든 전기설비는 방부, 방수 및 방진이 되어야 한다. Type UF 케이블이나 도관 처리된 전선만을 이용하여야 하며, 모든 전기설비에 표면 절연처리 하는 것은 권장할 만하다. </p>
      <h2>조명시설 </h2>
      <p>조명등은 다음과 같이 4가지 형태로 구분된다. </p><br/>
      <ul>
        <li>백열등 </li>
        <li>형광등 </li>
        <li>고조도 조명등(high intensity dischare; HID) </li>
        <li>텅스텐-할로겐등 </li>
      </ul><br/>
      <p>형태에 따라, ①조도, ②수명, ③색깔, ④효율 및 ⑤가격이 달라진다. 모든 조명등 고정틀은 방수가 되어야 한다. </p>
      <ul>
        <li>백열등 
          <ul>
            <li>백열등은 조명시간이 짧거나 점등과 소등을 자주 반복해야 하는 장소에 적합하다. 처음 설치비가 저렴하고, 모든 환경에서 잘 작동된다는 장점이 있으나, 효율이 상대적으로 낮아 전기비가 가장 많이 소모된다. 백열등 설치를 위해서는 방진 및 방수가 되는 고정틀과 열에 견디는 백열등 보호 유리가 필요하다. </li>
          </ul>
        </li>
        <li>형광등 
          <ul>
            <li>고정틀이 비싸기 때문에, 처음 설치비는 백열등에 비하여 많이 소요되지만, 조명효율은 백열등의 3-4배이다. 점등과 소등을 자주 반복하면 형광등의 수명이 짧아지기 때문에, 이러한 장소에는 백열등을 설치하는 것이 유리하지만, 1시간이상 점등을 요하는 장소에는 효율이 높은 형광등을 설치하는 것이 보다 경제적이다.  형광은 낮은 온도에 민감하기 때문에, 주로 옥내용으로 사용되는데 10℃까지의 온도에서 잘 작동된다. 특별 안정기를 사용할 경우에는 -20°F에서도 작동될 수 있다. 상대습도가 65% 이상인 경우에 점등이 잘 안된다. 고정틀은 부식되지 않는 재질로 만들고, 추운 곳에서도 점등될 수 있도록 특수 안정기가 부착된 것을 선택하도록 한다. 아크릴 확산기(diffuser)가 부착되고 ABC플라스틱으로 만들어진 고정틀은 축사환경에 적합하지 않다. </li>
          </ul>
        </li>
        <li>고조도 조명등 
          <ul>
            <li>고조도 조명등에는 수은, 금속 할라이드(halide), 고압나트륨, 또는 저압나트륨이 이용된다. 고조도 조명등은 수명이 길고 효율적이고 낮은 온도에서도 작동이 잘 되며, 빛에 색깔 (예; 수은등 = 청록색, 나트륨등 = 황금색)이 있다. 점등되는데 5-15분이 걸리므로 점등과 소등을 자주 해야 하는 곳에는 설치하지 않는다. 점등시간이 3시간 이상되고 최소한 16&apos;높이에 설치될 수 있다면, 고강도 조명등의 사용이 가장 효율적이다. 주로 사용되는 곳은 옥외사육장, 옥외보완용 및 후리스톨 우사이다. </li>
          </ul>
        </li>
        <li>텅스텐-할로겐등 
          <ul>
            <li>석영(quartz) 또는 석영-요오드(quartz iodine)라고 불리는 텅스텐-할로겐등은 백색광을 발산하며 효율적이다. 용도는 고조도 조명등과 비슷하지만 보다 빠르게 점등된다. 설치는 고조도 조명등의 경우에 준하면 된다. </li>
          </ul>
        </li>
      </ul>
      <h2>조도 </h2>
      <p className="pstyle">&nbsp;&nbsp;&nbsp;축사 및 가축 점검과 작업의 효율성을 높이기 위해서는 표 112에 나타난 바와 같이 조명등은 충분한 조도를 제공할 수 있어야 한다. 최소한 건물당 1개의 배선판을 설치하며 점등과 소등을 한 장소에서 조절할 수 있도록 각 실에 최소한 1개씩의 스위치를 입구 문에 설치한다. 일렬 조명등에 대한 스위치를 각기 분리 설치하면 조도 조절이 가능해진다. 여러개의 조명틀을 사용하는 대신에 조도가 높은 조명등을 적은 수의 고정틀에 설치할 수 있다. 그러나 고정틀수가 줄어듦에 따라 그림자가 형성되어 조도의 변이가 증가한다는 것을 염두에 주어야 한다. </p>
      <h2>조명등의 위치 선정 </h2>
      <ul>
        <li>후리스톨 우사
          <ul>
            <li>각통로의 중앙과 사료조 주위에 조명등을 설치한다. 특별한 일을 하거나. 어두운 구석을 비추거나 아니면, 그림자 지는 지역을 없애기 위하여 필요한 경우 조명등을 추가로 설치할 수 있다. 새의 배설물로 인하여 사료조나 급수조가 오염되는 때가 많기 때문에, 사료조나 급수조 바로위에 조명등을 설치하지 말아야 한다. 조명등 고정틀의 위치를 선정할 때는 항상 그림자가 적게 생기도록 위치선정을 고려해야 한다. </li>
          </ul>
        </li>
        <li>우리와 우상 
          <ul>
            <li>분만실 및 송아지 우리에는 각기 1개씩의 조명등을 설치하고, 스위치는 우리밖에 설치하며, 송아지 우리 위로 조명등을 한 줄로 설치한다.</li>
          </ul>
          <p><b>&lt;표 112&gt; 축사별 적정조도</b></p>
          <table className="tablestyle">
            <tr>
              <td>항목</td>
              <td colSpan="3">표준 형광등</td>
              <td colSpan="3">표준 백열등</td>
            </tr>
            <tr>
              <td rowSpan="2">등 수명, hr</td>
              <td>조도, 피트촉광</td>
              <td>40W</td>
              <td>100W</td>
              <td>150W</td>
              <td>수은등</td>
              <td>고조도조명등</td>
            </tr>
            <tr>
              <td>-</td>
              <td>10,000-20,000</td>
              <td>750</td>
              <td>750</td>
              <td>16,000</td>
              <td>16,000-18,000</td>
            </tr>
            <tr>
              <td></td>
              <td colSpan="6">---------- W/ft2 톤 ----------</td>
            </tr>
            <tr>
              <td>우사</td>
              <td>7</td>
              <td>0.20</td>
              <td>0.80</td>
              <td>0.70</td>
              <td>0.08</td>
              <td>0.04</td>
            </tr>
            <tr>
              <td>송아지 우사</td>
              <td>10</td>
              <td>0.28</td>
              <td>1.15</td>
              <td>1.00</td>
              <td>0.11</td>
              <td>0.06</td>
            </tr>
            <tr>
              <td>일반적인 착유시설</td>
              <td>20</td>
              <td>0.55</td>
              <td>2.29</td>
              <td>2.00</td>
              <td>0.22</td>
              <td>0.11</td>
            </tr>
            <tr>
              <td>젖소유방세척</td>
              <td>50</td>
              <td>1.38</td>
              <td>5.72</td>
              <td>5.00</td>
              <td>0.55</td>
              <td>0.28</td>
            </tr>
            <tr>
              <td>장비세척장소</td>
              <td>100</td>
              <td>2.75</td>
              <td>11.44</td>
              <td>10.00</td>
              <td>1.17</td>
              <td>0.55</td>
            </tr>
            <tr>
              <td>벌크탱크내부</td>
              <td>100</td>
              <td>2.75</td>
              <td>11.44</td>
              <td>10.00</td>
              <td>1.17</td>
              <td>0.55</td>
            </tr>
            <tr>
              <td>우유취급</td>
              <td>20</td>
              <td>0.55</td>
              <td>2.29</td>
              <td>2.00</td>
              <td>0.22</td>
              <td>0.11</td>
            </tr>
            <tr>
              <td>상차단</td>
              <td>15</td>
              <td>0.42</td>
              <td>1.72</td>
              <td>1.50</td>
              <td>0.17</td>
              <td>0.08</td>
            </tr>
            <tr>
              <td>젖소 관찰 및 취급</td>
              <td>20</td>
              <td>0.55</td>
              <td>2.29</td>
              <td>2.00</td>
              <td>0.22</td>
              <td>0.11</td>
            </tr>
            <tr>
              <td>사일로실</td>
              <td>20</td>
              <td>0.55</td>
              <td>2.29</td>
              <td>2.00</td>
              <td>0.22</td>
              <td>0.11</td>
            </tr>
            <tr>
              <td>채석장</td>
              <td>20</td>
              <td>0.55</td>
              <td>2.29</td>
              <td>2.00</td>
              <td>0.22</td>
              <td>0.11</td>
            </tr>
            <tr>
              <td>사료조</td>
              <td>10</td>
              <td>0.28</td>
              <td>1.15</td>
              <td>1.00</td>
              <td>0.11</td>
              <td>0.06</td>
            </tr>
            <tr>
              <td>사료저장 및 가공 장소</td>
              <td>10</td>
              <td>0.28</td>
              <td>1.15</td>
              <td>1.00</td>
              <td>0.11</td>
              <td>0.06</td>
            </tr>
            <tr>
              <td>건초저장소</td>
              <td>3</td>
              <td>0.09</td>
              <td>0.35</td>
              <td>0.30</td>
              <td>0.04</td>
              <td>0.02</td>
            </tr>
            <tr>
              <td>사무실</td>
              <td>50</td>
              <td>1.38</td>
              <td>5.72</td>
              <td>5.00</td>
              <td>0.55</td>
              <td>0.28</td>
            </tr>
            <tr>
              <td>화장실</td>
              <td>30</td>
              <td>0.83</td>
              <td>3.43</td>
              <td>3.00</td>
              <td>0.33</td>
              <td>0.17</td>
            </tr>
          </table>
        </li>
        <li>착유실 
          <ul>
            <li>착유피트의 양 측벽을 따라 일렬로 조명등을 설치한다. 각 착유우상에 조명등을 설치하며, 소의 통행로에 그림자가 생기지 않게 하고 눈이 부시지 않도록 조명등의 위치를 장 설정하여야 한다. </li>
          </ul>
        </li>
        <li>우유저장실 
          <ul>
            <li>각 저장조의 바깥 끝부분 위에 형광등을 설치하며, 바닥면적 100 ft2당 1개의 형광등을 설치한다. 고정틀의 길이는 4&apos;로 하며, 40W 형광등을 사용한다. 벌크탱크 배출구로부터 떨어진 위치에 형광등을 설치한다. </li>
          </ul>
        </li>
        <li>옥외 사료조 
          <ul>
            <li>평균 1피트 촉광으로 사료조 근처 30&apos;∼50&apos;을 밝힐 수 있는 조명시설을 마련해야 한다. 텅스텐-할로겐이나 고조도 조명등을 전봇대에 설치하는데 광전지(photocell)를 사용하여 저녁에 점등되고 새벽에 소등되도록 할 수 있다. </li>
          </ul>
        </li>
      </ul>
      <h2>예비동력</h2>
      <p className="pstyle">&nbsp;&nbsp;&nbsp;정전에 대비하여 예비 발전기를 마련해야 한다. 예비동력체계를 갖추기 위해서는 ① 동력라인으로부터 목장자체체계로 바꿔 주는 전환 스위치, ②전류를 생산하는 발전기, ③ 발전기를 가동시키는 고정엔진 또는 트랙터 동력인출장치가 필요하다. <br/>&nbsp;&nbsp;&nbsp;전부하 또는 부분부하 체계를 선택할 수 있는데, 전부하체계는 일부 주요시설인 착유, 우유냉각 및 기계식 사료급여에 사용되는 최대 가동부하와 최고 시동 부하를 감당할 수 있다. <br/>&nbsp;&nbsp;&nbsp;트랙터를 이용한 발전방법이 가장 보편적으로 사용되며, 가장 경제적인 방법이다. 그러나, 눈이 많이 쌓였을 경우에는, 트랙터를 발전기가 있는 곳으로 이동시키기 어려울 수 있다. 자동 스위치가 부착된 고정식 엔진을 이용하면 발전기를 신속하게 가동 시킬 수 있는 장점이 있다. 엔진으로 가동되는 발전기는 최소한 주 1회, 트랙터로 가동되는 발전기는 월 1회 점검을 실시하여야 한다. <br/>&nbsp;&nbsp;&nbsp;계량기 바로 다음에 있는 주전선 도입구에 전환스위치를 설치함으로서, 항상 인입 동력선으로부터 발전기가 격리되도록 하고, 정전 해제시 과다 전류로 인하여 발전기의 손상을 입지 않도록 한다. 전환 스위치의 용량과 설치는 전기회사의 조언을 받아 결정한다. <br/>&nbsp;&nbsp;&nbsp;예비동력의 요량을 결정하는 일은 쉽지 않다. 부분부하체계의 동력용량은 가장 큰 모터의 시동전력, 기타 모든 필수적인 모터의 가동전력, 필수 장비 및 조명에 소요되는 전력을 모두 합한 값이어야 한다. 향 후 확장과 장비 구입을 고려하여 충분한 용량의 예비동력을 갖추도록 한다. </p>
      <h2>전기울타리 </h2>
      <p>전기 울타리는 다음과 같은 경우에 효율적이고 경제적으로 이용한다. </p>
      <ul>
        <li>방목지를 관리하고 윤번으로 방목시킬 때 </li>
        <li>사료작물 재배지에 수확후 남은 곡류나 조사료를 섭취시킬 때 </li>
        <li>송아지와 처녀 소를 맹수로부터 보호할 때</li>
        <li>임시 통행로를 설치할 때 </li>
        <li>오래된 철사울타리의 수명을 연장시키고자 할 때 </li>
        <li>숫소우리나 방목울타리 밖으로 가축이 나가지 못하도록 할 때 </li>
        <li>밀집된 상태에서 울타리 밖으로 가축이 나가지 못하도록 할 때 </li>
        <li>운동장 울타리의 설치비를 줄이고자 할 때(10. 시설자재 부분 참조) </li>
      </ul>
      <p className="pstyle">&nbsp;&nbsp;&nbsp;전기 울타리는 태양열 충전기, 베터리, 또는 115V 전기시설에 의하여 전류가 공급된다. 전기를 사용할 수 있으면 115V 충전기를 사용할 수 있다. 울타리 길이에 따라 적절한 용량의 표준 충전기를 선택하여야 한다. <br/>&nbsp;&nbsp;&nbsp;울타리 충전기는 전류를 지속적으로 공급하지 않고, 분당 45∼55회 공급산하며, 매회 전류지속시간은 1/10초이다. 쇽크는 갑작스럽지만 짧으며, 상대적으로 해가 없으며 높은 전압(1,000∼2,000V)과 높은 전류(30∼40A)를 제공하는 충전기의 경우에는 전류발산을 분당 55회로 하고, 전류지속시간을 1/3,000,000초가 되도록 하여야 한다. <br/>&nbsp;&nbsp;&nbsp;반드시 방수되는 밀폐구조물 속에 충전기를 보관하여야 하며, 밀폐구조물과 스위치와 과다 전류 방지 장치가 있는 전기 시설박스는 건물로부터 최소한 10&apos;떨어진 곳에 설치하여야 한다. 충전기는 점검과 취급을 위한 접근이 가능하고 소가 접근하지 못하는 위치에 설치되어야 한다. <br/>&nbsp;&nbsp;&nbsp;충전기로부터 울타리와 접지로 연결되는 전선은 AWG 14 THW가 이용된다. 충전기의 양극단자에는 검정색 전선을, 음극단자에는 흰색 전선을 부착시킨다. 전기울타리와 울타리용 접지봉에 각각 검정색과 흰색 전선을 연결시키며, 전기울타리를 설치할 때는 접지 시설이 좋아야 하는 것이 필수 적이다. 접지봉의 클램프(clamp)는 땅에 직접 묻을 수 있어야 하며, 케이블 클램프를 사용해서는 안된다. 효율적인 접지를 의해서는 최소한 8&apos;크기의 접지봉을 이용하여야 하는데. 지면에서 6&quot;∼8&quot;깊이에 클램프가 위치하도록 하여 접지봉이 손상되지 않도록 한다. </p>
      <h2>번개로부터 보호 </h2>
      <p className="pstyle">&nbsp;&nbsp;&nbsp;전선의 전압급증을 제어하기 위하여 동력인입선 도입구를 피뢰기를 설치하여야 한다. 각 배전판에도 피뢰기를 설치하여야 하는데, 가까운 곳에서 강한 번개가 치면 피뢰기가 폭발하고 다량의 불꽃을 발산할 수 있기 때문에 옥외 배전판에 설치된 피뢰기에는 가능하면 덮개를 씌우도록 한다. 배전판 외에도, 착유제어기, 사료저울, 컴퓨터화된 사료급여시설, 컴퓨터 등과 같이 전기가 이용되는 장비에는 별도로 피뢰기를 설치한다. <br/>&nbsp;&nbsp;&nbsp;일반적으로 번개로부터 건물을 보호하기 위하여 피뢰침을 설치하는데, 지붕 박공(gable roof)으로부터 2&apos;이내에 20&apos;o.c 피뢰침을 설치 하며, 지붕과 벽에 3&apos;∼4&apos;간격으로 금속전도체를 부착한다. 길이가 최소한 8&apos;되는 접지봉을 건물로부터 2&apos;이상 떨어진 곳에 설치한다. 건물에는 알루미늄 피뢰침 케이블을 설치하며, 땅 밑(18&quot;이내)에는 구리 케이블을 설치한다. 접지봉과 케이블은 최소한 지면으로부터 8&quot;깊이에 설치해야 한다. 피뢰침용과 접지봉 사이에는 최소한 6&apos;거리가 유지되어야 하며, 두 접지봉을 연결시켜서는 안된다. <br/>&nbsp;&nbsp;&nbsp;외장이 급속으로된 건물인 경우에는 측면을 접지 시킴으로서 건물을 번개로부터 보호할 수 있지만, 대부분의 보험회사는 피뢰침 설치를 요구한다. 길이가 250&apos;인 건물에는 최소한 2개의 접지케이블을 설치하며, 길이가 100&apos;늘어남에 따라 접지케이블 1개를 추가로 설치하는 것이 좋다.</p>
    </div>
  );
}

export default Do227;