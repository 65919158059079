import React from 'react';
import GirlcowSelect from 'containers/milk/girlcowselect/GrilcowSelect';

function GirlcowSelectPage() {
  return (
    <GirlcowSelect />
  );
}

export default GirlcowSelectPage;
