import React from 'react';

import pic405 from 'assets/images/sub/_images/pic405.gif';
import pic406 from 'assets/images/sub/_images/pic406.gif';
import pic407 from 'assets/images/sub/_images/pic407.gif';
import pic408 from 'assets/images/sub/_images/pic408.gif';
import pic409 from 'assets/images/sub/_images/pic409.gif';
import pic410 from 'assets/images/sub/_images/pic410.gif';
import pic411 from 'assets/images/sub/_images/pic411.gif';

function Do214() {

  return (
    <div className="techinfoview">
      <h1>특수관리 및 치료시설</h1>
      <p className="pstyle">목장의 규모와 관계없이, 소를 다르고, 고정하고, 치료할 수 있는 시설을 마련하는 것은 효율적인 목장관리에 필수적이다. 이러한 특별 시설은 1) 한 사람의 관리자가 1두의 소나 1그룹의 소를 쉽게 관찰하고 이동시킬 수 있고, 2) 관리와 청소가 용이하며, 3) 환기가 잘되고, 4) 조명시설을 잘 갖추어야 한다. 특별관리 및 치료를 위한 시설을 선택하고 계획 하는 것은 관리 및 치료를 위한 시설을 선택하고 계획 하는 것은 관리자의 선호와 환경여건에 따라 결정된다. </p>
      <h2>특수관리를 요하는 우군 </h2>
      <p className="pstyle">&nbsp;&nbsp;&nbsp;비슷한 특징을 갖고 있어서 요구사항이 흡사한 소를 군분리하여 수용하면 효율적인 관리를 할 수 있다. 특별한 관리를 위하여 소를 ①분만 2∼3주전, ②분만, ③분만 후 0∼7일, ④건유초기, ⑤치료를 요하는 그룹으로 나눌 수 있다. 이들 그룹에 대해서는 자주 관찰하고, 청결한 환경을 조성해 주며, 고에너지 사료를 공급해 주어야 한다. <br/>&nbsp;&nbsp;&nbsp;전염병이 퍼지는 것을 최소화하기 위하여, 분만실은 다른 소, 특히 송아지로부터 멀리 떨어진 곳에 위치하는 것이 좋다. 개별 분만우리는 개체관리를 용이하게 하는데, 늘 청결을 유지하고, 마른 깔짚이 깔려 있어야 한다. <br/>&nbsp;&nbsp;&nbsp;분만 후부터 7일까지의 소는 후리스톨이나 깔짚이 깔려 있는 큰 우리에 분리 수용하며, 대규모 목장에서는 별도의 착유실 (파이프라인이나 이동식 착유기)을 갖추도록 하여, 개체별 사료섭취량과 산유량을 점검하여 정상적인 회복 여부를 관찰한다. <br/>&nbsp;&nbsp;&nbsp;자세한 관찰을 위하여 건유초기의 소도 분리하여, 건유초기에 합당한 사료를 급여한다. 장기치료를 요하는 소들도 분리 수용하여 사육하는 것이 좋다. </p>
      <h2>관찰 </h2>
      <p className="pstyle">&nbsp;&nbsp;&nbsp;어떤 축사시설이든, 관리자가 소를 쉽게 관찰할 수 있어야 하는데, 주요 관찰 지역은 착유실, 사료급여시설 주위, 운동장 및 분만실이다. 정기적인 관찰이 필요한 소는 분리 수용하여 점검이 쉽도록 한다.  </p>
      <h2>분리 </h2>
      <p className="pstyle">&nbsp;&nbsp;&nbsp;대기장 옆통로는 착유 후 소가 우사로 되돌아 갈 때 이용하는 통로인데, 이곳은 소를 점검하고 치료하기 위해 분리 선별하는 장소로 유용하게 사용될 수 있다. 사료급여 장소나 운동장에 있는 소는 문의 개폐를 통해 분리될 수 있다. <br/>&nbsp;&nbsp;&nbsp;질병의 전염을 줄일 수 있도록, 분만 및 치료를 위하여 개별 우리를 설치하며, 우리의 크기는 최소한 12`×12`나10`×14`가 되도록 한다. 분만 및 치료 우리는 20∼25두 및 50두당 가가 최소한 1개씩을 설치한다. 소를 고정시키기 위하여 1개 이상의 스탄을 각 우리에 설치한다. 분만우리 내의 스탄은 소를 고정하였을 때 소 후미의 공간이 충분하여 분만에 지장이 없도록 설치되어야 한다. <br/>&nbsp;&nbsp;&nbsp;그림에서 보는 바와 같이 소가 스탄이나 다른 스탄에 들어가지 않으려 할 때 게이트 (gate)를 이용하여 공간을 좁혀서 소를 스탄쉥 안으로 들여보낼 수 있다. 게이트의 재질이 약하면 쉽게 부서져 소나 관리자에게 외상을 입힐 수 있기 때문에 견고한 재질을 이용하여 설치하도록 한다. 게이트의 가로책 사이는 10˝ 이내로 하며, 소가 뛰어 넘지 못하도록 높이는 66˝이상으로 한다.</p>
      <img src={pic405} className="nonborder" width="60%" alt=""/>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;a. 분리게이트가 한쪽에만 있는 경우&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;b. 분리게이트가 양쪽에 있는 경우<br/>그림 75. 치료우사의 스탄촌과 게이트</p>
      <h2>치료장소 </h2>
      <ul>
        <li>우사내 치료 장소 
          <ul>
            <li>우사내에 인공수정, 분만 후 점검, 임신진단, 수술 및 환축치료를 위한 시설을 그림 76과 같이 마련할 수 있다. 소가 진입하는 통로에 전진방지 게이트(blocking gate)를 설치하여 치료중인 관리자로부터 다른 소들을 분리시켜야 한다. 치료장소 내에는 물품 보관 캐비넷과 치료약품을 올려놓을 수 있는 탁자가 있어야 한다. </li>
          </ul>
        </li>
        <li>치료우상 
          <ul>
            <li>그림 77과 같이 기존의 우상을 이용하여 치료 및 특수작업을 수행할 수 있는데, 양쪽에서 작업을 할 수 있는 우상을 선택하는 것이 좋다. 소를 들어 올릴 수 있도록 우상 중앙선을 따라서 천장에 고리 3개를 단단하게 고정시키며, 고리는 소의 어깨 및 꼬리부위를 들어올릴 수 있는 위치와 소 후미로부터 4`-6`떨어진 곳에 설치한다. 소를 더욱 견고하게 고정시킬 수 있도록 우상 정면에 위치한 벽면에 고리 1개를 설치한다. 소의 질식을 방지하는 가로책을 제거할 수 있도록 설치하는 것이 좋으며, 우상 뒤에 있는 분뇨구 통로를 6`길이의 뚜껑으로 덮는다.<br/>
              <img src={pic406} className="nonborder" width="60%" alt=""/>
              <p>&nbsp;&nbsp;&nbsp;&nbsp;그림 76. 우사 구석에 위치한 치료장소&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;그림 77. 기존우사을 이용한 치료장소</p>
            </li>
          </ul>
        </li>
        <li>대기장 옆통로에 위치한 치료장소 
          <ul>
            <li>착유실을 하루 중 일정한 시간만을 이용할 경우에, 그림 78에 나타난 바와 같이 대기장 옆에 있는 통로 쪽에 치료장소를 마련할 수 있다. 그러나 착유실을 소를 점검하고 치료하는 장소로 사용해서는 안된다.  대기장을 이용하는 경우, 그림 79와 80에서 보는 바와 같이 분리 게이트를 이용하여 착유실로부터 나오는 소를 선별 분리한다. 분리된 소가 고정될 수 있도록 치료장소 내에 여러개의 자가잠금 장치가 되어있는 스탄쉥을 설치한다. 일시 퇴장식 착유실의 경우에는 우상으로 되돌아가는 통로가 대기장 양쪽에서 분리하도록 해야 하지만 치료장소를 두군데에 설치할 필요는 없다. 착유실의 이용이 거의 대부분의 일과 시간을 차지하는 대규모 목장의 경우에는 그림 81과 같은 별도의 치료실을 마련하는 것이 효율적이다.  이 밖에도 자가 잠금장치가 되어있는 스탄이 사료조를 따라 개체별로 설치되어 있을 경우에는 이 곳을 치료장소로 이용할 수 있는데, 스탄에 소를 고정시켜 발정점검, 인공수정 및 임신 감정을 할 때 이용할 수 있다. </li>
          </ul>
        </li>
        <li>울타리스탄 
          <ul>
            <li>울타리 사료급여조를 따라 갱그락(ganglock)스탄을 설치함으로써, 소를 취급하고 관찰하며, 처치하는데 이용할 수 있고 운동장이나 방목지에도 스탄을 설치할 수 있다. 이러한 울타리 스탄은 발정확인, 수정 및 임신관찰 등에 이용될 수 있으며, 운동장이나 방목지의 스탄은 소에게 접근을 용이하게 하는데, 레버(lever)장치를 이용하여 동시에 잠금장치를 작동시킬 수 있다. 울타리 스탄의 장점은 다음과 같다. 
              <ul>
                <li>개체별 또는 그룹으로 잠금장치 사용가능 </li>
                <li>스탄 안으로 들어간 소가 머리를 숙일 때 자동적으로 잠금장치 작동 </li>
                <li>소의 방출이 용이</li>
                <li>소를 격리시키는 손쉬운 방법</li>
              </ul>
            </li>
          </ul>					
          <img src={pic407} className="nonborder" width="50%" alt=""/>
          <p>그림 78. 착유 종합 시설에 위치한 치료장소 </p>
          <img src={pic408} className="nonborder" width="50%" alt=""/>
          <p>그림 79. 착유 종합 시설에 위치한 치료장소</p>
        </li>
        <li>독립치료 시설 
          <ul>
            <li>착유가 대부분을 차지하는 목장에서는 그림 80과 같이 분리된 치료시설을 마련하는 것이 효율적이다, 유인통로에 소를 가두어 일상적인 예방접종을 실시 할 수 있으며, 방지게이트를 설치하여 소가 뒷걸음치는 것을 예방한다. 수의사가 소의 측면과 미부를 손쉽게 관찰할 수 있도록 고정우상은 비스듬히 설치하며, 소가 방출된 후에는 우사로 되돌아가거나 제각 테이블로 진입할 수 있도록 한다. 각기 다른 그룹을 위한 다양한 그룹 및 개체별 시설을 설치할 수 있는데, 후리스톨이나 루우즈 우사체계에서는 개체별 또는 소그룹으로 소를 다루는 것이 효율적이며, 그림 81에 나타난 바와 같이, 특별한 관리를 위하여 분리된 취급 및 처치장소를 마련하는 것이 바람직하다.</li>						
          </ul>
          <img src={pic409} width="40%" alt=""/>
          <p>그림 80. 치료 축사의 예(1)</p>
          <img src={pic410} width="40%" alt=""/>
          <p>그림 81. 치료 축사의 예(2)</p>
        </li>
        <li>분만장소 
          <ul>
            <li>갓 태어난 송아지가 다른 소아 접촉할 수 없도록 분만실은 분리시켜 설치하여야 하며, 규모가 큰 목장에서는 별도의 분만실을 마련하는 것이 효율적이다. 분만실에서는 소가 회전할 수도 있고 송아지를 잡아당기는 도구를 이용할 수 있는 공간이 확보될 수 있도록,12`×12` 혹은 10`×14`정도의 크기여야 한다. 20∼25두당 최소한 1개의 분만실을 설치하고 사료와 신선한 물을 각 우리에 공급할 수 있어야 한다,. 분만실 내의 스탄을 소가 대각선으로 설 수 있도록 구석에 설치하면 송아지가 분만시 차지하는 공간을 마련할 수 있다. 각각의 분만실 사이에 턱을 만들면 질병의 전파를 줄일 수 있으며, 가급적 트랙터나 중장비가 가까이 있는곳에 분만실을 두면 환축의 운반이 용이하다. </li>
          </ul>
        </li>
      </ul>
      <h2>헤드게이트와 보정틀(headgate and chutes)</h2>
      <p className="pstyle">&nbsp;&nbsp;&nbsp;헤드게이트는 소의 취급이나 치료를 용이하게 하기위하여 통로나 치료장소에 설치하는 것으로 직접 제조하거나 구입할 수도 있다. 헤드게이트는 자가 잠금장치가 되어있고 소의 크기에 따라 크기가 조절되며, 밑부분에서 윗부분까지 활짝 열리게 되어있어서 소가 다치지 않고 쉽게 빠져 나올 수 있어야 한다. 보정틀 (chutes)은 소가 좌우로 움직이지 못하게 하며, 옆문을 열어 소의 유방이나 복부를 쉽게 관찰할 수 있도록 해준다.  </p>
      <h2>상하차대 (loading chute)</h2>
      <p className="pstyle">&nbsp;&nbsp;&nbsp;소를 차량에 싣고 내리는 시간을 단축시키고, 소와 관리자의 손상을 방지할 수 있도록 하려면 상하차대를 설치해야 한다. 높이가 지면으로부터 12이하의 낮은 트레일러를 이용할 경우에는 경사로를 설치할 필요 없이 차량통로와 소를 몰아 넣을 수 있는 사이드게이트만 설치하면 된다. <br/>&nbsp;&nbsp;&nbsp;소를 차량에 실을 때는 상하차대를 이용하며, 싣기 전에 소를 대기시키는 우리가 필요하고, 소가 미끄러지지 않도록 상하차대닥에 미끄럼막이를 설치한다. 많은 두수의 소를 차량으로부터 쉽게 내리기 위해서는 넓은 장소가 필요한데, 큰 트럭이 이용될 경우에는 넓은 상하차대가 있어야 하며, 소가 옆으로 빠져나가지 않도록 경사로 측면에 5`∼6`높이의 가로책을 설치하여야 한다.</p>
      <h2>안전통로</h2>
      <p className="pstyle">&nbsp;&nbsp;&nbsp;문이나 울타리에 한 사람이 통과할 정도로 설치하는 통로로 그림 82에서 보는 바와 같이 폭이 12∼14정도가 되도록 한다. 소가 왕래하는 통로 25`∼30`당 1개를 설치하거나 한 우리에 최소한 1개의 안전통로를 설치하도록 한다.</p>
      <img src={pic411} width="20%" alt=""/>
      <p>그림 82. 안전통로</p>
    </div>
  );
}

export default Do214;