import React from 'react';
import Milk from 'containers/milk/Milk';

function MilkPage() {
  return (
    <Milk />
  );
}

export default MilkPage;
