import React from 'react';

import pic761 from 'assets/images/sub/_images/pic761.jpg';
import pic762 from 'assets/images/sub/_images/pic762.jpg';
import pic763 from 'assets/images/sub/_images/pic763.jpg';

function Do164() {

  return (
    <div className="techinfoview">
      <h1>고사리 중독</h1>
      <div className="leftpart">
      <p className="pstyle">고사리의 독성분에 의한 조혈장기(造血臟器), 특히 골수(骨髓)의 기능 장애로 인해 재생불량성 빈혈(再生 不良性貧血)을 일으키는 것이 특징이다. </p>
        <h2>증상</h2>
        <ul>
          <li>우리나라에서도 이미 제주도에서 그 피해의 발생보고가 있었다. 
            <ul>
              <li>눈의 결막, 질 점막, 구강 점막 등 외부에서 볼 수 있는 점막의 여러 곳에 좁쌀 크기의 출혈반(出血班)이 생겨난다. </li>
              <li>중증의 경우 이 부위에 궤양이 생기는 일도 있으며, 체온이 갑자기 40℃이상으로 올라가 정상으로 돌아오지 않는다. </li>
              <li>혈변, 혈뇨(血尿)의 배설을 보인다. 다만 경증(輕症)에서는 뚜렷한 변화가 없다.</li>
            </ul>
          </li>
        </ul>
        <h2>목장에서의 처치</h2>
        <ul>
          <li>고사리가 없는 방목지로 옮기고 사료중의 고사리를 제거한다. </li>
          <li>심한 운동을 삼가도록 한다. </li>
          <li>지혈제(비타민K등)를 주사한다. </li>
          <li>300∼2,000ml의 수혈을 실시한다. </li>
          <li>2차 감염을 방지하기 위해 항생물질을 투여한다. </li>
          <li>기타 대증요법으로서 강심제, 영양제, 건위 정장제 등을 투여한다.</li>
        </ul>
      </div>
      <div className="rightpart">
        <img src={pic761} className="nonborder" alt=""/>
        <img src={pic762} className="nonborder" alt=""/>
        <img src={pic763} className="nonborder" alt=""/>
      </div>
    </div>
  );
}

export default Do164;