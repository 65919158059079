import React from 'react';
import { Link , useNavigate , useLocation } from 'react-router-dom';

// 컴포넌트

// 이미지
import MilkLogo from 'assets/images/common/milkHeader_logo.png';

// scss
import './geneHeader.scss';

function GeneHeader() {

  const location = useLocation().pathname;
  const navigate = useNavigate();

  const logout = () => {

    if (window.confirm('로그아웃 하시겠습니까?')) {
      localStorage.removeItem('geneFarmCode');
      localStorage.removeItem('geneFarmPwd');
      
      navigate('/web/main');
    }
  };

  
  const onPopup = () => {
    const fname='inherited2.mrd';
    const congCode=localStorage.getItem('geneFarmCode').substring(0, 4);;
    const farmCode=localStorage.getItem('geneFarmCode').slice(-4);;    
    const queryParams = new URLSearchParams({
      fname,        
      cong_code:congCode,
      farm_code:farmCode      
    });
    const url = `${process.env.PUBLIC_URL}/crownix.html?${queryParams}`; 
    window.open(url, '_blank', 'noopener, noreferrer');
  };

  return (
    <header className='geneHeader'>
      <h1 className='hidden'>유전체 분석</h1>
      <nav className='geneNav'>
        <h2 className='hidden'>네비게이션 영역</h2>
        <ul>
          <li>
            <Link 
              to='geneResult'
              className={location.includes('geneResult') ? 'current' : ''}
            >
              유전체 분석 결과</Link>
          </li>
          <li>
            <Link 
              to='geneHowto'
              className={location.includes('geneHowto') ? 'current' : ''}
            >
              유전체 조회방법</Link>
          </li>
          <li>
            <Link 
              to='geneGrade'
              className={location.includes('geneGrade') ? 'current' : ''}
            >
              유전체 % 성적조회</Link>              
          </li>          
          
        </ul>
      </nav>        
      <button 
        type='button'
        onClick={onPopup}
        className='logoutBtn'
      >
              유전체분석 보고서 보기
      </button>
      <button
        type='button'
        onClick={logout}
        className='logoutBtn'
      >
        로그아웃
      </button>
    </header>
  );
}

export default GeneHeader;
