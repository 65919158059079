import React from 'react';

import pic764 from 'assets/images/sub/_images/pic764.jpg';

function Do165() {

  return (
    <div className="techinfoview">
      <h1>미경산우 유방염</h1>
      <p className="pstyle">일명 [여름철 유방염]으로 알려져 있으며 방목중의 처녀소 또는 건유증인 젖소에 다발한다.</p>
      <div className="leftpart">
        <h2>원인</h2>
        <ul>
          <li>흡혈곤충(모기, 쇠파리 등)이 유두를 물어서 생긴 자창(刺創)을 통해 감염된다. 그 원인균은 콜라이네 박테리움(화농간균)이다.</li>
        </ul>
        <h2>증상</h2>
        <ul>
          <li>급성 유방염의 일봉으로서 감염된 분방은 크게 붓는다. </li>
          <li>유즙은 황백색(黃白色)이며, </li>
          <li>대개 4개의 분방전체가 붓거나 딱딱해지며 통증이 나타난다. </li>
        </ul>
        <h2>목장에서의 처치</h2>
        <ul>
          <li>조기에 발견하여 농(膿)이 형성되기 전, 즉 분비물이 물과 같을 때 치료하여야 한다. </li>
          <li>먼저 따뜻한 물로 유방을 깨끗이 닦고 염증으로 생겨난 분비물을 완전히 배출시킨 후 유방염연고를 주입한다.-대개 페니실린계 항생제가 유효하다. </li>
          <li> 소염제( 안티푸라민 등)를 유방에 발라주고 단밴분해 효소제(키노트립신, 키모타보 등)의 유방내 주입은 효과를 돕는다. </li>
          <li>근육 또는 혈관으로 항생물질이나 썰파제, 비타민 A 및 부신피질 호르몬제를 투여한다. </li>
          <li>위의 처치를 하루 1회, 1∼2주일 꾸준히 계속한다. </li>
          <li>이유방염은 대개 유방이 붓게 되고 따라서 유선이 폐쇄되어 치료약제의 침투가 어려워 완치가 쉽지 않다. </li>
        </ul>
      </div>
      <div className="rightpart">
        <img src={pic764} className="nonborder" alt=""/>
      </div>
    </div>
  );
}

export default Do165;