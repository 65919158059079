import React from 'react';


function Do47() {

  return (
    <div className="techinfoview">
				<h1>유방염 관리</h1>
				<h2>유방염의 검사법 </h2>
				<ul>
					<li>유방염의 증상 
						<p className="pstyle">유방염은 세균이 유선조직을 침입하여 생기는 일종의 염증으로 산유량을 감소 시키고 세균 및 염증산물을 원유내로 유입시는 비위생적 원유생산의 주요 질병이다. 유방염은 직접 육안으로 관찰할 수 있는 임상형 유방염과 육안으로는 확인할 수 없지만 검사로 세균감염을 알 수 있는 준임상형으로 구분된다. <br/><br/>임상형 유방염은 원유내에 침전물 및 덩어리가 존재하기 때문에 감염 여부가 눈으로 확인되는데, 일반적으로 다음과 같은 증상을 보인다. </p>
						<ul>
							<li>염증 때문에 유방에서 열기가 느껴진다.</li>
							<li>유방이 단단해지고 산유량이 감소한다.</li>
							<li>원유는 산성화되어 신맛이 난다. </li>
							<li>원유 색깔이 누렇거나 혈액이 섞여 붉은 색을 띤다.</li>
							<li>점막세포의 탈락이나 유즙의 응고에 의하여 흰 혼탁물이 생긴다. </li>
							<li>체온이 상승하고 사료 섭취량이 감소한다. </li>
						</ul><br/>
						<p className="pstyle">준임상형 유방염은 외관상으로는 우유가 정상적으로 보이기 때문에 발견하기 어려우며, 발생율이 임상형 유방염보다 15-40배 높으며 우리나라 전체 젖소의 30% 정도가 감염된 것으로 보인다. 육안으로 관찰할 수 없기 때문에 정기적인 검사를 통하여 조기발견 하여야 한다. 준임상형 유방염의 경우에는 산유량을 감소시키고 비위생적인 원유를 생산하는 것 외에도 다음과 같은 문제점이 있기 때문에 각별히 유의하여야 한다. </p>
						<ul>
							<li>발견하기 어렵다. </li>
							<li>쉽게 임상형 유방염이 된다.</li>
							<li>감염기간이 길다.</li>
							<li>감염군을 보균하게 된다. </li>
						</ul><br/>
					</li>
					<li>유방염 진단
						<p>유방염을 조기에 발견하여 조기치료를 하는 것이 유방염관리의 필수적인 요소이다. 농가에서 쉽게 유방염을 진단할 수 있는 방법 및 검사요령은 다음과 같다. </p>
						<ul>
							<li>스트립 검사법(Strip Test) 
								<ul>
									<li>2∼3 줄기의 첫젖을 검은 판이나 천위에 짠다.</li>
									<li>우유의 농, 응고유무 및 붉기정도를 관찰 </li>
								</ul>
							</li>
							<li>C.M.T 검사법
								<ul>
									<li>4개의 반응판이 있는 백색판에 각 분방의 우유를 짜 넣는다. </li>
									<li> 우유를 각 반응판에 2㎖ 정도의 C.M.T 시약을 첨가한다. </li>
									<li>백색판을 동심원상으로 돌려 우유와 시약이 고루 섞고 10초 정도 후에 판정기준표에(표33)의하여 판정한다. </li>
								</ul>
								<table className="tablestyle">
									<tr>
										<td>반응상태</td>
										<td>판정</td>
									</tr>
									<tr>
										<td>변하지 않고 액상 그대로인 경우</td>
										<td>정상</td>
									</tr>
									<tr>
										<td>침전물이 약간 생성되지만 없어지는 경향을 보임</td>
										<td>정상(의심)</td>
									</tr>
									<tr>
										<td>겔을 형성하지는 않으나 침전물 형성</td>
										<td>준임상</td>
									</tr>
									<tr>
										<td>전체적으로 겔이 형성되며 판을 회전하면 중앙으로 모이고 중지하면 요철상으로 바닥을 덮음.</td>
										<td>준임상형</td>
									</tr>
									<tr>
										<td>전체적으로 겔을 형성하며 바닥에 붙은 상태를 유지</td>
										<td>임상형</td>
									</tr>
								</table><br/>
							</li>
							<li> 전기 전도율 측정법 
								<ul>
									<li>유방염에 걸린 젖소로부터 나오는 우유는 전기전도율이 높다는 원리를 이용한 검사법으로 신속한 것이 장점이다. 확실한 판정을 위해서는 C.M.T 법으로 재검하는 것이 바람직하다. </li>
								</ul>
							</li>
							<li> 체세포 검사법
								<ul>
									<li>정상적인 우유중에도 여러종류의 체세포가 함유되어 있는데, 일반적으로 상피세포, 중성구, 임파구 및 단핵구가 주를 이룬다. 정상적인 유방으로부터 분비되는 우유중의 약 60%가 상피세포이지만 유방염 감염 등과 같이 이상이 생겨 유선 조직이 손상을 입게되면 중성구 숫자가 급격하게 증가하여 전체 체세포수를 증가 시킨다.  따라서 최근에는 우유중의 체세포수를 검사하여 다음과 같은 기준표(표34)를 사용하여 유방염 감염 여부를 추정하는 방법이 유방염 예방 차원에서 권장되고 있다. </li>
								</ul>
								<p><b>&lt;표 34&gt; 체세포수와 유방염과의 관계</b></p>
								<table className="tablestyle">
									<tr>
										<td>체세포수(10,000)</td>
										<td>판정</td>
									</tr>
									<tr>
										<td>14∼22</td>
										<td>정상</td>
									</tr>
									<tr>
										<td>25∼40</td>
										<td>정상(의심)</td>
									</tr>
									<tr>
										<td>50∼100</td>
										<td>준임상형</td>
									</tr>
									<tr>
										<td>100∼200</td>
										<td>준임상형</td>
									</tr>
									<tr>
										<td>300이상</td>
										<td>임상형</td>
									</tr>
								</table>
							</li>
						</ul>
					</li>
				</ul>
				<h2>유두소독(teat dipping)에 의한 유방염 예방</h2>
				<p>착유직후에 세균감염방지용액(disinfectant solution)에 유두를 담그는 것은 유방염방지 에 아주 효과적인 방법이다. 이것 한가지만으로 유방염의 새로운 감염을 50%나 줄일 수 있다고 한다. 이의 시행방법 및 주의 사항은 다음과 같다. </p>
				<ul>
					<li>유두침지는 유두컵을 떼어낸 후 바로 실시하여 소가 드러누어 우상에 유두가 닿기 이전에 완료해야 한다. 효과적인 소독은 유두의 3분의 2정도가 소독액에 담가지도록 하면 된다. </li>
					<li>세균감염방지용액을 만든 제조회사의 사용지침서를 반드시 읽고 그대로 실천하는 것이좋다. </li>
					<li>처음에 유두소독을 시작하면 유두가 약간 부어오르고 껍질이 벗겨지며 딱지가 앉는 수가 있다. 이는 약 10일정도 계속되다 정상으로 돌아오므로 전혀 해롭지 않다. 이는 용액에 따라 정도가 다른 바 hypochlorite용액은 더 심하나 일반적으로 3주이내에 정상으로 회복된다. </li>
					<li>서늘하거나 추울 때는 유두에 용액이 많이 묻게 된다. 용액을 따뜻하게 해서 유두를 담그면 묻은 양이 적어지고 동결에 의한 유두 손상을 줄일 수 있다. 주운계절레 처음으로 chlorine용액을 사용하면 유두가 가볍게 붓고 또 트는 경우가 있다. </li>
					<li>효력이 높은 용액을 사용하고 착유할 때 용기에 새로 담아서 필요하면 더 채워가며 사용하되 전군에 대한 착유를 마친 후에는 쏟아버린다. 그리고 착유중이라도 더러워지면 버리고 다시 채워 쓴다. 한번 사용한 용액을 다시 병에 부어서는 안된다. </li>
					<li>chlorine용액을 훼놀이 함유된 연고와 함께 쓰면 우유에 냄새가 옮겨지니 이를 주의해야한다. </li>
					<li>수용성 소독용액을 사용하는 것이 좋다. 지용성 용액보다 효과가 높다. </li>
					<li>U자형 용기를 쓰는 것이 효과적이며 침지방법이 뿌리는 것보다 효과적이라는 연구결과도 있다. 분무하는 방법을 도입할때는 유두에 완전히 피복되도록 하는 것이 중요하다. </li>
					<li>유두를 침지하는데 소요되는 시간은 두당 3-4초에 불과하며 비용도 매우 적게 든다. 그러나 포도상구균(staphylococcus) 및 연쇄상구군(streptococcus)에 의해 발생하는 유방염 방지효과가 매우 크다는 것을 잊지 말아야겠다. 대장균류(E. Coli)에 의한 유방염 예방효과는 기대할 수 없다. </li>
				</ul>
				<h2>건유우에 대한 유방염 예방 </h2>
				<p>건유기에 들어가기 위해 최종적으로 착유한 후 각 유두에 항생제를 넣어 세균의 감염을 줄이는 방법이다. 이의 이점을 들면 다음과 같다. </p>
				<ul>
					<li>이는 건유초기에 새로운 유방염 발생을 예방하는 최선의 방법이다. 실제로 건유시작 후 3주동안에 유방염이 발생하는 빈도가 매우 높다. 이 기간 동안의 발생이 착유기간 중의 감염율보다 높다고 한다. </li>
					<li>유방내의 병원균의 수를 줄이는데 매우 효과적이다. 착유중의 치료로는 58%정도의 병원균 제거가 가능하나 건유시에 처리하면 90%이상 제거할 수 있다. 즉 연쇄상구균의 경우 90∼100%까지 박멸할 수 있으며 포도상구균은 40∼70%제거가 가능하다. 그러나 대장균에는 효과가 없다. </li>
					<li>이는 우유생산 최고기에 감염을 방지한다. 초산시 감염된 젖소의 3분의 1은 다음 유기에 또 유방염을 앓게 된다. 그리고 나머지 3분의 2도 잠재성 유방염의 상태에 있어 다른 소들에게 병원균을 감염시키는 원인이 된다. </li>
					<li>건유기간중에는 유선포의 재생이 이루어진다. 만일 유방에 염증이 생기면 유선조직이 결제조직으로 변하게 되고, 일단 대체된 결제조직은 유선조직으로 돌아가지 못한다. 건유시의 조치는 새로운 유선조직에 주는 피해를 줄인다. </li>
					<li>착유기간보다 유방내에 머물러 있는 항생제의 농도가 높아 치료 및 예방에 효과적이다. </li>
					<li> 투입한 항생제가 서서히 풀녀나 오래 동안 약효가 지속된다. 건유우의 유두에 항생제를 처리하는 것은 모든 건유우를 대상으로 모든 유두에 처리하는 것이 효과적이다. 이에 대한 주의 사항은 다음과 같다.
						<ul>
							<li>항생제를 처리할 유두는 먼저 위생 세척제로 닦고 1회용 종이수건 등으로 말린 후 유두끝을 70% 알콜을 솜에 찍어 소독한다. 한번 쓴 솜뭉치는 다시 쓰지 말고 버려야 한다. 그리고 완젼 멸균된 1회용 주사기를 사용해서 항생물질을 투입한다. </li>
							<li>약제는 제조회사의 사용법에 따라 사용하며 반드시 건유우용인가를 확인해야 한다. </li>
							<li>약제를 투입하기 전에 도태할 것인가 또는 계속 우유를 생산할 것인가를 결정한 후 처리하며 일단 처리한 소는 항생물질이 완전히 없어지기 전에 도살장에 보내지 않도록해야 한다. </li>
							<li>어떤 제품은 분만 예정일 몇주전 이후에는 사용해서는 안되는 것으로 되어 있다. 따라서 건유중에 유방염 치료를 해야할 경우에는 착유중에 사용하는 유방염 연고를 쓰도록하는 것이 좋다. </li>
							<li>마지막 치료 후 14∼21일이 지난 다음 모든 유두에서 우유를 채취하여 배양검사를 하게하고 필요하면 다시 치료하고 치료한 다음엔 다시 위와 같이 한다. </li>
							<li>유방염이 심하여 치료하기 어렵다는 판정이 나면 곧 도태 처분하는 것이 좋다. 이러한 판단을 위해서는 과거의 건강기록부를 검토할 필요가 있다. </li>
							<li>더 이상의 발생을 막기 위해 감염원인을 면밀히 분석한다. 착유관행, 착유기, 젖소의 건강관리관행 및 환경등 유방염을 발생케하는 모든 요인들을 철저히 검토해서 필요한 조처를 취하는 것이 좋다. </li>
						</ul>
					</li>
				</ul>
				<h2>유방염 발생시의 관리 </h2>
				<ul>
					<li>우유 검사컵의 우유를 관찰해 보고 이상이 있을때는 그 소에서 착유한 우유를 별도 관리한다. </li>
					<li>유방염에 감염된 소는 격리 수용하고 수의사의 협력을 받아 치료한다. 수의사로 하여금 우유를 채위 배양토록하여 항생물질 내성을 검사토록 하는 것이 좋다. </li>
					<li>수의사의 처방에 따라 다시 발병하지 않도록 치료한다. </li>
					<li>우유가 정상으로 돌아오고 항생물질 잔류기간이 지나면 다시 착유우군에 복귀시킨다. 그후 착유할 때마다 유방염 검사를 실시한다. </li>					
				</ul>
				<br/><p>물론 유전적인 요인이나 사료자체가 유방염 발생의 원인이 되기도 하지만 대부분 (95%) 착유관리의 미숙과 환경위생의 소홀에 의한 세균오염에 의하여 유방염이 발생되므로 낙농가에서는 착유시는 물론 착유종료 후에도 다음과 같은 사항에 각별히 유의하여야 한다. </p>
			</div>
  );
}

export default Do47;
