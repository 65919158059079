import React from 'react';

// 컴포넌트
import SubTitle from 'components/subTitle/SubTitle';

// scss
import './Email.scss';

// 이미지
import Eimage01 from 'assets/images/containers/guide/email/email_guard.png';


function Email() {
  return (
    <div className="email subCon">
      <SubTitle />

      <div className='email_notice'>
        <img src={Eimage01} alt="이메일보호 이미지"/>      
        <p>
        본 사이트에 게시된 이메일 주소가 전자우편 수집 프로그램이나 그밖의 기술적 장치를 이용하여 무단으로 수집되는 것을 거부하며, 이를 위반시 정보통신망법에 의해 형사처벌됨을 유념하시기 바랍니다.
        </p>
      </div>

      <div className="email_contents">
				<h2>제 50조의 2(전자우편주소의 무단수집행위 등 금지)</h2>
				<ul className="line3">
					<li>누구든지 전자우편주소의 수집을 거부하는 의사가 명시된 인터넷 홈페이지에서 자동으로 전자우편주소를 수집하는 프로그램 그 밖의 기술적 장치를 이용하여 전자우편주소를 수집하여서는 아니된다.</li>
					<li>누구든지 제1항의 규정을 위반하여 수집된 전자우편주소를 판매 유통하여서는 아니된다.</li>
					<li>누구든지 제1항 및 제2항의 규정에 의하여 수집 판매 및 유통이 금지된 전자우편주소임을 알고 이를 정보전송에 이용하여서는 아니된다.</li>
				</ul>
				
				<h2>제74조(벌칙)</h2>
				<ul className="line3">
					<li>다음 각 호의 어느 하나에 해당하는 자는 1년 이하의 징역 또는 1천만원 이하의 벌금에 처한다. (개정 2012.2.17.)</li>
					<li>제 50조의 2를 위반하여 전자우편주소를 수집·판매·유통하거나 정보 전송에 이용한 자</li>
				</ul>
			</div>
    
    </div>
  );
}

export default Email;
