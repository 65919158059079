import React from 'react';

// 컴포넌트
import DciInfo from 'containers/dcitest/dciInfo/DciInfo';

function DciInfoPage() {
  return (
    <DciInfo />
  );
}

export default DciInfoPage;
