import React, { useState, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

// api
import { postState } from 'utils/api';

function CommonInfoView({ boardViewVo, brdCode, deleteUrl, returnUrl }) {
  const navigate = useNavigate();
  const [modifiedHtmlContent, setModifiedHtmlContent] = useState('');

  useEffect(() => {
    if (!boardViewVo?.result?.bdc_body) return;

    let initialHtmlContent = boardViewVo.result.bdc_body;

    if (typeof initialHtmlContent === 'string') {
      initialHtmlContent = initialHtmlContent
        .replaceAll('&lt;', '<')
        .replaceAll('&gt;', '>');
    }

    // Create a temporary div element
    const tempElement = document.createElement('div');
    tempElement.innerHTML = initialHtmlContent;

    // Find all elements with width and height attributes and remove them
    const elementsWithAttributes =
      tempElement.querySelectorAll('[width], [height]');
    elementsWithAttributes.forEach((element) => {
      element.removeAttribute('width');
      element.removeAttribute('height');
    });

    const imgElements = tempElement.querySelectorAll('img');
    imgElements.forEach((img) => {
      img.removeAttribute('style');
    });

    // Get the modified HTML content
    const modifiedHtml = tempElement.innerHTML;

    // Set the modified HTML content to the state
    setModifiedHtmlContent(modifiedHtml);
  }, [boardViewVo]);

  const handleDelete = async () => {
    if (window.confirm('삭제 하시겠습니까?')) {
      // Implement form submission logic here
      try {
        const response = await postState(deleteUrl, {
          bdc_code: boardViewVo?.result?.bdc_code,
        });
        if (response?.return_code === 'SUCCESS') {
          alert('삭제되었습니다.');
          navigate(returnUrl);
        }
        if (response?.return_code === 'ERROR') {
          alert(response?.return_message);
          openModal();
        }
      } catch (error) {
        alert(error.message);
      }
    }
  };

  const handleList = () => {
    let actionUrl = '';

    switch (brdCode) {
      case '1001':
        actionUrl = navigate('/bo/info/InfoEvent/list');
        break;
      case '1002':
        actionUrl = navigate('/bo/info/InfoNew/list');
        break;
      case '1003':
        actionUrl = navigate('/bo/info/InfoForeignTopics/list');
        break;
      case '1004':
        actionUrl = navigate('/bo/info/InfoTechInfo/list');
        break;
      case '1005':
        actionUrl = navigate('/bo/info/InfoIndustryNews/list');
        break;
      case '1031':
        actionUrl = navigate('/bo/info/market/list');
        break;
      case '1037':
        actionUrl = navigate('/bo/info/InfoVideo/list');
        break;
      default:
        break;
    }

    if (actionUrl) {
      // Implement form submission logic here
      console.log(`Form submitted to ${actionUrl}`);
    }
  };

  const handleSave = () => {
    let editUrl = '';

    switch (brdCode) {
      case '1001':
        editUrl = '/bo/info/InfoEvent/edit';
        break;
      case '1002':
        editUrl = '/bo/info/InfoNew/edit';
        break;
      case '1003':
        editUrl = '/bo/info/InfoForeignTopics/edit';
        break;
      case '1004':
        editUrl = '/bo/info/InfoTechInfo/edit';
        break;
      case '1005':
        editUrl = '/bo/info/InfoIndustryNews/edit';
        break;
      case '1031':
        editUrl = '/bo/info/market/edit';
        break;
      case '1037':
        editUrl = '/bo/info/InfoVideo/edit';
        break;
      default:
        break;
    }

    if (editUrl) {
      navigate(editUrl, {
        state: {
          bdc_code: boardViewVo?.result?.bdc_code,
          bdc_title: boardViewVo?.result?.bdc_title,
          bdc_wname: boardViewVo?.result?.bdc_wname,
          bdc_body: boardViewVo?.result?.bdc_body,
          bdc_type: boardViewVo?.result?.bdc_type === 2 ? 'Y' : 'N',
          bdc_matter: boardViewVo?.result?.bdc_matter,
          bdc_del_fl: boardViewVo?.result?.bdc_del_fl === 2 ? 'Y' : 'N',
          bdc_sdate: boardViewVo?.result?.bdc_sdate,
          bdc_edate: boardViewVo?.result?.bdc_edate,
          bdc_del_fl_date: boardViewVo?.result?.bdc_del_fl_date,
          bdc_del_fl_hour: boardViewVo?.result?.bdc_del_fl_hour,
          video_url: boardViewVo?.result?.video_url,
          file_list: boardViewVo?.result?.file_list || [],
        },
      });
    }
  };

  const fileDownload = (fname, bdfCode) => {
    // Implement file download logic here
    console.log(`Downloading file: ${fname} with code: ${bdfCode}`);
  };

  return (
    <div className="info-view bo-content">
      <h2>게시글 정보</h2>
      <form>
        <div className="table-container bold-border">
          <table>
            <tbody>
              <tr>
                <th width="15%">제목</th>
                <td colSpan="3">
                  <strong>[{boardViewVo?.result?.bdc_wname}]</strong>&nbsp;
                  {boardViewVo?.result?.bdc_title}
                </td>
              </tr>
              <tr>
                <th>등록일</th>
                <td>{boardViewVo?.result?.bdc_rdt}</td>
                <th>조회수</th>
                <td>{boardViewVo?.result?.bdc_hit}</td>
              </tr>
              {boardViewVo?.result?.file_list?.length > 0 && (
                <tr>
                  <th>첨부파일</th>
                  <td colSpan="3">
                    {boardViewVo?.result?.file_list.map((file, index) => (
                      <React.Fragment key={index}>
                        {index > 0 && ' '}
                        <a
                          href={file?.bdf_fname}
                          onClick={(e) => {
                            e.preventDefault();
                            fileDownload(file.bdf_fname, file.bdf_code);
                          }}
                        >
                          {file.bdf_fname}({file.bdf_fsize}MByte)
                        </a>
                      </React.Fragment>
                    ))}
                  </td>
                </tr>
              )}
              <tr>
                <th>{boardViewVo?.bdc_type !== 2 ? '내용' : 'URL'}</th>
                <td colSpan="3" id="content">
                  <div
                    dangerouslySetInnerHTML={{ __html: modifiedHtmlContent }}
                  ></div>
                </td>
              </tr>
              {brdCode === '1037' && (
                <>
                  <tr>
                    <th>동영상URL</th>
                    <td colSpan="3">{boardViewVo?.result?.video_url}</td>
                  </tr>
                  <tr>
                    <td
                      colSpan="4"
                      style={{
                        textAlign: 'center',
                        height: '500px',
                      }}
                    >
                      <iframe
                        title="videoFrame"
                        id="videoFrame"
                        src={boardViewVo?.result?.video_url}
                        style={{
                          width: '70%',
                          height: '90%',
                          border: 'none',
                        }}
                        allowFullScreen
                      ></iframe>
                    </td>
                  </tr>
                </>
              )}
            </tbody>
          </table>
        </div>

        <div className="button-group">
          <button type="button" className="btn-l" onClick={handleSave}>
            수정
          </button>
          <button type="button" className="btn-l" onClick={handleDelete}>
            삭제
          </button>
          <button type="button" className="btn-l" onClick={handleList}>
            목록
          </button>
        </div>
      </form>
    </div>
  );
}

export default CommonInfoView;
