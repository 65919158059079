const SiteMapData = [
  {
    title: '사업안내',
    path: '/Web/Business/Companyintro',
    subpages: [
      {
        title: '젖소개량사업소 소개',
        path: '/Web/Business/Companyintro',
      },

      {
        title: '인사말',
        path: '/Web/Business/Greetings',
      },

      {
        title: '연혁 및 일반현황',
        path: '/Web/Business/History',
      },

      {
        title: '동결정액생산·공급',
        path: '/Web/Business/FreezeSemen',
      },

      {
        title: '유우군능력검정',
        path: '/Web/Business/BestCowTest',
      },

      {
        title: '후대검정',
        path: '/Web/Business/DescendantCow',
      },

      {
        title: '씨수소 사양관리',
        path: '/Web/Business/SuperiorCow',
      },

      {
        title: '직원현황',
        path: '/Web/Business/Staff',
      },

      {
        title: '찾아오시는 길',
        path: '/Web/Business/Visit',
      },
    ],
  },

  {
    title: '검정성적조회',
    path: '/Web/Performance/MonthFarmhouse',
    subpages: [
      {
        title: '이달의 검정농가',
        path: '/Web/Performance/MonthFarmhouse',
      },

      {
        title: '농가별 검정성적조회',
        path: '/Web/Performance/PerformanceCheck',
      },

      {
        title: '경제수명 및 도태우분석',
        path: '/Web/Performance/DieoutCowAnalysis',
      },

      {
        title: '개체찾기',
        path: '/Web/Performance/FindCow',
      },

      {
        title: '유전평가 계획교배',
        path: '/Web/Performance/PlannedMatingCow',
      },

      {
        title: '행정구역별 검정현황',
        path: '/Web/Performance/ProvinceStatus',
      },

      {
        title: '유전체 분석',
        path: '/web/Performance/GeneAnalysis',
      },
    ],
  },

  {
    title: '후대검정조회',
    path: '/Web/Progency/DescendantCowSearch',
    subpages: [
      {
        title: '농가별 후대검정 정보검색',
        path: '/Web/Progency/DescendantCowSearch',
      },

      {
        title: '한국형 후보씨수소 조회',
        path: '/Web/Progency/SuperiorCowSearch',
      },
    ],
  },

  {
    title: '씨수소안내',
    path: '/Web/Ox/OxSearch',
    subpages: [
      {
        title: '씨수소 검색',
        path: '/Web/Ox/OxSearch',
      },

      {
        title: '정액구입',
        path: '/Web/Ox/BuySemen',
      },

      {
        title: '씨수소 자료해설',
        path: '/Web/Ox/OxData',
      },
    ],
  },

  {
    title: '최신정보',
    path: '/Web/Info/MainNews',
    subpages: [
      {
        title: '주요소식',
        path: '/Web/Info/MainNews',
      },

      {
        title: '알림&행사',
        path: '/Web/Info/NotiAndEvents',
      },

      {
        title: '해외토픽',
        path: '/Web/Info/Overseas',
      },

      {
        title: '기술정보',
        path: '/Web/Info/TechnicalInfo',
      },

      {
        title: '업계소식',
        path: '/Web/Info/IndustryNews',
      },

      {
        title: '홍보동영상',
        path: '/Web/Info/PromotionalVideo',
      },
    ],
  },

  // 2025-02-14 고객사 요청으로 서비스 없앰
  //   {
  //     title: '묻고답하기',
  //     path: '/Web/Qna/Expert',
  //     subpages : [
  //         {
  //           title : '전문가',
  //           path : '/Web/Qna/Expert',
  //         },
  //       ]

  //   },

  {
    title: '정보창고',
    path: '/Web/Data/Notice',
    subpages: [
      {
        title: '공지사항',
        path: '/Web/Data/Notice',
      },

      {
        title: '현장포토',
        path: '/Web/Data/FieldPicture',
      },

      {
        title: '젖소검정사업보고서',
        path: '/Web/Data/Publication',
      },

      {
        title: '기술정보',
        path: '/Web/Data/QnaTechnicalInfo',
      },

      {
        title: '젖소검정성적표보는법',
        path: '/Web/Data/HowtoReport',
      },

      {
        title: '낙농용어사전',
        path: '/Web/Data/Dictionary',
      },

      {
        title: '관련사이트',
        path: '/Web/Data/RelatedSites',
      },
    ],
  },

  // 2024 - 02 - 20 고객사 요청으로 잠시 숨김
  // {
  //   title: '청정육종농가',
  //   path: '/Web/Album/Neulmok',
  //   subpages : [
  //       {
  //         title : '늘목목장',
  //         path : '/Web/Album/Neulmok',
  //       },

  //       {
  //         title : '순해목장',
  //         path : '/Web/Album/Sunhae',
  //       },

  //       {
  //         title : '원주목장',
  //         path : '/Web/Album/Wonju',
  //       },

  //       {
  //         title : '성강목장',
  //         path : '/Web/Album/Seonggang',
  //       },

  //       {
  //         title : 'L&H제네틱스',
  //         path : '/Web/Album/LAndH',
  //       },

  //       {
  //         title : '구원목장',
  //         path : '/Web/Album/Guwon',
  //       },

  //       {
  //         title : '이철목장',
  //         path : '/Web/Album/Icheol',
  //       },

  //       {
  //         title : '두평목장',
  //         path : '/Web/Album/Dupyeong',
  //       }

  //     ]

  // },
];

export default SiteMapData;
