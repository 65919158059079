import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { getState, postState } from 'utils/api';

function GroupEdit() {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const grpIdSnn = searchParams.get('grp_id');

  const [formData, setFormData] = useState({
    grp_id: '',
    grp_pass: '',
    grp_name: '',
    grp_desc: '',
    grp_stat: '0',
  });

  useEffect(() => {
    const fetchGroupData = async () => {
      if (grpIdSnn) {
        try {
          const response = await getState('/board/select_group', {
            grp_id: grpIdSnn,
          });

          if (response?.return_code === 'SUCCESS') {
            setFormData({
              grp_id: response.result.GRP_ID,
              grp_pass: response.result.GRP_PASS,
              grp_name: response.result.GRP_NAME,
              grp_desc: response.result.GRP_DESC,
              grp_stat: String(response.result.GRP_STAT),
            });
          }
        } catch (error) {
          console.error('Error fetching group data:', error);
        }
      }
    };

    fetchGroupData();
  }, [grpIdSnn]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    // Validation
    if (!formData.grp_id) {
      alert('사용자 그룹ID를 입력해주세요.');
      return;
    }

    if (!formData.grp_pass) {
      alert('비밀번호를 입력해주세요.');
      return;
    }

    if (!formData.grp_name) {
      alert('사용자 그룹명을 입력해주세요.');
      return;
    }

    const confirmMessage = grpIdSnn ? '수정하시겠습니까?' : '등록하시겠습니까?';
    if (!window.confirm(confirmMessage)) {
      return;
    }

    try {
      const endpoint = grpIdSnn
        ? '/board/update_group/'
        : '/board/insert_group/';
      const response = await postState(endpoint, formData);

      if (response?.return_code === 'SUCCESS') {
        alert(grpIdSnn ? '수정되었습니다.' : '등록되었습니다.');
        navigate('/bo/user/groupList');
      }
      if (response?.return_code === 'ERROR') {
        alert(response?.return_message);
      }
    } catch (error) {
      console.error('Error saving group:', error);
      alert('저장 중 오류가 발생했습니다.');
    }
  };

  return (
    <div className="bo-page">
      <h2>그룹 {grpIdSnn ? '수정' : '등록'}</h2>
      <div className="table-container bold-border">
        <table className="table_form">
          <colgroup>
            <col width="20%" />
            <col width="80%" />
          </colgroup>
          <tbody>
            <tr>
              <th>
                사용자 그룹ID<span className="required">*</span>
              </th>
              <td>
                {grpIdSnn || (
                  <>
                    <input
                      type="text"
                      name="grp_id"
                      value={formData.grp_id}
                      onChange={handleInputChange}
                      maxLength={50}
                    />
                  </>
                )}
              </td>
            </tr>
            <tr>
              <th>
                비밀번호<span className="required">*</span>
              </th>
              <td>
                <input
                  type="password"
                  name="grp_pass"
                  value={formData.grp_pass}
                  onChange={handleInputChange}
                  maxLength={15}
                />
              </td>
            </tr>
            <tr>
              <th>
                사용자 그룹명 <span className="required">*</span>
              </th>
              <td>
                <input
                  type="text"
                  name="grp_name"
                  value={formData.grp_name}
                  onChange={handleInputChange}
                  maxLength={50}
                />
              </td>
            </tr>
            <tr>
              <th>설명</th>
              <td>
                <input
                  type="text"
                  name="grp_desc"
                  value={formData.grp_desc}
                  onChange={handleInputChange}
                  maxLength={50}
                />
              </td>
            </tr>
            <tr>
              <th>
                상태<span className="required">*</span>
              </th>
              <td>
                <label>
                  <input
                    type="radio"
                    name="grp_stat"
                    value="0"
                    checked={formData.grp_stat === '0'}
                    onChange={handleInputChange}
                  />
                  정상
                </label>
                <label>
                  <input
                    type="radio"
                    name="grp_stat"
                    value="1"
                    checked={formData.grp_stat === '1'}
                    onChange={handleInputChange}
                  />
                  사용불가
                </label>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="button-group">
        <button type="button" className="btn-l" onClick={handleSubmit}>
          그룹 {grpIdSnn ? '수정' : '등록'}
        </button>
        <button
          type="button"
          className="btn-l"
          onClick={() => navigate('/bo/user/groupList')}
        >
          그룹 목록
        </button>
      </div>
    </div>
  );
}

export default GroupEdit;
