import React from 'react';
import './Pagination.scss';

function Pagination({ currentPage, totalPage, onPageChange }) {
  const pageGroupSize = 10; // 한 그룹당 페이지 수
  const currentGroup = Math.ceil(currentPage / pageGroupSize); // 현재 페이지 그룹
  const lastGroup = Math.ceil(totalPage / pageGroupSize); // 마지막 페이지 그룹

  // 현재 그룹의 시작과 끝 페이지 계산
  const groupStart = (currentGroup - 1) * pageGroupSize + 1;
  const groupEnd = Math.min(currentGroup * pageGroupSize, totalPage);

  // 페이지 배열 생성
  const pages = Array.from(
    { length: groupEnd - groupStart + 1 },
    (_, i) => groupStart + i,
  );

  // 다음 그룹의 첫 페이지가 총 페이지 수를 넘는지 확인
  const isLastGroup = groupEnd === totalPage;
  // 다음 그룹의 시작 페이지
  const nextGroupStart = groupEnd + 1;

  return (
    <div className="pagination">
      {/* 맨 처음으로 */}
      <button
        type="button"
        onClick={() => onPageChange(1)}
        disabled={currentPage === 1}
        className="page-btn first"
      >
        <span className="sr-only">처음</span>
      </button>

      {/* 이전 그룹으로 */}
      <button
        type="button"
        onClick={() => onPageChange(groupStart - 1)}
        disabled={currentGroup === 1}
        className="page-btn prev"
      >
        <span className="sr-only">이전</span>
      </button>

      {/* 페이지 번호들 */}
      <div className="page-numbers">
        {pages.map((page) => (
          <button
            key={page}
            type="button"
            onClick={() => onPageChange(page)}
            className={`page-btn number ${
              currentPage === page ? 'active' : ''
            }`}
          >
            {page}
          </button>
        ))}
      </div>

      {/* 다음 그룹으로 */}
      <button
        type="button"
        onClick={() => onPageChange(nextGroupStart)}
        disabled={isLastGroup}
        className="page-btn next"
      >
        <span className="sr-only">다음</span>
      </button>

      {/* 맨 끝으로 */}
      <button
        type="button"
        onClick={() => onPageChange(totalPage)}
        disabled={currentPage === totalPage || isLastGroup}
        className="page-btn last"
      >
        <span className="sr-only">마지막</span>
      </button>
    </div>
  );
}

export default Pagination;
