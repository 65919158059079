import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

// api
import { getState } from 'utils/api';

// components
import Pagination from 'components/bo/Pagination';

function SemenList() {
  const navigate = useNavigate();

  const [searchState, setSearchState] = useState({
    search_type: 's_j_name',
    search_keyword: '',
    page_no: 1,
    s_j_cmpl_fl: '', // 처리여부 ('' : 전체, '1': 처리, '0': 미처리)
  });

  const { data, isLoading, isError, error } = useQuery({
    queryKey: ['semen', searchState],
    queryFn: () =>
      getState('/board/select_semen_list', {
        ...searchState,
      }),
  });

  const semenList = data?.result?.LIST || [];
  const totalCount = data?.result?.TOTAL_COUNT || 0;
  const totalPages = data?.result?.PAGE_COUNT || 0;

  const handleSearch = (e) => {
    e.preventDefault();
    setSearchState({
      ...searchState,
      page_no: 1,
    });
  };

  const handlePageChange = (pageNo) => {
    setSearchState({
      ...searchState,
      page_no: pageNo,
    });
  };

  const handleRowClick = (semenNo) => {
    navigate(`/bo/semen/semenView?s_j_no=${semenNo}`);
  };

  return (
    <div className="bo-page">
      <h2>정액 주문 목록</h2>
      <div className="searchbox">
        <label>
          <input
            type="radio"
            name="s_j_cmpl_fl"
            value=""
            checked={searchState.s_j_cmpl_fl === ''}
            onChange={(e) =>
              setSearchState({ ...searchState, s_j_cmpl_fl: e.target.value })
            }
          />
          전체
        </label>
        <label>
          <input
            type="radio"
            name="s_j_cmpl_fl"
            value="1"
            checked={searchState.s_j_cmpl_fl === '1'}
            onChange={(e) =>
              setSearchState({ ...searchState, s_j_cmpl_fl: e.target.value })
            }
          />
          처리
        </label>
        <label>
          <input
            type="radio"
            name="s_j_cmpl_fl"
            value="0"
            checked={searchState.s_j_cmpl_fl === '0'}
            onChange={(e) =>
              setSearchState({ ...searchState, s_j_cmpl_fl: e.target.value })
            }
          />
          미처리
        </label>
        <select
          value={searchState.search_type}
          onChange={(e) =>
            setSearchState({ ...searchState, search_type: e.target.value })
          }
        >
          <option value="s_j_name">성명</option>
        </select>
        <input
          type="text"
          value={searchState.search_keyword}
          onChange={(e) =>
            setSearchState({ ...searchState, search_keyword: e.target.value })
          }
        />
        <button type="button" className="btn-search" onClick={handleSearch}>
          검색
        </button>
      </div>

      <div className="board-top">
        <p>
          총 자료수: <strong>{totalCount}</strong> 개, 페이지{' '}
          {searchState.page_no}/{totalPages}
        </p>
      </div>

      <div className="table-container">
        <table>
          <colgroup>
            <col width="7%" />
            <col width="7%" />
            <col width="10%" />
            <col width="10%" />
            <col width="10%" />
            <col width="10%" />
            <col width="7%" />
            <col width="5%" />
            <col width="*" />
            <col width="10%" />
          </colgroup>
          <thead>
            <tr>
              <th>번호</th>
              <th>처리여부</th>
              <th>성명</th>
              <th>주문날짜</th>
              <th>공급희망</th>
              <th>전화</th>
              <th>종모우수</th>
              <th>주문량</th>
              <th>메모</th>
              <th>금액</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td colSpan="10" className="loading-data">
                  데이터를 불러오는 중입니다...
                </td>
              </tr>
            ) : isError ? (
              <tr>
                <td colSpan="10" className="error-data">
                  에러가 발생했습니다: {error.message}
                </td>
              </tr>
            ) : semenList.length === 0 ? (
              <tr>
                <td colSpan="10" className="no-data">
                  게시글이 없습니다.
                </td>
              </tr>
            ) : (
              semenList.map((semen) => (
                <tr key={semen.S_J_NO}>
                  <td>{semen.ROW_NUM}</td>
                  <td>
                    <span
                      style={{
                        color: semen.S_J_CMPL_FL === 1 ? 'blue' : 'red',
                      }}
                    >
                      {semen.S_J_CMPL_FL === 1 ? '처리' : '미처리'}
                    </span>
                  </td>
                  <td>
                    <button
                      type="button"
                      onClick={() => handleRowClick(semen.S_J_NO)}
                    >
                      {semen.S_J_NAME}
                    </button>
                  </td>
                  <td>{semen.S_J_DATE}</td>
                  <td>{semen.S_B_DATE}</td>
                  <td>{semen.S_J_TEL}</td>
                  <td>{semen.S_J_CODE_NO}</td>
                  <td>{semen.S_J_STR}</td>
                  <td>{semen.S_J_MEMO}</td>
                  <td>{semen.S_J_KUM}</td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>

      <Pagination
        currentPage={searchState.page_no}
        totalPage={totalPages}
        onPageChange={handlePageChange}
      />
    </div>
  );
}

export default SemenList;
