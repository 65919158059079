import React, { useState, useEffect } from 'react';
import { getState } from 'utils/api';
import { Link } from 'react-router-dom';
// import { FaEllipsisH } from 'react-icons/fa'; // Ensure you import the icon
import './mainnews.scss';
import { FaEllipsisH } from 'react-icons/fa';
import article1 from 'assets/images/containers/main/article1.png';
import article2 from 'assets/images/containers/main/article2.png';
import article3 from 'assets/images/containers/main/article3.png';

import ImageComponent from './ImageComp';

/* const newsArticles = [
  {
    // title: '뉴스 제목 1',
    summary: '농협사료-낙농조합, 수입 조사료 사업 활성화 위해 ‘맞손’',
    image: article1,
    url: 'https://www.thekpm.com/news/articleView.html?idxno=157401',
  },
  {
    // title: '뉴스 제목 2',
    summary: '농협사료-낙농조합, 수입 조사료 사업 활성화 위해 ‘맞손’',
    image: article2,
    url: 'https://www.thekpm.com/news/articleView.html?idxno=157401',
  },
  {
    // title: '뉴스 제목 1',
    summary: '농협사료-낙농조합, 수입 조사료 사업 활성화 위해 ‘맞손’',
    image: article3,
    url: 'https://www.thekpm.com/news/articleView.html?idxno=157401',
  },
];
*/
const MainNews = () => {  
  const [contentData, setContentData] = useState(null);

  const baseDir = '/board/simple/';
  const props = { bname: 'industry', page_size: 3 };
  useEffect(() => {
    getState(baseDir, props)
      .then((getdata) => {
        if (getdata !== undefined) {
          setContentData(getdata.results);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  

  return (
    <div className="newsCon">
      <div className="main-news-container">
        <div className="news-info-group">
          <div className="news-header">
            <h2>
              <Link to="/Web/Info/IndustryNews" className="news-header-link">
                디지털<span>뉴스</span>
              </Link>
            </h2>
            <Link to="/Web/Info/IndustryNews" className="more-icon-link">
              <FaEllipsisH className="more-icon" />
            </Link>
          </div>
          <div className="news-slider">
            {contentData &&
              contentData.map((article, index) => (
                <a
                  key={index}
                  href={article.bdc_body}
                  target="_blank"
                  rel="noreferrer"
                  className="news-card"
                >
                  <ImageComponent imageUrl={article.title_img.replace('http:', '')} />                  
                  <div className="news-content">
                    <p>{article.bdc_title}</p>
                  </div>
                </a>
              ))}
          </div>
        </div>
      </div>
      {/* ////// */}
    </div>
  );
};

export default MainNews;
