import React from 'react';

import Pic165 from 'assets/images/sub/_images/pic165.gif';

function Do24() {

  return (
    <div className="techinfoview">
				<h1>임신과 분만</h1>
				<p>태아가 자궁내에서 발육하는 상태를 임신이라 한다. 임신에 있어서 모체의 최초의 반응은 착상에 따른 자궁내막의 반응과 발정의 정지이다.</p>
				<h2>태반과 태막</h2>
				<ul>
					<li>태반은 배 또는 태아의 조직이 모체의 자궁조직과 부착되어 모체와 태아간에 생리적인 물질교환을 수행하는 기관으로서 배반포가 착상한 후 영양세포의 활발한 증식에 의하여 점 차 성장하며 임신 중기에는 그 크기가 최대에 달한다. 태막은 양막, 뇨막 및 융모막으로 양막은 태아를 싸고 있는 가장 안쪽의 막이며, 가장 바깥쪽의 막인 융모막은 자궁내막과 직접 접해 있다. 일반적으로 산자수, 자궁의 내부구조, 모체와 태아조직간의 융합정도 등에 따라 산재성 태반과 궁부성 태반으로 나누는데 돼지와 말의 태반은 산재성 태반이고, 소와 면양의 태반은 궁부성 태반에 속한다.</li>
				</ul>
				<h2>임신기간</h2>
				<ul>
					<li>수태로부터 분만까지의 자축의 상태를 임신이라 하고, 그 기간을 임신기간이라 한다. 임신기간은 동물의 종류에 따라 품종, 연령, 계절, 모체의 상태. 태아의 성 및 산자수에 등 여러 가지 요인에 따라 다르며, 주요 가축의 임신기간은 아래 표와 같다.
					<p>[가축별 임신기간]</p>
					<table className="tablestyle">
						<tr>
							<td>가축별</td>
							<td>임신기간</td>
						</tr>
						<tr>
							<td>한우</td>
							<td>280 - 290</td>
						</tr>
						<tr>
							<td>젖소</td>
							<td>278 - 280</td>
						</tr>
						<tr>
							<td>육우(헤어포드)</td>
							<td>285(243 - 316)</td>
						</tr>
						<tr>
							<td>말(아라비안)</td>
							<td>337(301 - 371)</td>
						</tr>
						<tr>
							<td>돼지</td>
							<td>114(140 - 159)</td>
						</tr>
						<tr>
							<td>면양</td>
							<td>148(140 - 159)</td>
						</tr>
						<tr>
							<td>개</td>
							<td>63</td>
						</tr>
						<tr>
							<td>토끼</td>
							<td>31(30 - 22)</td>
						</tr>
					</table>
					</li>
				</ul>
				<h2>임신진단</h2>
				<p>가축이 교배이후 수태되었는가를 되도록 빨리 아는 것은 유산의 예방, 분만일의 결정, 건유일의 결정, 수태곤란 및 불임원인의 발견과 치료에 많은 도움이 되며, 번식효율을 향상시킬 수 있다.</p>
				<ul>
					<li>외진법
						<ul>
							<li>가축이 임신을 하게 되면 외부적으로 여러 가지 변화가 나타난다. 즉, 영양상태가 양호해지고 거동이 침착해지며, 복부가 팽대되는데 가장 대표적인 변화는 주기적으로 반복되던 발정이 중지되는 것이다. 수정후 2 - 4개월이 경과해도 발정이 오지 않을 때에는 임신으로 보는데 이것을 NR(Non-Return)이라고 한다.
							<p>[외진법과 수태율]</p>
							<table className="tablestyle">
								<tr>
									<td>구분</td>
									<td>30 - 60일</td>
									<td>60 - 90일</td>
									<td>90 - 120일</td>
								</tr>
								<tr>
									<td>NR법</td>
									<td>68.2%</td>
									<td>58.7%</td>
									<td>56.0%</td>
								</tr>
								<tr>
									<td>실제수태율</td>
									<td>53.4</td>
									<td>53.4</td>
									<td>53.4</td>
								</tr>
								<tr>
									<td>차이</td>
									<td>14.8</td>
									<td>5.3</td>
									<td>2.6</td>
								</tr>
							</table>
							</li>
						</ul>
					</li>
					<li>직장 검사법
						<ul>
							<li>직장검사법은 임신을 진단하는 가장 신뢰성이 높은 방법으로서 주로 소에서 많이 실시하고 있다.
								<ul>
									<li>난소
										<ul>
											<li>난소에는 임신황체가 존재하여 전임신기간 최대의 크기를 유지하기 때문에 발정황체와 구별된다. 이 황체를 임신황체 또는 진성황체라 한다. 이 진성황체는 4 - 5개월까지는 촉지할 수 있으나 그 이후가 되면 복강내로 처지기 때문에 촉진할 수 없게 된다.</li>
										</ul>
									</li>
									<li>자궁
										<ul>
											<li>자궁은 임신이 진행됨에 따라 커지기 때문에 자궁의 크기에 의하여 임신을 진단할 수 있다.즉, 태아는 자궁각에 착상되어 커지기 때문에 자궁각의 대소차이에 의하여 임신임을 확인할 수 있다.</li>
										</ul>
									</li>
									<li>궁부
										<ul>
											<li>비대한 궁부를 촉진하여 임신을 진단할 수 있는데 궁부의 크기는 임신단계와 개체에 따라 변이가 심하다. 궁부는 임신 3.5 - 4개월에 처음으로 촉진된다.</li>
										</ul>
										<img src={Pic165} style={{width: '50%'}} alt=""/>
										<p style={{width: '50%', textAlign: 'center'}}>기간별 임신 결정</p>
										<p>[직장검사법에 의한 임신진단시 태아의 크기 및 변화]</p>
										<table className="tablestyle">
											<tr>
												<td>임신일령(일)</td>
												<td>태아체중</td>
												<td>태아체장(cm)</td>
												<td>태아의 변화</td>
											</tr>
											<tr>
												<td>30</td>
												<td>0.28g</td>
												<td>1.2</td>
												<td>한쪽 자궁각이 확장되고 엷어짐<br/>앞다리,뒷다리가 생김.</td>
											</tr>
											<tr>
												<td>45</td>
												<td>3.5 - 7.1g</td>
												<td>2.5 - 3.2</td>
												<td>자궁각 다소 확장되고 엷어짐<br/>코와 눈이 생김. 배아낭은 달걀크기임</td>
											</tr>
											<tr>
												<td>60</td>
												<td>7.1 - 14.1g</td>
												<td>6.4</td>
												<td>자궁각은 바나나 크기임<br/>태액(胎液)은 충만됨. 태아는 생쥐 크기</td>
											</tr>
											<tr>
												<td>90</td>
												<td>85 - 170g</td>
												<td>12.7 - 15.2</td>
												<td>양측 자궁각 부풀어 오름. 중자궁 동맥직경<br/>3 - 4cm, 태아두부는 탁구공크기, 태아는 흰쥐크기, 이가 나기 시작</td>
											</tr>
											<tr>
												<td>120</td>
												<td>450 - 907g</td>
												<td>25 - 30</td>
												<td>궁부는 쉽게 촉지됨<br/>태아는 작은 고양이 크기</td>
											</tr>
											<tr>
												<td>150</td>
												<td>2 - 3g</td>
												<td>30 - 40</td>
												<td>태아촉지 곤란, 태아 두부는 야구공 크기,태아는 큰 고양이 크기, 눈과 콧구멍에 털이 생김</td>
											</tr>
											<tr>
												<td>180</td>
												<td>4 - 7g</td>
												<td>50 - 61</td>
												<td>태아와 자궁이 체강을 가득 채움<br/>태아는 작은 개 크기<br/>6개월령 이후는 태아의 발, 다리 및 코를잡으면 움직임을 확인할 수 있음</td>
											</tr>
											<tr>
												<td>210</td>
												<td>9 - 14g</td>
												<td>61 - 71</td>
												<td>태아 촉지 기능</td>
											</tr>
											<tr>
												<td>240</td>
												<td>18 - 27g</td>
												<td>71 - 81</td>
												<td>털이 전신에 나고, 앞니가 돋아나기 시작함.<br/>몸전체에 털이남</td>
											</tr>
											<tr>
												<td>270</td>
												<td>27 - 45g</td>
												<td>81 - 96</td>
												<td>중자궁 동맥직경 12 - 20mm임.</td>
											</tr>
										</table>
									</li>
									<li>중자궁 동맥 
										<ul>
											<li>중자궁 동맥의 비대와 동맥의 맥동파의 특징적인 변화로 임신을 진단할 수 있다. 임신 80일경에 최초로 동맥을 감지할 수 있고, 100 - 175일경에는 쉽게 찾을 수 있으며, 맥동도 감지할 수 있다. 임신말기로 갈수록 이 동맥은 굵어지면서 꾸불꾸불해지고 명확하게 감지되며, 연필정도의 굵기에 이르면 맥동도 힘차게 이루어진다.</li>
										</ul>
									</li>
								</ul>
							</li>
						</ul>
					</li>
					<li>질 검사법
						<ul>
							<li>질경을 사용하여 소나 말의 질내를 관찰하여 질과 자궁질부의 상태에 따라 임신여부를 판단하는 방법이다. 소의 경우 수정후 2 - 3개월이 되면 임신한 개체에서는 질경을 삽입할 때 상당한 저항을 느끼게 되며, 자궁질부는 긴축하여 작아지고, 자궁외부는 꼭 닫혀 있으며, 점액은 상당히 점착성을 띤다. 임신 4개월에는 질벽이 건조하고 자궁외부에서 뚜렷한 점액 덩어리를 볼 수 있다.</li>
						</ul>
					</li>
					<li>초음파 진단법
						<ul>
							<li>초음파 진단법은 주로 돼지에서 활용되는데 DOPPLER법과 ECHO법이 있다.</li>
						</ul>
					</li>
				</ul>
				<h2>분 만</h2>
				<ul>
					<li>분만징후 
						<ul>
							<li>유방 및 외음부의 부종(분만 3 - 5일전)을 보이고, 골반 인대의 이완으로 인한 외음부 함몰(분만 1 -2일전)이 시작되고, 식욕감퇴 및 불안한 거동이 불안해진다.</li>
						</ul>
					</li>
					<li>분만의 구분
						<ul>
							<li>제 1기 (개구기) - 초산우 4 - 6시간, 때로는 20시간, 경산우 2 - 3시간 </li>
							<li> 제 2기 (산출기) - 초산우 3 - 6시간, 경산우 2 - 4시간, 송아지가 크면 12시간 이상 </li>
							<li>제 3 기(후산기) - 분만후 1 - 2시간 이내 배출, 5 - 6시간 매달리는 경우도 있음</li>
						</ul>
					</li>
					<li>분만시 주의사항 
						<ul>
							<li>분만 제 1기에 분만 간섭시 분만이 지연되므로 끈기를 가지고 기다릴 것 </li>
							<li>분만 제 1기초기에 물주머니(요막)속에 송아지발이 보인다고 물주머니를 터트리지 말아야 하며, 송아지 다리를 절대로 잡아당기지 말 것. 만일 무리하게 잡아당기면 자궁경관의 경련이 일며, 송아지는 아무리 힘을 주어 당겨도 나오지 않음. </li>
							<li>분만 제 2기의 강력진통기간이 지난후 경산우는 2시간, 초임우는 3시간이 경과해도 아무것도 없을 때는 손을 깨끗이 씻은 후 희석된 베타딘 등으로, 손에 묻히고 어미소 외음부를 깨끗이 씻은 후 손은 넣어 태위 검사를 해 본다. </li>
							<li>송아지 태위가 잘못되었을 시에는 즉시 인근 수의사를 부른다. </li>
							<li>분만시 우사바닥에 깔짚, 모래 등을 충분히 깔아 어미소가 미끄러져 넘어지지 않도록 한다. </li>
							<li>송아지 머리가 산도를 완전히 빠져 나오지 못할때에는 송아지 발목위에 부드러운 천이나 끈으로 묶고 잡아당기되 어미소가 진통할 때마다, 같이 당겨주고 진통을 안할 때는 절대로 잡아당기지 말아야 한다.</li>
						</ul>
					</li>
					<li>갓 태어난 송아지가 호흡을 안할 때 처지 방법 
						<ul>
							<li>콧구멍 속을 짚으로 자극하기(5-6초간) </li>
							<li>송아지 입에 입김 불어 넣기(1분이상 계속 실시) </li>
							<li>인공호흡(5-10분간 계속 실시) </li>
							<li>거꾸로 매어 달은 후 찬물 끼얹기</li>
						</ul>
					</li>
				</ul>
				<h2>후산정체에 대하여</h2>
				<ul>
					<li>원인
						<ul>
							<li>전염성 유산(브루셀라) </li>
							<li> 캠필로 박터균 감염증 </li>
							<li>영양 결핍(Ca,Mg)</li>
							<li> 패혈증 </li>
							<li>분만중의 간섭 </li>
							<li>분만후의 피로</li>
						</ul>
					</li>
					<li>대책
						<ul>
							<li>매달려 있는 후산을 가위나, 칼로 바싹 잘라낸다.</li>
							<li>에스트로겐(Estrogen)호르몬 주사를 510mg 가량 3일간 간격으로 2회 주사한다. </li>
							<li>항생제를 투여한다.</li>
						</ul>
					</li>
				</ul>
				<h2>분만에 관련된 주요 질병 </h2>
				<ul>
					<li>자궁염전 </li>
					<li>경관 및 질탈(차기 분만시 재발율이 높다) </li>
					<li>자궁탈 (차기 분만시 거의 재발되지 않는다) </li>
					<li>양수과다증(자궁 수종증)</li>
				</ul>
				<h2>불임증 주요 원인</h2>
				<ul>
					<li>지연 교배(수정 적기를 놓쳤을 때) </li>
					<li>영양결핍(단백질, 인,칼슘, 코발트, 비타민 A결핍증,무발정으로 저수태율을 야기) </li>
					<li>선천성 생식기 이상(근친교배로 인한 유전성 불임증 등) </li>
					<li>각종 질병 감염(므루셀라, 요네병, 우결핵, 트리고모나스 IRB등) </li>
					<li>자궁내 질환( 질염, 경관염, 내막염, 만성자궁염,수란관염,자궁축농, 태아침지, 태아 미이라 변성, 난소낭종) 어미소의
						<ul>
							<li>영양수준과 발정 재귀일
								<table className="tablestyle">
									<tr>
										<td>영양수준</td>
										<td>고</td>
										<td>중</td>
										<td>저</td>
									</tr>
									<tr>
										<td>발정재귀일(일)</td>
										<td>58.7</td>
										<td>69.6</td>
										<td>71.0</td>
									</tr>
									<tr>
										<td>수태육(%)</td>
										<td>85.8</td>
										<td>78.9</td>
										<td>71.4</td>
									</tr>
									<tr>
										<td>분만간격(일)</td>
										<td>369</td>
										<td>410</td>
										<td>447</td>
									</tr>
									<tr>
										<td>(개월)</td>
										<td>(12.3)</td>
										<td>(13.6)</td>
										<td>(14.9)</td>
									</tr>
								</table>
								<p>※ 영양수준: 고, 중, 저는 NRC 영양수준에 각각 120%, 100%, 80%임. </p>
							</li>
							<li>운동의 정도와 수태율과의 관계
								<table className="tablestyle">
									<tr>
										<td>구분</td>
										<td>두수</td>
										<td>교배두수</td>
										<td>NR법(%)</td>
									</tr>
									<tr>
										<td>우사안에서만 사육</td>
										<td>894두</td>
										<td>9,412두</td>
										<td>64.1</td>
									</tr>
									<tr>
										<td>1일1회 운동</td>
										<td>3,237</td>
										<td>47,367</td>
										<td>69.5</td>
									</tr>
									<tr>
										<td>1일 2회 운동</td>
										<td>384</td>
										<td>4,070</td>
										<td>70.4</td>
									</tr>
									<tr>
										<td>개방우사 사육</td>
										<td>58</td>
										<td>4,070</td>
										<td>68.3</td>
									</tr>
								</table>
							</li>
							<li>분만 전후의 영양수준과 운동에 따른 번식기능 회복정도
								<table className="tablestyle">
									<tr>
										<td>구분</td>
										<td>영양상태</td>
										<td>공시두수</td>
										<td>자궁회복</td>
										<td>초회배란</td>
										<td>발정재귀</td>
									</tr>
									<tr>
										<td rowSpan="4">운동구</td>
										<td>130%</td>
										<td>18두</td>
										<td>34.1일</td>
										<td>39.4</td>
										<td>44.3</td>
									</tr>
									<tr>
										<td>100</td>
										<td>18</td>
										<td>31.0</td>
										<td>31.7</td>
										<td>34.0</td>
									</tr>
									<tr>
										<td>70</td>
										<td>17</td>
										<td>36.1</td>
										<td>44.7</td>
										<td>55.5</td>
									</tr>
									<tr>
										<td>평균</td>
										<td>18</td>
										<td>33.4</td>
										<td>38.4</td>
										<td>44.1</td>
									</tr>
									<tr>
										<td rowSpan="4">계류구</td>
										<td>130</td>
										<td>17두</td>
										<td>50.0일</td>
										<td>54.0</td>
										<td>64.8</td>
									</tr>
									<tr>
										<td>100</td>
										<td>17</td>
										<td>44.0</td>
										<td>54.7</td>
										<td>68.0</td>
									</tr>
									<tr>
										<td>70</td>
										<td>18</td>
										<td>44.5</td>
										<td>45.0</td>
										<td>55.5</td>
									</tr>
									<tr>
										<td>평균</td>
										<td>17</td>
										<td>46.5</td>
										<td>51.5</td>
										<td>61.6</td>
									</tr>
								</table>
							</li>
							<li>사육방법에 따른 발정개시 시각과 발현율
								<table className="tablestyle">
									<tr>
										<td rowSpan="2">구분</td>
										<td colSpan="2">방목사육</td>
										<td colSpan="2">우사에서 사육</td>
									</tr>
									<tr style={{background: '#273143', color: '#fff'}}>
										<td>두수</td>
										<td>발현율(%)</td>
										<td>두수</td>
										<td>발현율(%)</td>
									</tr>
									<tr>
										<td>오전</td>
										<td>47</td>
										<td>92</td>
										<td>61</td>
										<td>72</td>
									</tr>
									<tr>
										<td>오후</td>
										<td>4</td>
										<td>8</td>
										<td>24</td>
										<td>28</td>
									</tr>
									<tr>
										<td>계</td>
										<td>51</td>
										<td>100</td>
										<td>85</td>
										<td>100</td>
									</tr>
								</table>
							</li>
							<li>분만 후 발정 재귀까지의 기간과 수태율
								<table className="tablestyle">
									<tr>
										<td>분만 후 일수</td>
										<td>30</td>
										<td>30-45</td>
										<td>45-60</td>
										<td>61-90</td>
										<td>91-120</td>
										<td>121이상</td>
									</tr>
									<tr>
										<td>발정 출현율(%)</td>
										<td>25.7</td>
										<td>43.0</td>
										<td>43.0</td>
										<td>24.5</td>
										<td>24.5</td>
										<td>6.8</td>
									</tr>
									<tr>
										<td>수태율(%)</td>
										<td>57.1</td>
										<td>20.1</td>
										<td>63.6</td>
										<td>47.6</td>
										<td>58.8</td>
										<td>42.9</td>
									</tr>
									<tr>
										<td>수정횟수(회)</td>
										<td>3.0</td>
										<td>2.1</td>
										<td>1.6</td>
										<td>1.5</td>
										<td>1.7</td>
										<td>1.8</td>
									</tr>
								</table>
							</li>
							<li>분만 후의 배란시기와 발정 발견 정도
								<table className="tablestyle">
									<tr>
										<td rowSpan="2">구분</td>
										<td colSpan="3">분만 후의 배란</td>
									</tr>
									<tr style={{background: '#273143', color: '#fff'}}>
										<td>제 1차 배란</td>
										<td>제 2차 배란</td>
										<td>제 3차 배란</td>
									</tr>
									<tr>
										<td>분만 후 경과 일수</td>
										<td>23±12</td>
										<td>44±13</td>
										<td>63±10</td>
									</tr>
									<tr>
										<td>지속적 관찰시 발견율(%)</td>
										<td>50.0</td>
										<td>94.0</td>
										<td>100.0</td>
									</tr>
									<tr>
										<td>통상 관찰시 발견육(%)</td>
										<td>20.0</td>
										<td>44.0</td>
										<td>64.0</td>
									</tr>
								</table>
							</li>
						</ul>
					</li>
				</ul>
				<h2>암소의 번식관리 지표</h2>
				<ul>
					<li>이는 농가의 총체적인 번식관리 및 번식효율과 연관되는데 이 지표는 농가소득에 가장 직접적인 원인을 제공하는 요인이므로 소의 번식관련와 번식효율 지표를 살펴 봄으로서 효과적인 사양관리를 하는데 매우 효과적이라 할 수 있다.
					<table className="tablestyle12">
						<tr>
							<td></td>
							<td></td>
							<td>4개월령</td>
							<td></td>
							<td>14개월령</td>
							<td></td>
							<td>24개월령</td>
							<td colSpan="4"></td>
						</tr>
						<tr>
							<td colSpan="4"></td>
							<td>250Kg 이상</td>
							<td colSpan="6"></td>
						</tr>
						<tr>
							<td>출생</td>
							<td><hr noshade/></td>
							<td>이유</td>
							<td><hr noshade/></td>
							<td>첫수정</td>
							<td><hr noshade/></td>
							<td>분만</td>
							<td><hr noshade/></td>
							<td>수정</td>
							<td><hr noshade/></td>
							<td>이유</td>
						</tr>
						<tr>
							<td colSpan="4"></td>
							<td colSpan="5">←←←&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;미수정으로 인한 공태기 시작</td>
							<td colSpan="2"></td>
						</tr>
						<tr>
							<td colSpan="4"></td>
							<td colSpan="4">←←←&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;임신기간&nbsp;&nbsp;(280)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;→→→</td>
							<td colSpan="3"></td>
						</tr>
						<tr>
							<td colSpan="8"></td>
							<td colSpan="3">←←←&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;포유기간약 120일&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;→→→</td>
						</tr>
					</table>
						<ul>
							<li>교배효율
							<table className="tablestyle11">
								<tr>
									<td>분만 및 초종부 두수(21일기준)</td>
									<td rowSpan="2">× 100 = (&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;) %</td>
								</tr>
								<tr>
									<td>경산우 및 미경산우 총두수</td>
								</tr>
							</table>
							</li>
							<li> 초종부 수태율
							<table className="tablestyle11">
								<tr>
									<td>초종부 수태두수</td>
									<td rowSpan="2">× 100 = (&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;) %</td>
								</tr>
								<tr>
									<td>초종부 총두수</td>
								</tr>
							</table>
							</li>
							<li> 임신률
							<table className="tablestyle11">
								<tr>
									<td>경산우 임신두수</td>
									<td rowSpan="2">× 100 = (&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;) %</td>
								</tr>
								<tr>
									<td>경산우 총두수</td>
								</tr>
							</table>
							</li>
							<li>송아지 생존율
								<ul>
									<li>분만시 생존율
									<table className="tablestyle11">
										<tr>
											<td>분만후 24시간 생존 송아지 두수</td>
											<td rowSpan="2">× 100 = (&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;) %</td>
										</tr>
										<tr>
											<td>임신우 총두수</td>
										</tr>
									</table>
									</li>
									<li>이유율
									<table className="tablestyle11">
										<tr>
											<td>이유두수</td>
											<td rowSpan="2">× 100 = (&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;) %</td>
										</tr>
										<tr>
											<td>분만후 24시간 생존 송아지 두수</td>
										</tr>
									</table>
									</li>
									<li>송아지 산출고
									<table className="tablestyle11">
										<tr>
											<td>이유두수</td>
											<td rowSpan="2">× 100 = (&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;) %</td>
										</tr>
										<tr>
											<td>총교배 암소두수</td>
										</tr>
									</table>
									<p>[일반 농가의 번식 관리 지표(사육규모 100두 기준)]</p>
									<table className="tablestyle">
										<tr>
											<td>항목</td>
											<td>지표</td>
										</tr>
										<tr>
											<td>초종부월령(월)</td>
											<td>14</td>
										</tr>
										<tr>
											<td>분만가격(월)</td>
											<td>12</td>
										</tr>
										<tr>
											<td>분만후 공태기간(일)</td>
											<td>85</td>
										</tr>
										<tr>
											<td>1수태당 수정횟수(회)</td>
											<td>1.6</td>
										</tr>
										<tr>
											<td>1차 수태율(%)</td>
											<td>60</td>
										</tr>
										<tr>
											<td>분만 후 60일 이내 발정재귀율(%)</td>
											<td>85</td>
										</tr>
										<tr>
											<td>분만 후 60 - 84일 교배두수(%)</td>
											<td>90</td>
										</tr>
									</table>
									</li>
								</ul>
							</li>
							<li>번식 기록
								<ul>
									<li>번식성적을 향상시키기 위해서는 자신이 기르고 있는 소에 대하여 번식상태를 개체별로 기록하여야 한다. 기록상태가 양호한 양축농가에서의 송아지 생산율을 살펴보면 80.9 %인데 비해 불량한 농가에서는 54.8 %밖에 되지 않았다. 또한 번식성적을 개체별로 기록하기 이전의 송아지 생산율이 57.1 %였던 농가가 기록을 시작한 후에는 75%로 증가하는 결과였다. 즉 기록을 철저히 함으로써 번식성적이 그만큼 좋아지는 것은 개체관리에 철저해지고 생리적인 이상을 쉽게 발견하여 대책 수립과 함께 예방할 수 있기 때문이다. 따라서 사육하고 있는 소에 대하여 개체별 기록부를 작성하여 발정시기, 교배시기, 발정예정일, 분만예정일, 질병여부 및 번식상의 특성을 빠짐없이 기록하는 습관을 길러야만 한다.
										<p>[번식 기록과 송아지 생산율]</p>
										<table className="tablestyle">
											<tr>
												<td>기록상태</td>
												<td>농가호수</td>
												<td>사육두수</td>
												<td>송아지<br/>생산두수</td>
												<td>생산율(%)</td>
											</tr>
											<tr>
												<td>양호</td>
												<td>2</td>
												<td>34</td>
												<td>28.5</td>
												<td>80.9</td>
											</tr>
											<tr>
												<td>보통</td>
												<td>2</td>
												<td>25</td>
												<td>15.5</td>
												<td>62.0</td>
											</tr>
											<tr>
												<td>불량</td>
												<td>2</td>
												<td>21</td>
												<td>11.5</td>
												<td>54.8</td>
											</tr>
										</table>
										<p>[기록 이전과 이후의 송아지 생산율]</p>
										<table className="tablestyle">
											<tr>
												<td>기록유무</td>
												<td>농가호수</td>
												<td>사육두수</td>
												<td>송아지 생산두수</td>
												<td>생산율(%)</td>
											</tr>
											<tr>
												<td>이전</td>
												<td>2</td>
												<td>14</td>
												<td>8</td>
												<td>57.1</td>
											</tr>
											<tr>
												<td>이후</td>
												<td>2</td>
												<td>20</td>
												<td>15</td>
												<td>75.0</td>
											</tr>
										</table>
									</li>
								</ul>
							</li>
						</ul>
					</li>
				</ul>
			</div>
  );
}

export default Do24;
