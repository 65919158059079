import React from 'react';

import pic276 from 'assets/images/sub/_images/pic276.jpg';
import pic277 from 'assets/images/sub/_images/pic277.jpg';
import pic278 from 'assets/images/sub/_images/pic278.jpg';
import pic279 from 'assets/images/sub/_images/pic279.jpg';
import pic280 from 'assets/images/sub/_images/pic280.gif';

function Do183() {

  return (
    <div className="techinfoview">
      <h1>톨 훼스큐</h1>
      <p>영명 : Tall fescue <br/>학명 : Festuca arundinancea Schr.</p>
      <div style={{width: '60%',float: 'left'}}>
        <h2>원산지</h2>
        <p className="pstyle">&nbsp;&nbsp;&nbsp;유럽, 북부아프리카, 서부시베리아가 원산지로 건포 및 청예로 이용하고 화무지에도 때로는 재배되다. </p>
        <h2>성상</h2>
        <p className="pstyle">&nbsp;&nbsp;&nbsp;뿌리가 깊이 뻗는 영년생 북방형 화본과 목초이다. 직근은 짧으며 굵은 줄기는 예취나 방목에 잘 견디도록 되어 있고 뿌리는 강하고 질기며 수분이 적당한 토양에서는 지하 150cm정도까지 뻗는다. 옆초는 절간보다 짧고 줄기를 느슨하게 싸고 있으며, 작은 엽설을 가지고 있다. 잎은 반질반질하게 광택이 나며 짙은 녹색이다. 톨 훼스큐는 메도우 훼스큐보다 초장이 뚜렷이 크기 때문에 식별이 용이하며 잎이 퍼지고 잎표면이 짙은 녹색을 띤다. 잎은 뚜렷이 골이 파여 있고 거칠어서 메도우 훼스큐와 구분이 된다. 꽃은 원추화서로 가지의 길이는 10∼30cm 정도이며, 이는 수경에 많은 지경이 부착되어 있다. 종자는 그 크기나 모양이 라이그아스와 비슷하나 색깔이 약간 검다.</p>
        <h2>재배</h2>
        <p className="pstyle">&nbsp;&nbsp;&nbsp;기후에 대한 재배적응범위는 넓으나 특히 서늘한 기후조건이 최적조건이며 또한 배수가 잘되는 토양에서 생육이 좋다. 습한 토양에도 생육이 잘되고 알칼리성, 또는 염해지역에도 저항성이 강하며 가뭄에도 강하여 이러한 적응성을 이용하여 비행장이나 운동장, 제방, 척박지역에서도 뿌리가 깊게 뻗기 때문에 끈질기게 오래 유지된다. 토양개량에도 우수하고 특히 척박지에서 더욱 효과가 있다. 그 이유는 뿌리가 15cm 깊이의 토양에 분포되고 매년 뿌리의 일부가 죽어 토양에 축적되여 많은 유기물이 형성되기 때문이다.  어릴때에는 생육이 느리므로 초지조성 후 회 이용까지는 상당한 기간이 소요된다. 10a당 생초수량은 2,500∼4,900kg으로 품종으로는 켄터키31 훼스큐(Ky31 Fescue), 화운(Fawn),사포로나 마나니(Sappororymanani), 알타(Alta), 에스-170(S-170)등이 있다.</p>
        <h2>이용</h2>
        <ul>
          <li>건초
            <p>줄기밑동 부위에 잎이 많기 때문에 건초조제하는 데 제약을 받게 되지만 화본과와 두과 혼파지에서 비료를 충분히 사용한 초지에서는 수량이 많아 좋은 건초를 조제할 수가 있다. 양질 건초를 조제하기 위하여는 개화전이나 출수초기에 예취를 하여야 한다.</p>
          </li>
          <li>방목 및 청예
            <p>청초 생산량이 많기 때문에 청초로도 적합하고 관수나 비료를 충분히 사용하였을 때는 더욱 수량이 많아진다. 년중 수량이 많고 장기간 많은 수량을 생산할 수 있는 것이 유리한 점이다. 어렸을 때의 잎은 수분이 많고 기호성이 매우 좋으나 생장할수록 억세지고 섬유질이 많아서 기호성이 떨어진다. 그러나 두과 목초와 혼파하여 기호성을 높일 수 있으며 영양가도 높일 수 있다. 다른 화본과 목초와 혼파하였을 때는 털 훼스큐가 우점할 염려가 있다.<br/>&nbsp;&nbsp;&nbsp;또한 톨 훼스큐는 청초로 이용하는데 많은 제한이 있어 소를 방목할 때, 때로는 영양면에서 문제가 생긴다. 습한 지역에서는 늦은 봄이나 여름철에 청초를 급여했을 때 맥각(Ergot)중독증을 일으켜 가축에게 피해를 주므로 혼파를 하여 이용하는 것이 좋다.</p>
          </li>
        </ul>
      </div>
      <div style={{width: '40%', float: 'left'}}>
        <table>
          <tr>
            <td><img src={pic276} className="nonborder2" alt=""/></td>
          </tr> 
          <tr>
            <td><img src={pic277} className="nonborder2" alt=""/></td>
          </tr>
          <tr>
            <td><p style={{textAlign: 'center'}}>유식물의 생육과정</p></td>
          </tr>
          <tr>
            <td><img src={pic278} className="nonborder2" alt=""/></td>
          </tr>
          <tr>
            <td><p style={{textAlign: 'center'}}>출수과정</p></td>
          </tr>
          <tr>
            <td><img src={pic279} className="nonborder2" alt=""/></td>
          </tr>
          <tr>
            <td><p style={{textAlign: 'center'}}>종 자</p></td>
          </tr>
          <tr>
            <td><img src={pic280} className="nonborder2" alt=""/></td>
          </tr>
          <tr>
            <td><p style={{textAlign: 'center'}}>식물체와 이식모양</p></td>
          </tr>  
        </table>
      </div>
    </div>
  );
}

export default Do183;