import React from 'react';

import pic361 from 'assets/images/sub/_images/pic361.gif';
import pic362 from 'assets/images/sub/_images/pic362.gif';
import pic363 from 'assets/images/sub/_images/pic363.gif';
import pic364 from 'assets/images/sub/_images/pic364.gif';
import pic365 from 'assets/images/sub/_images/pic365.gif';
import pic366 from 'assets/images/sub/_images/pic366.gif';

function Do205() {

  return (
    <div className="techinfoview">
      <h1>처녀소 우사(6~24개월령)</h1>
      <p className="pstyle">전환기(3∼5개월령) 이후의 처녀소를 위한 우사의 형태에는 몇 가지가 있는데, 먼저 우군의 영양, 건강 및 번식상태 등을 고려하여 분리한다. 최소한 임신우와 미임신우는 분리하여야 한다. 처녀소 우사의 기본적인 기능은 취급을 용이하게 하며, 수정 장소를 제공하고, 소의 관찰을 용이하게 하는 것이다.  처녀소는 성장함에 따라서 스트레스에 잘 견디긴 하지만, 찬바람과 무더위 등과 같은 열악한 환경으로부터 보호받아야 한다. 전면이 개방된 축사의 경우에는 특히 겨울철 샛바람을 받지 않도록 우사구조에 주의를 기울여야 한다.</p>
      <img src={pic361} className="nonborder" width="50%" alt=""/>
      <p>그림 32. 송아지 우사의 설계도</p>
      <h2>후리스톨 우사</h2>
      <p className="pstyle">처녀소의 나이나 체중에 따른 우상의 크기는 그림 33에 나타나 있다. 후리스톨 우사는 깔짚 우사에 비하여 훨씬 적은 양의 깔짚이 소모되지만, 자주 분뇨를 제거 (주1∼2회)해야 하고, 겨울철에 분뇨가 바닥에 얼어붙는 문제를 없애기 위하여 바닥에 틈을 내는 것이 바람직하다. 후리스톨 우사는 사료급여 방법과 분뇨처리 방법에 따라 몇 가지 형태로 나눈다. </p>
      <ul>
        <li>2열식 후리스톨 우사(two-row free stall barn) 
          <ul>
            <li>후리스톨 우사에서는 분뇨를 제거하는 동안 소를 이동시킬수 없기 때문에, 트랙터 스트래퍼로 분뇨를 제거할 수 없다. 따라서, 자동스크래퍼를 이용하거나 혹은 바닥에 틈(slot)을 만들거나 아니면 물로 수세하는 방법으로 분뇨를 제거한다. 중앙 사료급여통로는 사료급여를 일륜차(cart)로 할 경우에는 폭을 8＇로 하고, 사료차(wagon)를 이용하여 사료를 급여할 경우에는 폭을 16＇- 18＇로 한다.<br/>
              <img src={pic362} className="nonborder" width="40%" alt=""/>
              <p>그림 33. 2열 대두식 후리스톨 우사</p>
            </li>
            <li></li>
          </ul>
        </li>
        <li>2열 등급식 후리스톨 우사(two-row graduated freestall barn) 
          <ul>
            <li>우사의 측면벽 쪽에 턱(curb)를 설치하므로 후리스톨의 길이를 변화시킨 우사형태로, 한쪽 끝의 우상이 다른 한쪽 끝의 우상에 비하여 짧다. 통로 바닥은 분뇨빠짐발판(grated gutter)이 있는 우상 쪽으로 경사지가 지도록 하여 자가청소되도록 한다. 분뇨빠짐발판이 덮인 자연흐름식(중력식) 분뇨통로(gravity gutter), 수세식(hush gutter) 또는 반 크리너(barn clean)를 이용하여 분뇨를 제거한다. 세절깔짚을 이용하여 깔짚과 분뇨빠짐발판을 통하여 분뇨통로 잘 빠져나가도록 한다. 배분뇨구가 얼지 않도록 우사내 온도는 영상을 유지하도록 하며, 자연환기가 잘 되도록 한다. </li>
          </ul>
        </li>
        <li>칸막이가 설치된 2열식 후리스톨 우사(two-row gated freestall barn) 
          <ul>
            <li>열고 닫을 수 있는 칸막이가 설치된 2열식 후리스톨 우사는 사료조가 한쪽편에만 있기 때문에, 우상의 배치에 따라서 사료급여를 짐수레 또는 짐차를 이용하는 다양한 사료급여 형태를 설계할수 있다. 후리스톨를 3열로 할 경우에는 사료조의 크기가 제한이 되므로, 늘 사료조에 사료가 있도록 하여 사료에 대한 경쟁이 없도록 하는 것이 중요하다. 우사형태를 대두식(그림34)과 대미식(그림35)으로 할 수 있으며, 우사 밖에 운동장을 마련할 수 있다. 한편 통로를 청소할 때 칸막이를 이용하며 다른 한편으로 소들을 가둘수 있으므로, 우사의 분뇨를 스크래퍼가 달린 트랙터를 이용하여 수거할 수 있다.<br/>
              <img src={pic363} className="nonborder" width="50%" alt=""/>
              <p>그림 34. 2열 대두식 후리스톨 우사의 설계도</p>
            </li>
          </ul>
        </li>
        <li>칸막이가 설치된 중앙통로식 후리스톨 우사(drive-through gated freestall barn) 
          <ul>
            <li>이는 칸막이(gate)가 설치된 ③의 2열식 후리스톨 우사를 양쪽(겹)으로 배열하고, 중앙사료급여 통로를 이용하여 양편에 있는 소들에게 사료를 줄수 있도록 한 우사형태이다. 대규모 낙농경영에 알맞는 시설이다.<br/>
              <img src={pic364} className="nonborder" width="50%" alt=""/>
              <p>그림 35. 2열 대미식 후리스톨 우사의 설계도</p>
            </li>
          </ul>
        </li>
      </ul>
      <h2>깔짚 우사(bedded pack housing)</h2>
      <p className="pstyle">&nbsp;&nbsp;&nbsp;일반적으로 깔짚 우사는 운동장이 있고 사료를 밖에서 급여하는 경우에 주로 적용된다(그림 36,37). 그러나 사료급여 및 청소 통로를 포함한 전지역에 지붕을 설치하면 여러 가지 면에서 유리한 점이 많다. 각 우군에는 깔짚이 깔린 충분한 휴식장을 제공하여야 하는데, 깔짚 휴식장은 지붕이 있어야 하며, 샛바람이 차단되고 안온해야 한다. 휴식장의 바닥에 자갈이나 콘크리트를 깔 수 있는데, 콘크리트 바닥인 경우에는 분뇨통로 쪽으로 경사지게 해야 한다. 분뇨와 깔짚의 제거는 일년에 2∼4회 실시하며 가축을 깨끗하게 유지하기 위해서 위에 덧잎혀 깔아주기 때문에 많은 양의 깔짚이 필요하다. <br/>&nbsp;&nbsp;&nbsp;사료급여 통로는 포장하여야 하고 1주에 2∼3회 청소해 주는데, 지붕이 연장되어 있으면 빗물에 의한 사료의 손실을 줄일 수 있다. 운동장이 마련되어 있을 경우에는 사료급여 통로가 축사와 멀리 떨어지기 때문에 지붕을 연장시킬 수 없으므로, 빗물이 유출되어 지하 및 지표수가 오염되지 않도록 운동장의 빗물(runoff)을 철저히 관리 해야 하며, 사료조에만 별도로 지붕을 설치할 수도 있다. 일반적으로 이러한 우사형태에서는 사료조의 공간이 충분하지 못하여 사료에 대한 경쟁이 심하기 때문에 사료조에 사료가 항상 남아 있도록 하여야 한다.</p>
      <img src={pic365} className="nonborder" width="50%" alt=""/>
      <p>그림 36. 미경산우와 건유우를 위한 깔짚우사의 설계도</p>
      <ul>
        <li>역방향 경사식 깔짚우사(counter-sloped barn)
          <ul>
            <li>상대적으로 건축비가 적게 소요되는 우사형태로 중간의 트랙터 청소통로 쪽으로 휴식장 바닥과 사료급여 통로바닥이 서로 역방향으로 8%(1＂/ft)만큼씩 경사지게 한 우사이다 (그림 37). 시설물 전체에 지붕을 설치함으로서 빗물의 유입을 방지할 수 있다. 6개월령 이하와 분만 3개월 전의 처녀소에게는 이러한 형태의 우사는 권장되지 않는다.<br/>
              <img src={pic366} className="nonborder" width="50%" alt=""/>
              <p>그림 37. 후보축 역방향 경사식 깔짚우사의 설계도</p>
            </li>
          </ul>
        </li>
        <li>선택적인 옥외운동장(ouside lots)설치 
          <ul>
            <li>옥외운동장 설치가 바람직한 경우에는 설계시 옥외운동장을 포함시킬 수 있다. 옥외운동장을 설치하면 우사내에 분뇨가 쌓이는 것을 감소시킬 수는 있지만 청소와 적절한 관리를 해야 한다. 옥외운동장은 건유우의 발과 다리 건강에 유익하다. <br/>옥외공운동장으로 목초지를 사용할 경우에는 가축의 밀도를 조절하여 목초가 다시 자랄 수 있도록 하여야 한다. 목초지가 여러차례에 걸쳐 심하게 이용되면 풀이 없는 나지로 전환되기 때문에 적절한 관리가 요구되며, 가능하다면 목초지를 윤번으로 이용하여 목초가 성장할 수 있도록 휴식기를 준다. <br/>옥외공터를 운동장으로 사용할 경우에는 목초가 거의 또는 전혀 자라지 않는데, 여름철에는 진흙투성이가 되어 처녀소가 비유전 유방염에 걸리기가 쉽다. 따라서 날씨가 좋을 때만 이용하는 것이 바람직하다. 이러한 문제는 운동장에 콘크리트를 포장하면 해결할 수 있다. 빗물 유출수가 수질을 오염시키지 않도록 각별한 관리가 요구된다.</li>
          </ul>
        </li>
      </ul>
    </div>
  );
}

export default Do205;