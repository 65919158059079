import React from 'react';

import Pic196 from 'assets/images/sub/_images/pic196.gif';

function Do49() {

  return (
    <div className="techinfoview">
				<h1>번식관리</h1>
				<p className="pstyle">높은 번식률은 높은 낙농소득을 올리는데 필수적인 요인이다. 수태율을 높여 번식간격을 12개월 정도로 유지하기 위한 번식관리 요령을 열거하면 다음과 같다. </p>
				<ul>
					<li>먼저 번식효율에 대한 목표를 설정한다. 즉 경산우는 분만후 85일 이내에 수정이 되도록 하여 매년 1회의 분만이 가능하도록 하며, 발정 발견율을 80% 이상으로 하고, 첫번째 인공수정에 의한 수태율이 60% 이상이 되도록함은 물론 수태당 수정회수가 1.5회 이하가 되게 하며 불임에 의한 도태율을 5% 이하로 하는 목표를 세운다. 그리고 정액은 예상차(PD) 값이 높은 종모우의 것을 사용한다. </li>
					<li>분만후 85일 이내에 수태가 되게 하려면 다음 요령을 따라야 한다. ① 분만 후 1개월령에 수의사로 하여금 번식기관의 이상유무를 검진하도록 하고 이상이 있을 경우는치료해야 한다. ② 분만후부터 발정상황을 면밀하게 관찰해서 발정주기표에 기록해 둔다. ③ 분만후 40일 이후에 발정이 왔을 때 수정 시킨다. ④ 발정이 오지 않는 개체가 확인되면 수정후 30∼60일이 지난후에 임신감정을 실시한다. ⑤ 수의사로 하여금 후산정체, 외음부에 비정상적인 점액이 흐르는 원인, 미발전의 원인 등을 진단하도록 한다. ⑥ 발정예정일, 발정일자, 수정일자, 질병 및 치료상황 등을 정확하게 기록하도록 해야 한다. </li>
					<li>일반적인 발정증상을 보면 다음과 같다. ① 흥분된 상태로 운동량이 많아지며 ② 자주 소리 지르고 ③ 다른 소의 냄새를 맡고 기어오르려 하며 ④ 외음부가 충혈이 되고(부어 오르고) 붉은색의 점액이 나와 꼬리에까지 묻는다. 이 점액은 발정후 2∼3일까지 남아있는 수도 있다. ⑤ 다른소가 올라타도 가만히 서 있으며 ⑥ 사료섭취량이 줄고 우유생산이 감소되기도 한다. </li>
					<li>발정 지속시간은 보통 15시간 내외이며 30분에서 20시간 사이에 분포되어 있다. </li>
					<li>발정중기 및 후기에 수정시키면 수태율이 가장 높다. 즉 발정초기에 종부하면 수태율이 44% 내외이며, 중기는 83%, 후기는 75%, 발정이 끝난 후 6시간 또는 24시간 후에 종부했을때는 63%, 12%에 불과한 수태율만 기대할 수 있다. </li>
					<li>하루에 2차례에 걸쳐 한번에 30분씩 소를 관찰, 발정한 소를 가려낸다. 지금까지 관찰한 결과에 의하면, 하루 24시간 계속 관찰하면 발정된 소는 90∼100%까지 발견해낼수 있으며 하루 2차례에 각가 30분씩 관찰했을 겨우는 80∼90%, 착유시 또는 사료급 여시등 일상 작업중에만 관찰할 겨우는 50∼60%밖에 발견하지 못한다고 한다. </li>
					<li>아침 일찍과 저녁 늦게 관찰하면 발견율을 높일 수 있다. 그리고 특히 계류식 우사에서 소를 사육할 겨우에는 소들을 운동장으로 몰아 넣고 관찰하면 더욱 효과적으로 발견할 수 있다. 비계류식 우사의 경우에도 콘크리트 바닥에서는 소들이 자유롭게 행동하지 못하는 경우가 많으므로 흙바닥으로 되어 있는 운동장에 있을 때는 발정 발견이 용이하다. </li>
					<li>연구 결과에 의하면 불임원인의 90% 이상이 발정발견의 실패, 최적 수정시기의 실기,수정기술의 미비 등에 있다고 하며, 10% 정도는 미발정 또는 불규칙한 발정이 원인이 되고 있다. </li>
					<li>앞에서 살펴본 바와 같이 영양상태 또한 수태율에 영향을 미치는 주요한 요인이다. 따라서 사료에 에너지와 단백질은 물론 칼슘, 인, 칼륨, 유황, 나트륨, 망간, 아연, 코발트, 셀리니움등 광물질과 비타민 A, D, E등이 걸핍되지 않도록 해야 한다. </li>
				</ul>
				<img src={Pic196} style={{width: '50%'}} alt=""/>
				<p style={{width: '50%', textAalign:'center'}}>그림 8. 수정시기에 따른 수태율의 비교</p>
			</div>
  );
}

export default Do49;
