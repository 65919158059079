import React, { useState , useEffect, useRef } from 'react';
import { postState, getState, patState } from 'utils/api';
import { useNavigate } from 'react-router-dom';

// 컴포넌트
import ErrorMessage from 'components/errorMessage/errorMessage';

// scss
import './girlcowability.scss';

function GirlcowAbility() {

  // 라우터
  const navigate = useNavigate();

  // 데이터
  const [ targetData , setTargetData ] = useState({});

  // 에러처리
  const [isModalOpen, setIsModalOpen] = useState(false); // 모달 추가
  const [errorMessage, setErrorMessage] = useState(''); 

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => {
    setIsModalOpen(false);
    setErrorMessage(''); // 모달이 닫힐 때 에러메세지 초기화
  };

  // 데이터 불러오기
  // eslint-disable-next-line no-restricted-globals 
  const baseDir=`${location.pathname.toLowerCase()}/`;  
  const [ mainData , setMainData ] = useState();  
  const [ currentAvg , setCurrentAvg ] = useState([]);

  const [ props , setProps] = useState({
    id:localStorage.getItem('milkFarmCode'),
    passwd:localStorage.getItem('milkFarmPwd '),    
  });
  
  const advanceDir=`/milk/advance/${localStorage.getItem('milkFarmCode')}/`;  
  

  useEffect(() => {

    postState(baseDir,props)
    .then(getdata => {      
      if (getdata !== undefined) {
        setMainData(getdata);    
      }
    })
    .catch(error => {           
      setErrorMessage(error.message);
      openModal();
    });
    
  }, [props]);

  useEffect(() => {
    getState(advanceDir)
    .then(getdata => {      
      if (getdata !== undefined) {
        setTargetData({val_mc:getdata.val_mc,
        val_fc:getdata.val_fc,
        val_pc:getdata.val_pc,
        val_sc:getdata.val_sc,
        val_cc_16:getdata.val_cc_16,
        val_udc:getdata.val_udc,
        val_flc:getdata.val_flc,
        val_ktpi:getdata.val_ktpi
      });    
      }
    })
    .catch(error => {           
      setErrorMessage(error.message);
      openModal();
    });    
  }, [props]);

  const validateTargetData = (e , key) => {
    const newTargetData = { 
      ...targetData ,
      [key]: e.target.value
    };
    if ( Object.values(newTargetData).reduce((acc, cur) =>acc + Number(cur),0) > 100) {
      alert('개량목표의 총 합은 100을 넘지 않아야 합니다.');
      e.target.value = targetData[key];
      return false;
    }

    setTargetData(newTargetData);
    return true;
  };

  const [ targetTotal , setTargetTotal] = useState(0);
  useEffect(() => {
    setTargetTotal(
      Object.values(targetData).reduce((acc, cur) =>acc + Number(cur),0)
    );

  },[targetData]);
  
  
  const onClickToSave = () =>{    
    // console.log('### targetdata : ', targetData);    
    patState(advanceDir,targetData)
    .then(getdata=> {   
      if (getdata.result === 'OK') {
        if (window.confirm('개량목표가 저장되었습니다. 정액선정 페이지로 이동합니다.')) {
          navigate('/milk/semenselect');
        }
      }
      if (getdata.result !== 'OK') {
        setErrorMessage('개량목표저장에 실패하였습니다. 다시 시도해주세요.');
        openModal();
      }
    })
    .catch(error => {           
      setErrorMessage(error.message);
      openModal();
    });
    
  };
  // 농가평균과 전국평균 상위 목록 중 가장 근사한 값을 찾는 함수
  const findClosestValues = () => {

    if (mainData) {
      const farmKeys = Object.keys(mainData.plot_data3[0]); // 목장평균의 키 목록

      const farmAvg = mainData.plot_data3[0]; // 목장평균
      const windAvg = mainData.plot_data4; // 전체평균 리스트 5개

      const closestIndexes = []; // 가장 근사한 값의 인덱스를 담을 배열

      farmKeys.forEach((key, _) => {
        const newKey = key.replace('avg','').replace('_16',''); // 키값을 정제

        let closestIndex = 0; // 기본값은 0
        let closetDiffrence = Math.abs(farmAvg[key] - windAvg[0][newKey]); // 목장평균 - 전국평균의 기본값 ( index 0과 같음)

        windAvg.forEach((list, index) => {
          const currentDiffrence = Math.abs(farmAvg[key] - list[newKey]); // 현재 목장평균 - 전국평균

          if (closetDiffrence > currentDiffrence) { // 만약 현재값이 더 작다면 더 근사한 값
            closestIndex = index; // 현재 index를 가장 근사한 값으로 설정
            closetDiffrence = currentDiffrence; // 현재 차이를 가장 근사한 값으로 설정
          }
        });
        
        
        closestIndexes.push(closestIndex); // 가장 근사한 값의 인덱스를 배열에 추가
      });

      return closestIndexes;
    }

    return [];
  };

  useEffect(() => {
    setCurrentAvg(findClosestValues());
  }, [mainData]);
  
  return (
    <div className='girlcowability'>
      <ErrorMessage isOpen={isModalOpen} content={errorMessage} closeModal={closeModal}/>
      <h2>농가유전능력</h2>
      <ul>
        <li>
          <div className='girlcowBtn'>
            <p>개량목표 가중치 설정</p>
            <button type='button' onClick={onClickToSave}>개량목표저장</button>
          </div>
          <div className='girlcowAbilityTable'>
            <table>
              <thead>
                <tr>
                  <th rowSpan='2'>구분</th>
                  <th colSpan='4'>유성분&#40;EBV&#41;</th>
                  <th colSpan='3'>체형&#40;EBV&#41;</th>
                  <th rowSpan='2'>KTPI&#40;EBV&#41;</th>
                </tr>
                <tr>
                  <th>유량&#40;kg&#41;</th>
                  <th>유지방&#40;kg&#41;</th>
                  <th>유단백&#40;kg&#41;</th>
                  <th>체세포&#40;천/Cell&#41;</th>
                  <th>종합점수</th>
                  <th>유방</th>
                  <th>지제</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>표준점수</th>
                  <td className='numberCon'>{mainData && mainData.plot_data[0].mc}</td>
                  <td className='numberCon'>{mainData && mainData.plot_data[0].fc}</td>
                  <td className='numberCon'>{mainData && mainData.plot_data[0].pc}</td>
                  <td className='numberCon'>{mainData && mainData.plot_data[0].sc}</td>
                  <td className='numberCon'>{mainData && mainData.plot_data[0].cc}</td>
                  <td className='numberCon'>{mainData && mainData.plot_data[0].udc}</td>
                  <td className='numberCon'>{mainData && mainData.plot_data[0].flc}</td>
                  <td className='numberCon'>{mainData && mainData.plot_data[0].ktpi}</td>
                </tr>
                <tr>
                  <th>개량목표</th>
                  {
                    targetData && 
                    Object.keys(targetData).map((key, index) => (
                      <td key={index}>
                        <select 
                          defaultValue={targetData && targetData[key]}
                          onChange={(e) => validateTargetData(e, key)}
                        >
                          <option value='0'>0</option>
                          <option value='5'>5</option>
                          <option value='10'>10</option>
                          <option value='15'>15</option>
                          <option value='20'>20</option>
                          <option value='25'>25</option>
                          <option value='30'>30</option>
                          <option value='35'>35</option>
                          <option value='40'>40</option>
                          <option value='45'>45</option>
                          <option value='50'>50</option>
                          <option value='55'>55</option>
                          <option value='60'>60</option>
                          <option value='65'>65</option>
                          <option value='70'>70</option>
                          <option value='75'>75</option>
                          <option value='80'>80</option>
                          <option value='85'>85</option>
                          <option value='90'>90</option>
                          <option value='95'>95</option>
                          <option value='100'>100</option>
                        </select>
                      </td>
                    ))
                  }
                </tr>
                <tr>
                  <th>개량목표 합계</th>
                  <td 
                    colSpan='8'
                    className={targetTotal < 100 ? 'error' : undefined}
                  >
                    개량목표의 총 합은 100 입니다. 현재 &#40; {targetTotal} &#41;
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <span>※ 우선순위 개량항목을 가장 높게 설정&#40;전국평균 0대비 표준점수 값이 높을 수록 개량도가 높음, 단 체세포는 낮을수록 개량도가 높음&#41;</span>
        </li>

        <li>
          <p>전국 대비 목장별 유전능력 평가 결과</p>
          <div className='girlcowAbilityTable'>
            <table>
              <thead>
                <tr>
                  <th rowSpan='2'>구분</th>
                  <th colSpan='4'>유성분&#40;EBV&#41;</th>
                  <th colSpan='3'>체형&#40;EBV&#41;</th>
                  <th rowSpan='2'>KTPI&#40;EBV&#41;</th>
                  <th rowSpan='2'>근친계수&#40;%&#41;</th>
                  <th rowSpan='2'>경산우두수</th>
                  <th rowSpan='2'>평가두수</th>
                </tr>
                <tr>
                  <th>유량&#40;kg&#41;</th>
                  <th>유지방&#40;kg&#41;</th>
                  <th>유단백&#40;kg&#41;</th>
                  <th>체세포&#40;천/Cell&#41;</th>
                  <th>종합점수</th>
                  <th>유방</th>
                  <th>지제</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>전국평균</th>
                  <td className='numberCon'>{mainData && (+mainData.plot_data2[0].mc).toFixed(2)}</td>
                  <td className='numberCon'>{mainData && (+mainData.plot_data2[0].fc).toFixed(2)}</td>
                  <td className='numberCon'>{mainData && (+mainData.plot_data2[0].pc).toFixed(2)}</td>
                  <td className='numberCon'>{mainData && (+mainData.plot_data2[0].sc).toFixed(2)}</td>
                  <td className='numberCon'>{mainData && (+mainData.plot_data2[0].cc).toFixed(2)}</td>
                  <td className='numberCon'>{mainData && (+mainData.plot_data2[0].udc).toFixed(2)}</td>
                  <td className='numberCon'>{mainData && (+mainData.plot_data2[0].flc).toFixed(2)}</td>
                  <td className='numberCon'>{mainData && (+mainData.plot_data2[0].ktpi).toFixed(2)}</td>
                  <td className='numberCon'>{mainData && (+mainData.plot_data2[0].inb).toFixed(2)}</td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <th>목장평균</th>
                  <td className='numberCon'>{mainData && mainData.plot_data3[0].avgmc.toFixed(2)}</td>
                  <td className='numberCon'>{mainData && mainData.plot_data3[0].avgfc.toFixed(2)}</td>
                  <td className='numberCon'>{mainData && mainData.plot_data3[0].avgpc.toFixed(2)}</td>
                  <td className='numberCon'>{mainData && mainData.plot_data3[0].avgsc.toFixed(2)}</td>
                  <td className='numberCon'>{mainData && mainData.plot_data3[0].avgcc_16.toFixed(2)}</td>
                  <td className='numberCon'>{mainData && mainData.plot_data3[0].avgudc.toFixed(2)}</td>
                  <td className='numberCon'>{mainData && mainData.plot_data3[0].avgflc.toFixed(2)}</td>
                  <td className='numberCon'>{mainData && mainData.plot_data3[0].avgktpi.toFixed(2)}</td>
                  <td className='numberCon'>{mainData && mainData.plot_data3[0].avginb.toFixed(2)}</td>
                  <td className='numberCon'>{mainData && mainData.plot_data3[0].p1.toFixed(2)}</td>
                  <td className='numberCon'>{mainData && mainData.plot_data3[0].p2.toFixed(2)}</td>
                </tr>
                <tr>
                  <th>차이</th>
                  <td className='numberCon'>{mainData && (mainData.plot_data3[0].avgmc-mainData.plot_data2[0].mc).toFixed(2)}</td>
                  <td className='numberCon'>{mainData && (mainData.plot_data3[0].avgfc-mainData.plot_data2[0].fc).toFixed(2)}</td>
                  <td className='numberCon'>{mainData && (mainData.plot_data3[0].avgpc-mainData.plot_data2[0].pc).toFixed(2)}</td>
                  <td className='numberCon'>{mainData && (mainData.plot_data3[0].avgsc-mainData.plot_data2[0].sc).toFixed(2)}</td>                  
                  <td className='numberCon'>{mainData && (mainData.plot_data3[0].avgcc_16-mainData.plot_data2[0].cc).toFixed(2)}</td>
                  <td className='numberCon'>{mainData && (mainData.plot_data3[0].avgudc-mainData.plot_data2[0].udc).toFixed(2)}</td>
                  <td className='numberCon'>{mainData && (mainData.plot_data3[0].avgflc-mainData.plot_data2[0].flc).toFixed(2)}</td>
                  <td className='numberCon'>{mainData && (mainData.plot_data3[0].avgktpi-mainData.plot_data2[0].ktpi).toFixed(2)}</td>                  
                  <td className='numberCon'>{mainData && (mainData.plot_data3[0].avginb-mainData.plot_data2[0].inb).toFixed(2)}</td>                                    
                  <td></td>
                  <td></td>                  
                </tr>
              </tbody>
            </table>
          </div>
        </li>

        <li>
          <p>전국 유전능력평가 상위 수준별 비교</p>
          <div className='girlcowAbilityTable'>
            <table>
              <thead>
                <tr>
                  <th rowSpan='2'>구분</th>
                  <th colSpan='4'>유성분&#40;EBV&#41;</th>
                  <th colSpan='3'>체형&#40;EBV&#41;</th>
                  <th rowSpan='2'>KTPI&#40;EBV&#41;</th>
                  <th rowSpan='2'>근친계수&#40;%&#41;</th>
                </tr>
                <tr>
                  <th>유량&#40;kg&#41;</th>
                  <th>유지방&#40;kg&#41;</th>
                  <th>유단백&#40;kg&#41;</th>
                  <th>체세포&#40;천/Cell&#41;</th>
                  <th>종합점수</th>
                  <th>유방</th>
                  <th>지제</th>
                </tr>
              </thead>
              <tbody>
                {mainData &&
                  mainData.plot_data4.map((data, index) => (
                    <tr key={index}>
                      <th>{data.per} %</th>
                      <td className={currentAvg[0] === index ? 'current' : ''}>{(+data.mc).toFixed(2)}</td>
                      <td className={currentAvg[1] === index ? 'current' : ''}>{(+data.fc).toFixed(2)}</td>
                      <td className={currentAvg[2] === index ? 'current' : ''}>{(+data.pc).toFixed(2)}</td>
                      <td className={currentAvg[3] === index ? 'current' : ''}>{(+data.sc).toFixed(2)}</td>
                      <td className={currentAvg[4] === index ? 'current' : ''}>{(+data.cc).toFixed(2)}</td>
                      <td className={currentAvg[5] === index ? 'current' : ''}>{(+data.cc).toFixed(2)}</td>
                      <td className={currentAvg[6] === index ? 'current' : ''}>{(+data.udc).toFixed(2)}</td>
                      <td className={currentAvg[7] === index ? 'current' : ''}>{(+data.flc).toFixed(2)}</td>
                      <td className={currentAvg[8] === index ? 'current' : ''}>{(+data.inb).toFixed(2)}</td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div> 
        </li>
      </ul>
    </div>
  );
}

export default GirlcowAbility;
