import React from 'react';

import pic729 from 'assets/images/sub/_images/pic729.jpg';
import pic730 from 'assets/images/sub/_images/pic730.jpg';
import pic731 from 'assets/images/sub/_images/pic731.jpg';

function Do152() {

  return (
    <div className="techinfoview">
      <h1>전염성 각 결막염</h1>
      <p className="pstyle">이 병은 모락셀라 보비스(Moraxella bovis)라는 세균이 눈의 각막이나 결막에 침입하여 눈의 흰자위(白色部分)가 핑크색(분홍색)으로 되고 심한 경우에는 궤양을 일으켜 실명(失明)하는 일이 있으며 여름철 방목하는소(측히 송아지)에 다발한다. </p>
      <div className="leftpart">
        <h2>증상</h2>
        <ul>
          <li>최초에 눈에 이상이 발견되어도 다른 전신증상은 거의 보이지 않는다. </li>
          <li>초기에는 눈의 각막에 직경 2∼3mm 정도의 반점이 형성되며 눈물을 많이 흘리고 눈이 충혈된다. </li>
          <li>중기 증상으로는 환축은 직사광선을 기피하고 각막의 백탁이 확대되며 충혈은 더욱 심해진다.(대개 초기∼중기에 자연 회복되는 경우가 많다.) </li>
          <li>다른 세균이나 바이러스가 혼합감염되면 더욱 증상이 악화되어 각막궤양과 화농이 생기며 마침내 각막에 구멍이 생겨 실명한다.</li>
        </ul>
        <h2>목장에서의 처치</h2>
        <ul>
          <li>발병된 소가 발견되면 즉시 격리숭요한다. </li>
          <li>이 병의 원인균에는 항생물질(페니실린, 크로람페니콜, 콜리스친)과 썰파제가 유효하다(특히 병의 초기에) </li>
          <li>적어도 이러한 항생물질의 치료는 1주일 정도 계속되어야 한다.(현장에서는 유방염 연고를 대신 사용하여도 효과가 좋다.)</li>
        </ul>
      </div>
      <div className="rightpart">
        <img src={pic729} className="nonborder" alt=""/>
        <img src={pic730} className="nonborder" alt=""/>
        <img src={pic731} className="nonborder" alt=""/>
      </div>
    </div>
  );
}

export default Do152;