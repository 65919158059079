import React from 'react';

// constants
import { BOARD_CODE, BOARD_NAME } from 'constants/backOfficeBoard';

// components
import BoardView from '../_components/BoardView';

function TestDtlPushPlanView() {
  return (
    <BoardView
      boardCode={BOARD_CODE.TEST_DTL_PUSH_PLAN}
      boardName={BOARD_NAME.TEST_DTL_PUSH_PLAN}
      boardUrl="testDtlPushPlan"
    />
  );
}

export default TestDtlPushPlanView;
