import React from 'react';

import pic723 from 'assets/images/sub/_images/pic720.jpg';
import pic724 from 'assets/images/sub/_images/pic721.jpg';

function Do150() {

  return (
    <div className="techinfoview">
      <h1>백혈병</h1>
      <p className="pstyle">이 병은 성우(成牛)에 볼 수 있는 일종의 백혈구 암으로서 소의 백혈병 바이러스에 기인하는 전염병이다. 감염 양식은 임신중 모체로부터 태반을 통해 감염되거나 감염된 소의 타액이나 흡혈곤충에 의한 혈액의 매개로 이루어지고 있다.</p>
      <div className="leftpart">
        <h2>증상</h2>
        <ul>
          <li>이 병은 2세 이상의 성우 특히 5∼10세 사이의 성우에 많이 발생된다
            <ul>
              <li> 증상은 바이러스의 침입 부위에 따라 다르나 대개 임파절이 붓고 열이나며 쇠약해진다.</li>
              <li>식욕감퇴, 원기소실과 점막의 빈혈이 나타나며 더욱 심해지면 기립불능에 빠져 1개월 이내에 거의 폐사한다.</li>
              <li>본 병의 특징적인 증상은 혈액내의 백혈구와 이형(異形)임파구의 이상 증가이다.</li>
            </ul>
          </li>
        </ul>
        <h2>목장에서의 처치</h2>
        <ul>
          <li>임상 증상과 혈액검사를 통해 분명하게 백혈병으로 진단이 내려지면 도살처분해야 되며 동거하는 소중에 항체조사에서 양성으로 진단된 것은 격리시켜야 한다. 현재로서는 치료법이 없다.<br/>※ 진단은 가축위생 연구소에 무균적으로 채취한 혈액 샘플을 보내면 된다.</li>
        </ul>
      </div>
      <div className="rightpart">
        <img src={pic723} className="nonborder" alt=""/>
        <img src={pic724} className="nonborder" alt=""/>
      </div>
    </div>
  );
}

export default Do150;