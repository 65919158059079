import React from 'react';

import pic675 from 'assets/images/sub/_images/pic675.jpg';
import pic676 from 'assets/images/sub/_images/pic676.jpg';
import pic677 from 'assets/images/sub/_images/pic677.jpg';
import pic678 from 'assets/images/sub/_images/pic678.jpg';

function Do135() {

  return (
    <div className="techinfoview">
      <h1>요석증</h1>
      <p className="pstyle">이 병은 오줌 속에 녹아 있는 무기염류(無機鹽類)가 신장 또는 방광에서 요석을 형성해 배뇨관(排尿管)이나 요도(尿道)를 막아 버림으로 배뇨 장애를 일으키는 질병이다. (특히 거세한 홀스타인 수소에 발생하기 쉽다.)</p>
      <div className="leftpart">
        <h2>원인</h2>
        <ul>
          <li>농후사료의 과잉급여와 조사료의 급여 부족</li>
          <li>비타민 A의 결핍과 급수량의 제한(오줌의 농축을 유발시켜 요석 형성을 조장한다.)</li>
          <li>조기 거세는 요도의 발육을 저해시켜 요석의 배설을 곤란하게 한다.</li>
        </ul>
        <h2>증상</h2>
        <ul>
          <li>배뇨횟수가 많아지며 생식기 주변의 털에는 백색의 이슬모양의 조그마한 결석이 붙어 있다. 그 외에 이상한 증상은 볼 수 없다. </li>
          <li>배뇨자세를 빈번히 나타내면 소량의 혈뇨만 나올뿐 대부분 배뇨불능이 되며 생식기주변의 털에는 다량의 조그마한 결석이 말라 붙어 있다. </li>
          <li>불안한 모습을 보이며 때때로 아랫배를 걷어 차는 등 산통(産痛)증상을 나타내고 </li>
          <li>때로는 땀이나 호흡이 빨라짐을 볼 수 있다. </li>
          <li>직장 검사를 해보면 팽대한 방광을 만져 볼 수 있다. </li>
          <li>배뇨 불능 상태가 계속되면 방광염, 요도염을 일으켜 방광 파열을 초래하여 죽게 된다.</li>
        </ul>
        <h2>목장에서의 처치</h2>
        <ul>
          <li>경증
            <ul>
              <li>가벼운 졍우에는 조사료 급여를 늘리고</li>
              <li>염화 암모늄과 비타민 A를 급여한다.</li>
              <li>일광욕을 실시하고 급수가 충분히 이루어지고 있는가를 점검한다.</li>
              <li> 발생율이 높은 겨울철에는 물을 따뜻이 데워 음수량(飮水量)이 늘도록 유도한다. </li>
            </ul>
          </li>
          <li>중증
            <ul>
              <li>심한 경우에는 조속히 수의사의 진단을 받도록 한다. </li>
            </ul>
          </li>
        </ul>
      </div>
      <div className="rightpart">
        <img src={pic675} className="nonborder" alt=""/>
        <img src={pic676} className="nonborder" alt=""/>
        <img src={pic677} className="nonborder" alt=""/>
        <img src={pic678} className="nonborder" alt=""/>
      </div>
    </div>
  );
}

export default Do135;