const reportData = [
  {
    id: 1,
    category: '검정성적 종합 안내문',
    content: [
      '해당월 검정성적에 대한 전체 내역 요약표',
      '목장 두수, 평균 유량, 유지율, 유단백율, 무지고형분율, 체세포수, 착유일수 및 305일 유량 및 유지율, 평균 산차, 분만간격, 공태일수 등',
      '후대검정 참여내역 및 후대검정딸소 보유 현황',
      '농가 혈통비율 및 보조 지급 여부',
    ],
  },
  {
    id: 2,
    category: '종합 검정 성적표',
    content: [
      '검정일 성적요약(13개월 검정우군. 검정일 성적, 체세포수, 305일 유량, 번식사항)',
      '검정일 유량수준별 및 산차별 성적관리',
      '산차별 성적관리(검정일 평균성적, 305일 및 성년형 성적)',
      '번식성적관리(산차별 두수 및 수정・임신 두수, 공태일)',
      '기타번식성적(초산월령, 분만간격)',
    ],
  },

  {
    id: 3,
    category: '개체 검정일 성적표',
    content: [
      '검정일 성적 관리에서 유지율 3.3이하, 유단백율 2.8이하, 무지고형분율 8.3이하, 체세포수 50만이상은 “*”로 집중 관리 표시',
      '전기검정일 성적과의 차이가 큰 유지율, 유단배율, 무지고형분율, 체세포수에 대해 *, **, *** 표시로 주의 요망, MUN 항목 변화 제시',
    ],
  },
  {
    id: 4,
    category: '개체 검정 성적표Ⅰ',
    content: [
      '검정우 정보에 농장관리번호(명호)와 등록번호를 넣어 개체를 쉽게 찾을 수 있도록 함.',
      '개체 누계 성적, 305일 성적, 성년형 성적 제시',
      '관리 및 번식상황에 분만,건유, 임신예정우 +와 장기미수정우, 임신미확인, 첫종부 등을 표시',
    ],
  },
  {
    id: 5,
    category: '개체 검정 성적표Ⅱ',
    content: [
      '검정우 정보에 농장관리번호(명호)와, 등록번호 및 바코드(이력제코드)를 표시',
      '개체별 최근 검정일 성적, 305일 성적, 성년형 성적, 최근 수정내역을 표시',
    ],
  },
  {
    id: 6,
    category: '체세포관리 통지서',
    content: [
      '체세포 관련 1년간 성적을 개체별로 제시',
      '개체별 손실유량, 유대손실액 등 항목 제시',
    ],
  },
  // 데이터항목
];

export default reportData;
