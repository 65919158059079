import React from 'react';

import Pic511 from 'assets/images/sub/_images/pic511.jpg';
import Pic512 from 'assets/images/sub/_images/pic512.jpg';
import Pic513 from 'assets/images/sub/_images/pic513.jpg';

function Do90() {

  return (
    <div className="techinfoview">
				<h1>구내염</h1>
				<p className="pstyle">구내염은 글자 그대로 입안의 점막에 염증이 생기는 것으로 병의 초기에는 점막이 빨갛게 부풀어 오르고 열이나며 침을 흘리는 것이 특징이다.</p>
				<div className="leftpart">
					<h2>원인</h2>
					<ul>
						<li>곰팡이가 핀사료, 딱딱한 사료, 농약이 묻은 사료 등에 의해 입안 점막이 심한 자극을 받았을 때 발병하기 쉽다. </li>
						<li>치아에 질환이 생겼을 때 </li>
						<li>세균 및 곰팡이 바이러스 등의 감염에 의해 발생한다. </li>
					</ul>
					<h2>증상</h2>
					<ul>
						<li>침을 많이 흘리며,식욕이 떨어지고 채식 시간이 길어진다. </li>
						<li>입안에서 악취가 나며, 점막이 붓소 충혈된다. </li>
						<li>열이 오른다. </li>
					</ul>
					<h2>목장에서의 처치</h2>
					<ul>
						<li>상처로 인한 구내염일때는 상처의 원인을 제거한다. </li>
						<li>감염성으로 의심이 되면 신속히 병우를 격리하여 급수기와 사료통 등을 통한 전파를 막는다. </li>
						<li>멸균된 미지근한 물로 구강을 씻어낸후 자극이 적은 소독약(생리식염수, 5% 중조액, 1%썰파제약,2%붕산수 등)으로 구강을 자주 세척한다. </li>
						<li>증세가 심하면 항생제를 투여해야 한다. </li>
					</ul>
				</div>
				<div className="rightpart">
					<img src={Pic511} className="nonborder" alt=""/>
					<img src={Pic512} className="nonborder" alt=""/>
					<img src={Pic513} className="nonborder" alt=""/>
				</div>
			</div>
  );
}

export default Do90;