import React, { useState , useEffect } from 'react';
import { postState } from 'utils/api';

import ErrorMessage from 'components/errorMessage/errorMessage';

// scss
import './geneGrade.scss';

function GeneGrade() {

  // 에러처리
  const [isModalOpen, setIsModalOpen] = useState(false); // 모달 추가
  const [errorMessage, setErrorMessage] = useState(''); 

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => {
    setIsModalOpen(false);
    setErrorMessage(''); // 모달이 닫힐 때 에러메세지 초기화
  };

  // 데이터 불러오기
  // eslint-disable-next-line no-restricted-globals 
  const baseDir=`${location.pathname.toLowerCase()}/`;  
  const [ mainData , setMainData ] = useState();

  const [ props , setProps] = useState({
    id:`${localStorage.getItem('cong')}${localStorage.getItem('farm')}`,
    passwd:localStorage.getItem('passwd'),
    date:localStorage.getItem('month')
  });

  useEffect(() => {

    postState(baseDir,props)
    .then(getdata => {      
      if (getdata !== undefined) {
      setMainData(getdata);
      }
    })
    .catch(error => {           
      setErrorMessage(error.message);
      openModal();
    });
    
  }, [props]);
    
  return (
    <div className='geneGrade'>
      <ErrorMessage isOpen={isModalOpen} content={errorMessage} closeModal={closeModal}/>
      <div className='geneGradeInner'>
        <h2>유전체 육종가 성적 % 기준</h2>
        <div className='geneTable'>
          <table>
            <thead>
              <tr>							
                <th>상위%</th>
                <th>유량</th>
                <th>유지방</th>
                <th>유단백</th>
                <th>체세포</th>
                <th>체형종합</th>
                <th>유방종합</th>
                <th>지제종합</th>
                <th>KTPI</th>			
              </tr>
            </thead>
            <tbody>					
            {
                mainData &&
                mainData.plot_data.map((info,index) => (
                  <tr key={index}>
                    <td>{info.tr % 1 ===0 ? 100-info.tr : info.tr}</td>
                    <td>{Number(info.milk1.toFixed(2)).toLocaleString()}</td>
                    <td>{info.fat1.toFixed(2)}</td>
                    <td>{info.prot1.toFixed(2)}</td>
                    <td>{info.scs1.toFixed(2)}</td>
                    <td>{info.ocs1.toFixed(2)}</td>
                    <td>{info.udc1.toFixed(2)}</td>
                    <td>{info.flc1.toFixed(2)}</td>
                    <td>{Number(info.ktpi1.toFixed(2)).toLocaleString()}</td>
                  </tr>
                ))
              }
            </tbody>
          </table>	
        </div>
      </div>
    </div>
  );
}

export default GeneGrade;
