import React from 'react';
import MonthFarmhouse from 'containers/web/performance/monthFarmhouse/MonthFarmhouse';
import { Helmet } from 'react-helmet-async';

function MonthFarmhousePage() {
  return (
    <>
      <Helmet>
        <title>이달의 검정농가</title>
      </Helmet>
      <MonthFarmhouse />
    </>
  );
}

export default MonthFarmhousePage;
