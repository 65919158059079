import React from 'react';

import d1by1 from 'assets/images/sub/_images/1_1.gif';
import d1by2 from 'assets/images/sub/_images/1_2.jpg';
import d1by3 from 'assets/images/sub/_images/1_3.jpg';
import d1by4 from 'assets/images/sub/_images/1_4.gif';
import d1by5 from 'assets/images/sub/_images/1_5.gif';
import d1by6 from 'assets/images/sub/_images/1_6.gif';
import d1by9 from 'assets/images/sub/_images/1_9.jpg';
import d1by5by1 from 'assets/images/sub/_images/1-5-1.jpg';
import d1by5by2 from 'assets/images/sub/_images/1-5-2.jpg';

function Fo18() {

  return (
    <div className="techinfo_view">
				<p className="viewTitle">육성목표</p>
				<h2>어떤 발육을 시키는가?</h2>
				<ul className="listStyle04 mg15f">
					<li>육성 모델(model)은 기본적으로 오른쪽 그림에 나타난 A,B,C 3가지의 형이 있고, 생리적으로 폐해(弊害)을 주지 않는 범위에 있으면 어떤 방법이라도 큰 차이는 없다. </li>
					<li>조기에 발육을 시킨 쪽이 frame size(체격 크기)가 크고, 조사료 주체형의 사육관리로 이행하기 쉽다.</li>
					<li>동일한 증체를 시킬 경우는 체중이 작은 쪽이 양분이 적고, 잘되어서 효율적.</li>
					<li>육성후반의 높은 증체는 관리는 용이하지만 농후사료다급의 폐해(弊害)가 나타나기 쉽다.</li>
				</ul>
				<p>경영 목적에 따라 발육을 조절(Control)하는 것이 중요.!! </p>
				<dl>
					<dt>육성목표</dt>
					<dd>
						<div className="imgBox"><img src={d1by1} alt=""/></div>					
						<ul>
							<li>1. frame size(체격 크기)의 확보. </li>
							<li>2. 루멘(반추)의 발달. </li>
							<li>3. 24개월로 분만할 수 있는 발육. </li>
							<li>4. 비유 생리를 저해(沮害)하지 않는 발육.</li>
							<li>5. 육성 비용이 싼 사양. </li>
						</ul>
					</dd>
				</dl>
				<div className="imgBox mg60f"><img src={d1by2} alt="유우의 체중의 성장곡선과 월간 증체량 곡선(雌牛) &lt;축산의 연구 36-7&gt;"/></div>
				
				<h2>생리적으로 이상(異常)한 발육이란?</h2>
				<ul className="listStyle04 mg15f">
					<li>9개월령이후의 성성숙기에 일증체량이 1Kg을 초과하는 증체가 계속되면 유선의 발달을 저해하는 위험이 있다.</li>
					<li>과비(비만)상태가 계속되면 내장에 지방이 침착하고, 수태성적과 채식능력이 저하한다.</li>
					<li>일시적으로 일증체량이 1kg을 초과하는 시기가 있어도, 단백질이 충분히 급여되고 frame size(체격 크기)가 발육하고 있으면 문제없다.</li>
					<li>CP(Crude protein): 조단백질이 부족하면 지방침착을 일으키기 쉽다.</li>
					<li>발육 불량은 frame size(체격 크기)의 부족, 난소기능의 저하를 초래한다.</li>
				</ul>
				<p>지방질 유방은 관리보다 유전이 크다.!! <br/>비만 자우의 유방은 지방침착으로 인해 크지만, 유선조직의 발달이 저해되고 있다.</p>
				<div className="tableWrap">
					<table className="tablestyle">
						<caption>표. 다른 일증체량으로 발육한 11개월령에 도살한 미숙자우의 유선(절반)의 분석. (Harrison, 1983)</caption>
						<thead>
							<tr>
								<th className="first">시험구</th>
								<th>L</th>
								<th>M</th>
								<th>H</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>일증체량(kg) </td>
								<td>0.57</td>
								<td>0.76</td>
								<td>1.18</td>
							</tr>
							<tr>
								<td>유선중량(kg</td>
								<td>0.45</td>
								<td>0.95</td>
								<td>1.94</td>
							</tr>
							<tr>
								<td>실질중량(kg)</td>
								<td>0.29</td>
								<td>0.25</td>
								<td>0.17</td>
							</tr>
							<tr>
								<td>실질중량의 유선 중량에 차지하는 비율(%)</td>
								<td>60.9</td>
								<td>26.9</td>
								<td>8.8</td>
							</tr>
							<tr>
								<td>유선 중량의 체중에 차지하는 비율(%)</td>
								<td>0.40</td>
								<td>0.68</td>
								<td>1.03</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div className="imgBox mg60f"><img src={d1by5by1} alt="그림. 비만 자우(13개월령)의 유방단면" /> <img src={d1by5by2} alt="그림. 정상발육자우(12.4개월령)의 유방단면" className="mg20l" /> </div>
				
				<h2>발육 속도와 발육 곡선</h2>
				<ul className="listStyle04 mg15f">
					<li>측정값이 발육곡선의 일정폭의 사이에 들어 있으면 좋다.</li>
					<li>시기에 의한 개체에 의해 발육부위가 다르다.</li>
					<li>성장은 발걸음부터 멈춘다.</li>
				</ul>
				<div className="imgBox mg60f"><img src={d1by3} alt="홀스타인(Holstein) 육성우의 성장곡선." /></div>
				
				<h2>발육 곡선의 활용</h2>
				<ul className="listStyle04 mg15f">
					<li>정기적으로 측정하는 것은 어렵기 때문에 기둥등에 표(기호, 마크)를 붙여 발육을 체크(Check)하면 편리.</li>
					<li>전두측정(全頭測定)이 아니라 대표적 Sample의 측정으로도 판단할 수 있다.</li>
				</ul>
				<table className="tablestyle">
					<caption>표. 발육 곡선에 측정치를 점으로 찍은 결과의 읽는 방법</caption>
					<thead>
						<tr>
							<th className="first">체중(體重)</th>
							<th>체고(體高)</th>
							<th>TDN level</th>
							<th>CPlevel</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>적다</td>
							<td>낮다</td>
							<td>부족</td>
							<td>부족</td>
						</tr>
						<tr>
							<td>많다</td>
							<td>낮다</td>
							<td>과잉</td>
							<td>부족</td>
						</tr>
						<tr>
							<td>적다</td>
							<td>표준</td>
							<td>부족</td>
							<td>적정</td>
						</tr>
						<tr>
							<td>많다</td>
							<td>표준</td>
							<td>과잉</td>
							<td>적정</td>
						</tr>
						<tr>
							<td>많다</td>
							<td>높다</td>
							<td>과잉</td>
							<td>과잉</td>
						</tr>
					</tbody>
				</table>
				<ul className="refer mg10t mg30f">
					<li>TDN:가소화 영양소총량  CP: 조 단백질(Crude protein)</li>
				</ul>
				<div className="imgBox mg60f"><img src={d1by9} alt="그림 5월 5일의 키대보기 처럼 기둥에 표시를 붙여 둔다." /></div>
				
				<h2>루멘(반추위)발달의 단계(step).</h2>
				<ul className="listStyle04 mg15f">
					<li>단위동물적 소화능력으로부터 반추동물로서의 기능(반추기능)을 획득해가기 위해서는 조기이유를 하고 목적에 맞는 고형사료를 조기부터 급여하는 것이 중요.</li>
					<li>루멘(반추)발달의 단계(Step)
						<ul>
							<li>단계 1: 출생 - 3일 ------&gt; 반추 미발달(단위 동물적 소화능력)</li>
							<li>단계 2: 4일 - 5주 -------&gt; 고형물 섭취로 인한 반추기능의 개발.</li>
							<li>단계 3: 5주 - 6주 -------&gt; 반추의 소화능력이 안정.</li>
							<li>단계 4: 6주 - 3개월 -----&gt; 반추 용적의 발달. </li>
							<li>단계 5: 3개월 - 6개월 ---&gt; 반추 용적의 부족(농후사료의존형 사양관리)</li>
							<li>단계 6: 6개월이후 -------&gt; 반추용적의 안정(조사료 주체형 사양관리)</li>
						</ul>
					</li>
				</ul>
				<div className="tableWrap mg60f">
					<table className="tablestyle">
						<caption>표. 자우와 성우와의 위 구조의 상이(相異)</caption>
						<thead>
							<tr>
								<th className="first">크기</th>
								<th>제1위</th>
								<th>제2위</th>
								<th>제3위</th>
								<th>제4위</th>
								<th> </th>
								<th> </th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>출생직후</td>
								<td>1,175g</td>
								<td>100g</td>
								<td>160g</td>
								<td>3500g</td>
								<td><img src={d1by4} alt=""/></td>							
								<td className="left">생후가 머지 않을 쯤은 제 1위보다도 제 4위에 소화능력이 있다. 제 4위에서는 전유와 대용유 같은 액상 사료를 출생할 쯤 자우에게 필요한 영양분으로서 소화한다. </td>
							</tr>
							<tr>
								<td>생후3개월령</td>
								<td>10∼15 l </td>
								<td></td>
								<td>0.5 l </td>
								<td>6 l </td>
								<td><img src={d1by5} alt=""/></td>							
								<td className="left">고형사료를 조기부터 급여할 것에 의해 제 1위는 발달을 촉진하고 기능이 발휘된다. 특히 인공유(starter)의 칼로리. 단백질로 제 1위 기능이 초기에 발달하는 것은 장래의 소화능력에 큰 영향이 있다.</td>
							</tr>
							<tr>
								<td>성우</td>
								<td>50∼200 ㅣ</td>
								<td></td>
								<td>7∼18 ㅣ</td>
								<td>8∼20 ㅣ </td>
								<td><img src={d1by6} alt=""/></td>							
								<td className="left">이 시기에는 제 1위는 소의 소화과정에 있어 가장 중요한 위의 작용으로 유우는 다량 조사료를 우유로 다시 만든다. </td>
							</tr>
						</tbody>
					</table>
				</div>
				
				<h2>육성 비용으로 무엇이 비싼가?</h2>
				<ul className="listStyle01 mg15f mg10l">
					<li>육성기간이 긴 것이 고비용의 첫째 요인.
						<ul className="mg10f">
							<li>a: 조사료는 싸지 않다.</li>
							<li>b: 노력이 소요된다.</li>
							<li>c: 시설의 회전율이 나쁘다.</li>
							<li>d: 보유두수가 증대한다.</li>
						</ul>
					</li>
					<li>장기포유의 유리성은 적다.
						<ul className="mg10f">
							<li>a: 포육경비가 비싸다. </li>
							<li>b: 노력이 든다. </li>
							<li>c: 하리의 위험이 크다.</li>
							<li>d: 반추위의 발달을 지연시킨다. </li>
						</ul>
					</li>
					<li>Cost down(경비절감) 고안점.
						<ul className="mg10f">
							<li>a: 시설에 경비를 쓰지 않는다.(자연에 가까운 쪽이 환경은 좋다.)</li>
							<li>b: 단순작업으로 하는 노력을 경감한다.(기계작업을 많이 한다.)</li>
							<li>c: 여성노동의 경감.</li>
						</ul>
					</li>
				</ul>
				
				<div className="tableWrap">
					<table className="tablestyle">
						<caption>표. 十勝(토카치) 중부에 있어서의 육성 모델의 생산 경비.</caption>
						<thead>
							<tr>
								<th className="first">비목(費目)</th>
								<th>금액(金額)</th>
								<th>구성비(構成費)</th>
								<th>적요(摘要) </th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>사료비(飼料費)</td>
								<td>
									엔(円)<br/>
									169,741 
								</td>
								<td>
									%<br/>
									56.8 
								</td>
								<td>
									엔(円) % <br/>
									대용유 9,885 (5.8) <br/>
									인공유 8,714 (5.1) <br/>
									농후사료 37,520 (22.1) <br/>
									Grass Silage 57,495 (33.9) <br/>
									건초 56,127 (33.1) 
								</td>
							</tr>
							<tr>
								<td>양축, 위생비 </td>
								<td>68,041</td>
								<td>22.8</td>
								<td>
									양축 27,195 (40.0) <br/>
									가축공제 20,150 (25.4) <br/>
									목장예탁 20,696 (23.4)
								</td>
							</tr>
							<tr>
								<td>노동임금</td>
								<td>30,000</td>
								<td>10.0</td>
								<td>20일 * 1,500円</td>
							</tr>
							<tr>
								<td>기계, 시설비</td>
								<td>21,367</td>
								<td>7.1</td>
								<td>
									동력 광열비 10,934 (51.2) <br/>
									시설비 5,430 (25.4) <br/>
									기타 5,003 (23.4)
								</td>
							</tr>
							<tr>
								<td>관리비</td>
								<td>9,760</td>
								<td>3.3</td>
								<td>
									저부담 3,080 (31.6)<br/> 
									판매비용 4,000 (41.0)<br/> 
									연수비,기타 2,680 (27.4)
								</td>
							</tr>
							<tr>
								<td>합계</td>
								<td>298,909 </td>
								<td>100</td>
								<td></td>
							</tr>
						</tbody>
					</table>
				</div>
				<ul className="refer mg30f" style={{marginTop: '-20px'}}>
					<li>※ Grass Sailage 주체형(Free stall 사양) </li>
					<li>※ 여름기간 육성 목장 예탁</li>
					<li>※ 平成(헤이세이) 1년 조사</li>
					<li>※ 자가 육성과 목장 예탁의 경제성</li>
				</ul>
				<ul className="listStyle01 mg30f">
					<li>자가 육성: 발육과 증체를 조절(Control)할 수 있다. 육성 비용은 비싸다. </li>
					<li>목장 예탁: 목장의 거래에 의해 발육에 좋고 나쁨이 발생한다. 분만 월령이 늦기 쉽다. </li>
				</ul>
				
				<div className="tableWrap">
					<table className="tablestyle">
						<caption>표. 공공 목장이용 경비와 개별 경영에 있어서의 경비 비교(1일당 円)</caption>
						<thead>
							<tr>
								<th className="first" rowSpan="2" colSpan="2">비용구분<br/>계절별</th>
								<th colSpan="2">생산비 계산 비용 </th>
								<th colSpan="2">개별경영 소득 계산비용 </th>
							</tr>
							<tr>
								<th>공공목장비용</th>
								<th>개별 경영</th>
								<th>평균</th>
								<th>저(低) ∼고(高)</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>하기(夏期) </td>
								<td>153일</td>
								<td>230일</td>
								<td>360엔(円)</td>
								<td>80% 290엔</td>
								<td>200 ∼ 400엔</td>
							</tr>
							<tr>
								<td>동기(冬期)</td>
								<td>212일</td>
								<td>560일</td>
								<td>600엔</td>
								<td>74% 445엔</td>
								<td>400 ∼ 500엔</td>
							</tr>
							<tr>
								<td>연평균(年平均)</td>
								<td>365일</td>
								<td>420일</td>
								<td>500엔</td>
								<td>76% 380엔</td>
								<td>320 ∼ 460엔</td>
							</tr>
						</tbody>
					</table>
				</div>
				<ul className="refer mg60f" style={{marginTop: '-20px'}}>
					<li>※ 공공목장 비용은 북해도 토카치 관내에 있어서 주로 공공목장의 여름, 겨울 예탁 요금과 기타 비용합계의 평균치</li>
					<li>※ 소득 계산비용 = 생산비 계산비용 - 가족노동(임금) - 자작지지대 - 자기자본이자. 따라서 공공목장에 예탁한 경우, 그 기간에 있어서의 생산비 계산비용과 소득 계산 비용은 거의 동일. </li>
				</ul>
				
				<h2>후계우(後繼牛)는 몇 두 필요한가?</h2>
				<ul className="listStyle01 mg15f">
					<li>낙농가에서 필요로 한 육성우의 두수는 다음과 같은 조건으로 부터 결정된다.
						<ul>
							<li>a: 태어난 자자우(雌子牛)의 두수</li>
							<li>b: 자우와 육성우의 사망율</li>
							<li>c: 초산분만 월령.</li>
							<li>d: 우군(牛群)의 회전율과 도태율(淘汰率) </li>
						</ul>
					</li>
					<li>오른 쪽 표는 초산분만 월령과 갱신율에 의해 필요로 한 육성우의 두수을 나타낸 것이다. 
						<ul>
							<li>a: 갱신율이 높아 지면 필요 두수가 많아 진다.</li>
							<li>b: 분만 월령이 늦어지면 보유두수가 많아 진다. </li>
						</ul>
					</li>
				</ul>
				<table className="tablestyle">
					<caption>표. 100두 우군에 필요로 하는 육성 두수. </caption>
					<thead>
						<tr>
							<th className="first">초산분만월령 (개월)<br/>갱신율(%) </th>
							<th>22</th>
							<th>24</th>
							<th>26</th>
							<th>28</th>
							<th>30</th>
							<th>32</th>
							<th>34</th>
							<th>36</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>20</td>
							<td>40</td>
							<td>44</td>
							<td>48</td>
							<td>51</td>
							<td>56</td>
							<td>61</td>
							<td>65</td>
							<td>69</td>
						</tr>
						<tr>
							<td>22</td>
							<td>44</td>
							<td>48</td>
							<td>52</td>
							<td>56</td>
							<td>61</td>
							<td>66</td>
							<td>69</td>
							<td>72</td>
						</tr>
						<tr>
							<td>24</td>
							<td>48</td>
							<td>53</td>
							<td>57</td>
							<td>62</td>
							<td>66</td>
							<td>70</td>
							<td>75</td>
							<td>79</td>
						</tr>
						<tr>
							<td>26</td>
							<td>52</td>
							<td>57</td>
							<td>62</td>
							<td>67</td>
							<td>72</td>
							<td>76</td>
							<td>81</td>
							<td>86</td>
						</tr>
						<tr>
							<td>28</td>
							<td>56</td>
							<td>62</td>
							<td>67</td>
							<td>72</td>
							<td>77</td>
							<td>82</td>
							<td>87</td>
							<td>92</td>
						</tr>
						<tr>
							<td>30</td>
							<td>61</td>
							<td>66</td>
							<td>72</td>
							<td>77</td>
							<td>83</td>
							<td>88</td>
							<td>94</td>
							<td>99</td>
						</tr>
						<tr>
							<td>32</td>
							<td>65</td>
							<td>70</td>
							<td>76</td>
							<td>82</td>
							<td>86</td>
							<td>94</td>
							<td>100</td>
							<td>106</td>
						</tr>
						<tr>
							<td>34</td>
							<td>69</td>
							<td>75</td>
							<td>81</td>
							<td>87</td>
							<td>94</td>
							<td>100</td>
							<td>106</td>
							<td>112</td>
						</tr>
						<tr>
							<td>36</td>
							<td>73</td>
							<td>79</td>
							<td>86</td>
							<td>92</td>
							<td>99</td>
							<td>106</td>
							<td>112</td>
							<td>119</td>
						</tr>
					</tbody>
				</table>
			</div>
  );
}

export default Fo18;
