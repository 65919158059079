import React from 'react';

function TableReproductivePerformance({ reproductivePerformance }) {
  return (
    <div className="tablebox reproductivePerformanceTable">
      <table>
        <thead>
          <tr>
            <th>산차</th>
            <th>날짜</th>
            <th>번식구분</th>
            <th>분만후 일수</th>
            <th>수정 간격</th>
            <th>분만 간격</th>
          </tr>
        </thead>
        <tbody>
          {reproductivePerformance &&
            Array.isArray(reproductivePerformance) &&
            reproductivePerformance.map((data, i) => (
              <tr key={i}>
                <td>{data?.PARI || '-'}</td>
                <td>{data?.LDATE ? data.LDATE.split('T')[0] : '-'}</td>
                <td>{data?.GU || '-'}</td>
                <td>{data?.CUM || '0'}</td>
                <td>{data?.INTT || '0'}</td>
                <td>{data?.TINTDAY || '0'}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
}

export default TableReproductivePerformance;
