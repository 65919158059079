import React from 'react';

import Pic146 from 'assets/images/sub/_images/pic146.gif';



function Do19() {

  return (
            <div className="techinfoview">
				<h1>정액제조 및 검사</h1>
				<h2>정액체취</h2>
				<p style={{lineHeight: '200%'}}>&nbsp;&nbsp;&nbsp;정액채취방법에는 종래의 채위법(정소상체정액 채취법, 누관법, 해면채법, 질내채취법, 콘돔법, 페서리법)과 마사아지법(음경마사아지법, 정관팽대 부마사아지법), 전기자극법과 인공질법이 있는데, 한우개량부에서 주로 사용하고 있는 인공질법에 대해서 언급하고자 한다.<br/><br/>&nbsp;&nbsp;&nbsp;인공질법이란 암소의 질을 모방하여 만든 인공질내에 사정시키 정액을 채취하는 방법으로써 가장 이상적인 방법이며 세계 도처에서 널리 사용되고 있다. 인공질에는 40 ∼42℃의 온수를 넣어 적당히 압력을 있게 한 다음에, 인공질 입구에는 반드시 윤활제를 발라서 준비한다. 이러한 준비가 끝나면 수소를 끌고 와서 의빈우의 후면으로부터 서서히 접근시켜 가승가를 2 -3회 시켜 흥분을 고취시킨 후에 채취기술자는 수소의 오른쪽에 서서 오른손에 인공질을 잡고 대기하고 있다가 수소가 승가하면 왼손으로 포피위로부터 음경근부를 가볍게 잡아 자기 앞으로 약간 당기면서 인공질로 유도한다. 인공질은 음경의 각도에 맞추어 개구부를 아래로 하여 30 - 35°정도의 경사를 유지한다. 음경의 상해를 줄이고 인공질을 유도하는 것이 무엇보다 중요하며, 대개 음경이 인공질내에 들어가면 약간 후구에 힘을 주면서 음경을 전진시켜 지체없이 사정한다. 사정이 끝나면 즉시 인공질의 개구부를 위로하여 정액을 정액관에 모은다. 정액관을 38℃내외의 보온기에 이동하여 보관하고 곧바로 원정액의 검사를 실시한다. 1회 채취시 평균정자수는 6 - 15억/cc이며, 정액량은 5cc이다.</p><br/>
				<p><b>[가축 정액 사정량과 정자수]</b></p>
				<table className="tablestyle">
					<tr>
						<td></td>
						<td>1회 사정량</td>
						<td>평균</td>
						<td>정자수 1mm</td>
					</tr>
					<tr>
						<td>소</td>
						<td>3.0 ~ 10.0cc</td>
						<td>5.0cc</td>
						<td>17 - 27만</td>
					</tr>
					<tr>
						<td>돼지</td>
						<td>60.0 ~ 500cc</td>
						<td>250.0cc</td>
						<td>3 - 7만</td>
					</tr>
					<tr>
						<td>개</td>
						<td>10.0 ~ 30.0cc</td>
						<td>15.0cc</td>
						<td>10 - 17만</td>
					</tr>
					<tr>
						<td>닭</td>
						<td>03. ~ 1.0cc</td>
						<td>0.6cc</td>
						<td>7 - 17만</td>
					</tr>
					<tr>
						<td>토끼</td>
						<td>0.4 ~ 1.0cc</td>
						<td> 06.cc</td>
						<td>1.7 ~ 2.3만</td>
					</tr>
				</table><br/>
				<ul>
					<li>정액채취시 일반적 주의사항
						<ul>
							<li>위생관념에 투철할 것 </li>
							<li>온도충격을 피할 것</li>
							<li>채취전에 종모축의 성적흥분을 앙등시킬 것 </li>
							<li>정액은 오전중에 채취할 것 </li>
							<li>사출된 정액의 손실을 줄일 것</li>
						</ul>
					</li>
				</ul>
				<h2>정액검사</h2>
				
                <p style={{lineHeight: '200%'}}>&nbsp;&nbsp;&nbsp;채취된 정액은 보온하에서 육안적 검사를 실시해야 하며 검사가 완료되면 지체없이 항온실로 운반한다. 정액을 채취하면 사용하기에 앞서 채취한 정액의 양과 질을 판단하여 그 정액의 사용여부를 결정함과 동시에 어떠한 처리를 어떻게 할 것인가를 결정하는 기초적인 자료를 얻는데 그 목적이 있다. 정액성상의 검사는 육안적 검사법과 현미경적 검사법으로 나눌 수가 있다.</p>
				<ul>
					<li>육안적 검사
						<ul>
							<li>육안적 검사는 정액량, 색체, 냄새, 농도, pH등으로 구분하여 실시한다. 이때 주의할 점은 30 - 35℃의 보온이 유지되어야 하며, 직사광선이나 한냉한 장소는 피하는 것이 좋다.</li>
						</ul>
					</li>
					<li>현미경 검사
						<ul>
							<li>정자의 활력, 생존률, 정자의 형태 및 정자수 등을 측정 검사한다. 일선가축개량사가 인공수정소로부터 냉동정액이나 소송된 정액을 수령하고자 할 때에도 현미경 검사를 실시하는 것이 바람직하다. 전기가온장치가 장착된 현미경으로 약 400배율로 확인한다.</li>
						</ul>
					</li>
					<li>정자의 운동성 : 직선적 직진운동, 선회운동, 진자운동으로 구분하여 표시
						<ul>
							<li>정자의 활력 표기
								<ul>
									<li>+++ : 가장 활발한 전진운동</li>
									<li>++ : 활발한 전진운동</li>
									<li>+ : 완만한 전진운동</li>
									<li>± : 선회 또는 진자운동 </li>
									<li>- : 운동하지 않는다.</li>
								</ul>
							</li>
						</ul>
					</li>
					<li>정자의 생존률 : 정자가 완전히 사멸되지 않아도 운동성은 중지되는 경우가 있기 때문에 염색에 의한 생사를 구분한다. </li>
					<li>정자의 농도 : 혈구계산기나 비탁계 또는 분광광도계를 이용하여 측정한다. </li>
					<li>정자의 형태 : 정자를 염색하여 현미경에서 기형의 종류와 그 비율을 파악한다.
						<ul>
							<li>신선한 정액의 정자 중에서 첨체에 이상이 있는 것은 소 14.1%, 돼지 4.3%이다. 이러한 첨체의 이상은 정액을 장기 보존하거나 온도, 충격을 가하면 현저하게 증가한다.</li>
						</ul>					
						<img src={Pic146} style={{width: '40%'}} alt=""/>
						<p style={{width: '40%', textAlign: 'center'}}>정자의 정상 및 이상</p>
					</li>
				</ul>
			</div>
  );
}

export default Do19;
