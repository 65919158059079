import React from 'react';

import pic240 from 'assets/images/sub/_images/pic240.jpg';
import pic241 from 'assets/images/sub/_images/pic241.jpg';
import pic242 from 'assets/images/sub/_images/pic242.jpg';

function Do191() {

  return (
    <div className="techinfoview">
      <h1>레드 클로버</h1>
      <p>영명 : Red clover <br/>학명 : Trifolium pratense L. </p>
      <div style={{width: '60%',float: 'left'}}>
        <h2>원산지</h2>
        <p className="pstyle">&nbsp;&nbsp;&nbsp;원산지는 서남아세아와 카스피 해(Caspesn sea)의 남부지방이며 유럽과 일부 아시아 지역으로 200년 전부터 알려지기 시작하였다. 이 목초가 농업용으로 이용된 것은 13세기부터이며 이탈리아는 1550년 영국은 1645년 미국은 1947년 우리 나라는 1907년에 재배되었다.</p>
        <h2>성상</h2>
        <p className="pstyle">&nbsp;&nbsp;&nbsp;주로 재배되고 있는 레드클로버는 2배체 (2n=14)이며, 4배체 (2n=28)는 조기 개화하여 2회 예취하는 것과 늦게 개화하여 1회 예취하는 형이 있다. 4배체는 일반적으로 줄기가 거칠고, 큰 잎을 가지고 있으며, 꽃은 크고 매끄러운 종자를 가지고 있다. 다년생 두과목초로서 품종과 토양, 기후조건에 따라 2-3년 동안 재배될 수 있다. 개개의 잎은 장방형의 3개의 잎으로 되어 있으며, 꽃은 광택 또는 엷은 장미빛이나 또는 자홍색으로 되있다. 뿌리는 직근으로 되어 있으며, 지근을 많이 가지고 있으나, 알팔파와 같이 토양 깊숙히 뻗지는 않는다. 유식물은 활력이 강하기 때문에 잘 뻗어 나간다. </p>
        <h2>재배</h2>
        <p className="pstyle">&nbsp;&nbsp;&nbsp;어느 토양조건에서나 잘 자라지만, 그 중에도 기온이 온화하고 습한 지역에서 재배가 잘된다.<br/>&nbsp;&nbsp;&nbsp;알팔파, 알사익클로버, 스위트클로버 보다는 산성에 강하지만 토양의 산도(ph)가 5.0이하일 때는 생산량이 감소된다. 내한성 또한 강하나 침수 될 때와 토양수분이 높을 때는 생육이 약간 저조한 편이며 배수가 불량한 곳에서도 알팔파나 스위트클로버 보다는 생육이 잘된다.<br/>&nbsp;&nbsp;&nbsp;이른봄에 2주 동안 침수된다 하더라도 생존이 가능하지만 염도가 높은 토양이나 장기간 가뭄에는 약하다. 종자는 작기 때문에 얕게 파종하여야 한다.<br/>&nbsp;&nbsp;&nbsp;뿌리썩음병, 탄저병등은 생산량을 감소시키고 때로는 생장력을 약하게하여 겨울동안에 동사하게 한다. 10a 청초수량은 3,000∼5,000kg정도이며, 재배되고 있는 품종은 캘렌드(Kenalnd) 등이 있다. </p>
        <h2>이용</h2>
        <ul>
          <li>건초
            <p>때로는 모든 두과목초중에서 생산량이 제일 많을 때도 있고, 알팔파가 잘 자라지 못하는 지역에서는 레드클로버로 대체 재배할 때도 있다. 수량은 많으나 수분함량이 많고 주로 습지대가 잘되기 때문에 건초를 만들기는 어려운 목초다. 더구나 건초의 녹색을 유지한다는 것이 다른 두과목초보다 어렵다. 그러므로 때로는 질이 낮은 건초가 된다. 티모시와 혼파하면 생유도 좋고 수확하기도 용이하다.</p>
          </li>
          <li>방목 및 청예
            <p>예취후 재생이 잘되고 가을에 기호성이 좋은 목초가 된다. 늦가을까지 방목 할 때에도 뿌리에 영양분의 저장력은 감소되지 않으며, 혼파하는 것이 좋으나, 2∼3년밖에 이용하지 못한다.</p>
          </li>
        </ul>
      </div>
      <div style={{width: '40%', float: 'left'}}>
        <table>
          <tr>
            <td><img src={pic240} className="nonborder2" alt=""/></td>
          </tr>
          <tr>
            <td><img src={pic241} className="nonborder2" alt=""/></td>
          </tr>
          <tr>
            <td><p style={{textAlign: 'center'}}>잎자루, 줄기 및 잎의 털모양</p></td>
          </tr>
          <tr>
            <td><img src={pic242} className="nonborder2" alt=""/></td>
          </tr>
          <tr>
            <td><p style={{textAlign: 'center'}}>꽃의 모양</p></td>
          </tr>
        </table>
      </div>
    </div>
  );
}

export default Do191;