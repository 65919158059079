import React from 'react';

import Pic477 from 'assets/images/sub/_images/pic477.jpg';
import Pic478 from 'assets/images/sub/_images/pic478.jpg';
import Pic479 from 'assets/images/sub/_images/pic479.jpg';

function Do79() {

  return (
    	<div className="techinfoview">
				<h1>방선균병</h1>
				<p className="pstyle">소의 턱골(顎骨)에 균이 침입, 골조직을 파괴하고 신생물의 형성을 초진시켜 안면의 변형을 일으키는 질병이다.</p>
				<div className="leftpart">
					<h2>원인</h2>
					<ul>
						<li>입속에 상재하는 균중 주로 방선균이 사료중의 이물에 의해 생긴 잇몸의 상처를 통해서 몸안으로 침입하여 발병한다.  </li>
					</ul>
					<h2>증상</h2>
					<ul>
						<li>보통은 알지 못하는 사이에 감염이 되어 시일이 경과함에 따라 턱골이 커지며 턱이 팽대된다. 
							<ul>
								<li>윗턱이 아랫턱에 비해 발생율이 높다. </li>
								<li>호흡 및 채식이 곤란해지고, </li>
								<li>소화불량을 초래한다. </li>
								<li> 조직의 현미경검사에 의하여 확진할 수 있다 .</li>
							</ul>
						</li>
					</ul>
					<h2>목장에서의 처치</h2>
					<ul>
						<li>본병은 입안의 상처를 통한 감염으므로 외상이 생기지 않도록 주의한다. </li>
						<li>우사내 금속성이물과 사료중의 단단한 나무 뿌리, 줄기 등의 제거에 노력한다.</li>
						<li>평소 소의 얼굴의 이상을 조기에 발견하도록 노력한다. </li>
						<li>국소요법으로 누관(屢菅)을 통해 옥도정기를 묻힌 가아제를 박아둔다. 초기일 때. 수의사에 의뢰, 절제나 소파수술을 받는 것이 바람직하다. </li>
						<li>치료약제로 옥도나트륨(Na I)의 주사제를 이용한다. </li>
						<li> 항생제로서는 페니실린과 스트렙토마이신을 같이 주사해 준다. </li>
					</ul>
				</div>
				<div className="rightpart">
					<img src={Pic477} className="nonborder" alt=""/>
					<p>* 방선균에 의해  아랫턱이 변형된 모습 </p>
					<img src={Pic478} className="nonborder" alt=""/>
					<p>* 좌측윗턱의 방선균腫, 육아조직이 이상 증식하여 피부를 뚫고 융기되었다.</p>
					<img src={Pic479} className="nonborder" alt=""/>
					<p>* 소의 하악골의 변형된 모습 </p>
				</div>
			</div>
  );
}

export default Do79;