import React from 'react';
import GenePrint from 'containers/genePrint/GenePrint';

function GenePrintPage() {
  return (
    <GenePrint />    
  );
}

export default GenePrintPage;
