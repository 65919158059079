import React from 'react';

import d2by71 from 'assets/images/sub/_images/2-71.jpg';
import d2by79 from 'assets/images/sub/_images/2-79.gif';

function Fo31() {

  return (
    <div className="techinfo_view">
				<p className="viewTitle">조기 이유의 장점</p>
				<h2>조기 이유(6주) 방식의 배경</h2>
				<ul className="listStyle04 mg15f">
					<li>생산한 모우의 우유는 될 수 있는 한 많이 출하한다</li>
					<li>고비유우가 되기 위해서의 채식·소화능력의 &#42434;보(장기의 발달)</li>
					<li>관리노력의 경감과 육성비용의 저감(低減)</li>
					<li>조기 이유를 하여도 우유와 대용유와 동등의 영양을 확보하면(특히 단백질) 발육이 저하하는 일은 없다</li>
				</ul>
				<table className="tablestyle mg30f">
					<caption>표. 영양분 단가의 비교예(생유가격은 가공원료)</caption>
					<thead>
						<tr>
							<th className="first"> </th>
							<th>TDN</th>
							<th>CP(Crude protein)</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>생유</td>
							<td>100%</td>
							<td>100%</td>
						</tr>
						<tr>
							<td>대용유</td>
							<td>74%</td>
							<td>73%</td>
						</tr>
						<tr>
							<td>인공유</td>
							<td>19%</td>
							<td>17%</td>
						</tr>
					</tbody>
				</table>
				<dl className="mg60f">
					<dt>포육일수</dt>
					<dd>1) 포육일수는 짧을수록 증체량이 크다</dd>
					<dd>2) 조기이유에 의한 발육저하는 없다</dd>
					<dd>3) 증체량 = 5개월령 체중 - 생시체중</dd>
				</dl>
				
				<h2>12개월령이후의 사료급여</h2>
				<ul className="listStyle04 mg15f">
					<li>12개월령의 체중목표는 300∼320kg, 1일증체 700∼800kg이 목표이다</li>
					<li>추장양분량으로서는 TDN61%, CP12%로 이것에 적합한 조사료이면 농후사료는 필요없다</li>
					<li>번식성적불량의 원인으로서는 저양분이 많으므로 1일증체 700∼800g를 확보한다</li>
					<li>옥수수 사일레지와 같은 고에너지 저단백질 조사료를 급여할 경우, 에너지과잉, 단백질 부족이 생기기 때문에 농후사료의 선택이 중요하다</li>
				</ul>
				<div className="tableWrap">
					<table className="tablestyle">
						<caption>표. 조사료성분과 농후사료급여비율 및 CP% (12개월령 이후)</caption>
						<thead>
							<tr>
								<th className="first" colSpan="2"> </th>
								<th>생시 (2)</th>
								<th>30일령 (2)</th>
								<th>60일령 (6)</th>
								<th>90일령 (6)</th>
								<th>42일령 (6)조기이유</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td colSpan="2">1. 제 1위<br/>2. 제 2위<br/>3. 제 3위<br/>4. 제 4위<br/>5. 식도<br/>6. 십이지장</td>
								<td colSpan="5"><img src={d2by71} alt=""/></td>
							</tr>
							<tr>
								<td rowSpan="2">제 1·2위 </td>
								<td>용적(ℓ)</td>
								<td>1.0</td>
								<td>10.7</td>
								<td>10.8</td>
								<td>23.9</td>
								<td>16.4</td>
							</tr>
							<tr>
								<td>중량(g)</td>
								<td>180</td>
								<td>1,030</td>
								<td>1,323</td>
								<td>2,660</td>
								<td>1,650</td>
							</tr>
							<tr>
								<td rowSpan="2">제 4위 </td>
								<td>용적(ℓ)</td>
								<td>2.6</td>
								<td>2.7</td>
								<td>2.8</td>
								<td>4.5</td>
								<td>3.8</td>
							</tr>
							<tr>
								<td>중량(g)</td>
								<td>320</td>
								<td>400</td>
								<td>528</td>
								<td>675</td>
								<td>487</td>
							</tr>
							<tr>
								<td rowSpan="3">위의 중량비(%)</td>
								<td>제 1·2위</td>
								<td>32</td>
								<td>64</td>
								<td>60</td>
								<td>65</td>
								<td>64</td>
							</tr>
							<tr>
								<td>제 3위</td>
								<td>9</td>
								<td>11</td>
								<td>17</td>
								<td>16</td>
								<td>18</td>
							</tr>
							<tr>
								<td>제 4위</td>
								<td>58</td>
								<td>25</td>
								<td>23</td>
								<td>19</td>
								<td>18</td>
							</tr>
							<tr>
								<td colSpan="2">체중 (kg)</td>
								<td>44.0</td>
								<td>66.7</td>
								<td>75.7</td>
								<td>113.3</td>
								<td>83.6</td>
							</tr>
						</tbody>
					</table>
				</div>
				<ul className="refer mg60f" style={{marginTop: '-20px'}}>
					<li>주) ( )내는 조사두수</li>
					<li>42일령(6)의 소는 조기이유를 위해 생후 5일째부터 인공유를 섭휘하게 했다. 다른 것은 4개월 포유기준으로 사양중의 자우. (농문협, 농업기술체계축산편.)</li>
				</ul>
				
				<h2>조기 이유법을 관행법과 비교한다면?</h2>
				<ul className="listStyle04 mg15f">
					<li>발육은 좋다. 또는 변화되지 않는다</li>
					<li>경비의 절감이 될 경우가 많다.(급여할 수 있는 폐기유(廢棄乳)가 많은 경우는 경비의 절감이 되지 않을지도 모른다</li>
					<li>대부분의 경우, 노력이 경감된다(시산(試算)으로는 1/2∼1/3)</li>
					<li>실천자의 감상으로서 [채식량이 많아져 유량이 향상했다.]가 많다</li>
				</ul>
				<div className="tableWrap">
					<table className="tablestyle">
						<caption>표. 조기이유실시자의 앙케이트 결과.</caption>
						<thead>
							<tr>
								<th className="first" colSpan="4">조기이유와 발육 조기이유와 경비,<br/>(이전의 이유방법과 비교해서).</th>
								<th colSpan="5">노동력<br/>(이전의 이유방법에 비교해서)</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td></td>
								<td>좋다</td>
								<td>동일</td>
								<td>나쁘다</td>
								<td></td>
								<td>되었다</td>
								<td colSpan="2">되지 않았다</td>
								<td>기타</td>
							</tr>
							<tr>
								<td>이유기 (포육기) </td>
								<td style={{background: '#ccddef'}}>37% (11戶)</td>
								<td style={{background: '#ccddef'}}>53% (16戶)</td>
								<td>110% (3戶)</td>
								<td>경비의 절감</td>
								<td style={{background: '#ccddef'}}>67%</td>
								<td colSpan="2">27%</td>
								<td>6%</td>
							</tr>
							<tr>
								<td>이유후 (육성기) </td>
								<td style={{background: '#ccddef'}}>40% (12戶)</td>
								<td style={{background: '#ccddef'}}>50% (15호)</td>
								<td>10% (3戶)</td>
								<td>노동력의 경감</td>
								<td colSpan="2" style={{background: '#ccddef'}}>87%</td>
								<td>6.5%</td>
								<td>6.5%</td>
							</tr>
						</tbody>
					</table>
				</div>
				<ul className="refer mg60f" style={{marginTop: '-20px'}}>
					<li>(平成: 헤이세이 2년도 북해도 토카치지정 농업개량보급원 축산부회 직장연수보고서)</li>
				</ul>
				
				<h2>조기 이유를 중지한 사람은 없는가?</h2>
				<ul className="listStyle04 mg15f">
					<li>적절한 일련(一連)의 조기 이유기술를 경험하면, 그후 그만 둔 사람은 희박하다</li>
					<li>조기이유가 낙농가에 유익한 기술인 것을 뒷받침할 것이다</li>
				</ul>
				<table className="tablestyle mg30f">
					<caption>표. 조기 이유실지자의 감상(소감) - 포육담당자</caption>
					<tbody>
						<tr>
							<th className="left">계속한다 97% 중지한다 3%</th>
						</tr>
						<tr>
							<td className="left">
								1) 하리가 없어 졌다<br/>
								2) 시설이 불충분으로 골칫거리<br/> 
								3) 채식량이 증가했다(늑골이 나왔다)<br/> 
								4) 이유후 발육 양호<br/> 
								5) 인공유의 결손에 마음을 쓴다<br/> 
								6) 쓸데없는 노력을 줄일 수 있다<br/> 
								7) 대용유를 사지 않고 해결된다<br/> 
								8) 조기이유로 지금까지 꽉 찬 시설이 쓰이지 않았다(생력화가 되었다)<br/> 
								9) 소의 상태를 보고 실시한다<br/> 
								10) 육성에 힘이 들게 되었다(꼭 공부한다) 
							</td>
						</tr>
					</tbody>
				</table>
				<table className="tablestyle">
					<caption>표. 조기 이유실지자의 감상(소감) - 경영주</caption>
					<tbody>
						<tr>
							<th className="left">계속한다 100% 중지한다0% </th>
						</tr>
						<tr>
							<td className="left">
								① 이후 조기이유의 성육을 본다<br/> 
								부모에게 포육을 맡기고 있기 때문에 조기이유가 좋은 것은 알지만 부모를 설득할 수 없다<br/> 
								(부모에게 포육담당을 그만 두게 되더라도 곤란하다)<br/> 
								③ 노동시간의 단축 되었다<br/> 
								④ 제 1위의 발달이 좋게 되었다<br/> 
								⑤ 물의 급여가 어렵다<br/> 
								⑥ 소의 상태를 보고 이유한다
							</td>
						</tr>
					</tbody>
				</table>
				<ul className="refer mg10t mg60f">
					<li>(平成: 헤이세이 2년도 북해도 토카지 지정 농업개량 보급원 축산부회 직장 연수 보고서) </li>
				</ul>
				
				<h2>조기이유의 보급은 왜 떨어지고 있는 것인가?</h2>
				<ul className="listStyle04 mg15f">
					<li>인공유의 보급부족</li>
					<li>인공유의 단백질 부족</li>
					<li>물의 급여 개시가 늦다.(물 부족에 의해 인공유, 건초의 채식량이 적다.)</li>
					<li>상기의 이유에 의해 액상사료의 장기급여 쪽이 발육이 좋다.(액상사료급여와 마찬가지의 영양공급을하면 조기이유라도 발육의 저하는 없다.)</li>
					<li>기술정보는 남성에게 들어오는 것이 많지만, 실제의 육성담당자는 여성으로 필요한 기술 정보가 담당자에게 전해지기 어렵다</li>
				</ul>
				<table className="tablestyle">
					<caption>표. 액상사료를 50일 이상 급여하는 이유</caption>
					<thead>
						<tr>
							<th className="first">이유(理由)</th>
							<th>고유량 농가</th>
							<th>평균 유량농가</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>발육이 좋기 때문</td>
							<td>7 (50)</td>
							<td>11 (38)</td>
						</tr>
						<tr>
							<td>습관으로</td>
							<td>1 (7)</td>
							<td>7 (24)</td>
						</tr>
						<tr>
							<td>생산조정을 위해</td>
							<td>2 (14)</td>
							<td>2 (7)</td>
						</tr>
						<tr>
							<td>유방염의 우유를 버리는 것이 아깝기 때문</td>
							<td>-</td>
							<td>2 (7)</td>
						</tr>
						<tr>
							<td>기타</td>
							<td>4 (29)</td>
							<td>7 (24)</td>
						</tr>
					</tbody>
				</table>
				<ul className="refer mg10t mg60f">
					<li>주) 호수(戶數), ( )내 % (昭和:쇼와 63년도 북해도 토카치 지정농업개량보급원 축산부회직장연수보고서)</li>
				</ul>
				
				<h2>자우 관리의 중심은 여성이 담당</h2>
				<ul className="listStyle04 mg15f">
					<li>자우 관리의 중심은 여성이다</li>
					<li>평균 유량농가보다도 고유량 농가의 쪽이 여성의 담당비율이 높다</li>
					<li>육성기술의 결정자는 80%이상이 남성이다</li>
					<li>육성기술의 결정자와 자우관리자와의 사이에 의사소통이 필요하다</li>
				</ul>
				<table className="tablestyle">
					<caption>표. 유성기술의 결정자. (重複回答)</caption>
					<thead>
						<tr>
							<th className="first"> </th>
							<th>고유량 농가</th>
							<th>평균유량 농가</th>
						</tr>
					</thead>
					<tfoot>				
						<tr>
							<td>계</td>
							<td>24인 (100%)</td>
							<td>28인 (100%)</td>
						</tr>
					</tfoot>
					<tbody>
						<tr>
							<td>경영주</td>
							<td>17인 (70.8%)</td>
							<td>18인 (64.3%)</td>
						</tr>
						<tr>
							<td>처(妻)</td>
							<td>3인 (12.5%)</td>
							<td>4인 (14.3%)</td>
						</tr>
						<tr>
							<td>모(母)</td>
							<td>0</td>
							<td>1인 (3.6%)</td>
						</tr>
						<tr>
							<td>자식(子息)</td>
							<td>4인 (16.7%)</td>
							<td>5인 (17.8%)</td>
						</tr>
					</tbody>
				</table>
				<ul className="refer mg10t mg30f">
					<li>(昭和:쇼와 63년도 북해도 토카치 지정농업개량보급원 축산부회직장연수보고서)</li>
				</ul>
				<div className="imgBox"><img src={d2by79} alt=""/><br/>(昭和:쇼와 63년도 북해도 토카치 지정농업개량보급원 축산부회직장연수보고서)</div>
				
			</div>
  );
}

export default Fo31;
