import React from 'react';

// constants
import { BOARD_NAME } from 'constants/backOfficeBoard';

function TestDtlPushPlanEdit() {
  return (
    <div className="bo-page">
      <h2>{BOARD_NAME.TEST_DTL_PUSH_PLAN} 수정페이지</h2>
    </div>
  );
}

export default TestDtlPushPlanEdit;
