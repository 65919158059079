import React, { useState , useEffect } from 'react';
import {
  ResponsiveContainer,
  ReferenceLine,
  ScatterChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Scatter,
  Cell
} from 'recharts';

// api
import { postState } from 'utils/api';
import { openCrownix2, openCrownix6 } from 'utils/openCrownix';

// 이미지
import PrintPDF from 'assets/svgs/common/print_PDF.svg';

// 컴포넌트
import ErrorMessage from 'components/errorMessage/errorMessage';
import MonthCode from 'components/dcitest/monthCode/MonthCode';

// scss
import './NewGraphTotal.scss';

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <p className="label">{`${payload[0].payload.sname}`}</p>
      </div>
    );
  }
  return null;
};

const CustomTooltip2 = ({ active, payload, label }) => {
  
  if (active && payload && payload.length) {
    
    return (
      <div className="custom-tooltip">
        
        <p className="label">{`${payload[0].payload.comm}`}</p>
      </div>
    );
  }

  return null;
};


function NewGraphTotal() {

  // 에러처리
  const [isModalOpen, setIsModalOpen] = useState(false); // 모달 추가
  const [errorMessage, setErrorMessage] = useState(''); 

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => {
    setIsModalOpen(false);
    setErrorMessage(''); // 모달이 닫힐 때 에러메세지 초기화
  };

  // 데이터 불러오기
  const baseDir='/chart/new_graph_scc/';
  const baseDir2='/chart/new_graph_fat/';
  const baseDir3='/chart/new_graph_prot/';
  const baseDir4='/chart/new_graph_mun/';
  const [ mainData1 , setMainData1 ] = useState();
  const [ mainData2 , setMainData2 ] = useState();
  const [ mainData3 , setMainData3 ] = useState();
  const [ mainData4 , setMainData4 ] = useState();

  const [ props , setProps] = useState({
    id:`${localStorage.getItem('cong')}${localStorage.getItem('farm')}`,
    passwd:localStorage.getItem('passwd'),
    date:localStorage.getItem('month')
  });
  useEffect(() => {

    postState(baseDir,props)
    .then(getdata => {      
      if (getdata !== undefined) {
      setMainData1(getdata);
      }
    })
    .catch(error => {           
      setErrorMessage(error.message);
      openModal();
    });
    
  }, [props]);
  useEffect(() => {

    postState(baseDir2,props)
    .then(getdata => {      
      if (getdata !== undefined) {
      setMainData2(getdata.plot_data);
      }
    })
    .catch(error => {           
      setErrorMessage(error.message);
      openModal();
    });
    
  }, [props]);
  useEffect(() => {

    postState(baseDir3,props)
    .then(getdata => {      
      if (getdata !== undefined) {
      setMainData3(getdata.plot_data);
      }
    })
    .catch(error => {           
      setErrorMessage(error.message);
      openModal();
    });
    
  }, [props]);
  useEffect(() => {

    postState(baseDir4,props)
    .then(getdata => {      
      if (getdata !== undefined) {
      setMainData4(getdata.plot_data);
      }
    })
    .catch(error => {           
      setErrorMessage(error.message);
      openModal();
    });
    
  }, [props]);
  // 차트  
  const COLORS1 = ['green','yellow','blue','red'];
  const classifyNumber1 = (num) => {
    if (num >= 0 && num < 100) return 0;
    if (num >= 100 && num <= 200)  return 1; 
    if (num > 200 && num < 500) return 2;
    if (num >= 500) return 3;
    return 0;
  };

  // 차트  
  const COLORS2 = ['blue','purple','red'];
  const classifyNumber2 = (x, y) => {
    if ( y < 3.4 ) return 1;
    if (x < 70 && y >= 5.5) return 2;
    return 0;
  };

  const COLORS3 = ['blue','purple','orange','red'];
  const classifyNumber3 = (x, y) => {
    if ( x < 70 && y <= 3) return 3;
    if ( x > 230 && y >= 3.6 ) return 2;
    if ( y <= 3.1) return 1;
    return 0;
  };

  const COLORS4 = ['black','red','gren','yellow','blue','gray','purple','orange','brown'];
  const classifyNumber4 = (x, y) => {
    // 기준이 몬지 몰르겟네..
    if ( x < 70 && y <= 3) return 3;
    if ( x > 230 && y >= 3.6 ) return 2;
    if ( y <= 3.1) return 1;
    return 0;
  };

  const CustomClick = (e) => {
    openCrownix2('New_ch_cow1.mrd',e.cowid , e.pari);
    // alert(e.sname2);
    };

  const COLORS = ['green','yellow','blue','red'];
  const classifyNumber = (num) => {
    if (num >= 0 && num < 100) return 0;
    if (num >= 100 && num <= 200)  return 1; 
    if (num > 200 && num < 500) return 2;
    if (num >= 500) return 3;
    return 0;
  };

  return (
    <div className='pdfMainArea'>
      <ErrorMessage isOpen={isModalOpen} content={errorMessage} closeModal={closeModal}/>
      <div className='pdfMainHeader'>
        <button 
          type='button'
          onClick={()=> openCrownix6('New_graph_total.mrd')}
        >
          <img src={PrintPDF} alt='PDF로 인쇄하기' />
          인쇄하기
        </button>
        {
          props && 
          <MonthCode 
            props={props}
            setProps={setProps}
            setErrorMessage={setErrorMessage}
            openModal={openModal}
          />
        }
      </div>
      <div className='pdfArea'>
        <div className='newGraphTotal'>  
        <span>{props.date.substring(0,4)}년 {props.date.slice(-2)}월 그래프</span>
          <ul>
            <li className='totalScc01'>
              <h1>비유일수별 체세포수 분포도</h1>
              <div className='dotChartAreaDual' >
                <ResponsiveContainer width="65%" height={200}>
                  <ScatterChart
                    width="90%"
                    height="90%"
                    margin={{              
                      top: 10,
                      right: 10,
                      bottom: 10,
                      left: 10,
                      }}>
                    <CartesianGrid />
                    <XAxis
                      dataKey="day"
                      type="number"
                      label={{ value: '비 유 일 수 [일]', position: 'bottom', offset: '-5' }}
                    />
                    <YAxis
                      dataKey="scc"
                      type="number"
                      label={{ value: '체세포수 (천)', position: 'insideLeft', offset: '10', angle: -90 }}
                    />
                    <Tooltip cursor={{ strokeDasharray: '3 3' }} />
                    <Scatter data={mainData1 && mainData1.plot_data} fill="green" onClick={CustomClick}>
                      {
                        mainData1 &&
                        mainData1.plot_data.map((entry) => (
                          <Cell key={`cell-${entry.day+entry.scc+entry.z}`} fill={COLORS1[classifyNumber1(entry.scc)]}/>
                        ))
                      }
                    </Scatter>
                    <ReferenceLine x={70} stroke="green" />
                    <ReferenceLine x={150} stroke="green" />
                    <ReferenceLine y={100} stroke="red" />
                    <ReferenceLine y={200} stroke="red" />
                  </ScatterChart>
                </ResponsiveContainer>    
                <ResponsiveContainer width='30%' height={200}>
                  <ScatterChart
                      width="90%"
                      height="85%"
                      margin={{              
                        top: 10,
                        right: 10,
                        bottom: 10,
                        left: 10,
                        }}>
                      <CartesianGrid />
                      <XAxis
                        dataKey="xaxis"
                        type="number"
                        label={{ value: '영역별두수(비율%)', position: 'bottom', offset: '0' }}
                      />
                      <YAxis
                        dataKey="yaxis"
                        type="number"                                    
                      />
                      <Tooltip cursor={{ strokeDasharray: '3 3' }} content={CustomTooltip2} />
                      <Scatter data={mainData1 && mainData1.plot_data2} fill="green">
                        {
                          mainData1 &&
                          mainData1.plot_data2.map((entry) => (
                            <Cell key={`cell-${entry.day+entry.scc+entry.z}`} fill={COLORS[classifyNumber(entry.scc)]}/>
                          ))
                        }
                      </Scatter>
                    </ScatterChart>
                  </ResponsiveContainer>
                <div className='flexbox:before'></div>
                <ul className='fatLabel'>
                  <li>10미만우</li>
                  <li>10만이상 20만이하우</li>
                  <li>20만초과 50만미만우</li>
                  <li>50만이상우</li>
                </ul>
              </div>
            </li>

            <li className='totalFat01'>
              <h1>비유일수별 유지방 분포도</h1>
              <div className='dotChartArea' >
                <ResponsiveContainer width="100%" height={200}>
                  <ScatterChart
                    width="90%"
                    height="90%"
                    margin={{              
                      top: 10,
                      right: 20,
                      bottom: 10,
                      left: 0,
                      }}>
                    <CartesianGrid />
                    <XAxis
                      dataKey="day"
                      type='number'
                      label={{ value: '비 유 일 수 [일]', position: 'bottom' ,offset: '0'}}
                    />
                    <YAxis
                      dataKey="fatp"
                      type='number'
                      label={{ value: '유지방 (%)', position: 'insideLeft', offset: '30', angle: -90 }}
                    />
                    <Tooltip cursor={{ strokeDasharray: '3 3' }} />
                    <Scatter data={mainData2} fill="green" onClick={CustomClick}>
                      {
                        mainData2 &&
                        mainData2.map((entry) => (
                          <Cell key={`cell-${entry.day+entry.fatp+entry.z}`} fill={COLORS2[classifyNumber2(entry.day , entry.fatp)]}/>
                        ))
                      }
                    </Scatter>
                    <ReferenceLine x={70} stroke="green" />
                    <ReferenceLine x={150} stroke="green" />
                    <ReferenceLine y={3.4} stroke="red" />                  
                  </ScatterChart>
                  
                </ResponsiveContainer>
                <ul className='fatLabel'>
                  <li>정상우</li>
                  <li>케토시스, 전위위험대상우</li>
                  <li>에너지과잉, 과산증위험</li>
                </ul>
              </div>    
            </li>

            <li className='totalProt01'>
              <h1>비유일수별 유단백 분포도</h1>
              <div className='dotChartArea' >
                <ResponsiveContainer width="100%" height={200}>
                  <ScatterChart
                    width="90%"
                    height="85%"
                    margin={{              
                      top: 10,
                      right: 20,
                      bottom: 10,
                      left: 0,
                      }}>
                    <CartesianGrid />
                    <XAxis
                      dataKey="day"
                      type='number'
                      label={{ value: '비 유 일 수 [일]', position: 'bottom' ,offset: '-5'}}
                    />
                    <YAxis
                      dataKey="protp"
                      type='number'
                      label={{ value: '유단백 (%)', position: 'insideLeft', offset: '25', angle: -90 }}
                    />
                    <Tooltip cursor={{ strokeDasharray: '3 3' }} />
                    <Scatter data={mainData3} fill="green" onClick={CustomClick}>
                      {
                        mainData3 &&
                        mainData3.map((entry) => (
                          <Cell key={`cell-${entry.day+entry.protp+entry.z}`} fill={COLORS3[classifyNumber3(entry.day , entry.protp)]}/>
                        ))
                      }
                    </Scatter>
                    <ReferenceLine x={70} stroke="green" />
                    <ReferenceLine x={150} stroke="green" />
                    <ReferenceLine y={3.1} stroke="red" />       
                  </ScatterChart>
                </ResponsiveContainer>
                <ul className='fatLabel'>
                  <li>정상우</li>
                  <li>번식장애, 배란장애우려</li>
                  <li>유량저하 예상</li>
                  <li>에너지함량과다 조.농 균형점검</li>
                </ul>
              </div>
            </li>

            <li className='totalMun01'>
              <h1>비유일수별 MUN 분포도</h1>
              <div className='dotChartArea' >
                <ResponsiveContainer width="100%" height={200}>
                  <ScatterChart
                    width="90%"
                    height="85%"
                    margin={{              
                      top: 10,
                      right: 20,
                      bottom: 10,
                      left: 10,
                      }}>
                    <CartesianGrid />
                    <XAxis
                      dataKey="cumday"
                      type='number'
                      label={{ value: '비 유 일 수 [일]', position: 'bottom' ,offset: '-5' }}
                    />
                    <YAxis
                      dataKey="mun"
                      type='number'
                      label={{ value: 'MUN (MG / DL)', position: 'insideLeft', offset: '25', angle: -90 }}
                    />
                    <Tooltip cursor={{ strokeDasharray: '3 3' }} />
                    <Scatter data={mainData4} fill="green" onClick={CustomClick}>
                      {
                        mainData4 &&
                        mainData4.map((entry) => (
                          <Cell key={`cell-${entry.cumday+entry.mun+entry.z}`} fill={COLORS4[classifyNumber4(entry.cumday , entry.mun)]}/>
                        ))
                      }
                    </Scatter>
                    <ReferenceLine x={70} stroke="green" />
                    <ReferenceLine x={150} stroke="green" />
                    <ReferenceLine y={12} stroke="red" />       
                    <ReferenceLine y={18} stroke="red" />
                  </ScatterChart>
                </ResponsiveContainer>
                <ul className='fatLabel'>
                  <li>조단백부족, 에너지과잉</li>
                  <li>에너지양호</li>
                  <li>조단백과잉, 에너지과잉</li>
                  <li>조단백부족, 약간에너지과잉</li>
                  <li>조단백균형, 에너지균형</li>
                  <li>조단백과잉, 약간에너지부족</li>
                  <li>조단백부족, 에너지부족</li>
                  <li>에너지부족</li>
                  <li>조단백과잉, 에너지부족</li>
                </ul>
              </div>
            </li>
          </ul>
        </div>   
      </div>
    </div>
  );
}

export default NewGraphTotal;
