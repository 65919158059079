import React from 'react';

import Pic522 from 'assets/images/sub/_images/pic522.jpg';
import Pic523 from 'assets/images/sub/_images/pic523.jpg';

function Do93() {

  return (
    <div className="techinfoview">
				<h1>요소중독</h1>
				<p className="pstyle">요소는 젖소에서 단백질 대용사료로서 이용되며 적정급여량은 전사료중에 함유된 조단백의 1/3에 상당하는 질소량으로서, 이것은 급여농후사료의 약 3%에 해당된다.</p>
				<div className="leftpart">
					<h2>원인</h2>
					<ul>
						<li>요소중독은 요소를 사료와 잘 혼합해서 먹일 때에는 거의 일어나지 않지만 요소만을 급여할 때는 20∼30G만을 급여해도 중독을 일으킬 수 있다. 또 요소는 물을 섞어 급여해서는 안되며 요소 급여 후 1시간 이내는 물을 먹지 않도록 주의한다. 또 요소를 분해하는 우레아제를 다량 함유한 두과식물의 잎줄기를 요소와 섞어 급여해서는 안된다.</li>
					</ul>
					<h2>증상</h2>
					<ul>
						<li>요소의 의한 중독증상은 요소 섭취후 20-30분이 지나면 나타나며, </li>
						<li>최초에는 전신근육의 경련과 강직이 나타나고, 고열, 식욕폐절, 침흘림, 반추 및 1위운동의 정지, 고창증과 호흡곤란이 나타난다. </li>
						<li>이어서 기립불능에 빠지며 안구는 약간 돌출한다.  </li>
					</ul>
					<h2>목장에서의 처치</h2>
					<ul>
						<li>염류사하제(인공가루스염, 황산마그네슘 등)를 먹여 제1위 내용물을 배설시킨다. </li>
						<li>강심제를 주사하고 대량의 수액(포동당, 링겔, 생리식염수)을 투여한다. </li>
						<li> 해독제로서 식초 500cc를 물에 2l에 타서 먹인다. </li>
						<li>경련에 대해서는 진정제와 비타민 B1등을 사용한다. </li>
					</ul>
				</div>
				<div className="rightpart">
					<img src={Pic522} className="nonborder" alt=""/>
					<img src={Pic523} className="nonborder" alt=""/>
				</div>
			</div>
  );
}

export default Do93;