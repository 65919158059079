import React from 'react';

function Fo56() {

  return (
    <div className="techinfo_view">
				<p className="viewTitle">송아지 허약증</p>
				<dl>
					<dt>발병대상</dt>
					<dd>소</dd>
				</dl>
				
				<dl>
					<dt>원인</dt>
					<dd>단백질과 그외 필수 영양소의 결핍이나 기후에 의한 스트레스가 원인이다. </dd>
				</dl>
				
				<dl>
					<dt>징후 및 증상</dt>
					<dd>송아지는 극히 힘이 없고 쇠약해지며 또한 등이 휘고 비경에 빨간 딱지가 생기고 설사를 하며 기립하지 못한다. 초산우에서 태어난 송아지가 경산우에서 태어난 송아지보다 자주 발생한다. </dd>
				</dl>
				
				<dl>
					<dt>질병분포와 손실규모</dt>
					<dd>송아지 허약증이란 용어는 1963년 몬타나의 비터루트 계곡에 실제 존재함에 따라 처음으로 쓰이기 시작했으며 미국의 북서부 지방에서 흔히 발생된다. 태평양 북서부 지역의 축군중에는 이 질병으로 인해 폐사율이 48%나 된다. 이외에도 유우의 번식실패로 경제적 손실이 크다. </dd>
				</dl>
				
				<dl>
					<dt>치료</dt>
					<dd>영양을 증진시키고 기후 스트레스를 최소로 줄인다.</dd>
				</dl>
				
				<dl>
					<dt>대책</dt>
					<dd>적절한 사료의 급여와 피난처를 설치해 준다. </dd>
				</dl>
				
				<dl>
					<dt>예방</dt>
					<dd>
						<p>다음과 같이 예방관리하면 발병율을 최소화할 수 있다.</p>
						<ul className="listStyle04">
							<li>사료와 관리를 최적으로 하여 분만전에 임신우의 체중이 증가하도록 유도한다.</li>
							<li>축군중에 경산우와 초산우를 분리시켜 각각 사료급여와 관리체계를 달리한다.</li>
							<li>에너지와 단백질, 무기물 및 비타민을 적절하게 급여한다.</li>
							<li>내부, 외부 기생충을 구제한다. </li>
						</ul>
					</dd>
				</dl>	
				
				<dl>
					<dt>참고</dt>
					<dd>송아지 허약증은 실제로 임신우의 영양적 결핍이나 기후 스트레스로 인해 일어난다. </dd>
				</dl>
			</div>
  );
}

export default Fo56;
