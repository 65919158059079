import React from 'react';
import { Outlet } from 'react-router-dom';

// 컴포넌트
import DciHeader from 'components/dcitest/header/DciHeader';

// scss
import './dciTest.scss';

function Dcitest() {

  return (
    <>
      {
        localStorage.getItem('month') &&
        <DciHeader />
      }
      <div className='dciCon'>
        <div className='dciConInner'>
          <Outlet />
        </div>
      </div>
    </> 
  );
}

export default Dcitest;
