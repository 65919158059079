import React from 'react';

import Pic533 from 'assets/images/sub/_images/pic533.jpg';
import Pic534 from 'assets/images/sub/_images/pic534.jpg';
import Pic535 from 'assets/images/sub/_images/pic535.jpg';

function Do97() {

  return (
    <div className="techinfoview">
      <h1>만성 위장염</h1>
      <div className="leftpart">
        <h2>원인</h2>
        <ul>
          <li>급성증과 비슷하며 
            <ul>
              <li>급성증의 원인이 지속되어 만성이 된다. </li>
              <li>위내 쌍구흡충등의 위충에 의해서도 만성 위장염이 발생한다.</li>
            </ul>
          </li>
        </ul>
        <h2>증상</h2>
        <ul>
          <li>설사와 변비가 되풀이 된다. </li>
          <li>가벼운 고창증 증세를 나타낸다. </li>
          <li>약간의 미열과 함께 식체를 일으키고 유량이 감소한다. </li>
          <li>빈혈증세와 영양불량 상태로 쇠약해진다. </li>
        </ul>
        <h2>목장에서의 처치</h2>
        <ul>
          <li>사료를 개선하여 주고 복부를 문지른다.  </li>
          <li>가벼운 운동과 일광욕을 실시하여 혈액순환과 위장의 활동을 촉진한다.  </li>
          <li>몸을 따뜻하게 해준다. </li>
          <li>양질의 청예사료와 보릿겨, 밀기울 등 소화되기 쉬운 사료를 급여한다. </li>
        </ul>
      </div>
      <div className="rightpart">
        <img src={Pic533} className="nonborder" alt=""/>
        <img src={Pic534} className="nonborder" alt=""/>
        <img src={Pic535} className="nonborder" alt=""/>
      </div>
    </div>
  );
}

export default Do97;