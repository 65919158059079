import React, { useRef } from 'react';
import useWindowSize from 'hooks/useWindowSize';

// 컴포넌트
import SubTitle from 'components/subTitle/SubTitle';

// 이미지
import background from 'assets/images/containers/business/descendantCow/descend_back.png';
import background768 from 'assets/images/containers/business/descendantCow/descend_back_768.png';
import OxSelection from 'assets/images/containers/business/descendantCow/Oxselection.png';
import OxSelection768 from 'assets/images/containers/business/descendantCow/OxSelection_768.png';
import freshFarmBackground from 'assets/images/containers/business/descendantCow/freshFarm_back.png';
import freshFarmBackground768 from 'assets/images/containers/business/descendantCow/freshFarm_back_768.png';
import freshFarmJoinSequence from 'assets/images/containers/business/descendantCow/freshFarmJoinSequence.png';
import freshFarmJoinSequence1440 from 'assets/images/containers/business/descendantCow/freshFarmJoinSequence_1440.png';
import descendantFarmJoinSquence from 'assets/images/containers/business/descendantCow/descendantFarmJoinSqeunce.png';
import descendantFarmJoinSquence768 from 'assets/images/containers/business/descendantCow/descendantFarmJoinSqeunce_768.png';

// scss
import './descendantCow.scss';

function DescendantCow() {
  const windowSize = useWindowSize();

  const oxRef = useRef(null);
  const freshRef = useRef(null);
  const descendantRef = useRef(null);

  const scrollToRef = (ref) => {
    const currenttop = ref.current.offsetTop - 100;
    window.scrollTo({
      top: currenttop,
      behavior: 'smooth',
    });
  };

  const scrollBehaivor = (tab) => {
    switch (tab) {
      case 'oxSelect':
        scrollToRef(oxRef);
        break;
      case 'freshFarm':
        scrollToRef(freshRef);
        break;
      case 'descendantFarm':
        scrollToRef(descendantRef);
        break;
      default:
        break;
    }
  };
  return (
    <div className="descendantCow subCon pullContent">
      <ul className="descendantTab">
        <li>
          <button type="button" onClick={() => scrollBehaivor('oxSelect')}>
            선발
          </button>
        </li>
        <li>
          <button type="button" onClick={() => scrollBehaivor('freshFarm')}>
            청정육종농가
          </button>
        </li>
        <li>
          <button
            type="button"
            onClick={() => scrollBehaivor('descendantFarm')}
          >
            후대검정농가
          </button>
        </li>
      </ul>
      <SubTitle />
      <div className="descendantCowTitle">
        <strong>후대검정은 농가혁신과 발전을 위한 첫걸음입니다.</strong>
        <img
          srcSet={`${background} 768w , ${background768} 769w`}
          sizes="(max-width: 768px) 100vw, 760px"
          alt="젖소이미지"
        />
        <dl>
          <dt>개요</dt>
          <dd>
            국내 및 낙농선진국에서 상위 0.1% 수준의 고능력 수정란을 도입하여
            청정육종농가에서 생산된 후보씨 수소를 국내 고유환경에서 후대검정을
            실시합니다.
          </dd>
          <dd>
            각종 질병발생과 경제적 요인으로 외국산 유전자원의 수입이 중단되는
            상황에서 어떤 외부환경의 변화에도 적극 대처할 수 있는 우리 고유의
            유전자원을 만들어야 합니다.{' '}
          </dd>
          <dd>
            실제 국내 낙농가에서 6년간의 후대검정 기간을 거쳐 최첨단 통계처리
            기법으로 국내 환경과 사양 여건에 맞는 국제적 수준의 한국형
            보증종모우를 선발합니다.
          </dd>
        </dl>
      </div>
      <div ref={oxRef} className="oxSelection">
        <h5>한국형 보증씨 수소의 선발</h5>
        {windowSize.width >= 768 ? (
          <img src={OxSelection768} alt="한국현 보증씨수소 선발 사진" />
        ) : (
          <img src={OxSelection} alt="한국현 보증씨수소 선발 사진" />
        )}
        <ol className="hidden">
          <li>
            <p>청정 육종농가</p>
            <span>고능력 수정관 이식 &#40;미국 , 캐나다&#41;</span>
            <span>후보씨 수소</span>
          </li>
          <li>
            <p>농협젖소개량사업소</p>
            <span>후대 검정액 생산 및 공급</span>
          </li>
          <li>
            <p>후대검정농가</p>
            <span>암소 교배</span>
          </li>
          <li>
            <p>농협젖소개량사업소</p>
            <span>딸소 능력검정</span>
          </li>
          <li>
            <p>국립축산과학원가축개량협의회</p>
            <span>유전능력평가 보증씨수소 선발</span>
          </li>
        </ol>
      </div>
      <div ref={freshRef} className="freshFarm">
        <div className="freshFarmInner">
          <h5>청정육종농가는?</h5>
          <p>
            낙농 선진국의 최고 유전인자를 공급받아 한국형 보증씨수소를 탄생시킬
            최정예 청정목장의 주인공 입니다.
          </p>
          {windowSize.width >= 768 ? (
            <img src={freshFarmBackground768} alt="농장사진" />
          ) : (
            <img src={freshFarmBackground} alt="한국현 보증씨수소 선발 사진" />
          )}
          <strong>청정육종농가</strong>
        </div>
        <dl>
          <dt>혜택</dt>
          <dd>
            <ul>
              <li>
                북미산 고능력&#40;상위 0.1% 이내&#41; 수정란 무상 공급과 수정란
                이식기술 지원
              </li>
              <li>고능력 수정란에 의해 생산된 암송아지 농가 보유</li>
              <li>
                정교한 우군 계획교배 컨설팅 및 육성우 채란을 통한 개량
                선도농가로 육성
              </li>
              <li>
                정기질병검진, 방역시설, 수송아지 사양비, 낙농선진지 연수등
                다양한 지원 시스템
              </li>
            </ul>
          </dd>
        </dl>
        <dl>
          <dt>신청자격</dt>
          <dd>
            <ul>
              <li>
                경산우 40두 이상 사육 농가 &#40;청정 육성우군 보유농가 협의후
                신청가능&#41;
              </li>
              <li>목장내 암소 중 부모를 아는 개체 비율이 75% 이상</li>
              <li>
                젖소 전염성 질병&#40;우결핵, 구제역, 부르셀라, 요네, 소 류코시드
                등&#41;이 모두 음성인 우군 보유
              </li>
              <li>
                육성우 목장 - 미경산우 20두 이상 사육 농가로 독립된
                방역시스템들을 구축, 젖소 전염병 질병 검사결과 음성인 농가
              </li>
            </ul>
          </dd>
        </dl>
        <div className="freshFarmJoinSequnce">
          <p>신청절차</p>
          {windowSize.width >= 1440 ? (
            <img src={freshFarmJoinSequence1440} alt="참여순서 사진" />
          ) : (
            <img src={freshFarmJoinSequence} alt="참여순서 사진" />
          )}
          <ol className="hidden">
            <li>1. 참여희망서 접수</li>
            <li>2. 질병검사 &#40;질병검사비용 보조&#41;</li>
            <li>3. 사업참여신청서 접수</li>
            <li>4. 현지심사</li>
            <li>5. 최종선정</li>
          </ol>
        </div>
      </div>
      <div ref={descendantRef} className="descendantFarm">
        <div className="descendantFarmInner">
          <h5>후대검정농가는?</h5>
          <p>북미 최상위 씨수소로 목장 개량과 보증씨수소 생산</p>
          {windowSize.width >= 768 ? (
            <img src={freshFarmBackground768} alt="농장사진" />
          ) : (
            <img src={freshFarmBackground} alt="한국현 보증씨수소 선발 사진" />
          )}
          <strong>후대검정농가</strong>
        </div>
        <div className="dlBox">
          <dl>
            <dt>혜택</dt>
            <dd>
              <ul>
                <li>
                  북미산 고능력&#40;상위 0.1% 이내&#41; 수정란 무상 공급과
                  수정란 이식기술 지원
                </li>
                <li>고능력 수정란에 의해 생산된 암송아지 농가 보유</li>
                <li>
                  정교한 우군 계획교배 컨설팅 및 육성우 채란을 통한 개량
                  선도농가로 육성
                </li>
                <li>
                  정기질병검진, 방역시설, 수송아지 사양비, 낙농선진지 연수등
                  다양한 지원 시스템
                </li>
              </ul>
            </dd>
          </dl>
          <dl>
            <dt>신청자격</dt>
            <dd>
              <ul>
                <li>검정사업 참여농가</li>
                <li>목장 내 암소중에서 부모를 아는 개체 비율이 70%이상</li>
              </ul>
            </dd>
          </dl>
        </div>
        <div className="descendantFarmJoinSequnce">
          <p>신청절차</p>
          <div className="imgBox">
            {windowSize.width >= 768 ? (
              <img
                src={descendantFarmJoinSquence768}
                alt="후대검정농가 순서 사진"
              />
            ) : (
              <img
                src={descendantFarmJoinSquence}
                alt="후대검정농가 순서 사진"
              />
            )}
            <ol className="hidden">
              <li>1. 사업 참여가능 여부확인 &#40;사육두수, 혈통비율 등&#41;</li>
              <li>검정조합에 서류제출&#40;사업신청서 등&#41;</li>
              <li>후대검정농가 최종선정</li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DescendantCow;
