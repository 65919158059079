import React from 'react';

import pic742 from 'assets/images/sub/_images/pic742.jpg';
import pic743 from 'assets/images/sub/_images/pic743.jpg';
import pic744 from 'assets/images/sub/_images/pic744.jpg';

function Do157() {

  return (
    <div className="techinfoview">
      <h1>질산염 중독</h1>
      <div className="leftpart">
      <p className="pstyle">질산염이 다량 함유된 사료나 식수를 단시간내에 대량 섭취하면 체내의 산소결핍상태를 초래해 호흡곤란을 일으켜 사망에 이른다. </p>
        <h2>원인</h2>
        <ul>
          <li>질산염이 다량 함유된 사료-일조량이 부족하고 기후가 급변하여 제초제가 많이 살포된 지역에서 생산된 건초, 무잎, 연맥, 귀리, 옥수수, 수단그라스 등 </li>
          <li>불결한 음용수(飮用水)-사람이나 가축의 배설물을 버린 장소, 쓰레기장, 우사에서 가까운 우물이나 고인물에 많이 함유되어 있다. </li>
        </ul>
        <h2>증상</h2>
        <ul>
          <li>반추와 식욕이 정지되며 유두 및 유방주위가 갑자기 새하얗게 퇴색한다. </li>
          <li>침을 흘리고 설사를 일으킨다.(체온은 정상 또는 정상이하로 떨어진다.) </li>
          <li>계속하여 눈, 입술, 혀, 외음부등이 암자색을 띤다. </li>
          <li>위와 같은 증상을 전후해서 심장 박동과 호흡이 가빠지며 마침내 옆으로 드러누워 1∼2시간 정도후 폐사한다. </li>
          <li>혈액은 쵸코렛색으로 변하고 응고가 잘되지 않는다. </li>
          <li>임신우에서는 유산을 일으킬 수 있다.</li>
        </ul>
        <h2>목장에서의 처치</h2>
        <ul>
          <li>발병후 경과가 빠르기 때문에 이미 손쓰기가 늦을 경우가 많다. 
            <ul>
              <li>전신의 보온, 수분 공급 및 피부를 마사아지하여 준다. </li>
              <li>같은 조건에 놓여있는 다른소가 발병하지 않도록 의심스런 사료는 빨리 치우고 수의사의 지도를 받는다. </li>
              <li>수혈을 실시하고 해독제(하이포-요법)를 응용한다.:제 2장 응급처지편 참고 </li>
              <li>쇼크에 의한 폐사를 줄이기 위해 부신 피질 호르몬을 주사한다.</li>
            </ul>
          </li>
        </ul>
      </div>
      <div className="rightpart">
        <img src={pic742} className="nonborder" alt=""/>
        <img src={pic743} className="nonborder" alt=""/>
        <img src={pic744} className="nonborder" alt=""/>
      </div>
    </div>
  );
}

export default Do157;