import React from 'react';

import Pic574 from 'assets/images/sub/_images/pic574.jpg';
import Pic575 from 'assets/images/sub/_images/pic575.jpg';
import Pic576 from 'assets/images/sub/_images/pic576.jpg';

function Do108() {

  return (
    <div className="techinfoview">
				<h1>악성 카탈열</h1>
				<div className="leftpart">
					<h2>원인</h2>
					<ul>
						<li>이 병은 바이러스에 의한 급성 열성 전염병으로서 이 바이러스를 가지고 있는 면양에 의해서 전파되며 미란성(카달성)의 구내염, 위장염, 각막, 결막염을 일으키며 고열을 보이는 것이 특징이다. </li>
					</ul>
					<h2>증상</h2>
					<ul>
						<li>병든 소는 갑자기 기립불능에 빠지며 극도로 식욕과 원기가 없어진다. </li>
						<li>비유가 정지되며 41°∼42℃의 고열이 나고 맥박수가 증가한다.(100∼210회/1분) </li>
						<li>점막이 침해받으므로 끈적한 다량의 콧물이 나오며 콧구멍이 막혀 호흡곤란이 나타난다. </li>
						<li>각막(角膜)은 전체에 걸쳐 하얗게 흐려지며 결막염이 생긴다. </li>
						<li>체표의 피부가 충혈되고 습진이 생겨만지면 통증을 나타낸다. </li>
						<li>급성예(急性例)에서는 의식장해와 근육경련이 나타나며 기립불능에 빠져 1∼3일경과로 사망한다. </li>
					</ul>
					<h2>목장에서의 처치</h2>
					<ul>
						<li> 젖소가 발병하면 즉시 격리하고 동거하는 면양이 있다면 즉시 처분해야 한다. </li>
						<li>면양은 이 바이러스에 감염되어도 발병하지 않고 계속 병원성 바이러스를 보균하기 때문에 본병 발생지의 면양이 도입은 금해야 한다. </li>
						<li>40℃이상의 발열이 계속되며 항생물질을 투여해도 효과가 없고 눈과 코 주위에 병변이 있다면 본병으로 의심된다. </li>
						<li>치료법은 없고 발견되면 즉시 도태하여 전파를 막는다.</li>
					</ul>
				</div>
				<div className="rightpart">
					<img src={Pic574} className="nonborder" alt=""/>
					<img src={Pic575} className="nonborder" alt=""/>
					<img src={Pic576} className="nonborder" alt=""/>
				</div>
			</div>
  );
}

export default Do108;