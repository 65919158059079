import React from 'react';

import Pic467 from 'assets/images/sub/_images/pic467.jpg';
import Pic468 from 'assets/images/sub/_images/pic468.jpg';

function Do75() {

  return (
    <div className="techinfoview">
				<h1>제4위 궤양</h1>
				<p className="pstyle">이 병은 이유기(생후 1∼2개월령) 송아지에 가장 많이 발생하지만 포유기 송아지에도 때때로 볼 수 있다.</p>
				<div className="leftpart">
					<h2>원인</h2>
					<ul>
						<li>이유기에 많이 발생하는 것은 인공유 또는 대용유에서 고형사료로 바꿀 때 제4위 점막이 물리적 상처를 입어 일어난다. </li>
						<li>포유기 때의 발생은 부적절한 포유에 의해 제4위 고창증을 유발하여 
							<ul>
								<li>제4위벽의 혈행장해 </li>
								<li> 제4위 점막에 세균의 침입 등으로 2차적으로 발생한다.</li>
							</ul>
						</li> 
					</ul>
					<h2>증상</h2>
					<ul>
						<li>발병 일령과 궤양을 일으킨 원인에 따라 다양</li>
						<li>만성형
							<ul>
								<li> 이유기 송아지때 볼 수 있는 제4위 궤양은 대부분 만성이다. </li>
								<li>만성 궤양은 비교적 궤양정도가 가볍기 때문에 뚜렷한 증상 없이 경과한다.</li>
							</ul>
						</li>
						<li>급성형
							<p>급성궤양은 경과가 빨라 사망률이 높다. </p>
							<ul>
								<li>갑자기 식욕이 없어지고 불안한 모습을 보이며 일어나지 않는다. </li>
								<li>체온은 정상이거나 떨어진다. 안구가 들어가고 탈수현상을 보인다.</li>
								<li>제4위가 가스로 인해 복부가 팽대한다. </li>
								<li>청진하면 우측겸부에서 급속성 음향을 들을 수 있다.  </li>
							</ul>
						</li>
					</ul>
					<h2>목장에서의 처치</h2>
					<ul>
						<li>이유후 식욕이 일정치 않고 발육이 늦은 것은 변의 상태를 유심히 관찰한다. </li>
						<li>때때로 정상적인 변에 진뜩진뜩한 액체나 응고된 혈액이 섞여 있을때는 만성 제4위 궤양을 의심한다. </li>
						<li>사료를 건초와 저단백질 사료로 바꾸어준다. </li>
						<li>이와 같은 방법으로 증상이 호전되지 않으면 수의사의 왕진을 구해야 한다.</li>
						<li>만성일 때 점막보호제나 제산제로서 산화마그네슘(체중 50kg당 50∼80g)을 먹인다. </li>
						<li>급성형이면 경과가 빨라 방치하면 폐사하므로 수의사의 왕진을 구한다.</li> 
					</ul>
				</div>
				<div className="rightpart">
					<img src={Pic467} className="nonborder" alt=""/>
					<p>* 아래(복부)에서 본 제 4위의 발달 모습 </p>
					<img src={Pic468} className="nonborder" alt=""/>
					<p>급성제4위 궤양을 일으킨 송아지(생후14일령)가스의 다량발생으로 복부가 팽대되어 있다. </p>
				</div>
			</div>
  );
}

export default Do75;