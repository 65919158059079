import React from 'react';
import FindCow from 'containers/web/performance/findCow/FindCow';
import { Helmet } from 'react-helmet-async';

function FindCowPage() {
  return (
    <>
      <Helmet>
        <title>개체찾기</title>
      </Helmet>
      <FindCow />
    </>
  );
}

export default FindCowPage;
