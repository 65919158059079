import React from 'react';

import pic367 from 'assets/images/sub/_images/pic367.gif';
import pic368 from 'assets/images/sub/_images/pic368.gif';
import pic369 from 'assets/images/sub/_images/pic369.gif';
import pic370 from 'assets/images/sub/_images/pic370.gif';
import pic371 from 'assets/images/sub/_images/pic371.gif';
import pic372 from 'assets/images/sub/_images/pic372.gif';
import pic373 from 'assets/images/sub/_images/pic373.gif';

function Do206() {

  return (
    <div className="techinfoview">
      <h1>착유우를 위한 우사 및 시설-계류식 우사</h1>
      <p className="pstyle">일반적으로 착유우 우사는 계류식이라 불리우는 타이스톨(tie stall) 혹은 스탄쉥(stanchion) 우사와 비계류식이라 불리는 후리스톨(free stall)우사로 구분된다. 우사형태는 각 형태의 장단점을 면밀히 검토하고, 건축비와 유지비, 노동력 등을 비교하여 결정하여야 한다. </p>
      <h2>계류식 우사(stall barn) </h2>
      <p className="pstyle">&nbsp;&nbsp;&nbsp;이는 경산우 60두 이하의 낙농가에서 널리 이용하고 있는 우사 형태로서 착유할 때 허리를 구부리고 작업해야 하는 것 이외에는 비계류식 우사의 경우와 같은 수준의 기계화가 가능하다. 착유우 60두 규모가지는 어느 형태를 선택해도 두당 건축비에는 차이가 없으나 계류식 우사는 개체관리가 용이한 장점이 있다.  실제로 계류식 우사를 신축하거나 기존우사를 계류식 우사로 개조할 때는 ①젖소 사육규모, 기본 착유두수, 노동력 상황 및 우유생산 목포, ②우상의 배열, 크기 및 형태, ③우상의 바닥피복 및 깔짚의 형태, ④필요한 우방(pen)의 수와 크기, ⑤단열 및 환기방법, ⑥착유시설의 종류 및 분뇨처리체계, ⑦사료형태, 저장 및 급여체계 그리고, ⑧앞으로의 규모 확장계획 등을 미리 검토하여 구체적인 계획을 세우는 것이 좋다.</p>
      <ul>
        <li>스탄스톨(stanchion stalls) 
          <ul>
            <li>스탄 우상은 그림 38에서 보는 바와같이 한 마리씩 잠그고 풀수 있게 되어 있거나 레바장치로 여러마리를 한꺼번에 잠그거나 풀어 줄수 있게 되어있다. 그리고 스탄쉥의 위치를 앞뒤로 조절해서 가터(gutter)에 분이 떨어지게 조정할 수 있게 만들 수도 있으나 큰 효과는 없다. 우상을 깨끗하게 유지하기 위해서는 우상의 길이를 알맞게 하고 배분할 때 몸을 움추리는 것을 방지하는 제어장치(cow trainer)의 위치를 잘 조정하며 관리를 잘하여야 한다.  워터 컵(water cup)은 한칸 걸러 한 개씩 사조 위에 설치할 수 있는데, 급수 파이프는 우사 바닥이나 스탄쉥구조의 일부로 또는 위에 설치 할 수 있다. 분리책(partition)을 철거하면 스탄쉥 스톨은 착유사로 이용될 수 있으며 질병치료를 위해 소를 고정하거나 분만시에도 사용될 수 있다.<br/>
              <img src={pic367} className="nonborder" width="30%" alt=""/>
              <p>그림 38. 스탄 스톨</p>
            </li>
          </ul>
        </li>
        <li>타이스톨(tie stalls) 
          <ul>
            <li>타이스톨은 그림 39와 같이 여러형태가 있다. 소를 개별적으로 매고 풀어 주기 때문에 레버 스탄쉥의 경우보다 소에게 편안함을 제공해 준다. 적절한 우상 크기가 소에게 편안함을 주고 깨끗한 우상을 유지하는 관건이 된다.<br/>
              <img src={pic368} className="nonborder" width="60%" alt=""/>
              <p>그림 39. 타이스톨의 종류와 치수</p>
              <ul>
                <li>우상(stall)의 크기
                  <ul>
                    <li>우상의 길이는 소가 앉았을 때 유방이 우상위에 놓일만큼 충분히 길어야 한다. 그리고 우상폭은 소나 착유자가 편안하도록 충분히 넓어야 한다. 우상의 크기는 표80에서 보는 바와같다. 우상의 길이가 짧으면 유두나 유방에 상처를 입히기 쉽다. 우상의 크기가 적절 하더라도 우상에 분뇨가 떨어지는 경우가 있다. 카우 트레이너(제어장치)의 위치를 적절히 조정하고 우상에 떨어진 분을 자주 긁어 내려 주므로서 소의 유방을 깨끗하게 유지할 수 있다. </li>
                  </ul>
                  <p><b>&lt;표 80&gt; 우상의 크기</b></p>	
                  <table className="tablestyle">
                    <tr>
                      <td rowSpan="2">젖소 체중(kg)</td>
                      <td colSpan="2">스탄스톨</td>
                      <td colSpan="2">타이스톨</td>
                    </tr>
                    <tr style={{background: '#273143',color: '#fff'}}>
                      <td>너비</td>
                      <td>길이</td>
                      <td>너비</td>
                      <td>길이</td>
                    </tr>
                    <tr>
                      <td>550내외</td>
                      <td>4′0″</td>
                      <td>5′6″</td>
                      <td>4′0″</td>
                      <td>5′9″</td>
                    </tr>
                    <tr>
                      <td>550-700</td>
                      <td>4′6″</td>
                      <td>5′9″</td>
                      <td>4′6″</td>
                      <td>6′0″</td>
                    </tr>
                    <tr>
                      <td>700이상</td>
                      <td>-</td>
                      <td>-</td>
                      <td>5′0″</td>
                      <td>6′6″</td>
                    </tr>
                  </table>
                  <p>주 : 1＇ = 30.48㎝ 1＂ = 2.54㎝</p>
                </li>
                <li>우상 깔판(stall mats) 
                  <ul>
                    <li>일반적으로 고무 깔판이 많이 사용되는데, 콘크리트 바닥에 단단히 고정 시키기 위해 콘크리트 바닥을 시공할 때 까는 것이 좋다. 고무깔판을 우상바닥 크기보다 앞과 뒤로 각각 6＂ 씩 짧게 하고 양옆으로는 각각 3＂씩 좁게 하여 시공하면 된다. 콘크리트 작업을 한 다음 한시간 쯤 기다렸다가 고무 깔판을 놓고 주변에 시멘트로 발라 고정시키면 된다(그림 40참고).  깔판을 잘 깔았다고 해서 소가 깨끗해지는 것이 아니므로 관리를 잘해야 한다. 짧게 짜른 깔짚을 조금 깔아 주고 소가 분뇨구에 배분 배뇨를 하도록 카우트레이너(제어장치)를 잘 설치해야 한다. 고무 깔판은 전류가 통하지 않기 때문에 전기식 카우 트레이너를 설치할 경우 타이스톨에서 사용하는 끈은 금속체인으로 해야 한다. 그리고 고무 깔판은 소의 발톱이 마모되지 않게 하므로 적당한 간격으로 발톱을 깍아주어야 한다. </li>
                  </ul>
                </li>
                <li>우상의 배열 
                  <ul>
                    <li>일반적으로 분뇨제거, 착유, 수정이나 임신감정 등 소 뒤에서 작업하는 시간이 많으므로 우상은 대미식으로 배열하는 것이 바람직하다. 이렇게 하므로서 작업통로(litter alley) 한 개만이 필요하게 되고 가터 크리너 (분뇨통로 청소기) 한조만 설치하면 되는 것이다. 뿐만 아니라 착유시에 걷는 거리와 시간을 단축할 수 있으며 파이프라인식 착유시설 설치 비용을 절감할 수 있다. 대두식으로 배열하면 사료 급여가 용이하나 착유가 불편하고 파이프라인 착유시설 설치비용이 많이 들 뿐만 아니라 분뇨구가 양쪽에 있어 양쪽 벽에 분뇨가 튀어 우사벽이 더럽혀 진다. 파이프라인 착유시설을 설치 할 때는 우사 바닥의 경사를 우유저장실 쪽으로 1/120 정도로 경사지게 하여 우유 파이프가 바닥에서 고른 높이에 위치하도록 하는 것이 좋다. 분뇨구의 기울기는 1/240 정도로 하거나 수평으로 해도 무방하며, 우유 저장실은 우사의 맨 끝에 위치하는 것이 좋다. <br/>
                      <img src={pic369} className="nonborder" width="30%" alt=""/>
                      <p>그림 40. 우상 깔판</p>
                    </li>
                  </ul>
                </li>
                <li>우사의 크기 
                  <ul>
                    <li>가장 알맞는 계류식 우사의 폭은 벽 바깥 쪽을 기준으로 36＇이다. 작업통로의 폭은 6＇가 알맞으며 사조는 평사조로 하는 것이 청소하기에 편하다 (표 81). 분뇨구의 폭은 그린 41과 같이 16＂또는 18＂로 한다. 분뇨구의 폭이 크면 뇨가 작업통로에 튀는 것이 줄어들 수 있으며, 깊이는 우상으로부터 16＂작업통로 바닥에서 14＂로 하는 것이 적당하다. 우상과 작업통로의 높이를 같이하고 분뇨구의 폭을 12＂로 하는 경우도 있다. 분뇨구에 깔짚이 들어가는 것을 막기 위해 분뇨구 쇠창살(gutter grates)을 설치할 수도 있다. 전통적인 우사청소기(barn cleaner, 반크리너)가 사용되는 분뇨통로는 그림 41과 42에 나타나 있으며, 계류식 우사의 표준치수와 착유우 60두를 수용할 수 있는 타이스톨 우사의 평면도와 단면도를 소개하면 그림 43, 44와 같다. <br/>
                    <p><b>&lt;표 81&gt; 계류식우사의 표준치수 </b></p>
                    <table className="tablestyle">
                      <tr>
                        <td colSpan="2">구분</td>
                        <td>규격</td>
                      </tr>
                      <tr>
                        <td rowSpan="4">통로폭</td>
                        <td>평사조 - 급사통로</td>
                        <td>5′6″- 6′6″</td>
                      </tr>
                      <tr>
                        <td>턱진 사조 - 급사통로</td>
                        <td>6′0″- 6′6″</td>
                      </tr>
                      <tr>
                        <td>총소(다용도)통로(반크리너설치)</td>
                        <td>6′0″</td>
                      </tr>
                      <tr>
                        <td>횡단통로</td>
                        <td>4′6″</td>
                      </tr>
                      <tr>
                        <td rowSpan="2">사조폭</td>
                        <td>체중550kg내외</td>
                        <td>20″</td>
                      </tr>
                      <tr>
                        <td>체중550kg이상</td>
                        <td>24″-27″</td>
                      </tr>
                      <tr>
                        <td rowSpan="3">배분뇨구</td>
                        <td>넓이(폭)</td>
                        <td>116″또는 18″</td>
                      </tr>
                      <tr>
                        <td>길이(우상쪽)</td>
                        <td>11″- 16″</td>
                      </tr>
                      <tr>
                        <td>(청소통로쪽)</td>
                        <td>11″- 14″</td>
                      </tr>
                    </table>
                    <p>주 : 1＇= 30.48㎝ 1＂= 2.54㎝</p><br/>
                    <img src={pic370} className="nonborder" width="30%" alt=""/>
                    <p>그림 41. 표준 반크리너를 도입한 분뇨구</p>
                    <img src={pic371} className="nonborder" width="40%" alt=""/>
                    <p>그림 42. 분뇨구 덮개 (gutter grates)</p>
                    <img src={pic372} className="nonborder" width="40%" alt=""/>
                    <p>그림 43. 경산우 60두용 타이스톨 우사의 평면도</p>
                    <img src={pic373} className="nonborder" width="40%" alt=""/>
                    <p>그림 44. 경산우 60두용 타이스톨 우사의 단면도</p>
                    </li>
                  </ul>
                </li>
                <li></li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
}

export default Do206;