import React, { useEffect } from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import BoLayout from 'components/bo/BoLayout';

// SCSS import
import './boStyles.scss';

function Bo() {
  const navigate = useNavigate();
  const location = useLocation();
  const isLoginPage = location.pathname.includes('/bo/login');

  useEffect(() => {
    const session = Cookies.get('session');
    if (!session && !isLoginPage) {
      alert('로그인이 필요한 서비스입니다.');
      navigate('/bo/login');
    }
  }, [navigate, location, isLoginPage]);

  return (
    <div className="bo-container">
      {isLoginPage ? (
        <Outlet />
      ) : (
        <BoLayout>
          <div className="bo-content">
            <Outlet />
          </div>
        </BoLayout>
      )}
    </div>
  );
}

export default Bo;
