import React from 'react';

import pic697 from 'assets/images/sub/_images/pic697.jpg';
import pic698 from 'assets/images/sub/_images/pic698.jpg';
import pic699 from 'assets/images/sub/_images/pic699.jpg';
import pic700 from 'assets/images/sub/_images/pic700.jpg';
import pic701 from 'assets/images/sub/_images/pic701.jpg';

function Do141() {

  return (
    <div className="techinfoview">
      <h1>골연증</h1>
      <p className="pstyle">칼슘이나 인산의 대사장애, 혹은 섭취 부족으로 인해 이것이 부족되면 기존 골격으로부터 칼슘이나 인이 융해되어 체내에서 이용되므로 뼈가 연약해져 각종 질환을 일으킨다. </p>
      <div className="leftpart">
        <h2>원인</h2>
        <ul>
          <li>칼슘 성분의 부족으로 칼슘(Ca)과 인(P)의 비율이 평형을 이루지 못해 발병.
            <ul>
              <li>사료에 칼슘이 부족하면 인과의 비율이 나빠져 흡수를 나쁘게 한다.</li>
              <li>비타민 D는 칼슘과 인의 흡수를 돕는데 일광욕이나 청초급여가 제대로 이루어지지 않으면 비타민 D가 부족하게 된다. </li>
            </ul>
          </li>
        </ul>
        <h2>증상</h2>
        <ul>
          <li>목탕이나 흙, 쇠붙이 등 아무것나 닥치는 대로 먹고 소화불량이나 설사를 일으킨다. </li>
          <li>육성기에는 성장이 지체된다.  </li>
          <li>안면이나 기타 뼈가 무러지고 파행(절뚝걸음), 관절의 종창, 통증, 골절을 일으키기 쉽다.</li>
        </ul>
        <h2>목장에서의 처치</h2>
        <ul>
          <li>골연증은 치료보다 예방이 중요하며 사양관리에 주의하는 것이 최선의 대책이다. 
            <ul>
              <li>자급 사료를 중심으로 양질의 건초, 양질의 청초 급여</li>
              <li>적당한 일광욕과 운동을 시킨다. </li>
              <li>운동장에 린칼 블록 등을 놓아두어 소가 자유로이 핥아 먹을 수 있도록 해준다. </li>
              <li>임신우에 있어서는 건유전까지 칼슘과 인을 별도로 급여해준다.</li>
              <li>시판되고 있는 칼슘제를 주사해 준다 .</li>
            </ul>
          </li>
        </ul>
      </div>
      <div className="rightpart">
        <img src={pic697} className="nonborder" alt=""/>
        <img src={pic698} className="nonborder" alt=""/>
        <img src={pic699} className="nonborder" alt=""/>
        <img src={pic700} className="nonborder" alt=""/>
        <img src={pic701} className="nonborder" alt=""/>
      </div>
    </div>
  );
}

export default Do141;