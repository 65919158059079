import React, { useState , useEffect, useRef } from 'react';
import { useLocation , useNavigate } from 'react-router-dom';
import { postState } from 'utils/api';

// 컴포넌트
import ErrorMessage from 'components/errorMessage/errorMessage';

// scss
import './girlcowSelect.scss';

function GirlcowSelect() {

  // 에러처리
  const [isModalOpen, setIsModalOpen] = useState(false); // 모달 추가
  const [errorMessage, setErrorMessage] = useState(''); 

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => {
    setIsModalOpen(false);
    setErrorMessage(''); // 모달이 닫힐 때 에러메세지 초기화
  };

  // 데이터 불러오기
  // eslint-disable-next-line no-restricted-globals
  const location = useLocation();
  const baseDir=`${location.pathname.toLowerCase()}/`;   
  const [ mainData , setMainData ] = useState(null);
  const [ filterData , setFilterData ] = useState(null);

  const [ props , setProps] = useState({
    id: localStorage.getItem('milkFarmCode'),
    passwd: localStorage.getItem('milkFarmPwd'),    
  });

  useEffect(() => {
    postState(baseDir,props)
    .then(getdata => {      
      if (getdata !== undefined) {  
        // console.log(getdata);            
        setMainData(getdata);
        setFilterData(getdata.plot_data2);
      }
    })
    .catch(error => {           
      setErrorMessage(error.message);
      openModal();
    });    
  }, [props]);
  

  
  const [ cowCheckList , setCowCheckList ] = useState([]); // 체크박스 선택된 암소들 [495, 496, 497, 498, 499
  const expRef = useRef(null); // 경산우 미경산우 선택
  const searchRef = useRef(null); // 검색어 종류
  const searchInputRef = useRef(null); // 검색어 입력

  // 경산우 미경산우
  function filterExpCow() {
    const exp = expRef.current.value;
  
    let newCowData = [];

    if (exp === 'all') {
      newCowData = mainData.plot_data2;
    }

    if (exp === 'exp') {
      newCowData = mainData.plot_data2.filter((data) => data.pari !== 0);
    }

    if (exp === 'noexp') {
      newCowData = mainData.plot_data2.filter((data) => data.pari === 0);
    }

    setFilterData(newCowData);
  }

  // 관리번호 바코드 검색
  function searchCow() {
    const searchType = searchRef.current.value;
    const searchInput = searchInputRef.current.value;

    let newCowData = [];

    if (searchType === 'number') {
      newCowData = mainData.plot_data2.filter((data) => String(data.csname).includes(searchInput));
    }

    if (searchType === 'code') {
      newCowData = mainData.plot_data2.filter((data) => String(data.barcode).includes(searchInput));
    }

    setFilterData(newCowData);
  }

  // 체크박스 선택
  function checkCow(regno) {
    if (cowCheckList.includes(regno)) {
      setCowCheckList(cowCheckList.filter((data) => data !== regno));
    }

    if (!cowCheckList.includes(regno)) {
      setCowCheckList([...cowCheckList, regno]);
    }
  }

  // 체크박스 전체선택
  function checkAllCow() {
    if (cowCheckList.length === filterData.length) {
      setCowCheckList([]);
    }

    if (cowCheckList.length !== filterData.length) {
      setCowCheckList(filterData.map((data) => data.regno));
    }
  }

  // 정렬
  const [ currentSort , setCurrentSort ] = useState('');

  const sortType = (type) => {
    setFilterData((prevData) => {
      const sortedCowList = [...prevData];
      
      // 현재 정렬된 상태 확인
      const isAscending = currentSort === type;
  
      sortedCowList.sort((a, b) => {
        if (isAscending) {
          return b[type] - a[type];
        }

        return a[type] - b[type];
      });
  
      // 현재 정렬된 상태에 따라 토글
      setCurrentSort(isAscending ? `-${type}` : type);
  
      return sortedCowList;
    });
  };

  const sortDate = (type) => {
    setFilterData((prevData) => {
      const sortedCowList = [...prevData];
  
      const isAscending = currentSort === type;
      
      sortedCowList.sort((a, b) => {
        const getDate = (cow) => new Date(cow[type]);
        const dateA = getDate(a);
        const dateB = getDate(b);
  
        if (isAscending) {
          return dateB - dateA;
        }
  
        return dateA - dateB;
      });
  
      setCurrentSort(isAscending ? `-${type}` : type);
  
      return sortedCowList;
    });
  };

  // 계획교배 시작
  const navigate = useNavigate();
  const startPlan = () => { 
    if (cowCheckList.length === 0) {
      setErrorMessage('암소를 선택해주세요.');
      openModal();
      return false;
    }

    if (cowCheckList.length > 0) {
      localStorage.setItem('selectGirlCow', cowCheckList);

      if (window.confirm('암소가 저장되었습니다. 계획교배 화면으로 이동합니다.')){
        navigate('/milk/milkplanmating');
      }
    }

    return true;
  };

  return (
    <div className='girlcowSelect'>
      <ErrorMessage isOpen={isModalOpen} content={errorMessage} closeModal={closeModal}/>
      <h2>암소 유전능력 및 교배우 선정</h2>
      <div className='girlcowSelectHead'>
        <div className='exp'>
          <span>선택</span>
          <select 
            defaultValue='all' 
            ref={expRef}
            onChange={filterExpCow}
          >
            <option value='all'>전체</option>
            <option value='exp'>경산우</option>
            <option value='noexp'>미경산우</option>
          </select>
        </div>
        <div className='girlcowSearch'>
          <div className='girlcowSearchArea'>
            <select 
              defaultValue='number'
              ref={searchRef}
            >
              <option value='number'>관리번호</option>
              <option value='code'>바코드</option>
            </select>
            <input 
              type='text' 
              ref={searchInputRef}
            />
          </div>
          <div className='girlcowSearchBtn'>
            <button 
              type='button'
              onClick={searchCow}
            >조회</button>
            <button 
              type='button'
              onClick={startPlan}
            >계획교배 시작</button>
          </div>
        </div>
      </div>
      <span>※ 유전평가 자료 제공 : 국립축산과학원</span>
      <div className='cowSelectTable'>
        <table>
          <thead>
            <tr>
              <th rowSpan='2'>선택</th>
              <th colSpan='4'>개체정보</th>
              <th colSpan='4'>유성분&#40;EBV&#41;</th>
              <th colSpan='3'>체형&#40;EBV&#41;</th>
              <th rowSpan='2'>
                <button 
                  type='button'
                  onClick={()=> sortType('ktpi')}
                  className={currentSort.includes('ktpi') ? 'current' : ''}
                >KTPI&#40;EBV&#41;</button>
              </th>
            </tr>
            <tr>
              <th>
                <button 
                  type='button'
                  onClick={()=> sortType('csname')}
                  className={currentSort.includes('csname') ? 'current' : ''}
                >관리번호</button>
              </th>
              <th>
                <button 
                  type='button'
                  onClick={()=> sortType('barcode')}
                  className={currentSort.includes('barcode') ? 'current' : ''}
                >바코드</button>
              </th>
              <th>
                <button 
                  type='button'
                  onClick={()=> sortDate('birth')}
                  className={currentSort.includes('birth') ? 'current' : ''}
                >생년월일</button>
              </th>
              <th>
                <button 
                  type='button'
                  onClick={()=> sortType('pari')}
                  className={currentSort.includes('pari') ? 'current' : ''}
                >산차</button>
              </th>
              <th>
                <button 
                  type='button'
                  onClick={()=> sortType('mc')}
                  className={currentSort.includes('mc') ? 'current' : ''}
                >유량&#40;kg&#41;</button>
              </th>
              <th>
                <button 
                  type='button'
                  onClick={()=> sortType('fc')}
                  className={currentSort.includes('fc') ? 'current' : ''}
                >유지방&#40;kg&#41;</button>
              </th>
              <th>
                <button
                  type='button'
                  onClick={()=> sortType('pc')}
                  className={currentSort.includes('pc') ? 'current' : ''}
                >유단백&#40;kg&#41;</button>
              </th>
              <th>
                <button
                  type='button'
                  onClick={()=> sortType('sc')}
                  className={currentSort.includes('sc') ? 'current' : ''}
              >체세포&#40;1000&#41;</button>
              </th>
              <th>
                <button
                  type='button'
                  onClick={()=> sortType('cc_16')}
                  className={currentSort.includes('cc_16') ? 'current' : ''}
                >종합점수</button>
              </th>
              <th>
                <button
                  type='button'
                  onClick={()=> sortType('udc')}
                  className={currentSort.includes('udc') ? 'current' : ''}
                >유방</button>
              </th>
              <th>
                <button
                  type='button'
                  onClick={()=> sortType('flc')}
                  className={currentSort.includes('flc') ? 'current' : ''}
                >지제</button>
              </th>
            </tr>
            <tr>
              <th>
                <input 
                  type='checkbox'
                  checked={filterData && cowCheckList.length === filterData.length}
                  onClick={checkAllCow}
                />
                <span className='hidden'>전체선택</span>
              </th>
              <th colSpan='4'>개량목표</th>
              <th>{mainData && mainData.plot_data[0].val_mc}</th>
              <th>{mainData && mainData.plot_data[0].val_fc}</th>
              <th>{mainData && mainData.plot_data[0].val_pc}</th>
              <th>{mainData && mainData.plot_data[0].val_sc}</th>
              <th>{mainData && mainData.plot_data[0].val_cc_16}</th>
              <th>{mainData && mainData.plot_data[0].val_udc}</th>
              <th>{mainData && mainData.plot_data[0].val_flc}</th>
              <th>{mainData && mainData.plot_data[0].val_ktpi}</th>
            </tr>
          </thead>
        </table>
        
        <ul className='girlcowSelectList'>
          {
            filterData === null 
            ?
            <li>데이터를 불러오는 중입니다.</li>
            :
            filterData.length === 0 
            ?
            <li>검색된 데이터가 없습니다.</li>
            :
            filterData.map((data, index) => (
              <li key={index} className={data.csname}>
                <ul>
                  <li>
                    <input 
                      type='checkbox'
                      checked={cowCheckList.includes(data.regno)}
                      onChange={()=> checkCow(data.regno)}
                    />
                  </li>
                  <li>{data.csname}</li>
                  <li>{data.barcode}</li>
                  <li>{data.birth}</li>
                  <li>{data.pari}</li>
                  <li className='numberCon'>{Number(data.mc).toFixed(2)}</li>
                  <li className='numberCon'>{Number(data.fc).toFixed(2)}</li>
                  <li className='numberCon'>{Number(data.pc).toFixed(2)}</li>
                  <li className='numberCon'>{Number(data.sc).toFixed(2)}</li>
                  <li className='numberCon'>{Number(data.cc_16).toFixed(2)}</li>
                  <li className='numberCon'>{Number(data.udc).toFixed(2)}</li>
                  <li className='numberCon'>{Number(data.flc).toFixed(2)}</li>
                  <li className='numberCon'>{Number(data.ktpi).toFixed(2)}</li>
                </ul>
              </li>
            ))
          }
        </ul>
      </div>
      <ul>
        <li>
          <span>- 검은색 : 혈통 및 검정기록이 있고 국내에서 유전능력평가를 받은 암소</span>
        </li>
        <li>
          <span>- 회색음영 : 검정기록이 없어 부모의 유전능력 결과를 바탕으로 계산한 예측치&#40;PA&#41; &#40;유전평가보다 정확도 낮음&#41;</span>
        </li>
        <li>
          <span>- 빈칸&#40;유전평가 예측불가&#41; : 부모의 유전능력평가 결과가 없어, 암소의 유전능력 예측 불가&#40;유전체&#40;Genomic&#41; 평가로 보완 예정&#41;</span>
        </li>
        <li>
          <span>*부모의 유전능력 평가 결과가 없는 이유: 1. 영불정액 교배, 2. 해당 암소가 기초등록, 3. 검정 미참여인 경우 등</span>
        </li>
      </ul>
    </div>
  );
}

export default GirlcowSelect;
