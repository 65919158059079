import React from 'react';

import pic661 from 'assets/images/sub/_images/pic661.jpg';
import pic662 from 'assets/images/sub/_images/pic662.jpg';

function Do130() {

  return (
    <div className="techinfoview">
      <h1>배꼽 헤르니아(탈장)</h1>
      <p className="pstyle">제륜(臍輪:배꼽구멍)을 통해 장(章)의 일부가 복벽 밖으로 빠져나와 피하에 정체되어 있는 상태를 말한다. </p>
      <div className="leftpart">
        <h2>원인</h2>
        <ul>
          <li>선천적으로 제륜이 정상보다 클 때 </li>
          <li>드물게는 배꼽의 세균감염에 의한 화농, 변비시의 노책, 고창증등에 의해 복압이 높아져서 배꼽     헤르니아가 일어나는 일이 있다. </li>
        </ul>
        <h2>증상</h2>
        <ul>
          <li>배꼽 부위의 피부가 불거져 나온 것이 달걀 정도의 크기에서부터 축구공 크기에 이르기까지 차이가 많다. 손으로 만져보면 말랑말랑하고 열감이나 통증은 없다. </li>
          <li>툭 불거진 배꼽이 시일이 지남에 따라 점차 커지면서 그 주머니 속에 소장(小腸)이 들어가서 점차로 소화장애 등 전신적인 증상이 나타난다. </li>
          <li>제륜의 직경에 손가락 4개가 들어갈 정도로 커지면 전신 증상이 악화하므로 수술을 받도록 해야 한다.</li>
        </ul>
        <h2>목장에서의 처치</h2>
        <ul>
          <li>선천적인 배꼽 헤르니아는 자연히 낫는 경우가 있다. </li>
          <li>헤르니아의 크기가 점점 커질때는 자연치유가 가능성이 없으므로 수의사와 상의하여 수술을 받아야 한다.</li>
        </ul>
      </div>
      <div className="rightpart">
        <img src={pic661} className="nonborder" alt=""/>
        <img src={pic662} className="nonborder" alt=""/>
      </div>
    </div>
  );
}

export default Do130;