import React from 'react';
import { Outlet } from 'react-router-dom';

// 컴포넌트
import GeneHeader from 'components/gene/header/GeneHeader';

// scss
import './gene.scss';

function Gene() {

  return (
    <div className='gene'>
      <GeneHeader />
      <div className='geneInner'>
        <Outlet />
      </div>
    </div>
  );
}

export default Gene;
