import React from 'react';

import Pic502 from 'assets/images/sub/_images/pic502.jpg';
import Pic503 from 'assets/images/sub/_images/pic503.jpg';

function Do87() {

  return (
    <div className="techinfoview">
      <h1>비만우증후군</h1>
      <p className="pstyle">비만 상태의 임신우가 분만후 여러 가지 산후 질병(유열m 후산정체, 산욕열등)을 병발하여 위독한 증상을 나타나는 질병으로 사망률이 높은 대사성질병이다.</p>
      <div className="leftpart">
        <h2>원인</h2>
        <ul>
          <li>비유후기에서 건유기에 걸쳐 과다한 사료급여에 의해 생겨나는 임신우의 비만은 지방간을 초래하여 간의 기능을 저해하여 케톤체를 생성, 중동증상을 일으킴. </li>
        </ul>
        <h2>증상</h2>
        <ul>
          <li>분만전 비만인 소가 분만후 갑자기 야위기 시작하여 케토시스와 같은 증상을 보인다. 
            <ul>
              <li>처음에는 원기 소실, 식욕감퇴와 채식중지, 피모광택의 소실등을 볼 수 있다.</li>
              <li>유량의 감소와 제1위 운동이 감퇴된다.</li>
              <li>숨쉴 때 및 뇨와 분변은 심한 아세톤 냄새가 난다. 뇨 케톤체 검사는 양성반응을 보인다. </li>
              <li>더욱 증상이 악화되면 설사와 기립불능에 빠져 죽게 된다. </li>
            </ul>
          </li>
        </ul>
        <h2>목장에서의 처치</h2>
        <ul>
          <li>사양관리 개선으로 예방에 유의한다.</li>
          <li>비유후기∼건유기 중에 너무 살찌지 않도록 해야 한다. </li>
          <li>비유 초기에는 비유량에 맞추어 충분한 양의 사료급여 하여야 한다.</li>
          <li>건유중에 콜린(비타민B복합체의 하나)제를 함유한 사료 첨가제를 급여하면 예방효과를 볼 수 있다. </li>
          <li>식욕이 정상으로 회복될 때까지는 1개월이 걸리는 일이 있으므로 수의사의 정확한 진단과 조치가 필요하다.  </li>
        </ul>
      </div>
      <div className="rightpart">
        <img src={Pic502} className="nonborder" alt=""/>
        <p>비만해 있는 건유증인 젖소</p>
        <img src={Pic503} className="nonborder" alt=""/>
        <p>지방변성이 뚜렷한 간장의 모습:황색으로 변해 있음</p>
      </div>
    </div>
  );
}

export default Do87;